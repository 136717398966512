import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { ReactComponent as RefreshIcon } from "../../../Assets/icons/Refresh.svg";
import { ReactComponent as CheckboxIcon } from "../../../Assets/icons/CheckboxTerms.svg";
import { ReactComponent as DatePickerIcon } from "../../../Assets/icons/DatePickerIcon.svg";
import {
  getDDMMMYYYYFormat,
  getLastUpdatedTime,
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
} from "../../../Utils/utils";
import { DatePicker } from "@mui/x-date-pickers";
import * as ServiceAction from "../../../redux/actions/service_request/index";
import { connect } from "react-redux";

const StyledTypography = styled(Typography)({
  color: "#0F2C6F",
  fontSize: "14px",
  fontWeight: 600,
  marginBottom: "5px",
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};
// const hours = ["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"]

const CargoLift = ({
  tenantData,
  serviceType,
  returnInputData,
  getCargoLiftObjective,
  cargoLiftObjective,
  termsAndCondition,
  requestId,
  serviceRequestByID,
  serviceReqDetails,
  setFromPreviewPage,
  previewPageData,
  setServiceType,
}) => {
  const [details, setDetails] = useState({});
  const [inputErrors, setInputErrors] = useState({});
  const [CheckboxDetails, setCheckboxDetails] = useState([
    ...cargoLiftObjective,
  ]);
  console.log(CheckboxDetails)
  const [liftPurposeSelected, setLiftPurposeSelected] = useState([]);
  console.log(liftPurposeSelected)
  const [liftTime, setLiftTime] = useState({
    date: null,
    time: "",
    startTime:
      Array.isArray(serviceReqDetails?.serviceRequestsArrayList) &&
      serviceReqDetails.serviceRequestsArrayList[0].from_time != "NA"
        ? serviceReqDetails.serviceRequestsArrayList[0].from_time
        : "",
    endTime:
      Array.isArray(serviceReqDetails?.serviceRequestsArrayList) &&
      serviceReqDetails.serviceRequestsArrayList[0].to_time != "NA"
        ? serviceReqDetails.serviceRequestsArrayList[0].to_time
        : " ",
  });
  const [liftConditionCheckbox, setLiftConditionCheckbox] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [hours, setHours] = useState([]);
  const [endHours, setEndHours] = useState([]);
  let terms_condition =
    termsAndCondition?.config_value &&
    JSON.parse(termsAndCondition?.config_value);

  useEffect(() => {
    setLastUpdatedTime(getLastUpdatedTime());
    if (
      requestId == "null" &&
      sessionStorage.getItem("LiftScreen") &&
      previewPageData.lift
    ) {
    } else {
      getCargoLiftObjective();
    }
    getLiftStartHours(0);
    getLiftEndHours(0);

    if (requestId != "null") {
      serviceRequestByID({ service_requests_id: requestId });
    }
  }, []);

  useEffect(() => {
    if (
      requestId == "null" &&
      sessionStorage.getItem("LiftScreen") &&
      previewPageData.lift
    ) {
    } else {
      let array = [...cargoLiftObjective];
      for (let i = 0; i < array.length; i++) {
        array[i].checked = false;
      }
      setCheckboxDetails(array);
    }
  }, [cargoLiftObjective]);

  //from preview screen
  useEffect(() => {
    if (
      requestId == "null" &&
      sessionStorage.getItem("LiftScreen") &&
      previewPageData.lift
    ) {
      let data = JSON.parse(sessionStorage.getItem("LiftScreen"));
      setDetails({
        ...details,
        ...data,
        office: `+${data.office_code} ${data.office_number}`,
        mobile: `+${data.mobile_code} ${data.mobile_number}`,
      });
      setLiftTime({
        ...liftTime,
        date: new Date(data.date),
        startTime: data.startTime,
        endTime: data.endTime,
      });
      setLiftPurposeSelected([...data.liftPurpose]);
      setFromPreviewPage({ lift: false, security: false, airCon: false });
      setCheckboxDetails([...data.checkbox]);
    }
  }, []);

  //from draft
  useEffect(() => {
    if (
      serviceReqDetails != null &&
      serviceReqDetails?.serviceRequestsArrayList?.length > 0
    ) {
      let draftDetails = serviceReqDetails?.serviceRequestsArrayList[0];
      const date = new Date(draftDetails.serviceRequestDate);

      setDetails({
        ...details,
        contractorCompany:
          draftDetails.contractor_company != "NA"
            ? draftDetails.contractor_company
            : "",
        personInCharge:
          draftDetails.person_in_charge != "NA"
            ? draftDetails.person_in_charge
            : "",
        workDetails:
          draftDetails.description != "NA" ? draftDetails.description : "",
        office:
          draftDetails.office_phone_code && draftDetails.office_phone_no != "NA"
            ? `+${draftDetails.office_phone_code} ${draftDetails.office_phone_no}`
            : "",
        office_code:
          draftDetails.office_phone_code && draftDetails.office_phone_no != "NA"
            ? draftDetails.office_phone_code
            : "",
        office_number:
          draftDetails.office_phone_code && draftDetails.office_phone_no != "NA"
            ? draftDetails.office_phone_no
            : "",
        mobile:
          draftDetails.contractor_phone_code &&
          draftDetails.contractor_phone_no != "NA"
            ? `+${draftDetails.contractor_phone_code} ${draftDetails.contractor_phone_no}`
            : "",
        mobile_code:
          draftDetails.contractor_phone_code &&
          draftDetails.contractor_phone_no != "NA"
            ? draftDetails.contractor_phone_code
            : "",
        mobile_number:
          draftDetails.contractor_phone_code &&
          draftDetails.contractor_phone_no != "NA"
            ? draftDetails.contractor_phone_no
            : "",
        date:
          draftDetails.serviceRequestDate != "NA"
            ? getYYYYMMDDFormat(date)
            : "",
        startTime: draftDetails.from_time != "NA" ? draftDetails.from_time : "",
        endTime: draftDetails.to_time != "NA" ? draftDetails.to_time : "",
      });
      setLiftTime({
        ...liftTime,
        date: date,
        startTime: draftDetails.from_time,
        endTime: draftDetails.to_time,
      });
    }

    if (
      serviceReqDetails != null &&
      serviceReqDetails.cargoObjectivesArrayList?.length > 0
    ) {
      let array = serviceReqDetails.cargoObjectivesArrayList;
      let checkBox = CheckboxDetails.map((item) => {
        let isMatched = array.some((obj) => obj.cargo_objectives_id === item.cargo_objectives_id);
        
        if (isMatched) {
          return { ...item, checked: true };
        } else {
          return item;
        }
      });
    
      // Filter the items that are checked to update liftPurposeSelected
      let selectedPurposes = checkBox.filter((item) => item.checked);
    
      // Update the states
      setCheckboxDetails(checkBox);
      setLiftPurposeSelected(selectedPurposes);
    }
    
  }, [serviceReqDetails]);

  const getLiftStartHours = (minTime) => {
    let timeIntrevels = [];
    for (let i = minTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    setHours([...timeIntrevels]);
  };

  const getLiftEndHours = (minTime) => {
    let innerLoopmin = minTime.toString().charAt(3);
    let timeIntrevels = [],
      startTime =
        minTime === 0
          ? 0
          : innerLoopmin != 0
          ? parseInt(minTime.slice(0, 2)) + 1
          : parseInt(minTime.slice(0, 2));
    for (let i = startTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    setEndHours([...timeIntrevels]);
  };

  const handleDetails = (e, datafrom, countryCode) => {
    if (e === null) {
      setInputErrors({
        ...inputErrors,
        [datafrom]: "Select a valid data",
      });
      setDetails({
        ...details,
        date: null,
      });
    } else {
      if (datafrom === "date") {
        let eDate = e.getDate() <= 9 ? `0${e.getDate()}` : e.getDate();
        let eMonth =
          e.getMonth() <= 9 ? `0${e.getMonth() + 1}` : e.getMonth() + 1;
        let eYear = e.getFullYear();
        let endDate = `${eYear}-${eMonth}-${eDate}`;
        setDetails({
          ...details,
          ["date"]: endDate,
        });
        setLiftTime({
          ...liftTime,
          date: e,
        });
        setInputErrors({ ...inputErrors, ["date"]: "" });
      } else if (datafrom === "startTime" || datafrom === "endTime") {
        setDetails({
          ...details,
          [datafrom]: e.target.value,
        });
        setLiftTime({
          ...liftTime,
          [datafrom]: e.target.value,
        });
        setInputErrors({ ...inputErrors, [datafrom]: "" });
        if (datafrom === "startTime") {
          getLiftEndHours(e.target.value);
        }
      } else if (datafrom == "mobile" || datafrom == "office") {
        setDetails({
          ...details,
          [`${datafrom}_code`]: countryCode.dialCode,
          [`${datafrom}_number`]: e.slice(countryCode.dialCode.length),
        });
        setInputErrors({ ...inputErrors, office: "" });
      } else if (datafrom === "nationality") {
        let { name, value } = e.target;
        setDetails({
          ...details,
          [name]: value.nationality_id,
          [`${name}name`]: value.nationality_name,
        });
      } else {
        let { name, value } = e.target;
        setDetails({
          ...details,
          [name]: value,
        });
        setInputErrors({ ...inputErrors, [name]: "" });
      }
    }
  };
  const handleCheckBox = (id, e) => {
    let arr = [...cargoLiftObjective];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].cargo_objectives_id === id) {
        arr[i].checked = e.target.checked;
      }
    }
    setCheckboxDetails([...arr]);
    let filter = arr.filter((value) => {
      return value.checked === true;
    });
    setLiftPurposeSelected(filter);
  };

  const validateCargoLift = () => {
    let isValid,
      errorArr = [],
      errObj = {};

    if (details.hasOwnProperty("date") && details.date !== null) {
      errorArr.push(true);
    } else {
      errObj["date"] = "Date is required!";
      errorArr.push(false);
    }

    if (details.hasOwnProperty("startTime") && details.startTime) {
      errorArr.push(true);
    } else {
      errObj["startTime"] = "Start  Time is Required!";
      errorArr.push(false);
    }

    if (details.hasOwnProperty("endTime") && details.endTime) {
      errorArr.push(true);
    } else {
      errObj["endTime"] = " End Time is Required!";
      errorArr.push(false);
    }

    if (details.hasOwnProperty("personInCharge") && details.personInCharge) {
      errorArr.push(true);
    } else {
      errObj["personInCharge"] = "PersonInCharge is Required";
      errorArr.push(true);
    }

    if (
      details.hasOwnProperty("contractorCompany") &&
      details.contractorCompany
    ) {
      errorArr.push(true);
    } else {
      errObj["contractorCompany"] = "Vendor Company is Required!";
      errorArr.push(false);
    }

    if (details.hasOwnProperty("office_number") && details.office_number) {
      errorArr.push(true);
    } else {
      errObj["office"] = "Office Number is Required!";
      errorArr.push(false);
    }

    if (liftPurposeSelected.length > 0) {
      errorArr.push(true);
    } else {
      errObj["purpose"] = "Select Purpose";
      errorArr.push(false);
    }

    setInputErrors(errObj);

    isValid = errorArr.includes(false);

    return isValid ? false : true;
  };

  const handleContinue = () => {
    let valid = validateCargoLift();
    if (valid) {
      details["liftPurpose"] = liftPurposeSelected;
      details["company_id"] = tenantData?.companyId;
      details["service_request_types_id"] = serviceType;
      details["terms_condition"] = terms_condition;
      details["checkbox"] = CheckboxDetails;
      returnInputData(details);
      sessionStorage.setItem("LiftScreen", JSON.stringify(details));
    }
  };

  return (
    <>
      <Box sx={{ mt: 4, borderTop: "1px solid #DBDFE9" }}>
        <Box sx={{ px: 3 }}>
          <Typography
            sx={{ color: "#0F2C6F", fontSize: "16px", fontWeight: 550, mt: 2 }}
          >
            Cargo Lift Usage Period
          </Typography>
          <Typography sx={{ color: "#6F80A9", fontSize: "14px", mt: "5px" }}>
            Please enter the date and time that you require the use of cargo
            lift from and till
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={5}>
              <StyledTypography>Date</StyledTypography>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                components={{
                  OpenPickerIcon: DatePickerIcon,
                }}
                disablePast
                value={liftTime.date}
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => preventDatePickerKeyboard(e)}
                    sx={{ height: "40px", color: "#0F2C6F" }}
                    disabled
                    {...params}
                  />
                )}
                onChange={(e) => handleDetails(e, "date")}
              />
              {inputErrors.date && (
                <Typography sx={{ color: "#f44336", mt: 2 }}>
                  {inputErrors.date}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={2}>
              <StyledTypography>Start Time </StyledTypography>
              <Select
                MenuProps={MenuProps}
                defaultValue={`${liftTime.startTime}`}
                sx={{ height: "55px", width: 100, color: "#0F2C6F" }}
                onChange={(e) => handleDetails(e, "startTime")}
                value={liftTime.startTime}
              >
                {hours.map((value, index) => {
                  return (
                    <MenuItem
                      sx={{ color: "#0F2C6F" }}
                      key={index}
                      value={value}
                    >
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              {inputErrors.startTime && (
                <Typography sx={{ color: "#f44336", mt: 1 }}>
                  {inputErrors.startTime}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledTypography>End Time</StyledTypography>
              <Select
                MenuProps={MenuProps}
                defaultValue={`${liftTime.endTime}`}
                sx={{ height: "55px", width: 100, color: "#0F2C6F" }}
                onChange={(e) => handleDetails(e, "endTime")}
                value={liftTime.endTime}
              >
                {endHours.map((value, index) => {
                  return (
                    liftTime.startTime != value && (
                      <MenuItem
                        sx={{ color: "#0F2C6F" }}
                        key={index}
                        value={value}
                      >
                        {value}
                      </MenuItem>
                    )
                  );
                })}
              </Select>
              {inputErrors.endTime && (
                <Typography sx={{ color: "#f44336", mt: 1 }}>
                  {inputErrors.endTime}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ px: 3 }}>
          <Typography
            sx={{ color: "#0F2C6F", fontSize: "16px", fontWeight: 550, mt: 7 }}
          >
            Contractor Details
          </Typography>
          <Typography sx={{ color: "#6F80A9", fontSize: "14px", mt: "5px" }}>
            Please enter contractor details and ensure details are accurate for
            building management processing.
          </Typography>

          <Grid container columnSpacing={1} rowSpacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <StyledTypography>Vendor Company *</StyledTypography>
              <OutlinedInput
                autoComplete="off"
                value={details.contractorCompany}
                name="contractorCompany"
                onChange={(e) => handleDetails(e)}
                sx={{
                  mt: "5px",
                  height: "46px",
                  borderRadius: "4px",
                  width: "90%",
                  color: "#0F2C6F",
                }}
              />
              {inputErrors.contractorCompany && (
                <Typography sx={{ color: "#f44336", mt: 1 }}>
                  {inputErrors.contractorCompany}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography sx={{ mb: 1 }}>
                Office Telephone *
              </StyledTypography>
              <PhoneInput
                value={details.office}
                inputProps={{ name: "office" }}
                onChange={(data, country, event, format) =>
                  handleDetails(data, "office", country)
                }
                country="sg"
                inputStyle={{ height: "46px", width: "90%", color: "#0F2C6F" }}
              />
              {inputErrors.office && (
                <Typography sx={{ color: "#f44336", mt: 1 }}>
                  {inputErrors.office}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography>Person-In-Charge *</StyledTypography>
              <OutlinedInput
                autoComplete="off"
                value={details.personInCharge}
                name="personInCharge"
                onChange={(e) => handleDetails(e)}
                sx={{
                  mt: "5px",
                  height: "46px",
                  borderRadius: "4px",
                  width: "90%",
                  color: "#0F2C6F",
                }}
              />
              {inputErrors.personInCharge && (
                <Typography sx={{ color: "#f44336", mt: 1 }}>
                  {inputErrors.personInCharge}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography sx={{ mb: 1 }}>
                Mobile Number *
              </StyledTypography>
              <PhoneInput
                value={details.mobile}
                inputProps={{ name: "mobile" }}
                onChange={(data, country, event, format) =>
                  handleDetails(data, "mobile", country)
                }
                country="sg"
                inputStyle={{ height: "46px", width: "90%", color: "#0F2C6F" }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ px: 3, borderTop: "1px solid #DBDFE9", mt: 4, py: 3 }}>
          <StyledTypography>Purpose</StyledTypography>
          <Typography
            sx={{ color: "#6F80A9", fontSize: "14px", mt: "5px", mb: "8px" }}
          >
            Select one or more reasons
          </Typography>
          <Grid container>
            {CheckboxDetails.length > 0 &&
              CheckboxDetails.map((value) => (
                <Grid item xs={12} sm={6}>
                  <Checkbox
                    icon={<CheckboxIcon />}
                    checked={value.checked}
                    onClick={(e) =>
                      handleCheckBox(value.cargo_objectives_id, e)
                    }
                  />
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#6F80A9",
                      fontSize: "14px",
                      mt: "5px",
                    }}
                  >
                    {value.objective}
                  </Typography>
                </Grid>
              ))}
          </Grid>
          {inputErrors.purpose && (
            <Typography sx={{ color: "#f44336", mt: 1 }}>
              {inputErrors.purpose}
            </Typography>
          )}
          <StyledTypography sx={{ mt: 2 }}>Work Details </StyledTypography>
          <OutlinedInput
            autoComplete="off"
            value={details.workDetails}
            onChange={(e) => handleDetails(e)}
            name="workDetails"
            sx={{ width: "100%", mt: 2, color: "#0F2C6F" }}
            placeholder="Please provide a brief description of the work"
          />
        </Box>
        <Divider sx={{ my: 4 }} />
        <Box sx={{ px: 3 }}>
          <StyledTypography>Specification of Cargo Lift</StyledTypography>
          <Typography sx={{ color: "#6F80A9", fontSize: "14px", mt: "5px" }}>
            Please comply to the specifications below
          </Typography>
          <Grid
            container
            sx={{
              border: "1px solid #DBDFE9",
              borderRadius: "6px",
              padding: "16px 24px",
              mt: 3,
              background: "#F3F4F8",
            }}
          >
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "14px", color: "#0F2C6F" }}
              >
                Maximum Load:{" "}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                >
                  1,600 kg
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#0F2C6F" }}
              >
                Speed:{" "}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                >
                  210mps
                </span>{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "14px", color: "#0F2C6F" }}
              >
                Width:{" "}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                >
                  2.1m x Depth 1.7m
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "14px", color: "#0F2C6F" }}
              >
                Height :{" "}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                >
                  3.5m{" "}
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "14px", color: "#0F2C6F" }}
              >
                Door Opening :{" "}
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                >
                  1.5m x 2.1m
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: "#6F80A9", mt: 1 }}>
                *Do not Load any material/equipment beyond the specified size
                and weight capacity of the lift.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {Array.isArray(terms_condition) && (
          <Box sx={{ mt: 3, px: 3 }}>
            <Typography
              sx={{ fontSize: "18px", color: "#0F2C6F", fontWeight: 550 }}
            >
              {terms_condition[0]?.title}*
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: " #6F80A9",
                my: 2,
                fontWeigth: 500,
              }}
            >
              {terms_condition[0]?.description}
            </Typography>
            {terms_condition[1]?.rules?.map((value, index) => (
              <Typography
                sx={{
                  color: "#6F80A9",
                  fontSize: "13px",
                  my: 1.5,
                  fontWeigth: 500,
                }}
              >{`${index + 1} . ${value[index + 1]}`}</Typography>
            ))}

            <Box sx={{ display: "flex" }}>
              <Checkbox
                icon={<CheckboxIcon />}
                sx={{ ml: -1 }}
                checked={liftConditionCheckbox}
                onClick={() => setLiftConditionCheckbox(!liftConditionCheckbox)}
              />
              <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 2 }}>
                I have read and agreed to comply with the above terms &
                conditions for the usage of the cargo lift with the Building
                Management's approval.
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          backgroundColor: "rgba(143, 146, 161,0.05)",
          py: 5,
        }}
      >
        <Box>
          <RefreshIcon style={{ marginTop: "5px" }} />
          <Typography
            sx={{
              color: " #9AA6C3",
              fontSize: "14px",
              display: "inline",
              ml: 2,
            }}
          >
            Last Updated at {lastUpdatedTime}
          </Typography>
        </Box>
        <Box sx={{ p: 5, pt: 0, pr: 0 }}>
          <Button
            sx={{
              color: "#0F2C6F",
              height: "50px",
              width: 100,
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
              textTransform: "capitalize",
              border: "1px solid #AAC3FF",
              "&:hover": { backgroundColor: "#FFFFFF" },
            }}
            onClick={() => setServiceType(0)}
            varian="outlined"
          >
            Delete
          </Button>
          <Button
            variant="contained"
            disabled={!liftConditionCheckbox}
            onClick={() => handleContinue()}
            sx={{
              backgroundColor: "#123FA9",
              width: 100,
              color: "#FFFFFF",
              ml: 3,
              borderRadius: "8px",
              height: "50px",
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "#123FA9" },
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cargoLiftObjective: state.Service_Req_Reducer.cargoLiftObjective,
    serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCargoLiftObjective: () =>
      dispatch(ServiceAction.getCargoLiftObjective()),
    serviceRequestByID: (data) =>
      dispatch(ServiceAction.serviceRequestDetailsById(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CargoLift);
