import React, {useState, useEffect} from 'react'
import { Box, FormControl,Button, Grid, MenuItem ,  Avatar, Divider , InputLabel, Chip, IconButton, OutlinedInput,Select, FormControlLabel, Tab, Tabs, Typography, Checkbox, TextField } from "@mui/material";
import { ReactComponent as PlusWhiteIcon } from "../../Assets/icons/PlusWhiteIcon.svg";
import {ReactComponent as DownloadIcon} from '../../Assets/icons/Download.svg';
import { Link, useParams,useNavigate} from "react-router-dom";
import styled from '@emotion/styled';
import {makeStyles} from '@mui/styles';
import { MenuProps } from '../../Utils/utils';
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg'
import PreviewImage  from "../../Assets/icons//PreviewImage.png";
import FacilityImage  from "../../Assets/icons//facilityImage.png";
import {ReactComponent as Circle} from '../../Assets/icons/circle.svg';
import {ReactComponent as FacilitySeatIcon} from '../../Assets/icons/FacilitySeatIcon.svg';
import {ReactComponent as FacilitySGDIcon} from '../../Assets/icons/FacilitySGDIcon.svg';
import {ReactComponent as FacilityConference} from '../../Assets/icons/FacilityConference.svg';
import {ReactComponent as FacilityLocation} from '../../Assets/icons/FacilityLocation.svg';

const StyledTab = styled(Tab)({
    textTransform:'capitalize',
    color:' #123FA9',
    flex:1
})

const spanStyle={
    backgroundColor:'#123FA9',
    color:'#FFFFFF',
    borderRadius:'50%',
    padding:'4px 6px',
    fontSize:'12px',
    marginRight:'5px'
}

const useStyles = makeStyles({
    flexContainer: {
      justifyContent: 'center',
    },
    indicator: {
        left: '0!important',
        width: '50%!important', 
      },
  });

export default function ManageBookingFacility() {
    const navigate = useNavigate();
    const [tabValue,setTabValue] = useState(0);
    const [tabDisabled,setTabDisabled] = useState({tab2:false})
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const classes = useStyles();

    const TabFilterOptions = () => {
        return (
          <>
          {/* {toastAlert.status && <ToastAlert status={toastAlert.status} message={toastAlert.message} color={toastAlert.color} />} */}
            <Grid container columnSpacing={1}>
              <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap:'10px'}}>
                <FormControl fullWidth size="small" sx={{ width: 160, }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Facility Type</InputLabel>
                  <Select name="facilityType" 
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                    {[1,2,3,4,5,6,7,8,9,0]?.map((item) => (
                      <MenuItem sx={{color:'#0F2C6F'}} value={item}>{item} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ width: 160, }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Room Amenities</InputLabel>
                  <Select name="roomAmenities" 
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                    {[1,2,3,4,5,6,7,8,9,0]?.map((item) => (
                      <MenuItem sx={{color:'#0F2C6F'}} value={item}>{item} </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ width: 160, }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Capacity</InputLabel>
                  <Select name="serviceType" 
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                    {[1,2,3,4,5,6,7,8,9,0]?.map((item) => (
                      <MenuItem sx={{color:'#0F2C6F'}} value={item}>{item} </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => console.log('reset')}>Reset</Typography>
              </Grid>
              <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <OutlinedInput placeholder="Search facility"
                  name='search'
                  endAdornment={<SearchIcon />} size="small" />
              </Grid>
            </Grid>
          </>
        )
      }

      const imageUrls = [
        FacilityImage,
        PreviewImage,
        FacilityImage,
      ];

      const handleImageSelect = (index) => {
        setSelectedImageIndex(index);
      };

      const BookingFacilities = () => {
        return(
            <>
                <Box sx={{backgroundColor:'rgba(143, 146, 161,0.05)', height:'10px'}} />
                    <Box sx={{backgroundColor:'#FFFFFF', borderRadius:'8px', mb:3}}>
                    
                        <Box sx={{display:'flex', justifyContent:'space-between', px:2,py:2}}>
                            <Box sx={{display:'flex', gap:'30px', width:'100%' }}>
                                <Box>
                                    <Carousels />
                                </Box>
                                
                                
                                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', gap:'15px'}}>
                                    <Box sx={{display:'flex', alignItems:'center', gap:'15px'}}>
                                        <FacilitySeatIcon />
                                        <Typography sx={{fontSize:'10px'}}>15 Seats</Typography>
                                    </Box>
                                    <Box sx={{display:'flex', alignItems:'center', gap:'15px'}}>
                                        <FacilitySGDIcon />
                                        <Typography sx={{fontSize:'10px'}}>SGD $50.00/hr</Typography>
                                    </Box>
                                    <Box sx={{display:'flex', alignItems:'center', gap:'15px'}}>
                                        <FacilityConference />
                                        <Typography sx={{fontSize:'10px'}}>Video Conference </Typography>
                                    </Box>
                                    <Box sx={{display:'flex', alignItems:'center', gap:'15px'}}>
                                        <FacilityLocation />
                                        <Typography sx={{fontSize:'10px'}}>Singland Tower L3</Typography>
                                    </Box>
                                </Box>
                             
                            </Box>
                            <Box >
                                <Typography sx={{background:'#D3F2E5', color:'#005934', borderRadius:'8px',padding:'0px 8px 0px 8px', fontSize:'14px',lineHeight:'24px'}}>Available</Typography>
                            </Box>
                        </Box>
                    </Box>
                </>
        )
      }

      const Carousels = () => {
        return(
            <Box sx={{display:'flex'}}>
                <Box>
                    <img src={imageUrls[selectedImageIndex]} style={{borderRadius:'10px'}} alt={`Image ${selectedImageIndex + 1}`} />

                    <Box sx={{display:'flex',justifyContent:'center', gap:'10px' }}>
                        {imageUrls.map((url, index) => (
                        <IconButton
                            key={index}
                            className="image-button"
                            onClick={() => handleImageSelect(index)}
                            sx={{width:'5px', height:'5px',
                            backgroundColor: selectedImageIndex === index ? '#0F2C6F' : '#D9D9D9', 
                            '&:hover': {
                                backgroundColor: selectedImageIndex === index ? '#0F2C6F' : '#D9D9D9', 
                            },
                            borderRadius: '50%', 
                            }}
                        >

                        </IconButton>
                        ))}
                    </Box>
                </Box>

            </Box>
            )

        }

  return (
    <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}} >
        <Grid item xs={0.5} />
            <Grid item xs={11}>
            <Box sx={{mb:5,mt:7}}>
                    <Typography sx={{fontSize:'26px',fontWeight:500,color:'#0F2C6F',my:1}}>Book Facility</Typography>
                    <Typography sx={{fontSize:'14px',fontWeight:500,color:'#6F80A9'}}>Overview of Facility Booking by Tenants.</Typography>
                </Box>
                <Box sx={{backgroundColor:'#FFFFFF', borderRadius:'8px', mb:3}}>
                    <Box sx={{borderBottom:'1px solid #DBDFE9'}}>
                        <Tabs value={tabValue} onChange={(e,value)=>setTabValue(value)}  classes={{flexContainer: classes.flexContainer}}>
                            <StyledTab label={
                                <Box>
                                    <span>Book New Facility</span>
                                </Box>
                            }></StyledTab>
                            <StyledTab disabled={tabDisabled.tab2} label={
                                <Box>
                                    <span>Existing Bookings</span>
                                </Box>
                            }></StyledTab>
                     
                        </Tabs>
                    </Box>
               
                        <TabPanel value={tabValue} index={0} >
                            <Box sx={{ px: 2, py: 2, backgroundColor: "#FFFFFF"}}>
                                {TabFilterOptions()}
                            </Box>
                            
                            <BookingFacilities/>
                        </TabPanel>
                    </Box>
                  
            </Grid>
    </Grid>
  )
}

const TabPanel = (props)=>{
    const {children,value,index} =props
    return(
        <>
        {
        value===index && 
        <>{children}</>
        }
        </>
    ) 
}