import { Box, Button, Checkbox, Grid, Chip, MenuItem, Select, TextField, Typography, TableHead, TableContainer, Table, TableRow, TableCell, TableBody } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as commonAction from "../../../redux/actions/commonAction";
import * as ServiceAction from "../../../redux/actions/service_request";
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { getTimeStamp, getYYYYMMDDFormat } from "../../../Utils/utils";
import ToastAlert from "../../common/Alert";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";
import { GSTPERCENTAGE, PRICEPERHOUR } from "../AirconUtils";
import moment from "moment";

const StyledTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '14px',
    marginBottom: '5px'
})

const StyledBillTypogarphy = styled(Typography)({
    color: '#6F80A9',
    fontSize: "14px"
})

const StyledTableCell = styled(TableCell)({
    color: "#0F2C6F",
    fontSize: "14px"
})
const PreviewAirCon = ({ setServiceRequestStateNull, tenantData, reviewData, hidePreview, generateBillValue, createAirConRequest,createAirConRequestAsDraft, addServiceReqStatus,getAirConCalc,airConCalc,setFromPreviewPage }) => {
    let { personInCharge, tenantCompany, office, mobile } = tenantData;
    let levelId = reviewData?.air_con_details[0]?.level_id;
    const navigate = useNavigate();
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [loaderStatus,setLoaderStatus] = useState(false)
    
    useEffect(()=>{
        let data = reviewData.durationList.map((value)=>{
            return{
                from_date:getYYYYMMDDFormat(value.start_date),
                to_date:getYYYYMMDDFormat(value.end_date),
                start_time:value.start_time,
                end_time:value.end_time,
                is_saturday_requested:value.requiredOnSaturdays,
                is_sunday_requested:value.requiredOnSundays,
                level_id:levelId
            }
        })
        getAirConCalc({aircondurationArrayList:JSON.stringify(data)})
    },[])
    
    useEffect(() => {
        setLoaderStatus(false)
        if (addServiceReqStatus === 'Success') {
            setSnackbarStatus({ message: 'Service Request Submitted', status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate(`/service_request_list/0`)
            }, 5000);
        } else if (addServiceReqStatus === 'Failed') {
            setSnackbarStatus({ message: 'Service Request Failed!', status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 5000);
        }
    }, [addServiceReqStatus])

    const removeSnackbar = () => {
        setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
    }

    const handleContinue = (submitAs) => {
        let data = [], toSubmit = {};
      
        for (var i = 0; i < reviewData.air_con_details.length; i++) {
            let dataobj = {};
            dataobj["from_date"] = reviewData.air_con_details[i].start_date;
            dataobj["start_time"] = reviewData.air_con_details[i].start_time;
            dataobj["to_date"] = reviewData.air_con_details[i].end_date;
            dataobj["end_time"] = reviewData.air_con_details[i].end_time;
            dataobj["total_duration"] = reviewData.air_con_details[i].requestedHours;
            dataobj['level_id'] = reviewData.air_con_details[i].level_id;
            dataobj["duration_type"] = "NA";
            dataobj["extra_charges"] = 0;
            dataobj["total_amount"] = reviewData.air_con_details[i].totalCost;
            dataobj["gst"] = GSTPERCENTAGE;
            dataobj["hourly_charges"] = PRICEPERHOUR;
            dataobj["total_days_count"] = reviewData.air_con_details[i].daysCount;
            dataobj["is_saturday"] = 'NA';
            dataobj["saturday_count"] = 0;
            dataobj["is_sunday"] = 'NA';
            dataobj["sunday_count"] = 0;
            dataobj["is_public_holiday"] = 'NA';
            dataobj["public_holiday_count"] = 0;
            data.push(dataobj)
        }
        toSubmit['aircondurationArrayList'] = JSON.stringify(airConCalc?.aircondurationArrayList);
        toSubmit["company_id"] = reviewData.company_id;
        toSubmit["service_request_types_id"] = reviewData.service_request_types_id;
        toSubmit["from_date"] = reviewData.air_con_details[0].start_date;
        toSubmit["from_time"] = reviewData.air_con_details[0].start_time;
        toSubmit["to_date"] = reviewData.air_con_details[0].end_date;
        toSubmit["to_time"] = reviewData.air_con_details[0].end_time;
        toSubmit["hourly_charge"] = 42;
        toSubmit["extra_charges"] = 0;
        toSubmit["duration"]= reviewData.air_con_details[0].duration
        toSubmit['total_charges'] = airConCalc?.grand_total_cost
    
        if(submitAs==="submit"){
            // console.log(toSubmit)
            createAirConRequest(toSubmit)
            setLoaderStatus(true)
        }else if(submitAs==="draft"){
            createAirConRequestAsDraft(toSubmit)
            setLoaderStatus(true)
        }

    }

    return (
        <>
        {loaderStatus && <Loader status={loaderStatus} />}
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />

            <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={0.5} />
                <Grid item xs={8}>
                    <Box sx={{ mt: 7, mb: 3 }}>
                        <ArrowIcon cursor='pointer' onClick={() =>{
                            hidePreview(false)
                            setFromPreviewPage({lift:false,security:false,airCon:true})
                            }} />
                        <Typography sx={{ fontSize: '26px', color: '#0F2C6F', mt: 1, mb: 1 }}>Service request- Use of Air-condition extension   </Typography>

                        {/* <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>Requested on {getTimeStamp(new Date())}</Typography> */}

                    </Box>
                </Grid>
                <Grid item sx={3.5} />
            </Grid>
            <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
                <Grid item xs={0.5} />
                <Grid item xs={8} >
                    <Grid container sx={{ pb: 3, backgroundColor: "#FFFFFF", p: 3 }} >
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                                <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>TenantDetails</Typography>
                                {/* <Button sx={{ color: '#0F2C6F', textTransform: 'capitalize', backgroundColor: '#CFDDFE', borderRadius: '6px', '&:hover': { backgroundColor: '#CFDDFE' } }} endIcon={<DownloadIcon />}>Download</Button> */}
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography sx={{ color: ' #0F2C6F', fontSize: '14px' }}>{personInCharge}</Typography>
                            <StyledTypography>{tenantCompany}</StyledTypography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StyledTypography>Office : {office}</StyledTypography>
                            <StyledTypography>Mobile : {mobile}</StyledTypography>
                        </Grid>
                        <Grid item xs={12} sm={4}>

                        </Grid>
                    </Grid>
                    <Box sx={{ backgroundColor: "#FFFFFF", p: 3 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>Request Details</Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Floor(Level)</StyledTableCell>
                                        <StyledTableCell>Start Date</StyledTableCell>
                                        <StyledTableCell>End Date</StyledTableCell>
                                        <StyledTableCell>Start Time</StyledTableCell>
                                        <StyledTableCell>End Time</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {reviewData?.air_con_details?.map((item,index) => (
                                        <TableRow key={index}>
                                            <StyledTableCell>{item.level_id}</StyledTableCell>
                                            <StyledTableCell>{moment(item.start_date).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell>{moment(item.end_date).format('DD-MM-YYYY')}</StyledTableCell>
                                            <StyledTableCell>{item.start_time}</StyledTableCell>
                                            <StyledTableCell >{item.end_time}</StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                    <Box sx={{ backgroundColor: "#FFFFFF", p: 3 }}>
                        <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>{reviewData.terms_condition[0].title}*</Typography>
                        <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{reviewData.terms_condition[0].description}</Typography>
                        {/* {reviewData.terms_condition[1].rules.map((value,index)=>(
                             <Typography  sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{`${index+1} . ${value[index+1]}`}</Typography>
                        ))} */}
                        <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>1.{reviewData?.terms_condition[1].rules[0]["ADVANCE NOTICE"]}</Typography>
                        <Typography sx={{ color: "#6F80A9", fontSize: '12px' }}>{reviewData?.terms_condition[1].rules[1]["Minimum Requirement"]}</Typography>
                        <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{reviewData?.terms_condition[1].rules[2]["URGENT/ ADHOC REQUEST"]}</Typography>
                        {/* <Box sx={{ display: "flex" }}>
                            <Checkbox checked={true} disabled />
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 2 }}>I have read and agreed to comply with the above terms and conditions for holding the event in the Building upon the Management’s approval.</Typography>
                        </Box> */}
                        <Box sx={{ display: "flex" }}>
                            <Checkbox checked={true} disabled />
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 2 }}>I have read and agreed to comply with the above terms and conditions for holding the event in the Building upon the Management’s approval.</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ py: 5 }}>

                    </Box>
                </Grid>
                <Grid item xs={3.5}>
                    <Box sx={{ backgroundColor: '#FFFFFF', mx: 3, p: 2 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>Bill Summary</Typography>
                    </Box>
                    <Box sx={{ borderTop: "1px solid #C0C7D7", borderBottom: "1px solid #F3F4F8", backgroundColor: '#FFFFFF', mx: 3, p: 2 }}>
                        {/* <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                            <StyledBillTypogarphy>Duration</StyledBillTypogarphy>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>{reviewData.air_con_details[0].duration}</Typography>
                        </Box> */}
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                            <StyledBillTypogarphy>Total Requested Hours</StyledBillTypogarphy>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>{airConCalc?.total_requested_hours} Hours</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                            <StyledBillTypogarphy>Total Cost</StyledBillTypogarphy>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>${airConCalc?.total_cost}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                            <StyledBillTypogarphy>GST Charges</StyledBillTypogarphy>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>${airConCalc?.total_gst}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
                            <StyledBillTypogarphy>Billing Method</StyledBillTypogarphy>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}> Company</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: '#FFFFFF', mx: 3, p: 2, display: "flex", justifyContent: "space-between" }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>Total Amount (SGD)</Typography>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>${airConCalc?.grand_total_cost} </Typography>
                    </Box>
                    <Box sx={{ mx: 3 }}>
                        <Button variant="outlined" fullWidth sx={{ mt: 2, textTransform: "capitalize", backgroundColor: "#FFFFFF", "&:hover": { backgroundColor: "#FFFFFF" } }} onClick={()=>handleContinue("draft")}>Save As Draft</Button>
                        <Button variant="contained" fullWidth sx={{ mt: 2, textTransform: 'capitalize', backgroundColor: "#123FA9", "&:hover": { backgroundColor: "#123FA9" } }} onClick={()=>handleContinue("submit")}>Continue</Button>
                    </Box>
                </Grid>
            </Grid>


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        levels: state.CommonReducer.tenantOccupiedLeves,
        addServiceReqStatus: state.Service_Req_Reducer.addServiceReqStatus,
        airConCalc : state.Service_Req_Reducer.airConCalc

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLevels: () => (dispatch(commonAction.getTenantAccessLevel())),
        generateBillValue: () => (dispatch(ServiceAction.generateBillValue())),
        createAirConRequest: (data) => (dispatch(ServiceAction.createAirConRequest(data))),
        createAirConRequestAsDraft: (data) => (dispatch(ServiceAction.createAirConRequestAsDraft(data))),
        setServiceRequestStateNull: () => (dispatch(ServiceAction.setServiceRequestStateNull())),
        getAirConCalc: (data) => (dispatch(ServiceAction.getAirConCalc(data))),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewAirCon)