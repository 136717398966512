import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Header from "../Header";
import ManageVisitor from '../Pages/Visitors/ManageVisitors.js';
import ReviewManageInvite from '../Pages/Visitors/UpcommingVisitors.js/ReviewManageInvite';
import RegisterVisitors from '../Pages/Visitors/RegisterVisitor';
import SingleVisitor from '../Pages/Visitors/RegisterVisitor/SingleVisitor';
import SpecialAccess from '../Pages/Visitors/RegisterVisitor/SpecialAccess';
import UpcommingVisitors from '../Pages/Visitors/UpcommingVisitors.js';
import ReviewRegistered from '../Pages/Visitors/ManageVisitors.js/ReviewRegistered';
import ReviewSpecialAccess from '../Pages/Visitors/ManageVisitors.js/ReviewSpecialAccess';
import HeplAndSupport from '../Header/HelpAndSupport';
import ErrorPage from '../components/LandingPage/ErrorPage';
import SendFeedback from '../Header/SendFeedback';
import ReportBugs from '../Header/ReportBugs';
import FaultReporting from '../Pages/FaultReporting/index.js';
import Faults from '../Pages/FaultReporting/Faults.js';
import FacilityBooking from '../Pages/FacilityBooking/index.js';
import ConfirmedBooking from '../Pages/FacilityBooking/ConfirmedBooking.jsx';
import BookingSelection from '../Pages/FacilityBooking/BookingSelection.jsx';
import SingleRoomFeature from '../Pages/FacilityBooking/SingleRoomFeature.jsx';
import PaymentConfirmation from './PaymentConfirmation.js';
export default function Receptionist() {
    return (
        <React.Fragment>
            <Header>
                <Routes>
                    <Route path="/dashboard" element={<RegisterVisitors />} />
                    <Route path="/register_visitor" element={<RegisterVisitors />} />
                    <Route path="/single_visitor" element={<SingleVisitor />} />
                    <Route path="/special_access" element={<SpecialAccess />} />
                    <Route path="/upcomming_visitors/:tabValue" element={<UpcommingVisitors />} />
                    <Route path="/manage_visitors/:tabValue" element={<ManageVisitor />} />
                    <Route path="/review_manage_invite/:tabValue/:invitation_id/:invitation_type" element={<ReviewManageInvite />} />
                    <Route path='/review_registered/:id/:type' element={<ReviewRegistered />} />
                    <Route path='/review_specialaccess/:id' element={<ReviewSpecialAccess/>} />
                    <Route path="/helpAndSupport" element={<HeplAndSupport/>} />
                    <Route path="/feedback" element={<SendFeedback/>} />
                    <Route path="/reportbug" element={<ReportBugs/>} />
                    <Route path="/my_faults/:tabvalue" element={<FaultReporting />} />
                    <Route path="/report_fault/:isDraft/:id" element={<Faults />} />
                    <Route path="/facility_booking" element={<FacilityBooking />} />
                    <Route path="/facility_booking/:facility_id" element={<ConfirmedBooking />} />
                    <Route path="/booking_selection" element={<BookingSelection />} />
                    <Route path='/single_room_feature/:feature_id' element={<SingleRoomFeature />} />
                    <Route path="/payment_response" element={<PaymentConfirmation />} />
                    <Route path="*" element={<ErrorPage />} />
                 </Routes>
            </Header>
        </React.Fragment>
    )
}
