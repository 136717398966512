import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import {ReactComponent as PlusIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import FacilityBookingList from './FacilityBookingList.jsx';
import { useNavigate, useLocation } from "react-router";
import { connect } from 'react-redux';
import { getAvaiiableRoomListFacilityBookingNullAction, reduxFacilityBookingRoomDataNullAction } from '../../redux/actions/facilityBookingAction/index.js';
import TecFacilityTransactionList from '../TecTeamFacilityBooking/TecFacilityTransactionList.js';
import TecFacilityBookingUsersList from '../TecTeamFacilityBooking/TecFacilityBookingUsersList.js';
import { ReactComponent as BackIcon } from '../../Assets/icons/back.svg';

export const checkTecTeam = () => {
    let role_id = JSON.parse(localStorage.getItem('userData'))?.role_id;
    return role_id === 16 ? true : false;
}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ paddingTop:'16px' }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }


  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const FaciliyBooking = ({reduxFacilityBookingRoomDataNullAction,getAvailableRoomListFacilityBookingNull}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tabValue, setTabValue] = useState(0);
    const [roleId, setRoleId] = useState(JSON.parse(localStorage.getItem('userData'))?.role_id)

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };    

    useEffect(() => {
      localStorage.removeItem('notification_clicked')
    },[])


  return (
    <div style={{display: "flex",width: "100%", justifyContent: "center", background: "#F9FAFA", height: "100%" }}>
    <div style={{  height: "100%", width: "95%"}}>
      <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", pt: "60px",}}>
    <Box>
    {roleId && (roleId == 2 || roleId == 14) && <BackIcon onClick={() => navigate('/manage_facility_booking')} style={{cursor:'pointer'}} />}
    <Typography sx={{color: "#002D74", fontSize: '26px',  fontWeight: 500}}>Amenity Bookings</Typography>
    <Typography sx={{color: "#002D74", fontSize: '14px', fontWeight: 500}}>Browse and book amenities within Singapore Land Tower.</Typography>
    </Box>
    {location.pathname.includes('/facility_booking')  &&
    <Button onClick={() =>{
      reduxFacilityBookingRoomDataNullAction();
      getAvailableRoomListFacilityBookingNull();
      localStorage.removeItem('facilityPaymentParams');
       navigate('/booking_selection')
       }} variant="contained" sx={{background: "#123FA9", display: "flex", alignItems: "center", gap: "8px", textTransform: "none", fontWeight: 600, py: '6px'}} > Make a Booking
    <PlusIcon />
    </Button>
    }
      </Box>

     <Box sx={{  background: "white", borderRadius: "10px", mt: "25px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{style:{backgroundColor: "#123FA9"}}} >
          <Tab label={checkTecTeam() ? "Bookings" : "Active"} {...a11yProps(0)} sx={{textTransform: "none", "&.Mui-selected": {
            color: "#123FA9",           
          },  
          }}   />
          <Tab label={checkTecTeam() ? "Transactions" : "Past"} {...a11yProps(1)} sx={{textTransform: "none", "&.Mui-selected": {
            color: "#123FA9"
          }}} />  
          {checkTecTeam() &&    <Tab label={'Users'} {...a11yProps(2)} sx={{textTransform: "none", "&.Mui-selected": {
            color: "#123FA9",           
          },  
          }}   />}        
        </Tabs>
      </Box>
      <CustomTabPanel value={tabValue} index={0}>        
        <FacilityBookingList tab={tabValue} />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        {checkTecTeam() ? <TecFacilityTransactionList tab={tabValue} /> : <FacilityBookingList tab={tabValue} /> }
      </CustomTabPanel>    
      {checkTecTeam() && 
      <CustomTabPanel value={tabValue} index={2}>
         <TecFacilityBookingUsersList />
      </CustomTabPanel>    
      }  
    </Box>
     </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    reduxFacilityBookingRoomDataNullAction:() => dispatch(reduxFacilityBookingRoomDataNullAction()),
    getAvailableRoomListFacilityBookingNull: () => {
      dispatch(getAvaiiableRoomListFacilityBookingNullAction())
    },
  }
}

const mapStateToProps = state => {
  return {
     
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FaciliyBooking)

