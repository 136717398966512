import { Tabs, Tab, Box, Grid, IconButton, ListItem, ListItemText, Typography, Select, MenuItem, Button, Menu, Stepper, Step, StepLabel, OutlinedInput } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as SearchIcon } from '../../../Assets/icons/SearchIcon.svg'
import VisitorsList from './VisitorsList';
import { Manage_Visitor_All_Tab, Manage_Visitor_Invited_Tab } from '../../../components/Data/Data';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import {useParams} from 'react-router-dom';
import moment from 'moment';
import { DateRangePicker, combine } from 'rsuite';
import { styled } from '@mui/styles';
import "rsuite/dist/rsuite.css"

const StyledTab = styled(Tab)({
    color:"#6F80A9",
    textTransform:"capitalize",
    fontSize:'14px'
})

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const ManageVisitorsTabs = ({ getVisitorsList, visitors, recordCount,receptionStateToInitial }) => {
    let { tabValue } = useParams();
    const formatdate = moment().format('YYYY-MM-DD');
    const [tabProps, setTabProps] = useState({ currentTab: parseInt(tabValue), getType: 'All', limit: 10, from: formatdate, to: formatdate, search: '',  offset:0  });
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedDay, setSelectedDay] = useState(0);
    const [tableProps, setTableProps] = useState({ columns: [], datas: [], totalDatas: 0 });
    const [allDatasSelected, setAllDatasSelected] = useState(false)
    const filterOpen = Boolean(menuAnchorEl);
    const twentyNineDaysAgo = new Date();
    twentyNineDaysAgo.setDate(twentyNineDaysAgo.getDate() - 29);
    const [selectedDates, setSelectedDates] = useState({
      startDate: null,
      endDate: null,
    });
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const { afterToday, combine } = DateRangePicker;

    function handleDate(e) {
        console.log('e ', e);
        setSelectedDates(e)
        if (e != null) {
          setFromDate(
            moment.utc(e[0], 'DD-MM-YYYY HH:mm:ss').local().format('YYYY-MM-DD')
          );
          setToDate(
            moment.utc(e[1], 'DD-MM-YYYY HH:mm:ss').local().format('YYYY-MM-DD')
          );
          setTabProps(prev => ({ ...prev,
             ['from']: moment.utc(e[0], 'DD-MM-YYYY HH:mm:ss').local().format('YYYY-MM-DD'),
             ['to']: moment.utc(e[1], 'DD-MM-YYYY HH:mm:ss').local().format('YYYY-MM-DD')
            }));
        } else {
          setFromDate('');
          setToDate('');
          setTabProps(prev => ({ ...prev,
            ['from']: moment().format('YYYY-MM-DD'),
            ['to']: moment().format('YYYY-MM-DD')
           }));
        }
    }

    useEffect(() => {
        getVisitorsList(tabProps)
        getTableColumnNames(tabProps.currentTab)
        return()=>{
            receptionStateToInitial()
        }
    }, [tabProps])

    useEffect(() => {
        if (recordCount) {
            let newDatas = tableProps;
            newDatas['datas'] = visitors;
            newDatas['totalDatas'] = recordCount;
            setTableProps({ ...newDatas })
            getTableColumnNames(tabProps.currentTab)
        }
        
    }, [visitors, recordCount])

    const handleClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setMenuAnchorEl(null);
    };
    const handleChange = (event, newValue) => {
        setTableProps({ columns: [], datas: [], totalDatas: 0 })
        setTabProps({ ...tabProps, from:formatdate, to:formatdate, ['getType']: event.target.name, ['currentTab']: newValue,offset:0 })
        setSelectedDates({startDate: null, endDate: null,})
    };
    const handlePagignation = (event, value) => {
        setTabProps({ ...tabProps, ['offset']: value - 1 });
    };
    const getTableColumnNames = (selectedTab) => {
        let newColumns;
        if (parseInt(selectedTab) === 0 || parseInt(selectedTab) === 2) {
            newColumns = Manage_Visitor_All_Tab
        } else {
            newColumns = Manage_Visitor_Invited_Tab;
        }
        setTableProps({ ...tableProps, columns: newColumns })

    }
    const handleFilterData = (value, name) => {
        setTabProps({ ...tabProps, [name]: value });
    }
    const handleAllSelected = () => {
        setAllDatasSelected(!allDatasSelected);
    }
    const handleSelected = () => {
        // console.log(!allDatasSelected);
    }
    const filterProps = () => {
        console.log('selecteddate ',selectedDates)
        return (
            <Grid container sx={{p:1.5  }}>
                <Grid itex xs={10}>
                <DateRangePicker
                 placeholder="dd/mm/yyyy"
                    showOneCalendar
                    format='dd/MM/yyyy'
                    value={selectedDates}
                    onChange={handleDate}
                    shouldDisableDate={combine((current) => {
                    return current < twentyNineDaysAgo;
                    }, afterToday())}
                    ranges={[
                    {
                        label: '',
                    },
                    ]}
                
                />
                </Grid>
                <Grid itex xs={2}>
                    <OutlinedInput placeholder="Search..."
                        name='search'
                        onChange={(e) => handleFilterData(e.target.value, e.target.name)}
                        endAdornment={<SearchIcon />} size="small" />

                </Grid>
            </Grid>
        )

    }
    
    return (
        <Box sx={{ width: '100%', background: '#FFFFFF', borderRadius: '8px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Tabs value={tabProps.currentTab} onChange={handleChange} aria-label="basic tabs example">
                    <StyledTab label="All" name='All' {...a11yProps(0)} />
                    <StyledTab label="Invited" name='Invited' {...a11yProps(1)} />
                    <StyledTab label="Registered" name='Registered' {...a11yProps(2)} />
                    <StyledTab label="Special Access" name='Special_access' {...a11yProps(3)} />
                </Tabs>
                {/* <Button variant='contained' sx={{ alignSelf: 'center', mr: 2,textTransform:'capitalize' }}>Download</Button> */}
            </Box>
            <TabPanel value={tabProps.currentTab} index={0}>
                {filterProps()}
                <VisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
            <TabPanel value={tabProps.currentTab} index={1} >
                {filterProps()}
                <VisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
            <TabPanel value={tabProps.currentTab} index={2}>
                {filterProps()}
                <VisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
            <TabPanel value={tabProps.currentTab} index={3}>
                {filterProps()}
                <VisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        visitors: state.ReceptionReducer.visitorsDataBms,
        recordCount: state.ReceptionReducer.recordCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getVisitorsList: (data) => { dispatch(receptionActions.getVisitorsBmsList(data)) },
        receptionStateToInitial: () => {dispatch(receptionActions.receptionStateToInitial())},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageVisitorsTabs)