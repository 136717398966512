import React from "react";
import { Route, Routes } from "react-router-dom";
import AuditLog from "../Pages/Reports/auditLog.js";
import SendEmail from "../Pages/Reports/sendEmail.js";
import Header from "../Header";
import HeplAndSupport from "../Header/HelpAndSupport";
import SendFeedback from "../Header/SendFeedback";
import ReportBugs from "../Header/ReportBugs";
import ErrorPage from "../components/LandingPage/ErrorPage";

const AuditTeam = () => {
  return (
    <React.Fragment>
      <Header>
        <Routes>
          {/* <Route path="/dashboard" element={<BmsManagerDashboard />} /> */}
          

          <Route path="/auditreport" element={<AuditLog />} />
          <Route path="/testEmail" element={<SendEmail />} />
          <Route path="/helpAndSupport" element={<HeplAndSupport/>} />
          <Route path="/feedback" element={<SendFeedback/>} />
          <Route path="/reportbug" element={<ReportBugs/>} />
          <Route path="*" element={<ErrorPage />} />
         </Routes>
      </Header>
    </React.Fragment>
  );
};

export default AuditTeam;
