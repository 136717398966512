import axios from "axios";
import { createAction } from "redux-actions";
export const sessionExpired = createAction("SESSION_EXPIRED");
export const globalSearchInitiate = createAction("GLOBAL_SEARCH_INITIATE");
export const globalSearchSuccess = createAction("GLOBAL_SEARCH_SUCCESS");
export const globalSearchFailure = createAction("GLOBAL_SEARCH_FAILURE");
export const globalSearchStateNull = createAction("GLOBAL_SEARCH_STATE_NULL");

export const globalSearchService = (requestData) => {
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  return (dispatch) => {
    dispatch(globalSearchInitiate());
    axios.post(`${process.env.REACT_APP_API_URL}/globalSearch`,requestData,config)
      .then((response) => {
        if (response.data?.response_json?.token) {
          dispatch(globalSearchSuccess(response.data));
        } else {
          dispatch(globalSearchFailure(response.data?.error_json?.error_message));
        }
      })
      .catch((error) => {
        if(error.response.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
            dispatch(globalSearchFailure(error.response.data?.error_json?.error_message));
          }
        
      });
  };
};