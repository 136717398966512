import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { getLoggedUserData } from "../../Utils/utils";
import AccessHistory from "./accessHistory";
import ReportsAircon from "./aircon";
import ReportsEmployees from "./employees";
import ReportsFault from "./faultreport";
import ReportsLightBulb from "./lightbulb";
import ReportsServicerequest from "./servicerequest";
import TurnStileAccessHistory from "./turnStileAccessHistory";
import VisitorManagement from "./visitorManagement";
import CompanyReport from "./company";
import { ReactComponent as FaultReportIcon } from "../../Assets/icons/FaultReport.svg";
import { ReactComponent as ServiceRequestIcon } from "../../Assets/icons/ServiceReport.svg";
import { ReactComponent as EmployeeIcon } from "../../Assets/icons/Staff.svg";
import { ReactComponent as AccessIcon } from "../../Assets/icons/Info.svg";
import { ReactComponent as CompanyIcon } from "../../Assets/icons/CompanyReport.svg";
import { ReactComponent as TurnstileAccess } from "../../Assets/icons/TurnstileAccess.svg";
import { useParams } from "react-router-dom";

const StyledButton = styled(Button)({
  backgroundColor: "#123FA9",
  borderRadius: "6px",
  textTransform: "capitalize",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#123FA9",
  },
  width: 70,
  marginTop: "35px",
});

const Reports = () => {
  let { isAccess } = useParams();
  let BmsDatas = [
    {
      id: 1,
      name: "Fault Report",
      buttonName: "Manage",
      argument: "fault",
      description:
        "Access fault report history and statistics, including submitted, ongoing and resolved faults. ",
    },
    {
      id: 2,
      name: "Service Request",
      buttonName: "Manage",
      argument: "service",
      description:
        "Access past service request data and statistics, including submitted, approved and resolved requests. ",
    },
    // {
    //     id:3,
    //     name:'Light Bulb Replacement',
    //     buttonName:'Manage',
    //     argument:'lightBulb'
    // },
    // {
    //     id:4,
    //     name:'Air Con Extension',
    //     buttonName:'Manage',
    //     argument:'airCon'
    // },
    {
      id: 5,
      name: "Access History",
      buttonName: "Manage",
      argument: "accessHistory",
      description:
        "View and download Singapore Land Tower access history and statistics.",
    },
    {
      id: 6,
      name: "Staff Employees",
      buttonName: "Manage",
      argument: "employees",
      description:
        "Access all employee report and data, including onboarding, active, offboarding and inactive employees.",
    },
    {
      id: 7,
      name: "Company Overview",
      buttonName: "Manage",
      argument: "company",
      description:
        "Overview of tenant companies residing within Singapore Land Tower.",
    },
    {
      id: 8,
      name: "View Turnstile Access History",
      buttonName: "View",
      argument: "turnstileAccessHistory",
      description: "Overview of tenant access history at Singapore Land Tower.",
    },
  ];

  let TenantDatas = [
    {
      id: 1,
      name: "Employees",
      buttonName: "Manage",
      argument: "employees",
      description:
        "Access all employee report and data, including onboarding, active, offboarding and inactive employees.",
    },
    {
      id: 1,
      name: "Access History",
      buttonName: "Manage",
      argument: "accessHistory",
      description:
        "View and download Singapore Land Tower access history and statistics.",
    },
    {
      id: 1,
      name: "Visitor History",
      buttonName: "Manage",
      argument: "visitorHistory",
      description: "View Singapore Land Tower access history and statistics.",
    },
  ];
  let { role_id } = getLoggedUserData();
  const [displayType, setDisplayType] = useState({
    visitorHistory:false,
    fault: false,
    service: false,
    airCon: false,
    lightBulb: false,
    accessHistory: false,
    employees: isAccess == "true" ? true : false,
    company: false,
    turnstileAccessHistory: false,
  });

  const renderScreen = () => {
    if (displayType.fault) {
      return <ReportsFault handleBackButton={handleBackButton} />;
    } else if (displayType.service) {
      return <ReportsServicerequest handleBackButton={handleBackButton} />;
    } else if (displayType.visitorHistory) {
      return <VisitorManagement handleBackButton={handleBackButton} />;
    }else if (displayType.airCon) {
      return <ReportsAircon handleBackButton={handleBackButton} />;
    } else if (displayType.lightBulb) {
      return <ReportsLightBulb handleBackButton={handleBackButton} />;
    } else if (displayType.accessHistory) {
      return <AccessHistory handleBackButton={handleBackButton} />;
    } else if (displayType.employees) {
      return <ReportsEmployees handleBackButton={handleBackButton} />;
    } else if (displayType.company) {
      return <CompanyReport handleBackButton={handleBackButton} />;
    } else if (displayType.turnstileAccessHistory) {
      return <TurnStileAccessHistory handleBackButton={handleBackButton} />;
    } else {
      return (
        <>
          <Typography
            sx={{ color: "#002D74", fontSize: "24px", fontWeight: "500" }}
          >
            Reports
          </Typography>
          <Typography
            sx={{ color: "#002D74", fontSize: "14px", fontWeight: "500" }}
          >
            Tenant Managers are able to manage and download employee access
            history and details on the SingLand Mobile app.
            {/* View and download reports on singland features and management */}
          </Typography>
          <Grid container sx={{ mt: 2 }}>
            {(role_id == 4 ? TenantDatas : BmsDatas).map((value) => (
              <Grid
                sx={{
                  backgroundColor: "#FFFFFF",
                  mr: 2,
                  my: 2,
                  borderRadius: "6px",
                  p: 4,
                }}
                item
                xs={role_id == 4 ? 5.7 : 3.7}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ minHeight: 40, maxHeight: 40 }}>
                    {value.name == "Fault Report" ? (
                      <FaultReportIcon />
                    ) : value.name == "Service Request" ? (
                      <ServiceRequestIcon />
                    ) : value.name == "Staff Employees" || value.name == "Visitor History" ? (
                      <EmployeeIcon />
                    ) : value.name == "Access History" ? (
                      <AccessIcon />
                    ) : value.name == "View Turnstile Access History" ? (
                      <TurnstileAccess />
                    ) : (
                      <CompanyIcon />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      color: "#0F2C6F",
                      fontSize: "18px",
                      my: 3,
                      fontWeight: 500,
                    }}
                  >
                    {value.name}
                  </Typography>
                  <Box sx={{ minHeight: 70 }}>
                    <Typography
                      sx={{
                        color: "#0F2C6F",
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      {value.description}{" "}
                    </Typography>
                  </Box>
                  <StyledButton
                    variant="contained"
                    onClick={() => handleViewButton(value.argument)}
                  >
                    {value.buttonName}
                  </StyledButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      );
    }
  };

  const handleViewButton = (datafrom) => {
    setDisplayType({
      fault: false,
      service: false,
      airCon: false,
      lightBulb: false,
      accessHistory: false,
      employees: false,
      turnstileAccessHistory: false,
      visitorHistory:false,
      [datafrom]: true,
    });
  };
  console.log("displaytype ", displayType);

  const handleBackButton = () => {
    setDisplayType({
      fault: false,
      service: false,
      airCon: false,
      lightBulb: false,
      accessHistory: false,
      employees: false,
      company: false,
      turnstileAccessHistory: false,
    });
  };
  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: "rgba(143, 146, 161,0.05)", minHeight: "100%" }}
      >
        <Grid item xs={0.5} />
        <Grid item sx={{ mt: 7 }} xs={11}>
          {renderScreen()}
        </Grid>
        <Grid item xs={0.5} />
      </Grid>
    </>
  );
};

export default Reports;
