import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { Box, Button, Grid, OutlinedInput, Typography, styled, InputAdornment,Dialog,DialogTitle, DialogContent, DialogActions, IconButton, TextField } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import * as ServiceAction from "../../redux/actions/service_request/index"
import { getConfigById, getConfigByIdNullAction,updateConfig,updateConfigNullAction } from "../../redux/actions/service_request/index";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { makeStyles } from '@mui/styles';

const StyledTypography = styled(Typography)({
    color: '#0F2C6F', 
    fontSize: '16px',
    marginBottom:'8px'
})

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const Style = makeStyles({
    input:{
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
})

const AddService = ({getConfigByIdAction,configData,getConfigByIdNull, updateConfigAction, updateConfigStatusData, updateConfigNull })=>{
    const navigate = useNavigate()
    const {screen} = useParams()
    const [data,setData] = useState(screen == 'aircon_charges' ? {aircon_cost_per_hour:''} : screen == 'light_bulb_charge' ? {light_bulb_cost_per_hour:'', light_bulb_cost_subsequent_hour:''} : {gst:''})
    const [error,setError] = useState(screen == 'aircon_charges' ? {aircon_cost_per_hour:''} : screen == 'light_bulb_charge' ?  {light_bulb_cost_per_hour:'', light_bulb_cost_subsequent_hour:''} : {gst:''})
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const [loaderStatus, setLoaderStatus] = useState(false)
    const customStyle = Style()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    console.log('data ',data)
    console.log('error ',error)
    console.log('screen ',screen)
    useEffect(()=>{
        if(screen!='null'){
            getConfigByIdAction({module_name:screen})
        }
    },[])   

    useEffect(()=>{
        if(configData?.response?.response_status == 1){
            if(screen=='aircon_charges') {  
                setData({
                    aircon_cost_per_hour: configData?.response?.dataArray?.aircon_cost_per_hour,
                })    

             } 

              else if(screen=='light_bulb_charge') {
                setData({light_bulb_cost_per_hour: configData?.response?.dataArray?.light_bulb_cost_per_hour,
                         light_bulb_cost_subsequent_hour: configData?.response?.dataArray?.light_bulb_cost_subsequent_hour,
                })    
             }  else if(screen=='gst') {
                setData({gst: configData?.response?.dataArray?.gst,
                })    
             }
             getConfigByIdNull()
        }
    },[configData])

    useEffect(()=>{
        if(updateConfigStatusData?.response?.response_status == 1){
            setLoaderStatus(false)
            setToastStatus({color:'green',status:true,message:updateConfigStatusData?.response?.message,icon:'tick'})
            setData({})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                updateConfigNull()
                navigate(-1)
            },3000)
        }else if(updateConfigStatusData?.response?.response_status ==0){
            setLoaderStatus(false)
            setToastStatus({color:'red',status:true,message:updateConfigStatusData?.response?.message,icon:'close'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                updateConfigNull()
            },3000)
        }
    },[updateConfigStatusData])

    const handleData = (e, dataFrom)=>{
        let {value} = e.target
        if(value.length > 10) return
        setData({
            ...data,
            [dataFrom]:value
        })
        setError({
            ...error,
            [dataFrom]:''
        })
    }

    const validate = ()=>{
        let err = false
        if(screen == 'aircon_charges') {
            if(data.aircon_cost_per_hour ==''){
                setError((prev)=>({
                    ...prev,
                    aircon_cost_per_hour:'Enter Hourly Rate'
                }))
                err=true
            } else if(data.aircon_cost_per_hour <=0) {
                setError((prev)=>({
                    ...prev,
                    aircon_cost_per_hour:'Price must be greater than 0'
                }))
                err=true
            }
        } else if(screen == 'light_bulb_charge') {
            console.log('within screen ',screen)
            if(data.light_bulb_cost_per_hour ==''){
                setError((prev)=>({
                    ...prev,
                    light_bulb_cost_per_hour:'Enter First Half Hour'
                }))
                err=true
            } else if(data.light_bulb_cost_per_hour <=0) {
                setError((prev)=>({
                    ...prev,
                    light_bulb_cost_per_hour:'Price must be greater than 0'
                }))
                err=true
            }
            if(data.light_bulb_cost_subsequent_hour==''){
                setError((prev)=>({
                    ...prev,
                    light_bulb_cost_subsequent_hour:'Enter Subsequent Half Hour'
                }))
                err=true
            } else if(data.light_bulb_cost_subsequent_hour <=0) {
                setError((prev)=>({
                    ...prev,
                    light_bulb_cost_subsequent_hour:'Price must be greater than 0'
                }))
                err=true
            }
        } else if(screen == 'gst') {
            if(data.gst ==''){
                setError((prev)=>({
                    ...prev,
                    gst:'Enter gst'
                }))
                err=true
            } else if(data.gst <=0) {
                setError((prev)=>({
                    ...prev,
                    gst:'Gst must be greater than 0'
                }))
                err=true
            }
        } 
    
        return err
    }   

    const handleSubmit = ()=>{
        let isError = validate()
        if(!isError){
            handleClickOpen()
        }
    }

    const updateValue = () => {
        setOpen(false)
            if(screen== 'aircon_charges'){
                
                updateConfigAction({
                    module_name:'aircon_charges',
                    aircon_hourly_charge:parseFloat(data.aircon_cost_per_hour),
                   
                }) 
            }else if(screen == 'light_bulb_charge'){
                updateConfigAction({
                    module_name:'light_bulb_charge',
                    first_half_hour_charge:parseFloat(data.light_bulb_cost_per_hour),
                    subsequent_half_hour_charge:parseFloat(data.light_bulb_cost_subsequent_hour),
                })
            } else if(screen == 'gst'){
                updateConfigAction({
                    module_name:'gst',
                    gst:parseFloat(data.gst)
                })
            }
            setLoaderStatus(true)
            setTimeout(() => {
                setLoaderStatus(false)
            },25000)
    }

    const ModalAlert = ()=> {
        return(
            <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2, fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#0F2C6F' }} id="customized-dialog-title">
              Confirm Update of Air-Con Extension Service Rates
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <DialogContent dividers>
                <Typography gutterBottom>
                    Please ensure the rate displayed is correct.
                </Typography>

                <Typography gutterBottom sx={{mb:2}}>
                    Once updated, the new rates will be updated for newly submitted reqeusts.
                </Typography>

                <Typography gutterBottom>
                    For existing/ ongoing requests, BMS Coordinators will have to inform the users of the
                </Typography>

                <Typography gutterBottom>
                    amended rates and ensure the rates are correct upon service completion.
                </Typography>
              </DialogContent>
              <DialogActions sx={{display:'flex', justifyContent:'space-between'}}>
              <Button variant="outlined" onClick={handleClose} sx={{borderRadius:'6px', p:'8px 10px',}}>
                  <Typography sx={{color:'#0F2C6F', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter'}}>Cancel</Typography>
                </Button>
                <Button variant="contained" onClick={updateValue} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}}>
                <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Update Now</Typography>
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
        )
    }

    return(
        <>
        {loaderStatus && <Loader status={loaderStatus} />} 
        <ModalAlert />
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:'100% '}}>            
            <Grid item xs={2} />
            <Grid item xs={8} sx={{mt:7}}>
             <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon}  />  
                <BackButton style={{cursor:'pointer'}} onClick={()=>navigate(-1)} />
                {screen == 'aircon_charges'?(<>
                <Box sx={{my:2}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:"20px"}}>Air Con Extension Rates and Charges</Typography>
                    <Typography sx={{color:'#6F80A9', fontFamily:'Inter', fontWeight:'500', fontSize:'14px', lineHeight:'20px', letterSpacing:'-0.25px'}}>Once product rates are updated, it will be reflected in all applicable new service requests. </Typography>
                </Box>
            
                <Grid container  sx={{backgroundColor:"#FFFFFF",p:2}}>
                    <Grid item xs={5.5}>
                        <Box sx={{my:3}}>
                            <StyledTypography>Air Con Hourly Rate</StyledTypography>
                            <OutlinedInput
                            className={customStyle.input} type="number" fullWidth name="aircon_cost_per_hour" value={data?.aircon_cost_per_hour} onChange={(e)=>handleData(e, 'aircon_cost_per_hour')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                            <Typography sx={{color:'red'}}>{error?.aircon_cost_per_hour}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
               
                <Box sx={{display:'flex',justifyContent:"flex-end",mt:5}}>
                    <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}} variant="contained" onClick={handleSubmit}>Save</Button>
                </Box></>):(
                    screen == 'light_bulb_charge' ? (<>
                <Box sx={{my:2}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:"20px"}}>Manpower Charges</Typography>
                    <Typography sx={{color:'#6F80A9', fontFamily:'Inter', fontWeight:'500', fontSize:'14px', lineHeight:'20px', letterSpacing:'-0.25px'}}>Once product rates are updated, it will be reflected in all applicable new service requests. </Typography>
                </Box>
                <Grid container  sx={{backgroundColor:"#FFFFFF",p:2}}>
                    <Grid item xs={5.5}>
                        <Box sx={{my:3}}>
                            <StyledTypography>First Half Hr</StyledTypography>
                            <OutlinedInput className={customStyle.input} type="number"  name="light_bulb_cost_per_hour" value={data?.light_bulb_cost_per_hour} onChange={(e)=>handleData(e, 'light_bulb_cost_per_hour')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                            <Typography sx={{color:'red'}}>{error?.light_bulb_cost_per_hour}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={5.5}>
                        <Box sx={{my:3}}>
                            <StyledTypography>Subsequent Half Hr</StyledTypography>
                            <OutlinedInput className={customStyle.input} type="number"  name="light_bulb_cost_subsequent_hour" value={data?.light_bulb_cost_subsequent_hour} onChange={(e)=>handleData(e,'light_bulb_cost_subsequent_hour')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                            <Typography sx={{color:'red'}}>{error?.light_bulb_cost_subsequent_hour}</Typography>
                        </Box>
                    </Grid>
                </Grid>
               
                <Box sx={{display:'flex',justifyContent:"flex-end",mt:5}}>
                    <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}} variant="contained" onClick={handleSubmit}>Save</Button>
                </Box></>
                ):(
                    <>
                <Box sx={{my:2}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:"20px"}}>Gst</Typography>
                    {/* <Typography sx={{color:'#6F80A9', fontFamily:'Inter', fontWeight:'500', fontSize:'14px', lineHeight:'20px', letterSpacing:'-0.25px'}}>Once product rates are updated, it will be reflected in all applicable new service requests. </Typography> */}
                </Box>
            
                <Grid container  sx={{backgroundColor:"#FFFFFF",p:2}}>
                    <Grid item xs={5.5}>
                        <Box sx={{my:3}}>
                            <StyledTypography>Gst</StyledTypography>
                            <OutlinedInput
                            className={customStyle.input} type="number" fullWidth name="gst" value={data?.gst} onChange={(e)=>handleData(e, 'gst')}
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                            <Typography sx={{color:'red'}}>{error?.gst}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
               
                <Box sx={{display:'flex',justifyContent:"flex-end",mt:5}}>
                    <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}} variant="contained" onClick={handleSubmit}>Save</Button>
                </Box></>
                ))}
            </Grid>
            <Grid item xs={2} />
        </Grid>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        configData: state.Service_Req_Reducer.getConfigByIdData,
        updateConfigStatusData: state.Service_Req_Reducer.updateConfigStatusData
    }

}

const mapDispacthToProps = (dispatch)=>{
    return{
        getConfigByIdAction: (data) => {dispatch(getConfigById(data))},
        getConfigByIdNull: () => {dispatch(getConfigByIdNullAction())},
        updateConfigAction: (data) => {dispatch(updateConfig(data))},
        updateConfigNull: () => {dispatch(updateConfigNullAction())},
    }
}
export default connect(mapStateToProps,mapDispacthToProps)(AddService)