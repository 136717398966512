import React, {useState, useEffect} from 'react'
import { Box, FormControl,Button, Grid, MenuItem , Avatar, Divider , IconButton, OutlinedInput,Select, FormControlLabel, Tab, Tabs, Typography, Checkbox, TextField } from "@mui/material";
import Phone  from "../../Assets/icons/phone.png";
import TV  from "../../Assets/icons/TV.png";
import Wifi  from "../../Assets/icons/wifi.png";
import WritingWall  from "../../Assets/icons/writingWall.png";
import Webcam  from "../../Assets/icons/webcam.png";
import styled from '@emotion/styled';
import WifiIcon from '@mui/icons-material/Wifi';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';

const options = [
    { value: 1, label: 'phone', src: Phone },
    { value: 2, label: 'tv', src: TV },
    { value: 3, label: 'wifi', src: Wifi },
    { value: 4, label: 'writingWall', src: WritingWall },
    { value: 5, label: 'webcam', src: Webcam },
  ];

const PrimaryTypography = styled(Typography)({
    color:"#0F2C6F",
    fontWeight:550,
    fontSize:'16px'
})

const SecondaryTypography = styled(Typography)({
    color:"#0F2C6F",
    fontWeight:500,
    fontSize:'14px'
})

export default function ReviewInformation({innerTabValue, facilityDetails, setInnerTabValue, uniqDetails, featuredImages, termsCondition}) {

    const FacilityDetail = () => {
        return(
            <Grid container sx={{ mt:3, mb:3}} rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "18px", color: '#0F2C6F' }}>Facility Details</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                        <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Title</Typography>
                        <Typography sx={{ fontSize: "14px", color: '#0F2C6F' }}>{facilityDetails?.title}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                        <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Description</Typography>
                        <Typography sx={{ fontSize: "14px", color: '#0F2C6F' }}>{facilityDetails?.description}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                        <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Level</Typography>
                        <Typography sx={{ fontSize: "14px", color: '#0F2C6F' }}>{facilityDetails?.officeLevel}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{display:'flex', flexDirection:'column', gap:'10px'}}>
                        <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Maximum People Allowed</Typography>
                        <Typography sx={{ fontSize: "14px", color: '#0F2C6F' }}>{facilityDetails?.people_allowed}</Typography>
                    </Box>
                </Grid>
            </Grid>
        )
        
    }

    const OpenHoursReviewItem = () => {
        return (
            <Grid container sx={{ mt:3, mb:3}} rowSpacing={3}>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "18px", color: '#0F2C6F' }}>Opening Hours</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box sx={{display:'flex'}}>
                        <Typography sx={{flex:1, fontSize: "14px", color: '#6F80A9' }}>Day</Typography>
                        <Typography sx={{flex:1, fontSize: "14px", color: '#6F80A9' }}>Opening Hours</Typography>
                    </Box>
                </Grid>
                {['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'].map((item, index) => (
                    <Grid item key={index} xs={12} sm={12}>
                        <Box sx={{display:'flex'}}>
                            <Typography sx={{flex:1, fontSize: "14px", color:'#0F2C6F' }}>{item}</Typography>
                            <Typography sx={{flex:1, fontSize: "14px", color:'#0F2C6F' }}>{
                                facilityDetails?.openHours[index]?.closed == 'true' ? 'closed' : facilityDetails?.openHours[index]?.from+' - '+facilityDetails?.openHours[index]?.to
                            }</Typography>
                        </Box>
                </Grid>
                ))}
            
            </Grid>
        )
    }

    const getIcon = (imageId) => {
        const reqObject = options.find(obj => obj.value == imageId)
        return reqObject.src
    }

    const UniqFeatureDetail = () => {
        return (
            <Grid container sx={{ mt:3, mb:3}} rowSpacing={3}>
                <Grid item xs={12} sx={{mb:3}}>
                    <Typography sx={{ fontSize: "18px", color: '#0F2C6F' }}>Unique Features</Typography>
                </Grid>
                {uniqDetails?.map((value, index) => (
                        <Box key={index} sx={{display:'flex', width:'100%', gap:'15px', mb:3 }}>
                            <Box sx={{display:'flex', flexDirection:'column', gap:'10px', width:'50%'}}>
                                <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Icon</Typography>
                                <Box>
                                    <img size = 'sm' src = {getIcon(value.uniq_icon)} />
                                </Box>
                            </Box>
                            <Box sx={{display:'flex', flexDirection:'column', gap:'10px', width:'50%'}}>
                                <Box sx={{display:'flex', flexDirection:'column'}}>
                                    <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Title</Typography>
                                    <Typography sx={{ fontSize: "14px", color: '#0F2C6F' }}>{value?.uniq_title}</Typography>
                                </Box>
                                <Box sx={{display:'flex', flexDirection:'column'}}>
                                    <Typography sx={{ fontSize: "14px", color: '#6F80A9' }}>Description</Typography>
                                    <Typography sx={{ fontSize: "14px", color: '#0F2C6F' }}>{value?.short_description}</Typography>
                                </Box>
                                
                            </Box>
                        </Box>
                      
                ))}  
            </Grid>
        )
    }

    const FacilityImages = () => {
        return(
            <Grid container sx={{ mt:3, mb:3}} rowSpacing={3}>
                <Grid item xs={12} sx={{mb:3}}>
                    <Typography sx={{ fontSize: "18px", color: '#0F2C6F' }}>Facility Images</Typography>
                </Grid>
                <Box  sx={{display:'flex', gap:'15px', flexWrap:'wrap'}}>
                {featuredImages?.map((item,index) => (
                        <Box key={index}>
                            <img style={{maxWidth:'200px', maxHeight:'200px'}} src={item.src} />
                        </Box>
                ))}
                    </Box>
            </Grid>
        )
    }

    const TermsandConditions = () => {
        return(
            <Grid container sx={{ mt:3, mb:3}} rowSpacing={3}>
                <Grid item xs={12} sx={{mb:1}}>
                    <Typography sx={{ fontSize: "18px", color: '#0F2C6F' }}>Terms & Conditions</Typography>
                </Grid>
                <p dangerouslySetInnerHTML={{ __html: termsCondition }} />
            </Grid>
        )
    }

    const MobilePreview = () => {
        return(
            <Grid container sx={{ mt:3, mb:3}} rowSpacing={3}>
            <Grid item xs={12}>
                <Typography sx={{ color:"#0F2C6F",fontWeight:550,fontSize:'18px'}}>Application Preview</Typography>
            </Grid>
            <Grid container item xs={12} justifyContent="center">
                <Grid container item xs={5} justifyContent="center" sx={{height:'500px', minWidth:'330px', overflowX:"hidden",overflowY:'auto'}}>
                    <Grid item xs={12}>
                        <Box  sx={{backgroundColor:'#F3F4F8',ml:1,borderRadius:'8px',p:1, overflow:'auto'}}>
                            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                <Typography style={{fontSize:"13px",color:'#0F2C6F'}}>11.50</Typography>
                                <Box sx={{display:'flex'}}>
                                    <WifiIcon style={{fontSize:"15px",color:'#0F2C6F'}} />
                                    <SignalCellularAltIcon style={{fontSize:"15px",mx:1,color:'#0F2C6F'}} />
                                    <BatteryCharging50Icon style={{fontSize:"15px",color:'#0F2C6F'}} />
                                </Box>
                            </Box>
                            <Box sx={{mt:2}}>
                                <Typography sx={{color:'#0F2C6F',fontSize:'18px',mb:1 }}>{facilityDetails?.title}</Typography>
                                <Typography sx={{color:'#6F80A9',mb:1, }}> Level {facilityDetails?.officeLevel}</Typography>
                                <Typography sx={{wordBreak:"break-all",my:1, color:'#0F2C6F'}}>{facilityDetails?.description}</Typography>
                            </Box>
                            <Typography sx={{color:'#6F80A9',mb:1, }}>Capacity: {facilityDetails?.people_allowed}</Typography>
                            {featuredImages?.map((value)=>(
                                <img style={{width:'100%',height:'150px'}} src={value.src} />
                            ))}
                            <Grid item xs={12} sx={{mt:2}}>
                                {uniqDetails?.map((item, index) => (
                                    <Box sx={{display:'flex', gap:'15px', mb:2}}>
                                        <Box>
                                            <img size = 'sm' src = {getIcon(item.uniq_icon)} />
                                        </Box>
                                        <Box sx={{display:'flex', flex:1, flexDirection:'column'}}>
                                            <Typography sx={{ color:'#0F2C6F'}}>{item?.uniq_title}</Typography>
                                            <Typography sx={{wordBreak:"break-all",color:'#6F80A9'}}>{item?.short_description}</Typography>
                                        </Box>
                                    </Box>
                                ))}

                            </Grid>
                        </Box>
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
        )
    }

  return (
    <Box sx={{p:3}}>
        <Box sx={{ backgroundColor: '#FFFFFF', mb: 8 }}>
        <Tabs value={innerTabValue} onChange={(e, value) => setInnerTabValue(value)} sx={{ borderBottom: "1px solid #DBDFE9" }}>
            <Tab label={<Box>
                <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>1</span>
                <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Details</span>
            </Box>}></Tab>
            <Tab label={<Box>
                <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>2</span>
                <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}> Preview</span>
            </Box>}>

            </Tab>
        </Tabs>
        <TabPanel value={innerTabValue} index={0} >
            <FacilityDetail /><Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
            <OpenHoursReviewItem/><Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
            <UniqFeatureDetail/><Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
            <FacilityImages /><Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
            <TermsandConditions/><Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
        </TabPanel>
        <TabPanel value={innerTabValue} index={1} >
        <MobilePreview/>
        </TabPanel>
        </Box>
    </Box>
  )
}

const TabPanel = (props)=>{
    const {children,value,index} =props
    return(
        <>
        {
        value===index && 
        <>{children}</>
        }
        </>
    ) 
}