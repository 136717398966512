export const weekDayHours = [
    '00:00', "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00",
    '18:00', "19:00", "20:00", "21:00", "22:00", "23:00"
];
export const saturDayHours = ['00:00', "01:00", "02:00", "03:00", "04:00", "05:00", "06:00",
    "07:00", "08:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]
export const sundayHours = ['00:00', "01:00", "02:00", "03:00", "04:00", "05:00", "06:00",
"07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]
export const PRICEPERHOUR = 42;
export const GSTPERCENTAGE = 8;
export const getSingleDayPrice = (saturdaysCount, sunDaysCount, WeekDaysCount, startTime, endTime,rangeData) => {
    let priceWithoutGST = 0, PriceWithGST = 0, GSTAmount = 0, TotalHours = 0;
    let startingHour = parseInt(startTime.split(':')[0]), endingHour = parseInt(endTime.split(':')[0]);
    let diffHour;
    if (sunDaysCount && rangeData.requiredOnSundays) {
        priceWithoutGST = 24 * PRICEPERHOUR;
        GSTAmount = (priceWithoutGST / 100) * GSTPERCENTAGE;
        PriceWithGST = priceWithoutGST + GSTAmount;
        TotalHours = 24;

    } else if (saturdaysCount && rangeData.requiredOnSaturdays) {
        if (startingHour <= 7 && endingHour <= 7) {
            diffHour = (endingHour - startingHour) + 1;
        } else if (startingHour >= 14 && endingHour >= 14) {
            diffHour = (endingHour - startingHour) + 1;
        } else if (startingHour <= 7 && endingHour >= 14) {
            diffHour = (endingHour - 14) + (8 - startingHour);
        }
        priceWithoutGST = diffHour * PRICEPERHOUR;
        GSTAmount = (priceWithoutGST / 100) * GSTPERCENTAGE;
        PriceWithGST = priceWithoutGST + GSTAmount;
        TotalHours = diffHour;
    } else if (WeekDaysCount) {
        if (startingHour <= 8 && endingHour <= 8) {
            diffHour = (endingHour - startingHour) + 1;
        } else if (startingHour >= 18 && endingHour >= 18) {
            diffHour = (endingHour - startingHour) + 1;
        } else if (startingHour <= 8 && endingHour >= 18) {
            diffHour = (endingHour - 17) + ( startingHour +1);
        }
        priceWithoutGST = diffHour * PRICEPERHOUR;
        GSTAmount = (priceWithoutGST / 100) * GSTPERCENTAGE;
        PriceWithGST = priceWithoutGST + GSTAmount;
        TotalHours = diffHour;
    }

    return { priceWithoutGST, PriceWithGST, GSTAmount, TotalHours }

}

export const calculateStartDayPrice = (dayType, dayDetails) => {
    let startDayPriceWithoutGST = 0, startDayPriceWithGST = 0, startDayPriceGSTAmount = 0, TotalHours = 0, diffHour = 0;
    let startingHour = parseInt(dayDetails.start_time.split(':')[0]);
    if (dayType === 'SUN' && dayDetails.requiredOnSundays) {
        if (startingHour === 23) {
            TotalHours = 1;
        } else {
            TotalHours = 24;
        }
    }
    if (dayType === 'SAT' && dayDetails.requiredOnSaturdays) {
        if (startingHour <= 7) {
            TotalHours = (8 - startingHour)+11;
        } else if (startingHour >= 14) {
            TotalHours = (startingHour - 13) + 8;
        }
    }
    if (dayType === 'WEEK') {
        if (startingHour <= 8) {
            TotalHours = (8 - startingHour) + 7;
        } else if (startingHour >= 18) {
            TotalHours = (startingHour - 17) + 9;
        }
    }
    startDayPriceWithoutGST = TotalHours * PRICEPERHOUR;
    startDayPriceGSTAmount = (startDayPriceWithoutGST / 100) * GSTPERCENTAGE;
    startDayPriceWithGST = startDayPriceWithoutGST + startDayPriceGSTAmount;
    return { startDayPriceWithoutGST, startDayPriceWithGST, startDayPriceGSTAmount, TotalHours };
}
export const calculateEndDayPrice = (dayType, dayDetails) => {
    let endingHour = parseInt(dayDetails.end_time.split(':')[0])
    let endDayPriceWithoutGST = 0, endDayPriceWithGST = 0, endDayPriceGSTAmount = 0, endTotalHours = 0;
    if (dayType === 'SUN' && dayDetails.requiredOnSundays) {
        if (endingHour === 23) {
            endTotalHours = 24;
        } else {
            endTotalHours = 1;
        }
    }
    if (dayType === 'SAT' && dayDetails.requiredOnSaturdays) {
        if (endingHour <= 7) {
            endTotalHours = 8 - endingHour;
        } else if (endingHour >= 14) {
            endTotalHours = (endingHour - 13) + 8;
        }
    }
    if (dayType === 'WEEK') {
        if (endingHour <= 8) {
            endTotalHours = (9 - endingHour);
        } else if (endingHour >= 18) {
            endTotalHours = (endingHour - 17) + 9;
        }
    }
    endDayPriceWithoutGST = endTotalHours * PRICEPERHOUR;
    endDayPriceGSTAmount = (endDayPriceWithoutGST / 100) * GSTPERCENTAGE;
    endDayPriceWithGST = endDayPriceWithoutGST + endDayPriceGSTAmount;
    return { endDayPriceWithoutGST, endDayPriceWithGST, endDayPriceGSTAmount, endTotalHours };
}
export const calcWeekDaysPrice = (weekDaysCount) => {
    let weekDaysPriceWithoutGST = 0, weekDaysPriceWithGST = 0, weekDaysGSTAmount = 0, weekDayHours = 0;

        weekDaysPriceWithoutGST =(weekDaysCount* 15 )* PRICEPERHOUR;
        weekDaysGSTAmount = (weekDaysPriceWithoutGST / 100) * GSTPERCENTAGE;
        weekDaysPriceWithGST = weekDaysPriceWithoutGST + weekDaysGSTAmount;
        weekDayHours =weekDaysCount*15;
    

    return { weekDaysPriceWithoutGST, weekDaysPriceWithGST, weekDaysGSTAmount, weekDayHours }
}

export const calculateSunDayPrice = (sundaysCount) => {
    let sunDayPriceWithoutGST = 0, sunDayPriceWithGST = 0, sunDayGSTAmount = 0,sunDayTotalHours=0;
    sunDayPriceWithoutGST = (sundaysCount * 24) * PRICEPERHOUR;
    sunDayGSTAmount = (sunDayPriceWithoutGST / 100) * GSTPERCENTAGE;
    sunDayPriceWithGST = sunDayPriceWithoutGST + sunDayGSTAmount;
    sunDayTotalHours=sundaysCount * 24;

    return {sunDayPriceWithoutGST, sunDayPriceWithGST, sunDayGSTAmount, sunDayTotalHours};
}

export const calculateSaturDayPrice = (saturdaysCount) => {
    let satPriceWithOutGST = 0, satPriceWithGST = 0, satGSTAmount = 0,satHours=0;
    satPriceWithOutGST = (saturdaysCount * 19) * PRICEPERHOUR;
    satGSTAmount = (satPriceWithOutGST / 100) * GSTPERCENTAGE;
    satPriceWithGST = satPriceWithOutGST + satGSTAmount;
    satHours=saturdaysCount * 19;
    return {satPriceWithOutGST,satGSTAmount,satPriceWithGST,satHours};
}

