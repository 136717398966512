import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrowLeft } from "../../Assets/icons/BackArrowLeft.svg";
import {ReactComponent as BackIcon} from '../../Assets/icons/back.svg'
import SingleStepperTab from "./SingleStepperTab";

const OnBoardSingle = () => {
  const navigate = useNavigate();
  return (
    <>
    <Grid container>
      <Grid item xs={1.5} sx={{backgroundColor:'#F9FAFA'}}/>

      <Grid item xs={9}>
      <Box
      sx={{
        background: "#F9FAFA",
        width: "100%",
        paddingTop: { md: "65px", xs: "30px" },
      }}
    >
      <Box sx={{ padding: { md: "0 25px", xs: "0 10px" } }}>
        
        <BackIcon style={{cursor:'pointer'}} onClick={()=>navigate(-1)}/>

        <Box sx={{ margin: { md: "25px 0", xs: "20px 0" } }}>
          <Typography
            sx={{
              fontSize: { md: "26px", xs: "22px" },
              fontWeight: 500,
              color: "#002D74",
            }}
          >
            Onboard a new employee
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: "#002D74",
              marginTop: { md: "0", xs: "10px" },
            }}
          >
            Fill in the form below to onboard a new employee. If you would like to
            onboard multiple employees, please select batch employee upload
            instead.
          </Typography>
        </Box>

        <Box>
          <SingleStepperTab />
        </Box>
      </Box>
    </Box>
      </Grid>

      <Grid item xs={1.5} sx={{backgroundColor:'#F9FAFA'}}/>
    </Grid>
   
    </>
  );
};

export default OnBoardSingle;
