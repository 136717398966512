import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import {Select,} from '@mui/material';
import {getEmployeeReport } from '../../redux/actions/reportAction/index';
import { connect } from 'react-redux';
import * as ReducerAction from "../../redux/actions/reportAction/index"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '260px',
      
    },
  },
};


function CompanyFilter({getEmpReportsFilter,empReportFilterData, companyNames,setComapanyNames,setFilterOptions, filterOptions}) {
  const [companyLists, setCompanyLists] = React.useState([])

  React.useEffect(()=>{
    getEmpReportsFilter()
},[])

console.log('empReportFilterData ',empReportFilterData)

React.useEffect(() => {
    if(empReportFilterData?.companyList?.length >0) {
        console.log('within empReportFilterData?.companyList ',empReportFilterData?.companyList)
        setCompanyLists(empReportFilterData.companyList.map((item) => ({companyName:item.company_name, companyId:item.company_id})))
    }
},[empReportFilterData])


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log('value ',value)
    setFilterOptions(prev=>({...prev, company_id: value}))
    setComapanyNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>

<FormControl size='small' fullWidth>
            <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Company</InputLabel>        
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={companyNames}
                onChange={handleChange}
                input={<OutlinedInput sx={{color: "#123FA9", fontSize: "14px",}} label="Name" />}
                MenuProps={MenuProps}
                sx={{height:'37px',color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:1}}
               >              
              {companyLists?.map(company => <MenuItem key={company.companyId} value={company.companyId}>
                {company.companyName}
              </MenuItem>)}
            </Select>
            </FormControl>

      {/* <FormControl sx={{ m: 1, width: 160,  display:'flex', justifyContent:'center'  }}>
        <InputLabel id="demo-multiple-name-label" sx={{color:'#123FA9', fontWeight:'500', 
  

    }}>Company</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput sx={{color: "#123FA9", fontSize: "14px",}} label="Name" />}
          MenuProps={MenuProps}
          sx={{height:'37px',color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:1}}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </div>
  );
}

const mapStateToProps = state => {
    return {
        empReportFilterData:state.ReportsReducer.empReportFilterData,
    }
  }

const mapDispatchToProps = (dispatch)=>{
    return{
        getEmpReportsFilter:()=>dispatch(ReducerAction.getEmpReportsFilter()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(CompanyFilter)