import { handleActions } from "redux-actions";
const eAndpReducer={
eventCreateStatus:null,
promotionCreateStatus:null,
eventAndPromotions:[],
eventAndPromotionsCount:0,
promotionProperties:[],
eventAndPromotionInactiveStatus: null,
eventAndPromotionReadStatus:null ,
eventAndPromotionDetails:{},
updateDraftStatus:null
}

const reducer=handleActions(
    {
        STATE_TO_NULL_eANDp:(state,action)=>{
            return{
                ...state,
                eventCreateStatus:null,
                promotionCreateStatus:null,
                eventAndPromotionInactiveStatus: null,
                updateDraftStatus:null,
                eventAndPromotionDetails:{}
            }
        },
        CREATE_EVENT_INITIATE:(state,action)=>{
            return{
                ...state,
            }
        },
        CREATE_EVENT_SUCCESS:(state,action)=>{
            return{
                ...state,
                eventCreateStatus:action.payload
            }
        },
        CREATE_EVENT_FAILURE:(state,action)=>{
            return{
                ...state,
                eventCreateStatus:action.payload
            }
        },
        CREATE_PROMOTION_INITIATE:(state,action)=>{
            return{
                ...state,
            }
        },
        CREATE_PROMOTION_SUCCESS:(state,action)=>{
            return{
                ...state,
                promotionCreateStatus:action.payload,
            }
        },
        CREATE_PROMOTION_FAILURE:(state,action)=>{
            return{
                ...state,
                promotionCreateStatus:action.payload,
            }
        },
        PROMOTION_EVENT_LIST_INITIATE:(state,action)=>{
            return{
                ...state,
            }
        },
        PROMOTION_EVENT_LIST_SUCCESS:(state,action)=>{
            return{
                ...state,
                eventAndPromotions:action.payload.response_data,
                eventAndPromotionsCount:action.payload.RecordCount
            }
        },
        PROMOTION_EVENT_LIST_FAILURE:(state,action)=>{
            return{
                ...state,
                eventAndPromotions:[],
                eventAndPromotionsCount:0,
            }
        },
        GET_PROPERTIES_INTITATE:(state)=>{
            return{
                ...state,
            }
        },
        GET_PROPERTIES_SUCCESS:(state,action)=>{
            console.log('GET_PROPERTIES_SUCCESS ',action)
            return{
                ...state,
                promotionProperties:action.payload
            }
        },
        GET_PROPERTIES_FAILURE:(state,action)=>{
            console.log('failure action ',action)
            return{
                ...state,
                promotionProperties:[]
            }
        },
        EVENT_PROMOTIONS_STATUS_INACTIVE_INITIATE:(state) => {
            return {
                ...state,
            }
        },
        EVENT_PROMOTIONS_STATUS_INACTIVE_SUCCESS:(state, action) => {
            return {
                ...state, 
                eventAndPromotionInactiveStatus: action.payload,
            }
        },
        EVENT_PROMOTIONS_STATUS_INACTIVE_FAILURE: (state, action) => {
            return {
                ...state, 
                eventAndPromotionInactiveStatus: null,
            }
        },
        UPDATE_EP_READSTATUS_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        UPDATE_EP_READSTATUS_SUCCESS:(state,action)=>{
            return{
                ...state,
                eventAndPromotionReadStatus:action.payload
            }
        },
        UPDATE_EP_READSTATUS_FAILURE:(state,action)=>{
            return{
                ...state,
                eventAndPromotionReadStatus:action.payload
            }
        },
        GET_EANDP_DETAILS_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        GET_EANDP_DETAILS_SUCCESS:(state,action)=>{
            return{
                ...state,
                eventAndPromotionDetails:action.payload
            }
        },
        GET_EANDP_DETAILS_FALURE:(state,action)=>{
            return{
                ...state,
                eventAndPromotionDetails:{}
            }
        },
        UPDATE_EANDP_DRAFT_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        UPDATE_EANDP_DRAFT_SUCCESS:(state,action)=>{
            return{
                ...state,
                updateDraftStatus:action.payload
            }
        },
        UPDATE_EANDP_DRAFT_FAILURE:(state)=>{
            return{
                ...state
            }
        }
    },eAndpReducer
)

export default reducer;
