import { Box, Button, Chip, Divider, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {ReactComponent as BackIcon} from '../../Assets/icons/back.svg';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import { ReactComponent as CloseButton } from '../../Assets/icons/Alertclose.svg';
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as CommonAction from '../../redux/actions/commonAction';
import { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import {getLoggedUserData, preventDatePickerKeyboard} from "../../Utils/utils";
import moment from "moment";
import ToastAlert from "../common/Alert";


const ReviewStaff = ({getEmpDetailsById,empDetails,offBoardEmp,empOffBoardStatus,setStatusNull})=>{
    const navigate = useNavigate();
    let {empId} = useParams();
    const [empData,setEmpData] = useState()
    const [offboardTenantStatus, setOffboardTenantStatus] = useState(false);
    const [offboardErrs,setOffboardErrs] = useState({date:'',remarks:''});
    const [remarks,setRemarks] = useState('');
    const [offboardData, setOffboardData] = useState(null);
    const [empOffStatus, setEmpOffStatus] = useState({ message: '', status: false, color: '', icon: '' })

    useEffect(()=>{
        getEmpDetailsById({employee_id:empId})
    },[])

    useEffect(()=>{
        setEmpData(empDetails?.employeeArrayList && empDetails.employeeArrayList[0])
    },[empDetails])

    useEffect(() => {
        if(empOffBoardStatus==='Employee Offboarded Successfully'){
            setEmpOffStatus({ message: 'Employee Offboarded Successfully', status: true, color: 'green', icon: 'tick' })
            closePopup();
            setTimeout(() => {
                setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
                setStatusNull();
                navigate('/manage_staff/2')
            }, 5000);
            
        }else if(empOffBoardStatus!=null){
            setEmpOffStatus({ message: empOffBoardStatus, status: true, color: 'red', icon: 'close' })
            setStatusNull();
            closePopup();
            setTimeout(() => {
                setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
            }, 5000);
        }
        
    }, [empOffBoardStatus])

    const closePopup = () => {
        setOffboardTenantStatus(false)
        setOffboardData(null)
        setOffboardErrs('')
        setRemarks('')
    }
    
    const validate = ()=>{
        let error = true;
        if(!remarks){
            setOffboardErrs((prevValue)=>({
                ...prevValue,
                remarks:'Should not be empty'
            }))
            error=false;  
        }
        if(offboardData==null){
            setOffboardErrs((prevValue)=>({
                ...prevValue,
                date:'Select date '
            }))
            error=false;
        }
        return error;
    }

    const offboardEmployee = () => {
        let err = validate();  
      if(err){ 
       let datestr=`${offboardData.getFullYear()}-${offboardData.getMonth()+1}-${offboardData.getDate()}`
        let data={
        offboard_date:datestr,
        employee_id:empId,
        created_by:getLoggedUserData().user_id,
        remarks:remarks
        }
       offBoardEmp(data)
      }
   }

    return(
        <>
         {empOffStatus.status && <ToastAlert message={empOffStatus.message} status={empOffStatus.status} color={empOffStatus.color} icon={empOffStatus.icon} /> }
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}}>
            <Grid item xs={1} />

            <Grid item xs={10}>
                <Box sx={{pt:7,pb:4}}>
                    <BackIcon style={{cursor:"pointer"}} onClick={()=>navigate(-1)}/>
                    <Typography sx={{fontSize:'26px',color:'#0F2C6F',my:2}}>Employee Details</Typography>
                    <Typography sx={{fontSize:'12px',color:'#9AA6C3'}}>Requested on {moment(empData?.created_at).format("DD MMM YYYY")}
                    {/* <span style={{color:'#0F2C6F',backgroundColor:'#DBDFE9',borderRadius:'6px',marginLeft:'5px',padding:'5px'}}>Submitted</span> */}
                    </Typography>
                </Box>
                <Box sx={{backgroundColor:'#FFFFFF',p:4}}>
                    <Grid container  sx={{backgroundColor:'#FFFFFF',border:' 1px solid #DBDFE9',borderRadius:'6px',p:3}}>
                        <Grid item xs={12} >
                            <Typography sx={{fontSize:'14px',color:'#0F2C6F'}}>{empData?.first_name} {empData?.last_name}</Typography>
                        </Grid>
                        
                        <Grid item xs={12} sm={3}>
                            {/* <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>Finance Executive</Typography> */}
                            <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>BMS: Administrator</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>Office : +65 21 123 4567</Typography>
                            <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>Mobile : +65 34 223 7824</Typography>
                            <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>{empData?.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography sx={{fontSize:"14px",color:'#0F2C6F'}}>Access Start Date</Typography>
                            <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>{empData?.access_start_date}</Typography>
                            <Typography sx={{fontSize:'12px',color:'#0F2C6F'}}>Office Unit : #{empData?.level_no} - {empData?.unit_no}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography sx={{fontSize:"14px",color:'#0F2C6F'}}>Offboard Date</Typography>                            <Typography></Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{display:'flex',justifyContent:'flex-end',py:5}}>
                    <Button onClick={()=>setOffboardTenantStatus(true)} variant="outlined" sx={{textTransform:'capitalize',color:'#0F2C6F',fontSize:'14px',height:"50px",borderRadius:"8px",backgroundColor:"#FFFFFF"}}>Offboard</Button>
                </Box>
            </Grid>

            <Grid item xs={1} />
        </Grid>

        <Modal open={offboardTenantStatus} onClose={()=>closePopup()}>
                <Box sx={{position:'relative',left:'30%',top:'10%',backgroundColor:"#FFFFFF",width:"40%"}}>
                    <Box sx={{
                            display: "flex",
                            justifyContent: 'space-between',
                            borderRadius: '8px 8px 0px 0px',
                            p:2.5
                             }}>
                            <Typography sx={{ fontSize: '14px', fontWeight: '500', color: '#0F2C6F' }}>Offboard Employee</Typography>
                        
                                <CloseButton cursor='pointer' onClick={() => closePopup()} />
                        
                        </Box>
                        <Divider />
                        <Box sx={{ padding: '16px'  }}>
                            <Typography sx={{color:'#6F80A9',fontSize:'14px'}}>Are you sure, want to offboard {empData?.first_name} {empData?.last_name} on below date?</Typography>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '14px',mt:5 }}>Please select a Offboard Date*</Typography>

                        </Box>
                        <Box sx={{ padding: '16px' }}>
                            <DatePicker
                                components={{
                                    OpenPickerIcon:DatePickerIcon
                                }}
                                minDate={ new Date () > new Date(empData?.access_start_date) ? new Date() :  new Date(empData?.access_start_date)}
                                value={offboardData}
                                onChange={(newValue) =>{
                                    setOffboardData(newValue)
                                    setOffboardErrs({
                                        ...offboardErrs,
                                        date:""
                                    })
                                }    
                                }
                                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                            />
                              {offboardErrs.date && <Typography sx={{ color: "#f44336" }}>{offboardErrs?.date}</Typography>}
                        <Box>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '14px',mt:5 }}>Add a Remark *</Typography>
                            <TextField value={remarks} onChange={(e)=>{setRemarks(e.target.value)
                            setOffboardErrs({
                                ...offboardErrs,
                                remarks:''
                            })} } sx={{mt:1}} />
                            {offboardErrs.remarks && <Typography sx={{ color: "#f44336" }}>{offboardErrs.remarks}</Typography>}

                        </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                            <Button variant="outlined" sx={{color:'#0F2C6F',fontSize:'14px',border:'1px solid #AAC3FF',
                            '&:hover':{backgroundColor:'#FFFFFF'},
                            borderRadius:'6px',textTransform:'capitalize'}} onClick={()=>closePopup()}>Cancel</Button>
                            
                        
                            <Button variant='contained' onClick={() => offboardEmployee()}
                                sx={{
                                    color: '#ffffff',
                                    textTransform:'capitalize',
                                    '&:hover':{backgroundColor:'#BD0251'},
                                    backgroundColor: '#BD0251', height: '40px', padding: '10px', fontSize: '14px',
                                }}>Offboard</Button>
                        </Box>
                    
                </Box>
                
           </Modal>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
         empDetails:state.CommonReducer.empDetails,
         empOffBoardStatus:state.CommonReducer.empOffBoardStatus
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getEmpDetailsById:(data)=>dispatch(CommonAction.getEmpDetailsById(data)),
        offBoardEmp:(data)=>dispatch(CommonAction.offBoardEmp(data)),
        setStatusNull:()=>dispatch(CommonAction.setStatusNull()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewStaff);