import { handleActions } from "redux-actions";

const bmsStaffReducer = {
    rolesList:[],
    accessList:[],
    defaultAccessRights:[],
    addBmsEmpStatus:null,
    bmsStaffList:[],
    bmsStaffListCount:null,
    editOnboardDateStatus:null,
    cancelOnboardStatus:null,
    levelList: [],
    assignedLevelList: [],
    assignedUnitList:[],
    assignedUnitsList:[]
}

const reducer = handleActions({
    UPDATE_BMSSTAFF_REDUCER:(state)=>{
        return{
            ...state,
            addBmsEmpStatus:null,
            editOnboardDateStatus:null,
            cancelOnboardStatus:null
        }
    },
    GET_STAFFROLE_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_STAFFROLE_SUCCESS:(state,action)=>{
        return{
            ...state,
            rolesList:action.payload
        }
    },
    GET_STAFFROLE_FAILURE:(state)=>{
        return{
            ...state,
            rolesList:[]
        }
    },
    GET_ACCESSLIST_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ACCESSLIST_SUCCESS:(state,action)=>{
        return{
            ...state,
            accessList:action.payload
        }
    },
    GET_ACCESSLIST_FAILURE:(state)=>{
        return{
            ...state,
            accessList:[]
        }
    },
    GET_DEFAULT_ACCESSRIGHTS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_DEFAULT_ACCESSRIGHTS_SUCCESS:(state,action)=>{
        return{
            ...state,
            defaultAccessRights:action.payload
        }
    },
    GET_DEFAULT_ACCESSRIGHTS_FAILURE:(state)=>{
        return{
            ...state,
            defaultAccessRights:[]
        }
    },
    ADD_BMSEMP_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    ADD_BMSEMP_SUCCESS:(state,action)=>{

        return{
            ...state,
            addBmsEmpStatus:action.payload
        }
    },
    ADD_BMSEMP_FAILURE:(state,action)=>{

        return{
            ...state,
            addBmsEmpStatus:action.payload
        }
    },
    GET_BMSSTAFFLIST_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_BMSSTAFFLIST_SUCCESS:(state,action)=>{
        return{
            ...state,
            bmsStaffList:action.payload.resultArrayList,
            bmsStaffListCount:action.payload.totalRecords
        }
    },
    GET_BMSSTAFFLIST_FAILURE:(state)=>{
        return{
            ...state,
            bmsStaffList:[],
            bmsStaffListCount:null
        }
    },
    EDIT_BMSSTAFF_ONBOARDDATE_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    EDIT_BMSSTAFF_ONBOARDDATE_SUCCESS:(state,action)=>{
        return{
            ...state,
            editOnboardDateStatus:action.payload
        }
    },  
    EDIT_BMSSTAFF_ONBOARDDATE_FAILURE:(state,action)=>{
        return{
            ...state,
            editOnboardDateStatus:action.payload
        }
    },
    CANCEL_BMSSTAFF_ONBOARD_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    CANCEL_BMSSTAFF_ONBOARD_SUCCESS:(state,action)=>{
        return{
            ...state,
            cancelOnboardStatus:action.payload
        }
    },
    CANCEL_BMSSTAFF_ONBOARD_FAILURE:(state,action)=>{
        return{
            ...state,
            cancelOnboardStatus:action.payload
        }
    },
    GET_STAFFLEVEL_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_STAFFLEVEL_SUCCESS:(state,action)=>{
        return{
            ...state,
            levelList:action.payload
        }
    },
    GET_STAFFLEVEL_FAILURE:(state)=>{
        return{
            ...state,
            levelList:[]
        }
    },     
    GET_ASSIGNEDLEVEL_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ASSIGNEDLEVEL_SUCCESS:(state,action)=>{
        return{
            ...state,
            assignedLevelList:action.payload
        }
    },
    GET_ASSIGNEDLEVEL_FAILURE:(state)=>{
        return{
            ...state,
            assignedLevelList:[]
        }
    },   
    GET_ASSIGNEDUNIT_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ASSIGNEDUNIT_SUCCESS:(state,action)=>{
        console.log('action.payload ',action.payload)
        return{
            ...state,
            assignedUnitList:action.payload,
            assignedUnitsList: action.payload
        }
    },
    GET_ASSIGNEDUNIT_FAILURE:(state)=>{
        return{
            ...state,
            assignedUnitList:[]
        }
    },      
    
},bmsStaffReducer)

export default reducer;