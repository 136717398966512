import React from "react";
import { bool, func } from "prop-types";
import {ReactComponent as CarouselFullScreen} from '../../../Assets/icons/CarouselFullScreen.svg'

const FullScreenIcon = React.memo(({ isFullscreen, onClick }) => {
  return (
    <button
      type="button"
      style={{zIndex:1}}
      className="image-gallery-icon image-gallery-fullscreen-button"
      onClick={onClick}
      aria-label="Open Fullscreen"
    >
      <CarouselFullScreen />
    </button>
  );
});

FullScreenIcon.displayName = "FullScreenIcon";

FullScreenIcon.propTypes = {
  isFullscreen: bool.isRequired,
  onClick: func.isRequired,
};

export default FullScreenIcon;
