import { Box, Checkbox, Pagination, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as CheckboxIcon} from '../../../Assets/icons/CheckboxIcon.svg';
import { connect } from 'react-redux';

const StyledTableCell = styled(TableCell)({
    color: '#0F2C6F',
    fontSize: '13px',
    fontWeight: 550,
    textAlign: "left",
    fontFamily: 'Inter',
    lineHeight: '18px'
})
const StyledTableRow = styled(TableRow)({
    cursor: "pointer",
    height: '15px !important',
    ':nth-last-of-type(even)': {
        backgroundColor: ' #F5F8FF'
    }
})

const  UpcommingVisitorsList = ({ handlePagignation, tabProps, tableColumnsAndDatas, allselected, handleAllSelected, handleSelected,visitors,recordCount })=> {
    const navigate = useNavigate();

    const getNavigationStatus = (inviteType) => {
        if (inviteType === 'Tenant_Invite' ||
            inviteType === 'Single_Visitor' ||
            inviteType === 'Self_kiosk' ||
            inviteType === 'Special_access') {
            return true
        } else {
            return false
        }
    }
    const renderTableDatas = (rowData) => {
        if (tabProps.getType === 'All' || tabProps.getType === 'Registered') {
            return (
                <StyledTableRow >
                    {/* <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}><Checkbox icon={<CheckboxIcon />} checked={false}></Checkbox></TableCell> */}
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`)
                        }
                    }}  sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.visit_date}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`)
                        }
                    }}  sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.visitor_name}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`)
                        }
                    }}  sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.email || rowData?.mobile_no}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`)
                        }
                    }}  sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.company_name}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`)
                        }
                    }}  sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.level_name}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`)
                        }
                    }}  sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.last_in_time || '-'}</TableCell>
                </StyledTableRow>
            )
        }
        else if (tabProps.getType === 'Invited' || tabProps.getType === 'Special_access') {
            return (
                <StyledTableRow >
                    {/* <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}><Checkbox icon={<CheckboxIcon />} checked={false}></Checkbox></TableCell> */}
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                        onClick={()=>{
                            rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }}
                    >
                        {rowData?.visit_date}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                        onClick={()=>{
                            rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }}
                    >
                        {rowData?.special_count}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                        onClick={()=>{
                            rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }}
                    >
                        {rowData?.invitations_title}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                       onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.requested_by}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                      onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.level_name}
                    </TableCell>
                </StyledTableRow>
            )
        }
    }
    return (
        <>
            <TableContainer >
                <Table>
                    <TableHead>
                        <TableRow>

                            {tableColumnsAndDatas?.columns.map((item) => (
                                item.column_name === 'Checkbox' ?
                                    <StyledTableCell >
                                        <Checkbox icon={<CheckboxIcon />} checked={Boolean(allselected)} onClick={() => handleAllSelected()} />
                                    </StyledTableCell> :
                                    <StyledTableCell >
                                        {item.column_name}
                                    </StyledTableCell>
                            ))}


                        </TableRow>
                    </TableHead>
                    {recordCount>0 && 
                    <TableBody>
                        {visitors.map((item) => (
                            renderTableDatas(item)
                        ))}
                    </TableBody>
                    }
                </Table>    
                
            </TableContainer> 
            
            {recordCount<1 && <Box sx={{display:"flex",justifyContent:"center",p:5,backgroundColor:"rgba(143, 146, 161,0.05)"}}>
                <Typography variant='p' sx={{fontSize:"20px",color:'#0F2C6F'}}>No Data Found</Typography>
            </Box>
            }
        
            {recordCount > 0 && 
            <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "rgba(143, 146, 161,0.05)", py: 2.5 }}>
                    <Pagination
                        count={Math.ceil(tableColumnsAndDatas.totalDatas / 10)}
                        page={tabProps.offset + 1}
                        size="large"
                        shape="rounded"
                        onChange={handlePagignation} />
            </Box>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        visitors: state.ReceptionReducer.visitorsData,
        recordCount: state.ReceptionReducer.recordCount
    }
}

const mapDispatchToProps = ()=>{
    return{

    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UpcommingVisitorsList)