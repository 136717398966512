import React from "react";
import { Route, Routes } from "react-router-dom";
import FaultReporting from "../Pages/FaultReporting";
import Faults from "../Pages/FaultReporting/Faults";
import ManageEmployees from "../Pages/NewManageEmployees";
import OnBoardBatch from "../Pages/NewManageEmployees/OnBoardBatch";
import OnBoardSingle from "../Pages/NewManageEmployees/OnBoardSingle";
import ServiceRequest from "../Pages/ServiceRequest/Index.js";
import Announcement from "../Pages/Announcement/Index.js";
import SeasonParking from "../Pages/SeasonParking/Index";
import ErrorPage from "../components/LandingPage/ErrorPage";
import Header from "../Header";
import SocialImpact from "../Pages/SocialImpact.js/Index";
import ReviewReport from "../Pages/FaultReporting/ReviewReport";
import ReviewEmployeeList from "../Pages/NewManageEmployees/ReviewEmployeeList";
import SingleEmployeeReview from "../Pages/NewManageEmployees/SingleEmployeeReview";
import UpdateEmpDetails from "../Pages/NewManageEmployees/UpdateEmpDetails";
import EditOffBoardingModal from "../Pages/NewManageEmployees/EditOffBoardingModal";
import NewServiceRequest from "../Pages/ServiceRequest/NewRequests/index";
import ReviewRequest from '../Pages/ServiceRequest/ReviewRequests';
import BatchInvitation from "../Pages/TenantVisitors/BatchInvitation";
import TenantVisitors from "../Pages/TenantVisitors";
import TenantDashboard from "../Pages/Dashboard/Tenant Manager";
import ReviewInvite from "../Pages/TenantVisitors/ReviewInvite";
import AnnouncementNotification from "../Pages/common/HeaderNotification";
import HeplAndSupport from "../Header/HelpAndSupport";
import Reports from "../Pages/Reports/index";
import SendFeedback from "../Header/SendFeedback";
import ReportBugs from "../Header/ReportBugs";
import InviteViaExcel from "../Pages/TenantVisitors/InviteViaExcel";
import FaciliyBooking from "../Pages/FacilityBooking";
import BookingSelection from "../Pages/FacilityBooking/BookingSelection";
import SingleRoomFeature from "../Pages/FacilityBooking/SingleRoomFeature";
import ConfirmedBooking from "../Pages/FacilityBooking/ConfirmedBooking";
import PaymentConfirmation from "./PaymentConfirmation.js";
import ManageUnitLevelAccess from "../Pages/NewManageEmployees/ManageUnitLevelAccess.js";
import UpdateEmpsDetails from "../Pages/NewManageEmployees/UpdateEmpsDetails.js";

const TenantManager = () => {
  return (
    <React.Fragment>
      <Header>
        <Routes>
          <Route path="/dashboard" element={<TenantDashboard />} />
          <Route path="/manage_employees/:tabvalue" element={<ManageEmployees />} />
          <Route path="/fault_reporting/:tabvalue" element={<FaultReporting />} />
          <Route path="/report_fault/:isDraft/:id" element={<Faults />} />
          <Route path="/review_fault/:id/:tabValue/:fromDashboard" element={<ReviewReport />} />
          <Route path="/single_employee_onboard" element={<OnBoardSingle />} />
          <Route path="/batch_onboarding" element={<OnBoardBatch />} />
          <Route path="/service_request_list/:tab_id" element={<ServiceRequest />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/season_parking" element={<SeasonParking />} />
          <Route path="/social_impact" element={<SocialImpact />} />
          <Route path='/review_employee_list/:emp_id/:fromBms' element={<ReviewEmployeeList />}></Route>
          <Route path='/single_employee_review/:emp_id/:activeType' element={<SingleEmployeeReview />} />
          <Route path="/service_request/:tabValue/:typeId/:reqId" element={<NewServiceRequest/>} />    
          <Route path="/update_employee/:emp_id/:activeType" element={<UpdateEmpDetails/>} />
          {/* <Route path="/update_employee/:emp_id/:activeType" element={<UpdateEmpsDetails/>} /> */}
          <Route path="/offboard_modal" element={<EditOffBoardingModal/>} />
          <Route path="/review_service_request/:service_req_id/:service_req_type_id/:tab_id/:fromDashboard" element={<ReviewRequest />} />
          <Route path="/tenant_visitors/:tabValue" element={<TenantVisitors/>} />
          <Route path="/review_tenant_inivte/:tabValue/:invite_id/:isEdit" element={<ReviewInvite/>} />
          <Route path="/batch_invitation" element={<BatchInvitation/>} />
          <Route path="/batch_invitation_excel" element={<InviteViaExcel  />} />
          <Route path="/notifications" element={<AnnouncementNotification />} />
          <Route path="/reports/:isAccess" element={<Reports />} />
          <Route path="/helpAndSupport" element={<HeplAndSupport/>} />
          <Route path="/feedback" element={<SendFeedback/>} />
          <Route path="/reportbug" element={<ReportBugs/>} />
          <Route path="/facility_booking" element={<FaciliyBooking />} />
          <Route path="/booking_selection" element={<BookingSelection />} />
          <Route path='/single_room_feature/:feature_id' element={<SingleRoomFeature />} />
          <Route path="/facility_booking/:facility_id" element={<ConfirmedBooking />} />
          <Route path="/payment_response" element={<PaymentConfirmation />} />
          <Route path='/manage_units_level_access' element={<ManageUnitLevelAccess />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Header>
    </React.Fragment>
  );
};

export default TenantManager;
