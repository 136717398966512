import { Box, Button, FormControl, ListSubheader, Menu, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {ReactComponent as CloseIcon} from '../../Assets/icons/Alertclose.svg';
import {ReactComponent as PdfIcon} from '../../Assets/icons/PDF.svg';
import {ReactComponent as ImageFormatIcon} from '../../Assets/icons/ImageFormatIcon.svg';
import * as Action from '../../redux/actions/faultreportAction';
import * as serviceRequestAction from '../../redux/actions/service_request/index';
import DropZone from "../../Utils/dropzone";


export const constructTechnicianListMappingData = (arr) => {
    let mappedArr = [];
    
    for(let i = 0; i < arr.length; i++){
        let updatedObj = {};
        for(let j = 0; j < arr[i].user_details_array.length; j++){
            updatedObj = arr[i].user_details_array[j];
            
            if(j === 0){
                mappedArr.push({...updatedObj, isHeader: true})
            }

            mappedArr.push(updatedObj);
 
        }
    }
    return mappedArr;
  }


const UpdatePopup = ({serviceReqId,serviceTypeValue,serviceType,currentStatus,techniciansList,getTechnicianList,closeFunc,updateServiceRequestStatus,serReqUpdateStatus,nextStatus})=>{
    let {service_req_id} = useParams()
    const [open,setOpen] = useState(true);
    const [data,setData] = useState({});
    const [inputErrors,setInputErrors] = useState({});
    const [enableAssignPerson,setEnableAssignPerson] = useState(false);
    const [SAPInvoiceFile, setSAPInvoiceFile] = useState([]);
    const [caseCloseParams, setCaseCloseParams] = useState({comments: "",finalInvoiceAmount: "",SAPNumber: "",});

    useEffect(()=>{
        getTechnicianList({id:service_req_id,requested_for:'service_request'});
          if((currentStatus==="approved" || currentStatus=='require_more_data_updated' || currentStatus=='pending_investigation' || currentStatus=='pending_work') ){
            setEnableAssignPerson(true);
          }
    },[])

    const handleData = (e)=>{
        let {name,value}  = e.target
        setData({
            ...data,
            [name]:value
        })
    }

    const validate = ()=>{
        let err= true;

        if(data.hasOwnProperty("status") && data.status){
            setInputErrors((prev)=>({
                ...prev,
                status:""
            }))
         err=true;
        }else{
            setInputErrors((prev)=>({
                ...prev,
                status:" Select Status"
            }))
            err=false;
       }

       if(enableAssignPerson && (data?.status=="pending_investigation" || data?.status=="pending_work" || data?.status=='reassign_technician' )){
            if(data.hasOwnProperty("personnel") && data.personnel){
                err=true;
            }else{
                setInputErrors((prev)=>({
                    ...prev,
                    personnel:"Assign Personnel"
                }))
                err=false;
            }

            if(data.hasOwnProperty("comments") && data.comments){
                err=true
            }else{
                setInputErrors((prev)=>({
                    ...prev,
                    comments:'Add a comment'
                }))
                err=false;
            }
       }
       console.log('validate ',err)
    
       
      return err;
    }

    const handleUpdate = ()=>{
    
        let valid = validate();
        if(valid){
            let form = new FormData();  

            form.append('service_requests_id',serviceType)
            form.append('description','')
            form.append('status',data?.status)
            
            if(enableAssignPerson && data?.status=="pending_investigation" || data?.status=="pending_work" || data?.status=='reassign_technician'){                            
                form.append('assignedEmployeeDataList',JSON.stringify([JSON.parse(data?.personnel)]));
                form.append('description',data?.comments);  
            }
            if(data?.status=='rejected') {
                form.append('rejected',data?.rejected); 
           
            }
            console.log(serviceTypeValue);
            
            if(data?.status==="closed" && (serviceTypeValue === 'Air Con Extension' || serviceTypeValue === 'Miscelleaneous Forms' || serviceTypeValue === 'Light Bulb Replacement' || serviceTypeValue === 'Security Clearance' || serviceTypeValue === 'Cargo List Requests')){
                
                form.append('sap_description',caseCloseParams?.comments)
                form.append('comments', caseCloseParams?.comments);
                form.append('sap_number', caseCloseParams?.SAPNumber);
                form.append('final_invoice_amount', caseCloseParams?.finalInvoiceAmount);
                form.append('file_count',  SAPInvoiceFile.length > 0 ? 1 : 0);
                form.append('file0', SAPInvoiceFile?.[0]?.file)  

            }
        
            
            console.log(form);

            updateServiceRequestStatus(form);
        }
 }

 console.log(SAPInvoiceFile);

  const handleCaseCloseInputs = (name,value) => {
    setCaseCloseParams({...caseCloseParams, [name]: value});
  }

    return(
       
        <>
        <Modal sx={{overflow:"scroll"}} open={open} onClose={()=>closeFunc(false)}>
            <Box sx={{backgroundColor:"#FFFFFF",position:'absolute',top:'5%',left:'30%',width:"40%"}}>
                <Box sx={{display:"flex",justifyContent:'space-between',p:3,borderBottom:"1px solid #C0C7D7"}}>
                    <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Update Status</Typography>
                    <CloseIcon onClick={()=>closeFunc(false)} cursor='pointer' />
                </Box> 
                <Box sx={{p:3}}>
                    <Typography sx={{color:" #6F80A9",fontSize:"14px"}}>You may assign case to tenant if you require their clarification.</Typography>
                    <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Service Type*</Typography>
                    <Select defaultValue={serviceTypeValue} sx={{width:"40%"}} onChange={(e)=>handleData(e)} name="type">
                        <MenuItem  value={serviceTypeValue}>{serviceTypeValue}</MenuItem>
                    </Select>
                    <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Update Status to</Typography>
                    <Select sx={{width:"60%"}} fullWidth onChange={(e)=>handleData(e)} name="status">
                        {
                            nextStatus?.length>0  && nextStatus.map((value)=>(
                                <MenuItem value={value.fault_status_name}>{value.display_name}</MenuItem>
                            ))
                        }
                    </Select>
                    {inputErrors.status && <Typography sx={{color:"#f44336"}}>{inputErrors.status}</Typography>}

                     {
                        enableAssignPerson && (data?.status=="pending_investigation" || data?.status=="pending_work" || data?.status=='reassign_technician' )   && 
                        <Box>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Assign Personnel</Typography>
 
                             <Select sx={{width:"60%"}} fullWidth 
                            onChange={(e)=>{
                                handleData(e)
                                setInputErrors((prev)=>({
                                    ...prev,
                                    personnel:"",    
                                }))
                            }}     
                            value={data.personnel || ""}                        
                                 name="personnel">
                                      {techniciansList.length > 0 && constructTechnicianListMappingData(techniciansList).map((item, index) => 
                                        <MenuItem key={index} value={item?.isHeader ? undefined : JSON.stringify({user_id:item?.user_id, value: item?.name})}
                                             sx={
                                                item?.isHeader && {
                                                    fontWeight: 600,
                                                    textTransform: "capitalize",
                                                    color: "#0F2C6F"
                                                
                                                }
                                            }
                                         
                                        >
                                            {item?.isHeader ? item?.role : item?.name}
                                        </MenuItem>
                            )}
                                {/* {
                                    techniciansList?.length > 0 && techniciansList?.map((value, index)=>(
                                        <>
                                             <ListSubheader variant="p" sx={{px: 2,py: 1, fontWeight: 600, textTransform: "capitalize"}}>{value?.role_name}</ListSubheader>
                                            {value?.user_details_array?.map((item, index) => 
                                        <MenuItem  value={item?.user_id} key={index} sx={{color: "#123FA9"}}>{item?.name}</MenuItem>                                        
                                        )}
                                        </>
                                     ))
                                }  */}
                              
                            </Select>

                          
                             {inputErrors.personnel && <Typography sx={{color:"#f44336"}}>{inputErrors.personnel}</Typography>}
                            
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Comments to Technician</Typography>
                            <OutlinedInput name="comments" value={data.comments} onChange={(e)=>{
                                handleData(e)
                                setInputErrors((prev)=>({
                                    ...prev,
                                    comments:""
                                }))
                            }} sx={{width:"60%"}}
                            placeholder='You may write a note to assigned personnel.'
                            minRows={3}                                    
                            multiline  
                            />
                            {inputErrors.comments && <Typography sx={{color:"#f44336"}}>{inputErrors.comments}</Typography>}
                        </Box>
                     } 

                    { 
                        data?.status==="rejected" && 
                    <Box>
                           <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Please provide reason for rejecting </Typography>
                            <TextField multiline fullWidth name="rejected" value={data?.rejected}  rows={4}onChange={(e)=>handleData(e)}  />
                            {inputErrors.rejected && <Typography sx={{color:"#f44336"}}>{inputErrors.rejected}</Typography>}
                    </Box>
                    }

                    { 
                        data?.status==="resolved" && 
                    <Box>
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Additional Notes</Typography>
                        <OutlinedInput sx={{width:"60%"}} name="additionalNote" onChange={(e)=>handleData(e)} inputProps={{maxLength:300}} />
                        <Typography sx={{color:" #9AA6C3",fontSize:"14px"}}>[{data?.additionalNote?.length}/300]</Typography>
                    </Box>
                    }
                    {
                        data?.status==="closed" && 
                    <Box sx={{width: "100%"}}>
                         
                         <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Comments (Optional)</Typography>
                            <OutlinedInput name="comments" value={data.comments} onChange={(e)=>{
                               handleCaseCloseInputs(e.target.name,e.target.value)
                            }} sx={{width:"100%"}}
                            placeholder='You may input any additional notes to the case prior to closing case. This can be seen by tenant managers too.'
                            minRows={3}                                    
                            multiline  
                            />
                      { (serviceTypeValue === 'Air Con Extension' || serviceTypeValue === 'Miscelleaneous Forms' || serviceTypeValue === 'Light Bulb Replacement') &&     <>
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Final Invoice Amount (Optional)</Typography>
                        <TextField placeholder="Input the final price on SAP invoice." fullWidth
                        name="finalInvoiceAmount"
                        onChange={(e)=>{
                            handleCaseCloseInputs(e.target.name,e.target.value)
                         }}
                         ></TextField>
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>SAP Number (Optional)</Typography>
                        <TextField placeholder="Input the SAP number." fullWidth 
                        name="SAPNumber"
                        onChange={(e)=>{
                            handleCaseCloseInputs(e.target.name,e.target.value)
                         }}
                        ></TextField>                        
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:2,mb:1}}>Upload SAP Invoice (Optional)</Typography>
                        {SAPInvoiceFile.length > 0 ?   <>
                        {SAPInvoiceFile.map((value)=>(
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #DBDFE9', borderRadius: "8px", p: 2,width:'100%%', alignItems: 'center', gap: 1 }}>
                                 {value.file.type === 'application/pdf' ? <PdfIcon style={{width: '40px', height: "40px"}} />
                                 : <ImageFormatIcon style={{width: '40px', height: "40px"}} />}

                                <Typography sx={{ color: " #0F2C6F", fontSize: '14px' }}>{value.fileName}</Typography>
                                  <CloseIcon onClick={() => setSAPInvoiceFile([])} cursor='pointer' />
                           </Box>
                        ))} </> :
                        <DropZone customFileFormat={{
                            'image/jpg': [], 
                            'image/jpeg': [], 
                            'image/png': [], 
                            'application/pdf': [], 
                        }} getFiles={fileData => setSAPInvoiceFile(fileData)}
                        singleFile={true}
                        />
                    }

                        </>}
                    </Box>  
                    }
                   
                </Box>  
               
                <Box sx={{display:'flex',justifyContent:"flex-end",p:2}}>
                    <Button onClick={handleUpdate} variant="contained" sx={{textTransform:"capitalize",backgroundColor:"#123FA9",color:'#FFFFFF',borderRadius:"6px"}}>Update</Button>
                </Box>
            </Box>
        </Modal>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        serReqUpdateStatus: state.Service_Req_Reducer.updateStatus,
        techniciansList: state.FaultReportReducer.techniciansList,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateServiceRequestStatus: (data) => (dispatch(serviceRequestAction.updateServiceRequestStatus(data))),
        getTechnicianList: (data) => dispatch(Action.getTechnicianList(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdatePopup);