import { Button, Grid, OutlinedInput, Box } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as superAdminActions from "../../redux/actions/superadminAction";

const Addbuilding = ({ addBuildingStatus, addBuilding }) => {
  const [building, setBuilding] = useState({
    building_name: "",
    address: "",
  });

  const handleBuilding = (e) => {
    const { name, value } = e.target;
    setBuilding({ ...building, [name]: value });
  };

  const buildingSubmit = () => {
    setBuilding({ building_name: "", address: "" });
    addBuilding(building);
  };

  return (
    <>
      <Grid
        container
        direction="row"
        rowSpacing={1}
        justifyContent="center"
        sx={{ m: 3 }}
      >
        <Grid item xs={12} md={4}>
          <OutlinedInput
            placeholder="Building Name"
            type="text"
            value={building.building_name}
            name="building_name"
            onChange={(e) => {
              handleBuilding(e);
            }}
          ></OutlinedInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <OutlinedInput
            placeholder="Building Address"
            type="text"
            value={building.address}
            name="address"
            onChange={(e) => {
              handleBuilding(e);
            }}
          ></OutlinedInput>
        </Grid>
      </Grid>

      <Box sx={{ ml: { xs: 10, sm: 65 } }}>
        <Button variant="contained" onClick={buildingSubmit}>
          Submit
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addBuildingStatus: state.SuperAdmin.addBuildingStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBuilding: (data) => {
      dispatch(superAdminActions.addBuilding(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addbuilding);
