import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { Box, Button, Grid, OutlinedInput, Typography, styled,Dialog,DialogTitle, DialogContent, DialogActions,IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as ServiceAction from "../../redux/actions/service_request/index"
import { deleteLightProduct } from "../../redux/actions/service_request/index";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { makeStyles } from '@mui/styles';
import Close from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
const StyledTypography = styled(Typography)({
    color: '#0F2C6F', 
    fontSize: '16px',
    marginBottom:'8px'
})

const Style = makeStyles({
    input:{
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
})

const AddCategory = ({lightBulbProduct,getLightProductById,addLightProduct,editLightProduct,setServiceRequestStateNull, deleteLightProduct,addFaultCategoryData,addFaultCategory,getFaultCategoryByIdData,geFaultCategoryById, editFaultCategory, editFaultCategoryData})=>{
    const navigate = useNavigate()
    const {id} = useParams()
    const [categoryName,setCategoryName] = useState('')
    const [categoryNameError,setCategoryNameError] = useState('')
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const [openModal, setOpenModal] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSaveClose = () => {
        setOpenSaveModal(false);
    };

    useEffect(()=>{
        if(id!='null'){
            geFaultCategoryById({fault_category_id:id})
        }
    },[])   

    useEffect(()=>{
        console.log('getFaultCategoryByIdData ',getFaultCategoryByIdData)
        if(getFaultCategoryByIdData?.response?.length>0 && id!='null'){
           setCategoryName(getFaultCategoryByIdData?.response[0]?.fault_category_name)
        }
    },[getFaultCategoryByIdData])
console.log('catname ',categoryName)
    useEffect(()=>{
        console.log('categoryData ',addFaultCategoryData)
        if(addFaultCategoryData?.response?.response_status==1){
            console.log('qithin useEffect add ',addFaultCategoryData?.response.message)
            setToastStatus({color:'green',status:true,message:addFaultCategoryData?.response.message,icon:'tick'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
                navigate(-1)
            },3000)
        }else if(addFaultCategoryData?.response_status==0){
            setToastStatus({color:'red',status:true,message:addFaultCategoryData?.response.message,icon:'close'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
            },3000)
        }
    },[addFaultCategoryData])
    console.log('editFaultCategoryData ',editFaultCategoryData)
    useEffect(()=>{
        console.log('editFaultCategoryData ',editFaultCategoryData)
        if(editFaultCategoryData?.response && editFaultCategoryData?.response?.response_status == 1 || editFaultCategoryData?.response&&  editFaultCategoryData?.response?.response_status == 0){
            setToastStatus({color:'green',status:true,message:editFaultCategoryData?.response?.message,icon:'tick'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
                navigate(-1)
            },3000)
        }
        // else if(editFaultCategoryData?.response && editFaultCategoryData?.response && editFaultCategoryData?.response[0]?.response_status ==0){
        //     setToastStatus({color:'red',status:true,message:editFaultCategoryData?.response[0]?.message,icon:'close'})
        //     setTimeout(()=>{
        //         setToastStatus({color:'',status:false})
        //         setServiceRequestStateNull()
        //     },3000)
        // }
    },[editFaultCategoryData])


    const handleData = (e)=>{
        let {value} = e.target
        setCategoryName(value)
        setCategoryNameError('')
    }

    const validate = ()=>{
        let err = false
        if(categoryName ==''){
            setCategoryNameError('Enter Category name')
            err=true
        }
        return err
    }   

    const handleSubmit = ()=>{
        let isError = validate()
        if(!isError){
            if(id=='null'){
               setOpenSaveModal(true)
            }else{
                editFaultCategory({
                    fault_category_id:id,
                    fault_category_name:categoryName
                })
            }
        }
    }

    const tryToDelete = () => {
        console.log('within')
        setOpenModal(true)
    }

    const ModalAlert = ()=> {
        return(
            <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={openModal}
            >
              <DialogTitle sx={{ m: 0, p: 4, fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#0F2C6F' }} id="customized-dialog-title">
              Confirm Delete Category - {categoryName} 
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <DialogContent dividers>
                <Typography gutterBottom>
                    Are you sure you wish to delete this category?
                </Typography>
              </DialogContent>
              <DialogActions sx={{display:'flex', justifyContent:'space-between'}}>
              <Button variant="outlined" onClick={handleClose} sx={{borderRadius:'6px', p:'8px 10px',}}>
                  <Typography sx={{color:'#0F2C6F', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter'}}>Cancel</Typography>
                </Button>
                <Button variant="contained" onClick={()=>{
                    deleteLightProduct({light_bulb_products_id: id})
                    setOpenModal(false)
            }} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#BD0251',"&:hover":{backgroundColor:'#BD0251'}}}>
                <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Delete Now</Typography>
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
        )
    }

    const ModalSaveAlert = ()=> {
        return(
            <React.Fragment>
            <BootstrapDialog
              onClose={handleSaveClose}
              aria-labelledby="customized-dialog-title"
              open={openSaveModal}
            >
                
              <DialogTitle sx={{m: '15px', mt:"40px", mb:0, p: 2, pl:0,  fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#0F2C6F' }} id="customized-dialog-title">
              Confirm additon of new category
              </DialogTitle>
              <Button
                aria-label="close"
                onClick={handleSaveClose}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  color: (theme) => theme.palette.grey[500],
                  minWidth:"auto",
                
                }}
              >
                <Close style={{  border:"2px solid #bdbdbd",}}/>
              </Button>
              <DialogActions style={{padding:"16px"}}  sx={{display:'flex', justifyContent:'space-between'}}>
              <Button variant="outlined" onClick={handleSaveClose} sx={{borderRadius:'6px', p:'8px 10px',}}>
                  <Typography sx={{color:'#0F2C6F', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter'}}>Cancel</Typography>
                </Button>
                <Button variant="contained" onClick={()=>{
                   addFaultCategory({
                    fault_category_name:categoryName,
                    }) 
                    setOpenSaveModal(false)
            }} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}}>
                <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Confirm</Typography>
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
        )
    }

    return(
        <>
        <ModalSaveAlert />
        <ModalAlert />
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:'100% '}}>            
            <Grid item xs={2} />
            <Grid item xs={8} sx={{mt:7}}>
             <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon}  />  
                <BackButton style={{cursor:'hover'}} onClick={()=>navigate(-1)} />
                <Box sx={{my:2}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:"20px"}}>{id !='null' ? 'Edit Category' : 'Add a new category'}</Typography>
                    {/* <Typography sx={{color:'#6F80A9', fontFamily:'Inter', fontWeight:'500', fontSize:'14px', lineHeight:'20px', letterSpacing:'-0.25px'}}>Once product details are updated, it will be reflected in all new service requests.  </Typography> */}
                </Box>
                <Box sx={{mt:3,backgroundColor:"#FFFFFF",p:2}}>
                    <StyledTypography >Category Name</StyledTypography>
                    <OutlinedInput multiline inputProps={{maxLength:50}} fullWidth name="name" value={categoryName} onChange={(e)=>handleData(e)} />
                    <Typography sx={{color:'red'}}>{categoryNameError}</Typography>
                </Box>               
                <Box sx={{display:'flex',justifyContent:"flex-end",mt:5, gap:'15px'}}>
                    {/* {id !='null' && <Button sx={{textTransform:'capitalize'}} variant="outlined" onClick={tryToDelete}>Delete</Button>} */}
                    <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}} variant="contained" onClick={handleSubmit}>{id !='null' ? 'Save' : 'Create'}</Button>
                </Box>
            </Grid>
            <Grid item xs={2} />
        </Grid>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        lightBulbProduct:state.Service_Req_Reducer.lightBulbProduct,
        getFaultCategoryByIdData:state.Service_Req_Reducer.getFaultCategoryByIdData,
        addFaultCategoryData:state.Service_Req_Reducer.addFaultCategory,
        editFaultCategoryData:state.Service_Req_Reducer.editFaultCategoryData
    }

}

const mapDispacthToProps = (dispatch)=>{
    return{
        getLightProductById:(data)=>dispatch(ServiceAction.getLightProductById(data)),
        geFaultCategoryById:(data)=>dispatch(ServiceAction.geFaultCategoryById(data)),
        addFaultCategory:(data)=>dispatch(ServiceAction.addFaultCategory(data)),
        editLightProduct:(data)=>dispatch(ServiceAction.editLightProduct(data)),
        editFaultCategory:(data)=>dispatch(ServiceAction.editFaultCategory(data)),
        setServiceRequestStateNull:()=>dispatch(ServiceAction.setServiceRequestStateNull()),
        deleteLightProduct:(data)=>dispatch(deleteLightProduct(data)),
    }
}
export default connect(mapStateToProps,mapDispacthToProps)(AddCategory)