import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import FaultReports from "./FaultReports";
import { useParams } from 'react-router-dom';
import { connect } from "react-redux";
import * as faultReportAction from '../../redux/actions/faultreportAction'
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography variant="p">{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)(({ theme }) => ({
  color: '#6F80A9',
  textTransform: "none",
  fontSize: "12px",
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
}));

const filterProperties = [
  ['submitted',
    'pending_investigation',
    'complete_investigation',
    'pending_vendor',
    'work_in_progress',
    'work_completed',
    'withdraw',
    'require_more_data',
    'require_more_data_updated'],
  ['closed', 'rejected'],
  []
]

const  FaultReportTabs = ({ tabValue, faultLocations,getActiveStatus,getResolvedStatus,activeStatus,resolvedStatus })=> {
  const [value, setValue] = useState(tabValue);
  const [authData, setAuthData] = useState({})
  const [active,setActive] = useState([])
  const [resolved,setResolved] = useState()
  const [filterStatus, setFilterStatus] = useState([])
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if(newValue==0){
       setFilterStatus([...active])
    }else{
      setFilterStatus([...resolved])
    }
  };

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem('userData'))
    setAuthData(localData)
    getResolvedStatus()
    getActiveStatus()
  }, [])

  useEffect(()=>{
    setActive(activeStatus)
    setFilterStatus([...activeStatus])
  },[activeStatus])

  useEffect(()=>{
    setResolved(resolvedStatus)
  },[resolvedStatus])

  const renderUserTabs = () => {
    if (authData.role === 'Tenant Manager') {
      return (
        <>
          <Box
            sx={{ background: "white", borderBottom: "1px solid #DBDFE9", borderRadius: "8px 8px 0px 0px", mb: 1 }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { background: "#123FA9" } }}
            >
              <StyledTab
                label="Active"
                {...a11yProps(0)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
              <StyledTab
                label="Resolved"
                {...a11yProps(1)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
              <StyledTab
                label="Drafts"
                {...a11yProps(2)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} style={{ background: "white" }}>
            <FaultReports activeType='all' tabValue={value} filterSources={filterStatus} faultLocations={faultLocations} />
          </TabPanel>
          <TabPanel value={value} index={1} style={{ background: "white" }}>
            <FaultReports activeType='closed' tabValue={value} filterSources={filterStatus} faultLocations={faultLocations} />
          </TabPanel>
          <TabPanel value={value} index={2} style={{ background: "white" }}>
            <FaultReports activeType='draft' tabValue={value} faultLocations={faultLocations} />
          </TabPanel>
        </>
      )
    } else {
      return <>
        <Box
          sx={{ borderColor: "divider", background: "white", borderBottom: "1px solid #DBDFE9", borderRadius: "8px 8px 0px 0px", mb: 1 }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { background: "#123FA9" } }}
          >
            <StyledTab
              label="Active"
              {...a11yProps(0)}
              sx={{
                textTransform: "none",
                color: "#123FA9",
                fontSize: "16px",
              }}
            />
            <StyledTab
              label="Resolved"
              {...a11yProps(1)}
              sx={{
                textTransform: "none",
                color: "#123FA9",
                fontSize: "16px",
              }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} style={{ background: "white" }}>
          <FaultReports activeType='all' tabValue={value} filterSources={filterStatus} faultLocations={faultLocations} />
        </TabPanel>
        <TabPanel value={value} index={1} style={{ background: "white" }}>
          <FaultReports activeType='closed' tabValue={value} filterSources={filterStatus} faultLocations={faultLocations} />
        </TabPanel>
      </>
    }
  }

  return (
    <Box sx={{mt:4}}>
      {renderUserTabs()}
    </Box>
  );
}

const mapStateToProps = (state)=>{
  return{
    activeStatus:state.FaultReportReducer.activeFilterStatus,
    resolvedStatus:state.FaultReportReducer.resolvedFilterStatus
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
    getActiveStatus:()=>dispatch(faultReportAction.getFaultActiveStatus()),
    getResolvedStatus:()=>dispatch(faultReportAction.getFaultResolvedStatus()),

  }
}

export default connect(mapStateToProps,mapDispatchToProps)(FaultReportTabs)