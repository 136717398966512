export const countryCode = [
    {
      "93":"13",
    },
    {   
      "355":"12",
    },
    {
      "213":"12",
    },
    {
      "376":"12",
    },
    {
      "244":"12",
    },
    {
      "1268":"11",
    },
    {
      "54":"10",
    },
    {
      "374":"8",
    },
    {
      "297":"12"
    },
    {
      "61":"10",
    },
    {
      "43":"13",
    },
    {
      "994":"9",
    },
    {
      "1242":"11",
    },
    {
      "973":"12",
    },
    {
      "880":"12",
    },
    {
      "1246":"11",
    },
    {
      "375":"9",
    },
    {
      "32":"9",
    },
    {
      "501":"12",
    },
    {
      "229":"12",
    },
    {
      "975":"12",
    },
    {
      "591":"12",
    },
    {
      "387":"12",
    },
    {
      "267":"12",
    },
    {
      "55":"11",
    },
    {
      "246":"12",
    },
    {
      "673":"12",
    },
    {
      "359":"12",
    },
    {
      "226":"12",
    },
    {
      "257":"12",
    },
    {
      "855":"12",
    },
    {
      "237":"12",
    },
    {
      "1":"10",
    },
    {
      "238":"12",
    },
    {
      "599":"12",
    },
    {
      "236":"12",
    },
    {
      "235":"12",
    },
    {
      "56":"13",
    },
    {
      "86":"11",
    },
    {
      "57":"10",
    },
    {
      "269":"12",
    },
    {
      "243":"12",
    },
    {
      "242":"12",
    },
    {
      "506":"8",
    },
    {
      "225":"8",
    },
    {
      "385":"12",
    },
    {
      "53":"13",
    },
    {
      "599":"12",
    },
    {
      "357":"8",
    },
    {
      "420":"12",
    },
    {
      "45":"8",
    },
    {
      "253":"12",
    },
    {
      "1767":"11",
    },
    {
      "1":"14",
    },
    {
      "593":"12",
    },
    {
      "20":"13",
    },
    {
      "503":"8",
    },
    {
      "240":"12",
    },
    {
      "291":"12",
    },
    {
      "372":"10",
    },
    {
      "251":"12",
    },
    {
      "679":"12",
    },
    {
      "358":"9",
    },
    {
      "33":"9",
    },
    {
      "594":"12",
    },
    {
      "689":"12",
    },
    {
      "241":"12",
    },
    {
      "220":"12",
    },
    {
      "995":"12",
    },
    {
      "49":"12",
    },
    {
      "233":"12",
    },
    {
      "30":"12",
    },
    {
      "1473":"12",
    },
    {
      "590":"12",
    },
    {
      "1671":"12",
    },
    {
      "502":"8",
    },
    {
      "224":"12",
    },
    {
      "245":"12",
    },
    {
      "592":"12",
    },
    {
      "509":"8",
    },
    {
      "504":"12",
    },
    {
      "852":"8",
    },
    {
      "36":"13",
    },
    {
      "354":"7",
    },
    {
      "91":"10",
    },
    {
      "62":"12",
    },
    {
      "98":"10",
    },
    {
      "964":"12",
    },
    {
      "353":"9",
    },
    {
      "972":"10",
    },
    {
      "39":"10",
    },
    {
      "1876":"12",
    },
    {
      "81":"10",
    },
    {
      "962":"12",
    },
    {
      "7":"10",
    },
    {
      "254":"12",
    },
    {
      "686":"12",
    },
    {
      "383":"12",
    },
    {
      "965":"12",
    },
    {
      "996":"9",
    },
    {
      "856":"12",
    },
    {
      "371":"8",
    },
    {
      "961":"12",
    },
    {
      "266":"12",
    },
    {
      "231":"12",
    },
    {
      "218":"12",
    },
    {
      "423":"12",
    },
    {
      "370":"12",
    },
    {
      "352":"12",
    },
    {
      "853":"12",
    },
    {
      "389":"12",
    },
    {
      "261":"12",
    },
    {
      "265":"12",
    },
    {
      "60":"10",
    },
    {
      "960":"12",
    },
    {
      "223":"12",
    },
    {
      "356":"12",
    },
    {
      "692":"12",
    },
    {
      "596":"12",
    },
    {
      "222":"12",
    },
    {
      "230":"12",
    },
    {
      "52":"10",
    },
    {
      "691":"12",
    },
    {
      "373":"8",
    },
    {
      "377":"12",
    },
    {
      "976":"12",
    },
    {
      "382":"12",
    },
    {
      "212":"12",
    },
    {
      "258":"12",
    },
    {
      "95":"12",
    },
    {
      "264":"12",
    },
    {
      "674":"12",
    },
    {
      "977":"12",
    },
    {
      "31":"10",
    },
    {
      "687":"12",
    },
    {
      "64":"10",
    },
    {
      "505":"12",
    },
    {
      "227":"12",
    },
    {
      "234":"12",
    },
    {
      "850":"12",
    },
    {
      "47":"8",
    },
    {
      "968":"12",
    },
    {
      "92":"10",
    },
    {
      "680":"12",
    },
    {
      "970":"12",
    },
    {
      "507":"12",
    },
    {
      "675":"12",
    },
    {
      "595":"12",
    },
    {
      "51":"12",
    },
    {
      "63":"11",
    },
    {
      "48":"9",
    },
    {
      "351":"12",
    },
    {
      "1":"14",
    },
    {
      "974":"12",
    },
    {
      "262":"12",
    },
    {
      "40":"12",
    },
    {
      "7":"10",
    },
    {
      "250":"12",
    },
    {
      "1869":"12",
    },
    {
      "1758":"12",
    },
    {
      "1784":"12",
    },
    {
      "685":"12",
    },
    {
      "378":"12",
    },
    {
      "239":"12",
    },
    {
      "966":"12",
    },
    {
      "221":"12",
    },
    {
      "381":"12",
    },
    {
      "248":"12",
    },
    {
      "232":"12",
    },
    {
      "65":"8",
    },
    {
      "421":"12",
    },
    {
      "386":"12",
    },
    {
      "677":"12",
    },
    {
      "252":"12",
    },
    {
      "27":"12",
    },
    {
      "82":"11",
    },
    {
      "211":"12",
    },
    {
      "34":"9",
    },
    {
      "94":"12",
    },
    {
      "249":"12",
    },
    {
      "597":"12",
    },
    {
      "268":"12",
    },
    {
      "46":"9",
    },
    {
      "41":"9",
    },
    {
      "963":"12",
    },
    {
      "886":"12",
    },
    {
      "992":"12",
    },
    {
      "255":"12",
    },
    {
      "66":"12",
    },
    {
      "670":"12",
    },
    {
      "228":"12",
    },
    {
      "676":"12",
    },
    {
      "1868":"12",
    },
    {
      "216":"12",
    },
    {
      "90":"10",
    },
    {
      "993":"12",
    },
    {
      "688":"12",
    },
    {
      "256":"12",
    },
    {
      "380":"9",
    },
    {
      "971":"12",
    },
    {
      "44":"10",
    },
    {
      "1":"10",
    },
    {
      "598":"12",
    },
    {
      "998":"9",
    },
    {
      "678":"12",
    },
    {
      "39":"10",
    },
    {
      "58":"12",
    },
    {
      "84":"12",
    },
    {
      "967":"12",
    },
    {
      "260":"12",
    },
    {
      "263":"12",
    }
  ]