import React from "react";
import { Route, Routes } from "react-router-dom";
import FaultReporting from "../Pages/FaultReporting";
import Faults from "../Pages/FaultReporting/Faults";
import ManageTenant from "../Pages/ManageTenant";
import TenantDetails from "../Pages/ManageTenant/TenantDetails";
import OnBoardBatch from "../Pages/NewManageEmployees/OnBoardBatch";
import OnBoardSingle from "../Pages/NewManageEmployees/OnBoardSingle";
import ServiceRequestList from "../Pages/ServiceRequest/Index.js";
import Announcement from "../Pages/Announcement/Index.js";
import OnboardingNewCompany from "../Pages/ManageTenant/OnboardingNewCompany";
import SeasonParking from "../Pages/SeasonParking/Index";
import ErrorPage from "../components/LandingPage/ErrorPage";
import Header from "../Header";
import SocialImpact from "../Pages/SocialImpact.js/Index";
import ReviewReport from "../Pages/FaultReporting/ReviewReport";
import ReviewTenant from "../Pages/ManageTenant/reviewTenant";
import ReviewEmployeeList from "../Pages/NewManageEmployees/ReviewEmployeeList";
import SingleEmployeeReview from "../Pages/NewManageEmployees/SingleEmployeeReview";
import ReviewRequest from "../Pages/ServiceRequest/ReviewRequests";
import NewAnnouncement from "../Pages/Announcement/NewAnnouncement";
import BmsCoordinatorDashboard from "../Pages/Dashboard/Bms Coordinator";
import Announcementnotification from "../Pages/common/HeaderNotification";
import HeplAndSupport from "../Header/HelpAndSupport";
import Reports from "../Pages/Reports/index";
import ReportBugs from "../Header/ReportBugs";
import ManageFacility from "../Pages/ManageFacility/index.js";
import TenantVisitors from "../Pages/TenantVisitors/index.js";
import ReviewInvite from "../Pages/TenantVisitors/ReviewInvite.js";
import BatchInvitation from "../Pages/TenantVisitors/BatchInvitation.js";
import InviteViaExcel from "../Pages/TenantVisitors/InviteViaExcel.js";
import PaymentConfirmation from "./PaymentConfirmation.js";
import FaciliyBooking from "../Pages/FacilityBooking/index.js";
import BookingSelection from "../Pages/FacilityBooking/BookingSelection.jsx";
import SingleRoomFeature from "../Pages/FacilityBooking/SingleRoomFeature.jsx";
import ConfirmedBooking from "../Pages/FacilityBooking/ConfirmedBooking.jsx";

const BmsCoordinator = () => {
  return (
    <React.Fragment>
      <Header>
        <Routes>
          <Route path="/dashboard" element={<BmsCoordinatorDashboard />} />
          <Route path="/review_tenant_details/:id/:activeType" element={<ReviewTenant />} />
           <Route path="/manage_faults/:tabvalue" element={<FaultReporting />} />    
          {/* <Route path="/report_fault" element={<Faults />} /> */}
          <Route path="/review_fault/:id/:tabValue/:fromDashboard" element={<ReviewReport />} />
         
          <Route path="/onboard_new_tenant/:empValue/:nestedTabValue" element={<ManageTenant />} />
          <Route
            path="/onboard_new_tenant_company"
            element={<OnboardingNewCompany />}
          />
          <Route path="/tenant_details" element={<TenantDetails />} />
          <Route path="/single_employee_onboard" element={<OnBoardSingle />} />
          <Route path="/batch_onboarding" element={<OnBoardBatch />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path='/new_announcement' element={<NewAnnouncement/>} />
          <Route path="/season_parking" element={<SeasonParking />} />
          <Route path="/social_impact" element={<SocialImpact />} />
          <Route path='/review_employee_list/:emp_id/:tabValue/:fromBms' element={<ReviewEmployeeList/>} />
          <Route path='/single_employee_review/:emp_id/:activeType' element={<SingleEmployeeReview/>} />
          <Route path="/service_request_list/:tab_id" element={<ServiceRequestList />} />
          <Route path="/review_service_request/:service_req_id/:service_req_type_id/:tab_id/:fromDashboard" element={<ReviewRequest />} />
          <Route path="/reports/:isAccess" element={<Reports />} />
          <Route path="/notifications" element={<Announcementnotification />} />
          <Route path="/helpAndSupport" element={<HeplAndSupport/>} />
          <Route path="/reportbug" element={<ReportBugs/>} />
          <Route path="/manage_facility" element={<ManageFacility />} />
          <Route path="/report_fault/:isDraft/:id" element={<Faults />} />
          <Route path="/tenant_visitors/:tabValue" element={<TenantVisitors/>} />
          <Route path="/review_tenant_inivte/:tabValue/:invite_id/:isEdit" element={<ReviewInvite/>} />
          <Route path="/batch_invitation" element={<BatchInvitation/>} />
          <Route path="/batch_invitation_excel" element={<InviteViaExcel  />} />
          <Route path="/facility_booking" element={<FaciliyBooking />} />
          <Route path="/booking_selection" element={<BookingSelection />} />
          <Route path='/single_room_feature/:feature_id' element={<SingleRoomFeature />} />
          <Route path="/facility_booking/:facility_id" element={<ConfirmedBooking />} />
          <Route path="/payment_response" element={<PaymentConfirmation />} />   
          <Route path="/my_faults/:tabvalue" element={<FaultReporting />} />  
          <Route path="*" element={<ErrorPage />} />
         </Routes>
      </Header>
    </React.Fragment>
  );
};

export default BmsCoordinator;
