import { Grid, Typography, Button } from '@mui/material'
import { ReactComponent as BackButton } from '../../Assets/icons/back.svg'
import { useNavigate } from 'react-router-dom'
import { Link,useParams } from "react-router-dom";
import React, { useState } from 'react'
import { Box } from "@mui/system";
import Important from './ImportantContacts'
import HandBook from './HandBook';

const TAB_HEADINGS = [
  {
    id: 0,
    name: "Important Contacts",
  },
  {
    id: 1,
    name: "Tenant Handbook",
  },
]
const Information = () => {
  const navigate = useNavigate()
const {tabValue} = useParams();
const [value,setValue]=useState(parseInt(tabValue))
const handleChange = (event, newValue) => {
  setValue(newValue);
};
  return (
    <>
      <Grid
        container
        sx={{ paddingTop: { md: '60px', xs: '20px' }, background: '#F9FAFA',minHeight:'100%' }}
      >
        <Grid item lg={2} md={1} sm={1} xs={0.5} />
        <Grid
          item
          sx={{ display: 'flex', flexDirection: 'column', color: '#0F2C6F' }}
          lg={8}
          md={10}
          sm={10}
          xs={11}
        >
          <BackButton
            onClick={() => {
              navigate(-1)
            }}
            cursor="pointer"
          />

          <Typography
            sx={{
              color: '#002D74',
              margin: '30px 0',
              fontSize: '26px',
              fontWeight: 500,
              height: '32px',
            }}
          >
            Manage Useful Information/Help
          </Typography>
          <Typography sx={{ fontSize: '14px',color:'#6F80A9' }}>
            You may update important contacts & upload files for users to access
            the tenant's handbook.
          </Typography>
        
        {/* tabs */}
        
      <Box>
        <Box sx={{ width: "100%", display: "flex", marginTop: "50px" }}>
          {TAB_HEADINGS.map((item, index) => {
            return (
              <Button
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: { md: "14px", xs: "12px" },
                  color: index === value ? "#0F2C6F" : "#9AA6C3",
                  background: index === value ? "#fff" : "#F3F4F8",
                  width: "190px",
                  boxShadow: "none",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: index === value ? "white" : "#e0e0e0",
                    boxShadow: "none",
                  },
                  padding: "13px 0",
                }}
                variant="contained"
                onClick={(e) => handleChange(e, index)}
                key={index}
              >
                {item.name}
              </Button>
            );
          })}
        </Box>
      </Box>
      <Box>
        {value === 0 && <Important />} 
        {value===1 && <HandBook/>}
      </Box>
        </Grid>
      </Grid>
    </>
  )
}
export default Information
