import { Box, Button, Checkbox, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import {ReactComponent as BackIcon} from '../../Assets/icons/back.svg';
import {ReactComponent as CheckboxIcon} from '../../Assets/icons/CheckboxTerms.svg';
import {ReactComponent as CloseIcon} from '../../Assets/icons/Alertclose.svg';
import {ReactComponent as RefreshIcon} from '../../Assets/icons/Refresh.svg';
import {ReactComponent as PdfIcon} from '../../Assets/icons/PDF.svg';
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import DropZone from "../../Utils/dropzone";
import * as ImportantContactAction from '../../redux/actions/ImportantContactAction/index';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "../common/faultalert";
import { getDateTimeFormat, getLastUpdatedTime, preventDatePickerKeyboard } from "../../Utils/utils";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";

const StyledTypography = styled(Typography)({
    fontSize:'14px',
    color:"#0F2C6F",
    fontWeight:500
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

const confirmData = {
    label:'Confirm New Handbook post',
    description:"Please ensure all details are entered correctly to prevent any delay to processing of request. Requests are subjected to Singapore Land Tower’s building management approval.",
    buttonvalue:"Submit"
}

const discardData = {
    label:"Discard the handbook",
    description:"Are you sure to delete this handbook ",
    buttonvalue:"Discard"
}

const UploadHandBook = ({getHbReason,handbookReason,createHb,createHandbookStatus,setUpdateImportantContact})=>{
    const navigate = useNavigate();
    const [lastUpdatedTime,setLastUpdatedTime] = useState();
    const [hours,setHours] = useState([]);
    const [details,setDetails] = useState({reason:"",description:"",postsettings:false,startDate:null,startTime:"",app:false,dashboard:false})
    const [errors,setErrors] = useState({});
    const [files,setFiles] = useState([]);
    const [alertStatus,setAlertStatus] = useState({publish:false,discard:false});
    const [toastStatus,setToastStatus] = useState({message:"",status:false,color:""})
    const [loaderStatus,setLoaderStatus] = useState(false)
    let date = new Date()
    
    useEffect(()=>{
        getLiftStartHours(date.getHours());
        getHbReason()
        setLastUpdatedTime(getLastUpdatedTime())
    },[])

    useEffect(()=>{
        let startDate = new Date(details.startDate)
        if(startDate>date){
            getLiftStartHours(0);
        }else{
            getLiftStartHours(date.getHours());
        }
        setDetails({
            ...details,
            startTime:''
        })
    },[details.startDate])
    
    useEffect(()=>{
        setToastStatus()
       if(createHandbookStatus?.response_status=='success'){
            setLoaderStatus(false)
            setToastStatus({message:createHandbookStatus?.response_message,status:true,color:"green"})
            setTimeout(()=>{
                setToastStatus()
                setUpdateImportantContact()
                navigate('/information/1')
            },5000)
       }else if(createHandbookStatus?.response_status=='fail'){
            setLoaderStatus(false)
            setToastStatus({message:createHandbookStatus?.response_message,status:true,color:"red"})
            setTimeout(()=>{
                setToastStatus()
                setUpdateImportantContact()
            },5000)
       }
    },[createHandbookStatus])

    const getLiftStartHours = (minTime)=>{
        let timeIntrevels=[]
        for(let i=minTime;i<24;i++){
           for(let j=1;j<=2;j++){
               if(j%2==0){
                timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
               }else{
                timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
               }
           }  
       };
       setHours([...timeIntrevels])
   }

   const handleDetails = (e,datafrom)=>{
    if(datafrom=="startDate"){
            let Date = e.getDate() <= 9 ? `0${e.getDate()}` : e.getDate();
            let Month = e.getMonth() <= 9 ? `0${e.getMonth() + 1}` : e.getMonth() + 1;
            let Year = e.getFullYear();
            let date = `${Month}/${Date}/${Year}`;
        setDetails({
            ...details,
            startDate:date,
            date:e
        })
        setErrors({
            ...errors,
            startDate:""
        })
    }else{
        let {name,value} = e.target;
        setDetails((prev)=>({
            ...prev,
            [name]:value
        }))
        setErrors({
            ...errors,
            [name]:""
        })
    }
   }

   const handleErrors = ()=>{
    let err = true;
    if(!details.reason){
        setErrors((prev)=>({
            ...prev,
            ["reason"]:"Please fill out this field "
        }))
        err=false;
    }
    if(!details.description){
        setErrors((prev)=>({
            ...prev,
            ["description"]:"Please fill out this field"
        }))
        err=false;
    }
    if(details.postsettings && !details.startDate){
        setErrors((prev)=>({
            ...prev,
            ["startDate"]:"Select Start Date"
        }))
        err=false;
    }
    if(details.postsettings && !details.startTime){
        setErrors((prev)=>({
            ...prev,
            ["startTime"]:"Select Start Time"
        }))
        err=false;
    }
    if(!files.length>=1){
        setErrors((prev)=>({
            ...prev,
            ["handbook"]:"No file chosen"
        }))
        err=false;
    }
    if(!details.app && !details.dashboard){
        setErrors((prev)=>({
            ...prev,
            ["platform"]:"Please select at least one if you want to proceed"
        }))
        err=false;
       }
    return err;
   }
   
   const handlePublish = ()=>{
        let valid = handleErrors();
        if(valid){
            setAlertStatus({
                ...alertStatus,
                publish:true
            })
        }
   }

   const confirmFunc = ()=>{
        let submitDate;
        if(details.postsettings){
            let time = details.startTime.split(":")
             submitDate= new Date(details.date.getFullYear(),details.date.getMonth(),details.date.getDate())
             submitDate.setHours(time[0])
             submitDate.setMinutes(time[1])
        }
      
       
        let data={
            reason_id:details.reason.id,
            notes:details.description,
            triggerStatus:details.postsettings ? 2 : 1,
            platform:platformDetails(),
            publish_time:details.postsettings ? getDateTimeFormat(submitDate): "",
            attachments:files[0].file
        }
        createHb(data)
        setLoaderStatus(true)
        setTimeout(()=>{
            setLoaderStatus(false)
        },15000)
        setAlertStatus()
   }

   const platformDetails = ()=>{
    if(details.app && !details.dashboard){
        return 1
    }else if(details.dashboard && !details.app){
        return 2
    }else if(details.app && details.dashboard){
        return 3
    }else{
        return null
    }
   }

    const uploadFile = (file) => {
        setFiles([file[0]]);
        setErrors((prev)=>({
            ...prev,
            handbook:""
        }))
    }

    const handleDeleteFiles=(id)=>{
        const filter = files.filter((value)=>{
          return value.id !==id
        })
        setFiles([...filter])
      }
    
    const handleUploadButton = (e) => {
        let target = e.target.files;
        let arr = files;
        let length = files.length+1;
        
        for (let i = 0; i < target.length; i++) {
            arr.push({ id:length,fileName: target[i].name, size: target[i].size, file: target[i], src: URL.createObjectURL(target[i]) });
            length+=1;
        }
        setFiles([...arr])
    }

   const handleDiscard = ()=>{
        setDetails({reason:"",description:"",postsettings:false,startDate:null,startTime:"",app:false,dashboard:false})
        setFiles([])
    }
    return(
        <>
        {loaderStatus && <Loader status={loaderStatus} />}
        {alertStatus?.publish && <Alert closeFunc={setAlertStatus} confirmFunc={confirmFunc} alertData={confirmData} />}
        {toastStatus?.status && <ToastAlert message={toastStatus.message} status={toastStatus.status} color={toastStatus.color} />}
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}}>
            <Grid item xs={1.5} />

            <Grid item xs={9}>
                <Box sx={{mt:8,mb:2}}>
                    <BackIcon style={{cursor:"pointer"}} onClick={()=>navigate(-1)} />
                    <Typography sx={{color:"#002D74",fontSize:'26px',fontWeight:500,mt:1}}>Upload Handbook</Typography>
                </Box>
                <Box sx={{backgroundColor:"#FFFFFF",p:2.5}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:"18px",fontWeight:500}}>Reason for updating of document</Typography>
                    <Box sx={{my:4}}>
                        <StyledTypography>Changes Made</StyledTypography>
                        <Select onChange={(e)=>handleDetails(e)} value={details.reason} name="reason"  sx={{width:"50%",mt:1,color:'#0F2C6F'}}>
                            {handbookReason?.map((value)=>(
                                <MenuItem value={value}>{value.name}</MenuItem>
                            ))}
                        </Select>
                    {errors.reason && <Typography sx={{color:"#f44336"}}>{errors.reason}</Typography> }
                    </Box>
                    <Box>
                        <StyledTypography>Where are the changes made to the document? (Please indicate appendix & page number)</StyledTypography>
                        <OutlinedInput onChange={(e)=>handleDetails(e)} value={details.description} name="description" inputProps={{maxLength:300}} sx={{width:"60%",mt:1,color:"#0F2C6F"}}/>
                        <Typography sx={{color:'#9AA6C3',fontSize:"14px",mt:1}}>[{details.description.length}/300]</Typography>
                    {errors.description && <Typography sx={{color:"#f44336"}}>{errors.description}</Typography> }
                    </Box>
                    <Box sx={{display:'flex',flexDirection:"column",my:4}}>
                        <Box sx={{display:'flex'}}>
                            <StyledTypography>Post Setting</StyledTypography>
                            <Box sx={{ml:3}}>
                            <StyledTypography>  <Checkbox checked={details.postsettings} onClick={()=>setDetails({
                                ...details,
                                postsettings:!details.postsettings
                            })} icon={<CheckboxIcon/>} sx={{display:'inline'}} />Publish Later</StyledTypography>
                            </Box>
                        </Box>
                        <Box sx={{display:'flex',mt:2}}>
                        <StyledTypography sx={{mr:6}}>Platform</StyledTypography>
                            <StyledTypography><Checkbox checked={details.app} onClick={()=>{
                                setDetails({
                                    ...details,
                                    app:!details.app
                                })
                                setErrors((prev)=>({
                                    ...prev,
                                    platform:""
                                }))
                                }} sx={{display:'inline'}} icon={<CheckboxIcon/>}/>App</StyledTypography>
                            <StyledTypography><Checkbox checked={details.dashboard} onClick={()=>{
                                setDetails({
                                    ...details,
                                    dashboard:!details.dashboard
                                })
                                setErrors((prev)=>({
                                    ...prev,
                                    platform:""
                                }))
                            }} sx={{display:'inline'}} icon={<CheckboxIcon/>}/>Dashboard</StyledTypography>
                        </Box>
                    {errors.platform && <Typography sx={{color:"#f44336"}}>{errors.platform}</Typography> }
                        
                    </Box>
                    {details.postsettings && <Grid container>
                        <Grid item xs={6} sx={{display:"flex"}}>
                            <Box>
                                <StyledTypography sx={{mb:1}}>Start Date</StyledTypography>
                                <DatePicker inputFormat="dd/MM/yyyy" components={{
                                    OpenPickerIcon:DatePickerIcon
                                }} disablePast onChange={(e)=>handleDetails(e,"startDate")} name="startDate" value={details.startDate} renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                                {errors.startDate && <Typography sx={{color:"#f44336"}}>{errors.startDate}</Typography> }
                            </Box>
                            <Box sx={{ml:2}}>
                                <StyledTypography sx={{mb:1}}>Start time</StyledTypography>
                                <Select sx={{color:"#0F2C6F"}} onChange={(e)=>handleDetails(e)} name="startTime" value={details.startTime} MenuProps={MenuProps} fullWidth>
                                    {hours?.map((value,index)=>(
                                      (new Date(details.startDate)>date || index!=0) && <MenuItem value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                                {errors.startTime && <Typography sx={{color:"#f44336"}}>{errors.startTime}</Typography> }
                            </Box>
                            {/* <Box>
                                <StyledTypography>End Date</StyledTypography>
                                <DatePicker renderInput={(params)=><TextField {...params} />} />
                            </Box> */}
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5} sx={{display:"flex",flexDirection:'column'}}>
                        {/* <StyledTypography>Platform</StyledTypography>
                            <Typography><Checkbox icon={<CheckboxIcon/>}/>App</Typography>
                            <Typography><Checkbox icon={<CheckboxIcon/>}/>Dashboard</Typography> */}
                            {/* <Box>
                                <StyledTypography>Start time</StyledTypography>
                                <Select MenuProps={MenuProps}>
                                    {hours?.map((value)=>(
                                        <MenuItem value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Box> */}
                            {/* <Box>
                                <StyledTypography>End time</StyledTypography>
                                <Select MenuProps={MenuProps}>
                                    {hours?.map((value)=>(
                                        <MenuItem value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Box> */}
                        </Grid>
                    </Grid>
                    }
                    <Box sx={{my:3}}>
                        <Typography sx={{color:' #0F2C6F',fontSize:'18px',mb:1}}>Upload New Handbook</Typography>
                        {files.length > 0  ? 
                        <>
                        {files.map((value)=>(
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #DBDFE9', borderRadius: "8px", mt: 2, p: 2,width:'60%' }}>
                                <PdfIcon />
                                <Typography sx={{ color: " #0F2C6F", fontSize: '18px',mt:5 }}>{value.fileName}</Typography>
                                  <CloseIcon onClick={() => handleDeleteFiles(value.id)} />
                           </Box>
                        ))}
                        {/* <Box sx={{display:"flex",justifyContent:"flex-end",mt:2}}>
                            <Button  variant="outlined" endIcon={<Fileupload></Fileupload>}  sx={{color: '#0F2C6F', mt: 2, textTransform: 'capitalize'}}  component="label"> <input multiple  onChange={(e)=>handleUploadButton(e)} type="file" style={{display:"none"}} />Upload</Button>
                        </Box> */}
                        </> : <DropZone onlyPdf={true} singleFile={true} getFiles={uploadFile} />
                       }
                        {errors.handbook && <Typography sx={{color:"#f44336",display:"flex",justifyContent:"center",mt:1}}>{errors.handbook}</Typography> }
                    </Box>
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",my:4}}>
                    <Typography sx={{color:"#9AA6C3",fontSize:"14px"}}> <RefreshIcon style={{display:"inline",marginRight:"5px"}} /> Last Updated at {lastUpdatedTime}</Typography>
                    <Box>
                            <Button
                            variant="outlined"
                            sx={{
                            textTransform: 'none',
                            backgroundColor:'#FFFFFF',
                            color: '#0F2C6F',
                            fontSize: { md: '14px', xs: '12px' },
                            padding: { md: '10px', xs: '5px' },
                            fontWeight: 600,
                            }}
                            onClick={()=>handleDiscard()}
                        >
                            Clear
                        </Button>

                        <Button
                            sx={{
                            background: '#123FA9',
                            fontSize: { md: '14px', xs: '12px' },
                            padding: { md: '10px', xs: '5px' },
                            textTransform:"capitalize",
                            ml:1,
                            color: '#FFFFFF',
                            '&:hover': {
                                background: '#123FA9',
                            },
                            }}
                            onClick={()=>handlePublish()}
                        >
                            Publish
                        </Button>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={1.5} />
        </Grid>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        handbookReason:state.ImportantContactReducer.handbookReason,
        createHandbookStatus:state.ImportantContactReducer.createHandbookStatus
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getHbReason:()=>(dispatch(ImportantContactAction.getHbReason())),
        createHb:(data)=>(dispatch(ImportantContactAction.createHb(data))),
        setUpdateImportantContact:()=>(dispatch(ImportantContactAction.setUpdateImportantContact())),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(UploadHandBook);