import { Box, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg'
import { ReactComponent as AddIcon } from '../../Assets/icons/WhiteRoundedPlusIcon.svg'
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg'
import { useNavigate, useParams } from "react-router-dom";
import { getLoggedUserData, getYYYYMMDDFormat, MenuProps, preventDatePickerKeyboard } from "../../Utils/utils";
import { connect } from "react-redux";
import * as serviceRequestAction from "../../redux/actions/service_request";
import ServiceRequestList from "./ServiceRequestList";
import { DatePicker } from "@mui/x-date-pickers";
import { getStatus } from "../../Utils/ActiveStatus";
import ToastAlert from '../common/Alert';

const TabPanel = ({ children, value, index }) => {
  return (
    <>
      {value === index ? <Box>{children}</Box> : ''}
    </>
  )
}

const ServiceRequest = ({ serviceRequestLists, getServiceRequestsList, setServiceRequestStateNull, totalRecords, serviceRequestTypes, getServiceRequestTypes,updateWatchStatus,getServiceFilter,serviceFilter,getServiceActiveStatus,getServiceInActiveStatus,activeFilterStatus,inactiveFilterStatus }) => {
  let { user_id, role_id } = getLoggedUserData();
  let { tab_id } = useParams();
  const navigate = useNavigate();
  const [serviceRequestData, setServiceRequestDats] = useState([])
  const [value, setValue] = useState(parseInt(tab_id));
  const [currentTabName, setCurrentTabName] = useState('')
  const [filterData, setFilterData] = useState({ search: '', serviceType: '', serviceStatus: '', date: null })
  const [currentPage, setPage] = useState(1)
  const [toastAlert,setToastAlert] = useState({message:"",status:false,color:""})
  const [sortapiData,setSortApiData] = useState({type:'',column_name:''})

  const handleValue = (event, newvalue) => {
    handleReset()
    setValue(newvalue)
    setServiceRequestStateNull()
    setCurrentTabName(event.target.name)
  }

  useEffect(() => {
      getServiceRequestTypes()
      getServiceFilter()
      getServiceActiveStatus()
      getServiceInActiveStatus()        
      return ()=>{
        setServiceRequestStateNull()
      }

  }, [])

  useEffect(() => {
    let timer;
    
    timer = setTimeout(() => 
    refreshData(), 1000);
    return ()=>{
      setServiceRequestStateNull()
      clearTimeout(timer);
    }
  }, [filterData,sortapiData])

  useEffect(()=>{
     fetchDataPagignation(currentPage)
    if(updateWatchStatus=="Service Request watch updated successfully"){
      setToastAlert({message:updateWatchStatus,status:true,color:"green"})
      setTimeout(()=>{
        setToastAlert({status:false,message:"",color:""})
        setServiceRequestStateNull()
      },5000)
    }else if(updateWatchStatus!=null){
      setToastAlert({message:updateWatchStatus,status:true,color:"red"})
      setTimeout(()=>{
        setToastAlert({status:false,message:"",color:""})
        setServiceRequestStateNull()
      },5000)
    }
  },[updateWatchStatus])

  useEffect(() => {
    // if (serviceRequestLists.length > 0) {
    setServiceRequestDats(serviceRequestLists)
    // }
  }, [serviceRequestLists])

  const getCurrentStatus = ()=>{
    if(role_id===3|| role_id===2 || role_id===14){
        if(currentTabName=="closed"){
          return "closed"
        }else if(tab_id==0){
          return "all"
        }else if(!currentTabName && tab_id==1){
          return "closed"
        }
    }else{
      if(currentTabName=="draft"){
        return "draft"
      }else if(currentTabName=="archived"){
        return "archived"
      }else if(tab_id==0 || currentTabName=='active'){
        return "active"
      }else if(!currentTabName && tab_id==1){
        return "archived"
      }else if(!currentTabName && tab_id==2){
        return "draft"
      }
    }
  }
  const refreshData = () => {
    let userRole = role_id === 3 ? 'BMS Coordinator' :  role_id===2 ? "BMS Manager" : role_id==14 ? 'BMS Executive' : '', queryStatus = role_id === 3 || role_id===2 || role_id==14 ? 'all' : 'closed';
    let reqData = {
      role: userRole,
      user_id: user_id,
      status: getCurrentStatus(),
      limit: 10,
      offset: 0,
      service_request_type_id: filterData.serviceType,
      date: filterData.date != null ? getYYYYMMDDFormat(filterData.date) : '',
      search: filterData.search,
      service_status: filterData.serviceStatus,
      order_by_column:sortapiData.column_name,
      order_by:sortapiData.type
    }
    getServiceRequestsList(reqData)
  }

  const fetchDataPagignation = (pageValue) => {
    setPage(pageValue)
    let userRole = role_id === 3 ? 'BMS Coordinator' : role_id===2 ? "BMS Manager" : role_id==14 ? 'BMS Executive' : '', queryStatus = currentTabName;
    let reqData = {
      role: userRole,
      user_id: user_id,
      status: getCurrentStatus(),
      limit: 10,
      offset: parseInt(pageValue) - 1,
      service_request_type_id: filterData.serviceType,
      date: filterData.date !== null ? getYYYYMMDDFormat(filterData.date) : '',
      search: filterData.search,
      service_status: filterData.serviceStatus,
      order_by_column:sortapiData.column_name,
      order_by:sortapiData.type
    }
    getServiceRequestsList(reqData)
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }


  const renderTabHeadings = () => {
    if (parseInt(role_id) === 3 || parseInt(role_id)===2 || parseInt(role_id)==14) {
      return (
        <Tabs value={value} onChange={handleValue} TabIndicatorProps={{ sx: { backgroundColor: '#123FA9' } }}>
          <Tab label='All' name={"all"} {...a11yProps(0)} sx={{ color: '#6F80A9', textTransform: 'capitalize' }} />
          <Tab label='Closed' name={"closed"}{...a11yProps(1)} sx={{ color: '#6F80A9', textTransform: 'capitalize' }} />
        </Tabs>
      )

    } else {
      return (<Tabs value={value} onChange={handleValue} TabIndicatorProps={{ sx: { backgroundColor: '#123FA9' } }}>
        <Tab label='Active' name={"active"} {...a11yProps(0)} sx={{ color: '#6F80A9', textTransform: 'capitalize' }} />
        <Tab label='Archived' name={"archived"}{...a11yProps(1)} sx={{ color: '#6F80A9', textTransform: 'capitalize' }} />
        {parseInt(role_id) != 15 ? <Tab label='Drafts' name={"draft"}{...a11yProps(2)} sx={{ color: '#6F80A9', textTransform: 'capitalize' }} />: ''}
      </Tabs >)
    }
  }

  const filterDatas = (value, name) => {
    setFilterData({ ...filterData, [name]: value })
  }
  const handleReset = () => {
    setFilterData({ search: '', serviceType: '', serviceStatus: '', date: null })
  }

  const TabFilterOptions = () => {
    return (
      <>
      {toastAlert.status && <ToastAlert status={toastAlert.status} message={toastAlert.message} color={toastAlert.color} />}
        <Grid container columnSpacing={1}>
          <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <FormControl fullWidth size="small" sx={{ width: 160, }}>
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Type of Service</InputLabel>
              <Select value={filterData.serviceType} name="serviceType" onChange={(e) => filterDatas(e.target.value, e.target.name)}
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                {serviceFilter?.map((item) => (
                  <MenuItem sx={{color:'#0F2C6F'}} value={item.service_request_types_id}>{item.display_name} </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
              <Select
                MenuProps={MenuProps}
                value={filterData.serviceStatus}
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                label="Status" name="serviceStatus"
                onChange={(e) => filterDatas(e.target.value, e.target.name)}>
                {
                   value==0 &&  activeFilterStatus?.map((item) =>( 
                        <MenuItem sx={{color:'#0F2C6F'}} value={item.fault_status_name}>{item.display_name}</MenuItem>
                  ))
                }
                {
                   value!=0 &&  inactiveFilterStatus?.map((item) =>( 
                        <MenuItem sx={{color:'#0F2C6F'}} value={item.fault_status_name}>{item.display_name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                // label={currentTabName === 'closed' ? 'Closed Date' : 'Requested Date'}
                value={filterData.date }
                onChange={(newValue) => filterDatas(newValue, 'date')}
                renderInput={(params) => <TextField autoComplete="off" onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                disableFuture={true}
              />
            </FormControl>
            <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => handleReset()}>Reset</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <OutlinedInput placeholder="Search..."
              name='search'
              onChange={(e) => filterDatas(e.target.value, e.target.name)}
              endAdornment={<SearchIcon />} size="small" />
          </Grid>
        </Grid>
      </>
    )
  }
  return (
    <>

      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161, 0.05)',minHeight:"100%" }}>
        <Grid item xs={0.5}>

        </Grid>

        <Grid item xs={11}  >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 8,mb:6 }}>
            <Box>
              <Typography sx={{ color: '#002D74', fontSize: '26px', fontWeight: '500'}}>Service Requests</Typography>
              <Typography sx={{ color: " #002D74", fontSize: "14px", fontWeight: '500' }}>
                {/* All submitted Service Requests will be listed below */}
                {/* Service Request  displayed are filed by tenant admins on behalf of the company.  */}
                All submitted service requests will be displayed in below table. BMS Administrators can accept, reject, assign staff to the tickets.
                These requests usually involve areas within the office premises only. Please ensure all details input are accurate as some service
                requests will involve billing.
                </Typography>
            </Box>
            {
              (role_id !== 3 && role_id !==2 && role_id !==14 && role_id !==15) ? <Button endIcon={<AddIcon></AddIcon>}
                onClick={() => navigate(`/service_request/${value}/0/null`)}
                sx={{px:'16px', textTransform: 'capitalize', minWidth:'150px', height: '36.5px', borderRadius: '6px', backgroundColor: '#1E4CB5', color: '#FFFFFF', '&:hover': { backgroundColor: '#1E4CB5' },fontSize: { md: "14px", xs: "10px"}  }}
              >New Request</Button>
                : ''
            }
          </Box>
          <Box sx={{ backgroundColor: '#FFFFFF', borderBottom: "1px solid #DBDFE9", borderRadius: '8px 8px 0px 0px' }}>
            {renderTabHeadings()}
          </Box>
          <TabPanel value={value} index={0}>
            <Box sx={{ px: 2, pt: 2, backgroundColor: "#FFFFFF" }}>
              {TabFilterOptions()}
            </Box>
            <ServiceRequestList sorting={setSortApiData} tableData={serviceRequestData} pageCount={totalRecords} tab_id={value} fetchData={fetchDataPagignation} currentPage={currentPage} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box sx={{ px: 2, pt: 2, backgroundColor: "#FFFFFF" }}>
              {TabFilterOptions()}
            </Box>
            <ServiceRequestList sorting={setSortApiData} isArchived="true" tableData={serviceRequestData} pageCount={totalRecords} tab_id={value} fetchData={fetchDataPagignation} currentPage={currentPage} />

          </TabPanel>
          {role_id !=15 && <TabPanel value={value} index={2}>
            <ServiceRequestList sorting={setSortApiData} isDraft="true" tableData={serviceRequestData} pageCount={totalRecords} tab_id={value} fetchData={fetchDataPagignation} currentPage={currentPage} />
          </TabPanel>}
        </Grid>
        <Grid item xs={0.5}>
        </Grid>
      </Grid>
      {/* {serviceRequestData.length>0 ? :
      <Box sx={{ display: 'flex', alignItems: 'center', height: '95%', backgroundColor: '#F3F4F8' }}>
        <Grid container >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ServiceRequestIcon />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', py: 2 }}>
            <Typography sx={{ fontSize: '26px', color: '#002D74' }}>Service Request</Typography>
            <Typography sx={{ fontSize: '14px', color: '#0F2C6F' }}> {parseInt(role_id) === 3 ? `You has not received any service requests.` : `You has not filled any service requested.`} </Typography>
            <Typography sx={{ fontSize: '14px', color: '#0F2C6F' }}>{parseInt(role_id) === 3 ? "" : `To start a new service request,click the button below.`} </Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            {parseInt(role_id) === 3 ? <Button sx={{
              textTransform: 'capitalize',
              backgroundColor: '#123FA9',
              borderRadius: '6px',
              color: '#FFFFFF', padding: '8px 10px', '&:hover': { backgroundColor: '#123FA9' }
            }}
              startIcon={<SyncIcon style={{ color: '#FFFFFF' }} />} onClick={() => refreshData()}>Refresh Data</Button> : <Button sx={{
                textTransform: 'capitalize',
                backgroundColor: '#123FA9',
                borderRadius: '6px',
                color: '#FFFFFF', padding: '8px 10px', '&:hover': { backgroundColor: '#123FA9' }
              }}
                startIcon={<AddIcon style={{ color: '#FFFFFF' }} />} onClick={() => navigate("/service_request")}>New Request</Button>}
          </Grid>
        </Grid>
      </Box>
    } */}
    </>
  )

};
const mapStateToProps = (state) => {
  return {
    serviceRequestLists: state.Service_Req_Reducer.service_req_list,
    totalRecords: state.Service_Req_Reducer.totalRecords,
    serviceRequestTypes: state.Service_Req_Reducer.serviceRequestTypes,
    updateWatchStatus:state.Service_Req_Reducer.updateWatchStatus,
    serviceFilter:state.Service_Req_Reducer.serviceFilter,
    activeFilterStatus:state.Service_Req_Reducer.activeFilterStatus,
    inactiveFilterStatus:state.Service_Req_Reducer.inactiveFilterStatus,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getServiceRequestsList: (data) => (dispatch(serviceRequestAction.getServiceRequestsList(data))),
    setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
    getServiceRequestTypes: () => (dispatch(serviceRequestAction.getServiceRequestTypes())),
    getServiceFilter: () => (dispatch(serviceRequestAction.getServiceFilter())),
    getServiceActiveStatus:() => (dispatch(serviceRequestAction.getServiceActiveStatus())),
    getServiceInActiveStatus:() => (dispatch(serviceRequestAction.getServiceInActiveStatus()))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
