import { Box, Button, Grid, MenuItem, Modal, OutlinedInput, Select, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {ReactComponent as CloseIcon} from '../../Assets/icons/Alertclose.svg';
import * as serviceRequestAction from "../../redux/actions/service_request";
 import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {ReactComponent as RoundedPlusIcon} from '../../Assets/icons/roundedplus.svg';
import ApiService from "../../AxiosConfig/ApiService";
import EditLightBulbQuotation from "./EditLightBulbQuotation";
 
const StyledTypography = styled(Typography)({
    color:"#0F2C6F",
    fontSize:"14px",
    fontWeight:600
})

//  const EditLightBulbQuotation = ({closeFunc,quotationDatas,PopupStatus,getData,productList,getLightBulbServiceCharge, getLightBulbServiceChargeData})=>{
//     const [open,setOpen] = useState(PopupStatus);
//     const [newQuotationData,setNewQuotationData] = useState(quotationDatas);
//     const [grandTotal,setGrandTotal] = useState(0);
//     const [product,setProuduct] = useState(productList);
//     const [error,setError] = useState('')
//     const [productError,setProductError] = useState('')

//     useEffect(() => {
//         let newGrandTotal=0;
//         quotationDatas.forEach(element => {
//             if(element.row_type==='grand_total_row'){
//                 newGrandTotal=element.grand_total_price
//             }
//         });
//         setGrandTotal(newGrandTotal)
//      }, [])

//     useEffect(()=>{
//         let data = product.filter((value)=>{
//             return value.light_bulb_products_id != newQuotationData.map((value)=>value.light_bulb_products_id )
//          })
//         setProuduct(data)
//     },[newQuotationData])

//     useEffect(() => {
//         if(getLightBulbServiceChargeData?.response?.response_status === 1){
//             let modifiedValue = getLightBulbServiceChargeData.response?.data_array;
//             // if(modifiedValue){
//                 console.log("val",modifiedValue)
//                         let newValueIndex = newQuotationData.findIndex(item => item.row_type === "technician_row");
//                         let updatedState = newQuotationData;
//                         updatedState[newValueIndex].price = modifiedValue?.totalCalculatedAmount;
//                         updatedState[newValueIndex].quantity = modifiedValue?.hours_count;
//                         setNewQuotationData(updatedState);
//              }
//              console.log(getLightBulbServiceChargeData && getLightBulbServiceChargeData);
//     }, [getLightBulbServiceChargeData])

//     console.log(newQuotationData);

//     const handleDetails =(e)=>{
//       let {name,value,id}=e.target;
//       let newGrandTotal=0;
//         const data =newQuotationData.map((item,i)=>{
//             if(parseInt(id)===i){
//                 return{
//                     ...item,
//                     [name]:value
//                 }
//             }else{
//                 return item
//             }
//         }
       
//     )
//     data.forEach(element => {
//         newGrandTotal+=(Number(element.quantity)* Number(element.price)) + ((Number(element.quantity)*Number(element.price))*element.vat_percentage)/100 
//     });
//     setGrandTotal(newGrandTotal.toFixed(2))
//     setNewQuotationData(data)
//     }   

//     const handleProductId =(e,id)=>{
//         let {name,value,}=e.target;
//           const data =newQuotationData.map((item,i)=>{
//               if(parseInt(id)===i){
//                   return{
//                       ...item,
//                       [name]:value.light_bulb_products_name=='Others' ? value.light_bulb_products_name : value.light_bulb_products_id,
//                       price:getPrice(value.light_bulb_products_id).price,
//                       vat_percentage:getPrice(value.light_bulb_products_id).vat
//                   }
//               }else{
//                   return item
//               }
//           }
         
//       )
//       setNewQuotationData(data)
//       } 

//     const handleProductName = (e,index)=>{
//         let data = newQuotationData.map((value,ind)=>{
//             if(index==ind){
//                 return{
//                     ...value,
//                     light_bulb_products_name:e.target.value,
//                     newProduct:true,
//                 }
//             }else{
//                 return value
//             }
//         })
//         setNewQuotationData([...data])
//         setProductError('')
//     }

//       const getPrice = (val)=>{
//         let filter = product.filter((value)=>{
//             return value.light_bulb_products_id == val
//         })
//         return{
//             price:filter[0].price,
//             vat:filter[0].vat_percentage
//         } 
//       }

//     const addDeleteProduct=(actionType,valueId)=>{
//         if(actionType==='add'){
//             let newQuotationArr=newQuotationData;
//             let newObj={id:valueId,light_bulb_products_name:'',quantity:0,price:0,isUserAdded:true,working_hours:0,row_type:'price_list_row'};
//             newQuotationArr.push(newObj)
//             setNewQuotationData([...newQuotationArr])
//         }else{
//             let newQuotationArr = newQuotationData.filter((value,index)=>{
//                 return index!==valueId
//             })
//             setNewQuotationData([...newQuotationArr])
//         }
//     }

//     const setStateToExistingData = ()=>{
//         let filterData = newQuotationData.filter((value)=>{
//             value.isUserAdded=false
//         })
//         setNewQuotationData([...filterData])
//     }

//     const handleUpdateChanges = ()=>{
//         if(newQuotationData[newQuotationData.length-1].light_bulb_products_id=='Others' && newQuotationData[newQuotationData.length-1].light_bulb_products_name==''){
//             setProductError("Select Product Name")
//         }else{
//             let filteredArray = newQuotationData.filter((value)=>{
//                 return value.quantity==0
//             })
    
//             if(filteredArray.length>0){
//                 setError('Select product and quantity')
//             }else{
//                 getData(newQuotationData)
//             }
//         }
//     }

//     const renderProductList=()=>{
//         return newQuotationData?.map((rowData,index)=>(
//             rowData.row_type!=='grand_total_row'&&
//             <Grid container key={index} sx={{mt:1}} columnSpacing={2} rowSpacing={2}>         
//             <Grid item xs={4} >
//                 <Typography sx={{color:"#0F2C6F",fontSize:"16px"}}>Product Name</Typography>
//                 {rowData?.isUserAdded?
//                 <Select sx={{width:"100%"}} onChange={(e)=>handleProductId(e,index)} name="light_bulb_products_id" value={rowData?.light_bulb_products_id} id={index}>
//                     {product.map((product)=>(
//                         <MenuItem value={product}>{product.light_bulb_products_name}</MenuItem>
//                     ))}
//                 </Select>
//                 :<OutlinedInput disabled value={rowData.light_bulb_products_name || 'Technician'} id={index} name="light_bulb_products_name" onChange={(e)=>handleDetails(e)}/>
                
//                 }
//             </Grid>
//             <Grid item xs={2} >
//                 <StyledTypography>Qty.</StyledTypography>
//                 <OutlinedInput value={parseFloat(rowData.quantity)} type='number' inputProps={{min:1}} id={index} name={'quantity'} onChange={(e)=>{
//                     if(rowData?.row_type === 'technician_row'){
//                         handleTechnicianCharges(e);
                        
//                     }else{
//                         handleDetails(e)
//                     }
//                     }}/>
//             </Grid>
//             <Grid item xs={3} >
//                 <StyledTypography>Price/Unit</StyledTypography>
//                 <OutlinedInput disabled={(rowData.light_bulb_products_id=="Others" || rowData.newProduct) ? false : true} value={parseFloat(rowData.price)} id={index}  type='number' inputProps={{min:1}} name={'price'} onChange={(e)=>handleDetails(e)}/>
//             </Grid>
//             <Grid item xs={2} >
//                 <StyledTypography>Total</StyledTypography>
//                 <OutlinedInput disabled value={parseInt(rowData.quantity)*rowData.price + ((parseInt(rowData.quantity)*rowData.price)*rowData.vat_percentage)/100 } id={index} name={'total_price'} />
//             </Grid>
//             <Grid item xs={1} sx={{display:'flex',alignItems:'center'}}>
//             {rowData?.row_type!='technician_row' &&  <RemoveCircleOutlineIcon sx={{cursor:'pointer'}} onClick={()=>addDeleteProduct('delete',index)}/> }
//             </Grid>
//             {(rowData.light_bulb_products_id=='Others' || rowData.newProduct) && <Grid item xs={12}>
//                     <StyledTypography>Enter Product Name</StyledTypography>
//                     <OutlinedInput fullWidth onChange={(e)=>handleProductName(e,index)} name='light_bulb_products_name' />
//                 </Grid>
//             }
//         </Grid>
//         ))
//     }

 
 
//     const handleTechnicianCharges =  (e) => {
//         console.log(e.target.value);
//         console.log(newQuotationData);
//         // let modifiedValue = await getLightBulbServiceChargeData(e.target.value);
//         getLightBulbServiceCharge({hours_count: e.target.value});

    

       
//     }

//     return(
//         <>
//         <Modal  open={open}  onClose={()=>{
//             setStateToExistingData()
//             closeFunc('')
//             }}>
//             <Box sx={{position:"absolute",backgroundColor:"#FFFFFF",top:"2%",left:'25%',width:"40%"}}
//             style={{
//                 position: 'absolute',
//                 top: '50%',
//                 left: '50%',
//                 transform: 'translate(-50%, -50%)',
//                 width: 600,
//                 bgcolor: 'white',                
//                 boxShadow: 24,
//                 p: 4,
//                 overflowY: 'auto',
//                 height: 600,
//                   }}
//             >
//                 <Box sx={{display:"flex",justifyContent:"space-between",p:2,borderBottom:"1px solid #C0C7D7"}}>
//                     <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Edit Quotation</Typography>
//                     <CloseIcon onClick={()=>{
//                         setStateToExistingData()
//                         closeFunc(false)
//                     }} style={{cursor:'pointer'}} />
//                 </Box>
               
//             <Grid container sx={{p:2}} rowSpacing={3}>
//                 <Grid item xs={12}>
//                      <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Editing Quotation below</Typography>
//                 </Grid>
//                 <Grid item xs={8}>
//                     <StyledTypography>Update status to*</StyledTypography>
//                     <Select onChange={(e)=>handleDetails(e)} sx={{width:"60%",mt:1}} >
//                        <MenuItem value="quotation_updated">Quotation Updated</MenuItem>
//                     </Select>
//                 </Grid>
//                 <Grid xs={4} />
//                 <Grid item xs={12}>
//                     <StyledTypography>Bill Summary</StyledTypography>
//                 </Grid>
//                 <Grid item xs={12} sx={{padding:'0px !important',mt:2}}>
//                         <StyledTypography sx={{fontWeight:500}}>Product List</StyledTypography>
//                 </Grid>
//                 {renderProductList()}
//                 <Grid item xs={12}>
//                     <Typography sx={{color:"red"}}>{productError}</Typography>
//                     <Typography sx={{color:"red"}}>{error}</Typography>
//                 </Grid>
//                 <Grid  item xs={12}>
//                     <Button size="small" variant="contained" onClick={()=>{
//                         if(newQuotationData[newQuotationData.length-1].light_bulb_products_id=='Others' && newQuotationData[newQuotationData.length-1].light_bulb_products_name==''){
//                             setProductError('Enter Product Name')
//                         }else{
//                             addDeleteProduct('add',newQuotationData.length+1)
//                         }
//                         }} sx={{color:"#FFFFFF",textTransform:"capitalize",borderRadius:"6px",mb:2}} endIcon={ <RoundedPlusIcon/>}>Add More</Button>
//                 </Grid>
               
//                 <Grid item xs={12}>
//                     <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:500}}>Total Price (SGD)</Typography>
//                     <OutlinedInput value={grandTotal} disabled />
//                     <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>VAT included</Typography>
//                 </Grid>
                
//             </Grid>
//             <Box sx={{display:"flex",justifyContent:"space-between",p:3}}>
//                 <Button variant="outlined" sx={{color:"#0F2C6F",border:"1px solid #AAC3FF",borderRadius:"6px",cursor:'pointer'}} onClick={()=>closeFunc('')}>Cancel</Button>
//                 <Button variant="contained" sx={{color:"#FFFFFF",backgroundColor:"#123FA9",textTransform:"capitalize",borderRadius:"6px"}} onClick={handleUpdateChanges}>Update Changes</Button>
//             </Box>
//             </Box>
            
//         </Modal>
//         </>
//     )
// }


const EditQuotation=({getServiceAccessoriesData,serviceAccesscoriesList,quotationType,quotationData,closeFunc,updateServiceRequestQuotation,serviceRequestId,getLightBulbServiceCharge,getLightBulbServiceChargeData})=>{

    const [getLightBulbServiceChargeDataState, setgetLightBulbServiceChargeDataState] = useState(getLightBulbServiceChargeData)

    useEffect(() => {
        getServiceAccessoriesData()
    }, [])

    useEffect(() => {
        if(getLightBulbServiceChargeData.response?.response_status === 1){
            setgetLightBulbServiceChargeDataState(getLightBulbServiceChargeData)
        }
    }, [])


 
   const  renderQuotation=(qType,qData)=>{

        if(qType==='lightBulb'){
            return <EditLightBulbQuotation productList={serviceAccesscoriesList} quotationDatas={qData} PopupStatus={true} closeFunc={closeFunc} getData={updateData} serviceRequestId={serviceRequestId}   />
        }
    }
    
    const updateData=(details)=>{
        let newFormData=new FormData();
        newFormData.append('service_requests_id',serviceRequestId);
        newFormData.append('lightBulbQuotationArrayList',JSON.stringify(details));
        updateServiceRequestQuotation(newFormData);
    }

    return(
        <>
        {renderQuotation(quotationType,quotationData)}
        </>
    )

}
const mapStateToProps=(state)=>{
    return{
        serviceAccesscoriesList:state.Service_Req_Reducer.serviceAccesscoriesList,
        getLightBulbServiceChargeData:state.Service_Req_Reducer.getLightBulbServiceCharge,
                
    }
}
const mapDispatchToProps=(dispatch)=>{
    return{
        updateServiceRequestQuotation: (data) => (dispatch(serviceRequestAction.updateQuotation(data))),
        getServiceAccessoriesData: (data) => (dispatch(serviceRequestAction.getServiceAccesscoriesData(data))),
        getLightBulbServiceCharge: (params) => (dispatch(serviceRequestAction.getLightBulbServiceChargeAction(params))),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(EditQuotation);
