import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Stack
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxTerms.svg";
import { ReactComponent as UploadIcon } from "../../Assets/icons/Upload.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/Alertclose.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as Notification } from "../../Assets/icons/BellIcon.svg";
import { ReactComponent as AnnBell } from "../../Assets/icons/AnnBell.svg";
import BlankPreview from "../../Assets/icons/blankPreview.png";
import AnnouncementBell from "../../Assets/icons/AnnouncementBell.png";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { DatePicker, DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import DropZone from "../../Utils/dropzone";
import Modal from "../common/faultalert";
import { connect } from "react-redux";
import * as announcementAction from "../../redux/actions/announcement";
import axios from "axios";
import {
  getDateTimeFormat,
  getUTCDateTimeFormat,
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
} from "../../Utils/utils";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { MenuProps } from "../../Utils/utils";
import moment from "moment";
import Alert from "../common/faultalert";
import WifiIcon from "@mui/icons-material/Wifi";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
import { getPlatformName } from "../Events_Promotions/CreateEvent";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import cloneDeep from "lodash/cloneDeep";
import DOMPurify from "dompurify";
import { ReactComponent as DeleteIcon } from '../../Assets/uploadIcons/DeleteIcon.svg';
import { BorderLinearProgress } from "../Events_Promotions/CreateEvent";
import { getFileIcon } from "../Events_Promotions/CreateEvent";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

const StyledTypography = styled(Typography)({
  fontSize: "13px",
  color: " #0F2C6F",
  fontWeight: 550,
});
const PrimaryTypography = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 550,
  fontSize: "16px",
});
const SecondaryTypography = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 500,
  fontSize: "14px",
});

const SecondaryTypographyEllispses = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 500,
  fontSize: "14px",
  "& p": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: 0,
    height: "25px",
    fontSize: "13px",
    // marginLeft: "-3px",
  },
});

const alertData = {
  label: "Confirm Post",
  description:
    "Please ensure all details are entered correctly to prevent any delay to processing of request. Requests are subjected to Singapore Land Tower’s building management approval.",
  buttonvalue: "Submit",
};

const checkedStyle = {
  backgroundColor: "#FFFFFF",
  color: "#6F80A9",
  border: "1px solid #6F80A9",
  ml: 2,
  "&:hover": { backgroundColor: "#FFFFFF" },
};
const uncheckedStyle = {
  backgroundColor: "#123FA9",
  color: "#FFFFFF",
  ml: 2,
  "&:hover": { backgroundColor: "#123FA9" },
};
const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ indent: "-1" }, { indent: "+1" }],
];
const module = {
  toolbar: toolbarOptions,
  keyboard: {
    bindings: {
      "list autofill": {
        prefix: /^\s*()$/,
      },
    },
  },
};
const NewAnnouncement = ({
  addAnnouncement,
  getAnnouncementTypes,
  announcementTypes,
  announceAddStatus,
  setAnnouncementStateNull,
  getAnnouncementDetailsById,
  announcementList,
  announcementDetails,
  editAnnouncement,
  editAnnouncementStatus,
}) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [postType, setPostType] = useState("scheduled");
  const intialAnnouncementState = { type: "", title: "", description: "" };
  const intialScheduleData = {
    startDate: "",
    endDate: "",
    displaystartDate: null,
    displayendDate: null,
    startTime: "",
    endTime: "",
    app: false,
    dashboard: false,
    pinPost: false,
    pinDate: "",
    pinTime: "",
  };
  const [announcement, setAnnouncement] = useState(intialAnnouncementState);
  console.log(announcement)
  const [featuredImages, setFeaturedImages] = useState([]);
  const [scheduleData, setScheduleData] = useState(intialScheduleData);
  console.log(scheduleData)
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState({ type: "", title: "", description: "" });
  const [snackbarStatus, setSnackbarStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [startTime, setStartTime] = useState([]);
  const [endTime, setEndTime] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [tooltipId, setTooltipId] = useState(null);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [apiRequestOngoing, setApiRequestOngoing] = useState(false)
  const [uploadErrorMsg, setUploadErrorMsg] = useState("")

  let today = new Date();
  let { id, type } = useParams();

  useEffect(() => {
    if (id != "null") getAnnouncementDetailsById({ id: id });
  }, []);

  useEffect(() => {
    let announcementData = cloneDeep(announcementDetails);
    if (id != "null" && announcementData) {
      const platformObj = getPlatformName(announcementData?.platform);
      const scheduleFromDate = announcementData?.start_datetime
        ? moment(announcementData?.start_datetime).format("YYYY-MM-DD")
        : "";
      const scheduleToDate = announcementData?.end_datetime
        ? moment(announcementData?.end_datetime).format("YYYY-MM-DD")
        : "";
      const schedulestartTime = announcementData?.start_datetime
        ? moment(announcementData?.start_datetime)
            .format("HH:mm:ss")
            ?.slice(0, 5)
        : "";
      const scheduleEndTime = announcementData?.end_datetime
        ? moment(announcementData?.end_datetime).format("HH:mm:ss")?.slice(0, 5)
        : "";
      // setScheduleData(prev => ({...prev, }))
      setScheduleData((prev) => ({
        ...prev,
        ...platformObj,
        pinPost: announcementData?.priority == 1 ? true : false,
        displaystartDate: scheduleFromDate,
        displayendDate: scheduleToDate,
        startDate: scheduleFromDate,
        endDate: scheduleToDate,
        startTime: schedulestartTime,
        endTime: scheduleEndTime,
      }));
      setAnnouncement((prev) => ({
        ...prev,
        type: announcementData?.announcement_type_name,
        title: announcementData?.title,
        description: announcementData?.short_desc,
      }));
      setPostType(announcementData?.trigger == 1 ? "now" : "scheduled");
      setFeaturedImages(
        announcementData?.attachments?.length > 0
          ? announcementData?.attachments
          : []
      );
      let duplicatedArray = announcementData?.attachments?.map((obj) => ({
        ...obj,
      }));
      console.log("announcementData ",announcementData)
      if (duplicatedArray?.length > 0) {
        for (let i = 0; i < duplicatedArray?.length; i++) {
          duplicatedArray[i].src = duplicatedArray[i].filelocation;
        }
        setFeaturedImages([...duplicatedArray]);
      }
      if (announcementData?.others?.length > 0) {
        let duplicatedDocumentArray = announcementData?.others?.map(
          (obj) => ({ ...obj })
        );
        for (let i = 0; i < duplicatedDocumentArray.length; i++) {
          duplicatedDocumentArray[i].src = duplicatedDocumentArray[i].new_filename;
        }
        setAdditionalDocuments([...duplicatedDocumentArray]);
      }
    }
  }, [announcementDetails]);

  const postTypeDidMount = useRef(false);
  useEffect(() => {
    if (!postTypeDidMount.current) {
      postTypeDidMount.current = true;
      return;
    } //-->prevent initial render
    if (postType == "now") {
      setScheduleData((prev) => ({
        ...prev,
        displaystartDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      }));
    }
  }, [postType]);
  useEffect(() => {
    getAnnouncementTypes();
    getStartHours(0);
    getEndHours(0);
  }, []);

  useEffect(() => {
    // if(new Date(scheduleData.endDate) > new Date()){
    //     getEndHours(0)
    // }
    if (scheduleData.startTime) {
      if (
        scheduleData?.endDate &&
        scheduleData?.startDate == scheduleData?.endDate
      ) {
        getEndHours(scheduleData.startTime);
      } else if (
        (scheduleData?.endDate &&
          scheduleData?.startDate < scheduleData?.endDate) ||
        new Date(scheduleData.endDate) > new Date()
      ) {
        getEndHours(0);
      }
    }
  }, [scheduleData.startTime]);

  useEffect(() => {
    setLoaderStatus(false);
    if (announceAddStatus?.response_status == "success") {
      setLoaderStatus(false);
      setSnackbarStatus({
        message: announceAddStatus?.response_message,
        status: true,
        color: "green",
        icon: "tick",
      });
      setModalOpen("");
      setAnnouncementStateNull();
      setAnnouncement(intialAnnouncementState);
      setScheduleData(intialScheduleData);
      setTimeout(() => {
        removeSnackbar();
        navigate("/announcement");
      }, 5000);
    } else if (announceAddStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setSnackbarStatus({
        message: announceAddStatus?.response_message,
        status: true,
        color: "red",
        icon: "close",
      });
      setModalOpen("");
      setAnnouncementStateNull();
      setTimeout(() => {
        removeSnackbar();
      }, 5000);
    }
  }, [announceAddStatus]);

  useEffect(() => {
    setLoaderStatus(false);
    if (editAnnouncementStatus?.response_status == "success") {
      setLoaderStatus(false);
      setSnackbarStatus({
        message: editAnnouncementStatus?.response_message,
        status: true,
        color: "green",
        icon: "tick",
      });
      setModalOpen("");
      setAnnouncement(intialAnnouncementState);
      setScheduleData(intialScheduleData);
      setTimeout(() => {
        removeSnackbar();
        setAnnouncementStateNull();
        navigate("/announcement");
      }, 5000);
    } else if (editAnnouncementStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setSnackbarStatus({
        message: editAnnouncementStatus?.response_message,
        status: true,
        color: "red",
        icon: "close",
      });
      setModalOpen("");
      setTimeout(() => {
        setAnnouncementStateNull();
        removeSnackbar();
      }, 5000);
    }
  }, [editAnnouncementStatus]);

  useEffect(() => {
    let promotionDate = scheduleData?.displaystartDate
      ? new Date(scheduleData?.displaystartDate)
      : new Date(scheduleData.startDate);
    if (promotionDate > today) {
      getStartHours(0);
    } else {
      getStartHours(today.getHours() + 1);
    }
  }, [
    scheduleData.startDate,
    announcementDetails,
    scheduleData.displaystartDate,
  ]);

  const getStartHours = (minTime) => {
    let timeIntrevels = [];
    for (let i = minTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    setStartTime([...timeIntrevels]);
  };

  const getEndHours = (minTime) => {
    let timeIntrevels = [];
    let startTime = minTime === 0 ? 0 : parseInt(minTime.slice(0, 2)) + 1;
    for (let i = startTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    setEndTime([...timeIntrevels]);
  };

  useEffect(() => {
    if (
      scheduleData?.endDate &&
      scheduleData?.startTime &&
      scheduleData?.endDate > scheduleData?.startDate
    ) {
      getEndHours(0);
    } else if (
      scheduleData?.endDate &&
      scheduleData?.startTime &&
      (scheduleData?.endDate <= scheduleData?.startDate ||
        new Date(scheduleData?.endDate) == new Date())
    ) {
      getEndHours(scheduleData?.startTime);
    }
  }, [scheduleData?.endDate]);

  const removeSnackbar = () => {
    setSnackbarStatus({ message: "", status: false, color: "", icon: "" });
  };
  const handleDate = (e, datafrom) => {
   
    let date = moment(e).format('YYYY-MM-DD')

    setScheduleData({
      ...scheduleData,
      [datafrom]: date,
      [`display${datafrom}`]: e,
    });

    setError({
      ...error,
      date: "",
      startdate: "",
      endDate: "",
    });

    if (datafrom == "startDate" || datafrom == "endDate") {
      setScheduleData((prev) => ({ ...prev, startTime: "" }));
      setScheduleData((prev) => ({ ...prev, endTime: "" }));
    }

    // console.log("scheduleData?.endDate > scheduleData?.startDate  ",datafrom == "endDate" && scheduleData?.startTime && (scheduleData?.endDate > scheduleData?.startDate || new Date(scheduleData?.endDate) == today) )
  };

  const handleTime = (e, datafrom) => {
    let Time;
    // Time = `${e.getHours() <= 9 ? `0${e.getHours()}` : `${e.getHours()}`}:${e.getMinutes() <= 9 ? `0${e.getMinutes()}` : `${e.getMinutes()}`}`;

    //    if(datafrom=="startTime"){
    //     console.log("within starttime ", scheduleData?.startDate == scheduleData?.endDate)
    //     if( scheduleData?.endDate && scheduleData?.startDate == scheduleData?.endDate) {
    //         console.log("within first")
    //         getEndHours(e.target.value)
    //     }
    //     else if(scheduleData?.endDate && scheduleData?.startDate < scheduleData?.endDate) {
    //         console.log("within second")
    //         getEndHours(0)
    //     }
    //     }
    setScheduleData({
      ...scheduleData,
      [datafrom]: e.target.value,
      [`display${datafrom}`]: e,
    });
    setError({
      ...error,
      time: "",
      starttime: "",
      endtime: "",
    });
  };

  const handleAnnouncement = (e, fieldName) => {
    if (fieldName == "description") {
      setAnnouncement((prev) => ({
        ...prev,
        description: e,
      }));

      setError((prev) => ({
        ...prev,
        description: "",
      }));
      return;
    }
    const { name, value } = e.target;
    setAnnouncement((prev) => ({
      ...prev,
      [name]: value,
    }));

    setError((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleFeaturedImages = (files) => {
    if (files.length > 0) {
      setFeaturedImages([...files]);
    }
  };

  const handleUploadButton = (e) => {
    let target = e.target.files;
    let arr = featuredImages;
    let length = featuredImages.length;
    for (let i = 0; i < target.length; i++) {
      arr.push({
        id: length,
        fileName: target[i].name,
        size: target[i].size,
        file: target[i],
        src: URL.createObjectURL(target[i]),
      });
      length += 1;
    }
    setFeaturedImages([...arr]);
  };

  const handleImageDelete = (id, attach_id) => {
    let res;
    if (id != null) {
      res = featuredImages.filter((value) => value.id !== id);
    } else if (attach_id != null) {
      res = featuredImages.filter((value) => value.attach_id !== attach_id);
    }
    setFeaturedImages([...res]);
  };

  const handleCheckBox = (name) => {
    setScheduleData({
      ...scheduleData,
      [name]: !scheduleData[name],
    });
  };

  const modalClose = () => {
    setModalOpen(false);
  };

  const validate = () => {
    let error = true;
    if (!announcement.type) {
      setError((prevValue) => ({
        ...prevValue,
        type: "Please fill out this field",
      }));
      error = false;
    }
    if (!announcement.title) {
      setError((prevValue) => ({
        ...prevValue,
        title: "Please fill out this field",
      }));
      error = false;
    }
    if (!scheduleData.app && !scheduleData.dashboard) {
      setError((prevValue) => ({
        ...prevValue,
        platform: "Please select at least one if you want to proceed",
      }));
      error = false;
    }
    if (
      !announcement.description ||
      (announcement.description && announcement.description == "<p><br></p>")
    ) {
      setError((prevValue) => ({
        ...prevValue,
        description: "Please fill out this field",
      }));
      error = false;
    }
    if (postType === "scheduled") {
      if (!scheduleData.displaystartDate && !scheduleData.startDate) {
        setError((prevValue) => ({
          ...prevValue,
          startdate: "Please select Start Date ",
        }));
        error = false;
      }

      if (!scheduleData.displayendDate && !scheduleData.endDate) {
        setError((prevValue) => ({
          ...prevValue,
          endDate: "Please select End Date ",
        }));
        error = false;
      }
      if (!scheduleData.startTime) {
        setError((prevValue) => ({
          ...prevValue,
          starttime: "Please select Start Time",
        }));
        error = false;
      }
      if (!scheduleData.endTime) {
        setError((prevValue) => ({
          ...prevValue,
          endtime: "Please select End Time",
        }));
        error = false;
      }
      if (
        (scheduleData?.startDate || scheduleData?.displaystartDate) &&
        (scheduleData?.endDate || scheduleData?.displayendDate) &&
        (new Date(scheduleData?.startDate) > new Date(scheduleData?.endDate) ||
          new Date(scheduleData?.displaystartDate) >
            new Date(scheduleData?.displayendDate) ||
          new Date(scheduleData?.displaystartDate) >
            new Date(scheduleData?.endDate) ||
          new Date(scheduleData?.startDate) >
            new Date(moment(scheduleData?.displayendDate).format("YYYY-MM-DD")))
      ) {
        setError((prevValue) => ({
          ...prevValue,
          endDate:
            "The end date must be greater than or equal to the start date.",
        }));
        error = false;
      }
    } else {
      if (!scheduleData.displayendDate && !scheduleData.endDate) {
        setError((prevValue) => ({
          ...prevValue,
          date: "Please select End Date ",
        }));
        error = false;
      }
    }

    return error;
  };

  const handleContinueButton = () => {
    let err = validate();
    if (err) {
      setModalOpen(true);
    }
  };
  const confirmFuncNew = () => {
    // let start = new Date(scheduleData.startDate)
    // let startTime = scheduleData.startTime.split(":")
    // start.setHours(startTime[0])
    // start.setMinutes(startTime[1])
    // let startDateTime = postType === 'now' ? getUTCDateTimeFormat(new Date()) : getUTCDateTimeFormat(start) ;

    // // let endDateTime =`${scheduleData.endDate} ${scheduleData.endTime}:00`;
    // let end = new Date(scheduleData.endDate)
    // let endTime = scheduleData.endTime.split(":")
    // end.setHours(endTime[0])
    // end.setMinutes(endTime[1])
    let startDateTime =
      postType === "now"
        ? getDateTimeFormat(new Date())
        : `${
            scheduleData.startDate
              ? scheduleData.startDate
              : scheduleData.displaystartDate
          } ${scheduleData.startTime}:00`;
    let endDateTime = `${
      scheduleData.endDate ? scheduleData.endDate : scheduleData.displayendDate
    } ${scheduleData.endTime}:00`;

    let postData = new FormData();
    let name = [
      "type_id",
      "title",
      "short_desc",
      "start_datetime",
      `${postType === "scheduled" ? "end_datetime" : "anno_end_datetime"}`,
      "priority",
      "status",
      "platform",
      "triggerStatus",
    ];
    let value = [
      announcement.type == "Building Alert" ? 1 : 2,
      announcement.title,
      announcement.description,
      startDateTime,
      `${
        postType === "scheduled"
          ? endDateTime
          : scheduleData.endDate
          ? scheduleData.endDate
          : scheduleData.displayendDate
      }`,
      scheduleData.pinPost ? "1" : "2",
      id != "null" ? (type == "Active" ? "Edit" : "Schedule") : "Create",
      scheduleData.app && scheduleData.dashboard
        ? "3"
        : scheduleData.app
        ? "1"
        : "2",
      postType === "now" ? "1" : "2",
    ];

    if (postType === "now") {
      name = name.filter((item) => item !== "start_datetime");
      value = value.filter((item) => item !== startDateTime);
    }

    for (let j = 0; j < name.length; j++) {
      postData.append(name[j], value[j]);
    }

    let arr = [];
    let documentsNewArr = [];
    let documentsExisArr = [];
    const newFeatureImages = featuredImages?.filter((item) => item.id != null);
    const existsFeatureImages = featuredImages?.filter(
      (item) => item.attach_id
    );
    additionalDocuments?.forEach((value) => {
      if (!value?.filesize && value?.attach_id != null) {
        documentsNewArr.push(value?.attach_id);
      }
    });
    additionalDocuments?.forEach((value) => {
      if (value?.filesize && value?.attach_id != null) {
          documentsExisArr.push(value?.attach_id);
      }
    });
    for (let i = 0; i < newFeatureImages.length; i++) {
      postData.append(`attachments[${i}]`, newFeatureImages[i]?.file);
    }
    existsFeatureImages?.forEach((item) => {
      if (item?.attach_id) {
        arr.push(item.attach_id);
      }
    });
    postData.append("other_attachs", documentsNewArr);

    if (id != "null") {
      postData.append("announcement_id", announcementDetails?.announcement_id);
      postData.append("exists_attachs_id", arr);
      postData.append("exists_others_id", documentsExisArr);
      editAnnouncement(postData);
    } else {
      addAnnouncement(postData);
    }

    setModalOpen(false);
    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 10000);
  };
  const handleDeleteButton = () => {
    setScheduleData({
      ...scheduleData,
      app: false,
      dashboard: false,
      pinPost: false,
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      displaystartDate: null,
      displayendDate: null,
    });
    navigate(-1);
  };

  const handleStartTimeDisabled = () => {
    let isDisbled = type == "Active" ? true : false;
    if (isDisbled) return true;
    return !scheduleData?.startDate && !scheduleData?.displaystartDate
      ? true
      : false;
  };

  const handleEndTimeDisabled = () => {
    let isDisbled = type == "Active" ? true : false;
    if (isDisbled) return true;
    return !scheduleData?.endDate && !scheduleData?.displayendDate
      ? true
      : false;
  };

  const getUniqueId = () => {
    let randomUniqueId;
    let isAlreadyExists;
    do {
      randomUniqueId = Math.floor(Math.random() * 10000);
      isAlreadyExists = additionalDocuments?.some(item => item.id === randomUniqueId);
      console.log("Checking ID:", randomUniqueId, "Exists:", isAlreadyExists);
    } while (isAlreadyExists);
  
    return randomUniqueId;
  };
  

  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: (event) => {
      console.log("event ", event);
      const progress = Math.round((100 * event.loaded) / event.total);
      setUploadProgress(progress);
    },
  };

  const handleUploadDocument = (e) => {
    let target = e.target.files;
    let arr = additionalDocuments;
    let length = getUniqueId();
    const formData = new FormData();
    formData.append("type", 'Announcement');
    formData.append("attachments", target[0]);
    if(id != "null") {
      formData.append("id", id);
    }
    setApiRequestOngoing(true)
    let apiUrl = id !="null" ? "https://sglandbev1.demopsts.com/public/api/EditGeneralupload" : "https://sglandbev1.demopsts.com/public/api/Generalupload"
    axios
    .post(apiUrl, formData, config)
    .then((response) => {
      console.log("File uploaded successfully", response.data);
      if(response?.data?.response_status == "success") {
      arr.push({
          attach_id: response?.data?.response_json?.id,
          fileName: target[0]?.name,
          size: target[0]?.size,
          file: target[0],
          type: target[0]?.type,
          src: URL.createObjectURL(target[0]),
          new_filename: response?.data?.response_json?.url
      })
      setAdditionalDocuments([...arr])
      const fileInput = document.getElementById("file_input");
      fileInput.value = "";
      setUploadErrorMsg("")
    } else if(response?.data?.response_status == "fail") {
      setUploadErrorMsg(response?.data?.response_message)
    }
    setApiRequestOngoing(false)
    setUploadProgress(0)
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
      if(error?.data?.response_status == "fail") {
        setUploadErrorMsg(error?.data?.response_message)
      }
      setApiRequestOngoing(false)
      setUploadProgress(0)
    });

  };


  const handleDeleteFiles = (id) => {
    console.log("additionalDocuments ",additionalDocuments)
    let removedFiles = additionalDocuments?.filter(item => item?.attach_id !== id)
    console.log("removedFiles ",removedFiles, 'id ',id)
    setAdditionalDocuments(removedFiles)
  }

  console.log("addiio ",additionalDocuments)

  const handleCopy = (id, new_filename) => {
    navigator.clipboard.writeText(new_filename);
    setTooltipId(id);
    setTimeout(() => {
      setTooltipId(null);
    }, 8000);
  };



  const getplatformScren = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
          }}
        >
          <StyledTypography>Platform</StyledTypography>
          <Box>
            <Checkbox
              disabled={type == "Active" ? true : false}
              icon={<CheckboxIcon />}
              checked={scheduleData.app}
              onClick={() => {
                handleCheckBox("app");
                setError({
                  ...error,
                  platform: "",
                });
              }}
              sx={{ display: "inline" }}
            />
            <Typography
              sx={{ display: "inline", color: "#0F2C6F", fontSize: "12px" }}
            >
              App
            </Typography>
          </Box>
          <Box>
            <Checkbox
              disabled={type == "Active" ? true : false}
              icon={<CheckboxIcon />}
              checked={scheduleData.dashboard}
              onClick={() => {
                handleCheckBox("dashboard");
                setError({
                  ...error,
                  platform: "",
                });
              }}
              sx={{ display: "inline" }}
            />
            <Typography
              sx={{ display: "inline", color: "#0F2C6F", fontSize: "12px" }}
            >
              Dashboard
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ color: "red" }}>{error.platform}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
            mt: 2,
          }}
        >
          <Box>
            <StyledTypography>Pin this post</StyledTypography>
            <Typography sx={{ fontSize: "12px", color: "#0F2C6F" }}>
              Priority your post up top
            </Typography>
          </Box>
          <Checkbox
            disabled={type == "Active" ? true : false}
            icon={<CheckboxIcon />}
            checked={scheduleData.pinPost}
            onClick={() => handleCheckBox("pinPost")}
          />
        </Box>
      </>
    );
  };
  return (
    <>
      <ToastAlert
        message={snackbarStatus.message}
        status={snackbarStatus.status}
        color={snackbarStatus.color}
        icon={snackbarStatus.icon}
      />
      {modalOpen && (
        <Modal
          alertData={alertData}
          closeFunc={modalClose}
          confirmFunc={confirmFuncNew}
        />
      )}
      {loaderStatus && <Loader status={loaderStatus} />}
      <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
        <Grid item xs={0.5} />
        <Grid item xs={11.5}>
          <Box sx={{ mb: 5, mt: 8 }}>
            <BackIcon
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
            <Typography
              sx={{
                color: "#0F2C6F",
                fontSize: "26px",
                fontWeight: 500,
                mt: 2,
              }}
            >
              {id != "null" ? "Edit" : "Create"} Announcement
            </Typography>
            <Typography
              sx={{
                color: "#6F80A9",
                fontSize: "14px",
                fontWeight: 500,
                mt: 1,
              }}
            >
              You may create announcements/ notifications which will be shown to
              all or selected app & dashboard users.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
        <Grid item xs={0.5} />
        <Grid item xs={8}>
          <Box sx={{ backgroundColor: "#FFFFFF", mb: 8 }}>
            <Tabs
              value={tabValue}
              onChange={(e, value) => setTabValue(value)}
              sx={{ borderBottom: "1px solid #DBDFE9" }}
            >
              <Tab
                label={
                  <Box>
                    <span
                      style={{
                        backgroundColor: "#123FA9",
                        fontSize: "12px",
                        color: "#FFFFFF",
                        borderRadius: "50%",
                        padding: "3px 7px",
                      }}
                    >
                      1
                    </span>
                    <span
                      style={{
                        textTransform: "capitalize",
                        marginLeft: "3px",
                        color: "#123FA9",
                        fontSize: "14px",
                      }}
                    >
                      Create
                    </span>
                  </Box>
                }
              ></Tab>
              <Tab
                label={
                  <Box>
                    <span
                      style={{
                        backgroundColor: "#123FA9",
                        fontSize: "12px",
                        color: "#FFFFFF",
                        borderRadius: "50%",
                        padding: "3px 7px",
                      }}
                    >
                      2
                    </span>
                    <span
                      style={{
                        textTransform: "capitalize",
                        marginLeft: "3px",
                        color: "#123FA9",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      Preview
                    </span>
                  </Box>
                }
              ></Tab>
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <Grid container sx={{ p: 4 }} rowSpacing={3}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "18px", color: "#0F2C6F" }}>
                    Announcement Content
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "30%" }} size="small">
                    <Typography
                      sx={{
                        color: " #0F2C6F",
                        fontSize: "14px",
                        fontWeight: 550,
                        mb: 1,
                      }}
                    >
                      Type *
                    </Typography>
                    <Select
                      name="type"
                      sx={{ height: "48px", color: "#0F2C6F" }}
                      value={announcement?.type || ""}
                      onChange={(e) => handleAnnouncement(e)}
                    >
                      {announcementTypes.length > 0 ? (
                        announcementTypes.map((item) => (
                          <MenuItem
                            sx={{ color: "#0F2C6F" }}
                            value={item.name}
                            key={item.anno_type_id}
                          >
                            {item.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem sx={{ color: "#0F2C6F" }} value="">
                          NO Types Available
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                  {error.type && (
                    <Typography sx={{ color: "#f44336" }}>
                      {error.type}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: " #0F2C6F",
                      fontSize: "14px",
                      fontWeight: 550,
                    }}
                  >
                    Title *
                  </Typography>
                  <OutlinedInput
                    name="title"
                    inputProps={{ maxLength: 200 }}
                    onChange={(e) => handleAnnouncement(e)}
                    value={announcement?.title}
                    fullWidth
                    sx={{ height: "48px", color: "#0F2C6F", my: 1 }}
                  />
                  <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>
                    {announcement?.title?.length}/200
                  </Typography>
                  {error.title && (
                    <Typography sx={{ color: "#f44336" }}>
                      {error.title}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 550 }}
                  >
                    Short Description *
                  </Typography>
                  {/* <TextField name="description"
                                        multiline
                                        autoComplete='off'
                                        value={announcement.description}
                                        onChange={(e) => handleAnnouncement(e)}
                                        fullWidth sx={{  color: '#0F2C6F',my:1,overflowY:'auto' }}
                                        inputProps={{ maxLength: 5000 }} /> */}
                  <Box sx={{ mb: 6 }}>
                    <ReactQuill
                      value={announcement?.description}
                      onChange={(e) => handleAnnouncement(e, "description")}
                      modules={module}
                      theme="snow"
                      style={{ height: "150px" }}
                      placeholder=""
                    />
                  </Box>
                  {/* <Typography sx={{ color: '#6F80A9', fontSize: '14px' }}>{announcement?.description?.length}/5000</Typography> */}
                  {error.description && (
                    <Typography sx={{ color: "#f44336" }}>
                      {error.description}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      color: " #0F2C6F",
                      fontWeight: 550,
                      fontSize: "14px",
                      mb: 1,
                    }}
                  >
                    Add Featured Image <span>(optional)</span>
                  </Typography>
                  {featuredImages.length === 0 ? (
                    <DropZone
                      onlyImage={true}
                      getFiles={handleFeaturedImages}
                    />
                  ) : (
                    <>
                      {featuredImages.map((value, index) => (
                        <Box
                          key={index}
                          sx={{
                            border: "1px solid #DBDFE9",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "space-between",
                            height: "48px",
                            p: 1,
                            mt: 1,
                          }}
                        >
                          <Typography>
                            {value.fileName ? value.fileName : value.filename}
                          </Typography>
                          <CloseIcon
                            onClick={() =>
                              handleImageDelete(value.id, value.attach_id)
                            }
                          />
                        </Box>
                      ))}
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          variant="outlined"
                          component="label"
                          sx={{ textTransform: "capitalize", mt: 1 }}
                          endIcon={<UploadIcon />}
                        >
                          <input
                            style={{ display: "none" }}
                            multiple
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleUploadButton(e)}
                          />
                          Upload
                        </Button>
                      </Box>
                    </>
                  )}
                </Grid>
                <Grid
                        item
                        xs={12}
                        sx={{ mt: 3, display: "flex", justifyContent: "space-between", width:"100%" }}
                        >
                        <Box sx={{display:"flex", flexDirection:"column", }} >
                        <Typography
                            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
                        >
                            Add Documents
                            <span style={{ fontSize: "10px", color: "#0F2C6F", textOverflow:"clip"}}>
                            {" "}
                            (optional) 
                            </span>
                        </Typography>
                        <span style={{fontSize: "10px", color: "#0F2C6F",}}> (Note - allowed types:  jpg,jpeg,png,svg,webp,doc,docx,xls,xlsx,ppt,pptx,txt,mp4,pdf)</span>
                        </Box>
                        
                    
                            <Button
                            component="label"
                            variant="outlined"
                            disabled={apiRequestOngoing}
                            sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize",  }}
                            endIcon={<UploadIcon />}
                            >
                            Upload
                            <input
                                capture
                                id="file_input"
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => handleUploadDocument(e)}
                            />
                            </Button>
                    </Grid>     
                    <Box sx={{ display: "flex", flexDirection: "column", width:"100%" }}>
                    
                        {additionalDocuments?.length > 0 && (
                        <Box>
                            {additionalDocuments?.map((value) => (
                            <Box
                                key={value?.attach_id} // Add a unique key for each item
                                sx={{
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: 'space-between',
                                gap: '5px',
                                border: '1px solid #DBDFE9',
                                borderRadius: "8px",
                                mt: 2,
                                p: 1,
                                }}
                            >
                                <Box sx={{display:"flex", alignItems:"center",gap:"5px", height:"54.8px", width:"100%"}}>
                                <Box>
                                    {getFileIcon(value?.type ? value?.type : value?.filetype)}

                                </Box>
                                {/* <Box sx={{display:"flex", flexDirection:"column",  width:"100%", height:"90%"}}> */}
                                <Typography
                                    sx={{
                                    color: "#0F2C6F",
                                    fontSize: '14px',
                                    maxWidth: "500px",
                                    wordWrap: "break-word", // Allows text wrapping when max width is exceeded
                                    flexGrow: 1, // Takes up remaining space on the left
                                    }}
                                >
                                    {value?.fileName ? value?.fileName : value?.orig_filename ? value?.orig_filename : value?.filename}
                                    <span style={{ color: "#9AA6C3", fontSize: '12px', marginLeft: '10px' }}>
                                    ({Math.round(value?.size ? value?.size / 1000 : value?.filesize / 1000)}KB)
                                    </span>
                                </Typography>
                                {/* <BorderLinearProgress variant="determinate" value={uploadProgress} />
                                </Box> */}
                                </Box>

                            

                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <Tooltip
                                    open={tooltipId === value?.attach_id}
                                    title="Copied to clipboard"
                                    placement="top"
                                    onClose={() => setTooltipId(null)}
                                    PopperProps={{
                                    modifiers: [
                                        {
                                        name: "offset",
                                        options: {
                                            offset: [0, 8],
                                        },
                                        },
                                    ],
                                    }}
                                    componentsProps={{
                                    tooltip: {
                                        sx: {
                                        bgcolor: "black",
                                        color: "white",
                                        fontSize: 12,
                                        p: 1,
                                        },
                                    },
                                    }}
                                >
                                    <IconButton onClick={() => handleCopy(value?.attach_id, value?.new_filename)}>
                                    <ContentCopyOutlinedIcon />
                                    </IconButton>
                                </Tooltip>
                                    <DeleteIcon style={{width:"40px", height:"40px", cursor:"pointer"}} onClick={() => handleDeleteFiles(value?.attach_id)}/>
                                </Box>
                            </Box>
                            ))}
                        </Box>
                        )}
                        {apiRequestOngoing && <Stack spacing={2} sx={{ flexGrow: 1, border:"1px solid #DBDFE9", borderRadius:"8px", mt:2, p:2, pb:'8px' }}>
                                    <BorderLinearProgress variant="determinate" value={uploadProgress} />
                                    <span>{uploadProgress}%</span>
                                </Stack>}
                            {uploadErrorMsg && <Typography variant="p" sx={{ fontSize: "14px", color: "red", mt:4 }}>{uploadErrorMsg}</Typography>}
                        
                    </Box>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Grid container sx={{ backgroundColor: "#FFFFFF", p: 1 }}>
                <Grid item xs={12}>
                  <PrimaryTypography sx={{ my: 2 }}>
                    Application Preview
                  </PrimaryTypography>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <Grid item>
                    <Box
                      sx={{
                        height: "100%",
                        minWidth: "260px",
                        maxWidth: "260px",
                        position: "relative",
                        borderRadius: "8px",
                        border: "1px solid #F3F4F8",
                        backgroundColor: "#F3F4F8",
                      }}
                    >
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography style={{ fontSize: "15px" }}>
                          11.50
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <WifiIcon style={{ fontSize: "15px" }} />
                          <SignalCellularAltIcon
                            style={{ fontSize: "15px", mx: 1 }}
                          />
                          <BatteryCharging50Icon style={{ fontSize: "15px" }} />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "12px 12px 0 0",
                          position: "absolute",
                          bottom: "0%",
                          width: "87.5%",
                          p: 2,
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        {/* <Box component="img" src={AnnBell} sx={{height:"40px", width:"50px"}} /> */}
                        <AnnBell style={{ height: "40px", width: "40px" }} />
                        <Typography sx={{ color: "#be0754", my: 1 }}>
                          {announcement.type}
                        </Typography>
                        {/* <SecondaryTypography>Emergency alerts inside Singapore Land Tower such as building renovation,fire,drill,etc.</SecondaryTypography> */}
                        <Box
                          sx={{
                            borderRadius: "8px",
                            my: 2,
                            backgroundColor: "#F3F4F8",
                            p: 1,
                            display: "flex",
                            gap: "20px",
                            position: "relative",
                            alignItems: "center",
                          }}
                        >
                          <SecondaryTypography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              pr: "8px",
                              minWidth: "60px",
                            }}
                          >
                            All Day{" "}
                          </SecondaryTypography>
                          <SecondaryTypography
                            sx={{
                              fontSize: "30px",
                              position: "absolute",
                              left: "70px",
                              top: "-10px",
                            }}
                          >
                            .
                          </SecondaryTypography>
                          <SecondaryTypography
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textWrap: "nowrap",
                            }}
                          >
                            {announcement?.title}
                          </SecondaryTypography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#F3F4F8",
                      ml: 1,
                      borderRadius: "8px",
                      minWidth: "260px",
                      maxWidth: "260px",
                    }}
                  >
                    <Box
                      sx={{
                        px: 2,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography style={{ fontSize: "15px" }}>
                        11.50
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <WifiIcon style={{ fontSize: "15px" }} />
                        <SignalCellularAltIcon
                          style={{ fontSize: "15px", mx: 1 }}
                        />
                        <BatteryCharging50Icon style={{ fontSize: "15px" }} />
                      </Box>
                    </Box>
                    <Box sx={{ my: 4, mx: 1 }}>
                      <SecondaryTypography sx={{ fontSize: "18px", mb: "5px" }}>
                        Messages
                      </SecondaryTypography>
                      <SecondaryTypography>
                        Overwiew of the messages
                      </SecondaryTypography>
                    </Box>
                    <Box
                      sx={{
                        width: "50%",
                        borderBottom: "3px solid #123FA9",
                        display: "inline-block",
                      }}
                    >
                      <SecondaryTypography
                        sx={{ mb: "10px" }}
                        textAlign={"center"}
                      >
                        Announcement
                      </SecondaryTypography>
                    </Box>
                    <Box
                      sx={{ width: "50%", float: "right", display: "inline" }}
                    >
                      <SecondaryTypography textAlign={"center"}>
                        Tickets
                      </SecondaryTypography>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: "#FFFFFF",
                        p: 1,
                        m: 1,
                        mt: "10px",
                        borderRadius: "20px",
                      }}
                    >
                      <Typography sx={{ color: "#be0754", fontSize: "14px" }}>
                        {announcement?.type}
                      </Typography>
                      <SecondaryTypography
                        sx={{
                          mt: 1,
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          fontSize: "1rem",
                          lineHeight: "30px",
                        }}
                      >
                        {announcement?.title}
                      </SecondaryTypography>
                      <SecondaryTypographyEllispses sx={{ mb: 1 }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              announcement?.description
                            ),
                          }}
                        />
                      </SecondaryTypographyEllispses>
                      {postType == "now" ? (
                        <SecondaryTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            position: "relative",
                            top: "-21px",
                          }}
                        >
                          {moment().format("ddd, DD MMM, YYYY")}{" "}
                          <span
                            style={{
                              fontSize: "34px",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            .
                          </span>{" "}
                          {moment().format("HH:mm")}
                        </SecondaryTypography>
                      ) : (
                        <SecondaryTypography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "400",
                            position: "relative",
                            top: "-21px",
                          }}
                        >
                          {scheduleData?.startDate
                            ? moment(scheduleData?.startDate).format(
                                "ddd, DD MMM, YYYY"
                              )
                            : ""}{" "}
                          {scheduleData?.startDate ? (
                            <span
                              style={{
                                fontSize: "34px",
                                position: "relative",
                                top: "-3px",
                              }}
                            >
                              .
                            </span>
                          ) : (
                            ""
                          )}{" "}
                          {scheduleData?.startTime
                            ? moment(scheduleData?.startTime, "HH:mm").format(
                                "HH:mm"
                              )
                            : ""}
                        </SecondaryTypography>
                      )}
                      {/* <Typography>skmjrbkjfvwe</Typography> */}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      backgroundColor: "#F3F4F8",
                      ml: 1,
                      borderRadius: "8px",
                      pb: 1,
                      minWidth: "260px",
                      maxWidth: "260px",
                      maxHeight: "600px",
                      overflowX: "hidden",
                      overflowY: "auto",
                      overflowY: "auto",
                      "&::-webkit-scrollbar": { display: "none" },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                  >
                    <Box
                      sx={{
                        zIndex: 1,
                        position: "sticky",
                        top: 0,
                        height: "260px",
                        width: "260px",
                        backgroundImage: `url(${
                          featuredImages[0]?.src
                            ? featuredImages[0]?.src
                            : BlankPreview
                        })`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          px: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography style={{ fontSize: "15px" }}>
                          11.50
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <WifiIcon style={{ fontSize: "15px" }} />
                          <SignalCellularAltIcon
                            style={{ fontSize: "15px", mx: 1 }}
                          />
                          <BatteryCharging50Icon style={{ fontSize: "15px" }} />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          position: "absolute",
                          bottom: "0px",
                          display: "flex",
                          alignItems: "center",
                          backgroundImage:
                            "linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.6),rgba(0,0,0,0.8))",
                        }}
                      >
                        {/* <Typography sx={{color:'#ff1a75'}}>{announcement?.type?.name}</Typography> */}
                        <PrimaryTypography
                          sx={{
                            color: "#FFFFFF",
                            fontSize: "18px",
                            p: "10px 8px",
                            fontFamily: "Inter",
                            fontWeight: "300 !important",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {announcement?.title}
                        </PrimaryTypography>
                      </Box>
                      {/* {featuredImages?.map((value)=>(
                                                                    <img style={{width:'250px',height:'250px'}} src={value.src} />
                                                                ))} */}
                    </Box>
                    {/* </Box> */}
                    <Box sx={{ px: "8px" }}>
                      {/* <PrimaryTypography sx={{textTransform:"uppercase",mt:1,px:1}}>Alert Details</PrimaryTypography> */}
                      <Typography
                        sx={{ color: "#be0754", fontSize: "14px", my: 1 }}
                      >
                        {announcement?.type}
                      </Typography>
                      <SecondaryTypography sx={{ wordBreak: "break-all" }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              announcement?.description
                            ),
                          }}
                        />
                      </SecondaryTypography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        m: "8px",
                        gap: "5px",
                        overflow: "auto",
                        "&::-webkit-scrollbar": { display: "none" },
                        msOverflowStyle: "none",
                        scrollbarWidth: "none",
                      }}
                    >
                      {featuredImages?.map((value) => (
                        <Box
                          component="img"
                          sx={{
                            maxWidth: "113px",
                            maxHeight: "90px",
                            minHeight: "90px",
                            borderRadius: "8px",
                            backgroundRepeat: "no-repeat",
                            ml: 0.2,
                            backgroundSize: "contain",
                          }}
                          src={value?.src}
                        ></Box>
                      ))}
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3.5} sx={{ px: 2 }}>
          <Box sx={{ backgroundColor: "#FFFFFF", p: 2 }}>
            <Chip
              disabled={type == "Active" ? true : false}
              label="Post Now"
              onClick={() => {
                setPostType("now");
                setScheduleData((prev) => ({
                  ...prev,
                  startDate: "",
                  endDate: "",
                  startTime: "",
                  endTime: "",
                  displaystartDate: null,
                  displayendDate: null,
                }));
              }}
              sx={postType === "now" ? uncheckedStyle : checkedStyle}
            />
            <Chip
              disabled={type == "Active" ? true : false}
              label="Schedule Post"
              onClick={() => {
                setPostType("scheduled");
                setScheduleData((prev) => ({
                  ...prev,
                  startDate: "",
                  endDate: "",
                  startTime: announcementDetails?.start_datetime
                    ? announcementDetails?.start_datetime?.slice(11, 16)
                    : "",
                  endTime: announcementDetails?.end_datetime
                    ? announcementDetails?.end_datetime?.slice(11, 16)
                    : "",
                  displaystartDate: announcementDetails?.start_datetime
                    ? announcementDetails?.start_datetime?.slice(0, 10)
                    : null,
                  displayendDate: announcementDetails?.end_datetime
                    ? announcementDetails?.end_datetime?.slice(0, 10)
                    : null,
                }));
              }}
              sx={postType === "scheduled" ? uncheckedStyle : checkedStyle}
            />
          </Box>
          {postType === "now" ? (
            <>
              <Box sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
                <Grid container>
                  <Grid item xs={7} sx={{ p: 1 }}>
                    <StyledTypography>End Date</StyledTypography>
                    <DesktopDatePicker
                      disabled={type == "Active" ? true : false}
                      inputFormat="dd/MM/yyyy"
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      disablePast
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                      value={scheduleData.displayendDate}
                      onChange={(e) => handleDate(e, "endDate")}
                    />
                    <Grid item xs={12}>
                      {error.date && (
                        <Typography sx={{ color: "red", textAlign: "center" }}>
                          {error.date}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ p: 1 }}>
                    {getplatformScren()}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Box sx={{ backgroundColor: "#FFFFFF", px: 2 }}>
              <Grid container>
                {/* <Grid container item xs={12} > */}
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography>Start Date</StyledTypography>
                  <DatePicker
                    disabled={type == "Active" ? true : false}
                    inputFormat="dd/MM/yyyy"
                    disablePast
                    components={{
                      OpenPickerIcon: DatePickerIcon,
                    }}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => preventDatePickerKeyboard(e)}
                        {...params}
                      />
                    )}
                    value={scheduleData.displaystartDate}
                    onChange={(e) => handleDate(e, "startDate")}
                  />
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {error.startdate}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography>End Date</StyledTypography>
                  <DesktopDatePicker
                    disabled={type == "Active" ? true : false}
                    inputFormat="dd/MM/yyyy"
                    minDate={
                      scheduleData.startDate && new Date(scheduleData.startDate)
                    }
                    disablePast
                    components={{
                      OpenPickerIcon: DatePickerIcon,
                    }}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => preventDatePickerKeyboard(e)}
                        {...params}
                      />
                    )}
                    value={scheduleData.displayendDate}
                    onChange={(e) => handleDate(e, "endDate")}
                  />
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {error.endDate}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                                        {error.date && <Typography sx={{color:"red",textAlign:"center"}}>{error.date}</Typography>}
                                    </Grid> */}

                {/* </Grid> */}

                {/* <Grid container item xs={12} sx={{ my: 2 }}> */}
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    Start Time
                  </StyledTypography>
                  <Select
                    disabled={handleStartTimeDisabled()}
                    MenuProps={MenuProps}
                    sx={{ display: "block" }}
                    value={scheduleData.startTime}
                    onChange={(e) => handleTime(e, "startTime")}
                  >
                    {startTime.map((value) => (
                      <MenuItem sx={{ color: "#0F2C6F" }} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {error.starttime}
                  </Typography>
                  {/* <TimePicker  ampm={false} renderInput={(params) => <TextField {...params} />}  value={scheduleData.displaystartTime}  onChange={(e)=>handleTime(e,"startTime")} /> */}
                </Grid>
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    End Time{" "}
                  </StyledTypography>
                  <Select
                    disabled={handleEndTimeDisabled()}
                    MenuProps={MenuProps}
                    sx={{ display: "block" }}
                    value={scheduleData.endTime}
                    onChange={(e) => handleTime(e, "endTime")}
                  >
                    {endTime.map((value) => (
                      <MenuItem sx={{ color: "#0F2C6F" }} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography sx={{ color: "red", fontSize: "14px" }}>
                    {error.endtime}
                  </Typography>
                  {/* <TimePicker  ampm={false} renderInput={(params) => <TextField {...params} />} value={scheduleData.displayendTime} onChange={(e)=>handleTime(e,"endTime")} /> */}
                </Grid>
                {/* <Grid item xs={12}>
                                        {error.time && <Typography sx={{color:"red",textAlign:"center"}}>{error.time}</Typography>}
                                    </Grid> */}
                {/* </Grid> */}

                <Grid item xs={12} sx={{ p: 1 }}>
                  {getplatformScren()}
                </Grid>
              </Grid>
            </Box>
          )}
          <Button
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              height: "50px",
              border: " 1px solid #AAC3FF",
              borderRadius: "8px",
              mt: 2,
            }}
            onClick={() => handleDeleteButton()}
          >
            Back
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              textTransform: "capitalize",
              height: "50px",
              backgroundColor: "#123FA9",
              borderRadius: "8px",
              mt: 2,
            }}
            onClick={() => handleContinueButton()}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
const TabPanel = (props) => {
  const { value, index, children } = props;
  return <>{value === index && <>{children}</>}</>;
};

const mapStateToProps = (state) => {
  return {
    announceAddStatus: state.AnnouncementReducer.announceAddStatus,
    editAnnouncementStatus: state.AnnouncementReducer.editAnnouncementStatus,
    announcementTypes: state.AnnouncementReducer.announcementTypes,
    announcementList: state.AnnouncementReducer.announcementList,
    announcementDetails: state.AnnouncementReducer.announcementDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnnouncement: (data) =>
      dispatch(announcementAction.addAnnouncement(data)),
    getAnnouncementTypes: () =>
      dispatch(announcementAction.getAnnouncementTypes()),
    getAnnouncementsList: (data) =>
      dispatch(announcementAction.getAnnouncementsList(data)),
    getAnnouncementDetailsById: (data) =>
      dispatch(announcementAction.getAnnouncementDetailsById(data)),
    editAnnouncement: (data) =>
      dispatch(announcementAction.editAnnouncement(data)),
    setAnnouncementStateNull: () =>
      dispatch(announcementAction.setAnnouncementStateNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAnnouncement);
