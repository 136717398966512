import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { facilityBookingPaymentStatusNullAction, facilityBookingPaymentStatusUpdateAction } from '../redux/actions/facilityBookingAction';
import { connect } from 'react-redux';
import { showSnackBarAction } from '../redux/actions/snackbarAction';
 
const PaymentConfirmation = ({facilityBookingPaymentStatusUpdate,showSnackBar}) => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    const bookingStatus = queryParams.get('booking_status');
    const paymentStatus = queryParams.get('payment_status');
    const roomBookingId = queryParams.get('room_booking_id');
    const errorMessage = queryParams.get('error_message');

    useEffect(() => {
        let token = localStorage.getItem('userToken');

        if(token === null){
            navigate('/')
        }else{            
            if(bookingStatus && paymentStatus){
                if(bookingStatus === "confirmed" && paymentStatus === "success" && roomBookingId){
                    facilityBookingPaymentStatusUpdate({status: 1, message:"Amenity booking has been successfully confirmed!"})
                }else if(bookingStatus === "cancelled" && paymentStatus === "error") {
                    facilityBookingPaymentStatusUpdate({status: 0, message:"Payment Failed!"})
                }else if(bookingStatus === "cancelled" && paymentStatus === "cancel") {
                    facilityBookingPaymentStatusUpdate({status: 2, message:"Payment Cancelled!"})
                } 

                if(roomBookingId){
                    setTimeout(() => {
                        navigate(`/facility_booking/${roomBookingId}`)         
                    }, 2000)                     
                }else{
                    let roomId = JSON.parse(localStorage.getItem('facilityPaymentParams'))?.room_id;
                    if(bookingStatus == "duplicate_booking" && paymentStatus == "success") {
                        setTimeout(() => {
                            navigate('/booking_selection')   
                            console.log('within navigate within duplicatebooking ',errorMessage)
                            localStorage.setItem('payment_message',errorMessage)
                            showSnackBar({message:errorMessage , severity: "error"})      
                        }, 2000) 
                    } else if(paymentStatus == "error") {
                        setTimeout(() => {
                            navigate('/booking_selection')   
                            console.log('within navigate within duplicatebooking paymentStatus error ',errorMessage)
                            localStorage.setItem('payment_message',errorMessage)
                            showSnackBar({message:errorMessage , severity: "error"})      
                        }, 2000) 
                    }
                    else if(roomId){
                    setTimeout(() => {
                        navigate(`/single_room_feature/${roomId}`)         
                    }, 2000)                     
                    }
                    
                }
            }else{
                navigate("/dashboard");
            }
        }            
    }, [])

  return (
    <Box sx={{height: "-webkit-fill-available", display: "grid", placeItems:"center", width: "100%"}}>
        <CircularProgress sx={{color: "#0F2C6F"}}/>
    </Box>
    )
}

 const mapDispatchToProps = (dispatch) => {
    return {
      facilityBookingPaymentStatusUpdate: (params) => dispatch(facilityBookingPaymentStatusUpdateAction(params)),
      facilityBookingPaymentStatusNull: () => facilityBookingPaymentStatusNullAction(),
      showSnackBar: (params) => dispatch(showSnackBarAction(params)),
    }
}

const mapStateToProps = state => {
    return {
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation)