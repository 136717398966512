import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {ReactComponent as DatePickerIcon} from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg';
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import {ReactComponent as DownloadIcon} from '../../Assets/icons/Download.svg';
import * as ReportACtion from "../../redux/actions/reportAction/index"
import { DatePicker } from "@mui/x-date-pickers";
import { connect } from "react-redux";
import { getLoggedUserData, getYYYYMMDDFormat, MenuProps, preventDatePickerKeyboard } from "../../Utils/utils";
import moment from "moment";
import axios from "axios";
import { DateRangePicker, combine } from 'rsuite';
import {ReactComponent as AddWhiteIcon} from '../../Assets/icons/WhiteRoundedPlusIcon.svg';
import "rsuite/dist/rsuite.css"

let SelectDays = ["Today","Yesterday","Last 7 days","Last 30 days","Last 90 days","Last 1 year","Custom"]  

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F',
    [`&.${tableCellClasses.head}`]: {
      fontSize: "13px",
      fontWeight: 550,
    
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
    }
  }));

const StyledTableRow = styled(TableRow)({
    cursor:'pointer',
    '&:nth-child(odd)': {
        backgroundColor: '#FAFBFF',

    },
    '&:nth-child(even)': {
        backgroundColor: '#FFFFFF'
    }
})

const AuditLog = ({getAccessHistory,accessHistory,getAccessFilter,accessFilter,auditModule,getEmpData,empData,handleBackButton})=>{
    
    const [rangeDate,setRangeDate] = useState(null)
    const [filterDate,setFilterDate] = useState("Last 90 days")
    const [data,setData] = useState({type:"",status:"",company:"",employee:"",module_name:"",startDate:new Date(),endDate:new Date(),search:'',rows:'10',level:''})
    const [page,setPage] = useState(1)
    const [disabled,setDisabled] = useState(false)
    const [loader,setLoader] = useState(false)
    const twentyNineDaysAgo = new Date();
    twentyNineDaysAgo.setDate(twentyNineDaysAgo.getDate() - 29);
    const [selectedDates, setSelectedDates] = useState([
        new Date(),
      new Date(),
    ]);

    const { afterToday, combine } = DateRangePicker;

    function handleDate(e) {
      console.log('e ', e);
      setSelectedDates(e)
      if (e != null) {
        setData(prev => ({ ...prev,
           ['startDate']: moment.utc(e[0], 'DD-MM-YYYY HH:mm:ss').local().format('YYYY-MM-DD'),
           ['endDate']: moment.utc(e[1], 'DD-MM-YYYY HH:mm:ss').local().format('YYYY-MM-DD')
          }));
      } else {
        setData(prev => ({ ...prev,
          ['startDate']: null,
          ['endDate']: null
         }));
      }
  }

    useEffect(()=>{
        getAccessFilter()
    },[])

    const callDownload = async()=>{
      console.log('accessHistory?.excel_path ',accessHistory?.excel_path)
      setDisabled(true)
      setLoader(true)
      try{
          let token=localStorage.getItem('userToken')
          let config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType:'arraybuffer'
          };
          let response = await axios.get(accessHistory?.excel_path,config)
          let blob = new Blob([response.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
          const url = URL.createObjectURL(blob)
          let tag = document.createElement("a")
          tag.setAttribute('href',url)
          tag.setAttribute('download',`Access History Report ${moment(new Date()).format('DD MMM YYYY')}`)
          tag.click()
          setDisabled(false)
          setLoader(false)
      }catch(err){
          setDisabled(false)
      }
  }

  useEffect(()=>{
    if(!accessHistory?.turnstileLogArrayListCount>0){
        setDisabled(true)
    }else{
        setDisabled(false)
    }   
  },[accessHistory])

    useEffect(()=>{
      let submitData = {
        company_id:data.company,
        limit:data.rows,
        offset:page-1,
        employee_type:data.type,
        start_date: data.startDate !=null ? getYYYYMMDDFormat(data.startDate) : '',
        end_date:data.endDate!=null ? getYYYYMMDDFormat(data.endDate) : "",
        employee_id:data.employee,
        entry_mode:data.status || '',
        export_excel:'excel',
        user_id:getLoggedUserData()?.user_id,
        level_type:data.level,
        module_name: data.module_name
     }
     console.log('submitData ',submitData)
    getAccessHistory(submitData)
    },[data,page])

    useEffect(()=>{ 
      getEmpData({
        company_id:data.company,
        employee_type:getLoggedUserData().role_id==4 ? '' :  data.type
      })
    },[data.company,data.type])

    useEffect(()=>{
      setData({
        ...data,
        company:"",
        employee:"",
      })
    },[data.type])

    useEffect(()=>{
        let today = new Date()
        let date = new Date()
    
        if(filterDate=="Yesterday"){
            date.setDate(today.getDate()-1)
        }else if(filterDate=="Last 7 days"){
            date.setDate(today.getDate()-7)
        }else if(filterDate=="Last 30 days"){
            date.setDate(today.getDate()-30)
        }else if(filterDate=="Last 90 days"){
            date.setDate(today.getDate()-90)
        }else if(filterDate=="Last 1 year"){
            date.setDate(today.getDate()-365)
        }
       
      },[filterDate])
    
      useEffect(()=>{
          getDateRange()
      },[filterDate])

      const getDateRange = ()=>{
        const today = new Date()
        const previousDate = new Date()
    
        if(filterDate=="Today"){
            setRangeDate(today)
        }else if(filterDate=="Yesterday"){  
            previousDate.setDate(previousDate.getDate()-1)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 7 days"){
            previousDate.setDate(previousDate.getDate()-7)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 30 days"){
            previousDate.setDate(previousDate.getDate()-30)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 90 days"){
            previousDate.setDate(previousDate.getDate()-90)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 1 year"){
            previousDate.setDate(previousDate.getDate()-365)
            setRangeDate(previousDate)
        }
      }

      const handleData = (e,datafrom)=>{
        console.log('module name ',datafrom)
        if(datafrom){
            setData({
                ...data,
                [datafrom]:e
            })
            setPage(1)
        }else{
            let {name,value} = e.target
            setData({
                ...data,
                [name]:value
            })
            if(name == 'type') {
              setData(prev => ({
                ...prev,
                ['module_name']:""
            }))
            }
            setPage(1)
        }
      }

    //   const callDownload = async()=>{
    //     setDisabled(true)
    //     setLoader(true)
    //     try{
    //         let token=localStorage.getItem('userToken')
    //         let config = {
    //             headers: {
    //               Authorization: `Bearer ${token}`,
    //             },
    //             responseType:'arraybuffer'
    //         };
    //         let response = await axios.get(faultDatas?.data_array?.excel_path,config)
    //         let blob = new Blob([response.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    //         const url = URL.createObjectURL(blob)
    //         let tag = document.createElement("a")
    //         tag.setAttribute('href',url)
    //         tag.setAttribute('download',`FaultReport Reports ${moment(new Date()).format('DD MMM YYYY')}`)
    //         tag.click()
    //         setDisabled(false)
    //         setLoader(false)
    //     }catch(err){
    //         setDisabled(false)
    //     }
    // }

      const handleReset = ()=>{
        setData({type:"",company:"",employee:"",module_name:"", startDate:new Date(),endDate:new Date(),search:'',level:"",rows:"10"})
        setSelectedDates([
           new Date(),
           new Date(),
        ])
        setPage(1)
      }

    const handlePagination = (event, value) => {
       setPage(value)
    }
    console.log('datas ',selectedDates)
    return(
        <>
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)",minHeight:'100%'}}>
                <Grid item xs={0.5} />
                <Grid item sx={{mt:7}} xs={11}>
        <Box>
        <Typography sx={{ fontSize: "26px", fontWeight: "500",color:"#0F2C6F",mt:2 }}>Audit Log</Typography>
        
        <Grid container sx={{backgroundColor:"#FFFFFF",mt:4,p:2}} >
          <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            {getLoggedUserData().role_id!=4 &&   <FormControl fullWidth size="small" sx={{ width: 160, }}>
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Type </InputLabel>
              <Select value={data.type} onChange={(e)=>handleData(e)}  name="type"
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                  <MenuItem value="bms_employee">BMS Employee</MenuItem>
                    <MenuItem value="tenant_employee">Tenant Employee</MenuItem>
              </Select>
            </FormControl>
            }
            {data.type=="tenant_employee"   &&
                <>
                  <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Company</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    name="company"
                    value={data.company} onChange={(e)=>handleData(e)}
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="Status"
                  >
                    {accessFilter?.map((value)=>(
                        <MenuItem value={value.company_id}>{value.company_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
               
                </>  
          }
            {(getLoggedUserData().role_id==4 || (getLoggedUserData().role_id!=4 && (data.type=="tenant_employee" || data.type=="bms_employee"))) &&  <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Employee</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={data?.employee} onChange={(e)=>handleData(e)} name="employee"
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="Status" 
                  >
                    {Array.isArray(empData) &&  empData?.map((value)=>(
                      <MenuItem value={value.employee_id}>{value.first_name} {value.last_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
          }   

           {data.type!='' &&  <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Module Name</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={data?.module_name} onChange={(e)=>handleData(e)} name="module_name"
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="Status" 
                  >
                       {auditModule?.map((value)=>(
                        <MenuItem value={value.module_name}>{value.module_name}</MenuItem>
                    ))}
                  </Select>
            </FormControl>
            }

            {/* <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Level</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={data?.level} onChange={(e)=>handleData(e)} name="level"
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="level" 
                  >
                      <MenuItem value='low'>Low Rise</MenuItem>
                      <MenuItem value='mid'>Mid Rise</MenuItem>
                      <MenuItem value='high'>High Rise</MenuItem>

                  </Select>
            </FormControl> */}
          <Box sx={{ width: 220,ml:2 }}>
            <DateRangePicker
             onClose={() => {
              // Handle close event here
              console.log("Calendar closed");
            }}
            style={{width:'220px'}}
                 placeholder="dd/mm/yyyy"
                    showOneCalendar
                    format='dd/MM/yyyy'
                    value={selectedDates}
                    onChange={handleDate}
                    onClean={() => {
                      // Handle close icon event here
                      setSelectedDates([
                        new Date(),
                      new Date(),
                    ])
                    setData(prev=> ({...prev, startDate:new Date(),endDate:new Date()}))
                    setPage(1)
                  }}
                    shouldDisableDate={combine((current) => {
                      console.log('twentyNineDaysAgo ',new Date('2022-01-22'))
                    return current < new Date('2022-01-22');
                    }, afterToday())}
                    ranges={[
                    {
                        label: '',
                    },
                    ]}
                
                />
              </Box>
            {/* {getLoggedUserData().role_id == 2 && <Box>
              <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
              <DatePicker
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                value={data.startDate}
                onChange={(e)=>handleData(e,"startDate")}
                label="From"
                name="startDate"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} sx={{color:"#0F2C6F"}} size="small" {...params} />}
                disableFuture={true}
              />
            </FormControl>
            <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
              <DatePicker
                value={data.endDate}
                name="endDate"
                inputFormat="dd/MM/yyyy"
                onChange={(e)=>handleData(e,"endDate")}
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                label="To"
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                disableFuture={true}
              />
            </FormControl></Box>} */}
            
            <Typography onClick={handleReset}  sx={{color:"#2764F4",mr:1,textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}}>Reset</Typography>
            
          {/* <Grid item  sx={{ display: 'flex', flex:1, justifyContent: 'end',}}>
                <Button
                  variant='contained'
                  onClick={callDownload}
                  disabled={disabled}
                  endIcon={<DownloadIcon/>}
                  sx={{
                    textTransform: 'none',
                    color: '#FFFFFF',
                    fontSize: { md: '14px', xs: '12px' },
                    padding: { md: '10px', xs: '5px' },
                    backgroundColor:'#123FA9',
                    height:50,
                    position:"relative",
                    alignSelf:'flex-end'
                  }}
              >
                Download .xls
                {loader && <CircularProgress size={20} sx={{position:'absolute',top:'50%',left:'50%'}} /> }
              </Button>
          </Grid> */}
          </Grid>
          <Grid  item xs={2} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <Button onClick={callDownload} variant="contained" sx={{color:"#FFFFFF",backgroundColor:"#123FA9","&:hover":{backgroundColor:"#123FA9"},textTransform:"capitalize",fontSize: { md: "14px", xs: "10px"} }} endIcon={<DownloadIcon/>}>Download</Button>
          </Grid>
           {/* <Grid item xs={10} />
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end',mt:2 }}>
                <Button
                  variant='contained'
                  onClick={callDownload}
                  disabled={disabled}
                  endIcon={<DownloadIcon/>}
                  sx={{
                    textTransform: 'none',
                    color: '#FFFFFF',
                    fontSize: { md: '14px', xs: '12px' },
                    padding: { md: '10px', xs: '5px' },
                    backgroundColor:'#123FA9',
                    height:50,
                    position:"relative"
                  }}
              >
                Download .xls
                {loader && <CircularProgress size={20} sx={{position:'absolute',top:'50%',left:'50%'}} /> }
              </Button>
          </Grid> */}
        </Grid>
        <Box sx={{backgroundColor:"#FFFFFF"}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Module Name</StyledTableCell>
                            <StyledTableCell>Event Type</StyledTableCell>
                            <StyledTableCell>Log Description</StyledTableCell>
                            <StyledTableCell>Role</StyledTableCell>
                            <StyledTableCell>Created By</StyledTableCell>
                            <StyledTableCell>Company</StyledTableCell>
                            <StyledTableCell sx={{minWidth:"200px"}}>Date Created</StyledTableCell>
                            {/* <StyledTableCell>Total Hours</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {accessHistory?.turnstileLogArrayList?.map((value)=>(
                          <StyledTableRow>
                            {console.log('accessHistory value ',value)}
                          <StyledTableCell>{value.module_name}</StyledTableCell>
                          <StyledTableCell>{value.event_type}</StyledTableCell>
                          <StyledTableCell>{value.log_description}</StyledTableCell>
                          <StyledTableCell>{value.role}</StyledTableCell>
                          {/* <StyledTableCell>{value.access_time!=null ?  moment.unix(value.access_time).format("DD MMM YYYY HH:mm") : ''}</StyledTableCell> */}
                          <StyledTableCell>{value.name}</StyledTableCell>
                          <StyledTableCell>{value.company_name}</StyledTableCell>
                          <StyledTableCell>{value.created_at!=null ?  moment.utc(value.created_at).local().format("DD MMM YYYY HH:mm") : ''}</StyledTableCell>
                      </StyledTableRow>
                      ))}
                    </TableBody>
                </Table>
            </TableContainer>
           
        </Box>      
        {accessHistory?.turnstileLogArrayList?.length < 1 && 
                        <Box sx={{ display: 'flex', justifyContent: 'center', m: 5 }}>
                            <Typography sx={{color:'#0F2C6F'}} variant='h6'>No Data Found</Typography>
                        </Box>
        }
        {accessHistory?.turnstileLogArrayListCount > 0 && 
        <Grid container >
        <Grid item xs={9}>
          <Pagination onChange={handlePagination} sx={{ display: 'flex',  my: 4}} count={Math.ceil(accessHistory?.turnstileLogArrayListCount / data.rows)} page={page} size="large" shape="rounded" />
        </Grid>
        <Grid item xs={3} sx={{display:'flex',alignItems:'center'}}>
          <Typography sx={{color:'#0F2C6F',mr:2}}>Show per page</Typography>
          <Select value={data.rows} name="rows" onChange={(e)=>{
                handleData(e)
                setPage(1)
                }} size="small" sx={{height:'40px',width:100}} >
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
              </Select>
        </Grid>
        </Grid>}
        
          
        </Box>

        </Grid>
        <Grid item xs={0.5} />
        </Grid>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        accessHistory:state.ReportsReducer.auditLogHistory,
        accessFilter:state.ReportsReducer.accessFilter.company,
        auditModule:state.ReportsReducer.accessFilter.auditLogModuleNameArrayList,
        empData:state.ReportsReducer.empData
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getAccessHistory:(data)=>(dispatch(ReportACtion.getAllAuditLog(data))),
        getAccessFilter:()=>(dispatch(ReportACtion.getAccessFilter())),
        getEmpData:(data)=>(dispatch(ReportACtion.getEmpData(data)))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(AuditLog);