import { Box, Button, Modal, Typography,Divider, TextField } from "@mui/material";
import React, { useState } from "react";
import {ReactComponent as CloseIcon} from '../../Assets/icons/Alertclose.svg';
import { DatePicker } from "@mui/x-date-pickers";
import { preventDatePickerKeyboard } from "../../Utils/utils";

const EditOffBoardingModal = ()=>{
    const [modalOpen,setModalOpen] = useState(true)
    const [offboardDate,setOffboardDate] = useState('');
    const [offboardError,setOffboardError] = useState();

    const handleDate = (value)=>{
           
            setOffboardDate(value)
    }
    const handleModalClose = ()=>{
        setModalOpen(false)
    }

    const validate = ()=>{
        let error = true
        if(!offboardDate){
            setOffboardError("Select Date")
            error = false
        }
        return error;
    }

    const handleConfirm = ()=>{
       if(validate()){
       
       }
    }
    return(
        <>
        <Modal open={modalOpen}>
            <Box sx={{position:'absolute',top:'20%',left:'30%',backgroundColor:'#FFFFFF',borderRadius:'8px',width:'600px',padding:'24px'}}>
                <Box sx={{display:'flex',justifyContent:'space-between'}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:'18px'}}>Edit OffBoarding Date</Typography>
                    <CloseIcon onClick={handleModalClose} />
                </Box>
                <Divider sx={{my:2}} />
                <Typography sx={{color:'#0F2C6F',fontSize:"14px"}}>Are you sure you want to offboard Muhamad Farhan on below date?</Typography>
              
               

                <Box sx={{position:'relative',width:'200px',height:'121px'}}>
                <Typography sx={{color:'#0F2C6F',fontWeight:600, fontSize:'14px',mb:2,mt:4}}>Offboard Date</Typography>
                    {/* <DatePicker className="dateStyle" selected={offboardDate}  dateFormat="dd MMM yyyy" onChange={(value)=>handleDate(value)}/>
                    <CalendarIcon style={{position:'absolute',cursor:'pointer',right:'1%',top:'40%'}}/> */}
                    <DatePicker value={offboardDate} onChange={(value)=>handleDate(value)} inputFormat='dd/MM/yyyy'  renderInput={(params)=><TextField {...params} onKeyDown={(e)=>preventDatePickerKeyboard(e)} />} />
                    {offboardError && <Typography sx={{color:'#f44336',mt:1}}>{offboardError}</Typography>}
                </Box>

                <Box sx={{display:'flex',justifyContent:"space-between",mt:5}}>
                    <Button variant="outlined" onClick={handleModalClose}  sx={{color:'#0F2C6F',height:'40px',fontSize:'14px',textTransform:"capitalize",border:'1px solid #AAC3FF',borderRadius:'6px','&:hover':{backgroundColor:"#FFFFFF"}}}>Cancel</Button>
                    <Button variant="contained" onClick={handleConfirm} sx={{color:'#FFFFFF',height:'40px',backgroundColor:'#BD0251',textTransform:'capitalize',borderRadius:"6px",'&:hover':{backgroundColor:"#BD0251"}}}>Confirm</Button>
                </Box>
            </Box>
        </Modal>
        
        </>
    )
}

export default EditOffBoardingModal;