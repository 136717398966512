import { Button, Grid, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../../Assets/icons/back.svg';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import * as CommonAction from '../../../redux/actions/commonAction';
import { getTimeStamp } from "../../../Utils/utils";
import PrintIcon from '@mui/icons-material/Print';
import { TbMailForward } from "react-icons/tb";
import { MdOutlineSms } from "react-icons/md";
import  ResendMailIcon  from '../../../Assets/icons/email.png';
import { ReactComponent as ResendSmsIcon } from '../../../Assets/icons/submit.svg';
import ToastAlert from "../../common/Alert";


const StyledTypography = styled(Typography)({
    color:"#0F2C6F",
    fontSize:"13px",
    fontWeight:"500"
})

const StyledTableCell = styled(TableCell)({
    color:"#0F2C6F",
    fontSize:"12px",
    [`&.${tableCellClasses.head}`]:{
        fontWeight:550
    },
    [`&.${tableCellClasses.body}`]:{
        fontWeight:500,
        border:'none'
    }
})

const ReviewRegistered = ({getInvitationDetails,invitationDetails,resendInviteMail,resendInviteMailNullAction,resendInviteMailStatus})=>{ 
    const navigate = useNavigate()
    let {id,type} = useParams()
    const [showIframe,setShowIframe] = useState(false)
    const [url,setUrl] = useState(null)
    const [empOffStatus, setEmpOffStatus] = useState({ message: '', status: false, color: '', icon: '' })
    useEffect(()=>{
        getInvitationDetails({
            id:parseInt(id),
            type:type
        })
    },[])

    useEffect(()=>{
        setTimeout(()=>{
            setShowIframe(false)
        },2000)
    },[showIframe])

    useEffect(() => {
        if(resendInviteMailStatus?.response != null) {
        if(resendInviteMailStatus?.response?.response_status == 'success') {
            setEmpOffStatus({ message: resendInviteMailStatus?.response?.response_message, status: true, color: 'green', icon: 'tick' })
            setTimeout(()=> {
                resendInviteMailNullAction()
                setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
            },5000)
        } else if(resendInviteMailStatus?.response?.response_status == 'fail') {
            setEmpOffStatus({ message: resendInviteMailStatus?.response?.response_message, status: true, color: 'red', icon: 'close' })
            setTimeout(()=> {
                resendInviteMailNullAction()
                setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
            },5000)
        } else {
            setEmpOffStatus({ message: 'something went wrong', status: true, color: 'red', icon: 'close' })
            setTimeout(()=> {
                resendInviteMailNullAction()
                setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
            },5000)
        }
    }
    },[resendInviteMailStatus])

    const handleResendInviteMail = (invitation_id) => {
        resendInviteMail({sent_invitations_id:invitation_id})
    }
    return(
        <>
        {empOffStatus.status && <ToastAlert message={empOffStatus.message} status={empOffStatus.status} color={empOffStatus.color} icon={empOffStatus.icon} />}
        {invitationDetails=='fail' ? 
            <Box sx={{background: 'rgba(143, 146, 161,0.05)',height:"100%",width:'100%',display:'flex',justifyContent:"center",alignItems:"center"}}>
                <Typography sx={{fontSize:'22px',color:'#0F2C6F'}}>No Data Found</Typography>
            </Box> : 
            <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}}>
                <Grid item xs={2} />

                <Grid item xs={8}>
                    <Box sx={{mt:7}}>
                        <BackIcon onClick={() => navigate(-1)} />
                        <Typography sx={{color:"#0F2C6F",fontSize:"26px",mt:2}}>Registered Visitor</Typography>
                        <Typography sx={{color:"#9AA6C3",fontSize:"12px",mt:1}}>Assigned on {invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).local().format('DD MMM YYYY')}</Typography>
                    </Box>
                    
                    <Grid container rowSpacing={3} sx={{backgroundColor:"#FFFFFF",p:3,mt:3}}>
                        <Grid item xs={12}>
                            <Box sx={{width:"50%",display:'flex',justifyContent:"space-between"}}>
                                <Typography sx={{color:"#0F2C6F",fontSize:"13px",fontWeight:550}}>Reason for Visit</Typography>
                                <StyledTypography>{invitationDetails?.notes_to_visitor}</StyledTypography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Registered by</Typography>
                        </Grid>
                        <Grid item container xs={12}>
                                <Grid item xs={3}>
                                    <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:550}}>{invitationDetails?.invited_by}</Typography>
                                    <StyledTypography>{invitationDetails?.job_title}</StyledTypography>
                                    <StyledTypography>Office Unit : {invitationDetails?.comp_office_unit}</StyledTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    <StyledTypography>Office:{invitationDetails?.office_country_code} {invitationDetails?.office_phone_no}</StyledTypography>
                                    <StyledTypography>Email:{invitationDetails?.invited_by_email}</StyledTypography>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <StyledTypography>Laura Lee</StyledTypography>
                                    <StyledTypography>Laura Lee</StyledTypography> */}
                                </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ border: '1px solid #DBDFE9', borderRadius: '6px', minHeight: 100, display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                <Box sx={{ p: 2, }}>
                                <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Invitation Period
                                </Typography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Start : {invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).local().format('DD MMM YYYY HH:mm ' )}
                                </StyledTypography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    End : {invitationDetails?.to_date_time!=null && moment.utc(invitationDetails?.to_date_time).local().format('DD MMM YYYY HH:mm ' )}
                                </StyledTypography>
                                </Box>
                                <Box sx={{ p: 2, pl: 6 }}>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    {invitationDetails?.visitors?.length} Visitor
                                </StyledTypography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Company: {invitationDetails?.company_name}
                                </StyledTypography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Unit: {invitationDetails?.comp_office_unit}
                                </StyledTypography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Contact</StyledTableCell>
                                            <StyledTableCell>Time In</StyledTableCell>
                                            <StyledTableCell>Time Out</StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                            <StyledTableCell>QR</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            invitationDetails?.visitors?.map((value)=>(
                                            <TableRow>
                                                <StyledTableCell sx={{display:'flex'}}><Box sx={{alignSelf:'flex-end'}}>{value.name}</Box> {(invitationDetails?.inv_type == 'Single_vistor' || invitationDetails?.inv_type == 'Tenant_Invite') && <Box>{value.entry_mode == 1 ? (<Tooltip title='Resend Sms'><IconButton disabled={resendInviteMailStatus?.isLoading}  onClick={() => handleResendInviteMail(value.sent_invitations_id)} sx={{height:'20px'}}><Box sx={{ width:'20px', height:'24px'}}><ResendSmsIcon style={{height:'20px', width:'20px'}}/></Box></IconButton></Tooltip>)
                                                    :(<Tooltip title='Resend Mail'><IconButton  disabled={resendInviteMailStatus?.isLoading} onClick={() => handleResendInviteMail(value.sent_invitations_id)} sx={{height:'20px', padding:'0 8px'}}><Box sx={{ width:'20px'}}><img style={{width:'100%', height:'100%'}} src={ResendMailIcon}/></Box></IconButton></Tooltip>)    
                                                }</Box>}</StyledTableCell>
                                                <StyledTableCell>{value.email}</StyledTableCell>
                                                <StyledTableCell>{value.last_in_time!=null ? moment.utc(value.last_in_time).local().format('HH:mm') : '_'}</StyledTableCell>
                                                <StyledTableCell>{value.last_out_time!=null ? moment.utc(value.last_out_time).local().format('HH:mm') : '_'}</StyledTableCell>
                                                <StyledTableCell>{invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).local().format('DD MMM yyyy')}</StyledTableCell>
                                                <StyledTableCell>
                                                {value.url!='' &&   <PrintIcon onClick={()=>{
                                                        setUrl(value?.url)
                                                        setShowIframe(true)
                                                        }} ></PrintIcon>}
                                                </StyledTableCell>
                                            </TableRow> 
                                            ))
                                        }
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {showIframe && <iframe src={url} style={{visibility:'hidden'}} />}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={2} />
            </Grid>
        }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      invitationDetails:state.ReceptionReducer.invitationDetails,
      resendInviteMailStatus: state.CommonReducer.resendInviteMail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getInvitationDetails: (requestData) => { dispatch(receptionActions.getInvitationDetails(requestData)) },
      resendInviteMail: (data) => (dispatch(CommonAction.resendInviteMail(data))),
      resendInviteMailNullAction: () => dispatch(CommonAction.resendInviteMailNullAction()),
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(ReviewRegistered)
