import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {ReactComponent as DatePickerIcon} from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg';
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import {ReactComponent as DownloadIcon} from '../../Assets/icons/Download.svg';
import * as ReportACtion from "../../redux/actions/reportAction/index"
import { DatePicker } from "@mui/x-date-pickers";
import { connect } from "react-redux";
import { getLoggedUserData, getYYYYMMDDFormat, MenuProps, preventDatePickerKeyboard } from "../../Utils/utils";
import moment from "moment";
import axios from "axios";

let SelectDays = ["Today","Yesterday","Last 7 days","Last 30 days","Last 90 days","Last 1 year","Custom"]  

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            <Typography variant="p">{children}</Typography>
          </Box>
        )}
      </Box>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const StyledTab = styled(Tab)(({ theme }) => ({
    color: '#6F80A9',
    textTransform: "none",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F',
    [`&.${tableCellClasses.head}`]: {
      fontSize: "13px",
      fontWeight: 550,
    
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
    }
  }));

const StyledTableRow = styled(TableRow)({
    cursor:'pointer',
    '&:nth-child(odd)': {
        backgroundColor: '#FAFBFF',

    },
    '&:nth-child(even)': {
        backgroundColor: '#FFFFFF'
    }
})

const TurnStileAccessHistory = ({getAccessHistory,accessHistory,getAccessFilter,accessFilter,getEmpData,empData,handleBackButton})=>{
    const [rangeDate,setRangeDate] = useState(null)
    const [filterDate,setFilterDate] = useState("Last 90 days")
    const [data,setData] = useState({type:"",status:"",company:"",employee:"",startDate:new Date(),endDate:new Date(),search:'',rows:'10',level:''})
    const [page,setPage] = useState(1)
    const [disabled,setDisabled] = useState(false)
    const [loader,setLoader] = useState(false)
    const [value, setValue] = useState(0);
    
    const tabs = {0:'all', 1:'bms_employee',2:'tenant_employee',3:'visitors'}


    useEffect(()=>{
        getAccessFilter()
    },[])
    console.log('data ',data)

    const callDownload = async()=>{
      setDisabled(true)
      setLoader(true)
      try{
          let token=localStorage.getItem('userToken')
          let config = {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              responseType:'arraybuffer'
          };
          let response = await axios.get(accessHistory?.excel_path,config)
          let blob = new Blob([response.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
          const url = URL.createObjectURL(blob)
          let tag = document.createElement("a")
          tag.setAttribute('href',url)
          tag.setAttribute('download',`Access History Report ${moment(new Date()).format('DD MMM YYYY')}`)
          tag.click()
          setDisabled(false)
          setLoader(false)
      }catch(err){
          setDisabled(false)
      }
  }

  useEffect(()=>{
    if(!accessHistory?.turnstileLogArrayListCount>0){
        setDisabled(true)
    }else{
        setDisabled(false)
    }   
  },[accessHistory])

  useEffect(() => {
    setPage(1)
  },[value])

    useEffect(()=>{
      let submitData = {
        company_id:data.company,
        limit:data.rows,
        offset:page-1,
        employee_type:tabs[value],
        start_date: data.startDate !=null ? getYYYYMMDDFormat(data.startDate) : '',
        end_date:data.endDate!=null ? getYYYYMMDDFormat(data.endDate) : "",
        employee_id:data.employee,
        entry_mode:data.type || '',
        export_excel:'excel',
        user_id:getLoggedUserData()?.user_id,
        level_type:data.level
     }
     console.log('submitData ',submitData)
    getAccessHistory(submitData)
    },[data,page])
console.log('page ',page)
    useEffect(()=>{ 
      getEmpData({
        company_id:data.company,
        employee_type:getLoggedUserData().role_id==4 ? '' :  tabs[value]
      })
    },[data.company,data.type])

    // useEffect(()=>{
    //   setData(prev=>({
    //     ...prev,
    //     company:"",
    //     employee:"",
    //   }))
    // },[data.type])

    useEffect(()=>{
        let today = new Date()
        let date = new Date()
    
        if(filterDate=="Yesterday"){
            date.setDate(today.getDate()-1)
        }else if(filterDate=="Last 7 days"){
            date.setDate(today.getDate()-7)
        }else if(filterDate=="Last 30 days"){
            date.setDate(today.getDate()-30)
        }else if(filterDate=="Last 90 days"){
            date.setDate(today.getDate()-90)
        }else if(filterDate=="Last 1 year"){
            date.setDate(today.getDate()-365)
        }
       
      },[filterDate])
    
      useEffect(()=>{
          getDateRange()
      },[filterDate])

      const getDateRange = ()=>{
        const today = new Date()
        const previousDate = new Date()
    
        if(filterDate=="Today"){
            setRangeDate(today)
        }else if(filterDate=="Yesterday"){  
            previousDate.setDate(previousDate.getDate()-1)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 7 days"){
            previousDate.setDate(previousDate.getDate()-7)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 30 days"){
            previousDate.setDate(previousDate.getDate()-30)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 90 days"){
            previousDate.setDate(previousDate.getDate()-90)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 1 year"){
            previousDate.setDate(previousDate.getDate()-365)
            setRangeDate(previousDate)
        }
      }

      const handleData = (e,datafrom)=>{
        if(datafrom){
            console.log('datafrom ',datafrom)
            setData(prev=>({
                ...prev,
                [datafrom]:e
            }))
            setPage(1)
        }else{
            let {name,value} = e.target
            setData(prev=>({
                ...prev,
                [name]:value
            }))
            setPage(1)
        }
      }

      const handleReset = ()=>{
        setData({type:"",company:"",employee:"",startDate:new Date(),endDate:new Date(),search:'',level:"",rows:"10"})
        setPage(1)
      }
      console.log('value ',value)
    const handlePagination = (event, value) => {
       setPage(value)
    }
    const handleChange = (event, newValue) => {
        console.log('newValue ',tabs[newValue])
        setValue(newValue);
      setData({type:"",company:"",employee:"",startDate:new Date(),endDate:new Date(),search:'',level:"",rows:"10"})
      if(newValue == 1 || newValue == 2) {
        getEmpData({
            company_id:data.company,
            employee_type: tabs[newValue]
          })
      }


    };
    console.log('accessHistory ',accessHistory)

    return(
        <Box >
        <BackButton onClick={()=>handleBackButton()}/>
        <Box sx={{mb:'30px'}}>
            <Typography sx={{ fontSize: "26px", fontWeight: "500",color:"#0F2C6F",mt:2 }}>Turnstile Access History</Typography>
            <Typography sx={{fontSize: "14px", lineHeight:'24px', letterSpacing:'-0.25px', fontWeight: "500", fontFamily:'Inter', color:"#0F2C6F",}}>Below contains the list of turnstile access history.</Typography>
        </Box>
        <Box
            sx={{display:'flex', justifyContent:'space-between', background: "white",  borderBottom: "1px solid #DBDFE9", borderRadius: "8px 8px 0px 0px", }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{p:'16px 8px 0 8px',}}
              TabIndicatorProps={{ style: { background: "#123FA9", } }}
            >
              <StyledTab
                label="All"
                {...a11yProps(0)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
              <StyledTab
                label="Staff"
                {...a11yProps(1)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
              <StyledTab
                label="Tenant"
                {...a11yProps(2)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
                <StyledTab
                label="Visitors"
                {...a11yProps(3)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
            </Tabs>
            <Box sx={{ display: 'flex', justifyContent: 'end', alignSelf:'center' }}>
                <Button
                  variant='contained'
                  onClick={callDownload}
                  // disabled={disabled}
                  endIcon={<DownloadIcon/>}
                  sx={{
                    textTransform: 'none',
                    color: '#FFFFFF',
                    fontSize: { md: '14px', xs: '14px' },
                    padding: { md: '8px 10px', xs: '5px' },
                    borderRadius:'6px',
                    backgroundColor:'#123FA9',
                    height:50,
                    fontFamily:'Inter',
                    position:"relative"
                  }}
              >
                Download .xls
                {loader && <CircularProgress size={20} sx={{position:'absolute',top:'50%',left:'50%'}} /> }
              </Button>
          </Box>
          </Box>
        {[1,2,3,4].map((item,index) => (
            <TabPanel value={value} index={index} style={{ background: "white" }}>
        <Box sx={{backgroundColor:"#FFFFFF",p:2}}>
        <Grid container sx={{backgroundColor:"#FFFFFF",p:2}} >
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            { (value != 0 && value != 1) &&
                <>
                  <FormControl fullWidth size="small" sx={{ width: 160, }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Company</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    name="company"
                    value={data.company} onChange={(e)=>handleData(e)}
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="Status"
                  >
                    {accessFilter?.map((value)=>(
                        <MenuItem value={value.company_id}>{value.company_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
               
                </>  
          }
            {(getLoggedUserData().role_id==4 || (getLoggedUserData().role_id!=4 && (data.type=="tenant_employee" || data.type=="bms_employee" || value==1 || value==2))) &&  <FormControl fullWidth size="small" sx={{ width: 160,ml: value == 1 ? 0 : 2}}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Employee</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={data?.employee} onChange={(e)=>handleData(e)} name="employee"
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="Status" 
                  >
                    {Array.isArray(empData) &&  empData?.map((value)=>(
                      <MenuItem value={value.employee_id}>{value.first_name} {value.last_name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
          }   

            {getLoggedUserData().role_id!=4 &&   <FormControl fullWidth size="small" sx={{ width: 160, ml:value == 0 ? 0 :2}}>
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Type </InputLabel>
              <Select value={data.type} onChange={(e)=>handleData(e)}  name="type"
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                  <MenuItem value="In">In Data Only</MenuItem>
                  <MenuItem value="Out">Out Data Only</MenuItem>
                    <MenuItem value="Both">In & Out Data</MenuItem>
              </Select>
            </FormControl>
            }

           {/* {data.type!='' &&  <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={data?.status} onChange={(e)=>handleData(e)} name="status"
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="Status" 
                  >
                      <MenuItem value='IN'>IN</MenuItem>
                      <MenuItem value='OUT'>OUT</MenuItem>
                  </Select>
            </FormControl>
            } */}

            <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Level</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    value={data?.level} onChange={(e)=>handleData(e)} name="level"
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                    label="level" 
                  >
                      <MenuItem value=''>All Storeys</MenuItem>
                      <MenuItem value='eot'>EOT</MenuItem>
                      <MenuItem value='low'>Low Rise</MenuItem>
                      <MenuItem value='mid'>Mid Rise</MenuItem>
                      <MenuItem value='high'>High Rise</MenuItem>

                  </Select>
            </FormControl>

            <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
              <DatePicker
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                value={data.startDate}
                onChange={(e)=>handleData(e,"startDate")}
                label="From"
                name="startDate"
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} sx={{color:"#0F2C6F"}} size="small" {...params} />}
                disableFuture={true}
              />
            </FormControl>
            <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
              <DatePicker
                value={data.endDate}
                name="endDate"
                inputFormat="dd/MM/yyyy"
                onChange={(e)=>handleData(e,"endDate")}
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                label="To"
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                disableFuture={true}
              />
            </FormControl>
            <Typography onClick={handleReset}  sx={{color:"#2764F4",mr:1,textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}}>Reset</Typography>
          </Grid>
           <Grid item xs={10} />
          {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end',mt:2 }}>
                <Button
                  variant='contained'
                  onClick={callDownload}
                  disabled={disabled}
                  endIcon={<DownloadIcon/>}
                  sx={{
                    textTransform: 'none',
                    color: '#FFFFFF',
                    fontSize: { md: '14px', xs: '12px' },
                    padding: { md: '10px', xs: '5px' },
                    backgroundColor:'#123FA9',
                    height:50,
                    position:"relative"
                  }}
              >
                Download .xls
                {loader && <CircularProgress size={20} sx={{position:'absolute',top:'50%',left:'50%'}} /> }
              </Button>
          </Grid> */}
        </Grid>
        <Box sx={{backgroundColor:"#FFFFFF"}}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            {value == 0 && <StyledTableCell>Type</StyledTableCell>}
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>{value ==3 ? 'Visiting Company' :'Company'}</StyledTableCell>
                            <StyledTableCell>Time</StyledTableCell>
                            {/* <StyledTableCell>Total Hours</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {accessHistory?.turnstileLogArrayList?.map((item)=>(
                          <StyledTableRow>
                          <StyledTableCell>{item.name}</StyledTableCell>
                          {value == 0 && <StyledTableCell>{item.emp_type}</StyledTableCell>}
                          <StyledTableCell><Typography sx={{display:'flex',justifyContent:'center', alignItems:'center', padding:'0px 8px 0px 8px', backgroundColor: item?.entry_mode == 'In' ? '#CFDDFE' : item?.entry_mode == 'Out' ? '#D3F2E5':'', width:'28px', height:'24px', fontSize:'14px', fontFamily:'Inter', fontWeight:'400', letterSpacing:'-0.25px',color:'#0F2C6F', borderRadius:'6px', textAlign:'center'}}>{item.entry_mode}</Typography></StyledTableCell>
                          <StyledTableCell>{item.company_name}</StyledTableCell> 
                          <StyledTableCell>{item.access_time!=null ?  moment.unix(item.access_time).format("DD MMM YYYY HH:mm") : ''}</StyledTableCell>
                          {/* <StyledTableCell>Total Hours</StyledTableCell> */}
                      </StyledTableRow>
                      ))}
                    </TableBody>
                </Table>
            </TableContainer>
           
            </Box>      
        </Box>
            </TabPanel>
        ))}
     

        {accessHistory?.turnstileLogArrayList?.length < 1 && 
                        <Box sx={{ display: 'flex', justifyContent: 'center', m: 5 }}>
                            <Typography sx={{color:'#0F2C6F'}} variant='h6'>No Data Found</Typography>
                        </Box>
        }
        {accessHistory?.turnstileLogArrayListCount > 0 && 
        <Grid container >
        <Grid item xs={9}>
          <Pagination onChange={handlePagination} sx={{ display: 'flex',  my: 4}} count={Math.ceil(accessHistory?.turnstileLogArrayListCount / data.rows)} page={page} size="large" shape="rounded" />
        </Grid>
        <Grid item xs={3} sx={{display:'flex',alignItems:'center'}}>
          <Typography sx={{color:'#0F2C6F',mr:2}}>Show per page</Typography>
          <Select value={data.rows} name="rows" onChange={(e)=>{
                handleData(e)
                setPage(1)
                }} size="small" sx={{height:'40px',width:100}} >
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
              </Select>
        </Grid>
        </Grid>}
        
          
        </Box>
    )
}

const mapStateToProps = (state)=>{
    return{
        accessHistory:state.ReportsReducer.accessHistory,
        accessFilter:state.ReportsReducer.accessFilter.company,
        empData:state.ReportsReducer.empData
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getAccessHistory:(data)=>(dispatch(ReportACtion.getAccessHistory(data))),
        getAccessFilter:()=>(dispatch(ReportACtion.getAccessFilter())),
        getEmpData:(data)=>(dispatch(ReportACtion.getEmpData(data)))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TurnStileAccessHistory);