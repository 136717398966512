import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Pagination,
  Select,
  styled,
} from "@mui/material";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F2C6F",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
  },
}));

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  "&:nth-child(odd)": {
    backgroundColor: "#FAFBFF",
  },
  "&:nth-child(even)": {
    backgroundColor: "#FFFFFF",
  },
});

const VisitorManagement = ({ handleBackButton }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = () => {
    const userToken = localStorage.getItem("userToken");

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/visitor_report`,
        {
          start_date: startDate.toISOString().split("T")[0], // format as YYYY-MM-DD
          end_date: endDate.toISOString().split("T")[0], // format as YYYY-MM-DD
          limit,
          page,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((response) => {
        setData(response.data.response_data);
        setTotalPages(response.data.total_pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, limit, page]);

  const handleDateRangeChange = (range) => {
    if (range) {
      setStartDate(range[0]);
      setEndDate(range[1]);
    }
  };

  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const disabledDate = (date) => {
    return date < thirtyDaysAgo || date > today;
  };

  return (
    <Box>
      <BackButton
        style={{ cursor: "pointer" }}
        onClick={() => handleBackButton()}
      />
      <Typography
        sx={{ fontSize: "26px", fontWeight: "500", color: "#0F2C6F", mt: 2 }}
      >
        Visitor History
      </Typography>

      <Grid container sx={{ backgroundColor: "#FFFFFF", mt: 4, p: 2 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "235px", ml: 2 }}>
            <DateRangePicker
              style={{ width: "235px" }}
              placeholder="dd/mm/yyyy"
              showOneCalendar
              format="dd/MM/yyyy"
              value={[startDate, endDate]}
              onChange={handleDateRangeChange}
              disabledDate={disabledDate}
            />
          </Box>

          <Typography
            onClick={() => {
              setStartDate(new Date());
              setEndDate(new Date());
            }}
            sx={{
              color: "#2764F4",
              mr: 1,
              textDecoration: "underline",
              fontSize: "16px",
              ml: 2,
              textUnderlineOffset: "5px",
              cursor: "pointer",
            }}
          >
            Reset
          </Typography>
        </Grid>
        <Grid item xs={10} />
      </Grid>
      <Box sx={{ backgroundColor: "#FFFFFF" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Visitor Name</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                <StyledTableCell>Invitation Name</StyledTableCell>
                <StyledTableCell>Entry</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((visitor, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{visitor?.visitor_name}</StyledTableCell>
                    <StyledTableCell>{visitor?.inv_type}</StyledTableCell>
                    <StyledTableCell>{visitor?.invitations_title}</StyledTableCell>
                    <StyledTableCell>{visitor?.entry_mode}</StyledTableCell>
                    <StyledTableCell>
                    {visitor?.Access_Time}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    No data available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
        <Grid item xs={9}>
          <Pagination
            page={page}
            onChange={(e, value) => setPage(value)}
            sx={{ py: 3, display: "flex", justifyContent: "center" }}
            size="large"
            shape="rounded"
            count={totalPages}
          ></Pagination>
        </Grid>
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ color: "#0F2C6F", mr: 2 }}>
            Show per page
          </Typography>
          <Select
            value={limit}
            onChange={(e) => {
              setLimit(e.target.value);
              setPage(1);
            }}
            size="small"
            sx={{ height: "40px", width: 100 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisitorManagement;
