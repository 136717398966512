import { Box, Button, FormControl, Grid, InputLabel,  Tab, Tabs, MenuItem, OutlinedInput, Checkbox, Select,  Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {ReactComponent as BackIcon} from  '../../Assets/icons/back.svg';
import {ReactComponent as RefreshIcon} from '../../Assets/icons/Refresh.svg';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import {ReactComponent as CheckboxIcon} from '../../Assets/icons/CheckboxIcon.svg';
import styled from "@emotion/styled";
import PhoneInput from "react-phone-input-2";
import { useNavigate,useParams } from "react-router-dom";
import * as CommonAction from '../../redux/actions/commonAction';
import * as BmsStaffAction from "../../redux/actions/bmsStaffAction";
import { connect } from "react-redux";
import {getLastUpdatedTime,getLoggedUserData, getYYYYMMDDFormat, preventDatePickerKeyboard} from '../../Utils/utils.js';
import {initialSystemAccess,} from '../../components/Data/Data.js';
import ToastAlert from "../common/Alert";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
 
const StyledTypography = styled(Typography)({
    color:'#0F2C6F',
    fontSize:'14px'
})


const StyledTableCell = styled(TableCell)(({theme})=>({
    color:" #0F2C6F",
    [`&.${tableCellClasses.head}`]:{
        fontSize:'13px',
        fontWeight:550
    },
    [`&.${tableCellClasses.body}`]:{
        fontSize:"14px",
        fontWeight:500
    }
}))

const StyledOutlinedInput = styled(OutlinedInput)({
    borderRadius:'6px',
    width:'90%',
    height:'48px',
    color:'#0F2C6F',
    marginTop:'10px'
})

const StyledTab = styled(Tab)({
    textTransform:'capitalize',
    color:' #123FA9'
})

const spanStyle={
    backgroundColor:'#123FA9',
    color:'#FFFFFF',
    borderRadius:'50%',
    padding:'4px 6px',
    fontSize:'12px',
    marginRight:'5px'
}



const UpdateEmpsDetails = ({empDetails,getEmpDetailsById,updateEmployeeStatus, defaultAccessRights, userAccessRights,getdefaultAccessRights, updateEmployee,setStatusNull,getAllLevels,getUnitsByLevelid,levelDatas,unitDatas, getAllRoles, roleData, getStaffRoles,bmsRoles})=>{
    const navigate = useNavigate();
    const {emp_id,activeType} = useParams();
    const [employeeDetails,setEmployeeDetails] = useState([]);
    const [systemAccess,setSystemAccess] = useState(initialSystemAccess)
    const [accessRights,setAccessRights] = useState([])
    const [submissionCheck,setSubmissionCheck] = useState(false)
    const [submissionRights,setSubmissionRights] = useState([])
    const [approvalRights,setApprovalRights] = useState([])
    const [approvalCheck,setApprovalCheck] = useState(false)
    const [companyDetails,setCompanyDetails] = useState({});
    const [time,setTime] = useState({hour:'00',minute:'00',am_pm:''})
    const [empData,setEmpData] = useState(
    {firstName:'',
     lastName:'',mail:'',jobPosition:'',mobile:"",office:'',onboardDate:"",offboardDate:"",submittedBy:"",jobTitle:"",tenantRole:"",level:'',unit:'', role_id: ""})
    const [empUpdateStatus, setEmpUpdateStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [accessStartDate,setAccessStartDate] = useState(null)
    const [accessError,setAccessError] = useState('')
    const [allRights,setAllRights] = useState(true)
    const [startDate,setStartDate] = useState(null)   
    const [tabValue,setTabValue] = useState(0);
    const [tabDisabled,setTabDisabled] = useState({tab2:true}) 
    const [userRightsCount, setUserRightsCount] = useState([])
    console.log('accessRights ',accessRights)

    useEffect(()=>{
      setTime({...time,timeStr:getLastUpdatedTime()})
      getEmpDetailsById({employee_id:emp_id})
      getAllLevels()
        if(checkTenantManger()){
            getAllRoles();
        }else if(checkBMSRoles()){
            getStaffRoles();
        }

        
    },[])

    useEffect(()=>{
        if(empData.role_id){
            console.log('bmsrolechange')
            getdefaultAccessRights({role_id:empData.role_id})
        }
     
    },[empData.role_id])

    useEffect(()=>{

        let array = [];
        const newArray = defaultAccessRights?.map(obj => ({ ...obj }));
        const newArray2 = userAccessRights?.map(obj => ({ ...obj }));

        // Create a map from array2 for faster lookup
const mapArray2 = newArray2?.reduce((map, obj) => {
    map[obj.access_rights_id] = obj.checked_status === 1; // true if check is 1, false otherwise
    return map;
}, {});

// Update array1 with isSelected field based on array2
const updatedArray1 = newArray?.map(obj => ({
    ...obj,
    isSelected: mapArray2[obj.access_rights_id] || false,
    isDisabled: false
}));

console.log('updatedarray ',updatedArray1);

        console.log('new ',userAccessRights)
        for(let j =0;j<newArray.length;j++){
            let obj = newArray[j]
            obj["isSelected"] = newArray[j].access_rights==1 ? true : false
            obj["isDisabled"] = false
            array.push(obj)
        }
        setAccessRights(updatedArray1)
    },[defaultAccessRights])

    useEffect(() => {
        if(userAccessRights?.length > 0) {
            // const newArray = userAccessRights?.filter(obj => { if(obj.checked_status == 1) return obj.access_rights_id});
            const newArray = userAccessRights?.filter(obj => obj.checked_status === 1).map(obj => obj.access_rights_id);
            console.log('user newArray ',newArray)
            setUserRightsCount(newArray)
        }
    },[userAccessRights])

    useEffect(() => {
        if(accessRights) {
        const acc = accessRights?.filter((item) => item.isSelected == false)
        
        if(acc?.length <=0) {
            setAllRights(true)
        } else {
            setAllRights(false)
        }
        }
    },[accessRights])
    
    

    const isAccessRightsCountChanged = () => {
        let newArray = accessRights?.filter(obj => obj.isSelected === true).map(obj => obj.access_rights_id);
        newArray.sort()
        let userRightsCountSorted = userRightsCount?.sort()
        if(newArray) {
        const is_same = (userRightsCountSorted.length == newArray.length) && userRightsCountSorted.every(function(element, index) {
            return element == newArray[index]; 
        });
        console.log('si_same ',is_same)
        if(is_same) {
            return false
        } else {
           return true
        }
    }
    }

 
    useEffect(
        ()=>{
            console.log('empDetails ',empDetails)
            if(empDetails?.employeeArrayList?.length > 0  ){
                 setEmployeeDetails(empDetails.employeeArrayList);
                setCompanyDetails(empDetails.companyArrayList[0])

                const {employeeArrayList} = empDetails;

                setEmpData({...empData,firstName:employeeArrayList[0]?.first_name,
                lastName:employeeArrayList[0]?.last_name,
                mail:"",
                jobPosition:'',
                mobile:`${employeeArrayList[0]?.mobile_country_code} ${employeeArrayList[0]?.mobile_no}`,
                office:`${companyDetails?.country_code  ? `${companyDetails?.country_code} - ${companyDetails?.phone_no}`  : `${employeeDetails[0]?.office_country_code} ${employeeArrayList[0]?.office_phone_no}`} `,
                onboardDate:"",
                offboardDate:"",
                submittedBy:"",
                jobTitle:employeeArrayList[0]?.job_title,
                tenantRole:"",
                unit:employeeArrayList[0]?.unit_id,
                level:employeeArrayList[0]?.level_id,
                role_id: empDetails.employeeArrayList[0].employee_role_id,
             })
             setStartDate(new Date(empDetails.employeeArrayList[0]?.access_start_date))
            }
        },[empDetails]
    )
    
    useEffect(()=>{
        if(empData.level!='' && empData.level!=undefined){
            getUnitsByLevelid({level_id:empData.level})
        }
    },[empData.level])

    useEffect(() => {
        if(updateEmployeeStatus?.response_status==='success'){
            setEmpUpdateStatus({ message: updateEmployeeStatus?.response_json?.success_message, status: true, color: 'green', icon: 'tick' })
            setTimeout(() => {
                setEmpUpdateStatus({ message: '', status: false, color: '', icon: '' })
                setStatusNull()
                if(getLoggedUserData().role_id==2 || getLoggedUserData().role_id==14){
                    navigate('/manage_staff/0')
                }else{
                    navigate('/manage_employees/0')
                }
              }, 5000);
           
        }else if(updateEmployeeStatus?.response_status==='fail'){
            setEmpUpdateStatus({ message: updateEmployeeStatus?.error_json?.error_message, status: true, color: 'red', icon: 'close' })
            setTimeout(() => {
                setEmpUpdateStatus({ message: '', status: false, color: '', icon: '' })
                setStatusNull()
              }, 5000);
        }
       
       }, [updateEmployeeStatus])

       const checkTenantManger = () => {
        let id = JSON.parse(localStorage.getItem('userData')).role_id;
        return id === 4 ? true : false;
       }

       const checkBMSRoles = () => {
        let id = JSON.parse(localStorage.getItem('userData')).role_id;
        return (id === 2 || id === 3 || id === 14) ? true : false;
       }

    const handleInputChange = (e)=>{
        const {name,value} = e.target;
        console.log('roleid ',value)
        setEmpData({...empData,[name]:value})
        setTime({...time,timeStr:getLastUpdatedTime()})
    }
 
    const handleMobileInput = (data,country,event,value)=>{
       let {name} = event.target
        setEmpData({
            ...empData,[event.target.name]:value,
            [`${name}Code`]:`+${country.dialCode}`,
            [`${name}number`]:data.slice(country.dialCode.length)
        })
    }
    const handleSaveButton = ()=>{
        if(tabValue == 0) {
            setTabValue(1);
            setTabDisabled({ tab2:false})
        } else {
            const accessData =  accessRights.map((value)=>{
                return{
                    access_rights_id:value.access_rights_id,
                    access_rights:value.isSelected ? 1 : 0,
                    submission_rights:0,
                    approval_rights:0,
                    
                }
            })
        let data = {
            first_name: empData.firstName !== '' ? empData.firstName : employeeDetails[0]?.first_name,
            last_name: empData.lastName !== '' ? empData.lastName : employeeDetails[0]?.last_name,
            job_title: empData.jobTitle !== '' ? empData.jobTitle : employeeDetails[0]?.job_title,
            level_no: empData.level,
            unit_no:empData.unit,
            office_phone_no: empData.officenumber,
            office_country_code:empData.officeCode,
            mobile_no: empData.mobilenumber,
            mobile_country_code:empData.mobileCode,
            emp_id:employeeDetails[0]?.employee_id,
            email:employeeDetails[0]?.email,
            role_id: empData.role_id,
            access_start_date:(activeType==='inactive' || activeType==='in_active') ? getYYYYMMDDFormat(accessStartDate) : activeType=='approved' ? getYYYYMMDDFormat(startDate) :  employeeDetails[0]?.access_start_date,
            is_reonboard:(activeType==='inactive' || activeType==='in_active') ? 'yes' : 'no',
            accessRightsDataArrayList:JSON.stringify(accessData),
            is_role_changed: isAccessRightsCountChanged() ? 1 : 0
        }
        console.log('api data ',data)
        if(activeType==='inactive' || activeType==='in_active'){
            if(accessStartDate===null){
                setAccessError('Enter Reonboard Date')
            }else{
                setAccessError('')
                updateEmployee(data)
            }   
        }else{
            updateEmployee(data)
        }
    }
    }    

    const handleClear = () =>{
        if(tabValue == 0) {
        setEmpData({
            firstName:"",
            lastName:"",
            jobPosition:'',
            mobile:"+65",
            // office:`${companyDetails?.country_code} ${companyDetails?.phone_no}`,
            office:"+65",
            // onboardDate:"",
            // offboardDate:"",
            // submittedBy:"",
            // jobTitle:"",
            // tenantRole:""
         })
        } else if(tabValue == 1 && allRights) {
            setAllRights(false)
            handleAccessCheckBox('','all');
            handleSubmissionCheckBox('','all')
            handleSystemCheckBox('','all')
            handleApprovalCheckBox("","all")
        }
    }

    const checkActiveDate = (date) => {     
        return new Date() > date ? true : false;
    }

    const handleSystemCheckBox=(value,all)=>{
        let arr = systemAccess;
        if(all){
        for(let i=0;i<arr.length;i++){ 
            if(allRights){

                arr[i].isSelected=false;
            }else{
                arr[i].isSelected=true
            } 
             
        }
            setSystemAccess([...arr])
        }else{
            for(let i=0;i<arr.length;i++){ 
                if(arr[i].id===value){
                    arr[i].isSelected=!arr[i].isSelected;
                    
                }
            }
            
            setSystemAccess([...arr])
        }
        
    } 
    
    const handleAccessCheckBox=(value,all)=>{
        let arr =accessRights;
        
        if(all){
            for(let i=0;i<arr.length;i++){
                if(allRights){
                    arr[i].isSelected=false
                }else{
                    arr[i].isSelected=true
                }
            }
            setAccessRights([...arr])
        }else{
           let array =  arr.map((item,index)=>{
                if(value==index){
                    return{
                        ...item,
                        ["isSelected"]:!item.isSelected
                    }
                }else{
                    return item
                }
        })
        setAccessRights([...array])
        }
        
    }

    const handleSubmissionCheckBox=(value,all)=>{
        setSubmissionCheck(!submissionCheck)
        let array =submissionRights;
        let acc = accessRights;
        let app = approvalRights;
        if(all){
            for(let i=0;i<array.length;i++){
                if(allRights){
                    array[i].isSelected=false
                }else{
                    array[i].isSelected=true
                }
            }
            setSubmissionRights([...array])
        }else{
            let arr =  array.map((item,index)=>{
                if(value==index){
                    return{
                        ...item,
                        ["isSelected"]:!item.isSelected
                    }
                }else{
                    return item
                }
            
        })
        let access = acc.map((item,index)=>{
            if(index==value){
                if(submissionCheck){
                    return{
                        ...item,
                        ["isSelected"]:!item.isSelected,
                        ["isDisabled"]:!item.isDisabled
                       }
                }else{
                    return{
                        ...item,
                        ["isSelected"]:true,
                        ["isDisabled"]:true
                    }
                }
               
            }else{
                return item
            }
        })
        let approval = app.map((item,index)=>{
            if(index==value){
                if(submissionCheck){
                    return{
                        ...item,
                        ["isSelected"]:!item.isSelected,
                        ["isDisabled"]:!item.isDisabled
                       }
                }else{
                    return{
                        ...item,
                        ["isSelected"]:true,
                        ["isDisabled"]:true
                    }
                }
               
            }else{
                return item
            }
        })
           
            setSubmissionRights([...arr])
            setAccessRights([...access])
            setApprovalRights([...approval])
        }
        
    }

    const handleApprovalCheckBox = (value,all)=>{
        setApprovalCheck(!approvalCheck)
        let arr=approvalRights;
        let acc = accessRights;
        if(all){
            for(let i=0;i<arr.length;i++){
                if(allRights){
                    arr[i].isSelected = false
                }else{
                    arr[i].isSelected = true
                }
          
            }
            setApprovalRights([...arr])
        }else{
            let array =  arr.map((item,index)=>{
                if(value==index){
                    return{
                        ...item,
                        ["isSelected"]:!item.isSelected
                    }
                }else{
                    return item
                }
            })

            let access = acc.map((item,index)=>{
                if(index==value){
                    if(approvalCheck){
                        return{
                            ...item,
                            ["isSelected"]:!item.isSelected,
                            ["isDisabled"]:!item.isDisabled
                           }
                    }else{
                        return{
                            ...item,
                            ["isSelected"]:true,
                            ["isDisabled"]:true
                        }
                    }
                   
                }else{
                    return item
                }
            })
            setAccessRights([...access])
            setApprovalRights([...array])
        } 
    }

    const selectAllCheckBox=()=>{
        setAllRights(!allRights);
        handleAccessCheckBox('','all');
        handleSubmissionCheckBox('','all')
        handleSystemCheckBox('','all')
        handleApprovalCheckBox("","all")
    }

    console.log('empData ',empData);

    return(
        <>
     <ToastAlert message={empUpdateStatus.message} status={empUpdateStatus.status} color={empUpdateStatus.color} icon={empUpdateStatus.icon} />

        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}}>
            <Grid item xs={2} />


            <Grid item xs={8}>
                <Box sx={{pt:7,pb:4}}>
                    <BackIcon onClick={()=>navigate(-1)} cursor='pointer'/>
                    <Typography sx={{color:' #0F2C6F',fontSize:'26px',mt:3,mb:1}}>{(activeType==='inactive' || activeType==='in_active') ? 'Reonboard ' : 'Update Employee Details'}</Typography>
                    <Typography sx={{color:'#9AA6C3',fontSize:'12px'}}>Onboarded on {moment(employeeDetails[0]?.access_start_date).format('DD-MM-YYYY')}</Typography>
                
                <Box sx={{backgroundColor:'#FFFFFF',}}>
                <Tabs value={tabValue} onChange={(e,value)=>setTabValue(value)}>
                <StyledTab label={
                    <Box>
                        <span style={spanStyle}>1</span>
                        <span>Details</span>
                    </Box>
                }></StyledTab>
                <StyledTab disabled={tabDisabled.tab2} label={
                    <Box>
                        <span style={spanStyle}>2</span>
                        <span>Access & Rights</span>
                    </Box>
                }></StyledTab>
                
            </Tabs>

                <TabPanel value={tabValue} index={0} >
                <Grid container sx={{backgroundColor:'#FFFFFF',p:3}} rowSpacing={2} >
                    
                    <Grid item xs={12} sm={6}>
                        <StyledTypography >First Name</StyledTypography>
                        <StyledOutlinedInput name="firstName" onChange={(e)=>handleInputChange(e)} value={empData.firstName} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Last Name</StyledTypography>
                        <StyledOutlinedInput name="lastName" value={empData.lastName} onChange={(e)=>handleInputChange(e)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Work Email Address</StyledTypography>
                        <StyledOutlinedInput name="mail"  value={employeeDetails[0]?.email || ""} disabled/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography>Job Position</StyledTypography>
                        <StyledOutlinedInput name="jobTitle" value={empData.jobTitle} onChange={(e)=>handleInputChange(e)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography sx={{mb:2}}>Mobile Number</StyledTypography>
                        <PhoneInput country={'sg'} value={empData.mobile} onChange={(data,country,event,value)=>handleMobileInput(data,country,event,value)} inputStyle={{height:'48px',border:' 1px solid #9AA6C3',width:'90%'}}  inputProps={{name:'mobile'}} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTypography sx={{mb:2}}>Office Phone Number</StyledTypography>
                        <PhoneInput  name='office' value={empData.office} onChange={(data,country,event,value)=>handleMobileInput(data,country,event,value)}  inputStyle={{height:'48px',border:' 1px solid #9AA6C3',width:'90%'}} inputProps={{name:'office'}} />
                    </Grid>

      
                    <Grid item xs={12} sm={6}>
         <StyledTypography sx={{mb:2}}> BMS Role </StyledTypography>
                     <FormControl sx={{minWidth: 150, width:'90%'}}>
            <Select          
            value={empData.role_id || ""}
            onChange={e => handleInputChange({target: {name: "role_id", value: e.target.value}})}
                >
            {bmsRoles?.map((ele,index) => <MenuItem key={index} value={ele.role_id}>{ele.role}</MenuItem>)}
            </Select>
            </FormControl>
            </Grid>
        


                    {employeeDetails[0]?.employee_type=='tenant_employee' &&  <Grid item xs={12}>
                        <StyledTypography sx={{mb:2}}>Office Unit Number</StyledTypography>
                        <Select  sx={{
                            height: { md: "inherit", sm: "55px", xs: "45px" },
                            width: '80px',
                            color: '#0F2C6F',
                            fontSize: "14px",
                            color:'#0F2C6F'
                          }} value={Number(empData.level) || ""} name="level" onChange={(e)=>handleInputChange(e)}>
                            {levelDatas?.map((value, index)=>(
                                <MenuItem key={index} value={Number(value.level_id)}>{value.level_name}</MenuItem>
                            ))}
                        </Select>
                        <Select  sx={{
                              ml:2,
                              height: { md: "inherit", sm: "55px", xs: "45px" },
                              fontSize: "14px",
                              color:'#0F2C6F'
                            }} value={Number(empData.unit) || ""} name="unit" onChange={(e)=>handleInputChange(e)}>
                            {unitDatas?.map((value, index)=>(
                                <MenuItem key={index} value={Number(value.unit_id)}>{value.unit_name}</MenuItem>
                            ))}
                        </Select>
                        {activeType !== 'inactive' &&
                        <>
                        <StyledTypography sx={{mt:2}}>Onboard Date</StyledTypography>
                        <DatePicker disablePast inputFormat="dd/MM/yyyy" value={new Date(startDate)} onChange={(value)=>setStartDate(value)}
                        renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                        components={{
                            OpenPickerIcon:DatePickerIcon
                        }} 
                        disabled={activeType === "approved" && checkActiveDate(startDate)}
                        
                        />
                        </>
                    }
                        
                    </Grid>
                    }
                    {(activeType==='inactive' || activeType==='in_active') && 
                        <Grid item xs={12}>
                               <StyledTypography sx={{mb:1}}>Reonboard Date</StyledTypography>
                                <DatePicker disablePast inputFormat="dd/MM/yyyy" value={accessStartDate} onChange={(value)=>setAccessStartDate(value)}
                                        renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                                        components={{
                                            OpenPickerIcon:DatePickerIcon
                                        }} 
                                />
                                <Typography sx={{color:"red",mt:1}}>{accessError}</Typography>
                        </Grid>
                    }
                </Grid>
                <Box sx={{backgroundColor:'#FFFFFF',p:2}}>
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Onboarded Date</StyledTableCell>
                                <StyledTableCell>Offboarded Date</StyledTableCell>
                                <StyledTableCell>Submitted by</StyledTableCell>
                                <StyledTableCell>Role</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{backgroundColor:' #F5F8FF',cursor:"pointer"}}>
                                <StyledTableCell sx={{fontWeight:400}}>{moment(employeeDetails[0]?.access_start_date).format('DD-MM-YYYY')}</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:400}}>{employeeDetails[0]?.offboard_date!=null && moment(employeeDetails[0]?.offboard_date).format('DD-MM-YYYY') }</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:400}}>{employeeDetails[0]?.created_by_name}</StyledTableCell>
                                <StyledTableCell sx={{fontWeight:400}}>{employeeDetails[0]?.created_by_role}</StyledTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </Box>
                </TabPanel>
                </Box>  
               
                <TabPanel value={tabValue} index={1} >
                <Box sx={{p:3, backgroundColor:'white'}}>
                <Typography sx={{fontSize:'18px',color:'#0F2C6F'}}>Access & Rights</Typography>
                <Typography sx={{fontSize:'14px',color:'#6F80A9',mb:2,mt:1}}>Finance Manager will be the person who is authorised to make payments on behalf of the company. </Typography>
               
               <Checkbox checked={allRights} icon={<CheckboxIcon checked={allRights} />} onClick={()=>selectAllCheckBox()}/><Typography sx={{display:'inline',color:'#0F2C6F ',fontSize:'12px'}}>Select all rights and access</Typography>
               <Grid container sx={{mt:2}} >
                    {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>System Access</Typography>
                        <Box sx={{display:'flex',flexDirection:'column'}}>
                        {
                            systemAccess.map((value,index)=>(
                                <Box key={index}>
                                    <Checkbox icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleSystemCheckBox(value.id)}/>
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.name}</Typography>
                                </Box>
                            ))
                        }
                        </Box>
                        
                    </Grid> */}
                    <Grid item xs={12} >
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Access Rights</Typography>
                        <Box sx={{display:'flex',flexDirection:'column', width: "100%"}}>
                        {
                            accessRights?.map((value,index)=>(
                                <Box key={index}>
                                    <Checkbox disabled={value.isDisabled} icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleAccessCheckBox(index)} />
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                                </Box>
                            ))
                        }
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Submission Rights</Typography>
                        <Box>
                            {
                                submissionRights.map((value,index)=>{
                                     return <Box key={index}>
                                        <Checkbox disabled={value.isDisabled} icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleSubmissionCheckBox(index)} />
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                                    </Box>
                                    
                                })
                            }
                        </Box>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={3}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:500}}>Approval Rights</Typography>
                        <Box>
                            {
                                approvalRights.map((value,index)=>{
                                 
                                        return <Box key={index}>
                                        <Checkbox disabled={value.isDisabled} icon={<CheckboxIcon />}  checked={value.isSelected} onClick={()=>handleApprovalCheckBox(index)} />
                                    <Typography sx={{display:'inline',fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>{value.access_rights_name}</Typography>
                                    </Box>
                                    
                                    
                                })
                            }
                        </Box>
                    </Grid> */}
               </Grid>
               </Box> 
                </TabPanel>
                </Box>
                <Box sx={{display:'flex',justifyContent:'space-between',py:5}}>
                    <Box sx={{display:'flex'}}>
                        <RefreshIcon  />
                        <Typography sx={{display:'inline',color:' #9AA6C3',fontSize:'14px',ml:1}}>Last updated at {time.timeStr}</Typography>
                    </Box>
                    <Box>
                        <Button onClick={()=>handleClear()} sx={{textTransform:'capitalize',color:' #0F2C6F',height:'50px',borderRadius:'8px',border:'1px solid #AAC3FF',mr:2,'&:hover':{backgroundColor:'#FFFFFF'}}}>Clear</Button>
                        <Button onClick={()=>handleSaveButton()} sx={{textTransform:'capitalize',backgroundColor:'#123FA9',height:'50px',borderRadius:'8px',color:'#FFFFFF','&:hover':{backgroundColor:'#123FA9'}}}>{tabValue == 0 ? "Continue" : "Save"}</Button>
                    </Box>
                </Box>
            </Grid>

            <Grid item xs={2} />
        </Grid>
        </>
    )
}

const TabPanel = (props)=>{
    const {children,value,index} =props
    return(
        <>
        {
        value===index && 
        <>{children}</>
        }
        </>
    ) 
}

const mapStateToProps = (state)=>{
    return{
         empDetails:state.CommonReducer.empDetails,
         userAccessRights:state.CommonReducer.empDetails.userAccessRights,
         updateEmployeeStatus:state.CommonReducer.updateEmployeeStatus,
         levelDatas: state.CommonReducer.tenantOccupiedLeves,
         unitDatas: state.CommonReducer.tenantOccupiedUnits,
         roleData: state.CommonReducer.roleData,
         bmsRoles: state.BMSStaffReducer.rolesList,
         defaultAccessRights:state.BMSStaffReducer.defaultAccessRights,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getEmpDetailsById:(data)=>dispatch(CommonAction.getEmpDetailsById(data)),
        updateEmployee:(data)=>dispatch(CommonAction.updateEmployee(data)),
        getAllLevels: () => dispatch(CommonAction.getTenantAccessLevel()),
        getUnitsByLevelid: (data) => dispatch(CommonAction.getTenantAccessUnit(data)),
        setStatusNull:()=>dispatch(CommonAction.setStatusNull()),
        getAllRoles: () => dispatch(CommonAction.getAllRoles()),
        getStaffRoles : () => dispatch(BmsStaffAction.getStaffRole()),
        getdefaultAccessRights:(data)=>(dispatch(BmsStaffAction.getdefaultAccessRights(data))),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateEmpsDetails);