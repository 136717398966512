import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as BackArrow } from "../../Assets/icons/BackArrowAdd.svg";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Sync } from "../../Assets/icons/Sync.svg";
import { ReactComponent as PdfIcon } from "../../Assets/icons/PdfEditIcon.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/EditFileClose.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/DeleteButtonIcon.svg";
import { ReactComponent as ImageFormatIcon } from "../../Assets/icons/ImageFormatIcon.svg";
import { ReactComponent as ExcelFormatIcon } from "../../Assets/icons/Excel.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetServiceRequestFormsByIdAction,
  GetServiceRequestFormsByIdNullAction,
  EditServiceRequestFormsAction,
  EditServiceRequestFormsNull,
  DeleteServiceRequestFormsNullAction,
  DeleteServiceRequestFormsAction,
} from "../../redux/actions/service_request_forms_action";
import { connect } from "react-redux";
import moment from "moment";
import DropZone from "../../Utils/dropzone";
import ToastAlert from "../common/Alert";

const EditFileServiceRequest = ({
  getServiceRequestById,
  getServiceRequestByIdData,
  getServiceRequestByIdNull,
  editServiceRequestFormsData,
  EditServiceRequestForm,
  DeleteServiceRequestForm,
  DeleteServiceRequestFormNull,
  deleteServiceRequestFormsData,
  EditServiceRequestFormNull
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [dropTrigger, setDropTrigger] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [serviceFormData, setServiceFormData] = useState([]);
  const [title, setTitle] = useState("");
  const [FileError, setFileError] = useState("");
  const [titleError, setTitleError] = useState('');
  const [file, setFile] = useState(null);
  const [fileTrigger, setFileTrigger] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });

  const onDrop = (acceptedFiles) => {
    setFileError("");
    setFile(acceptedFiles[0]);
    setFileTrigger(true);
    setDropTrigger(false)
  };

  const handleDeleteForm = () => {
    DeleteServiceRequestForm(id);
    setOpenDeleteModal(false)
  }


  useEffect(() => {
    getServiceRequestById(id);
    return () => {
      getServiceRequestByIdNull();
    };
  }, []);

  useEffect(() => {
    if (deleteServiceRequestFormsData.response?.response_status === 1) {
      setUploadStatus({
        message: deleteServiceRequestFormsData?.response?.message,
        status: true,
        color: "green",
        icon: "tick",
      });
      DeleteServiceRequestFormNull();

      setTimeout(() => {
        setUploadStatus({ message: '', status: false, color: '', icon: '' });
        navigate('/service_request_forms/0/0/null')
      }, 4000);
    }
  }, [deleteServiceRequestFormsData])

  useEffect(() => {
    if (editServiceRequestFormsData.response?.response_status === 1) {
      setUploadStatus({
        message: "File Updated Successfully!",
        status: true,
        color: "green",
        icon: "tick",
      });
      EditServiceRequestFormNull();


      setTimeout(() => {
        setUploadStatus({ message: '', status: false, color: '', icon: '' });
        navigate('/service_request_forms/0/0/null');
      }, 4000)
    }
  }, [editServiceRequestFormsData])


  useEffect(() => {
    if (getServiceRequestByIdData.response) {
      setServiceFormData(getServiceRequestByIdData?.response.data_array);
      setTitle(getServiceRequestByIdData?.response.data_array?.name);
      setTitleError('')
    }
  }, [getServiceRequestByIdData]);

  const handleFormSubmit = () => {
    let form = new FormData();

    form.append('name', title);

    if (fileTrigger && file !== null) {
      form.append('path', file?.file)
    }

    EditServiceRequestForm(id, form);
    setOpenUpdateModal(false);
  }

  const handleDiscard = () => {
    setDropTrigger(true);
    setServiceFormData("");
    setFileTrigger(false);
    setTitle('')
    setFile(null);
  }


  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <>
      <ToastAlert
        message={uploadStatus.message}
        status={uploadStatus.status}
        color={uploadStatus.color}
        icon={uploadStatus.icon}
      />
      <Box
        sx={{
          padding: "50px 30px 50px 30px",
          maxWidth: "782px",
          margin: "0 auto",
        }}
      >

        <BackArrow cursor={"pointer"} onClick={() => navigate(-1)} />
        <Typography
          style={{
            fontWeight: 500,
            fontSize: "26px",
            color: "#002D74",
            marginTop: "20px",
          }}
        >
          Update Report Form
        </Typography>
        <Box
          sx={{
            borderRadius: "8px",
            padding: "25px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: "14px", color: "#0F2C6F" }}
          >
            Title
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Enter title of the service request form"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
              setTitleError('');
            }}
            InputProps={{
              style: {
                borderRadius: "6px",
                border: "1px solid #9AA6C3",
                padding: "8px 12px 8px 12px",
                color: "#0F2C6F",
                height: "48px",
                marginTop: "10px",
                minWidth: "395px",
                fontWeight: 500,
                fontSize: "14px",
              },
              disableUnderline: true,
            }}
          />
          <Typography sx={{ color: "#f44336", mt: 1, }}>{titleError}</Typography>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "18px",
              color: "#0F2C6F",
              marginTop: "25px",
            }}
          >
            Upload New Service Request Form
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#6F80A9",
            }}
          >
            The new updated form will replace existing form once confirmed.
          </Typography>
          {!dropTrigger && serviceFormData?.path && (
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #DBDFE9",
                padding: "25px",
                marginTop: "10px",
                 display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
                   <Box sx={{ display: "flex", flexDirection: "row" }}>
                    {serviceFormData?.file_type === 'pdf' && <PdfIcon style={{ height: "100%", height: '120px' }} />}
                    {serviceFormData?.file_type === 'xlsx' && <ExcelFormatIcon style={{ width: '100px', height: "120px" }} />}
                    <Box sx={{ marginLeft: "20px", marginTop: "20px" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "#0F2C6F",
                        }}
                      >
                        {moment(serviceFormData?.created_at).format("DD MMMM YYYY")}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "18px",
                          color: "#0F2C6F",
                          marginTop: "10px",
                        }}
                      >
                        {serviceFormData?.file_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "#6F80A9",
                          marginTop: "10px",
                        }}
                      >
                        {serviceFormData?.file_size && `${serviceFormData.file_size} of ${serviceFormData.file_size} KB `}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <CloseIcon
                      cursor={"pointer"}
                      onClick={() => {
                        setDropTrigger(true);
                        setServiceFormData("");
                        setFileTrigger(false);
                      }}
                    />
                  </Box>            
            </Box>
           )}
          {(dropTrigger && !fileTrigger) && (
            // <Box
            //   {...getRootProps()}
            //   sx={{
            //     borderRadius: "8px",
            //     marginTop: "20px",
            //     cursor: "pointer",
            //     backgroundColor: "#EAF0FF",
            //     height: 250,
            //     display: 'grid',
            //     placeItems: "center"
            //   }}
            // >
            //   <Box
            //     sx={{
            //       display: "flex",
            //       flexDirection: "column",
            //       alignItems: "center",
            //       textalign: "center",
            //     }}
            //   >
            //     <Typography
            //       sx={{ fontWeight: 600, fontSize: "14px", color: "#0F2C6F" }}
            //     >
            //       Drag and Drop file here
            //     </Typography>
            //     <Typography
            //       sx={{
            //         fontWeight: 400,
            //         fontSize: "12px",
            //         color: "#0F2C6F",
            //         width: "350px",
            //         marginTop: "10px",
            //       }}
            //     >
            //       Please ensure that uploaded service request form is accurate.
            //       Files Supported: .pdf, .png, .jpg, .xls (Max 25 MB)
            //     </Typography>
            //     <Button
            //       sx={{
            //         borderRadius: "6px",
            //         border: "1px solid #AAC3FF",
            //         backgroundColor: "#FFFFFF",
            //         padding: "8px 10px 8px 10px",
            //         color: "#0F2C6F",
            //         fontWeight: 600,
            //         fontSize: "14px",
            //         marginTop: "30px",
            //       }}
            //     >
            //       Choose File <input {...getInputProps()} />
            //     </Button>
            //   </Box>
            // </Box>
            <DropZone getFiles={e => onDrop(e)} customFileFormat={{
              'application/pdf': [],
              'application/vnd.ms-excel': [],
              'text/csv': []
            }} singleFile={true} customFileText={'.pdf, .xls'} />
          )}
          {!dropTrigger && fileTrigger &&
            <Box
              sx={{
                borderRadius: "8px",
                border: "1px solid #DBDFE9",
                padding: "25px",
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                {file?.file?.type === 'application/pdf' && <PdfIcon style={{ width: '100px', height: "120px" }} />}
                {file?.file?.type === "text/csv" && <ExcelFormatIcon style={{ width: '100px', height: "120px" }} />}

                <Box sx={{ marginLeft: "20px", marginTop: "20px" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "18px",
                      color: "#0F2C6F",
                      marginTop: "10px",
                    }}
                  >
                    {file?.fileName}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "12px",
                      color: "#6F80A9",
                      marginTop: "10px",
                    }}
                  >
                    {(file?.size / 1024).toFixed(2)} of{" "}
                    {(file?.size / 1024).toFixed(2)} KB
                  </Typography>
                </Box>
              </Box>
              <Box>
                <CloseIcon
                  cursor={"pointer"}
                  onClick={() => {
                    setFileTrigger(false);
                    setFile(null);
                    setDropTrigger(true);
                   }}
                />
              </Box>
            </Box>
          }
          {FileError && (
            <Typography sx={{ color: "#f44336" }}>{FileError}</Typography>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Sync style={{ marginRight: "8px" }} />
          <Typography
            sx={{ color: "#9AA6C3", fontWeight: 400, fontSize: "14px" }}
          >
            Last Updated at {moment.utc(serviceFormData?.updated_at).local().format('MM:HH A')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <Box>
            <Button
              sx={{
                borderRadius: "8px",
                padding: "13px 10px 13px 10px",
                backgroundColor: "#BD0251",
                fontSize: "14px",
                color: "#FFFFFF",
                display: "flex",
                flexDirection: "row",
                "&:hover": {
                  backgroundColor: "#BD0251",
                },
                textTransform: "none",
                gap: 2
              }}
              onClick={() => {
                if (deleteServiceRequestFormsData.isLoading){
                  return;
                }
                setOpenDeleteModal(true)}}
            >
              <Box sx={{display: 'flex',alignItems: "center"}}>
              <DeleteIcon style={{ marginRight: "5px" }} /> Delete Form
              </Box>
              {deleteServiceRequestFormsData.isLoading && <CircularProgress sx={{color: 'white'}} size={20} />}
            </Button>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Button
              sx={{
                borderRadius: "8px",
                border: "1px solid #AAC3FF",
                backgroundColor: "#FFFFFF",
                padding: "13px 10px 13px 10px",
                color: "#0F2C6F",
                textTransform: 'none',
                fontSize: "14px",
                marginRight: "20px",
              }}
              onClick={() => handleDiscard()}
            >
              Clear
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                backgroundColor: "#123FA9",
                color: "#FFFFFF",
                textTransform: 'none',
                fontSize: "14px",
                display: 'flex',
                alignItems: "center",
                gap: 2
              }}
              variant="contained"
              onClick={() => {
                if(editServiceRequestFormsData.isLoading){
                  return;
                }
                if (title.trim() !== '' &&  !dropTrigger && (file || serviceFormData?.name)) {
                  setOpenUpdateModal(true)
                }
                if (title.trim() === '') {
                  setTitleError('Title should not be empty!');
                }

                if (dropTrigger && !file && !fileTrigger) {
                  setFileError('File should not be empty!');
                }
                console.log('file',file, 'fileTrigger', fileTrigger,'dropTrigger', dropTrigger)
              }}
            >              
              Update
              {editServiceRequestFormsData.isLoading === true && <CircularProgress sx={{color: "white"}} size={20} />}
            </Button>
          </Box>
        </Box>
        <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #C0C7D7",
                borderRadius: "8px 8px 0px 0px",
                padding: "24px",
                width: "500px",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: "18px", color: "#0F2C6F" }}
              >
                Delete Service Request Form
              </Typography>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenDeleteModal(false)}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#6F80A9",
                width: "500px",
                padding: "24px",
              }}
            >
              Are you sure you wish to delete the service request form? Once
              deleted, the form will be removed from the downloadable forms and
              this action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              padding: "24px",
            }}
          >
            <Button
              sx={{
                borderRadius: "6px",
                border: "1px solid #AAC3FF",
                padding: "8px 10px 8px 10px ",
                backgroundColor: "#FFFFFF",
                color: "#0F2C6F",
                fontWeight: 600,
                fontSize: "14px",
              }}
              onClick={() => setOpenDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteForm}
              sx={{
                borderRadius: "6px",
                padding: "8px 10px 8px 10px ",
                backgroundColor: "#BD0251",
                color: "#FFFFFF",
                fontWeight: 600,
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: "#BD0251",
                },
              }}
            >
              Delete Now
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openUpdateModal} onClose={() => setOpenUpdateModal(false)}>
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #C0C7D7",
                borderRadius: "8px 8px 0px 0px",
                padding: "24px",
                width: "500px",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Typography
                sx={{ fontWeight: 500, fontSize: "18px", color: "#0F2C6F" }}
              >
                Confirm Update to Season Parking Application Form
              </Typography>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => setOpenUpdateModal(false)}
              />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
                color: "#6F80A9",
                width: "500px",
                padding: "24px",
              }}
            >
              Please ensure the form details are correct. Once uploaded, the form
              will be published and visible to Singapore Land Tower’s tenants.
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              padding: "24px",
            }}
          >
            <Button
              sx={{
                borderRadius: "6px",
                border: "1px solid #AAC3FF",
                padding: "8px 10px 8px 10px ",
                backgroundColor: "#FFFFFF",
                color: "#0F2C6F",
                fontWeight: 600,
                fontSize: "14px",
              }}
              onClick={() => setOpenUpdateModal(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleFormSubmit()}
              sx={{
                borderRadius: "6px",
                padding: "8px 10px 8px 10px ",
                backgroundColor: "#123FA9",
                color: "#FFFFFF",
                fontWeight: 600,
                fontSize: "14px",
              }}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Box>.
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceRequestById: (data) => {
      dispatch(GetServiceRequestFormsByIdAction(data));
    },
    getServiceRequestByIdNull: () => {
      dispatch(GetServiceRequestFormsByIdNullAction());
    },
    EditServiceRequestForm: (params, id) => {
      dispatch(EditServiceRequestFormsAction(params, id));
    },
    EditServiceRequestFormNull: () => {
      dispatch(EditServiceRequestFormsNull());
    },
    DeleteServiceRequestForm: (data) => {
      dispatch(DeleteServiceRequestFormsAction(data));
    },
    DeleteServiceRequestFormNull: () => {
      dispatch(DeleteServiceRequestFormsNullAction());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    getServiceRequestByIdData:
      state.ServiceRequestFormsReducer.ServiceRequestByIdData,
    editServiceRequestFormsData:
      state.ServiceRequestFormsReducer.EditServiceRequestFormsData,
    deleteServiceRequestFormsData:
      state.ServiceRequestFormsReducer.DeleteServiceRequestFormsData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFileServiceRequest);
