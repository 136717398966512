import { handleActions } from "redux-actions";

const responseDef = {
  isLoading: false,
  response: null,
  isError: false,
};

const ServiceRequestFormsReducerData = {
  getServiceRequestFormsData: { ...responseDef },
  addServiceRequestData: { ...responseDef },
  ServiceRequestByIdData: { ...responseDef },
  EditServiceRequestFormsData: { ...responseDef },
  DeleteServiceRequestFormsData: { ...responseDef },
  downloadServiceRequestForms: {...responseDef},
};

export const ServiceRequestFormsReducer = handleActions(
  {
    GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_INITIATE: (state, action) => {
      return {
        ...state,
        getServiceRequestFormsData: {
          ...state.getServiceRequestFormsData,
          isLoading: true,
          isError: false,
        },
      };
    },
    GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_SUCCESS: (state, action) => {
      return {
        ...state,
        getServiceRequestFormsData: {
          isError: false,
          response: action.payload,
          isLoading: false,
        },
      };
    },
    GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_FAILURE: (state, action) => {
      return {
        ...state,
        getServiceRequestFormsData: {
          response: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    },
    GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_NULL: (state, action) => {
      return {
        ...state,
        getServiceRequestFormsData: { ...responseDef },
      };
    },

    ADD_SERVICE_REQUEST_INITIATE: (state, action) => {
      return {
        ...state,
        addServiceRequestData: {
          ...state.addServiceRequestData,
          isLoading: true,
          isError: false,
        },
      };
    },
    ADD_SERVICE_REQUEST_SUCCESS: (state, action) => {
      return {
        ...state,
        addServiceRequestData: {
          isError: false,
          response: action.payload,
          isLoading: false,
        },
      };
    },
    ADD_SERVICE_REQUEST_FAILURE: (state, action) => {
      return {
        ...state,
        addServiceRequestData: {
          response: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    },
    ADD_SERVICE_REQUEST_NULL: (state, action) => {
      return {
        ...state,
        addServiceRequestData: { ...responseDef },
      };
    },

    GET_SERVICE_REQUEST_FORMS_BY_ID_INITIATE: (state, action) => {
      return {
        ...state,
        ServiceRequestByIdData: {
          ...state.ServiceRequestByIdData,
          isLoading: true,
          isError: false,
        },
      };
    },
    GET_SERVICE_REQUEST_FORMS_BY_ID_SUCCESS: (state, action) => {
      return {
        ...state,
        ServiceRequestByIdData: {
          isError: false,
          response: action.payload,
          isLoading: false,
        },
      };
    },
    GET_SERVICE_REQUEST_FORMS_BY_ID_FAILURE: (state, action) => {
      return {
        ...state,
        ServiceRequestByIdData: {
          response: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    },

    GET_SERVICE_REQUEST_FORMS_BY_ID_NULL: (state, action) => {
      return {
        ...state,
        addServiceRequestData: { ...responseDef },
      };
    },

    EDIT_SERVICE_REQUEST_FORMS_INITIATE: (state, action) => {
      return {
        ...state,
        EditServiceRequestFormsData: {
          ...state.EditServiceRequestFormsData,
          isLoading: true,
          isError: false,
        },
      };
    },
    EDIT_SERVICE_REQUEST_FORMS_SUCCESS: (state, action) => {
      return {
        ...state,
        EditServiceRequestFormsData: {
          isError: false,
          response: action.payload,
          isLoading: false,
        },
      };
    },
    EDIT_SERVICE_REQUEST_FORMS_FAILURE: (state, action) => {
      return {
        ...state,
        EditServiceRequestFormsData: {
          response: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    },

    EDIT_SERVICE_REQUEST_FORMS_NULL: (state, action) => {
      return {
        ...state,
        EditServiceRequestFormsData: { ...responseDef },
      };
    },
    DELTE_SERVICE_REQUEST_FORMS_INITIATE: (state, action) => {
      return {
        ...state,
        DeleteServiceRequestFormsData: {
          ...state.DeleteServiceRequestFormsData,
          isLoading: true,
          isError: false,
        },
      };
    },
    DELTE_SERVICE_REQUEST_FORMS_SUCCESS: (state, action) => {
      return {
        ...state,
        DeleteServiceRequestFormsData: {
          isError: false,
          response: action.payload,
          isLoading: false,
        },
      };
    },
    DELTE_SERVICE_REQUEST_FORMS_FAILURE: (state, action) => {
      return {
        ...state,
        DeleteServiceRequestFormsData: {
          response: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    },

    DELTE_SERVICE_REQUEST_FORMS_NULL: (state, action) => {
      return {
        ...state,
        DeleteServiceRequestFormsData: { ...responseDef },
      };
    },
    DOWNLOAD_SERVICE_REQUEST_FORMS_INITIATE: (state, action) => {
      return {
        ...state,
        downloadServiceRequestForms: {
          ...state.DeleteServiceRequestFormsData,
          isLoading: true,
          isError: false,
        },
      };
    },
    DOWNLOAD_SERVICE_REQUEST_FORMS_SUCCESS: (state, action) => {
      return {
        ...state,
        downloadServiceRequestForms: {
          isError: false,
          response: action.payload,
          isLoading: false,
        },
      };
    },
    DOWNLOAD_SERVICE_REQUEST_FORMS_FAILURE: (state, action) => {
      return {
        ...state,
        downloadServiceRequestForms: {
          response: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    },

    DOWNLOAD_SERVICE_REQUEST_FORMS_NULL: (state, action) => {
      return {
        ...state,
        downloadServiceRequestForms: { ...responseDef },
      };
    },
  },
  ServiceRequestFormsReducerData
);
