import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as DownloadIcon } from "../../Assets/icons/Download.svg";
import * as ReducerAction from "../../redux/actions/reportAction/index";
import { Reports_Service_columnName } from "../../components/Data/Data";
import {
  getLoggedUserData,
  getTimeStamp,
  getYYYYMMDDFormat,
  MenuProps,
} from "../../Utils/utils";
import { ReactComponent as ArrowUpwardIcon } from "../../Assets/icons/sortasc.svg";
import { ReactComponent as ArrowDownwardIcon } from "../../Assets/icons/sortdes.svg";
import { ReactComponent as ArrowdDisableIcon } from "../../Assets/icons/sortDisable.svg";
import { getStatus } from "../../Utils/ActiveStatus";
import moment from "moment";
import axios from "axios";

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFBFF",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F2C6F !important",
  padding: "5px !important",
  textAlign: "left",
  height: "50px",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const sortColumns = {
  "Date Requested": "DESC",
  "Request Type": "ASC",
  "Company Name": "ASC",
  "Requested By": "ASC",
  "Last Updated": "ASC",
};

const payloadSortFields = {
  "Date Requested": "service_requests.created_at",
  "Request Type": "service_request_types.service_request_types_name",
  "Company Name": "companies.company_name",
  "Requested By": "users.name",
  "Last Updated": "service_requests.updated_at",
};

const sortColumnNames = [
  "Date Requested",
  "Request Type",
  "Company Name",
  "Requested By",
  "Last Updated",
];

const ReportsServicerequest = ({
  handleBackButton,
  getFilterData,
  faultReportFilterData,
  getServiceReports,
  serviceDatas,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filterDatas, setFilterDatas] = useState({
    status: "",
    start_date: null,
    end_date: null,
    company: "",
    technician: "",
    rows: "10",
    order_by_column: "",
    order_by: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedSort, setSelectedSort] = useState({
    ["service_requests.created_at"]: false,
    ["service_request_types.service_request_types_name"]: false,
    ["companies.company_name"]: false,
    ["users.name"]: false,
    ["service_requests.updated_at"]: true,
  });
  const [sortColumnData, setSortColumnData] = useState(sortColumns);
  useEffect(() => {
    getFilterData();
  }, []);

  const callDownload = async () => {
    setDisabled(true);
    setLoader(true);
    try {
      let token = localStorage.getItem("userToken");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      };
      let response = await axios.get(
        serviceDatas?.data_array?.excel_path,
        config
      );
      let blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      let tag = document.createElement("a");
      tag.setAttribute("href", url);
      tag.setAttribute(
        "download",
        `ServiceRequest Report ${moment(new Date()).format("DD MMM YYYY")}`
      );
      tag.click();
      setDisabled(false);
      setLoader(false);
    } catch (err) {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!serviceDatas?.data_array?.total_service_request_count > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [serviceDatas]);

  useEffect(() => {
    getServiceReports({
      status: filterDatas.status == "" ? "NA" : filterDatas.status,
      limit: filterDatas.rows,
      offset: currentPage - 1,
      company_id: filterDatas.company,
      start_date: getYYYYMMDDFormat(filterDatas.start_date),
      end_date: getYYYYMMDDFormat(filterDatas.end_date),
      technician_id: filterDatas.technician,
      user_id: getLoggedUserData()?.user_id,
      order_by_column:
        payloadSortFields[filterDatas.order_by_column] != undefined
          ? payloadSortFields[filterDatas.order_by_column]
          : "",
      order_by: filterDatas.order_by,
    });
  }, [filterDatas, currentPage]);

  const handleFilterDetails = (e, datafrom) => {
    if (datafrom) {
      setFilterDatas({
        ...filterDatas,
        [datafrom]: e,
      });
      setCurrentPage(1);
    } else {
      let { name, value } = e.target;
      setFilterDatas({
        ...filterDatas,
        [name]: value,
      });
      setCurrentPage(1);
    }
  };

  const handlePagination = (e, value) => {
    setCurrentPage(value);
  };

  const handleSortClick = (val, sortVal, keyName) => {
    const madeStatusFalse = {
      ["service_requests.created_at"]: false,
      ["service_request_types.service_request_types_name"]: false,
      ["companies.company_name"]: false,
      ["users.name"]: false,
      ["service_requests.updated_at"]: false,
    };
    setSelectedSort({ ...madeStatusFalse, [keyName]: true });
    let columnSortData = { ...sortColumnData };
    for (const key in columnSortData) {
      if (key === val) {
        columnSortData[key] = sortVal === "ASC" ? "DESC" : "ASC";
        setFilterDatas((prevValue) => ({
          ...prevValue,
          order_by_column: val,
          order_by: sortVal === "ASC" ? "DESC" : "ASC",
        }));
      } else {
        columnSortData[key] = "ASC";
      }
    }
    setSortColumnData({ ...columnSortData });
  };
  console.log("selectedSort ", selectedSort);

  const clickSorting = (columnName, sortColumnData, keyName) => {
    console.log("keyName ", keyName);
    if (sortColumnNames.includes(columnName))
      handleSortClick(columnName, sortColumnData, keyName);
  };

  const handleReset = () => {
    setFilterDatas({
      status: "",
      start_date: null,
      end_date: null,
      company: "",
      technician: "",
      rows: "10",
      order_by_column: "",
      order_by: "",
    });
  };
  return (
    <>
      <Box>
        <BackButton onClick={() => handleBackButton()} />
        <Typography
          sx={{ color: "#0F2C6F", fontSize: "24px", fontWeight: 540, my: 1.5 }}
        >
          Service Request
        </Typography>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <Box
              sx={{
                height: 100,
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography className="reportCount">
                {serviceDatas?.data_array?.pending_count}
              </Typography>
              <Typography className="reportCount">Pending</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                height: 100,
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography className="reportCount">
                {serviceDatas?.data_array?.work_in_progress_count}
              </Typography>
              <Typography className="reportCount">Work in progress</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                height: 100,
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography className="reportCount">
                {serviceDatas?.data_array?.resolved_count}
              </Typography>
              <Typography className="reportCount">Resolved</Typography>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                height: 100,
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography className="reportCount">
                {serviceDatas?.data_array?.withdraw_count +
                  serviceDatas?.data_array?.rejected_count}
              </Typography>
              <Typography className="reportCount">Withdraw/Rejected</Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ my: 2, backgroundColor: "#FFFFFF" }}>
          <Grid container sx={{ p: 2 }} columnSpacing={2}>
            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel
                  sx={{ color: "#123FA9", fontSize: "14px", fontWeight: 600 }}
                >
                  Status
                </InputLabel>
                <Select
                  value={filterDatas.status}
                  name="status"
                  onChange={(e) => handleFilterDetails(e)}
                  MenuProps={MenuProps}
                  label="Status"
                >
                  {faultReportFilterData?.fault_report_status?.map((value) => (
                    <MenuItem value={value.fault_status_name}>
                      {value.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel
                  sx={{ color: "#123FA9", fontSize: "14px", fontWeight: 600 }}
                >
                  Company
                </InputLabel>
                <Select
                  value={filterDatas.company}
                  name="company"
                  onChange={(e) => handleFilterDetails(e)}
                  MenuProps={MenuProps}
                  label="Company"
                >
                  {faultReportFilterData?.company_details?.map((value) => (
                    <MenuItem value={value.company_id}>
                      {value.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl size="small" fullWidth>
                <InputLabel
                  sx={{ color: "#123FA9", fontSize: "14px", fontWeight: 600 }}
                >
                  Technician
                </InputLabel>
                <Select
                  value={filterDatas.technician}
                  name="technician"
                  onChange={(e) => handleFilterDetails(e)}
                  MenuProps={MenuProps}
                  label="Technician"
                >
                  {faultReportFilterData?.technician_details?.map((value) => (
                    <MenuItem value={value.id}>{value.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1.7}>
              <FormControl size="auto" fullWidth>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  disableFuture
                  components={{
                    OpenPickerIcon: DatePickerIcon,
                  }}
                  onChange={(value) => handleFilterDetails(value, "start_date")}
                  value={filterDatas.start_date}
                  label="From"
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1.7}>
              <FormControl size="auto" fullWidth>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  disableFuture
                  components={{
                    OpenPickerIcon: DatePickerIcon,
                  }}
                  value={filterDatas.end_date}
                  onChange={(value) => handleFilterDetails(value, "end_date")}
                  label="To"
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Typography
                sx={{
                  color: "#2764F4",
                  textDecoration: "underline",
                  fontSize: "16px",
                  ml: 2,
                  textUnderlineOffset: "5px",
                  cursor: "pointer",
                }}
                onClick={() => handleReset()}
              >
                Reset
              </Typography>
            </Grid>
            <Grid item xs={1.5}>
              <Button
                variant="contained"
                disabled={disabled}
                endIcon={<DownloadIcon />}
                onClick={callDownload}
                sx={{
                  textTransform: "none",
                  color: "#FFFFFF",
                  fontSize: { md: "14px", xs: "12px" },
                  padding: { md: "10px", xs: "5px" },
                  backgroundColor: "#123FA9",
                  position: "relative",
                }}
              >
                Download .xls
                {loader && (
                  <CircularProgress
                    size={20}
                    sx={{ position: "absolute", top: "50%", left: "50%" }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {Reports_Service_columnName.map((value) => (
                    <StyledTableCell
                      onClick={() =>
                        clickSorting(
                          value.name,
                          sortColumnData[value.name],
                          value.key
                        )
                      }
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                          cursor: sortColumnNames.includes(value.name)
                            ? "pointer"
                            : "normal",
                        }}
                      >
                        {value.name}
                        {sortColumnData[value.name] !== undefined && (
                          <Box>
                            <IconButton
                              sx={{ flex: 1 }}
                              onClick={() =>
                                handleSortClick(
                                  value.name,
                                  sortColumnData[value.name],
                                  value.key
                                )
                              }
                            >
                              {/* {sortColumnData[value.name] === 'ASC' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />   } */}
                              {selectedSort[value.key] ? (
                                sortColumnData[value.name] === "ASC" ? (
                                  <ArrowUpwardIcon />
                                ) : (
                                  <ArrowDownwardIcon />
                                )
                              ) : (
                                <ArrowdDisableIcon />
                              )}
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              {serviceDatas?.data_array?.total_service_request_count > 0 && (
                <TableBody>
                  {serviceDatas?.data_array?.service_request_report_details?.map(
                    (value) => (
                      <StyledTableRow>
                        <TableCell sx={{ color: "#2764F4" }}>
                          {value.ticket_id}
                        </TableCell>
                        <StyledTableCell>
                          {moment
                            .utc(value?.created_at)
                            .local()
                            .format("DD MMM YYYY - HH:mm")}
                        </StyledTableCell>
                        <StyledTableCell>{value.display_name}</StyledTableCell>
                        <StyledTableCell>{value.company_name}</StyledTableCell>
                        <StyledTableCell>{value.name}</StyledTableCell>
                        <StyledTableCell>
                          <Typography
                            sx={{
                              background: getStatus(value.status, "bgcolor"),
                              display: "inline-flex",
                              padding: "5px",
                              fontWeight: 400,
                              fontSize: "13px",
                              color: getStatus(value.status, "color"),
                              borderRadius: "6px",
                            }}
                          >
                            {getStatus(value.status, "text")}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment
                            .utc(value?.updated_at)
                            .local()
                            .format("DD MMM YYYY - HH:mm")}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {serviceDatas?.data_array?.total_service_request_count > 0 ? (
            <Grid
              container
              sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}
            >
              <Grid item xs={9}>
                <Pagination
                  onChange={handlePagination}
                  sx={{ display: "flex", justifyContent: "center", py: 4 }}
                  count={Math.ceil(
                    serviceDatas?.data_array?.total_service_request_count /
                      filterDatas.rows
                  )}
                  page={currentPage}
                  size="large"
                  shape="rounded"
                />
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ color: "#0F2C6F", mr: 2 }}>
                  Show per page
                </Typography>
                <Select
                  value={filterDatas.rows}
                  name="rows"
                  onChange={(e) => {
                    handleFilterDetails(e);
                    setCurrentPage(1);
                  }}
                  size="small"
                  sx={{ height: "40px", width: 100 }}
                >
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="20">20</MenuItem>
                  <MenuItem value="50">50</MenuItem>
                  <MenuItem value="100">100</MenuItem>
                </Select>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", m: 5 }}>
              <Typography
                sx={{ textAlign: "center", fontSize: "20px", color: "#0F2C6F" }}
              >
                No data found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    faultReportFilterData: state.ReportsReducer.faultReportFilterData,
    serviceDatas: state.ReportsReducer.serviceDatas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilterData: () => dispatch(ReducerAction.getFaultReportsFilterData()),
    getServiceReports: (data) =>
      dispatch(ReducerAction.getServiceReports(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsServicerequest);
