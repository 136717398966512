const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const stringAndWhiteSpaceRegex = /^[A-Za-z\s]*$/;

const onlyAlphabetsWithSpaces = /^[A-Za-z\s]+$/;

export const validateEmail = (email) => {
    return emailRegex.test(email) ? true : false;
}

export const validateStringAndWhiteSpace = (str) => {
    return stringAndWhiteSpaceRegex.test(str) ? true : false;
}

export const validateAlphabets = (str) => {
    const trimmedStr = str.trim(); 
    return onlyAlphabetsWithSpaces.test(trimmedStr);
  };

export function containsNumbers(str) {
    console.log('str within ',str) 
    return /\d/.test(str);
}