import { handleActions } from "redux-actions";

const faqReducer = {
    addFaqStatus: null,
    allFaqList: null,
    updateFaqStatus: null,
    deleteFaqStatus: null,
    sortOrderStatus: null,
}

const reducer = handleActions({
    ADD_FAQ_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    ADD_FAQ_SUCCESS:(state,action)=>{

        return{
            ...state,
            addFaqStatus:action.payload
        }
    },
    ADD_FAQ_FAILURE:(state,action)=>{

        return{
            ...state,
            addFaqStatus:action.payload
        }
    },
    FAQ_NULL:(state)=> {
      return{
        ...state,
        addFaqStatus: null
      }
    },
    GETALL_FAQ_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GETALL_FAQ_SUCCESS:(state,action)=>{

        return{
            ...state,
            allFaqList:action.payload
        }
    },
    GETALL_FAQ_FAILURE:(state,action)=>{

        return{
            ...state,
            allFaqList:action.payload
        }
    },
    UPDATE_FAQ_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    UPDATE_FAQ_SUCCESS:(state,action)=>{

        return{
            ...state,
            updateFaqStatus:action.payload
        }
    },
    UPDATE_FAQ_FAILURE:(state,action)=>{

        return{
            ...state,
            updateFaqStatus:action.payload
        }
    },
    UPDATE_FAQ_NULL:(state)=> {
        return{
            ...state,
            updateFaqStatus: null
        }
      },
    DELETE_FAQ_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    DELETE_FAQ_SUCCESS:(state,action)=>{

        return{
            ...state,
            deleteFaqStatus:action.payload
        }
    },
    DELETE_FAQ_FAILURE:(state,action)=>{

        return{
            ...state,
            deleteFaqStatus:action.payload
        }
    },
    DELETE_FAQ_NULL:(state)=> {
        return{
            ...state,
            deleteFaqStatus: null
        }
      },
    SORT_FAQ_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    SORT_FAQ_SUCCESS:(state,action)=>{

        return{
            ...state,
            sortOrderStatus:action.payload
        }
    },
    SORT_FAQ_FAILURE:(state,action)=>{

        return{
            ...state,
            sortOrderStatus:action.payload
        }
    },
    SORT_FAQ_NULL:(state)=> {
        return{
          ...state,
          sortOrderStatus: null
        }
    },
},faqReducer)

export default reducer;