

import { handleActions } from 'redux-actions';
const ReceptionReducer = {
    visitorReasons: [],
    addStatus:null,
    receptionCompanies:[],
    specialInviteStatus:null,
    visitorsData:[],
    visitorsDataBms:[],
    recordCount:0,
    inviteAuthorities:[],
    invitationDetails:{},
    visitorsDetails:null,
    tenatnVisitorsList:[],
    teantInviteDetails:{},
    deleteVisitorStatus:null,
    addMultipleVisitorStatus:null,
    visitorMail:[],
    ticketList:[],
    visitorsRefreshData:[]
};
const reducer = handleActions({
    RECEPTION_ACTION_NULL: (state) => {
        return {
            ...state,
            addStatus:null,
            specialInviteStatus:null,
            deleteVisitorStatus:null,
            addMultipleVisitorStatus:null,
            visitorsData:[],
            visitorsDataBms:[],
            recordCount:0
        };
    },
    REASON_FOR_VISITOR_INITIATE: (state) => {
        return {
            ...state,
        };
    },
    REASON_FOR_VISITOR_SUCCESS: (state,action) => {
        return {
            ...state,
            visitorReasons:action.payload
        };
    },
    REASON_FOR_VISITOR_FAILURE: (state) => {
        return {
            ...state,
            visitorReasons:[]
        };
    },
    ADD_SINGLE_VISITOR_INITIATE: (state) => {
        return {
            ...state,

        };
    },
    ADD_SINGLE_VISITOR_SUCCESS: (state,action) => {
        return {
            ...state,
            addStatus:action.payload
        };
    },
    ADD_SINGLE_VISITOR_FAILURE: (state) => {
        return {
            ...state,
            addStatus:false
        };
    },
    ORGANIZATION_LIST_INITIATE: (state,action) => {
        return {
            ...state,
        };
    },
    ORGANIZATION_LIST_SUCCESS: (state,action) => {
        return {
            ...state,
            receptionCompanies:action.payload
        };
    },
    ORGANIZATION_LIST_FAILURE: (state,action) => {
        return {
            ...state,
            receptionCompanies:[]
        };
    },
    INVITE_SPECIAL_ACCESS_INITIATE: (state,action) => {
        return {
            ...state,
            receptionCompanies:[]
        };
    },
    INVITE_SPECIAL_ACCESS_SUCCESS: (state,action) => {
        return {
            ...state,
            specialInviteStatus:action.payload
        };
    },
    INVITE_SPECIAL_ACCESS_FAILURE: (state,action) => {
        return {
            ...state,
            specialInviteStatus:false
        };
    },
    VISITORS_LIST_INITIATE: (state,action) => {
        return {
            ...state,
        };
    },
    VISITORS_LIST_SUCCESS: (state,action) => {
        return {
            ...state,
            visitorsData:action.payload.response_data,
            recordCount:action.payload.resultCount,
            visitorsDetails:action.payload
        };
    },
    VISITORS_LIST_FAILURE: (state,action) => {
        return {
            ...state,
            visitorsData:[],
            recordCount:0,
        };
    },
    VISITORS_BMS_LIST_INITIATE: (state,action) => {
        return {
            ...state,
        };
    },
    VISITORS_BMS_LIST_SUCCESS: (state,action) => {
        return {
            ...state,
            visitorsDataBms:action.payload.response_data,
            recordCount:action.payload.resultCount,
            visitorsDetails:action.payload
        };
    },
    VISITORS_BMS_LIST_FAILURE: (state,action) => {
        return {
            ...state,
            visitorsDataBms:[],
            recordCount:0,
        };
    },
    VISITORS_LIST_REFRESH_INITIATE: (state,action) => {
        return {
            ...state,isLoading:true,
        };
    },
    VISITORS_LIST_REFRESH_SUCCESS: (state,action) => {
        console.log('vistoererer  ',action.payload.response_json)
        return {
            ...state,
            visitorsRefreshData:action.payload.response_json,
            isLoading:false,
        };
    },
    VISITORS_LIST_REFRESH_FAILURE: (state,action) => {
        return {
            ...state,
            visitorsRefreshData:[],
            isLoading:false,
        };
    },
    INVITE_AUTHORITIES_INITIATE: (state,action) => {
        return {
            ...state,
        };
    },
    INVITE_AUTHORITIES_SUCCESS: (state,action) => {
        return {
            ...state,
            inviteAuthorities:action.payload,
        };
    },
    INVITE_AUTHORITIES_FAILURE: (state,action) => {
        return {
            ...state,
            inviteAuthorities:[],
        };
    },
    INVITATION_DETAILS_BY_ID_INITIATE: (state,action) => {
        return {
            ...state,
        };
    },
    INVITATION_DETAILS_BY_ID_SUCCESS: (state,action) => {
        return {
            ...state,
            invitationDetails:action.payload,
        };
    },
    INVITATION_DETAILS_BY_ID_FAILURE: (state,action) => {
        return {
            ...state,
            invitationDetails:action.payload,
        };
    },
    TENANT_INVITERS_LIST_SUCCESS: (state,action) => {
        return {
            ...state,
            tenatnVisitorsList:action.payload,
        };
    },
    TENANT_INVITERS_DETAILS_SUCCESS: (state,action) => {
        return {
            ...state,
            teantInviteDetails:action.payload,
        };
    },
    DELETE_VISITOR_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    DELETE_VISITOR_SUCCESS:(state,action)=>{
        return{
            ...state,
            deleteVisitorStatus:action.payload
        }
    },
    DELETE_VISITOR_FAILURE:(state,action)=>{
        return{
            ...state,
            deleteVisitorStatus:action.payload
        }
    },
    ADD_MULTIPLE_VISITOR_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    ADD_MULTIPLE_VISITOR_SUCCESS:(state,action)=>{
        return{
            ...state,
            addMultipleVisitorStatus:action.payload
        }
    },
    ADD_MULTIPLE_VISITOR_FAILURE:(state,action)=>{
        return{
            ...state,
            addMultipleVisitorStatus:action.payload
        }
    },
    GET_ALLUSERS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ALLUSERS_SUCCESS:(state,action)=>{
        return{
            ...state,
            visitorMail:action.payload
        }
    },
    GET_ALLUSERS_FAILURE:(state,action)=>{
        return{
            ...state,
            visitorMail:action.payload
        }
    },
    GET_TICKETLIST_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_TICKETLIST_SUCCESS:(state,action)=>{
        return{
            ...state,
            ticketList:action.payload
        }
    },
    GET_TICKETLIST_FAILURE:(state,action)=>{
        return{
            ...state,
            ticketList:action.payload
        }
    }
},ReceptionReducer)
export default reducer;