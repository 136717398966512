import { Box, Button, CircularProgress, FormControl, IconButton, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import {ReactComponent as DatePickerIcon} from "../../Assets/icons/DatePickerIcon.svg";
import {ReactComponent as DownloadIcon} from '../../Assets/icons/Download.svg';
import { Reports_Employee_ColumnName } from "../../components/Data/Data";
import * as ReducerAction from "../../redux/actions/reportAction/index"
import { getLoggedUserData, getYYYYMMDDFormat, MenuProps } from "../../Utils/utils";
import '../../index.css';
import axios from "axios";
import moment from "moment";
import {ReactComponent as ArrowUpwardIcon} from '../../Assets/icons/sortasc.svg';
import {ReactComponent as ArrowDownwardIcon} from '../../Assets/icons/sortdes.svg';
import {ReactComponent as ArrowdDisableIcon} from '../../Assets/icons/sortDisable.svg';
import * as commonAction from "../../redux/actions/commonAction";

const StyledTableRow = styled(TableRow)({
    "&:nth-of-type(odd)":{
        backgroundColor:"#FAFBFF"
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F !important',
    padding: '5px !important',
    textAlign: "left",
    height: '50px',
    [`&.${tableCellClasses.head}`]: {
        fontSize: '13px',
        fontWeight: 550
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        fontWeight: 500,
    },
}));

const sortColumns = {
    'Employee Name': 'ASC',
    'Employee Type': 'ASC',
    'Job Title': 'ASC',
    'Company Name': 'ASC',
    'Access Start Date' : 'ASC',
    'Offboard Date' : 'ASC',
    'Created at' : 'ASC'
}

const payloadSortFields = {
    'Employee Name': 'employee_name',
    'Employee Type': 'employee_type',
    'Job Title': 'job_title',
    'Company Name': 'company_name',
    'Access Start Date' : 'access_start_date',
    'Offboard Date' : 'offboard_date',
    'Created at' : 'created_at'
}

const sortColumnNames = ['Employee Name','Employee Type','Job Title','Company Name','Access Start Date','Offboard Date','Created at']

const ReportsEmployees = ({handleBackButton,getEmployeeReport,employeeReport,getEmpReportsFilter,empReportFilterData,getAllLevels,levelDatas})=>{
    let {role_id,company_id} = getLoggedUserData()
    const [filterDatas,setFilterDatas] = useState({type:'',company:'',  selectedLevel: '',status:'',from_date:null,to_date:null,rows:'10', order_by: '',order_by_type: "",})
    const [currentPage,setCurrentPage] = useState(1)
    const [disabled,setDisabled] = useState(false)
    const [loader,setLoader] = useState(false)
    const [sortColumnData, setSortColumnData] = useState(sortColumns)
    const [selectedSort, setSelectedSort] = useState({employee_name:true,employee_type:false,job_title:false,company_name:false,access_start_date:false, created_at: false})

    useEffect(()=>{
        getEmpReportsFilter()
        getAllLevels()
    },[])

    const callDownload = async()=>{
        setDisabled(true)
        setLoader(true)
        try{
            let token=localStorage.getItem('userToken')
            let config = {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                responseType:'arraybuffer'
            };
            let response = await axios.get(employeeReport?.data_array?.excel_path,config)
            let blob = new Blob([response.data],{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
            const url = URL.createObjectURL(blob)
            let tag = document.createElement("a")
            tag.setAttribute('href',url)
            tag.setAttribute('download',`Employee Report ${moment(new Date()).format('DD MMM YYYY')}`)
            tag.click()
            setDisabled(false)
            setLoader(false)
        }catch(err){
            setDisabled(false)
        }
    }

    useEffect(()=>{
        if(!employeeReport?.data_array?.totalRecords>0){
            setDisabled(true)
        }else{
            setDisabled(false)
        }   
    },[employeeReport])

    useEffect(()=>{
        getEmployeeReport({
            limit:filterDatas.rows,
            offset:currentPage-1,
            employee_tye:filterDatas.type,
            company_id:role_id==4 ? company_id  : filterDatas.type!='bms_employee' ?  filterDatas.company : '',
            status:filterDatas.status,
            employee_id:'',
            from_date:filterDatas.from_date==null ? '' : getYYYYMMDDFormat(filterDatas.from_date),
            to_date:filterDatas.to_date==null ? '' :getYYYYMMDDFormat(filterDatas.to_date),
            searchData:'',
            level_id: filterDatas.selectedLevel,
            user_id:getLoggedUserData()?.user_id,
            order_by: payloadSortFields[filterDatas.order_by],
            order_by_type: filterDatas.order_by_type
        })
    },[currentPage,filterDatas])

    const handlePagination = (e,value)=>{
        setCurrentPage(value)
    }
    const handleInputValue = (e,datafrom)=>{
        if(datafrom){
            setFilterDatas({
                ...filterDatas,
                [datafrom]:e
            })
            setCurrentPage(1)
        }else{
            let {name,value} = e.target
            setFilterDatas({
                ...filterDatas,
                [name]:value
            })
            setCurrentPage(1)
        }
    }
    const handleReset = ()=>{
        setFilterDatas({
            ...filterDatas,
            type:'',company:'',status:'',selectedLevel:'',from_date:null,to_date:null,order_by: '',order_by_type: ""})
    }

    const handleSortClick = (val, sortVal, keyName) => {   
        const madeStatusFalse = {employee_name:false,employee_type:false,job_title:false,company_name:false,access_start_date:false, created_at: false}
        setSelectedSort({...madeStatusFalse,[keyName]:true})       
        let columnSortData = {...sortColumnData};
         for(const key in columnSortData){
           if(key === val){
              columnSortData[key] = sortVal === 'ASC' ? 'DESC' : 'ASC';
             setFilterDatas(prevValue => ({...prevValue, order_by: val, order_by_type: sortVal === 'ASC' ? 'DESC' : 'ASC' }))
           }else{
               columnSortData[key] = 'ASC';
            }
        }
        setSortColumnData({...columnSortData});
    } 

    const clickSorting = (columnName, sortColumnData, keyName) => {
        console.log('keyName ',keyName)
        if(sortColumnNames.includes(columnName))  handleSortClick(columnName, sortColumnData,keyName)
    }

    return(
        <>
            <Box>
                <BackButton onClick={()=>handleBackButton()}/>
                <Typography sx={{color:'#0F2C6F',fontSize:'24px',my:1.3}}>{getLoggedUserData().role_id=='4' ? 'Employees' : 'Staff Employees'} </Typography>
                <Grid container columnSpacing={3}>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{employeeReport?.data_array?.employeeCountArray?.onboardEmployeeCount}</Typography>
                            <Typography className="reportCount">Onboarding</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{employeeReport?.data_array?.employeeCountArray?.activeEmployeeCount}</Typography>
                            <Typography className="reportCount"> Active</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{employeeReport?.data_array?.employeeCountArray?.offboardEmployeeCount}</Typography>
                            <Typography className="reportCount">Offboarding</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{employeeReport?.data_array?.employeeCountArray?.inactiveEmployeeCount}</Typography>
                            <Typography className="reportCount">Inactive</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{my:2,backgroundColor:'#FFFFFF'}}>
                    <Grid container sx={{p:2}} columnSpacing={2}>
                        {getLoggedUserData().role_id!=4 && <Grid item xs={1.7}>
                            <FormControl size="small" fullWidth>
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Employee Type</InputLabel>
                                <Select value={filterDatas.type} onChange={(e)=>{
                                    handleInputValue(e)
                                }} name='type'  label='Employee Type'>
                                {empReportFilterData?.employeeTypeArray?.map((value)=>(
                                        <MenuItem value={value.employee_type}>{value.employee_type_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        }
                        {(getLoggedUserData().role_id!=4 && filterDatas.type!='bms_employee') &&  <Grid item xs={1.5}>
                            <FormControl size="small" fullWidth>
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Company</InputLabel>
                                <Select value={filterDatas.company} onChange={(e)=>handleInputValue(e)} name='company' MenuProps={MenuProps}  label='Company'>
                                    {empReportFilterData?.companyList?.map((value)=>(
                                        <MenuItem value={value.company_id}>{value.company_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        } 
                        <Grid item xs={1.3}>
                            <FormControl size="small" fullWidth>
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
                                <Select value={filterDatas.status} onChange={(e)=>handleInputValue(e)} name="status" label='Status'>
                                {empReportFilterData?.statusArray?.map((value)=>(
                                        <MenuItem value={value.status}>{value.status_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1.7}>
                            <DatePicker inputFormat="dd/MM/yyyy" components={{
                                    OpenPickerIcon:DatePickerIcon
                                }} value={filterDatas.from_date} onChange={(e)=>handleInputValue(e,'from_date')} label='From' renderInput={(params)=><TextField size="small" {...params} />} />
                        </Grid>
                        <Grid item xs={1.7}>
                            <DatePicker inputFormat="dd/MM/yyyy" components={{
                                    OpenPickerIcon:DatePickerIcon
                                }} value={filterDatas.to_date} onChange={(e)=>handleInputValue(e,'to_date')} label='To' renderInput={(params)=><TextField size="small" {...params} />} />
                        </Grid>
                        <Grid item xs={1}>
                            <FormControl size='small' sx={{ width: 100, }}>
                                <InputLabel
                                    sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px' }}
                                >
                                    Level
                                </InputLabel>
                                <Select
                                    value={filterDatas.selectedLevel || ''}
                                    label="level_id"
                                    name="level_id"
                                    onChange={(e) => {
                                        setFilterDatas(prevValue => ({...prevValue, selectedLevel: e.target.value}));
                                    }}
                                    MenuProps={{ style: { maxHeight: 300 } }}
                                >
                                     <MenuItem sx={{ color: "#0F2C6F" }} value="All">All</MenuItem>
                                    {levelDatas !== null && Array.isArray(levelDatas) && levelDatas.map((item, index) => (
                                        <MenuItem sx={{ color: "#0F2C6F" }} key={index} value={item?.level_id}>{item.level_name}</MenuItem>
                                    ))}


                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => handleReset()}>Reset</Typography>
                        </Grid>
                        {/* {getLoggedUserData().role_id==4 && <Grid item xs={4} /> } */}
                        <Grid item xs={1.7}>
                        <Button
                            variant='contained'
                            onClick={callDownload} 
                            endIcon={<DownloadIcon/>}
                            disabled={disabled}
                            sx={{
                                textTransform: 'none',
                                color: '#FFFFFF',
                                fontSize: { md: '14px', xs: '12px' },
                                padding: { md: '10px', xs: '5px' },
                                backgroundColor:'#123FA9',
                                height:50,
                                position:"relative"
                            }}
                        >
                            Download .xls
                        {loader && <CircularProgress size={20} sx={{position:'absolute',top:'50%',left:'50%'}} /> }
                        </Button>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {Reports_Employee_ColumnName.map((value)=>(
                                        <StyledTableCell  onClick={() => clickSorting(value.name,sortColumnData[value.name], value.key)}>
                                            <Box sx={{display: 'flex', justifyContent:'space-between', alignItems: "center", gap: 1, cursor:sortColumnNames.includes(value.name)? 'pointer':'normal'}}>
                                            {value.name}
                                             {sortColumnData[value.name] !== undefined && (
                                            <Box>
                                                <IconButton sx={{flex:1}} onClick={() => handleSortClick(value.name,sortColumnData[value.name],value.key)}>
                                                {/* {sortColumnData[value.name] === 'ASC' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />   } */}
                                                {selectedSort[value.key ] ? sortColumnData[value.name] === 'ASC' ? <ArrowUpwardIcon />   : <ArrowDownwardIcon  />   :  <ArrowdDisableIcon /> }
                                                </IconButton >
                                            </Box> ) }
                                            </Box>
                                        </StyledTableCell>

                                    ))}
                                </TableRow>
                            </TableHead>
                            {employeeReport?.data_array?.totalRecords>0 &&  <TableBody>
                                    {employeeReport?.data_array?.resultArrayList?.map((value)=>(
                                        <StyledTableRow>
                                            <StyledTableCell>{value.employee_name}</StyledTableCell>
                                            <StyledTableCell>{value.employee_type}</StyledTableCell>
                                            <StyledTableCell>{value.job_title}</StyledTableCell>
                                            <StyledTableCell>{value.company_name==null ? '-' : value.company_name}</StyledTableCell>
                                            <StyledTableCell>{value.access_start_date}</StyledTableCell>
                                            <StyledTableCell>{value.offboard_date}</StyledTableCell>
                                            <StyledTableCell>{value.accessLevel}</StyledTableCell>
                                            <StyledTableCell>{value.created_by_name}</StyledTableCell>
                                            <StyledTableCell>{value.created_at}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        }
                        </Table>
                    </TableContainer>
                    {employeeReport?.data_array?.totalRecords>0 ? 
                    <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}}>
                        <Grid item xs={9}>
                            <Pagination page={currentPage} onChange={handlePagination} sx={{py:3,display:'flex',justifyContent:'center'}} size="large" shape="rounded" count={Math.ceil(employeeReport?.data_array?.totalRecords/filterDatas.rows)}></Pagination> 
                        </Grid>
                        <Grid item xs={3} sx={{display:'flex',alignItems:'center'}}>
                            <Typography sx={{color:'#0F2C6F',mr:2}}>Show per page</Typography>
                             <Select value={filterDatas.rows} name="rows" onChange={(e)=>{
                                handleInputValue(e)
                                setCurrentPage(1)
                                }} size="small" sx={{height:'40px',width:100}} >
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="20">20</MenuItem>
                                <MenuItem value="50">50</MenuItem>
                                <MenuItem value="100">100</MenuItem>
                            </Select>
                        </Grid>
                    </Grid> : 
                     <Box sx={{display:"flex",justifyContent:"center",m:5}}>
                        <Typography sx={{textAlign:"center",fontSize:"20px",color:'#0F2C6F'}}>No data found</Typography>
                     </Box>
                    }
                     </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        employeeReport:state.ReportsReducer.employeeList,
        empReportFilterData:state.ReportsReducer.empReportFilterData,
        levelDatas: state.CommonReducer.levelDatas,
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getEmployeeReport:(data)=>dispatch(ReducerAction.getEmployeeReport(data)),
        getEmpReportsFilter:()=>dispatch(ReducerAction.getEmpReportsFilter()),
        getAllLevels: () => (dispatch(commonAction.getAllLevels())),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReportsEmployees)