import { Box, Button, ClickAwayListener, Grid, Grow, Menu, MenuItem, MenuList, Paper, Popper, styled, Tab, Tabs, Typography } from '@mui/material'
import { NavLink } from "react-router-dom"
import React, { useState, useRef, useEffect } from 'react'
 import {ReactComponent as AddIcon} from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import * as visitorsAction from '../../redux/actions/visitorsAction'
import TenantVisitorsList from './TenantVisitorsList';
import { getLoggedUserData, getYYYYMMDDFormat } from '../../Utils/utils';
const StyledTab = styled(Tab)(({ theme }) => ({
    color: '#6F80A9',
    textTransform: "none",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
        fontSize: "14px",
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography variant="span">{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const uploadOptions = [{
    id: 1, 
    title: "Update Individual",
    navLink: "/batch_invitation",
}, {
    id: 2,
    title: "Batch via Excel",
    navLink: "/batch_invitation_excel",
}]

const TenantVisitors = ({ tenatnVisitorsList, eventAndPromotionsCount, getTenantVisitorsList }) => {
    const anchorRef = useRef(null);
    let { company_details, company_id } = getLoggedUserData();
    let navigate = useNavigate();
    const [authData, setAuthData] = useState({})
    const [tabValue, setTabValue] = useState(0)
    const [currentPage,setCurrentPage] = useState(1)
    const [filterDate,setFilterDate] = useState(null)
    const [userData, setUserData] = useState();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const uploadOptionsOpen = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useEffect(() => {
        if(localStorage.getItem('userData') !== null){
            setUserData(JSON.parse(localStorage.getItem('userData')));
        }
    }, [localStorage.getItem('userData')])

    console.log(userData)

    useEffect(() => {
        let datas = {
            company_id: company_id,
            limit: 10,
            offset: currentPage-1,
            listFor:tabValue==0 ?  'active' : 'History',
            filter_date:filterDate==null ? '' : getYYYYMMDDFormat(filterDate)
        }
        getTenantVisitorsList(datas)
    }, [currentPage])

    useEffect(() => {
        let datas = {
            company_id: company_id,
            limit: 10,
            offset: 0,
            listFor:tabValue==0 ?  'active' : 'History',
            filter_date:filterDate==null ? '' : getYYYYMMDDFormat(filterDate)
        }
        getTenantVisitorsList(datas)
    }, [filterDate])

    const handleChange = (event, newValue) => {
        setFilterDate(null)
        setTabValue(newValue);
        let datas = {
            company_id: company_id,
            limit: 10,
            offset: 0,
            listFor: event.target.name,
            filter_date:''
        }
        getTenantVisitorsList(datas)
    };

    useEffect(() => {
        let localData = JSON.parse(localStorage.getItem('userData'))
        setAuthData(localData)
    }, [])
    return (
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)",minHeight:"100%"}}>
            <Grid item xs={0.5} />
            <Grid item xs={11} >
            <div>       
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={uploadOptionsOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
       >
        {uploadOptions.map(item => <MenuItem key={item.id} onClick={handleClose} sx={{width: 'inherit'}}><NavLink to={item.navLink} style={{textDecoration: "none", color: "inherit", fontSize: "inherit", fontWeight: "inherit"}}><Typography>{item.title}
            </Typography></NavLink></MenuItem>
            )}
        
      </Menu>
    </div>
               <Box sx={{display:"flex",justifyContent:"space-between",mt:8, width: "100%"}}>
                    <Box sx={{width: "100%"}}>
                            <Typography style={{ fontSize: "26px", fontWeight: "500", color: '#002D74' }}>
                                Invite Visitor
                            </Typography>
                            <Typography sx={{ fontSize: "14px", fontWeight: "500", color: '#002D74',width:'95%' }}>
                                {/* Below is a list of invitation requests made by {company_details?.companyArrayList[0]?.company_name?.toUpperCase()} */}
                                Tenant Managers are able to make invitation requests for a duration of up to one month. Your tenants will only be able to
                                make invitation requests for up to one day.
                                {/* Below is a list of invitation requests made by {userData?.company_details?.companyArrayList[0]?.company_name} */}
                            </Typography>
                        </Box>
                        <Button variant="contained"
                            sx={{ background: '#123FA9', "&:hover": { backgroundColor: "#123FA9" },minWidth:'146px',textTransform: 'capitalize',height: "36.5px",fontSize: { md: "14px", xs: "10px"} }}
                            // onClick={() => navigate('/batch_invitation')}
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            endIcon={<AddIcon />}>
                            Invite Visitor
                        </Button>
                </Box> 
               
                <Box
                    sx={{ background: "white", borderBottom: "1px solid #DBDFE9", borderRadius: "8px 8px 0px 0px",mt:4 }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "#123FA9" } }}
                    >
                        <StyledTab
                            name='active'
                            label="Active"
                            {...a11yProps(0)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        />  
                        <StyledTab
                            name='History'
                            label="Past"
                            {...a11yProps(1)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        />
                        {/* <StyledTab
                            name='draft'
                            label="Drafts"
                            {...a11yProps(2)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        /> */}
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0} >
                    <TenantVisitorsList currentPage={currentPage} setCurrentPage={setCurrentPage} filterDate={filterDate} setFilterDate={setFilterDate} activeType='all' tableData={tenatnVisitorsList.response_json} tableDataCount={tenatnVisitorsList.totalInvitaionCount} />
                </TabPanel>
                <TabPanel value={tabValue} index={1} >
                    <TenantVisitorsList currentPage={currentPage} setCurrentPage={setCurrentPage} filterDate={filterDate} setFilterDate={setFilterDate} activeType='closed' tableData={tenatnVisitorsList.response_json} tableDataCount={tenatnVisitorsList.totalInvitaionCount} />
                </TabPanel>
                {/* <TabPanel value={tabValue} index={2} >
                    <TenantVisitorsList activeType='draft' tableData={tenatnVisitorsList.response_json} tableDataCount={tenatnVisitorsList.totalInvitaionCount} />
                </TabPanel> */}
            </Grid>
            <Grid item xs={0.5} />
        </Grid>

        
    )
}

const mapStateToProps = (state) => {
    return {
        tenatnVisitorsList: state.ReceptionReducer.tenatnVisitorsList
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantVisitorsList: (data) => { dispatch(visitorsAction.getTenantVisitorsList(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TenantVisitors)