import React, {useState, useEffect } from 'react'
import { useNavigate,useParams, useLocation } from "react-router-dom";
import {Box, Grid, Typography, Checkbox , Dialog, DialogTitle, DialogContent, DialogActions,  TextField, Input,  InputLabel, IconButton, FormControl, MenuItem, Select, Chip, Button, Switch, SwitchProps,FormGroup, FormControlLabel, Menu, Card, CardContent, CardMedia, CardActionArea, CircularProgress  } from "@mui/material";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as CloseIcon } from '../../Assets/icons/AgreementCloseIcon.svg';
import ToastAlert from "../common/Alert";
import Faultalert from "../common/faultalert";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import CustomLeftNav from './carousel/CustomLeftNav';
import CustomRightNav from './carousel/CustomRightNav';
import FullScreenIcon from './carousel/FullScreenIcon'; 
import { makeStyles } from '@mui/styles';
import {styled} from '@mui/styles';
import { connect } from 'react-redux';
import { addNonWorkingHourRoomBookingAction, facilityBookingPaymentStatusNullAction, facilityBookingRedirectPaymenNullAction, facilityBookingRedirectPaymentAction, getSingleRoomListByIdAction, addNonWorkingHourRoomBookingNullAction } from '../../redux/actions/facilityBookingAction';
import moment from 'moment';
  
 
const useStyles = makeStyles((theme) => ({
    imageGalleryBullets: {
        '& .image-gallery-bullets': {
            zIndex: '1',
          },
      },
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root' : {
        minWidth:'30%',
    },
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

 

function SingleRoomFeature({getAvailableRoomListData,getSingleRoomListDetailsData,getSingleRoomListById, facilityBookingRedirectPayment, facilityBookingRedirectPaymentData, facilityBookingRedirectPaymentActionNull, addNonWorkingHourRoomBooking,addNonWorkingHourRoomBookingData, facilityBookingPaymentStatusData, facilityBookingPaymentStatusNull, addNonWorkingHourRoomBookingNull,termsAndCondition}) {
    const navigate = useNavigate();
    const {feature_id} = useParams();
    const [carouselImages, setCarouselImages] = useState();
    const [roomDetails, setRoomDetails] = useState();
    const [featureIcons, setFeatureIcons] = useState([]);
    const [isAgreed, setIsAgreed] = useState(false);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();   
    const [toastConfigData, setToastConfigData] = useState({ message: '', status: false, color: '', icon: '' })
    const location = useLocation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgreement = (event) => {
        if(event.target.checked == true) {
            setOpen(true);
        } else {
            setIsAgreed(false);
        }
        
      };

    const handleYesAgreement = () => {
        setIsAgreed(true);
        setOpen(false);
    }

 

    useEffect(() => {
 

        let params;
        if(getAvailableRoomListData.response?.dataArray?.post_data ){ 
            // let customParams = {
            //     "start_date": "2023-11-22T01:00:00",
            //     "end_date": "2023-11-22T04:00:00",
            //     "room_capacity": "01-10",
            //     "is_vc_booking": "false"
            // }
 
                params = {                
                    ...getAvailableRoomListData.response?.dataArray?.post_data,
                    // ...customParams,
                    room_id : Number(feature_id)
                 };
                
                 
                
                getSingleRoomListById(params);
            }else if(localStorage.getItem('facilityPaymentParams') !== null){
                params = {
                    ...JSON.parse(localStorage.getItem('facilityPaymentParams')),                    
                }
                getSingleRoomListById(params);

        }else{
            navigate('/booking_selection');
        }

            
     }, [])

    useEffect(() => {
         if(getSingleRoomListDetailsData.response?.response_status === 1){
            setRoomDetails(getSingleRoomListDetailsData.response?.dataArray?.[0]?.room_details_array)
            setCarouselImages(makeCarouselImages(getSingleRoomListDetailsData.response?.dataArray?.[0]?.room_image_details_array))
            setFeatureIcons(getSingleRoomListDetailsData.response?.dataArray?.[0]?.tec_feature_icon_array_list)
        }

        console.log("start date111",getSingleRoomListDetailsData.response?.dataArray?.[0]?.room_details_array?.requested_utc_start_date_time);
    }, [getSingleRoomListDetailsData])

    useEffect(() => {        
         if(facilityBookingRedirectPaymentData.response?.response_status === 1){            
    
                window.open(facilityBookingRedirectPaymentData.response.dataArray.redirectionUrl, '_self', );
            }
    }, [facilityBookingRedirectPaymentData])

    useEffect(() => {
        if(addNonWorkingHourRoomBookingData.response?.response_status == 1){
            let booking_id = addNonWorkingHourRoomBookingData.response?.dataArray?.room_booking_id;
            navigate(`/facility_booking/${booking_id}`);            
        } else if(addNonWorkingHourRoomBookingData.response?.response_status == 0) {
            setToastConfigData({ message: addNonWorkingHourRoomBookingData?.response?.message, status: true, color: 'red', icon: 'close' })  
            setTimeout(() => {
                navigate('/booking_selection')
                addNonWorkingHourRoomBookingNull()
                setToastConfigData({ message: "", status: false, color: '', icon: '' })  
            },5000)
            
        }
    }, [addNonWorkingHourRoomBookingData])

    useEffect(() => {
        if(facilityBookingPaymentStatusData.status !== null && getSingleRoomListDetailsData.response?.response_status === 1 ){
            if(facilityBookingPaymentStatusData.status === 2){
                console.log(facilityBookingPaymentStatusData);
                setToastConfigData({ message: facilityBookingPaymentStatusData.message, status: true, color: 'red', icon: 'close' })            
            }else if(facilityBookingPaymentStatusData.status === 0){
                setToastConfigData({ message: facilityBookingPaymentStatusData.message, status: true, color: 'red', icon: 'close' })         
            }
            facilityBookingPaymentStatusNull();

            setTimeout(() => {
                setToastConfigData({ message: '', status: false, color: '', icon: '' })
            }, 6000)

        }
    }, [facilityBookingPaymentStatusData, getSingleRoomListDetailsData])

    const makeCarouselImages = (imagesArr) => {
        let Arr = [];

        if(Array.isArray(imagesArr) === false){
            return;
        }

        for(const ele of imagesArr){
            Arr.push({original: ele.path});
        }

        return Arr;
    }

    const handleFacilityBooking = () => {
        if(roomDetails?.isWithinOfficeHour){
            
            let respParams;
            if(localStorage.getItem("facilityPaymentParams") === null){
                respParams =  getAvailableRoomListData.response?.dataArray?.post_data;
            }else{
                respParams = JSON.parse(localStorage.getItem('facilityPaymentParams'));
            }
            let params = {
                start_date: respParams?.start_date,
                end_date: respParams?.end_date,
                room_id: Number(feature_id),
                base_price: roomDetails?.base_price,
                tax_value: roomDetails?.tax_value,
                discount: roomDetails?.discount,
                total_price: roomDetails?.totalAmountValue,
                remarks: "-",
                guest_count: 4,
                requested_from: "web",
                is_vc_booking: respParams?.is_vc_booking === "true" ? 1 : respParams?.is_vc_booking === "false" ? 0 : null,
                non_office_hour_duration: roomDetails?.splitted_non_office_hour_duration,
                non_office_hour_price_per_hour: roomDetails?.splitted_non_office_hour_price_per_hour,  
                non_office_hour_total_price: roomDetails?.splitted_non_office_hour_total_price,
                office_hour_duration: roomDetails?.splitted_office_hour_duration,   
                office_hour_price_per_hour: roomDetails?.splitted_office_hour_price_per_hour,
                office_hour_total_price :roomDetails?.splitted_office_hour_total_price,
                best_pricing_strategy_name: roomDetails?.best_pricing_strategy_name, 
            };

            localStorage.setItem('facilityPaymentParams', JSON.stringify({
                ...respParams,
                room_id : Number(feature_id) }))
            
            facilityBookingRedirectPayment(params);
        }else{
            let getAvailableRoomListResponseParams = getAvailableRoomListData.response?.dataArray?.post_data;
             let params = {
                start_date: getAvailableRoomListResponseParams?.start_date,
                end_date: getAvailableRoomListResponseParams?.end_date,
                is_vc_booking: getAvailableRoomListResponseParams?.is_vc_booking === "true" ? true : false,
                room_id: Number(feature_id),
                base_price: roomDetails?.base_price,
                tax_value: roomDetails?.tax_value,
                discount: roomDetails?.discount,
                total_price: roomDetails?.totalAmountValue,
                remarks: "-",
                guest_count: Number(roomDetails?.maximum_attendees_count),
                requested_from: "web",
                non_office_hour_duration: roomDetails?.splitted_non_office_hour_duration,
                non_office_hour_price_per_hour: roomDetails?.splitted_non_office_hour_price_per_hour,  
                non_office_hour_total_price: roomDetails?.splitted_non_office_hour_total_price,
                office_hour_duration: roomDetails?.splitted_office_hour_duration,   
                office_hour_price_per_hour: roomDetails?.splitted_office_hour_price_per_hour,
                office_hour_total_price :roomDetails?.splitted_office_hour_total_price,   
                best_pricing_strategy_name: roomDetails?.best_pricing_strategy_name
            }
             addNonWorkingHourRoomBooking(params);
        }

         

    }
    

    const Carousel = () => {
        return(
        <>
            {carouselImages && <Box className={classes.imageGalleryBullets} sx={{height:'500px', overflow:'hidden'}}>
                <ImageGallery  items={carouselImages} showBullets   showFullscreenButton={true} showThumbnails={false} showPlayButton={false}  className="image-gallery-image"
                renderLeftNav= {(onClick, disabled) => (
                    <CustomLeftNav onClick={onClick} disabled={disabled} />
                )}
                renderRightNav= {(onClick, disabled) => (
                    <CustomRightNav onClick={onClick} disabled={disabled} />
                    )}
                renderFullscreenButton= {(onClick, disabled) => (
                    <FullScreenIcon onClick={onClick} disabled={disabled} />
                    )}
                />
            </Box>}
        </>
        )
    }

    const RoomDetails = () => {
        return(
            <Box sx={{display:'flex', flexDirection:'column', gap:'15px', mt:3, p:3}}>
            <Typography sx={{color:'#0F2C6F',fontSize:'18px',fontWeight:500, lineHeight:'24px'}}>Room Details</Typography>
            <Box sx={{display:'flex', gap:'10px', justifyContent:'space-between', flexWrap:'wrap'}}>
                <Box sx={{display:'flex', gap:'10px', flexDirection:'column'}}>
                    <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'20px'}}>Maximum Attendees</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'16px',fontWeight:400, lineHeight:'24px'}}>{roomDetails?.maximum_attendees}</Typography>
                </Box>
                <Box sx={{display:'flex', gap:'10px', flexDirection:'column'}}>
                    <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'20px'}}>level</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'16px',fontWeight:400, lineHeight:'24px'}}>{roomDetails?.level}</Typography>
                </Box>
                <Box sx={{display:'flex', gap:'10px', flexDirection:'column'}}>
                    <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'20px'}}>Unit</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'16px',fontWeight:400, lineHeight:'24px'}}>{roomDetails?.unit}</Typography>
                </Box>
                <Box sx={{display:'flex', gap:'10px', flexDirection:'column'}}>
                    <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'20px'}}>Rate</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'16px',fontWeight:400, lineHeight:'24px'}}>{roomDetails?.rate_breakdown}</Typography>
                </Box>
              
            </Box>
            {/* <Box sx={{display:'flex', gap:'10px', flexDirection:'column', mt:1}}>
                    <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'20px'}}>Video Conference Capable</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'16px',fontWeight:400, lineHeight:'24px'}}>{roomDetails?.video_conference_capable}</Typography>
                </Box> */}

        </Box>
        )
    }

    const FeatureDetails = () => {
        return(
            <Box sx={{ p:3,}}>
                <Typography sx={{mb:3,color:'#0F2C6F',fontSize:'18px',fontWeight:500, lineHeight:'24px'}}>Features</Typography>
                <Box sx={{display:'flex',gap:'10px', width:'100%', flexWrap:'wrap'}}>
                {featureIcons?.map((item, index) => (
                    <Box key={index} sx={{padding:'12px',mb:3,display:'flex', gap:'10px', flexDirection:'column', minWidth:'28%', backgroundColor: item.isAvailable ? '#EAF0FF' : '#F3F4F8', borderRadius:'12px'}}>
                            <Box>
                            <img  src={item?.path} height={24} />
                        </Box>
                        <Typography sx={{color: item?.display_status ? '#0F2C6F' : '#C0C7D7',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>{item?.name}</Typography>
                    </Box>
                ))}  
                </Box>                                
            </Box>
        )
    }

    const CostBreakdown = () => {
        return(
            <Box sx={{ width:'-webkit-fill-available', position: {xs: 'relative',lg:"fixed"}, mt: 4, overflowY: 'auto', height: '-webkit-fill-available', mr: '40px', maxWidth:'650px', width:{xs:'auto', lg:'413px'}}}>
            <Box  sx={{backgroundColor:"#FFFFFF",p:3, borderRadius:"8px",boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.05)",}}>
                    <Typography sx={{mb:3,color:'#0F2C6F',fontSize:'18px',fontWeight:500, lineHeight:'24px'}}>Cost Breakdown</Typography>
                    <Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
                        <Box sx={{mt:3,display:'flex', flexDirection:'column', gap:'10px'}}>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Date of Request</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{moment.utc(roomDetails?.requested_utc_start_date_time).local().format('DD MMM YYYY')}</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Requested hours</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{moment.utc(roomDetails?.requested_utc_start_date_time).local().format('HH:mm')} - {moment.utc(roomDetails?.requested_utc_end_date_time).local().format('HH:mm')}</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Total Duration(Office Hrs)</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.tempWorkingDurationHours || '-'}</Typography> 
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Rate Breakdown(Office Hrs)</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.tempWorkingBaseRatePerHalfHour || '-'}</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Total Duration(Non Office Hrs)</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.tempNonWorkingDurationHours || '-'}</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Rate Breakdown(Non Office Hrs)</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.tempNonWorkingBaseRatePerHalfHour || '-'}</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Discount</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.discount}</Typography>
                            </Box>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#6F80A9',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>Extra Charges</Typography>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.extra_charges}</Typography>
                            </Box>
                            <Box sx={{ borderBottom: "1px solid #DBDFE9"}}/>
                            <Box sx={{display:'flex', justifyContent:'space-between'}}>
                                <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:600, lineHeight:'24px'}}>Total Amount (SGD)</Typography>
                                <Typography sx={{color:'#A48B2C',fontSize:'24px',fontWeight:500, lineHeight:'24px', textAlign: 'end'}}>{roomDetails?.totalAmount}</Typography>
                            </Box>
                            <Box sx={{display:'flex', alignItems:'center'}}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={isAgreed}
                                    onChange={handleAgreement}
                                    color="primary"
                                />
                                }
                            />
                            <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, lineHeight:'24px'}}>I agree with the <a style={{color:'#0F2C6F', textDecoration:"underline", cursor:"pointer"}} onClick={() => setOpen(true)}>Terms and Conditions</a></Typography>
                            </Box>
                        </Box> 
                    </Box>
                    <Box sx={{width:'100%'}}>
                    <Button variant='contained' disabled={(facilityBookingRedirectPaymentData.isLoading === true || addNonWorkingHourRoomBookingData.isLoading == true) == true ? true : !isAgreed} sx={{mt:3,textTransform: 'capitalize',color: '#FFFFFF', borderRadius: '8px', width:'100%', height: '48px', backgroundColor: '#123FA9', '&:hover': { backgroundColor: '#123FA9' }, display: "flex",alignItems: "center", gap: 2}} onClick={() => handleFacilityBooking()}>Book Room
                    {(facilityBookingRedirectPaymentData.isLoading === true || addNonWorkingHourRoomBookingData.isLoading == true) && <CircularProgress sx={{color: "white"}} size={25} />}
                    </Button>
                    </Box>
            </Box>
        )
    }

    const AgreementModal = () => {
        return(
            <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Terms & Conditions
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {/* <Typography sx={{mb:3, color:'#6F80A9',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>Please read the terms and conditions below carefully before proceeding.</Typography>
                <Box sx={{display:'flex', flexDirection:'column', gap:'10px', mb:3}}>
                    <Typography sx={{color:'#A48B2C',fontSize:'14px',fontWeight:600, lineHeight:'20px'}}>BOOKING POLICY</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>Bookings for periods during working hours will be processed immediately.</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>Bookings for periods after working hours will be subjected to TEC’s booking policy. TEC staff will directly contact you if the booking is able to be completed via your contact number.</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', gap:'5px', mb:3}}>
                    <Typography sx={{color:'#A48B2C',fontSize:'14px',fontWeight:600, lineHeight:'20px'}}>CANCELLATION POLICY</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>We accept no responsibility for any cancellation by the Client. Any cancellation of the Meeting Room booking will result in a 100% cancellation booking charge. The Executive Centre will not provide a refund, whether in a partial or full amount for the total amount charged.To get in touch with our Team about cancelling your booking, please contact Events-Singapore@executivecentre.com.</Typography>
                </Box>
                <Box sx={{display:'flex', flexDirection:'column', gap:'10px', mb:3}}>
                    <Typography sx={{color:'#A48B2C',fontSize:'14px',fontWeight:600, lineHeight:'20px'}}>RESCHEDULING POLICY</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>To reschedule, the Client must notify The Executive Centre Team 2 working days before the booking starts. The Meeting Room booking can be rescheduled up to 1 time only and within a 3-month period, at no additional charge. Rescheduling is not guaranteed and is based on the availability of the space requested.To get in touch with our Team about rescheduling your booking, please contact Events-Singapore@executivecentre.com.</Typography>
                </Box> */}
                <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: "4px" }}>

            <Typography variant='p' sx={{ color: "#6F80A9", lineHeight: "24px", fontSize: '14px' }}>{termsAndCondition && termsAndCondition[0]?.description}</Typography>
            <Box>
            {termsAndCondition && termsAndCondition[1]?.rules?.map((item, index) => (
                <Box key={index} sx={{ py: 1 }}>
                {Object.entries(item).map(([key, value]) => (
                    <>
                    <Box>
                        <Typography key={key} variant='p' sx={{py: 1, color: "#A48B2C", lineHeight: "24px", fontSize: '14px', fontWeight: 600 }}>{key}</Typography>
                    </Box>
                    <Box>
                        <Typography key={key} variant='p' sx={{py: 1, color: "#0F2C6F", lineHeight: "24px", fontSize: '14px' }}>{value}</Typography>
                    </Box>
                    </>
                ))}
                </Box>
            ))}
            </Box>
        </Box>
                <Box sx={{display:'flex', justifyContent:'space-between', gap:'10px', mb:3}}>
                    <Button variant='outlined' sx={{ textTransform: 'capitalize', color: '#0F2C6F',borderRadius: '8px', minWidth:'50px',  height: '40px',}} onClick={handleClose}>Cancel</Button>
                    <Button variant='contained' sx={{textTransform: 'capitalize',color: '#FFFFFF', borderRadius: '8px', minWidth:'50px',  height: '40px', backgroundColor: '#123FA9', '&:hover': { backgroundColor: '#123FA9' }}} onClick={handleYesAgreement}>Yes, I Agree</Button>
                </Box>
            </DialogContent>

          </BootstrapDialog>
        )
    }
  return (
    <>  
     <ToastAlert message={toastConfigData.message} status={toastConfigData.status} color={toastConfigData.color} icon={toastConfigData.icon} />    
    <AgreementModal/>
        {getSingleRoomListDetailsData.isLoading === true ? <Box sx={{height: "100%", width: "100%", display: "grid", placeItems: "center"}}><CircularProgress /></Box> : 
        <Box sx={{pb:7,px:3,backgroundColor:"rgba(143, 146, 161,0.05)"}}>
            
            <Grid container >
                <Grid item xs={8} >
                    <Grid container >
                        <Grid item xs={11.7} >
                        <Box sx={{pb:2}}>
                <BackIcon cursor='pointer' onClick={()=>navigate('/booking_selection')} />
                <Typography sx={{color:'#0F2C6F',fontSize:'26px',fontWeight:500,my:2}}>{roomDetails?.title}</Typography>
                <Box sx={{display:'flex', gap:'10px'}}>
                    <Location/>
                    <Typography sx={{color:'#9AA6C3',fontSize:'14px',fontWeight:500}}>Singapore land Tower</Typography>
                </Box>
            </Box>
                            <Box sx={{backgroundColor:"#FFFFFF",borderRadius:"8px",boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.05)"}}>
                                <Carousel />
                                <RoomDetails />
                                <Box sx={{mx:3, borderBottom: "1px solid #DBDFE9"}}/>
                                <FeatureDetails />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} sx={{height: "100%" }}  >
                    <CostBreakdown />
                </Grid>
            </Grid>
        </Box>

}

    </>
  )
}

const mapDispatchToProps = dispatch => {
    return {
        getSingleRoomListById: (params) => dispatch(getSingleRoomListByIdAction(params)),
        facilityBookingRedirectPayment: (params) => dispatch(facilityBookingRedirectPaymentAction(params)),
        facilityBookingRedirectPaymentActionNull: () => dispatch(facilityBookingRedirectPaymenNullAction()),
        addNonWorkingHourRoomBooking : (params) => dispatch(addNonWorkingHourRoomBookingAction(params)),
        facilityBookingPaymentStatusNull: () => dispatch(facilityBookingPaymentStatusNullAction()),
        addNonWorkingHourRoomBookingNull: () => dispatch(addNonWorkingHourRoomBookingNullAction())
    }
}

const mapStateToProps = state => {
    return {
        getAvailableRoomListData: state.FacilityBookingReducer.getAvailableRooms,
        getSingleRoomListDetailsData: state.FacilityBookingReducer.getSingleRoomDetailsById,
        termsAndCondition: state.FacilityBookingReducer.getSingleRoomDetailsById?.response && JSON.parse(state.FacilityBookingReducer.getSingleRoomDetailsById?.response?.amenity_booking_terms_and_conditions?.config_value),
        facilityBookingRedirectPaymentData: state.FacilityBookingReducer.facilityBookingRedirectPayment,
        addNonWorkingHourRoomBookingData: state.FacilityBookingReducer.addNonWorkingHourRoomBooking,
        facilityBookingPaymentStatusData: state.FacilityBookingReducer.facilityBookingPaymentStatus,
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleRoomFeature)