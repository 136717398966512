import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  InputLabel,
  Box,
  TextField,
  Button,
  OutlinedInput,
} from "@mui/material";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as RefreshIcon } from "../../Assets/icons/Refresh.svg";
import { ReactComponent as AddIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/Delete.svg";
import { getLastUpdatedTime } from "../../Utils/utils";
import * as ImportantContactAction from "../../redux/actions/ImportantContactAction/index";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { id } from "date-fns/locale";

const Style = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

const Contacts = ({
  createContactStatus,
  createContact,
  setUpdateImportantContact,
  oneGroupContactList,
  getOneGroupContact,
  editContact,
  editContactStatus,
  addGroupSubcontact,
}) => {
  let { title, isGroup, contactId, isEdit } = useParams();
  let obj1 = {
    Title1: "",
    contactNumber1: "",
    description1: "",
    groupTitle: isGroup === "true" ? "" : title,
  };
  let obj2 = { groupTitle: isGroup === "true" ? "" : title };
  const navigate = useNavigate();
  const [showInputForm, setShowInputForm] = useState([
    { id: 1, formName: "Contact 1" },
  ]);
  const [inputDetails, setInputDetails] = useState(
    isEdit == "true" ? obj2 : obj1
  );
  const [inputDetailsError, setInputDetailsError] = useState({});
  const [time, setTime] = useState({
    hour: "00",
    minute: "00",
    am_pm: "",
    timeStr: "",
  });
  const customStyle = Style();
  const [toastData, setToastData] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [existingData, setExistingData] = useState(0);
  useEffect(() => {
    setTime({ ...time, timeStr: getLastUpdatedTime() });
    if (contactId !== false) {
      let data = {
        id: parseInt(contactId),
      };
      getOneGroupContact(data);
    }
    if (isEdit == "true") {
      setExistingData(oneGroupContactList?.sub_contacts?.length);
    }
  }, []);

  useEffect(() => {
    if (isEdit == "true") {
      let arr = [];
      let obj = {};
      for (let i = 0; i < oneGroupContactList?.sub_contacts?.length; i++) {
        arr.push({
          id: oneGroupContactList?.sub_contacts[i]?.sub_contact_id,
          formName: `Contact ${oneGroupContactList?.sub_contacts[i]?.sub_contact_id}`,
        });

        obj[`Title${oneGroupContactList?.sub_contacts[i]?.sub_contact_id}`] =
          oneGroupContactList?.sub_contacts[i]?.title;
        obj[
          `description${oneGroupContactList?.sub_contacts[i]?.sub_contact_id}`
        ] = oneGroupContactList?.sub_contacts[i]?.desc;
        obj[
          `contactNumber${oneGroupContactList?.sub_contacts[i]?.sub_contact_id}`
        ] = oneGroupContactList?.sub_contacts[i]?.number;
      }
      setShowInputForm([...arr]);
      setInputDetails({
        ...inputDetails,
        ...obj,
      });
    } else {
    }
  }, [oneGroupContactList]);

  useEffect(() => {
    setLoaderStatus(false);
    if (createContactStatus?.response_status == "success") {
      setToastData({
        message: createContactStatus?.response_message,
        status: true,
        color: "green",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "", icon: "" });
        setUpdateImportantContact();
        navigate("/information/0");
      }, 5000);
    } else if (createContactStatus?.response_status == "fail") {
      setToastData({
        message: createContactStatus?.response_message,
        status: true,
        color: "red",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "", icon: "" });
        setUpdateImportantContact();
      }, 5000);
    }
  }, [createContactStatus]);

  useEffect(() => {
    if (editContactStatus?.response_status == "success") {
      setLoaderStatus(false);
      setToastData({
        message: editContactStatus?.response_message,
        status: true,
        color: "green",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "", icon: "" });
        setUpdateImportantContact();
        navigate("/information/0");
      }, 5000);
    } else if (editContactStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setToastData({
        message: editContactStatus?.response_message,
        status: true,
        color: "red",
      });
      setTimeout(() => {
        setToastData({ message: "", status: false, color: "", icon: "" });
        setUpdateImportantContact();
      }, 5000);
    }
  }, [editContactStatus]);

  const handleChange = (e) => {
    setTime({ ...time, timeStr: getLastUpdatedTime() });

    let { name, value } = e.target;
    setInputDetails({ ...inputDetails, [name]: value });
    setInputDetailsError({
      ...inputDetailsError,
      [name]: "",
    });
  };

  const handleReset = () => {
    setInputDetails({});
  };

  const validate = () => {
    let error = false,
      errObj = {};
    const mobRegex = /^[0]?[65]\d{9}$/;

    for (const property in inputDetails) {
      if (inputDetails[property] === "") {
        error = true;
        errObj[property] = "Please fill out this field";
      }
    }
    setInputDetailsError(errObj);
    return error;
  };

  const handleSubmit = () => {
    let error = validate();
    if (!error) {
      let array = [];
      for (let i = 1; i <= showInputForm.length; i++) {
        let obj = {};
        obj.title = inputDetails[`Title${i}`];
        obj.number = inputDetails[`contactNumber${i}`];
        obj.desc = inputDetails[`description${i}`]
          ? inputDetails[`description${i}`]
          : "";
        array.push(obj);
      }

      if (isEdit == "false" && isGroup == "false") {
        let data = {
          contact_id: contactId,
          new_contacts: JSON.stringify(array),
        };
        addGroupSubcontact(data);
        setLoaderStatus(true);
      } else {
        let data = {
          group_title: inputDetails.groupTitle,
          contacts: JSON.stringify(array),
        };
        createContact(data);
        setLoaderStatus(true);
      }

      //  setTimeout(()=>{
      //   setLoaderStatus(false)
      //  },15000)
    }
  };

  const handleGroupSubmit = () => {
    let valid = validate();
    if (!valid) {
      let array = [];
      let contactArray = [];
      let filteredArray = showInputForm.filter((value) => {
        return (
          value.id >
          oneGroupContactList.sub_contacts[
            oneGroupContactList.sub_contacts.length - 1
          ].sub_contact_id
        );
      });
      if (filteredArray.length > 0) {
        for (
          let i = filteredArray[0].id;
          i <= filteredArray[filteredArray.length - 1].id;
          i++
        ) {
          let obj = {};
          obj.title = inputDetails[`Title${i}`];
          obj.number = inputDetails[`contactNumber${i}`];
          obj.desc = inputDetails[`description${i}`]
            ? inputDetails[`description${i}`]
            : "";
          array.push(obj);
        }
      }
      for (
        let j = oneGroupContactList.sub_contacts[0].sub_contact_id;
        j <=
        oneGroupContactList.sub_contacts[
          oneGroupContactList.sub_contacts.length - 1
        ].sub_contact_id;
        j++
      ) {
        let obj = {};
        obj.sub_contact_id = j;
        obj.title = inputDetails[`Title${j}`];
        obj.number = inputDetails[`contactNumber${j}`];
        obj.desc = inputDetails[`description${j}`]
          ? inputDetails[`description${j}`]
          : "";
        contactArray.push(obj);
      }
      let data = {
        contact_id: oneGroupContactList.contact_id,
        group_title: oneGroupContactList.group_title,
        contacts: JSON.stringify(contactArray),
        new_contacts: filteredArray.length > 0 ? JSON.stringify(array) : [],
      };
      editContact(data);
      setLoaderStatus(true);

      // setTimeout(()=>{
      //   setLoaderStatus(false)
      //  },15000)
    }
  };

  const handelInputFormValues = () => {
    let inputObj = inputDetails,
      errObj = inputDetailsError;
    inputObj[`Title${showInputForm[showInputForm.length - 1].id + 1}`] = "";
    inputObj[`contactNumber${showInputForm[showInputForm.length - 1].id + 1}`] =
      "";
    inputObj[`description${showInputForm[showInputForm.length - 1].id + 1}`] =
      "";
    errObj[`Title${showInputForm[showInputForm.length - 1].id + 1}`] = "";
    errObj[`contactNumber${showInputForm[showInputForm.length - 1].id + 1}`] =
      "";
    errObj[`description${showInputForm[showInputForm.length - 1].id + 1}`] = "";
    setInputDetails({ ...inputObj });
    setInputDetailsError({ ...errObj });
    let prevForms = showInputForm;
    let newFormsCount = {
      id: showInputForm[showInputForm.length - 1].id + 1,
      formName: `Contact ${showInputForm[showInputForm.length - 1].id + 1}`,
    };
    prevForms.push(newFormsCount);
    setShowInputForm([...prevForms]);
  };
  const handleSaveButton = () => {
    if (isEdit == "true") {
      return handleGroupSubmit();
    } else {
      return handleSubmit();
    }
  };

  const handleSingleContactDelete = (id) => {
    let arr = showInputForm.filter((value) => {
      return value.id != id;
    });
    setShowInputForm([...arr]);

    let input = inputDetails;
    delete input[`Title${id}`];
    delete input[`contactNumber${id}`];
    delete input[`description${id}`];
    setInputDetails(input);

    let error = inputDetailsError;
    delete error[`Title${id}`];
    delete error[`contactNumber${id}`];
    delete error[`description${id}`];
    setInputDetailsError(error);
  };

  const renderInputForm = (formToShow) => {
    return formToShow.map((formsData, index) => (
      <Box sx={{ margin: "30px 0" }} key={index}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // flexDirection: 'column',
            fontWeight: 500,
            rowGap: "5px",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontSize: "14px", fontWeight: 600, color: "#0F2C6F" }}
          >
            Contact {index + 1}
          </Typography>
          {formToShow?.length > 1 && (
            <>
              {index == formToShow.length - 1 && index >= existingData && (
                <DeleteIcon
                  onClick={() => handleSingleContactDelete(formsData.id)}
                />
              )}
            </>
          )}
        </Box>
        {/* Title & Number */}
        <Grid
          container
          sx={{
            marginTop: "30px",
            rowGap: "15px",
          }}
        >
          <Grid item lg={6} xs={12}>
            <Box
              sx={{
                mr: { md: "10px", xs: "0" },
                rowGap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputLabel
                sx={{
                  fontSize: { md: "14px", xs: "12px" },
                  fontWeight: 500,
                  color: "#0F2C6F",
                }}
              >
                Title *
              </InputLabel>
              <TextField
                type="text"
                fullWidth
                autoComplete="off"
                inputProps={{
                  sx: {
                    height: { sm: "20px", xs: "10px" },
                    fontSize: { sm: "14px", xs: "12px" },
                    width: "100%",
                    border: "1px solid #9AA6C3",
                    borderRadius: "6px",
                  },
                  maxLength: 100,
                }}
                name={`Title${formsData.id}`}
                onChange={(e) => handleChange(e)}
                value={inputDetails[`Title${formsData.id}`]}
              />
            </Box>
            <Typography sx={{ color: "#f44336" }}>
              {inputDetailsError[`Title${formsData.id}`]}
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Box
              sx={{
                mr: { md: "10px", xs: "0" },
                rowGap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputLabel
                sx={{
                  fontSize: { md: "14px", xs: "12px" },
                  fontWeight: 500,
                  color: "#0F2C6F",
                }}
              >
                Number *
              </InputLabel>
              <OutlinedInput
                type="number"
                className={customStyle.input}
                fullWidth
                autoComplete="off"
                inputProps={{
                  sx: {
                    height: { sm: "20px", xs: "10px" },
                    fontSize: { sm: "14px", xs: "12px" },
                    width: "100%",
                    border: "1px solid #9AA6C3",
                    borderRadius: "6px",
                  },
                  maxLength: 50,
                }}
                name={`contactNumber${formsData.id}`}
                onChange={(e) => handleChange(e)}
                value={inputDetails[`contactNumber${formsData.id}`]}
                onKeyDown={(e) => {
                  if (e.key === 'e' || e.key === 'E') {
                    e.preventDefault(); // Prevents the letter "E" from being entered
                  }
                }}
              />
            </Box>
            <Typography sx={{ color: "#f44336" }}>
              {inputDetailsError[`contactNumber${formsData.id}`]}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            marginTop: "30px",
            rowGap: "15px",
          }}
        >
          <Grid item lg={12} xs={12}>
            <Box
              sx={{
                mr: { md: "10px", xs: "0" },
                rowGap: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <InputLabel
                sx={{
                  fontSize: { md: "14px", xs: "12px" },
                  fontWeight: 500,
                  color: "#0F2C6F",
                }}
              >
                Description *
              </InputLabel>
              <TextField
                type="text"
                fullWidth
                multiline
                autoComplete="off"
                inputProps={{
                  sx: {
                    alignitems: "flex-start",
                    fontSize: { sm: "14px", xs: "12px" },
                    height: "89px",
                  },
                  maxLength: 80,
                }}
                name={`description${formsData.id}`}
                onChange={(e) => handleChange(e)}
                value={inputDetails[`description${formsData.id}`]}
                // helperText={`${inputDetails.description.length}/${80}`}
              />
              <Typography>
                {inputDetails[`description${formsData.id}`]?.length}/80
              </Typography>
              <Typography sx={{ color: "#f44336" }}>
                {inputDetailsError[`description${formsData.id}`]}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    ));
  };
  return (
    <>
      {toastData.status && (
        <ToastAlert
          message={toastData.message}
          status={toastData.status}
          color={toastData.color}
        />
      )}
      {loaderStatus && <Loader status={loaderStatus} />}
      <Grid
        container
        sx={{ paddingTop: { md: "60px", xs: "20px" }, background: "#F9FAFA" }}
      >
        <Grid item lg={2} md={1} sm={1} xs={0.5} />
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", color: "#0F2C6F" }}
          lg={8}
          md={10}
          sm={10}
          xs={11}
        >
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
            cursor="pointer"
          />

          <Typography
            sx={{
              color: "#002D74",
              margin: "30px 0",
              fontSize: "26px",
              fontWeight: 500,
            }}
          >
            Important Contacts
          </Typography>
          <Box
            sx={{
              width: "100%",
              background: "#fff",
              borderRadius: "8px",
              color: "#0F2C6F",
              "& .css-19kzrtu": {
                padding: "0",
              },
            }}
          >
            <Box sx={{ padding: { md: "25px", xs: "15px" } }}>
              {/* Group title */}
              <Grid container sx={{ marginTop: "30px", rowGap: "20px" }}>
                <Grid item lg={6} xs={12}>
                  <Box
                    sx={{
                      mr: { md: "10px", xs: "0" },
                      rowGap: "8px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontSize: { md: "14px", xs: "12px" },
                        fontWeight: 500,
                        color: "#0F2C6F",
                      }}
                    >
                      Group Title
                    </InputLabel>
                    <TextField
                      type="text"
                      disabled={isGroup === "true" ? false : true}
                      autoComplete="off"
                      inputProps={{
                        sx: {
                          fontSize: { sm: "14px", xs: "12px" },
                        },
                        maxLength: 100,
                      }}
                      name="groupTitle"
                      onChange={(e) => handleChange(e)}
                      value={inputDetails.groupTitle}
                    />
                  </Box>
                  <Typography sx={{ color: "#f44336" }}>
                    {inputDetailsError.groupTitle}
                  </Typography>
                </Grid>
              </Grid>
              {renderInputForm(showInputForm)}

              {/* Add */}
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={() => handelInputFormValues()}
                  variant="outlined"
                  sx={{
                    background: "#FFFFFF",
                    border: "1px solid #AAC3FF",
                    borderradius: "6px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "8px 10px",
                    fontSize: { md: "14px", xs: "10px" },
                    fontWeight: 600,
                    textTransform: "capitalize",
                    color: "#0F2C6F",
                  }}
                  endIcon={<AddIcon />}
                >
                  Add Contact
                </Button>
              </Box>

              {/* save & Delete */}
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", py: 5 }}>
            <Box sx={{ display: "flex" }}>
              <RefreshIcon />
              <Typography sx={{ color: " #9AA6C3", fontSize: "14px", ml: 1 }}>
                Last updated at {time.timeStr}
              </Typography>
            </Box>

            {/* Discard & Save */}
            <Box
              sx={{
                display: "flex",
                gap: "4px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color: "#0F2C6F",
                  fontSize: { md: "14px", xs: "12px" },
                  padding: { md: "10px", xs: "5px" },
                  fontWeight: 600,
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>

              <Button
                sx={{
                  background: "#123FA9",
                  fontSize: { md: "14px", xs: "12px" },
                  padding: { md: "10px", xs: "5px" },
                  textTransform: "capitalize",
                  ml: 1,
                  color: "#FFFFFF",
                  "&:hover": {
                    background: "#123FA9",
                  },
                }}
                onClick={() => handleSaveButton()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    createContactStatus: state.ImportantContactReducer.createContactStatus,
    oneGroupContactList: state.ImportantContactReducer.oneGroupContactList,
    editContactStatus: state.ImportantContactReducer.editContactStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createContact: (data) =>
      dispatch(ImportantContactAction.createContact(data)),
    setUpdateImportantContact: () =>
      dispatch(ImportantContactAction.setUpdateImportantContact()),
    getOneGroupContact: (data) =>
      dispatch(ImportantContactAction.getOneGroupContact(data)),
    editContact: (data) => dispatch(ImportantContactAction.editContact(data)),
    addGroupSubcontact: (data) =>
      dispatch(ImportantContactAction.addGroupSubcontact(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
