import { handleActions } from "redux-actions";

const AnnouncementReducer = {
  announcementList: [],
  announcementCounts:0,
  announceAddStatus:null,
  announceUpdateStatus:null,
  announcementTypes:[],
  editAnnouncementStatus:null,
  announcementDetails:[],
  deleteAnnouncementStatus:null,
  updateAnnouncementStatus:[],
  updateAnnouncementPinStatus: null,

};
const reducer = handleActions(
    {
      UPDATE_ANNOUNCEMENT_STATE: (state, action) => {
        return {
          ...state,
          announceAddStatus:null,
          announceUpdateStatus:null,
          updateAnnouncementStatus: null,
          updateAnnouncementPinStatus: null,
          editAnnouncementStatus: null
        }
      },
      GET_ANNOUNCEMENTS_INITIATE: (state, action) => {
        return {
          ...state,
       
        }
      },
      GET_ANNOUNCEMENTS_SUCCESS: (state, action) => {
        return {
          ...state,
          announcementList:action.payload.response_json,
          announcementCounts:action.payload.totalRecords
        }
      },
      GET_ANNOUNCEMENTS_FAILURE: (state, action) => {
        return {
          ...state,
          announcementList:[],
          announcementCounts:0
        }
      },
      GET_ANNOUNCEMENT_TYPES_INITIATE: (state, action) => {
        return {
          ...state,
         
        }
      },
      GET_ANNOUNCEMENTS_TYPES_SUCCESS: (state, action) => {
        return {
          ...state,
          announcementTypes:action.payload
        }
      },
      GET_ANNOUNCEMENTS_TYPES_FAILURE: (state, action) => {
        return {
          ...state,
          announcementTypes:[]
        }
      },
      ADD_ANNOUNCEMENT_INITIATE: (state, action) => {
        return {
          ...state,
         
        }
      },
      ADD_ANNOUNCEMENT_SUCCESS: (state, action) => {
        return {
          ...state,
          announceAddStatus:action.payload,
        }
      },
      ADD_ANNOUNCEMENT_FAILURE: (state, action) => {
        return {
          ...state,
          announceAddStatus:action.payload
        }
      },
      EDIT_ANNOUNCEMENT_INITIATE:(state)=>{
        console.log("state.payload ",state)
        return{
          ...state
        }
      },
      EDIT_ANNOUNCEMENT_SUCCESS:(state,action)=>{
        console.log("action.payload ",action.payload)
        return{
          ...state,
          editAnnouncementStatus:action.payload
        }
      },
      EDIT_ANNOUNCEMENT_FAILURE:(state,action)=>{
        return{
          ...state,
          editAnnouncementStatus:null
        }
      },
      GET_ANNOUNCEMENTDETAILS_BYID_INITIATE:(state)=>{
        return{
          ...state
        }
      },
      GET_ANNOUNCEMENTDETAILS_BYID_SUCCESS:(state,action)=>{
        return{
          ...state,
          announcementDetails:action.payload
        }
      },
      GET_ANNOUNCEMENTDETAILS_BYID_FAILURE:(state,action)=>{
        return{
          ...state,
          announcementDetails:[]
        }
      },
      DELETE_ANNOUNCEMENT_INITIATE:(state)=>{
        return{
          ...state
        }
      },
      DELETE_ANNOUNCEMENT_SUCCESS:(state,action)=>{
        return{
          ...state,
          deleteAnnouncementStatus:action.payload
        }
      },
      DELETE_ANNOUNCEMENT_FAILURE:(state,action)=>{
        return{
          ...state,
          deleteAnnouncementStatus:[]
        }
      },
      UPDATE_ANNOUNCEMENT_INITIATE:(state)=>{
        return{
          ...state
        }
      },
      UPDATE_ANNOUNCEMENT_SUCCESS:(state,action)=>{
        return{
          ...state,
          updateAnnouncementStatus:action.payload
        }
      },
      UPDATE_ANNOUNCEMENT_FAILURE:(state,action)=>{
        return{
          ...state,
          updateAnnouncementStatus:[]
        }
      },
      UPDATE_ANNOUNCEMENT_PIN_STATUS_INITIATE:(state) => {
        return {
        ...state
      }
      },
      UPDATE_ANNOUNCEMENT_PIN_STATUS_SUCCESSS:(state, action) => {
        return {
          ...state, 
          updateAnnouncementPinStatus: action.payload,
        }
      },
      UPDATE_ANNOUNCEMENT_PIN_STATUS_FAILURE:(state, action) => {
        return {
          ...state,
          updateAnnouncementPinStatus: null,
        }
      }
      
    },
    AnnouncementReducer
    );

export default reducer;