import { Autocomplete, Box, Button, Checkbox, Divider, FormControlLabel, Grid, InputLabel, MenuItem, OutlinedInput, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useState } from "react";
import { ReactComponent as BackIcon } from '../../Assets/icons/back.svg';
import { ReactComponent as RoundedPlusIcon } from '../../Assets/icons/RoundedPlusIcon.svg';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxTerms.svg';
import { ReactComponent as RemoveCircleOutlineRoundedIcon } from '../../Assets/icons/MinusIcon.svg';
import { ReactComponent as RefreshIcon } from "../../Assets/icons/Refresh.svg";
import { useNavigate } from "react-router-dom";
import { getDateTimeFormat, getLastUpdatedTime, getLoggedUserData, getUTCDateTimeFormat, getYYYYMMDDFormat, preventDatePickerKeyboard } from "../../Utils/utils";
import { connect } from 'react-redux'
import * as visitorsAction from '../../redux/actions/visitorsAction';
import * as commonAction from '../../redux/actions/commonAction';
import moment from 'moment'
import Loader from "../common/Loader";
import { useEffect } from "react";
import ToastAlert from "../common/Alert";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

const tabTypography = {
    color: "#FFFFFF",
    backgroundColor: "#123FA9",
    fontSize: '14px',
    fontWeight: 600,
    borderRadius: "50%",
    px: 1,
    py: 0.2
};
const tabTypographyText = {
    color: "#123FA9",
    fontSize: "14px",
    fontWeight: 500,
    textTransform: 'capitalize',
    ml: 1
}
const TabPanel = (props) => {
    let { value, index, children } = props
    if (value == index) {
        return (
            <>
                {children}
            </>
        )
    }
}

const StyledTypography = styled(Typography)(({
    color: '#0F2C6F',
    fontSize: '14px',
    marginBottom: '5px'
}))

const StyledPrimaryTypography = styled(Typography)({
    color: "#0F2C6F",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: '5px'
})
const StyledErrorTypography = styled(Typography)({
    color: "#f44336",
    fontSize: "14px",

});

const StyledTableRow = styled(TableRow)({
    cursor: "pointer",
    '&:nth-of-type(odd)': {
        backgroundColor: "#F8F9FB"
    }
});


const BatchInvitation = ({ inviteVisitorOnTenant, addStatus, receptionStateToInitial,getAllUsers,visitorMail, getAllLevels, levelDatas, unitDatas, getUnitsByLevelid }) => {
    const navigate = useNavigate()
    let { company_details, company_id,emp_level_name,emp_unit_name } = getLoggedUserData();
    const [tabValue, setTabValue] = useState(0)
    const [visitorDetails, setVisitorDetails] = useState([{ id: 1, first_name: '', last_name: '', mail: '',disabled:false }])
    const [visitorErrors, setVisitorErrors] = useState([{ id: 1, first_name: '', last_name: '', mail: '' }])
    const [inviteDetails, setInviteDetails] = useState({
        meeting_title: "",
        meeting_description: "",
        access_start_date: '',
        access_start_time: '',
        access_end_date: '',
        access_end_time: '',
        level_no: emp_level_name,
        unit_no: emp_unit_name,
        first_reached: false,
        office_level_id: '',
        office_unit_id: ''
    })

    const [errorDetails, setErrorDetails] = useState({
        noErrors: false,
        meeting_title: "",
        // meeting_description: "",
        access_start_date: '',
        access_start_time: '',
        access_end_date: '',
        access_end_time: '',
        office_level_id: '',
        office_unit_id: ''
    })

    const [loaderStatus, setLoaderStatus] = useState(false)
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [options,setOptions] = useState([])
    const [existingMail,setExistingMailList] = useState([])
    const [hours,setHours] = useState([])
    
    useEffect(()=>{
        getAllUsers();
        getAllLevels();
    },[])

    useEffect(()=>{
        let today = new Date()
        let hours = today.getHours()
        // let minutes = today.getMinutes()
        if(inviteDetails.access_start_date > today){
            getStartHours(0)
        }else{
            getStartHours(hours)
        }
    },[inviteDetails.access_start_date])

    useEffect(()=>{
        setExistingMailList(visitorMail)
        let arr = []
        for(let i=0;i<visitorMail?.length;i++){
            arr.push({label:visitorMail[i].email})
        }
        setOptions([...arr])
    },[visitorMail])

    useEffect(() => {

        if (addStatus !== null) {
            if (addStatus.response_status === 1) {
                setLoaderStatus(false)
                setSnackbarStatus({ message: addStatus.message, status: true, color: 'green', icon: 'close' })
                setTimeout(() => {
                    navigate('/tenant_visitors/0')
                    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
                    receptionStateToInitial();
                }, 5000);
            } else {
                setLoaderStatus(false)
                setSnackbarStatus({ message: addStatus.message, status: true, color: 'red', icon: 'close' })
                setTimeout(() => {
                    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
                    receptionStateToInitial();
                }, 5000);
            }
        }
    }, [addStatus])

    const getStartHours = (minTime)=>{
       let minutesArray = ['00','15','30','45']
        let timeIntrevels=[]
        for(let i=minTime;i<24;i++){
            minutesArray.map((value,index)=>{
                timeIntrevels.push(i<=9?`0${i}:${minutesArray[index]}`:`${i}:${minutesArray[index]}`) 
            })
       };
       setHours([...timeIntrevels])
   }


    const handleTabsChange = (value) => {
        setTabValue(value)
    }

    const addVisitorDetails = () => {
        let arr = visitorDetails, errorArray = visitorErrors;
        let length = visitorDetails?.length + 1;
        arr.push({ id: length, [`first_name`]: "", [`last_name`]: "", [`mail`]: "" })
        errorArray.push({ id: length, [`first_name`]: "", [`last_name`]: "", [`mail`]: "" })
        setVisitorDetails([...arr])
        setVisitorErrors([...errorArray])
    }
    const removeVisitors = (removeIndex) => {
        let newVisitorList = visitorDetails.filter((property, index) => {
            return property.id !== removeIndex
        })
        setVisitorDetails([...newVisitorList])
        let newErrorsList = errorDetails.filter((property, index) => {
            return property.id !== removeIndex
        })
        setVisitorErrors([...newErrorsList])
    }
    const handleVisitorsData = (e, reqIndex,value) => {
        if(e=='email'){
            let newVisitorList = [];
            let filter = existingMail.filter((item)=>{
                return value==item.email
            })
            if(filter?.length>0){
                visitorDetails.forEach((element, index) => {
                    if (index === reqIndex) {
                        let newobj = { ...element, mail: value,first_name:filter[0].first_name,last_name:filter[0].last_name,disabled:true }
                        newVisitorList.push(newobj)
                    } else {
                        newVisitorList.push(element)
                    }
                });
                setVisitorDetails([...newVisitorList])
            }else{
                visitorDetails.forEach((element, index) => {
                    if (index === reqIndex) {
                        let newobj = { ...element, mail: value,disabled:false }
                        newVisitorList.push(newobj)
                    } else {
                        newVisitorList.push(element)
                    }
                });
                setVisitorDetails([...newVisitorList])
            }
            
        }else{
            let { name, value } = e.target;
            let newVisitorList = [];
            visitorDetails.forEach((element, index) => {
                if (index === reqIndex) {
                    let newobj = { ...element, [name]: value }
                    newVisitorList.push(newobj)
                } else {
                    newVisitorList.push(element)
                }

            });
            setVisitorDetails([...newVisitorList])
        }
    }

    const handleInviteDetails = (e) => {
        let { name, value } = e.target;
        setInviteDetails({ ...inviteDetails, [name]: value })
        setErrorDetails({
            ...errorDetails,
            [name]: ""
        })
    }
    const handleDelete = () => {
        setVisitorDetails([{ id: 1, first_name: "", last_name: "", mail: "" }]);
        setInviteDetails({
            meeting_title: "",
            meeting_description: "",
            access_start_date: '',
            access_start_time: '',
            level_no: company_details?.companyArrayList[0].level_name,
            unit_no: company_details?.companyArrayList[0].unit_name
        })
        setErrorDetails({
            noErrors: false
        })
    }
    const handleContinue = () => {
        let noErrors = validate();

        if (noErrors) {
            setTabValue(1);
        }
    }
    const handleBack = () => {
        setTabValue(0);
    }
    const handleSubmit = () => {

        let formatedVisitors = [];
        visitorDetails.forEach((element) => {
            let visitorsObj = {};
            visitorsObj['first_name'] = element.first_name;
            visitorsObj['last_name'] = element.last_name;
            visitorsObj['email'] = element.mail;
            visitorsObj['user_id'] = '';
            visitorsObj['name'] = `${element.first_name} ${element.last_name}`;
            formatedVisitors.push(visitorsObj);
        })
        let date = new Date(inviteDetails.access_start_date)
        let endDate = new Date(inviteDetails.access_end_date)
        let time = inviteDetails.access_start_time?.split(':')
        let endTime = inviteDetails.access_end_time?.split(':')
        date.setHours(time[0])
        date.setMinutes(time[1])
        endDate.setHours(endTime[0])
        endDate.setMinutes(endTime[1])
        let submissionData = {
            invitations_title: inviteDetails.meeting_title,
            from_date_time: getUTCDateTimeFormat(date),
            to_date_time: getUTCDateTimeFormat(endDate),
            company_id: company_id,
            notes_to_visitor: inviteDetails.meeting_description,
            userArrayList: JSON.stringify(formatedVisitors),
            level_id: Number(inviteDetails.office_level_id),
            unit_id: Number(inviteDetails.office_unit_id),
        };
        inviteVisitorOnTenant(submissionData)
        setLoaderStatus(true)
        setTimeout(() => {
            setLoaderStatus(false)
        }, 30000)
    }

    const validate = () => {
        let errorsObj = {}, errorsStatus = [], visitorsErrors = [];
        let errorMessages = {
            meeting_title: 'Title should be at least 4 characters ',
            // meeting_description: 'Should not be empty!',
            access_start_date: 'Please select a date!',
            access_start_time: 'Please select a time!',
            access_end_date: 'Please select a date!',
            access_end_time: 'Please select a time!',
            first_name: 'First name required!',
            last_name: 'Last name required!',
            mail: 'Email required!',
            invalidEmail: 'Invalid Email!',
            uniqueEmail:"Please enter unique email",
            office_level_id: "Please select office level id",
            office_unit_id: "Please select office unit id",
        };
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let datas in inviteDetails) {
            
            if (datas == "meeting_title") {
                if (inviteDetails[datas]?.length < 4) {
                    errorsStatus.push(false);
                    errorsObj[datas] = errorMessages[datas]
                }
            } else if(datas=="access_end_time"){
                let date = new Date(inviteDetails.access_start_date)
                let endDate = new Date(inviteDetails.access_end_date)
                let time = inviteDetails.access_start_time?.split(':')
                let endTime = inviteDetails.access_end_time?.split(':')
                date.setHours(time[0])
                date.setMinutes(time[1])
                endDate.setHours(endTime[0])
                endDate.setMinutes(endTime[1])

                if (inviteDetails[datas] === '') {
                    errorsStatus.push(false);
                    errorsObj[datas] = errorMessages[datas]
                } else if(endDate<=date){
                    errorsStatus.push(false);
                    errorsObj[datas] = 'Select a valid time'
                }else {
                    errorsStatus.push(true);
                    errorsObj[datas] = ''
                }
            } else if(datas !="meeting_description") {
                if (inviteDetails[datas] === '') {
                    errorsStatus.push(false);
                    errorsObj[datas] = errorMessages[datas]
                } else {
                    errorsStatus.push(true);
                    errorsObj[datas] = ''
                }
            }

        }

        for (let visitors = 0; visitors < visitorDetails?.length; visitors++) {
            let visitorsErrorsObj = {}

            for (let visitordatas in visitorDetails[visitors]) {
                if (visitordatas === 'mail') {
                    if (visitorDetails[visitors][visitordatas] === '') {
                        errorsStatus.push(false);
                        visitorsErrorsObj[visitordatas] = errorMessages[visitordatas]
                    } else if (!visitorDetails[visitors][visitordatas].match(emailRegex)) {
                        errorsStatus.push(false);
                        visitorsErrorsObj[visitordatas] = errorMessages['invalidEmail']
                    } else {
                        errorsStatus.push(true);
                        visitorsErrorsObj[visitordatas] = ''
                    }
                } else {
                    if (visitorDetails[visitors][visitordatas] === '') {
                        errorsStatus.push(false);
                        visitorsErrorsObj[visitordatas] = errorMessages[visitordatas]
                    } else {
                        errorsStatus.push(true);
                        visitorsErrorsObj[visitordatas] = ''
                    }
                }
            }
            visitorsErrors.push(visitorsErrorsObj)
        }

        let mailArray = []
        for(let i=0;i<visitorDetails?.length;i++){
            mailArray.push(visitorDetails[i].mail)
        }
        let set = new Set(mailArray)
        if(set.size === visitorDetails?.length){
            errorsStatus.push(true)
        }else{
            errorsStatus.push(false)
            errorsObj["uniqueEmail"] = errorMessages.uniqueEmail
        }

 
        setErrorDetails(errorsObj);
        setVisitorErrors([...visitorsErrors])
        let isErrors = errorsStatus.includes(false);
        return !isErrors;
       
    }

    const handleLevelChange = (levelId) => {
        setInviteDetails(prevValue => {
            return {...prevValue, office_level_id: levelId };
        })
        setErrorDetails(prevValue => {
            return {...prevValue, office_level_id: ""}
          })
          
          getUnitsByLevelid({level_id: levelId});
        }        
 
    const getUnitNameById = (unit_id) => {
        return unitDatas?.filter(item => item.unit_id === unit_id)[0]?.unit_name;
    }

    const newInviteDetails = () => {
        return (<Grid container rowSpacing={3} sx={{ p: 3 }}>
            <Grid item xs={12}>
                <Typography sx={{ color: "#0F2C6F", fontSize: "18px", fontFamily: 'Inter', fontWeight: 500, }}>Batch Visitor Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <StyledTypography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>Meeting/ Event Title</StyledTypography>
                <OutlinedInput
                    sx={{ color: '#0F2C6F' }}
                    fullWidth
                    inputProps={{ maxLength: 50 }}
                    name='meeting_title'
                    onChange={(e) => handleInviteDetails(e)}
                    value={inviteDetails.meeting_title} />
                <Typography sx={{
                    color: "#0F2C6F",
                    fontSize: "14px",
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    mt: 1
                }}>{inviteDetails.meeting_title?.length}/50</Typography>
                <StyledErrorTypography>
                    {errorDetails.meeting_title}
                </StyledErrorTypography>
            </Grid>
            <Grid item xs={12}>
                <StyledTypography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>Description (Additional)</StyledTypography>
                <OutlinedInput
                    sx={{ color: '#0F2C6F' }}
                    inputProps={{ maxLength: 300 }}
                    fullWidth
                    multiline
                    name='meeting_description'
                    onChange={(e) => handleInviteDetails(e)}
                    minRows={3}
                    value={inviteDetails.meeting_description} />
                <Typography sx={{
                    color: "#0F2C6F",
                    fontSize: "14px",
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    mt: 1
                }}>{inviteDetails.meeting_description?.length}/300</Typography>
                {/* <StyledErrorTypography>
                    {errorDetails.meeting_description}
                </StyledErrorTypography> */}
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Box sx={{ display: "flex" }} >
                    <Box>
                        <StyledPrimaryTypography>Invitation Start Date*</StyledPrimaryTypography>
                        <DatePicker
                            disablePast
                            maxDate={new Date(new Date().setMonth(new Date().getMonth()+3))}
                            inputFormat='dd/MM/yyyy'
                            components={{
                                OpenPickerIcon: DatePickerIcon
                            }}
                            value={inviteDetails.access_start_date || null }
                            onChange={(newValue) => {
                                setInviteDetails({ ...inviteDetails, ['access_start_date']: newValue })
                                setErrorDetails({ ...errorDetails, access_start_date: "" })
                            }}
                            renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                        <StyledErrorTypography>
                            {errorDetails.access_start_date}
                        </StyledErrorTypography>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <StyledPrimaryTypography>Start Time</StyledPrimaryTypography>
                        <Select
                            MenuProps={MenuProps}
                            sx={{ width: 150 }} 
                            value={inviteDetails.access_start_time} 
                            onChange={(e) => {
                                setInviteDetails({ ...inviteDetails, ['access_start_time']: e.target.value })
                                setErrorDetails({ ...errorDetails, access_start_time: "" })
                            }}
                        >
                            {hours?.map((value)=>{
                               return <MenuItem value={value}>{value}</MenuItem>
                            })}
                        </Select>
                        {/* <TextField
                            onChange={(e) => {
                                setInviteDetails({ ...inviteDetails, ['access_start_time']: e.target.value })
                                setErrorDetails({ ...errorDetails, access_start_time: "" })
                            }}
                            value={inviteDetails.access_start_time}
                            id="time"
                            label=""
                            type="time"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            sx={{ width: 150 }}
                        /> */}
                        <StyledErrorTypography>
                            {errorDetails.access_start_time}
                        </StyledErrorTypography>
                    </Box>
                </Box>
                <Box sx={{display:'flex',mt:4}}>
                    <Box>
                        <StyledPrimaryTypography>Invitation End Date*</StyledPrimaryTypography>
                        <DatePicker
                            disablePast
                            inputFormat='dd/MM/yyyy'
                            components={{
                                OpenPickerIcon: DatePickerIcon
                            }}
                            minDate={inviteDetails?.access_start_date || new Date()}
                            maxDate={new Date(inviteDetails?.access_start_date).setMonth(new Date(inviteDetails?.access_start_date).getMonth()+1) || new Date().setDate(new Date().getDate()+30)} 
                            value={inviteDetails.access_end_date || null }
                            onChange={(newValue) => {
                                setInviteDetails({ ...inviteDetails, ['access_end_date']: newValue })
                                setErrorDetails({ ...errorDetails, access_end_date: "" })
                            }}
                            renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                        <StyledErrorTypography>
                            {errorDetails.access_end_date}
                        </StyledErrorTypography>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <StyledPrimaryTypography>End Time</StyledPrimaryTypography>
                        <Select
                            MenuProps={MenuProps}
                            sx={{ width: 150 }} 
                            value={inviteDetails.access_end_time} 
                            onChange={(e) => {
                                setInviteDetails({ ...inviteDetails, ['access_end_time']: e.target.value })
                                setErrorDetails({ ...errorDetails, access_end_time: "" })
                            }}
                        >
                            {hours?.map((value,index)=>{
                               return <MenuItem value={value}>{value}</MenuItem>
                            })}
                        </Select>
                        <StyledErrorTypography>
                            {errorDetails.access_end_time}
                        </StyledErrorTypography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <StyledPrimaryTypography>Location (Office premises)</StyledPrimaryTypography>
                <TextField
                    disabled
                    value={inviteDetails.level_no}
                    label=""
                    sx={{ width: 80 }}
                />
                <TextField
                    disabled
                    value={inviteDetails.unit_no}
                    label=""
                    sx={{ ml: 2, width: 100 }}
                />
            </Grid>

            <Grid
                    item
                    sm={10}
                    xs={12}
                    sx={{
                      paddingRight: { md: "100px", xs: "5px" },
                      padding: "10px 0",
                      width: "100%",
                     }}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#0F2C6F",
                        paddingBottom: "8px",
                      }}
                    >
                      Office Unit Number *
                    </InputLabel>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "15% 30% auto",
                        placeItems:"center",
                        gap: "10px",
                        
                       }}
                    >
                      
                        <Select
                          name="accessLevelNumber"
                           value={inviteDetails.office_level_id}
                          sx={{
                             width: '80px',
                            color: '#0F2C6F',
                            fontSize: "14px",
                            color:'#0F2C6F',
                            width: "100%",

                          }}
                          onChange={e => handleLevelChange(e.target.value)}
                        >

                          {levelDatas?.map((item, index) => (
                            <MenuItem value={item?.level_id} key={index}>{item?.level_name}</MenuItem>
                          ))}
                        </Select>
                       
 
                      {/* unitData[0] === 'No data found' */}
                      {unitDatas?.length === 0 ? <OutlinedInput sx={{ ml: 10, width: '100%' }} value='No units found' disabled /> :
                         
                          <Select
                            name="unitNumber"
                            onChange={(e) => {                                
                              setInviteDetails(prevValue => {
                                return {...prevValue, office_unit_id: e.target.value}
                              })
                              setErrorDetails(prevValue => {
                                return {...prevValue, office_unit_id: ""}
                              })
                            }}
                            sx={{
                                 fontSize: "14px",
                              color:'#0F2C6F',
                              width: "100%",

                            }}
                            value={inviteDetails.office_unit_id}
                          >
                            {unitDatas?.map((item, index) => (
                              <MenuItem value={item.unit_id} key={index}>{item.unit_name}</MenuItem>
                            ))
                            }
                          </Select>                                        
                      }
                    </Box>
                    {errorDetails.office_level_id && (
                          <Typography sx={{ color: '#f44336', pt: "6px" }}>{errorDetails.office_level_id}</Typography>
                        )}
                    {errorDetails.office_unit_id && (
                          <Typography sx={{ color: '#f44336',  pt: "5px" }}>{errorDetails.office_unit_id}</Typography>
                        )}
                  </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography sx={{ color: "#0F2C6F", fontSize: "18px" }}>Visitors Details</Typography>
            </Grid>
            <Grid item container columnSpacing={2} xs={12}>
                {visitorDetails.map((value, index) => (
                    <Grid container columnSpacing={2} rowSpacing={2} sx={{ p: 2, pt: 1 }} key={index} >
                        <Grid item xs={4}>
                            <StyledPrimaryTypography>E-mail</StyledPrimaryTypography>
                            {/* <OutlinedInput
                                sx={{ color: '#0F2C6F' }}
                                fullWidth
                                name={`mail`}
                                onChange={(e) => handleVisitorsData(e, index)}
                                value={value.mail} /> */}
                                <Autocomplete freeSolo  onInputChange={(e,value) => handleVisitorsData('email', index,value)} options={options} disableClearable value={value.mail} renderInput={(params)=><TextField  {...params}/>} />
                            <StyledErrorTypography>
                                {visitorErrors[index].mail}
                            </StyledErrorTypography>
                        </Grid>
                        <Grid item xs={3.7}>
                            <StyledPrimaryTypography>First Name</StyledPrimaryTypography>
                            <OutlinedInput
                                disabled={value.disabled}
                                sx={{ color: '#0F2C6F' }}
                                fullWidth
                                inputProps={{maxLength:125}}
                                name={`first_name`}
                                onChange={(e) => handleVisitorsData(e, index)}
                                value={value.first_name} />
                            <StyledErrorTypography>
                                {visitorErrors[index].first_name}
                            </StyledErrorTypography>
                        </Grid>
                        <Grid item xs={3.7}>
                            <StyledPrimaryTypography>Last Name</StyledPrimaryTypography>
                            <OutlinedInput
                                disabled={value.disabled}
                                sx={{ color: '#0F2C6F' }}
                                fullWidth
                                inputProps={{maxLength:125}}
                                name={`last_name`}
                                onChange={(e) => handleVisitorsData(e, index)}
                                value={value.last_name} />
                            <StyledErrorTypography>
                                {visitorErrors[index].last_name}
                            </StyledErrorTypography>
                        </Grid>
                        <Grid item xs={0.6} sx={{ display: "flex", alignItems: "center", justifyContent: 'center' }}>
                            {value.id == 1 ?
                                <Box sx={{ mt: 2.5 }}>
                                    <RoundedPlusIcon onClick={visitorDetails?.length <= 50 ? () => addVisitorDetails() : null} style={{ cursor: 'pointer' }} />
                                </Box> :
                                <Box sx={{ mt: 2.5 }}>
                                    <RemoveCircleOutlineRoundedIcon onClick={() => removeVisitors(value.id)} style={{ cursor: 'pointer' }} />
                                </Box>
                            }
                        </Grid>
                    </Grid>
                ))}
              {errorDetails.uniqueEmail && 
              <Box sx={{display:"flex",justifyContent:"center",width:"100%"}}>
                <Typography sx={{color:"red",fontSize:"14px",textAlign:"center"}}>{errorDetails.uniqueEmail}</Typography>
              </Box>
             }
            </Grid>
          
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontFamily: 'Inter', fontWeight: 500, color: '#6F80A9', fontSize: '14px' }}>
                    {visitorDetails?.length <= 50 ? 'Maximum of only 50 visitors allowed' : 'Maximum visitors reached!'}
                </Typography>
            </Grid>
        </Grid>)
    }
    const previewInviteDetails = () => {
        return (
            <Grid container sx={{ p: 3 }}>
                <Grid item xs={12}>
                    <Typography sx={{ color: "#6F80A9", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>
                        For normal invitations, you will receive a notificaiton and your invited visitors will be able to use the QR access code via email or download the app to access Singapore Land Tower.
                        For VIP invitations, please ensure that all data provided are accurate to prevent any delays in processing. You will be notified within 1-3 working days once your request has been processed.
                    </Typography>

                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "18px", fontFamily: 'Inter', fontWeight: 500, }}>
                        {inviteDetails.meeting_title}
                    </Typography>
                </Grid>
                <Grid item xs={3} sx={{ mt: 2 }}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>
                        Description
                    </Typography>
                </Grid>
                <Grid item xs={9} sx={{ mt: 2 }}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400,wordBreak:"break-all" }}>
                        {inviteDetails.meeting_description}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "18px", fontFamily: 'Inter', fontWeight: 500, }}>
                        Invited by
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ mt: 2 }}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>
                        {getLoggedUserData().user_name}
                    </Typography>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                        {getLoggedUserData().role}
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ mt: 2 }}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                        {company_details.companyArrayList[0]?.country_code}  {company_details.companyArrayList[0]?.phone_no}
                    </Typography>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                        {getLoggedUserData().email}
                    </Typography>
                </Grid>
                <Grid item xs={4} sx={{ mt: 2 }}>
                    {/* <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                        HR Manager
                    </Typography> */}
                    <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                        {`Office Unit: #${inviteDetails?.level_no} - ${inviteDetails.unit_no}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Divider />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Box sx={{ height: 100, border: '1px solid #DBDFE9', borderRadius: '6px', width: 500, display: 'flex', justifyContent: 'start' }}>
                        <Box sx={{ p: 3 }}>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>
                                Access Date
                            </Typography>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                                Start: {moment(inviteDetails?.access_start_date).format("DD MMM yyyy")}  {inviteDetails?.access_start_time}
                            </Typography>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                                End: {moment(inviteDetails?.access_end_date).format("DD MMM yyyy")}  {inviteDetails?.access_end_time}
                            </Typography>
                        </Box>
                        <Box sx={{ p: 3, pl: 10 }}>
                            <Typography sx={{mt:2}}></Typography>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                            Number of Visitor : {visitorDetails?.length}
                            </Typography>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                                {`Location: #${inviteDetails?.office_level_id} - ${getUnitNameById(inviteDetails.office_unit_id)}`}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontFamily: 'Inter', color: '#0F2C6F', fontSize: '12px', fontWeight: 500, }}>
                                        Name
                                    </TableCell>
                                    <TableCell sx={{ fontFamily: 'Inter', color: '#0F2C6F', fontSize: '12px', fontWeight: 500, }}>
                                        Email
                                    </TableCell>
                                    <TableCell sx={{ fontFamily: 'Inter', color: '#0F2C6F', fontSize: '12px', fontWeight: 500, }}>
                                        Access Unit
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visitorDetails.map((item, index) => (
                                    <StyledTableRow key={index}>
                                        <TableCell sx={{ borderBottom: 'none',color: '#0F2C6F' }}>
                                            {item.first_name}   {item.last_name}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none',color: '#0F2C6F' }}>
                                            {item.mail}
                                        </TableCell>
                                        <TableCell sx={{ borderBottom: 'none',color: '#0F2C6F' }}>
                                        #{inviteDetails?.office_level_id} - {getUnitNameById(inviteDetails.office_unit_id)}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                    <FormControlLabel
                        label={<Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 500, fontFamily: 'Inter' }}>Notify the inviter when first guest has arrived.</Typography>}
                        control={<Checkbox checked={inviteDetails.first_reached} icon={<CheckboxIcon />} onChange={() => setInviteDetails({ ...inviteDetails, ['first_reached']: !inviteDetails.first_reached })} name='requiredOnSundays'
                        />} />
                </Grid>

            </Grid>
        )
    }
    return (
        <>
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
            {loaderStatus && <Loader status={loaderStatus} />}
            <Grid container sx={{ padding: 20, pt: 8, background: "rgba(143, 146, 161,0.05)" }}>
                <Grid item xs={12}>
                    <Box >
                        <BackIcon onClick={() => navigate(-1)} />
                        <Typography sx={{ color: " #0F2C6F", fontSize: "26px", mt: 2 }}>Batch Invitation</Typography>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>Follow below steps to batch invite guests (maximum 50 at once).</Typography>
                    </Box>
                    <Box sx={{ backgroundColor: '#FFFFFF', mt: 5, borderRadius: '8px' }}>
                        <Tabs value={tabValue} onChange={(e, value) => handleTabsChange(value)}>
                            <Tab label={
                                <Box sx={{ display: "flex" }}>
                                    <Typography sx={tabTypography}>1</Typography>
                                    <Typography sx={tabTypographyText}>Template</Typography>
                                </Box>}>
                            </Tab>
                            <Tab disabled={true} label={
                                <Box sx={{ display: "flex" }}>
                                    <Typography sx={tabTypography}>2</Typography>
                                    <Typography sx={tabTypographyText}>Review</Typography>
                                </Box>}>
                            </Tab>

                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            {newInviteDetails()}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {previewInviteDetails()}
                        </TabPanel>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", py: 5, }}>
                        <Box><RefreshIcon /><span style={{ color: "#9AA6C3", fontSize: "14px", marginLeft: '5px' }}>Last Updated at {getLastUpdatedTime()}</span></Box>
                        {tabValue === 0 ? <Box>
                            <Button variant='outlined'
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#0F2C6F',
                                    borderRadius: '8px', height: '48px', mr: 2
                                }}
                                onClick={() => handleDelete()}
                            >
                                Delete
                            </Button>
                            <Button variant='contained'
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#FFFFFF',
                                    borderRadius: '8px',
                                    height: '48px',
                                    backgroundColor: '#123FA9',
                                    '&:hover': { backgroundColor: '#123FA9' }
                                }}
                                onClick={() => handleContinue()}
                            >
                                Continue
                            </Button>
                        </Box> : <Box>
                            <Button variant='outlined'
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#0F2C6F',
                                    borderRadius: '8px', height: '48px', mr: 2
                                }}
                                onClick={() => handleBack()}
                            >
                                Back
                            </Button>
                            <Button variant='contained'
                                sx={{
                                    textTransform: 'capitalize',
                                    color: '#FFFFFF',
                                    borderRadius: '8px',
                                    height: '48px',
                                    backgroundColor: '#123FA9',
                                    '&:hover': { backgroundColor: '#123FA9' }
                                }}
                                onClick={() => handleSubmit()}
                            >
                                Submit
                            </Button>
                        </Box>}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        addStatus: state.ReceptionReducer.addStatus,
        visitorMail:state.ReceptionReducer.visitorMail,
        levelDatas:state.CommonReducer.tenantOccupiedLeves,
        unitDatas: state.CommonReducer.tenantOccupiedUnits,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        inviteVisitorOnTenant: (data) => dispatch(visitorsAction.inviteVisitorOnTenant(data)),
        receptionStateToInitial: () => {
            dispatch(visitorsAction.receptionStateToInitial());
        },
        getAllUsers:(data)=>(dispatch(visitorsAction.getAllUsers(data))),
        getAllLevels: () => dispatch(commonAction.getTenantAccessLevel()),
        getUnitsByLevelid: (data) => dispatch(commonAction.getTenantAccessUnit(data)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BatchInvitation);