import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import {Tenant_visitor_Active_Column} from "../../components/Data/Data";
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxIcon.svg';
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import { DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/system';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { preventDatePickerKeyboard } from '../../Utils/utils';
import moment from 'moment';

const StyledTableRow = styled(TableRow)(({theme})=>({
  "&:nth-of-type(odd)":{
    backgroundColor: "#F8F9FB"
  }
}))

const StyledTableCell = styled(TableCell)({
  color:"#0F2C6F",
  fontSize:'14px',
  fontWeight:500,
  cursor:'pointer'
})

const TenantVisitorsList = ({activeType,tableData,tableDataCount,setCurrentPage,currentPage,filterDate,setFilterDate}) => {
  const navigate=useNavigate();
  const handlePagination = (e,value)=>{
    setCurrentPage(value)
  } 
  const today = new Date();
  const tableFilters = ()=>{
    return(
      <>
      <Grid container columnSpacing={1} sx={{p:2}}>
          <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            {/* <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
              <Select
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}
                label="Status" name="serviceStatus">
                    <MenuItem value="status">Status</MenuItem>
              </Select>
            </FormControl> */}
            <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
              <DatePicker
                inputFormat='dd/MM/yyyy'
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                value={filterDate}
                onChange={(value)=>setFilterDate(value)}
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                disableFuture={activeType=='all' ?  false : true}
                disablePast={activeType=='all' ? true : false}
                maxDate={activeType!='all' ?  today.setDate(today.getDate() - 1) : null} 
              />
            </FormControl>
            <Typography onClick={()=>setFilterDate(null)} sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}}>Reset</Typography>
          </Grid>
          {/* <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <OutlinedInput placeholder="Search..."
              name='search'
              endAdornment={<SearchIcon />} size="small" />
          </Grid> */}
        </Grid>
      </>
    )
  }

  const getTableColumns = ()=>{
    let columns = activeType=="all" ? Tenant_visitor_Active_Column : Tenant_visitor_Active_Column
    return (
      <>
      {columns.map((value)=>(
        <TableCell sx={{color: '#0F2C6F',fontSize: '13px',fontWeight:550}}>
          {value.column_name=="Checkbox" ? <Checkbox icon={<CheckboxIcon />} /> : value.column_name}
        </TableCell>
      ))}
      </>
    )
  }

  const getTableDatas = ()=>{
    return(
      <>
      {tableData?.map((value)=>(
        <StyledTableRow>
          {/* <TableCell>
            <Checkbox icon={<CheckboxIcon />} />
          </TableCell> */}
          <StyledTableCell onClick={()=>navigate(`/review_tenant_inivte/0/${value.invitations_id}/${activeType=='all' ? 'true' : 'false'}`)}>{value.invitations_title}</StyledTableCell>
          <StyledTableCell onClick={()=>navigate(`/review_tenant_inivte/0/${value.invitations_id}/${activeType=='all' ? 'true' : 'false'}`)}>{value.visitor_count}</StyledTableCell>
          {/* <StyledTableCell onClick={()=>navigate(`/review_tenant_inivte/0/${value.invitations_id}/${activeType=='all' ? 'true' : 'false'}`)}>{value.type}</StyledTableCell> */}
          <StyledTableCell onClick={()=>navigate(`/review_tenant_inivte/0/${value.invitations_id}/${activeType=='all' ? 'true' : 'false'}`)}>{moment.utc(value.from_date_time).local().format('DD MMM YYYY HH:mm')}</StyledTableCell>
          <StyledTableCell onClick={()=>navigate(`/review_tenant_inivte/0/${value.invitations_id}/${activeType=='all' ? 'true' : 'false'}`)}>{moment.utc(value.to_date_time).local().format('DD MMM YYYY HH:mm')}</StyledTableCell>
          <StyledTableCell onClick={()=>navigate(`/review_tenant_inivte/0/${value.invitations_id}/${activeType=='all' ? 'true' : 'false'}`)}>{value.submitted_by_name}</StyledTableCell>
        </StyledTableRow>
      ))}
      </>
    )
  }
  return (
    <>
    <Box sx={{backgroundColor:"#FFFFFF"}}>
      {tableFilters()}
     <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {getTableColumns()}
          </TableRow>
        </TableHead>
        <TableBody>
            {getTableDatas()}
        </TableBody>
      </Table>
     </TableContainer>
     {(tableData==undefined || tableData.length<1 ) && 
      <Box sx={{p:5,backgroundColor:'rgba(143, 146, 161,0.05)'}}>
        <Typography sx={{fontSize:'18px',textAlign:"center",color:'#0F2C6F'}}>No Data Found</Typography>
      </Box>
      }
     {tableDataCount > 0 && 
      <Box sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}}>
        <Pagination  count={Math.ceil(tableDataCount/10)} page={currentPage} onChange={handlePagination} sx={{display:"flex",justifyContent:"center",py:2}} size="large" shape='rounded' /> 
      </Box>
      }
     </Box>
    </>
  )
}

const mapStateToProps = (state) => {
  return{

  }
}

const mapDispatchToProps = (dispatch)=>{
  return{

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TenantVisitorsList)