import { Box, Typography } from "@mui/material";
import React from "react";

const UnAuthorized = ({data})=>{
    return(
        <>
        <Box sx={{display:"flex",justifyContent:"center",alignItems:'center',backgroundColor:"rgba(143, 146, 161,0.05)",height:'90vh'}}>
                <Typography sx={{color:'red',fontSize:"20px"}}>{data}</Typography>
        </Box>
        </>
    )
}

export default UnAuthorized;