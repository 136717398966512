import axios from "axios";
import { createAction } from "redux-actions";
import ApiService from "../../../AxiosConfig/ApiService";

const getToken = () => {
    const token = localStorage.getItem('userToken')
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return config;
}
    
export const sessionExpired = createAction("SESSION_EXPIRED");

//when a notification receives from fcm this action triggers and getNotificaton api will be called 
export const isNotificatonRceived = createAction("IS_NOTIFICATION_RECEIVED")
export const notificatonReceived = (data)=>{
    return(dispatch)=>{
        dispatch(isNotificatonRceived(data))
    }
}

const getDbDataInitiate = createAction("GET_DBDATA_INITIATE")
const getDbDataSuccess = createAction("GET_DBDATA_SUCCESS")
const getDbDataFailure = createAction("GET_DBDATA_FAILURE")

export const  getDbData = (data)=>{
    return(dispatch)=>{
        dispatch(getDbDataInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getDashboardData`,data,getToken())
        .then((response)=>{
            if(response.data.response_json.response_status==1){
                 dispatch(getDbDataSuccess(response.data.response_json.data_array))
            }else{
                dispatch(getDbDataFailure(response.data.response_json.message))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getDbDataFailure(err.response?.data?.message))
            }
        })
    }

}

const getNotificationMsgInitiate = createAction("GET_NOTIFICATION_MSG_INITIATE")
const getNotificationMsgSuccess = createAction("GET_NOTIFICATION_MSG_SUCCESS")
const getNotificationMsgFailure = createAction("GET_NOTIFICATION_MSG_FAILURE")

export const getNotificationMsg = ()=>{
    return(dispatch)=>{
        dispatch(getNotificationMsgInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/getAllNotificationMessages`,getToken())
        .then((response)=>{
            if(response.data){
                dispatch(getNotificationMsgSuccess(response.data.data_array))
            }else{
                dispatch(getNotificationMsgFailure(''))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getNotificationMsgFailure(err.message))
            }
        })
    }

}

const updateAnnouncementReadStatusInitiate = createAction("UPDATE_ANNOUNCEMENTREAD_INITIATE") 
const updateAnnouncementReadStatusSuccess = createAction("UPDATE_ANNOUNCEMENTREAD_SUCCESS") 
const updateAnnouncementReadStatusFailure = createAction("UPDATE_ANNOUNCEMENTREAD_FAILURE") 

export const updateAnnouncementReadStatus = (data)=>{
    return(dispatch)=>{
        dispatch(updateAnnouncementReadStatusInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/UpdateAnnouncementReadedStatus`,data,getToken())
        .then((response)=>{
            if(response.data.response_status=="success"){
                dispatch(updateAnnouncementReadStatusSuccess(response.data))
            }else{
                dispatch(updateAnnouncementReadStatusFailure())
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(updateAnnouncementReadStatusFailure(err.message))
            }
        })
    }
}

const updateNotificationStatusInitiate = createAction("UPDATE_NOTIFICATION_VIEWSTATUS_INITIATE")
const updateNotificationStatusSuccess = createAction("UPDATE_NOTIFICATION_VIEWSTATUS_SUCCESS")
const updateNotificationStatusFailure = createAction("UPDATE_NOTIFICATION_VIEWSTATUS_FAILURE")

export const updateNotificationViewStatus = (data)=>{
    return(dispatch)=>{
        dispatch(updateNotificationStatusInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/updateNotificationViewStatus`,data,getToken())
        .then((response)=>{
            if(response.data){
                dispatch(updateNotificationStatusSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(updateNotificationStatusFailure(err.message))
            }
        })
    }
}

const getGlobalFilterInitiate = createAction("GET_GLOBALFILTER_INITIATE")
const getGlobalFilterSuccess = createAction("GET_GLOBALFILTER_SUCCESS")
const getGlobalFilterFailure = createAction("GET_GLOBALFILTER_FAILURE")

export const getGlobalFilter = (data)=>{
    return(dispatch)=>{
        dispatch(getGlobalFilterInitiate)
        axios.post(`${process.env.REACT_APP_API_URL}/getSearchDashboardData`,data,getToken())
        .then((response)=>{
            if(response.data.response_status==1){
                dispatch(getGlobalFilterSuccess(response.data.data_array.tempArrayList))
            }else{
                dispatch(getGlobalFilterFailure())
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getGlobalFilterFailure())
            }
        })
    }
}


const getRealtimeTurnStileDataInitiate = createAction("GET_REALTIME_TURNSTILE_DATA_INITIATE")
const getRealtimeTurnStileDataSuccess = createAction("GET_REALTIME_TURNSTILE_DATA_SUCCESS")
const getRealtimeTurnStileDataFailure = createAction("GET_REALTIME_TURNSTILE_DATA_FAILURE")
const getRealtimeTurnStileDataNull = createAction("GET_REALTIME_TURNSTILE_DATA_NULL")

export const getRealtimeTurnStileNullAction = () => dispatch => dispatch(getRealtimeTurnStileDataNull());

export const getRealtimeTurnStileAction = () => async (dispatch) => {
    dispatch(getRealtimeTurnStileDataInitiate());
    try {
        const response = await ApiService().get('/realtimeTurnStileDatas');
        console.log(response);

        if(response.data?.response_status === 1){
            dispatch(getRealtimeTurnStileDataSuccess(response.data));
        }else{
            dispatch(getRealtimeTurnStileDataFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getRealtimeTurnStileDataFailure(error.response.data));
            }

    }
}