import { Box, Button, Grid, Typography } from "@mui/material";
import { ReactComponent as BackArrowLeft } from "../../Assets/icons/BackArrowLeft.svg";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import React from "react";
import StepperTab from "./StepperTab";
import { useNavigate } from "react-router-dom";

const OnBoardBatch = () => {
  const navigate = useNavigate();
  return (
    <>
    <Grid container>
    <Grid item xs={1.5} sx={{backgroundColor:'#F9FAFA'}}/>

    <Grid item xs={9}>
    <Box
      sx={{
        padding: { md: "65px 20px 0 20px", xs: "30px 20px" },
        background: "#F9FAFA",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",

          borderRadius: "6px",
        }}
      >
        
        <BackIcon style={{cursor:'pointer'}} onClick={()=>navigate(-1)}/>
      </Box>

      {/* Title*/}
      <Box sx={{ padding: "30px 0" }}>
        <Typography
          sx={{ fontSize: "26px", fontWeight: 500, color: "#002D74" }}
        >
          Batch Onboarding
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 500,
            color: "#002D74",
            padding: "5px 0",
          }}
        >
          Please fill in employee details into excel template generated in previous step and upload below. If you would like to manually enter details, please select single employee instead. 

        </Typography>
      </Box>
      <StepperTab />
    </Box>
    </Grid>

    <Grid item xs={1.5} sx={{backgroundColor:'#F9FAFA'}}/>
    </Grid>

    
    </>
  );
};

export default OnBoardBatch;
