import {
  Box,
  Button,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Modal,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import Faultalert from "../common/faultalert";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CloseButton } from "../../Assets/icons/Alertclose.svg";
import DropZone from "../../Utils/dropzone";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import * as commonAction from "../../redux/actions/commonAction/index";
import { connect } from "react-redux";
import Loader from "../common/Loader";

const tableData = [
  {
    name: "abcd",
    mail: "abc@gmail.com",
    mobile: "+9999999999",
    title: "Finance Executive",
    role: "Administrator",
  },
  {
    name: "abcd",
    mail: "abc@gmail.com",
    mobile: "+9999999999",
    title: "Finance Executive",
    role: "Administrator",
  },
  {
    name: "abcd",
    mail: "abc@gmail.com",
    mobile: "+9999999999",
    title: "Finance Executive",
    role: "Administrator",
  },
  {
    name: "abcd",
    mail: "abc@gmail.com",
    mobile: "+9999999999",
    title: "Finance Executive",
    role: "Administrator",
  },
];
const StyledTableCell = styled(TableCell)({
  color: "#0F2C6F",
  fontSize: "13px",
  fontWeight: 550,
  textAlign: "center",
});

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  height: "18px",
  ":nth-last-of-type(even)": {
    backgroundColor: " #F5F8FF",
  },
});
const ReviewEmployeeList = ({
  updateEmployeeApprovedStatus,
  updateEmployeeStatus,
  batchEmp,
  getBatchEmp,
}) => {
  const { emp_id, tabValue, fromBms } = useParams();
  const navigate = useNavigate();
  const [alertStatus, setAlertStatus] = useState(false);
  const [rejectAlert, setRejectAlert] = useState(false);
  const [localStorageData, setLocalStorageData] = useState({});
  const [alertData, setAlertData] = useState({
    label: "",
    description: "",
    buttonvalue: "",
    buttonStyle: "",
    activityType: "",
  });
  const [rejectionMsg, setRejectionMsg] = useState("");
  const [supportingFiles, setSupportingFiles] = useState([]);
  const [empApprovedStatus, setEmpApprovedStatus] = useState("e");
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [batchEmpDetails, setBatchEmpDetails] = useState([]);

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("userData"));
    setLocalStorageData(localData);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 1000);
    getBatchEmp({ employee_batch_log_id: emp_id });
    setBatchEmpDetails(batchEmp);
  }, []);
  useEffect(() => {
    if (batchEmp?.length > 0) {
      setBatchEmpDetails(batchEmp);
    }
  }, [batchEmp]);

  useEffect(() => {
    setEmpApprovedStatus(updateEmployeeApprovedStatus);
  }, [updateEmployeeApprovedStatus]);

  const handleButtonActions = (actionType) => {
    updateEmployeeStatus({ employee_id: 206, approved_status: "rejected" });
    if (actionType !== "reject") {
      setAlertStatus(true);
      setAlertData({
        label:
          actionType === "delete"
            ? "Delete employee Onboard Request"
            : "Approve employee onboard request",
        description: `Are you sure want to ${actionType} onboard request?`,
        buttonvalue: "confirm",
        buttonStyle: "blue",
        activityType: actionType,
      });
    } else {
      setRejectAlert(true);
    }
  };

  const confirmFunc = () => {};
  const closeFunc = () => {
    setAlertStatus(false);
    setRejectAlert(false);
    setAlertData({
      label: "",
      description: "",
      buttonvalue: "",
      buttonStyle: "",
      activityType: "",
    });
    setSupportingFiles([]);
    setRejectionMsg("");
  };

  const rejectEmpOnboardReq = () => {};
  const handleRejectionMsg = (e) => {
    if (e.target.value.length <= 300) {
      setRejectionMsg(e.target.value);
    }
  };
  const supportingFile = (files) => {
    setSupportingFiles(files);
  };
  const removeSupportImage = (id) => {
    let newSupportImages = [];
    for (var i = 0; i < supportingFiles.length; i++) {
      if (supportingFiles[i].id !== id) {
        newSupportImages.push(supportingFiles[i]);
      }
    }
    setSupportingFiles([...newSupportImages]);
  };

  const backArrowFunction = () => {
    // if(localStorageData.role_id===4){
    //     navigate('/manage_employees/1')
    // }else{
    //     navigate(`/onboard_new_tenant/1/${tabValue}`)
    // }
    navigate(-1);
  };

  return (
    <>
      {loaderStatus === true ? (
        <Loader status={loaderStatus} />
      ) : (
        <>
          {alertStatus && (
            <Faultalert
              closeFunc={closeFunc}
              confirmFunc={confirmFunc}
              alertData={alertData}
            />
          )}
          <Grid
            container
            sx={{
              backgroundColor: "rgba(143, 146, 161,0.05)",
              height: "100%",
              borderRadius: "6px",
            }}
          >
            <Grid item xs={1.5} />

            <Grid item xs={9}>
              <Box sx={{ pt: 7, pb: 5 }}>
                <BackIcon cursor="pointer" onClick={backArrowFunction} />
                <Typography sx={{ color: "#0F2C6F", fontSize: "26px", my: 2 }}>
                  Onboard New Employees
                </Typography>
                <Typography
                  sx={{ display: "inline", color: "#9AA6C3", fontSize: "12px" }}
                >
                  Requested on{" "}
                  {batchEmpDetails && batchEmpDetails[0]?.created_at}
                </Typography>
                <Chip
                  label={
                    batchEmpDetails && `${batchEmpDetails[0]?.approved_status}`
                  }
                  sx={{
                    p: 0,
                    color: "#0F2C6F",
                    backgroundColor: "#DBDFE9",
                    borderRadius: "6px",
                    height: "24px",
                    ml: 2,
                  }}
                />
              </Box>

              <Box sx={{ backgroundColor: "#FFFFFF", p: 3 }}>
                <Box
                  sx={{
                    border: " 1px solid #DBDFE9",
                    borderRadius: "6px",
                    p: 2,
                    width: "60%",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12} sm={5}>
                      <Typography sx={{ fontSize: "14px", color: " #0F2C6F" }}>
                        Access start Date
                      </Typography>
                      <Typography sx={{ fontSize: "13px", color: " #0F2C6F" }}>
                        {batchEmpDetails &&
                          batchEmpDetails[0]?.access_start_date}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Typography sx={{ fontSize: "13px", color: " #0F2C6F" }}>
                        {batchEmpDetails && batchEmpDetails?.length} personnel
                      </Typography>
                      <Typography sx={{ fontSize: "13px", color: " #0F2C6F" }}>
                        Office Unit :{" "}
                        {batchEmpDetails && batchEmpDetails[0]?.location}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box sx={{ backgroundColor: "#FFFFFF" }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Employee Name</StyledTableCell>
                        <StyledTableCell>Work mail</StyledTableCell>
                        <StyledTableCell>Mobile Number</StyledTableCell>
                        <StyledTableCell>Tenant Role</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {batchEmpDetails && batchEmpDetails.length > 0
                        ? batchEmpDetails.map((value) => (
                            <StyledTableRow
                              onClick={() => {
                                if (fromBms != "true") {
                                  navigate(
                                    `/single_employee_review/${value.employee_id}/approved`
                                  );
                                }
                              }}
                            >
                              <TableCell
                                sx={{ color: "#0F2C6F", textAlign: "center" }}
                              >
                                {value.name}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#0F2C6F", textAlign: "center" }}
                              >
                                {value.email}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#0F2C6F", textAlign: "center" }}
                              >
                                {value.mobile_country_code && value.mobile_no
                                  ? `${
                                      value.mobile_country_code.includes("+")
                                        ? value.mobile_country_code
                                        : `+${value.mobile_country_code}`
                                    } ${value.mobile_no}`
                                  : "-"}
                              </TableCell>
                              <TableCell
                                sx={{ color: "#0F2C6F", textAlign: "center" }}
                              >
                                {value.role}
                              </TableCell>
                            </StyledTableRow>
                          ))
                        : ""}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            <Grid item xs={1.5} />
          </Grid>
          <Modal open={rejectAlert} sx={{ overflow: "scroll" }}>
            <Box
              sx={{
                position: "absolute",
                top: "10%",
                left: { xs: "5%", sm: "30%" },

                backgroundColor: "#FFFFFF",
                width: { xs: "auto", md: "600px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C0C7D7",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "24px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "500", color: "#0F2C6F" }}
                >
                  Reject onboarding tenant employee request
                </Typography>
                <IconButton sixe="large">
                  <CloseButton onClick={() => closeFunc()} />
                </IconButton>
              </Box>
              <Box sx={{ padding: "16px " }}>
                <Typography sx={{ color: "#6F80A9", fontSize: "16px" }}>
                  Comments below will be visible to Tenant
                </Typography>
              </Box>
              <Box sx={{ padding: "16px " }}>
                <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
                  Please provide Instruction for Tenant to update*
                </Typography>
              </Box>
              <Box sx={{ padding: "16px " }}>
                <OutlinedInput
                  fullWidth
                  sx={{ color: "#0F2C6F", fontSize: "14px", minHeight: 80 }}
                  placeholder="You may write a short summary or note to tenant"
                  onChange={(e) => handleRejectionMsg(e)}
                  value={rejectionMsg}
                />
                <Typography
                  sx={{ color: "#6F80A9", fontSize: "14px", mt: 1 }}
                >{`[${rejectionMsg.length}/300]`}</Typography>
              </Box>
              <Box sx={{ padding: "16px 24px" }}>
                <Typography sx={{ color: "#0F2C6F", fontSize: "16px", mb: 2 }}>
                  Supporting Materials(optional)
                </Typography>
                {supportingFiles.length > 0 ? (
                  supportingFiles.map((item) => (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        border: "2px solid #80808021",
                        mb: 0.5,
                        borderRadius: "5px",
                      }}
                    >
                      <List
                        sx={{
                          width: "100%",
                          bgcolor: "background.paper",
                          p: 0,
                        }}
                      >
                        <ListItem
                          sx={{ pl: 0 }}
                          secondaryAction={
                            <IconButton edge="end" aria-label="delete">
                              <CloseOutlinedIcon
                                onClick={() => removeSupportImage(item.id)}
                              />
                            </IconButton>
                          }
                        >
                          <ListItemText
                            primary={
                              <Typography variant="p" sx={{ m: 2 }}>
                                {item.fileName}
                                <span
                                  style={{ color: "gray", fontSize: "15px" }}
                                >{`     (${Math.floor(
                                  item.size / 1024
                                )} of ${Math.floor(
                                  item.size / 1024
                                )}KB)`}</span>
                              </Typography>
                            }
                            secondary={""}
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  ))
                ) : (
                  <DropZone getFiles={supportingFile} />
                )}
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      padding: "24px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => closeFunc()}
                      sx={{
                        height: "40px",
                        padding: "10px",
                        fontSize: "14px",
                      }}
                    >
                      Discard
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      padding: "24px",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => rejectEmpOnboardReq()}
                      disabled={rejectionMsg.length <= 0 ? true : false}
                      sx={{
                        color: "#ffffff",
                        backgroundColor: "#0F2C6F",
                        height: "40px",
                        padding: "10px",
                        fontSize: "14px",
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    updateEmployeeApprovedStatus:
      state.CommonReducer.updateEmployeeApprovedStatus,
    batchEmp: state.CommonReducer.batchEmp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEmployeeStatus: (data) =>
      dispatch(commonAction.updateEmployeeApprovedStatus(data)),
    getBatchEmp: (data) => dispatch(commonAction.getBatchEmp(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewEmployeeList);
