import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DevelopmentProgress from '../DevelopmentProgress';
import ReviewAirConExtension from './ReviewAirConExtension';
import ReviewCargoLiftRequst from './ReviewCargoLiftRequst';
import ReviewLightBulb from './ReviewLightBulb';
import ReviewMiscADHOCRequest from './ReviewMiscADHOCRequest';
import ReviewSecurityClearance from './ReviewSecurityClearance';

export default function ReviewRequest() { 
  let { service_req_type_id, tab_id,fromDashboard } = useParams();

  useEffect(()=>{
    renderServiceRequest()
  },[service_req_type_id])

  const renderServiceRequest = () => {
    let returnComponent;
    if (service_req_type_id === '1') {
      returnComponent = <ReviewCargoLiftRequst fromDashboard={fromDashboard} tab_value={tab_id} />
    } else if (service_req_type_id === '4') {
      returnComponent = <ReviewSecurityClearance fromDashboard={fromDashboard} tab_value={tab_id} />
    } else if (service_req_type_id === '2') {
      returnComponent = <ReviewLightBulb fromDashboard={fromDashboard} tab_value={tab_id} />
    } else if(service_req_type_id==='3'){
      returnComponent=<ReviewAirConExtension fromDashboard={fromDashboard} tab_value={tab_id} />
    } else if(service_req_type_id==='5' || service_req_type_id==='7'){
      returnComponent=<ReviewMiscADHOCRequest fromDashboard={fromDashboard} tab_value={tab_id} />
    }
    else {
      returnComponent = <DevelopmentProgress />
    }
    return returnComponent;
  }

  return (
    <div>{renderServiceRequest()}</div>
  )
}
