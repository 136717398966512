import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import {ReactComponent as AnnouncentIcon} from '../../../Assets/icons/Announcement.svg';
import {ReactComponent as StaffIcon} from '../../../Assets/icons/Staff.svg';
import {ReactComponent as InfoIcon} from '../../../Assets/icons/Info.svg';
import {ReactComponent as SettingsIcon} from '../../../Assets/icons/Settings.svg';
import {ReactComponent as ServiceRequest} from '../../../Assets/icons/ServiceRequestIcon.svg';
import { useNavigate, NavLink } from "react-router-dom";

const PrimaryTypography = styled(Typography)({
  fontSize:'18px',
  color:'#0F2C6F',
  fontWeight:500,
  marginTop:'10px'
})

const SecondaryTypography = styled(Typography)({
  fontSize:'14px',
  color:'#0F2C6F',
  fontWeight:400,
  marginTop:'10px'
})

const StyledButton = styled(Button)({
  backgroundColor:'#123FA9',
  borderRadius:'6px',
  textTransform:'capitalize',
  cursor:'pointer',
  '&:hover':{
    backgroundColor:'#123FA9'
  },
  marginBottom:'5px'
 
})

const VisitorManagement = () => {
  const navigate = useNavigate();
  return (
    <>
    <Box sx={{height: "-webkit-fill-available", width: "100%", background: "#F9FAFA", p:6}}>

    
        <Box sx={{display: 'grid', gridTemplateColumns: "33% 33% 33%"}}>
            <Box sx={{p: 4, display: "flex", flexDirection: "column", gap: 5, alignItems: "start", mx: 1, my: 3, background: "white", borderRadius: "8px"}}>
                <StaffIcon />
                <Box>
                <Typography sx={{color: "#0F2C6F", fontSize: "18px", fontWeight: 500}}>Manage Visitor</Typography>
                <Typography sx={{color: "#0F2C6F", fontSize: "14px", fontWeight: 400, mt: "20px"}}>Manage visitors include an overview of the list of visitors registered and have entered/ exited Singapore Land Tower.</Typography>
                </Box>
                <NavLink to={'/manage_visitor/0'} style={{marginTop: "10px"}}>
                <Button sx={{textTransform: "none", background: "#123FA9", borderRadius:"6px", }} variant='contained'>Continue</Button>
                </NavLink>
            </Box>
            <Box sx={{p: 4, display: "flex", flexDirection: "column", gap: 5, alignItems: "start", mx: 1, my: 3, background: "white", borderRadius: "8px"}}>
                <StaffIcon />
                <Box>
                <Typography sx={{color: "#0F2C6F", fontSize: "18px", fontWeight: 500}}>Upcoming Visitors</Typography>
                <Typography sx={{color: "#0F2C6F", fontSize: "14px", fontWeight: 400, mt: "20px"}}>Upcoming visitors include an overview of invitations that have been made by all SingLand Mobile app users.</Typography>
                </Box>
                <NavLink to={'/upcomming_visitors/0'} style={{marginTop: "10px"}}>
                <Button sx={{textTransform: "none", background: "#123FA9", borderRadius:"6px", }} variant='contained'>Continue</Button>
                </NavLink>
            </Box>
         </Box>
    </Box>
    </>
  );
};

export default VisitorManagement;
