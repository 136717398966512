import { Box, Typography } from '@mui/material';
import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { renderToString } from "react-dom/server"

const getDateDatas = (len) => {
  let returnData = [];

  for (let i = 1; i <= len; i++) {
    returnData.push(`${i} Sep`)
  }
  console.log(returnData);
  return returnData;
}

const getAreaDatas = (len) => {
  let returnArr = [];

  for (let i = 0; i < len; i++) {
    returnArr.push(Math.floor(Math.random() * 45))
  }

  console.log(returnArr);

  return returnArr;
}



const DashboardChart = ({ chartData }) => {

  const areaOptions = {
    series: chartData?.chart_full_data,
    options: {
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
        }
      },
      colors: ["#2764F4", "#0FA08C",],
      fill: {
        background: ["#2764F4", "#0FA08C",],
      },

      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        colors: ['transparent'],
        width: 2,
      },
      xaxis: {
        type: 'text',
        categories: chartData?.x_axis_data,
      },
      tooltip: {

        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          console.log("custom hover data", 'series', series, '\n', 'series index', seriesIndex, '\n', 'data point index', dataPointIndex, '\n', 'w', w)
          //  let date = new Date();

          //  let dayArr  = ['Sunday','Monday','Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
          //  let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct','Nov', 'Dec']

          let currentObj = chartData?.tooltip_data[seriesIndex][dataPointIndex];

          // console.log(chartData?.tooltip_data)
          // console.log(currentArr)
          // console.log(currentArr[dataPointIndex])



          return renderToString(<div style={{ display: 'flex', flexDirection: 'column', background: "white", minWidth: "150px", padding: "8px 16px", gap: '4px' }} >

            <p style={{ color: '#02194D', fontWeight: 'bold' }}>{currentObj?.title}</p>

            <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}> 
              {currentObj?.role_wise_data?.map((item, index) => {
                return <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', lineHeight: '20px' }} key={index}>
                  <div style={{ color: item?.color, display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                      <div style={{ height: '8px', width: '8px', borderRadius: '1px', background: item?.color, }} />
                      <div>
                        {item?.name}
                      </div>
                    </div>
                  </div>
                  <div style={{ color: item.color }}>{item?.value}</div>
                </div>
              })}
            </div>
            <p style={{ color: '#6F80A9', fontWeight: 'medium' }}>{currentObj?.date}</p>
          </div>)
        }

      },
    },
  }

  return (
    <ReactApexChart options={areaOptions.options} series={areaOptions.series} type="bar" height={350} className="chart" />

  )
}

export default DashboardChart