import axios from "axios";
import { createAction } from "redux-actions";
const getToken = () => {
    const token = localStorage.getItem('userToken')
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return config;
}
export const eventPromotionStateNull = createAction("STATE_TO_NULL_eANDp");
export const sessionExpired = createAction("SESSION_EXPIRED");
export const SETeANDpStateToNULL = () => {
    return (dispatch) => {
        dispatch(eventPromotionStateNull())
    }
}

export const createEventInitiate = createAction("CREATE_EVENT_INITIATE");
export const createEventSuccess = createAction('CREATE_EVENT_SUCCESS');
export const createEventFailure = createAction('CREATE_EVENT_FAILURE');

export const createEvent = (requestData) => {
    return (dispatch) => {
        dispatch(createEventInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/Eventcreate`, requestData, getToken())
            .then((response) => {                                                                          
                if (response.data) {
                    dispatch(createEventSuccess(response.data));
                }
            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
}

export const createPromotionInitiate = createAction('CREATE_PROMOTION_INITIATE');
export const createPromotionSuccess = createAction('CREATE_PROMOTION_SUCCESS');
export const createPromotionFailure = createAction('CREATE_PROMOTION_FAILURE');

export const createPromotion = (requestData) => {
    let token=localStorage.getItem('userToken')
    let configs = {
        headers: {
             Authorization: `Bearer ${token}`,
             "Content-Type": "multipart/form-data",
        },
    };
    return (dispatch) => {
        dispatch(createEventInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/Createpromotion`, requestData, configs)
            .then((response) => {
                if(response.data){
                    dispatch(createPromotionSuccess(response.data))
                }
            })
            .catch((error) => {
                dispatch(createPromotionFailure())
            })
    }
}

export const getPromotionEventListInitiate = createAction('PROMOTION_EVENT_LIST_INITIATE');
export const getPromotionEventListSuccess = createAction('PROMOTION_EVENT_LIST_SUCCESS');
export const getPromotionEventListFailure = createAction('PROMOTION_EVENT_LIST_FAILURE');

export const getPromotionEventList = (reqData) => {
    return (dispatch) => {
        dispatch(getPromotionEventListInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/EventsandPromotionsListWeb`, reqData, getToken())
            .then((response) => {
                if (response.data?.response_status === 'success') {
                    dispatch(getPromotionEventListSuccess(response.data))
                }else{
                    dispatch(getPromotionEventListFailure())
                }
            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
}

export const getPropertiesInitiate = createAction('GET_PROPERTIES_INTITATE');
export const getPropertiesSuccess = createAction('GET_PROPERTIES_SUCCESS');
export const getPropertiesFailure = createAction('GET_PROPERTIES_FAILURE');

export const getPromotionProperties = () => {
    return (dispatch) => {
        dispatch(getPropertiesInitiate());
        axios.get(`${process.env.REACT_APP_API_URL}/getProperty`, getToken())
            .then((response) => {
                if(response.data.response_status=="success"){
                    dispatch(getPropertiesSuccess(response.data.response_data))
                }else{
                    dispatch(getPropertiesSuccess())
                }
                // console.log(response.data);
            })
            .catch((error) => {
                if(error.status===401){
                    dispatch(sessionExpired('Session Expired'));
                  }else{
                    dispatch(getPropertiesFailure())
                  }
            })
    }
}


export const updateInactiveStatusInitiate = createAction('EVENT_PROMOTIONS_STATUS_INACTIVE_INITIATE');
export const updateInactiveStatusSuccess = createAction('EVENT_PROMOTIONS_STATUS_INACTIVE_SUCCESS');
export const updateInactiveStatusFailure = createAction('EVENT_PROMOTIONS_STATUS_INACTIVE_FAILURE');

export const updateEventsPromotionInactive = (reqData) => {
    return(dispatch) => {
        dispatch(updateInactiveStatusInitiate());
        
        axios.post(`${process.env.REACT_APP_API_URL}/EventPromotionChangeStatus`,reqData, getToken())
        .then((response) => {
            if(response.data.response_status=="success"){
                dispatch(updateInactiveStatusSuccess(response.data))
            }else{
                dispatch(updateInactiveStatusSuccess())
            }
            // console.log(response.data);
        })
        .catch((error) => {
            if(error.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                dispatch(updateInactiveStatusFailure())
              }
        })
    }
}

const updateReadStatusInitiate = createAction("UPDATE_EP_READSTATUS_INITIATE")
const updateReadStatusSuccess = createAction("UPDATE_EP_READSTATUS_SUCCESS")
const updateReadStatusFailure = createAction("UPDATE_EP_READSTATUS_FAILURE")

export const updateReadStatus = (data)=>{
    return(dispatch) => {
        dispatch(updateReadStatusInitiate());
        
        axios.post(`${process.env.REACT_APP_API_URL}/UpdateEvePromoReadedStatus`,data, getToken())
        .then((response) => {
            if(response.data.response_status=="success"){
                dispatch(updateReadStatusSuccess(response.data))
            }else{
                dispatch(updateReadStatusFailure())
            }
        })
        .catch((error) => {
            if(error.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                dispatch(updateReadStatusFailure())
              }
        })
    }
}

const getEandPDetailsInitiate = createAction("GET_EANDP_DETAILS_INITIATE")
const getEandPDetailsSuccess = createAction("GET_EANDP_DETAILS_SUCCESS")
const getEandPDetailsFailure = createAction("GET_EANDP_DETAILS_FAILURE")

export const getEandPDetails = (data)=>{
    return(dispatch)=>{
        dispatch(getEandPDetailsInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getSingleEventPromotionDetailsById`,data,getToken())
        .then((response)=>{
            dispatch(getEandPDetailsSuccess(response.data.response_data))
        })
        .catch((error) => {
            if(error.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                dispatch(getEandPDetailsFailure())
              }
        })
    }
}

const updateEandPDraftInitiate = createAction("UPDATE_EANDP_DRAFT_INITIATE")
const updateEandPDraftSuccess = createAction("UPDATE_EANDP_DRAFT_SUCCESS")
const updateEandPDraftFailure = createAction("UPDATE_EANDP_DRAFT_FAILURE")

export const updateEandPDraft = (data)=>{
    return(dispatch)=>{
        dispatch(updateEandPDraftInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/UpdateDraftsEventPromotion`,data,getToken())
        .then((response) => {                                                                          
            if (response.data) {
                dispatch(updateEandPDraftSuccess(response.data));
            }
        })
        .catch((error) => {
            if(error.status===401){
                dispatch(sessionExpired('Session Expired'));
              }
        })
    }
}