import React,{useEffect} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Button,
  TextField
} from "@mui/material";
import {ReactComponent as SearchIcon} from '../../Assets/icons/SearchIcon.svg';
import {ReactComponent as DatePickerIcon} from '../../Assets/icons/DatePickerIcon.svg';
import Loader from "../common/Loader";
import TenantEmpList from './TenantEmployeeList';
import { useParams } from 'react-router-dom';
import { DatePicker } from "@mui/x-date-pickers";
import { preventDatePickerKeyboard } from "../../Utils/utils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TAB_HEADING = [
  {
    id: 3,
    name: "Active",
  },
  {
    id: 2,
    name: "Onboarding",
  },
  {
    id: 4,
    name: "Offboarding",
  },
];

const applicableFilters = [
  {
    id: 0,
    status: false,
    sort:true,
    date: true,
    search: true,
    isResetApplicable: true,
    statusHeading: '',
    dateHeading: 'Active Date',
    searchHeading: 'Search by company',
    disableFeatureDate: true
  },
  {
    id: 1,
    status: false,
    sort:true,
    date: true,
    search: true,
    isResetApplicable: true,
    statusHeading: 'Status',
    dateHeading: 'Onboarding Date',
    searchHeading: 'Search by company, Tic id',
    disableFeatureDate: false
  },
  {
    id: 2,
    status: false,
    sort:true,
    date: true,
    search: true,
    isResetApplicable: true,
    statusHeading: '',
    dateHeading: 'Offboard Date',
    searchHeading: 'Search by company',
    disableFeatureDate: false
  },
]

const applicableStatus = [
  ['', ''],
  ['submitted', 'approved', 'rejected'],
  [''],
]
const TenantEmployee = () => {
  let { nestedTabValue } = useParams();
  let selectedtab = parseInt(nestedTabValue);
  const [value, setValue] = React.useState(selectedtab);
  const [typeOfwork, setTypeOfWork] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [dateValue, setDateValue] = React.useState("");
  const [loaderStatus, setLoaderStatus] = React.useState(false);
  const [applyFilter, setApplyFilter] = React.useState(applicableFilters[0]);
  const [filterByStatusData, setFilterByStatusData] = React.useState(applicableStatus[0]);
  const [filterData, setFilterData] = React.useState({ status: '', date:null, search: '',sort:'most_recent' })
 
  useEffect(() => {
    if(sessionStorage.getItem("filterData")){
      setFilterData(JSON.parse(sessionStorage.getItem("filterData")))
    }
  }, [])
  const handleChange = (event, newValue) => {
    setLoaderStatus(true)
    setTimeout(() => {
      setLoaderStatus(false)
    }, 1000);
    setValue(newValue);
    setApplyFilter(applicableFilters[newValue])
    setFilterByStatusData(applicableStatus[newValue])
    setFilterData({ status: '', date: null, search: '',sort:'most_recent' })
  };

  const handleFilter = (value, name) => {
    setFilterData({ ...filterData, [name]: value })
  }
  const handleReset = () => {
    setFilterData({ status: '', date:null, search: '',sort:'' })
  }
  const CustomTabs = (props) => {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            background: "#fff",
            padding: "10px 10px 0 10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p:2,
            
          }}
          rowGap={1}
        >
          <Grid
            item
            md={8}
            xs={12}
            sx={{
              display: "flex",
              alignItems: 'center',
              gap: { md: "10px", sm: "8px", xs: "4px" },
            }}
          >
            {applyFilter.status ?
              <FormControl fullWidth size='small' sx={{ width: 180 }} >
                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>
                  {applyFilter.statusHeading}
                </InputLabel>
                <Select value={filterData.status} name={'status'}
                  onChange={(e) => handleFilter(e.target.value, e.target.name)}
                  sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF' }} label="Type of work">
                  {filterByStatusData.map((item, index) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl> : ""}
            
              {applyFilter?.sort && <FormControl fullWidth size='small' sx={{ width: 160,ml:2 }}>
              <InputLabel  sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Sort</InputLabel>
                <Select  size="small" value={filterData.sort} name={'sort'}
                onChange={(e) => handleFilter(e.target.value, e.target.name)}
                sx={{ color: "#123FA9",fontSize: "14px", borderRadius: '6px',boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF',
                }} label="Sort">
                  <MenuItem value={'a_to_z'} sx={{ color: '#123FA9'}}>Company Name(A - Z)</MenuItem>
                  <MenuItem value={'z_to_a'} sx={{ color: '#123FA9'}}>Company Name(Z - A)</MenuItem>
                  <MenuItem value={'most_recent'} sx={{ color: '#123FA9'}}>Most Recent</MenuItem>
              </Select>
            </FormControl>}

            {/* Status */}
            {applyFilter.date ? <FormControl
              sx={{
                width:180,
                fontSize: "10px",
                background: "white",
                boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF',
                borderColor: "white",
                outline: "none",
                ml:1
              }}
            >
              <DatePicker
                disableFuture
                inputFormat="dd/MM/yyyy"
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                value={filterData.date }
                onChange={(newValue) => { handleFilter(newValue, 'date') }}
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                // disableFuture={applyFilter.disableFeatureDate}
              />
            </FormControl> : ""}
            {applyFilter.isResetApplicable ? <Box
              sx={{
                display: "flex",
                gap: "50px",
                alignItems: "center",
              }}
            >
              <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => handleReset()}>Reset</Typography>
            </Box> : ""}
          </Grid>
          <Grid item md={4} xs={12} sx={{ mx: "auto" }}>
            {applyFilter.search ? <Box
              sx={{
                float: "right",
                padding: "5px",
                display: "flex",
                alignItems: "center",
                border: " 1px solid #DBDFE9",
                borderRadius: "6px",
              }}
            >
              <Input
                name='search'
                onChange={(e) => handleFilter(e.target.value, e.target.name)}
                type="search"
                size="small"
                placeholder={applyFilter.searchHeading}
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "100%",
                }}
                inputProps={{
                  sx: {
                    height: { md: "25px", sm: "20px", xs: "4px" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    flexGrow: 1,
                    width: "inherit",
                  },
                }}
                disableUnderline
              />
              <SearchIcon cursor="pointer" style={{ flexGrow: 0 }} />
            </Box> : ""}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  return (
    <Box
      sx={{
        "& .css-19kzrtu": {
          padding: "0",
        },
      }}
    >
      
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ background: "#fff" }}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {TAB_HEADING.map((item, index) => {
            return (
              <Tab
                key={index}
                label={
                  <Typography
                    variant="span"
                    sx={{
                      color: '#6F80A9',
                      textTransform: "none",
                      letterSpacing: "-0.25px",
                      fontSize: { md: "14px", xs: "12px" },
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {CustomTabs()}

        <TenantEmpList dataType={"approved"} tabValue={value} filterData={filterData} />

      </TabPanel>
      <TabPanel value={value} index={1}>
        {CustomTabs()}
        <TenantEmpList dataType={"submitted"} tabValue={value} filterData={filterData} />

      </TabPanel>
      <TabPanel value={value} index={2}>
        {CustomTabs()}
        <TenantEmpList dataType={"Offboarded"} tabValue={value} filterData={filterData} />
      </TabPanel>
    </Box>
  );
};

export default TenantEmployee;
