import axios from "axios";
import { array } from "prop-types";
import { createAction } from "redux-actions";

const getToken = () => {
    const token = localStorage.getItem('userToken')
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return config;
}

export const sessionExpired = createAction("SESSION_EXPIRED");

const getAccessHistoryInitiate = createAction("GET_ACCESS_HISTORY_INITIATE")
const getAccessHistorySuccess = createAction("GET_ACCESS_HISTORY_SUCCESS")
const getAccessHistoryFailure = createAction("GET_ACCESS_HISTORY_FAILURE")

export const getAccessHistory = (data)=>{
    return(dispatch)=>{
        dispatch(getAccessHistoryInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllAccessReports`,data,getToken())
        .then((response)=>{
            if(response.data){
                dispatch(getAccessHistorySuccess(response.data.data_array))
            }else{
                dispatch(getAccessHistoryFailure([]))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getAccessHistoryFailure([]))
            }
        })
    }
}

const getAllAuditLogInitiate = createAction("GET_ALL_AUDIT_LOG_INITIATE")
const getAllAuditLogSuccess = createAction("GET_ALL_AUDIT_LOG_SUCCESS")
const getAllAuditLogFailure = createAction("GET_ALL_AUDIT_LOG_FAILURE")

export const getAllAuditLog = (data)=>{
    return(dispatch)=>{
        dispatch(getAllAuditLogInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllAuditLogsReports`,data,getToken())
        .then((response)=>{
            if(response.data){
                dispatch(getAllAuditLogSuccess(response.data.data_array))
            }else{
                dispatch(getAllAuditLogFailure([]))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getAllAuditLogFailure([]))
            }
        })
    }
}

const getAccessFilterInitiate = createAction("GET_ACCESS_FILTER_INITIATE")
const getAccessFilterSuccess = createAction("GET_ACCESS_FILTER_SUCCESS")
const getAccessFilterFailure = createAction("GET_ACCESS_FILTER_FAILURE")

export const getAccessFilter = ()=>{
    return(dispatch)=>{
        dispatch(getAccessFilterInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getReportFilterDetails`,null,getToken())
        .then((response)=>{
            if(response.data.response_json.response_status==1){
                dispatch(getAccessFilterSuccess(response.data.response_json.data_array))
            }else{
                dispatch(getAccessFilterFailure([]))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getAccessFilterFailure([]))
            }
        })
    }
}

const getEmpDataInitiate = createAction("GETEMP_BY_COMPANYID_INITIATE")
const getEmpDataSuccess = createAction("GETEMP_BY_COMPANYID_SUCCESS")
const getEmpDataFailure = createAction("GETEMP_BY_COMPANYID_FAILURE")

export const getEmpData = (data)=>{
    return(dispatch)=>{
        dispatch(getEmpDataInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getEmployeeListByCompanyId`,data,getToken())
        .then((response)=>{
            if(response.data.response_json.response_status==1){
                dispatch(getEmpDataSuccess(response.data.response_json.data_array.employeeArrayList))
            }else{
                dispatch(getEmpDataFailure())
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getEmpDataFailure(err.message))
            }
        })
    }
}

const getEmpReportsFilterInitiate = createAction("GET_EMPREPORT_FILTERDATA_INITIATE")
const getEmpReportsFilterSuccess = createAction("GET_EMPREPORT_FILTERDATA_SUCCESS")
const getEmpReportsFilterFailure = createAction("GET_EMPREPORT_FILTERDATA_FAILURE")

export const getEmpReportsFilter = ()=>{
    return(dispatch)=>{
        dispatch(getEmpReportsFilterInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getFilterMasterDataForEmployeesReport`,null,getToken())
        .then((response)=>{
            dispatch(getEmpReportsFilterSuccess(response.data.data_array))
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getEmpReportsFilterFailure(err.message))
            }
        })
    }
}

const getEmployeeReportInitiate = createAction("GET_EMPLOYEE_REPORT_INITIATE")
const getEmployeeReportSuccess = createAction("GET_EMPLOYEE_REPORT_SUCCESS")
const getEmployeeReportFailure = createAction("GET_EMPLOYEE_REPORT_FAILURE")

export const getEmployeeReport = (data)=>{
    return(dispatch)=>{ 
        dispatch(getEmployeeReportInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllEmployeesReport`,data,getToken())
        .then((response)=>{
            if(response.data){
                dispatch(getEmployeeReportSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getEmployeeReportFailure(err.message))
            }
        })
    }
}

const getFaultReportFilterDataInitiate = createAction('GET_FAULTREPORTS_FILTERDATA_INITRIATE')
const getFaultReportFilterDataSuccess = createAction('GET_FAULTREPORTS_FILTERDATA_SUCCESS')
const getFaultReportFilterDataFailure = createAction('GET_FAULTREPORTS_FILTERDATA_FAILURE')

export const getFaultReportsFilterData = ()=>{
    return(dispatch)=>{
        dispatch(getFaultReportFilterDataInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/faultReportsMasterDatas`,null,getToken())
        .then((response)=>{
            dispatch(getFaultReportFilterDataSuccess(response.data.response_json.data_array))
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getFaultReportFilterDataFailure(err.message))
            }
        })
    }
}

const getReportsFaultInitiate = createAction("GET_REPORTS_FAULT_REPORTS_INITIATE")
const getReportsFaultSuccess = createAction("GET_REPORTS_FAULT_REPORTS_SUCCESS")
const getReportsFaultFailure = createAction("GET_REPORTS_FAULT_REPORTS_FAILURE")

export const getReportsFault = (data)=>{
    return(dispatch)=>{
        dispatch(getReportsFaultInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getFaultReportDetailsForReports`,data,getToken())
        .then((response)=>{
            if(response.data.response_json){
                dispatch(getReportsFaultSuccess(response.data.response_json))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getReportsFaultFailure([]))
            }
        })
    }
}

const getServiceReportsInitiate = createAction("GET_SERVICE_REPORTS_INITIATE")
const getServiceReportsSuccess = createAction("GET_SERVICE_REPORTS_SUCCESS")
const getServiceReportsFailure = createAction("GET_SERVICE_REPORTS_FAILURE")

export const getServiceReports = (data)=>{
    return(dispatch)=>{
        dispatch(getServiceReportsInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getServiceRequestReport`,data,getToken())
        .then((response)=>{
            if(response.data.response_json){
                dispatch(getServiceReportsSuccess(response.data.response_json))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getServiceReportsFailure([]))
            }
        })
    }
}

const getLightReportsInitiate = createAction("GET_LIGHT_REPORTS_INITIATE")
const getLightReportsSuccess = createAction("GET_LIGHT_REPORTS_SUCCESS")
const getLightReportsFailure = createAction("GET_LIGHT_REPORTS_FAILURE")

export const getLightReports = (data)=>{
    return(dispatch)=>{
        dispatch(getLightReportsInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getLightBulbReplacementReport`,data,getToken())
        .then((response)=>{
            if(response.data.response_json.response_status==1){
                dispatch(getLightReportsSuccess(response.data.response_json.data_array))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getLightReportsFailure([]))
            }
        })
    }
}

const getAirConReportsInitiate = createAction("GET_AIRCON_REPORTS_INITIATE")
const getAirReportsSuccess = createAction("GET_AIRCON_REPORTS_SUCCESS")
const getAirReportsFailure = createAction("GET_AIRCON_REPORTS_FAILURE")

export const getAirConReports = (data)=>{
    return(dispatch)=>{
        dispatch(getAirConReportsInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAirconReportList`,data,getToken())
        .then((response)=>{
            if(response.data.response_status=='success'){
                dispatch(getAirReportsSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getAirReportsFailure([]))
            }
        })
    }
}


const getCompanyReportsInitiate = createAction("GET_COMPANY_INITIATE")
const getCompanyReportsSuccess = createAction("GET_COMPANY_SUCCESS")
const getCompanyReportsFailure = createAction("GET_COMPANY_FAILURE")

export const getCompanyReports = (data)=>{
    return(dispatch)=>{
        dispatch(getCompanyReportsInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getCompanyReport`,data,getToken())
        .then((response)=>{
            if(response.data){
                dispatch(getCompanyReportsSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status=="401"){
                dispatch(sessionExpired())
            }else{
                dispatch(getCompanyReportsFailure([]))
            }
        })
    }
}





