import { createAction } from "redux-actions";
import ApiService from "../../../AxiosConfig/ApiService";
import { sessionExpired } from "../faultreportAction";
 
export const reduxFacilityBookingRoomDataUpdate = createAction('REDUX_FACILITY_BOOKING_ROOM_DATA_UPDATE')
export const reduxFacilityBookingRoomDataNull = createAction('REDUX_FACILITY_BOOKING_ROOM_DATA_NULL')

export const reduxFacilityBookingRoomDataUpdateAction = (data) => dispatch => dispatch(reduxFacilityBookingRoomDataUpdate(data));
export const reduxFacilityBookingRoomDataNullAction = (data) => dispatch => dispatch(reduxFacilityBookingRoomDataNull());


export const facilityBookingPaymentStatusUpdate = createAction('FACILITY_BOOKING_PAYMENT_STATUS_UPDATE');
export const facilityBookingPaymentStatusNull = createAction('FACILITY_BOOKING_PAYMENT_STATUS_NULL');

export const facilityBookingPaymentStatusUpdateAction = (params) => dispatch => {
    dispatch(facilityBookingPaymentStatusUpdate(params));
}

export const facilityBookingPaymentStatusNullAction = () => dispatch => dispatch(facilityBookingPaymentStatusNull());

export const getAvailableRoomListFacilityBookingInitiate = createAction('GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_INITIATE');
export const getAvailableRoomListFacilityBookingSuccess = createAction('GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_SUCCESS');
export const getAvailableRoomListFacilityBookingFailure = createAction('GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_FAILURE');
export const getAvailableRoomListFacilityBookingNull = createAction('GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_NULL');

export const getAvaiiableRoomListFacilityBookingNullAction = () => dispatch => dispatch(getAvailableRoomListFacilityBookingNull())  


export const getAvailableRoomListFacilityBookingAction = (params) => {
    return async (dispatch) => {
         dispatch(getAvailableRoomListFacilityBookingInitiate());
        try {
            const response = await ApiService().post('/getAvailableRoomList',params);
            console.log(response);
            if(response.data?.response_status === 1){
                 dispatch(getAvailableRoomListFacilityBookingSuccess(response.data))
                }else{
                dispatch(getAvailableRoomListFacilityBookingFailure(response.data))

                }
            } catch (error) {
                if(error.status===401){
                    dispatch(sessionExpired('Session Expired'));
                  }else{
                      dispatch(getAvailableRoomListFacilityBookingFailure(error.response.data))
                    }
         }
    }
}

export const getSingleRoomListByIdInitiate = createAction('GET_SINGLE_ROOM_LIST_BY_ID_INITIATE');
export const getSingleRoomListByIdSuccess = createAction('GET_SINGLE_ROOM_LIST_BY_ID_SUCCESS');
export const getSingleRoomListByIdFailure = createAction('GET_SINGLE_ROOM_LIST_BY_ID_FAILURE');
export const getSingleRoomListByIdNull = createAction('GET_SINGLE_ROOM_LIST_BY_ID_NULL');

export const getSingleRoomDetailsByIdNullAction = () => dispatch => dispatch(getSingleRoomListByIdNull());

export const getSingleRoomListByIdAction = (params) => {
    return async (dispatch) => {
        dispatch(getSingleRoomListByIdInitiate());
        try {
            const response = await ApiService().post('/getSingleRoomDetailsById', params);
            console.log(response);

            if(response.data?.response_status === 1){
                dispatch(getSingleRoomListByIdSuccess(response.data));
            }else{
                dispatch(getSingleRoomListByIdFailure(response.data));

            }
        } catch (error) {
             if(error.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                  dispatch(getSingleRoomListByIdFailure(error.response.data));
                }

        }
    }
}


export const facilityBookingRedirectPaymentInitiate = createAction('FACILITY_BOOKING_REDIRECT_PAYMENT_INITIATE');
export const facilityBookingRedirectPaymentSuccess = createAction('FACILITY_BOOKING_REDIRECT_PAYMENT_SUCCESS');
export const facilityBookingRedirectPaymentFailure = createAction('FACILITY_BOOKING_REDIRECT_PAYMENT_FAILURE');
export const facilityBookingRedirectPaymentNull = createAction('FACILITY_BOOKING_REDIRECT_PAYMENT_NULL');

export const facilityBookingRedirectPaymenNullAction = () => dispatch => dispatch(facilityBookingRedirectPaymentNull());

export const facilityBookingRedirectPaymentAction = (params) => async (dispatch) => {
    dispatch(facilityBookingRedirectPaymentInitiate());
    try {
        const response = await ApiService().post('/redirectToPaymentGateway', params);
        console.log(response);

        if(response.data?.response_status === 1){
            dispatch(facilityBookingRedirectPaymentSuccess(response.data));
        }else{
            dispatch(facilityBookingRedirectPaymentFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(facilityBookingRedirectPaymentFailure(error.response.data));
            }

    }
}

export const getFacilityBookingDetailsByIdInitiate = createAction('GET_FACILITY_BOOKING_DETAILS_BY_ID_INITIATE');
export const getFacilityBookingDetailsByIdSuccess = createAction('GET_FACILITY_BOOKING_DETAILS_BY_ID_SUCCESS');
export const getFacilityBookingDetailsByIdFailure = createAction('GET_FACILITY_BOOKING_DETAILS_BY_ID_FAILURE');
export const getFacilityBookingDetailsByIdNull = createAction('GET_FACILITY_BOOKING_DETAILS_BY_ID_NULL');

export const getfacilityBookingDetailsByIdNullAction = () => dispatch => dispatch(getFacilityBookingDetailsByIdNull());

export const getfacilityBookingDetailsByIdAction = (params) => async (dispatch) => {
    dispatch(getFacilityBookingDetailsByIdInitiate());
    try {
        const response = await ApiService().post('/getRoomBookingDetailsById', params);
        console.log(response);

        if(response.data?.response_status === 1){
            dispatch(getFacilityBookingDetailsByIdSuccess(response.data));
        }else{
            dispatch(getFacilityBookingDetailsByIdFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getFacilityBookingDetailsByIdFailure(error.response.data));
            }

    }
}

export const addNonWorkingHourRoomBookingInitiate = createAction('ADD_NON_WORKING_HOUR_ROOM_BOOKING_INITIATE');
export const addNonWorkingHourRoomBookingSuccess = createAction('ADD_NON_WORKING_HOUR_ROOM_BOOKING_SUCCESS');
export const addNonWorkingHourRoomBookingFailure = createAction('ADD_NON_WORKING_HOUR_ROOM_BOOKING_FAILURE');
export const addNonWorkingHourRoomBookingNull = createAction('ADD_NON_WORKING_HOUR_ROOM_BOOKING_NULL');

export const addNonWorkingHourRoomBookingNullAction = () => dispatch => dispatch(addNonWorkingHourRoomBookingNull());

export const addNonWorkingHourRoomBookingAction = (params) => async (dispatch) => {
    dispatch(addNonWorkingHourRoomBookingInitiate());
    try {
        const response = await ApiService().post('/addNonWorkingHourRoomBooking', params);
        console.log(response);

        if(response.data?.response_status === 1){
            dispatch(addNonWorkingHourRoomBookingSuccess(response.data));
        }else{
            dispatch(addNonWorkingHourRoomBookingFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(addNonWorkingHourRoomBookingFailure(error.response.data));
            }
    }
}

export const getRoomBookingDetailsInitiate = createAction('GET_ROOM_BOOKING_DETAILS_INITIATE');
export const getRoomBookingDetailsSuccess = createAction('GET_ROOM_BOOKING_DETAILS_SUCCESS');
export const getRoomBookingDetailsFailure = createAction('GET_ROOM_BOOKING_DETAILS_FAILURE');
export const getRoomBookingDetailsNull = createAction('GET_ROOM_BOOKING_DETAILS_NULL');

export const getRoomBookingListNullAction = () => dispatch => dispatch(getRoomBookingDetailsNull());

export const getRoomBookingListAction = (params) => async (dispatch) => {
    dispatch(getRoomBookingDetailsInitiate());
    try {
        const response = await ApiService().post('/getRoomBookingDetailsList', params);
        if(response.data?.response_status === 1){
            dispatch(getRoomBookingDetailsSuccess(response.data));
        }else{
            dispatch(getRoomBookingDetailsFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getRoomBookingDetailsFailure(error.response.data));
            }
    }
}
export const getAllBookingStatusInitiate = createAction('GET_ALL_BOOKING_STATUS_INITIATE');
export const getAllBookingStatusSuccess = createAction('GET_ALL_BOOKING_STATUS_SUCCESS');
export const getAllBookingStatusFailure = createAction('GET_ALL_BOOKING_STATUS_FAILURE');
export const getAllBookingStatusNull = createAction('GET_ALL_BOOKING_STATUS_NULL');

export const getAllBookingStatusNullAction = () => dispatch => dispatch(getAllBookingStatusNull());

export const getAllBookingStatusAction = () => async (dispatch) => {
    dispatch(getAllBookingStatusInitiate());
    try {
        const response = await ApiService().get('/getAllBookingStatus');
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getAllBookingStatusSuccess(response.data));
        }else{
            dispatch(getAllBookingStatusFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getAllBookingStatusFailure(error.response.data));
            }
    }
}
export const getTecFeatureListInitiate = createAction('GET_TEC_FEATURE_LIST_INITIATE');
export const getTecFeatureListSuccess = createAction('GET_TEC_FEATURE_LIST_SUCCESS');
export const getTecFeatureListFailure = createAction('GET_TEC_FEATURE_LIST_FAILURE');
export const getTecFeatureListNull = createAction('GET_TEC_FEATURE_LIST_NULL');

export const getTecFeatureListNullAction = () => dispatch => dispatch(getTecFeatureListNull());

export const getTecFeatureListAction = () => async (dispatch) => {
    dispatch(getTecFeatureListInitiate());
    try {
        const response = await ApiService().get('/getTecFeatureList');
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getTecFeatureListSuccess(response.data));
        }else{
            dispatch(getTecFeatureListFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getTecFeatureListFailure(error.response.data));
            }
    }
}


export const getEditContactDetailsInitiate = createAction('GET_EDIT_CONTACT_DETAILS_INITIATE');
export const getEditContactDetailsSuccess = createAction('GET_EDIT_CONTACT_DETAILS_SUCCESS');
export const getEditContactDetailsFailure = createAction('GET_EDIT_CONTACT_DETAILS_FAILURE');
export const getEditContactDetailsNull = createAction('GET_EDIT_CONTACT_DETAILS_NULL');

export const getEditContactDetailsNullAction = () => dispatch => dispatch(getEditContactDetailsNull());

export const getEditContactDetailsAction = () => async (dispatch) => {
    dispatch(getEditContactDetailsInitiate());
    try {
        const response = await ApiService().get('/getEditContactDetails');
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getEditContactDetailsSuccess(response.data));
        }else{
            dispatch(getEditContactDetailsFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getEditContactDetailsFailure(error.response.data));
            }
    }
}

export const getAllRoomListInitiate = createAction('GET_ALL_ROOM_LIST_INITIATE');
export const getAllRoomListSuccess = createAction('GET_ALL_ROOM_LIST_SUCCESS');
export const getAllRoomListFailure = createAction('GET_ALL_ROOM_LIST_FAILURE');
export const getAllRoomListNull = createAction('GET_ALL_ROOM_LIST_NULL');

export const getAllRoomListNullAction = () => dispatch => dispatch(getAllRoomListNull());

export const getAllRoomListAction = () => async (dispatch) => {
    dispatch(getAllRoomListInitiate());
    try {
        const response = await ApiService().get('/getAllRoomsList');
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getAllRoomListSuccess(response.data));
        }else{
            dispatch(getAllRoomListFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getAllRoomListFailure(error.response.data));
            }
    }
}

export const getRoomBookingTransactionDetailsListInitiate = createAction('GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_INITIATE');
export const getRoomBookingTransactionDetailsListSuccess = createAction('GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_SUCCESS');
export const getRoomBookingTransactionDetailsListFailure = createAction('GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_FAILURE');
export const getRoomBookingTransactionDetailsListNull = createAction('GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_NULL');

export const getRoomBookingTransactionDetailsListNullAction = () => dispatch => dispatch(getRoomBookingTransactionDetailsListNull());

export const getRoomBookingTransactionDetailsListAction = (params) => async (dispatch) => {
    dispatch(getRoomBookingTransactionDetailsListInitiate());
    try {
        const response = await ApiService().post('/getRoomBookingTransactionDetailsList',params);
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getRoomBookingTransactionDetailsListSuccess(response.data));
        }else{
            dispatch(getRoomBookingTransactionDetailsListFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getRoomBookingTransactionDetailsListFailure(error.response.data));
            }
    }
}

export const updateWatchStatusInitiate = createAction("UPDATE_FACILITYWATCHSTATUS_INITIATE")
export const updateWatchStatusSuccess = createAction("UPDATE_FACILITYWATCHSTATUS_SUCCESS")
export const updateWatchStatusFailure = createAction("UPDATE_FACILITYWATCHSTATUS_FAILURE")
export const updateWatchStatusNull = createAction('UPDATE_FACILITYWATCHSTATUS_NULL');

export const updateRoomBookingWatchStatusNullAction = () => dispatch => dispatch(updateWatchStatusNull());

export const updateRoomBookingWatch = (params) => async (dispatch) => {
    dispatch(updateWatchStatusInitiate());
    try {
        const response = await ApiService().post('/updateRoomBookingWatch',params);
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(updateWatchStatusSuccess(response.data));
        }else{
            dispatch(updateWatchStatusFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(updateWatchStatusFailure(error.response.data));
            }
    }
}

export const getTecMemberDetailsInitiate = createAction("GET_TEC_MEMBER_DETAILS_INITIATE")
export const getTecMemberDetailsSuccess = createAction("GET_TEC_MEMBER_DETAILS_SUCCESS")
export const getTecMemberDetailsFailure = createAction("GET_TEC_MEMBER_DETAILS_FAILURE")
export const getTecMemberDetailsNull = createAction('GET_TEC_MEMBER_DETAILS_NULL');

export const getTecMemberDetailsNullAction = () => dispatch => dispatch(getTecMemberDetailsNull());

export const getTecMemberDetailsAction = (params) => async (dispatch) => {
    dispatch(getTecMemberDetailsInitiate());
    try {
        const response = await ApiService().post('/getTecMemberDetails', params);
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getTecMemberDetailsSuccess(response.data));
        }else{
            dispatch(getTecMemberDetailsFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getTecMemberDetailsFailure(error.response.data));
            }
    }
}