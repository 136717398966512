import { Tabs, Tab, Box, Grid, IconButton, ListItem, ListItemText, Typography, Select, MenuItem, Button, Menu, Stepper, Step, StepLabel, OutlinedInput } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as SearchIcon } from '../../../Assets/icons/SearchIcon.svg'
import { Upcomming_Visitor_All_Tab, Upcomming_Visitor_Invited_Tab } from '../../../components/Data/Data';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import UpcommingVisitorsList from './UpcommingVisitorsList';
import { useParams } from 'react-router-dom'
import { styled } from '@mui/system';

const StyledTab = styled(Tab)({
    color:"#6F80A9",
    textTransform:"capitalize",
    fontSize:'14px'
})

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const getTabName = (currentTabValue) => {
    if (currentTabValue === 0) {
        return 'All';
    } else if (currentTabValue === 1) {
        return 'Invited';
    } else {
        return 'Special_access';
    }

}
const UpcommingVisitorsTabs = ({ getVisitorsList, visitors, recordCount,receptionStateToInitial }) => {
    let { tabValue } = useParams();
    const [tabProps, setTabProps] = useState({ currentTab: parseInt(tabValue), getType: getTabName(parseInt(tabValue)), limit: 10, offset: 0, search: '', date: -1, totalInvites: 0,via:"Upcoming" });
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedDay, setSelectedDay] = useState(0);
    const [tableProps, setTableProps] = useState({ columns: [], datas: [], totalDatas: 0 });
    const [allDatasSelected, setAllDatasSelected] = useState(false)
    const filterOpen = Boolean(menuAnchorEl);

    useEffect(() => {
        getVisitorsList(tabProps)
        getTableColumnNames(tabProps.currentTab)
        return()=>{
            receptionStateToInitial()
        }
    }, [tabProps])

    useEffect(() => {
        if (recordCount) {
            let newDatas = tableProps;
            newDatas['datas'] = visitors;
            newDatas['totalDatas'] = recordCount;
            setTableProps({ ...newDatas })
            getTableColumnNames(tabProps.currentTab)
        }
    }, [visitors, recordCount])

    useEffect(()=>{
        returnTabPanel()
    },[tableProps])

    const handleClick = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setMenuAnchorEl(null);
    };
    const handleChange = (event, newValue) => {
        setTableProps({ columns: [], datas: [], totalDatas: 0 })
        setTabProps({ ...tabProps, ['getType']: event.target.name, ['currentTab']: newValue,offset:0 })
    };
    const handlePagignation = (event, value) => {
        setTabProps({ ...tabProps, ['offset']: value - 1 });
    };
    const getTableColumnNames = (selectedTab) => {
        let newColumns;
        if (parseInt(selectedTab) === 0) {
            newColumns = Upcomming_Visitor_All_Tab;
        } else {
            newColumns = Upcomming_Visitor_Invited_Tab;
        }
        setTableProps({ ...tableProps, columns: newColumns })

    }
    const handleFilterData = (value, name) => {
        setTabProps({ ...tabProps, [name]: value,offset:0 });
    }
    const handleAllSelected = () => {
        setAllDatasSelected(!allDatasSelected);
    }
    const handleSelected = () => {
        // console.log(!allDatasSelected);
    }
    const filterProps = () => {
        return (
            <Grid container sx={{p:2}}>
                <Grid itex xs={10}>
                    <Button endIcon={<ExpandMoreIcon />}
                        id="basic-button"
                        aria-controls={filterOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={filterOpen ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px', boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                        Day
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={menuAnchorEl}
                        open={filterOpen}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Stepper activeStep={tabProps.date} alternativeLabel connector>
                            {[1, 2, 3, 4, 5, 6].map((label, index) => (
                                <Step key={label} onClick={() => handleFilterData(index+1, 'date')} >
                                    <StepLabel>{label}</StepLabel>      
                                </Step>
                            ))}
                        </Stepper>
                    </Menu>
                </Grid> 
                <Grid itex xs={2}>
                    <OutlinedInput placeholder="Search..."
                        name='search'
                        onChange={(e) => handleFilterData(e.target.value, e.target.name)}
                        endAdornment={<SearchIcon />} size="small" />

                </Grid>
            </Grid>
        )

    }

    const returnTabPanel = ()=>{
        return(
            <>
            <TabPanel value={tabProps.currentTab} index={0}>
                {filterProps()}
                <UpcommingVisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
            <TabPanel value={tabProps.currentTab} index={1} >
                {filterProps()}
                <UpcommingVisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
            <TabPanel value={tabProps.currentTab} index={2}>
                {filterProps()}
                <UpcommingVisitorsList
                    handlePagignation={handlePagignation}
                    tabProps={tabProps}
                    tableColumnsAndDatas={tableProps}
                    allselected={allDatasSelected}
                    handleAllSelected={handleAllSelected}
                    handleSelected={handleSelected} />
            </TabPanel>
            </>
        )
    }
    return (
        <Box sx={{ width: '100%', background: '#FFFFFF', borderRadius: '8px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Tabs value={tabProps.currentTab} onChange={handleChange} aria-label="basic tabs example">
                    <StyledTab sx={{color:"#6F80A9"}} label="All" name='All' {...a11yProps(0)} />
                    <StyledTab label="Invited" name='Invited' {...a11yProps(1)} />
                    <StyledTab label="Special Access" name='Special_access' {...a11yProps(2)} />
                </Tabs>
                {/* <Button variant='contained' sx={{ alignSelf: 'center', mr: 2,textTransform:"capitalize" }}>Download</Button> */}
            </Box>
            {returnTabPanel()}
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        visitors: state.ReceptionReducer.visitorsData,
        recordCount: state.ReceptionReducer.recordCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getVisitorsList: (data) => { dispatch(receptionActions.getVisitorsList(data)) },
        receptionStateToInitial: () => {dispatch(receptionActions.receptionStateToInitial())},

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpcommingVisitorsTabs)