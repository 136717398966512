import LoginReducer from "../loginReducer";
import UserReducer from "../userReducer";
import CommonReducer from "../commonReducer";
import SuperAdmin from "../superadminReducer";
import FaultReportReducer from '../faultreportReducer'
import AnnouncementReducer from '../announcementReducer'
import Service_Req_Reducer from '../service_requestReducer'
import GlobalSearchReducer from '../globalSearchReducer'
import ReceptionReducer from '../visitorsReducer'
import ImportantContactReducer from '../importantcontactReducer'
import BMSStaffReducer from '../bmsStaffReducer'
import eAndpReducer from '../EventsAndPromotionReducer'
import DashboardReducer from "../DashboardReducer"
import ReportsReducer from "../reportReducer"
import { FacilityBookingReducer } from "../facilityBookingReducer";
import { SustainableListReducer  } from "../SustainabilityReducer";
import { ServiceRequestFormsReducer } from "../service_request_forms_reducer";
import { SnackBarReducer } from "../snackbarReducer";
import faqReducer from '../helpReducer'


const combinedReducers = {
  LoginReducer: LoginReducer,
  UserReducer: UserReducer,
  CommonReducer: CommonReducer,
  SuperAdmin: SuperAdmin,
  FaultReportReducer:FaultReportReducer,
  AnnouncementReducer:AnnouncementReducer,
  Service_Req_Reducer:Service_Req_Reducer,
  GlobalSearchReducer:GlobalSearchReducer,
  ReceptionReducer:ReceptionReducer,
  ImportantContactReducer:ImportantContactReducer,
  BMSStaffReducer:BMSStaffReducer,
  eAndpReducer:eAndpReducer,
  DashboardReducer:DashboardReducer,
  ReportsReducer:ReportsReducer,
  FacilityBookingReducer: FacilityBookingReducer,
  SustainableListReducer: SustainableListReducer,
  ServiceRequestFormsReducer: ServiceRequestFormsReducer,
  SnackBarReducer: SnackBarReducer,
  faqReducer: faqReducer
};
export default combinedReducers;
