import React, { useState, useEffect } from 'react'
import { Autocomplete, Box, Button, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { ReactComponent as DatePickerIcon } from '../../../Assets/icons/DatePickerIcon.svg';
import { connect } from 'react-redux';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import ToastAlert from '../../common/Alert';
import { getUTCDateFormat, getYYYYMMDDFormat, preventDatePickerKeyboard } from '../../../Utils/utils'
import Loader from '../../common/Loader';
import moment from 'moment/moment';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};
const SpecialAccess = ({ inviteAuthorities, getInviteAuthorities, specialInviteStatus, receptionStateToInitial, getOrganizationList, receptionCompanies, inviteSpecialAccess }) => {
    const navigate = useNavigate();
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [visitorDetails, setVisitorDetails] = useState({
        company_id: '',
        reason_for_visiting: '',
        passToGenValue: '',
        date: null,
        approved_by: ''
    })
    const [errorDetails, setErrorDetails] = useState({
        company_id: '',
        reason_for_visiting: '',
        passToGenValue: '',
        date: '',
        approved_by: ''
    })
    const [loaderStatus,setLoaderStatus] = useState(false)
    const [displayFrame,setDisplayFrame] = useState(false)
    const [url,setUrl] = useState(false)
    const [company,setCompany] = useState([])

    useEffect(() => {
        getOrganizationList();
        getInviteAuthorities();
    }, [])

    useEffect(()=>{
        if(receptionCompanies?.length>0){
            let arr = receptionCompanies;
            for(let i=0;i<receptionCompanies.length;i++){
                arr[i].label = arr[i].company_name
            }
            setCompany([...arr])
        }
    },[receptionCompanies])

    useEffect(() => {
        if (specialInviteStatus !== null) {
            if (specialInviteStatus.response_status === 'success') {
                setLoaderStatus(false)
                getOrganizationList();
                setVisitorDetails({
                    company_id: '',
                    reason_for_visiting: '',
                    passToGenValue: '',
                    date: null,
                    approved_by: ''
                })
                setSnackbarStatus({ message: specialInviteStatus.response_message, status: true, color: 'green', icon: 'close' })
                setTimeout(() => {
                    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
                    receptionStateToInitial();
                }, 5000);
                if(specialInviteStatus.response_data.url !=null && specialInviteStatus.response_data.url!=""){
                    setUrl(specialInviteStatus.response_data.url)
                    setDisplayFrame(true)
                }
            } else {
                setLoaderStatus(false)
                setSnackbarStatus({ message: specialInviteStatus.response_message, status: true, color: 'red', icon: 'close' })
                setTimeout(() => {
                    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
                    receptionStateToInitial();
                }, 5000);
            }
        }
    }, [specialInviteStatus])

    const handleInput = (event) => {
        const { name, value } = event.target;
        setVisitorDetails({ ...visitorDetails, [name]: value })
        setErrorDetails({ ...errorDetails, [name]: '' })
    }

    const handleVisitor = (visitingBy) => {

        let validation = validate()
        if (validation) {
            let datasToSubmit = {
                company_id: visitorDetails.company_id,
                reason: visitorDetails.reason_for_visiting,
                approved_by: visitorDetails.approved_by,
                date: getYYYYMMDDFormat(visitorDetails.date),
                // date: getUTCDateFormat(visitorDetails.date),
                pass_count: visitorDetails.passToGenValue
            }
            inviteSpecialAccess(datasToSubmit)
            setLoaderStatus(true)
            setTimeout(()=>{
                setLoaderStatus(false)
            },30000)
        }
    }

    const validate = () => {
        let errors = {}, errorStatus = [];

        if (visitorDetails.company_id === '' || visitorDetails.company_id==undefined) {
            errorStatus.push(false)
            errors['company_id'] = 'Select Visiting Company!'
        } else {
            errorStatus.push(true)
            errors['company_id'] = ''
        }

        if (visitorDetails.reason_for_visiting === '') {
            errorStatus.push(false)
            errors['reason_for_visiting'] = 'Reason required!'
        } else {
            errorStatus.push(true)
            errors['reason_for_visiting'] = ''
        }

        if (visitorDetails.approved_by === '') {
            errorStatus.push(false)
            errors['approved_by'] = 'Select approving authority!'
        } else {
            errorStatus.push(true)
            errors['approved_by'] = ''
        }

        if (visitorDetails.date === '' || visitorDetails.date==null) {
            errorStatus.push(false)
            errors['date'] = 'Date required!'
        } else {
            errorStatus.push(true)
            errors['date'] = ''
        }

        if (visitorDetails.passToGenValue === '') {
            errorStatus.push(false)
            errors['passToGenValue'] = 'How many passes required!'
        } else if(visitorDetails.passToGenValue<1){
            errorStatus.push(false)
            errors['passToGenValue'] = 'Minimum 1 pass is required'
        }
        else if (visitorDetails.passToGenValue > 50) {
            errorStatus.push(false)
            errors['passToGenValue'] = 'Maximum 50 passes only allowed'
        } else {
            errorStatus.push(true)
            errors['passToGenValue'] = ''
        }
        let isError = errorStatus.includes(false);
        setErrorDetails({ ...errors });
        return !isError;

    }

    const handleDate = (e, datafrom) => {
        setVisitorDetails({ ...visitorDetails, [datafrom]: e })
        setErrorDetails({
            ...errorDetails,
            [datafrom]: ''
        })
    }

    return (
        <>
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
            {loaderStatus && <Loader status={loaderStatus} /> }
            
            <Grid container sx={{ p: 25, pb: 0, pt: 7, background: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                    <ArrowIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                    <Typography sx={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '26px',
                        color: "#0F2C6F",
                        my:2
                    }}>Generate Special Access Pass</Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', }}>
                    <Box sx={{ width: '100%', background: '#FFFFFF', borderRadius: '8px',p:2 }}>
                        <Grid container rowSpacing={2} sx={{ p: 1 }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ color: '#0F2C6F', fontFamily: 'Inter', fontSize: '18px', fontWeight: 500 }}>
                                    Access Details
                                </Typography>
                                <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400,mt:1 }}>
                                    Please enter the below details in order to generate QR. Staff with access to this component are reminded not to only use this service only with verbal instruction/ approval from BMS Executive and Managers.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Reason to Generate Special Access
                                </Typography>
                                <OutlinedInput
                                    value={visitorDetails.reason_for_visiting}
                                    name='reason_for_visiting'
                                    onChange={(e) => handleInput(e)} fullWidth placeholder='Reason for special access' />
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.reason_for_visiting}
                                </Typography>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Requestor Company
                                </Typography>
                                <Autocomplete noOptionsText={<Typography>No companies available</Typography>} options={company} renderInput={(params)=> <TextField {...params} />} renderOption={(props,option)=><Typography {...props}>{`${option.company_name}`}</Typography>} 
                                    onChange={(e,value)=>{
                                        setVisitorDetails({
                                            ...visitorDetails,
                                            company_id:value?.company_id
                                        })
                                        setErrorDetails({
                                            ...errorDetails,
                                            company_id:''
                                        })
                                    }} />
                                {/* <Select value={visitorDetails.company_id} fullWidth name='company_id' onChange={(e) => handleInput(e)} MenuProps={MenuProps}>
                                    {receptionCompanies.map((item, index) => (
                                        <MenuItem key={index} value={item.company_id}>{`${item.company_name}   (${item.location})`}</MenuItem>
                                    ))}
                                </Select> */}
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.company_id}
                                </Typography>

                            </Grid>
                            <Grid item xs={3.5} sx={{ pr: 1, mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Approved by
                                </Typography>
                                <Select fullWidth name='approved_by' onChange={(e) => handleInput(e)} value={visitorDetails.approved_by}>
                                    {inviteAuthorities.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                    
                                </Select>
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.approved_by}
                                </Typography>
                            </Grid>
                            <Grid item xs={3.5} sx={{ mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Date
                                </Typography>
                                <DesktopDatePicker
                                    inputFormat='dd/MM/yyyy'
                                    components={{
                                        OpenPickerIcon:DatePickerIcon
                                    }}
                                    label=""
                                    disablePast
                                    value={visitorDetails?.date }
                                    onChange={(newValue) => handleDate(newValue, 'date')}
                                    renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                                    minDate={new Date().setDate(new Date().getDate() + 1)}
                                    maxDate={new Date().setDate(new Date().getDate() + 60)}
                                />
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.date}
                                </Typography>
                            </Grid>
                            <Grid item xs={5} sx={{ mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }} />
                            <Grid item xs={4} sx={{ pr: 1, mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Pass to Generate
                                </Typography>
                                <OutlinedInput
                                    type='number'
                                    inputProps={{
                                        min: 0,
                                        max: 50
                                    }}
                                    value={visitorDetails.passToGenValue}
                                    name='passToGenValue'
                                    onChange={(e) => handleInput(e)} fullWidth placeholder='Maximum of 50 passes at time' />
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.passToGenValue}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mt: 5, mb: 5 }}>
                    <Button variant='contained' sx={{ height: '50px', background: '#123FA9',"&:hover":{background: '#123FA9'},textTransform:"capitalize" }} onClick={() => handleVisitor('printPass')}>
                        Print Pass
                    </Button>
                </Grid>
            </Grid >
            {displayFrame &&  
                <Box >
                    <iframe  id="print" height={300} style={{visibility:"hidden"}} src={url} />
                </Box>
            }
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        receptionCompanies: state.ReceptionReducer.receptionCompanies,
        specialInviteStatus: state.ReceptionReducer.specialInviteStatus,
        inviteAuthorities: state.ReceptionReducer.inviteAuthorities
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        inviteSpecialAccess: (data) => {
            dispatch(receptionActions.inviteSpecialAccess(data));
        },
        receptionStateToInitial: () => {
            dispatch(receptionActions.receptionStateToInitial());
        },
        getOrganizationList: () => {
            dispatch(receptionActions.getOrganizationList());
        },
        getInviteAuthorities: () => {
            dispatch(receptionActions.getInviteAuthorities());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SpecialAccess)