import { handleActions } from 'redux-actions';
const GlobalSearchReducer = {
    GlobalSearchData: [],
    recordCount:0
};
const reducer = handleActions({
    GLOBAL_SEARCH_INITIATE: (state) => {
        return {
            ...state,
        };
    },
    GLOBAL_SEARCH_SUCCESS: (state,action) => {
        return {
            ...state,
            GlobalSearchData:action.payload
        };
    },
    GLOBAL_SEARCH_FAILURE: (state,action) => {
        return {
            ...state,
            GlobalSearchData:action.payload,
        }
    },
    GLOBAL_SEARCH_STATE_NULL:(state)=>{
        return {
            ...state,
            GlobalSearchData:[]
        }
    }
}, GlobalSearchReducer)
export default reducer;