import { Box, Button, Typography } from '@mui/material'
import React,{useEffect} from 'react'
import {ReactComponent as CalendarIcon} from "../../Assets/icons/CalendarWithTick.svg";
import {ReactComponent as UserIcon} from "../../Assets/icons/UserIconAmenityManage.svg";
import { NavLink } from 'react-router-dom';

const AmenityManagementCardData = [{id: 1, icon: <CalendarIcon />, title: "My Bookings", desc: "Management of my personal amenity bookings.", buttonName: "Manage", link: "/facility_booking"},
{id: 2, icon: <UserIcon />,title: "All Users", desc: "View amenity bookings made by all SingLand Mobile app users.",buttonName: "View", link: "/manage_all_facility_bookings"}
];

const ManageFacilityBooking = () => {
  const identifyRoute = (buttonName) => {
    if(buttonName == 'View') {
      localStorage.setItem('typeof_amenity','/manage_all_facility_bookings')
    } else if(buttonName == 'Manage') {
      localStorage.setItem('typeof_amenity','/facility_booking')
    }
      
  }

  useEffect(() => {
    localStorage.removeItem('typeof_amenity')
  })
  return (
    <Box sx={{height: "-webkit-fill-available", width: "100%", background: "#F9FAFA", pt: 8,pl: 6}}>
        <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#002D74"}}>Amenities Management</Typography>
        <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#002D74"}}>Select a amenity management function to view.</Typography>
    
        <Box sx={{display: 'grid', gridTemplateColumns: "33% 33% 33%"}}>
            {AmenityManagementCardData.map(item =><Box key={item.id} sx={{p: 4, display: "flex", flexDirection: "column", gap: 5, alignItems: "start", mx: 1, my: 3, background: "white", borderRadius: "8px"}}>
                {item.icon}
                <Box>
                <Typography sx={{color: "#0F2C6F", fontSize: "18px", fontWeight: 500}}>{item.title}</Typography>
                <Typography sx={{color: "#0F2C6F", fontSize: "14px", fontWeight: 400, mt: "20px"}}>{item.desc}</Typography>
                </Box>
                <NavLink to={item.link} style={{marginTop: "10px"}}>
                <Button onClick={() =>identifyRoute(item.buttonName)} sx={{textTransform: "none", background: "#123FA9", borderRadius:"6px", }} variant='contained'>{item.buttonName}</Button>
                </NavLink>
            </Box>)}
         </Box>
    </Box>
  )
}

export default ManageFacilityBooking