import { handleActions } from "redux-actions";

const responseDef = {
  isError: false,
  isLoading: false,
  response: null
}

const FaultReportReducer = {
  faultReports: [],
  reportAddStatus: null,
  reportUpdateStatus: null,
  faultReportData:[],
  faultLocations:[],
  techniciansList:[],
  faultCount:null,
  updateWatchStatus:null,
  typeOfFault:[],
  updateDraft:null,
  activeFilterStatus:[],
  resolvedFilterStatus:[],
  myFaultReports: {...responseDef},
  getPriorityLevels: {...responseDef},
};
const reducer = handleActions(
  {
    UPDATE_STATE_FAULT_REPORT: (state, action) => {
      return {
        ...state,
        reportAddStatus: null,
        reportUpdateStatus: null,
        faultReports:null,
        faultCount:null,
        updateWatchStatus:null,
        updateDraft:null
      }
    },
    ADD_FAULT_REPORT_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADD_FAULT_REPORT_SUCCESS: (state, action) => {
      return {
        ...state,
        reportAddStatus: action.payload,
      };
    },
    ADD_FAULT_REPORT_FAILURE: (state,action) => {
      return {
        ...state,
        reportAddStatus: action.payload,
      };
    },
    FAULT_REPORT_INITIATE: (state, action) => {
      return {
        ...state,

      }
    },
    FAULT_REPORT_SUCCESS: (state, action) => {
      return {
        ...state,
        faultReports: action.payload.response_json,
        faultCount:action.payload.totalRecords
      }
    },
    FAULT_REPORT_FAILURE: (state, action) => {
      return {
        ...state,
        faultReports: [],
        faultCount:0
      }
    },
    FAULT_REPORT_NULL: (state) => {
      return {
        ...state,
        faultReports: [],
        faultCount: 0,
      }
    },
    UPDATE_REPORT_INITIATE: (state, action) => {
      return {
        ...state,

      }
    },
    UPDATE_REPORT_SUCCESS: (state, action) => {
      return {
        ...state,
        reportUpdateStatus: action.payload
      }
    },
    UPDATE_REPORT_FAILURE: (state, action) => {
      return {
        ...state,
        reportUpdateStatus: action.payload
      }
    },
    FAULTREPORT_BYID_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    FAULTREPORT_BYID_SUCCESS:(state,action)=>{
      return{
        ...state,
        faultReportData:action.payload
      }
    },
    FAULTREPORT_BYID_FAILURE:(state,action)=>{
      return{
        ...state,
        faultReportData:action.payload
      }
    },
    FAULT_LOCATION_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    FAULT_LOCATION_SUCCESS:(state,action)=>{
      return{
        ...state,
        faultLocations:action.payload
      }
    },
    FAULT_LOCATION_FAILURE:(state,action)=>{
      return{
        ...state,
        faultLocations:[]
      }
    },
    TECHNICIANS_INITIATE:(state,action)=>{
      return{
        ...state,
        faultLocations:[]
      }
    },
    TECHNICIANS_SUCCESS:(state,action)=>{
      return{
        ...state,
        techniciansList:action.payload
      }
    },
    TECHNICIANS_FAILURE:(state,action)=>{
      return{
        ...state,
        techniciansList:[]
      }
    },
    UPDATE_WATCHSTATUS_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    UPDATE_WATCHSTATUS_SUCCESS:(state,action)=>{
      return{
        ...state,
        updateWatchStatus:action.payload
      }
    },
    UPDATE_WATCHSTATUS_FAILURE:(state,action)=>{
      return{
        ...state,
        updateWatchStatus:action.payload
      }
    },
    GET_TYPEOFFAULT_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    GET_TYPEOFFAULT_SUCCESS:(state,action)=>{
      return{
        ...state,
        typeOfFault:action.payload
      }
    },
    GET_TYPEOFFAULT_FAILURE:(state)=>{
      return{
        ...state,
        typeOfFault:[]
      }
    },
    UPDATE_DRAFT_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    UPDATE_DRAFT_SUCCESS:(state,action)=>{
      return{
        ...state,
        updateDraft:action.payload
      }
    },
    UPDATE_DRAFT_FAILURE:(state,action)=>{
      return{
        ...state,
        updateDraft:action.payload
      }
    },
    GET_FAULTACTIVEFILTERSTATUS_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    GET_FAULTACTIVEFILTERSTATUS_SUCCESS:(state,action)=>{
      return{
        ...state,
        activeFilterStatus:action.payload
      }
    },
    GET_FAULTACTIVEFILTERSTATUS_FAILURE:(state)=>{
      return{
        ...state,
        activeFilterStatus:[]
      }
    },
    GET_FAULTRESOLVEDFILTERSTATUS_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    GET_FAULTRESOLVEDFILTERSTATUS_SUCCESS:(state,action)=>{
      return{
        ...state,
        resolvedFilterStatus:action.payload
      }
    },
    GET_FAULTRESOLVEDFILTERSTATUS_FAILURE:(state)=>{
      return{
        ...state,
        resolvedFilterStatus:[]
      }
    },
    GET_MY_FAULT_REPORTS_FOR_WEB_INITIATE: (state) => {
      return {
        ...state,
        myFaultReports: {...state.myFaultReports,isLoading: true, isError: false,}
      }
    },
    GET_MY_FAULT_REPORTS_FOR_WEB_SUCCESS: (state, action) => {
     return {
      ...state,
      myFaultReports: {isLoading: false, isError: false, response: action.payload}}
    },
    GET_MY_FAULT_REPORTS_FOR_WEB_FAILURE: (state, action) => {
      return {
        ...state,
        myFaultReports: {isLoading: false, isError: true, response: action.payload}}      
    },
    GET_MY_FAULT_REPORTS_FOR_WEB_NULL: (state) => {
      return {
        ...state,
        myFaultReports: {...responseDef}
      }
    },
    GET_PRIORITY_LEVELS_INITIATE: (state) => {
      return {
        ...state,
        getPriorityLevels: {...state.getPriorityLevels,isLoading: true, isError: false,}
      }
    },
    GET_PRIORITY_LEVELS_SUCCESS: (state, action) => {
     return {
      ...state,
      getPriorityLevels: {isLoading: false, isError: false, response: action.payload}}
    },
    GET_PRIORITY_LEVELS_FAILURE: (state, action) => {
      return {
        ...state,
        getPriorityLevels: {isLoading: false, isError: true, response: action.payload}}      
    },
    GET_PRIORITY_LEVELS_NULL: (state) => {
      return {
        ...state,
        getPriorityLevels: {...responseDef}
      }
    }
  },
  FaultReportReducer
);

export default reducer;
