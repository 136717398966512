import { Box, Button, Checkbox, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Table, TableBody, TableContainer, TableHead, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { StyledTableCell, StyledTableRow } from '../FaultReporting/FaultReports'
import { ReactComponent as SearchIcon } from "../../Assets/icons/SearchIcon.svg";
import { useDebounce } from 'use-debounce';
import * as commonAction from "../../redux/actions/commonAction";
import { connect } from 'react-redux';
import ToastAlert from '../common/Alert';
import { showSnackBarAction } from '../../redux/actions/snackbarAction';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ManageUnitLevelAccess = ({ getAllEmployeeListForUpdateDefaultRole, getAllEmployeeListForUpdateDefaultRoleData,getAssignedLevelsToEditDefaultAccess, getAssignedLevelsToEditDefaultAccessData,getAllEmployeeListForUpdateDefaultRoleNull,getAssignedLevelsToEditDefaultAccessNull,updateDefaultAccessLevel,updateDefaultAccessLevelData,updateDefaultAccessLevelNull,showSnackBar ,snackBarData}) => {
 
  const [filterOptions, setFilterOptions] = useState({ level: "", unit: "" });
  const [searchInput, setSearchInput] = useState('');
  const [searchTextDebounce, setSearchTextDebounce] = useDebounce('', 1000)
  const [employeeList, setEmployeeList] = useState([]);
  const manageUnitLevelAccessTableHeads = [ 'Employee Name', 'Email','Tenant Role', 'Office Unit']
  const [levelData, setLevelData] = useState([]);
  const [unitData, setUnitData] = useState([]);

  const [selectedLevelList, setSelectedLevelList] = useState([])
  const [selectedUnitList, setSelectedUnitList] = useState([])

  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [isRowUnSelected, setIsRowUnselected] = useState(false)

  const [selectUnitLevelError, setSelectUnitLevelError] = useState({level: "", unit: ""});

  const [toastConfigData, setToastConfigData] = useState({ message: '', status: false, color: '', icon: '' })  
   
  let dummyData = [{ id: 1, employee_name: "kumar", email: 'kumaresan.psts@gmail.com', job_title: 'Developer', tenant_role: 'Employee', office_unit: '#01-01' }]

  useEffect(() => {     
     getAssignedLevelsToEditDefaultAccess();
    
    return () => {
      getAllEmployeeListForUpdateDefaultRoleNull();
      getAssignedLevelsToEditDefaultAccessNull();
    }
  }, [])

  useEffect(() => {
    if (getAllEmployeeListForUpdateDefaultRoleData.response?.response_status === 1) {
      let arr = getAllEmployeeListForUpdateDefaultRoleData.response?.dataArray;       
      setEmployeeList(() => arr.map(item => ({...item, isChecked: false})));
    }else{
      setEmployeeList([]);
    }
  }, [getAllEmployeeListForUpdateDefaultRoleData])

  useEffect(() => {
    if(getAssignedLevelsToEditDefaultAccessData.response?.response_status === 1){
      setLevelData(getAssignedLevelsToEditDefaultAccessData.response?.data_array?.level_list)
    }
  }, [getAssignedLevelsToEditDefaultAccessData])

  useEffect(() => {
    if(filterOptions.level && filterOptions.unit){
      handleSelectedUnitLevelData(JSON.parse(filterOptions.level)?.level_id, JSON.parse(filterOptions.unit)?.unit_id)
    }
    setSelectedUnit('');
    setSelectedLevel('');

    getAllEmployeeListForUpdateDefaultRole({
      level_id: filterOptions.level ? JSON.parse(filterOptions.level)?.level_id  : '',
      unit_id: filterOptions.unit ? JSON.parse(filterOptions.unit)?.unit_id : '',
      search: searchInput,

    })
  }, [filterOptions,searchTextDebounce])

  useEffect(() => {
    if(updateDefaultAccessLevelData.response?.response_status === 1){
        handleReset();
        showSnackBar({message: updateDefaultAccessLevelData.response?.message});      
        updateDefaultAccessLevelNull();
    }
  }, [updateDefaultAccessLevelData])

  const handleCheckBoxSelect = id => {      

    setEmployeeList(prevValue => {
 
      let index = prevValue.findIndex(item => item.user_id === id);    

      if(index !== -1){
        prevValue[index].isChecked = !prevValue[index].isChecked;
      }

      return [...prevValue];  

    })
    const isAnyRowUnSelected = employeeList?.find((item) => item.isChecked == false)  
    if(isAnyRowUnSelected) {
      setIsRowUnselected(true)
    } else {
      setIsRowUnselected(false)
    }
  }

  const handleUnitData = (val) => { 
    let levelUnitArr = levelData.filter(item => item.level_id === JSON.parse(val)?.level_id);
    if(levelUnitArr.length > 0){
      setUnitData(levelUnitArr?.[0]?.units_list);
    }
  }
  
  
  const handleSelectedUnitLevelData = (level_id, unit_id) => {
    if(level_id, unit_id){
      let selectedLevelObj = levelData.filter(item => item?.level_id === level_id)[0];
      if(selectedLevelObj.units_list?.length > 1){
        setSelectedLevelList(levelData);
      }else{
        let modifiedLevelList = levelData.filter(item => item?.level_id !== level_id);
        setSelectedLevelList(modifiedLevelList);
        console.log(modifiedLevelList);
      }    
      
    }
  }
  
  
  const handleSelectedLevelUnitData = (val) => {
    let parseVal = JSON.parse(val);
    setSelectedUnit('');
    setSelectedLevel(val);
    let levelUnitArr = levelData.filter(item => item.level_id === parseVal?.level_id);
    let unitListArr = levelUnitArr?.[0]?.units_list;
    
    if(filterOptions.level === val){
      unitListArr = unitListArr?.filter(item => item?.unit_id !== JSON.parse(filterOptions.unit)?.unit_id);      
      setSelectedUnitList(unitListArr);
    }else{
      setSelectedUnitList(unitListArr);
     }  

  }

  const handleReset = () => {
    setSearchInput('');
    setFilterOptions({level: "", unit: ""});
  }

  const checkValidCheckBox = () => {
    for(const ele of employeeList){
      if(ele.isChecked === true){
        return true;
      }
    }
    return false;
}

const handleUpdateButton = () => {
  if(!selectedLevel || !selectedUnit){
    setSelectUnitLevelError({level: !selectedLevel ? "Should not be empty!" : "",unit: !selectedUnit ? "Should not be empty!" : "" })
  }else if(checkValidCheckBox() === false){
    //toast
    showSnackBar({message: "No employee selected", severity: "error"});      
    }else{
      //api call
      let selectedCheck = employeeList.filter(item => item.isChecked === true);
      let ids = selectedCheck.map(item => item.user_id);
      let unitId = JSON.parse(selectedUnit).unit_id;
      let levelId = JSON.parse(selectedLevel).level_id;

      updateDefaultAccessLevel({assigned_level_id: levelId, assigned_unit_id: unitId, assigned_user_id_str: ids.join(',')});

    }
  }

  const handleAllCheckUncheck = (checkStatus) => {
    setEmployeeList(prevValue => prevValue.map(item => ({...item,isChecked : checkStatus ? true : false})  ))
  }
console.log('employeeList ',employeeList)
  return (
    <Box sx={{ background: "#F9FAFA", height: '100%', paddingTop: "35px", px: "20px" }}>
        <ToastAlert message={toastConfigData.message} status={toastConfigData.status} color={toastConfigData.color} icon={toastConfigData.icon} closeFunc={() => setToastConfigData({ message: '', status: false, color: '', icon: '' })} />    
      <br/><Typography sx={{ fontSize: "24px", fontWeight: 500, color: '#002D74', marginTop:'14px' }}>Manage Access Level Units</Typography>

      <Box sx={{ background: "white", p: 2, mt: 3, borderRadius:'8px 8px 0 0' }}>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, alignItems: { xs: 'flex-start', lg: 'center' }, justifyContent: 'space-between', gap: { xs: 2, lg: 0 } }}>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', paddingTop:'19px' }}>
            <Box sx={{ width: 150 }}>
              <FormControl size='small' fullWidth>
                <InputLabel
                  sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px' }}
                >
                  Level
                </InputLabel>
                <Select
                  sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px', boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF", }}
                  value={filterOptions.level || ""}
                  label="level_no" name="level_no"
                  onChange={(e) => {
                    setFilterOptions(prevValue => ({ ...prevValue, level: e.target.value }))
                    setFilterOptions(prevValue => ({ ...prevValue, unit: '' }))

                    handleUnitData(e.target.value);
                    setSelectUnitLevelError(prevValue => ({...prevValue, level: ""}))
                  }}
                  MenuProps={{ style: { maxHeight: 300 } }}
                >
                   {levelData.length > 0 && levelData?.map((item, index) => (
                    <MenuItem sx={{ color: "#0F2C6F" }} key={index} value={JSON.stringify({level_id: item?.level_id, level_name: item?.level_name})}>{item.level_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: 150 }}>
              <FormControl size='small' fullWidth>
                <InputLabel
                  sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px' }}
                >
                  Unit
                </InputLabel>
                <Select
                  sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px', boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF", }}
                  value={filterOptions.unit || ""}
                  label="level_no" name="level_no"
                  onChange={(e) => {
                    setSelectUnitLevelError(prevValue => ({...prevValue, unit: ""}))
                    setFilterOptions(prevValue => ({ ...prevValue, unit: e.target.value }))
                  }}
                  MenuProps={{ style: { maxHeight: 300 } }}
                >
                   {unitData.length > 0 && unitData?.map((item, index) => (
                    <MenuItem sx={{ color: "#0F2C6F" }} key={index} value={JSON.stringify({unit_name: item?.unit_name,unit_id: item?.unit_id})}>{item?.unit_name}</MenuItem>
                  ))}
 

                </Select>
              </FormControl>
            </Box>

            <Box sx={{ width: 180 }}>
              <TextField size='small' placeholder='Search...' InputProps={{
                endAdornment: <Box sx={{ display: 'grid', placeItems: 'center' }}>
                  <SearchIcon />
                </Box>
              }}
                value={searchInput}
                onChange={e => {
                  setSearchInput(e.target.value);
                  setSearchTextDebounce(e.target.value);
                }}
              />
            </Box>

            <Typography sx={{
              textDecoration: "underline", color: '#2764F4', cursor: 'pointer', userSelect: 'none', fontWeight: 400, fontSize: '14px', textUnderlineOffset: '1px',
              '&:hover': {
                opacity: 0.8,
              }
            }}
            onClick={() => handleReset()}
             >Reset</Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start', gap: "10px", }}>
            <Typography sx={{ fontSize: '14px', color: "#123FA9", fontWeight: 600 }}>
              Update to Default Unit
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <Box sx={{ width: 150 }}>
                <FormControl size='small' fullWidth>
                  <InputLabel
                    sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px' }}
                  >
                    Level
                  </InputLabel>
                  <Select
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px', boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF", }}
                    value={selectedLevel || ""}
                    label="level_no" name="level_no"
                    onChange={(e) => {
                       handleSelectedLevelUnitData(e.target.value);
                       setSelectUnitLevelError(prevValue => ({...prevValue, level: ""}));
                      }}
                      MenuProps={{ style: { maxHeight: 300 } }}
                      >
                     {selectedLevelList.length > 0 && selectedLevelList.map((item, index) => (
                       <MenuItem sx={{ color: "#0F2C6F" }} key={index} value={JSON.stringify({level_id: item?.level_id, level_name: item?.level_name})}>{item.level_name}</MenuItem>
                       ))}
                  </Select>
                </FormControl>
                <Typography sx={{fontSize: "14px", mt: 1, color: "#ff0000"}}>{selectUnitLevelError?.level}</Typography>
              </Box>
              <Box sx={{ width: 150 }}>
                <FormControl size='small' fullWidth>
                  <InputLabel
                    sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px' }}
                    >
                    Unit
                  </InputLabel>
                  <Select
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px', boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF", }}
                    value={selectedUnit || ""}
                    label="level_no" name="level_no"
                    onChange={(e) => {
                      // handleFilterDatas(e.target.value, e.target.name);
                      setSelectedUnit(e.target.value)
                      setSelectUnitLevelError(prevValue => ({...prevValue, unit: ""}));
                      console.log(e.target.value);
                    }}
                    MenuProps={{ style: { maxHeight: 300 } }}
                  >
                     {selectedUnitList?.length > 0 && selectedUnitList?.map((item, index) => (
                       <MenuItem sx={{ color: "#0F2C6F" }} key={index} value={JSON.stringify({unit_name: item?.unit_name,unit_id: item?.unit_id})}>{item?.unit_name}</MenuItem>))}


                  </Select>
                </FormControl>
                <Typography sx={{fontSize: "14px", mt: 1, color: "#ff0000"}}>{selectUnitLevelError?.unit}</Typography>
              </Box>
              <Box>
              <Button variant='contained' sx={{ textTransform: "none", background: '#123FA9'}} 
              onClick={() => handleUpdateButton()}
              >Update</Button>
              </Box>
            </Box>
          </Box>
        </Box>
        {getAllEmployeeListForUpdateDefaultRoleData.isLoading && <Box sx={{ width: "100%", display: "grid", py: 2, height: 200,placeItems: 'center' }}>
          <CircularProgress sx={{ color: "#0F2C6F" }} />
        </Box>}
       
        {getAllEmployeeListForUpdateDefaultRoleData.isLoading === false &&
        <Grid item xs={12}>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <StyledTableRow>
                <StyledTableCell>
                      <Checkbox
                      size="small"
                        {...label}
                        onClick={(e) => {
                          handleAllCheckUncheck(e.target.checked)
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: '#0F2C6F',
                          },
                        }}
                      /></StyledTableCell>
                  {manageUnitLevelAccessTableHeads.map((item, index) => <StyledTableCell key={index} >
                    <StyledTableCell>
                      {item}
                    </StyledTableCell>
                  </StyledTableCell>)}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {employeeList.length > 0 && employeeList?.map(item =>
                  <StyledTableRow key={item.id}>
                    <StyledTableCell>
                      <Checkbox
                        size="small"
                        {...label}
                         checked={item?.isChecked}
                         onClick={() => {
                          handleCheckBoxSelect(item?.user_id)
                        }}
                        sx={{
                          '&.Mui-checked': {
                            color: '#0F2C6F',
                          },
                        }}
                      /></StyledTableCell>
                    <StyledTableCell sx={{fontWeight:'400!important'}}>{item?.name}</StyledTableCell>
                    <StyledTableCell sx={{fontWeight:'400!important'}}>{item?.email}</StyledTableCell>
                    <StyledTableCell sx={{fontWeight:'400!important'}}>{item?.role}</StyledTableCell>
                    <StyledTableCell sx={{fontWeight:'400!important'}}>{item?.office_unit_no}</StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          </Grid>}

          {employeeList.length === 0 && getAllEmployeeListForUpdateDefaultRoleData.isLoading === false && 
         <Box sx={{ display: 'flex', justifyContent: 'center', p: 5,backgroundColor:"rgba(143, 146, 161,0.05)" }}>
         <Typography sx={{color:"#0F2C6F"}} variant='h6'>No Data Found</Typography>
     </Box>
         }

      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    getAllEmployeeListForUpdateDefaultRoleData: state.CommonReducer.getAllEmployeeListForUpdateDefaultRole,
    getAssignedLevelsToEditDefaultAccessData: state.CommonReducer.getAssignedLevelsToEditDefaultAccess,
    updateDefaultAccessLevelData: state.CommonReducer.updateDefaultAccessLevel,
    snackBarData: state.SnackBarReducer.snackBarData,

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllEmployeeListForUpdateDefaultRole: (data) => dispatch(commonAction.getAllEmployeeListForUpdateDefaultRoleAction(data)),
    getAllEmployeeListForUpdateDefaultRoleNull: () => dispatch(commonAction.getAllEmployeeListForUpdateDefaultRoleNullAction),
    getAssignedLevelsToEditDefaultAccess: () => dispatch(commonAction.getAssignedLevelsToEditDefaultAccessAction()),
    getAssignedLevelsToEditDefaultAccessNull: () => dispatch(commonAction.getAssignedLevelsToEditDefaultAccessNullAction()),
    updateDefaultAccessLevel: (params) => dispatch(commonAction.updateDefaultAccessLevelAction(params)),
    updateDefaultAccessLevelNull: () => dispatch(commonAction.updateDefaultAccessLevelNullAction()),
    showSnackBar: (params) => dispatch(showSnackBarAction(params)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ManageUnitLevelAccess)
