import { Grid, Typography, Button, Tooltip } from '@mui/material';
import Box from "@mui/material/Box";
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../../Assets/icons/PlusBlue.svg';
import { ReactComponent as AddWhiteIcon } from '../../Assets/icons/WhiteRoundedPlusIcon.svg';
import * as ImportantContactAction from '../../redux/actions/ImportantContactAction/index';

// Truncate function to limit the length of text
const truncate = (str, maxLength) => {
  return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
};

const Important = ({ contactList, getContactList }) => {
  const navigate = useNavigate();

  useEffect(() => {
    getContactList();
  }, []);

  return (
    <Grid
      container
      sx={{
        background: "#fff",
        padding: "10px 10px 0 10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 4,
      }}
      rowGap={1}
    >
      <Grid
        item
        md={9}
        xs={12}
        sx={{
          display: "flex",
          gap: { md: "10px", sm: "8px", xs: "4px" },
        }}
      >
        <Typography sx={{ fontSize: '14px', color: '#0F2C6F', ml: 2 }}>
          Update important contact informations like emergency contact, BMS hotline etc.
        </Typography>
      </Grid>

      {contactList?.map((item) => (
        <Box
          key={item?.contact_id}
          sx={{
            padding: { md: '25px', xs: '15px' },
            width: '100%',
            border: '1px solid #DBDFE9',
            borderRadius: "8px",
            m: 2,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
            {/* Tooltip for truncated group title */}
            <Tooltip 
              title={<Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{item?.group_title}</Typography>} 
              placement="top"
            >
              <Typography 
                sx={{ 
                  fontSize: '18px', 
                  color: '#0F2C6F', 
                  fontWeight: '500', 
                  cursor: 'pointer'
                }}
              >
                {truncate(item?.group_title, 20)}
              </Typography>
            </Tooltip>
            <Button
              onClick={() => navigate(`/contacts/${item.group_title}/false/${item.contact_id}/true`)}
              variant='outlined'
              sx={{
                color: "#0F2C6F",
                "&:hover": { backgroundColor: "#FFFFFF" },
                textTransform: "capitalize",
                border: "1px solid #AAC3FF",
              }}
            >
              Edit...
            </Button>
          </Box>

          <Grid container sx={{ mt: 2 }}>
            {item?.sub_contacts?.map((value) => (
              <Grid
                item
                container
                xs={12}
                sx={{ borderBottom: "1px solid #DBDFE9", py: 3 }}
              >
                {/* Tooltip for truncated title */}
                <Grid item xs={4}>
                  <Tooltip 
                    title={<Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{value?.title}</Typography>} 
                    placement="top"
                  >
                    <Typography 
                      sx={{ 
                        color: "#0F2C6F", 
                        fontSize: "14px", 
                        fontWeight: 600, 
                        cursor: 'pointer'
                      }}
                    >
                      {truncate(value?.title, 20)}
                    </Typography>
                  </Tooltip>
                </Grid>
                
                {/* Tooltip for truncated description */}
                <Grid item xs={4}>
                  <Tooltip 
                    title={<Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{value?.desc}</Typography>} 
                    placement="top"
                  >
                    <Typography 
                      sx={{ 
                        color: "#0F2C6F", 
                        fontSize: "12px", 
                        cursor: 'pointer'
                      }}
                    >
                      {truncate(value?.desc, 20)}
                    </Typography>
                  </Tooltip>
                </Grid>

                {/* Allow phone number to break lines */}
                <Grid item xs={4}>
                  <Typography 
                    sx={{ 
                      color: "#0F2C6F", 
                      fontSize: "18px", 
                      fontWeight: 500, 
                      wordBreak: 'break-word'  
                    }}
                  >
                    {value.number}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              startIcon={<AddIcon />}
              sx={{
                textTransform: "capitalize",
                fontSize: '14px',
                my: 1,
                mt: 3,
                fontWeight: 600,
                border: '1px solid #AAC3FF',
                color: "#0F2C6F",
              }}
              onClick={() => {
                navigate(`/contacts/${item.group_title}/false/${item.contact_id}/false`);
              }}
            >
              Add Contact
            </Button>
          </Grid>
        </Box>
      ))}

      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', padding: '8px 10px' }}>
        <Button
          endIcon={<AddWhiteIcon />}
          onClick={() => navigate(`/contacts/none/true/false/false`)}
          variant='contained'
          sx={{
            borderradius: '6px',
            mb: 1,
            textTransform: "capitalize",
            background: '#123FA9',
            '&:hover': {
              background: '#123FA9',
            },
            fontSize: { md: "14px", xs: "10px" },
          }}
        >
          Create Group
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    contactList: state.ImportantContactReducer.contactList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactList: () => dispatch(ImportantContactAction.getContactList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Important);
