import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as PlusWhiteIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import Company from "./Company";
import TenantEmployee from "./TenantEmployee";
import { Link, useParams,useNavigate} from "react-router-dom";

const TAB_HEADINGS = [
  {
    id: 0,
    name: "Company",
  },
  {
    id: 1,
    name: "Tenant Employee",
  },
];

const ManageTenant = () => {
  const { empValue } = useParams();
  const navigate = useNavigate()
  const [value, setValue] = React.useState(parseInt(empValue));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/onboard_new_tenant/${newValue}/0`)
  };
  return (
    <Grid container sx={{background:"#F9FAFA",minHeight:"100%"}}>
      <Grid item xs={0.5}></Grid>

      <Grid item xs={11}>
      
      {/* Heading */}
      <Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            mt:8
          }}
        >
          <Typography
            sx={{ fontWeight: 500,color:"#002D74", fontSize: { md: "26px", xs: "22px" } }}
          >
            Onboarding
          </Typography>
          <Link
            to="/onboard_new_tenant_company"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                background: "#123FA9",

                display: "flex",
                alignItems: "center",
              }}
              endIcon={<PlusWhiteIcon />}
            >               
                New Company                
             </Button>
          </Link>
        </Box>
        <Box sx={{ }}>
          <Typography
            variant="p"
            sx={{ fontSize: { md: "14px", xs: "12px" }, fontWeight:500,color:"#002D74", lineHeight:1.5 }}
          >
            {/* BMS staff are able to submit tenant company onboarding requests,to
            approve/ reject tenant onboarding requests submitted by tenants. */}
            BMS Administrators are able to manage the company and employees registered in the SingLand System.
            You are able to add, edit and delete companies listed here.
          </Typography>
        </Box>
      </Box>

      {/* Tabs */}

      <Box>
        <Box sx={{ width: "100%", display: "flex", marginTop: "50px" }}>
          {TAB_HEADINGS.map((item, index) => {
            return (
              <Button
                sx={{
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: { md: "14px", xs: "12px" },
                  color: index === value ? "#0F2C6F" : "#9AA6C3",
                  background: index === value ? "#fff" : "#F3F4F8",
                  width: "190px",
                  boxShadow: "none",
                  "&.MuiButtonBase-root:hover": {
                    bgcolor: index === value ? "white" : "#e0e0e0",
                    boxShadow: "none",
                  },
                  padding: "13px 0",
                }}
                variant="contained"
                onClick={(e) => handleChange(e, index)}
                key={index}
              >
                {item.name}
              </Button>
            );
          })}
        </Box>
      </Box>
      <Box>
        {value === 0 && <Company />}
        {value === 1 && <TenantEmployee />}
      </Box>
    
      </Grid>

      <Grid item xs={0.5}></Grid>
    </Grid>
    
  );
};

export default ManageTenant;
