import {
  Box,
  Button,
  Chip,
  Divider,
  Skeleton,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Modal,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as InactiveIcon } from "../../Assets/icons/InactiveIcon.svg";
import { ReactComponent as ActiveIcon } from "../../Assets/icons/ActiveIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { TbMailForward } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as CommonAction from "../../redux/actions/commonAction";
import Loader from "../common/Loader";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { ReactComponent as CloseButton } from "../../Assets/icons/Alertclose.svg";
import {
  getUTCDateFormat,
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
  isToday,
} from "../../Utils/utils";
import ToastAlert from "../common/Alert";
import Faultalert from "../common/faultalert";

const StyledTypography = styled(Typography)({
  color: " #0F2C6F",
  fontSize: "14px",
  fontWeight: 500,
});

const StyledTableCell = styled(TableCell)({
  color: "#0F2C6F",
  fontSize: "12px",
  fontWeight: 600,
});

const StyledChip = styled(Chip)({
  borderRadius: "24px",
  color: "#ffffff",
  fontWeight: 500,
  marginRight: "7px",
  border: "1px solid #C0C7D7",
});

const SingleEmployeeReview = ({
  setStatusNull,
  empDetails,
  getEmpDetailsById,
  offBoardEmp,
  empOffBoardStatus,
  editOffDateStatus,
  editOffboardDate,
  cancelOffboardEmpStatus,
  cancelOffEmp,
  resendWelcomeMail,
  resendWelcomeMailStatus,
  resendWelcomeMailNullAction,
}) => {
  const { emp_id, activeType } = useParams();
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [localStorageData, setLocalStorageData] = useState({});
  const [loaderStatus, setLoaderStattus] = useState(true);
  const [offboardTenantStatus, setOffboardTenantStatus] = useState(false);
  const [offboardData, setOffboardData] = useState(null);
  const [offboardErrs, setOffboardErrs] = useState({
    date: "",
    remarks: "",
    editDate: "",
  });
  const [remarks, setRemarks] = useState("");
  const [empOffStatus, setEmpOffStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [accessDate, setAccessDate] = useState("");
  const date = new Date(accessDate);
  const today = new Date();
  const [editOff, setEditOff] = useState(false);
  const [editOffDate, setEditOffDate] = useState(null);
  console.log(editOffDate, "editOff");
  const [editOffconfirm, setEditOffConfirm] = useState(false);
  const [alertData, setAlertData] = useState({
    status: false,
    alertData: {
      label: "Cancel Offboarding",
      description: `Are you sure you want to Cancel offboarding ${employeeDetails[0]?.first_name} ${employeeDetails[0]?.last_name}`,
      buttonvalue: "Confirm",
    },
  });
  const [InactiveData, setInactiveData] = useState({
    label: "Inactive",
    description:
      "Employee accounts which have been successfully offboarded would be automatically pushed to the inactive tab. To reactivate account, tenant managers are to onboard employee again.",
    buttonvalue: "Ok",
  });
  const [assignedList, setAssignedList] = useState([]);
  const [accessRights, setAccessRights] = useState([]);
  const [assignedUnitsArrayList, setAssignedUnitsArrayList] = useState([]);
  const [reonboardDate, setReonboardDate] = useState(null);
  const [reonboardDateError, setReonboardDateError] = useState(null);

  useEffect(() => {
    getEmpDetailsById({ employee_id: emp_id });
    let localData = JSON.parse(localStorage.getItem("userData"));
    setLocalStorageData(localData);

    setTimeout(() => {
      setLoaderStattus(false);
    }, 800);
  }, []);

  useEffect(() => {
    setAlertData({
      status: false,
      alertData: {
        label: "Cancel Offboarding",
        description: `Are you sure you want to Cancel offboarding ${employeeDetails[0]?.first_name} ${employeeDetails[0]?.last_name}`,
        buttonvalue: "Confirm",
      },
    });
  }, [employeeDetails]);

  useEffect(() => {
    if (empDetails?.employeeArrayList?.length > 0) {
      setEmployeeDetails(empDetails.employeeArrayList);
      setCompanyDetails(empDetails.companyArrayList[0]);
      setAccessDate(empDetails.employeeArrayList[0].access_start_date);
      setAssignedList(empDetails?.assignedUnitsArrayList || []);
      setAccessRights(empDetails?.accessRightsArrayList || []);
      setAssignedUnitsArrayList(empDetails?.assignedUnitsArrayList || []);
      setEditOffDate(empDetails?.employeeArrayList[0]?.offboard_date);
      console.log(
        "empDetails?.companyArrayList[0]?.offboard_date ",
        empDetails?.employeeArrayList[0]?.offboard_date
      );
    }
  }, [empDetails]);

  useEffect(() => {
    if (empOffBoardStatus === "Employee Offboarded Successfully") {
      // setEmpOffStatus({ message: empOffBoardStatus, status: true, color: 'green', icon: 'tick' })
      // setTimeout(() => {
      inactiveModal(true);
      // }, 1000)
      setTimeout(() => {
        setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
        setStatusNull();
        getEmpDetailsById({ employee_id: emp_id });
        inactiveModal(false);
        if (localStorageData.role_id == 2 || localStorageData.role_id == 14) {
          navigate("/manage_staff/2");
        } else {
          navigate("/manage_employees/2");
        }
      }, 8000);
    } else if (empOffBoardStatus != null && empOffBoardStatus != undefined) {
      setEmpOffStatus({
        message: empOffBoardStatus,
        status: true,
        color: "red",
        icon: "close",
      });
      setStatusNull();
      closePopup();
      setTimeout(() => {
        setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
      }, 5000);
    }
  }, [empOffBoardStatus]);

  useEffect(() => {
    if (editOffDateStatus == "Offboard Date Updated Successfully") {
      setEmpOffStatus({
        message: editOffDateStatus,
        status: true,
        color: "green",
        icon: "tick",
      });
      closePopup();
      setTimeout(() => {
        setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
        setStatusNull();
        if (localStorageData.role_id == 2 || localStorageData.role_id == 14) {
          navigate("/manage_staff/2");
        } else {
          navigate("/manage_employees/2");
        }
      }, 5000);
    } else if (editOffDateStatus != null) {
      setEmpOffStatus({
        message: editOffDateStatus,
        status: true,
        color: "red",
        icon: "close",
      });
      closePopup();
      setStatusNull();
      setTimeout(() => {
        setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
      }, 5000);
    }
  }, [editOffDateStatus]);

  useEffect(() => {
    if (cancelOffboardEmpStatus === "Offboard cancelled successfully") {
      setEmpOffStatus({
        message: cancelOffboardEmpStatus,
        status: true,
        color: "green",
        icon: "tick",
      });
      closePopup();
      setTimeout(() => {
        setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
        setStatusNull();
        if (localStorageData.role_id == 2 || localStorageData.role_id == 14) {
          navigate("/manage_staff/2");
        } else {
          navigate("/manage_employees/2");
        }
      }, 5000);
    } else if (cancelOffboardEmpStatus != null) {
      setEmpOffStatus({
        message: cancelOffboardEmpStatus,
        status: true,
        color: "red",
        icon: "close",
      });
      closePopup();
      setStatusNull();
      setTimeout(() => {
        setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
      }, 5000);
    }
  }, [cancelOffboardEmpStatus]);

  useEffect(() => {
    if (resendWelcomeMailStatus?.response != null) {
      if (resendWelcomeMailStatus?.response?.response_status == "success") {
        setEmpOffStatus({
          message: resendWelcomeMailStatus?.response?.response_message,
          status: true,
          color: "green",
          icon: "tick",
        });
        setTimeout(() => {
          resendWelcomeMailNullAction();
          setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
        }, 5000);
      } else if (resendWelcomeMailStatus?.response?.response_status == "fail") {
        setEmpOffStatus({
          message: resendWelcomeMailStatus?.response?.response_message,
          status: true,
          color: "red",
          icon: "close",
        });
        setTimeout(() => {
          resendWelcomeMailNullAction();
          setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
        }, 5000);
      } else {
        setEmpOffStatus({
          message: "something went wrong",
          status: true,
          color: "red",
          icon: "close",
        });
        setTimeout(() => {
          resendWelcomeMailNullAction();
          setEmpOffStatus({ message: "", status: false, color: "", icon: "" });
        }, 5000);
      }
    }
  }, [resendWelcomeMailStatus]);

  const momentDateOne = moment(editOffDate);
  const momentDateTwo = moment(reonboardDate);

  const validate = () => {
    let error = true;
    // if(!remarks){
    //     setOffboardErrs((prevValue)=>({
    //         ...prevValue,
    //         remarks:'Should not be empty'
    //     }))
    //     error=false;
    // }
    if (offboardData == null) {
      setOffboardErrs((prevValue) => ({
        ...prevValue,
        date: "Select date ",
      }));
      error = false;
    }
    const isDateOneGreaterOrEqual = momentDateOne.isSameOrAfter(momentDateTwo);
    if (reonboardDate != null && isDateOneGreaterOrEqual) {
      console.log("withins ", reonboardDate, editOffDate);
      setReonboardDateError(
        "Reonboard Date should be greater than offboard date"
      );
      error = false;
    }
    return error;
  };

  const closePopup = () => {
    setOffboardTenantStatus(false);
    setEditOffDate(null);
    setOffboardData(null);
    setReonboardDate(null);
    setReonboardDateError("");
    setOffboardErrs("");
    setRemarks("");
    setEditOff(false);
    setEditOffConfirm(false);
    setAlertData({
      status: false,
      alertData: {
        label: "Cancel Offboarding",
        description: `Are you sure you want to Cancel offboarding ${employeeDetails[0]?.first_name} ${employeeDetails[0]?.last_name}`,
        buttonvalue: "Confirm",
      },
    });
  };

  const inactiveModal = (status) => {
    setOffboardTenantStatus(false);
    setEditOff(false);
    setOffboardData(null);
    setOffboardErrs("");
    setRemarks("");
    setEditOff(false);
    setEditOffConfirm(false);
    setAlertData({ status: status, alertData: InactiveData });
  };

  const offboardTenant = () => {
    let err = validate();
    if (err) {
      let datestr = `${offboardData.getFullYear()}-${
        offboardData.getMonth() + 1
      }-${offboardData.getDate()}`;
      let reonboardDateStr = `${reonboardDate.getFullYear()}-${
        reonboardDate.getMonth() + 1
      }-${reonboardDate.getDate()}`;
      let data = {
        offboard_date: datestr,
        // offboard_date:getUTCDateFormat(offboardData),
        employee_id: emp_id,
        created_by: localStorageData?.user_id,
        remarks: remarks,
        reonboard_date: reonboardDateStr,
      };
      console.log("datadata ", data);
      offBoardEmp(data);
    }
  };

  const editDate = () => {
    const isDateOneGreaterOrEqual = momentDateOne.isSameOrAfter(momentDateTwo);
    console.log("isDateOneGreaterOrEqual ", isDateOneGreaterOrEqual);
    if (reonboardDate != null && isDateOneGreaterOrEqual) {
      console.log("withins ", reonboardDate, editOffDate);
      setReonboardDateError(
        "Reonboard Date should be greater than offboard date"
      );
      return;
    }
    if (editOffDate) {
      setEditOffConfirm(true);
      setEditOff(false);
    } else {
      setOffboardErrs({
        ...offboardErrs,
        editDate: "Select Date",
      });
    }
  };
  const editDateConfirm = () => {
    // let datestr=`${editOffDate.getFullYear()}-${editOffDate.getMonth()+1}-${editOffDate.getDate()}`
    let data = {
      employees_id: emp_id,
      offboard_employees_id: employeeDetails[0].offboard_employees_id,
      offboard_date: getYYYYMMDDFormat(editOffDate),
      reonboard_date: getYYYYMMDDFormat(reonboardDate),
      // offboard_date:getUTCDateFormat(editOffDate)
    };
    editOffboardDate(data);
    console.log("datadata ", data);
  };
  const closeFunc = () => {
    setAlertData({
      status: false,
      alertData: {
        label: "Cancel Offboarding",
        description: `Are you sure you want to Cancel offboarding ${employeeDetails[0]?.first_name} ${employeeDetails[0]?.last_name}`,
        buttonvalue: "Confirm",
      },
    });
  };

  const cancelOffboard = () => {
    let data = {
      offboard_employees_id: employeeDetails[0].offboard_employees_id,
    };
    cancelOffEmp(data);
  };

  const resendWelcomeMailForEmployees = (employeeId) => {
    resendWelcomeMail({ employee_id: employeeId });
  };
  console.log(
    "employeeDetails[0]?.reonboard_date ",
    moment(reonboardDate).format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD")
  );

  if (loaderStatus) return <Loader status={loaderStatus} />;

  return (
    <>
      {alertData.status && (
        <Faultalert
          closeFunc={closeFunc}
          confirmFunc={
            employeeDetails[0]?.offboard_date === null
              ? () => inactiveModal(false)
              : cancelOffboard
          }
          alertData={alertData.alertData}
        />
      )}
      {empOffStatus.status && (
        <ToastAlert
          message={empOffStatus.message}
          status={empOffStatus.status}
          color={empOffStatus.color}
          icon={empOffStatus.icon}
        />
      )}
      {localStorageData.role_id !== 2 ||
      localStorageData.role_id !== 3 ||
      localStorageData.role_id !== 14 ? (
        <>
          {employeeDetails.length > 0 ? (
            <Grid
              container
              sx={{
                backgroundColor: "rgba(143, 146, 161,0.05)",
                minHeight: "100%",
              }}
            >
              <Grid item xs={1.5} />
              <Grid item xs={9}>
                <Box sx={{ pb: 5, pt: 7 }}>
                  <BackIcon cursor="pointer" onClick={() => navigate(-1)} />
                  <Typography
                    sx={{
                      color: "#0F2C6F",
                      fontSize: "26px",
                      fontWeight: 500,
                      my: 2,
                    }}
                  >
                    Employee Details
                  </Typography>
                  <Typography
                    sx={{ color: "#9AA6C3", fontSize: "13px", fontWeight: 500 }}
                  >
                    Onboarded on{" "}
                    {moment(employeeDetails[0]?.access_start_date).format(
                      "DD-MM-YY"
                    )}
                  </Typography>
                </Box>
                <Box sx={{ backgroundColor: "#FFFFFF", p: 3 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "16px",
                          fontWeight: 550,
                        }}
                      >
                        {`${employeeDetails[0]?.first_name}   ${employeeDetails[0]?.last_name}  `}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "14px",
                          mt: 2,
                          fontWeight: 550,
                        }}
                      >
                        {companyDetails?.company_name}
                      </Typography>
                    </Box>
                    {employeeDetails[0]?.emp_status == "Inactive" ? (
                      <Chip
                        icon={<InactiveIcon />}
                        label="Inactive"
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "14px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                          border: "1px solid #AAC3FF",
                        }}
                      />
                    ) : (
                      <Chip
                        icon={<ActiveIcon />}
                        label="Active"
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "14px",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                          border: "1px solid #AAC3FF",
                        }}
                      />
                    )}
                  </Box>
                  {localStorageData.role_id === 4 ||
                  localStorageData.role_id === 2 ||
                  localStorageData.role_id === 14 ? (
                    <Grid container sx={{ my: 5 }}>
                      <Grid item xs={12} sm={4}>
                        <StyledTypography>
                          Default Unit: #{employeeDetails[0]?.level_no}-
                          {employeeDetails[0]?.unit_no}
                        </StyledTypography>
                        {/* <StyledTypography>
                          Role: {employeeDetails[0]?.job_title}
                        </StyledTypography> */}
                        {/* <StyledTypography>Mobile : +65 {employeeDetails[0].mobile_no}</StyledTypography>
                           <StyledTypography>{empDetails.userArrayList[0].email}</StyledTypography>
    */}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        sx={{ maxWidth: "none !important" }}
                        style={{ flex: 1 }}
                      >
                        {/* <StyledTypography>#{employeeDetails[0].level_no}-{employeeDetails[0].unit_no}</StyledTypography>
                           <StyledTypography>
                              {employeeDetails[0]?.job_title}
                           </StyledTypography> */}
                        {employeeDetails[0]?.mobile_no ? (
                          <StyledTypography>
                            Mobile :{employeeDetails[0]?.mobile_country_code}{" "}
                            {employeeDetails[0]?.mobile_no}
                          </StyledTypography>
                        ) : (
                          <></>
                        )}

                        <StyledTypography>
                          Main Email: {employeeDetails[0]?.email}{" "}
                          <IconButton
                            disabled={resendWelcomeMailStatus?.isLoading}
                            onClick={() =>
                              resendWelcomeMailForEmployees(
                                employeeDetails[0].company_id
                              )
                            }
                          >
                            <TbMailForward />
                          </IconButton>
                        </StyledTypography>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}

                  {localStorageData.role_id == 2 && (
                    <>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "16px",
                          fontWeight: 550,
                        }}
                      >
                        Occupied Levels
                      </Typography>
                      {assignedList?.map((value) => (
                        <StyledChip
                          sx={{
                            my: 2,
                            backgroundColor: value?.unit_name?.includes(
                              "default"
                            )
                              ? "#173C92"
                              : "#9AA6C3",
                          }}
                          label={`#${value.level_name}`}
                        />
                      ))}
                    </>
                  )}
                  {localStorageData.role_id == 4 && (
                    <>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "16px",
                          fontWeight: 550,
                        }}
                      >
                        Occupied Units
                      </Typography>
                      {assignedList?.map((value) => (
                        <StyledChip
                          sx={{
                            my: 2,
                            backgroundColor: value?.unit_name?.includes(
                              "default"
                            )
                              ? "#173C92"
                              : "#9AA6C3",
                          }}
                          label={`#${value?.level_name}-${value?.unit_name}`}
                        />
                      ))}
                    </>
                  )}

                  {/* {
                                        assignedUnitsArrayList?.length > 0 &&
                                        <>
                                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px', fontWeight: 550 }}>Acess Level Units</Typography>
                                            {assignedUnitsArrayList?.map((value) =>
                                                (<StyledChip sx={{ my: 2, backgroundColor:  '#173C92'  }} label={value.level_name} />)
                                            )}
                                        </>
                                    } */}
                  {accessRights?.length > 0 && (
                    <Box sx={{ marginTop: "20px" }}>
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: "16px",
                          fontWeight: 550,
                        }}
                      >
                        Access Rights
                      </Typography>
                      {accessRights?.map((value) => (
                        <StyledChip
                          sx={{ my: 1, backgroundColor: "#173C92" }}
                          label={value}
                        />
                      ))}
                    </Box>
                  )}
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Onboarded Date</StyledTableCell>
                          <StyledTableCell>Offboard Date</StyledTableCell>
                          <StyledTableCell>Submitted by</StyledTableCell>
                          <StyledTableCell> Role</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: " #0F2C6F",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {moment(
                              employeeDetails[0]?.access_start_date
                            ).format("DD-MM-YY")}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: " #0F2C6F",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {employeeDetails[0]?.offboard_date != null &&
                              moment(employeeDetails[0]?.offboard_date)?.format(
                                "DD-MM-YYYY"
                              )}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: " #0F2C6F",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {employeeDetails[0]?.created_by_name}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: " #0F2C6F",
                              fontSize: "14px",
                              fontWeight: 500,
                            }}
                          >
                            {employeeDetails[0]?.created_by_role}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                {(localStorageData.role_id === 4 ||
                  localStorageData.role_id === 2 ||
                  localStorageData.role_id === 14) &&
                activeType != "offboarded" &&
                activeType != "offboard" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      py: 5,
                      gap: "10px",
                    }}
                  >
                    {localStorageData.user_id !=
                      employeeDetails[0]?.user_id && (
                      <>
                        {employeeDetails[0]?.offboard_date === null &&
                        new Date() >
                          new Date(employeeDetails[0]?.access_start_date) ? (
                          <Button
                            variant="outlined"
                            sx={{
                              color: " #0F2C6F",
                              textTransform: "capitalize",
                              border: " 1px solid #AAC3FF",
                              borderRadius: "8px",
                              height: "50px",
                              backgroundColor: "#FFFFFF",
                              "&:hover": { backgroundColor: "#FFFFFF" },
                            }}
                            onClick={() => setOffboardTenantStatus(true)}
                          >
                            Offboard
                          </Button>
                        ) : (
                          ""
                        )}

                        <Button
                          variant="outlined"
                          onClick={() =>
                            navigate(`/update_employee/${emp_id}/${activeType}`)
                          }
                          sx={{
                            color: " #FFFFFF",
                            textTransform: "capitalize",
                            borderRadius: "8px",
                            height: "50px",
                            backgroundColor: "#123FA9",
                            "&:hover": { backgroundColor: "#123FA9" },
                          }}
                        >
                          Edit
                        </Button>
                      </>
                    )}
                  </Box>
                ) : (
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", my: 3 }}
                  >
                    <Button
                      onClick={() =>
                        setAlertData({
                          ...alertData,
                          status: true,
                        })
                      }
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        color: "#0F2C6F",
                        fontSize: "14px",
                        borderRadius: "8px",
                        mr: 2,
                        height: "48px",
                      }}
                    >
                      Cancel Offboard
                    </Button>
                    <Button
                      onClick={() => setEditOff(true)}
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        color: "#FFFFFF",
                        backgroundColor: "#123FA9",
                        borderRadius: "8px",
                      }}
                    >
                      Edit Offboard
                    </Button>
                  </Box>
                )}
              </Grid>
              <Grid item xs={1.5} />
            </Grid>
          ) : (
            <Skeleton variant="text" height="100%" />
          )}
        </>
      ) : (
        ""
      )}
      <Modal open={offboardTenantStatus} onClose={() => closePopup()}>
        <Box
          sx={{
            position: "relative",
            borderRadius: "6px",
            left: "30%",
            top: "10%",
            backgroundColor: "#FFFFFF",
            width: "40%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderRadius: "8px 8px 0px 0px",
              p: 2.5,
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "500", color: "#0F2C6F" }}
            >
              Offboard Employee
            </Typography>

            <CloseButton cursor="pointer" onClick={() => closePopup()} />
          </Box>
          <Divider />
          <Box sx={{ padding: "16px" }}>
            <Typography sx={{ color: "#0F2C6F", fontSize: "15px" }}>
              <span style={{ fontWeight: 750, fontSize: "16px" }}>
                {employeeDetails[0]?.first_name} {employeeDetails[0]?.last_name}
              </span>{" "}
              will be offboarded by the end of selected offboard date.
            </Typography>
            <Typography sx={{ color: "#0F2C6F", fontSize: "15px", mt: 1 }}>
              The employee will not be able to login to the app after the
              offboard date has passed.
            </Typography>
            <Typography
              sx={{
                color: "#0F2C6F",
                fontSize: "14px",
                mt: 5,
                fontWeight: 550,
              }}
            >
              Offboard Date*
            </Typography>
          </Box>
          <Box sx={{ padding: "16px" }}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              minDate={
                new Date() > new Date(employeeDetails[0]?.access_start_date)
                  ? new Date()
                  : new Date(employeeDetails[0]?.access_start_date)
              }
              value={offboardData}
              onChange={(newValue) => {
                setOffboardData(newValue);
                setOffboardErrs({
                  ...offboardErrs,
                  date: "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            {offboardErrs.date && (
              <Typography sx={{ color: "#f44336" }}>
                {offboardErrs.date}
              </Typography>
            )}
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px", mt: 2.5 }}>
              Reonboard Date
            </Typography>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              shouldDisableDate={isToday}
              disablePast
              // minDate={employeeDetails[0]?.reonboard_date}
              value={
                reonboardDate != null &&
                moment(reonboardDate).format("YYYY-MM-DD") !=
                  moment().format("YYYY-MM-DD")
                  ? reonboardDate
                  : employeeDetails[0]?.reonboard_date == null
                  ? null
                  : employeeDetails[0]?.reonboard_date
              }
              onChange={(value) => {
                setReonboardDate(value);
                setReonboardDateError("");
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            {reonboardDateError != "" && (
              <Typography sx={{ color: "red" }}>
                {reonboardDateError}
              </Typography>
            )}
            <Box>
              <Typography
                sx={{
                  color: "#0F2C6F",
                  fontSize: "14px",
                  mt: 5,
                  fontWeight: 550,
                }}
              >
                Add a Remark (Optional)
              </Typography>
              <TextField
                fullWidth
                placeholder="You may provide reasons for offboarding."
                value={remarks}
                onChange={(e) => {
                  setRemarks(e.target.value);
                  setOffboardErrs({
                    ...offboardErrs,
                    remarks: "",
                  });
                }}
                sx={{ mt: 1 }}
              />
              {/* {offboardErrs.remarks && <Typography sx={{ color: "#f44336" }}>{offboardErrs.remarks}</Typography>} */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#0F2C6F",
                fontSize: "14px",
                border: "1px solid #AAC3FF",
                "&:hover": { backgroundColor: "#FFFFFF" },
                borderRadius: "6px",
                textTransform: "capitalize",
              }}
              onClick={() => closePopup()}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => offboardTenant()}
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#BD0251" },
                backgroundColor: "#BD0251",
                height: "40px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              Offboard
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={editOff} onClose={() => closePopup()}>
        <Box
          sx={{
            position: "relative",
            left: "30%",
            top: "20%",
            backgroundColor: "#FFFFFF",
            width: "40%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
              Edit Offboarding Date
            </Typography>
            <CloseButton cursor="pointer" onClick={() => closePopup()} />
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
              Are you sure you want to offboard{" "}
              <span style={{ fontSize: "16px", fontWeight: 550 }}>
                {employeeDetails[0]?.first_name} {employeeDetails[0]?.last_name}{" "}
              </span>{" "}
              on below date?
            </Typography>
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px", mt: 2.5 }}>
              Offboard Date
            </Typography>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              minDate={employeeDetails[0]?.offboard_date}
              value={
                editOffDate != null
                  ? editOffDate
                  : employeeDetails[0]?.offboard_date
              }
              onChange={(value) => {
                setEditOffDate(value);
                setOffboardErrs({
                  ...offboardErrs,
                  editDate: "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            {offboardErrs.editDate && (
              <Typography sx={{ color: "red" }}>
                {offboardErrs.editDate}
              </Typography>
            )}
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px", mt: 2.5 }}>
              Reonboard Date
            </Typography>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              shouldDisableDate={isToday}
              disablePast
              // minDate={employeeDetails[0]?.reonboard_date}
              value={
                reonboardDate != null &&
                moment(reonboardDate).format("YYYY-MM-DD") !=
                  moment().format("YYYY-MM-DD")
                  ? reonboardDate
                  : employeeDetails[0]?.reonboard_date == null
                  ? null
                  : employeeDetails[0]?.reonboard_date
              }
              onChange={(value) => {
                setReonboardDate(value);
                setReonboardDateError("");
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            {reonboardDateError != "" && (
              <Typography sx={{ color: "red" }}>
                {reonboardDateError}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#0F2C6F",
                fontSize: "14px",
                border: "1px solid #AAC3FF",
                "&:hover": { backgroundColor: "#FFFFFF" },
                borderRadius: "6px",
                textTransform: "capitalize",
              }}
              onClick={() => closePopup()}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => editDate()}
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#BD0251" },
                backgroundColor: "#BD0251",
                height: "40px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={editOffconfirm}>
        <Box
          sx={{
            position: "relative",
            left: "30%",
            top: "20%",
            backgroundColor: "#FFFFFF",
            width: "40%",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
              Edit Offboarding Date
            </Typography>
            <CloseButton
              cursor="pointer"
              onClick={() => {
                setEditOff(true);
                setEditOffConfirm(false);
              }}
            />
          </Box>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
              Are you sure you want to change freeze date on{" "}
              <span style={{ fontSize: "16px", fontWeight: 550 }}>
                {employeeDetails[0]?.first_name} {employeeDetails[0]?.last_name}{" "}
              </span>{" "}
              account from{" "}
              <span style={{ fontSize: "16px", fontWeight: 550 }}>
                {employeeDetails[0]?.offboard_date
                  ? moment(employeeDetails[0]?.offboard_date).format(
                      "DD-MM-YYYY"
                    )
                  : moment(editOffDate).format("DD-MM-YYYY")}
              </span>{" "}
              <span style={{ fontSize: "16px", fontWeight: 550 }}>
                {" "}
                {reonboardDate != null
                  ? `to ${moment(reonboardDate).format("DD-MM-YYYY")}`
                  : ""}{" "}
                ?
              </span>{" "}
            </Typography>
            {/* <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Are you sure you want to change freeze date on <span style={{ fontSize: "16px", fontWeight: 550 }}>{employeeDetails[0]?.first_name} {employeeDetails[0]?.last_name} </span> account from   <span style={{ fontSize: "16px", fontWeight: 550 }}>{`${employeeDetails[0]?.offboard_date}`  `to ${editOffDate != null ? getYYYYMMDDFormat(editOffDate) : ''}}`}</span> ? </Typography> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "16px",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#0F2C6F",
                fontSize: "14px",
                border: "1px solid #AAC3FF",
                "&:hover": { backgroundColor: "#FFFFFF" },
                borderRadius: "6px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setEditOff(true);
                setEditOffConfirm(false);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              onClick={() => editDateConfirm()}
              sx={{
                color: "#ffffff",
                textTransform: "capitalize",
                "&:hover": { backgroundColor: "#BD0251" },
                backgroundColor: "#BD0251",
                height: "40px",
                padding: "10px",
                fontSize: "14px",
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    empDetails: state.CommonReducer.empDetails,
    empOffBoardStatus: state.CommonReducer.empOffBoardStatus,
    editOffDateStatus: state.CommonReducer.editOffDateStatus,
    cancelOffboardEmpStatus: state.CommonReducer.cancelOffboardEmpStatus,
    resendWelcomeMailStatus: state.CommonReducer.resendMailForEmployees,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmpDetailsById: (data) => dispatch(CommonAction.getEmpDetailsById(data)),
    offBoardEmp: (data) => dispatch(CommonAction.offBoardEmp(data)),
    setStatusNull: () => dispatch(CommonAction.setStatusNull()),
    editOffboardDate: (data) => dispatch(CommonAction.editOffDate(data)),
    cancelOffEmp: (data) => dispatch(CommonAction.cancelOffEmp(data)),
    resendWelcomeMail: (data) => dispatch(CommonAction.resendWelcomeMail(data)),
    resendWelcomeMailNullAction: () =>
      dispatch(CommonAction.resendWelcomeMailNullAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleEmployeeReview);
