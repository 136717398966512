import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, Modal, OutlinedInput, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { ReactComponent as DownloadIcon } from '../../../Assets/icons/DownloadIcon.svg';
import { ReactComponent as BellWatch } from "../../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../../Assets/icons/StructuralFaultBell.svg";
import {ReactComponent as CloseIcon} from '../../../Assets/icons/Alertclose.svg';
import {ReactComponent as RefreshIcon} from '../../../Assets/icons/Refresh.svg';
import { ReactComponent as LogoIcon } from '../../../Assets/icons/Logo.svg';
import { useNavigate,useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import { getStatus } from "../../../Utils/ActiveStatus";
import { checkFCCRole, getLoggedUserData, getTimeStamp } from "../../../Utils/utils";
import StatusStepper from "../../common/stepper";
import { saveAs } from "file-saver";
import Alert from '../../common/faultalert';
import ToastAlert from "../../common/Alert";
import UpdatePopup from "../UpdatePopup";
import Loader from "../../common/Loader";
import html2PDF from 'jspdf-html2canvas';
import moment from "moment";
import UnAuthorized from "../../common/Unauthorized";
import {ReactComponent as PdfIcon} from '../../../Assets/icons/PDF.svg';
import {ReactComponent as ImageFormatIcon} from '../../../Assets/icons/ImageFormatIcon.svg';
import {ReactComponent as DownloadButtonIcon} from '../../../Assets/icons/OutlinedDownload.svg';

const StyledTypography = styled(Typography)({
    color:"#0F2C6F",
    fontSize:"18px",
    fontWeight:540

})

const SecondaryTypography = styled(Typography)({
    color:"#0F2C6F;",
    fontSize:'12px',
    fontWeight:400
})

const alertDatas = [
    {
        label:"Withdraw Request",
        description:'Are you sure want to withdraw request?',
        buttonvalue:'Confirm'
    },
    {
        label:"Reject Service Request",
        description:'Reject service request?',
        buttonvalue:'Confirm'
    },{
        label:"Approve Service Request",
        description:'Approve service request?',
        buttonvalue:'Confirm'
    }
]


const ReviewMiscADHOCRequest = ({tab_value,serviceReqDetails,serviceRequestByID,serReqUpdateStatus,updateServiceRequestStatus,setServiceRequestStateNull,fromDashboard,updateWatchStatus,updateWatch,notification,isUnAuthorized})=>{
    let {role_id} = getLoggedUserData()
    let localStorageData = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();
    let {service_req_id,service_req_type_id} = useParams();
    const [open,setOpen] = useState(false);
    const [note,setNote] = useState('');
    const [tenantData,setTenantData] = useState();
    const [reviewData,setReviewData] = useState();
    const [submittedfiles,setSubmittedFiles] = useState();
    const [alertOpen,setAlertOpen] = useState({actionStatus:"",status:false,data:{}})
    const [snackbarStatus,setSnackbarStatus] = useState({message:"",status:false,color:"",icon:""})
    const [openUpdatePopup,setOpenUpdatePopup] = useState(false);
    const [nextUpdateStatus,setNextUpdateStatus] = useState(null);
    const [openAcknowledgeAlert,setOpenAcknowledgeAlert] = useState(false);
    const [acknowledgeFeedback,setAcknowledgeFeedback] = useState('');
    const [error,setError] = useState({acknowledge:"",notes:""})
    const [terms,setTerms] = useState(null);
    const [loaderStatus,setLoaderStatus] = useState(true);
    const [description, setDescription] = useState("");
    const [sapDetails, setSapDetails] = useState({})
    const [showSapDetails, setShowSapDetails] = useState(true);


    useEffect(()=>{
        serviceRequestByID({service_requests_id:service_req_id})
    },[updateWatchStatus,service_req_id,notification])

    useEffect(()=>{
        if(serviceReqDetails.companiesArrayList){
            setTenantData(serviceReqDetails?.companiesArrayList[0]);
        }
        if(serviceReqDetails.serviceRequestsArrayList){
            setReviewData(serviceReqDetails?.serviceRequestsArrayList[0]);
            setLoaderStatus(false)
        }else if(isUnAuthorized!=false){
            setLoaderStatus(false)
        }
        if(serviceReqDetails.service_request_files){
            setSubmittedFiles(serviceReqDetails?.service_request_files)
        }
        if(serviceReqDetails?.termsArrayList){
            setTerms(JSON.parse(serviceReqDetails?.termsArrayList[0].config_value))
        }
        if(serviceReqDetails?.nextStatusListArrayList){
            setNextUpdateStatus(serviceReqDetails.nextStatusListArrayList)
        }
        if(serviceReqDetails?.service_request_sap_invoice){
            setSapDetails(serviceReqDetails?.service_request_sap_invoice);
        }
    },[serviceReqDetails])

    useEffect(()=>{
        if(serReqUpdateStatus?.response_status==1){
            alertClose()
            setSnackbarStatus({message:serReqUpdateStatus?.message,status:true,color:'green',icon:'close'})
            setOpenUpdatePopup(false)
            setOpenAcknowledgeAlert(false)
            setServiceRequestStateNull()
            setTimeout(()=>{
                removeSnackbar()
                navigate(`/service_request_list/${tab_value}`);
            },3000)
        }else if(serReqUpdateStatus?.response_status==0){
            alertClose()
            setSnackbarStatus({message:serReqUpdateStatus?.message,status:true,color:'red',icon:'close'})
            setServiceRequestStateNull()
            setTimeout(()=>{
                removeSnackbar()
            },3000)
        }else if(serReqUpdateStatus!=null){
            alertClose()
            setSnackbarStatus({message:serReqUpdateStatus,status:true,color:'red',icon:'close'})
            setServiceRequestStateNull()
            setTimeout(()=>{
                removeSnackbar()
            },3000)
        }
    },[serReqUpdateStatus])

    useEffect(()=>{
        if(updateWatchStatus=="Service Request watch updated successfully"){
         setSnackbarStatus({ message: updateWatchStatus, status: true, color: 'green', icon: 'close' })
          setTimeout(()=>{
             removeSnackbar()
            setServiceRequestStateNull()
          },3000)
        }else if(updateWatchStatus!=null){
         setSnackbarStatus({ message:updateWatchStatus , status: true, color: 'red', icon: 'close' })
          setTimeout(()=>{
             removeSnackbar()
            setServiceRequestStateNull()
          },3000)
        }
      },[updateWatchStatus])

    const handleFileDownload = (path,name)=>{
        if(path){
            saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${path}`,`${name}`)
        }
    }

    const handleSubmit = (status)=>{

        if(!description && alertOpen.actionStatus === "rejected"){
            setError(prevValue=>({...prevValue, description:'Remarks required !' }))
            return;
        }else setError(prevValue=>({...prevValue, description:'' }))

        let data={
            service_requests_id: service_req_id,
            description: status==="approved" ? note : description,
            status:alertOpen?.actionStatus
        }
        updateServiceRequestStatus(data)
    }

    const handleAlertPopup = (status,statusId)=>{
        setAlertOpen({
            actionStatus: status,
            status:true,
            data:alertDatas[statusId]
        })
        if(status==="approved"){
            setOpen(false);
        }
    }

    const alertClose = ()=>{
        setAlertOpen({status:false,actionStatus:'',data:{}})
    }

    const removeSnackbar = () => {
        setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
    }

    const updateAcknowledgement = ()=>{
        if(!acknowledgeFeedback){
            setError({
                ...error,
                acknowledge:"Should not be empty"
            })
        }else{
            let serReqNewStatus = {
                service_requests_id: service_req_id,
                status: 'acknowledgement',
                description:acknowledgeFeedback
            }
            updateServiceRequestStatus(serReqNewStatus)
        }
    }

    const stepperWithdrawFunction = ()=>{
        let serReqNewStatus = {
            service_requests_id: service_req_id,
            status: 'withdraw'
        }
        updateServiceRequestStatus(serReqNewStatus)
    }

    const handleWatchUnWatch =(id,status)=>{
        let data = {
            service_requests_id:id,
            watch_status:status
        }
        updateWatch(data)
    }

    const downloadPDF = () => {
        let data = document.getElementById('print')
        html2PDF(data,{
              jsPDF: {
                format: 'a4',
              },    
              imageType: 'image/jpeg',
              output: `${reviewData?.ticket_id} ${moment(new Date()).format('DD/MM/yyyy')}.pdf`
        })

    }

    const renderButton = ()=>{
        if(localStorageData?.role_id===4 && reviewData?.status==="submitted"){
            return(
                <>  
                <Button onClick={()=>handleAlertPopup('withdraw',0)} variant="contained" sx={{textTransform:"capitalize",height:"48px",color:"#FFFFFF",backgroundColor:"#BD0251","&:hover":{backgroundColor:"#BD0251"}}}>Withdraw</Button>
                </>
            )
        }else if(localStorageData?.role_id===4 && reviewData?.status==="resolved"){
            return(<Button onClick={()=>setOpenAcknowledgeAlert(true)} variant="contained" sx={{
                background: '#123FA9',
                color: '#FFFFFF',
                height: '50px',
                width: '114px',
                fontSize: '14px',
                pr: 0,
                pl: 0,
                textTransform: 'none',
                
            }} >Acknowledge</Button>)

        } else if((localStorageData?.role_id===3 || localStorageData?.role_id===2 || localStorageData?.role_id===14 || checkFCCRole()) && reviewData?.status==="submitted"){
            return(
                <>
                <Button onClick={()=>handleAlertPopup('rejected',1)} variant="outlined" sx={{  background: '#FFFFFF',
                    color: '#123FA9',
                    height: '50px',
                    mr:2,
                    fontSize: '14px',
                    textTransform: 'none',}}>Reject</Button>
                <Button onClick={()=>setOpen(true)} variant="contained" sx={{ background: '#123FA9',
                        color: '#FFFFFF',
                        height: '50px',
                        fontSize: '14px',
                        textTransform: 'none',}}>Approve</Button>
                </>
            )
        }else if((localStorageData?.role_id===3 || localStorageData?.role_id===2 || localStorageData?.role_id===14 || checkFCCRole())  && reviewData?.status!=="submitted" && reviewData?.status!=="resolved" && reviewData?.status!=="closed" && reviewData?.status!="rejected" && reviewData?.status!="withdraw" ){
            return(
                <>
                <Button variant="contained" onClick={()=>setOpenUpdatePopup(true)} sx={{
                    background: '#123FA9',
                    "&:hover":{ background: '#123FA9'},
                    color: '#FFFFFF',
                    height: '50px',
                    width: '114px',
                    fontSize: '14px',
                    pr: 0,
                    pl: 0,
                    textTransform: 'none'}}>Update Status</Button>
                </>
            )
        }
    }

    const renderDescription =()=>{
        if(alertOpen.actionStatus === "rejected"){
            return  <Box sx={{  padding: '16px 24px' }}>
              <TextField multiline fullWidth rows={3} value={description} name="description" onChange={(e)=> { 
                setDescription(e.target.value); setError(prevValue=>({...prevValue,description:''})) ;
                }} />
              <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                  <br /> {error?.description}
              </Typography>
            </Box>
        }else return null
    }

    const handleSapFileDownload = (downloadFile, fileName) => {
        saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${downloadFile}`, fileName)
  }

    return(
        <>
        {loaderStatus ? <Loader status={loaderStatus} /> : isUnAuthorized!=false ? <UnAuthorized data={isUnAuthorized} /> :
        <>
        {snackbarStatus.status && <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon}  />}
       {alertOpen.status && <Alert renderElement={renderDescription()} confirmFunc={handleSubmit} closeFunc={()=>{ setAlertOpen("");  setError(prevValue=>({...prevValue, description:'' })); }} alertData={alertOpen.data}/>}
        {openUpdatePopup && <UpdatePopup serviceReqId={service_req_type_id} closeFunc={setOpenUpdatePopup} serviceType={service_req_id} serviceTypeValue={"Miscelleaneous Forms"} currentStatus={reviewData?.status} nextStatus={nextUpdateStatus} />}
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}}>
            <Grid item xs={0.5} />
            <Grid item xs={8} >
                    <Box sx={{ mt: 7, mb: 5 }}>
                        <ArrowIcon cursor='pointer' onClick={()=>{
                            // if(fromDashboard=="true"){
                            //     navigate(`/dashboard`)
                            // }else{
                                navigate(-1)
                            // }
                            }} />
                            <Box sx={{display:"flex"}}>
                                <Box sx={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}> 

                                <Typography sx={{ fontSize: '26px', color: '#0F2C6F', pt: 2, pb: 2 }}> {reviewData?.display_name} - #{reviewData?.ticket_id}  </Typography>
                                <Button data-html2canvas-ignore='true' onClick={downloadPDF} sx={{ color: '#0F2C6F', textTransform: 'capitalize', backgroundColor: '#CFDDFE', borderRadius: '6px', '&:hover': { backgroundColor: '#CFDDFE' } }} endIcon={<DownloadIcon />}>Download</Button>
                                </Box>
                                {reviewData?.status!='rejected' && reviewData?.status!='closed' && reviewData?.status!='withdraw' && 
                                    reviewData?.status!='acknowledgement' && reviewData?.status!='resolved' && 
                                <>
                                    {serviceReqDetails?.watch_status=="yes" ?  <BellWatch onClick={()=>handleWatchUnWatch(service_req_id,"no")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' /> : 
                                        <BellUnWatch onClick={()=>handleWatchUnWatch(service_req_id,"yes")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' />
                                    }
                                </>
                                }
                            </Box>
                        <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>{getTimeStamp(reviewData?.created_at)}</Typography>
                        <Chip label={getStatus(reviewData?.status, 'text')} sx={{ backgroundColor: getStatus(reviewData?.status, 'bgcolor'), color: getStatus(reviewData?.status, 'color'), borderRadius: '6px', height: '24px', fontSize: '14px', ml: 2 }} />
                    </Box>
            </Grid>
            <Grid item xs={3.5} />
        </Grid>
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}}>
            <Grid item xs={0.5} />
            <Grid id='print' item container xs={8} sx={{backgroundColor:"#FFFFFF"}} >
                <Box sx={{display:'flex',justifyContent:'space-between',px:3,py:2,width:'100%',zIndex:-5}}>
                            <StyledTypography>Adhoc Request -  {reviewData?.ticket_id}</StyledTypography>
                            <LogoIcon />
                </Box>

                {reviewData?.status === 'closed' &&
                        <Box sx={{mb: 3,mt:1,width: "100%", background: '#ffffff', borderRadius: '8px',px:3,pb:3 }}>
                            <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#0F2C6F"}}>Final SAP Invoice</Typography>
                            <Box sx={{p:2, border: '1px solid #DBDFE9', borderRadius: "8px", my: 2}}>

                            {showSapDetails &&
                                <Box sx={{mb: 2, borderBottom: '1px solid #DBDFE9', pb: 2, display: 'flex', flexDirection: "column", gap: '20px'}}>
                                {/* SAP No */}
                               {sapDetails?.sap_number && <Box>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 500}}>SAP Number</Typography>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 400}}>{sapDetails?.sap_number}</Typography>
                                </Box>}

                                {/* Comments */}
                              {sapDetails?.sap_description &&  <Box>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 500}}>Comments</Typography>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 400}}>{sapDetails?.sap_description}</Typography>
                                </Box> }
                                </Box> }

                                <Box sx={{display: "flex", width: '100%', justifyContent: 'space-between', alignItems: "center"}}>  
                                    <Box>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>Total Amount (SGD)</Typography>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>GST Included</Typography>

                                    </Box>
                                    <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#A48B2C"}}>${sapDetails?.final_invoice_amount}</Typography>
                                </Box>


                            </Box>
                           {sapDetails?.file &&  showSapDetails &&  <Box sx={{pt: 2}}>
                            <Typography sx={{fontSize: "18px", fontWeight: 500, color: "#0F2C6F "}}>Uploaded SAP Invoice</Typography>
                            <Box sx={{display: "flex", width:"-webkit-fill-available", justifyContent: "space-between", alignItems: "center", my: 2, p: 2,border: '1px solid #DBDFE9', borderRadius: "8px"}}>
                                <Box sx={{display: 'flex', alignItems: "center", gap: 2}}>
                                {sapDetails?.file_type === 'pdf' ? <PdfIcon style={{width: '40px', height: "40px"}} />
                                 : <ImageFormatIcon style={{width: '40px', height: "40px"}} />}
                                 <Box>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>{sapDetails?.file_name}</Typography>
                                    {sapDetails?.file_size && <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#9AA6C3"}}>{sapDetails?.file_size} of {sapDetails?.file_size} KB</Typography>}
                                 </Box>
                                </Box> 

                                <IconButton style={{borderRadius: 0}} onClick={() => handleSapFileDownload(sapDetails?.file, sapDetails?.file_name)}>
                                 <DownloadButtonIcon />
                                </IconButton>
                             </Box>
                             </Box>}

                            <Box sx={{display: "flex", py: 1, justifyContent: "center", cursor: "pointer"}}>
                                <Typography sx={{fontSize: '14px', color: "#2764F4", textDecoration: "underline"}}
                                onClick={() => setShowSapDetails(prevValue => !prevValue)}
                                >{showSapDetails ? 'Hide' : 'Show'} details</Typography>
                            </Box>
                            
                        </Box>
                            }
                <Grid item container xs={12} sx={{px:3,pb:3}}>
                    <Grid item xs={12}>
                        <Box sx={{display:"flex",justifyContent:"space-between"}}>
                            <StyledTypography>Tenant Details</StyledTypography>
                           
                        </Box>
                    </Grid>
                  
                    <Grid item xs={4}>
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}></Typography>
                        <SecondaryTypography>{tenantData?.company_name }</SecondaryTypography>
                    </Grid>
                    <Grid item xs={4}>
                        <SecondaryTypography>Office:{tenantData?.country_code} {tenantData?.phone_no}</SecondaryTypography>
                        <SecondaryTypography>Mobile:{tenantData?.country_code} {tenantData?.phone_no}</SecondaryTypography>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sx={{p:3}}>
                        <Grid item xs={4}>
                            <StyledTypography>Requested Period </StyledTypography>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>{moment(reviewData?.from_date).format('DD-MM-YYYY')} - {moment(reviewData?.to_date).format('DD-MM-YYYY')}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <SecondaryTypography>Time: {reviewData?.from_time} - {reviewData?.to_time}</SecondaryTypography>
                            <SecondaryTypography>Location: #{reviewData?.level} - {reviewData?.unit}</SecondaryTypography>
                        </Grid>
                </Grid>
                <Grid item xs={12} sx={{p:3}}>
                    <StyledTypography>Request Title</StyledTypography>
                    <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:500,mt:1}}>{reviewData?.request_title}</Typography>
                </Grid>
                <Grid item xs={12} sx={{p:3}}>
                    <StyledTypography>Request Details</StyledTypography>
                    <Typography  sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:500,mt:1}}>{reviewData?.description}</Typography>
                </Grid>
                <Grid item xs={12} sx={{p:3}}>
                    <Box sx={{display:'flex',justifyContent:"space-between"}}>
                        <StyledTypography>Supporting Materials (optional)</StyledTypography>
                        {submittedfiles?.length > 0 &&  <Button data-html2canvas-ignore='true' onClick={()=>handleFileDownload()} variant="outlined" endIcon={<DownloadIcon />} sx={{ color: '#0F2C6F', fontSize: '14px', textTransform: 'capitalize', fontWeight: 600, '&:hover': { backgroundColor: "#FFFFFF" } }}>Download All Attachments</Button>}
                    </Box>

                    <Box sx={{mt:2}}>
                    {
                        submittedfiles?.length > 0 ?
                        submittedfiles.map((value)=>(
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #DBDFE9', borderRadius: "8px", mt: 2, p: 2 }}>
                                <Typography sx={{ color: " #0F2C6F", fontSize: '14px' }}>{value.original_name}
                                    {/* <span style={{ color: "#9AA6C3", fontSize: '12px', marginLeft: '10px' }}>({Math.round(value.size / 1000)} of {Math.round(value.size / 1000)}KB)</span> */}
                                    </Typography>
                                <DownloadIcon onClick={()=>handleFileDownload(value.path,value.original_name)} />
                            </Box>
                        )) : <Typography sx={{display:"flex",justifyContent:"center",color:"#0F2C6F"}}>No Attachments </Typography>
                       
                    }
                    </Box>
                    
                </Grid>
                <Grid item xs={12} sx={{p:3}}>
                    <StyledTypography>{ terms && terms[0]?.title}*</StyledTypography>
                    <Typography sx={{color:"#6F80A9",fontSize:"14px",mt:1}}>{terms && terms[0]?.description}</Typography>
                    {terms && terms[1].rules.map((value,index)=>(
                                <Typography sx={{color:"#6F80A9",fontSize:"12px",my:1}}>{index+1} . {value[`${index+1}`]}</Typography>
                            ))}
                    
                    <Box sx={{display:"flex",mt:1}}>
                        <Checkbox checked disabled />
                        <Typography sx={{color:"#9AA6C3",fontSize:"14px"}}>I have read and agreed to comply with the above terms and conditions for holding the event in the Building upon the Management’s approval.</Typography>
                    </Box>
                   
                </Grid>
                
                <Box data-html2canvas-ignore='true' sx={{display:"flex",justifyContent:"flex-end",backgroundColor:"rgba(143, 146, 161,0.05)",width:"100%",py:4}}>
                    {/* <Typography sx={{color:"#9AA6C3",fontSize:"14px"}}><RefreshIcon style={{marginRight:"10px"}} />Last Updated at 10:00</Typography> */}
                    <Box>
                        {renderButton()}
                        {/* <Button variant="contained" sx={{color:"#FFFFFF",backgroundColor:"",textTransform:"capitalize"}}>Update Status</Button> */}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3.5}>
                <Box sx={{ backgroundColor: '#FFFFFF', mx: 3 }}>
                    <Box sx={{borderBottom:"1px solid #C0C7D7",py:2,px:3,borderRadius:"8px 8px 0 0 "}}>
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>Status Updates</Typography>
                    </Box>
                    <Box sx={{p:2}}>
                        {role_id==4 ? 
                            <StatusStepper type='ServiceRequest' currentStatus={reviewData?.status} withdrawFunc={stepperWithdrawFunction} statusLogs={serviceReqDetails?.serviceRequestLogTempArray?.length >0 ? serviceReqDetails?.serviceRequestLogTempArray : []} /> : 
                            <StatusStepper currentStatus={reviewData?.status} withdrawFunc={stepperWithdrawFunction} statusLogs={serviceReqDetails?.serviceRequestsLogsArrayList?.length >0 ? serviceReqDetails?.serviceRequestsLogsArrayList : []} />
                        }
                    </Box>
                </Box>
                
           </Grid>
        </Grid>
        <Modal open={open} onClose={()=>setOpen(false)}>
            <Box sx={{position:'absolute',backgroundColor:"#FFFFFF",left:'25%',top:'15%',width:"40%"}}>
                <Box sx={{display:"flex",justifyContent:"space-between",borderBottom:"1px solid #C0C7D7",p:3}}>
                    <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Approve Ad-Hoc Request</Typography>
                    <CloseIcon onClick={()=>setOpen(false)} />
                </Box>
                <Box sx={{p:3}}>
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>The tenant will be notified of the approval for ad-hoc request.</Typography>
                    <Typography sx={{color:"#0F2C6F",fontSize:"14px",mt:3,mb:1}}>Please provide some details on how BMS will handle this request</Typography>
                    <OutlinedInput inputProps={{maxLength:300}} onChange={(e)=>{
                        setNote(e.target.value)
                        setError({
                            ...error,
                            notes:""
                        })
                        }} sx={{width:"80%",color:"#0F2C6F"}} />
                    <Typography sx={{color:"#9AA6C3",fontSize:"14px",mt:1}}>[{note?.length}/300]</Typography>
                    {error.notes && <Typography sx={{color:"red"}}>{error.notes}</Typography>}
                </Box>
                <Box sx={{display:"flex",justifyContent:"space-between",p:3}}>
                    <Button onClick={()=>setOpen(false)} variant="outlined" sx={{textTransform:"capitalize",color:'#0F2C6F'}}>Back</Button>
                    <Button 
                    onClick={()=>{
                        if(!note){
                            setError({
                                ...error,
                                notes:"Should not be empty"
                            })
                        }else{
                            handleAlertPopup('approved',2)
                        }
                    }} 
                    variant="contained" sx={{textTransform:"capitalize",backgroundColor:"#123FA9",borderRadius:"6px"}}>Approve</Button>
                </Box>
            </Box>
        </Modal>
        <Modal open={openAcknowledgeAlert}>
                <Box sx={{ position: "absolute", backgroundColor: "#FFFFFF", left: '25%', top: '10%', width: "40%" }}>
                    <Box sx={{ display: "flex", justifyContent: 'space-between', borderBottom: "1px solid #C0C7D7", p: 3 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "18px" }}>Acknowledgement of Miscellaneuos Adhoc Request</Typography>
                        <CloseIcon onClick={() => setOpenAcknowledgeAlert(false)} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ p: 3 }}>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>By clicking this button means you have acknowledge the completation of the request and agree to the invoice then turn it to a resolved case. If there is any inconvenient, please contact BMS 6824 8333</Typography>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 3, mb: 1 }}>Feedback on Work</Typography>
                        <OutlinedInput inputProps={{ maxLength: 300 }} onChange={(e) => setAcknowledgeFeedback(e.target.value)} fullWidth />
                        <Typography sx={{ mt: 1, color: " #9AA6C3", fontSize: "14px" }}>[{acknowledgeFeedback?.length}/300]</Typography>
                        {error.acknowledge && <Typography sx={{color:"#f44336"}}>{error.acknowledge}</Typography>}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
                        <Button sx={{
                            textTransform: "capitalize",
                            color: "#0F2C6F",
                            border: "1px solid #AAC3FF",
                            borderRadius: "6px"
                        }} onClick={() => setOpenAcknowledgeAlert(false)} >Cancel</Button>
                        <Button
                        onClick={updateAcknowledgement}
                   
                         sx={{
                            color: "#FFFFFF",
                            backgroundColor: "#123FA9",
                            textTransform: "capitalize",
                            borderRadius: "6px",
                            "&:hover": { backgroundColor: "#123FA9" }
                        }}>Acknowledge</Button>
                    </Box>

                </Box>
            </Modal>
        </> }
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
        serReqUpdateStatus: state.Service_Req_Reducer.updateStatus,
        updateWatchStatus:state.Service_Req_Reducer.updateWatchStatus,
        notification:state.DashboardReducer.notification,
        isUnAuthorized:state.Service_Req_Reducer.isUnAuthorized

    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        serviceRequestByID: (data) => (dispatch(serviceRequestAction.serviceRequestDetailsById(data))),
        updateServiceRequestStatus: (data) => (dispatch(serviceRequestAction.updateServiceRequestStatus(data))),
        setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
        updateWatch: (data) => (dispatch(serviceRequestAction.updateWatchStatus(data))),

    }
}


export default connect(mapStateToProps,mapDispatchToProps)(ReviewMiscADHOCRequest);