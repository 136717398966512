import { getLoggedUserData, getTimeStamp, getYYYYMMDDFormat, MenuProps, preventDatePickerKeyboard } from "../../Utils/utils";
import { connect } from "react-redux";
import { CompanyReportColumn } from "../../components/Data/Data";
import '../../index.css';
import React, { useEffect, useState } from "react";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { ReactComponent as DownloadIcon } from '../../Assets/icons/Download.svg';
import { Box, Grid, FormControl, InputLabel, MenuItem, Pagination, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography, OutlinedInput, Button, CircularProgress, Input, IconButton } from "@mui/material";
import { styled } from "@mui/styles";
import { getStatus } from "../../Utils/ActiveStatus";
import * as ReducerAction from "../../redux/actions/reportAction/index"
import axios from "axios";
import moment from "moment";
import * as commonAction from "../../redux/actions/commonAction";
import { useDebounce } from "use-debounce";
import { ReactComponent as CloseIcon } from '../../Assets/icons/Close.svg';
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg';
import {ReactComponent as ArrowUpwardIcon} from '../../Assets/icons/sortasc.svg';
import {ReactComponent as ArrowDownwardIcon} from '../../Assets/icons/sortdes.svg';
import {ReactComponent as ArrowdDisableIcon} from '../../Assets/icons/sortDisable.svg';

const StyledTableRow = styled(TableRow)({
    "&:nth-of-type(odd)": {
        backgroundColor: "#FAFBFF"
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F !important',
    padding: '7px !important',
    textAlign: "left",
    height: '50px',
    [`&.${tableCellClasses.head}`]: {
        fontSize: '13px',
        fontWeight: 550
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        fontWeight: 500,
    },
}));

const StyledTypography = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#0F2C6F',
    marginBottom: '5px',


})

const sortColumns = {
    'Company Name': 'ASC',
    'Email': 'ASC',
    'Tenant Manager': 'ASC',
    'Created On': 'ASC',
    'Office Unit': 'ASC'
}

const payloadSortFields = {
    'Company Name': 'company_name',
    'Email': 'company_email',
    'Tenant Manager': 'manager_name',
    'Created On': 'created_on',
    'Office Unit': 'level_name'
}

const sortColumnNames = ['Company Name','Email','Tenant Manager','Created On','Office Unit']

const CompanyReport = ({ handleBackButton, getCompanyReport, companyDatas, getAllLevels, levelDatas }) => {
    // const [currentPage, setCurrentPage] = useState(1)
    const [filterDatas, setFilterDatas] = useState({
        status: '',
        rows: '10',
        selectedLevel: "",
        currentPage: '',
        order_by: '',
        order_by_type: "",
    })
    const [disabled, setDisabled] = useState(false)
    const [loader, setLoader] = useState(false)

    const [selectedLevel, setSelectedLevel] = useState('');
    const [searchDebounce, setSearchDebounce] = useDebounce('', 1500);
    const [searchText, setSearchText] = useState('');
    const [sortColumnData, setSortColumnData] = useState(sortColumns)
    const [selectedSort, setSelectedSort] = useState({company_name:true,email:false,tenant_manager:false,access_level:false,phone_no:false, approved_status: false, name:false,created_at:false,onboarded_employee_count: false,offboard_count:false,total_count:false})
  
    useEffect(() => {
        getAllLevels();
    }, [])

    useEffect(() => {
        getCompanyReport({
            approved_status: filterDatas.status == '' ? 'NA' : filterDatas.status,
            limit: filterDatas.rows,
            offset: filterDatas.currentPage - 1,
            user_id: getLoggedUserData()?.user_id,
            level_id: filterDatas.selectedLevel,
            search: searchText,
            order_by: payloadSortFields[filterDatas.order_by],
            order_by_type: filterDatas.order_by_type
        })
    }, [filterDatas, searchDebounce,])

 
    const callDownload = async () => {
        setDisabled(true)
        setLoader(true)
        try {
            let token = localStorage.getItem('userToken')
            let config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'arraybuffer'
            };
            let response = await axios.get(companyDatas?.response_json?.data_array?.excel_path, config)
            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const url = URL.createObjectURL(blob)
            let tag = document.createElement("a")
            tag.setAttribute('href', url)
            tag.setAttribute('download', `Company Report ${moment(new Date()).format('DD MMM YYYY')}`)
            tag.click()
            setDisabled(false)
            setLoader(false)
        } catch (err) {
            setDisabled(false)
        }
    }

    useEffect(() => {
        if (!companyDatas?.response_json?.response_status == 1) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [companyDatas])

    const handleFilterDetails = (e, datafrom) => {
        if (datafrom) {
            setFilterDatas({
                ...filterDatas,
                [datafrom]: e,
            })
            setFilterDatas(prevValue => ({...prevValue, currentPage: 1}))
        } else {
            let { name, value } = e.target
            setFilterDatas({
                ...filterDatas,
                [name]: value,
            })
            setFilterDatas(prevValue => ({...prevValue, currentPage: 1}))
         }
    }
    const handlePagination = (e, value) => {
        setFilterDatas(prevValue => ({...prevValue, currentPage: value}))
        
    }
    const handleReset = () => {
        setFilterDatas({
            status: '',
            rows: '10',
            selectedLevel: "",
            currentPage: '',
            order_by: '',
            order_by_type: "",
        })
        setSearchText('');
        setSortColumnData(sortColumns);
    }

    const handleSortClick = (val, sortVal, keyName) => { 
        const madeStatusFalse = {company_name:false,email:false,tenant_manager:false,access_level:false,phone_no:false, approved_status: false, name:false,created_at:false,onboarded_employee_count: false,offboard_count:false,total_count:false}    
        setSelectedSort({...madeStatusFalse,[keyName]:true}) // disable all other sorting when one sorting is enabled
        let columnSortData = {...sortColumnData};
         for(const key in columnSortData){
           if(key === val){
              columnSortData[key] = sortVal === 'ASC' ? 'DESC' : 'ASC';
             setFilterDatas(prevValue => ({...prevValue, order_by: val, order_by_type: sortVal === 'ASC' ? 'DESC' : 'ASC' }))
           }else{
               columnSortData[key] = 'ASC';
            }
        }
        setSortColumnData({...columnSortData});
    } 

    const clickSorting = (columnName, sortColumnData, keyName) => {
        if(sortColumnNames.includes(columnName))  handleSortClick(columnName, sortColumnData, keyName)
    }
  
    return (
        <>
            <Box>
                <BackButton onClick={() => handleBackButton()} />
                <Typography sx={{ color: '#0F2C6F', fontSize: '24px', fontWeight: 540, my: 1.5 }}>Company Overview</Typography>

                <Box sx={{ my: 2, backgroundColor: '#FFFFFF' }}>
                    <Box sx={{display: 'flex', alignItems:"center", justifyContent: "space-between",p: 2}}>
                    <Box sx={{ alignItems: "center", display: 'flex', gap: 1 }} columnSpacing={2}>
                        <Box>
                            <FormControl size="small" sx={{ width: 180, }} >
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
                                <Select value={filterDatas.status} name='status' onChange={(e) => handleFilterDetails(e)} MenuProps={MenuProps} label='Status'>
                                    <MenuItem value={'approved'}>Approved</MenuItem>
                                    <MenuItem value={'rejected'}>Rejected</MenuItem>
                                    <MenuItem value={'submitted'}>Submitted</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box>
                            <FormControl size='small' sx={{ width: 180, }}>
                                <InputLabel
                                    sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px' }}
                                >
                                    Level
                                </InputLabel>
                                <Select
                                    value={filterDatas.selectedLevel || ''}
                                    label="level_no"
                                    name="level_no"
                                    onChange={(e) => {
                                        setFilterDatas(prevValue => ({...prevValue, selectedLevel: e.target.value}));
                                    }}
                                    MenuProps={{ style: { maxHeight: 300 } }}
                                >
                                    <MenuItem sx={{ color: "#0F2C6F" }} value="All">All</MenuItem>
                                    {levelDatas !== null && Array.isArray(levelDatas) && levelDatas.map((item, index) => (
                                        <MenuItem sx={{ color: "#0F2C6F" }} key={index} value={item?.level_id}>{item.level_name}</MenuItem>
                                    ))}


                                </Select>
                            </FormControl>
                        </Box>

                        <Box
                            sx={{
                                float: "right",
                                padding: '5px',
                                display: "flex",
                                alignItems: "center",
                                border: " 1px solid #DBDFE9",
                                borderRadius: "6px",
                                mr: 2
                            }}
                        >
                            <Input
                                name={'search'}
                                size="small"
                                type="text"
                                placeholder='Search'
                                style={{
                                    border: "none",
                                    outline: "none",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    width: 180,

                                }}
                                inputProps={{
                                    sx: {
                                        height: { md: "25px", sm: "20px", xs: "4px" },
                                        fontSize: { md: "16px", sm: "14px", xs: "12px" },
                                        flexGrow: 1,
                                        width: "inherit",
                                    },

                                }}
                                value={searchText}
                                disableUnderline
                                onChange={e => {
                                    setSearchDebounce(e.target.value)
                                    setSearchText(e.target.value)
                                }}
                            />
                            <Box sx={{ display: 'flex', flexGrow: 0, alignItems: 'center',gap: 1 }}>
                                {searchText && <Box onClick={() => {
                                    setSearchDebounce("");
                                    setSearchText("")
                                }} sx={{cursor: "pointer"}}> <CloseIcon style={{ width: 15, height: 15, }} /> </Box>}
                                <SearchIcon cursor="pointer" />
                            </Box>
                        </Box>

                        <Box>
                            <Typography sx={{ color: "#2764F4", textDecoration: "underline", fontSize: "16px", textUnderlineOffset: "5px", cursor: "pointer" }} onClick={() => handleReset()}>Reset</Typography>
                        </Box>


                        {/* <Grid item xs={2}>
                            <OutlinedInput size="small" />
                           
                        </Grid> */}
                    </Box>
                        <Box>
                            <Button
                                variant='contained'
                                disabled={disabled}
                                onClick={callDownload}                                
                                sx={{
                                    textTransform: 'none',
                                    color: '#FFFFFF',
                                    fontSize: { md: '14px', xs: '12px' },
                                    padding: { md: '10px', xs: '5px' },
                                    backgroundColor: '#123FA9',
                                    position: "relative",
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                Download .xls
                                <DownloadIcon style={{height: 25, width: 25}} />
                                {loader && <CircularProgress size={20} sx={{color: "#123FA9"}} />}
                            </Button>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {CompanyReportColumn.map((value) => (
                                        
                                        <StyledTableCell onClick={() => clickSorting(value.name,sortColumnData[value.name], value.key)}>
                                            <Box sx={{display: 'flex', alignItems: "center", gap: 1, cursor:sortColumnNames.includes(value.name)? 'pointer':'normal'}}>
                                            {value.name}
                                             {sortColumnData[value.name] !== undefined && (
                                            <Box sx={{flex:1, alignSelf:'center', display:'flex',justifyContent:'flex-end'}}><IconButton  onClick={() => handleSortClick(value.name,sortColumnData[value.name], value.key)}>
                                            {/* {sortColumnData[value.name] === 'ASC' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />   } */}
                                            {selectedSort[value.key ] ? sortColumnData[value.name] === 'ASC' ? <ArrowUpwardIcon />   : <ArrowDownwardIcon  />   :  <ArrowdDisableIcon /> }
                                            </IconButton ></Box> ) }
                                            </Box>
                                            </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {companyDatas?.response_json?.response_status == 1 && <TableBody>
                                {companyDatas?.response_json?.data_array?.companyArrayList?.map((value) => (
                                    <StyledTableRow>
                                        <StyledTableCell sx={{ color: "#2764F4  !important" }}>{value.company_name}</StyledTableCell>

                                        <StyledTableCell>{value.email}</StyledTableCell>
                                        <StyledTableCell>{value.tenant_manager}</StyledTableCell>
                                        <StyledTableCell>{value.access_level}</StyledTableCell>
                                        <StyledTableCell>{value.country_code} {value.phone_no}</StyledTableCell>
                                        <StyledTableCell
                                        ><Typography sx={{
                                            background: getStatus(value.approved_status, 'bgcolor'),
                                            display: "inline-flex",
                                            padding: '5px',
                                            fontWeight: 400,
                                            fontSize: "13px",
                                            color: getStatus(value.approved_status, 'color'),
                                            borderRadius: "6px",
                                        }}>{getStatus(value.approved_status, 'text')}</Typography></StyledTableCell>
                                        <StyledTableCell>{value.name}</StyledTableCell>
                                        <StyledTableCell>{getTimeStamp(value.created_at)}</StyledTableCell>
                                        <StyledTableCell>{value.onboarded_employee_count}</StyledTableCell>
                                        <StyledTableCell>{value.offboard_count}</StyledTableCell>
                                        <StyledTableCell>{value.total_count}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            }
                        </Table>
                    </TableContainer>
                    {companyDatas?.response_json?.response_status == 1 ?
                        <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)', py: 3 }}>
                            <Grid item xs={9}>
                                <Pagination onChange={handlePagination} sx={{ display: 'flex', justifyContent: 'center' }} size="large" count={Math.ceil(companyDatas?.response_json?.data_array?.totalRecords / filterDatas.rows)} page={filterDatas.currentPage * 1} shape="rounded" />
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography sx={{ color: '#0F2C6F' }}>Show per page</Typography>
                                <Select value={filterDatas.rows} name="rows" onChange={(e) => {
                                    handleFilterDetails(e)
                                    setFilterDatas(prevValue => ({...prevValue, currentPage: 1}))
                                }} size="small" sx={{ height: '40px', width: 100, ml: 2, color: '#0F2C6F' }} >
                                    <MenuItem value="10">10</MenuItem>
                                    <MenuItem value="20">20</MenuItem>
                                    <MenuItem value="50">50</MenuItem>
                                    <MenuItem value="100">100</MenuItem>
                                </Select>

                            </Grid>
                        </Grid> :
                        <Box sx={{ display: "flex", justifyContent: "center", m: 5 }}>
                            <Typography sx={{ textAlign: "center", fontSize: "20px", color: '#0F2C6F' }}>No data found</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        companyDatas: state.ReportsReducer.companyDatas,
        levelDatas: state.CommonReducer.levelDatas,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyReport: (data) => dispatch(ReducerAction.getCompanyReports(data)),
        getAllLevels: () => (dispatch(commonAction.getAllLevels())),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyReport)