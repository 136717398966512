import { Box, Button, Chip, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import Chart from "react-apexcharts"; 
import {ReactComponent as DatePickerIcon} from "../../Assets/icons/DatePickerIcon.svg";
import {ReactComponent as NotifyIcon} from "../../Assets/icons/dashboardnotification.svg";
import { getLoggedUserData, getYYYYMMDDFormat, preventDatePickerKeyboard } from "../../Utils/utils";
import * as DashboardAction from "../../redux/actions/Dashboard Action/index"
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const StyledPrimaryTypography = styled(Typography)({
    color:" #0F2C6F",
    fontSize:"16px",
    fontWeight:550
})

const StyledSecTypography = styled(Typography)({
  color:'#0F2C6F',
  fontSize:"14px",
  fontWeight:500
})

const StyledButton = styled(Button)({
    color:"#0F2C6F",
    height:'48px',
    fontSize:"14px",
    fontWeight:550,
    border:'1px solid #2764F4',
    borderRadius:'6px',
    textTransform:'capitalize',
    "&:hover":{
        backgroundColor:"#FFFFFF"
    }
})

const checkedStyle = { backgroundColor: '#FFFFFF', color: "#6F80A9", border: '1px solid #6F80A9',mr:2, '&:hover': { backgroundColor: '#FFFFFF' } }
const uncheckedStyle = { backgroundColor: "#123FA9", color: '#FFFFFF', mr: 2, '&:hover': { backgroundColor: '#123FA9' } }
let SelectDays = ["Today","Yesterday","Last 7 days","Last 30 days","Last 90 days","Last 1 year","Custom"]  

const BmsManagerDashboard = ({getDbData,dbData})=>{
  let navigate = useNavigate()
  const [rangeDate,setRangeDate] = useState(null)
  const [filterDate,setFilterDate] = useState("Last 90 days")
  const [tenantStatus,setTenantStatus] = useState({
    company:true,
    employee:false
  })
  const [faultData,setFaultData] = useState({
    ongoing:null,
    completed:null,
    withdraw:null,
    total:null
  })
  const [serviceData,setServiceData] = useState({
        ongoing:null,
        completed:null,
        withdraw:null,
        total:null
  })
  const [accessData,setAccessData] = useState({
        tenant:null,
        visitor:null,
        invites:null,
        total:null
  })
  const [staffData,setStaffData] = useState({
      active:null,
      onboarding:null,
      offboarding:null,
      total:null
  })
  const [tenantData,setTenantData] = useState({
      activeEmp:null,
      onboardingEmp:null,
      offboardingEmp:null,
      totalEmp:null,
      activeTenant:null,
      onboardingTenant:null,
      offboardingTenant:null,
      totalTenant:null  
  })
  const [customDate,setCustomDate] = useState({
    start:null,
    end:null
  })

  useEffect(()=>{
    let today = new Date()
    let date = new Date()

    if(filterDate=="Yesterday"){
        date.setDate(today.getDate()-1)
    }else if(filterDate=="Last 7 days"){
        date.setDate(today.getDate()-7)
    }else if(filterDate=="Last 30 days"){
        date.setDate(today.getDate()-30)
    }else if(filterDate=="Last 90 days"){
        date.setDate(today.getDate()-90)
    }else if(filterDate=="Last 1 year"){
        date.setDate(today.getDate()-365)
    }

    if(filterDate!="Custom"){ 
        let data = {
          start_date: getYYYYMMDDFormat(date) ,
          end_date:getYYYYMMDDFormat(new Date()),
          limit:10,
          offset:0,
          currentMonth:''
        }
        getDbData(data)
    }else if((customDate.start!=null && customDate.end!=null)){
      let data = {
        start_date: getYYYYMMDDFormat(customDate.start) ,
        end_date: getYYYYMMDDFormat(customDate.end),
        limit:10,
        offset:0,
        currentMonth:''
      }
      getDbData(data)
    }
   
  },[filterDate,customDate])

  useEffect(()=>{
      getDateRange()
  },[filterDate])

  useEffect(()=>{
    setFaultData({
      ongoing:dbData.ongoing_fault_report,
      completed:dbData.completed_fault_report,
      withdraw:dbData.withdraw_fault_report,
      total:dbData.total_fault_report
    })

    setServiceData({
      ongoing:dbData.ongoing_service_request,
      completed:dbData.completed_service_request,
      withdraw:dbData.withdraw_service_request,
      total:dbData.total_service_requests
    })

    setAccessData({
      tenant:dbData.tenant,
      visitor:dbData.visitors,
      invites:dbData.invite,
      total:dbData.total_accesses
    })

    setStaffData({
      active:dbData.active_bms_staff,
      onboarding:dbData.onboarding_bms_staff,
      offboarding:dbData.offboarding_bms_staff,
      total:dbData.total_lots,
    })

    setTenantData({
      activeEmp:dbData.active_employees,
      onboardingEmp:dbData.onboarding_employees,
      offboardingEmp:dbData.offboarding_employees,
      totalEmp:dbData.total_employees,
      activeTenant:dbData.active_companies,
      onboardingTenant:dbData.onboarding_companies,
      offboardingTenant:dbData.offboarding_companies,
      totalTenant:dbData.total_companies
    })

  },[dbData])

  const getDateRange = ()=>{
    const today = new Date()
    const previousDate = new Date()

    if(filterDate=="Today"){
        setRangeDate(today)
    }else if(filterDate=="Yesterday"){  
        previousDate.setDate(previousDate.getDate()-1)
        setRangeDate(previousDate)
    }else if(filterDate=="Last 7 days"){
        previousDate.setDate(previousDate.getDate()-7)
        setRangeDate(previousDate)
    }else if(filterDate=="Last 30 days"){
        previousDate.setDate(previousDate.getDate()-30)
        setRangeDate(previousDate)
    }else if(filterDate=="Last 90 days"){
        previousDate.setDate(previousDate.getDate()-90)
        setRangeDate(previousDate)
    }else if(filterDate=="Last 1 year"){
        previousDate.setDate(previousDate.getDate()-365)
        setRangeDate(previousDate)
    }
  }

  const renderBarChart = (horizontal,data1,data2,data3)=>{
    let DASHBOARD_CHART_DATA = {
      series: [
        {
         name:[""],
          data:[data1,data2,data3]
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal:horizontal,
            distributed:true,
            borderRadius: 0,
            dataLabels: {
              position: "", // top, center, bottom
            },
          },
        },
        colors:['#0F2C6F','#2764F4','#AAC3FF'],
        dataLabels: {
          enabled: false,
          formatter: function (val) {
            return val ;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },
        xaxis: {
          categories: ['','',''],
          position: "bottom",
          labels:{
            show:false
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#B7C0D4",
                colorTo: "#B7C0D4",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
          
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val ;
            },
          },
        },
        title: {
          floating: true,
          offsetY: 0,
          align: "left",
          style: {
            color: "#444",
          },
        },
        tooltip:{
          x:{
            show:false
          },
          y:{
            show:false
          }
        },
        legend:{
          show:false
        }
      },
  };
      return(
        <>
          <Chart
                style={{
                        boxShadow: "none",
                         margin: "-20px 0 0 -10px",
                  }}
                options={DASHBOARD_CHART_DATA.options}
                series={DASHBOARD_CHART_DATA.series}
                type="bar"
                height="220px"
          />
        </>
      )
  }

  const renderRoundChart = (data1,data2,data3,label1,label2,label3)=>{
    var chart = {
      series: [data1, data2, data3],
      options:{
          chart: {
              width: 380,
              type: 'donut',
              dropShadow: {
                enabled: true,
                color: '#111',
                top: -1,
                left: 3,
                blur: 3,
                opacity: 0.2
              }
            },
            labels:[label1,label2,label3],
            stroke: {
              width: 0,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      showAlways:false,
                      show: true
                    }
                  }
                }
              }
            },
            colors:['#0F2C6F','#2764F4','#AAC3FF'],
            dataLabels: {
              enabled:false,
              dropShadow: {
              
                blur: 3,
                opacity: 0.8
              }
            },
            
            states: {
              hover: {
                filter: 'none'
              }
            },
            legend:{
              show:false
            }
      }
    };
      return(
        <>
           <Chart type="donut" height="200px" series={chart.series} options={chart.options} ></Chart>
        </>
      )
  }

  const renderCountList = (totalName,totalCount,listoneName,listoneCount,listtwoName,listtwoCount,listthreeName,listthreeCount)=>{
    return(
      <>
      <List>
          <ListItem secondaryAction={<StyledPrimaryTypography>{totalCount}</StyledPrimaryTypography>}>
              <ListItemText>
                  <StyledPrimaryTypography>{totalName}</StyledPrimaryTypography>
              </ListItemText>
          </ListItem>
      </List>
      <Divider sx={{border:"1px solid #DBDFE9"}} />
      <List>
            <ListItem secondaryAction={<StyledSecTypography>{listoneCount}</StyledSecTypography>}>
                  <ListItemIcon>
                      <Box sx={{height:"8px",width:"8px",backgroundColor:"#0F2C6F",borderRadius:"45%"}}>
                      </Box>
                  </ListItemIcon>
                  <ListItemText>
                          <StyledSecTypography>{listoneName}</StyledSecTypography>
                 </ListItemText>
            </ListItem>
            <Divider sx={{border:"1px solid #DBDFE9"}} />
            <ListItem secondaryAction={<StyledSecTypography>{listtwoCount}</StyledSecTypography>}>
                  <ListItemIcon>
                      <Box sx={{height:"8px",width:"8px",backgroundColor:"#2764F4",borderRadius:"45%"}}>
                      </Box>
                   </ListItemIcon>
                  <ListItemText>
                       <StyledSecTypography>{listtwoName}</StyledSecTypography>
                  </ListItemText>
            </ListItem>
            <Divider sx={{border:"1px solid #DBDFE9"}} />
            <ListItem secondaryAction={<StyledSecTypography>{listthreeCount}</StyledSecTypography>}>
                  <ListItemIcon>
                        <Box sx={{height:"8px",width:"8px",backgroundColor:"#AAC3FF",borderRadius:"45%"}}>
                        </Box>
                  </ListItemIcon>
                  <ListItemText>
                        <StyledSecTypography>{listthreeName}</StyledSecTypography>
                   </ListItemText>
            </ListItem>
      </List>
      </>
    )
  }

    return(
        <>
            <Box sx={{py:7,px:2,backgroundColor:"rgba(143, 146, 161,0.05)"}}>
                <Box sx={{display:'flex',justifyContent:"space-between",mb:4}}>
                    <Typography sx={{color:"#002D74",fontSize:"32px",textTransform:"capitalize"}}>Welcome {getLoggedUserData()?.user_name}</Typography>
                    <Box sx={{display:'flex'}}>
                    <Select defaultValue={"Last 90 days"} value={filterDate} sx={{width:150,mr:3,color:"#0F2C6F"}} onChange={(e)=>setFilterDate(e.target.value)}>
                        {SelectDays.map((value)=>(
                            <MenuItem value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                    {filterDate=="Custom" ? 
                      <>
                        <Box sx={{width:150}}>
                          <DatePicker inputFormat="dd MMM yyyy" disableFuture  value={customDate.start}  components={{
                              OpenPickerIcon:DatePickerIcon
                          }} onChange={(e)=>setCustomDate({
                            ...customDate,
                            start:e
                          })} renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                        </Box>
                        <Typography sx={{display:"flex",alignItems:"center",mx:0.5}}> - </Typography>
                        <Box sx={{width:150}}>
                            <DatePicker minDate={customDate.start} onChange={(e)=>setCustomDate({
                            ...customDate,
                            end:e
                            })} disableFuture   components={{
                              OpenPickerIcon:DatePickerIcon
                          }} inputFormat="dd MMM yyyy" value={customDate.end}  renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)}  {...params} />} />
                        </Box>
                      </> : 

                      <>
                        <Box sx={{width:120}}>
                          <DatePicker inputFormat="dd MMM yyyy" disableOpenPicker  disabled value={rangeDate}  components={{
                              OpenPickerIcon:DatePickerIcon
                          }} renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                        </Box>
                        <Typography sx={{display:"flex",alignItems:"center",mx:0.5}}> - </Typography>
                        <Box sx={{width:120}}>
                            <DatePicker inputFormat="dd MMM yyyy"  disableOpenPicker disabled renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)}  {...params} />} />
                        </Box>
                      </>
                    
                    }
                    
                </Box>
                </Box>

                {/* <Box sx={{border:"1px solid #2764F4",borderRadius:"6px",display:"flex",justifyContent:"space-between",my:2,px:3,py:1}}>
                    <Typography sx={{color:"#2764F4",fontSize:"14px"}}> <NotifyIcon style={{marginTop:"8px",marginRight:'18px'}} /> 
                        There will be fire drill on 30 October 2022, please be prepare to be at the meeting point of the building.
                    </Typography>
                    <Button variant="text" sx={{textTransform:"capitalize",textDecoration:'underline'}}>View All</Button>
                </Box> */}

                <Grid container >
                    <Grid item xs={3.8} sx={{backgroundColor:"#FFFFFF",p:2}}>
                    <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                    <Box>
                                        <StyledPrimaryTypography>Fault Report</StyledPrimaryTypography>
                                        <StyledSecTypography>Total: {faultData.total}</StyledSecTypography>
                                    </Box>
                                    <StyledButton variant="outlined" onClick={()=>navigate(`/manage_faults/0`)}>Manage</StyledButton>
                    </Box>
                                {/* <Typography>New report: </Typography> */}
                                {renderBarChart(false,faultData.ongoing,faultData.completed,faultData.withdraw)}           
                                {renderCountList("Total Report",faultData.total,"Ongoing",faultData.ongoing,"Completed",faultData.completed,"Withdrawn",faultData.withdraw)}
                    </Grid>
                    
                    <Grid item xs={0.2} />

                    <Grid item  xs={3.9} sx={{backgroundColor:"#FFFFFF",p:2,maxHeight:'550px'}}>
                                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                        <Box>
                                            <StyledPrimaryTypography>Service Request</StyledPrimaryTypography>
                                            <StyledSecTypography>Total: {serviceData.total}</StyledSecTypography>
                                        </Box>
                                        <StyledButton variant="outlined" onClick={()=>navigate(`/service_request_list/0`)
                                        }>Manage</StyledButton>
                                    </Box>
                                    {/* <Typography>New report: 3</Typography> */}
                                    {renderBarChart(true,serviceData.ongoing,serviceData.completed,serviceData.withdraw)}  
                                    {renderCountList("Total Requests",serviceData.total,"Ongoing",serviceData.ongoing,"Completed",serviceData.completed,"Withdrawn",serviceData.withdraw)}
                                    
                            </Grid>

                            <Grid item xs={0.2} />

                            <Grid item xs={3.8} sx={{backgroundColor:"#FFFFFF",p:2}}>
                                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                    <StyledPrimaryTypography>Tenant</StyledPrimaryTypography>
                                    <StyledButton variant="outlined" onClick={()=>navigate(`/onboard_new_tenant/0/0`)
                                    }>Manage</StyledButton>
                                </Box>

                                <Box sx={{display:"flex",my:2}}>
                                    <Chip onClick={()=>{
                                        setTenantStatus({
                                          company:true,
                                          employee:false
                                        })
                                    }} sx={tenantStatus.company ? uncheckedStyle : checkedStyle}  label="Company" />
                                    <Chip onClick={()=>{
                                        setTenantStatus({
                                          company:false,
                                          employee:true
                                        })
                                    }} sx={tenantStatus.employee ? uncheckedStyle : checkedStyle}  label="Employee"  />
                                </Box>
                                {tenantStatus.employee ?  
                                <>
                                    {renderRoundChart(tenantData.activeEmp,tenantData.onboardingEmp,tenantData.offboardingEmp,"Active","Onboarding","Offboarding")}
                                    {renderCountList("Total Employee",tenantData.totalEmp,"Active",tenantData.activeEmp,"Onboarding",tenantData.onboardingEmp,"Offboarding",tenantData.offboardingEmp)}
                                </> : tenantStatus.company ? 
                                <>
                                  {renderRoundChart(tenantData.activeTenant,tenantData.onboardingTenant,tenantData.offboardingTenant,"Active","Onboarding","Offboarding")}
                                  {renderCountList("Total Company",tenantData.totalTenant,"Active",tenantData.activeTenant,"Onboarding",tenantData.onboardingTenant,"Offboarding",tenantData.offboardingTenant)}
                                </> : ""
                                }
                            </Grid>
                            
                            <Grid item xs={3.8} sx={{backgroundColor:"#FFFFFF",p:2,mt:2}}>
                            <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                            <Box>
                                                <StyledPrimaryTypography>Building Access</StyledPrimaryTypography>
                                                <StyledSecTypography>Total: {accessData.total}</StyledSecTypography>
                                            </Box>
                                            <StyledButton onClick={()=>navigate('/reports/true')} variant="outlined">Manage</StyledButton>
                                </Box>
                                {/* <Typography>New report: 3</Typography> */}
                                {renderBarChart(true,accessData.tenant,accessData.visitor,accessData.invites)}  
                                  {renderCountList("Total Access",accessData.total,"Tenant",accessData.tenant,"Visitor",accessData.visitor,"Invites",accessData.invites)}
                            </Grid>

                            {/* <Grid item xs={0.2} /> */}

                            {/* <Grid item xs={3.9} sx={{backgroundColor:"#FFFFFF",p:2,mt:2}}>
                                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                                    <Box>
                                                        <Typography>Seasonal Parking</Typography>
                                                        <Typography>Total: 68</Typography>
                                                    </Box>
                                                    <StyledButton variant="outlined">Manage</StyledButton>
                                    </Box>
                                    <Typography>New report: 3</Typography>
                             </Grid> */}

                             <Grid item xs={0.2} />

                            <Grid item xs={3.8} sx={{backgroundColor:"#FFFFFF",p:2,mt:2}}>
                                <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                                    <Box>
                                                        <StyledPrimaryTypography>BMS Staff</StyledPrimaryTypography>
                                                        <StyledSecTypography>Total: {staffData.total}</StyledSecTypography>
                                                    </Box>
                                                    <StyledButton variant="outlined" onClick={()=>navigate(`/manage_staff/0`)
                                                    }>Manage</StyledButton>
                                    </Box>
                                    {/* <Typography>New report: 3</Typography> */}
                                    {renderRoundChart(staffData.active,staffData.onboarding,staffData.offboarding,"Active","Onboarding","Offboarding")}
                                    {renderCountList("Total Lots",staffData.total,"Active",staffData.active,"Onboarding",staffData.onboarding,"Offboarding",staffData.offboarding)}
                             </Grid>
                    </Grid>
              
            </Box>
        </>
    )
}

const mapStateToProps = (state)=>{
  return{
      dbData:state.DashboardReducer.data,
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
      getDbData:(data)=>dispatch(DashboardAction.getDbData(data)),
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(BmsManagerDashboard)
