import React from 'react'
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as PlusWhiteIcon } from "../../Assets/icons/PlusWhiteIcon.svg";
import {ReactComponent as DownloadIcon} from '../../Assets/icons/Download.svg';
import { Link, useParams,useNavigate} from "react-router-dom";

const TAB_HEADINGS = [
    {
      id: 0,
      name: "Company",
    },
    {
      id: 1,
      name: "Tenant Employee",
    },
  ];
export default function ManageFacility() {
    const { empValue } = useParams();
    const navigate = useNavigate()
    const [value, setValue] = React.useState(parseInt(empValue));
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
      navigate(`/onboard_new_tenant/${newValue}/0`)
    };
    return (
      <Grid container sx={{background:"#F9FAFA",minHeight:"100%"}}>
        <Grid item xs={0.5}></Grid>
  
        <Grid item xs={11}>
        
        {/* Heading */}
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              mt:8
            }}
          >
            <Typography
              sx={{ fontWeight: 500,color:"#002D74", fontSize: { md: "26px", xs: "22px" } }}
            >
              Facilities
            </Typography>
            <Box sx={{display:'flex', justifyContent:"space-between", gap:"15px"}}>
            <Link
              to="/onboard_new_tenant_company"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                startIcon= {<DownloadIcon/>}
                sx={{
                  textTransform: "none",
                  gap: "6px",
                  background: "#123FA9",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="span"
                  sx={{ fontSize: { md: "14px", xs: "10px", color:'#fff' } }}
                >
                  Download Report
                </Typography>
              </Button>
            </Link>
            <Link
              to="/create_facility"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  gap: "6px",
                  background: "#123FA9",
  
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { md: 20, xs: 15 },
                  }}
                >
                  <PlusWhiteIcon />
                </Box>
                <Typography
                  variant="span"
                  sx={{ fontSize: { md: "14px", xs: "10px" } }}
                >
                  Create
                </Typography>
              </Button>
            </Link>
            </Box>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Typography
              variant="p"
              sx={{ fontSize: { md: "14px", xs: "12px" }, fontWeight:500,color:"#002D74" }}
            >
              {/* BMS staff are able to submit tenant company onboarding requests,to
              approve/ reject tenant onboarding requests submitted by tenants. */}
              Manage the facilities.
            </Typography>
          </Box>
        </Box>

        <Grid item xs={12} sx={{mt:5}}>
          <Box sx={{backgroundColor:'#FFFFFF', display:'flex', padding:'50px', flexDirection:'column', justifyContent:'center', alignItems:'center', gap:'30px'}}>
            <Typography variant="p" sx={{ fontSize: '16px', fontWeight:600,color:"#6F80A9" }}>
                No existing facility
            </Typography>
            <Typography variant="p" sx={{ fontSize: '16px', fontWeight:500,color:"#6F80A9" }}>
                You may add facilities by clicking on the create button.
            </Typography>
            <Link
                to="/create_facility"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    gap: "6px",
                    background: "#123FA9",
    
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: { md: 20, xs: 15 },
                    }}
                  >
                    <PlusWhiteIcon />
                  </Box>
                  <Typography
                    variant="span"
                    sx={{ fontSize: { md: "14px", xs: "10px" } }}
                  >
                    Create
                  </Typography>
                </Button>
            </Link>
          </Box>
        </Grid>
        </Grid>

        <Grid item xs={0.5}></Grid>
      </Grid>
  )
}
