import { Box, Button, Checkbox, Divider, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { TimePicker,DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { ReactComponent as RefreshIcon } from '../../../Assets/icons/Refresh.svg';
import { ReactComponent as RoundedPlusIcon } from '../../../Assets/icons/RoundedPlusIcon.svg';
import { ReactComponent as CheckboxIcon } from '../../../Assets/icons/CheckboxTerms.svg';
import { ReactComponent as RoundedMinusIcon } from '../../../Assets/icons/MinusIcon.svg';
import { ReactComponent as DatePickerIcon } from '../../../Assets/icons/DatePickerIcon.svg';
import { getLastUpdatedTime, getYYYYMMDDFormat, preventDatePickerKeyboard } from "../../../Utils/utils";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import {makeStyles} from "@mui/styles";



const StyledTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '5px'
})

const Style = makeStyles({
    input:{
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

const SecurityClearance = ({returnInputData,countriesList,getNationality,tenantData,serviceType,termsAndCondition,getPublicHoliday,publicHolidays,serviceReqDetails,serviceRequestByID,requestId,previewPageData, setServiceType})=>{
    const [workmanForms, setWorkmanForms] = useState([{ id: 1, workManName: '', workManNationality: '', workManNRIC: '' }])
    const [details, setDetails] = useState({showstartDate:null,showendDate:null});
    const [inputErrors, setInputErrors] = useState({});
    const [securityClearanceTime,setSecurityClearanceTime] = useState({weekStartTime:"",weekEndTime:"",satStartTime:"",satEndTime:"",sunStartTime:"",sunEndTime:""});
    const [lastUpdatedTime, setLastUpdatedTime] = useState('');
    const [securityClearanceCheckBox, setSecurityClearanceCheckbox] = useState(false);
    const [startHours,setStartHours] = useState([])
    const [endHours,setEndHours] = useState([])
    const [days,setDays] = useState({});
    const [weekdays,setWeekdays] = useState([]);
    const [isPublicHoliday,setIsPublicHoliday] = useState([]);
    const [satStartHours,setSatStartHours] = useState([]);
    const [sunStartHours,setSunStartHours] = useState([])
    const [satEndHours,setSatEndHours] = useState([]);
    const [sunEndHours,setSunEndHours] = useState([])
    let terms_condition = termsAndCondition?.config_value && JSON.parse(termsAndCondition.config_value);
    const customStyle = Style();
    useEffect(()=>{
        setLastUpdatedTime(getLastUpdatedTime());
        getNationality();
        getPublicHoliday()
        getSecurityStartHours(18)
        getSecuritySatStartHours(13)
        getSecuritySunStartHours(8)
        getSecurityEndHours(0);

        if(requestId!="null"){
            serviceRequestByID({service_requests_id:requestId})
        }
    },[])

    //from preview screen
    useEffect(()=>{
        if(requestId=='null' && sessionStorage.getItem('SecurityPreview') && previewPageData.security){
            let data = JSON.parse(sessionStorage.getItem('SecurityPreview'))
            getSecuritySatEndHours(data.details.time.satEndTime)
            getSecuritySunEndHours(data.details.time.sunEndTime)
            getSecurityEndHours(data.details.time.weekEndTime);
            setDetails({
                ...details,
                ...data.details,
                fullstartDate:new Date(data.details.fullstartDate),
                fullendDate:new Date(data.details.fullendDate),
                office:`${data.details.office_code} ${data.details.office_number}`,
                mobile:`${data.details.mobile_code} ${data.details.mobile_number}`
            })  
            setSecurityClearanceTime({
                ...securityClearanceTime,
                ...data.details.time
            })
        }
    },[])

    const getSecuritySunStartHours = (minTime)=>{
        let timeIntrevels=[]
        for(let i=minTime;i<24;i++){
           for(let j=1;j<=2;j++){
               if(j%2==0){
                timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
               }else{
                timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
               }
           }  
       };
       setSunStartHours([...timeIntrevels])
    }

    //from draft
    useEffect(()=>{
        if(serviceReqDetails!=null && serviceReqDetails?.serviceRequestsArrayList?.length > 0){
            let draftDetails = serviceReqDetails?.serviceRequestsArrayList[0]
            let workmanList = serviceReqDetails?.securityClearanceWorkMensArrayList
            let arr = []
            let obj2 = {}
            for(let i=0;i<workmanList.length;i++){
                let obj = {}
                obj.id = i+1
                obj.workManName = workmanList[i].name
                obj.workManNRIC = workmanList[i].nric
                obj.workManNationality = workmanList[i].nationality_id
                arr.push(obj)
                obj2[`workManName${i+1}`] = workmanList[i].name
                obj2[`workManNRIC${i+1}`] = workmanList[i].nric
                obj2[`workManNationality${i+1}`] = workmanList[i].nationality_id
                obj2[`workManNationality${i+1}name`] = workmanList[i].nationality_name
            }

            setWorkmanForms([...arr]);
            if(draftDetails?.saturday_from_time!='NA' && draftDetails?.saturday_from_time!=null) {
                getSecuritySatEndHours(draftDetails?.saturday_from_time)
            }
            if(draftDetails?.sunday_ph_from_time!='NA' && draftDetails?.sunday_ph_from_time!=null) {
                getSecuritySunEndHours(draftDetails?.sunday_ph_from_time)
            }
            // (draftDetails?.saturday_from_time!='NA' && draftDetails?.saturday_from_time!=null) && getSecuritySatEndHours(draftDetails?.saturday_from_time)
            // (draftDetails?.sunday_ph_from_time!='NA' && draftDetails?.sunday_ph_from_time!=null) &&  getSecuritySunEndHours(draftDetails?.sunday_ph_from_time)

            setDetails({
                ...details,
                contractorCompany:draftDetails?.contractor_company!="NA" ? draftDetails?.contractor_company : "",
                personInCharge:draftDetails?.person_in_charge!="NA" ? draftDetails?.person_in_charge : '',
                addressLine1:draftDetails?.contractor_address_1!="NA" ? draftDetails?.contractor_address_1 : "",
                addressLine2:draftDetails?.contractor_address_2!="NA" ? draftDetails?.contractor_address_2 : '',
                workDetails:draftDetails?.description!="NA" ? draftDetails?.description : '',
                office: `${draftDetails?.office_phone_code} ${draftDetails?.office_phone_no}`,
                office_code:`${draftDetails?.office_phone_code}`,
                office_number:`${draftDetails?.office_phone_no}`,
                mobile:`${draftDetails?.contractor_phone_code} ${draftDetails?.contractor_phone_no}`,
                mobile_code:`${draftDetails?.contractor_phone_code}`,
                mobile_number:`${draftDetails?.contractor_phone_no}`,
                natureOfWork:draftDetails?.description,
                startDate:draftDetails?.from_date,
                showstartDate:draftDetails?.from_date,
                endDate:draftDetails?.to_date,
                showendDate:draftDetails?.to_date,
                ...obj2
            })
            setSecurityClearanceTime({
                ...securityClearanceTime,
                weekStartTime:(draftDetails?.weekday_from_time!='undefined' &&  draftDetails?.weekday_from_time!='NA')? draftDetails?.weekday_from_time : "",
                weekEndTime:(draftDetails?.weekday_to_time!='undefined' && draftDetails?.weekday_to_time!='NA' )? draftDetails?.weekday_to_time : "",
                satStartTime:(draftDetails?.saturday_from_time!='undefined' && draftDetails?.saturday_from_time!='NA') ? draftDetails?.saturday_from_time : "",
                satEndTime:(draftDetails?.saturday_to_time!='undefined' && draftDetails?.saturday_to_time!='NA') ? draftDetails?.saturday_to_time : "",
                sunStartTime:(draftDetails?.sunday_ph_from_time!='undefined' && draftDetails?.sunday_ph_from_time!='NA' )? draftDetails?.sunday_ph_from_time : "",
                sunEndTime:(draftDetails?.sunday_ph_to_time!='undefined' && draftDetails?.sunday_ph_to_time!='NA') ? draftDetails?.sunday_ph_to_time : "",
                
            })
            
        }
        
    },[serviceReqDetails])
    
    useEffect(()=>{
        getTimePicker()
        handlePublicHoliday()
    },[details])    

    const handlePublicHoliday = ()=>{
        let startDate = new Date(details?.fullstartDate)
        let endDate = new Date(details?.fullendDate)
        let arr = [];
        for(let i=0;i<publicHolidays.length;i++){
            let checkdate = publicHolidays[i].calendars_date;
            let date = checkdate.split("-");
            let dateFormat = new Date(checkdate)
            //console.log(dateFormat,startDate,endDate,(dateFormat >= startDate && dateFormat<=endDate))
            if(dateFormat>=startDate && dateFormat<=endDate){
                arr.push(true)
            }
            else{   
                arr.push(false)
            }
        }
        setIsPublicHoliday([...arr])
    }

    const getSecurityStartHours = (minTime)=>{
        let timeIntrevels=[]
        for(let i=minTime;i<24;i++){
           for(let j=1;j<=2;j++){
               if(j%2==0){
                timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
               }else{
                timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
               }
           }  
       };
       setStartHours([...timeIntrevels])
   }

   const getSecuritySatStartHours = (minTime)=>{
            let timeIntrevels=[]
            for(let i=minTime;i<24;i++){
            for(let j=1;j<=2;j++){
                if(j%2==0){
                    timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
                }else{
                    timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
                }
            }  
        };
        setSatStartHours([...timeIntrevels])
    }


const getSecurityEndHours = (minTime)=>{
    let innerLoopmin = minTime.toString().charAt(3)
    let timeIntrevels=[],startTime=minTime===0?0: innerLoopmin!=0 ? parseInt(minTime.slice(0,2))+1 : parseInt(minTime.slice(0,2));
    for(let i=startTime;i<24;i++){
       for(let j=1;j<=2;j++){
           if(j%2==0){
            timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
           }else{
            timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
           }
       }  
   };
   setEndHours([...timeIntrevels])
}
const getSecuritySatEndHours = (minTime)=>{
    console.log('qithin function')
    let innerLoopmin = minTime.toString().charAt(3)
    let timeIntrevels=[],startTime=minTime===0?0 : innerLoopmin!=0 ? parseInt(minTime.slice(0,2))+1 : parseInt(minTime.slice(0,2));
    for(let i=startTime;i<24;i++){
       for(let j=1;j<=2;j++){
           if(j%2==0){
            timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
           }else{
            timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
           }
       }  
   };
   setSatEndHours([...timeIntrevels]) 
}

const getSecuritySunEndHours = (minTime)=>{
    let innerLoopmin = minTime.toString().charAt(3)
    let timeIntrevels=[],startTime=minTime===0?0 : innerLoopmin!=0 ? parseInt(minTime.slice(0,2))+1 : parseInt(minTime.slice(0,2));
    for(let i=startTime;i<24;i++){
       for(let j=1;j<=2;j++){
           if(j%2==0){
            timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
           }else{
            timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
           }
       }  
   };
   setSunEndHours([...timeIntrevels])
}
    const handleDetails = (e, datafrom, countryCode) => {

        if (datafrom == "mobile" || datafrom == 'office') {
            setDetails({
                ...details,
                [`${datafrom}_code`]: countryCode.dialCode,
                [`${datafrom}_number`]: e.slice(countryCode.dialCode.length)

            })
            setInputErrors({ ...inputErrors, office: '' })
        }else if(datafrom==="nationality"){
            let {name,value} = e.target;
            let filter =  countriesList?.nationalityArrayList?.filter((item)=>{
               return value == item.nationality_id
            })
            setDetails({
                ...details,
                [name]:value,
                [`${name}name`]:filter[0]?.nationality_name
            })
            setInputErrors({
                ...inputErrors,
                [name]:""
            })
            
        }
        else if(datafrom=='NRIC') {
            let { name, value } = e.target;
            setDetails({
                ...details,
                [name]: value.toUpperCase(),

            })
            setInputErrors({
                ...inputErrors,
                [name]:""
            })
        }else {
            let { name, value } = e.target;
            setDetails({
                ...details,
                [name]: value,

            })
            setInputErrors({
                ...inputErrors,
                [name]:""
            })
        }
        
    }

    const handleSecurityTime = (e,name)=>{
        if(e==null){
            setInputErrors({
                ...inputErrors,
                securityTime:"Select a valid time"
            })
        }else{
            //let time  = `${value.getHours()<9 ? `0${value.getHours()}` : value.getHours()} : ${value.getMinutes() < 9 ? `0${value.getMinutes()}` : value.getMinutes()}`
       
            setSecurityClearanceTime({
                ...securityClearanceTime,
                [name]:e.target.value,
                [`submit${name}`]:e.target.value
            })

            setInputErrors({
                ...inputErrors,
                securityTime:''
            })
        }
        
    }

    const handleDate = (e,name)=>{
        if(e===null){
            setInputErrors({
                ...inputErrors,
                [name]:'Select a valid date'
               
            })
            setDetails({
                ...details,
                [name]:null
            })
       }else{
        let Date = `${e.getDate() > 9 ? e.getDate() : `0${e.getDate()}` }`;
        let Month = `${e.getMonth()>9 ? e.getMonth()+1 : `0${e.getMonth()+1}`}`;
        let Year = e.getFullYear();
        setDetails({
         ...details,
         [name]:`${Year}-${Month}-${Date}`,
         [`show${name}`]:`${Year}/${Month}/${Date}`,
         [`full${name}`]:e
        })
        setDays({
            ...days,
            [`${name}day`]:e.getDay()
        })
       
        setInputErrors({
         ...inputErrors,
         startDate:"",
         endDate:''
        })
       }
    
    }

    const handleWorkmanCounts = () => {
        if(workmanForms.length<15){
            let workmanObj={},newWorkManArr=workmanForms
            workmanObj['id']=workmanForms.length+1;
            workmanObj['workmanName']='';
            workmanObj['workManNationality']='';
            workmanObj['workManNRIC']='';
            newWorkManArr.push(workmanObj);
            setWorkmanForms([...newWorkManArr])
        }

    }

    const validateSecurityClearance=()=>{
        let errArray = [],errObj={},isValid;

        if(details.hasOwnProperty("contractorCompany") && details.contractorCompany){
            errArray.push(true)
        }else{
            errObj["contractorCompany"]='Vendor Company is required';
            errArray.push(false)
        }

        if(details.hasOwnProperty("personInCharge") && details.personInCharge){
            errArray.push(true)
        }else{
            errObj["personInCharge"] = "Person In Charge is Required";
            errArray.push(false)
        }

        if((details.hasOwnProperty("office") || details.hasOwnProperty("office_number")) && (details.office || details.office_number)){
            errArray.push(true)
        }else{
            errObj["office"]="Office Number is Required";
            errArray.push(false)
        }

        if(details.hasOwnProperty("addressLine1") && details.addressLine1){
            errArray.push(true)
        }else{
            errObj["addressLine1"]="Address is Required";
            errArray.push(false)
        }

        if(details.hasOwnProperty("startDate") && details.startDate!==null){
            errArray.push(true)
        }else{
            errObj["startDate"]='Start  date is Required';
            errArray.push(false)
        }

        
        if(details.hasOwnProperty("endDate") && details.endDate!==null){
            errArray.push(true)
        }else{
            errObj["endDate"]=' End date is Required';
            errArray.push(false)
        }

        if(details.hasOwnProperty("workDetails") && details.workDetails){
            errArray.push(true)
        }else{
            errObj["workDetails"] = "Work Details is Required";
            errArray.push(false)
        }

        if(weekdays.includes(1) || weekdays.includes(2) ||weekdays.includes(3) ||weekdays.includes(4) ||weekdays.includes(5)){
            if(securityClearanceTime.weekStartTime){
                errArray.push(true)
            }else{
                errObj["weekStartTime"] = "Select Start Time";
                errArray.push(false)
            }

            if(securityClearanceTime.weekEndTime){
                errArray.push(true)
            }else{
                errObj["weekEndTime"] = "Select End Time";
                errArray.push(false)
            }
        }
             
        if(weekdays.includes(6)){
            if(securityClearanceTime.satStartTime){
                errArray.push(true)
            }else{
                errObj["satStartTime"] = "Select Start Time";
                errArray.push(false)
            }
        }

        if(weekdays.includes(6)){
            if(securityClearanceTime.satEndTime){
                errArray.push(true)
            }else{
                errObj["satEndTime"] = "Select End Time"
                errArray.push(false)
            }
        }

        if(weekdays.includes(0)){
            if(securityClearanceTime.sunStartTime){
                errArray.push(true)
            }else{
                errObj["sunStartTime"] = "Select Start Time"
                errArray.push(false)
            }
        }

        if(weekdays.includes(0)){
            if(securityClearanceTime.sunEndTime){
                errArray.push(true)
            }else{
                errObj["sunEndTime"] = "Select End Time"
                errArray.push(false)
            }
        }
        handleWorkManErrors(errArray,errObj);
        
        setInputErrors(errObj)
        isValid = errArray.includes(false)
         return isValid ? false : true;
    }

    let handleWorkManErrors = (errArray,errObj)=>{
        
        let iterate = 0;
        console.log('workmanForms ',workmanForms)
        let iterationValue = workmanForms[iterate]?.id
        let NRICregex = /^[\d]{3}[A-Z]{1}$/;
        for(let i=iterationValue;i<=workmanForms?.length;i++){
            if(details.hasOwnProperty(`workManName${i}`)){
                errArray.push(true)
            }else{
                errObj[`workManName${i}`] = "Work Man Name is Required";
                errArray.push(false)
            }

            if(details.hasOwnProperty(`workManNationality${i}`)){
                errArray.push(true)
            }else{
                errObj[`workManNationality${i}`] = "Nationality is Required";
                errArray.push(false)
            }

            if(details.hasOwnProperty(`workManNRIC${i}`) && details[`workManNRIC${i}`].match(NRICregex)){
                errArray.push(true)
            }else{
                errObj[`workManNRIC${i}`] = "NRIC is Required";
                errArray.push(false)
            }
            iterate+=1;
        }
    }
    const handleContinue = ()=>{
        let valid = validateSecurityClearance();
        if(valid){
            details['company_id']=tenantData?.companyId;
            details['service_request_types_id'] = serviceType;
            details['time']=securityClearanceTime;
            details['workmanCount']=workmanForms.length;
            details["terms_condition"]=terms_condition;
            returnInputData(details)
            let toStoreData = {
                details:details,
                workmanForms:workmanForms
            }
            sessionStorage.setItem('SecurityPreview',JSON.stringify(toStoreData))
        }
    }

    const removeWorkman = (id)=>{
        const filteredArray = workmanForms.filter((value)=>{
            return value.id != id
        })
        setWorkmanForms([...filteredArray])
        delete inputErrors[`workManNRIC${id}`]
        delete inputErrors[`workManName${id}`]
        delete inputErrors[`workManNationality${id}`]
    }

    const getTimePicker = ()=>{
        // let startDay = days?.startDateday ===0 ? 7 : days?.startDateday;
        // let endDay = days?.endDateday === 0 ? 7 : days?.endDateday;
        
        const difference = new Date(details.endDate).getTime() - new Date(details.startDate).getTime()
        const numOfDays = difference/(1000*3600*24)
        const iterationValue = numOfDays + 1
        let arr = [];
        for(let i=0;i<iterationValue;i++){
            let startDate = new Date(details.startDate)
            let date = new Date(details.startDate)
            date.setDate(startDate.getDate()+i)
            arr.push(date.getDay())
        }
        setWeekdays([...arr])
     
    }

    const renderSecurityWorkmans = (FormInput) => {
        return FormInput.map((forms,index) => 
             <Grid container key={index}>
                <Grid item xs={4} sx={{ mt: 1, p: 1 }}>
                    <StyledTypography >Name</StyledTypography>
                    <OutlinedInput name={`workManName${forms.id}`}
                        autoComplete="off"
                        value={details[`workManName${forms.id}`]}
                        inputProps={{
                            maxLength:100
                        }}
                        onChange={(e)=>handleDetails(e)}
                            placeholder="John Doe" sx={{
                             height: '48px',
                            width: '100%',
                            borderRadius: "6px",
                            color: '#0F2C6F',
                        }} />
                    {inputErrors[`workManName${forms.id}`] && <Typography sx={{color:"#f44336"}}>{inputErrors[`workManName${forms.id}`]}</Typography>}
                 </Grid>
                <Grid item xs={3} sx={{ mt: 1, p: 1 }}>
                    <StyledTypography >Nationality</StyledTypography>
                        <Select  value={Number(details[`workManNationality${forms.id}`])} name={`workManNationality${forms.id}`} fullWidth sx={{height:'48px'}} onChange={(e)=>handleDetails(e,"nationality")}>
                            {countriesList?.nationalityArrayList?.length >0 && 
                                countriesList?.nationalityArrayList?.map((value,index)=>(
                                    <MenuItem value={value.nationality_id}>{value.nationality_name}</MenuItem>
                                    // <MenuItem selected={value.nationality_id==details[`workmanNationality${forms.id}`] ? true : false} value={value}>{value.nationality_name}</MenuItem>
                                    ))
                            }
                        </Select>
                 {inputErrors[`workManNationality${forms.id}`] && <Typography sx={{color:"#f44336"}}>{inputErrors[`workManNationality${forms.id}`]}</Typography>}
                </Grid>
                <Grid item xs={4} sx={{ mt: 1, p: 1 }}>
                    <StyledTypography >NRIC (Last 4 numbers only) </StyledTypography>
                    <OutlinedInput name={`workManNRIC${forms.id}`}
                            autoComplete="off"
                            placeholder="234U"
                            inputProps={{maxLength:4}}
                            className={customStyle.input}
                            value={details[`workManNRIC${forms.id}`]}
                             onChange={(e)=>handleDetails(e,"NRIC")}
                              sx={{
                            height: '48px',
                            width: '100%',
                            borderRadius: "6px",
                            color: '#0F2C6F',
                           
                        }} />
                 {inputErrors[`workManNRIC${forms.id}`] && <Typography sx={{color:"#f44336"}}>{inputErrors[`workManNRIC${forms.id}`]}</Typography>}
                </Grid>
                <Grid item xs={1} sx={{ p: 1, display: 'flex', alignItems: 'center' }} >

                    {forms.id === 1 ? <RoundedPlusIcon style={{ marginTop: '30px', cursor: 'pointer' }} onClick={() => handleWorkmanCounts()} /> :
                        <RoundedMinusIcon  style={{marginTop:"10px",cursor:"pointer"}} onClick={()=>removeWorkman(forms.id)} />
                }
                </Grid>
            </Grid>
        )
}

    return(
        <>
            <Box sx={{px:3}}>           
            <Grid container columnSpacing={1} rowSpacing={3} sx={{ mt: 1 }}>
                <Grid item xs={12}>
                    <Typography sx={{ color: '#0F2C6F', fontSize: '18px', fontWeight: 550}}>Contractor Details</Typography>
                    <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: '5px' }}>Please enter contractor details and ensure details are accurate for building management processing.</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTypography >Vendor Company *</StyledTypography>
                    <OutlinedInput autoComplete="off" value={details.contractorCompany} name='contractorCompany' onChange={(e)=>handleDetails(e)} sx={{ mt: '5px', height: '46px', borderRadius: '4px', width: '90%' }} />
                    {inputErrors.contractorCompany && <Typography sx={{ color: "#f44336", mt: 1 }}>{inputErrors.contractorCompany}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTypography sx={{ mb: 1 }}>Office Telephone *</StyledTypography>
                    <PhoneInput value={details.office} inputProps={{ name: 'office' }} onChange={(data, country, event, format) => handleDetails(data, 'office', country)} country='sg' inputStyle={{ height: '46px', width: '90%' }} />
                    {inputErrors.office && <Typography sx={{ color: "#f44336", mt: 1 }}>{inputErrors.office}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTypography >Person-In-Charge *</StyledTypography>
                    <OutlinedInput autoComplete="off" value={details.personInCharge} name='personInCharge' onChange={(e) => handleDetails(e)} sx={{ mt: '5px', height: '46px', borderRadius: '4px', width: '90%' }} />
                    {inputErrors.personInCharge && <Typography sx={{ color: "#f44336", mt: 1 }}>{inputErrors.personInCharge}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <StyledTypography sx={{ mb: 1 }}>Mobile Number *</StyledTypography>
                    <PhoneInput value={details.mobile} inputProps={{ name: 'mobile' }} onChange={(data, country, event, format) => handleDetails(data, 'mobile', country)} country='sg' inputStyle={{ height: '46px', width: '90%' }} />
                </Grid>
        </Grid>
        
        <Grid container sx={{mt:2}}>
                        <Grid item xs={12} >
                            <StyledTypography >Address Line 1</StyledTypography>
                            <OutlinedInput multiline inputProps={{maxLength:500}} autoComplete="off" value={details?.addressLine1} name='addressLine1' onChange={(e) => handleDetails(e)} sx={{ mt: '5px',  borderRadius: '4px', width: '100%' }} />
                            {inputErrors.addressLine1 && <Typography sx={{color:"#f44336"}}>{inputErrors.addressLine1}</Typography>}
                        </Grid>
                        <Grid item xs={12} sx={{mt:2}}>
                            <StyledTypography >Address Line 2</StyledTypography>
                            <OutlinedInput multiline inputProps={{maxLength:500}} autoComplete="off" value={details.addressLine2} name='addressLine2' onChange={(e) => handleDetails(e)} sx={{ mt: '5px', borderRadius: '4px', width: '100%' }} />
                        </Grid>
                        <Divider sx={{ my: 4 }} />
                    </Grid>
                    <Typography sx={{ color: '#0F2C6F', fontSize: '18px', fontWeight: 550, mt: 7 }}>Contract Period</Typography>
                    <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: '5px' }}>Please enter the event details.</Typography>
                    <Grid container sx={{my:3}}>
                        <Grid item xs={3}>
                            <StyledTypography>Start Date </StyledTypography>
                            <DatePicker inputFormat="dd/MM/yyyy" components={{
                                OpenPickerIcon:DatePickerIcon
                            }} disablePast   renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} value={details.showstartDate} onChange={(e)=>handleDate(e,'startDate')} />
                            {inputErrors.startDate && <Typography sx={{color:"#f44336"}}>{inputErrors.startDate}</Typography>}
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={3}>
                            <StyledTypography >End Date </StyledTypography>
                            <DatePicker  inputFormat="dd/MM/yyyy" minDate={details.startDate} components={{
                                OpenPickerIcon:DatePickerIcon
                            }} renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)}  {...params} />} value={details.showendDate} onChange={(e)=>handleDate(e,'endDate')} />
                            {inputErrors.endDate && <Typography sx={{color:"#f44336"}}>{inputErrors.endDate}</Typography>}
                        </Grid>
                        <Grid item xs={5} />
                            
                       
                    </Grid>
                 
                    <Grid container sx={{my:1}} rowSpacing={2}>
                          {(weekdays.includes(1) || weekdays.includes(2) || weekdays.includes(3) || weekdays.includes(4) || weekdays.includes(5)) && weekdays.length>0 ? 
                        <>
                             <Grid item xs={4} >
                            <StyledTypography >Working Days</StyledTypography>
                            <Select
                                value={"weekdays"}
                                defaultValue="weekdays"
                                sx={{ width: 200,height:"50px",color:"#0F2C6F" }}
                            >
                                <MenuItem value={'weekdays'}>Weekdays</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={2} >
                            <StyledTypography >Start Time</StyledTypography>
                            <Select
                             MenuProps={
                                MenuProps
                             } 
                            sx={{height:"50px",width:100,}}  onChange={(e)=>{handleSecurityTime(e,"weekStartTime")
                            getSecurityEndHours(e.target.value)
                            }}   value={securityClearanceTime.weekStartTime} >
                               {startHours.map((value,index)=>{
                                        return <MenuItem  sx={{color:"#0F2C6F"}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>      
                            {inputErrors.weekStartTime && <Typography sx={{color:"#f44336"}}>{inputErrors.weekStartTime}</Typography> }
                            
                        </Grid>
                       
                        <Grid item xs={2} sx={{  ml: 1 }}>
                            <StyledTypography >End Time</StyledTypography>
                            <Select
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"50px",width:100,}}  onChange={(e)=>handleSecurityTime(e,"weekEndTime")}    value={securityClearanceTime.weekEndTime} >
                               {endHours.map((value,index)=>{
                                        return securityClearanceTime.weekStartTime!=value &&  <MenuItem sx={{color:"#0F2C6F"}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>    
                            {inputErrors.weekEndTime && <Typography sx={{color:"#f44336"}}>{inputErrors.weekEndTime}</Typography> }
                            
                        </Grid>
                        </>  : 
                        <Grid item xs={10} />  
                    }
                       

                        <Grid item xs={2} ></Grid>
                        {weekdays.includes(6) ? <>
                        <Grid item xs={4} sx={{ mt: 1 }} >
                            <StyledTypography >Working Days</StyledTypography>
                            <Select
                                defaultValue={"saturday"}
                                sx={{ width: 200,height:"50px",color:"#0F2C6F" }}
                            >
                                <MenuItem value={'saturday'}>Saturday</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={2} sx={{ mt: 1 }}>
                            <StyledTypography >Start Time</StyledTypography>
                            <Select
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"50px",width:100,}}  onChange={(e)=>{handleSecurityTime(e,"satStartTime")
                                getSecuritySatEndHours(e.target.value)
                            }}    value={securityClearanceTime.satStartTime} >
                               {satStartHours.map((value,index)=>{
                                        return <MenuItem sx={{color:"#0F2C6F"}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>     
                            {inputErrors.satStartTime && <Typography sx={{color:"#f44336"}}>{inputErrors.satStartTime}</Typography> }
                            
                        </Grid>
                        <Grid item xs={2} sx={{ mt: 1, ml: 1 }}>
                            <StyledTypography >End Time</StyledTypography>
                            <Select
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"50px",width:100,}}  onChange={(e)=>handleSecurityTime(e,"satEndTime")} value={securityClearanceTime.satEndTime} >
                               {satEndHours.map((value,index)=>{
                                        return securityClearanceTime.satStartTime!=value && <MenuItem sx={{color:"#0F2C6F"}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>   
                            {inputErrors.satEndTime && <Typography sx={{color:"#f44336"}}>{inputErrors.satEndTime}</Typography> }
                            
                        </Grid>
                        </> : <Grid item xs={10}></Grid>}
                        <Grid item xs={2} ></Grid>
                       {(weekdays.includes(0) || (isPublicHoliday.includes(true))) &&  <>

                       
                        <Grid item xs={4} sx={{ mt: 1 }}>
                            <StyledTypography >Working Days</StyledTypography>
                            <Select
                                value={'Sunday/PH'}
                                defaultValue={'Sunday/PH'}
                                sx={{ width: 200,height:"50px",color:"#0F2C6F" }}
                            >
                                <MenuItem value={'Sunday/PH'}>Sunday/PH</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={2} sx={{ mt: 1 }}>
                            <StyledTypography >Start Time</StyledTypography>
                            <Select
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"50px",width:100,}}  onChange={(e)=>{handleSecurityTime(e,"sunStartTime")
                                getSecuritySunEndHours(e.target.value)
                            }}  value={securityClearanceTime.sunStartTime} >
                               {sunStartHours.map((value,index)=>{
                                        return <MenuItem sx={{color:"#0F2C6F"}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>
                            {inputErrors.sunStartTime && <Typography sx={{color:"#f44336"}}>{inputErrors.sunStartTime}</Typography> }
                            
                        </Grid>
            
                        <Grid item xs={2} sx={{ mt: 1, ml: 1 }}>
                            <StyledTypography >End Time</StyledTypography>
                            <Select
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"50px",width:100,}}  onChange={(e)=>handleSecurityTime(e,"sunEndTime")} value={securityClearanceTime.sunEndTime} >
                               {sunEndHours.map((value,index)=>{
                                        return securityClearanceTime.sunStartTime!=value && <MenuItem sx={{color:"#0F2C6F"}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>
                            {inputErrors.sunEndTime && <Typography sx={{color:"#f44336"}}>{inputErrors.sunEndTime}</Typography> }
                            
                        </Grid>
                        </>}
                        <Grid item xs={2} ></Grid>
                        <Grid item xs={12} >
                        {inputErrors.securityTime && <Typography sx={{color:"#f44336"}}>{inputErrors.securityTime}</Typography>}
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 3, }}>
                            <StyledTypography >Nature of Work</StyledTypography>
                            <OutlinedInput multiline inputProps={{maxLength:500}}  autoComplete="off" value={details.workDetails}  onChange={(e) => handleDetails(e)} name='workDetails' sx={{ width: '100%', mt: 1 }} placeholder="Please provide a brief description of the work" />
                            {inputErrors.workDetails && <Typography sx={{color:"#f44336"}}>{inputErrors.workDetails}</Typography>}
                        </Grid>
                        <Divider sx={{ my: 4 }} />
                        <Grid item xs={12} sx={{ mt: 3, }}>
                            <StyledTypography >Workmen Particulars</StyledTypography>
                            <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: '5px' }}>
                                Please ensure workmen details are accurate, only the workmen requested below will be cleared for entry to premises.
                            </Typography>
                        </Grid>
                    </Grid>
                    {renderSecurityWorkmans(workmanForms)}
                    <Box sx={{display:'flex',justifyContent:"center",my:2}}>
                    <Typography sx={{color:' #6F80A9',fontSize:"14px"}}>Maximum of only 15 workmen allowed</Typography>
                    </Box>
                    
                    <Box sx={{mt:3}}>
                        <Typography sx={{ fontSize: '18px', color: '#0F2C6F',fontWeight:550 }}>{terms_condition &&  terms_condition[0].title}*</Typography>
                        <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{terms_condition && terms_condition[0].description}</Typography>
                        {terms_condition && terms_condition[1].rules.map((value,index)=>(
                             <Typography sx={{ color: "#6F80A9", fontSize: '13px', my: 1 }}>{`${index+1} . ${value[index+1]}`}</Typography>
                        ))}
                        
                        <Box sx={{ display: "flex",ml:-1 }}>
                            <Checkbox icon={<CheckboxIcon/>} checked={securityClearanceCheckBox} onClick={() => setSecurityClearanceCheckbox(!securityClearanceCheckBox)} />
                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 1 }}>I have read and agree to comply with the above terms & conditions.</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, backgroundColor: 'rgba(143, 146, 161,0.05)', py: 5 }}>
                        <Box>
                            <RefreshIcon style={{ marginTop: '5px' }} />
                            <Typography sx={{ color: ' #9AA6C3', fontSize: '14px', display: 'inline', ml: 2 }}>Last Updated at {lastUpdatedTime}</Typography>
                        </Box>
                        <Box sx={{ p: 5, pt: 0,pr:0 }}>
                            <Button sx={{
                                color: '#0F2C6F',
                                height: '50px',
                                width:100,
                                borderRadius: "8px",
                                backgroundColor: '#FFFFFF',
                                textTransform: 'capitalize',
                                border: "1px solid #AAC3FF",
                                '&:hover': { backgroundColor: '#FFFFFF' }
                            }}
                            onClick={()=>setServiceType(0)}
                                varian="outlined">Delete</Button>
                            <Button variant='contained'
                                disabled={!securityClearanceCheckBox}
                                onClick={() => handleContinue()}
                                sx={{
                                    backgroundColor: '#123FA9',width:100, color: '#FFFFFF', ml: 3, borderRadius: "8px", height: "50px",
                                    textTransform: 'capitalize', '&:hover': { backgroundColor: '#123FA9' }
                                }}>
                                Continue</Button>
                        </Box>
                </Box>              
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        countriesList:state.Service_Req_Reducer.countries_list,
        publicHolidays:state.Service_Req_Reducer.publicHolidays,
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getNationality:()=>(dispatch(serviceRequestAction.getNationality())),
        getPublicHoliday:()=>(dispatch(serviceRequestAction.getPublicHoliday())),
        serviceRequestByID: (data) => (dispatch(serviceRequestAction.serviceRequestDetailsById(data)))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityClearance);