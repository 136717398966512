import { Avatar, Dialog, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Snackbar, Typography } from '@mui/material'
// import { onMessage } from 'firebase/messaging';
import React, { useEffect, useState } from 'react'
// import { messaging } from '../../firebase';
import moment from 'moment'
import CloseIcon from '@mui/icons-material/Close';
import  * as DashboardAction from '../../redux/actions/Dashboard Action';
import { connect } from 'react-redux';

const snackBarContent = {
    height: '50px !important',
    background: 'aliceblue !important',
    color: '#0f2c6f !important',
}

const NotificationPopup = ({ children,notificatonReceived })=> {
    const [notifyData, setNotifyData] = useState({
        id: 1,
        title: '',
        message: '',
        status: false
    })
    // onMessage(messaging, (payload) => {
    //     let navigationPath=JSON.parse(payload.data.data)
    //     let newNotification = {
    //         id: 1,
    //         title: payload.notification.title,
    //         message: payload.notification.body,
    //         status: true,
    //         navigateTo:`${navigationPath?.location}/${navigationPath?.id}`
    //     }
    //     setNotifyData(newNotification)
    //     notificatonReceived(newNotification)
    //     // setTimeout(() => {
    //     //   setNotifyData({
    //     //     id: 1,
    //     //     title: '',
    //     //     message: '',
    //     //     status: false
    //     //   })
    //     // }, 5000);
    // });

    const removeNotification=()=>{
          setNotifyData({
            id: 1,
            title: '',
            message: '',
            status: false
          })
    }
    return (
        <>
        <Dialog  open={notifyData.status}>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={notifyData.status}
                // onClose={setNotifyData({...notifyData,status:false})}
                // message={notifyData.message}
                key={'topright'}
                sx={{
                    top: '80px !important',
                }}
                ContentProps={{ sx: snackBarContent }}>

                <List sx={{ width: '100%', minWidth: 300, bgcolor: 'background.paper',borderRadius:'7px' }}>
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={()=>removeNotification()} >
                              <CloseIcon/>
                            </IconButton>
                        }
                    >
                        <ListItemText
                            primary={<Typography variant='h6' sx={{ color: '#0F2C6F', size: '14px' }} >Notifications</Typography>}
                        ></ListItemText>
                    </ListItem>
                    <Divider/>
                    <ListItem>
                       
                        <ListItemAvatar>
                            <Avatar>
                                {/* <ImageIcon /> */}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={<Typography variant='h6' sx={{ color: '#0F2C6F', size: '16px' }} >{notifyData.title}</Typography>}
                            secondary={<Typography variant='p' sx={{ color: '#0F2C6F', size: '14px' }} >{notifyData.message}<span><br></br>{moment(new Date().getTime()).format('LT')}</span></Typography>} />
                    </ListItem>
                </List>
            </Snackbar>
            </Dialog>
            {children}
        </>
    )
}

const mapStateToProps = ()=>{return {}};

const mapDispatchToProps = (dispatch)=>{
    return{
        notificatonReceived:(data)=>dispatch(DashboardAction.notificatonReceived(data))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(NotificationPopup);
