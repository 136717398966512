import {  handleActions } from "redux-actions";

const contactReducer = {
    createContactStatus:null,
    editContactStatus:null,
    contactList:null,
    oneGroupContactList:null,
    handbookReason:null,
    createHandbookStatus:null,
    handbookList:[],
    handbookUpdateStatus:null
}

const reducer = handleActions({
    UPDATE_CONTACT_REDUCER:(state)=>{
        return{
            ...state,
            createContactStatus:null,
            editContactStatus:null,
            createHandbookStatus:null,
            handbookUpdateStatus:null
        }
    },
    CREATE_CONTACT_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    CREATE_CONTACT_SUCCESS:(state,action)=>{
        return{
            ...state,
            createContactStatus:action.payload
        }
    },
    CREATE_CONTACT_FAILURE:(state,action)=>{
        return{
            ...state,
            createContactStatus:action.payload
        }
    },
    GET_CONTACTLIST_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_CONTACTLIST_SUCCESS:(state,action)=>{
        return{
            ...state,
            contactList:action.payload
        }
    },
    GET_CONTACTLIST_FAILURE:(state)=>{
        return{
            ...state,
            contactList:[]
        }
    },
    EDIT_CONTACT_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    EDIT_CONTACT_SUCCESS:(state,action)=>{
        return{
            ...state,
            editContactStatus:action.payload
        }
    },
    EDIT_CONTACT_FAILURE:(state,action)=>{
        return{
            ...state,
            editContactStatus:action.payload
        }
    },
    GET_ONEGROUPCONTACT_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ONEGROUPCONTACT_SUCCESS:(state,action)=>{
        return{
            ...state,
            oneGroupContactList:action.payload
        }
    },
    GET_ONEGROUPCONTACT_FAILURE:(state)=>{
        return{
            ...state,
            oneGroupContactList:{}
        }
    },
    GET_HBREASON_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_HBREASON_SUCCESS:(state,action)=>{
        return{
            ...state,
            handbookReason:action.payload
        }
    },
    GET_HBREASON_FAILURE:(state)=>{
        return{
            ...state,
            handbookReason:[]
        }
    },
    CREATE_HANDBOOK_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    CREATE_HANDBOOK_SUCCESS:(state,action)=>{
        return{
            ...state,
            createHandbookStatus:action.payload
        }
    },
    CREATE_HANDBOOK_FAILURE:(state,action)=>{
        return{
            ...state,
            createHandbookStatus:action.payload
        }
    },
    GET_HBLIST_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_HBLIST_SUCCESS:(state,action)=>{
        return{
            ...state,
            handbookList:action.payload
        }
    },
    GET_HBLIST_FAILURE:(state)=>{
        return{
            ...state,
            handbookList:[]
        }
    },
    UPDATE_HBSTATUS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    UPDATE_HBSTATUS_SUCCESS:(state,action)=>{
        return{
            ...state,
            handbookUpdateStatus:action.payload
        }
    },
    UPDATE_HBSTATUS_FAILURE:(state,action)=>{
        return{
            ...state,
            handbookUpdateStatus:action.payload
        }
    },
    ADD_GROUP_SUBCONTACT_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    ADD_GROUP_SUBCONTACT_SUCCESS:(state,action)=>{
        return{
            ...state,
            editContactStatus:action.payload
        }
    },
    ADD_GROUP_SUBCONTACT_FAILURE:(state,action)=>{
        return{
            ...state,
            editContactStatus:action.payload
        }
    }

},contactReducer)

export default reducer;