import { Box, Button, Chip, Menu, MenuItem, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {ReactComponent as AddWhiteIcon} from '../../Assets/icons/WhiteRoundedPlusIcon.svg';
import {ReactComponent as PdfIcon} from '../../Assets/icons/PDF.svg';
import {ReactComponent as MoreIcon} from '../../Assets/icons/more-alt.svg';
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as ImportantContactAction from '../../redux/actions/ImportantContactAction/index';
import { styled } from "@mui/system";
import Alert from "../common/faultalert";
import ToastAlert from "../common/Alert";
import moment from "moment/moment";

const StyledTableRow = styled(TableRow)({
    "&:nth-of-type(odd)":{
        backgroundColor:"#FAFBFF"
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F !important',
    padding: '5px !important',
    textAlign: "center",
    height: '50px',

    [`&.${tableCellClasses.head}`]: {
        fontSize: '13px',
        fontWeight: 550
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        fontWeight: 500,
    },
}));

const alertData = {
    label:"Update status",
    description:"Are you sure want to update status to 'Active' ",
    buttonvalue:"Confirm"
}

const HandBook = ({getHbList,handbookList,handbookUpdateStatus,updateHbStatus,setUpdateImportantContact})=>{
    const navigate = useNavigate();
    const [toast,setToast] = useState({status:false,message:"",color:""})
    const [activeBook,setActiveBook] = useState();
    const [alertStatus,setAlertStatus] = useState(false);
    const [activeId,setActiveId] = useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
     const optionsOpen = Boolean(anchorEl);

    useEffect(()=>{
        getHbList()
    },[handbookUpdateStatus])

    useEffect(()=>{
        getActiveFile()
    },[handbookList])


    useEffect(()=>{
        if(handbookUpdateStatus?.response_status=='success'){
            setAlertStatus(false)
            setToast({status:true,message:handbookUpdateStatus?.response_message,color:"green"})
            setTimeout(()=>{
                setToast()
                setUpdateImportantContact()
            },5000)
        }else if(handbookUpdateStatus?.response_status=='fail'){
            setAlertStatus(false)
            setToast({status:true,message:handbookUpdateStatus?.response_message,color:"red"})
            setTimeout(()=>{
                setToast()
                setUpdateImportantContact()
            },5000)
        }
    },[handbookUpdateStatus])

    const handleActiveStatus = ()=>{
        let data = {
            id:activeId
        }
        updateHbStatus(data)
    }

    const getActiveFile = ()=>{
        for(let i=0;i<=handbookList.length;i++){
            if(handbookList[i]?.status==1){
                setActiveBook(handbookList[i])
            }
        }
    }

    const handleClick = (event, id) => {
        // setSelectedId(id);
       setAnchorEl(event.currentTarget);
     };
     const handleOptionClose = () => {                    
       setAnchorEl(null);
     };
    
    return(
        <>
        {toast?.status && <ToastAlert status={toast.status} message={toast.message} color={toast.color} />}
        {alertStatus && <Alert alertData={alertData} confirmFunc={handleActiveStatus} closeFunc={setAlertStatus} />}
        <Box sx={{p:3,backgroundColor:"#FFFFFF",mb:4}}>
            <Typography >Tenant handbook contains important information such as SOP and extended contact informations</Typography>
            <Box sx={{ border:'1px solid #DBDFE9',borderRadius:"6px",my:2}}>
                <Box sx={{display:"flex",justifyContent:"space-between",p:2}}>
                    <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Current Version</Typography>
                    <Button onClick={()=>navigate('/new_handbook')} variant="contained" sx={{color:"#FFFFFF",backgroundColor:"#123FA9","&:hover":{backgroundColor:"#123FA9"},textTransform:"capitalize",fontSize: { md: "14px", xs: "10px"} }} endIcon={<AddWhiteIcon/>}>Upload Handbook</Button>
                </Box>
                {activeBook && <Box sx={{display:'flex',p:3}}>
                    <PdfIcon />
                    <Box sx={{display:"flex",flexDirection:"column",ml:3,mt:4}}>
                        <Chip label="Active" sx={{backgroundColor:"#D3F2E5",borderRadius:"6px",width:"80px"}}/>
                        <Typography sx={{color:" #0F2C6F",fontSize:"18px",mt:2}}>{activeBook?.orig_filename}</Typography>
                        <Button target='_blank' href={activeBook.new_filename} variant="outlined" sx={{textTransform:'capitalize',mt:2,color:" #0F2C6F"}}>View PDF</Button>
                    </Box>
                </Box>
                }
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Published</StyledTableCell>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell>Submitted by</StyledTableCell>
                            <StyledTableCell>Display</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {handbookList?.map((value)=>(
                            <StyledTableRow>
                                <StyledTableCell>{moment(value.created_at).format("DD MMM YYYY")}</StyledTableCell>
                                <StyledTableCell>{value.orig_filename}</StyledTableCell>
                                <StyledTableCell>{value.submitted_by}</StyledTableCell>
                                <StyledTableCell>{value.platform===3 ? "All" : value.platform===1 ? "App" : value.platform===2?"Dashboard" : ""}</StyledTableCell>
                                <StyledTableCell>{value.status==1 && <Chip label="Active" sx={{backgroundColor:"#D3F2E5",borderRadius:"6px",width:"70px"}}  />}</StyledTableCell>
                                <StyledTableCell>{value.status==0 && <MoreIcon onClick={(e)=>{
                                    setActiveId(value.id)
                                    setAnchorEl(e.currentTarget)
                                }} />}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        <Menu
            anchorEl={anchorEl}
            open={optionsOpen}
            onClose={handleOptionClose}
        >
        <MenuItem onClick={() => {
            setAlertStatus(true)
            handleOptionClose()
            }} sx={{margin: "0 20px"}}>Active</MenuItem>        
        </Menu>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        handbookList:state.ImportantContactReducer.handbookList,
        handbookUpdateStatus:state.ImportantContactReducer.handbookUpdateStatus
        
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getHbList:()=>(dispatch(ImportantContactAction.getHbList())),
        updateHbStatus:(data)=>(dispatch(ImportantContactAction.updateHbStatus(data))),
        setUpdateImportantContact:()=>(dispatch(ImportantContactAction.setUpdateImportantContact())),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(HandBook);