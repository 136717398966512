import { Box, Button, Tab, Tabs, Typography,Grid } from '@mui/material'
import { styled } from '@mui/styles'
import { ReactComponent as PlusWhiteIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import React from 'react'
import SustainabiltyTabs from './SustainabiltyTabs';
import { getYYYYMMDDFormat } from '../../Utils/utils';
import moment from 'moment';
import { connect } from 'react-redux';
import { getSustainableListAction, getSustainableListNullAction } from '../../redux/actions/SustainabilityAction';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const StyledTypography = styled(Typography)({
  color: "#002D74"
})

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{background: "white"}}
    >
      {value === index && (
        <Box sx={{ paddingTop:'16px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Sustainbility = ({getSustainableList, getSustainableListNull, getSustainableListData, sustainableListCount}) => {

  const [value, setValue] = React.useState(localStorage.getItem('tab') ? parseInt(localStorage.getItem('tab')) : 0);
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if(localStorage?.getItem('tab')) {
      localStorage.removeItem('tab')
    }
  },[])

  return (   
    <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)",minHeight:"100%"}}>
      <Grid item xs={0.5} />
            <Grid item xs={11} >
    <Box sx={{ width: '100%',  background: "#F9FAFA", p: '64px 0 0 0', display: "flex",flexDirection: "column", alignItems: "center"}}>
      <Box sx={{ width: '100%', display: "flex", justifyContent: 'space-between',  }}>
        <Box>
          <StyledTypography sx={{fontSize: "26px", fontWeight: 500, color:'#002D74' }}>Manage Sustainability Post</StyledTypography>
          <StyledTypography sx={{fontSize: "14px", fontWeight: 500, color:'#002D74' }}>Manage building related events and promotion to tenants and BMS team.</StyledTypography>
        </Box>
        <Button
        onClick={() => navigate('/create_sustainability/null/false/false/false')}
              variant="contained"
              sx={{
                textTransform: "none",
                gap: "6px",
                background: "#123FA9",
                height: 36.5,
                display: "flex",
                alignItems: "center",
               }}
              endIcon={<PlusWhiteIcon />}
            >               
            Create New         </Button>
      </Box>
      
      <Box sx={{ width: '100%', py: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{background: "white"}} >
          <Tab label="Active" {...a11yProps(0)} sx={{textTransform: "none"}} />
          <Tab label="Scheduled" {...a11yProps(1)}  sx={{textTransform: "none"}} />
          <Tab label="Archived" {...a11yProps(2)}  sx={{textTransform: "none"}} />
          <Tab label="Drafts" {...a11yProps(3)}  sx={{textTransform: "none"}} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SustainabiltyTabs setValue={setValue} tabName='Active'/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SustainabiltyTabs setValue={setValue} tabName='Schedule' />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SustainabiltyTabs setValue={setValue} tabName='Archived' />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <SustainabiltyTabs setValue={setValue} tabName='Draft' />
      </CustomTabPanel>
    </Box>

    </Box>
    </Grid>
    <Grid item xs={0.5} />
    </Grid>    
  )
}

export default Sustainbility