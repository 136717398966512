import {
  Box,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Divider,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Stack 
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { ReactComponent as UploadIcon } from "../../Assets/icons/Upload.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxTerms.svg";
import { ReactComponent as CloseIcon } from '../../Assets/icons/Alertclose.svg';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import * as eventsAndPromotionAction from "../../redux/actions/EventsAndPromotionAction";
import * as CommonAction from "../../redux/actions/commonAction";
import {
  getYYYYMMDDFormat,
  getUTCDateTimeFormat,
  getDateTimeFormat,
  preventDatePickerKeyboard,
  MenuProps,
} from "../../Utils/utils";
import ToastAlert from "../common/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../../Assets/icons/back.svg";
import Loader from "../common/Loader";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import Alert from "../common/faultalert";
import Location from "../../Assets/icons/location.svg";
import { ReactComponent as Clock } from "../../Assets/icons/clock.svg";
import Phone from "../../Assets/icons/phone.png";
import Email from "../../Assets/icons/email.png";
import WifiIcon from "@mui/icons-material/Wifi";
import { RiVideoLine } from "react-icons/ri"; //video
import { BsFiletypePpt } from "react-icons/bs"; //ppt
import { RiImage2Line } from "react-icons/ri"; //image
import { FaRegFilePdf } from "react-icons/fa"; //pdf
import { BsFiletypeDoc } from "react-icons/bs"; //doc
import { TiDocumentText } from "react-icons/ti"; //txt
import { BiSpreadsheet } from "react-icons/bi"; //spreadsheet
import { ReactComponent as ExcelIcon } from '../../Assets/uploadIcons/Excel.svg';
import { ReactComponent as ImageIcon } from '../../Assets/uploadIcons/Jpeg.svg';
import { ReactComponent as PDFIcon } from '../../Assets/uploadIcons/PDF.svg';
import { ReactComponent as PPTIcon } from '../../Assets/uploadIcons/Powerpoint.svg';
import { ReactComponent as WordIcon } from '../../Assets/uploadIcons/Word.svg';
import { ReactComponent as DeleteIcon } from '../../Assets/uploadIcons/DeleteIcon.svg';
import axios from "axios";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moments from "moment-timezone";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ReactComponent as CropClose } from "../../Assets/icons/CropClose.svg";
import DOMPurify from "dompurify";
import LinearProgress, { linearProgressClasses,} from "@mui/material/LinearProgress";

export const BorderLinearProgress = muiStyled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "dark" ? "#308fe8" : "#33B7A5",
  },
}));

export const getFileIcon = (fileType) => {
  let splited = fileType?.split('/')
  console.log("splited ",splited, splited[0])
  if(splited[0] == 'application' ) {
    if(splited[1].includes('pdf')) {
      return <PDFIcon style={{width: "50px", height:"50px"}}/>
    }else if(splited[1].includes('word') && !splited[1].includes('presentation')) {
      return <WordIcon style={{width: "50px", height:"50px"}}/>
    }else if( splited[1].includes('presentation')) {
      return <PPTIcon style={{width: "50px", height:"50px"}}/>
    }else if(splited[1].includes('csv') || splited[1].includes('spread') && !splited[1].includes('presentation')) {
      return <ExcelIcon style={{width: "50px", height:"50px"}}/>
    }
  }
  if(splited[0] == "text") {
    if(splited[1] == "csv") {
      return <ExcelIcon style={{width: "50px", height:"50px"}}/>
    } else {
    return ''
    }
  }
  if(splited[0] == "video") {
    return <ImageIcon style={{width: "50px", height:"50px"}} />
  }
  if(splited[0] == "image") {
    return <ImageIcon style={{width: "50px", height:"50px"}} />
  }
}

export const PrimaryTypography = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 550,
  fontSize: "16px",
});
export const SecondaryTypography = styled(Typography)({
  color: "#0F2C6F",
  fontWeight: 500,
  fontSize: "14px",
});

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: "#6F80A9",
  textTransform: "none",
  fontSize: "12px",
  [theme.breakpoints.up("md")]: {
    fontSize: "14px",
  },
}));

export const getPlatformName = (id) => {
  if (id) {
    const platforms = [
      { app: false, dashboard: false },
      { app: true, dashboard: false },
      { app: false, dashboard: true },
      { app: true, dashboard: true },
    ];
    return platforms[id];
  }
};

// export const getDateFormat = (data, id) => {
//     console.log('within event ',id);
//     if(id !== 'null' && data) {
//         const formattedDate = moments(data, 'YYYY-MM-DD HH:mm:ss').tz('Asia/Kolkata').format('ddd MMM DD YYYY HH:mm:ss [GMTZ (India Standard Time) {}]');
//         const dateObject = moment.tz(formattedDate, 'ddd MMM DD YYYY HH:mm:ss [GMTZ (India Standard Time) {}]', 'Asia/Kolkata').toDate();
//         return dateObject;
//     } else {
//          return null
//         }
// }
export const getDateFormat = (data, id) => {
  // console.log('data ',data)
  if (id !== "null" && data) {
    // Parse the UTC time string
    // const utcDateTime = moment.utc(data);

    // Convert UTC time to local time
    const localTimes = moment(data).format(" HH:mm:ss"); //YYYY-MM-DD
    const localDates = moment(data).format(" YYYY-MM-DD");

    return { dateObject: localDates, timeString: localTimes };
  } else {
    return null;
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography variant="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTypography = styled(Typography)({
  fontSize: "13px",
  color: " #0F2C6F",
  fontWeight: 550,
  margin: "8px 0 8px 0",
});

const checkedStyle = {
  backgroundColor: "#FFFFFF",
  color: "#6F80A9",
  border: "1px solid #6F80A9",
  ml: 2,
  "&:hover": { backgroundColor: "#FFFFFF" },
};
const uncheckedStyle = {
  backgroundColor: "#123FA9",
  color: "#FFFFFF",
  ml: 2,
  "&:hover": { backgroundColor: "#123FA9" },
};

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ indent: "-1" }, { indent: "+1" }],
];
const module = {
  toolbar: toolbarOptions,
  keyboard: {
    bindings: {
      "list autofill": {
        prefix: /^\s*()$/,
      },
    },
  },
};
const CreateEvent = ({
  SETeANDpStateToNULL,
  createEvent,
  eventPromotionStateNull,
  eventCreateStatus,
  eventAndPromotionDetails,
  getEandPDetails,
  updateDraftStatus,
  updateEandPDraft,
  match,
}) => {
  const navigate = useNavigate();
  let { id, reopen, edit } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [coverImage, setCoverImage] = useState([]);
  const [croppedCoverImage, setCroppedCoverImage] = useState([]);
  const [aditionalImages, setAditionalImages] = useState([]);
  const [additionalDocuments, setAdditionalDocuments] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [postType, setPostType] = useState("scheduled");
  const [eventData, setEventData] = useState({
    eventStartDate: null,
    eventEndDate: null,
    promotionStartDate: null,
    promotionEndDate: null,
    eventStartTime: "",
    eventEndTime: "",
  });
  const [eventAddStatus, setEventAddStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [error, setError] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [eventStartHours, setEventStartHours] = useState([
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ]);
  const [tooltipId, setTooltipId] = useState(null);
  const [apiRequestOngoing, setApiRequestOngoing] = useState(false)
  const [uploadErrorMsg, setUploadErrorMsg] = useState("")
  const [eventEndHours, setEventEndHours] = useState();
  const [scheduleEndHours, setScheduleEndHours] = useState();
  const [scheduleStartHours, setScheduleStartHours] = useState();
  const [alertStatus, setAlertStatus] = useState(false);
  const [isCoverImageRemoved, setIsCoverImageRemoved] = useState(null);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [termsAndConditionLength, setTermsAndConditionLength] = useState(0);
  const [uploadedImg, setUploadedImg] = useState(null);
  const [submitBool, setSubmitBool] = useState(false);
  const [croppedImg, setCroppedImg] = useState(null);
  const [cropperKey, setCropperKey] = useState(Date.now());
  const [imagename, setCoverImageName] = useState("");
  const [isCroppedSubmit, setIsCroppedSubmit] = useState(false);
  const currentTab = localStorage.getItem("currentScreen");
  const cropperRef = useRef(null);
  const onCrop = (e) => {
    if (isCroppedSubmit) return;
    const cropper = cropperRef.current?.cropper;
    const ur = cropper.getCroppedCanvas().toDataURL();
    const fd = new FormData();
    fetch(ur)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], imagename, {
          type: coverImage[0].file.type,
        });
        setCroppedCoverImage([file]);

        // fd.append('image', file)
      });
    setCroppedImg(cropper.getCroppedCanvas().toDataURL());
  };

  let today = new Date();
  useEffect(() => {
    // getStartHours(0)
    getEventStartHours(0);
    getScheduleStartHours(0);
    getEventEndHours(0);
    getScheduleEndHours(0);
    if (id != "null") {
      getEandPDetails({ id: parseInt(id) });
    }
  }, []);

  const postSetting = () => {
    setPostType(eventAndPromotionDetails?.trigger == 1 ? "now" : "scheduled");
    // const promotionStart = getDateFormat(eventAndPromotionDetails?.schedule_from_datetime, id);
    // const promotionEnd = getDateFormat(eventAndPromotionDetails?.schedule_to_datetime, id);
    const promotionStart = getDateFormat(
      eventAndPromotionDetails?.schedule_from_datetime,
      id
    );
    const promotionEnd =
      eventAndPromotionDetails?.trigger == 1
        ? eventAndPromotionDetails?.schedule_to_datetime == null
          ? null
          : eventAndPromotionDetails?.schedule_to_datetime?.slice(0, 10)
        : getDateFormat(eventAndPromotionDetails?.schedule_to_datetime, id);
    const promotionStartTime = getDateFormat(
      eventAndPromotionDetails?.schedule_from_datetime,
      id
    );
    const promotionEndTime = getDateFormat(
      eventAndPromotionDetails?.schedule_to_datetime,
      id
    );
    let obj = {
      // promotionStartDate:promotionStart ? promotionStart : null,
      // promotionEndDate:promotionEnd ? promotionEnd : null,
      // promotionStartTime: eventAndPromotionDetails?.schedule_from_datetime_format,
      // promotionEndTime: eventAndPromotionDetails?.schedule_to_datetime_format == "23:59" ? "23:00" : eventAndPromotionDetails?.schedule_to_datetime_format,
      promotionStartDate: promotionStart?.dateObject
        ? promotionStart.dateObject
        : null,
      promotionEndDate:
        eventAndPromotionDetails?.trigger == 1
          ? promotionEnd
            ? promotionEnd
            : null
          : promotionEnd?.dateObject
          ? promotionEnd.dateObject
          : null,
      promotionStartTime: promotionStartTime?.timeString
        ? promotionStartTime?.timeString?.slice(0, 6).trim()
        : null,
      promotionEndTime: promotionEndTime?.timeString
        ? promotionEndTime?.timeString?.slice(0, 6).trim()
        : null,
    };
    return obj;
  };
  console.log("eventData ", eventData);
  useEffect(() => {
    if (id != "null") {
      let startTime =
        eventAndPromotionDetails.event_start_time != null
          ? moment(eventAndPromotionDetails.event_start_time).format("HH:mm")
          : "";
      let endTime =
        eventAndPromotionDetails.event_end_time != null
          ? moment(eventAndPromotionDetails.event_end_time).format("HH:mm")
          : "";
      setEventData((prev) => ({
        ...prev,
        title:
          eventAndPromotionDetails?.title != null
            ? eventAndPromotionDetails?.title
            : "",
        description:
          eventAndPromotionDetails?.desc != null
            ? eventAndPromotionDetails?.desc
            : "",
        terms_conditions:
          eventAndPromotionDetails?.t_and_c != null
            ? eventAndPromotionDetails?.t_and_c
            : "",
        location:
          eventAndPromotionDetails?.location != null
            ? eventAndPromotionDetails?.location
            : "",
        contact_email:
          eventAndPromotionDetails?.email != null
            ? eventAndPromotionDetails?.email
            : "",
        mobile:
          eventAndPromotionDetails?.mobile_code != null &&
          eventAndPromotionDetails?.mobile_no != null
            ? `${eventAndPromotionDetails?.mobile_code}${eventAndPromotionDetails?.mobile_no}`
            : "",
        mobile_code:
          eventAndPromotionDetails?.mobile_code != null
            ? eventAndPromotionDetails?.mobile_code.slice(1)
            : "",
        mobile_number:
          eventAndPromotionDetails?.mobile_no != null
            ? eventAndPromotionDetails?.mobile_no
            : "",
        eventStartDate:
          eventAndPromotionDetails?.event_start_time != null
            ? new Date(eventAndPromotionDetails?.event_start_time)
            : null,
        eventEndDate:
          eventAndPromotionDetails?.event_end_time != null
            ? new Date(eventAndPromotionDetails?.event_end_time)
            : null,
        eventStartTime: eventAndPromotionDetails?.event_start_time_format,
        eventEndTime: eventAndPromotionDetails?.event_end_time_format,
        ...getPlatformName(eventAndPromotionDetails?.platform),
        ...postSetting(),
      }));
      if (
        eventAndPromotionDetails?.assets?.cover?.length == 0 ||
        eventAndPromotionDetails?.assets?.cover == null
      ) {
        // console.log('within if eventAndPromotionDetails?.assets?.cover ',eventAndPromotionDetails?.assets?.cover)
      } else {
        // console.log('eventAndPromotionDetails?.assets?.cover ',eventAndPromotionDetails?.assets?.cover)
        setCoverImage([
          {
            ...eventAndPromotionDetails?.assets?.cover,
            src: eventAndPromotionDetails?.assets?.cover?.new_filename,
          },
        ]);
      }
      if (eventAndPromotionDetails?.assets?.add_on?.length > 0) {
        let data = eventAndPromotionDetails?.assets?.add_on;
        let duplicatedArray = eventAndPromotionDetails?.assets?.add_on?.map(
          (obj) => ({ ...obj })
        );
        for (let i = 0; i < duplicatedArray.length; i++) {
          duplicatedArray[i].src = duplicatedArray[i].new_filename;
        }
        setAditionalImages([...duplicatedArray]);
      }
      if (eventAndPromotionDetails?.assets?.others?.length > 0) {
        let data = eventAndPromotionDetails?.assets?.others;
        let duplicatedDocumentArray = eventAndPromotionDetails?.assets?.others?.map(
          (obj) => ({ ...obj })
        );
        for (let i = 0; i < duplicatedDocumentArray.length; i++) {
          duplicatedDocumentArray[i].src = duplicatedDocumentArray[i].new_filename;
        }
        setAdditionalDocuments([...duplicatedDocumentArray]);
      }
    }
  }, [eventAndPromotionDetails]);
  // console.log('eventAndPromotionDetailseventAndPromotionDetails ',eventAndPromotionDetails)
  useEffect(() => {
    if (eventCreateStatus?.response_status == "success") {
      setLoaderStatus(false);
      setEventAddStatus({
        message: eventCreateStatus?.response_message,
        status: true,
        color: "green",
        icon: "tick",
      });
      setTimeout(() => {
        setEventAddStatus({ message: "", status: false, color: "", icon: "" });
        setAlertStatus(false);
        SETeANDpStateToNULL();
        eventPromotionStateNull();
        navigate("/events_promotions/0");
      }, 5000);
    } else if (eventCreateStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setEventAddStatus({
        message: eventCreateStatus?.response_message,
        status: true,
        color: "red",
        icon: "close",
      });
      setTimeout(() => {
        setEventAddStatus({ message: "", status: false, color: "", icon: "" });
        SETeANDpStateToNULL();
        eventPromotionStateNull();
      }, 5000);
    }
  }, [eventCreateStatus]);

  useEffect(() => {
    if (updateDraftStatus?.response_status == "success") {
      setLoaderStatus(false);
      setEventAddStatus({
        message: updateDraftStatus?.response_message,
        status: true,
        color: "green",
        icon: "tick",
      });
      setTimeout(() => {
        setAlertStatus(false);
        setEventAddStatus({ message: "", status: false, color: "", icon: "" });
        SETeANDpStateToNULL();
        eventPromotionStateNull();
        navigate("/events_promotions/0");
      }, 5000);
    } else if (updateDraftStatus?.response_status == "fail") {
      setLoaderStatus(false);
      setEventAddStatus({
        message: updateDraftStatus?.response_message,
        status: true,
        color: "red",
        icon: "close",
      });
      setTimeout(() => {
        setEventAddStatus({ message: "", status: false, color: "", icon: "" });
        SETeANDpStateToNULL();
        eventPromotionStateNull();
      }, 5000);
    }
  }, [updateDraftStatus]);

  useEffect(() => {
    let promotionDate = new Date(eventData.promotionStartDate);
    if (promotionDate > today) {
      getScheduleStartHours(0);
    } else {
      getScheduleStartHours(today.getHours() + 1);
    }
  }, [eventData.promotionStartDate, eventAndPromotionDetails]);

  useEffect(() => {
    let promotionDate = new Date(eventData.promotionEndDate);
    if (promotionDate > today) {
      getScheduleEndHours(0);
    }
  }, [eventData.promotionStartTime, eventData.promotionEndDate]);
  useEffect(() => {
    let promotionDate = new Date(eventData.promotionEndDate);
    if (
      moment(promotionDate).format("YYYY-MM-DD") ==
      moment(today).format("YYYY-MM-DD")
    ) {
      // console.log('promotionDate>today ',moment(promotionDate).format('YYYY-MM-DD'), moment(today).format('YYYY-MM-DD'))
      getScheduleEndHours(
        eventData.promotionStartTime == null ||
          eventData.promotionStartTime == ""
          ? `${today.getHours()}:00`
          : eventData.promotionStartTime
      );
    } else {
      // console.log('else promotionDate>today ',moment(promotionDate).format('YYYY-MM-DD'), moment(today).format('YYYY-MM-DD'))
      getScheduleEndHours(0);
    }
  }, [eventData.promotionEndDate]);

  useEffect(() => {
    let eventDate = new Date(eventData.eventStartDate);
    // if(eventDate>today){
    getEventStartHours(0);
    // }else{
    //     getEventStartHours(today.getHours()+1)
    // }
  }, [eventData.eventStartDate]);

  useEffect(() => {
    const promotionStart = getDateFormat(
      eventAndPromotionDetails?.schedule_from_datetime,
      id
    );
    const promotionEnd = getDateFormat(
      eventAndPromotionDetails?.schedule_to_datetime,
      id
    );
    setEventData((prev) => ({
      ...prev,
      promotionStartDate:
        promotionStart == null ? null : promotionStart?.dateObject,
      promotionEndDate: promotionEnd == null ? null : promotionEnd?.dateObject,
      promotionStartTime:
        id == "null"
          ? null
          : eventAndPromotionDetails?.schedule_from_datetime_format,
      promotionEndTime:
        id == "null"
          ? null
          : eventAndPromotionDetails?.schedule_to_datetime_format == "23:59"
          ? "23:00"
          : eventAndPromotionDetails?.schedule_to_datetime_format,
    }));
  }, [postType]);

  const handleChange = (event, newValue) => {
    // if(tabValue==0){
    //     let noError = validate()
    //     if(noError){
    //         setTabValue(newValue)
    //     }
    // }else{
    setTabValue(newValue);
    // }
  };

  //     const getStartHours = (minTime)=>{
  //         let timeIntrevels=[]
  //         for(let i=minTime;i<24;i++){
  //            for(let j=1;j<=2;j++){
  //                if(j%2==0){
  //                 timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`)
  //                }else{
  //                 timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)
  //                }
  //            }
  //        };
  //        setStartHours([...timeIntrevels])
  //    }

  const getEventStartHours = (minTime) => {
    // console.log('minTime ',minTime)
    let timeIntrevels = [];
    for (let i = minTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    const startHour = eventAndPromotionDetails?.event_start_time_format;
    timeIntrevels.push(startHour);
    setEventStartHours([...timeIntrevels]);
  };

  const getScheduleStartHours = (minTime) => {
    let timeIntrevels = [];
    for (let i = minTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    const startHour = eventAndPromotionDetails?.schedule_from_datetime_format;
    // const startHour = getDateFormat(eventAndPromotionDetails?.schedule_from_datetime, id)
    timeIntrevels.push(startHour);
    setScheduleStartHours([...timeIntrevels]);
  };

  const getEventEndHours = (minTime) => {
    // console.log('sssssminTime ',minTime)
    let timeIntrevels = [],
      startTime = 0;
    // console.log('startTime ',startTime)
    for (let i = startTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    setEventEndHours([...timeIntrevels]);
  };

  const getScheduleEndHours = (minTime) => {
    // console.log('endminTime ',minTime)
    let timeIntrevels = [],
      startTime = minTime === 0 ? 0 : parseInt(minTime.slice(0, 2)) + 1;

    for (let i = startTime; i < 24; i++) {
      for (let j = 1; j <= 2; j++) {
        if (j % 2 == 0) {
          timeIntrevels.push(i <= 9 ? `0${i}:30` : `${i}:30`);
        } else {
          timeIntrevels.push(i <= 9 ? `0${i}:00` : `${i}:00`);
        }
      }
    }
    const endHours = getDateFormat(
      eventAndPromotionDetails?.schedule_to_datetime,
      id
    );
    timeIntrevels.push(endHours?.timeString);
    setScheduleEndHours([...timeIntrevels]);
  };
  const handleContinueButton = () => {
    // let startTime = eventData?.eventStartTime?.split(":")
    // let endTime = eventData?.eventEndTime?.split(":")

    // let utcStartdate = new Date(eventData?.eventStartDate)
    // utcStartdate.setHours(startTime[0],startTime[1],0)

    // let utcEnddate = new Date(eventData?.eventEndDate)
    // utcEnddate.setHours(endTime[0],endTime[1],0)
    let arr = [];
    let documentsNewArr = [];
    let documentsExisArr = [];
    let existAddonImageId = aditionalImages.map((value) => {
      if (value.asset_id != null) {
        arr.push(value.asset_id);
      }
      return value.asset_id;
    });
     additionalDocuments?.forEach((value) => {
      if (!value?.filesize && value?.asset_id != null) {
        documentsNewArr.push(value?.asset_id);
      }
    });
    additionalDocuments?.forEach((value) => {
      if (value?.filesize && value?.asset_id != null) {
        documentsExisArr.push(value?.asset_id);
      }
    });
    let newAddonImage = aditionalImages.slice(0, 5).filter((value) => {
      return value.asset_id == undefined;
    });
    let err = validate();
    if (err == false) setTabValue(0);
    if (uploadedImg != null && !isCroppedSubmit) {
      setEventAddStatus({
        message: "Please submit cropped image",
        status: true,
        color: "red",
        icon: "close",
      });
      setTabValue(0);
      setTimeout(() => {
        setEventAddStatus({ message: "", status: false, color: "", icon: "" });
      }, 5000);
      return;
    }
    if (err) {
      const convertUtc = (localDateTimeString) => {
        if (
          localDateTimeString.includes("undefined") ||
          localDateTimeString.includes("null")
        ) {
          return "";
        }
        const localDateTimes = moment(
          localDateTimeString,
          "YYYY-MM-DD HH:mm:ss"
        );

        const utcDateTimes = localDateTimes.utc().format("YYYY-MM-DD HH:mm:ss");

        return utcDateTimes;
      };

      const getHHMMSStime = () => {
        const currentDate = new Date();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
        return formattedTime;
      };
      let submissionData = {
        title: eventData?.title,
        desc: eventData?.description,
        location: eventData?.location == undefined ? "" : eventData?.location,
        email:
          eventData?.contact_email == undefined ? "" : eventData?.contact_email,
        mobilecode:
          eventData?.mobile_code && eventData?.mobile_number != ""
            ? `+${eventData?.mobile_code}`
            : "",
        mobile_no:
          eventData?.mobile_number && eventData?.mobile_number != "-"
            ? parseInt(eventData?.mobile_number)
            : "",
        // start_date_time: postType!='now' ?  `${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${eventData?.promotionStartTime}:00` : `${getYYYYMMDDFormat(new Date())} 00:00:00`,
        start_date_time:
          postType != "now"
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${
                  eventData?.promotionStartTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(new Date())} ${getHHMMSStime(new Date())}`
              ).format("YYYY-MM-DD HH:mm:ss"),
        end_date_time:
          postType != "now"
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} ${
                  eventData?.promotionEndTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:30:00`
              ).format("YYYY-MM-DD HH:mm:ss"),
        tc_desc:
          eventData?.terms_conditions == undefined
            ? ""
            : eventData?.terms_conditions,
        cover_img:
          id != "null" && isCoverImageRemoved == null
            ? ""
            : coverImage[0]?.file,
        cover_img_id:
          id != "null" && isCoverImageRemoved == null
            ? coverImage[0]?.asset_id
            : "",
        triggerstatus: postType == "now" ? 1 : 2,
        platform: getPlatform(),
        status: edit == "true" ? "Edit" : "Create",
        event_end_time:
          eventData?.eventEndDate == null || eventData?.eventEndDate == ""
            ? ""
            : `${getYYYYMMDDFormat(eventData?.eventEndDate)} ${
                eventData?.eventEndTime
              }:00`,
        event_start_time:
          eventData?.eventStartTime == null || eventData?.eventStartTime == ""
            ? ""
            : `${getYYYYMMDDFormat(eventData?.eventStartDate)} ${
                eventData?.eventStartTime
              }:00`,
        cropped_cover_img:
          id != "null" && croppedCoverImage[0] == undefined
            ? ""
            : croppedCoverImage[0] != undefined
            ? croppedCoverImage[0]
            : "",
        add_on_img_ids: arr,
        others_attachs: documentsNewArr,
      };
      let formData = new FormData();
      for (let pair in submissionData) {
        formData.append(pair, submissionData[pair]);
      }

      for (let i = 0; i < newAddonImage.length; i++) {
        formData.append(`add_on_img[${i}]`, newAddonImage[i].file);
      }
      if (id != "null" && reopen === "false") {
        formData.append("id", id);
        formData.append("env_type", "Event");
        formData.append("exist_add_on_img", JSON.stringify(arr));
        formData.append("exists_others_attachs", documentsExisArr);
      }
      // console.log('submissionData ',submissionData)
      //API
      if (id != "null" && reopen === "false") {
        updateEandPDraft(formData);
      } else {
        createEvent(formData);
      }
      setLoaderStatus(true);
      setTimeout(() => {
        setLoaderStatus(false);
      }, 25000);
    }
  };
  // console.log('isCoverImageRemoved ',isCoverImageRemoved==null)

  const saveAsDraft = () => {
    if (uploadedImg != null && !isCroppedSubmit) {
      setEventAddStatus({
        message: "Please submit cropped image",
        status: true,
        color: "red",
        icon: "close",
      });
      setTimeout(() => {
        setEventAddStatus({ message: "", status: false, color: "", icon: "" });
      }, 5000);
      return;
    }
    let arr = [];
    let documentsNewArr = [];
    let documentsExisArr = [];
    let existAddonImageId = aditionalImages.map((value) => {
      if (value.asset_id != null) {
        arr.push(value.asset_id);
      }
      return value.asset_id;
    });
    additionalDocuments?.forEach((value) => {
      if (!value?.filesize && value?.asset_id != null) {
        documentsNewArr.push(value?.asset_id);
      }
    });
    additionalDocuments?.forEach((value) => {
      if (value?.filesize && value?.asset_id != null) {
        documentsExisArr.push(value?.asset_id);
      }
    });
    let newAddonImage = aditionalImages.slice(0, 5).filter((value) => {
      return value.asset_id == undefined;
    });
    const getStartDateTime = () => {
      if (
        eventData.promotionStartDate ||
        eventData.promotionStartTime == null ||
        eventData.promotionStartDate ||
        eventData.promotionStartTime == ""
      ) {
        // console.log('within if eventData ',eventData)
        return "";
      } else {
        // console.log('else if eventData ',eventData)
        return `${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${
          eventData?.promotionStartTime
        }:00`;
      }
    };
    const convertUtc = (localDateTimeString) => {
      if (
        localDateTimeString.includes("undefined") ||
        localDateTimeString.includes("null")
      ) {
        return "";
      }
      const localDateTimes = moment(localDateTimeString, "YYYY-MM-DD HH:mm:ss");

      const utcDateTimes = localDateTimes.utc().format("YYYY-MM-DD HH:mm:ss");

      return utcDateTimes;
    };
    const getHHMMSStime = () => {
      const currentDate = new Date();
      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes();
      const seconds = currentDate.getSeconds();
      const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
      return formattedTime;
    };
    let submissionData = {
      title: eventData?.title == undefined ? "" : eventData?.title,
      desc: eventData?.description == undefined ? "" : eventData?.description,
      location: eventData?.location == undefined ? "" : eventData?.location,
      email:
        eventData?.contact_email == undefined ? "" : eventData?.contact_email,
      mobilecode:
        eventData?.mobile_code == undefined || eventData?.mobile_code == ""
          ? ""
          : `+${eventData?.mobile_code}`,
      mobile_no:
        eventData?.mobile_number == undefined || eventData?.mobile_number == ""
          ? ""
          : parseInt(eventData?.mobile_number),
      // start_date_time: postType!='now' ? getStartDateTime()   : `${getYYYYMMDDFormat(new Date())} 00:00:00`,
      // end_date_time: eventData?.promotionEndDate==undefined ? '' :  `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:59:00`  ,
      start_date_time:
        postType != "now"
          ? eventData?.promotionStartDate == null ||
            eventData?.promotionStartDate == ""
            ? ""
            : eventData?.promotionStartTime == null ||
              eventData?.promotionStartTime == ""
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionStartDate)} 00:00:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(eventData?.promotionStartDate)} ${
                  eventData?.promotionStartTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
          : moment(
              `${getYYYYMMDDFormat(new Date())} ${getHHMMSStime(new Date())}`
            ).format("YYYY-MM-DD HH:mm:ss"),
      end_date_time:
        postType != "now"
          ? eventData?.promotionEndDate == null ||
            eventData?.promotionEndDate == ""
            ? ""
            : eventData?.promotionEndTime == null ||
              eventData?.promotionEndTime == ""
            ? moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:30:00`
              ).format("YYYY-MM-DD HH:mm:ss")
            : moment(
                `${getYYYYMMDDFormat(eventData?.promotionEndDate)} ${
                  eventData?.promotionEndTime
                }:00`
              ).format("YYYY-MM-DD HH:mm:ss")
          : moment(
              `${getYYYYMMDDFormat(eventData?.promotionEndDate)} 23:30:00`
            ).format("YYYY-MM-DD HH:mm:ss"),
      tc_desc:
        eventData?.terms_conditions == undefined
          ? ""
          : eventData?.terms_conditions,
      cover_img:
        coverImage[0]?.file == "" || coverImage[0]?.file == null
          ? ""
          : coverImage[0]?.file,
      cover_img_id:
        coverImage[0]?.file == undefined || id != "null"
          ? coverImage[0]?.asset_id
          : "",
      triggerstatus: postType == "now" ? 1 : 2,
      platform: getPlatform(),
      status: "Draft",
      event_end_time:
        eventData?.eventEndDate == null || eventData?.eventEndDate == ""
          ? ""
          : eventData?.eventEndTime == null || eventData?.eventEndTime == ""
          ? `${getYYYYMMDDFormat(eventData?.eventEndDate)} 23:30:00`
          : `${getYYYYMMDDFormat(eventData?.eventEndDate)} ${
              eventData?.eventEndTime
            }:00`,
      event_start_time:
        eventData?.eventStartDate == null || eventData?.eventStartDate == ""
          ? ""
          : eventData?.eventStartTime == undefined ||
            eventData?.eventStartTime == ""
          ? `${getYYYYMMDDFormat(eventData?.eventStartDate)} 00:00:00`
          : `${getYYYYMMDDFormat(eventData?.eventStartDate)} ${
              eventData?.eventStartTime
            }:00`,
      cropped_cover_img:
        id != "null" && croppedCoverImage[0] == undefined
          ? ""
          : croppedCoverImage[0] != undefined
          ? croppedCoverImage[0]
          : "",
      add_on_img_ids: arr,
      others_attachs: documentsNewArr,
    };
    let formData = new FormData();
    for (let pair in submissionData) {
      formData.append(pair, submissionData[pair]);
    }
    for (let i = 0; i < newAddonImage.length; i++) {
      formData.append(`add_on_img[${i}]`, newAddonImage[i].file);
    }
    if (id != "null") {
      formData.append("id", id);
      formData.append("env_type", "Event");
      formData.append("exist_add_on_img", JSON.stringify(arr));
      formData.append("exists_others_attachs", documentsExisArr);
    }
    // api
    // console.log('before id ',id);
    if (id != "null") {
      // console.log('id ',id);
      updateEandPDraft(formData);
    } else {
      createEvent(formData);
    }

    //loader
    setAlertStatus(false);
    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 25000);
  };
  const getPlatform = () => {
    if (eventData.app && !eventData.dashboard) {
      return 1;
    } else if (!eventData.app && eventData.dashboard) {
      return 2;
    } else if (eventData.app && eventData.dashboard) {
      return 3;
    } else {
      return 0;
    }
  };

  const validate = () => {
    let newErrors = [],
      errorMessges = {};
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (eventData.title === undefined || eventData.title == "") {
      newErrors.push(false);
      errorMessges["title"] = "Please fill out this field";
    } else {
      newErrors.push(true);
      errorMessges["title"] = "";
    }

    if (
      eventData.description === undefined ||
      eventData.description == "" ||
      descriptionLength == 0
    ) {
      newErrors.push(false);
      errorMessges["description"] = "Please fill out this field";
    } else {
      newErrors.push(true);
      errorMessges["description"] = "";
    }

    // if (eventData.location === undefined || eventData.location=='') {
    //     newErrors.push(false)
    //     errorMessges['location'] = 'Please fill out this field'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['location'] = ''
    // }

    // if (eventData.contact_email === undefined || eventData.contact_email=='') {
    //     newErrors.push(false)
    //     errorMessges['contact_email'] = 'Email Required!'
    // } else if (!eventData.contact_email.match(emailRegex)) {
    //     newErrors.push(false)
    //     errorMessges['contact_email'] = 'Invalid Email!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['contact_email'] = ''
    // }

    // if (eventData.eventStartDate === undefined || eventData.eventStartDate==null) {
    //     newErrors.push(false)
    //     errorMessges['eventStartDate'] = 'Start Date Required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['eventStartDate'] = ''
    // }

    // if (eventData.eventEndDate === undefined || eventData.eventEndDate==null) {
    //     newErrors.push(false)
    //     errorMessges['eventEndDate'] = 'End Date Required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['eventEndDate'] = ''
    // }

    // if (eventData.eventStartTime === undefined || eventData.eventStartTime=='' ) {
    //     newErrors.push(false)
    //     errorMessges['eventStartTime'] = 'Start Time Required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['eventStartTime'] = ''
    // }

    // if (eventData.eventEndTime === undefined || eventData.eventEndTime=='') {
    //     newErrors.push(false)
    //     errorMessges['eventEndTime'] = 'End Time Required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['eventEndTime'] = ''
    // }

    // if (eventData.terms_conditions === undefined || eventData.terms_conditions=='' || termsAndConditionLength ==0) {
    //     newErrors.push(false)
    //     errorMessges['terms_conditions'] = 'Terms and conditions required!'
    // } else {
    //     newErrors.push(true)
    //     errorMessges['terms_conditions'] = ''
    // }

    if (
      postType === "scheduled" &&
      (eventData.promotionStartDate === undefined ||
        eventData.promotionStartDate == null)
    ) {
      newErrors.push(false);
      errorMessges["promotionStartDate"] = "Start Date required!";
    } else {
      newErrors.push(true);
      errorMessges["promotionStartDate"] = "";
    }

    if (
      postType === "scheduled" &&
      (eventData.promotionStartTime === undefined ||
        eventData.promotionStartTime === "")
    ) {
      newErrors.push(false);
      errorMessges["promotionStartTime"] = "Start Time required!";
    } else if (
      currentTab == 1 &&
      postType === "scheduled" &&
      eventData.promotionStartTime != undefined &&
      eventData.promotionStartTime != ""
    ) {
      if (
        moment(eventData.promotionStartDate).format("YYYY-MM-DD") ==
          moment(today).format("YYYY-MM-DD") &&
        eventData.promotionStartTime <
          moment(new Date()).add(15, "minutes").format("HH:mm")
      ) {
        newErrors.push(false);
        errorMessges["promotionStartTime"] = "Choose valid time!";
      }
    } else {
      newErrors.push(true);
      errorMessges["promotionStartTime"] = "";
    }

    if (
      postType === "scheduled" &&
      (eventData.promotionEndTime === undefined ||
        eventData.promotionEndTime == "")
    ) {
      newErrors.push(false);
      errorMessges["promotionEndTime"] = "End Time required!";
    } else {
      newErrors.push(true);
      errorMessges["promotionEndTime"] = "";
    }

    if (
      eventData.promotionEndDate === undefined ||
      eventData.promotionEndDate == null
    ) {
      newErrors.push(false);
      errorMessges["promotionEndDate"] = "End Date required!";
    } else {
      newErrors.push(true);
      errorMessges["promotionEndDate"] = "";
    }

    if (aditionalImages.length > 5) {
      newErrors.push(false);
      errorMessges["additionalImages"] = "Max 5 images only allowed";
    } else {
      newErrors.push(true);
      errorMessges["additionalImages"] = "";
    }

    // if(!eventData.app && !eventData.dashboard){
    //     newErrors.push(false)
    //     errorMessges['platform'] = 'Please select at least one if you want to proceed'
    // }else {
    //     newErrors.push(true)
    //     errorMessges['platform'] = ''
    // }

    if (coverImage.length < 1) {
      newErrors.push(false);
      errorMessges["coverImage"] = "Add Cover Image ";
    } else {
      newErrors.push(true);
      errorMessges["coverImage"] = "";
    }

    let errorStatus = newErrors.includes(false);
    setError({ ...errorMessges });
    return !errorStatus;
  };
  const handleDate = (e, datafrom) => {
    setEventData((prev) => ({ ...prev, [datafrom]: e }));
    setError({
      ...error,
      [datafrom]: "",
    });
  };
  const handleTime = (e, datafrom) => {
    if (datafrom == "eventStartTime") {
      getEventEndHours(e.target.value);
    } else if (datafrom == "promotionStartTime") {
      getScheduleEndHours(e.target.value);
    }

    setEventData((prev) => ({
      ...prev,
      [datafrom]: e.target.value,
      [`display${datafrom}`]: e,
    }));
    setError({
      ...error,
      [datafrom]: "",
    });
  };
  const handleUploadButton = (e) => {
    setError({
      ...error,
      coverImage: "",
    });
    setUploadedImg(null);
    setSubmitBool(false);
    setUploadedImg(URL.createObjectURL(e.target.files[0]));
    let target = e.target.files;
    setCoverImageName(target[0].name);
    let arr = [];
    arr.push({
      id: 1,
      fileName: target[0].name,
      size: target[0].size,
      file: target[0],
      src: URL.createObjectURL(target[0]),
    });

    setCoverImage([...arr]);
  };
  const handleUploadMultipleButton = (e) => {
    let target = e.target.files;
    let arr = aditionalImages;

    if (target.length > 5) {
      setError({
        ...error,
        additionalImages: "Please select maximum 5 images only",
      });
    } else {
      for (let i = 0; i < target.length; i++) {
        arr.push({
          fileName: target[i].name,
          size: target[i].size,
          file: target[i],
          src: URL.createObjectURL(target[i]),
        });
      }
      setAditionalImages([...arr]);
      setError({
        ...error,
        additionalImages: "",
      });
    }
  };
  const removeAditionalImages = (indexId) => {
    let arr = aditionalImages.filter((item, index) => {
      if (index !== indexId) {
        return item;
      }
    });

    setAditionalImages([...arr]);
  };

  const getUniqueId = () => {
    let randomUniqueId;
    let isAlreadyExists;
    do {
      randomUniqueId = Math.floor(Math.random() * 10000);
      isAlreadyExists = additionalDocuments?.some(item => item.id === randomUniqueId);
      console.log("Checking ID:", randomUniqueId, "Exists:", isAlreadyExists);
    } while (isAlreadyExists);
  
    return randomUniqueId;
  };
  
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: (event) => {
      console.log("event ", event);
      const progress = Math.round((100 * event.loaded) / event.total);
      setUploadProgress(progress);
    },
  };

  const handleUploadDocument = (e) => {
    let target = e.target.files;
    let arr = additionalDocuments;
    let length = getUniqueId();
    const formData = new FormData();
    formData.append("type", 'Event');
    formData.append("attachments", target[0]);
    if(id != "null") {
      formData.append("id", id);
    }
    setApiRequestOngoing(true)
    let apiUrl = id !="null" ? "https://sglandbev1.demopsts.com/public/api/EditGeneralupload" : "https://sglandbev1.demopsts.com/public/api/Generalupload"
    axios
    .post(apiUrl, formData, config)
    .then((response) => {
      console.log("File uploaded successfully", response.data);
      if(response?.data?.response_status == "success") {
      arr.push({
          asset_id: response?.data?.response_json?.id,
          fileName: target[0]?.name,
          size: target[0]?.size,
          file: target[0],
          type: target[0]?.type,
          src: URL.createObjectURL(target[0]),
          new_filename: response?.data?.response_json?.url
      })
      setAdditionalDocuments([...arr])
      const fileInput = document.getElementById("file_input");
      fileInput.value = "";
      setUploadErrorMsg("")
    } else if(response?.data?.response_status == "fail") {
      setUploadErrorMsg(response?.data?.response_message)
    }
    setApiRequestOngoing(false)
    setUploadProgress(0)
    })
    .catch((error) => {
      console.error("Error uploading file:", error);
      if(error?.data?.response_status == "fail") {
        setUploadErrorMsg(error?.data?.response_message)
      }
      setApiRequestOngoing(false)
      setUploadProgress(0)
    });

  };

  const handleDeleteFiles = (id) => {
    console.log("additionalDocuments ",additionalDocuments)
    let removedFiles = additionalDocuments?.filter(item => item?.asset_id !== id)
    console.log("removedFiles ",removedFiles, 'id ',id)
    setAdditionalDocuments(removedFiles)
  }

  console.log("addiio ",additionalDocuments)

  const handleCheckBox = (name) => {
    setEventData((prev) => ({
      ...prev,
      [name]: !eventData[name],
    }));
  };

  const handleMobileInput = (data, country, event) => {
    const { name, value } = event.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
      mobile_code: country.dialCode,
      mobile_number: data.slice(country.dialCode.length),
    }));
  };
  const handleInput = (e, fieldName) => {
    if (fieldName == "terms_conditions" || fieldName == "description") {
      setEventData((prev) => ({ ...prev, [fieldName]: e }));
      setError({
        ...error,
        [fieldName]: "",
      });
      return;
    }
    const { name, value } = e.target;
    setEventData((prev) => ({ ...prev, [name]: value }));
    setError({
      ...error,
      [name]: "",
    });
  };
  const handleBackButton = () => {
    let params = ["title", "description", "email", "terms_conditions"];
    let arr = [];
    for (let i = 0; i < params.length; i++) {
      if (eventData[params[i]] != "" && eventData[params[i]] != undefined) {
        arr.push(true);
      } else {
        arr.push(false);
      }
    }
    if (edit == "false" && arr.includes(true)) {
      setAlertStatus(true);
    } else {
      if (uploadedImg != null && !isCroppedSubmit) return;
      navigate(-1);
    }
    localStorage.removeItem("currentScreen");
  };

  const clickContinue = () => {
    if (tabValue == 0) {
      setTabValue(1);
    } else if (tabValue == 1) {
      handleContinueButton();
    }
  };

  useEffect(() => {
    const descriptionText = document.getElementById("description");
    setDescriptionLength(descriptionText.innerText.length);
    const termsAndConditionText = document.getElementById("termsandcondition");
    setTermsAndConditionLength(termsAndConditionText.innerText.length);
  }, [eventData.description, eventData.terms_conditions]);


  const handleCopy = (id, new_filename) => {
    navigator.clipboard.writeText(new_filename);
    setTooltipId(id);
    setTimeout(() => {
      setTooltipId(null);
    }, 8000);
  };

  const getplatformScren = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#FFFFFF",
          }}
        >
          <StyledTypography>Platform</StyledTypography>
          <Box>
            <Checkbox
              icon={<CheckboxIcon />}
              checked={eventData?.app == true ? true : false}
              onClick={() => handleCheckBox("app")}
              sx={{ display: "inline" }}
            />
            <Typography
              sx={{ display: "inline", color: "#0F2C6F", fontSize: "12px" }}
            >
              App
            </Typography>
          </Box>
          <Box>
            <Checkbox
              icon={<CheckboxIcon />}
              checked={eventData?.dashboard == true ? true : false}
              onClick={() => handleCheckBox("dashboard")}
              sx={{ display: "inline" }}
            />
            <Typography
              sx={{ display: "inline", color: "#0F2C6F", fontSize: "12px" }}
            >
              Dashboard
            </Typography>
          </Box>
        </Box>
        <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
          {error?.platform}
        </Typography>
      </>
    );
  };
  const renderNewEvent = (eventData) => {
    return (
      <Grid rowSpacing={2} container sx={{ background: "white", p: 2 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Event Details*
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "550", color: "#0F2C6F" }}
          >
            Title*
          </Typography>
          <TextField
            fullWidth
            sx={{ my: 1 }}
            inputProps={{ maxLength: 200 }}
            id="outlined-basic"
            label=""
            variant="outlined"
            name="title"
            value={eventData?.title}
            onChange={(e) => handleInput(e)}
          />
          <Typography variant="p" sx={{ fontSize: "14px", color: "#0F2C6F" }}>
            {eventData?.title?.length || 0}/200
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            <br />
            {error?.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "550", color: "#0F2C6F" }}
          >
            Description*
          </Typography>
          {/* <textarea maxLength={3000} name='description'
                        value={eventData.description}
                        onChange={(e) => handleInput(e)} 
                        style={{minWidth:'100%',maxWidth:'100%',height:100,marginTop:'5px',fontSize:'15px',border:'1px solid #DBDFE9'}}
                    /> */}
          <Box sx={{ mb: 6 }}>
            <ReactQuill
              value={eventData?.description}
              name="description"
              onChange={(e) => handleInput(e, "description")}
              modules={module}
              theme="snow"
              style={{ height: "150px" }}
            />
          </Box>
          {/* <TextField
                         sx={{my:1}}
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        name='description'
                        value={eventData.description}
                        onChange={(e) => handleInput(e)}
                        inputProps={{ maxLength: 3000 }}
                    /> */}
          <Typography variant="p" sx={{ fontSize: "14px", color: "#0F2C6F" }}>
            {/* {descriptionLength || 0}/5000 */}
            <p
              id="description"
              style={{ display: "none" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(eventData?.description),
              }}
            />
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            <br />
            {error?.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "550", color: "#0F2C6F" }}
          >
            Location
          </Typography>
          <TextField
            multiline
            inputProps={{ maxLength: 100 }}
            fullWidth
            id="outlined-basic"
            sx={{ my: 1 }}
            label=""
            variant="outlined"
            name="location"
            value={eventData.location == undefined ? "" : eventData.location}
            onChange={(e) => handleInput(e)}
          />
          {/* <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                        <br />{error?.location}
                    </Typography> */}
        </Grid>
        <Grid item xs={6} sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "550", color: "#0F2C6F" }}
          >
            Email / Contact
          </Typography>
          <TextField
            multiline
            fullWidth
            id="outlined-basic"
            sx={{ my: 1 }}
            inputProps={{ maxLength: 300 }}
            label=""
            variant="outlined"
            name="contact_email"
            value={
              eventData?.contact_email == undefined
                ? ""
                : eventData?.contact_email
            }
            onChange={(e) => handleInput(e)}
          />
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            {error?.contact_email}
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ mt: 1, pl: 1 }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              mb: 1,
            }}
          >
            Contact Us
          </Typography>
          <PhoneInput
            value={eventData?.mobile}
            inputStyle={{
              width: "90%",
              height: "56px",
              borderRadius: "6px",
              color: "#0F2C6F",
            }}
            onChange={(data, country, event) =>
              handleMobileInput(data, country, event)
            }
            country="sg"
            inputProps={{ name: "mobile" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Event Timing
          </Typography>
        </Grid>
        <Grid xs={6} sx={{ mt: 1, display: "flex" }}>
          <Grid>
            <StyledTypography>Start Date</StyledTypography>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              // label="Start Date"
              // disablePast
              // maxDate={eventData?.eventEndDate}
              // inputFormat="MM/DD/YYYY"
              value={eventData?.eventStartDate}
              onChange={(newValue) => handleDate(newValue, "eventStartDate")}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
              {error?.eventStartDate}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <StyledTypography>End Date</StyledTypography>
            <DesktopDatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              // label="End Date"
              // disablePast
              minDate={eventData?.eventStartDate || new Date()}
              // inputFormat="MM/DD/YYYY"
              value={eventData?.eventEndDate}
              onChange={(newValue) => handleDate(newValue, "eventEndDate")}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
            <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
              {error?.eventEndDate}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={6} sx={{ pl: 1, mt: 1, display: "flex" }}>
          <Grid>
            <StyledTypography>Start Time</StyledTypography>
            <Select
              sx={{ width: 100 }}
              MenuProps={MenuProps}
              disabled={
                eventData.eventStartDate == null ||
                eventData.eventStartDate == ""
              }
              value={eventData.eventStartTime}
              defaultValue={eventData.eventStartTime || ""}
              onChange={(newValue) => {
                handleTime(newValue, "eventStartTime");
              }}
            >
              {eventStartHours?.map((value) => (
                <MenuItem
                  sx={{
                    color: "#0F2C6F",
                    display: eventData?.eventStartTime == value ? "none" : "",
                  }}
                  value={value}
                >
                  {value}
                </MenuItem>
              ))}
            </Select>

            <Typography
              variant="p"
              sx={{ fontSize: "14px", color: "red", display: "block" }}
            >
              {error?.eventStartTime}
            </Typography>
          </Grid>
          <Grid sx={{ ml: 1 }}>
            <StyledTypography>End Time</StyledTypography>
            <Select
              sx={{ width: 100 }}
              MenuProps={MenuProps}
              disabled={
                eventData.eventEndDate == null || eventData.eventEndDate == ""
              }
              value={eventData?.eventEndTime}
              onChange={(newValue) => handleTime(newValue, "eventEndTime")}
            >
              {eventEndHours?.map((value) => (
                <MenuItem sx={{ color: "#0F2C6F" }} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>

            <Typography
              variant="p"
              sx={{ fontSize: "14px", color: "red", display: "block" }}
            >
              {error?.eventEndTime}
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={6}></Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          {/* <Divider sx={{ mb: 1, mt: 1 }} /> */}
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Terms & Conditions
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "550",
              color: "#0F2C6F",
              mt: 1,
            }}
          >
            Description
          </Typography>
          {/* <textarea style={{minWidth:'100%',maxWidth:'100%',height:80,marginTop:'5px',fontSize:'15px',border:'1px solid #DBDFE9'}}  name="terms_conditions" value={eventData?.terms_conditions} onChange={(e) => handleInput(e)} maxLength={3000}  /> */}
          <Box sx={{ mb: 6 }}>
            <ReactQuill
              value={eventData?.terms_conditions}
              name="terms_conditions"
              onChange={(e) => handleInput(e, "terms_conditions")}
              modules={module}
              theme="snow"
              style={{ height: "150px" }}
              placeholder=""
            />
          </Box>
          {/* <Editor editorState={eventData?.terms_conditions}  onEditorStateChange={(value)=>{
                        console.log(value)
                        setEventData({  
                            ...eventData,
                            terms_conditions:value
                        })
                    }} toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName" /> */}
          {/* <TextField fullWidth id="outlined-basic"
                         sx={{my:1}}
                        label=""
                        variant="outlined"
                        multiline
                        minRows={3}
                        maxRows={5}
                        name="terms_conditions" value={eventData?.terms_conditions} onChange={(e) => handleInput(e)}
                        inputProps={{ maxLength: 3000 }}
                    /> */}
          <Typography variant="p" sx={{ fontSize: "14px", color: "#0F2C6F" }}>
            {/* {termsAndConditionLength || 0}/5000 */}
            <p
              id="termsandcondition"
              style={{ display: "none" }}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(eventData?.terms_conditions),
              }}
            />
          </Typography>
          <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
            <br /> {error?.terms_conditions}
          </Typography>
          <Divider sx={{ mt: 2 }} />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
            >
              Add Cover Image*{" "}
              <span style={{ fontSize: "10px", color: "#0F2C6F" }}>
                ( maximum 1 images)
              </span>
            </Typography>
            <Box>
              {coverImage.length < 1 && (
                <Button
                  component="label"
                  variant="outlined"
                  sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize" }}
                  endIcon={<UploadIcon />}
                >
                  Upload
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(e) => handleUploadButton(e)}
                  />
                </Button>
              )}
            </Box>
          </Box>

          {uploadedImg && !submitBool && (
            <Cropper
              key={cropperKey} // Re-render Cropper when the key changes
              src={uploadedImg}
              style={{ height: 225, width: 400 }}
              // Cropper.js options
              // initialAspectRatio={0}
              aspectRatio={1 / 1}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
              minCropBoxWidth={150}
              minCropBoxHeight={150}
            />
          )}
          {uploadedImg && !submitBool && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "400px",
                mt: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setSubmitBool(false);
                  setCoverImage([]);
                  setIsCroppedSubmit(false);
                  setUploadedImg(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setSubmitBool(true);
                  setIsCroppedSubmit(true);
                  setUploadedImg(croppedImg);
                }}
              >
                Submit
              </Button>
            </Box>
          )}
          {uploadedImg != null && submitBool && (
            <Box
              sx={{
                position: "relative",
                display: "flex",
                width: "135px",
                my: 2,
              }}
            >
              <Box
                component="img"
                src={!isCroppedSubmit ? croppedImg : uploadedImg}
                alt="previewImg"
                sx={{
                  height: "135px",
                  borderRadius: "8px",
                  objectFit: "contain",
                }}
              />
              <Box sx={{ position: "absolute", right: "-13px", top: "-15px" }}>
                <CropClose
                  style={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setCoverImage([]);
                    setIsCoverImageRemoved(true);
                    setIsCroppedSubmit(false);
                    setUploadedImg(null);
                    setSubmitBool(false);
                  }}
                />
              </Box>
            </Box>
          )}
        </Grid>
        <Typography sx={{ color: "red", textAlign: "center" }}>
          {error.coverImage}
        </Typography>
        {submitBool == false &&
          uploadedImg == null &&
          coverImage.length > 0 &&
          coverImage.map((item, index) => (
            <Box
              item
              sx={{
                width: "135px",
                height: "135px",
                my: 2,
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div
                style={{ position: "absolute", right: "-13px", top: "-15px" }}
              >
                <CropClose
                  style={{
                    width: "21.22px",
                    height: "21.22px",
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => {
                    setCoverImage([]);
                    setIsCoverImageRemoved(true);
                  }}
                />
              </div>
              <Box
                key={index}
                component="img"
                src={item.src}
                sx={{
                  width: "135px",
                  height: "135px",
                  // background: `url(${item.src})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  borderRadius: "5px",
                }}
              />
            </Box>
          ))}
        <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Add Additional Images
            <span style={{ fontSize: "10px", color: "#0F2C6F" }}>
              {" "}
              (optional) (maximum 5 images)
            </span>
          </Typography>
          {aditionalImages.length < 5 && (
            <Button
              component="label"
              variant="outlined"
              sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize" }}
              endIcon={<UploadIcon />}
            >
              Upload
              <input
                capture
                multiple
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => handleUploadMultipleButton(e)}
              />
            </Button>
          )}
        </Grid>
        {aditionalImages.length > 0 &&
          aditionalImages.slice(0, 5).map((item, index) => (
            <Grid
              item
              sx={{
                p: 1,
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", right: "0", top: "5px" }}>
                <CropClose
                  style={{
                    width: "21.22px",
                    height: "21.22px",
                    cursor: "pointer",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                  onClick={() => removeAditionalImages(index)}
                />
              </div>

              <Box
                key={index}
                component="img"
                src={item.src}
                sx={{
                  width: "100px",
                  height: "100px",
                  maxWidth: "100px",
                  maxHeight: "100px",
                  // background:`url(${item.src})` ,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  borderRadius: "5px",
                }}
              />
            </Grid>
          ))}
        {error.additionalImages && (
          <Grid
            item
            xs={12}
            sx={{
              p: 1,
              mt: 1,
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Typography variant="p" sx={{ fontSize: "14px", color: "red" }}>
              <br /> {error?.additionalImages}
            </Typography>
          </Grid>
        )}
         <Grid
          item
          xs={12}
          sx={{ mt: 3, display: "flex", justifyContent: "space-between", width:"100%" }}
        >
          <Box sx={{display:"flex", flexDirection:"column", }} >
          <Typography
            sx={{ fontSize: "18px", fontWeight: "500", color: "#0F2C6F" }}
          >
            Add Documents
            <span style={{ fontSize: "10px", color: "#0F2C6F", textOverflow:"clip"}}>
              {" "}
              (optional) 
            </span>
          </Typography>
          <span style={{fontSize: "10px", color: "#0F2C6F",}}> (Note - allowed types:  jpg,jpeg,png,svg,webp,doc,docx,xls,xlsx,ppt,pptx,txt,mp4,pdf)</span>
          </Box>
        
       
            <Button
              component="label"
              variant="outlined"
              disabled={apiRequestOngoing}
              sx={{ color: "#0F2C6F", mt: 2, textTransform: "capitalize" }}
              endIcon={<UploadIcon />}
            >
              Upload
              <input
                capture
                id="file_input"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleUploadDocument(e)}
              />
            </Button>
        </Grid>     
        <Box sx={{ display: "flex", flexDirection: "column", width:"100%" }}>
           
            {additionalDocuments.length > 0 && (
              <Box>
                {additionalDocuments?.map((value) => (
                  <Box
                    key={value?.asset_id} // Add a unique key for each item
                    sx={{
                      display: 'flex',
                      alignItems: "center",
                      justifyContent: 'space-between',
                      gap: '5px',
                      border: '1px solid #DBDFE9',
                      borderRadius: "8px",
                      mt: 2,
                      p: 1,
                    }}
                  >
                    <Box sx={{display:"flex", alignItems:"center",gap:"5px", height:"54.8px", width:"100%"}}>
                      <Box>
                        {getFileIcon(value?.type ? value?.type : value?.filetype)}

                      </Box>
                      {/* <Box sx={{display:"flex", flexDirection:"column",  width:"100%", height:"90%"}}> */}
                      <Typography
                        sx={{
                          color: "#0F2C6F",
                          fontSize: '14px',
                          maxWidth: "500px",
                          wordWrap: "break-word", // Allows text wrapping when max width is exceeded
                          flexGrow: 1, // Takes up remaining space on the left
                        }}
                      >
                        {value?.fileName ? value?.fileName : value?.orig_filename}
                        <span style={{ color: "#9AA6C3", fontSize: '12px', marginLeft: '10px' }}>
                          ({Math.round(value?.size ? value?.size / 1000 : value?.filesize / 1000)}KB)
                        </span>
                      </Typography>
                      {/* <BorderLinearProgress variant="determinate" value={uploadProgress} />
                      </Box> */}
                    </Box>

                

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Tooltip
                        open={tooltipId === value?.asset_id}
                        title="Copied to clipboard"
                        placement="top"
                        onClose={() => setTooltipId(null)}
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 8],
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "black",
                              color: "white",
                              fontSize: 12,
                              p: 1,
                            },
                          },
                        }}
                      >
                        <IconButton onClick={() => handleCopy(value?.asset_id, value?.new_filename)}>
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                        <DeleteIcon style={{width:"40px", height:"40px", cursor:"pointer"}} onClick={() => handleDeleteFiles(value?.asset_id)}/>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
              {apiRequestOngoing && <Stack spacing={2} sx={{ flexGrow: 1, border:"1px solid #DBDFE9", borderRadius:"8px", mt:2, p:2, pb:'8px' }}>
                        <BorderLinearProgress variant="determinate" value={uploadProgress} />
                        <span>{uploadProgress}%</span>
                      </Stack>}
                {uploadErrorMsg && <Typography variant="p" sx={{ fontSize: "14px", color: "red", mt:4 }}>{uploadErrorMsg}</Typography>}
            
          </Box>

      </Grid>
    );
  };
  // console.log('eventData ',eventData);
  const renderNewPreview = () => {
    return (
      <>
        <Grid container sx={{ backgroundColor: "#FFFFFF", p: 2, pb: "24px" }}>
          <Grid item xs={12}>
            <PrimaryTypography>Application Preview</PrimaryTypography>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Grid item sx={{ width: "320px" }}>
              <Box
                sx={{
                  height: "600px",
                  width: "320px",
                  position: "relative",
                  borderRadius: "8px",
                  border: "1px solid #F3F4F8",
                  mt: 2,
                  backgroundColor: "#F3F4F8",
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography style={{ fontSize: "15px" }}>
                    {moment(new Date()).local().format("HH:mm")}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <WifiIcon style={{ fontSize: "15px" }} />
                    <SignalCellularAltIcon
                      style={{ fontSize: "15px", mx: 1 }}
                    />
                    <BatteryCharging50Icon style={{ fontSize: "15px" }} />
                  </Box>
                </Box>
                <Box sx={{ position: "absolute", top: "77%", width: "100%" }}>
                  <Box
                    sx={{
                      backgroundColor: "#ccf2ff",
                      width: "100%",
                      borderRadius: "12px 12px 0 0",
                    }}
                  >
                    <PrimaryTypography
                      textAlign={"center"}
                      sx={{ py: 2, textAlign: "left", marginLeft: "10px" }}
                    >
                      Events
                    </PrimaryTypography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      p: 1,
                      justifyContent: "start",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "70px",
                        minHeight: "70px",
                        borderRadius: "6px",
                        background: `url(${coverImage[0]?.src})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    ></Box>
                    {/* <Box sx={{display:'flex',flexDirection:'column',ml:1}}>
                                            <SecondaryTypography sx={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis'}}>{eventData?.title}</SecondaryTypography>
                                            <SecondaryTypography>{eventData?.eventStartDate!=null && moment(eventData?.eventStartDate).format('DD MMM YYYY')} - {eventData?.eventEndDate!=null &&  moment(eventData?.eventEndDate).format('DD MMM YYYY')}</SecondaryTypography>
                                            <SecondaryTypography sx={{maxWidth:'200px',overflow:'hidden',textOverflow:'ellipsis'}}>{ eventData?.eventStartTime} - {eventData?.eventEndTime}</SecondaryTypography>
                                        </Box> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        ml: "14px",
                        gap: "10px",
                      }}
                    >
                      <Typography
                        style={{
                          maxWidth: "200px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          color: "#0F2C6F",
                          fontSize: "14px",
                          whiteSpace: "nowrap",
                          fontFamily: "Inter",
                          fontWeight: "600",
                        }}
                      >
                        {eventData?.title}
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            color: "#0F2C6F",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "500!important",
                          }}
                        >
                          {eventData?.eventStartDate && eventData?.eventEndDate
                            ? `${moment(eventData.eventStartDate).format(
                                "DD MMM YYYY"
                              )} - ${moment(eventData.eventEndDate).format(
                                "DD MMM YYYY"
                              )}`
                            : eventData?.eventStartDate
                            ? moment(eventData.eventStartDate).format(
                                "DD MMM YYYY"
                              )
                            : ""}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#0F2C6F",
                            fontSize: "12px",
                            fontFamily: "Inter",
                            maxWidth: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontWeight: "400!important",
                          }}
                        >
                          {eventData?.eventStartTime && eventData?.eventEndTime
                            ? `${moment(
                                eventData.eventStartTime,
                                "HH:mm"
                              ).format("h:mm A")} - ${moment(
                                eventData.eventEndTime,
                                "HH:mm"
                              ).format("h:mm A")}`
                            : eventData?.eventStartTime
                            ? moment(eventData.eventStartTime, "HH:mm").format(
                                "h:mm A"
                              )
                            : ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sx={{ ml: 4, maxWidth: "320px" }}>
              <Box
                sx={{
                  height: "600px",
                  overflowX: "hidden",
                  overflowY: "auto",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": { display: "none" },
                  msOverflowStyle: "none",
                  scrollbarWidth: "none",
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid #F3F4F8",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    zIndex: 1,
                    position: "sticky",
                    top: 0,
                    height: "310px",
                    width: "320px",
                    backgroundImage: `url(${coverImage[0]?.src})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography style={{ fontSize: "13px" }}>
                      {moment(new Date()).local().format("HH:mm")}
                    </Typography>
                    <Box sx={{ display: "flex" }}>
                      <WifiIcon
                        style={{ fontSize: "15px", color: "#FFFFFF" }}
                      />
                      <SignalCellularAltIcon
                        style={{ fontSize: "15px", mx: 1, color: "#FFFFFF" }}
                      />
                      <BatteryCharging50Icon
                        style={{ fontSize: "15px", color: "#FFFFFF" }}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "0%",
                      px: 2,
                      overflowWrap: "anywhere!important",
                      width: "95%",
                      backgroundImage:
                        "linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.6),rgba(0,0,0,0.8))",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "18px",
                        wordWrap: "break-word",
                        fontFamily: "Inter",
                        fontWeight: "300 !important",
                        overflowWrap: "anywhere!important",
                      }}
                    >
                      {eventData?.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        width: "90%",
                        fontWeight: "400",
                        fontSize: "16px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        marginTop: "5px",
                        marginBottom: "14px",
                      }}
                    >
                      {eventData?.name || ""}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ py: 1, px: 2 }}>
                  <SecondaryTypography
                    sx={{
                      whiteSpace: "pre-line",
                      wordBreak: "break-word",
                      lineHeight: "24px",
                      margin: "0!important",
                    }}
                  >
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(eventData?.description),
                      }}
                    />
                  </SecondaryTypography>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 1,
                      mb: 2,
                      gap: "5px",
                      overflow: "auto",
                      "&::-webkit-scrollbar": { display: "none" },
                      msOverflowStyle: "none",
                      scrollbarWidth: "none",
                    }}
                  >
                    {aditionalImages?.map((value) => (
                      <Box
                        component="img"
                        sx={{
                          maxWidth: "113px",
                          maxHeight: "90px",
                          minHeight: "90px",
                          borderRadius: "8px",
                          backgroundRepeat: "no-repeat",
                          ml: 0.2,
                          backgroundSize: "contain",
                        }}
                        src={value?.src}
                      ></Box>
                    ))}
                  </Box>
                  <Box>
                    {/* <SecondaryTypography sx={{wordBreak:"break-word",whiteSpace:"pre-line"}}>{eventData?.description}</SecondaryTypography> */}

                    <PrimaryTypography
                      sx={{
                        my: 1,
                        display: termsAndConditionLength > 0 ? "block" : "none",
                      }}
                    >
                      CONDITIONS
                    </PrimaryTypography>
                    {/* <SecondaryTypography sx={{whiteSpace:'pre-line',wordBreak:"break-word"}}>{eventData?.terms_conditions}</SecondaryTypography> */}
                    <SecondaryTypography
                      sx={{
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        lineHeight: "24px",
                      }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            eventData?.terms_conditions
                          ),
                        }}
                      />
                    </SecondaryTypography>
                  </Box>

                  <Box
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: "rgba(143, 146, 161,0.05)",
                      mt: 2,
                    }}
                  >
                    {eventData?.location != "-" &&
                      eventData?.location != undefined &&
                      eventData?.location != "undefined" &&
                      eventData?.location != "" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            p: 1,
                            gap: "24px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              mr: 1,
                              position: "relative",
                            }}
                          >
                            <img
                              style={{
                                position: "absolute",
                                top: "0%",
                                height: "18px",
                                width: "18px",
                              }}
                              src={Location}
                            />
                            <SecondaryTypography sx={{ ml: 3 }}>
                              Location
                            </SecondaryTypography>
                          </Box>
                          <SecondaryTypography
                            sx={{
                              width: "90%",
                              overflow: "hidden",
                              wordWrap: "break-word",
                              textOverflow: "clip",
                            }}
                          >
                            {eventData?.location}
                          </SecondaryTypography>
                        </Box>
                      )}
                    {eventData?.eventStartTime != "" &&
                      eventData?.eventStartTime != null && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "40px",
                            mt: 1,
                            p: 1,
                          }}
                        >
                          <Box sx={{ display: "flex", mr: 1 }}>
                            <Clock
                              style={{
                                height: "16px",
                                width: "18px",
                                marginTop: "2px",
                              }}
                            />
                            <SecondaryTypography sx={{ ml: 1 }}>
                              Hours
                            </SecondaryTypography>
                          </Box>
                          <SecondaryTypography>
                            {eventData?.eventStartTime +
                              " - " +
                              eventData?.eventEndTime}
                          </SecondaryTypography>
                        </Box>
                      )}

                    {/* <Box sx={{display:"flex",justifyContent:'space-between',my:1}}>
                                <Box sx={{display:'flex'}}>
                                    <img src={Clock} />
                                    <Typography sx={{ml:1}}>Hours</Typography>
                                </Box>
                                <Box></Box>
                            </Box>  */}
                    {eventData?.mobile != null &&
                      eventData?.mobile != "" &&
                      eventData?.mobile != "--" &&
                      eventData?.mobile != "undefinedundefined" &&
                      eventData?.mobile != "nullnull" && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "28px",
                            my: 1,
                            p: 1,
                          }}
                        >
                          <Box sx={{ display: "flex", mr: 1 }}>
                            <img
                              style={{
                                alignSelf: "flex-start",
                                height: "17px",
                                width: "15px",
                              }}
                              src={Phone}
                            />
                            <SecondaryTypography sx={{ ml: 1 }}>
                              Contact
                            </SecondaryTypography>
                          </Box>
                          <SecondaryTypography>
                            {eventData?.mobile}
                          </SecondaryTypography>
                        </Box>
                      )}

                    {eventData?.contact_email != "-" &&
                      eventData?.contact_email != undefined &&
                      eventData?.contact_email != "" &&
                      eventData?.contact_email != null && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "40px",
                            mt: 1,
                            p: 1,
                            overflowWrap: "break-word!important",
                          }}
                        >
                          <Box sx={{ display: "flex", mr: 1 }}>
                            <img
                              style={{ height: "15px", width: "17px" }}
                              src={Email}
                            />
                            <SecondaryTypography sx={{ ml: 1 }}>
                              Email
                            </SecondaryTypography>
                          </Box>
                          <SecondaryTypography
                            sx={{ overflowWrap: "anywhere!important" }}
                          >
                            {eventData?.contact_email}
                          </SecondaryTypography>
                        </Box>
                      )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };
  return (
    <>
      {alertStatus && (
        <Alert
          confirmFunc={saveAsDraft}
          closeFunc={() => {
            setAlertStatus();
            if (uploadedImg != null && !isCroppedSubmit) return;
            navigate(-1);
          }}
          alertData={{
            label: "Are you sure want to save as draft",
            description: "",
            buttonvalue: "Save as Draft",
          }}
        />
      )}
      {loaderStatus && <Loader status={loaderStatus} />}
      <Grid
        container
        rowSpacing={2}
        sx={{ padding: 5, pt: 8, background: "rgba(143, 146, 161,0.05)" }}
      >
        <ToastAlert
          message={eventAddStatus.message}
          status={eventAddStatus.status}
          color={eventAddStatus.color}
          icon={eventAddStatus.icon}
        />
        <Grid item xs={12}>
          <ArrowIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleBackButton()}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: "26px", fontWeight: "500", color: "#002D74" }}
          >
            {id == "null" ? "Create Event" : "Edit Event"}
          </Typography>
          <Typography
            style={{ fontSize: "14px", fontWeight: "500", color: "#002D74" }}
          >
            You may create an event which will be shown to all or selected app &
            dashboard users.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              background: "white",
              borderBottom: "1px solid #DBDFE9",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              TabIndicatorProps={{ style: { background: "#123FA9" } }}
            >
              <StyledTab
                label="Create"
                {...a11yProps(0)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
              <StyledTab
                label="Preview"
                {...a11yProps(1)}
                sx={{
                  textTransform: "none",
                  color: "#123FA9",
                  fontSize: "16px",
                }}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {renderNewEvent(eventData)}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {renderNewPreview()}
          </TabPanel>
        </Grid>
        <Grid item xs={4} sx={{ pl: 2 }}>
          <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "5px" }}>
            <Grid item xs={12} sx={{ py: 1 }}>
              <Typography
                sx={{
                  display: "inline",
                  px: 3,
                  fontSize: "14px",
                  color: " #0F2C6F",
                  fontWeight: 500,
                }}
              >
                Post Setting
              </Typography>
              <Divider style={{ marginTop: "8px" }} />
            </Grid>
            <Box sx={{ py: 2 }}>
              <Chip
                label="Post Now"
                onClick={() => setPostType("now")}
                sx={postType === "now" ? uncheckedStyle : checkedStyle}
              />
              <Chip
                label="Schedule Post"
                onClick={() => setPostType("scheduled")}
                sx={postType === "scheduled" ? uncheckedStyle : checkedStyle}
              />
            </Box>
          </Box>
          {postType === "now" ? (
            <>
              <Box sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
                <Grid container>
                  <Grid item xs={7} sx={{ p: 1 }}>
                    <StyledTypography>End Date*</StyledTypography>
                    <DesktopDatePicker
                      inputFormat="dd/MM/yyyy"
                      disablePast
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      value={eventData?.promotionEndDate}
                      onChange={(e) => handleDate(e, "promotionEndDate")}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                    />
                    <Typography
                      variant="p"
                      sx={{ fontSize: "14px", color: "red" }}
                    >
                      {error?.promotionEndDate}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ p: 1 }}>
                    {getplatformScren()}
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Box sx={{ backgroundColor: "#FFFFFF", px: 2 }}>
              <Grid container>
                {/* <Grid container item xs={12} > */}
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography>Start Date*</StyledTypography>
                  <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    disablePast
                    // maxDate={eventData?.eventEndDate && new Date(eventData?.eventEndDate)}
                    components={{
                      OpenPickerIcon: DatePickerIcon,
                    }}
                    disabled={currentTab == 0 && edit == "true"}
                    value={eventData?.promotionStartDate}
                    onChange={(e) => handleDate(e, "promotionStartDate")}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => preventDatePickerKeyboard(e)}
                        {...params}
                      />
                    )}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionStartDate}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography>End Date*</StyledTypography>
                  <DesktopDatePicker
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date(eventData?.promotionStartDate)}
                    // maxDate={eventData?.eventEndDate &&  new Date(eventData?.eventEndDate) }
                    components={{
                      OpenPickerIcon: DatePickerIcon,
                    }}
                    value={eventData?.promotionEndDate}
                    onChange={(e) => handleDate(e, "promotionEndDate")}
                    renderInput={(params) => (
                      <TextField
                        onKeyDown={(e) => preventDatePickerKeyboard(e)}
                        {...params}
                      />
                    )}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionEndDate}
                  </Typography>
                </Grid>

                {/* </Grid> */}

                {/* <Grid container item xs={12} sx={{ my: 2 }}> */}
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    Start Time*
                  </StyledTypography>
                  {/* <Select fullWidth sx={{display:"block"}}  MenuProps={MenuProps} value={eventData?.promotionStartTime ? scheduleStartHours?.includes(eventData?.promotionStartTime) ? eventData?.promotionStartTime : scheduleStartHours[0] : ""} onChange={(e) => handleTime(e, "promotionStartTime")}>
                                    {scheduleStartHours?.map((value)=>(
                                        <MenuItem sx={{color:'#0F2C6F', display:eventData?.promotionStartTime == value ? 'none': '' }} value={value}>{value?.slice(0,6)}</MenuItem>
                                    ))}
                                </Select> */}
                  <Select
                    fullWidth
                    sx={{ display: "block" }}
                    disabled={
                      (currentTab == 0 && edit == "true") ||
                      eventData.promotionStartDate == null ||
                      eventData.promotionStartDate == ""
                    }
                    MenuProps={MenuProps}
                    value={
                      eventData?.promotionStartTime
                        ? eventData?.promotionStartTime
                        : ""
                    }
                    onChange={(e) => handleTime(e, "promotionStartTime")}
                  >
                    {scheduleStartHours?.map((value) => (
                      <MenuItem
                        sx={{
                          color: "#0F2C6F",
                          display:
                            eventData?.promotionStartTime == value
                              ? "none"
                              : "",
                        }}
                        value={value}
                      >
                        {value?.slice(0, 6)}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionStartTime}
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ p: 1 }}>
                  <StyledTypography sx={{ display: "inline" }}>
                    End Time*{" "}
                  </StyledTypography>
                  {/* <Select fullWidth sx={{display:"block"}}  MenuProps={MenuProps} value={eventData?.promotionEndTime? eventData.promotionEndTime : ""} onChange={(e) => handleTime(e, "promotionEndTime")}>
                                {scheduleEndHours?.map((value)=>(
                                        <MenuItem sx={{color:'#0F2C6F', display:eventData?.promotionEndTime == value ? 'none': ''}} value={value}>{value?.slice(0,6)}</MenuItem>
                                    ))}
                                </Select> */}
                  <Select
                    fullWidth
                    sx={{ display: "block" }}
                    MenuProps={MenuProps}
                    disabled={
                      eventData.promotionEndDate == null ||
                      eventData.promotionEndDate == ""
                    }
                    value={
                      eventData?.promotionEndTime
                        ? eventData.promotionEndTime
                        : ""
                    }
                    onChange={(e) => handleTime(e, "promotionEndTime")}
                  >
                    {scheduleEndHours?.map((value) => (
                      <MenuItem
                        sx={{
                          color: "#0F2C6F",
                          display:
                            eventData?.promotionEndTime == value ? "none" : "",
                        }}
                        value={value}
                      >
                        {value?.slice(0, 6)}
                      </MenuItem>
                    ))}
                  </Select>

                  <Typography
                    variant="p"
                    sx={{ fontSize: "14px", color: "red" }}
                  >
                    {error?.promotionEndTime}
                  </Typography>
                </Grid>

                {/* </Grid> */}

                <Grid item xs={12} sx={{ p: 1 }}>
                  {getplatformScren()}
                </Grid>
              </Grid>
            </Box>
          )}
          <Button
            onClick={() => navigate(-1)}
            fullWidth
            variant="outlined"
            sx={{
              textTransform: "capitalize",
              backgroundColor: "#FFFFFF",
              "&:hover": { backgroundColor: "#FFFFFF" },
              height: "50px",
              border: " 1px solid #AAC3FF",
              borderRadius: "8px",
              mt: 2,
            }}
          >
            Back
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              textTransform: "capitalize",
              height: "50px",
              backgroundColor: "#123FA9",
              borderRadius: "8px",
              mt: 2,
            }}
            onClick={clickContinue}
          >
            {tabValue == 0 ? "Continue" : "Submit"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    eventCreateStatus: state.eAndpReducer.eventCreateStatus,
    eventAndPromotionDetails: state.eAndpReducer.eventAndPromotionDetails,
    updateDraftStatus: state.eAndpReducer.updateDraftStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createEvent: (reqData) => {
      dispatch(eventsAndPromotionAction.createEvent(reqData));
    },
    SETeANDpStateToNULL: () => {
      dispatch(eventsAndPromotionAction.SETeANDpStateToNULL());
    },
    eventPromotionStateNull: () => {
      dispatch(eventsAndPromotionAction.eventPromotionStateNull());
    },
    getEandPDetails: (data) =>
      dispatch(eventsAndPromotionAction.getEandPDetails(data)),
    updateEandPDraft: (data) =>
      dispatch(eventsAndPromotionAction.updateEandPDraft(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateEvent);
