import { Box, Button, Checkbox, Grid, Menu, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react'
import {ReactComponent as RefreshIcon} from '../../../Assets/icons/Refresh.svg';
import {ReactComponent as CloseIcon} from '../../../Assets/icons/Alertclose.svg';
import { ReactComponent as CheckboxIcon } from '../../../Assets/icons/CheckboxTerms.svg';
import { ReactComponent as Fileupload } from '../../../Assets/icons/Fileupload.svg';
import { ReactComponent as DatePickerIcon } from '../../../Assets/icons/DatePickerIcon.svg';
import DropZone from '../../../Utils/dropzone';
import { DatePicker,TimePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import * as Commonaction from '../../../redux/actions/commonAction/index';
import * as ServiceAction from "../../../redux/actions/service_request";
import {getLastUpdatedTime, getUTCDateFormat, getUTCTimeFormat, preventDatePickerKeyboard} from '../../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import ToastAlert from '../../common/Alert';
import Loader from '../../common/Loader';

const StyledTypography = styled(Typography)({
  color:'#0F2C6F',
  fontSize:"18px",
  fontWeight:550
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

const MiscellaneousADHOCRequest = ({requestId,reqId,tenantData,serviceType,level,getTenantAccessLevel,unit,getTenantAccessUnit,addMiscelleaneousAdhocRequest,addMiscelleaneousAdhocRequestAsDraft,addServiceReqStatus,setServiceRequestStateNull,termsAndCondition,serviceReqDetails,serviceRequestByID,updateMiscDraft,updateMiscDraftStatus})=> {
  const navigate = useNavigate();
  const [details,setDetails] = useState({displayfromDate:null,displaytoDate:null,
    fromTime:(Array.isArray(serviceReqDetails?.serviceRequestsArrayList) && serviceReqDetails?.serviceRequestsArrayList[0]?.from_time !="NA" ) ?  serviceReqDetails?.serviceRequestsArrayList[0]?.from_time : "",
    toTime:(Array.isArray(serviceReqDetails?.serviceRequestsArrayList) && serviceReqDetails?.serviceRequestsArrayList[0]?.to_time !="NA" ) ?  serviceReqDetails?.serviceRequestsArrayList[0]?.to_time : ""
  });
  const [lastUpdatedTime,setLastUpdatedTime] = useState();
  const [selectedFiles,setSelectedFiles] = useState([]);
  const [inputErrors,setInputErrors] = useState({});
  const [checkBoxSelected,setCheckBoxSelected] = useState(false);
  const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
  const [startHours,setStartHours] = useState([])
  const [endHours,setEndHours] = useState([])
  const [loaderStatus,setLoaderStatus] = useState(false);
  const [draftFiles,setDraftFiles] = useState([])
  const [draftDeletedFiles,setDraftDeletedFiles] = useState([])
  let terms_condition = termsAndCondition?.config_value &&  JSON.parse(termsAndCondition.config_value);

  useEffect(()=>{
    getTenantAccessLevel();
    setLastUpdatedTime(getLastUpdatedTime())
    getMiscStartHours(0)
    getMiscEndHours(0)

    if(reqId!=="null"){
      serviceRequestByID({service_requests_id:reqId})
    }
  },[])

  useEffect(()=>{
    if(details.displaytoDate>details.displayfromDate){
      getMiscEndHours(0)
    }
  },[details.displaytoDate,details.fromTime])

  useEffect(() => {
    setLoaderStatus(false)
    if (addServiceReqStatus?.response_status == 1) {
        setSnackbarStatus({ message: addServiceReqStatus?.message, status: true, color: 'green', icon: 'close' })
        setServiceRequestStateNull()
        setTimeout(() => {
            removeSnackbar()
            navigate(`/service_request_list/0`)
        }, 5000);
    } else if (addServiceReqStatus !== null) {
        setSnackbarStatus({ message: addServiceReqStatus?.message, status: true, color: 'red', icon: 'close' })
        setServiceRequestStateNull()
        setTimeout(() => {
            removeSnackbar()
        }, 5000);
    }
}, [addServiceReqStatus])

  useEffect(()=>{
    setLoaderStatus(false)    
    if(updateMiscDraftStatus?.response_status==1){
      setSnackbarStatus({ message: updateMiscDraftStatus?.message, status: true, color: 'green', icon: 'close' })
        setServiceRequestStateNull()
        setTimeout(() => {
            removeSnackbar()
            navigate(`/service_request_list/0`)
        }, 5000);
    }else if(updateMiscDraftStatus?.response_status==0){
      setSnackbarStatus({ message: updateMiscDraftStatus?.message, status: true, color: 'red', icon: 'close' })
        setServiceRequestStateNull()
        setTimeout(() => {
            removeSnackbar()
        }, 5000);
    }
  },[updateMiscDraftStatus])

  //FROM DRAFT
  useEffect(()=>{
    if(serviceReqDetails!=null && serviceReqDetails?.serviceRequestsArrayList?.length>0) {
        let draftDetails = serviceReqDetails.serviceRequestsArrayList[0]
        let draftFiles =  serviceReqDetails.service_request_files

        if(draftDetails.level !='NA'){
          getTenantAccessUnit({level_id:draftDetails.level})
        }
        setDetails({
          ...details,
          title:draftDetails.request_title != "NA" ? draftDetails.request_title : "",
          details:draftDetails.description!="NA" ? draftDetails.description : "",
          displayfromDate:draftDetails.from_date!="NA" ? draftDetails.from_date : "" ,
          fromDate:draftDetails.from_date!="NA" ? draftDetails.from_date : "",
          displaytoDate:draftDetails.to_date!="NA" ? draftDetails.to_date : "",
          toDate:draftDetails.to_date!="NA" ? draftDetails.to_date : "",
          fromTime:draftDetails.from_time!="NA" ? draftDetails.from_time : "",
          toTime:draftDetails.to_time !="NA" ? draftDetails.to_time : "",
          level:draftDetails.level !='NA' ? draftDetails.level : '',
          unit:draftDetails.unit !='NA' ? draftDetails.unit : ''
        })
        if(draftFiles.length>0){
          let arr = []
          for(let i=0;i<draftFiles.length;i++){
              arr.push({id:200+i,submitId:draftFiles[i].id,fileName:draftFiles[i].original_name,size:'',draftFile:true,
              service_request_logs_id:draftFiles[i].service_requests_logs_id})
          }
          setDraftFiles([...arr])
          setSelectedFiles([...arr])
        }
        
    }
  },[serviceReqDetails,level])


const getMiscStartHours = (minTime)=>{
  let timeIntrevels=[]
  for(let i=minTime;i<24;i++){
     for(let j=1;j<=2;j++){
         if(j%2==0){
          timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
         }else{
          timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
         }
     }  
 };
 setStartHours([...timeIntrevels])
}

const getMiscEndHours = (minTime)=>{
    let timeIntrevels=[],startTime=minTime===0?0:parseInt(minTime.slice(0,2))+1;

    for(let i=startTime;i<24;i++){
    for(let j=1;j<=2;j++){
        if(j%2==0){
          timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
        }else{
          timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
        }
    }  
    };
    setEndHours([...timeIntrevels])
}

  const handleFiles = (file)=>{
    if(file.length>0){
      setSelectedFiles(file)
    }
  }

  const handleUploadButton = (e) => {

    let target = e.target.files;
    let length = selectedFiles.length;
    let files = selectedFiles;
    for (let i = 0; i < target.length; i++) {

      files.push({ id: length, fileName: target[i].name, file: target[i], size: target[i].size, source: URL.createObjectURL(target[i]) })
      length += 1;
    }
    setSelectedFiles([...files])

  }

  const handleDetails = (e)=>{
    let {name,value} = e.target
    setDetails({
      ...details,
      [name]:value
    })
    setInputErrors({
      ...inputErrors,
      [name]:""
    })
  }
  
  const handleDate = (value,datafrom)=>{
    if(value===null){
      setInputErrors({
        ...inputErrors,
        [datafrom]:'Select a valid date'
      })
    }else{
      let date = `${value.getFullYear()}-${value.getMonth()>8 ? `${value.getMonth()+1}` : `0${value.getMonth()+1}`}-${value.getDate()>9 ? value.getDate() : `0${value.getDate()}`}`
      setDetails({
        ...details,
        [`display${datafrom}`]:value,
        [datafrom]:date
      })
      setInputErrors({
        ...inputErrors,
        [datafrom]:""
      })
    }
  
  }

  const handleTime = (e,datafrom)=>{
    // let time = `${value.getHours()>9 ? value.getHours() : `0${value.getHours()}`}:${value.getMinutes()>9 ? value.getMinutes() : `0${value.getMinutes()}`}`
    let {name,value} = e.target

    setDetails({
      ...details,
      [`display${datafrom}`]:value,
      [datafrom]:value
    })
    if(datafrom==="fromTime"){
      getMiscEndHours(value)
    }
    setInputErrors({
      ...inputErrors,
      [datafrom]:""
    })
  }

  const validate = ()=>{
    let err = true;
    if(details.hasOwnProperty("title") && details.title){
     err=true
    }else{
      setInputErrors((prev)=>(
        {
          ...prev,
          title:"Should not be empty"
        }
      ))
      err=false
    }

    if(details.hasOwnProperty("details") && details.details){
      err=true
     }else{
       setInputErrors((prev)=>(
         {
           ...prev,
           details:"Should not be empty"
         }
       ))
       err=false
     }

    //  if(details.hasOwnProperty("level") && details.level){
    //   err=true
    //  }else{
    //    setInputErrors((prev)=>(
    //      {
    //        ...prev,
    //        level:"Select level"
    //      }
    //    ))
    //    err=false
    //  }

    //  if(details.hasOwnProperty("unit") && details.unit){
    //   err=true
    //  }else{
    //    setInputErrors((prev)=>(
    //      {
    //        ...prev,
    //        unit:"Select Unit"
    //      }
    //    ))
    //    err=false
    //  }

    //  if(details.hasOwnProperty("fromDate") && details.fromDate){
    //   err=true
    //  }else{
    //   setInputErrors((prev)=>(
    //     {
    //       ...prev,
    //       fromDate:"Select Start Date"
    //     }
    //   ))
    //   err=false
    // }

    // if(details.hasOwnProperty("toDate") && details.toDate){
    //   err=true
    //  }else{
    //   setInputErrors((prev)=>(
    //     {
    //       ...prev,
    //       toDate:"Select End Date"
    //     }
    //   ))
    //   err=false
    // }

    // if(details.hasOwnProperty("fromTime") && details.fromTime){
    //   err=true
    //  }else{
    //   setInputErrors((prev)=>(
    //     {
    //       ...prev,
    //       fromTime:"Select Start Time"
    //     }
    //   ))
    //   err=false
    // }

    // if(details.hasOwnProperty("toTime") && details.toTime){
    //   err=true
    //  }else{
    //   setInputErrors((prev)=>(
    //     {
    //       ...prev,
    //       toTime:"Select End Time"
    //     }
    //   ))
    //   err=false
    // }
    return err
  }

  const removeSnackbar = () => {
    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
}


  const handleDeleteFiles=(id)=>{
    const filter = selectedFiles.filter((value)=>{
      return value.id !==id
    })
    setSelectedFiles([...filter])

    let isDraftDeleted = draftFiles.filter((value)=>{
      return value.id == id
    })
    setDraftDeletedFiles([...draftDeletedFiles,...isDraftDeleted])
  }

  const handleSubmitButton = ()=>{
    let valid = validate()
    if(valid){
      let arr = []
      let deletedFile = draftDeletedFiles.map((value)=>{
          arr.push(value.submitId)
          return value.submitId
      })
      let string = arr.toString()
      let submittingFile = selectedFiles.filter((value)=>{
          return value.draftFile!=true
      })

      let data = new FormData();
      data.append("company_id",tenantData.companyId);
      data.append("request_title",details.title)
      data.append("description",details.details)
      data.append("from_date",details.fromDate ? details.fromDate : '')
      data.append("from_time",details.fromTime )
      data.append("to_date",details.toDate ? details.toDate : '')
      data.append("to_time",details.toTime)
      // data.append("from_date",getUTCDateFormat(details.fromDate))
      // data.append("from_time",getUTCTimeFormat(details.fromDate,details.fromTime))
      // data.append("to_date",getUTCDateFormat(details.toDate))
      // data.append("to_time",getUTCTimeFormat(details.fromDate,details.toTime))
      data.append("service_request_types_id",requestId[1].service_request_types_id)
      data.append("level",details.level)
      data.append("unit",details.unit);
      if(reqId!='null'){
        data.append('service_requests_id',reqId)
        data.append('status','submitted')
        data.append('delete_file',deletedFile.length>0 ? string : '')
      }
      submittingFile.length>0 && data.append("file_count",submittingFile.length);
      for(let i=0;i<submittingFile.length;i++){
        data.append(`file${i}`,submittingFile[0].file)
      }
      if(reqId!='null'){
        updateMiscDraft(data);
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }else{
        addMiscelleaneousAdhocRequest(data);
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }
      
    }
  }

  const handleDraft = ()=>{
    if(details.title){
      let arr = []
      let deletedFile = draftDeletedFiles.map((value)=>{
          arr.push(value.submitId)
          return value.submitId
      })
      let string = arr.toString()
      let submittingFile = selectedFiles.filter((value)=>{
          return value.draftFile!=true
      })

      let data = new FormData();
      data.append("company_id",tenantData.companyId);
      data.append("request_title",details.title)
      data.append("description",details.details)
      data.append("from_date",details.fromDate)
      data.append("from_time",details.fromTime)
      data.append("to_date",details.toDate)
      data.append("to_time",details.toTime)
      // data.append("from_date",details.fromDate && getUTCDateFormat(details.fromDate))
      // data.append("from_time",details.fromDate && getUTCTimeFormat(details.fromDate,details.fromTime))
      // data.append("to_date",details.toDate && getUTCDateFormat(details.toDate))
      // data.append("to_time",details.toDate && getUTCTimeFormat(details.fromDate,details.toTime))
      data.append("service_request_types_id",requestId[1].service_request_types_id)
      data.append("level",details.level && details.level)
      data.append("unit",details.unit && details.unit);
      data.append('delete_file',deletedFile.length>0 ? string :'')
      if(reqId!='null'){
        data.append('service_requests_id',reqId)
        data.append('status','draft')
      }
      submittingFile.length>0 && data.append("file_count",submittingFile.length);
      for(let i=0;i<submittingFile.length;i++){
        data.append(`file${i}`,submittingFile[0].file)
      }
      if(reqId!='null'){
        updateMiscDraft(data)
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }else{
        addMiscelleaneousAdhocRequestAsDraft(data)
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }
     
    }else{
      setInputErrors((prev)=>(
        {
          ...prev,
          title:"Should not be empty"
        }
      ))
    }
    
  }
  return (
    <>
    {loaderStatus && <Loader status={loaderStatus} />}
     <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
    <Box sx={{p:3}}>
          <Box>
              <StyledTypography>Request Title*</StyledTypography>
              <Typography sx={{fontSize:"14px",color:' #6F80A9'}}>Please input a request title below </Typography>
              <OutlinedInput value={details.title} autoComplete='off' fullWidth sx={{mt:3,color:"#0F2C6F" }} name="title" onChange={(e)=>handleDetails(e)}  />
              {inputErrors.title && <Typography sx={{color:"#f44336"}}>{inputErrors.title}</Typography>}          
          </Box>
          <Box sx={{mt:5}}>
            <StyledTypography>Request Details*</StyledTypography>
            <Typography sx={{fontSize:"14px",color:' #6F80A9'}}>Please provide a detailed description of your request for us to assist you. </Typography>
            <OutlinedInput value={details.details} autoComplete='off' fullWidth sx={{mt:2,color:"#0F2C6F" }} name="details" onChange={(e)=>handleDetails(e)}  />
            {inputErrors.details && <Typography  sx={{color:"#f44336"}}>{inputErrors.details}</Typography>}    
          </Box>
          <Grid container rowSpacing={3} sx={{mt:4}}>
            <Grid item xs={12}>
              <StyledTypography>Request Period</StyledTypography>
              <Typography sx={{fontSize:"14px",color:' #6F80A9'}}>Please provide the estimated date and time (if any) for the Building Management team to carry out any necessary works.</Typography>
            </Grid>
            <Grid  item container xs={12} columnSpacing={2} >
                <Grid item xs={4}>
                  <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600,mb:1}}>Date from</Typography>
                  <DatePicker inputFormat='dd/MM/yyyy' components={{
                    OpenPickerIcon:DatePickerIcon
                  }} maxDate={details.displaytoDate} value={details.displayfromDate} onChange={(value)=>handleDate(value,"fromDate")} disablePast renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                  {inputErrors.fromDate && <Typography  sx={{color:"#f44336"}}>{inputErrors.fromDate}</Typography>} 
               </Grid>
                <Grid item xs={2}>
                  <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600,mb:1}}>Time</Typography>
                  <Select
                            defaultValue={details.fromTime}
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"55px",width:100,color:"#0F2C6F"}}  onChange={(e)=>handleTime(e,"fromTime")} value={details.fromTime} >
                               {startHours.map((value,index)=>{
                                        return <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>            
                  {/* <TimePicker  placeholder="hh:mm" maxTime={details.displaytoTime}  ampm={false} onChange={(value)=>handleTime(value,"fromTime")} value={details.displayfromTime} renderInput={(params)=><TextField  size='small' {...params} />} /> */}
                  {inputErrors.fromTime && <Typography  sx={{color:"#f44336"}}>{inputErrors.fromTime}</Typography>} 
                </Grid>
                <Grid item xs={6} />
            </Grid>
            <Grid  item container xs={12} columnSpacing={2}>
                <Grid item xs={4}>
                  <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600,mb:1}}>Date to</Typography>
                  <DatePicker inputFormat='dd/MM/yyyy' components={{
                    OpenPickerIcon:DatePickerIcon
                  }} minDate={details.displayfromDate } value={details.displaytoDate}   onChange={(value)=>handleDate(value,"toDate")} renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                  {inputErrors.toDate && <Typography  sx={{color:"#f44336"}}>{inputErrors.toDate}</Typography>} 
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600,mb:1}}>Time</Typography>
                  <Select
                            defaultValue={details.toTime}
                             MenuProps={
                                MenuProps
                            } 
                            sx={{height:"55px",width:100,color:"#0F2C6F"}}  onChange={(e)=>handleTime(e,"toTime")} value={details.toTime} >
                               {endHours.map((value,index)=>{
                                        return <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>    
                  {/* <TimePicker minTime={details.displayfromTime} ampm={false} onChange={(value)=>handleTime(value,"toTime")} value={details.displaytoTime} renderInput={(params)=><TextField size='small' {...params} />} /> */}
                  {inputErrors.toTime && <Typography  sx={{color:"#f44336"}}>{inputErrors.toTime}</Typography>} 
                </Grid>
                <Grid item xs={6} />
            </Grid>
            <Grid item container xs={12} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600,mb:1}}>Location</Typography>
              </Grid>
              <Grid item xs={2}>
                <Select value={Number(details.level) || ''} sx={{color:"#0F2C6F"}} fullWidth name="level" onChange={(e)=>handleDetails(e)} >
                  {
                    level?.length > 0 && level.map((value)=>(
                      <MenuItem sx={{color:'#0F2C6F'}} value={value.level_id} onClick={()=>getTenantAccessUnit({level_id:value.level_id})}>{value.level_name}</MenuItem>
                    ))
                  }
                </Select>
                {inputErrors.level && <Typography  sx={{color:"#f44336"}}>{inputErrors.level}</Typography>}    
              </Grid>
              <Grid item xs={4}>
                <Select value={Number(details.unit)} sx={{color:"#0F2C6F"}} fullWidth name="unit" onChange={(e)=>handleDetails(e)} >
                  {
                    unit?.length > 0 && unit.map((value)=>(
                      <MenuItem sx={{color:'#0F2C6F'}} value={value.unit_id}>{value.unit_name}</MenuItem>
                    ))
                  }
                </Select>
                {inputErrors.unit && <Typography  sx={{color:"#f44336"}}>{inputErrors.unit}</Typography>}    
              </Grid>
              <Grid item xs={6} />
            </Grid>
          </Grid>
          <Box sx={{mt:5}}>
            <StyledTypography sx={{mb:2}}>Supporting Materials</StyledTypography>
            <>
            {
              selectedFiles.length > 0 ?
              <Box>
               {
                selectedFiles.map((value)=>(
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #DBDFE9', borderRadius: "8px", mt: 2, p: 2 }}>
                        <Typography sx={{ color: " #0F2C6F", fontSize: '14px' }}>{value.fileName}
                              <span style={{ color: "#9AA6C3", fontSize: '12px', marginLeft: '10px' }}>({Math.round(value.size / 1000)} of {Math.round(value.size / 1000)}KB)</span></Typography>
                          <CloseIcon onClick={() => handleDeleteFiles(value.id)} />
                   </Box>
                ))
               }
               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button component='label' variant='outlined' sx={{ color: '#0F2C6F', mt: 2, textTransform: 'capitalize' }} endIcon={<Fileupload></Fileupload>}>Upload
                            <input multiple type='file'  style={{ display: 'none' }} onChange={(e) => handleUploadButton(e)} />
                    </Button>
                </Box>
              </Box> : <DropZone getFiles={handleFiles} />
            }
            </>
            
            <Box sx={{mt:2}}>
              <StyledTypography sx={{mt:3}}>{terms_condition && terms_condition[0]?.title}</StyledTypography>
              <Typography sx={{fontSize:"14px",color:' #6F80A9',mt:1}}>{terms_condition && terms_condition[0]?.description}</Typography>
              {terms_condition && terms_condition[1]?.rules?.map((value,index)=>(
                  <Typography sx={{color:"#6F80A9",fontSize:'12px',mt:2}}>{`${index+1} . ${value[index+1]}`}</Typography>
              ))}
           <Typography sx={{fontSize:"14px",color:' #6F80A9',mt:1,ml:-1}}>  <Checkbox icon={<CheckboxIcon/>} onClick={()=>setCheckBoxSelected(!checkBoxSelected)} /> I have read and agreed to comply with the above terms and conditions.</Typography>
            </Box>
          </Box>
     </Box>
     <Box sx={{display:"flex",justifyContent:"space-between",backgroundColor:"rgba(143, 146, 161,0.05)",py:4}}>
            <Typography sx={{color:"#9AA6C3",fontSize:"14px"}}><RefreshIcon style={{marginRight:"5px"}} />Last Updated at {lastUpdatedTime}</Typography>
            <Box >
              <Button onClick={handleDraft} variant='outlined' sx={{height:"50px",border:"1px solid #AAC3FF",borderRadius:"6px",color:"#0F2C6F",fontSize:"14px",fontWeight:600,textTransform:"capitalize",mr:2,backgroundColor:"#FFFFFF","&:hover":{backgroundColor:"#FFFFFF"}}}>Save As Draft</Button>
              <Button onClick={handleSubmitButton} disabled={!checkBoxSelected} variant='contained' sx={{height:"50px",textTransform:"capitalize  ",backgroundColor:' #123FA9',"&:hover":{backgroundColor:"#123FA9"},borderRadius:"6px",color:'#FFFFFF'}}>Submit</Button>
            </Box>
      </Box>
    </>
  )
}

const mapStateToProps = (state)=>{
  return{
    level:state.CommonReducer.tenantOccupiedLeves,
    unit:state.CommonReducer.tenantOccupiedUnits,
    addServiceReqStatus: state.Service_Req_Reducer.addServiceReqStatus,
    serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
    updateMiscDraftStatus:state.Service_Req_Reducer.updateMiscDraftStatus
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
    getTenantAccessLevel:()=>(dispatch(Commonaction.getTenantAccessLevel())),
    getTenantAccessUnit:(data)=>dispatch(Commonaction.getTenantAccessUnit(data)),
    addMiscelleaneousAdhocRequest:(data)=>(dispatch(ServiceAction.addMiscelleaneousAdhocRequest(data))),
    addMiscelleaneousAdhocRequestAsDraft:(data)=>(dispatch(ServiceAction.addMiscelleaneousAdhocRequestAsDraft(data))),
    setServiceRequestStateNull: () => (dispatch(ServiceAction.setServiceRequestStateNull())),
    serviceRequestByID: (data) => (dispatch(ServiceAction.serviceRequestDetailsById(data))),
    updateMiscDraft:(data)=>(dispatch(ServiceAction.updateMiscDraft(data)))
  }
}



export default connect(mapStateToProps,mapDispatchToProps)(MiscellaneousADHOCRequest);