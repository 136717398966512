import { Box, Button, Checkbox, Chip, Grid, IconButton, Modal, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import {ReactComponent as BackIcon} from '../../../Assets/icons/back.svg';
import { ReactComponent as BellWatch } from "../../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../../Assets/icons/StructuralFaultBell.svg";
import {ReactComponent as DownloadIcon} from '../../../Assets/icons/DownloadIcon.svg';
import {ReactComponent as DownloadButtonIcon} from '../../../Assets/icons/OutlinedDownload.svg';
import {ReactComponent as RefreshIcon} from '../../../Assets/icons/Refresh.svg';
import { ReactComponent as LogoIcon } from '../../../Assets/icons/Logo.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/Alertclose.svg';
import {ReactComponent as PdfIcon} from '../../../Assets/icons/PDF.svg';
import {ReactComponent as ImageFormatIcon} from '../../../Assets/icons/ImageFormatIcon.svg';
import StatusStepper from "../../common/stepper";
import { useNavigate,useParams } from "react-router-dom";
import Alert from '../../common/faultalert';
import ToastAlert from "../../common/Alert";
import { connect } from "react-redux";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import { checkFCCRole, getLoggedUserData, getTimeStamp } from "../../../Utils/utils";
import { getStatus } from "../../../Utils/ActiveStatus";
import UpdatePopup from "../UpdatePopup";
import Loader from "../../common/Loader";
import moment from "moment";
import html2PDF from 'jspdf-html2canvas';
import UnAuthorized from "../../common/Unauthorized";
import { saveAs } from "file-saver";

const StyledTypography = styled(Typography)({
    color:"#0F2C6F",
    fontSize:"18px"
})

const StyledContentTypography = styled(Typography)({
    fontSize:"12px",
    color:"#0F2C6F"
})

const StyledTableCell = styled(TableCell)({
    border:"none",
    fontSize:"14px",
    color:"#0F2C6F"
})

const alertDatas = [
    {
        label:"Approve Service Request",
        description:'Approve service request?',
        buttonvalue:'Confirm'
    },
    {
        label:"Reject Service Request",
        description:'Reject service request?',
        buttonvalue:'Confirm'
    },
    {
        label:"Withdraw Request",
        description:'Are you sure you want to withdraw request?',
        buttonvalue:'Confirm'
    },
    
]

const ReviewAirConExtension = ({tab_value,serviceReqDetails,serReqUpdateStatus,serviceRequestByID,updateServiceRequestStatus,setServiceRequestStateNull,fromDashboard,updateWatchStatus,updateWatch,notification,isUnAuthorized})=>{
    let {role_id} = getLoggedUserData()
    const localData = JSON.parse(localStorage.getItem("userData"));
    const navigate = useNavigate();
    const statusArray = ["approved","rejected","withdraw","assigned"]
    let {service_req_id} = useParams();
    const [tenantData,setTenantData] = useState();
    const [reviewData,setReviewData] = useState();
    const [alertOpen,setAlertOpen] = useState({status:false,data:{},actionStatus:""});
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [openUpdateStatus,setOpenUpdateStatus] = useState(false);
    const [nextUpdateStatus,setNextUpdateStatus] = useState(null)
    const [openAcknowledgeAlert,setOpenAcknowledgeAlert] = useState(false);
    const [terms,setTerms] = useState();
    const [error,setError] = useState({acknowledge:""})
    const [acknowledgeFeedback,setAcknowledgeFeedback] = useState("")
    const [duration,setDuration] = useState(null);
    const [dateDuration,setDateDuration] = useState(null)
    const [loaderStatus,setLoaderStatus] = useState(true);
    const [description, setDescription] = useState("");
    const [sapDetails, setSapDetails] = useState({})
    const [showSapDetails, setShowSapDetails] = useState(true);

    useEffect(()=>{
        serviceRequestByID({service_requests_id:service_req_id});
    },[updateWatchStatus,service_req_id,notification])


    useEffect(()=>{
        if(serviceReqDetails.companiesArrayList){
            setTenantData(serviceReqDetails?.companiesArrayList[0]);
        }
        if(serviceReqDetails.serviceRequestsArrayList){
            setReviewData(serviceReqDetails?.serviceRequestsArrayList[0])
            setLoaderStatus(false)
        }else if(isUnAuthorized!=false){
            setLoaderStatus(false)
        }
        if(serviceReqDetails.airConExtensionDurationArrayListForWeb){
            setDuration(serviceReqDetails.airConExtensionDurationArrayListForWeb )
        }
        if(serviceReqDetails.airConExtensionDurationArrayList){
            setDateDuration(serviceReqDetails.airConExtensionDurationArrayList)
        }
        if(serviceReqDetails?.termsArrayList){
            setTerms(JSON.parse(serviceReqDetails?.termsArrayList[0].config_value))
        }
        if(serviceReqDetails?.nextStatusListArrayList){
            setNextUpdateStatus(serviceReqDetails?.nextStatusListArrayList)
        }

        if(serviceReqDetails?.service_request_sap_invoice){
            setSapDetails(serviceReqDetails?.service_request_sap_invoice);
        }
    },[serviceReqDetails])

    useEffect(() => {
        if (serReqUpdateStatus?.response_status==1) {
            setOpenUpdateStatus(false)
            closeFunc()
            setSnackbarStatus({ message: serReqUpdateStatus?.message, status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate(`/service_request_list/${tab_value}`)
            }, 3000);
        } else if (serReqUpdateStatus?.response_status==0) {
            setOpenUpdateStatus(false)
            closeFunc()
            setSnackbarStatus({ message:serReqUpdateStatus?.message, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 3000);
        }else if (serReqUpdateStatus!=null) {
            setOpenUpdateStatus(false)
            closeFunc()
            setSnackbarStatus({ message:serReqUpdateStatus, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 3000);
        }
    }, [serReqUpdateStatus])

    useEffect(()=>{
        if(updateWatchStatus=="Service Request watch updated successfully"){
         setSnackbarStatus({ message: updateWatchStatus, status: true, color: 'green', icon: 'close' })
          setTimeout(()=>{
             removeSnackbar()
            setServiceRequestStateNull()
          },3000)
        }else if(updateWatchStatus!=null){
         setSnackbarStatus({ message:updateWatchStatus , status: true, color: 'red', icon: 'close' })
          setTimeout(()=>{
             removeSnackbar()
            setServiceRequestStateNull()
          },3000)
        }
      },[updateWatchStatus])

    const removeSnackbar = () => {
        setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
    }

    const closeFunc = () => {
        setAlertOpen({ status: false, alertData: {}, actionStatus: '' })
    }

    const updateStatusPopup = (id)=>{
        setAlertOpen({
            status:true,
            data:alertDatas[id],
            actionStatus:statusArray[id]
        })
    }

    const handleSubmitData = ()=>{

        if(!description && alertOpen.actionStatus === "rejected"){
            setError(prevValue=>({...prevValue, description:'Remarks required !' }))
            return;
        }else setError(prevValue=>({...prevValue, description:'' }))

        let data={
            service_requests_id: service_req_id,
            description: description,
            status:alertOpen?.actionStatus
        }
        updateServiceRequestStatus(data)
    }
    const StepperSubmitData = ()=>{
        let data={
            service_requests_id: service_req_id,
            description: '',
            status:'withdraw'
        }
        updateServiceRequestStatus(data)
    }

    const updateAcknowledgement = ()=>{
        if(!acknowledgeFeedback){
            setError({
                ...error,
                acknowledge:"Should not be empty"
            })
        }else{
            let serReqNewStatus = {
                service_requests_id: service_req_id,
                status: 'acknowledgement',
                description:acknowledgeFeedback
            }
            updateServiceRequestStatus(serReqNewStatus)
        }
       
    }   

    const handleWatchUnWatch =(id,status)=>{
        let data = {
            service_requests_id:id,
            watch_status:status
        }
        updateWatch(data)
    }

    const downloadPDF = () => {
        let data = document.getElementById('print')
        html2PDF(data,{
              jsPDF: {
                format: 'a4',
              },    
              imageType: 'image/jpeg',
              output: `${reviewData?.ticket_id} ${moment(new Date()).format('DD/MM/yyyy')}.pdf`
        })

    }

    const renderButtons = ()=>{
        if(localData.role_id===4 && reviewData?.status==="submitted"){
           return <Button onClick={()=>updateStatusPopup(2)} variant="contained" sx={{backgroundColor:"#BD0251",color:'#FFFFFF',textTransform:"capitalize",borderRadius:'8px',height:"48px","&:hover":{backgroundColor:"#BD0251"}}}>Withdraw</Button>
        }else if(localData.role_id===3 || localData.role_id===2 || localData.role_id===14 || checkFCCRole()){
            if(reviewData?.status==="submitted"){
                return <>
                    <Button onClick={()=>updateStatusPopup(1)}  variant="outlined" sx={{textTransform:'capitalize',backgroundColor:"#FFFFFF",color:"#0F2C6F",borderRadius:'6px',height:"50px",mr:2}}>Reject</Button>
                    <Button  onClick={()=>updateStatusPopup(0)} variant="contained" sx={{textTransform:'capitalize',color:"#FFFFFF",backgroundColor:"#123FA9",borderRadius:"6px",height:"50px"}}>Approve</Button>
                </>
            }else if (reviewData?.status==="resolved" || reviewData?.status==="closed" ||  reviewData?.status==="rejected" || reviewData?.status==="withdraw"){

            }else{
                return<>
                        
                    <Button variant="contained"
                        onClick={()=>setOpenUpdateStatus(true)}
                        sx={{
                    background: '#123FA9',
                    color: '#FFFFFF',
                    height: '50px',
                    width: '114px',
                    fontSize: '14px',
                    pr: 0,
                    pl: 0,
                    textTransform: 'none',

                }} >Update Status</Button>
                </>
            }
            
        }
    }

    const renderDescription =()=>{
        if(alertOpen.actionStatus === "rejected"){
            return  <Box sx={{  padding: '16px 24px' }}>
              <TextField multiline fullWidth rows={3} value={description} name="description" onChange={(e)=> { 
                setDescription(e.target.value); setError(prevValue=>({...prevValue,description:''})) ;
                }} />
              <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                  <br /> {error?.description}
              </Typography>
            </Box>
        }else return null
    }

    const handleSapFileDownload = (downloadFile, fileName) => {
        //   saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${downloadFile}`, fileName)
        saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${fileName}`, fileName)
    }

    return(
        <>
        {loaderStatus ? <Loader status={loaderStatus} /> :  isUnAuthorized!=false ? <UnAuthorized data={isUnAuthorized} /> :
        <>
        {alertOpen.status && <Alert renderElement={renderDescription()} closeFunc={()=>{ setAlertOpen("");  setError(prevValue=>({...prevValue, description:'' }))}} alertData={alertOpen.data} confirmFunc={handleSubmitData} />}
        <ToastAlert  message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
        {openUpdateStatus && <UpdatePopup closeFunc={setOpenUpdateStatus} serviceType={service_req_id}  serviceTypeValue={"Air Con Extension"} currentStatus={reviewData?.status} nextStatus={nextUpdateStatus} /> }
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}} >
            <Grid item xs={0.5} />
            <Grid item xs={8}>
                 <Box sx={{pt:7,pb:2}}>
                    <BackIcon style={{cursor:"pointer"}} onClick={()=>{
                        // if(fromDashboard=="true"){
                        //     navigate(`/dashboard`)
                        // }else{
                            navigate(-1)
                        // }
                    }} />
                    <Box sx={{display:"flex"}}>
                        <Box sx={{display: 'flex', alignItems: "center", justifyContent: 'space-between', width: "100%"}}>
                        <Typography sx={{pt:1,color:"#0F2C6F",fontSize:"26px",pb:1}}>{reviewData?.display_name} - #{reviewData?.ticket_id}</Typography>
                        <Button data-html2canvas-ignore='true' onClick={downloadPDF} variant="contained" endIcon={<DownloadIcon/>} sx={{fontWeight:600,outline:"none",textTransform:'capitalize',backgroundColor:"#CFDDFE",color:"#0F2C6F",borderRadius:"6px","&:hover":{backgroundColor:"#CFDDFE"}}}>Download</Button>
                        </Box>
                        {reviewData?.status!='rejected' && reviewData?.status!='closed' && reviewData?.status!='withdraw' && 
                            reviewData?.status!='acknowledgement' && reviewData?.status!='resolved' && 
                            <>
                                {serviceReqDetails?.watch_status=="yes" ?  <BellWatch onClick={()=>handleWatchUnWatch(service_req_id,"no")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' /> : 
                                        <BellUnWatch onClick={()=>handleWatchUnWatch(service_req_id,"yes")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' />
                                }
                            </>
                        }
                    </Box>
                    <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>{getTimeStamp(reviewData?.created_at)}</Typography>
                    <Chip label={getStatus(reviewData?.status, 'text')} sx={{ backgroundColor: getStatus(reviewData?.status, 'bgcolor'), color:getStatus(reviewData?.status, 'color'), borderRadius: '6px', height: '24px', fontSize: '14px', ml: 2 }} />
                </Box>
            </Grid>
            <Grid item xs={3.5} />
        </Grid>
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}} columnSpacing={1}>
            <Grid item xs={0.5} />
            <Grid item xs={8}>  
                <Box id='print'>
                        {reviewData?.status === 'closed' &&
                        <Box sx={{my: 3, background: '#ffffff', borderRadius: '8px',p:3 }}>
                            <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#0F2C6F"}}>Final SAP Invoice</Typography>
                            <Box sx={{p:2, border: '1px solid #DBDFE9', borderRadius: "8px", my: 2}}>

                            {showSapDetails &&
                                <Box sx={{mb: 2, borderBottom: '1px solid #DBDFE9', pb: 2, display: 'flex', flexDirection: "column", gap: '20px'}}>
                                {/* SAP No */}
                                <Box>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 500}}>SAP Number</Typography>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 400}}>{sapDetails?.sap_number || '-'}</Typography>
                                </Box>

                                {/* Comments */}
                               <Box>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 500}}>Comments</Typography>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 400}}>{sapDetails?.sap_description || '-'}</Typography>
                                </Box> 
                                </Box> }

                                <Box sx={{display: "flex", width: '100%', justifyContent: 'space-between', alignItems: "center"}}>  
                                    <Box>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>Total Amount (SGD)</Typography>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>GST Included</Typography>

                                    </Box>
                                    <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#A48B2C"}}>${sapDetails?.final_invoice_amount || 0}</Typography>
                                </Box>


                            </Box>
                           {sapDetails?.file &&  showSapDetails &&  <Box sx={{pt: 2}}>
                            <Typography sx={{fontSize: "18px", fontWeight: 500, color: "#0F2C6F "}}>Uploaded SAP Invoice</Typography>
                            <Box sx={{display: "flex", width:"-webkit-fill-available", justifyContent: "space-between", alignItems: "center", my: 2, p: 2,border: '1px solid #DBDFE9', borderRadius: "8px"}}>
                                <Box sx={{display: 'flex', alignItems: "center", gap: 2}}>
                                {sapDetails?.file_type === 'pdf' ? <PdfIcon style={{width: '40px', height: "40px"}} />
                                 : <ImageFormatIcon style={{width: '40px', height: "40px"}} />}
                                 <Box>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>{sapDetails?.file_name}</Typography>
                                   {sapDetails?.file_size && <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#9AA6C3"}}>{sapDetails?.file_size} of {sapDetails?.file_size} KB</Typography>}
                                 </Box>
                                </Box> 

                                <IconButton style={{borderRadius: 0}} onClick={() => handleSapFileDownload(sapDetails?.file, sapDetails?.file_name)}>
                                 <DownloadButtonIcon />
                                </IconButton>
                             </Box>
                             </Box>}

                            <Box sx={{display: "flex", py: 1, justifyContent: "center", cursor: "pointer"}}>
                                <Typography sx={{fontSize: '14px', color: "#2764F4", textDecoration: "underline"}}
                                onClick={() => setShowSapDetails(prevValue => !prevValue)}
                                >{showSapDetails ? 'Hide' : 'Show'} details</Typography>
                            </Box>
                            
                        </Box>
                            }
                        
                        <Box sx={{backgroundColor:"#FFFFFF",p:3}}>
                            <Box sx={{display:"flex",justifyContent:'space-between'}}>
                                <Typography sx={{color:"#0F2C6F",fontSize:"26px"}}>Bill Summary - <span style={{fontSize:'18px'}}>{reviewData?.ticket_id}</span></Typography>
                                <LogoIcon style={{marginTop:'5px'}} />
                            </Box>
                            <Box sx={{border:"1px solid #DBDFE9",borderRadius:"6px",p:2,mt:2}}>
                                <Typography sx={{fontSize:"14px",color:"#0F2C6F",fontWeight:600}}>Bill Summary</Typography>
                                <Typography sx={{fontSize:"14px",color:"#0F2C6F",my:0.3}}>Billing Method : Company </Typography>
                                <Typography sx={{fontSize:"14px",color:"#0F2C6F",my:0.5}}>Duration:</Typography>
                                {dateDuration?.map((value,index)=>(
                                    <Typography sx={{fontSize:"14px",color:"#0F2C6F",my:1,ml:3}}>  {moment(value.from_date).format("DD MMM YYYY")} {value.start_time}  -  {moment(value.to_date).format("DD MMM YYYY")} {value.end_time}</Typography>
                                ))}
                                <Typography sx={{fontSize:"14px",color:"#0F2C6F",mt:0.5,mb:1}}>Total Requested Hours: {duration && duration[0]?.total_duration}  </Typography>
                                <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{'&.MuiTableRow-root':{border:"none"}}}>
                                            <StyledTableCell sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600}}>Cost Breakdown</StyledTableCell>
                                            <StyledTableCell sx={{color:"#0F2C6F",fontSize:"14px"}}>Qty.</StyledTableCell>
                                            <StyledTableCell sx={{color:"#0F2C6F",fontSize:"14px"}}>Price/Unit</StyledTableCell>
                                            <StyledTableCell sx={{color:"#0F2C6F",fontSize:"14px"}}>Total</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <StyledTableCell>Air- Con Charges</StyledTableCell>
                                            <StyledTableCell>{duration && duration[0]?.total_duration} Hours</StyledTableCell>
                                            <StyledTableCell>${duration &&  duration[0]?.hourly_charges}</StyledTableCell>
                                            <StyledTableCell>${duration &&  duration[0]?.total_amount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell>GST</StyledTableCell>
                                            <StyledTableCell>-</StyledTableCell>
                                            <StyledTableCell>{duration && duration[0]?.gst}%</StyledTableCell>
                                            <StyledTableCell>${duration && duration[0]?.gst_amount}</StyledTableCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledTableCell><Typography sx={{fontSize:"14px",color:"#0F2C6F",fontWeight:600}}>Total Amount (SGD)</Typography>
                                            <Typography sx={{fontSize:"14px",color:"#0F2C6F"}}>GST Included</Typography></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            <StyledTableCell> <Typography sx={{color:"#A48B2C",fontSize:"26px"}}>$ {duration && duration[0]?.grand_total_amount} </Typography></StyledTableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        
                            </Box>
                        </Box>
                    <Grid container sx={{backgroundColor:"#FFFFFF",mt:2,p:3}} rowSpacing={4}>
                        <Grid item xs={12}>
                            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                                <StyledTypography>Tenant Details</StyledTypography>                               
                            </Box>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={4}>
                                {/* <Typography sx={{color:" #0F2C6F",fontSize:"14px"}}>Joshua Sholz</Typography> */}
                                <StyledContentTypography>{tenantData?.company_name}</StyledContentTypography>
                            </Grid>
                            <Grid item xs={4}>
                                    <StyledContentTypography>Office : {tenantData?.country_code} {tenantData?.phone_no} </StyledContentTypography>
                                    <StyledContentTypography>Mobile : {tenantData?.country_code} {tenantData?.phone_no} </StyledContentTypography>
                            </Grid>
                            <Grid item xs={4}>
                                                        
                            </Grid>
                        </Grid> 
                        <Grid item container xs={12}>
                            <Grid item xs={4}>
                                <StyledTypography>Work Period </StyledTypography>
                                <Typography sx={{color:" #0F2C6F",fontSize:"14px"}}>{moment(reviewData?.from_date).format('DD-MM-YYYY')} - {moment(reviewData?.to_date).format('DD-MM-YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <StyledContentTypography>Time : {reviewData?.from_time} - {reviewData?.to_time}</StyledContentTypography>
                                <StyledContentTypography>Duration : {duration && duration[0]?.total_duration} hours </StyledContentTypography>
                                <StyledContentTypography>Level : {dateDuration && dateDuration[0]?.level_name} </StyledContentTypography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTypography>Chargeable Rates</StyledTypography>
                            <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Short description of chargeable rates for this work request</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{border:" 1px solid #DBDFE9",borderRadius:"6px",backgroundColor:"#F3F4F8",p:2,mt:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>Request must be made at least 24 hours in advance to requested date must be provided:</Typography>
                            <Typography  sx={{color:"#0F2C6F",fontSize:"14px",mt:2}}>Air-Condition Extension Charges: ${serviceReqDetails?.aircon_cost_per_hour}/hour</Typography>
                            <Typography  sx={{color:"#0F2C6F",fontSize:"14px"}}>*not including {serviceReqDetails?.aircon_gst}%GST</Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ backgroundColor:"#FFFFFF",p:3 }}>
                            <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>{terms && terms[0]?.title} *</Typography>
                            <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{ terms && terms[0]?.description}</Typography>
                            <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{terms && terms[1]?.rules[0]?.["ADVANCE NOTICE"]}</Typography>
                            <Typography sx={{ color: "#6F80A9", fontSize: '12px' }}>{terms && terms[1]?.rules[1]?.["Minimum Requirement"]}</Typography>
                            <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{terms && terms[1]?.rules[2]?.["URGENT/ ADHOC REQUEST"]}</Typography>
                            <Box sx={{ display: "flex", alignItems: "center",mt: 1 }}>
                                <Checkbox checked disabled />
                                <Typography sx={{ color: "#0F2C6F", fontSize: "14px" }}>I have read and agree to comply with the above terms & conditions and chargeable rates. </Typography>
                            </Box>
                            {/* <Box sx={{ display: "flex" }}>
                                <Checkbox checked disabled/>
                                <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 2 }}>I have read and agreed to comply with the above terms and conditions for holding the event in the Building upon the Management’s approval.</Typography>
                            </Box> */}
                        </Box>
                </Box>
                <Box sx={{display:"flex",justifyContent:"flex-end",py:5}}>
                    <Box>
                        {renderButtons()}
                    </Box>
                </Box>
               
            </Grid>
            <Grid item xs={3.5}>
                <Box sx={{ backgroundColor: '#FFFFFF', mx: 3 }}>
                    <Box sx={{borderBottom:"1px solid #C0C7D7",py:2,px:3,borderRadius:"8px 8px 0 0 "}}>
                        <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>Status Updates</Typography>
                    </Box>
                    <Box sx={{p:2}}>
                        {role_id==4 ? 
                        <StatusStepper type="ServiceRequest" currentStatus={reviewData?.status} withdrawFunc={StepperSubmitData} statusLogs={serviceReqDetails?.serviceRequestLogTempArray?.length >0 ? serviceReqDetails?.serviceRequestLogTempArray : []} /> : 
                        <StatusStepper  currentStatus={reviewData?.status} withdrawFunc={StepperSubmitData} statusLogs={serviceReqDetails?.serviceRequestsLogsArrayList?.length >0 ? serviceReqDetails?.serviceRequestsLogsArrayList : []} />
                        }
                    </Box>
                </Box>
                
            </Grid>
        </Grid>
        <Modal open={openAcknowledgeAlert}>
                <Box sx={{ position: "absolute", backgroundColor: "#FFFFFF", left: '25%', top: '10%', width: "40%" }}>
                    <Box sx={{ display: "flex", justifyContent: 'space-between', borderBottom: "1px solid #C0C7D7", p: 3 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "18px" }}>Acknowledgement of Light Bulb Replacement Request</Typography>
                        <CloseIcon onClick={() => closeFunc()} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ p: 3 }}>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>By clicking this button means you have acknowledge the completation of the request and agree to the invoice then turn it to a resolved case. If there is any inconvenient, please contact BMS 6824 8333</Typography>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 3, mb: 1 }}>Feedback on Work</Typography>
                        <OutlinedInput inputProps={{ maxLength: 300 }} onChange={(e) => setAcknowledgeFeedback(e.target.value)} fullWidth />
                        <Typography sx={{ mt: 1, color: " #9AA6C3", fontSize: "14px" }}>[{acknowledgeFeedback?.length}/300]</Typography>
                        {error.acknowledge && <Typography sx={{color:'#f44336'}}> {error.acknowledge}</Typography> }
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
                        <Button sx={{
                            cursor:"pointer",
                            textTransform: "capitalize",
                            color: "#0F2C6F",
                            border: "1px solid #AAC3FF",
                            borderRadius: "6px"
                        }} onClick={() => closeFunc()} >Cancel</Button>
                        <Button
                        onClick={updateAcknowledgement}
                        //disabled={notetoTenant?.length===0}
                         sx={{
                            cursor:"pointer",
                            color: "#FFFFFF",
                            backgroundColor: "#123FA9",
                            textTransform: "capitalize",
                            borderRadius: "6px",
                            "&:hover": { backgroundColor: "#123FA9" }
                        }}>Acknowledge</Button>
                    </Box>

                </Box>
            </Modal>
        </> }
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
        serReqUpdateStatus: state.Service_Req_Reducer.updateStatus,
        updateWatchStatus:state.Service_Req_Reducer.updateWatchStatus,
        notification:state.DashboardReducer.notification,
        isUnAuthorized:state.Service_Req_Reducer.isUnAuthorized

    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        serviceRequestByID: (data) => (dispatch(serviceRequestAction.serviceRequestDetailsById(data))),
        updateServiceRequestStatus: (data) => (dispatch(serviceRequestAction.updateServiceRequestStatus(data))),
        setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
        updateWatch: (data) => (dispatch(serviceRequestAction.updateWatchStatus(data))),

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewAirConExtension)