import React, {useState, useEffect} from 'react'
import { Box, FormControl,Button, Grid, MenuItem , Avatar, Divider , InputLabel, Chip, IconButton, OutlinedInput,Select, FormControlLabel, Tab, Tabs, Typography, Checkbox, TextField } from "@mui/material";
import { DoNotDisturbOn } from '@mui/icons-material';
import { styled } from "@mui/system";
import {ReactComponent as BackIcon} from '../../Assets/icons/back.svg';
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxTerms.svg';
import Phone  from "../../Assets/icons/phone.png";
import TV  from "../../Assets/icons/TV.png";
import Wifi  from "../../Assets/icons/wifi.png";
import WritingWall  from "../../Assets/icons/writingWall.png";
import Webcam  from "../../Assets/icons/webcam.png";
import { useNavigate } from "react-router-dom";
import { MenuProps } from '../../Utils/utils';
import DropZone from "../../Utils/dropzone";
import { ReactComponent as UploadIcon } from '../../Assets/icons/Upload.svg';
import { ReactComponent as CloseIcon } from '../../Assets/icons/Alertclose.svg';
import { ReactComponent as RoundedPlusIcon } from '../../Assets/icons/RoundedPlusIcon.svg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReviewInformation from './ReviewInformation';


const StyledTab = styled(Tab)({
    textTransform:'capitalize',
    color:' #123FA9'
})

const spanStyle={
    backgroundColor:'#123FA9',
    color:'#FFFFFF',
    borderRadius:'50%',
    padding:'4px 6px',
    fontSize:'12px',
    marginRight:'5px'
}

const StyledTypography = styled(Typography)({
    fontSize:'14px',
    fontWeight:500,
    color:'#0F2C6F',
    marginBottom:'5px',
    
   
})

const StyledOutlinedInput = styled(OutlinedInput)({
    height:'56px',
    borderColor:' #9AA6C3',
    borderRadius:'6px',
    width:'100%',
    color:'#0F2C6F'
})

const imageStyles = {
    cursor: 'pointer',
    margin: '10px',
    borderRadius: '8px',
    padding: '5px',
  };

const selectedImageStyles = {
...imageStyles,
background: 'lightgray',
boxShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
borderRadius:'10px',

};

const dailyData = [{ id: 1, day: 'Monday', FromTime: '', toTime: '', isDisable: false },
{ id: 2, day: 'TuesDay', FromTime: '', toTime: '', isDisable: false },
{ id: 3, day: 'Wednesday', FromTime: '', toTime: '', isDisable: false },
{ id: 4, day: 'Thursday', FromTime: '', toTime: '', isDisable: false },
{ id: 5, day: 'Friday', FromTime: '', toTime: '', isDisable: false },
{ id: 6, day: 'Saturday', FromTime: '', toTime: '', isDisable: false },
{ id: 7, day: 'Sunday', FromTime: '', toTime: '', isDisable: false }
]

const options = [
    { value: 1, label: 'phone', src: Phone },
    { value: 2, label: 'tv', src: TV },
    { value: 3, label: 'wifi', src: Wifi },
    { value: 4, label: 'writingWall', src: WritingWall },
    { value: 5, label: 'webcam', src: Webcam },
  ];

  const newUniqFeature = {
    uniq_icon: '',
    uniq_title:'',
    short_description:'',
  }

  const newUniqFeatureError = {
    uniq_icon: '',
    uniq_title:'',
    short_description:'',
  }

export default function CreateFacility() {
    const navigate = useNavigate();
    const [tabValue,setTabValue] = useState(0);
    const [dailyDatas, setDailyDatas] = useState([...dailyData])
    const [startHours,setStartHours] = useState()
    const [endHours,setEndHours] = useState()
    const [tabDisabled,setTabDisabled] = useState({tab2:true,tab3:true})
    const newObject = { ...newUniqFeature }
    const newObjectError = { ...newUniqFeatureError }
    const [featuredImages, setFeaturedImages] = useState([]);
    const [facilityDetails,setFacilityDetails] = useState({
        title:'',
        description:'',
        officeLevel:'',
        people_allowed:'',
        everyDayHours:'',
        openHours: "",
        selectedUnits: [],
        unit:'',
    })
    const [uniqDetails,setUniqDetails] = useState([newObject])
    const [uniqDetailsError,setUniqDetailsError] = useState([newObjectError])
    const [facilityDetailsError,setFacilityDetailsError] = useState({
        title:'',
        description:'',
        people_allowed:'',
        bmsRole:'',
        accessStartDate:'',
        officeLevel:'',
        unit:'',
        addUnit:'',
        office:'',
    
    })
    const [termsCondition, setTermsConditions] = useState();
    const toolbarOptions = [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
        [{ indent: "-1" }, { indent: "+1" }],
      ];
      const module = {
        toolbar: toolbarOptions,
        keyboard: {
          bindings: {
            "list autofill": {
              prefix: /^\s*()$/,
            },
          },
        },
      };
    const [innerTabValue, setInnerTabValue] = useState(0);

    const handleFacilityDetails = (e)=>{
        const {name,value} = e.target;
        setFacilityDetails({
                ...facilityDetails,
                [name]:value
            })
        
       
            setFacilityDetailsError({
            ...facilityDetailsError,
            [name]:'' 
        })
    }
    const handleUniqDetailsDetails = (e,index) => {
        const {name,value} = e.target;
        setUniqDetails(preValue=> {
            preValue[index][name] = value
            return [...preValue]
        })
        
        setUniqDetailsError(preValue=> {
            preValue[index][name] = " "
            return [...preValue]
        })
    }
    
    const handleWeeKdaysSelection = (selectedIndex) => {
        let updateData = [];
        dailyDatas.forEach(element => {
            if (element.id === selectedIndex) {
                updateData.push({ ...element, isDisable: !element.isDisable })
            } else {
                updateData.push({ ...element })
            }
        });
        setDailyDatas([...updateData])
    }
    const handleSameHours=(toSet)=>{
        setFacilityDetails({...facilityDetails,[toSet]:!facilityDetails.sameHoursApplied})
        if(!facilityDetails.sameHoursApplied){
            let updateData = [];
            dailyDatas.forEach(element => {
                updateData.push({ ...element, isDisable: true })
            });
        }
    }

    const getStartHours = (minTime)=>{
        let timeIntrevels=[]
        for(let i=minTime;i<24;i++){
           for(let j=1;j<=2;j++){
               if(j%2==0){
                timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
               }else{
                timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
               }
           }  
       };
       setStartHours([...timeIntrevels])
   }

   const getEndHours = (minTime)=>{
            let timeIntrevels=[],startTime=minTime===0?0:parseInt(minTime.slice(0,2))+1;

            for(let i=startTime;i<24;i++){
            for(let j=1;j<=2;j++){
                if(j%2==0){
                    timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
                }else{
                    timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
                }
            }  
        };
        setEndHours([...timeIntrevels])
    } 

    const getOpenhours = ()=>{
        let arr =  dailyDatas.map((value)=>{
            return{
                from:value.submitFromTime || "",
                to:value.submittoTime || "",
                closed:value.isDisable.toString()
            }
        }
        )
       return  arr
    }

    const handleDailyTime = (e, datafrom,rowId) => {
        let {value} = e.target
        setFacilityDetails({
            ...facilityDetails,
            [datafrom]: value,
            [`display${datafrom}`]: value
        })
        let updateData = [];
        dailyDatas.forEach(element => {
            if (element.id === rowId) {
                updateData.push({ ...element, [datafrom]: value,[`submit${datafrom}`]:value })
            } else {
                updateData.push({ ...element })
            }
        });
       
        setDailyDatas([...updateData])

        setFacilityDetailsError({
            ...facilityDetailsError,
            [datafrom]: ''
        })
    }

    const validate = () => {
        let error = true;
        let newErrors = [], errorMessges = {};
        if (facilityDetails.title === undefined || facilityDetails.title=='') {
            error = false
            errorMessges['title'] = 'Title field Required'
        } else {
            newErrors.push(true)
            errorMessges['title'] = ''
        }

        if (facilityDetails.description === undefined || facilityDetails.description=='') {
            error = false
            errorMessges['description'] = 'Please fill out this field'
        } else {
            newErrors.push(true)
            errorMessges['description'] = ''
        }


        if (facilityDetails.officeLevel === undefined || facilityDetails.officeLevel=='') {
            error = false
            errorMessges['officeLevel'] = 'Please choose level'
        } else {
            newErrors.push(true)
            errorMessges['officeLevel'] = ''
        }
        
        if (facilityDetails.unit === undefined || facilityDetails.unit=='') {
            error = false
            errorMessges['unit'] = 'Please choose unit'
        } else {
            newErrors.push(true)
            errorMessges['unit'] = ''
        }
        
        if (facilityDetails.unit !='' && facilityDetails.officeLevel !='' && facilityDetails.selectedUnits.length == 0) {
            error = false
            errorMessges['addUnit'] = 'Please add unit'
        } else {
            newErrors.push(true)
            errorMessges['addUnit'] = ''
        }
        

        dailyDatas.forEach(element => {

            if(!element.isDisable){
                if(element.FromTime==='' || element.FromTime==null){
                    error = false
                    errorMessges[`eventStartTime${element.day}`] = 'Start Time Required'
                }else{
                    newErrors.push(true)
                    errorMessges[`eventStartTime${element.day}`] = ''
                }
                if(element.toTime==='' || element.toTime==null){
                    error = false
                    errorMessges[`eventEndTime${element.day}`] = 'End Time Required'
                }else{
                    newErrors.push(true)
                    errorMessges[`eventEndTime${element.day}`] = ''
                }
                if(element?.FromTime?.slice(0,2) > element?.toTime?.slice(0,2)){
                    error = false
                    errorMessges[`validate${element.day}`] = 'End Time should be greater than Start Time'
                }else{
                    newErrors.push(true)
                    errorMessges[`validate${element.day}`] = ''
                }
            }

        });
        let errorStatus = newErrors.includes(false)
        setFacilityDetailsError({ ...errorMessges })
        return error;
    }

    const handleContinueButton = () => {
        setFacilityDetails({
            ...facilityDetails,
            ['openHours']:getOpenhours()
        })
        let err = validate();
        if(err) {
            if(tabValue == 0) {
                setTabValue(1);
                setTabDisabled({tab2:false, tab3:true})
            }
        }
    }


    const handleTab2ContinueBtn = () => {
        let error = true
        let err = validate();
        if(err == false) {
            setTabValue(0);
            error = false;
            return error;
        }
        const validationErrors = uniqDetailsError.map((data) => ({uniq_title:'', uniq_icon: '', short_description: '' }));
        uniqDetails.forEach((data, index) => {
            if (data.uniq_title == "") {
                validationErrors[index].uniq_title = 'title is required';
                error = false;
              }
            if (data.short_description == "") {
                validationErrors[index].short_description = 'desc is required';
                error = false;
            }
            if (data.uniq_icon == "") {
                validationErrors[index].uniq_icon = 'icon is required';
                error = false;
            }
        })
        setUniqDetailsError(validationErrors);

        if(validationErrors.every((error) => !error.uniq_title && !error.uniq_icon && !error.short_description)) {
            if(tabValue == 1 && err) {
                setTabValue(2);
                setTabDisabled({tab2:false, tab3:false})
            } 
        }
        if(error == false) setTabValue(1);
        
        return error
    }

    const handleIconClick = (value, name, index) => {
        
        setUniqDetails(preValue=> {
            preValue[index][name] = value
            return [...preValue]
        })
    };
    const addNewUniqFeature = () => {
        const emptyObject = { ...newUniqFeature }
        setUniqDetails(prevValue => [...prevValue, emptyObject])
        const emptyObject2 = { ...newUniqFeatureError }
        setUniqDetailsError(prevValue => [...prevValue, emptyObject2])
    }

    const removeUniqFeature = (index) => {
        setUniqDetails(prevValue => {
            prevValue.splice(index, 1)
            return [...prevValue]
        })
        setUniqDetailsError(prevValue => {
            prevValue.splice(index, 1)
            return [...prevValue]
        })
    }

    const handleFeaturedImages = (files) => {
        if (files.length > 0) {
            setFeaturedImages([...files])
        }
    }

    const handleUploadButton = (e) => {

        let target = e.target.files;
        let arr = featuredImages;
        let length = featuredImages.length;
        for (let i = 0; i < target.length; i++) {
            arr.push({ id: length, fileName: target[i].name, size: target[i].size, file: target[i], src: URL.createObjectURL(target[i]) });
            length += 1;
        }
        setFeaturedImages([...arr])
    }

    const handleImageDelete = (id) => {
        let res = featuredImages.filter((value) => (
            value.id !== id
        ))
        setFeaturedImages([...res])
    }

    const handlSubmit = () => {
       const res =  handleTab2ContinueBtn();
       if(res) {
            console.log('you can move on');
       } else {
        console.log('something error');
       }

    }

         
    const handleSelectedUnits = () => {
        console.log('addddddd');
        const newArray = { ...facilityDetails };
        const levelData = facilityDetails.officeLevel;
        const unitData = facilityDetails.unit;
        if(levelData && unitData) {
            let newData = `${"#"+levelData+'-'+unitData}`
            if(!newArray.selectedUnits.includes(newData)) {
                newArray.selectedUnits.push(newData);
                setFacilityDetails(newArray);
                setFacilityDetailsError({
                    ...facilityDetailsError,
                    ['addUnit']:'' 
                })
            }
            if(facilityDetails?.selectedUnits.length>0) {
                setFacilityDetailsError({
                    ...facilityDetailsError,
                    ['addUnit']:'' 
                })
            }

        }
        
    }
    
    // console.log('facils ',facilityDetails);

    const handleDelete = (value, index) => {
        const newArray = { ...facilityDetails };
        const newData = facilityDetails.selectedUnits.filter((item) => item != value);
        newArray.selectedUnits = newData;
        setFacilityDetails(newArray);
    }
    console.log('facilityDetailsError ',facilityDetailsError);
  
    useEffect(() => {
        getStartHours(0)
        getEndHours(0)
    },[])

  return (
    <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}} >
        <Grid item xs={1} />
            <Grid item xs={10}>
                <Box sx={{mb:5,mt:7}}>
                    <BackIcon onClick={()=>navigate(-1)} style={{cursor:'pointer'}} />
                    <Typography sx={{fontSize:'26px',fontWeight:500,color:'#0F2C6F',my:1}}>Create Facility</Typography>
                    <Typography sx={{fontSize:'14px',fontWeight:500,color:'#6F80A9'}}>Enter the full details of the facility below.</Typography>
                </Box>
            <Box sx={{backgroundColor:'#FFFFFF'}}>
            <Box sx={{borderBottom:'1px solid #DBDFE9'}}>
            <Tabs value={tabValue} onChange={(e,value)=>setTabValue(value)}>
                <StyledTab label={
                    <Box>
                        <span style={spanStyle}>1</span>
                        <span>Details & Booking</span>
                    </Box>
                }></StyledTab>
                <StyledTab disabled={tabDisabled.tab2} label={
                    <Box>
                        <span style={spanStyle}>2</span>
                        <span>Features & Images</span>
                    </Box>
                }></StyledTab>
                <StyledTab disabled={tabDisabled.tab3} label={
                    <Box>
                        <span style={spanStyle}>3</span>
                        <span>Review information</span>
                    </Box>
                }></StyledTab>
            </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0} >
            <Box sx={{p:3}}>
               <Typography sx={{color:'#0F2C6F',fontSize:'18px',fontWeight:500, mb:3}}>Facility Details</Typography>
               <Grid container rowSpacing={2}>
               <Grid item xs={12} sm={12}>
                    <StyledTypography>Title *</StyledTypography>
                    <StyledOutlinedInput onChange={(e)=>handleFacilityDetails(e)} name="title" value={facilityDetails.title} />
                    {facilityDetailsError.title && <Typography sx={{ color: "#f44336" }}>{facilityDetailsError.title}</Typography>}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <StyledTypography>Description *</StyledTypography>
                    <textarea minRows={4} maxRows={8}  onChange={(e)=>handleFacilityDetails(e)} name="description" value={facilityDetails.description}  sx={{'&:focus': {borderColor: 'lightblue'}}}   style={{minWidth:'100%',maxWidth:'100%',height:100,marginTop:'5px',fontSize:'15px',border:'1px solid #DBDFE9'}}/>
                    {facilityDetailsError.description && <Typography sx={{ color: "#f44336" }}>{facilityDetailsError.description}</Typography>}
                </Grid>
                <Grid item xs={6} sm={6} sx={{ mt: 1, pl: 1, display: 'flex', gap: '10px' }}>
                    <Box sx={{width:'30%'}}>
                    <StyledTypography>Level *</StyledTypography>
                    <FormControl sx={{width:"100%"}}>
                        <Select MenuProps={MenuProps} onChange={(e)=>handleFacilityDetails(e)} name="officeLevel" sx={{ mr:3,color:'#0F2C6F'}} value={facilityDetails.officeLevel}>
                        {
                           [1,2,3,4,5,6,7,8,9,0].map((value, index) => {
                               return <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>
                                })
                        }
                        </Select>
                        </FormControl>
                    {facilityDetailsError.officeLevel && <Typography sx={{ color: "#f44336" }}>{facilityDetailsError.officeLevel}</Typography>}
                    </Box>
                {/* </Grid> */}
                {/* <Grid item xs={6} sm={4} sx={{ mt: 1, pl: 1 }}> */}
                <Box sx={{width:'70%'}}>
                    <StyledTypography>Unit *</StyledTypography>
                    <FormControl sx={{width:"100%"}}>
                        <Select MenuProps={MenuProps} onChange={(e)=>handleFacilityDetails(e)} name="unit" sx={{color:'#0F2C6F'}} value={facilityDetails.unit}>
                        {
                           [1,2,3,4,5,6,7,8,9,0].map((value, index) => {
                               return <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>
                                })
                        }
                        </Select>
                        </FormControl>
                    {facilityDetailsError.unit && <Typography sx={{ color: "#f44336" }}>{facilityDetailsError.unit}</Typography>}
                    </Box>
                    <Box
                      sx={{
                        width: '15%',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems:'center'
                      }}
                    >
                      <RoundedPlusIcon
                        cursor='pointer'
                        onClick={() => handleSelectedUnits()}
                      />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={4} sx={{ mt: 1, pl: 1 }}>
                <InputLabel
                  sx={{
                    fontSize: { md: '14px', xs: '12px' },
                    fontWeight: 700,
                    marginBottom: '10px',
                    color: '#0F2C6F',
                   
                  }}
                >
                  Selected Units
                </InputLabel>
                {facilityDetailsError.addUnit && <Typography sx={{ color: "#f44336" }}>{facilityDetailsError.addUnit}</Typography>}
                <Grid
                  container
                  sx={{
                    gap: '10px',
                  }}
                >
                  {facilityDetails?.selectedUnits?.map((item, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={item}
                        key={index}
                        onDelete={() => handleDelete(item,index)}
                        sx={{
                          color: '#fff',
                          background: '#123FA9',
                          '& .MuiChip-deleteIcon': {
                            color: '#fff',
                            '&:hover': {
                              color: '#B0B0B0',
                            },
                          },
                          fontSize: { md: '14px', xs: '10px' },
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                </Grid>
                <Grid item xs={12} sm={12} sx={{ mt: 1, pl: 1 }}>
                    <StyledTypography>Maximum People Allowed</StyledTypography>
                    <StyledOutlinedInput onChange={(e)=>handleFacilityDetails(e)} name="people_allowed" value={facilityDetails.people_allowed} />
                    {/* {facilityDetailsError.people_allowed && <Typography sx={{ color: "#f44336" }}>{facilityDetailsError.people_allowed}</Typography>} */}
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>  
                    <Typography sx={{ fontSize: "14px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
                        Opening Hours
                    </Typography>
                    <FormControlLabel sx={{color:'#0F2C6F'}} control={
                        <Checkbox icon={<CheckboxIcon />} checked={facilityDetails?.sameHoursApplied} name="everyDayHours" onClick={() => handleSameHours('sameHoursApplied')} />
                    }
                        label="Same hours everyday" />
                </Grid>
                {dailyDatas.map((item, index) => (
                    <Grid xs={12} sx={{ mt: 3, display: 'flex', justifyContent: 'start' }}>
                        <Grid>
                            <Typography sx={{ fontSize: "14px", fontWeight: "550", color: '#0F2C6F', fontFamily: 'Inter',textTransform:'capitalize' }}>
                                {item.day}
                            </Typography>
                            <FormControlLabel sx={{color:'#0F2C6F'}} control={
                                <Checkbox icon={<CheckboxIcon />} checked={item.isDisable} name={item.day} onClick={() => handleWeeKdaysSelection(item.id)} />
                            }
                                label={<Typography sx={{ fontSize: '14px', fontFamily: 'Inter' }}>Closed Today</Typography>} />
                        </Grid>
                        <Grid sx={{ml:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:550}}>From</Typography>
                            <Select sx={{width:100}} MenuProps={MenuProps} disabled={item.isDisable} value={item.isDisable ? '' :item.FromTime}  onChange={(newValue) => handleDailyTime(newValue, `FromTime`,item.id)}>
                                {startHours?.map((value)=>(
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))
                                }
                            </Select>      
                            <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                                <br /> {facilityDetailsError[`eventStartTime${item.day}`]}
                            </Typography>                    
                        </Grid>
                        <Grid sx={{ml:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:550}}>To</Typography>
                            <Select sx={{width:100}} MenuProps={MenuProps} disabled={item.isDisable} value={item.isDisable ? '' :item.toTime}  onChange={(newValue) => handleDailyTime(newValue, `toTime`,item.id)}>
                                {startHours?.map((value)=>(
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))
                                }
                            </Select>
                            <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                                <br /> {facilityDetailsError[`eventEndTime${item.day}`]}
                            </Typography>
                            <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                                {facilityDetailsError[`validate${item.day}`]}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
                
            </Grid>
            </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1} >
                <Box sx={{p:3}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:'18px',fontWeight:500, mb:3}}>Unique Features</Typography>
                    <Box >
                    {uniqDetails?.map((item, index) => (
                        <Box key={index} sx={{border:'1px solid #E5E7EB', padding:'10px', borderRadius:'12px', mb:3}}>
                        <Box sx={{display:'flex',justifyContent:'space-between'}}>
                            <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500, mb:3}}>Unique Feature {index + 1}</Typography>
                            <IconButton tabIndex={"-1"} onClick={() => removeUniqFeature(index)}><DoNotDisturbOn sx={{ height: "20px", width: "20px", marginRight: "6px", fill: '#FF5D5D', cursor: 'pointer' }} /></IconButton>
                        </Box>
                        <Grid container rowSpacing={2} >
                        <Box style={{ display: 'flex', mb:3 }}>
                            {options.map((option) => (
                                <Box key={option.value}
                                    onClick={() => handleIconClick(option.value, 'uniq_icon', index)}
                                    style={item.uniq_icon === option.value ? selectedImageStyles : imageStyles}
                                    >
                                    <Box>
                                        <img size="sm" src={option.src} />
                                    </Box>
                                </Box>
                            ))}
                        </Box><br/>
                        <Grid item xs={12} sm={12}>{uniqDetailsError[index].uniq_icon && <Typography sx={{display:'block', color: "#f44336" }}>{uniqDetailsError[index].uniq_icon}</Typography>}   </Grid>
                            <Grid item xs={12} sm={12}>
                                <StyledTypography>Title *</StyledTypography>
                                <StyledOutlinedInput onChange={(e)=>handleUniqDetailsDetails(e, index)} name="uniq_title" value={uniqDetails[index].uniq_title} />
                                {uniqDetailsError[index].uniq_title && <Typography sx={{ color: "#f44336" }}>{uniqDetailsError[index].uniq_title}</Typography>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <StyledTypography>Description *</StyledTypography>
                                <textarea minRows={4} maxRows={8}
                                  onChange={(e)=>handleUniqDetailsDetails(e, index)} name="short_description" value={uniqDetails[index].short_description}  sx={{'&:focus': {borderColor: 'lightblue'}}}   style={{minWidth:'100%',maxWidth:'100%',height:100,marginTop:'5px',fontSize:'15px',border:'1px solid #DBDFE9'}}/>
                                {uniqDetailsError[index].short_description && <Typography sx={{ color: "#f44336" }}>{uniqDetailsError[index].short_description}</Typography>}
                            </Grid>
                        </Grid>
                        </Box>
                    ))}           
                        

                    </Box>

                    <Box>
                        <Button variant='contained' sx={{textTransform:'capitalize',color:'#FFFFFF',borderRadius:'8px',height:'48px',backgroundColor:'#123FA9','&:hover':{backgroundColor:'#123FA9'}}} onClick={()=>addNewUniqFeature()}>Add New Feature</Button>
                    </Box>
                    <Box sx={{mt:6}}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'18px',fontWeight:500, mb:3}}>Facility Images</Typography>
                        <Grid container rowSpacing={2} >
                            <Grid item xs={12} >
                                    {featuredImages.length === 0 ? <DropZone onlyImage={true} getFiles={handleFeaturedImages} /> :
                                        <>
                                            {featuredImages.map((value,index) => (
                                                <Box key={index} sx={{ border: '1px solid #DBDFE9', borderRadius: '8px', display: 'flex', justifyContent: 'space-between', height: '48px', p: 1, mt: 1 }}>
                                                    <Typography>{value.fileName}</Typography>
                                                    <CloseIcon onClick={() => handleImageDelete(value.id)} />
                                                </Box>
                                            ))}
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Button variant="outlined" component='label' sx={{ textTransform: 'capitalize', mt: 1 }} endIcon={<UploadIcon />}>
                                                    <input style={{ display: 'none' }} multiple type='file' accept="image/*" onChange={(e) => handleUploadButton(e)} />
                                                    Upload</Button>
                                            </Box>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                    </Box>
                    <Box sx={{mt:6, mb:6}}>
                        <Typography sx={{color:'#0F2C6F',fontSize:'18px',fontWeight:500, mb:3}}>Terms & Conditions</Typography>
                        <ReactQuill value={termsCondition} onChange={setTermsConditions} modules={module} theme='snow'
                         style={{height:'150px'}}
                         placeholder='Enter the terms and conditions for the facility usage.' />
                    </Box>
                </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
            <ReviewInformation termsCondition={termsCondition} featuredImages={featuredImages} uniqDetails={uniqDetails} innerTabValue={innerTabValue} facilityDetails={facilityDetails} setInnerTabValue={setInnerTabValue}/>
            </TabPanel>
        </Box>
        <Box sx={{display:'flex',justifyContent:'flex-end',py:5}}>
                <Box>
                    {/* <Button variant='outlined' sx={{textTransform:'capitalize',color:'#0F2C6F',borderRadius:'8px',height:'48px',mr:2}}>Delete</Button> */}
                    <Button variant='contained' sx={{textTransform:'capitalize',color:'#FFFFFF',borderRadius:'8px',height:'48px',backgroundColor:'#123FA9','&:hover':{backgroundColor:'#123FA9'}}} onClick={()=> {
                        if(tabValue == 0) {
                            handleContinueButton()
                        } else if(tabValue ==1) {
                            handleTab2ContinueBtn()
                        } else if(tabValue == 2) {
                            handlSubmit();
                        }
                        
                        }}>Continue</Button>
                </Box>
        </Box>  
        </Grid>
        <Grid item xs={1} />
    </Grid>
  )
}

const TabPanel = (props)=>{
    const {children,value,index} =props
    return(
        <>
        {
        value===index && 
        <>{children}</>
        }
        </>
    ) 
}