import React, { useEffect, useState } from 'react';
import PropTypes, { array, string } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as RoundedPlusIcon } from '../../Assets/icons/RoundedPlusIcon.svg';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import { useTheme } from '@mui/styles';
import { ReactComponent as Recent } from '../../Assets/icons/Recent.svg';
import { ReactComponent as ModalCloseButton } from '../../Assets/icons/ModalCloseButton.svg';
import styled from '@emotion/styled';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import * as commonAction from '../../redux/actions/commonAction';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Faultalert from '../common/faultalert';
import { getLastUpdatedTime, getUTCDateFormat, preventDatePickerKeyboard } from '../../Utils/utils';
import ToastAlert from '../common/Alert';
import moment from 'moment';
import Loader from '../common/Loader';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography variant='p'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TAB_NAMES = [
  {
    id: 1,
    name: 'Details',
  },
  {
    id: 2,
    name: 'Main Contacts',
  },
  {
    id: 3,
    name: 'Review',
  },
];

const LEVEL = [11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
const UNIT = ['All Units', 567, 568, 569, 570, 571, 572, 573, 574, 575, 576];

function getStyles(UNIT, accessUnit, theme) {
  return {
    fontWeight:
      accessUnit.indexOf(UNIT) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const OnboardingCompanyTabs = ({ addTenant, tenantAddStatus, levelDatas, getAllLevels, unitDatas, getUnitsByLevelid, setStatusNull, getTenantDetailsById, tenantDetails, tenantUpdateStatus, updateTenant }) => {

  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('type');
  const routeArr = location.pathname.split("/");
  
  const StyledInputLabel = styled(InputLabel)(() => ({
    color: '#6F80A9',
    fontSize: '14px',
  }));

  const [level, setLevel] = useState([]);
  const [unit, setUnit] = useState([]);
  const [alertStatus, setAlertStatus] = useState(false)

  const [companyUnits, setCompanyUnits] = useState([])
  const [specifyUnits, setSpecifyUnits] = useState([])
  const [managerUnits, setManagerUnits] = useState([])
  const [mainadmin, setMainAdmin] = useState([])
  const [financemanager, setFinanceManager] = useState([])
  const [time, setTime] = useState({ hour: '00', minute: '00', am_pm: '',timeStr:'' })
  const [mobileNumbers, setMobileNumbers] = useState({officeNo:"", oneMobileNo:'',oneOfficeNo:'',twoMobileNo:'',twoOfficeNo:""})

  const [firstemp,setFirstEmp] = useState([]);
  const [secondemp,setSecondEmp] = useState([]);
  const [thirdemp,setThirdEmp] = useState([]);

  const [snackbar, setSnackbar] = React.useState({
    status: false,
    type: 'error',
    message: '',
  });

  const [validForm, setValidForm] = React.useState();
  const [keyPress, setkeyPress] = React.useState(false);
  const [snackBarKeyPress, setSnackBarKeyPress] = React.useState(false);
  const [alertData, setAlertData] = useState({ label: 'Delete Draft', description: 'Are you sure want delete this draft', buttonvalue: 'Delete' })

  const [accessLevel,setAccessLevel] = useState([]);
  const [accessUnit,setAccessUnit] = useState([]);


  const detailsIntialState = {
    companyName: '',
    email: '',
    officeNo: '',
    officeNoCode: '',
    level: '',
    unit: '',
    accessLevel: '',
    accessUnit: "",
    selectedUnits: [],
    unitsToSubmit: [],
    receptionUnit:[]
  };


  const [detailsData, setDetailsData] = React.useState(detailsIntialState);
  const [detailsDataError, setDetailsDataError] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const initialFirstContact = {
    oneFirstName: '',
    oneLastName: '',
    oneEmail: '',
    oneJob: '',
    oneMobileNo: '',
    oneMobileCode:'',
    oneOfficeNo: '',
    oneOfficeCode:'',
    oneAccessStartDate: '',
    oneLevel: '',
    oneUnit: '',
    role: 'Tenant Manager',
  };

  const intialSecondContact = {
    twoFirstName: '',
    twoLastName: '',
    twoEmail: '',
    twoJob: '',
    twoMobileNo: '',
    twoMobileCode:'',
    twoOfficeNo: '',
    twoOfficeCode:'',
    twoAccessStartDate: '',
    twoLevel: '',
    twoUnit: '',
    role: 'Main Administrator',
  };

  const intialThirdContact = {
    threeFirstName: '',
    threeLastName: '',
    threeEmail: '',
    threeJob: '',
    threeMobileNo: '',
    threeMobileCode:'',
    threeOfficeNo: '',
    threeOfficeCode:'',
    threeAccessStartDate: '',
    threeLevel: '',
    threeUnit: '',
    role: 'Finance Manager',
  };

  const [firstContact, setFirstContact] = React.useState(initialFirstContact);

  const [secondContact, setSecondContact] = React.useState(intialSecondContact);

  const [thirdContact, setThirdContact] = React.useState(intialThirdContact);

  const [contactErrors, setContactError] = React.useState({});

  const [submissionData, setSubmissionData] = React.useState({
    company_name: '',
    email: '',
    phone_no: '',
    phone_code: '',
    level_no: '',
    unit_no: [],
    employeeDataList: [],
  });

  const [checkBox, setCheckBox] = React.useState({
    checked: false,
    checkBoxName: '',
  });

  const [detailsCheckBox, setDetailsCheckBox] = React.useState({
    name: '',
    isChecked: '',
  });
  const [levelType, setLevelType] = useState('')
  const [loaderStatus,setLoaderStatus] = useState(false)
  const [isUnique,setIsUnique] = useState({})
  const [tabDisabled,setTabDisabled] = useState({tab2:true,tab3:true})
  const [allUnits,setAllUnits] = useState({
    level:[],
    unit:[]
  }) 

  const checkEditRoute = () => routeArr.includes("update_onboard_tenant_company") ? true : false;
 
  React.useEffect(() => {
    if (tenantAddStatus && snackBarKeyPress) {
      setLoaderStatus(false)
      setSnackbar({
        message: tenantAddStatus,
        color:tenantAddStatus=='Company Added Successfully' ? "green" : "red",
        status: true,
      });
      setTimeout(() => {
        setSnackbar({ status: '',color: '', message: '' });
        setSnackBarKeyPress(false);
      }, 5000);
      if (tenantAddStatus === 'Company Added Successfully') {
        setTimeout(() => {
          navigate('/onboard_new_tenant/0/1');
        }, 5000);
      }
      setStatusNull()
    }
  }, [tenantAddStatus, snackBarKeyPress]);

  React.useEffect(() => {
    if (keyPress) {
      let errorName;
      if (Object.keys(detailsDataError).length > 0) {
        errorName = document.getElementsByName(
          Object.keys(detailsDataError)[0]
        )[0];
      } else if (Object.keys(contactErrors).length > 0) {
        errorName = document.getElementsByName(
          Object.keys(contactErrors)[0]
        )[0];
      }
      errorName?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    setkeyPress(false);
  }, [keyPress]);

  useEffect(() => {
    setTime({...time,timeStr:getLastUpdatedTime()})
    getAllLevels()

    if(query === "edit" && checkEditRoute()){
      let data = {
        company_id: Number(params.update_tenant_id)
      }
      getTenantDetailsById(data,localStorage.getItem('userToken'))      
     }else{
       setDetailsData(detailsIntialState);
     }

  }, [])


  useEffect(() => {
     if(tenantDetails && tenantDetails?.companiesArrayList && levelDatas?.length > 0){     
    handleUpdateTenantCompany(tenantDetails);
    }else if(tenantDetails?.error_message){
      navigate("/onboard_new_tenant_company")
   }
  }, [tenantDetails, levelDatas])
  

  useEffect(() => {
    setLevel(levelDatas)

  }, [levelDatas])

 
  useEffect(() => {


    if (unitDatas.length > 0) {
      if (levelType === 'companyLevel') {
        setCompanyUnits(unitDatas)
      }
      else if (levelType === 'managerUnits') {
        setManagerUnits(unitDatas)
      }
      else if (levelType === 'mainadmin') {
        setMainAdmin(unitDatas)
      }
      else if (levelType === 'financemanager') {
        setFinanceManager(unitDatas)
      }
      else if (levelType === 'specificLevel') {
        setSpecifyUnits(unitDatas)
      
      }
    }else{
      setSpecifyUnits([])
    }

  }, [unitDatas])

  useEffect(() => {
     if (tenantUpdateStatus?.response_status === 1) {
      // setLoaderStatus(false)
      setSnackbar({
        message: tenantUpdateStatus?.message,
        color:tenantUpdateStatus?.response_status== 1 ? "green" : "red",
        status: true,
      });
      setTimeout(() => {
        setSnackbar({ status: '',color: '', message: '' });
        setSnackBarKeyPress(false);
      }, 4500);
      if (tenantUpdateStatus?.response_status === 1) {
        setTimeout(() => {
          navigate('/onboard_new_tenant/0/0');
        }, 5000);
      }
      setStatusNull()
    }else if(tenantUpdateStatus?.response_status === 0){
       setSnackbar({
        message: tenantUpdateStatus?.message,
        color:tenantUpdateStatus?.response_status== 1 ? "green" : "red",
        status: true,
      });
      setTimeout(() => {
        setSnackbar({ status: '',color: '', message: '' });
        setSnackBarKeyPress(false);
      }, 3000);
    }
  }, [tenantUpdateStatus, snackBarKeyPress])

  // useEffect(()=>{
  //   if(unitDatas.length>0){
  //     setAccessUnit(unitDatas)
  //   }
  // },[unitDatas])

  const handleUpdateTenantCompany = (data) => {
    
    if(checkEditRoute() === false){
      setDetailsData(detailsIntialState);
      return;
    }

    let {company_name, email, country_code, phone_no} = data.companiesArrayList[0];

    let tempReceptionUnit = [];
    let selUnits = [];
    let unitArrList = data.assignedUnitsArrayList;
    let empArrList = data.employeesArrayList;
     let levelArr = [];
    let levelArrCheck = [];
    let modifiedArr = [];
    let defaultValue = {
      unit_name: "",
      unit_id: "",
      level_name: ""
    };
     let unitList = [];
  
     for(const ele of unitArrList){
      selUnits.push(`#${ele.level_name}-${ele.unit_name.includes('(default)') ? ele.unit_name.replace('(default)', ''): ele.unit_name}`);

      if(ele.unit_name.includes('(default)')){
        defaultValue['unit_name'] = ele.unit_name.replace('(default)', '');
        defaultValue['level_name'] = ele.level_name;
        defaultValue['unit_id'] = ele.unit_id;
      }

      let levelObj = levelDatas.filter(item => item.level_name == ele.level_name)[0];
      if(levelArrCheck.includes(ele.level_name) === false){
        levelArrCheck.push(ele.level_name);
        modifiedArr.push(levelObj) 
      }
      tempReceptionUnit.push({level: levelObj, name: ele.unit_name.replace('(default)', ''), unit: ele.unit_id});      
       if(unitList.includes(ele.unit_id) === false){
        unitList.push(ele.unit_id);
      }
     }     

     setAccessLevel(modifiedArr)
 
     for(const ele of empArrList){
       if(levelArr.includes(ele.level_no) === false){
        levelArr.push(ele.level_no);
       }
      // tempReceptionUnit.push({level: levelObj,name: ele.unit_name, unit: Number(ele.unit_no)})
     }

 
     setMobileNumbers((prev) => ({
      ...prev,
      ['officeNo']: `${country_code} ` + phone_no
    }))
    setDetailsData(prevValue => {
      return {...prevValue, companyName: company_name, email: email, officeNoCode: country_code, officeNo: country_code + phone_no, selectedUnits: selUnits, receptionUnit: tempReceptionUnit, unitsToSubmit: unitList }
    })     

    let defaultLevel = levelDatas.filter(item => item.level_name === defaultValue.level_name)[0];
    let defaultUnit = {level: defaultLevel, unit: defaultValue.unit_id, name: defaultValue.unit_name};

    // setDetailsData(pervValue => {
    //   return {...prevValue}
    // })
    // handleReceptionLevels({target: {value: defaultLevel, name: "level"}}, 'companyLevel');
    setDetailsData(prevValue => {
      return {...prevValue, level: defaultLevel}
    })
    let tempAccessUnitList = tempReceptionUnit.filter(item => item?.level?.level_id === defaultLevel?.level_id);
    setAccessUnit(tempAccessUnitList);
    
  
      setDetailsData(prevValue => {
        return {...prevValue, unit: defaultUnit.unit};
      })  
    
  }

 
  const checkValidEdit = () => {
    if(checkEditRoute() && tenantDetails?.companiesArrayList && unitDatas.length > 0){
      return true;
    }else{
      return false;
    }
  }

  const checkDefaultUnit = (unit) => {  
    const {level, name} = detailsData?.unit;
    let str = `#${level?.level_name}-${name}`;
    console.log(str);
    return unit === str ? true : false;

  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };
console.log('firsts ',firstContact)
  const handleFirstContactInput = (e, lvlType,country) => {
    console.log('country ',country)
    setIsUnique({})
    setTime({...time,timeStr:getLastUpdatedTime()})
    const { name, value } = e.target;
    

    if (lvlType) {
      setLevelType(lvlType)
    }

    if (value) {
      setContactError((prevValue) => ({ ...prevValue, [`${name}`]: '' }));
    }

    if(name==='oneMobileNo'){
      console.log('oneMobileNo ',value)
      setFirstContact((prevValue) => ({
        ...prevValue,
        [name]: value,
        oneMobileCode:country.dialCode,
      }));
    } else if(name==='oneOfficeNo') {
      setFirstContact((prevValue) => ({
        ...prevValue,
        [name]: value,
        oneOfficeCode:country.dialCode
      }));
    }else{
      setFirstContact((prevValue) => ({
        ...prevValue,
        [name]: value,
        
      }));
    }

 };

  const handleMobileNoInput = (value, name,country, event) => {
    let e = { target: { name: name, value: value } };
    if(name == "oneMobileNo" || name == "oneMobileNo" || name == 'oneOfficeNo' || name == "twoMobileNo" || name == "twoOfficeNo") {
      setMobileNumbers((prev) => ({
        ...prev,
        [name]: event.target.value
      }))
    }
    if (name.substring(0, 3) === 'one') {
      handleFirstContactInput(e,'',country);
    } else if (name.substring(0, 3) === 'two') {
      handleSecondContactInput(e,'',country);
    } else if (name.substring(0, 3) === 'thr') {
      handleThirdContactInput(e,'',country);
    }
  };

  const handleOneDateInput = (value) => {
    let formattedDate = formatDate(value);

    let e = {
      target: { name: 'oneAccessStartDate', value: formattedDate.toString() },
    };
    handleFirstContactInput(e);
  };

  const handleSecondContactInput = (e, lvlType,country) => {
    setTime({...time,timeStr:getLastUpdatedTime()})
    const { name, value } = e.target;
    if (lvlType) {
      setLevelType(lvlType)
    }

    if (value) {
      setContactError((prevValue) => ({ ...prevValue, [`${name}`]: '' }));
    }

    if(name==='twoMobileNo'){
      setSecondContact((prevValue) => ({
        ...prevValue,
        [name]: value,
        twoMobileCode:country.dialCode,
      }));
    } else if(name==='twoOfficeNo') {
      setSecondContact((prevValue) => ({
        ...prevValue,
        [name]: value,
        twoOfficeCode:country.dialCode
      }));
    }else{
      setSecondContact((prevValue) => ({
        ...prevValue,
        [name]: value,
      
      }));
    }

  };
  
  const handleTwoDateInput = (value) => {
    let formattedDate = formatDate(value);

    let e = {
      target: { name: 'twoAccessStartDate', value: formattedDate.toString() },
    };
    handleSecondContactInput(e);
  };

  const handleThirdContactInput = (e, lvlType,country) => {
    setTime({...time,timeStr:getLastUpdatedTime()})
    const { name, value } = e.target;
    if (lvlType) {
      setLevelType(lvlType)
    }

    if (value) {
      setContactError((prevValue) => ({ ...prevValue, [`${name}`]: '' }));
    }

    if(name==='threeMobileNo' || name==='threeOfficeNo'){
      setThirdContact((prevValue) => ({
        ...prevValue,
        [name]: value,
        threeMobileCode:country.dialCode,
        threeOfficeCode:country.dialCode
      }));
    }else{
      setThirdContact((prevValue) => ({
        ...prevValue,
        [name]: value,
       
      }));
    }
   
};

  const handleThreeDateInput = (value) => {
    let formattedDate = formatDate(value);

    let e = {
      target: { name: 'threeAccessStartDate', value: formattedDate.toString() },
    };
    handleThirdContactInput(e);
  };

  const handleChange = (event, newValue) => {
    if (value === 2 && validateMainContacts()) {
      setValidForm(true);
    } else if (value === 1) {
      validateDetails();
    }
    setValue(newValue);
  };

  const handleUnitChange = (event) => {

     const { value, name } = event.target;

    setDetailsData({
      ...detailsData,accessUnit:value
    })

    setDetailsDataError({...detailsDataError,[name]:''})
 
  };


 const handleSelectedUnits = () => {
  if(detailsData.accessUnit.unit_id=="0"){
    setAllUnits({
      ...allUnits,
      level:detailsData.accessLevel?.level_id,
      unit:detailsData.unit
    })
  }
  console.log('detailsData ',detailsData);

    handleReceptionLevel()
  for (let k = 0; k < detailsData.accessUnit.length; k++) {
      detailsData.unitsToSubmit.push(detailsData.accessUnit[k].unit_id)
    }
    if (
      detailsData.accessUnit.length === 0 ||
      detailsData.accessLevel.length === 0
    ) {
      setDetailsDataError({
        ...detailsDataError,
        selectedUnits: 'Invalid Input',
      });
      return;
    } else {
      if (detailsData.selectedUnits.length==0) {

        for (let i = 0; i < detailsData.accessUnit.length; i++) {
          setDetailsData({
            ...detailsData,
            receptionUnit:[...detailsData.receptionUnit,{level:detailsData.accessLevel.level_name,unit:detailsData.accessUnit.unit_id,name:detailsData.accessUnit.unit_name
            }],
            selectedUnits: [...detailsData.selectedUnits,
            { name: `# ${detailsData.accessLevel.level_name} - ${detailsData.accessUnit[i].unit_name} ` }
            ]
          })
          detailsData.unitsToSubmit.push(detailsData.accessUnit.unit_id)
        }

        setDetailsData({
          ...detailsData,
          receptionUnit:[...detailsData.receptionUnit,{level:detailsData.accessLevel,unit:detailsData.accessUnit.unit_id,name:detailsData.accessUnit.unit_name}],
          selectedUnits: [
            ...detailsData.selectedUnits,
            `#${detailsData.accessLevel.level_name}-${detailsData.accessUnit.unit_name}`,
          ],
        });
        detailsData.unitsToSubmit.push(detailsData.accessUnit.unit_id)
      }
      else {
        let isMatched = false;
        for (var i = 0; i < detailsData.selectedUnits.length; i++) {
          if (detailsData.selectedUnits[i] === `#${detailsData.accessLevel.level_name}-${detailsData.accessUnit.unit_name}`) {
            isMatched = true;
            break;
          }
        }
        if (!isMatched) {
          setDetailsData({
            ...detailsData,
            receptionUnit:[...detailsData.receptionUnit,{level:detailsData.accessLevel,unit:detailsData.accessUnit.unit_id,name:detailsData.accessUnit.unit_name}],
            selectedUnits: [
              ...detailsData.selectedUnits,
              `#${detailsData.accessLevel.level_name}-${detailsData.accessUnit.unit_name}`,
            ],
          });
          detailsData.unitsToSubmit.push(detailsData.accessUnit.unit_id)
        }
      }

      setDetailsDataError({ ...detailsDataError, selectedUnits: '' });
    }

}

const handleDelete = (item,index) => {

  handleReceptions(item)
    let updatedArray = detailsData.selectedUnits.filter(
          (data) => data !== item
    );

    let units = detailsData.unitsToSubmit;

    let splitData = item.split("-");

     let deletedLevelId = splitData[0].replace("#", '');
    let deletedUnitId = detailsData.receptionUnit.filter(item => item.level?.level_id === Number(deletedLevelId) && item.name === splitData[1])[0]?.unit;    
    
    let newReceptionUnit = detailsData.receptionUnit.filter(item => item.unit !== deletedUnitId )
     let newArray = [...units.slice(0,index),...units.slice(index+1)]
    setAccessUnit([]);
    setDetailsData({
      ...detailsData,
      level: "",
      unit: "",
      unitsToSubmit:[...newArray],
      selectedUnits: [...updatedArray],
      receptionUnit: newReceptionUnit
    })
} 

const handleReceptions = (item) =>{
      let levelWithHash=item.split("-");
      let newlevels=levelWithHash[0].slice(1,levelWithHash[0].length);
      let levelCount=0;

      //filtering the access level
      for(var i=0;i<detailsData.selectedUnits.length;i++){
        let currLvlWithHash=detailsData.selectedUnits[i].split("-");
        let currLevel=currLvlWithHash[0].slice(1,currLvlWithHash[0].length);
        if(newlevels===currLevel){
          levelCount+=1
        }
      }
      if(levelCount===1){
          var filteredArray = accessLevel.filter(e => e.level_name != parseInt(newlevels))
        setAccessLevel([...filteredArray])
      }

      let newReceptionUnit=[]
      for(var j=0;j<detailsData.receptionUnit.length;j++){
        if(parseInt(levelWithHash[1])===parseInt(detailsData.receptionUnit[j].unit)){
        continue;
        }else{
          newReceptionUnit.push(detailsData.receptionUnit[j])
        }
      }

      setDetailsData({
        ...detailsData,
        receptionUnit:[...newReceptionUnit]})

        //filtering the access unit

        let newUnit = levelWithHash[1].slice(0,levelWithHash[1].length);
        let unitCount =0;
            var filteredArray = detailsData.receptionUnit.filter(e => e.name != newUnit)
            setDetailsData({
              ...detailsData,
              receptionUnit:[...filteredArray]
            })
            setAccessUnit([...filteredArray])
                         
}

const handleReceptionLevels=(e,levelType)=>{
    let { name, value } = e.target;
     setDetailsDataError({...detailsDataError,[name]:''})
        if(levelType=='first'){
            setFirstContact({
              ...firstContact,
              oneLevel:value
            })
            let arr=[];
            for(var i=0;i<detailsData.receptionUnit.length;i++){
                if(detailsData.receptionUnit[i].level===value){
                arr.push(detailsData.receptionUnit[i])
                }
                setFirstEmp([...arr])
              }
        }

        if(levelType=='second'){
          setSecondContact({
            ...secondContact,
            twoLevel:value
          })
          let arr=[];
          for(var i=0;i<detailsData.receptionUnit.length;i++){
              if(detailsData.receptionUnit[i].level===value){
              arr.push(detailsData.receptionUnit[i])
              }
              setSecondEmp([...arr])
            }
      }

      if(levelType=='third'){
        setThirdContact({
          ...thirdContact,
          threeLevel:value
        })
        let arr=[];
        for(var i=0;i<detailsData.receptionUnit.length;i++){
            if(detailsData.receptionUnit[i].level===value){
            arr.push(detailsData.receptionUnit[i])
            }
            setThirdEmp([...arr])
          }
    }

    setDetailsData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
 
    if(checkEditRoute() && name === 'level'){
      let tempArr = detailsData.receptionUnit.filter(item => Number(item.level?.level_id) === Number(value?.level_id));
       setAccessUnit([...tempArr])
    }else{

      let arr=[];
      for(var i=0;i<detailsData.receptionUnit.length;i++){
          if(detailsData.receptionUnit[i].level===value){  
         
          arr.push(detailsData.receptionUnit[i])
          }
      }
            setAccessUnit([...arr])
    }
  }


  const handleReceptionUnits = (e)=>{
    let {name,value} = e.target;
    setDetailsData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));

    setDetailsDataError({...detailsDataError,[name]:''})
  }


  const handleDetailsInput = (e, lvlType) => {
    console.log('ee ',e)
    setTime({...time,timeStr:getLastUpdatedTime()})
    let { name, value } = e.target;
    if (lvlType) {
      setLevelType(lvlType)
    }

    if (levelType === 'specificLevel' && name === 'accessLevel') {
      setDetailsData((prevValue) => ({
        ...prevValue,
        ['accessUnit']: [],
      }));
    }

    if (value) {
      setDetailsDataError({ ...detailsDataError, [`${name}`]: '' });
    }

    setDetailsData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));


    for(let i=0;i<detailsData.receptionUnit.length;i++){
        if(detailsData.receptionUnit.level===accessLevel[i]){
          setCompanyUnits([...companyUnits,detailsData.receptionUnit[i]])
        }
    }
};

const handleReceptionLevel = ()=>{
      let isMatched = false;
        if(accessLevel.length === 0){
          isMatched=true;
          setAccessLevel([...accessLevel,detailsData.accessLevel])
        }else{
          for(let i =0;i<accessLevel.length;i++){
            if(detailsData.accessLevel===accessLevel[i]){
              isMatched=true;
              break;
            }else{
              isMatched=false;
            }
          }
        }

      if(!isMatched){
        setAccessLevel([...accessLevel,detailsData.accessLevel])
      }
}



 const handleMobileInput = (value, country, name,event,format) => {
    console.log('eventevent ',event.target.value)
    if(name == "officeNo") {
      setMobileNumbers((prev) => ({
        ...prev,
        [name]: event.target.value
      }))
    }
    let e = { target: { name: name, value: value } };
    setDetailsData((prevValue) => ({
      ...prevValue,
      [`${name}Code`]: `+${country.dialCode}`,
      
    }));
    handleDetailsInput(e);

  };

  let firstTimeFlag = false; 

  const validateDetails = () => {

    let noError = true;
    let errorObj = {};
    const userNameRegex = /^[a-zA-Z_. ]{4,}$/;
    const mobRegex = /^[0]?[65]\d{9}$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (let [key, values] of Object.entries(detailsData)) {
      if (values.length === 0) {
         if(checkEditRoute() && detailsData.selectedUnits.length > 0  && (key === 'accessLevel' || key === "accessUnit")){
          }else{
          errorObj[`${key}`] = 'Should not be empty!';
          noError = false;
        }
      } else if (
        key === 'companyName' &&
        values.match(userNameRegex) === null && values.length < 4
      ) {
        errorObj[`${key}`] = 'Company name must be at least 4 characters';
        noError = false;
      } else if (key === 'email' && values.match(emailRegex) === null) {
        errorObj[`${key}`] = 'Invalid email address';
        noError = false;
      }
    }
    setDetailsDataError(errorObj);
     return noError;
  };

  const validateMainContacts = () => {
    let errorObj = {};
    const userNameRegex = /^[a-zA-Z_ ]*$/;
    const firstnameRegex = /^[a-zA-Z_. ]{3,}([a-zA-Z+ ?])$/;
    const mobRegex = /^[0]?[65]\d{9}$/;

    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    let noError = true;
    for (let [key, values] of Object.entries(firstContact)) {
    if(key === 'oneFirstName' && (values.match(firstnameRegex)===null)  ) {
        errorObj[`${key}`] = "Must be atleast 3 characters";
        noError = false;
      } else if (
        ( key === 'oneLastName') &&
        values.match(userNameRegex) === null
      ) {
        errorObj[`${key}`] = `Invalid ${key.substring(3, key.length)} Input`;
        noError = false;
      } else if (key === 'oneEmail' && values.match(emailRegex) === null) {
        errorObj[`${key}`] = 'Invalid Email';
        noError = false;
      } 
      // else if (
      //   (key === 'oneMobileNo' ) &&
      //   values.match(mobRegex) === null
      // ) {
      //   errorObj[`${key}`] = 'Invalid Number';
      //   noError = false;
      // } 
      else if(key=="oneJob" && value.length<0){
        errorObj[`${key}`] = 'Should not be empty';
        noError = false;
      } 
    }
    for (let [key, values] of Object.entries(secondContact)) {
    if(key === 'twoFirstName' && values.match(firstnameRegex)===null){
        errorObj[`${key}`] ="Must be atleast 3 characters" ;
        noError = false;
      } 
      else if (
        ( key === 'twoLastName' ) &&
        values.match(userNameRegex) === null
      ) {
        errorObj[`${key}`] = `Invalid ${key.substring(3, key.length)} Input`;
        noError = false;
      } else if (key === 'twoEmail' && values.match(emailRegex) === null ) {
        errorObj[`${key}`] = 'Invalid Email';
        noError = false;
      } else if(key === "twoEmail" && values.match(emailRegex) ===null && firstContact.oneEmail===values){
        errorObj[`${key}`] = 'Email should be unique';
        noError = false;
      }
      //  else if (
      //   (key === 'twoMobileNo' ) &&
      //   values.match(mobRegex) === null
      // ) {
      //   errorObj[`${key}`] = 'Invalid Number';
      //   noError = false;
      // }
      else if(key == "twoJob" && value.length<0){
        errorObj[`${key}`] ="Should not be empty" ;
        noError = false;
      }
    }
    // for (let [key, values] of Object.entries(thirdContact)) {
    //   if (values.length === 0) {
    //     errorObj[`${key}`] = 'Should not be empty';
    //     noError = false;
    //   } else if (
    //     (key === 'threeFirstName' ||
    //       key === 'threeLastName' ||
    //       key === 'threeJob') &&
    //     values.match(userNameRegex) === null
    //   ) {
    //     errorObj[`${key}`] = `Invalid ${key.substring(5, key.length)} Input`;
    //     noError = false;
    //   } else if (key === 'threeEmail' && values.match(emailRegex) === null) {
    //     errorObj[`${key}`] = 'Invalid Email';
    //     noError = false;
    //   } else if (
    //     (key === 'threeMobileNo' || key === 'threeOfficeNo') &&
    //     values.match(mobRegex) === null
    //   ) {
    //     errorObj[`${key}`] = 'Invalid Number';
    //     noError = false;
    //   }
    // }
    setContactError(errorObj);

    return noError;
  };

  const validateUnique = ()=>{
    let error = true;
    if(firstContact.oneFirstName == secondContact.twoFirstName){
      setIsUnique((prev)=>({
        ...prev,
        name:"Name Should be Unique"
      }))
      error = false;
    } 
     if(firstContact.oneEmail === secondContact.twoEmail){
      setIsUnique((prev)=>({
        ...prev,
        mail:'Email Should be Unique'
      }))
      error = false;
    } 
     if(firstContact.oneMobileNo === secondContact.twoMobileNo){
      setIsUnique((prev)=>({
        ...prev,
        mobile:"Mobile Should be Unique"
      }))
      error = false;
    }
    return error
  }
  const handleCheckBox = (e) => {
    const { name, checked } = e.target;
    setSecondEmp(firstemp)
    setCheckBox({ checked: checked, checkBoxName: name });

    if (name.substring(0, 3) === 'one') {
      
      setSecondContact({
        ...secondContact,
        twoAccessStartDate: firstContact.oneAccessStartDate,
        twoLevel: firstContact.oneLevel,
        twoUnit: firstContact.oneUnit,
      });
      setThirdContact({
        ...thirdContact,
        threeAccessStartDate: firstContact.oneAccessStartDate,
        threeLevel: firstContact.oneLevel,
        threeUnit: firstContact.oneUnit,
      });
    }

    if (name.substring(0, 3) === 'two') {
      setFirstContact({
        ...firstContact,
        oneAccessStartDate: secondContact.twoAccessStartDate,
        oneLevel: secondContact.twoLevel,
        oneUnit: secondContact.twoUnit,
      });
      setThirdContact({
        ...thirdContact,
        threeAccessStartDate: secondContact.twoAccessStartDate,
        threeLevel: secondContact.twoLevel,
        threeUnit: secondContact.twoUnit,
      });
    }

    if (name.substring(0, 3) === 'thr') {
      setFirstContact({
        ...firstContact,
        oneAccessStartDate: thirdContact.threeAccessStartDate,
        oneLevel: thirdContact.threeLevel,
        oneUnit: thirdContact.threeUnit,
      });
      setSecondContact({
        ...secondContact,
        twoAccessStartDate: thirdContact.threeAccessStartDate,
        twoLevel: thirdContact.threeLevel,
        twoUnit: thirdContact.threeUnit,
      });
    }

    setContactError((prevValue) => ({
      ...prevValue,
      oneAccessStartDate: '',
      oneUnit: '',
      oneLevel: '',
      twoAccessStartDate: '',
      twoUnit: '',
      threeAccessStartDate: '',
      threeUnit: '',
      threeLevel: '',
    }));
  };


  const handleDetailsCheckBox = (e) => {
    const { name, checked } = e.target;

    if (name === 'firstContactCheck' && checked) {
      setThirdContact({
        threeFirstName: firstContact.oneFirstName,
        threeLastName: firstContact.oneLastName,
        threeEmail: firstContact.oneEmail,
        threeJob: firstContact.oneJob,
        threeMobileNo: firstContact.oneMobileNo,
        threeMobileCode:firstContact.oneMobileCode,
        threeOfficeNo: firstContact.oneOfficeNo,
        threeOfficeCode:firstContact.oneOfficeCode,
        threeAccessStartDate: firstContact.oneAccessStartDate,
        threeLevel: firstContact.oneLevel,
        threeUnit: firstContact.oneUnit,
      });
    }
    if (name === 'secondContactCheck' && checked) {
      setThirdContact({
        threeFirstName: secondContact.twoFirstName,
        threeLastName: secondContact.twoLastName,
        threeEmail: secondContact.twoEmail,
        threeJob: secondContact.twoJob,
        threeMobileNo: secondContact.twoMobileNo,
        threeMobileCode:secondContact.twoMobileCode,
        threeOfficeNo: secondContact.twoOfficeNo,
        threeOfficeCode:secondContact.twoOfficeCode,
        threeAccessStartDate: secondContact.twoAccessStartDate,
        threeLevel: secondContact.twoLevel,
        threeUnit: secondContact.twoUnit,
      });
    }
    setContactError((prevValue) => ({
      ...prevValue,
      threeFirstName: '',
      threeLastName: '',
      threeEmail: '',
      threeJob: '',
      threeMobileNo: '',
      threeOfficeNo: '',
      threeAccessStartDate: '',
      threeLevel: '',
      threeUnit: '',
    }));

    if (!checked) {
      setThirdContact(intialThirdContact);
    }
    setDetailsCheckBox({ name: name, isChecked: checked });
  };
  const renameFirstObj = (object) => {
    console.log('moblen ',object.oneMobileCode.length)
    let renamedObj = {
      first_name: object.oneFirstName,
      last_name: object.oneLastName,
      email: object.oneEmail,
      job_title: object.oneJob,
      mobile_no: mobileNumbers.oneMobileNo.slice(object.oneMobileCode.length +2),
      mobile_no_code:`+${object.oneMobileCode}`,
      office_phone_no: mobileNumbers.oneOfficeNo.slice(object.oneOfficeCode.length +2),
      office_country_code:`+${object.oneOfficeCode}`,
      access_start_date: object.oneAccessStartDate,
      // access_start_date: getUTCDateFormat(new Date(object.oneAccessStartDate)),
      level_no: object.oneLevel?.level_id,
      level_name:object.oneLevel.level_name,
      unit_no: object.oneUnit.unit,
      unit_name:object.oneUnit.name,
      role: 'Tenant Manager',
    };
console.log('renamedObj ',renamedObj)
    return renamedObj;
  };
  const renameSecondObj = (object) => {

    let renamedObj = {  
      first_name: object.twoFirstName,
      last_name: object.twoLastName,
      email: object.twoEmail,
      job_title: object.twoJob,
      mobile_no: mobileNumbers.twoMobileNo.slice(object.twoMobileCode.length +2),
      mobile_no_code:`+${object.twoMobileCode}`,
      office_phone_no: mobileNumbers.twoOfficeNo.slice(object.twoOfficeCode.length +2),
      office_country_code:`+${object.twoOfficeCode}`,
      access_start_date: object.twoAccessStartDate,
      // access_start_date: getUTCDateFormat(new Date(object.twoAccessStartDate)),
      level_no: object.twoLevel.level_id,
      level_name:object.twoLevel.level_name,
      unit_no: object.twoUnit.unit,
      unit_name:object.twoUnit.name,
      role: 'Main Administrator',
    };

    return renamedObj;
  };
  const renameThirdObj = (object) => {
    let renamedObj = {
      first_name: object.threeFirstName,
      last_name: object.threeLastName,
      email: object.threeEmail,
      job_title: object.threeJob,
      mobile_no: object.threeMobileNo.slice(object.threeMobileCode.length),
      mobile_no_code:object.threeMobileCode,
      office_phone_no: object.threeOfficeNo.slice(object.threeOfficeCode.length),
      office_country_code:object.threeOfficeCode,
      access_start_date: object.threeAccessStartDate,
      level_no: object.threeLevel,
      unit_no: object.threeUnit,
      role: 'Finance Manager',
    };

    return renamedObj;
  };
console.log('data detailsData ',detailsData)
  const handleDetailsSubmit = () => {
    if(checkEditRoute()){
      
        if(validateDetails()){    
          console.log('mobileNumbers ',mobileNumbers)      
           let companyDetails = tenantDetails.companiesArrayList[0];
           let reqParams = {
            company_id: companyDetails.company_id,
            company_name: detailsData.companyName,
            email: detailsData.email,
            phone_no: mobileNumbers.officeNo.slice(detailsData.officeNoCode.length + 1),
            country_code: detailsData.officeNoCode,
            unit_no: detailsData.unit,
            level_no: detailsData.level?.level_id,
            assigned_unit_id_str: JSON.stringify(detailsData.unitsToSubmit),            
          };
          console.log('regdpapa ',reqParams)
          updateTenant(reqParams, localStorage.getItem('userToken'));
      }
      return;
    }

    setkeyPress(true);
    if (validateDetails()) {
      setValue(1);
      setTabDisabled({
        tab2:false,
        tab3:true
      })
    }
  };

  const handleMainContacts = () => {
    const detailsError = validateDetails();
    const uniqueError =  validateUnique()  
    if (detailsError === false ) {
      setValue(0);
    } else if (validateMainContacts() && uniqueError) {
      let array = [];

      for (let i = 0; i < 3; i++) {
        console.log('how many times ',i)
        if (i === 0) {
          array.push(renameFirstObj(firstContact));
        }
        if (i === 1) {
          array.push(renameSecondObj(secondContact));
        }
        // if (i === 2) {
        //   array.push(renameThirdObj(thirdContact));
        // }
      }
      console.log('mobileNumbers ',mobileNumbers)
      setSubmissionData({
        company_name: detailsData.companyName,
        email: detailsData.email,
        phone_no: mobileNumbers.officeNo.slice(detailsData.officeNoCode.length +1),
        phone_code: detailsData.officeNoCode,
        level_no: detailsData.level,
        unit_no: detailsData.unit,
        employeeDataList: [...array],
        units_to_submit:detailsData.unitsToSubmit
      });
      setValue(2);
      setTabDisabled({
        tab2:false,
        tab3:false
      })
      setValidForm(true);
    }
    setkeyPress(true);
  };
  console.log('submissionData ',submissionData)

  const handleDataSubmission = () => {
    setkeyPress(true);
    if (validateDetails() && validateMainContacts()) {
      handleModalOpen();
    } else if (validateDetails() === false) {
      setValue(0);
    } else if (validateMainContacts() === false) {
      setValue(1);
    }
  };

  const handleConfirmSubmission = () => {
    
    setSnackBarKeyPress(true);
    let responseUserData = JSON.parse(localStorage.getItem("userData"));
    let data = {
      company_name: submissionData.company_name,
      email: submissionData.email,
      phone_no: submissionData.phone_no,
      level_no: submissionData.level_no?.level_id,
      unit_no: JSON.stringify(submissionData.unit_no.unit),
      employeeDataList: JSON.stringify(submissionData.employeeDataList),
      country_code: submissionData.phone_code,  
      created_by: responseUserData.user_id,
      assigned_unit_id_str:JSON.stringify(submissionData.units_to_submit),
    };
    console.log('data ',data)
    addTenant(data, localStorage.getItem('userToken'));
    setLoaderStatus(true)
    handleModalClose();
    setTimeout(()=>{
      setLoaderStatus(false)   
    },20000)
  };

  const handleDeleteDetails = () => {
    setAlertStatus(true)
  }
  const DeleteFunc = () => {
    setAlertStatus(!alertStatus)

  }

  const confirmFunc = () => {
    if (value === 0) {
      setDetailsData(detailsIntialState);
      setDetailsDataError(detailsIntialState);
    } else if (value === 1) {
      setFirstContact(initialFirstContact);
      setSecondContact(intialSecondContact);
      setThirdContact(intialThirdContact);
      setContactError({});
      let firstInput = document.getElementsByName('oneFirstName')[0];
      firstInput.scrollIntoView({ block: 'center', behavior: 'smooth' });
    } else if (value === 2 && validForm) {
      setValue(0);
      setDetailsData(detailsIntialState);
      setDetailsDataError(detailsIntialState);
      setFirstContact(initialFirstContact);
      setSecondContact(intialSecondContact);
      setThirdContact(intialThirdContact);
      setContactError({});
    }
  }

const handleReset = () =>{
  setDetailsData(detailsIntialState);
  setFirstContact(initialFirstContact);
  setSecondContact(intialSecondContact);
  setThirdContact(intialThirdContact);
  setSubmissionData({
    company_name: '',
    email: '',
    phone_no: '',
    phone_code: '',
    level_no: '',
    unit_no: [],
    employeeDataList: [],
  })
  setAccessLevel([])
  setAccessUnit([])
  setValue(0)
}
  return (


    <React.Fragment>
      {loaderStatus && <Loader status={loaderStatus} />}
      {alertStatus && <Faultalert closeFunc={DeleteFunc} confirmFunc={confirmFunc} alertData={alertData}></Faultalert>}
      {/* <Snackbar
        open={snackbar.status ? snackbar.status : false}
        autoHideDuration={5000}
      >
        <Alert
          severity={snackbar.type ? snackbar.type : 'info'}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar> */}
      {snackbar.status && <ToastAlert message={snackbar.message} status={snackbar.status} color={snackbar.color} />}
      <Box
        sx={{
          width: '100%',
          background: '#fff',
          borderRadius: '8px',
          color: '#0F2C6F',
          '& .css-19kzrtu': {
            padding: '0',
          },
        }}
      >

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
            allowScrollButtonsMobile
            variant='scrollable'
          >
            <Tab label={
                  <Box>
                      <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>1</span>
                      <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Details</span>
                  </Box>
                } />
                {checkEditRoute() === false &&
                <Tab disabled={tabDisabled.tab2} label={
                  <Box>
                      <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>2</span>
                      <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Main Contacts</span>
                  </Box>
                } /> }
                {checkEditRoute() === false &&
                <Tab disabled={tabDisabled.tab3} label={
                  <Box>
                      <span style={{ backgroundColor: '#123FA9', fontSize: '12px', color: '#FFFFFF', borderRadius: '50%', padding: '3px 7px' }}>3</span>
                      <span style={{ textTransform: 'capitalize', marginLeft: '3px', color: '#123FA9', fontSize: '14px' }}>Review</span>
                  </Box>
                } />}
            {/* <Tab label="Item Two" {...a11yProps(1)} />
          <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ padding: { md: '25px', xs: '15px' } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                fontWeight: 500,
                rowGap: '2px',
              }}
            >
              <Typography
                variant='p'
                sx={{ fontSize: { md: '18px', xs: '16px' } }}
              >
                Company Details
              </Typography>
              <Typography
                variant='p'
                sx={{ fontSize: { md: '14px', xs: '12px' }, color: '#6F80A9' }}
              >
                Fill in below form to onboard a new tenant company
              </Typography>
            </Box>

            {/* Name and Email */}
            <Grid container sx={{ marginTop: '30px', rowGap: '20px' }}>
              <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    mr: { md: '10px', xs: '0' },
                    rowGap: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: { md: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: '#0F2C6F',
                    }}
                  >
                    Company Full Name*
                  </InputLabel>
                  <TextField
                    type='text'
                    fullWidth
                    autoComplete='off'
                    inputProps={{
                      sx: {
                        height: { sm: '20px', xs: '10px' },
                        fontSize: { sm: '14px', xs: '12px' },
                        width: '100%',
                        borderRadius: '6px',
                        px: '10px',
                        color:'#0F2C6F'
                      },
                    }}
                    name='companyName'
                    onChange={(e) => handleDetailsInput(e)}
                    value={detailsData.companyName}
                  />
                  {
                    <Typography
                      sx={{
                        color: '#f44336',
                        fontSize: { md: '14px', xs: '12px' },
                      }}
                    >
                      {detailsDataError.companyName}
                    </Typography>
                  }
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    mr: { md: '10px', xs: '0' },
                    rowGap: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: { md: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: '#0F2C6F',
                    }}
                  >
                    Company Email Address*
                  </InputLabel>
                  <TextField
                    name='email'
                    onChange={(e) => handleDetailsInput(e)}
                    value={detailsData.email}
                    type='text'
                    fullWidth
                    autoComplete='off'
                    inputProps={{
                      sx: {
                        height: { sm: '20px', xs: '10px' },
                        fontSize: { sm: '14px', xs: '12px' },
                        width: '100%',
                        borderRadius: '6px',
                        px: '10px',
                        color:'#0F2C6F'
                      },
                    }}
                  />
                  {
                    <Typography
                      sx={{
                        color: '#f44336',
                        fontSize: { md: '14px', xs: '12px' },
                      }}
                    >
                      {detailsDataError.email}
                    </Typography>
                  }
                </Box>
              </Grid>
            </Grid>

            {/* Phone Number and Reception Unit Number */}
            <Grid
              container
              sx={{
                rowGap: '20px',
                marginTop: '20px',
                paddingBottom: '30px',
                borderBottom: '1px solid #F3F4F8',
              }}
            >
              <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    mr: { md: '10px', xs: '0' },
                    rowGap: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: { md: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: '#0F2C6F',
                    }}
                  >
                    Office Phone Number*
                  </InputLabel>
                  <PhoneInput
                    inputProps={{ name: 'officeNo' }}
                    value={detailsData.officeNo}
                    country='sg'
                    inputStyle={{
                      width: '100%',
                      background: '#fff',
                      fontSize: '13px',
                      height: '48px',
                      fontWeight: 500,
                      border: '1px solid lightgray',
                    }}
                    containerStyle={{
                      borderRadius: '6px',
                    }}
                    buttonStyle={{
                      border: 'transparent',
                      margin: 2,
                      background: '#fff',
                    }}
                    style={{
                      '& .PhoneInputInput': {
                        '&:focus-visible': {
                          outline: 'none',
                        },
                      },
                    }}
                    onChange={(officeNo, country,event,format) =>
                      handleMobileInput(officeNo, country, 'officeNo',event,format)
                    }
                  />
                  {
                    <Typography
                      sx={{
                        color: '#f44336',
                        fontSize: { md: '14px', xs: '12px' },
                      }}
                    >
                      {detailsDataError.officeNo}
                    </Typography>
                  }
                </Box>
              </Grid>

            </Grid>

            {/* Specify Access Units */}

            <Box sx={{ margin: '30px 0' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontWeight: 500,
                  rowGap: '5px',
                }}
              >
                <Typography
                  variant='p'
                  sx={{ fontSize: { md: '18px', xs: '16px' } }}
                >
                  Specify Access units
                </Typography>
                <Typography
                  variant='p'
                  sx={{ fontSize: { md: '14px', xs: '12px' } }}
                >
                  Access units needs to be specified before reception unit
                  number can be selected.
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              sx={{
                marginTop: '30px',
                rowGap: '15px',
              }}
            >
              <Grid item md={6} xs={12} sx={{ display: 'flex', gap: '10px' }}>
                <Box sx={{ width: '30%' }}>
                  <InputLabel
                    sx={{
                      fontSize: { md: '14px', xs: '13px' },
                      fontWeight: 700,
                      marginBottom: '10px',
                      color: '#0F2C6F',
                    }}
                  >
                    Levels*
                  </InputLabel>
                 <Select
                    MenuProps={MenuProps}
                    sx={{
                      height: { md: '55px', xs: '45px' },
                      outline: 'none',
                      fontSize: { md: '14px', xs: '12px' },
                      width: '100%',
                      color:'#0F2C6F'
                    }}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    name='accessLevel'

                    value={detailsData.accessLevel}
                    onChange={(e) => handleDetailsInput(e, 'specificLevel')}
                  >

                    {
                      level?.map((value, index) => (
                        <MenuItem key={index} value={value} onClick={() => {
                          getUnitsByLevelid({ level_id: value.level_id })
                          //setSpecifyUnits({...specifyUnits,unit:unitDatas})
                        }}>{value.level_name}</MenuItem>
                      ))
                    }
                  </Select>
                  {
                          <Typography
                            sx={{
                              color: '#f44336',
                              fontSize: { md: '14px', xs: '12px' },
                            }}
                          >
                            {detailsDataError.accessLevel}
                          </Typography>
                        }
                </Box>


                <Box sx={{ width: '70%' }}>
                  <InputLabel
                    sx={{
                      fontSize: { md: '14px', xs: '12px' },
                      fontWeight: 700,
                      marginBottom: '10px',
                      color: '#0F2C6F',
                    }}
                  >
                    Unit*
                  </InputLabel>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {specifyUnits[0] === 'No data found' ? <OutlinedInput placeholder='No Units found' disabled></OutlinedInput> :
                      <Select
                        MenuProps={MenuProps}
                        sx={{
                          height: { md: '55px', xs: '45px' },
                          outline: 'none',
                          fontSize: { md: '14px', xs: '12px' },
                          width: '80%',
                          color:'#0F2C6F'
                        }}
                        displayEmpty
                        name='accessUnit'
                        value={detailsData.accessUnit}
                        onChange={(e) => handleUnitChange(e)}
                      
                      >

                        {
                          specifyUnits.length > 0 &&
                          specifyUnits.map((value, index) => (
                            <MenuItem key={index} value={value} >{value.unit_name}</MenuItem>
                          ))
                        }

                      </Select>
                    } {
                      <Typography
                        sx={{
                          color: '#f44336',
                          fontSize: { md: '14px', xs: '12px' },
                        }}
                      >
                        {detailsDataError.accessUnit}
                      </Typography>
                    }                
                    <Box
                      sx={{
                        width: '15%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <RoundedPlusIcon
                        cursor='pointer'
                        onClick={() => handleSelectedUnits()}
                      />
                    </Box>
                  </Box>
                </Box>
                
              </Grid>

              <Grid item md={6} xs={12} sx={{ width: '100%', maxHeight: '150px',
                    overflow: 'auto', }}>
                <InputLabel
                  sx={{
                    fontSize: { md: '14px', xs: '12px' },
                    fontWeight: 700,
                    marginBottom: '10px',
                    color: '#0F2C6F',
                   
                  }}
                >
                  Selected Units
                </InputLabel>
                <Grid
                  container
                  sx={{
                    gap: '10px',
                  }}
                >
                  {detailsData?.selectedUnits?.map((item, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={item}
                        key={index}
                        onDelete={() => handleDelete(item,index)}
                        sx={{
                          color: '#fff',
                          background: '#123FA9',
                          '& .MuiChip-deleteIcon': {
                            color: '#fff',
                            '&:hover': {
                              color: '#B0B0B0',
                            },
                          },
                          fontSize: { md: '14px', xs: '10px' },
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                {/* {detailsDataError.selectedUnits && (
                  <Grid
                    item
                    sx={{
                      color: '#f44336',
                      fontSize: { md: '14px', xs: '12px' },
                      margin: '15px',
                    }}
                  >
                    {detailsDataError.selectedUnits}
                  </Grid>
                )} */}
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    mr: { md: '10px', xs: '0' },
                    rowGap: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop:'5%'
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: { md: '14px', xs: '12px' },
                      fontWeight: 500,
                      color: '#0F2C6F',
                    }}
                  >
                    Reception Unit Number*
                  </InputLabel>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '80%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <FormControl sx={{ width: '35%' }}>
                      <InputLabel sx={{ fontSize: { md: '14px', xs: '13px' } }}>
                        Level
                      </InputLabel>
                      <Select
                        disabled={detailsData?.selectedUnits?.length>0 ? false : true}
                        label='Level'
                        name='level'
                        MenuProps={MenuProps}
                        value={detailsData.level}
                        sx={{
                          height: { md: '48px', xs: '48px' },
                          outline: 'none',
                          fontSize: { md: '14px', xs: '12px' },
                          color:'#0F2C6F'
                        }}
                        onChange={(e) => handleReceptionLevels(e, 'companyLevel')}
                        displayEmpty
                      >

                        {/* {
                          level?.map((value, index) => (
                            <MenuItem key={index} value={value.level_id} onClick={() => {
                              getUnitsByLevelid({ level_id: value.level_id })
                            }
                            }

                            >{value.level_id}</MenuItem>
                          ))
                        } */}

                        {accessLevel?.map((value,index)=>(
                          <MenuItem key={index} value={value}>{value.level_name}</MenuItem>
                        ))}
                      </Select>
                      {
                        <Typography
                          sx={{
                            color: '#f44336',
                            fontSize: { md: '14px', xs: '12px' },
                          }}
                        >
                          {detailsDataError.level}
                        </Typography>
                      }
                    </FormControl>


                    {companyUnits[0] === 'No data found' ? <OutlinedInput value='No units found' disabled></OutlinedInput> :
                      <FormControl sx={{ width: '60%' }}>
                        <InputLabel sx={{ fontSize: { md: '14px', xs: '12px' } }}>
                          Unit
                        </InputLabel>
                        <Select
                          disabled={detailsData?.selectedUnits?.length>0 ? false : true}
                          label='Unit'
                          name='unit'
                          MenuProps={MenuProps}
                          value={detailsData.unit}
                          sx={{
                            height: { md: '48px', xs: '48px' },
                            outline: 'none',
                            fontSize: { md: '14px', xs: '12px' },
                            color:'#0F2C6F'
                          }}
                          onChange={(e) => handleReceptionUnits(e)}
                          displayEmpty
                        >
                          
                         {checkEditRoute() === false ?
                          allUnits?.level_id==detailsData?.accessLevel?.level_id ? allUnits?.unit :  accessUnit?.map((value)=>(
                          <MenuItem value={value}>{value.name}</MenuItem>
                         )) : accessUnit?.map((value)=>(
                          <MenuItem value={value.unit}>{value.name}</MenuItem>
                         ))}
                      </Select>

                        {
                          <Typography
                            sx={{
                              color: '#f44336',
                              fontSize: { md: '14px', xs: '12px' },
                            }}
                          >
                            {detailsDataError.unit}
                          </Typography>
                        }
                      </FormControl>
                    }

                  </Box>
                </Box>
              </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* Main Contacts */}
          <Box
            sx={{
              borderBottom: '25px solid #F3F4F8',
              padding: { md: '25px', xs: '15px' },
            }}
          >
            <Typography sx={{ fontSize: { md: '18px', xs: '14px' } }}>
              Tenant Manager - First Contact Personnel*
            </Typography>
            <Typography
              sx={{
                fontSize: { md: '14px', xs: '12px' },
                color: '#6F80A9',
                marginTop: '8px',
              }}
            >
              Tenant Managers will be responsible for onboarding administrators
              who will add the rest of the tenant employees and giving approval
              to tenant employees service requests. Tenant manager will have
              access to all the company units but a main access unit number
              should be provided.
            </Typography>

            <Grid container sx={{ paddingTop: '40px', rowGap: '10px' }}>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  First Name*
                </StyledInputLabel>
                <TextField
                  sx={{color:"#0F2C6F"}}
                  type='text'
                  fullWidth
                  size='small'
                  name='oneFirstName'
                  id='oneFirstName'
                  onChange={(e) => handleFirstContactInput(e)}
                  value={firstContact.oneFirstName}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneFirstName}
                </Typography>
                {!contactErrors.oneFirstName &&  <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {isUnique.name}
                </Typography>
              }
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Last Name*
                </StyledInputLabel>
                <TextField
                  name='oneLastName'
                  onChange={handleFirstContactInput}
                  type='text'
                  fullWidth
                  size='small'
                  value={firstContact.oneLastName}
                  sx={{ color:'#0F2C6F'}}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneLastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Work Email Address*
                </StyledInputLabel>
                <TextField
                  name='oneEmail'
                  onChange={(e) => handleFirstContactInput(e)}
                  type='text'
                  fullWidth
                  size='small'
                  value={firstContact.oneEmail}
                  sx={{ color:'#0F2C6F'}}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneEmail}
                </Typography>
                {!contactErrors.oneEmail && <Typography
                  sx={{ 
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {isUnique.mail}
                </Typography>
              }
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Job Position*
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='oneJob'
                  onChange={(e) => handleFirstContactInput(e)}
                  value={firstContact.oneJob}
                  sx={{ color:'#0F2C6F'}}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneJob}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  Mobile Telephone *
                </StyledInputLabel>
                <PhoneInput
                  inputProps={{
                    name: 'oneMobileNo',
                    required: true,
                  }}
                  value={firstContact.oneMobileNo}
                  onChange={(oneMobileNo,country, event,format) => {
                    handleMobileNoInput(oneMobileNo, 'oneMobileNo',country, event, format);
                  }}
                  country='sg'
                  inputStyle={{
                    width: '100%',
                    background: '#F3F4F8',
                    fontSize: '13px',
                    height: '48px',
                    fontWeight: 500,
                    border: 'none',
                  }}
                  containerStyle={{
                    borderRadius: '6px',
                  }}
                  buttonStyle={{
                    border: 'transparent',
                    margin: 2,
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneMobileNo}
                </Typography>
                {!contactErrors.oneMobileNo && <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {isUnique.mobile}
                </Typography>
              }
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  Office Telephone 
                </StyledInputLabel>
                <PhoneInput
                  inputProps={{ 
                    name: 'oneOfficeNo',
                    required: true,
                  }}
                  value={firstContact.oneOfficeNo}
                  onChange={(oneOfficeNo,country,event,format) => {
                    handleMobileNoInput(oneOfficeNo, 'oneOfficeNo',country,event,format);
                  }}
                  // onFocus={() => setMobileBlur(false)}
                  // onBlur={() => setMobileBlur(true)}
                  country='sg'
                  inputStyle={{
                    width: '100%',
                    background: '#F3F4F8',
                    fontSize: '13px',
                    height: '48px',
                    fontWeight: 500,
                    border: 'none',
                  }}
                  containerStyle={{
                    borderRadius: '6px',
                    // border:
                    //   faultDetailErrors.mobileNumber && mobileBlur
                    //     ? "1px solid #f44336"
                    //     : "none",
                  }}
                  buttonStyle={{
                    border: 'transparent',
                    margin: 2,
                  }}
                />
                {/* <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneOfficeNo}
                </Typography> */}
              </Grid>
              <Grid item sm={4} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Access Start Date*
                </StyledInputLabel>
                <DatePicker
                  size='small'
                  name='oneAccessStartDate'
                  onChange={handleOneDateInput}
                  value={firstContact.oneAccessStartDate || null}
                  renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                  disablePast
                  components={{
                    OpenPickerIcon:DatePickerIcon 
                  }}
                  inputProps={{
                    sx: {
                      height: 10,
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.oneAccessStartDate}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Office Unit Number*
                </StyledInputLabel>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  <FormControl size='small' sx={{ width: '45%' }}>
                    <InputLabel sx={{ fontSize: '12px' }}>Level</InputLabel>
                    <Select
                      size='small'
                      label='level'
                      MenuProps={MenuProps}
                      // onChange={(e) => handleFirstContactInput(e, 'managerUnits')}
                      onChange={(e)=>handleReceptionLevels(e,'first')}
                      name='oneLevel'
                      value={firstContact.oneLevel}
                      displayEmpty
                       sx={{ color:'#0F2C6F'}}
                    >


                      {/* {levelDatas.map((item, index) => (
                        <MenuItem key={index} value={item.level_id} onClick={() => getUnitsByLevelid({ level_id: item.level_id })}>
                          {item.level_id}
                        </MenuItem>
                      ))} */}


{accessLevel.map((value,index)=>(
                          <MenuItem key={index} value={value}>{value.level_name}</MenuItem>
                        ))}

                    </Select>
                  </FormControl>

                  {managerUnits[0] === 'No data found' ? <OutlinedInput sx={{ height: '40px' }} value='No units found' disabled /> :
                    <FormControl size='small' sx={{ width: '55%' }}>
                      <InputLabel sx={{ fontSize: '12px' }}>Unit</InputLabel>
                      <Select
                      defaultValue={''}
                        size='small'
                        label='unit'
                        MenuProps={MenuProps}
                        onChange={handleFirstContactInput}
                        name='oneUnit'
                        value={firstContact.oneUnit}
                        displayEmpty
                        sx={{ color:'#0F2C6F'}}
                      >


                        {/* {managerUnits.map((item, index) => (
                          <MenuItem value={item.unit_id} key={index}>
                            {item.unit_id}
                          </MenuItem>
                        ))} */}

{firstemp.map((value)=>(
                          <MenuItem value={value}>{value.name}</MenuItem>
                         ))}

                      </Select>
                    </FormControl>
                  }
                </Box>
              </Grid>
              <Grid
                item
                sm={2}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {contactErrors.oneUnit && contactErrors.oneLevel && (
                  <Typography
                    sx={{
                      color: '#f44336',
                      fontSize: { md: '14px', xs: '12px' },
                    }}
                  >
                    Should not empty
                  </Typography>
                )}
              </Grid>
              <Box
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 10px',
                }}
              >
                <Checkbox
                  name='oneCheckBox'
                  onChange={handleCheckBox}
                  checked={checkBox.checked}
                  disabled={
                    checkBox.checked && checkBox.checkBoxName !== 'oneCheckBox'
                  }
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { md: 28, xs: 22 },
                    },
                  }}
                />
                Apply Access date and Office Unit to all
              </Box>
            </Grid>
          </Box>

          {/* Main Administrator */}
          <Box
            sx={{
              borderBottom: '25px solid #F3F4F8',
              padding: { md: '25px', xs: '15px' },
            }}
          >
            <Typography sx={{ fontSize: { md: '18px', xs: '14px' } }}>
              Main Administrator - Second Contact Personnel*
            </Typography>
            <Typography
              sx={{
                fontSize: { md: '14px', xs: '12px' },
                color: '#6F80A9',
                marginTop: '8px',
              }}
            >
              Main Administrator will take over the tenant office manager’s role
              and responsibilities in event that person resigns or is on leave.
            </Typography>

            <Grid container sx={{ paddingTop: '40px', rowGap: '10px' }}>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  First Name*
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='twoFirstName'
                  onChange={(e) => handleSecondContactInput(e)}
                  value={secondContact.twoFirstName}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoFirstName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Last Name*
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='twoLastName'
                  onChange={(e) => handleSecondContactInput(e)}
                  value={secondContact.twoLastName}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoLastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Work Email Address*
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='twoEmail'
                  value={secondContact.twoEmail}
                  onChange={(e) => handleSecondContactInput(e)}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoEmail}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Job Position*
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='twoJob'
                  value={secondContact.twoJob}
                  onChange={(e) => handleSecondContactInput(e)}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoJob}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  Mobile Telephone *
                </StyledInputLabel>
                <PhoneInput
                  inputProps={{
                    name: 'twoMobileNo',
                    required: true,
                  }}
                  value={secondContact.twoMobileNo}
                  onChange={(twoMobileNo,country,event,format) => {
                    handleMobileNoInput(twoMobileNo, 'twoMobileNo',country,event,format);
                  }}
                  // onFocus={() => setMobileBlur(false)}
                  // onBlur={() => setMobileBlur(true)}
                  country='sg'
                  inputStyle={{
                    width: '100%',
                    background: '#F3F4F8',
                    fontSize: '13px',
                    height: '48px',
                    fontWeight: 500,
                    border: 'none',
                  }}
                  containerStyle={{
                    borderRadius: '6px',
                    // border:
                    //   faultDetailErrors.mobileNumber && mobileBlur
                    //     ? "1px solid #f44336"
                    //     : "none",
                  }}
                  buttonStyle={{
                    border: 'transparent',
                    margin: 2,
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoMobileNo}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  Office Telephone 
                </StyledInputLabel>
                <PhoneInput
                  inputProps={{
                    name: 'twoOfficeNo',
                    required: true,
                  }}
                  value={secondContact.twoOfficeNo}
                  onChange={(twoOfficeNo,country,event,format) => {
                    handleMobileNoInput(twoOfficeNo, 'twoOfficeNo',country,event,format);
                  }}
                  // onFocus={() => setMobileBlur(false)}
                  // onBlur={() => setMobileBlur(true)}
                  country='sg'
                  inputStyle={{
                    width: '100%',
                    background: '#F3F4F8',
                    fontSize: '13px',
                    height: '48px',
                    fontWeight: 500,
                    border: 'none',
                  }}
                  containerStyle={{
                    borderRadius: '6px',
                    // border:
                    //   faultDetailErrors.mobileNumber && mobileBlur
                    //     ? "1px solid #f44336"
                    //     : "none",
                  }}
                  buttonStyle={{
                    border: 'transparent',
                    margin: 2,
                  }}
                />
                {/* <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoOfficeNo}
                </Typography> */}
              </Grid>
              <Grid item sm={4} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Access Start Date*
                </StyledInputLabel>
                <DesktopDatePicker
                  size='small'
                  name='twoAccessStartDate'
                  onChange={handleTwoDateInput}
                  value={secondContact.twoAccessStartDate || null}
                  renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                  disablePast
                  components={{
                    OpenPickerIcon:DatePickerIcon 
                  }}
                  inputProps={{
                    sx: {
                      height: 10,
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.twoAccessStartDate}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Office Unit Number*
                </StyledInputLabel>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  <FormControl size='small' sx={{ width: '45%' }}>
                    <InputLabel sx={{ fontSize: '12px' }}>Level</InputLabel>
                    <Select
                      size='small'
                      label='level'
                      MenuProps={MenuProps}
                      name='twoLevel'
                      value={secondContact.twoLevel}
                      onChange={(e) => handleReceptionLevels(e, 'second')}
                    >
                      {/* {levelDatas.map((item, index) => (
                        <MenuItem key={index} value={item.level_id} onClick={() => getUnitsByLevelid({ level_id: item.level_id })}>
                          {item.level_id}
                        </MenuItem>
                      ))} */}

                      {accessLevel.map((value)=>(
                        <MenuItem value={value}>{value.level_name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {mainadmin[0] === 'No data found' ? <OutlinedInput sx={{ height: '40px' }} value="no units found" disabled /> :
                    <FormControl size='small' sx={{ width: '55%' }}>
                      <InputLabel sx={{ fontSize: '12px' }}>Unit</InputLabel>
                      <Select
                      defaultValue={''}
                        size='small'
                        label='unit'
                        name='twoUnit'
                        MenuProps={MenuProps}
                        value={secondContact.twoUnit}
                        onChange={handleSecondContactInput}
                      >
                        {/* {mainadmin.map((item, index) => (
                          <MenuItem value={item.unit_id} key={index}>
                            {item.unit_id}
                          </MenuItem>
                        ))} */}

                         

                        {secondemp.map((value)=>(
                          <MenuItem value={value}>{value.name}</MenuItem>
                         )) 
                        
                         
                        } 
                      </Select>
                    </FormControl>
                  }
                </Box>
              </Grid>
              <Grid
                item
                sm={2}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {contactErrors.twoUnit && contactErrors.twoLevel && (
                  <Typography
                    sx={{
                      color: '#f44336',
                      fontSize: { md: '14px', xs: '12px' },
                    }}
                  >
                    Should not empty
                  </Typography>
                )}
              </Grid>
              {/* <Box
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 10px',
                }}
              >
                <Checkbox
                  name='twoCheckBox'
                  onChange={handleCheckBox}
                  checked={checkBox.checked}
                  disabled={
                    checkBox.checked && checkBox.checkBoxName !== 'twoCheckBox'
                  }
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { md: 28, xs: 22 },
                    },
                  }}
                />
                Apply Access date and Office Unit to all
              </Box> */}
            </Grid>
          </Box>

          {/* Finanace Manager */}
          {/* <Box
            sx={{
              borderBottom: '25px solid #F3F4F8',
              padding: { md: '25px', xs: '15px' },
            }}
          > */}
            {/* <Typography sx={{ fontSize: { md: '18px', xs: '14px' } }}>
              Finance Manager - Third Contact Personnel*
            </Typography>
            <Typography
              sx={{
                fontSize: { md: '14px', xs: '12px' },
                color: '#6F80A9',
                marginTop: '8px',
              }}
            >
              Finance Manager will be the person who is authorised to make
              payments on behalf of the company.
            </Typography> */}

            {/* <Grid
              container
              sx={{ display: 'flex', fontSize: '14px', marginTop: '5px' }}
            >
              <Grid item sm={6} xs={12}>
                <Box>
                  <Checkbox
                    name='firstContactCheck'
                    onChange={handleDetailsCheckBox}
                    checked={
                      detailsCheckBox.name === 'firstContactCheck' &&
                      detailsCheckBox.isChecked
                    }
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: { md: 28, xs: 22 },
                      },
                    }}
                  />{' '}
                  Apply First Contact Details
                </Box>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Box>
                  <Checkbox
                    name='secondContactCheck'
                    onChange={handleDetailsCheckBox}
                    checked={
                      detailsCheckBox.name === 'secondContactCheck' &&
                      detailsCheckBox.isChecked
                    }
                    sx={{
                      '& .MuiSvgIcon-root': {
                        fontSize: { md: 28, xs: 22 },
                      },
                    }}
                  />{' '}
                  Apply Second Contact Details
                </Box>
              </Grid>
            </Grid> */}

            {/* <Grid container sx={{ paddingTop: '40px', rowGap: '10px' }}>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  First Name
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='threeFirstName'
                  value={thirdContact.threeFirstName}
                  onChange={(e) => handleThirdContactInput(e)}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeFirstName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Last Name
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='threeLastName'
                  value={thirdContact.threeLastName}
                  onChange={(e) => handleThirdContactInput(e)}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeLastName}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Work Email Address
                </StyledInputLabel>
                <TextField
                  type='email'
                  fullWidth
                  size='small'
                  name='threeEmail'
                  value={thirdContact.threeEmail}
                  onChange={(e) => handleThirdContactInput(e)}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeEmail}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Job Position
                </StyledInputLabel>
                <TextField
                  type='text'
                  fullWidth
                  size='small'
                  name='threeJob'
                  value={thirdContact.threeJob}
                  onChange={(e) => handleThirdContactInput(e)}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeJob}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  Mobile Telephone 
                </StyledInputLabel>
                <PhoneInput
                  inputProps={{
                    name: 'threeMobileNo',
                    required: true,
                  }}
                  value={thirdContact.threeMobileNo}
                  onChange={(threeMobileNo,country) => {
                    handleMobileNoInput(threeMobileNo, 'threeMobileNo',country);
                  }}
                  // onFocus={() => setMobileBlur(false)}
                  // onBlur={() => setMobileBlur(true)}
                  country='sg'
                  inputStyle={{
                    width: '100%',
                    background: '#F3F4F8',
                    fontSize: '13px',
                    height: '48px',
                    fontWeight: 500,
                    border: 'none',
                  }}
                  containerStyle={{
                    borderRadius: '6px',
                    // border:
                    //   faultDetailErrors.mobileNumber && mobileBlur
                    //     ? "1px solid #f44336"
                    //     : "none",
                  }}
                  buttonStyle={{
                    border: 'transparent',
                    margin: 2,
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeMobileNo}
                </Typography>
              </Grid>
              <Grid item md={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel
                  sx={{
                    marginBottom: '5px',
                  }}
                >
                  Office Telephone 
                </StyledInputLabel>
                <PhoneInput
                  inputProps={{
                    name: 'threeOfficeNo',
                    required: true,
                  }}
                  // value={faultDetails.mobileNumber}
                  onChange={(threeOfficeNo,country) => {
                    handleMobileNoInput(threeOfficeNo, 'threeOfficeNo',country);
                  }}
                  value={thirdContact.threeOfficeNo}
                  // onFocus={() => setMobileBlur(false)}
                  // onBlur={() => setMobileBlur(true)}
                  country='sg'
                  inputStyle={{
                    width: '100%',
                    background: '#F3F4F8',
                    fontSize: '13px',
                    height: '48px',
                    fontWeight: 500,
                    border: 'none',
                  }}
                  containerStyle={{
                    borderRadius: '6px',
                    // border:
                    //   faultDetailErrors.mobileNumber && mobileBlur
                    //     ? "1px solid #f44336"
                    //     : "none",
                  }}
                  buttonStyle={{
                    border: 'transparent',
                    margin: 2,
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeOfficeNo}
                </Typography>
              </Grid>
              <Grid item sm={4} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Access Start Date
                </StyledInputLabel>
                <DesktopDatePicker
                  size='small'
                  name='threeAccessStartDate'
                  onChange={handleThreeDateInput}
                  value={thirdContact.threeAccessStartDate || null}
                  renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                  disablePast
                  inputProps={{
                    sx: {
                      height: 10,
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: '#f44336',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  {contactErrors.threeAccessStartDate}
                </Typography>
              </Grid>
              <Grid item sm={6} xs={12} sx={{ p: '0 10px' }}>
                <StyledInputLabel sx={{ marginBottom: '5px' }}>
                  Office Unit Number
                </StyledInputLabel>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: { md: '14px', xs: '12px' },
                  }}
                >
                  <FormControl size='small' sx={{ width: '40%' }}>
                    <InputLabel sx={{ fontSize: '12px' }}>Level</InputLabel>
                    <Select
                      size='small'
                      label='level'
                      MenuProps={MenuProps}
                      value={thirdContact.threeLevel}
                      name='threeLevel'
                      onChange={(e) => handleReceptionLevels(e, 'third')}
                    >
                      {accessLevel.map((value)=>(
                        <MenuItem value={value}>{value}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                        
                  {financemanager[0] === 'No data found' ? <OutlinedInput sx={{ height: '40px' }} value='No units found ' disabled /> :
                    <FormControl size='small' sx={{ width: '60%' }}>
                      <InputLabel sx={{ fontSize: '12px' }}>Unit</InputLabel>
                      <Select
                      defaultValue={''}
                        size='small'
                        label='unit'
                        value={thirdContact.threeUnit}
                        MenuProps={MenuProps}
                        name='threeUnit'
                        onChange={(e)=>handleThirdContactInput(e)}
                      >
                         {thirdemp.map((value)=>(
                          <MenuItem value={value.unit}>{value.name}</MenuItem>
                         ))}

                      </Select>
                    </FormControl>
                  }
                </Box>
              </Grid>
              <Grid
                item
                sm={2}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {contactErrors.threeUnit && contactErrors.threeLevel && (
                  <Typography
                    sx={{
                      color: '#f44336',
                      fontSize: { md: '14px', xs: '12px' },
                    }}
                  >
                    Should not be empty
                  </Typography>
                )}
              </Grid>

              <Box
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '0 10px',
                }}
              >
                <Checkbox
                  name='threeCheckBox'
                  onChange={handleCheckBox}
                  checked={checkBox.checked}
                  disabled={
                    checkBox.checked &&
                    checkBox.checkBoxName !== 'threeCheckBox'
                  }
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: { md: 28, xs: 22 },
                    },
                  }}
                />{' '}
                Apply Access date and Office Unit to all
              </Box>
            </Grid> */}

        </TabPanel>
        <TabPanel value={value} index={2}>
          {validForm ? (
            <Box
              sx={{
                color: '#0F2C6F',
              }}
            >
              <Box sx={{ padding: { md: '35px 25px', xs: '15px' } }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                  Company Details
                </Typography>
                <Box
                  sx={{
                    margin: { md: '25px0', xs: '25px 0' },
                    display: 'flex',
                    gap: { md: '150px', sm: '100px', xs: '20px' },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography sx={{ fontSize: { md: '16px', xs: '13px' } }}>
                      {submissionData.company_name}
                    </Typography>
                    <Typography sx={{ fontSize: { md: '16px', xs: '13px' } }}>
                      Unit: #{submissionData.level_no.level_name}-{submissionData.unit_no.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    {' '}
                    <Typography sx={{ fontSize: { md: '16px', xs: '14px' } }}>
                      Office:  {submissionData.phone_code} {submissionData.phone_no}
                    </Typography>
                    <Typography sx={{ fontSize: { md: '16px', xs: '14px' } }}>
                      {submissionData.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider sx={{ borderColor: '#F3F4F8' }} />
              <Box sx={{ padding: { md: '35px 25px', xs: '15px' } }}>
                <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
                  Occupied Units
                </Typography>
                <Grid container sx={{ marginTop: '20px', gap: '6px' }}>
                  {detailsData.selectedUnits.map((item, index) => (
                    <Grid item key={index}>
                      <Chip
                        label={item}
                        sx={{ color: '#fff', background: checkDefaultUnit(item) ? "#173C92" : '#C0C7D7' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box
                sx={{
                  marginTop: '20px',
                  padding: { md: '35px 20px', xs: '15px' },
                  fontSize: { md: '14px', xs: '12px' },
                }}
              >
                {submissionData.employeeDataList.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid #C0C7D7',
                      borderRadius: '6px',
                      padding: '24px',
                      margin: '6px 0',
                    }}
                  >
                    <Typography sx={{ fontSize: '18px' }}>
                      {index === 0 && 'Tenant Manager'}
                      {index === 1 && 'Main Administrator'}
                      {/* {index === 2 && 'Finanace Manager'} */}
                    </Typography>
                    <Grid container sx={{ rowGap: '4px', marginTop: '20px' }}>
                      <Grid item md={4} sm={6} xs={12} sx={{ px: '10px' }}>
                        {item.first_name}  {item.last_name}
                      </Grid>
                      <Grid item md={4} sm={6} xs={12} sx={{ px: '10px' }}>
                        Access Start: {moment(item.access_start_date).format('DD-MM-YYYY')}
                      </Grid>
                      <Grid item md={4} sm={6} xs={12} sx={{ px: '10px' }}>
                        Office: {item.office_phone_no ? item.office_country_code :'-'} {item.office_phone_no}
                      </Grid>
                      <Grid item md={4} sm={6} xs={12} sx={{ px: '10px' }}>
                        {item.job_title}
                      </Grid>
                      <Grid item md={4} sm={6} xs={12} sx={{ px: '10px' }}>
                        Office Unit: #{item.level_name}-{item.unit_name}
                      </Grid>
                      <Grid item md={4} sm={6} xs={12} sx={{ px: '10px' }}>
                        <Grid container direction='column' rowGap={'4px'}>
                          <Grid item> Mobile: {item.mobile_no_code} {item.mobile_no}</Grid>
                          <Grid item> {item.email}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
            </Box>
          ) : (
            <Typography
              variant='h5'
              sx={{ padding: '120px 0', textAlign: 'center',color:'#0F2C6F' }}
            >
              No Data Found
            </Typography>
          )}
        </TabPanel>
      </Box>
      <Box
        sx={{
          display: 'flex',
          margin: '30px 0',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            color: '#9AA6C3',
            fontSize: { md: '14px', xs: '12px' },
          }}
        >
          <Recent /> Last Updated at {time.timeStr}
        </Typography>
        <Box sx={{ display: 'flex', gap: { md: '20px', xs: '10px' } }}>
          {checkEditRoute() === false &&<Button
            variant='outlined'
            sx={{
              textTransform: 'none',
              color: '#0F2C6F',
              fontSize: { md: '14px', xs: '12px' },
              padding: { md: '10px', xs: '5px' },
            }}
            onClick={()=>handleReset()}
          >
            Delete
          </Button>}
          {value === 0 && (
            <Button
              variant='contained'
              sx={{
                textTransform: 'none',
                background: '#123FA9',
                fontSize: { md: '14px', xs: '12px' },
                padding: { md: '10px', xs: '5px' },
              }}
              onClick={()=>handleDetailsSubmit()}
            >
              {checkEditRoute() ? 'Update':'Continue'}
            </Button>
          )}
          {value === 1 && (
            <Button
              variant='contained'
              sx={{
                textTransform: 'none',
                background: '#123FA9',
                fontSize: { md: '14px', xs: '12px' },
                padding: { md: '10px', xs: '5px' },
                display: value === 2 ? 'none' : 'block',
              }}
              onClick={()=> handleMainContacts()}
            >
              Continue
            </Button>
          )}

          {value === 2 && (
            <Button
              variant='contained'
              sx={{
                textTransform: 'none',
                background: '#123FA9',
                fontSize: { md: '14px', xs: '12px' },
                padding: { md: '10px', xs: '5px' },
              }}
              onClick={handleDataSubmission}
            >
              Submit
            </Button>
          )}
        </Box>
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: { md: '600px', sm: '400px', xs: '300px' },
              bgcolor: 'background.paper',
              borderRadius: '8px',
              boxShadow: 24,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: { md: '24px', xs: '16px' },
                borderBottom: ' 1px solid #C0C7D7',
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: '18px', sm: '15px', xs: '14px' },
                  color: '#0F2C6F',
                }}
              >
                Confirm Tenant Onboarding 
              </Typography>
              <Box
                sx={{
                  width: { md: '17px', sm: '14px', xs: '10px' },
                  display: 'flex',
                  cursor: 'pointer',
                }}
                onClick={handleModalClose}
              >
                <ModalCloseButton />
              </Box>
            </Box>

            <Typography
              id='modal-modal-description'
              sx={{
                p: { md: '24px', xs: '16px' },
                fontSize: { md: '14px', xs: '12px' },
                color: '#6F80A9',
              }}
            >
              Please confirm that you wish to onboard a new tenant with  (2) employees. If the
              above details is correct, please press confirm below. You may
              click the cross button on top right to return to form.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                p: { md: '24px', xs: '16px' },
              }}
            >
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  color: '#0F2C6F',
                  fontSize: { md: '14px', xs: '12px' },
                }}
                onClick={handleModalClose}
              >
                Cancel
              </Button>
              <Button
                variant='contained'
                sx={{
                  textTransform: 'none',
                  background: '#123FA9',
                  fontSize: { md: '14px', xs: '12px' },
                }}
                onClick={handleConfirmSubmission}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userMenus: state.UserReducer.userMenus,
    tenantAddStatus: state.CommonReducer.tenantAddStatus,
    levelDatas: state.CommonReducer.levelDatas,
    unitDatas: state.CommonReducer.unitDatas,
    tenantDetails: state.CommonReducer.tenantDetails,
    tenantUpdateStatus: state.CommonReducer.tenantUpdateStatus,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTenant: (data, token) =>
      dispatch(commonAction.manageTenant(data, token)),
    getAllLevels: () => dispatch(commonAction.getAllLevels()),
    getUnitsByLevelid: (data) => dispatch(commonAction.getAvailableUnitsByLevelid(data)),
    setStatusNull: () => dispatch(commonAction.setStatusNull()),
    getTenantDetailsById: (data, token) => dispatch(commonAction.getTenantDetailsById(data, token)),
    updateTenant: (data, token) => dispatch(commonAction.updateTenant(data, token)),

  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingCompanyTabs);
