import { Box, Button, Chip, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker, MonthPicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {ReactComponent as DatePickerIcon} from "../../Assets/icons/DatePickerIcon.svg";
import {ReactComponent as NotifyIcon} from "../../Assets/icons/dashboardnotification.svg";
import {ReactComponent as PriorityHighTwoToneIcon} from "../../Assets/icons/Warning.svg";
import {ReactComponent as KeyboardArrowRightIcon} from "../../Assets/icons/rightarrowgray.svg";
import Chart from "react-apexcharts"; 
import { styled } from "@mui/system";
import * as DashboardAction from "../../redux/actions/Dashboard Action/index"
import { connect } from "react-redux";
import { getStatus } from "../../Utils/ActiveStatus";
import { getLoggedUserData, getYYYYMMDDFormat, preventDatePickerKeyboard } from "../../Utils/utils";
import { useNavigate } from "react-router-dom";

const StyledPrimaryTypography = styled(Typography)({
    color:"#0F2C6F",
    fontSize:"16px",
    fontWeight:550
})

const StyledSecTypography = styled(Typography)({
    color:'#0F2C6F',
    fontSize:"14px",
    fontWeight:500
})

let SelectDays = ["Today","Yesterday","Last 7 days","Last 30 days","Last 90 days","Last 1 year","Custom"]  
const checkedStyle = { backgroundColor: '#FFFFFF', color: "#6F80A9", border: '1px solid #6F80A9',mr:2, '&:hover': { backgroundColor: '#FFFFFF' } }
const uncheckedStyle = { backgroundColor: "#123FA9", color: '#FFFFFF', mr: 2, '&:hover': { backgroundColor: '#123FA9' } }

const TenantDashboard =({dbData,getDbData})=>{
    const navigate = useNavigate()
    const [month, setMonth] = useState(moment(new Date()).format("MMMM"));
    const [anchorEl,setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);
    const [pendingAction,setPendingAction] = useState({
        onboarding:false,
        fault:true,
        service:false
    })
    const [internalApproval,setInternalApproval] = useState({
        onboarding:false,
        service:false
    })
    const [highlighted,setHighlighted] = useState({
        fault:true,
        service:false
    })
    const [filterDate,setFilterDate] = useState("Last 90 days")
    const [rangeDate,setRangeDate] = useState(null)
    const [pendingData,setPendingData] = useState([])
    const [highlightData,setHighlightData] = useState([])
    const [empData,setEmpData] = useState({
        onboardEmpCount:null,
        offboardEmpCount:null,
        onboardEmp:null,
        offboardEmp:null,
        totalEmpCount:null,
        activeEmpCount:null
    })
    const [customDate,setCustomDate] = useState({
        start:null,
        end:null
    })

    var chart = {
        series: [empData.activeEmpCount, empData.onboardEmpCount, empData.offboardEmpCount],
        options:{
            chart: {
                width: 380,
                type: 'donut',
                dropShadow: {
                  enabled: true,
                  color: '#111',
                  top: -1,
                  left: 3,
                  blur: 3,
                  opacity: 0.2
                }
              },
              labels:["Active","Onboarding","Offboarding"],
              stroke: {
                width: 0,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        showAlways:false,
                        show: true
                      }
                    }
                  }
                }
              },
              colors:['#0F2C6F','#2764F4','#AAC3FF'],
              dataLabels: {
                enabled:false,
                dropShadow: {
                
                  blur: 3,
                  opacity: 0.8
                }
              },
              
              states: {
                hover: {
                  filter: 'none'
                }
              },
              legend:{
                show:false
              }
        }
      };

    useEffect(()=>{
        let today = new Date()
        let date = new Date()

        if(filterDate=="Yesterday"){
            date.setDate(today.getDate()-1)
        }else if(filterDate=="Last 7 days"){
            date.setDate(today.getDate()-7)
        }else if(filterDate=="Last 30 days"){
            date.setDate(today.getDate()-30)
        }else if(filterDate=="Last 90 days"){
            date.setDate(today.getDate()-90)
        }else if(filterDate=="Last 1 year"){
            date.setDate(today.getDate()-365)
        }
       
        if(filterDate!="Custom"){ 
            let data = {
              start_date: getYYYYMMDDFormat(date) ,
              end_date:getYYYYMMDDFormat(new Date()),
              limit:10,
              offset:0,
              currentMonth:month.split(" ")[0]
            }
            getDbData(data)
        }else if((customDate.start!=null && customDate.end!=null)){
          let data = {
            start_date: getYYYYMMDDFormat(customDate.end) ,
            end_date: getYYYYMMDDFormat(customDate.start),
            limit:10,
            offset:0,
            currentMonth:month.split(" ")[0]
          }
          getDbData(data)
        }

    },[month,filterDate,customDate])
    
    useEffect(()=>{
        let newPendingData = pendingAction.fault ? dbData?.pending_fault_report : dbData?.pending_service_request;
        let newHighlightData = highlighted.fault ? dbData?.high_lighted_updates_fault_report : dbData?.high_lighted_updates_service_request

        setHighlightData(newHighlightData)
        setPendingData(newPendingData)
        setEmpData({
            ...empData,
            onboardEmpCount:dbData?.onboarding_employees_count,
            offboardEmpCount:dbData?.offboarding_employees_count,
            onboardEmp:dbData?.onboarding_employees,
            offboardEmp:dbData?.offboarding_employees,
            totalEmpCount:dbData?.total_employees_count,
            activeEmpCount:dbData?.active_employees_count
        })
    },[pendingAction,highlighted,dbData])


    useEffect(()=>{
        getDateRange()
    },[filterDate])

    const handleClicked = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleMonthChange = (e) => {
      setMonth(moment(e).format("MMMM"));
    };
    
    const getDateRange = ()=>{
        const today = new Date()
        const previousDate = new Date()

        if(filterDate=="Today"){
            setRangeDate(today)
        }else if(filterDate=="Yesterday"){
            previousDate.setDate(previousDate.getDate()-1)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 7 days"){
            previousDate.setDate(previousDate.getDate()-7)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 30 days"){
            previousDate.setDate(previousDate.getDate()-30)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 90 days"){
            previousDate.setDate(previousDate.getDate()-90)
            setRangeDate(previousDate)
        }else if(filterDate=="Last 1 year"){
            previousDate.setDate(previousDate.getDate()-365)
            setRangeDate(previousDate)
        }
    }

    const renderCountList = (totalName,totalCount,listoneName,listoneCount,listtwoName,listtwoCount,listthreeName,listthreeCount)=>{
        return(
          <>
          <List>
              <ListItem secondaryAction={<StyledPrimaryTypography>{totalCount}</StyledPrimaryTypography>}>
                  <ListItemText>
                      <StyledPrimaryTypography>{totalName}</StyledPrimaryTypography>
                  </ListItemText>
              </ListItem>
          </List>
          <Divider sx={{border:"1px solid #DBDFE9"}} />
          <List>
                <ListItem secondaryAction={<StyledSecTypography>{listoneCount}</StyledSecTypography>}>
                      <ListItemIcon>
                          <Box sx={{height:"8px",width:"8px",backgroundColor:"#0F2C6F",borderRadius:"45%"}}>
                          </Box>
                      </ListItemIcon>
                      <ListItemText>
                              <StyledSecTypography>{listoneName}</StyledSecTypography>
                     </ListItemText>
                </ListItem>
                <Divider sx={{border:"1px solid #DBDFE9"}} />
                <ListItem secondaryAction={<StyledSecTypography>{listtwoCount}</StyledSecTypography>}>
                      <ListItemIcon>
                          <Box sx={{height:"8px",width:"8px",backgroundColor:"#2764F4",borderRadius:"45%"}}>
                          </Box>
                       </ListItemIcon>
                      <ListItemText>
                           <StyledSecTypography>{listtwoName}</StyledSecTypography>
                      </ListItemText>
                </ListItem>
                <Divider sx={{border:"1px solid #DBDFE9"}} />
                <ListItem secondaryAction={<StyledSecTypography>{listthreeCount}</StyledSecTypography>}>
                      <ListItemIcon>
                            <Box sx={{height:"8px",width:"8px",backgroundColor:"#AAC3FF",borderRadius:"45%"}}>
                            </Box>
                      </ListItemIcon>
                      <ListItemText>
                            <StyledSecTypography>{listthreeName}</StyledSecTypography>
                       </ListItemText>
                </ListItem>
          </List>
          </>
        )
      }
    return(
        <>
        <Box sx={{pt:7,px:3,backgroundColor:"rgba(143, 146, 161,0.05)"}}>
            <Box sx={{display:"flex",justifyContent:"space-between",mb:4}}>
                <Typography sx={{color:"#002D74",fontSize:"32px",textTransform:"capitalize"}}>Welcome {getLoggedUserData()?.user_name}</Typography>
                <Box sx={{display:'flex'}}>
                    <Select defaultValue={"Last 90 days"} value={filterDate} sx={{width:150,mr:3,color:"#0F2C6F"}} onChange={(e)=>setFilterDate(e.target.value)}>
                        {SelectDays.map((value, index)=>(
                            <MenuItem  key={index} value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                    {filterDate=="Custom" ? 
                      <>
                        <Box sx={{width:150}}>
                          <DatePicker inputFormat="dd MMM yyyy" disableFuture  value={customDate?.start}  components={{
                              OpenPickerIcon:DatePickerIcon
                          }} onChange={(e)=>setCustomDate({
                            ...customDate,
                            start:e
                          })} renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                        </Box>
                        <Typography sx={{display:"flex",alignItems:"center",mx:0.5}}> - </Typography>
                        <Box sx={{width:150}}>
                            <DatePicker minDate={customDate.start} onChange={(e)=>setCustomDate({
                            ...customDate,
                            end:e
                            })} disableFuture   components={{
                              OpenPickerIcon:DatePickerIcon
                          }} inputFormat="dd MMM yyyy" value={customDate?.end}  renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)}  {...params} />} />
                        </Box>
                      </> : 
                      <>
                            <Box sx={{width:120}}>
                                <DatePicker disableOpenPicker  disabled value={rangeDate}  components={{
                                    OpenPickerIcon:DatePickerIcon
                                }} renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                            </Box>
                            <Typography sx={{display:"flex",alignItems:"center",mx:0.5}}> - </Typography>
                            <Box sx={{width:120}}>
                                <DatePicker  disableOpenPicker disabled renderInput={(params)=><TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} />
                            </Box>
                      </>
                    }
                    
                </Box>
            </Box>
            
            {/* <Box sx={{border:"1px solid #2764F4",borderRadius:"6px",display:"flex",justifyContent:"space-between",my:2,px:3,py:1}}>
                <Typography sx={{color:"#2764F4",fontSize:"14px"}}> <NotifyIcon style={{marginTop:"8px",marginRight:'18px'}} /> 
                    There will be fire drill on 30 October 2022, please be prepare to be at the meeting point of the building.
                </Typography>
                <Button variant="text" sx={{textTransform:"capitalize",textDecoration:'underline'}}>View All</Button>
            </Box> */}

            <Grid container >
                <Grid item xs={8} >
                    <Grid container >
                    <Grid item xs={11.7} >
                        <Box sx={{backgroundColor:"#FFFFFF",p:2,borderRadius:"8px",boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.05)"}}>
                            <StyledPrimaryTypography>Pending Action</StyledPrimaryTypography>
                            <StyledSecTypography>from BMS (external)</StyledSecTypography>
                            <Box sx={{display:"flex",my:2}}>
                                {/* <Chip onClick={()=>{
                                    setPendingAction({
                                        onboarding:true,
                                        fault:false,
                                        service:false
                                    })
                                }} label="Onboarding" sx={pendingAction.onboarding ? uncheckedStyle : checkedStyle}/> */}
                                <Chip onClick={()=>{
                                    setPendingAction({
                                        onboarding:false,
                                        fault:true,
                                        service:false
                                    })
                                }} label="Fault reports" sx={pendingAction.fault ? uncheckedStyle : checkedStyle} />
                                <Chip onClick={()=>{
                                    setPendingAction({
                                        onboarding:false,
                                        fault:false,
                                        service:true
                                    })
                                }} label="Service request" sx={pendingAction.service ? uncheckedStyle : checkedStyle} />
                            </Box>
                            <Box sx={{minHeight:350,maxHeight:350,overflowY:"auto",mt:2}}>
                               
                                {pendingData?.length>0 ?  pendingData?.map((value)=>(
                                <Box onClick={()=>{
                                    if(pendingAction.fault){
                                        navigate(`/review_fault/${value.fault_report_id}/0/true`)
                                    }else if(pendingAction.service){
                                        navigate(`/review_service_request/${value.service_requests_id}/${value.service_request_types_id}/0/true`)
                                    }
                                }} sx={{cursor:'pointer',border:"1px solid #DBDFE9",p:2,my:1,mr:1,borderRadius:"8px"}}>
                                    <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                        <Typography sx={{color:" #2764F4",fontSize:"14px"}}>{pendingAction.fault ? 'Fault Report' : 'Service Request'}</Typography>
                                        <Chip sx={{borderRadius:"6px",color:getStatus(value.status,"color"),backgroundColor:getStatus(value.status,"bg")}} label={getStatus(value.status,"text")} />
                                    </Box>
                                    <Typography sx={{color:"#6F80A9",fontSize:"12px ",textTransform:"capitalize"}}>{pendingAction.fault ?  value.ticket_no : value.ticket_id}</Typography>
                                    <Box sx={{display:"flex",justifyContent:"space-between",mt:1}}>
                                        <Box>
                                            <StyledPrimaryTypography>{pendingAction.fault ? value.fault_title : value.display_name}</StyledPrimaryTypography>
                                            <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>{moment(value.created_at).format("DD MMM YYYY ")}</Typography>
                                        </Box>
                                        <KeyboardArrowRightIcon style={{marginTop:"16px"}} />
                                    </Box>
                                </Box>
                                )) : 
                                <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:300}}>
                                    <PriorityHighTwoToneIcon />
                                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>You are caught up!</Typography>
                                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Nothing to approve at the moment.</Typography>
                                </Box>
                            }

                            </Box>
                            
                        </Box>
                    </Grid>

                    {/* <Grid item xs={5.8} sx={{backgroundColor:"#FFFFFF",ml:1.5}} >
                        <Box sx={{p:2,borderRadius:"8px",boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.05)"}}>
                        <StyledPrimaryTypography>Internal Approval</StyledPrimaryTypography>
                        <StyledSecTypography>Internal</StyledSecTypography>
                        <Box sx={{display:"flex",my:2}}>
                            <Chip onClick={()=>{
                                setInternalApproval({
                                    ...internalApproval,
                                    onboarding:true,
                                    service:false
                                })
                            }} label="Onboarding"  sx={internalApproval.onboarding ? uncheckedStyle : checkedStyle} />
                            <Chip onClick={()=>{
                                setInternalApproval({
                                    ...internalApproval,
                                    onboarding:false,
                                    service:true
                                })
                            }}  label="Service request"  sx={internalApproval.service ? uncheckedStyle : checkedStyle} />
                        </Box>
                        <Box sx={{height:350,overflowY:"auto"}}>
                            <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:300}}>
                                <PriorityHighTwoToneIcon />
                                <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>You are all caught up!</Typography>
                                <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>nothing to approved at the moment</Typography>
                            </Box>
                            
                        </Box>
                        </Box>
                    </Grid> */}

                    <Grid item xs={11.7} sx={{minHeight:740,backgroundColor:"#FFFFFF",my:2,p:2,borderRadius:"8px",boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.05)",overflowY:"auto   "}}>
                        <Box sx={{display:"flex",justifyContent:"space-between",mb:2}}>
                            <StyledPrimaryTypography>Employees</StyledPrimaryTypography>
                            <Button variant="outlined" sx={{color:"#0F2C6F",fontSize:"14px",textTransform:"capitalize"}} onClick={()=>navigate(`/manage_employees/0`)}>View Detail</Button>
                        </Box>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                                    <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: "none",
                                        display: "flex",
                                        gap: "5px",
                                        fontSize: { md: "14px", sm: "12px", xs: "10px" },
                                        padding: { md: "6px  12px", xs: "4px 8px" },
                                        fontWeight: 700,
                                        color: "#0F2C6F",
                                        background: "rgba(143, 146, 161, 0.05)",
                                        alignItems: "center",
                                    }}
                                    onClick={handleClicked} >
                                    {month}
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                <MenuItem onClick={handleClose}>
                                    <MonthPicker
                                        onChange={(e) => handleMonthChange(e)}
                                        sx={{ width: "120px" }}
                                    />
                                </MenuItem>
                                </Menu>
                                <Box sx={{borderBottom:"1px solid black",display:"flex",justifyContent:"space-between",mt:1}}>
                                    <Typography sx={{color:"#33B7A5",fontSize:'14px',fontWeight:600,mb:1}}>Onboarding</Typography>
                                    <Typography sx={{color:"#33B7A5",fontSize:'14px'}}>{empData.onboardEmpCount}</Typography>
                                </Box>
                                <Box>
                                    {
                                        empData.onboardEmpCount>0 ? 
                                        <>
                                            {empData?.onboardEmp?.map((value)=>(
                                                <Typography sx={{color:"#0F2C6F",fontSize:'14px',my:0.5}}>{`${value.first_name} ${value.last_name}`}</Typography>
                                            ))}
                                        </> : 
                                        <Box sx={{p:7}}>
                                            <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>There is no upcoming onboarding in this month.</Typography>
                                        </Box>
                                    }
                                    
                                    
                                </Box>
                                
                            </Grid>
                            <Grid item xs={6}>
                                    <Chart type="donut" series={chart.series} options={chart.options} ></Chart>
                            </Grid>
                            <Grid item xs={6}>
                                <Box sx={{borderBottom:"1px solid black",display:"flex",justifyContent:"space-between"}}>
                                    <Typography sx={{color:"#BD0251",fontSize:"14px",fontWeight:600,mb:1}}>Offboarding</Typography>
                                    <Typography sx={{color:"#BD0251",fontSize:"14px"}}>{empData.offboardEmpCount}</Typography>
                                </Box>
                                <Box>
                                    {
                                        empData.offboardEmpCount>0 ? 
                                        <>
                                            {empData?.offboardEmp?.map((value)=>(
                                                <Typography sx={{color:"#0F2C6F",fontSize:'14px',my:0.5}}>{`${value.first_name} ${value.last_name}`}</Typography>
                                            ))}
                                        </> : 
                                        <Box sx={{p:7}}>
                                            <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>There is no upcoming offboarding in this month.</Typography>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                {renderCountList("Total",empData.totalEmpCount,"Active",empData.activeEmpCount,"Onboarding",empData.onboardEmpCount,"Offboarding",empData.offboardEmpCount)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
             
                <Grid item xs={4} sx={{backgroundColor:"#FFFFFF",mb:4,minHeight:1250,maxHeight:1250}}>
                <Box sx={{p:2,borderRadius:"8px",boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.05)"}}>
                    <Box sx={{display:"flex",justifyContent:"space-between"}}>
                        <Box>
                            <StyledPrimaryTypography>Highlighted Updates</StyledPrimaryTypography>
                            <StyledSecTypography>Item you highilighted</StyledSecTypography>
                        </Box>
                       <Button onClick={()=>{
                        if(highlighted.fault){
                            navigate(`/fault_reporting/0`)
                        }else{
                            navigate(`/service_request_list/0`)
                        }
                        
                       }} variant="outlined" sx={{color:"#0F2C6F",borderRadius:"6px",backgroundColor:"#FFFFFF",fontSize:"13px",fontWeight:550,textTransform:"capitalize",height:"40px"}}>View All</Button>
                    </Box>
                   
                    <Box sx={{display:"flex",my:2}}>
                        <Chip onClick={()=>{
                            setHighlighted({
                                ...highlighted,
                                fault:true,
                                service:false
                            })
                        }} label="Fault reports" sx={highlighted.fault ? uncheckedStyle : checkedStyle}/>
                        <Chip onClick={()=>{
                            setHighlighted({
                                ...highlighted,
                                fault:false,
                                service:true
                            })
                        }} label="Service request" sx={highlighted.service ? uncheckedStyle : checkedStyle} />
                    </Box>
                    <Box sx={{minHeight:1100,maxHeight:1100,overflowY:"auto"}}>
                            {highlightData?.length>0 ?  highlightData?.map((value)=>(
                                <Box onClick={()=>{
                                    if(highlighted.fault){
                                        navigate(`/review_fault/${value.fault_report_id}/0/true`)
                                    }else if(highlighted.service){
                                        navigate(`/review_service_request/${value.service_requests_id}/${value.service_request_types_id}/0/true`)
                                    }
                                }} sx={{cursor:'pointer',border:"1px solid #DBDFE9",p:2,my:1,mr:1,borderRadius:"8px"}}>
                                    <Box sx={{display:"flex",justifyContent:"space-between"}}>
                                        <Typography sx={{color:" #2764F4",fontSize:"14px"}}>{highlighted.fault ? "Fault Report" : "Service Request"}</Typography>
                                        <Chip sx={{borderRadius:"6px",color:getStatus(value.status,"color"),backgroundColor:getStatus(value.status,"bg")}} label={getStatus(value.status,"text")} />
                                    </Box>
                                    <Typography sx={{color:"#6F80A9",fontSize:"12px",textTransform:"capitalize"}}>{highlighted.fault ? value.ticket_no : value.ticket_id}</Typography>
                                    <Box sx={{display:"flex",justifyContent:"space-between",mt:1}}>
                                        <Box>
                                            <StyledPrimaryTypography>{highlighted.fault ? value.fault_title : value. display_name}</StyledPrimaryTypography>
                                            <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>{moment(value.created_at).format("DD MMM YYYY ")}</Typography>
                                        </Box>
                                        <KeyboardArrowRightIcon  style={{marginTop:"16px"}} />
                                    </Box>
                                </Box>
                                )) : 
                                <Box sx={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:300}}>
                                    <PriorityHighTwoToneIcon />
                                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>You are caught up!</Typography>
                                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Nothing to approve at the moment.</Typography>
                                </Box>
                                
                            }

                    </Box>
                </Box>
                </Grid>
            </Grid>    
        </Box>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        dbData:state.DashboardReducer.data,

    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getDbData:(data)=>dispatch(DashboardAction.getDbData(data)),

    }
}
export default connect(mapStateToProps,mapDispatchToProps)(TenantDashboard)