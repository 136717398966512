import React from "react";
import { bool, func } from "prop-types";
import {ReactComponent as CarouselRightArrow} from '../../../Assets/icons/CarouselRightArrow.svg'

const CustomRightNav = React.memo(({ disabled, onClick }) => {
  return (
    <button
      type="button"
      style={{zIndex:1}}
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Next Slide"
    >
      <CarouselRightArrow  />
    </button>
  );
});

CustomRightNav.displayName = "CustomRightNav";

CustomRightNav.propTypes = {
  disabled: bool.isRequired,
  onClick: func.isRequired,
};

export default CustomRightNav;
