import { Box, Button, Checkbox, Fade, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Modal, OutlinedInput, Pagination, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Events_Promotions_Active_Columns,Events_Promotions_Archived_Columns,Events_Promotions_Schedule_Columns,Events_Promotions_Dratf_Columns } from '../../components/Data/Data';
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxIcon.svg';
import moment from 'moment';
import ModalView from './ModalView';
import { ReactComponent as MoreIcons } from '../../Assets/icons/more-alt.svg';
import { connect } from 'react-redux';
import * as eventsAndPromotionAction from '../../redux/actions/EventsAndPromotionAction'
import ToastAlert from '../common/Alert';
import { useNavigate } from 'react-router-dom';
import { BootstrapDialog } from '../FacilityBooking/SingleRoomFeature';
import { ReactComponent as CloseIcon } from '../../Assets/icons/AgreementCloseIcon.svg';

const StyledTableCell = styled(TableCell)({
  color:"#0F2C6F",
  fontSize:'13px',
  fontWeight:550
})

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)":{
    backgroundColor: '#F5F8FF'
  },
  cursor:'pointer'
})

const StyledBodyCell = styled(TableCell)({
  color:"#0F2C6F",
  fontSize:'14px',
  fontWeight:500
})


const EventsAndPromotionsList = ({activeType,tableData,tableDataCount,fetchDataPagignation,currentPage, tabValue,eventAndPromotionsInactiveStatus, updateEventsAndPromotionsInactive, updateEventsAndPromotionsInactiveStateNull, getPromotionEventList})=> {
  const navigate = useNavigate()
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedId, setSelectedId] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [snackbarStatus, setSnackbarStatus] = React.useState({ message: '', status: false, color: '', icon: '' }); 
  const [menuText,setMenuText] = useState('In-active')
  const [envType,setEnvType] = useState('')
  const optionsOpen = Boolean(anchorEl);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const [open, setOpen] = React.useState(false);
  console.log('optionsOpen current ',optionsOpen);
  useEffect(() => {
    if(eventAndPromotionsInactiveStatus !== null && eventAndPromotionsInactiveStatus?.response_status === 'success'){
      setSnackbarStatus({ message: eventAndPromotionsInactiveStatus.response_message, status: true, color: 'green', icon: 'tick' })
      let data={
        TabStatus:"Active",
        limit:10,
        offset:0,
        search:"",
        date: null,
        type:"",
    }
    getPromotionEventList(data);
    updateEventsAndPromotionsInactiveStateNull();

      setTimeout(() => {
        setSnackbarStatus({message: '', status: false, color: '', icon: ''})
    }, 3000)
    }
  }, [eventAndPromotionsInactiveStatus])

  const handleSelectRowClick = (data) => {
    handleModalOpen();
    setSelectedRow(data);
  }

  const handlePagination = (e,value)=>{
    fetchDataPagignation(value)
  }

  const handleClick = (event, id,name,type) => {
    console.log('event ',event.currentTarget);
    setSelectedId(id);
   setAnchorEl(event.currentTarget);
   setMenuText(name)
   console.log('type is ',event);
   setEnvType(type)
   localStorage.setItem('currentScreen',tabValue)
 };

 const handleOptionClose = () => {                    
   setAnchorEl(null);
 };

 const handleStatusInactive = () => {
  handleOptionClose();
  updateEventsAndPromotionsInactive({id: Number(selectedId), status: 'Inactive'})
 }

  const handleReopen = ()=>{
    if(envType=='Event'){
      navigate(`/create_event/${selectedId}/true/false`)
    }else{
        navigate(`/create_promotion/${selectedId}/true/false`)
    }
  }

  const getColumns = ()=>{
    let  column = activeType=="all" ? Events_Promotions_Active_Columns : activeType=="closed" ? Events_Promotions_Archived_Columns : 
                   activeType=="schedule" ? Events_Promotions_Archived_Columns: Events_Promotions_Dratf_Columns
   
   return  column.map((value)=>{
      return <>
        <StyledTableCell>{value.column_name=="Checkbox" ? <Checkbox icon={<CheckboxIcon />} /> : value.column_name}</StyledTableCell>
      </>
    })
  }

  const handleClose = () => {
    setOpen(false);
    handleOptionClose();
};

const handleOpen = () => {
  setOpen(true);
};

const MakeInactive = () => {
  handleStatusInactive();
  setOpen(false);
}

  const AgreementModal = () => {
    return(
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      > <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
      Do you want to In-active?
      </DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent dividers>
            {/* <Typography sx={{mb:3, color:'#6F80A9',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>Do you want to In-active?</Typography> */}
           
         
            <Box sx={{display:'flex', justifyContent:'space-between', gap:'10px',}}>
                <Button variant='outlined' sx={{ textTransform: 'capitalize', color: '#0F2C6F',borderRadius: '8px', minWidth:'50px',  height: '40px',}} onClick={handleClose}>Cancel</Button>
                <Button variant='contained' sx={{textTransform: 'capitalize',color: '#FFFFFF', borderRadius: '8px', minWidth:'50px',  height: '40px', backgroundColor: '#123FA9', '&:hover': { backgroundColor: '#123FA9' }}} onClick={MakeInactive}>Yes</Button>
            </Box>
        </DialogContent>

      </BootstrapDialog>
    )
}
console.log('tabValue ',tabValue);

  const getTableDatas = ()=>{
    if(activeType=='Draft'){
      return tableData.map((value)=>(
        <StyledTableRow onClick={()=>{
            if(value.env_type=='Event'){
              navigate(`/create_event/${value.eve_pro_id}/false/false`)
            }else{
                navigate(`/create_promotion/${value.eve_pro_id}/false/false`)
            }
        }}>
          <StyledBodyCell>{moment.utc(value.created_at).local().format("DD MMM YYYY")}</StyledBodyCell>
          <StyledBodyCell sx={{maxWidth:150,overflow:"hidden",textOverflow:'ellipsis' }}>{value.title}</StyledBodyCell>
          <StyledBodyCell>{value.schedule_from_datetime !== null ? moment(value.schedule_from_datetime).format("DD MMM YYYY") : ""}</StyledBodyCell>
          <StyledBodyCell>{value?.dis_env_type}</StyledBodyCell>
          <StyledBodyCell>{value.property}</StyledBodyCell>
          <StyledBodyCell></StyledBodyCell>
        </StyledTableRow>
      ))
    }else if(activeType=='closed'){
      return tableData.map((value)=>(
        <StyledTableRow>
          {/* <TableCell><Checkbox icon={<CheckboxIcon />} /></TableCell> */}
          <StyledBodyCell>{moment(value.schedule_from_datetime).format("DD MMM YYYY")}</StyledBodyCell> 
          <StyledBodyCell onClick={() => handleSelectRowClick(value)} sx={{maxWidth:150,overflow:"hidden",textDecoration: 'underline',textUnderlineOffset: "4px",textOverflow:'ellipsis' }}>{value.title}</StyledBodyCell>
          <StyledBodyCell>{moment(value.schedule_to_datetime).format("DD MMM YYYY")}</StyledBodyCell>
          <StyledBodyCell>{value.submitted_by}</StyledBodyCell>
          <StyledBodyCell>{value?.dis_env_type}</StyledBodyCell>
          <StyledBodyCell>{value.name !== null ? value.name : '-' }</StyledBodyCell>
           <StyledBodyCell><IconButton><MoreIcons onClick={(e) => handleClick(e, value.eve_pro_id,'Re-open',value.env_type)} /></IconButton></StyledBodyCell>
        </StyledTableRow>
      ))
    }else{
      return tableData.map((value)=>(
        <StyledTableRow>
          {/* <TableCell><Checkbox icon={<CheckboxIcon />} /></TableCell> */}
          <StyledBodyCell>{moment(value.schedule_from_datetime).format("DD MMM YYYY")}</StyledBodyCell> 
          <StyledBodyCell onClick={() => handleSelectRowClick(value)} sx={{maxWidth:150,overflow:"hidden",textDecoration: 'underline',textUnderlineOffset: "4px",textOverflow:'ellipsis' }}>{value.title}</StyledBodyCell>
          <StyledBodyCell>{moment(value.schedule_to_datetime).format("DD MMM YYYY")}</StyledBodyCell>
          {activeType == "all" && <StyledBodyCell sx={{textAlign:"center"}}>{value?.eve_promo_reads_count}</StyledBodyCell>}
          <StyledBodyCell>{value.submitted_by}</StyledBodyCell>
          <StyledBodyCell>{value?.dis_env_type}</StyledBodyCell>
          <StyledBodyCell>{value.name !== null ? value.name : '-' }</StyledBodyCell>
          {(tabValue === 0 || tabValue === 1)  && <StyledBodyCell><IconButton><MoreIcons onClick={(e) => 
                     {
                      
            handleClick(e, value.eve_pro_id,'In-active', value.env_type)
                     }
            } /></IconButton></StyledBodyCell>}
        </StyledTableRow>
      ))
    }
  
  }
  console.log('outside current ',anchorEl);
  
  return (
    <>    <AgreementModal />
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />

{modalOpen &&
<ModalView modelOpen={modalOpen} handleModalClose={handleModalClose} item={selectedRow}/> }

    <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={optionsOpen}
        onClose={handleOptionClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={() =>menuText=='In-active' ?  handleOpen() : handleReopen()} sx={{margin: "0 20px"}}>{menuText}</MenuItem>        
        {menuText=='In-active' &&  <MenuItem onClick={()=>{
          console.log('envType ',envType, " eventype ",selectedId);
          if(envType=='Event'){
            navigate(`/edit_event/${selectedId}/false/true`)
          }else{
            navigate(`/edit_promotion/${selectedId}/false/true`)
          }
        }} sx={{margin: "0 20px"}}>Edit Post</MenuItem> }
      </Menu>
   
      <Box sx={{backgroundColor:"#FFFFFF"}}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                  {getColumns()}
              </TableRow>
            </TableHead>
            {tableData?.length > 0 && 
            <TableBody>
                {getTableDatas()}
            </TableBody>
            }
          </Table>
        </TableContainer>
        {tableData?.length<1 && 
          <Box sx={{display:"flex",justifyContent:"center",m:5}}>
               <Typography sx={{textAlign:"center",fontSize:"20px",color:'#0F2C6F'}}>No data found</Typography>
          </Box>
        }

       {tableDataCount>0 &&  <Pagination page={currentPage} sx={{display:"flex",justifyContent:"center",py:3,backgroundColor:"rgba(143, 146, 161, 0.05)" }} count={Math.ceil(tableDataCount/10)} size="large" shape='rounded' onChange={handlePagination}  /> }
      </Box> 
     
    </>
  )
}

const mapStateToProps = (state) => {
  return {
      eventAndPromotionsInactiveStatus:state.eAndpReducer.eventAndPromotionInactiveStatus,
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
      updateEventsAndPromotionsInactive:(data)=>{dispatch(eventsAndPromotionAction.updateEventsPromotionInactive(data))},
      updateEventsAndPromotionsInactiveStateNull:()=>{dispatch(eventsAndPromotionAction.SETeANDpStateToNULL())},
      getPromotionEventList:(data)=>{dispatch(eventsAndPromotionAction.getPromotionEventList(data))},
      
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsAndPromotionsList)
