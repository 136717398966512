import { Box, Button, Checkbox, Chip, Divider, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { ReactComponent as NotificationIcon } from '../../../Assets/icons/OutlinedNotification.svg';
import { ReactComponent as DownloadIcon } from '../../../Assets/icons/DownloadIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../Assets/icons/DeleteExcel.svg';
import { ReactComponent as PdfIcon } from '../../../Assets/icons/PDFIcon.svg';
import { useNavigate, useParams } from "react-router-dom";
import DropZone from "../../../Utils/dropzone";
import { connect } from "react-redux";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import ToastAlert from "../../common/Alert";
import Loader from "../../common/Loader";
import { getUTCDateFormat, getUTCTimeFormat } from "../../../Utils/utils";
import moment from "moment";
const StyledTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '12px'
})

const StyledPrimaryTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '18px'
})

const PreviewLift = ({ hidePreview,reviewData, tenantData,addServiceReqStatus, createCargoLiftRequest, setServiceRequestStateNull,createCargoLiftRequestAsDraft,updateLiftDraft,updateLiftDraftStatus,setFromPreviewPage }) => {
    let {reqId} = useParams()
    const navigate = useNavigate();
    let { personInCharge, tenantCompany, office, mobile } = tenantData;
    console.log(office ,'offie' , mobile ,'mobile')
    const [selectedfile, setSelectedFile] = useState([]);
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [loaderStatus,setLoaderStatus] = useState(false);

    useEffect(() => {
        setLoaderStatus(false)
        if (addServiceReqStatus?.response_status == 1) {
            setSnackbarStatus({ message: addServiceReqStatus?.message, status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate(`/service_request_list/0`)
            }, 5000);
        } else if (addServiceReqStatus !== null) {
            setSnackbarStatus({ message: addServiceReqStatus?.message, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 5000);
        }
    }, [addServiceReqStatus])

    useEffect(()=>{
        setLoaderStatus(false)
        if(updateLiftDraftStatus?.response_status==1){
            setSnackbarStatus({ message: updateLiftDraftStatus?.message, status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate(`/service_request_list/0`)
            }, 5000);
        }else if (updateLiftDraftStatus?.response_status==0) {
            setSnackbarStatus({ message:updateLiftDraftStatus?.message, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 5000);
        }
    },[updateLiftDraftStatus])

    const removeSnackbar = () => {
        setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
    }

    const uploadPdf = (file) => {
        
        setSelectedFile([file[0]]);
    }

    const handleSubmit = (submissionType) => {
        let toSubmitData = reviewData;
        
        let toSubmitObjectiveId  ='';
        for(let i=0;i<toSubmitData.liftPurpose.length;i++){
            toSubmitObjectiveId+=`${toSubmitData.liftPurpose[i].cargo_objectives_id},`
        }
        
        let data = new FormData();
        data.append('company_id',toSubmitData.company_id);
        data.append('contractor_phone_code',toSubmitData.mobile_code)
        data.append('contractor_phone_no',toSubmitData.mobile_number)
        data.append('office_phone_code',toSubmitData.office_code)
        data.append('office_phone_no',toSubmitData.office_number)
        data.append('description',toSubmitData.workDetails)
        data.append('from_date',toSubmitData.date)
        // data.append('from_date',getUTCDateFormat(new Date(toSubmitData.date)))
        data.append('to_date',"NA")    
        data.append('from_time',toSubmitData.startTime)
        data.append('to_time',toSubmitData.endTime) 
        // data.append('from_time',getUTCTimeFormat(toSubmitData.date,toSubmitData.startTime))
        // data.append('to_time',getUTCTimeFormat(toSubmitData.date,toSubmitData.endTime))
        data.append('person_in_charge',toSubmitData.personInCharge)
        data.append('contractor_company',toSubmitData.contractorCompany)
        data.append('service_request_types_id',toSubmitData.service_request_types_id)
        data.append('status',submissionType=='submitted' ? 'submitted' : 'draft')
        data.append('cargo_objectives_id',toSubmitObjectiveId)
        data.append('file0',selectedfile[0]?.file)
        data.append('file_count',selectedfile[0]?.file?'1':'0')
        if(reqId!='null'){
            data.append('service_requests_id',reqId)
        }

        if(reqId=='null'){
            if (submissionType==='submitted' ) { 
                createCargoLiftRequest(data)
                setLoaderStatus(true)
                setTimeout(()=>{
                    setLoaderStatus(false)
                },15000)
            }else{
                createCargoLiftRequestAsDraft(data)
                setLoaderStatus(true)
                setTimeout(()=>{
                    setLoaderStatus(false)
                },15000)
            }
        }else{
            updateLiftDraft(data)
            setLoaderStatus(true)
            setTimeout(()=>{
                setLoaderStatus(false)
            },15000)
        }
        
    }

    return (
        <>
        {loaderStatus && <Loader status={loaderStatus} />}
          <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
            <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={1} />
                <Grid item xs={8}>
                    <Box sx={{ mt: 7, mb: 4 }}>
                        <ArrowIcon cursor='pointer' onClick={() =>{
                            setFromPreviewPage({lift:true,security:false,airCon:false})
                            hidePreview(false)
                            }} />
                        <Typography sx={{ fontSize: '26px', color: '#0F2C6F', mt: 2, mb: 1 }}>Service request- Use of Cargo Lift   </Typography>

                        {/* <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>Requested on 13 Jun 2022</Typography> */}
                       
                    </Box>
                </Grid>
                <Grid item xs={3} />
            </Grid>

            <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={1} />

                <Grid item xs={8}>

                    <Box sx={{ backgroundColor: '#FFFFFF', p: 3 }}>
                        <StyledPrimaryTypography >Tenant Details</StyledPrimaryTypography>
                        <Grid container sx={{ my: 3, pb: 3 }} >
                            <Grid item xs={12} sm={4}>
                                <Typography sx={{ color: ' #0F2C6F', fontSize: '14px' }}>{personInCharge}</Typography>
                                <StyledTypography>{tenantCompany}</StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <StyledTypography>Office : {office}</StyledTypography>
                                <StyledTypography>Mobile : {mobile}</StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                {/* <Button onClick={downloadPDF} sx={{ color: '#0F2C6F', textTransform: 'capitalize', backgroundColor: '#CFDDFE', borderRadius: '6px', '&:hover': { backgroundColor: '#CFDDFE' } }} endIcon={<DownloadIcon />}>Download</Button> */}
                            </Grid>
                        </Grid>

                        <Grid container rowSpacing={5}>
                            <Grid item xs={12} sm={4}>
                                <StyledPrimaryTypography>Contractor Details</StyledPrimaryTypography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <StyledTypography>{reviewData.personInCharge}</StyledTypography>
                                <StyledTypography>{reviewData.contractorCompany}</StyledTypography>
                                <StyledTypography>Office:+{reviewData.office_code} {reviewData.office_number}</StyledTypography>
                                <StyledTypography>Mobile:+{reviewData.mobile_code} {reviewData.mobile_number}</StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={3.5} >
                                <StyledPrimaryTypography>Cargo Lift Requesting Period</StyledPrimaryTypography>
                            </Grid>
                            <Grid item xs={12} sm={0.5}></Grid>
                            <Grid item xs={12} sm={4} >
                                <StyledPrimaryTypography>Preferably Work Period</StyledPrimaryTypography>
                                <StyledTypography>{moment(reviewData?.date).format('DD-MM-YYYY')}</StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <StyledTypography>Start Time: {reviewData.startTime}</StyledTypography>
                                <StyledTypography>End Time: {reviewData.endTime}</StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={4} >
                                <StyledPrimaryTypography>Purpose</StyledPrimaryTypography>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                {
                                    reviewData?.liftPurpose && reviewData.liftPurpose.map((purpose) => (
                                        <StyledTypography sx={{fontSize:'14px'}}>{purpose.objective}</StyledTypography>
                                    ))
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <StyledPrimaryTypography>Work Details</StyledPrimaryTypography>
                                <StyledTypography sx={{mt:1,fontSize:"14px"}}>{reviewData?.workDetails}</StyledTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <Box >
                                    <StyledPrimaryTypography >Specification of Cargo Lift</StyledPrimaryTypography>
                                    <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: '5px' }}>In order to use cargo lift,please follow this specification.</Typography>
                                    <Grid container sx={{ border: '1px solid #DBDFE9', borderRadius: "6px", padding: '16px 24px', mt: 3, background: '#F3F4F8' }}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0F2C6F' }}>Maximum Load:  <span style={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>1,600 kg</span></Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: '#0F2C6F' }}>Speed: <span style={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>210mps</span> </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0F2C6F' }}>Width:  <span style={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>2.1m x Depth 1.7m</span></Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0F2C6F' }}>Height : <span style={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>3.5m </span></Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: '#0F2C6F' }}>Door Opening : <span style={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>1.5m x 2.1m</span></Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography sx={{ color: '#6F80A9',mt:1 }}>*Do not Load any material/equipment beyond the specified size and weight capacity of the lift.</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{mt:2}}>
                        <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>{reviewData.terms_condition[0].title}</Typography>
                        <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{reviewData.terms_condition[0].description}</Typography>
                        {reviewData.terms_condition[1].rules.map((value,index)=>(
                             <Typography sx={{color:"#6F80A9",fontSize:'12px',my:1}}>{`${index+1} . ${value[index+1]}`}</Typography>
                        ))}
                        
                        
                        <Box sx={{display:"flex"}}>
                            <Checkbox defaultChecked  disabled/>
                            <Typography sx={{color:"#9AA6C3",fontSize:"14px",mt:2}}>I have read and agreed to comply with the above terms and conditions for holding the event in the Building upon the Management’s approval.</Typography>
                        </Box>
                        </Box>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"flex-end",width:"100%",my:2}}>
                            <Button fullWidth variant="outlined" sx={{height:'50px',textTransform: 'capitalize', color: '#0F2C6F',width:140,mr:2,border:'1px solid #AAC3FF','&:hover':{backgroundColor:'#FFFFFF'} }} onClick={() => handleSubmit('draft')}>Save as Draft</Button>
                            <Button fullWidth variant="contained"  sx={{height:'50px', width:100, textTransform: 'capitalize' }} onClick={() => handleSubmit('submitted')}>Submit</Button>
                    </Box>
                </Grid>

                {/* <Grid item xs={3}>
                    <Box sx={{ backgroundColor: '#FFFFFF', mx: 3 }}>
                        <Box sx={{ p: 1.5 }}>
                            <StyledPrimaryTypography >Contractor Signature</StyledPrimaryTypography>
                        </Box>
                        <Divider />
                        <Box sx={{ px: 1.5, py: 2 }}>
                            <Typography sx={{ mb: 1, color: '#0F2C6F', fontSize: '14px' }}>Please download the PDF of the filled service request and upload the signed version from Contractor here. </Typography>
                            {
                                selectedfile.length > 0 ?
                                    <Box sx={{ display: 'flex', p: 2, justifyContent: 'space-between', border: '1px solid #0F2C6F', borderRadius: '6px' }}>
                                        <PdfIcon/>
                                        <Box sx={{ml:1}}>
                                            <Typography sx={{ color: '#0F2C6F', fontSize: "14px" }}>{selectedfile[0].fileName}</Typography>
                                            <Typography sx={{ color: '#9AA6C3', fontSize: "12px" }}>{`(${Math.round(selectedfile[0].size / 1000)} of ${Math.round(selectedfile[0].size / 1000)}KB)`}</Typography>
                                        </Box>
                                        <DeleteIcon style={{marginTop:"5px"}} onClick={() => setSelectedFile([])} />

                                    </Box> : <DropZone getFiles={uploadPdf} />
                            }
                            <Button fullWidth variant="outlined" sx={{ my: 2, textTransform: 'capitalize', color: '#0F2C6F',border:'1px solid #AAC3FF','&:hover':{backgroundColor:'#FFFFFF'} }} onClick={() => handleSubmit('draft')}>Save as Draft</Button>
                            <Button fullWidth variant="contained" disabled={selectedfile.length > 0 ? false : true} sx={{ my: 1, textTransform: 'capitalize' }} onClick={() => handleSubmit('submitted')}>Submit</Button>
                        </Box>

                    </Box>
                </Grid> */}
            </Grid>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        addServiceReqStatus: state.Service_Req_Reducer.addServiceReqStatus,
        updateLiftDraftStatus:state.Service_Req_Reducer.updateLiftDraftStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createCargoLiftRequest: (data) => (dispatch(serviceRequestAction.createCargoLiftRequest(data))),
        createCargoLiftRequestAsDraft: (data) => (dispatch(serviceRequestAction.createCargoLiftReqAsDraft(data))),
        setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
        updateLiftDraft:(data)=>(dispatch(serviceRequestAction.updateLiftDraft(data)))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewLift);