import { Box, Button, Checkbox, Grid, Typography } from "@mui/material";
import { ReactComponent as Delete } from "../../Assets/icons/Delete.svg";
import { ReactComponent as BackArrow } from "../../Assets/icons/BackArrow.svg";
import { ReactComponent as Notification } from "../../Assets/icons/Notification.svg";
import { ReactComponent as DownArrow } from "../../Assets/icons/DownArrow.svg";
import { ReactComponent as Recent } from "../../Assets/icons/Recent.svg";

import PreviewImage from "../../Assets/icons/PreviewImage.png";

import React from "react";
import { Link } from "react-router-dom";

const TenantDetails = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [termsCheck, setTermsCheck] = React.useState(false);

  return (
    <Grid
      sx={{
        paddingTop: "135px ",
        background: "#F9FAFA",
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
      }}
      container
    >
      <Grid item lg={2} md={1} sm={1} xs={0.5} />
      <Grid
        item
        sx={{ display: "flex", flexDirection: "column" }}
        lg={8}
        md={10}
        sm={10}
        xs={11}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              alignItems: "center",
              display: "flex",
              background: "#F3F4F8",
              padding: "8px 12px",
              borderRadius: "4px",
            }}
          >
            <BackArrow />{" "}
            <Typography
              sx={{
                ml: "8px",
                fontWeight: 500,
                fontSize: "14px",
                color: "#6F80A9",
              }}
            >
              Back
            </Typography>
          </Typography>
          <Delete />
        </Box>

        {/* Actions Timeline */}
        <Box
          sx={{
            margin: "30px 0px",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "6px",
            background: "white",
          }}
        >
          <Box
            sx={{
              padding: "20px",
              background: "white",
              display: "flex",
            }}
          >
            <Box
              sx={{
                background: "#EFECFB",
                alignItems: "center",
                display: "flex",
                padding: "10px",
              }}
            >
              <Notification sx={{ color: "#0F2C6F" }} />
            </Box>
            <Box sx={{ marginLeft: "18px" }}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 700, color: "#001C63" }}
              >
                Actions Timeline
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: 400, color: "#001C63" }}
              >
                View the current fault status
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", px: "20px" }}>
            <DownArrow />
          </Box>
        </Box>

        {/* Main Content */}
        <Box
          sx={{ marginTop: "30px", background: "white", borderRadius: "6px" }}
        >
          <Box sx={{ padding: "30px" }}>
            <Typography
              sx={{ color: "#0F2C6F", fontSize: "18px", fontWeight: 500 }}
            >
              Tenant Details*
            </Typography>
            <Typography
              sx={{ color: "#6F80A9", fontSize: "14px", fontWeight: 300 }}
            >
              Please enter the tenant particulars
            </Typography>
          </Box>
          <Grid container sx={{ padding: "30px", display: "flex" }}>
            <Grid item lg={4} md={5} xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
              >
                Person-In-Charge
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#0F2C6F",
                  padding: "12px",
                }}
              >
                Cassandra Lee
              </Typography>
            </Grid>
            <Grid item lg={2} md={1} xs={0} />
            <Grid item lg={4} md={5} xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
              >
                Tenant Company
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#0F2C6F",
                  padding: "12px",
                }}
              >
                TP ICAP Pte Ltd
              </Typography>
            </Grid>
            <Grid item lg={2} />
          </Grid>

          <Grid
            container
            sx={{
              padding: "30px",
              display: "flex",
              borderBottom: "1px solid #F3F4F8",
            }}
          >
            <Grid item lg={2} md={3} sm={4} xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
              >
                Unit#
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#0F2C6F",
                  padding: "12px",
                }}
              >
                #17-02/05
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={0} xs={0} />
            <Grid item lg={4} md={3.5} sm={4} xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
              >
                Office Number
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#0F2C6F",
                  padding: "12px",
                }}
              >
                6555 1123
              </Typography>
            </Grid>
            <Grid item lg={1} md={1} sm={0} xs={0} />
            <Grid item lg={4} md={3.5} sm={4} xs={6}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
              >
                Tenant Company
              </Typography>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#0F2C6F",
                  padding: "12px",
                }}
              >
                9127 1121
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "30px",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", fontWeight: 500, color: "#0F2C6F" }}
            >
              Supporting Images (If Available)
            </Typography>
            <Button
              variant="outlined"
              sx={{
                textTransform: "none",
                fontWeight: 700,
                fontSize: "14px",
                color: "#0F2C6F",
              }}
            >
              Download all images
            </Button>
          </Box>
          <Box
            sx={{ padding: "0 0 30px 30px", borderBottom: "1px solid #F3F4F8" }}
          >
            <img
              src={PreviewImage}
              style={{ width: "70px", height: "70px", borderRadius: "6px" }}
              alt="preview"
            />
          </Box>

          <Grid
            container
            sx={{
              padding: "30px",
              color: "#0F2C6F",
              borderBottom: "1px solid #F3F4F8",
            }}
          >
            <Grid item lg={2} md={4} xs={4}>
              <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                Level*
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 300, padding: "12px" }}
              >
                17
              </Typography>
            </Grid>
            <Grid item lg={3} md={3} xs={1} />
            <Grid item lg={4} md={5} xs={7}>
              <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
                Incident Location*
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 300, padding: "12px" }}
              >
                Office Reception Area #17-02
              </Typography>
            </Grid>
            <Grid item lg={3} md={0} />
          </Grid>

          <Box
            sx={{
              padding: "30px",
              color: "#0F2C6F",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              Report Title
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 300, padding: "30px" }}
            >
              Lights very dim at reception area
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "0 30px",
              color: "#0F2C6F",
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
              Report Description
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 300,
                padding: "30px 30px 120px 30px",
              }}
            >
              We saw that the light bulbs above the reception area cannot be
              turned on. Please kindly send someone down to fix it asap. Thank
              you.
            </Typography>
          </Box>

          <Box sx={{ padding: "30px" }}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 500, color: "#0F2C6F" }}
            >
              Terms & Conditions*
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "#6F80A9",
                py: "8px",
              }}
            >
              Tenants must agree to the below terms & conditions and will have
              to agree before submitting to building management.
            </Typography>
            <Box
              sx={{
                border: "1px solid #DBDFE9",
                borderRadius: "4px",
                padding: "15px",
              }}
            >
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
              >
                Important Conditions
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  paddingTop: "20px",
                  paddingBottom: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#6F80A9",
                  }}
                >
                  1.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#6F80A9",
                    paddingBotton: "4px",
                  }}
                >
                  Request will be attended to within 1-2 working days. Please do
                  not sent repeated reports for the same incident.
                </Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#6F80A9",
                  }}
                >
                  2.
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
                >
                  We aim to resolve your issues within 3-4 working days
                  depending on complexity.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", paddingBotton: "4px" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#6F80A9",
                  }}
                >
                  3.
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}
                >
                  If fault is deemed to be within office premises, please
                  instead place a miscellaenous service request so our staff can
                  handle it appropriately.
                </Typography>
              </Box>
            </Box>
            <br />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "60px",
                color: "#6F80A9",
              }}
            >
              <Checkbox
                {...label}
                onClick={() => setTermsCheck((prevValue) => !prevValue)}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: { md: 28, xs: 18 },
                  },
                }}
              />
              <Typography
                sx={{
                  fontWeight: 400,
                  pr: "4px",
                }}
              >
                I Agree with the
              </Typography>
              <Link to="/">
                <Typography sx={{ fontWeight: 500 }}>
                  terms and conditions
                </Typography>
              </Link>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "30px 0",
            display: "flex",
            justifyContent: "space-between",
            mx: 1,
            alignItems: "flex-start",
          }}
        >
          <Typography sx={{ alignItems: "center", display: "flex" }}>
            <Recent />
            <Typography
              sx={{
                fontSize: "14px",
                color: "#9AA6C3",
                fontWeight: "400",
                pl: "4px",
              }}
            >
              Saved Previously at 10.35AM
            </Typography>
          </Typography>

          <Button
            variant="contained"
            sx={{
              padding: "13px 10px",
              textTransform: "none",
              borderRadius: "8px",
            }}
            disabled={!termsCheck}
            type="submit"
          >
            Update
          </Button>
        </Box>
        {/* Grid End */}
      </Grid>

      <Grid item lg={2} md={1} sm={1} xs={0.5} />
    </Grid>
  );
};

export default TenantDetails;
