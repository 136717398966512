import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
} from "@mui/material";
import { connect } from "react-redux";
import * as superAdminActions from "../../redux/actions/superadminAction";

const DeleteAction = ({ countryDeleteStatus, deleteCountry }) => {
  let [countrydata, setCountrydata] = useState([]);
  const [selectedid, setSelectedid] = useState();
  let obj = { country_id: selectedid };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/getAllCountries`)
      .then((response) => {
        setCountrydata(response.data.response_json.countryArrayList);
      });
  }, [countryDeleteStatus]);

  const submitdelete = () => {
    deleteCountry(obj);
  };
  return (
    <>
      <Box sx={{ m: 5 }}>
        <FormControl>
          <InputLabel>Select Country</InputLabel>
          <Select
            label="Select Country"
            sx={{ width: 300 }}
            onChange={(e) => {
              setSelectedid(e.target.value);
            }}
          >
            {countrydata.map((value) => {
              return (
                <MenuItem value={value.country_id} key={value.country_id}>
                  {value.country_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Box sx={{ mt: 3, ml: 10 }}>
          <Button variant="contained" onClick={submitdelete}>
            Submit
          </Button>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    countryDeleteStatus: state.SuperAdmin.countryDeleteStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteCountry: (data) => dispatch(superAdminActions.deleteCountry(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteAction);
