import { handleActions } from 'redux-actions';
const LoginReducer = {
    loginMessage: '',
    sendStatus: null,
    verifyStatus: null,
    resetStatus: null,
};
const reducer = handleActions({
    LOGIN_INITIATE: (state) => {
        return {
            ...state,
        };
    },
    LOGIN_SUCCESS: (state, action) => {
        return {
            ...state,
            loginMessage: action.payload
        };
    },
    LOGIN_FAILURE: (state, action) => {
        return {
            ...state,
            loginMessage: action.payload,
        }
    },
    SEND_OTP_INITIATE: (state, action) => {
        return {
            ...state,
        }
    },
    SEND_OTP_SUCCESS: (state, action) => {
        return {
            ...state,
            sendStatus: action.payload,
        }
    },
    SEND_OTP_FAILURE: (state, action) => {
        return {
            ...state,
            sendStatus: action.payload,
        }
    },
    VERIFY_OTP_INITIATE: (state, action) => {
        return {
            ...state,
        }
    },
    VERIFY_OTP_SUCCESS: (state, action) => {
        return {
            ...state,
            verifyStatus: action.payload,
        }
    },
    VERIFY_OTP_FAILURE: (state, action) => {
        return {
            ...state,
            verifyStatus: action.payload,
        }
    },
    PASSWORD_RESET_INITIATE: (state, action) => {
        return {
            ...state,
        }
    },
    PASSWORD_RESET_SUCCESS: (state, action) => {
        return {
            ...state,
            resetStatus: action.payload,
        }
    },
    PASSWORD_RESET_FAILURE: (state, action) => {
        return {
            ...state,
            resetStatus: action.payload,
        }
    },
    LOGIN_STATE_NULL: (state) => {
        return {
            ...state,
            loginMessage: '',
            sendStatus: null,
            verifyStatus: null,
            resetStatus: null,
        }
    }
}, LoginReducer)
export default reducer;