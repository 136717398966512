export const getStatus = (status, req) => {
    let res;
    if (req === 'color') {
        if (status === 'pending_investigation') {
            res ='#0F2C6F'
        } else if(status==='Pending Investigation'){
            res ='#0F2C6F'
        }
        else if(status==='withdraw'){
            res ='#8C5001'
        }
        else if (status === 'submitted') {
            res ='#0F2C6F'
        } else if (status === 'work_completed') {
            res ='#005934'
        } else if (status === 'Require More Information') {
            res ='#890122'
        } else if (status === 'require_more_data') {
            res ='#890122'
        }
        else if (status === 'Completed Investigation') {
            res ='#8C5001'
        } else if(status === 'complete_investigation'){
            res='#8C5001'
        } else if (status === 'pending_vendor') {
            res ='#0F2C6F'
        }else if(status==='work_in_progress'){
            res='#8C5001'
        }else if(status=='closed'){
            res='#890122'
        }
        else if(status=='approved'){
            res='#005934'
        }else if(status==='deleted'){
            res='#890122'
        }else if(status==='offboarded'){
            res='#890122'
        }else if(status==='Update Required' || status==="update_required"){
            res='#0F2C6F'
        }else if (status === 'draft') {
            res ='#8C5001'
        }else if(status==="require_more_info_updated" || status==="require_more_data_updated"){
            res="#890122"
        }else if(status==="starting_investigation"){
            res="#0F2C6F"
        }else if(status==="quotation_updated"){
            res="#890122"
        }else if(status==="quotation_accepted"){
            res="#8C5001"
        }else if(status==="acknowledgement"){
            res="#005934"
        }else if(status === 'quotation accepted'){
            res='#8C5001'
        }else if(status==="rejected"){
            res='#8C5001'
        }else if(status==="resolved"){
            res="#005934"
        }else if(status==="pending_work"){
            res="#8C5001"
        }else if(status==="update_to_bms"){
            res="#0F2C6F"
        }else if(status==="update_to_technician"){
            res="#890122"
        }
    } else if(req==='text') {
        if (status === 'pending_investigation') {
            res ='Pending Investigation'
        } else if(status==='Pending Investigation'){
            res='Pending Investigation'
        }
         else if (status === 'submitted') {
            res ='Submitted'
        } else if (status === 'work_completed') {
            res ='Work Completed'
        } else if (status === 'Require More Information') {
            res ='More Information Required'
        } else if (status === 'require_more_data') {
            res ='More Information Required'
        }
        else if (status === 'Completed Investigation') {
            res ='Completed Investigation'
        } else if(status==='complete_investigation'){
            res = 'Investigation Completed'
        } else if (status === 'pending_vendor') {
            res ='Pending Vendor'
        } else if(status === 'work_in_progress'){
            res = "Work in Progress"
        } else if(status==='closed'){
            res='Case Closed'
        }
        else if(status==='approved'){
            res='Approved'
        }else if(status==='deleted'){
            res='In-Active'
        }else if(status==='offboarded'){
            res='Offboarded'
        }else if(status==='withdraw'){
            res='Withdrawn'
        }else if(status==='Update Required' || status==="update_required"){
            res='Update Required'
        }else if (status === 'draft') {
            res ='Draft'
        }else if(status==="require_more_info_updated" || status==="require_more_data_updated"){
            res="Require More info Updated"
        }else if(status==="starting_investigation"){
            res="Starting Investigation"
        }else if(status==="quotation_updated"){
            res="Quotation Updated"
        }else if(status==="quotation_accepted"){
            res="Quotation Approved"
        }else if(status==="acknowledgement"){
            res="Acknowledged"
        }else if(status === 'quotation accepted'){
            res='Quotation Accepted'
        }else if(status==="rejected"){
            res='Rejected'
        }else if(status==="resolved"){
            res="Resolved"
        }else if(status==="pending_work"){
            res="Pending Work"
        }else if(status=="update_to_bms"){
            res="Technician Require More Data"
        }else if(status=="update_to_technician"){
            res="Info Updated To Technician"
        }
    }else{
        if (status === 'pending_investigation') {
            res ='#DBDFE9'
        } else if(status==='Pending Investigation'){
            res ='#DBDFE9'
        }
         else if (status === 'submitted') {
            res ='#DBDFE9'
        } else if (status === 'work_completed') {
            res ='#D3F2E5'
        } else if (status === 'Require More Information') {
            res ='#F9E7EB'
        } else if (status === 'require_more_data') {
            res ='#F9E7EB'
        }
         else if (status === 'Completed Investigation') {
            res ='#FFF2D0'
        } else if(status==='complete_investigation'){
            res='#FFF2D0'
        }else if (status === 'pending_vendor') {
            res ='#DBDFE9'
        }else if( status==='work_in_progress'){
            res='#FFF2D0'
        }else if(status==='closed'){
            res=' #F9E7EB'
        }
        else if( status==='approved'){
            res='#D3F2E5'
        }else if(status==='deleted'){
            res='#F9E7EB'
        }else if(status==='offboarded'){
            res='#F9E7EB'
        }else if(status==='withdraw'){
            res='#FFF2D0'
        }else if(status==='Update Required' || status==="update_required"){
            res='#DBDFE9'
        } else if (status === 'draft') {
            res ='#FFF2D0'
        } else if(status==="require_more_data_updated" || status==="require_more_info_updated"){
            res="#F9E7EB"
        }
        else if(status==="starting_investigation"){
            res="#DBDFE9"
        }else if(status==="quotation_updated"){
            res="#F9E7EB"
        }else if(status==="quotation_accepted"){
            res="#FFF2D0 "
        }else if(status==="acknowledgement"){
            res="#D3F2E5"
        }else if(status === 'quotation accepted'){
            res='#FFF2D0'
        }else if(status==="rejected"){
            res='#FFF2D0'
        }else if(status==="resolved"){
            res="#D3F2E5"
        }else if(status==="pending_work"){
            res="#FFF2D0"
        }else if(status==="update_to_bms"){
            res ='#DBDFE9'
        }
        else if(status==="update_to_technician"){
            res="#F9E7EB"
        }
    }
    return res;
}