import { Box, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import {ReactComponent as DatePickerIcon} from "../../Assets/icons/DatePickerIcon.svg";
import { Reports_Faults_ColumnName, Reports_Service_columnName } from "../../components/Data/Data";
import { connect } from "react-redux";
import * as ReducerAction from "../../redux/actions/reportAction/index"
import { getYYYYMMDDFormat, MenuProps } from "../../Utils/utils";
import moment from "moment";
import { getStatus } from "../../Utils/ActiveStatus";
import '../../index.css';

const StyledTableRow = styled(TableRow)({
    "&:nth-of-type(odd)":{
        backgroundColor:"#FAFBFF"
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F !important',
    padding: '5px !important',
    textAlign: "left",
    height: '50px',
    [`&.${tableCellClasses.head}`]: {
        fontSize: '13px',
        fontWeight: 550
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        fontWeight: 500,
    },
}));

const ReportsAircon = ({handleBackButton,getFilterData,getAirConReports,faultReportFilterData,airConDatas})=>{
    const [currentPage,setCurrentPage] = useState(1)
    const [filterDatas,setFilterDatas] = useState({
        status:'',
        start_date:null,
        end_date:null,
        company:'',
        technician:''
    })

    useEffect(()=>{
        getFilterData()
    },[])

    useEffect(()=>{
        getAirConReports({
            status:filterDatas.status=='' ? '' : filterDatas.status,
            limit:10,
            offset:currentPage-1,
            filterDate:'',
            searData:''
            // company_id:filterDatas.company,
            // start_date:getYYYYMMDDFormat(filterDatas.start_date),
            // end_date:getYYYYMMDDFormat(filterDatas.end_date),
            // technician_id:filterDatas.technician,
        })
    },[filterDatas,currentPage])

    const handleFilterDetails = (e,datafrom)=>{
        if(datafrom){
            setFilterDatas({
                ...filterDatas,
                [datafrom]:e
            })
        }else{
            let {name,value} = e.target
            setFilterDatas({
                ...filterDatas,
                [name]:value
            })
        }
    }
    // console.log(airConDatas)
    const handlePagination = (e,value)=>{
        setCurrentPage(value)
    }

    const handleReset = ()=>{
        setFilterDatas({
            status:'',
            start_date:null,
            end_date:null,
            company:'',
            technician:''
        })
    }
    return(
        <>
            <Box>
                <BackButton onClick={()=>handleBackButton()}/>
                <Typography sx={{color:'#0F2C6F',fontSize:'24px',fontWeight:540,my:1.5}}>Air Con Extension</Typography>
                <Grid container columnSpacing={2} >
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{airConDatas?.pending_count}</Typography>
                            <Typography className="reportCount">Pending</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{airConDatas?.work_in_progress_count}</Typography>
                            <Typography className="reportCount">Work in progress</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{airConDatas?.resolved_count}</Typography>
                            <Typography className="reportCount">Resolved</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box sx={{height:150,backgroundColor:'#FFFFFF',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                            <Typography className="reportCount">{airConDatas?.withdraw_count + airConDatas?.rejected_count}</Typography>
                            <Typography className="reportCount">Withdraw/Rejected</Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{my:2,backgroundColor:'#FFFFFF'}}>
                <Grid container sx={{p:2}} columnSpacing={2}>
                        <Grid item xs={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
                                <Select value={filterDatas.status} name='status' onChange={(e)=>handleFilterDetails(e)} MenuProps={MenuProps} label='Status'>
                                    {faultReportFilterData?.fault_report_status?.map((value)=>(
                                        <MenuItem value={value.fault_status_name}>{value.display_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Company</InputLabel>
                                <Select value={filterDatas.company} name='company' onChange={(e)=>handleFilterDetails(e)}  MenuProps={MenuProps} label='Company'>
                                    {faultReportFilterData?.company_details?.map((value)=>(
                                        <MenuItem value={value.company_id}>{value.company_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl size="small" fullWidth>
                                <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Technician</InputLabel>
                                <Select value={filterDatas.technician} name='technician' onChange={(e)=>handleFilterDetails(e)}  MenuProps={MenuProps} label='Technician'>
                                    {faultReportFilterData?.technician_details?.map((value)=>(
                                        <MenuItem value={value.id}>{value.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl size="auto" fullWidth>
                                <DatePicker disableFuture components={{
                                    OpenPickerIcon:DatePickerIcon
                                }} onChange={(value)=>handleFilterDetails(value,'start_date')} value={filterDatas.start_date} label='From' renderInput={(params)=><TextField size="small" {...params} />} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl size="auto" fullWidth>
                                <DatePicker disableFuture components={{
                                    OpenPickerIcon:DatePickerIcon
                                }} value={filterDatas.end_date} onChange={(value)=>handleFilterDetails(value,'end_date')} label='To' renderInput={(params)=><TextField size="small" {...params} />} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => handleReset()}>Reset</Typography>
                        </Grid>
                    </Grid>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {Reports_Service_columnName.map((value)=>(
                                        <StyledTableCell>{value.name}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {airConDatas?.response_json?.map((value)=>(
                                        <StyledTableRow>
                                            <StyledTableCell sx={{color:"#2764F4 !important"}}>{value.service_requests.ticket_id}</StyledTableCell>
                                            <StyledTableCell>{moment.utc(value?.service_requests.created_at).local().format('DD MMM YYYY - HH:mm')}</StyledTableCell>
                                            <StyledTableCell>{value.service_requests.display_name}</StyledTableCell>
                                            <StyledTableCell>{value.service_requests.name}</StyledTableCell>
                                            <StyledTableCell>
                                                <Typography sx={{
                                                    background: getStatus(value.service_requests.status, 'bgcolor'),
                                                    display: "inline-flex",
                                                    padding:'5px',
                                                    fontWeight: 400,    
                                                    fontSize: "13px",
                                                    color: getStatus(value.service_requests.status, 'color'),
                                                    borderRadius: "6px",
                                                }}>{getStatus(value.service_requests.status, 'text')}</Typography>
                                            </StyledTableCell>
                                            <StyledTableCell>{moment.utc(value?.service_requests.updated_at).local().format('DD MMM YYYY - HH:mm')}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination onChange={handlePagination} sx={{ display: 'flex',backgroundColor:'rgba(143, 146, 161,0.05)',justifyContent: 'center', py: 4 }} count={Math.ceil(airConDatas?.totalRecords/ 10)} page={currentPage} size="large" shape="rounded" />
                </Box>
            </Box>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        faultReportFilterData:state.ReportsReducer.faultReportFilterData,
        airConDatas:state.ReportsReducer.airConDatas
    }
}

const mapDispatchToProps = (dispatch)=>{
    return{
        getFilterData:()=>dispatch(ReducerAction.getFaultReportsFilterData()),
        getAirConReports:(data)=>dispatch(ReducerAction.getAirConReports(data))
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ReportsAircon)