import { Alert, Box, Button, LinearProgress, Typography } from '@mui/material'
import { ReactComponent as Excel } from "../../Assets/icons/Excel.svg";
import { ReactComponent as ExcelDeleteIcon } from "../../Assets/icons/DeleteExcel.svg";
import { useDropzone } from 'react-dropzone';
import { ReactComponent as FileUploadIcon } from "../../Assets/icons/FileUploadIcon.svg";
import React, { useEffect, useRef, useState } from 'react'
import * as XLSX from "xlsx";
import { Grid } from 'react-loader-spinner';
import { validateAlphabets, validateEmail } from '../../Utils/validation';

const UploadInviteExcel = ({ setValidExcelData, validExcelData, excelFile, setExcelFile, excelFileName, setExcelFileName, validExcelFile, setValidExcelFile}) => {
    const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone();
    const uploadClickRef = useRef();

    const [excelFileError, setExcelFileError] = useState(null);
    const [progress, setProgress] = useState(0);
    const [excelError, setExcelErrors] = useState([])
    const [uploadErrorBoolean, setUploadErrorBoolean] = useState(false);
    
 
    useEffect(() => {      
      if(validExcelData.length > 0){
        console.log(validExcelData);
        setUploadErrorBoolean(true);     
        setProgress(100)   
      }
    }, [])
 
    const deleteExcelDatas = () => {
        setExcelFile(null)
        setValidExcelData(null)
        setProgress(0);
      }

      useEffect(() => {
        if(acceptedFiles){
          handleFileUpload(acceptedFiles);
         }
      }, [acceptedFiles])

      const handleFileUpload = (acceptedFiles) => {
        let selectedFile = acceptedFiles[0];       

        if (selectedFile ) {
          if (selectedFile && (selectedFile.type === "text/csv" || selectedFile.type === "application/vnd.ms-excel" || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            setProgress(0);
            setTimeout(() => {
              setProgress(45);
            }, 1000);
            setExcelFileName(selectedFile.name);
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onload = (e) => {
              setExcelFileError(null);
              setExcelFile(e.target.result);
               handleExcelInputs(e.target.result)
            };            
            setTimeout(() => {
              setProgress(80);
            }, 2000);
    
            setTimeout(() => {
              setProgress(100);
              window.scrollTo(0, document.body.scrollHeight);
            }, 3000);            
          
          } else {
            setExcelFileError("Please select only excel file types");
            setExcelFile(null);
          }
        } else {
          return;
        }
      };

      let errArr = [];
      let validationSchema = {
        Email: validateEmail,
        'First Name': validateAlphabets,
        'Last Name': validateAlphabets,
        "No": () => true,
      }

      let noError = true;
      let coulmnNames = ['No','Email', 'First Name', 'Last Name' ];

      function CheckColumnFields (columns, no) {
         let objArr = Object.keys(columns);
         let out = coulmnNames.filter((i => a => a !== objArr[i] || !++i)(0));
        console.log(no, out, columns);
        if(out.length > 0){
          noError = false;
          for(let i = 0; i < out.length; i++){
              let str = `Row ${no} ${out[i]} Invalid Input!`;
              setExcelErrors(prevValue => [str, ...prevValue]); 
          }
        }else{
          for(const key in columns){      
            if(validationSchema[key](columns[key])){
             continue;
            }else {
              let str = `Row Name ${no}: ${key} Invalid Input`;
               setExcelErrors(prevValue => [str, ...prevValue])
             noError = false;
            }
        }
         }         
       }
      
      const handleExcelInputs = (newExcelFile) => {
    const workbook = XLSX.read(newExcelFile, { type: "buffer"});
    const worksheetName = workbook.SheetNames[0]; 
    const worksheet = workbook.Sheets[worksheetName];
    const data = XLSX.utils.sheet_to_json(worksheet)

    setExcelErrors([]);
   for(let i = 0; i < data.length; i++){          
    CheckColumnFields(data[i], i+1); 
    }

   
    
   setUploadErrorBoolean(noError);
    if(noError === false){        
     setExcelErrors(prevValue => {
       let arr = [...errArr, ...prevValue];       
       return arr;
      })
    } else{
      let emailArr = [];
      for(let i = 0; i < data.length; i++){
        emailArr[i] = data[i]['Email'];
      }  
 if(validateDuplicateMails(emailArr) === false){   
   setValidExcelData(data)
   setValidExcelFile(acceptedFiles);
  }
    
   }
  }

  const validateDuplicateMails = (mailArrList) => {
    let error = false;
    let errorMails = [];
    for(let i = 0; i < mailArrList.length; i++){
        for(let j = 0; j < mailArrList.length; j++){
            if(i !== j && mailArrList[i] === mailArrList[j]){
                  error = true;
                  if(errorMails.includes(mailArrList[j]) === false){
                    errorMails.push(mailArrList[j]);
                  }
                  
            }
        }
    }

    if(error){
      setUploadErrorBoolean(false);
      setExcelErrors(prevValue => {
        return [`${errorMails.join(", ")} - duplication not allowed, Mail id should be unique!`, ...prevValue];
      })
    }

    return error;
}
 
  return (  excelFile !== null && uploadErrorBoolean ? 
    <>
    <Box sx={{padding: "20px"}}>
      <Typography sx={{ color: '#0F2C6F', fontSize: "18px" }}>Upload here</Typography>
      <Typography sx={{ color: '#6F80A9', fontSize: '14px' }}>Finance Manager will be the person who is authorised to make payments on behalf of the company. </Typography>
    </Box>
    <Box
      sx={{
        height: "100px",
        background: "#fff",
        padding: { md: "15px", xs: "4px 0" },
      }}
    >
      <Box
        sx={{
          padding: { md: "12px 24px", xs: "14px" },
          borderRadius: "8px",
          border: "1px solid #DBDFE9",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Icon */}
        <Excel width="40px" />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: "16px",
            rowGap: { md: "8px", xs: "4px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", sm: "12px", xs: "11px" },
                  fontWeight: 500,
                  color: "#0F2C6F",
                }}
              >
                {excelFileName}
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "14px", sx: "12px", xs: "11px" },
                  fontWeight: 400,
                  color: "#6F80A9",
                }}
              >
                {Math.round(validExcelFile[0].size * (progress / 100))}{" "}
                of {validExcelFile[0].size}KB
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: { md: "center", xs: "flex-end" },
                flexDirection: { md: "row", xs: "column-reverse" },
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "14px", sm: "12px", xs: "9px" },
                  fontWeight: 500,
                  color: "#0F2C6F",
                }}
              >
                {progress !== 100
                  ? `Uploading... ${Math.floor(progress)}%`
                  : "Upload Complete"}
              </Typography>
              {/* <ExcelUploadCancel height="26px" width="26px" /> */}
              <Box sx={{ border: '1px solid #AAC3FF', borderRadius: '6px', p: 1, cursor: "pointer" }}>
                < ExcelDeleteIcon onClick={() => deleteExcelDatas()} />
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                background: "#F3F4F8",
                "& .MuiLinearProgress-barColorPrimary": {
                  backgroundColor: "#33B7A5",
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  </> : <Box
              sx={{
                padding: "20px",
                background: "#EAF0FF",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              {...getRootProps({
                isDragActive,
              })}
            >
              <Box sx={{ textAlign: "center", padding: "44px 0" }}>
                <FileUploadIcon />
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "#0F2C6F",
                    paddingTop: "20px",
                  }}
                >
                  Drag and Drop files here
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F2C6F",
                    paddingTop: "10px",
                  }}
                >
                  Please fill in employee details into excel template generated in previous step and upload below. 
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#0F2C6F",
                    mt:1
                  }}
                >
                If you would like to manually enter details, please select single employee instead. 
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#0F2C6F",mt:1 }}
                >
                  Files Supported: .csv, .xls (Max 5 MB)
                </Typography>
                {/* {excelError.messageStatus ? <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "red" }}
                >
                  {excelError.messageData ? excelError.messageData : `In uploaded File ${excelError.errorcounts} out of ${excelError.totalLength} have invalid emails/phone no's/last name/first name must have 3 characters.Upload valid data`}
                </Typography> : ''} */}
                {/* <Grid container >
                {excelError.messageStatus && excelError?.messageData?.map((value)=>(
                  <Grid item xs={excelError?.messageData?.length>25 ? 6 : 12}>
                    <Typography  sx={{m:0.5, fontSize: "14px", fontWeight: 400, color: "red",textAlign:'left' }}>{value}</Typography>
                  </Grid>
                ))}
                </Grid> */}
                <Box sx={{display: 'flex', flexDirection: "column-reverse",gap: '5px', py: "10px" }}>

                {excelError.length > 0 && excelError.map((item,index) => <Typography key={index} sx={{color: "#ff000080"}}>{item}</Typography>)  }
                </Box>
                <Box sx={{ paddingTop: "30px" }}>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      background: "#FFFFFF",
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "#0F2C6F",
                      "&:hover": {
                        background: "#0F2C6F",
                        color: "white",
                      },
                    }}
                    // onClick={() => {
                    //   uploadClickRef.current.click();
                    // }}
                  >
                    Choose File
                  </Button>
                  <input
                    type="file"
                    name="file_upload"
                    accept="application/vnd.ms-excel"
                    style={{ display: "none" }}
                    {...getInputProps({ ref: uploadClickRef })}
                  />
                </Box>
                {excelFileError && (
                  <Alert severity="warning" sx={{ marginTop: "20px" }}>
                    {excelFileError}
                  </Alert>
                )}
              </Box>
            </Box>
  )
}

export default UploadInviteExcel