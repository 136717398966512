import React, { useEffect, useState } from 'react'
import {ReactComponent as SearchIcon} from '../../Assets/icons/SearchIcon.svg';
import {ReactComponent as NoDataIcon} from '../../Assets/icons/NoDataIcon.svg';
import {ReactComponent as CloseIcon} from '../../Assets/icons/Close.svg';
import { Box } from '@mui/system';
import { CircularProgress, IconButton, Input, Pagination, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import { useDebounce } from 'use-debounce';
import { connect } from 'react-redux';
import { getTecMemberDetailsAction, getTecMemberDetailsNullAction } from '../../redux/actions/facilityBookingAction';
import { StyledTableCell, StyledTableRow } from '../ManageTenant/ActiveTenant';
import moment from 'moment';


const TecFacilityBookingUsersList = ({getTecMemberDetails, getTecMemberDetailsData, getTecMemberDetailsNull}) => {

    const tableHeads = ['Name', 'Email', 'Phone Number', 'Company Name', 'Profile ID (From TEC)', 'Created At']

    const [searchText,setSearchText ] = useDebounce('', 1000)
    const [textInputState,setTextInputState ] = useState('')

    const [usersList, setUsersList] = useState([]);
    const [filterOptions, setFilterOptions] = useState({limit: 10, offset: 0,})
    const [currentPage,setCurrentPage] = useState(1)



    useEffect(() => {      
        return () => getTecMemberDetailsNull();
    }, [])

    useEffect(() => {
      getTecMemberDetails({
        limit: filterOptions.limit,
        offset: filterOptions.offset,
        search: searchText,
      })
    }, [filterOptions,searchText])

    useEffect(() => {
        if(getTecMemberDetailsData.response?.response_status === 1){
            setUsersList(getTecMemberDetailsData.response?.data_array?.tec_member_list)
          }else if(getTecMemberDetailsData.response?.message.toLowerCase() === 'no data found'){
          setUsersList([])
        }
    }, [getTecMemberDetailsData])

    const handlePagination = (e,value)=>{
      setCurrentPage(value)
      setFilterOptions({...filterOptions, offset: Number(value) - 1})
  }
    

  return (
   <>
   <Box sx={{height:"auto"}}> 
   <Box sx={{display: 'flex',alignItems: "center", justifyContent: "flex-end"}}>

   <Box
            sx={{
              float: "right",
             padding:'4px',
              display: "flex",
              alignItems: "center",
              border: " 1px solid #DBDFE9",
              borderRadius: "6px",
              mr:2
            }}
          >
            <Input
              name={'search'}
               size="small"
              type="text"
               placeholder='Search'
              style={{
                border: "none",
                outline: "none",
                fontSize: "14px",
                fontWeight: 400,
                width: "100%",
              }}
              inputProps={{
                sx: {
                  height: { md: "25px", sm: "20px", xs: "4px" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  flexGrow: 1,
                  width: "inherit",
                },
                
               }}
               value={textInputState}
              disableUnderline
              onChange={e => {
                setTextInputState(e.target.value)
                setSearchText(e.target.value)
              }}
              />
            <Box sx={{display: 'flex', flexGrow: 0, alignItems:'center',}}>              
            {textInputState && <IconButton onClick={() => {
              setTextInputState("");
              setSearchText("")
              }}> <CloseIcon style={{width: 15, height: 15,  }} /></IconButton>}
            <SearchIcon cursor="pointer" />
            </Box>
          </Box>
          </Box>

   </Box>

   <TableContainer>
    
              <Table>
                <TableHead>
                    <StyledTableRow>
                        {tableHeads.map((item, index) => <StyledTableCell key={index}>{item}</StyledTableCell>)}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {usersList.length > 0 && getTecMemberDetailsData.isLoading === false && usersList?.map(item => <StyledTableRow key={item?.tec_member_id}>
                        <StyledTableCell>{item?.name}</StyledTableCell>
                        <StyledTableCell>{item?.email}</StyledTableCell>
                        <StyledTableCell>{item?.mobile_no}</StyledTableCell>
                        <StyledTableCell>{item?.company_name}</StyledTableCell>
                        <StyledTableCell>{item?.profile_id}</StyledTableCell>
                        <StyledTableCell>{moment.utc(item?.created_at).local().format('DD/MM/YYYY-hh:mm')}</StyledTableCell>
                    </StyledTableRow>)}
                </TableBody>
              </Table>
   </TableContainer>
   {usersList?.length === 0 && getTecMemberDetailsData.isLoading === false && <Box sx={{
    display: 'grid', placeItems: "center", width: '100%',py: 4
   }}>
    <Typography sx={{fontSize: "20px", fontWeight: 600, color: '#0F2C6F'}}>No Data Found</Typography>
    </Box>} 
      {getTecMemberDetailsData.isLoading &&   <Box sx={{ width: "100%",display: 'flex', justifyContent: 'center', p: 5, }}>
                            <CircularProgress  />
                        </Box>}         

   {usersList?.length > 0 &&
              <Pagination onChange={handlePagination} sx={{ display: 'flex',justifyContent: 'center', py: 4 }} count={Math.ceil(getTecMemberDetailsData.response?.dataArray?.total_tec_member_list / 10) || 1} page={currentPage} size="large" shape="rounded" /> }

   </>
  )
}

 
const mapDispatchToProps = dispatch => {
    return { 
      getTecMemberDetails: (params) => dispatch(getTecMemberDetailsAction(params)) ,
      getTecMemberDetailsNull: (params) => dispatch(getTecMemberDetailsNullAction(params)) 
     }
  }
  
  const mapStateToProps = state => {
    return {
 
        getTecMemberDetailsData: state.FacilityBookingReducer.getTecMemberDetails,
   
    }
  }
   export default connect(mapStateToProps, mapDispatchToProps)(TecFacilityBookingUsersList)