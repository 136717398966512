import React from "react";
import { Route, Routes } from "react-router-dom";
import FaultReporting from "../Pages/FaultReporting";
import Faults from "../Pages/FaultReporting/Faults";
import ManageTenant from "../Pages/ManageTenant";
import TenantDetails from "../Pages/ManageTenant/TenantDetails";
import OnBoardBatch from "../Pages/NewManageEmployees/OnBoardBatch";
import OnBoardSingle from "../Pages/NewManageEmployees/OnBoardSingle";
import Announcement from "../Pages/Announcement/Index.js";
import OnboardingNewCompany from "../Pages/ManageTenant/OnboardingNewCompany";
import SeasonParking from "../Pages/SeasonParking/Index";
import BMSAdministration from "../Pages/BMSAdministration/Index";
import ErrorPage from "../components/LandingPage/ErrorPage";
import Header from "../Header";
import ServiceRequest from "../Pages/ServiceRequest/Index";
import ReviewTenant from "../Pages/ManageTenant/reviewTenant";
import ReviewEmployeeList from "../Pages/NewManageEmployees/ReviewEmployeeList";
import SingleEmployeeReview from "../Pages/NewManageEmployees/SingleEmployeeReview";
import StaffTab from "../Pages/BMSAdministration/StaffTab";
import StaffOnboard from "../Pages/BMSAdministration/StaffOnboard";
import ReviewStaff from "../Pages/BMSAdministration/ReviewStaff";
import Contacts from '../Pages/BMSAdministration/Contacts'
import Information from '../Pages/BMSAdministration/Information'
import NewAnnouncement from "../Pages/Announcement/NewAnnouncement";
import ReviewReport from "../Pages/FaultReporting/ReviewReport";
import ServiceRequestList from "../Pages/ServiceRequest/ServiceRequestList";
import ReviewRequest from "../Pages/ServiceRequest/ReviewRequests";
import EventsAndPromotions from "../Pages/Events_Promotions";
import CreateEvent from "../Pages/Events_Promotions/CreateEvent";
import CreatePromotion from "../Pages/Events_Promotions/CreatePromotion";
import UploadHandBook from "../Pages/BMSAdministration/UploadHandbook";
import UpdateEmpDetails from "../Pages/NewManageEmployees/UpdateEmpDetails";
import BmsManagerDashboard from "../Pages/Dashboard/Bms Manager";
import Announcementnotification from "../Pages/common/HeaderNotification";
import HeplAndSupport from "../Header/HelpAndSupport";
import Reports from "../Pages/Reports/index";
import SendFeedback from "../Header/SendFeedback";
import ReportBugs from "../Header/ReportBugs";
import LightbulbProducts from "../Pages/BMSAdministration/lightbulbProducts";
import AddProduct from "../Pages/BMSAdministration/addProduct";
import AddService from "../Pages/BMSAdministration/AddService.js";
import ManageFacility from "../Pages/ManageFacility/index.js";
import CreateFacility from "../Pages/ManageFacility/CreateFacility";
import ManageBookingFacility from "../Pages/ManageFacility/BookingIndex";
import FaciliyBooking from "../Pages/FacilityBooking";
import BookingSelection from "../Pages/FacilityBooking/BookingSelection";
import ManageServiceRequestForms from "../Pages/ManageServiceRequestForms/Index.js";
import TenantVisitors from "../Pages/TenantVisitors/index.js";
import ReviewInvite from "../Pages/TenantVisitors/ReviewInvite.js";
import BatchInvitation from "../Pages/TenantVisitors/BatchInvitation.js";
import InviteViaExcel from "../Pages/TenantVisitors/InviteViaExcel.js";
import PaymentConfirmation from "./PaymentConfirmation.js";
import SingleRoomFeature from "../Pages/FacilityBooking/SingleRoomFeature.jsx";
import ConfirmedBooking from "../Pages/FacilityBooking/ConfirmedBooking.jsx";
import BMSv12 from "../Pages/Dashboard/BMSv1.2.js";
import NewServiceRequest from "../Pages/ServiceRequest/NewRequests/index";
import Sustainability from "../Pages/Sustainability/Sustainability.js";
import CreateSustainable from "../Pages/Sustainability/CreateSustainable.js";
import AddFileServiceRequest from "../Pages/ManageServiceRequestForms/AddFileServiceRequest.js";
import EditFileServiceRequest from "../Pages/ManageServiceRequestForms/EditFileServiceRequest.js";
import ManageFacilityBooking from "../Pages/FacilityBooking/ManageFacilityBooking.js";
import ManageVisitor from '../Pages/Visitors/ManageVisitors.js';
import UpcommingVisitors from '../Pages/Visitors/UpcommingVisitors.js';
import ReviewManageInvite from '../Pages/Visitors/UpcommingVisitors.js/ReviewManageInvite';
import ReviewSpecialAccess from '../Pages/Visitors/ManageVisitorBms/ReviewSpecialAccess.js';
import VisitorManagement from "../Pages/Visitors/ManageVisitorBms/VisitorManagement.js";
import ReviewRegistered from '../Pages/Visitors/ManageVisitors.js/ReviewRegistered';
import UpdateEmpsDetails from "../Pages/NewManageEmployees/UpdateEmpsDetails.js";

const BmsExecutive = () => {
  return (
    <React.Fragment>
      <Header>
        <Routes>
          {/* <Route path="/dashboard" element={<BmsManagerDashboard />} /> */}
          <Route path="/dashboard" element={<BMSv12 />} />
          <Route path="/review_tenant_details/:id/:activeType" element={<ReviewTenant />} />
          <Route path="/onboard_new_tenant/:empValue/:nestedTabValue" element={<ManageTenant />} />
          <Route
            path="/onboard_new_tenant_company"
            element={<OnboardingNewCompany />}
          />
          <Route
            path="/update_onboard_tenant_company/:update_tenant_id"
            element={<OnboardingNewCompany />}
          />
          <Route path="/tenant_details" element={<TenantDetails />} />
          <Route path="/single_employee_onboard" element={<OnBoardSingle />} />
          <Route path='/single_employee_review/:emp_id/:activeType' element={<SingleEmployeeReview />} />
          <Route path="/update_employee/:emp_id/:activeType" element={<UpdateEmpDetails/>} />
          {/* <Route path="/update_employee/:emp_id/:activeType" element={<UpdateEmpsDetails/>} /> */}
          <Route path="/batch_onboarding" element={<OnBoardBatch />} />
          <Route path='/review_employee_list/:emp_id/:tabValue/:fromBms' element={<ReviewEmployeeList />} />
          <Route path='/review_registered/:id/:type' element={<ReviewRegistered />} />
          <Route path="/service_request" element={<ServiceRequest />} />
          <Route path="/announcement" element={<Announcement />} />
          <Route path="/season_parking" element={<SeasonParking />} />
          <Route path="/bms_administration" element={<BMSAdministration />} />
          <Route path="/manage_faults/:tabvalue" element={<FaultReporting />} />
          <Route path="/review_fault/:id/:tabValue/:fromDashboard" element={<ReviewReport />} />
          <Route path="/service_request_list/:tab_id" element={<ServiceRequest />} />
          <Route path="/review_service_request/:service_req_id/:service_req_type_id/:tab_id/:fromDashboard" element={<ReviewRequest />} />
          <Route path='/manage_staff/:tabId' element={<StaffTab />} /> 
          <Route path="/onboard_new_staff" element={<StaffOnboard/>} />
          <Route path='/review_staff/:status/:empId' element={<ReviewStaff />} /> 
          <Route path="/contacts/:title/:isGroup/:contactId/:isEdit" element={<Contacts />} />
          <Route path="/information/:tabValue" element={<Information />} />
          <Route path='/new_announcement' element={<NewAnnouncement />} />
          <Route path='/new_handbook' element={<UploadHandBook/>} />
          <Route path='/events_promotions/:tabValue' element={<EventsAndPromotions />} />
          <Route path='/create_event/:id/:reopen/:edit' element={<CreateEvent />} />
          <Route path='/create_promotion/:id/:reopen/:edit' element={<CreatePromotion />} />
          <Route path='/edit_event/:id/:reopen/:edit' element={<CreateEvent />} />
          <Route path='/edit_promotion/:id/:reopen/:edit' element={<CreatePromotion />} />
          <Route path="/reports/:isAccess" element={<Reports />} />
          <Route path="/lightbulbProducts" element={<LightbulbProducts/>} /> 
          <Route path='/addProduct/:id' element={<AddProduct/>} />
          <Route path='/addService/:screen' element={<AddService/>} />
          <Route path="/notifications" element={<Announcementnotification />} />
          <Route path="/helpAndSupport" element={<HeplAndSupport/>} />
          <Route path="/feedback" element={<SendFeedback/>} />
          <Route path="/reportbug" element={<ReportBugs/>} />
          <Route path="/manage_facility" element={<ManageFacility />} />
          <Route path="/create_facility" element={<CreateFacility />} />
          <Route path="/manage_booking" element={<ManageBookingFacility />} />
          <Route path="/facility_booking" element={<FaciliyBooking />} />
          <Route path="/booking_selection" element={<BookingSelection />} />
          <Route path='/single_room_feature/:feature_id' element={<SingleRoomFeature />} />
          <Route path="/facility_booking/:facility_id" element={<ConfirmedBooking />} />
          <Route path="/manage_service_request_forms" element={<ManageServiceRequestForms />} />
          <Route path="/report_fault/:isDraft/:id" element={<Faults />} />
          <Route path="/tenant_visitors/:tabValue" element={<TenantVisitors/>} />
          <Route path="/review_tenant_inivte/:tabValue/:invite_id/:isEdit" element={<ReviewInvite/>} />
          <Route path="/batch_invitation" element={<BatchInvitation/>} />
          <Route path="/batch_invitation_excel" element={<InviteViaExcel  />} />
          <Route path="/payment_response" element={<PaymentConfirmation />} />
          <Route path="/my_faults/:tabvalue" element={<FaultReporting />} />
          <Route path="/service_request_forms/:tabValue/:typeId/:reqId" element={<NewServiceRequest/>} />    
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/service_request_forms/add_file" element={<AddFileServiceRequest/>}/>
          <Route path="/service_request_forms/editfile/:id" element={<EditFileServiceRequest/>}/>
          <Route path="/manage_facility_booking" element={<ManageFacilityBooking />} />
          <Route path="/manage_all_facility_bookings" element={<FaciliyBooking />} />
          <Route path="/create_sustainability/:id/:reopen/:edit/:isDraft" element={<CreateSustainable />} />
          <Route path="/edit_sustainability/:id/:reopen/:edit/:isDraft" element={<CreateSustainable />} />
          <Route path="/manage_service_request_forms" element={<ManageServiceRequestForms />} />
          <Route path="/service_request_forms/:tabValue/:typeId/:reqId" element={<NewServiceRequest/>} />    
           <Route path="/service_request_forms/add_file" element={<AddFileServiceRequest/>}/>
          <Route path="/service_request_forms/editfile/:id" element={<EditFileServiceRequest/>}/>
          <Route path="/visitor_management" element={<VisitorManagement />} />
          <Route path="/manage_visitor/:tabValue" element={<ManageVisitor />} />
          <Route path="/upcomming_visitors/:tabValue" element={<UpcommingVisitors />} />
          <Route path="/review_manage_invite/:tabValue/:invitation_id/:invitation_type" element={<ReviewManageInvite />} />
          <Route path='/review_specialaccess/:id' element={<ReviewSpecialAccess/>} />

          <Route path="*" element={<ErrorPage />} />
         </Routes>
      </Header>
    </React.Fragment>
  );
};

export default BmsExecutive;
