import React from "react";

const ExcelTable = ({ excelData, date, unit }) => {
  
  const tableClass = {
    border: "0.2px solid black",
    borderCollapse: "collapse",
    fontSize: "13px",
  };

  return (
    <table style={tableClass}>
      <thead>
        <tr>
          <th style={tableClass}>OB-SN</th>
          <th style={tableClass}>First Name</th>
          <th style={tableClass}>Last Name</th>
          <th style={tableClass}>Work Email</th>
          <th style={tableClass}>
            Mobile Country Code (Only for foreign numbers)
          </th>
          <th style={tableClass}>Mobile Number</th>
          <th style={tableClass}>Job Title</th>
          {/* <th style={tableClass}>Access Start Date</th> */}
          <th style={tableClass}>Office Location</th>
          <th style={tableClass}>"Tenant Manager (Indicate “ Y” if appointed Tenant Manager, if not leave blank)"</th>
          {/* <th style={tableClass}>Role-Tenant Manager Y-if Yes blank for no.</th> */}
          <th style={tableClass}>
          Remarks - Optional (For eg, sub tenant, contract staff)
          </th>
        </tr>
      </thead>

      <tbody>
        {excelData.length > 1 &&
          excelData.map(
            (item, index) =>
              index !== 0 && (
                <tr key={index}>
                  <td style={tableClass}>{index}</td>
                  <td style={tableClass}></td>
                  <td style={tableClass}></td>
                  <td style={tableClass}></td>
                  <td style={tableClass}></td>
                  <td style={tableClass}></td>
                  <td style={tableClass}></td>
                  {/* <td style={tableClass}>{date}</td> */}
                  <td style={tableClass}>{unit}</td>
                  <td style={tableClass}></td>
                  {/* <td style={tableClass}></td> */}
                  <td style={tableClass}></td>
                </tr>
              )
          )}
      </tbody>
    </table>
  );
};

export default ExcelTable;
