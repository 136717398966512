import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { ReactComponent as BellWatch } from "../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../Assets/icons/StructuralFaultBell.svg";
import { ReactComponent as Tickicon } from "../../Assets/icons/Tick.svg";
import { ReactComponent as SearchIcon } from "../../Assets/icons/SearchIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import ReviewReport from "./ReviewReport";
import { connect } from "react-redux";
import * as faultReportAction from "../../redux/actions/faultreportAction";
import { getAllLevels as GetAllLevels } from "../../redux/actions/commonAction";
import {
  getTimeStamp,
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
} from "../../Utils/utils";
import { getStatus } from "../../Utils/ActiveStatus";
import Faultalert from "../common/faultalert";
import ToastAlert from "../common/Alert";
import { ReactComponent as DropDownIcon } from "../../Assets/icons/DropdownColored.svg";
import { DatePicker } from "@mui/x-date-pickers";
import { ReactComponent as ArrowUpwardIcon } from "../../Assets/icons/sortasc.svg";
import { ReactComponent as ArrowdDisableIcon } from "../../Assets/icons/sortDisable.svg";
import { ReactComponent as ArrowDownwardIcon } from "../../Assets/icons/sortdes.svg";
import { SortIconStyle } from "../../Utils/utils";
import * as ServiceRequestAction from "../../redux/actions/service_request/index";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F2C6F !important",
  padding: "5px !important",
  height: "50px",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

const TicIDTableCell = styled(TableCell)(({ theme }) => ({
  color: "#2764F4  !important",
  padding: "5px !important",
  height: "50px",

  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 500,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFBFF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const typeoFWork = [
  {
    id: 1,
    value: "toilet_fault",
    Label: "Toilet Fault",
  },
  {
    id: 2,
    value: "structurel_fault",
    Label: "Structurel Fault",
  },
  {
    id: 3,
    value: "equipement_fault",
    Label: "Equipment Fault",
  },
  {
    id: 4,
    value: "other_fault",
    Label: "Other Faults",
  },
];
const statusData = [
  { id: 1, label: "Submitted", value: "submitted" },
  { id: 2, label: "Investigating", value: "investigating" },
  { id: 3, label: "Closed", value: "closed" },
  { id: 4, label: "More info Req", value: "moreinforeq" },
];

const batchMenus = [
  {
    id: 1,
    label: "Watch",
    value: "Watch",

    isSelected: false,
  },
  {
    id: 2,
    label: "UnWatch",
    value: "UnWatch",

    isSelected: false,
  },
  {
    id: 3,
    label: "Withdraw",
    value: "Withdraw",

    isSelected: false,
  },
];

const withdrawData = {
  label: "Withdraw Report",
  description: "Are you sure you want to withdraw this report?",
  buttonvalue: "Confirm",
  buttonStyle: "blue",
};

const FaultReports = ({
  filterSources,
  faultLocations,
  tabValue,
  faultCount,
  activeType,
  getFaultReports,
  faultReports,
  updateReport,
  reportUpdateStatus,
  setFaultReportStateNull,
  updateWatch,
  updateWatchStatus,
  getAllLevels,
  levelDatas,
  getMyFaults,
  myFaultsDatas,
  getMyFaultReportsForWebNull,
  getFaultReportsNull,
  getPriorityLevels,
  getPriorityLevelsData,
  getFaultCategoryData,
  getFaultCategoryListData,
}) => {
  const navigate = useNavigate();
  const [filterData, setFilterData] = useState({
    date: null,
    typeOfFault: "",
    status: "",
    category: "All",
    batchAction: "",
    search: "",
    level_no: "All",
    priority_level_id: null,
  });
  const [data, setData] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [showBatchAction, setShowBatchAction] = useState(false);
  const [showBatchActionCount, setShowBatchActionCount] = useState("");
  const [showBatchActionMenus, setShowBatchActionMenus] = useState(batchMenus);
  const [userData, setUserData] = useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [alert, setAlert] = useState(false);
  const [filterActinData, setFilterActionData] = useState({
    status: "",
    message: "",
  });
  const [faultId, setFaultId] = useState();
  const [toast, setToast] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    label: "",
    description: "",
    buttonvalue: "",
    buttonStyle: "",
    confirmFunc: null,
  });
  const [allSelectedData, setAllSelectedData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [alertStatus, setAlertStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [sortStatus, setSortStatus] = useState({
    date: false,
    title: false,
    priority: false,
    status: false,
    lastUpdate: false,
    level_no: false,
  });
  const [selectedSort, setSelectedSort] = useState({
    date: true,
    title: false,
    priority: false,
    status: false,
    lastUpdate: false,
    level_no: false,
  });
  const [sortapiData, setSortApiData] = useState({ type: "", column_name: "" });
  const [priorityLevels, setPriorityLevels] = useState([]);
  const location = useLocation();
  const [categoryData, setCategoryData] = useState([]);

  console.log("selectedSort ", selectedSort);
  useEffect(() => {
    getFaultCategoryData();
    if (getPriorityLevelsData.response?.response_status === 1) {
      console.log(
        "getPriorityLevelsData.response?.data_array ",
        getPriorityLevelsData.response?.data_array
      );
      setPriorityLevels(getPriorityLevelsData.response?.data_array);
    }
  }, [getPriorityLevelsData]);
  console.log("categoryData ", categoryData);
  useEffect(() => {
    if (getFaultCategoryListData?.response != "Failed") {
      const listArray =
        getFaultCategoryListData?.response?.data_array?.categoryArrayList?.map(
          (item) => {
            return {
              fault_category_name: item.fault_category_name,
              fault_category_id: item.fault_category_id,
            };
          }
        );
      setCategoryData(listArray);
    }
  }, [getFaultCategoryListData]);

  useEffect(() => {
    let responseUserData = JSON.parse(localStorage.getItem("userData"));
    if (responseUserData) {
      setUserData(responseUserData);
      fetchFaultReport(responseUserData);
    }
    return () => {
      setFaultReportStateNull();
    };
  }, [getFaultReports, activeType, filterData, updateWatchStatus, sortStatus]);

  useEffect(() => {
    if (localStorage.getItem("locationKey") === null) {
      localStorage.setItem("locationKey", location.key);
    }

    if (localStorage.getItem("locationKey") !== location.key) {
      localStorage.setItem("locationKey", location.key);

      if (sortapiData.column_name && sortapiData.type) {
        handleReset();
      }

      if (checkIsSortModified()) {
        handleSortReset();
      }

      if (
        sortapiData.column_name === "" &&
        sortapiData.type === "" &&
        checkIsSortModified() === false
      ) {
        let responseUserData = JSON.parse(localStorage.getItem("userData"));
        if (responseUserData) {
          setUserData(responseUserData);
          fetchFaultReport(responseUserData);
        }
      }
    }
  }, [location.key]);

  useEffect(() => {
    getPriorityLevels();
    return () => {
      localStorage.removeItem("locationKey");
    };
  }, []);

  useEffect(() => {
    if (reportUpdateStatus?.response_status == 1) {
      setAlertStatus({
        message: reportUpdateStatus?.message,
        status: true,
        color: "green",
        icon: "close",
      });
      let responseUserData = JSON.parse(localStorage.getItem("userData"));
      setUserData(responseUserData);
      fetchFaultReport(responseUserData);
      setFaultReportStateNull();
      setTimeout(() => {
        removeAlert();
      }, 4000);
    } else if (reportUpdateStatus?.response_status == 0) {
      setAlertStatus({
        message: reportUpdateStatus?.message,
        status: true,
        color: "red",
        icon: "close",
      });
      setFaultReportStateNull();
      setTimeout(() => {
        removeAlert();
      }, 4000);
    }
  }, [reportUpdateStatus]);

  useEffect(() => {
    if (updateWatchStatus == "Fault report watch updated successfully") {
      setAlertStatus({
        message: updateWatchStatus,
        status: true,
        color: "green",
        icon: "close",
      });
      setFaultReportStateNull();
      setTimeout(() => {
        removeAlert();
      }, 4000);
    } else if (updateWatchStatus != null) {
      setAlertStatus({
        message: updateWatchStatus,
        status: true,
        color: "red",
        icon: "close",
      });
      setFaultReportStateNull();
      setTimeout(() => {
        removeAlert();
      }, 4000);
    }
  }, [updateWatchStatus]);

  useEffect(() => {
    if (myFaultsDatas.response?.response_status === "success") {
      setData(myFaultsDatas.response?.response_json);
    } else {
      setData([]);
    }
  }, [myFaultsDatas]);

  useEffect(() => {
    if (faultReports?.length > 0) {
      setData(faultReports);
    } else {
      setData([]);
    }
  }, [faultReports]);

  const checkIsSortModified = () => {
    let returnValue = false;

    for (const key in sortStatus) {
      if (sortStatus[key] === false) {
        returnValue = true;
      }
    }

    return returnValue;
  };

  const fetchFaultReport = (responseUserData) => {
    setData(null);
    getMyFaultReportsForWebNull();
    setFaultReportStateNull();
    let data = {
      user_id: responseUserData?.user_id,
      status: activeType,
      role: responseUserData?.role,
      limit: 10,
      offset: 0,
      priority_level_id: filterData?.priority_level_id,
      filter_status: filterData?.status,
      date:
        filterData?.date !== null ? getYYYYMMDDFormat(filterData?.date) : "",
      search: filterData?.search,
      fault_type: filterData?.typeOfFault,
      order_by_column: sortapiData?.column_name,
      order_by: sortapiData?.type,
      fault_category_id:
        filterData?.category != "All" ? Number(filterData.category) : "",
      level_no:
        filterData?.level_no !== "All" ? Number(filterData.level_no) : "",
    };

    if (window.location.pathname.includes("/my_faults")) {
      getMyFaults(data);
    } else {
      getFaultReports(data);
    }
  };
  console.log("filterData ", filterData);

  const handleFilterDatas = (value, name) => {
    if (name === "level_no" && value === "All") {
      if (sortStatus.level_no === false) {
        handleSortStatus("level_no", "level_no", sortStatus.level_no);
      }
    }

    setFilterData({ ...filterData, [name]: value });
  };

  const handleCheckbox = (newData) => {
    let updatedData = data;
    for (let i = 0; i < updatedData.length; i++) {
      if (
        updatedData[i].fault_report.fault_report_id ===
        newData.fault_report.fault_report_id
      ) {
        updatedData[i].fault_report.is_selected =
          data[i].fault_report.is_selected === 0 ? 1 : 0;
      }
    }
    setData([...updatedData]);
    showHideBatchAction(updatedData);
  };
  const showHideBatchAction = (updatedData) => {
    let data = [];
    let status = false,
      i,
      count = 0,
      reportLength = updatedData.length;
    for (i = 0; i < reportLength; i++) {
      if (updatedData[i].fault_report.is_selected) {
        data.push(updatedData[i].fault_report.fault_report_id);
        setSelectedData(data);
        status = true;
        count += 1;
      }
    }
    setShowBatchAction(status);
    setShowBatchActionCount(count);
  };

  const handleAllCheck = (e) => {
    const { checked } = e.target;
    setIsAllChecked((prevValue) => !prevValue);
    let updatedData = data;
    let allselected = [];
    if (checked === true) {
      for (let i = 0; i < updatedData.length; i++) {
        updatedData[i].fault_report.is_selected = 1;
        allselected.push(updatedData[i].fault_report.fault_report_id);
      }
      setShowBatchAction(true);
      setShowBatchActionCount("All");
      setData([...updatedData]);
      setAllSelectedData(allselected);
    } else if (checked === false) {
      for (let i = 0; i < updatedData.length; i++) {
        updatedData[i].fault_report.is_selected = 0;
      }
      setShowBatchAction(false);
      setData([...updatedData]);
    }
  };
  const handleWatchUnWatch = (id, status) => {
    let data = {
      fault_report_id: id,
      watch_status: status,
    };
    updateWatch(data);
  };
  const previewReport = (item) => {
    if (activeType == "draft") {
      navigate(`/report_fault/true/${item.fault_report.fault_report_id}`);
    } else {
      navigate(
        `/review_fault/${item.fault_report.fault_report_id}/${tabValue}/false`
      );
    }
  };
  const handleBatchActions = (e) => {
    let { name, value } = e.target;
    if (value <= 3) {
      let newArray = [];

      for (let i = 0; i < showBatchActionMenus.length; i++) {
        let obj = {};
        if (value === showBatchActionMenus[i].id) {
          obj["id"] = showBatchActionMenus[i].id;
          obj["label"] = showBatchActionMenus[i].label;
          obj[" value"] = showBatchActionMenus[i].value;
          obj["isSelected"] = !showBatchActionMenus[i].isSelected;
          newArray.push(obj);
        } else {
          obj["id"] = showBatchActionMenus[i].id;
          obj["label"] = showBatchActionMenus[i].label;
          obj[" value"] = showBatchActionMenus[i].value;
          obj["isSelected"] = false;
          newArray.push(obj);
        }
      }

      setShowBatchActionMenus([...newArray]);
      setFilterActionData({
        status: `${
          newArray[e.target.value - 1].label
        } ${showBatchActionCount} Selected`,
        message: `${newArray[e.target.value - 1].label}`,
      });
    } else {
    }
  };
  const handlePagignation = (event, value) => {
    let responseUserData = JSON.parse(localStorage.getItem("userData"));
    let data = {
      user_id: responseUserData.user_id,
      status: activeType,
      role: responseUserData.role,
      limit: 10,
      offset: value - 1,
      priority_level_id:
        filterData?.priority_level_id !== null
          ? filterData?.priority_level_id
          : "",
      filter_status: filterData.status,
      date:
        filterData?.date !== null ? getYYYYMMDDFormat(filterData?.date) : "",
      search: filterData?.search,
      fault_type: filterData.typeOfFault,
    };
    getFaultReports(data);
    setCurrentPage(value);
  };

  const handleBatchApply = () => {
    // setFilterData({ ...filterData, batchAction: filterActinData.status })
    setOpen(false);

    if (filterActinData.message === "Watch") {
      setAlert(true);
      setAlertData({
        label: `${filterActinData.message} Report`,
        description: `Are you sure want to ${filterActinData.status}`,
        buttonvalue: "Confirm",
        buttonStyle: "blue",
      });
    } else if (filterActinData.message === "Unwatch") {
      setAlert(true);
      setAlertData({
        label: `${filterActinData.message} Report`,
        description: `Are you sure want to ${filterActinData.status}`,
        buttonvalue: "Confirm",
        buttonStyle: "blue",
      });
    } else {
      setAlert(true);
      setAlertData({
        label: `${filterActinData.message} Report `,
        description: `Are you sure want to ${filterActinData.status}`,
        buttonvalue: "Confirm",
        buttonStyle: "blue",
      });
    }
  };

  const reportActions = (item, action) => {
    setAlert(true);
    setFaultId(item.fault_report.fault_report_id);
    if (action === "withdraw") {
      setAlertData({
        label: "Withdraw Report",
        description: "Are you sure want to withdraw this report",
        buttonvalue: "Confirm",
        buttonStyle: "blue",
      });
    } else {
      setAlertData({
        label: "Delete Report",
        description: "Are you sure want to delete this report",
        buttonvalue: "Confirm",
        buttonStyle: "blue",
      });
    }
  };

  const withdrawConfirm = () => {
    updateReport({
      status: "deleted",
      fault_report_id: faultId,
      is_response_format_changed: "yes",
    });
    setAlert(false);
  };

  const removeAlert = () => {
    setAlertStatus({ message: "", status: false, color: "", icon: "" });
  };
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleReset = () => {
    setFilterData({
      ...filterData,
      date: null,
      typeOfFault: "",
      status: "",
      category: "All",
      batchAction: "",
      search: "",
      level_no: "All",
      priority_level_id: null,
    });
  };

  const handleDateField = (e) => {
    if (e.code == "Backspace")
      setFilterData((prev) => ({ ...prev, date: null }));
    preventDatePickerKeyboard(e);
  };

  const handleSortStatus = (name, column_name, currentStatus) => {
    const madeStatusFalse = {
      date: false,
      title: false,
      priority: false,
      status: false,
      lastUpdate: false,
      level_no: false,
    };
    setSelectedSort({ ...madeStatusFalse, [name]: true });
    setSortStatus({
      date: name == "date" ? !sortStatus.date : false,
      title: name == "title" ? !sortStatus.title : false,
      priority: name == "priority" ? !sortStatus.priority : false,
      status: name == "status" ? !sortStatus.status : false,
      lastUpdate: name == "lastUpdate" ? !sortStatus.lastUpdate : false,
      level_no: name == "level_no" ? !sortStatus.level_no : false,
    });
    setSortApiData({ column_name, type: currentStatus ? "DESC" : "ASC" });
  };

  const handleSortReset = () => {
    setSortApiData({ type: "", column_name: "" });
    setSortStatus({
      date: false,
      title: true,
      priority: true,
      status: true,
      lastUpdate: true,
      level_no: true,
    });
  };
  return (
    <>
      <ToastAlert
        message={alertStatus.message}
        status={alertStatus.status}
        color={alertStatus.color}
        icon={alertStatus.icon}
      />
      {alert && (
        <Faultalert
          confirmFunc={withdrawConfirm}
          closeFunc={setAlert}
          alertData={alertData}
        />
      )}
      <Grid container rowSpacing={1} sx={{ pt: 1 }}>
        {activeType !== "draft" ? (
          <>
            <Grid
              item
              xs={9}
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                pr: 1,
              }}
            >
              {!showBatchAction ? (
                <>
                  {activeType !== "all" && (
                    <FormControl size="small" sx={{ width: 180, ml: 2 }}>
                      <InputLabel
                        sx={{
                          color: "#123FA9",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Fault Type
                      </InputLabel>
                      <Select
                        value={filterData.typeOfFault}
                        label="Type of Work"
                        name="typeOfFault"
                        sx={{
                          color: "#123FA9",
                          fontSize: "14px",
                          borderRadius: "6px",
                          boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF",
                        }}
                        onChange={(e) => {
                          handleFilterDatas(e.target.value, e.target.name);
                        }}
                      >
                        {faultLocations.map((item, index) => (
                          <MenuItem
                            sx={{ color: "#0F2C6F" }}
                            key={index}
                            value={item.fault_report_types_name}
                          >
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <FormControl size="small" sx={{ width: 180, pl: 1 }}>
                    <InputLabel
                      sx={{
                        color: "#123FA9",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Status
                    </InputLabel>
                    <Select
                      sx={{
                        color: "#123FA9",
                        fontSize: "14px",
                        borderRadius: "6px",
                        boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF",
                      }}
                      value={filterData.status}
                      label="Status"
                      name="status"
                      onChange={(e) => {
                        handleFilterDatas(e.target.value, e.target.name);
                      }}
                    >
                      {filterSources.map((item, index) => (
                        <MenuItem
                          sx={{ color: "#0F2C6F" }}
                          key={index}
                          value={item.fault_status_name}
                        >
                          {item.display_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl size="small" sx={{ width: 180, pl: 1 }}>
                    <InputLabel
                      sx={{
                        color: "#123FA9",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Level
                    </InputLabel>
                    <Select
                      sx={{
                        color: "#123FA9",
                        fontSize: "14px",
                        borderRadius: "6px",
                        boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF",
                      }}
                      value={filterData.level_no}
                      label="level_no"
                      name="level_no"
                      onChange={(e) => {
                        handleFilterDatas(e.target.value, e.target.name);
                      }}
                      MenuProps={{ style: { maxHeight: 300 } }}
                    >
                      <MenuItem sx={{ color: "#0F2C6F" }} value="All">
                        All
                      </MenuItem>
                      {levelDatas !== null &&
                        Array.isArray(levelDatas) &&
                        levelDatas.map((item, index) => (
                          <MenuItem
                            sx={{ color: "#0F2C6F" }}
                            key={index}
                            value={item?.level_id}
                          >
                            {item.level_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl size="small" sx={{ width: 180, pl: 1 }}>
                    <InputLabel
                      sx={{
                        color: "#123FA9",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Category
                    </InputLabel>
                    <Select
                      sx={{
                        color: "#123FA9",
                        fontSize: "14px",
                        borderRadius: "6px",
                        boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF",
                      }}
                      value={filterData.category}
                      label="category"
                      name="category"
                      onChange={(e) => {
                        handleFilterDatas(e.target.value, e.target.name);
                      }}
                      MenuProps={{ style: { maxHeight: 300 } }}
                    >
                      <MenuItem sx={{ color: "#0F2C6F" }} value="All">
                        All
                      </MenuItem>
                      {categoryData?.length != 0 &&
                        categoryData?.map((item, index) => (
                          <MenuItem
                            sx={{ color: "#0F2C6F" }}
                            key={index}
                            value={item?.fault_category_id}
                          >
                            {item.fault_category_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ width: 200, ml: 1 }}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      size="small"
                      // label={activeType === 'all' ? 'Requested Date' : 'Closed Date'}
                      value={filterData.date}
                      onChange={(newValue) => {
                        handleFilterDatas(newValue, "date");
                      }}
                      renderInput={(params) => (
                        <TextField
                          autoComplete="off"
                          onKeyDown={handleDateField}
                          size="small"
                          {...params}
                        />
                      )}
                      disableFuture={true}
                    />
                  </FormControl>
                  {userData.role_id && userData.role_id !== 4 && (
                    <FormControl size="small" sx={{ width: 180, pl: 1 }}>
                      <InputLabel
                        sx={{
                          color: "#123FA9",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        Priority
                      </InputLabel>
                      <Select
                        sx={{
                          color: "#123FA9",
                          fontSize: "14px",
                          borderRadius: "6px",
                          boxShadow: "0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF",
                        }}
                        value={filterData?.priority_level_id}
                        label="Priority"
                        name="priority_level_id"
                        onChange={(e) => {
                          handleFilterDatas(e.target.value, e.target.name);
                        }}
                        MenuProps={{ style: { maxHeight: 300 } }}
                      >
                        <MenuItem sx={{ color: "#0F2C6F" }} value="">
                          All
                        </MenuItem>
                        {priorityLevels !== null &&
                          Array.isArray(priorityLevels) &&
                          priorityLevels?.map((item, index) => (
                            <MenuItem
                              sx={{ color: item?.text_colour_code }}
                              key={index}
                              value={item?.id}
                            >
                              <Box sx={{ display: "flex", gap: "5px" }}>
                                <img
                                  src={`${process.env.REACT_APP_ASSETS_PATH}/${item?.path}`}
                                  alt={""}
                                  width={20}
                                  style={{ objectFit: "contain" }}
                                />
                                {item?.priority_level_name}
                              </Box>
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}
                </>
              ) : (
                <FormControl fullWidth size="auto">
                  <OutlinedInput
                    placeholder="Batch Action"
                    variant="outlined"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    value={filterData.batchAction}
                    onClick={(e) => handleClick(e)}
                    endAdornment={
                      <DropDownIcon style={{ marginLeft: "5px" }} />
                    }
                    sx={{
                      width: 200,
                      height: "40px",
                      color: "#123FA9",
                      fontWeight: 500,
                    }}
                  />
                </FormControl>
              )}
            </Grid>
            {!showBatchAction ? (
              <Grid
                item
                xs={3}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  pr: 1,
                  alignItems: "center",
                }}
              >
                <FormControl size="small">
                  <OutlinedInput
                    name={"search"}
                    onChange={(e) => {
                      handleFilterDatas(e.target.value, e.target.name);
                    }}
                    placeholder="Search..."
                    endAdornment={<SearchIcon />}
                  />
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">
                  {console.log("sortStatus ", sortStatus)}
                  {/* <Checkbox
                                            icon={<CheckboxIcon/>}
                                             size="small"
                                            onClick={(e) => handleAllCheck(e)}
                                            checked={isAllChecked}

                                        /> */}
                </StyledTableCell>
                {activeType == "all" && <StyledTableCell></StyledTableCell>}
                <StyledTableCell>Ticket-ID</StyledTableCell>
                <StyledTableCell
                  onClick={() =>
                    handleSortStatus("date", "created_at", sortStatus.date)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {"Date Reported"}
                    {/* {sortStatus.date ? <ArrowUpwardIcon style={SortIconStyle} />   : selectedSort.date ? <ArrowDownwardIcon style={SortIconStyle}/> : <ArrowdDisableIcon style={SortIconStyle}/>} */}
                    {selectedSort.date ? (
                      sortStatus.date ? (
                        <ArrowUpwardIcon style={SortIconStyle} />
                      ) : (
                        <ArrowDownwardIcon style={SortIconStyle} />
                      )
                    ) : (
                      <ArrowdDisableIcon style={SortIconStyle} />
                    )}
                  </Box>
                </StyledTableCell>
                {userData.role_id && userData.role_id !== 4 && (
                  <StyledTableCell
                    onClick={() =>
                      handleSortStatus(
                        "priority",
                        "priority_level_name",
                        sortStatus.priority
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {userData.role_id && userData.role_id !== 4 && "Priority"}
                      {/* {sortStatus.priority ? <ArrowUpwardIcon style={SortIconStyle} />  : selectedSort.priority ? <ArrowDownwardIcon style={SortIconStyle}/> : <ArrowdDisableIcon style={SortIconStyle}/> } */}
                      {selectedSort.priority ? (
                        sortStatus.priority ? (
                          <ArrowUpwardIcon style={SortIconStyle} />
                        ) : (
                          <ArrowDownwardIcon style={SortIconStyle} />
                        )
                      ) : (
                        <ArrowdDisableIcon style={SortIconStyle} />
                      )}
                    </Box>
                  </StyledTableCell>
                )}
                <StyledTableCell
                  onClick={() =>
                    handleSortStatus("title", "fault_title", sortStatus.title)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {"Title"}
                    {/* {sortStatus.title ? <ArrowUpwardIcon style={SortIconStyle} />  : selectedSort.title ? <ArrowDownwardIcon style={SortIconStyle}/> : <ArrowdDisableIcon style={SortIconStyle}/> } */}
                    {selectedSort.title ? (
                      sortStatus.title ? (
                        <ArrowUpwardIcon style={SortIconStyle} />
                      ) : (
                        <ArrowDownwardIcon style={SortIconStyle} />
                      )
                    ) : (
                      <ArrowdDisableIcon style={SortIconStyle} />
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>Report Type</StyledTableCell>
                {userData.role === "BMS Coordinator" ? (
                  <StyledTableCell>Reported By</StyledTableCell>
                ) : (
                  ""
                )}
                <StyledTableCell
                  onClick={() =>
                    handleSortStatus("status", "status", sortStatus.status)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {"Category "}
                    {/* {sortStatus.status ? <ArrowUpwardIcon style={SortIconStyle} />   : selectedSort.status ? <ArrowDownwardIcon style={SortIconStyle}/> : <ArrowdDisableIcon style={SortIconStyle}/> } */}
                    {/* {selectedSort.status ? sortStatus.status ? <ArrowUpwardIcon style={SortIconStyle}/>   : <ArrowDownwardIcon style={SortIconStyle} />   :  <ArrowdDisableIcon style={SortIconStyle}/> } */}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {"Status "}
                    {/* {sortStatus.status ? <ArrowUpwardIcon style={SortIconStyle} />   : selectedSort.status ? <ArrowDownwardIcon style={SortIconStyle}/> : <ArrowdDisableIcon style={SortIconStyle}/> } */}
                    {selectedSort.status ? (
                      sortStatus.status ? (
                        <ArrowUpwardIcon style={SortIconStyle} />
                      ) : (
                        <ArrowDownwardIcon style={SortIconStyle} />
                      )
                    ) : (
                      <ArrowdDisableIcon style={SortIconStyle} />
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  onClick={() =>
                    handleSortStatus(
                      "lastUpdate",
                      "updated_at",
                      sortStatus.lastUpdate
                    )
                  }
                >
                  {"Last Updated"}
                  {selectedSort.lastUpdate ? (
                    sortStatus.lastUpdate ? (
                      <ArrowUpwardIcon style={SortIconStyle} />
                    ) : (
                      <ArrowDownwardIcon style={SortIconStyle} />
                    )
                  ) : (
                    <ArrowdDisableIcon style={SortIconStyle} />
                  )}
                </StyledTableCell>
                {/* <StyledTableCell>Job Role</StyledTableCell> */}
                <StyledTableCell
                  onClick={() => {
                    if (filterData?.level_no === "All") {
                      handleSortStatus(
                        "level_no",
                        "level_no",
                        sortStatus.level_no
                      );
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {"Level"}
                    {filterData?.level_no === "All" &&
                      (selectedSort.level_no ? (
                        sortStatus.level_no ? (
                          <ArrowUpwardIcon style={SortIconStyle} />
                        ) : (
                          <ArrowDownwardIcon style={SortIconStyle} />
                        )
                      ) : (
                        <ArrowdDisableIcon style={SortIconStyle} />
                      ))}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody>
              {data?.length > 0
                ? data?.map((item, index) => (
                    <StyledTableRow key={index}>
                      {console.log("data ", data)}
                      <StyledTableCell align="left">
                        {/* <Checkbox
                                                    icon={<CheckboxIcon/>}
                                                    size="small"
                                                    onClick={() => handleCheckbox(item)}
                                                    checked={item.fault_report.is_selected === 0 ? false : true}

                                                /> */}
                      </StyledTableCell>
                      {activeType == "all" && (
                        <StyledTableCell
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {item.fault_report.watch_status == null ||
                          item.fault_report.watch_status == "no" ? (
                            <BellUnWatch
                              onClick={() =>
                                handleWatchUnWatch(
                                  item.fault_report.fault_report_id,
                                  "yes"
                                )
                              }
                            />
                          ) : (
                            <BellWatch
                              onClick={() =>
                                handleWatchUnWatch(
                                  item.fault_report.fault_report_id,
                                  "no"
                                )
                              }
                            />
                          )}
                        </StyledTableCell>
                      )}
                      <TicIDTableCell
                        sx={{
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        onClick={() => previewReport(item)}
                      >
                        {item.fault_report.ticket_no}
                      </TicIDTableCell>

                      <StyledTableCell onClick={() => previewReport(item)}>
                        {getTimeStamp(item.fault_report.created_at)}
                      </StyledTableCell>
                      {userData.role_id && userData.role_id !== 4 && (
                        <StyledTableCell onClick={() => previewReport(item)}>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            {userData.role_id &&
                              userData.role_id !== 4 &&
                              item.fault_report?.priority_level_icon_path && (
                                <img
                                  src={`${process.env.REACT_APP_ASSETS_PATH}/${item.fault_report?.priority_level_icon_path}`}
                                  alt={item.fault_report.priority_level_name}
                                  width={20}
                                  style={{ objectFit: "contain" }}
                                />
                              )}
                            <Typography
                              sx={{
                                color:
                                  item.fault_report.text_colour_code != null
                                    ? item.fault_report.text_colour_code +
                                      " !important"
                                    : "#0F2C6F !important",
                              }}
                            >
                              {item.fault_report.priority_level_name}
                            </Typography>
                          </Box>
                        </StyledTableCell>
                      )}

                      <TableCell
                        sx={{
                          color: "#0F2C6F !important",
                          padding: "5px !important",
                          height: "50px",
                          maxWidth: 300,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                        onClick={() => previewReport(item)}
                      >
                        {item.fault_report.fault_title}{" "}
                      </TableCell>
                      <StyledTableCell onClick={() => previewReport(item)}>
                        {item.fault_report.typeOfFaultReportName}
                      </StyledTableCell>
                      {userData.role === "BMS Coordinator" ? (
                        <StyledTableCell onClick={() => previewReport(item)}>
                          {item.fault_report.created_by}
                        </StyledTableCell>
                      ) : (
                        ""
                      )}
                      <StyledTableCell>
                        {item.fault_report.fault_category_name}
                      </StyledTableCell>
                      
                      <StyledTableCell onClick={() => previewReport(item)}>
                        <Typography
                          sx={{
                            background: getStatus(
                              item.fault_report.status,
                              "bgcolor"
                            ),
                            display: "inline-flex",
                            padding: { md: "0 8px", xs: "2px 6px" },
                            fontWeight: 400,
                            fontSize: "13px",
                            color: getStatus(item.fault_report.status, "color"),
                            borderRadius: "6px",
                          }}
                        >
                          {getStatus(item.fault_report.status, "text")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell onClick={() => previewReport(item)}>
                        {getTimeStamp(item.fault_report.updated_at)}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="p">
                          {" "}
                          {item?.fault_report?.level_no}
                        </Typography>
                      </StyledTableCell>
                      {userData.role !== "BMS Coordinator" ? (
                        <StyledTableCell>
                          {activeType === "draft" ? (
                            <Button
                              sx={{ textTransform: "capitalize" }}
                              variant="outlined"
                              onClick={() => reportActions(item, "delete")}
                            >
                              Delete
                            </Button>
                          ) : null}
                        </StyledTableCell>
                      ) : (
                        ""
                      )}
                    </StyledTableRow>
                  ))
                : ""}
            </TableBody>
          </Table>

          {data?.length < 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 5,
                backgroundColor: "rgba(143, 146, 161, 0.05)",
              }}
            >
              <Typography
                sx={{ fontSize: "20px", color: "#0F2C6F" }}
                variant="p"
              >
                No Data Found
              </Typography>
            </Box>
          )}

          {data === null && (
            <Box sx={{ width: "100%", display: "grid", py: 2 }}>
              <center>
                <CircularProgress sx={{ color: "#0F2C6F" }} />
              </center>
            </Box>
          )}

          {faultCount > 0 || data?.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 2,
                p: 4,
                backgroundColor: "rgba(143, 146, 161, 0.05)",
              }}
            >
              <Pagination
                count={Math.ceil(faultCount / 10 || data?.length / 10)}
                page={currentPage}
                size="large"
                shape="rounded"
                onChange={handlePagignation}
              />
            </Box>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {showBatchActionMenus.map((item, index) => (
          <MenuItem
            key={index}
            value={item.id}
            onClick={(e) => handleBatchActions(e)}
          >
            {item.label} {showBatchActionCount} Selected{" "}
            {item.isSelected ? <Tickicon /> : ""}
          </MenuItem>
        ))}
        <MenuItem>
          <Button
            sx={{ width: "100%", fontSize: "14px" }}
            variant="contained"
            onClick={() => handleBatchApply()}
          >
            Apply
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    faultReports: state.FaultReportReducer.faultReports,
    reportUpdateStatus: state.FaultReportReducer.reportUpdateStatus,
    faultCount: state.FaultReportReducer.faultCount,
    updateWatchStatus: state.FaultReportReducer.updateWatchStatus,
    levelDatas: state.CommonReducer.levelDatas,
    myFaultsDatas: state.FaultReportReducer.myFaultReports,
    getPriorityLevelsData: state.FaultReportReducer.getPriorityLevels,
    getFaultCategoryListData: state.Service_Req_Reducer.getFaultCategoryList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFaultReports: (data) =>
      dispatch(faultReportAction.getAllFaultReport(data)),
    updateReport: (data) => dispatch(faultReportAction.updateFaultReport(data)),
    setFaultReportStateNull: () =>
      dispatch(faultReportAction.setFaultReportStateNull()),
    updateWatch: (data) => dispatch(faultReportAction.updateWatchStatus(data)),
    getAllLevels: () => dispatch(GetAllLevels()),
    getMyFaults: (params) =>
      dispatch(faultReportAction.getMyFaultReportsForWebAction(params)),
    getMyFaultReportsForWebNull: () =>
      dispatch(faultReportAction.getMyFaultReportsForWebNullAction()),
    getFaultReportsNull: () =>
      dispatch(faultReportAction.getFaultReportNullAction()),
    getPriorityLevels: () =>
      dispatch(faultReportAction.getPriorityLevelsAction()),
    getFaultCategoryData: (data) =>
      dispatch(ServiceRequestAction.getFaultCategoryData(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FaultReports);
