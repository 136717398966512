import { handleActions } from "redux-actions";

const SuperAdmin = {
  countryAddStatus: null,
  countryDeleteStatus: null,
  addBuildingStatus: null,
  addLevel: null,
  addFaultType: null,
  addServiceType: null,
  addUnit: null,

};

const reducer = handleActions(
  {
    ADD_COUNTRY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADD_COUNTRY_SUCCESS: (state, action) => {
      return {
        ...state,
        countryAddStatus: action.payload,
      };
    },
    ADD_COUNTRY_FAILURE: (state, action) => {
      return {
        ...state,
        countryAddStatus: action.payload,
      };
    },
    DELETE_COUNTRY_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    DELETE_COUNTRY_SUCCESS: (state, action) => {
      return {
        ...state,
        countryDeleteStatus: action.payload,
      };
    },
    DELETE_COUNTRY_FAILURE: (state, action) => {
      return {
        ...state,
        countryDeleteStatus: action.payload,
      };
    },
    ADD_BUILDING_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADD_BUILDING_SUCCESS: (state, action) => {
      return {
        ...state,
        addBuildingStatus: action.payload,
      };
    },
    ADD_BUILDING_FAILURE: (state, action) => {
      return {
        ...state,
        addBuildingStatus: action.payload,
      };
    },
    ADD_FAULTTYPE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADD_FAULTTYPE_SUCCESS: (state, action) => {
      return {
        ...state,
        addFaultType: action.payload,
      };
    },
    ADD_FAULTTYPE_FAILURE: (state, action) => {
      return {
        ...state,
        addFaultType: action.payload,
      };
    },
    ADD_SERVICETYPE_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADD_SERVICETYPE_SUCCESS: (state, action) => {
      return {
        ...state,
        addServiceType: action.payload,
      };
    },
    ADD_SERVICETYPE_FAILURE: (state, action) => {
      return {
        ...state,
        addServiceType: action.payload,
      };
    },
    ADD_UNIT_INITIATE: (state) => {
      return {
        ...state,
      };
    },
    ADD_UNIT_SUCCESS: (state, action) => {
      return {
        ...state,
        addUnit: action.payload,
      };
    },
    ADD_UNIT_FAILURE: (state, action) => {  
      return {
        ...state,
        addUnit: action.payload,
      };
    },
    ADD_LEVEL_INITIATE:(state)=>{
      return{
        ...state
      }
    },
    ADD_LEVEL_SUCCESS:(state,action)=>{
      return{
        ...state,
        addLevel:action.payload
      }
    },
    ADD_LEVEL_FAILURE:(state,action)=>{
      return{
        ...state,
        addLevel:action.payload
      }
    }
  
  },
  SuperAdmin
);
export default reducer;
