import { handleActions } from "redux-actions"

const responseDef = {
    isLoading: false,
    response: null,
    isError: false,
}

const paymentDataDef = {
    status: null,
    message: "",
 }

 
const reduxFacilityBookingDetailsDef = {
    date: "",
    fromTime: "",
    endTime: "",
    roomCapacity: "",
    isVideoConference: false
  }

const facilityBookingReducerData = {
    reduxBookingData: {...reduxFacilityBookingDetailsDef},
    facilityBookingPaymentStatus: {...paymentDataDef},
    getAvailableRooms: {...responseDef},
    getSingleRoomDetailsById: {...responseDef},
    facilityBookingRedirectPayment: {...responseDef},
    getFacilityBookingDetailsById: {...responseDef},
    addNonWorkingHourRoomBooking: {...responseDef},
    getBookingDetailsList: {...responseDef},
    getAllBookingStatus: {...responseDef},
    getTecFeatureList: {...responseDef},
    getEditContactDetailsList: {...responseDef},
    getAllRoomList: {...responseDef},
    getRoomBookingTransactionDetailsList: {...responseDef},
    updateRoomBookingWatchStatus: {...responseDef},
    getTecMemberDetails: {...responseDef}
}


export const FacilityBookingReducer = handleActions({
    REDUX_FACILITY_BOOKING_ROOM_DATA_UPDATE: (state, action) => {
        return{
            ...state,
            reduxBookingData: action.payload
        }
    },
    REDUX_FACILITY_BOOKING_ROOM_DATA_NULL: (state, action) => {
        return{
            ...state,
            reduxBookingData: {...reduxFacilityBookingDetailsDef}
        }
    },
    FACILITY_BOOKING_PAYMENT_STATUS_UPDATE: (state, action) => {
        return {
            ...state,
            facilityBookingPaymentStatus: {...action.payload},
        }
    },
    FACILITY_BOOKING_PAYMENT_STATUS_NULL: (state, action) => {
        return {
            ...state,
            facilityBookingPaymentStatus: {...paymentDataDef},
        }
    }
    ,GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_INITIATE: (state, action) => {
        return {
            ...state,
            getAvailableRooms: {...state.getAvailableRooms, isLoading: true, isError: false}
        }
    },
    GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_SUCCESS: (state, action) => {
        return {
            ...state,
            getAvailableRooms: {isError: false, response: action.payload, isLoading: false}
        }
    },
    GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_FAILURE: (state, action) => {
        return {
            ...state,
            getAvailableRooms: {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_AVAILABLE_ROOMS_LIST_FACILITY_BOOKING_NULL: (state, action) => {
        return {
            ...state,
            getAvailableRooms: {...responseDef}
        }
    },
    GET_SINGLE_ROOM_LIST_BY_ID_INITIATE: (state, action) => {
        return {
            ...state,
            getSingleRoomDetailsById: {...state.getSingleRoomDetailsById, isLoading: true, isError: false}};
    },
    GET_SINGLE_ROOM_LIST_BY_ID_SUCCESS: (state, action) => {
        return {
            ...state,
            getSingleRoomDetailsById: {isError: false,response: action.payload, isLoading: false}}
    },
    GET_SINGLE_ROOM_LIST_BY_ID_FAILURE: (state, action) => {
        return {
            ...state, 
            getSingleRoomDetailsById: {response: action.payload, isLoading: false, isError: true}}
    },
    GET_SINGLE_ROOM_LIST_BY_ID_NULL: (state, action) => {
        return {
            ...state, 
            getSingleRoomDetailsById: {...responseDef}}
    },
    FACILITY_BOOKING_REDIRECT_PAYMENT_INITIATE: (state, action) => {
        return {
            ...state,
            facilityBookingRedirectPayment: {...state.facilityBookingRedirectPayment, isLoading: true, isError:false}
        }
    },
    FACILITY_BOOKING_REDIRECT_PAYMENT_SUCCESS: (state, action) => {
        return {
            ...state, 
            facilityBookingRedirectPayment: {isError: false, isLoading: false, response: action.payload}
        }
    },
    FACILITY_BOOKING_REDIRECT_PAYMENT_FAILURE: (state, action) => {
        return {
            ...state, 
            facilityBookingRedirectPayment: {response: action.payload, isLoading: false, isError: true}
        }
    },
    FACILITY_BOOKING_REDIRECT_PAYMENT_NULL: (state,action) => {
        return {
            ...state, 
            facilityBookingRedirectPayment: {...responseDef}
        }
    },  
    GET_FACILITY_BOOKING_DETAILS_BY_ID_INITIATE: (state, action) => {
        return {
            ...state,
            getFacilityBookingDetailsById: {...state.getFacilityBookingDetailsById, isLoading: true, isError:false}
        }
    },
    GET_FACILITY_BOOKING_DETAILS_BY_ID_SUCCESS: (state, action) => {
        return {
            ...state, 
            getFacilityBookingDetailsById: {isError: false, isLoading: false, response: action.payload}
        }
    },
    GET_FACILITY_BOOKING_DETAILS_BY_ID_FAILURE: (state, action) => {
        return {
            ...state, 
            getFacilityBookingDetailsById: {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_FACILITY_BOOKING_DETAILS_BY_ID_NULL: (state,action) => {
        return {
            ...state, 
            getFacilityBookingDetailsById: {...responseDef}
        }
    }, 
    ADD_NON_WORKING_HOUR_ROOM_BOOKING_INITIATE: (state, action) => {
        return {
            ...state,
            addNonWorkingHourRoomBooking: {
                ...state.addNonWorkingHourRoomBooking,
                isError: false,
                isLoading: true,
            }
        }
    },
    ADD_NON_WORKING_HOUR_ROOM_BOOKING_SUCCESS: (state, action) => {
        return {
            ...state, 
            addNonWorkingHourRoomBooking: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    ADD_NON_WORKING_HOUR_ROOM_BOOKING_FAILURE: (state, action) => {
        return {
            ...state, 
            addNonWorkingHourRoomBooking:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    ADD_NON_WORKING_HOUR_ROOM_BOOKING_NULL: (state,) => {
        return {
            ...state,
            addNonWorkingHourRoomBooking: {...responseDef}
        }
    },
    GET_ROOM_BOOKING_DETAILS_INITIATE: (state, action) => {
        return {
            ...state,
            getBookingDetailsList: {
                ...state.getBookingDetailsList,
                isError: false,
                isLoading: true,
            }
        }
    },
    GET_ROOM_BOOKING_DETAILS_SUCCESS: (state, action) => {
        return {
            ...state, 
            getBookingDetailsList: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    GET_ROOM_BOOKING_DETAILS_FAILURE: (state, action) => {
        return {
            ...state, 
            getBookingDetailsList:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_ROOM_BOOKING_DETAILS_NULL: (state,) => {
        return {
            ...state,
            getBookingDetailsList: {...responseDef}
        }
    },
    GET_ALL_BOOKING_STATUS_INITIATE: (state, action) => {
        return {
            ...state,
            getAllBookingStatus: {
                ...state.getAllBookingStatus,
                isError: false,
                isLoading: true,
            }
        }
    },
    GET_ALL_BOOKING_STATUS_SUCCESS: (state, action) => {
        return {
            ...state, 
            getAllBookingStatus: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    GET_ALL_BOOKING_STATUS_FAILURE: (state, action) => {
        return {
            ...state, 
            getAllBookingStatus:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_ALL_BOOKING_STATUS_NULL: (state,) => {
        return {
            ...state,
            getAllBookingStatus: {...responseDef}
        }
    },    
    GET_TEC_FEATURE_LIST_INITIATE: (state, action) => {
        return {
            ...state,
            getTecFeatureList: {
                ...state.getTecFeatureList,
                isError: false,
                isLoading: true,
            }
        }
    },
    GET_TEC_FEATURE_LIST_SUCCESS: (state, action) => {
        return {
            ...state, 
            getTecFeatureList: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    GET_TEC_FEATURE_LIST_FAILURE: (state, action) => {
        return {
            ...state, 
            getTecFeatureList:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_TEC_FEATURE_LIST_NULL: (state,) => {
        return {
            ...state,
            getTecFeatureList: {...responseDef}
        }
    },    
    GET_EDIT_CONTACT_DETAILS_INITIATE: (state, action) => {
        return {
            ...state,
            getEditContactDetailsList: {
                ...state.getEditContactDetailsList,
                isError: false,
                isLoading: true,
            }
        }
    },
    GET_EDIT_CONTACT_DETAILS_SUCCESS: (state, action) => {
        return {
            ...state, 
            getEditContactDetailsList: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    GET_EDIT_CONTACT_DETAILS_FAILURE: (state, action) => {
        return {
            ...state, 
            getEditContactDetailsList:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_EDIT_CONTACT_DETAILS_NULL: (state,) => {
        return {
            ...state,
            getEditContactDetailsList: {...responseDef}
        }
    },   
    GET_ALL_ROOM_LIST_INITIATE: (state, action) => {
        return {
            ...state,
            getAllRoomList: {
                ...state.getAllRoomList,
                isError: false,
                isLoading: true,
            }
        }
    },
    GET_ALL_ROOM_LIST_SUCCESS: (state, action) => {
        return {
            ...state, 
            getAllRoomList: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    GET_ALL_ROOM_LIST_FAILURE: (state, action) => {
        return {
            ...state, 
            getAllRoomList:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_ALL_ROOM_LIST_NULL: (state,) => {
        return {
            ...state,
            getAllRoomList: {...responseDef}
        }
    },       
    GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_INITIATE: (state, action) => {
        return {
            ...state,
            getRoomBookingTransactionDetailsList: {
                ...state.getRoomBookingTransactionDetailsList,
                isError: false,
                isLoading: true,
            }
        }
    },
    GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_SUCCESS: (state, action) => {
        return {
            ...state, 
            getRoomBookingTransactionDetailsList: {
                isError: false,
                isLoading: false,
                response: action.payload
            }
        }
    },
    GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_FAILURE: (state, action) => {
        return {
            ...state, 
            getRoomBookingTransactionDetailsList:  {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_ROOM_BOOKING_TRANSACTION_DETAILS_LIST_NULL: (state,) => {
        return {
            ...state,
            getRoomBookingTransactionDetailsList: {...responseDef}
        }
    },
    UPDATE_FACILITYWATCHSTATUS_INITIATE:(state)=>{
        return{
          ...state,
          updateRoomBookingWatchStatus:{
            ...state.updateRoomBookingWatchStatus,
            isError: false,
            isLoading: true,
          }
        }
      },
      UPDATE_FACILITYWATCHSTATUS_SUCCESS:(state,action)=>{
        return{
          ...state,
          updateRoomBookingWatchStatus: {
            isError: false,
            isLoading: false,
            response: action.payload
        }
        }
      },
      UPDATE_FACILITYWATCHSTATUS_FAILURE:(state,action)=>{
        return{
          ...state,
          updateRoomBookingWatchStatus:  {response: action.payload, isLoading: false, isError: true}
        }
      },
      UPDATE_FACILITYWATCHSTATUS_NULL: (state,) => {
        return {
            ...state,
            updateRoomBookingWatchStatus: {...responseDef}
        }
    },
    GET_TEC_MEMBER_DETAILS_INITIATE:(state)=>{
        return{
          ...state,
          getTecMemberDetails:{
            ...state.getTecMemberDetails,
            isError: false,
            isLoading: true,
          }
        }
      },
      GET_TEC_MEMBER_DETAILS_SUCCESS:(state,action)=>{
        return{
          ...state,
          getTecMemberDetails: {
            isError: false,
            isLoading: false,
            response: action.payload
        }
        }
      },
      GET_TEC_MEMBER_DETAILS_FAILURE:(state,action)=>{
        return{
          ...state,
          getTecMemberDetails:  {response: action.payload, isLoading: false, isError: true}
        }
      },
      GET_TEC_MEMBER_DETAILS_NULL: (state,) => {
        return {
            ...state,
            getTecMemberDetails: {...responseDef}
        }
    },


},facilityBookingReducerData)