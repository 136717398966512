
import { Box, Button, Chip, emphasize, FormControl, Grid, InputLabel, ListSubheader, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Close } from '../../Assets/icons/Alertclose.svg'
import CloseIcon from '@mui/icons-material/Close';
import * as faultReportAction from '../../redux/actions/faultreportAction'
import { useNavigate, useParams } from 'react-router-dom';
import { constructTechnicianListMappingData } from '../ServiceRequest/UpdatePopup';

const MoreInfoPopup = ({ nextStatus,techniciansList, getTechnicianList, setFaultReportStateNull, updateAlertStatus, reportUpdateStatus, updateReport, getFaultReports, reportdata, hideReviewScreen, getAllFaultStatus, faultStatusData, status,getFaultLocations,faultLocations, getPriorityLevelsData, getPriorityLevels }) => {
    const navigate = useNavigate();
    const {id} = useParams()
    const [open, setOpen] = useState(true)
    const [report, setReport] = useState({
        faultType: '',
        ticketOwner: '',
        status: '',
        instruction: '',
        comments: "",
        assignedEmployeeDataList: { id: 0, value: 'Angu' }

    })
    const [wordcount, setWordcount] = useState(0)
    const [selectedEmps, setSelectedEmps] = useState("");
    const [disabled, setDisabled] = useState(true)
    const [updateStatus, setUpdateStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [checked, setChecked] = useState(false)
    const [statusdata, setStatusdata] = useState([]);
    const [empLiSt, setEmpList] = useState([]);
    const [reportStatus, setReportStatus] = useState([]);
    const [currentStatus, setCurrentStatus] = useState(reportdata?.fault_report?.status);
    const [updateErr, setUpdateErr] = useState('');
    const [technicianErr,setTechnicianErr] = useState("");
    const [faulttypeErr,setFaultTypeErr] = useState(false);
    const [statusErr,setStatusErr] = useState('')
    const [reject,setReject] = useState({
        value:"",
        error:''
    })
    const [technicianList,setTechnicianList] = useState([])
    const [securityList,setSecurityList] = useState([])
    const [cleaningList,setCleaningList] = useState([])
    const [priorityLevels, setPriorityLevels] = useState([]);
    const [selectedPriorityLevel, setSelectedPriorityLevels] = useState("");
    const [priorityLevelsError, setPriorityLevelsError] = useState("")
 
    useEffect(() => {
        getTechnicianList({id:id,requested_for:'fault_report'})
        getFaultLocations();
        getPriorityLevels();
    }, [])

    useEffect(() => {
        if (reportUpdateStatus) {
            setUpdateStatus({
                message: reportUpdateStatus?.message, status: true, color: reportUpdateStatus?.response_status == 0 ? 'red' : 'green',
                icon: reportUpdateStatus?.response_status == 0 ? 'close' : 'tick'
            })
            setOpen(false)
            updateAlertStatus('')
            // setTimeout(() => {
            //     setFaultReportStateNull()
            // }, 5000);
        }
    }, [reportUpdateStatus])

    useEffect(() => {
         if (techniciansList?.length > 0) {
            // let technician = []
            // let security = []
            // let cleaning = []
            // setEmpList(techniciansList)
            // for(let i=0;i<techniciansList.length;i++){
            //     if(techniciansList[i].role=='technician'){
            //         technician.push(techniciansList[i])
            //     }
            //      if(techniciansList[i].role=='cleaning'){
            //         cleaning.push(techniciansList[i])
            //     }
            //      if(techniciansList[i].role=='security'){
            //         security.push(techniciansList[i])
            //     }
            // }

            // if(technician.length>0){
            //     setTechnicianList([...technician])
            // }else{
            //     setTechnicianList([])
            // }

            // if(security.length>0){
            //     setSecurityList([...security])
            // }else{
            //     setSecurityList([])
            // }

            // if(cleaning.length>0){
            //     setCleaningList([...cleaning])
            // }else{
            //     setCleaningList([])
            // }
            setTechnicianList(constructTechnicianListMappingData(techniciansList));
            }else{
                setTechnicianList([]);            
        }
    }, [techniciansList])

    console.log(technicianList)

    useEffect(() => {
        if(getPriorityLevelsData.response?.response_status === 1){
            setPriorityLevels(getPriorityLevelsData.response?.data_array);
        }
    }, [getPriorityLevelsData])

    const handleReport = (e) => {
        const { value, name } = e.target
        if (name === 'comments') {
            if (value.length <= 300) {
                setWordcount(value.length)
                setReport({ ...report, [name]: value })
            }

        } else {
            setReport({ ...report, [name]: value })
            setFaultTypeErr("")
        }

    }
    const handlePopup = () => {
        updateAlertStatus('')
        setOpen(false)
    }

    const updateReportStatus = () => {
        const data = {
            status: report.status,
            fault_report_id: reportdata?.fault_report?.fault_report_id,
            ticket_owner: report.ticketOwner,
            type_of_fault: report.faultType,
            fault_description: report.status=="rejected" ? reject.value : report.comments,
            assignedEmployeeDataList: selectedEmps && JSON.stringify([JSON.parse(selectedEmps)]),
            is_response_format_changed : "yes"

        }

        if(report.status == ""){
            setStatusErr('Fault Status Required!')
        }
 
        if (report.status === 'work_in_progress') {
            if (report.status === ''|| report.faultType==='') {
                setUpdateErr('Fault Type & Fault Status Required!')
             } else {
                updateReport(data)
                setUpdateErr('')
            }
        } else if(report.status === "pending_investigation" || report.status==='reassign_technician'){
            if(selectedEmps.length > 0 && selectedPriorityLevel !== null && selectedPriorityLevel !== ""){
                updateReport({...data, priority_level_id: selectedPriorityLevel});
            }else{
                console.log(selectedEmps, selectedPriorityLevel);
                if(selectedEmps === ""){
                    setTechnicianErr("Select Technician")
                }
                if(selectedPriorityLevel === ""){
                    setPriorityLevelsError("Priority Level must be selected");
                }
            }
        }else if(report.status==="closed"){
            if(!report.faultType){
                setFaultTypeErr("Select Fault Type")
            }else{
                updateReport(data)
            }
        }else if(report.status=="rejected"){
            if(!reject.value){
                setReject({
                    ...reject,
                    error:"Should not be empty"
                })
            }else{
                updateReport(data)
            }
        }else if(report.status == "work_completed"){             
            updateReport(data)            
        }
        else {
            if (report.status === '') {
                setUpdateErr('Fault Status Required!')
            } else {
                updateReport(data)
                setUpdateErr('')
            }
        }


    }

    const handleSelectedEmp = (e) => {
        // let emp = [], removerList = [];
        // let newValue = JSON.parse(e.target.value);
        // let isExist = false
        // if (selectedEmps.length === 0) {
        //     emp.push(newValue)
        //     isExist = true;
        // } else {
        //     for (var i = 0; i < selectedEmps.length; i++) {
        //         if (newValue.user_id === selectedEmps[i].user_id) {
        //             isExist = true;
        //             continue;
        //         } 
                
        //     }
        // }

        // if (!isExist) {
        //     emp.push(newValue)
        // }

        let {name, value} = e.target;

 
        setSelectedEmps(value)
        setReport({ ...report, assignedEmployeeDataList: JSON.stringify([JSON.parse(value)]) })
        setTechnicianErr("");

    }

    const handleDisabled = () => {
        setChecked(!checked)
        setDisabled(!disabled)
    }

    const menuFilter = (id) => {
        let filter = selectedEmps.filter((value) => {
            return value.id !== id
        })
        setSelectedEmps(filter)
    }

    return (
        <>
            <Modal open={open} sx={{ overflow: 'scroll' }}>
                <Box sx={{
                    position: 'absolute',
                    left: { xs: '2%', sm: '30%' },
                    top: '7%',
                    backgroundColor: '#FFFFFF',
                    width: { xs: 'auto', md: '600px' }
                }}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        borderBottom: '1px solid #C0C7D7',
                        borderRadius: '4px 4px 0px 0px',
                        padding: '24px'
                    }}>
                        <Typography sx={{ fontSize: '18px', color: '#0F2C6F',fontWeight:500 }}>Update Fault Report Status</Typography>
                        <Close style={{ cursor: 'pointer' }} onClick={() => handlePopup()} />
                    </Box>
                    
                    <Box sx={{ height: '80px', padding: '24px', mt: 2 }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Update Status to*</Typography>
                        <FormControl fullWidth sx={{ mt: '10px' }}>
                            {/* <InputLabel sx={{ color: ' #9AA6C3' }}>Status</InputLabel> */}
                            <Select  sx={{ color: '#0F2C6F' }}  value={report.status} name='status' onChange={(e) => { 
                                handleReport(e) 
                                setStatusErr('')
                                }}>

                                {nextStatus?.length > 0 && nextStatus?.map((value) => (
                                    <MenuItem value={value.fault_status_name}>{value.display_name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {statusErr &&  <Box sx={{ mt: '15px', mb: '5px' }}>
                                <Typography sx={{ color: '#0F2C6F', mt: '3px',color:'red' }}>{statusErr}</Typography>
                            </Box>
                        }
                    </Box>
                    
                    {report.status==="closed" ? <Box sx={{ height: '80px', padding: '24px' }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px' }}>Fault Type *</Typography>
                        <FormControl sx={{ mt: '10px', width: 300 }}>
                            <InputLabel sx={{ color: ' #9AA6C3' }}>Choose Fault Type</InputLabel>
                            <Select label='Choose Fault Type' sx={{ color: '#0F2C6F' }} value={report.faultType} name='faultType' onChange={(e) => { handleReport(e) }} >
                                {faultLocations?.length>0 && faultLocations.map((item)=>(
                                       <MenuItem value={item.fault_report_types_id}>{item.display_name}</MenuItem>
                                ))}
                               
                            </Select>
                        </FormControl>

                        {faulttypeErr && <Typography sx={{color:"red"}}>{faulttypeErr}</Typography>}
                    </Box> : ''
                    }
                    
                    {
                        report.status=="rejected" && <Box  sx={{ padding: '24px' }}>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Please provide reason for rejecting </Typography>
                            <TextField multiline fullWidth value={reject.value}  rows={4} onChange={(e)=>setReject({
                                value:e.target.value,
                                error:""
                            })} />

                        {reject.error && <Typography sx={{color:"red"}}>{reject.error}</Typography>}                
                        </Box>
                    }

                    <>
                        <Box sx={{ padding: '24px', mt: 2 }}>
                        {
                        (report.status === "pending_investigation" || report.status === 'reassign_technician') &&  
                    <Box>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Assign Personnel*</Typography>
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                
                                {/* {empLiSt?.length > 0 ? <Select onChange={(e) => { handleSelectedEmp(e) }} 
                                    value={report.assignedEmployeeDataList} name='assignedEmployeeDataList'>
                                    <ListSubheader>Technician</ListSubheader>
                                        {technicianList.map((value,index)=>(
                                            <MenuItem value={JSON.stringify(value)}>{`${index+1}. ${value.name} (${value.role})`}</MenuItem>
                                        ))}
                                    <ListSubheader>Security</ListSubheader>
                                        {securityList.map((value,index)=>(
                                            <MenuItem value={JSON.stringify(value)}>{`${index+1}. ${value.name} (${value.role})`}</MenuItem>
                                        ))}
                                    <ListSubheader>Cleaning</ListSubheader>
                                        {cleaningList.map((value,index)=>(
                                            <MenuItem value={JSON.stringify(value)}>{`${index+1}. ${value.name} (${value.role})`}</MenuItem>
                                        ))}
                                </Select> : ''
                                } */}

<Select sx={{width:"60%"}} fullWidth 
                            onChange={(e)=>{
                                handleSelectedEmp(e)                             
                            }}     
                            value={selectedEmps || ""}
                            // value={data.personnel || ""}                        
                                 name="empList">
                                      {technicianList.length > 0 && technicianList?.map((item, index) => 
                                        <MenuItem key={index} value={item?.isHeader ? undefined : JSON.stringify({user_id:item?.user_id, value: item?.name})}
                                             sx={
                                                item?.isHeader && {
                                                    fontWeight: 600,
                                                    textTransform: "capitalize",
                                                    color: "#0F2C6F"
                                                
                                                }
                                            }
                                         
                                        >
                                            {item?.isHeader ? item?.role : item?.name}
                                        </MenuItem>
                            )}
                            </Select>
                            </FormControl>

                          

                            {/* {
                                selectedEmps?.length > 0 ? <Grid container sx={{ mt: '2px' }}>
                                    {selectedEmps?.map((value) => <Grid item xs={6} sx={{ my: '4px',mx:'1px' }}>
                                        <Chip label={`${value.name}(${value.role})`} deleteIcon={<CloseIcon style={{ color: '#f0f0f07a' }} />} sx={{ color: '#FFFFFF', backgroundColor: '#123FA9' }}
                                            onDelete={() => { menuFilter(value.id) }}
                                        ></Chip></Grid>)}

                                </Grid> : null
                            } */}

                            {technicianErr && <Typography sx={{color:"red"}}>{technicianErr}</Typography>}

                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px', mt: 2 }}>Priority Levels*</Typography>

<FormControl fullWidth sx={{ mt: 1 }}>
    <Select value={selectedPriorityLevel || ""} onChange={e => {
        setSelectedPriorityLevels(e.target.value)
        setPriorityLevelsError("");
        }}  
        sx={{ 
            "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                gap:1,
            }
        }}
        >                                    
    {priorityLevels?.map((item, index) =>
    <MenuItem key={index} value={item.id} sx={{display: 'flex', alignItems: "center", gap:1}}>{item?.path && <img src={`${process.env.REACT_APP_ASSETS_PATH}/${item?.path}`} alt={item?.priority_level_name} width={20}  style={{objectFit: "contain"}} />} {item.priority_level_name}</MenuItem>
     )}                                 
    </Select>
</FormControl>

<Typography sx={{color:"red"}}>{priorityLevelsError}</Typography>


                            <Box sx={{ mt: '25px', mb: '5px' }}>
                                <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Comments for Technician</Typography>
                                <OutlinedInput
                                    fullWidth
                                    placeholder='You may write a note to assigned personnel.'
                                    value={report.comments}
                                    name='comments'
                                    onChange={(e) => handleReport(e)}
                                    minRows={3}                                    
                                    multiline   

                                />
                                <Typography sx={{ color: '#0F2C6F', mt: '3px' }}>[{wordcount}/300]</Typography>
                            </Box>

                            {updateErr?.length>0 ? <Box sx={{ mt: '15px', mb: '5px' }}>
                                <Typography sx={{ color: '#0F2C6F', mt: '3px',color:'red' }}>{updateErr}</Typography>
                            </Box>:''
                            }

                        </Box>}

                        {report.status === 'update_to_bms' || report.status === 'complete_investigation' &&  
                        <>
                        <Box sx={{ mb: '5px' }}>
                                <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Comments</Typography>
                                <OutlinedInput
                                    fullWidth
                                    placeholder='You may write a note.'
                                    value={report.comments}
                                    name='comments'
                                    onChange={(e) => handleReport(e)}
                                    minRows={3}                                    
                                    multiline   

                                />
                                <Typography sx={{ color: '#0F2C6F', mt: '3px' }}>[{wordcount}/300]</Typography>
                            </Box>

                            {updateErr?.length>0 ? <Box sx={{ mt: '15px', mb: '5px' }}>
                                <Typography sx={{ color: '#0F2C6F', mt: '3px',color:'red' }}>{updateErr}</Typography>
                            </Box>:''
                            }
                            </>
                             }
                            
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9'}} variant='contained' onClick={() => updateReportStatus()}>Submit</Button>
                            </Box>
                        </Box>
                    </>
                   
                </Box>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        reportUpdateStatus: state.FaultReportReducer.reportUpdateStatus,
        techniciansList: state.FaultReportReducer.techniciansList,
        faultLocations: state.FaultReportReducer.typeOfFault,
        getPriorityLevelsData: state.FaultReportReducer.getPriorityLevels,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateReport: (data) => (dispatch(faultReportAction.updateFaultReport(data))),
        getFaultReports: (data) => (dispatch(faultReportAction.getAllFaultReport(data))),
        setFaultReportStateNull: () => dispatch(faultReportAction.setFaultReportStateNull()),
        getTechnicianList: (data) => dispatch(faultReportAction.getTechnicianList(data)),
        getFaultLocations: () => dispatch(faultReportAction.getTypeOfFault()),
        getPriorityLevels: () =>dispatch(faultReportAction.getPriorityLevelsAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MoreInfoPopup)
