import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import { Button, FormControl, Grid, InputLabel, Menu, MenuItem, Select, Snackbar, SnackbarContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../Assets/icons/Close.svg";
import { ReactComponent as DropIcon } from "../../Assets/icons/Dropdown.svg";
import { connect } from "react-redux";
import * as commonAction from "../../redux/actions/commonAction";
const ManageEmployees = () => {
  const location = useLocation({ state: false });
  const navigate = useNavigate();
  const [toastOpen, setToastOpen] = useState(location.state);
  const [userType, setUserType] = useState("");
  const [menuOpen,setMenuOpen] = useState(false);
  const [anchorEl,setAnchorEl] = useState(null)
  let {tabvalue}  =useParams();
  let tab=parseInt(tabvalue);
  useEffect(() => {
    let userToken = localStorage.getItem("userToken");
    let user = JSON.parse(localStorage.getItem("userData"));
    setUserType(user.role);
    if (!userToken) {
      navigate("/");
    }
  }, []);

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToastOpen(false);
    navigate("", { state: null });
  };

  return (
    <React.Fragment>
      <Grid container sx={{backgroundColor:"rgba(143, 146, 161, 0.05)",minHeight:"100%"}} >
        <Grid item xs={0.5} ></Grid>
        <Grid item xs={11}>
        <Box
        sx={{
          paddingTop: { md: "60px", xs: "30px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor:'#F9FAFA'
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { md: "26px", sm: "22px", xs: "16px" },
              color: "#002D74",
            }}
          >
            Manage Employees
          </Typography>

          {userType === "Tenant Manager" && (
            <Box
              sx={{
                gap: "5px",
                display: "flex",
                backgroundColor:'#F9FAFA'
              }}
            >
              <Button onClick={() => navigate('/manage_units_level_access')} sx={{backgroundColor:'#123FA9',borderRadius:'6px',color:'#FFFFFF',fontSize:'14px',fontWeight:600,padding:'8px 10px',
              minWidth:'146px',textTransform:'capitalize','&:hover':{backgroundColor:"#123FA9"}}}>
                Bulk Update Default Access
              </Button>
              <Button onClick={(e)=>{
                setMenuOpen(true)
                setAnchorEl(e.currentTarget)
              }} 
              sx={{backgroundColor:'#123FA9',borderRadius:'6px',color:'#FFFFFF',fontSize:'14px',fontWeight:600,padding:'8px 10px',
              width:'146px',textTransform:'capitalize','&:hover':{backgroundColor:"#123FA9"}}} endIcon={<DropIcon/>}>Add Employee
              </Button>
              <Menu anchorEl={anchorEl} open={menuOpen} onClose={()=>setMenuOpen(false)} >
                <MenuItem onClick={()=>navigate('/batch_onboarding')} sx={{width:'146px',color:' #0F2C6F'}}>Batch</MenuItem>
                <MenuItem onClick={()=>navigate('/single_employee_onboard')} sx={{width:'146px',color:'#0F2C6F'}}>Individual</MenuItem>
              </Menu>
            </Box>
          )}
        </Box>

       
          {/* <Typography
            sx={{
              fontWeight: 500,
              color: "#0F2C6F",
              my:3,
              fontSize: { md: "14px", xs: "12px" },
             
              
            }}
          >
            Below is a list of onboarding submissions
          </Typography> */}
          

         <Box sx={{ backgroundColor:'#FFFFFF',borderRadius:"8px 8px 0px 0px",mt:5 }}>
          <Tabs tabValue={tab} />
        </Box> 
      </Box>
    </Grid>
        <Grid item xs={0.5} ></Grid>
      </Grid>
     
      <Snackbar
        open={toastOpen}
        autoHideDuration={5000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ display: "flex", alignItems: "center" }}
      >
        <SnackbarContent
          sx={{
            background: "#fff",
            borderLeft: "6px solid #66B3A8",
            height: "56px",
          }}
          message={
            <Typography
              sx={{
                color: "#0F2C6F",
                fontSize: "14px",
                fontWeight: 600,
                display: "flex",
                alignItems: "center",

                justifyContent: "space-between",
              }}
            >
              Onboarding Request Submitted
            </Typography>
          }
          action={
            <CloseIcon
              width={16}
              height={16}
              onClick={handleToastClose}
              cursor="pointer"
              style={{ float: "right" }}
            />
          }
        />
      </Snackbar>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageEmployees);
