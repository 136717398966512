import React,{useEffect} from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Button,
  TextField
} from "@mui/material";
import { ReactComponent as DropDownArrow } from "../../Assets/icons/DropDownArrow.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ActiveTenant from "./ActiveTenant";
import Loader from "../common/Loader";
import { useParams } from 'react-router-dom';
import { DatePicker } from "@mui/x-date-pickers";
import '../../index.css';
import {ReactComponent as SearchIcon} from '../../Assets/icons/SearchIcon.svg';
import { preventDatePickerKeyboard } from "../../Utils/utils";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography variant="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TAB_HEADING = [
  {
    id: 3,
    name: "Active",
  },
  {
    id: 2,
    name: "Onboarding",
  },
  {
    id: 4,
    name: "Offboarding",
  },
  {
    id: 5,
    name: "Inactive",
  },
  // {
  //   id: 1,
  //   name: "Drafts",
  // },
];

const applicableFilters = [
  {
    id: 0,
    status: false,
    sort:true,
    date: true,
    search: true,
    max_emp_size: false,
    isResetApplicable: true,
    statusHeading: 'Status',
    dateHeading: 'Onboarding Date',
    searchHeading: 'Search by company',
    empSizeHeding: 'Employee size',
    disableFeatureDate: true
  },
  {
    id: 1,
    status: false,
    sort:true,
    date: true,
    search: true,
    max_emp_size: false,
    isResetApplicable: true,
    statusHeading: 'Status',
    dateHeading: 'Onboarding Date',
    searchHeading: 'Search by company',
    empSizeHeding: '',
    disableFeatureDate: false
  },
  {
    id: 2,
    status: false,
    sort:true,
    date: true,
    search: true,
    max_emp_size: false,
    isResetApplicable: true,
    statusHeading: '',
    dateHeading: 'Offboard Date',
    searchHeading: 'Search by company',
    empSizeHeding: '',
    disableFeatureDate: false
  },
  {
    id: 3,
    status: false,
    sort:true,
    date: true,
    search: true,
    max_emp_size: false,
    isResetApplicable: true,
    statusHeading: 'Status',
    dateHeading: 'Offboard Date',
    searchHeading: 'Search by company',
    empSizeHeding: '',
    disableFeatureDate: true
  },
  {
    id: 4,
    status: false,
    sort:true,
    date: false,
    search: false,
    max_emp_size: false,
    isResetApplicable: false,
    statusHeading: '',
    dateHeading: '',
    searchHeading: '',
    empSizeHeding: '',
    disableFeatureDate: false
  }
]

const applicableStatus = [
  ['approved'],
  ['submitted', 'approved'],
  [''],
  ['inactive', 'rejected']
]

const employeeRange = [
  { max_emp_size: 10, title: '1 to 10' },
  { max_emp_size: 20, title: '11 to 20' },
  { max_emp_size: 30, title: '21 to 30' },
  { max_emp_size: 31, title: 'More than 30' }
]

const Company = () => {
  let { nestedTabValue } = useParams();
  let tab = parseInt(nestedTabValue)
  const [value, setValue] = React.useState(tab);
  const [loaderStatus, setLoaderStatus] = React.useState(false);
  const [applyFilter, setApplyFilter] = React.useState(applicableFilters[0]);
  const [filterByStatusData, setFilterByStatusData] = React.useState(applicableStatus[0]);
  const [filterData, setFilterData] = React.useState({ status: '', date: null, max_emp_size: '', search: '',sort:'most_recent' })

  useEffect(() => {
    if(sessionStorage.getItem("filterData")){
      setFilterData(JSON.parse(sessionStorage.getItem("filterData")))
    } 
  }, [])
  
  const handleChange = (event, newValue) => {
    setLoaderStatus(true)
    setTimeout(() => {
      setLoaderStatus(false)
    }, 1000);
    setValue(newValue);
    setApplyFilter(applicableFilters[newValue])
    setFilterByStatusData(applicableStatus[newValue])
    setFilterData({ status: '', date: null, max_emp_size: '', search: '',sort:'most_recent' })
  };
  const handleFilter = (value, name) => {
    if(value!=null){
      setFilterData({ ...filterData, [name]: value })
    }
 
  }
  const handleReset = () => {
    setFilterData({ status: '', date: null, max_emp_size: '', search: '',sort:'' })
  }
  const CustomTabs = (props) => {
    return (
      <Grid
        container
        sx={{ py: 2, backgroundColor: "#FFFFFF" }}
      >
        <Grid
          item
          md={9}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          {applyFilter?.status ?
            <FormControl fullWidth size='small' sx={{ width: 160,ml:2 }} >
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>
                {applyFilter?.statusHeading}
              </InputLabel>
              <Select value={filterData.status} name={'status'}
                onChange={(e) => handleFilter(e.target.value, e.target.name)}
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF',
                }} label="Type of work">
                {filterByStatusData.map((item, index) => (
                  <MenuItem value={item} key={index}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl> : ''}
          {applyFilter?.sort && <FormControl fullWidth size='small' sx={{ width: 160,ml:2 }}>
              <InputLabel  sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Sort</InputLabel>
                <Select  size="small" value={filterData.sort} name={'sort'}
                onChange={(e) => handleFilter(e.target.value, e.target.name)}
                sx={{ color: "#123FA9",fontSize: "14px", borderRadius: '6px',boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF',
                }} label="Sort">
                  <MenuItem value={'a_to_z'} sx={{ color: '#123FA9'}}>Company Name(A - Z)</MenuItem>
                  <MenuItem value={'z_to_a'} sx={{ color: '#123FA9'}}>Company Name(Z - A)</MenuItem>
                  <MenuItem value={'level_a_to_z'} sx={{ color: '#123FA9'}}>Level Ascending</MenuItem>
                  <MenuItem value={'level_z_to_a'} sx={{ color: '#123FA9'}}>Level Descending</MenuItem>
                  <MenuItem value={'most_recent'} sx={{ color: '#123FA9'}}>Most Recent</MenuItem>
              </Select>
            </FormControl>}
          {/* Status */}
          {applyFilter.max_emp_size ?
            <FormControl fullWidth size='small' sx={{ width: 160, ml: 2 }} >
              <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>
                {applyFilter?.empSizeHeding}
              </InputLabel>
              <Select value={filterData.max_emp_size} name={'max_emp_size'}
                onChange={(e) => handleFilter(e.target.value, e.target.name)}
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px', boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF'}} label="Type of work">
                {employeeRange.map((item, index) => (
                  <MenuItem value={item.max_emp_size} key={index}>{item.title}</MenuItem>
                ))}
              </Select>
            </FormControl> : ''}

          {/* Date */}
          {applyFilter?.date ? <FormControl  sx={{ width: 160, ml:2.5,boxShadow: '0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF' }}>
            <DatePicker
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon:DatePickerIcon
              }}
              className="datepicker"
              value={filterData.date }
              onChange={(newValue) => { handleFilter(newValue, 'date') }}
              renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
              disableFuture={applyFilter.disableFeatureDate}
            />
          </FormControl> : ""}
          {applyFilter.isResetApplicable ? <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => handleReset()}>Reset</Typography> : ""}
        </Grid>
        <Grid item md={3} xs={12} sx={{ mx: "auto" }}>
          {applyFilter?.search ? <Box
            sx={{
              float: "right",
             padding:'5px',
              display: "flex",
              alignItems: "center",
              border: " 1px solid #DBDFE9",
              borderRadius: "6px",
              mr:2
            }}
          >
            <Input
              name={'search'}
              onChange={(e) => handleFilter(e.target.value, e.target.name)}
              size="small"
              type="search"
              value={filterData.search}
              placeholder={applyFilter?.searchHeading}
              style={{
                border: "none",
                outline: "none",
                fontSize: "14px",
                fontWeight: 400,
                width: "100%",
              }}
              inputProps={{
                sx: {
                  height: { md: "25px", sm: "20px", xs: "4px" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  flexGrow: 1,
                  width: "inherit",
                },
              }}
              disableUnderline
            />
            <SearchIcon cursor="pointer" style={{ flexGrow: 0 }} />
          </Box> : ""}
        </Grid>
      </Grid>
    );
  };
  return (
    <Box
      sx={{
        "& .css-19kzrtu": {
          padding: "0",
        },
      }}
    >

      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{ background: "#fff" }}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {TAB_HEADING.map((item, index) => {
            return (
              <Tab
                key={index}
                label={
                  <Typography
                    variant="span"
                    sx={{
                      textTransform: "none",
                      letterSpacing: "-0.25px",
                      fontSize: { md: "14px", xs: "12px" },
                      color: '#6F80A9'
                    }}
                  >
                    {item.name}
                  </Typography>
                }
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {CustomTabs()}
        <ActiveTenant dataType={"approved"} filterData={filterData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {CustomTabs()}
        <ActiveTenant dataType={"submitted"} filterData={filterData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {CustomTabs()}
        <ActiveTenant dataType={"offboarded"} filterData={filterData} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        {CustomTabs()}
        <ActiveTenant dataType={"deleted"} filterData={filterData} />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        {CustomTabs()}
        <ActiveTenant dataType={"drafted"} filterData={filterData} />

      </TabPanel> */}
    </Box>
  );
};

export default Company;
