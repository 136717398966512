import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as globalSearhAction from "../redux/actions/globalSearch";
const GlobalFilter = ({ searchValue,GlobalSearchData,globalSearchService }) => {
    const [page,currentPage]=useState(0)
    useEffect(() => {
        let data={
            keyWord:searchValue,
            limit:10,
            offset:page
        }
        globalSearchService(data)

    }, [searchValue])
    
    useEffect(() => {
    //   console.log(GlobalSearchData);
    }, [GlobalSearchData])
    
    return (
        <Grid container>
            <Grid item xs={12}>

            </Grid>
        </Grid>
    )
}
const mapStateToProps = (state) => {
    return {
        GlobalSearchData:state.GlobalSearchReducer.GlobalSearchData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        globalSearchService: (data) =>
        dispatch(globalSearhAction.globalSearchService(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GlobalFilter)