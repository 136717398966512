import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Input, InputLabel, IconButton, FormControl, MenuItem, Select, Chip, Button, Switch, SwitchProps, FormGroup, FormControlLabel, Menu, Card, CardContent, CardMedia, CardActionArea, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses, Modal, CircularProgress } from "@mui/material";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as Location } from "../../Assets/icons/location.svg";
import { ReactComponent as CloseIcon } from '../../Assets/icons/AgreementCloseIcon.svg';
import { ReactComponent as Notify } from '../../Assets/icons/FacilityBookedNotifyIcon.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ReactComponent as StatusBlueIcon } from '../../Assets/icons/StatusBlueIcon.svg';
import { ReactComponent as CalendarIcon } from '../../Assets/icons/Calendar.svg';
import { ReactComponent as ClockIcon } from '../../Assets/icons/clock.svg';
import { ReactComponent as EmailIcon } from '../../Assets/icons/EmailIcon.svg';
import { ReactComponent as LocationIcon } from '../../Assets/icons/location.svg';
import { ReactComponent as LogoIcon } from '../../Assets/icons/Logo.svg';
import ToastAlert from "../common/Alert";
import Faultalert from "../common/faultalert";
import Phone from "../../Assets/icons/phone.png";
import TV from "../../Assets/icons/TV.png";
import Wifi from "../../Assets/icons/wifi.png";
import WritingWall from "../../Assets/icons/writingWall.png";
import Webcam from "../../Assets/icons/webcam.png";
import DisabledVideoIcon from "../../Assets/icons/DisabledVideoIcon.svg";
import DisabledNaturalLight from "../../Assets/icons/DisabledNaturalLight.svg";
import PhoneIcon from "../../Assets/icons/PhonePng.png"
import EmailPng from "../../Assets/icons/EmailPng.png"
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/styles';
import { addNonWorkingHourRoomBookingNullAction, facilityBookingPaymentStatusNullAction, getEditContactDetailsAction, getfacilityBookingDetailsByIdAction, updateRoomBookingWatch, updateRoomBookingWatchStatusNullAction,getfacilityBookingDetailsByIdNullAction } from '../../redux/actions/facilityBookingAction';
import { connect } from 'react-redux';
import { getTimeStamp, getUpdatedTimeBasedOnDate } from '../../Utils/utils';
import moment from 'moment';
import html2PDF from 'jspdf-html2canvas';
import { ReactComponent as BellWatch } from "../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../Assets/icons/StructuralFaultBell.svg";
import { getLoggedUserData } from '../../Utils/utils';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const costBreakdown = { dateOfRequest: '31 October 2023', requestedHours: '11:00 - 14:00', totalDuration: 3, rate: '$39/Half Hour', extraCharges: '-', totalAmount: '234.00' }
const confirmedData = {
    header: {
        title: "Amenity Booking - TEC Swing Room A #13432233",
        requestedDate: '13 jun 2022',
    }
}

const featureIcons = [
    { value: 1, label: 'Phone', src: Phone, isAvailable: true },
    { value: 2, label: 'Tv', src: TV, isAvailable: true },
    { value: 3, label: 'Wifi', src: Wifi, isAvailable: true },
    { value: 4, label: 'Video Conference', src: DisabledVideoIcon, isAvailable: false },
    { value: 5, label: 'Writing Wall', src: WritingWall, isAvailable: true },
    { value: 6, label: 'Natural Light', src: DisabledNaturalLight, isAvailable: false },
    { value: 7, label: 'Webcam', src: Webcam, isAvailable: true },
];



const QuotationHeads = ['Cost Breakdown', 'Hours', 'Price / 0.5 Hour', 'Total'];

const QuotationData = [{ title: "Facility Price", qty: 4, priceperhr: "$116", total: "$464" }, { title: "Additional Rates", qty: 0, priceperhr: '$0', total: "$0" }]

const termsAndConditionsData = [{
    title: "BOOKING POLICY",
    passages: [{ data: 'Bookings for periods during working hours will be processed immediately.' }, { data: 'Bookings for periods after working hours will be subjected to TEC’s booking policy. TEC staff will directly contact you if the booking is able to be completed via your contact number.' }]
}, {
    title: "CANCELLATION POLICY",
    passages: [{
        data: "We accept no responsibility for any cancellation by the Client. Any cancellation of the Meeting Room booking will result in a 100% cancellation booking charge. The Executive Centre will not provide a refund, whether in a partial or full amount for the total amount charged.To get in touch with our Team about cancelling your booking, please contact Events-Singapore@executivecentre.com.",
        subData: "",
    },
        // {
        //     data: "24-48 hours in advance:",
        //     subData: "50% booking charge",
        // },
        // {
        //     data: "Less than 24 hours in advance:",
        //     subData: "100% booking charge",
        // }
    ]
}, {
    title: "RESCHEDULING POLICY",
    passages: [{ data: "To reschedule, the Client must notify The Executive Centre Team 2 working days before the booking starts. The Meeting Room booking can be rescheduled up to 1 time only and within a 3-month period, at no additional charge. Rescheduling is not guaranteed and is based on the availability of the space requested.To get in touch with our Team about rescheduling your booking, please contact Events-Singapore@executivecentre.com." }]
}]

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "6px",
};


function ConfirmedBooking({ getFacilityBookingByIdData, getFacilityBookingById, getEditContactDetails, getEditContactDetailsData, addNonWorkingHourRoomBookingNull, facilityBookingPaymentStatusData, facilityBookingPaymentStatusNull, addNonWorkingHourRoomBookingData, updateRoomBookingWatch, updateRoomBookingWatchNull, updateRoomBookingWatchData, updateRoomBookingWatchStatusData, updateWatch, updateWatchNull,termsAndCondition,getFacilityBookingByIdNull }) {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [bookingDetails, setBookingDetails] = useState({});
    const [quotationDetails, setQuotationDetails] = useState([]);
    const [totalAmount, setTotalAmount] = useState("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [editContactDetails, setEditContactDetails] = useState(null);
    const { facility_id } = useParams();
    const [backNavConfirmation, setBackNavConfirmation] = useState(false);
    const [toastConfigData, setToastConfigData] = useState({ message: '', status: false, color: '', icon: '' })
    const [backNavigation, setBackNavigation] = useState(localStorage.getItem('typeof_amenity'))
    const [isNotificationClicked, setIsNotification] = useState(localStorage.getItem('notification_clicked'));
    const [termandCondition, setTermandCondition] = useState([])
    let { user_id, role_id, role } = getLoggedUserData();

    const TecContactDummyDetails = [
        { id: 1, title: "Open on", Icon: <CalendarIcon style={{ height: 25, width: 25 }} />, data: editContactDetails?.tec_contact_open_on },
        { id: 2, title: "Hours", Icon: <ClockIcon style={{ height: 25, width: 25 }} />, data: editContactDetails?.tec_contact_hours },
        { id: 3, title: "Contact", Icon: <img src={PhoneIcon} alt="phoneIcon" style={{ height: 25, width: 25, objectFit: "contain" }} />, data: editContactDetails?.tec_contact_phone },
        { id: 4, title: "Email", Icon: <img src={EmailPng} alt="phoneIcon" style={{ height: 25, width: 25, objectFit: "contain" }} />, data: editContactDetails?.tec_contact_email },
        { id: 5, title: "Reception", Icon: <LocationIcon style={{ height: 25, width: 25 }} />, data: editContactDetails?.tec_contact_reception }]

    useEffect(() => {
        if (facility_id) {
            getFacilityBookingById({ room_booking_id: Number(facility_id) })
            getEditContactDetails();
            if (addNonWorkingHourRoomBookingData.response?.response_status === 1) {
                setToastConfigData({ message: addNonWorkingHourRoomBookingData.response?.message, status: true, color: 'green', icon: 'close' })
                setTimeout(() => {
                    setToastConfigData({ message: '', status: false, color: '', icon: '' })
                }, 5000)
                addNonWorkingHourRoomBookingNull();
            } else if (addNonWorkingHourRoomBookingData.response?.response_status === 0) {
                setToastConfigData({ message: addNonWorkingHourRoomBookingData.response?.message, status: true, color: 'red', icon: 'close' })
                setTimeout(() => {
                    setToastConfigData({ message: '', status: false, color: '', icon: '' })
                }, 5000)
                addNonWorkingHourRoomBookingNull();
            }
        }
        return ()=> {
            localStorage.removeItem('notification_clicked')
            getFacilityBookingByIdNull();
        }
    }, [])
console.log('termsAndCondition ',termandCondition)
    useEffect(() => {
        console.log('from useeffect -----------', getFacilityBookingByIdData)
        if (getFacilityBookingByIdData.response?.response_status === 1) {
            setBookingDetails(getFacilityBookingByIdData.response.dataArray);
            let tempQuotationArr = getFacilityBookingByIdData.response?.dataArray?.costBreakdownArrayList;
            setQuotationDetails(tempQuotationArr?.slice(0, tempQuotationArr.length - 1));
            setTotalAmount(tempQuotationArr[tempQuotationArr.length - 1]?.total_amount)
            setTermandCondition(termsAndCondition)
        } 
        if(getFacilityBookingByIdData?.response?.response_status === 0) {
            console.log('within 0')
            navigate('/booking_selection')
        }  
    }, [getFacilityBookingByIdData])


    useEffect(() => {
        if (getEditContactDetailsData.response?.response_status === 1) {
            setEditContactDetails(getEditContactDetailsData.response?.data_array);
        }
    }, [getEditContactDetailsData])

    useEffect(() => {
        console.log('outside facilityBookingPaymentStatusData ',facilityBookingPaymentStatusData)
        if (facilityBookingPaymentStatusData.status !== null) {
            console.log('outside facilityBookingPaymentStatusData ',facilityBookingPaymentStatusData)
            if (facilityBookingPaymentStatusData.status === 1) {
                setToastConfigData({ message: facilityBookingPaymentStatusData.message, status: true, color: 'green', icon: 'close' })
                facilityBookingPaymentStatusNull();

                setTimeout(() => {
                    setToastConfigData({ message: '', status: false, color: '', icon: '' })
                }, 6000)
            }

        }
    }, [facilityBookingPaymentStatusData])

    useEffect(() => {
        if (updateRoomBookingWatchStatusData?.response?.response_status == 1) {
            getFacilityBookingById({ room_booking_id: Number(facility_id) });
            setToastConfigData({ message: updateRoomBookingWatchStatusData?.response?.message, status: true, color: 'green', icon: 'close' })
            updateWatchNull()
            // addNonWorkingHourRoomBookingNull()
            setTimeout(() => {
                setToastConfigData({ message: '', status: false, color: '', icon: '' })
            }, 4000)
        } else if (updateRoomBookingWatchStatusData?.response != null) {
            setToastConfigData({ message: updateRoomBookingWatchStatusData?.response?.message, status: true, color: 'red', icon: 'close' })
            updateWatchNull()
            // addNonWorkingHourRoomBookingNull()
            setTimeout(() => {
                setToastConfigData({ message: '', status: false, color: '', icon: '' })
            }, 4000)
        }

    }, [updateRoomBookingWatchStatusData])

    // useEffect(() => {
    //     if(facility_id)  getFacilityBookingById({room_booking_id: Number(facility_id)})
    //     console.log('useEffect')
    //     if(facility_id) 
    // },[updateRoomBookingWatchStatusData])


    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const downloadPdf = () => {
        let data = document.getElementById('print')
        html2PDF(data, {
            jsPDF: {
                format: 'a4',
            },
            imageType: 'image/jpeg',
            output: `Amenity Booking - ${bookingDetails?.room_name} ${bookingDetails?.slt_transaction_number}}.pdf`
        })
    }

    const handleWatchUnWatch = (id, status) => {
        let data = {
            room_booking_id: id,
            watch_status: status
        }


        if (updateRoomBookingWatchStatusData.isLoading === false && getFacilityBookingByIdData.isLoading === false) {
            updateWatch(data)
        }
    }

    const CostBreakdown = () => {
        return (
            <>


                {/* <Box sx={{position:'fixed', width:'25%'}}> */}
                <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)", }}>
                    <Typography sx={{ p: 3, color: '#0F2C6F', fontSize: '14px', fontWeight: 500, lineHeight: '24px' }}>Status Updates</Typography>
                    <Box sx={{ borderBottom: "2px solid #DBDFE9" }} />
                    <Box sx={{ p: 3 }}>
                        {bookingDetails?.roomBookingLogArrayList?.map((item, index) =>
                            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <StatusBlueIcon />
                                    <ChipComponent text={item?.display_name} bgColor={item?.is_row_enabled ? item?.background_color_code : "#6F80A920"} textColor={item?.is_row_enabled ? item?.text_color_code : "#6F80A990"} />
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', pl: 3 }}>

                                    <Typography sx={{ color: item?.is_row_enabled ? '#0F2C6F' : "#6F80A9", fontSize: '12px', fontWeight: 400, lineHeight: '18px' }}>{item?.description}</Typography>
                                    <Typography sx={{ color: item?.is_row_enabled ? '#6F80A9' : "#555555", fontSize: '11px', fontWeight: 400, lineHeight: '14px' }}>{item?.is_row_enabled ? getTimeStamp(item?.created_at) : ""}</Typography>
                                    <Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <Button variant='outlined' sx={{ textTransform: 'capitalize', borderRadius: '8px', height: '48px', borderColor: '#CFDDFE', backgroundColor: '#fff', '&:hover': { backgroundColor: '#fff', borderColor: '#CFDDFE', } }} onClick={() => setShowEditModal(true)}><Typography sx={{ color: '#9AA6C3', fontSize: '12px', fontWeight: 500, lineHeight: '18px' }}>Edit Booking</Typography></Button>
                    </Box>
                </Box>

                {/* </Box> */}
            </>
        )
    }

    const AgreementModal = () => {
        return (
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Terms & Conditions
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography sx={{ mb: 3, color: '#6F80A9', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>Please read the terms and conditions below carefully before proceeding.</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mb: 3 }}>
                        <Typography sx={{ color: '#A48B2C', fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>BOOKING POLICY</Typography>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>Bookings for periods during working hours will be processed immediately.</Typography>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>Bookings for periods after working hours will be subjected to TEC’s booking policy. TEC staff will directly contact you if the booking is able to be completed via your contact number.</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', mb: 3 }}>
                        <Typography sx={{ color: '#A48B2C', fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>CANCELLATION POLICY</Typography>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>We accept no responsibility for any cancellation by the Client. Any cancellation of the Meeting Room booking will result in a 100% cancellation booking charge. The Executive Centre will not provide a refund, whether in a partial or full amount for the total amount charged.To get in touch with our Team about cancelling your booking, please contact Events-Singapore@executivecentre.com.</Typography>
                        {/* <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px', mb:1}}>no cancellation charge</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>24-48 hours in advance:</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px', mb:1}}>50% booking charge</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px'}}>less than 24 hours in advance:</Typography>
                    <Typography sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:400, lineHeight:'20px', mb:1}}>5100% booking charge</Typography> */}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mb: 3 }}>
                        <Typography sx={{ color: '#A48B2C', fontSize: '14px', fontWeight: 600, lineHeight: '20px' }}>RESCHEDULING POLICY</Typography>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}>To reschedule, the Client must notify The Executive Centre Team 2 working days before the booking starts. The Meeting Room booking can be rescheduled up to 1 time only and within a 3-month period, at no additional charge. Rescheduling is not guaranteed and is based on the availability of the space requested.To get in touch with our Team about rescheduling your booking, please contact Events-Singapore@executivecentre.com.</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', mb: 3 }}>
                        <Button variant='outlined' sx={{ textTransform: 'capitalize', color: '#0F2C6F', borderRadius: '8px', minWidth: '50px', height: '40px', }} onClick={handleClose}>Cancel</Button>
                        <Button variant='contained' sx={{ textTransform: 'capitalize', color: '#FFFFFF', borderRadius: '8px', minWidth: '50px', height: '40px', backgroundColor: '#123FA9', '&:hover': { backgroundColor: '#123FA9' } }} onClick={handleClose}>Okay</Button>
                    </Box>
                </DialogContent>

            </BootstrapDialog>
        )
    }

    const ChipComponent = ({ text, textColor, bgColor }) => {
        return (
            <Box
                sx={{
                    borderRadius: '6px',
                    background: bgColor,
                    fontSize: { xs: '14px' }, padding: '0px 8px 0px 8px',
                    textAlign: "center"
                }}
            >
                <Typography sx={{ color: textColor, fontSize: '14px', fontWeight: 400, lineHeight: '24px' }}>{text}</Typography>
            </Box>
        )
    }

    const TenantDetails = () => {
        return (
            <Box sx={{ px: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ alignSelf: 'flex-end', color: '#0F2C6F', fontSize: '18px', fontWeight: 500, lineHeight: '24px' }}>Tenant Details</Typography>
                    <Button data-html2canvas-ignore="true" variant='contained' sx={{ textTransform: 'capitalize', color: '#0F2C6F', borderRadius: '8px', minWidth: '50px', height: '48px', backgroundColor: '#CFDDFE', '&:hover': { backgroundColor: '#CFDDFE' } }} endIcon={<FileDownloadOutlinedIcon />}
                    ><Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 600, lineHeight: '24px' }}
                        onClick={downloadPdf}
                    >Download</Typography></Button>
                </Box>
                <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 500, lineHeight: '24px', mt: 2 }}>{bookingDetails?.user_name}</Typography>

                <Grid container sx={{ mt: 2, rowGap: 1 }} >
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: "center" }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '12px', fontWeight: 400, }}>Company : {bookingDetails?.company_name}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: "center" }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '12px', fontWeight: 400, }}>Office : {bookingDetails?.company_contact_no}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: "center" }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '12px', fontWeight: 400, }}>Email : {bookingDetails?.email}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: "center" }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '12px', fontWeight: 400, }}>Mobile : {bookingDetails?.mobile}</Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const RequestPeriod = () => {
        return (
            <Box sx={{ my: 6, px: 3 }}>
                <Typography sx={{ alignSelf: 'flex-end', color: '#0F2C6F', fontSize: '18px', fontWeight: 500, lineHeight: '24px' }}>Requested Period </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3, width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '33.33%' }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400, lineHeight: '24px' }}>
                            {moment.utc(bookingDetails?.requested_utc_from_time).local().format('DD MMM YYYY')}                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1 }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '12px', fontWeight: 400, lineHeight: '18px', marginTop: '3px' }}>Time:{moment.utc(bookingDetails?.requested_utc_from_time).local().format('HH:mm')} - {moment.utc(bookingDetails?.requested_utc_to_time).local().format('HH:mm')}</Typography>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '12px', fontWeight: 400, lineHeight: '18px', marginTop: '3px' }}>Duration: {bookingDetails?.duration}</Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    const RoomDetails = () => {
        return (
            <Box sx={{ mt: 3, px: 3 }}>
                <Typography sx={{ color: '#02194D', fontSize: '32px', fontWeight: 500, lineHeight: '24px', mb: 6 }}>TEC {bookingDetails?.room_name}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <Typography sx={{ color: '#0F2C6F', fontSize: '18px', fontWeight: 500, lineHeight: '24px' }}>Room Details</Typography>
                    <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <Typography sx={{ color: '#6F80A9', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>Maximum Attendees</Typography>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>{bookingDetails?.maximum_attendees}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <Typography sx={{ color: '#6F80A9', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>level</Typography>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>{bookingDetails?.level}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <Typography sx={{ color: '#6F80A9', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>Unit</Typography>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>#{bookingDetails?.level}-{bookingDetails?.unit}</Typography>
                        </Box>
                        {/* <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                            <Typography sx={{ color: '#6F80A9', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}>Video Conference</Typography>
                            <Typography sx={{ color: '#0F2C6F', fontSize: '16px', fontWeight: 400, lineHeight: '24px' }}>
                                {bookingDetails?.video_conference_capable ? "Yes" : "No"}</Typography>
                        </Box> */}

                    </Box>

                </Box>
            </Box>
        )
    }

    const FeatureDetails = () => {
        return (
            <Box sx={{ p: 3, }}>
                <Typography sx={{ mb: 3, color: '#0F2C6F', fontSize: '18px', fontWeight: 500, lineHeight: '24px' }}>Features</Typography>
                <Box sx={{ display: 'flex', gap: '10px', width: '100%', flexWrap: 'wrap' }}>
                    {bookingDetails?.tec_feature_icon_array_list?.map((item, index) => (
                        <Box key={index} sx={{ padding: '12px', mb: 3, display: 'flex', gap: '10px', flexDirection: 'column', minWidth: '28%', backgroundColor: item.isAvailable ? '#EAF0FF' : '#F3F4F8', borderRadius: '12px' }}>
                            <Box>
                                <img height="24" src={item?.path} />
                            </Box>
                            <Typography sx={{ color: item?.display_status ? '#0F2C6F' : '#C0C7D7', fontSize: '14px', fontWeight: 500, lineHeight: '24px' }}>{item?.name}</Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        )
    }


    const Quotation = () => {
        return (
            <Box sx={{ p: 3, mb: 3, display: 'grid', }}>
                <Box  >
                    <Typography sx={{ mb: 3, color: '#0F2C6F', fontSize: '26px', fontWeight: 500, lineHeight: '32px' }}>Quotation</Typography>
                </Box>
                <Box sx={{ border: "1px solid #DBDFE9", borderRadius: '6px', display: "grid", placeItems: "center", p: 1 }}>
                    <TableContainer sx={{ display: 'grid', placeItems: "center", }}>
                        <Table aria-label="simple table" width="80%" sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                                color: "#0F2C6F"
                            },
                        }}>
                            <TableHead>
                                <TableRow>
                                    {QuotationHeads.map((item, index) => <TableCell key={index} align={index === 0 ? 'left' : index === 3 ? "right" : 'center'} sx={{ fontWeight: 600 }}>{item}</TableCell>)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quotationDetails && quotationDetails?.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            [`& .${tableCellClasses.root}`]: {
                                                fontWeight: 500
                                            },
                                        }}
                                    >
                                        <TableCell component="th" scope="row" width='50%'>
                                            {row?.name}
                                        </TableCell>
                                        <TableCell align="center" width='auto' >{row?.hours}</TableCell>
                                        <TableCell align="center" width='auto' >{row?.price_per_hour.replace(/\s+/g, "")}</TableCell>
                                        <TableCell align="right" width='auto' >{row?.total_amount.replace(/\s+/g, "")}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ borderBottom: "1px solid #DBDFE9", width: "95%", pt: 2 }}></Box>

                    <Box sx={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', p: 2, width: "95%" }}>
                        <Typography variant='p' sx={{ color: "#0F2C6F", fontWeight: 400, fontSize: '14px', lineHeight: "24px" }}><span style={{ fontWeight: 600 }}>Total Amount (SGD)</span><br />
                            VAT included.</Typography>

                        <Typography variant='p' sx={{ color: "#A48B2C", fontSize: "26px", fontWeight: 500 }}>{totalAmount}</Typography>
                    </Box>
                </Box>
            </Box>
        )
    }

    const TermsAndConditions = () => {
        return <Box sx={{ p: 3, display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant='p' sx={{ color: '#0F2C6F', fontSize: "18px", fontWeight: 500 }}>{termandCondition && termandCondition[0]?.title}</Typography>
            <br />
            <Typography variant='p' sx={{ color: "#0F2C6F", lineHeight: "24px", fontSize: '14px' }}>{termandCondition && termandCondition[0]?.description}</Typography>
            <div>
      {termandCondition && termandCondition[1]?.rules?.map((item, index) => (
        <Box key={index} sx={{ py: 1 }}>
          {Object.entries(item).map(([key, value], index) => (
            <div key={index}>
            <Box>
                <Typography key={key} variant='p' sx={{py: 1, color: "#0F2C6F", lineHeight: "24px", fontSize: '14px', fontWeight: 600 }}>{key}</Typography>
            </Box>
            <Box>
                <Typography key={key} variant='p' sx={{py: 1, color: "#0F2C6F", lineHeight: "24px", fontSize: '14px' }}>{value}</Typography>
            </Box>
            </div>
          ))}
        </Box>
      ))}
    </div>
        </Box>
    }
    return (
        <>
            {/* <AgreementModal/> */}
            <ToastAlert message={toastConfigData.message} status={toastConfigData.status} color={toastConfigData.color} icon={toastConfigData.icon} />
            <Modal
                open={showEditModal}
                onClose={() => setShowEditModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 3 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "18px", fontWeight: 500 }}>Edit Amenity Booking</Typography>
                        <IconButton onClick={() => setShowEditModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ borderBottom: "2px solid #C0C7D7", }}></Box>
                    <Typography sx={{ px: 4, py: 3, color: "#6F80A9", fontSize: "14px" }}>Bookings made can only be edited or deleted by approaching TEC reception for assistance.</Typography>
                    <Box sx={{ p: 3, border: '1px solid #C0C7D7', mx: 3, mb: 3, borderRadius: "8px" }}>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px", fontWeight: 600 }}>TEC Contact Details</Typography>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: '12px', justifyContent: "center", pt: 2 }}>
                            {TecContactDummyDetails.map(item => <Box key={item.id} sx={{ display: "grid", width: "100%", alignItems: 'center', gridTemplateColumns: "50% 50%", }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: "center" }}>
                                    {item.Icon}
                                    <Typography sx={{ fontSize: "14px", fontWeight: 500, color: "#6F80A9" }}>{item.title}:</Typography>
                                </Box>
                                <Typography sx=
                                    {{ fontSize: "14px", fontWeight: 500, color: item?.title === "Email" ? "#2764F4" : "#6F80A9", textDecoration: item?.title === 'Email' ? "underline" : "none" }} >{item?.data}</Typography>
                            </Box>
                            )}
                        </Box>
                    </Box>

                </Box>
            </Modal>
            <Box sx={{ pt: 7, px: 3, pb: 3, backgroundColor: "rgba(143, 146, 161,0.05)" }}>
                <Box sx={{ pb: 2 }}>
                    <BackIcon cursor='pointer' onClick={() => navigate(isNotificationClicked == null ? ((role_id ==2 || role_id ==14) ? backNavigation : '/facility_booking') : ((role_id ==2 || role_id ==14) ? '/manage_all_facility_bookings' : '/facility_booking'))} />
                    <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '26px', fontWeight: 500, my: 2 }}>Amenity Booking - {bookingDetails?.room_name} {bookingDetails?.slt_transaction_number}</Typography>
                        {/* <Notify/> */}
                        {bookingDetails?.watch_status == "yes" ? <BellWatch onClick={() => handleWatchUnWatch(bookingDetails?.room_booking_id, "no")} style={{ cursor: 'pointer' }} /> : <BellUnWatch onClick={() => handleWatchUnWatch(bookingDetails?.room_booking_id, "yes")} style={{ cursor: 'pointer' }} />}
                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px', alignItems: "center" }}>
                        <Typography sx={{ color: '#9AA6C3', fontSize: '14px', fontWeight: 500 }}>Created on {moment(bookingDetails?.created_at).format('DD MMM YYYY')}</Typography>
                        <ChipComponent text={bookingDetails?.booking_status_display_name} textColor={bookingDetails?.booking_status_text_color_code} bgColor={bookingDetails?.booking_status_background_color_code} />
                    </Box>
                </Box>
                <Grid container >
                    <Grid item xs={8}  >
                        <Grid container >
                            <Grid item xs={11.7} >
                                <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)" }}>
                                    <div id="print">
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', zIndex: -5, py: 1, px: 2 }}>
                                            <Typography sx={{ color: '#0F2C6F', fontSize: '18px' }}>Amenity Booking - {bookingDetails?.room_name} {bookingDetails?.slt_transaction_number}</Typography>
                                            <LogoIcon />
                                        </Box>
                                        <TenantDetails />
                                        <RequestPeriod />
                                        <RoomDetails />
                                        <Box sx={{ pt: 3, borderBottom: "1px solid #DBDFE9" }} />
                                        <FeatureDetails />
                                        <Quotation />
                                        <TermsAndConditions />
                                    </div>
                                    {/* <Box sx={{ px: 2,pb: 2, display: "flex", alignItems: "center"}}>
                                    <Checkbox /> 
                                    <Typography sx={{color: "#0F2C6F", fontWeight: 400, fontSize: '14px',}}>
                                    I agree with the <span style={{textDecoration: "underline"}}>{" "}terms and conditions.</span>
                                    </Typography>
                                </Box> */}

                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} >
                        <CostBreakdown />
                    </Grid>
                    <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
                        <Button variant="contained" sx={{ textTransform: "none", background: "#1E4CB5" }} onClick={() => setShowEditModal(true)}>Edit Booking</Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        getFacilityBookingById: params => dispatch(getfacilityBookingDetailsByIdAction(params)),
        getFacilityBookingByIdNull: () => dispatch(getfacilityBookingDetailsByIdNullAction()),
        getEditContactDetails: () => dispatch(getEditContactDetailsAction()),
        addNonWorkingHourRoomBookingNull: () => dispatch(addNonWorkingHourRoomBookingNullAction()),
        facilityBookingPaymentStatusNull: () => dispatch(facilityBookingPaymentStatusNullAction()),
        updateWatch: (data) => dispatch(updateRoomBookingWatch(data)),
        updateWatchNull: () => dispatch(updateRoomBookingWatchStatusNullAction())
    }
}

const mapStateToProps = state => {
    return {
        
        getFacilityBookingByIdData: state.FacilityBookingReducer.getFacilityBookingDetailsById,
        termsAndCondition: (state.FacilityBookingReducer.getFacilityBookingDetailsById?.response?.response_status ==0 || state.FacilityBookingReducer.getFacilityBookingDetailsById?.response == null) ? "" : JSON.parse(state.FacilityBookingReducer.getFacilityBookingDetailsById?.response?.amenity_booking_terms_and_conditions?.config_value),
        getEditContactDetailsData: state.FacilityBookingReducer.getEditContactDetailsList,
        facilityBookingPaymentStatusData: state.FacilityBookingReducer.facilityBookingPaymentStatus,
        addNonWorkingHourRoomBookingData: state.FacilityBookingReducer.addNonWorkingHourRoomBooking,
        updateRoomBookingWatchStatusData: state.FacilityBookingReducer.updateRoomBookingWatchStatus,

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmedBooking)