import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import { ReactComponent as ArrowIcon } from "../../../Assets/icons/back.svg";
import { ReactComponent as RefreshIcon } from "../../../Assets/icons/Refresh.svg";
import { ReactComponent as EyeIcon } from "../../../Assets/icons/EyeOnlyIcon.svg";
import SecurityClearance from "./SecurityClearanceRequest";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { styled } from "@mui/system";
import { getLastUpdatedTime } from "../../../Utils/utils";
import CargoLift from "./CargoLiftRequest";
import PreviewLift from "../PreviewRequests/PreviewLift";
import PreviewSecurityClearance from "../PreviewRequests/PreviewSecurityClearance";
import LightBulb from "./LightBulbRequest";
import AirConExtensionRequest from "./AirConExtensionRequest";
import DevelopmentProgress from "../DevelopmentProgress";
import PreviewAirCon from "../PreviewRequests/PreviewAirCon";
import MiscellaneousADHOCRequest from "./MiscellaneousADHOCRequest";
import MiscelleaneousOthers from "./MiscelleaneousOthers";

const StyledTypography = styled(Typography)({
  color: "#0F2C6F",
  fontSize: "14px",
  fontWeight: 550,
  marginBottom: "5px",
});

const buttonUnSelectedStyle = {
  border: "1px solid #AAC3FF",
  borderRadius: "6px",
  color: "#0F2C6F",
  fontSize: "14px",
  fontWeight: "600",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
  marginRight: "5px",
};

const buttonSelectedStyle = {
  backgroundColor: "#123FA9",
  borderRadius: "6px",
  color: "#FFFFFF",
  fontSize: "14px",
  textTransform: "capitalize",
  marginRight: "10px",
  marginLeft: "10px",
  "&:hover": {
    backgroundColor: "#123FA9",
  },
};

const NewServiceRequest = ({
  serviceRequestTypes,
  serviceRequestTerms,
  miscelleaneousTypes,
  getServiceRequestTypes,
  getMiscReqType,
}) => {
  let { tabValue, typeId, reqId } = useParams();
  const navigate = useNavigate();
  const [tenantDetails, setTenantDetails] = useState({
    personInCharge: "",
    tenantCompany: "",
    unit: "",
    office: "",
    mobile: "",
    companyId: "",
  });
  const [serviceType, setServiceType] = useState(
    parseInt(typeId == 7 ? 5 : typeId)
  );
  const [lastUpdatedTime, setLastUpdatedTime] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [miscelleaneousType, setMiscelleaneousType] = useState({
    adhoc: parseInt(typeId) == 7 ? true : false,
    otherService: true,
  });
  const [fromPreviwePage, setFromPreviewPage] = useState({
    lift: false,
    security: false,
    airCon: false,
  });
  const location = useLocation();

  useEffect(() => {
    console.log(location);
    if (location.pathname.includes("/service_request_forms")) {
      setServiceType(5);
    }
  }, [location]);

  useEffect(() => {
    let localData = JSON.parse(localStorage.getItem("userData"));
    setLastUpdatedTime(getLastUpdatedTime());
    setTenantDetails({
      ...tenantDetails,
      tenantCompany: localData.company_details.companyArrayList[0].company_name,
      unit: localData.company_details.companyArrayList[0].unit_name,
      mobile: `${
        localData?.tenant_mobile_country_code
          ? localData.tenant_mobile_country_code + " " // Added space between country code and number
          : ""
      }${localData?.tenant_mobile_no ? localData.tenant_mobile_no : ""}`,
      office:
        checkIsEmpty(localData.company_details.companyArrayList[0].phone_no) ==
        ""
          ? ""
          : `${localData.company_details.companyArrayList[0].country_code} ${localData.company_details.companyArrayList[0].phone_no}`,
      personInCharge: localData.user_name,
      companyId: localData.company_id,
    });
    getServiceRequestTypes();
    getMiscReqType();
  }, []);

  const handleServiceType = (e) => {
    setServiceType(e.target.value);
  };

  const exchangeInputData = (filledData) => {
    setPreviewData(filledData);
    setShowPreview(true);
  };
  const renderRequestDetails = (serviceType, tenantDetails) => {
    let terms = serviceRequestTerms[serviceType == 7 ? 5 - 1 : serviceType - 1];
    if (serviceType === 1) {
      return (
        <CargoLift
          requestId={reqId}
          tenantData={tenantDetails}
          serviceType={serviceType}
          returnInputData={exchangeInputData}
          termsAndCondition={terms}
          previewPageData={fromPreviwePage}
          setFromPreviewPage={setFromPreviewPage}
          setServiceType={setServiceType}
        />
      );
    } else if (serviceType === 2) {
      return (
        <LightBulb
          requestId={reqId}
          tenantData={tenantDetails}
          serviceType={serviceType}
          termsAndCondition={terms}
        />
      );
    } else if (serviceType === 3) {
      return (
        <AirConExtensionRequest
          requestId={reqId}
          tenantData={tenantDetails}
          serviceType={serviceType}
          returnInputData={exchangeInputData}
          termsAndCondition={terms}
          previewPageData={fromPreviwePage}
          setFromPreviewPage={setFromPreviewPage}
          setServiceType={setServiceType}
        />
      );
    } else if (serviceType === 4) {
      return (
        <SecurityClearance
          requestId={reqId}
          tenantData={tenantDetails}
          serviceType={serviceType}
          returnInputData={exchangeInputData}
          termsAndCondition={terms}
          previewPageData={fromPreviwePage}
          setFromPreviewPage={setFromPreviewPage}
          setServiceType={setServiceType}
        />
      );
    } else if (
      serviceType === 7 ||
      (serviceType === 5 && miscelleaneousType.adhoc)
    ) {
      return (
        <MiscellaneousADHOCRequest
          reqId={reqId}
          tenantData={tenantDetails}
          serviceType={serviceType}
          termsAndCondition={terms}
          requestId={miscelleaneousTypes}
          setServiceType={setServiceType}
        />
      );
    } else if (serviceType === 5 && miscelleaneousType.otherService) {
      return <MiscelleaneousOthers />;
    }
  };

  const renderPreviewScreens = () => {
    if (serviceType === 1) {
      return (
        <PreviewLift
          tenantData={tenantDetails}
          reviewData={previewData}
          hidePreview={setShowPreview}
          setFromPreviewPage={setFromPreviewPage}
        />
      );
    } else if (serviceType === 3) {
      return (
        <PreviewAirCon
          tenantData={tenantDetails}
          reviewData={previewData}
          hidePreview={setShowPreview}
          setFromPreviewPage={setFromPreviewPage}
        />
      );
    } else if (serviceType === 4) {
      return (
        <PreviewSecurityClearance
          tenantData={tenantDetails}
          reviewData={previewData}
          hidePreview={setShowPreview}
          setFromPreviewPage={setFromPreviewPage}
        />
      );
    }
  };
  const checkIsEmpty = (data) => {
    if (
      data == null ||
      data == undefined ||
      data == "undefined" ||
      data == "null" ||
      data == ""
    ) {
      return "";
    }
    return data;
  };
  return (
    <>
      {showPreview ? (
        renderPreviewScreens()
      ) : (
        <Grid container sx={{ minHeight: "100%" }}>
          <Grid
            item
            xs={1}
            sx={{
              backgroundColor: "rgba(143, 146, 161,0.05)",
              minHeight: "90%",
            }}
          ></Grid>
          <Grid item xs={12} lg={8}>
            <Box
              sx={{
                backgroundColor: "rgba(143, 146, 161,0.05)",
                pb: 0.5,
                pt: 7,
              }}
            >
              <ArrowIcon
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
              />
              <Typography sx={{ color: "#0F2C6F", fontSize: "26px", mt: 1 }}>
                {location.pathname.includes("/service_request_forms")
                  ? "Manage Service Request Forms"
                  : "Service Request"}
              </Typography>
              {serviceType != 0 && (
                <Box sx={{ my: 1.5, display: "flex", alignItems: "center" }}>
                  <RefreshIcon />
                  <Typography
                    sx={{ color: " #9AA6C3", fontSize: "14px", ml: 1 }}
                  >
                    Last Updated at {lastUpdatedTime}
                  </Typography>
                </Box>
              )}
            </Box>

            {location.pathname.includes("/service_request_forms") && (
              <Box
                sx={{
                  p: 2,
                  px: 2,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                  background: "white",
                  borderBottom: "15px solid #F9FAFA",
                }}
              >
                <EyeIcon />
                <Typography
                  sx={{ fontSize: "12px", color: "#6F80A9", fontWeight: 600 }}
                >
                  The service request forms displayed below are downloadable
                  forms and can be managed and replaced.
                </Typography>
              </Box>
            )}
            <Box sx={{ p: 3 }}>
              <StyledTypography sx={{ mb: 1 }}>Service Type</StyledTypography>
              <FormControl siz="small">
                {/* <InputLabel sx={{ color: '#9AA6C3' }}>Choose Service Type</InputLabel> */}
                <Select
                  sx={{ color: "#0F2C6F", height: "48px", minWidth: 300 }}
                  value={serviceType}
                  onChange={(e) => handleServiceType(e)}
                >
                  {serviceRequestTypes?.map((item, index) => (
                    <MenuItem
                      sx={{ color: "#0F2C6F" }}
                      value={item.service_request_types_id}
                      key={index}
                    >
                      {item.display_name}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {serviceType === 5 && (
              <Box sx={{ px: 3 }}>
                <Button
                  sx={
                    miscelleaneousType.otherService
                      ? buttonSelectedStyle
                      : buttonUnSelectedStyle
                  }
                  onClick={() => {
                    setMiscelleaneousType({
                      ...miscelleaneousType,
                      otherService: true,
                      adhoc: false,
                    });
                  }}
                >
                  Other Services
                </Button>
                <Button
                  sx={
                    miscelleaneousType.adhoc
                      ? buttonSelectedStyle
                      : buttonUnSelectedStyle
                  }
                  onClick={() => {
                    setMiscelleaneousType({
                      ...miscelleaneousType,
                      otherService: false,
                      adhoc: true,
                    });
                  }}
                >
                  AD Hoc Request
                </Button>
              </Box>
            )}
            {(serviceType === 5 && miscelleaneousType.adhoc === true) ||
            serviceType !== 5 ? (
              <Box sx={{ p: 3 }}>
                <Typography
                  sx={{ color: "#0F2C6F", fontSize: "18px", fontWeight: "550" }}
                >
                  Tenant Details
                </Typography>
                <Typography sx={{ color: "#6F80A9", fontSize: "14px", mt: 1 }}>
                  Please enter the tenant particulars
                </Typography>
                <Grid container columnSpacing={3} rowSpacing={3} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={5}>
                    <StyledTypography>Person-In-Charge</StyledTypography>
                    <OutlinedInput
                      name="personInCharge"
                      value={tenantDetails.personInCharge}
                      disabled
                      placeholder="Casandra Lee"
                      sx={{
                        backgroundColor: "#F3F4F8",
                        height: "48px",
                        width: "100%",
                        borderRadius: "6px",
                        color: "#0F2C6F",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StyledTypography>Tenant Company</StyledTypography>
                    <OutlinedInput
                      value={tenantDetails.tenantCompany}
                      name="tenantCompany"
                      disabled
                      placeholder="IP ICAP Pte Ltd"
                      sx={{
                        backgroundColor: "#F3F4F8",
                        height: "48px",
                        width: "100%",
                        borderRadius: "6px",
                        color: "#0F2C6F",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <StyledTypography>Unit#</StyledTypography>
                    <OutlinedInput
                      value={tenantDetails.unit}
                      name="unit"
                      disabled
                      placeholder="#17-02/05"
                      sx={{
                        backgroundColor: "#F3F4F8",
                        height: "48px",
                        width: "100%",
                        borderRadius: "6px",
                        color: "#0F2C6F",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Office Phone Number</StyledTypography>
                    <PhoneInput
                      disabled
                      country="sg"
                      value={tenantDetails?.office}
                      inputStyle={{
                        width: "100%",
                        backgroundColor: "#F3F4F8",
                        color: "#0F2C6F",
                        height: "48px",
                      }}
                    ></PhoneInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledTypography>Mobile Number</StyledTypography>
                    <PhoneInput
                      disabled
                      country="sg"
                      value={tenantDetails?.mobile}
                      inputStyle={{
                        width: "100%",
                        backgroundColor: "#F3F4F8",
                        color: "#0F2C6F",
                        height: "48px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Divider />
              </Box>
            ) : (
              ""
            )}
            {renderRequestDetails(serviceType, tenantDetails)}
          </Grid>

          <Grid
            item
            xs={3}
            sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}
          ></Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceRequestTypes: state.Service_Req_Reducer.serviceRequestTypes,
    serviceRequestTerms: state.Service_Req_Reducer.serviceRequestTerms,
    miscelleaneousTypes: state.Service_Req_Reducer.miscelleaneousTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceRequestTypes: () =>
      dispatch(serviceRequestAction.getServiceRequestTypes()),
    getMiscReqType: () => dispatch(serviceRequestAction.getMiscReqType()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewServiceRequest);
