import { Box, Button, ClickAwayListener, FormControl, Grid, Grow, InputLabel, MenuItem, MenuList, OutlinedInput, Paper, Popper, Select, styled, Tab, Tabs, TextField, Typography } from '@mui/material'
import React, { useState, useRef,useEffect } from 'react'
import {ReactComponent as AddIcon} from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import EventsAndPromotionsList from '../Events_Promotions/EventsAndPromotionsList';
import * as eventsAndPromotionAction from '../../redux/actions/EventsAndPromotionAction'
import { DatePicker } from '@mui/x-date-pickers';
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import { ReactComponent as SearchIcon } from '../../Assets/icons/SearchIcon.svg';
import { getYYYYMMDDFormat, preventDatePickerKeyboard } from '../../Utils/utils';
import { ReactComponent as PlusWhiteIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg";

const StyledTab = styled(Tab)(({ theme }) => ({
    color: '#6F80A9',
    textTransform: "none",
    fontSize: "12px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  }));
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box >
              <Typography variant="span">{children}</Typography>
            </Box>
          )}
        </div>
      );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }



const EventsAndPromotions = ({eventAndPromotions,eventAndPromotionsCount,getPromotionEventList}) => {
    const anchorRef = useRef(null);
    let navigate = useNavigate();
    const [createMenu, setCreateMenu] = useState(false);
    const [authData, setAuthData] = useState({})
    const [tabValue, setTabValue] = useState(0)
    const [filterData,setFilterData] = useState({type:"",date:null,search:""})
    const [currentPage,setCurrentPage] = useState(1)

    useEffect(() => {
      let timer;

      timer = setTimeout(() => 
        {let datas={
            TabStatus:tabValue==0 ? "Active" : tabValue==1 ? "Schedule" : tabValue==2 ?  'Archieved'  : 'Draft',
            limit:10,
            offset:0,
            search:filterData.search,
            date:filterData.date!=null ? getYYYYMMDDFormat(filterData.date) : "",
            type:filterData.type
        }
        getPromotionEventList(datas)}, 1000);

        return () => clearTimeout(timer);
    }, [filterData])
    
    useEffect(() => {
        let localData = JSON.parse(localStorage.getItem('userData'))
        setAuthData(localData)
    }, [])

    // useEffect(()=>{
    //     handleReset()
    // },[tabValue])

    const handleFilterData = (e,datafrom)=>{
  
      if(datafrom){
          setFilterData({
            ...filterData,
            date:e
          })
      }else{
        let {name,value} = e.target
        setFilterData({
          ...filterData,
          [name]:value
        })
      }
      
    }
  
    const handleReset = ()=>{
      setFilterData({
        type:"",
        date:null,
        search:""
      })
    }
   
    const handleToggle = () => {
      localStorage.removeItem('currentScreen')
        setCreateMenu((createMenu) => !createMenu);
    };

    const navigateToCreate = (toNavigate) => {
        setCreateMenu(false)
        navigate(toNavigate)
    }
    const handleChange = (event, newValue) => {
        setFilterData({
            type:"",
            date:null,
            search:""
        })
        // let datas={
        //     TabStatus:event.target.name,
        //     limit:10,
        //     offset:0,
        //     search:filterData.search,
        //     date:filterData.date!=null ? getYYYYMMDDFormat(filterData.date) : "",
        //     type:filterData.type
        // }
        // getPromotionEventList(datas)
        setTabValue(newValue);
        console.log('newValue ',newValue)

    };

    const getTabStatus = ()=>{
       if(tabValue==0){
            return "Active"
       }else if(tabValue==1){
            return "Schedule"
       }else if(tabValue==2){
            return "Archieved"
       }else{
          return "Draft"
       }
    }
    
    const fetchDataPagignation = (value)=>{
      setCurrentPage(value)
        let datas={
            TabStatus:getTabStatus(),
            limit:10,
            offset:parseInt(value-1),
            search:filterData.search,
            date:filterData.date!=null ? getYYYYMMDDFormat(filterData.date) : "",
            type:filterData.type
        }
        getPromotionEventList(datas)
    }

    
    const tabFilterOptions = ()=>{
        return(
          <>
        <Grid sx={{p:2,backgroundColor:"#FFFFFF"}} container >
                <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <FormControl fullWidth size="small" sx={{ width: 160, }}>
                    <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Type </InputLabel>
                    <Select value={filterData.type}  onChange={(e)=>handleFilterData(e)}  name="type" 
                      sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }} label="Type of work">
                      <MenuItem sx={{color:'#0F2C6F'}} value="all">All</MenuItem>
                      <MenuItem sx={{color:'#0F2C6F'}} value="event">Event</MenuItem>
                      <MenuItem sx={{color:'#0F2C6F'}} value="promotion">Key Highlight</MenuItem>
                    </Select>
                  </FormControl>
                  
                  <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      components={{
                        OpenPickerIcon:DatePickerIcon
                      }}
                      // label={currentTabName === 'closed' ? 'Closed Date' : 'Requested Date'}
                      value={filterData.date}
                      onChange={(e)=>handleFilterData(e,"date")}
                      name="date"
                      renderInput={(params) => <TextField autoComplete='off' onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                      disableFuture={true}
                    />
                  </FormControl>
                 <Typography onClick={handleReset} sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}}>Reset</Typography>
                </Grid>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                  <OutlinedInput onChange={(e)=>handleFilterData(e)} value={filterData.search} placeholder="Search..."
                    name='search'
                    endAdornment={<SearchIcon />} size="small" />
                </Grid>
              </Grid>
          </>
        )
      }
    return (
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161, 0.05)",minHeight:'100%'}}>
            <Grid item xs={0.5} />
            <Grid item xs={11}>
                <Box sx={{display:"flex",justifyContent:"space-between",mt:8}}>
                    <Box>
                        <Typography style={{ fontSize: "26px", fontWeight: "500", color: '#002D74' }}>
                        Events / Key Highlights 
                        </Typography>
                        <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#002D74' }}>
                        BMS Admin can manage, post and edit Events / Key Highlights. Once submitted, the Events / Key Highlights. These will be visible to all users using the SingLand system (Mobile app and Dashboard).
                        </Typography>
                    </Box>
                    <Button variant="contained" sx={{display:'flex',height:36.5, minWidth:'140px', background: '#123FA9',"&:hover":{backgroundColor:"#123FA9"},textTransform:'capitalize', fontSize: { md: "14px", xs: "10px"} }} endIcon={<PlusWhiteIcon />}
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={createMenu ? 'composition-menu' : undefined}
                        aria-expanded={createMenu ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}>
                        <Typography sx={{ fontSize: { md: "14px", xs: "10px", fontWeight: 500} }}>Create New</Typography> 
                    </Button>
                </Box>
                <Box
                    sx={{ background: "white", borderBottom: "1px solid #DBDFE9", borderRadius: "8px 8px 0px 0px",mt:3 }}
                >
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{ style: { background: "#123FA9" } }}
                    >
                        <StyledTab
                            label="Active"
                            name="Active"
                            {...a11yProps(0)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        />
                        <StyledTab
                            label="Scheduled"
                            name="Schedule"
                            {...a11yProps(1)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        />
                        <StyledTab
                            label="Archived"
                            name="Archived"
                            {...a11yProps(1)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        />
                        <StyledTab
                            label="Drafts"
                            name="Drafts"
                            {...a11yProps(2)}
                            sx={{
                                textTransform: "none",
                                color: "#123FA9",
                                fontSize: "16px",
                            }}
                        />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0} >
                    <>
                        {tabFilterOptions()}
                        <EventsAndPromotionsList currentPage={currentPage}  activeType='all' tableData={eventAndPromotions} tableDataCount={eventAndPromotionsCount} fetchDataPagignation={fetchDataPagignation} tabValue={tabValue}/>
                    </>
                </TabPanel>
                <TabPanel value={tabValue} index={1} >
                    <>
                        {tabFilterOptions()}
                        <EventsAndPromotionsList currentPage={currentPage}  activeType='schedule' tableData={eventAndPromotions} tableDataCount={eventAndPromotionsCount} fetchDataPagignation={fetchDataPagignation} tabValue={tabValue}/>
                    </>
                </TabPanel>
                <TabPanel value={tabValue} index={2} >
                    <EventsAndPromotionsList currentPage={currentPage}  activeType='closed' tableData={eventAndPromotions} tableDataCount={eventAndPromotionsCount} fetchDataPagignation={fetchDataPagignation}/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <EventsAndPromotionsList currentPage={currentPage}  activeType='Draft' tableData={eventAndPromotions} tableDataCount={eventAndPromotionsCount} fetchDataPagignation={fetchDataPagignation}/>
                </TabPanel>
                <Popper
                open={createMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener >
                                <MenuList
                                    autoFocusItem={createMenu}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    // onKeyDown={handleListKeyDown}
                                    sx={{ width: 150 }}
                                >
                                    <MenuItem onClick={() => navigateToCreate('/create_event/null/false/false')} sx={{ color: ' #0F2C6F', fontSize: '14px' }}>Event</MenuItem>
                                    <MenuItem onClick={() => navigateToCreate('/create_promotion/null/false/false')} sx={{ color: ' #0F2C6F', fontSize: '14px' }}>Key Highlight</MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            </Grid>
            <Grid item xs={0.5} />
            </Grid>
        
    )
}

const mapStateToProps = (state) => {
    return {
        eventAndPromotions:state.eAndpReducer.eventAndPromotions,
        eventAndPromotionsCount:state.eAndpReducer.eventAndPromotionsCount
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        getPromotionEventList:(data)=>{dispatch(eventsAndPromotionAction.getPromotionEventList(data))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EventsAndPromotions)