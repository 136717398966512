import { createAction } from "redux-actions";
import ApiService from "../../../AxiosConfig/ApiService";
import { sessionExpired } from "../commonAction";

export const getSustainableListInitiate = createAction('GET_SUSTAINABLE_LIST_INITIATE');
export const getSustainableListSuccess = createAction('GET_SUSTAINABLE_LIST_SUCCESS');
export const getSustainableListFailure = createAction('GET_SUSTAINABLE_LIST_FAILURE');
export const getSustainableListNull = createAction('GET_SUSTAINABLE_LIST_NULL');

export const getSustainableListNullAction = () => dispatch => dispatch(getSustainableListNull())  


export const getSustainableListAction = (params) => {
    return async (dispatch) => {
         dispatch(getSustainableListInitiate());
        try {
            const response = await ApiService().post('/Sustainables_list',params);
            console.log('sustaibele response ',response);
            if(response.data?.response_status === 1){
                 dispatch(getSustainableListSuccess(response.data))
                }else{
                dispatch(getSustainableListFailure(response.data))

                }
            } catch (error) {
                if(error?.response?.status===401){
                    dispatch(sessionExpired('Session Expired'));
                  }else{
                    dispatch(getSustainableListFailure(error.response.data))
                  }
         }
    }
}

export const CreateSustainableEvents = createAction("CREATE_SUSTAINABLE_EVENT_INITIATE");
export const CreateSustainableEventSuccess = createAction('CREATE_SUSTAINABLE__EVENT_SUCCESS');
export const CreateSustainableEventFailure = createAction('CREATE_SUSTAINABLE__EVENT_FAILURE');

export const sustainableStateNull = createAction("STATE_TO_NULL");
export const SETeANDpStateToNULL = () => {
    return (dispatch) => {
        dispatch(sustainableStateNull())
    }
}
export const createSustainableEvent = (params) => {
    return async (dispatch) => {
      dispatch(CreateSustainableEvents());
      try {
        const response = await ApiService().post('/Create_Sustainables',params);
        console.log('createEvent response ',response);

        if(response.status === 200){
          console.log('createEvent response ',response);
             dispatch(CreateSustainableEventSuccess(response.data))
            }
        } catch (error) {
            if(error?.response?.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                dispatch(CreateSustainableEventFailure(error.response.data))
              }
     }
    }
}

const getSingleSustainableDetailsInitiate = createAction("GET_SINGLE_SUSTAIN_DETAILS_INITIATE")
const getSingleSustainableDetailsSuccess = createAction("GET_SINGLE_SUSTAIN_DETAILS_SUCCESS")
const getSingleSustainableDetailsFailure = createAction("GET_SINGLE_SUSTAIN_DETAILS_FAILURE")

export const getSingleSustainableNull = createAction("GET_SINGLE_SUSTAIN_STATE_TO_NULL");
export const getSingleSustainableStateNull = () => {
    return (dispatch) => {
        dispatch(getSingleSustainableNull())
    }
}

export const getSingleSustainableDetails = (id) => {
  return async (dispatch) => {
    dispatch(getSingleSustainableDetailsInitiate());
    try {
      const response = await ApiService().post('/getSingleSustainableRecordById',id);

      if(response.status === 200){
        console.log('getSingleSustainableDetails response ',response);
           dispatch(getSingleSustainableDetailsSuccess(response.data))
          }
      } catch (error) {
          if(error?.response?.status===401){
              dispatch(sessionExpired('Session Expired'));
            }else{
              dispatch(getSingleSustainableDetailsFailure(error.response.data))
            }
   }
  }
}

export const  EditSustainableEvents = createAction("EDIT_SUSTAINABLE_EVENT_INITIATE");
export const  EditSustainableEventSuccess = createAction('EDIT_SUSTAINABLE__EVENT_SUCCESS');
export const  EditSustainableEventFailure = createAction('EDIT_SUSTAINABLE__EVENT_FAILURE');

export const editsustainableStateNull = createAction("EDIT_STATE_TO_NULL");
export const editSETeANDpStateToNULL = () => {
    return (dispatch) => {
        dispatch(sustainableStateNull())
    }
}
export const editSustainableEvent = (params) => {
    return async (dispatch) => {
      dispatch(EditSustainableEvents());
      try {
        const response = await ApiService().post('/EditSustainables',params);
        console.log('EditSustainables response ',response);

        if(response.status === 200){
          console.log('EditSustainables response ',response);
             dispatch(EditSustainableEventSuccess(response.data))
            }
        } catch (error) {
            if(error?.response?.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                dispatch(EditSustainableEventFailure(error.response.data))
              }
     }
    }
}

const updateSustainableDraftInitiate = createAction("UPDATE_SUSTAINABLE_DRAFT_INITIATE")
const updateSustainableDraftSuccess = createAction("UPDATE_SUSTAINABLE_DRAFT_SUCCESS")
const updateSustainableDraftFailure = createAction("UPDATE_SUSTAINABLE_DRAFT_FAILURE")

export const updateSustainableStateNull = createAction("UPDATE_STATE_TO_NULL");
export const updateSustainableStateToNULL = () => {
    return (dispatch) => {
        dispatch(updateSustainableStateNull())
    }
}
export const updateSustainableDraftEvent = (params) => {
  return async (dispatch) => {
    dispatch(updateSustainableDraftInitiate());
    try {
      const response = await ApiService().post('/UpdateDraftsSustainableRecord',params);
      console.log('update response ',response);

      if(response.status === 200){
        console.log('update response ',response);
           dispatch(updateSustainableDraftSuccess(response.data))
          }
      } catch (error) {
          if(error?.response?.status===401){
              dispatch(sessionExpired('Session Expired'));
            }else{
              dispatch(updateSustainableDraftFailure(error.response.data))
            }
   }
  }
}

export const InactiveSustainableEvents = createAction("INACTIVE_SUSTAINABLE_EVENT_INITIATE");
export const InactiveSustainableEventSuccess = createAction('INACTIVE_SUSTAINABLE__EVENT_SUCCESS');
export const InactiveSustainableEventFailure = createAction('INACTIVE_SUSTAINABLE__EVENT_FAILURE');

export const InactivesustainableStateNull = createAction("INACTIVE_STATE_TO_NULL");
export const InactiveStateToNULL = () => {
    return (dispatch) => {
        dispatch(sustainableStateNull())
    }
}
export const InactiveSustainableEvent = (params) => {
    return async (dispatch) => {
      dispatch(InactiveSustainableEvents());
      try {
        const response = await ApiService().post('/SustainableChangeStatus',params);
        console.log('SustainableChangeStatus response ',response);

        if(response.status === 200){
          console.log('SustainableChangeStatus response ',response);
             dispatch(InactiveSustainableEventSuccess(response.data))
            }
        } catch (error) {
            if(error?.response?.status===401){
                dispatch(sessionExpired('Session Expired'));
              }else{
                dispatch(InactiveSustainableEventFailure(error.response.data))
              }
     }
    }
}