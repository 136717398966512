import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const SendFeedback = ()=>{
    return(
        <>
            <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:'100%'}}>
                <Grid item xs={1.5} />
                <Grid item xs={9} sx={{pt:8}}>
                    <Box>
                        <Typography sx={{color:"#002D74",fontSize:"32px",textTransform:"capitalize"}}>Send feedback & Improvements</Typography>
                        <Typography sx={{color:"#002D74",fontSize:"20px",my:2}}>For any feedback/improvements send a mail to the below E-mail </Typography>
                        <Typography sx={{color:"#002D74",fontSize:"15px",fontWeight:550}}>servicedesk@singaporeland.com</Typography>
                     </Box>
                </Grid>
                <Grid item xs={1.5} />
            </Grid>
        </>
    )
}

export default SendFeedback;