import { Box, Button, Grid, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from '../../../Assets/icons/back.svg';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import PrintIcon from '@mui/icons-material/Print';

const StyledTypography = styled(Typography)({
    color:"#0F2C6F",
    fontSize:"13px",
    fontWeight:"500"
})

const StyledTableCell = styled(TableCell)({
    color:"#0F2C6F",
    fontSize:"12px",
    [`&.${tableCellClasses.head}`]:{
        fontWeight:550
    },
    [`&.${tableCellClasses.body}`]:{
        fontWeight:500,
    }
})

const ReviewSpecialAccess = ({getInvitationDetails,invitationDetails})=>{
    const navigate = useNavigate()
    let {id} = useParams()
    const [showIframe,setShowIframe] = useState(false)
    useEffect(()=>{
        getInvitationDetails({
            id:id,
            type:"Special_access"
        })
    },[])

    useEffect(()=>{
        setTimeout(()=>{
            setShowIframe(false)
        },2000)
    },[showIframe])

    return(
        <>
        {invitationDetails=='fail' ? 
            <Box sx={{background: 'rgba(143, 146, 161,0.05)',height:"100%",width:'100%',display:'flex',justifyContent:"center",alignItems:"center"}}>
                <Typography sx={{fontSize:'22px',color:'#0F2C6F'}}>No Data Found</Typography>
            </Box> : 
            <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}}>
                <Grid item xs={2} />

                <Grid item xs={8}>
                    <Box sx={{mt:7}}>
                        <BackIcon onClick={() => navigate(-1)} />
                        <Typography sx={{color:"#0F2C6F",fontSize:"26px",mt:2}}> Special Access QR</Typography>
                        <Typography sx={{color:"#9AA6C3",fontSize:"12px",mt:1}}>Assigned on {invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).local().format("DD MMM YYYY")}</Typography>
                    </Box>
                    
                    <Grid container rowSpacing={3} sx={{backgroundColor:"#FFFFFF",p:3,mt:3}}>
                        <Grid item xs={12}>
                            <Box sx={{display:"flex",justifyContent:'space-between'}}>
                                <Typography sx={{color:"#0F2C6F",fontSize:"20px"}}>Special Access</Typography>
                            {invitationDetails?.visitors && (invitationDetails?.visitors[0]?.url!='' &&  <PrintIcon onClick={()=>setShowIframe(true)} ></PrintIcon>)}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{width:"50%",display:'flex',justifyContent:"space-between"}}>
                                <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:500}}>Requesting Company</Typography>
                                <StyledTypography>{invitationDetails?.company_name}</StyledTypography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{width:"50%",display:'flex',justifyContent:"space-between"}}>
                                <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:500}}>Approved by</Typography>
                                <StyledTypography>{invitationDetails?.job_title}</StyledTypography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{width:"50%",display:'flex',justifyContent:"space-between"}}>
                                <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:500}}>Reasons</Typography>
                                <StyledTypography>{invitationDetails?.notes_to_visitor}</StyledTypography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ border: '1px solid #DBDFE9', borderRadius: '6px', minHeight: 100, display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                                <Box sx={{ p: 2, }}>
                                <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Invitation Period
                                </Typography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Start : {invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).local().format("DD MMM YYYY")} {moment.utc(invitationDetails?.from_date_time).local().format("HH:mm")}
                                </StyledTypography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Ends : {invitationDetails?.to_date_time!=null && moment.utc(invitationDetails?.to_date_time).local().format("DD MMM YYYY")} {moment.utc(invitationDetails?.to_date_time).local().format("HH:mm")}
                                </StyledTypography>
                                </Box>
                                <Box sx={{ p: 2, pl: 6 }}>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Visitor Details
                                </StyledTypography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Access Passes Printed: {invitationDetails?.special_count}
                                </StyledTypography>
                                <StyledTypography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                                    Access Level: {invitationDetails?.comp_office_unit}
                                </StyledTypography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Number</StyledTableCell>
                                            <StyledTableCell>Time In</StyledTableCell>
                                            <StyledTableCell>Time Out</StyledTableCell>
                                            <StyledTableCell>Date</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {invitationDetails?.visitors?.map((value)=>(
                                        <TableRow>
                                            <StyledTableCell>{value.name}</StyledTableCell>
                                            <StyledTableCell>{value.last_in_time ==null ? " _ " : moment.utc(value.last_in_time).local().format('HH:mm')}</StyledTableCell>
                                            <StyledTableCell>{value.last_out_time == null ? " _" : moment.utc(value.last_out_time).local().format('HH:mm') }</StyledTableCell>
                                            <StyledTableCell>{invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).local().format("DD/MM/YYYY")}</StyledTableCell>
                                        </TableRow> 
                                        ))}
                                        
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {(showIframe && (invitationDetails?.visitors && invitationDetails?.visitors[0]?.url!='')) && <iframe style={{visibility:'hidden'}} src={invitationDetails?.visitors[0]?.url} />}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={2} />
            </Grid>
        }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
      invitationDetails:state.ReceptionReducer.invitationDetails
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      getInvitationDetails: (requestData) => { dispatch(receptionActions.getInvitationDetails(requestData)) },
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(ReviewSpecialAccess)