import React, { useEffect,  useState } from 'react'
import { GlobalTableDropDown } from '../../Utils/GlobalComponents'
import { useNavigate, useLocation } from "react-router";
import { useDebounce } from "use-debounce"
import { Box, Button, Menu, MenuItem, Typography, Input, Pagination,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,Checkbox, CircularProgress, TextField, Select, FormControl, FormControlLabel, InputLabel, IconButton, TableContainer } from '@mui/material'
import { ReactComponent as DropDownIcon } from '../../Assets/icons/DropDownArrow.svg';
import {ReactComponent as SearchIcon} from '../../Assets/icons/SearchIcon.svg';
import {ReactComponent as NoDataIcon} from '../../Assets/icons/NoDataIcon.svg';
import {ReactComponent as CloseIcon} from '../../Assets/icons/Close.svg';
import { StyledTableCell, StyledTableRow } from '../ManageTenant/ActiveTenant';
import { getAllBookingStatusAction, getAllRoomListAction, getAllRoomListNullAction, getRoomBookingListAction, getRoomBookingListNullAction, updateRoomBookingWatch, updateRoomBookingWatchAction, updateRoomBookingWatchNullAction, updateRoomBookingWatchStatusNullAction } from '../../redux/actions/facilityBookingAction';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { preventDatePickerKeyboard, getLoggedUserData } from '../../Utils/utils';
import moment from 'moment';
import {checkTecTeam} from "./index";
import { ReactComponent as BellWatch } from "../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../Assets/icons/StructuralFaultBell.svg";
import ToastAlert from '../common/Alert';
import CompanyFilter from './CompanyFilter';


const FacilityBookingList = ({tab, getRoomBookingList, getRoomBookingListData, getRoomBookingListNull, getAllBookingStatus, getAllBookingStatusData,getAllRoomList,getAllRoomListNull,getAllRoomListData,updateWatch, updateWatchNull, updateRoomBookingWatchStatusData}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [facilityBookingOptionOpen, setFacilityBookingOptionOpen] = useState(null);
  const [statusOptionOpen, setStatusOptionOpen] = useState(null);
  const [dateOptionOpen, setDateOptionOpen] = useState(null);
  const [roomBookingList, setRoomBookingList] = useState([]);  
  const [statusOptionsList, setStatusOptionsList] = useState([]);
  const [filterOptions, setFilterOptions] = useState({status: "", date: "", limit: 10, offset:0, room_id: "", company_id: ""});
  const [searchText, setSearchText] = useDebounce("", 1000)
  const [textInputState, setTextInputState] = useState("");
  const [currentPage,setCurrentPage] = useState(1)
  const [roomIdList, setRoomIdList] = useState([]);
  const [toastConfigData, setToastConfigData] = useState({ message: '', status: false, color: '', icon: '' })  
  const [companyNames, setComapanyNames] = useState([])
  const {role_id} = getLoggedUserData()
  console.log('location ',location)
  useEffect(() => {
    getAllBookingStatus();
    getAllRoomList();
    return () => {
      getRoomBookingListNull();
      getAllRoomListNull();
      updateWatchNull();
    };
  }, []) 
  
  useEffect(() => {
    if(getAllBookingStatusData.response?.response_status === 1){
      setStatusOptionsList(getAllBookingStatusData.response?.data_array?.bookingStatusArrayList)
    }
  },[getAllBookingStatusData])
 

  useEffect(() => {
    if(getRoomBookingListData.response?.response_status === 1){
      setRoomBookingList(getRoomBookingListData.response?.dataArray);
    }else{
      setRoomBookingList([]);
    }
  }, [getRoomBookingListData])

  useEffect(() => {    
    getRoomBookingList({
      list_for: checkTecTeam() ? 'tec_team' : tab === 0 ?"active" : tab === 1 && "history",
      booking_status: filterOptions.status,
      room_id: filterOptions.room_id,
      booking_date: filterOptions.date ? moment(filterOptions.date).format('YYYY-MM-DD') : "",
      is_my_booking: location.pathname.includes('/facility_booking')   ? 1 : 0,
      search_data: textInputState,
      limit: filterOptions.limit,
      offset: filterOptions.offset,
      company_id: filterOptions.company_id == "" ? "" :JSON.stringify(filterOptions.company_id)
    });
  }, [filterOptions, searchText])

  useEffect(() => {
    if(getAllRoomListData.response?.response_status === 1){
      setRoomIdList(getAllRoomListData.response?.data_array?.roomArrayList)
    }
  }, [getAllRoomListData])

  useEffect(() => {
    if(updateRoomBookingWatchStatusData?.response?.response_status == 1){
      getRoomBookingList({
        list_for: checkTecTeam() ? 'tec_team' : tab === 0 ?"active" : tab === 1 && "history",
        booking_status: filterOptions.status,
        room_id: filterOptions.room_id,
        booking_date: filterOptions.date ? moment(filterOptions.date).format('YYYY-MM-DD') : "",
        is_my_booking: location.pathname.includes('/facility_booking')   ? 1 : 0,
        search_data: textInputState,
        limit: filterOptions.limit,
        offset: filterOptions.offset,
        // company_id: filterOptions.company_id == "" ? "" : JSON.stringify(filterOptions.company_id)
        company_id: filterOptions.company_id == "" ? "" :JSON.stringify(filterOptions.company_id)
      });
      setToastConfigData({ message: updateRoomBookingWatchStatusData?.response?.message, status: true, color: 'green', icon: 'close' })
      updateWatchNull()
      // addNonWorkingHourRoomBookingNull()
      setTimeout(() => {
          setToastConfigData({ message: '', status: false, color: '', icon: '' })
      }, 4000)
  }else if(updateRoomBookingWatchStatusData?.response!=null){
      setToastConfigData({ message: updateRoomBookingWatchStatusData?.response?.message, status: true, color: 'red', icon: 'close' })
      updateWatchNull()
      // addNonWorkingHourRoomBookingNull()
      setTimeout(() => {
          setToastConfigData({ message: '', status: false, color: '', icon: '' })
      }, 4000)
  }

  }, [updateRoomBookingWatchStatusData])
 
  const handleUpdateWatchStatus = (id, status) => {
    console.log(id, status);
    updateWatch({
      room_booking_id: id,
      watch_status: (status === 'yes' && 'no') || (status === 'no' && 'yes'),
    })

  }
 
  const handlePagination = (e,value)=>{
    setCurrentPage(value)
    setFilterOptions({...filterOptions, offset: Number(value) - 1})
}
  

   const handleFacilityBookingOptionOpen = (event) => {
    setFacilityBookingOptionOpen(event.currentTarget);
  };
  const handleFacilityBookingOptionClose = () => {
    setFacilityBookingOptionOpen(null);
  };

  const handleStatusOptionOpen = (e) => {
    setStatusOptionOpen(e.currentTarget);
  }

  const handleStatusOptionClose = () => {
    setStatusOptionOpen(null);
  }

  const handleDateOptionOpen = (e) => {
    setDateOptionOpen(e.currentTarget);
  }

  const handleDateOptionClose = () => {
    setDateOptionOpen(null)
  }
  console.log('companyNames ',companyNames)

  const ColumnsNames = ["",'Ticket-ID','Ref ID', 'Booked On', 'Facility', 'Location', 'Status', 'Created At', ((role_id ==2 || role_id == 14 || role_id == 16) && location.pathname == '/manage_all_facility_bookings') && 'Company Name', ''];

  const TecGroupColumnName = ["",'Ticked-ID', 'Ref-ID', 'Name','Email', 'Booked On', 'Room Code', 'Status','Created On', 'Transaction-ID'];

   

console.log(roomIdList);
 
  const getTableColumns = (dataType) => {
    let columns = checkTecTeam() ? TecGroupColumnName : ColumnsNames ;
    return (
      columns.map((item, index) => (
      <StyledTableCell sx={{color: '#0F2C6F', fontWeight: 500 }} key={index}>{item}</StyledTableCell>
      ))
    )
  }

  const handleFilterReset = () => {
    setFilterOptions({status: "", date: "",  limit: 10, offset:0, room_id: "", company_id:""});
    setComapanyNames([])
    setTextInputState("");
    // setSearchText("")
  } 

  const BodyData = () => {
    return(
      <Box sx={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', gap:'10px', padding:'60px 0', width:'100%'}}>
        <NoDataIcon />
        {((!filterOptions?.date == '' || !filterOptions?.room_id =='' || !filterOptions?.status == '' || !searchText == '') && roomBookingList?.length ==0) ? (
          <Typography sx={{color:'#0F2C6F', fontSize:'14px',lineHeight:'24px', textAlign:'center', fontWeight:'500'}}>There are no active bookings for selected facilities.</Typography>
        ):(
          <Box>
            <Typography sx={{color:'#0F2C6F', fontSize:'14px',lineHeight:'24px', textAlign:'center', fontWeight:'500'}}>You have no active booking yet.</Typography>
            <Typography sx={{color:'#0F2C6F', fontSize:'14px',lineHeight:'24px', textAlign:'center', fontWeight:'500'}}>In order to make a booking, click on the make a booking button.</Typography>        
          </Box>
        )}
      </Box>
    )
  }

  const ActiveTable = () => {
    return(
      <>
      <TableContainer >
      <Table>
        <TableHead>
          <StyledTableRow >
            {getTableColumns()}
          </StyledTableRow>
        </TableHead>
        <TableBody>
         
             {roomBookingList?.length > 0 && getRoomBookingListData.isLoading === false && roomBookingList.map((item,index) => <StyledTableRow key={index}>
              <StyledTableCell >
                {(item?.watch_status.toLowerCase() === 'yes' || item?.watch_status.toLowerCase() === 'no') &&
                <IconButton onClick={() => handleUpdateWatchStatus(item?.id, item?.watch_status)}
                  disabled={updateRoomBookingWatchStatusData.isLoading || getRoomBookingListData.isLoading }
                 >
                 {item?.watch_status.toLowerCase() === 'yes' && <BellWatch  />}
                 {item?.watch_status.toLowerCase() === 'no' && <BellUnWatch  />}
                </IconButton>
                }
                 </StyledTableCell>
               <StyledTableCell  >{checkTecTeam() ? `#${item?.slt_transaction_number}` :<NavLink style={{color:  "#2764F4", textDecoration: "none"}} to={`/facility_booking/${item?.id}`}>
               #{item?.slt_transaction_number}</NavLink>}</StyledTableCell>
              <StyledTableCell  >{item?.tec_booking_id || ' - '}</StyledTableCell>
              {checkTecTeam() && <StyledTableCell>{item?.created_by_name}</StyledTableCell>}
              {checkTecTeam() && <StyledTableCell>{item?.email || "-"}</StyledTableCell>}
              <StyledTableCell sx={{color: "#0F2C6F", fontWeight: 500}}>{moment(item?.start_date_time).format('DD/MM/YYYY,HH:mm - ')} {moment(item?.end_date_time).format('HH:mm')}</StyledTableCell>
              <StyledTableCell sx={{color: "#0F2C6F", fontWeight: 500}}>{item?.name}</StyledTableCell>
              {checkTecTeam() === false && <StyledTableCell >{item?.Location}</StyledTableCell>}
              
              <StyledTableCell ><Box sx={{display: 'flex', justifyContent: 'flex-start', height: "100%"}}>
                <Typography sx={{px: 1, background: item?.booking_status_background_color_code, color: item?.booking_status_text_color_code, borderRadius: "6px", fontSize: "14px"}}>{item?.booking_status_display_name}</Typography>
                </Box>
                </StyledTableCell>
              <StyledTableCell >{moment.utc(item?.created_at).local().format('DD/MM/YYYY - HH:mm')
              }</StyledTableCell>
              {checkTecTeam() && <StyledTableCell>{item?.payment_transaction_id || "-"}</StyledTableCell>}
              {((role_id ==2 || role_id == 14 || role_id == 16) && location.pathname == '/manage_all_facility_bookings') && <StyledTableCell >{item?.company_name}</StyledTableCell>}                            
              {checkTecTeam() === false && <StyledTableCell ><Button variant='outlined' sx={{textTransform: "none", border: "1px solid #AAC3FF", color: "#0F2C6F", fontSize: "12px", fontWeight: 500}} onClick={() => navigate(`/facility_booking/${item?.id}`) }>View</Button></StyledTableCell>}

             </StyledTableRow>)}
        </TableBody>
      </Table> 
      </TableContainer>
      {roomBookingList?.length === 0 && getRoomBookingListData.isLoading === false && <BodyData/>} 
      {getRoomBookingListData.isLoading &&   <Box sx={{ width: "100%",display: 'flex', justifyContent: 'center', p: 5, }}>
                            <CircularProgress />
                        </Box>}              
      </>

    )
  }

  const getYesterdayDate = () => {
    let d = new Date();
    d.setDate(d.getDate() - 1);

    return d;
  }
 
  return (
    <Box>
       <ToastAlert message={toastConfigData.message} status={toastConfigData.status} color={toastConfigData.color} icon={toastConfigData.icon} />    
      <Box sx={{display:'flex', justifyContent:'space-between', px:'12px'}}>
        <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
        <Box 
            sx={{minWidth: 120 }}
            >
            <FormControl size='small' fullWidth>
            <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Facility</InputLabel>        
              <Select
                 label="Facility"
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:1 }}
                onChange={(e) => {
                  setFilterOptions({...filterOptions, room_id: e.target.value})
                }}
                value={filterOptions.room_id || ""}
                MenuProps={{
                  style: {
                     maxHeight: 400,
                        },
                  }}
               >              
              {roomIdList?.map(item => <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>)}
            </Select>
            </FormControl>
              </Box>
            <Box 
            sx={{minWidth: 120}}
            >
            <FormControl size='small' fullWidth>
            <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>        
              <Select
                 label="Status"
                sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px',boxShadow:1 }}
                onChange={(e) => {
                  setFilterOptions({...filterOptions, status: e.target.value})
                }}
                value={filterOptions.status || ""}
               >              
              {statusOptionsList?.map(item => <MenuItem key={item?.id} value={item?.booking_status_name}>
                {item?.display_name}
              </MenuItem>)}
            </Select>
            </FormControl>
              </Box>
              <FormControl size="auto" sx={{ width: 160,ml:1.5,boxShadow:"0px 0px 2px #C0C7D7, 0px 3px 2px #EAF0FF" }}>
           <DesktopDatePicker  inputFormat='dd/MM/yyyy' renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} size="small" />} 
          //  value={formatDate(filterOptions.date).toString() || ""} 
           value={filterOptions.date || null} 
          onChange={e => setFilterOptions({...filterOptions,date:e})} 
          disablePast={tab === 0 && checkTecTeam() === false}
          disableFuture={tab === 1 && checkTecTeam() === false}
          maxDate={tab === 1 ? getYesterdayDate() : null}
           />        
          </FormControl>
          {((role_id ==2 || role_id == 14 || role_id == 16) && location.pathname == '/manage_all_facility_bookings') && <FormControl sx={{ width: '160px'}} size='small' fullWidth>
            <CompanyFilter setComapanyNames={setComapanyNames} companyNames={companyNames} filterOptions={filterOptions} setFilterOptions={setFilterOptions} />  
          </FormControl>}
           
          <Box>
          <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"14px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} onClick={() => handleFilterReset()} >Reset</Typography>
          </Box>
        </Box>
        <Box>
        <Box
            sx={{
              float: "right",
             padding:'5px',
              display: "flex",
              alignItems: "center",
              border: " 1px solid #DBDFE9",
              borderRadius: "6px",
              mr:2
            }}
          >
            <Input
              name={'search'}
               size="small"
              type="text"
               placeholder='Search'
              style={{
                border: "none",
                outline: "none",
                fontSize: "14px",
                fontWeight: 400,
                width: "100%",
              }}
              inputProps={{
                sx: {
                  height: { md: "25px", sm: "20px", xs: "4px" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  flexGrow: 1,
                  width: "inherit",
                },
                
               }}
               value={textInputState}
              disableUnderline
              onChange={e => {
                setTextInputState(e.target.value)
                setSearchText(e.target.value)
              }}
              />
            <Box sx={{display: 'flex', flexGrow: 0, alignItems:'center',}}>              
            {textInputState && <IconButton onClick={() => {
              setTextInputState("");
              setSearchText("")
              }}> <CloseIcon style={{width: 15, height: 15,  }} /></IconButton>}
            <SearchIcon cursor="pointer" />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box>
              <ActiveTable />
              {getRoomBookingListData.response?.dataArray?.length > 0 &&
              <Pagination onChange={handlePagination} sx={{ display: 'flex',justifyContent: 'center', py: 4 }} count={Math.ceil(getRoomBookingListData.response?.totalRecordCount / 10) || 1} page={currentPage} size="large" shape="rounded" /> 
            }
      </Box>
    </Box>
  )
}
 
const mapDispatchToProps = dispatch => {
  return {
    getRoomBookingList: (params) => dispatch(getRoomBookingListAction(params)),
    getRoomBookingListNull: () => dispatch(getRoomBookingListNullAction()),
    getAllBookingStatus: () => dispatch(getAllBookingStatusAction()),
    getAllRoomList: () => dispatch(getAllRoomListAction()),
    getAllRoomListNull: () => dispatch(getAllRoomListNullAction()),
    updateWatch: (data) => dispatch(updateRoomBookingWatch(data)), 
    updateWatchNull: () => dispatch(updateRoomBookingWatchStatusNullAction())
   }
}

const mapStateToProps = state => {
  return {
      getRoomBookingListData: state.FacilityBookingReducer.getBookingDetailsList,
      getAllBookingStatusData: state.FacilityBookingReducer.getAllBookingStatus,
      getAllRoomListData: state.FacilityBookingReducer.getAllRoomList,      
      updateRoomBookingWatchStatusData:state.FacilityBookingReducer.updateRoomBookingWatchStatus,
  }
}
 export default connect(mapStateToProps, mapDispatchToProps)(FacilityBookingList)