import { Tabs, Tab, Box, Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import UpcommingVisitorsTabs from './UpcommingVisitorsTabs';
import { ReactComponent as BackIcon } from '../../../Assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export const UpcommingVisitors = (props) => {
  const navigate = useNavigate()
  const [isReceptionist, setIsReceptionaist] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("userToken") && localStorage.getItem('userData')) {
      if(JSON.parse(localStorage.getItem('userData'))?.role_id == 13){
        setIsReceptionaist(true)
        
      }}
  },[])

  return (
    <>
      <Grid container sx={{background: 'rgba(143, 146, 161,0.05)', fontFamily: 'Inter',minHeight:"100%" }}>
        <Grid item xs={0.5} />
        <Grid item xs={11}>
              <Box sx={{mt:8,mb:4}}>
              {!isReceptionist && <BackIcon onClick={() => navigate(-1)} style={{cursor:'pointer'}} />}
                <Typography style={{ fontSize: "26px", fontWeight: "500", color: '#002D74' }}>
                  Upcoming Visitors
                </Typography>
                <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#002D74' }}>
                  Below are the list of visitors registered and have entered/exited Singapore Land Tower.
                </Typography>
              </Box>
            <UpcommingVisitorsTabs />
        </Grid>
        <Grid item xs={0.5} />
        
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {

  }
}

const mapDispatchToProps = (dispatch) => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpcommingVisitors)