import { Box, Button, Checkbox, Chip, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { ReactComponent as BellWatch } from "../../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../../Assets/icons/StructuralFaultBell.svg";
import { ReactComponent as DownloadIcon } from '../../../Assets/icons/DownloadIcon.svg';
import { ReactComponent as LogoIcon } from '../../../Assets/icons/Logo.svg';
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import ToastAlert from "../../common/Alert";
import StatusStepper from '../../common/stepper'
import { checkFCCRole, getLoggedUserData, getTimeStamp } from "../../../Utils/utils";
import Faultalert from "../../common/faultalert";
import { getStatus } from "../../../Utils/ActiveStatus";
import { ReactComponent as PdfIcon } from '../../../Assets/icons/PDFIcon.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loader from "../../common/Loader";
import html2PDF from 'jspdf-html2canvas';
import moment from "moment";
import UnAuthorized from "../../common/Unauthorized";
import UpdatePopup from "../UpdatePopup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#0F2C6F',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#0F2C6F',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const StyledTypography = styled(Typography)({
  color: '#0F2C6F',
  fontSize: '12px'
})

const StyledPrimaryTypography = styled(Typography)({
  color: '#0F2C6F',
  fontSize: '18px'
})

const alertDatas = [
  { id: 1, label: "Withdraw Request", description: 'Are you sure you want to withdraw request', buttonvalue: 'Confirm', buttonStyle: 'blue' },
  { id: 2, label: "Approve Service Request ", description: 'Approve service request?', buttonvalue: 'Confirm', buttonStyle: 'blue' },
  { id: 3, label: "Reject Service Request", description: 'Reject service request?', buttonvalue: 'Confirm', buttonStyle: 'blue' },
]

const ReviewSecurityClearance = ({tab_value, serviceReqDetails, addServiceReqStatus, serviceRequestByID, setServiceRequestStateNull, updateServiceRequestStatus, serReqUpdateStatus,fromDashboard,updateWatchStatus,updateWatch,notification,isUnAuthorized }) => {
  let { user_id, role_id } = getLoggedUserData();
  const navigate = useNavigate();
  const { service_req_id,tab_id } = useParams();
  const [reviewData, setReviewData] = useState({})
  const [tenantDetails, setTenantDetails] = useState({})
  const [terms,setTerms] = useState(null);
  const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
  const [confirmationAlert, setConfirmAlert] = useState({ status: false, alertData: {}, actionStatus: '' });
  const [loaderStatus,setLoaderStatus] = useState(true)
  const [error, setError] = useState({});
  const [description, setDescription] = useState("");
  const [openPopup, setOpenPopup] = useState(false)
  const [nextUpdateStatus,setNextUpdateStatus] = useState(null); 

  useEffect(() => {
    serviceRequestByID({ service_requests_id: service_req_id })
  }, [updateWatchStatus,service_req_id,notification])

  useEffect(() => {
    if (serviceReqDetails?.serviceRequestsArrayList) {
      setReviewData(serviceReqDetails?.serviceRequestsArrayList[0])
      setTenantDetails(serviceReqDetails?.companiesArrayList[0])
      setLoaderStatus(false)
    }else if(isUnAuthorized!=false){
      setLoaderStatus(false)
    }
    if(serviceReqDetails?.nextStatusListArrayList){
      setNextUpdateStatus(serviceReqDetails?.nextStatusListArrayList)
  }
    if(serviceReqDetails?.termsArrayList){
      setTerms(JSON.parse(serviceReqDetails?.termsArrayList[0].config_value))
  }
  }, [serviceReqDetails])

  useEffect(() => {
    if (serReqUpdateStatus?.response_status==1) {
      closeFunc()
      setOpenPopup(false)
      setSnackbarStatus({ message:serReqUpdateStatus?.message, status: true, color: 'green', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
        navigate(`/service_request_list/${tab_value}`)
      }, 3000);
    } else if (serReqUpdateStatus?.response_status==0) {
      closeFunc()
      setSnackbarStatus({ message:serReqUpdateStatus?.message , status: true, color: 'red', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
      }, 3000);
    }else if(serReqUpdateStatus!=null){
      closeFunc()
      setSnackbarStatus({ message:serReqUpdateStatus , status: true, color: 'red', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
      }, 3000);
    }
  }, [serReqUpdateStatus])

  useEffect(()=>{
    if(updateWatchStatus=="Service Request watch updated successfully"){
     setSnackbarStatus({ message: updateWatchStatus, status: true, color: 'green', icon: 'close' })
      setTimeout(()=>{
         removeSnackbar()
        setServiceRequestStateNull()
      },3000)
    }else if(updateWatchStatus!=null){
     setSnackbarStatus({ message:updateWatchStatus , status: true, color: 'red', icon: 'close' })
      setTimeout(()=>{
         removeSnackbar()
        setServiceRequestStateNull()
      },3000)
    }
  },[updateWatchStatus])

  const removeSnackbar = () => {
    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
  }

  const downloadPDF = () => {
    let data = document.getElementById('print')
    html2PDF(data,{
          jsPDF: {
            format: 'a4',
          },    
          imageType: 'image/jpeg',
          output: `${reviewData?.ticket_id} ${moment(new Date()).format('DD/MM/yyyy')}.pdf`
    })

}

  const downloadUploadedSign = (filePath, fileName) => {
    if (filePath !== '') {
      window.open(`${process.env.REACT_APP_ASSETS_PATH}/${filePath}`, '_blank');
    }

  }
  const updateStatus = (actionStatus, actionStatusTypeId) => {
    if (actionStatusTypeId < 3) {
      setConfirmAlert({ status: true, alertData: alertDatas[actionStatusTypeId], actionStatus: actionStatus })
    }

  }

  const renderActButtons = () => {

    if (parseInt(role_id) === 3 || parseInt(role_id)===2 || parseInt(role_id)===14 || checkFCCRole() ) {
      if (reviewData?.status === 'submitted') {
        return (<> <Button variant="outlined" sx={{
          background: '#FFFFFF',
          color: '#123FA9',
          height: '50px',
          width: '114px',
          fontSize: '14px',
          pr: 0,
          pl: 0,
          mr:2,
          "&:hover":{ background: '#FFFFFF'},
          textTransform: 'none',
        }} onClick={() => updateStatus('rejected', 2)}>Reject</Button>
          <Button variant="contained" sx={{
            background: '#123FA9',
            color: '#FFFFFF',
            height: '50px',
            width: '114px',
            fontSize: '14px',
            pr: 0,
            pl: 0,
            textTransform: 'none',
            "&:hover":{ background: '#123FA9'}
          }} onClick={() => updateStatus('approved', 1)}>Approve</Button></>
        )
      } else if (reviewData?.status==="resolved" || reviewData?.status==="closed" ||  reviewData?.status==="rejected" || reviewData?.status==="withdraw"){

      } else {
        return (<Button variant="contained" sx={{
          background: '#123FA9',
          color: '#FFFFFF',
          height: '50px',
          width: '114px',
          fontSize: '14px',
          pr: 0,
          pl: 0,
          textTransform: 'none',

      }} onClick={() =>  setOpenPopup(true)}>Update Status</Button>)
      } 
    } else {
      if (parseInt(role_id)==4 && reviewData?.status==="submitted") {
        return (
          <Button variant="contained" sx={{
            background: '#BD0251',
            color: '#FFFFFF',
            height: '50px',
            width: '114px',
            fontSize: '14px',
            pr: 0,
            pl: 0,
            textTransform: 'none',
            "&:hover":{backgroundColor:"#BD0251"}
          }} onClick={() => updateStatus('withdraw', 0)}>Withdraw</Button>
        )

      }
    }

  }

  const confirmFunc = () => {
 
 
    if(!description && confirmationAlert.actionStatus === "rejected"){
      setError(prevValue=>({...prevValue, description:'Remarks required !' }))
      return;
    }else setError(prevValue=>({...prevValue, description:'' }))

    let serReqNewStatus = {
      service_requests_id: service_req_id,
      description: description,
      status: confirmationAlert.actionStatus
    }

    updateServiceRequestStatus(serReqNewStatus)
  }

  const stepperWithdrawFunction = ()=>{
    let serReqNewStatus = {
      service_requests_id: service_req_id,
      description: '',
      status: 'withdraw'
    }
    updateServiceRequestStatus(serReqNewStatus)
  }

  const closeFunc = () => {
    setConfirmAlert({ status: false, alertData: {}, actionStatus: '' })
    setError(prevValue=>({...prevValue, description:'' }))
  }

  const handleWatchUnWatch =(id,status)=>{
    let data = {
        service_requests_id:id,
        watch_status:status
    }
    updateWatch(data)
}

const renderDescription =()=>{
    if(confirmationAlert.actionStatus === "rejected"){
        return  <Box sx={{  padding: '16px 24px' }}>
          <TextField multiline fullWidth rows={3} value={description} name="description" onChange={(e)=> { 
            setDescription(e.target.value); setError(prevValue=>({...prevValue,description:''})) ;
            }} />
          <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
              <br /> {error?.description}
          </Typography>
        </Box>
    }else return null
}


  return (
    <>
    {loaderStatus ? <Loader status={loaderStatus} /> : isUnAuthorized!=false ? <UnAuthorized data={isUnAuthorized} /> :
    <>
      <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
      {confirmationAlert.status && <Faultalert confirmFunc={confirmFunc} closeFunc={closeFunc} alertData={confirmationAlert.alertData} renderElement={renderDescription()} />}
      {openPopup && <UpdatePopup closeFunc={()=>{setOpenPopup(false);  setError(prevValue=>({...prevValue, description:'' }))}} serviceType={service_req_id} serviceTypeValue={"Security Clearance"} currentStatus={reviewData?.status} nextStatus={nextUpdateStatus} />}
      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
        <Grid item xs={0.5} />
        <Grid item xs={8}>
          <Box sx={{ mt: 7, mb: 4 }}>
            <ArrowIcon cursor='pointer' onClick={() =>{
              // if(fromDashboard=="true"){
              //   navigate(`/dashboard`)
              // }else{
                navigate(-1)
              // }
            }} />
            <Box sx={{display:"flex"}}>
                <Typography sx={{ fontSize: '26px', color: '#0F2C6F', mt: 2, mb: 1 }}>{reviewData?.display_name} - #{reviewData?.ticket_id}   </Typography>
                {reviewData?.status!='rejected' && reviewData?.status!='closed' && reviewData?.status!='withdraw' && 
                  <>
                  {serviceReqDetails?.watch_status=="yes" ?  <BellWatch onClick={()=>handleWatchUnWatch(service_req_id,"no")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' /> : 
                                  <BellUnWatch onClick={()=>handleWatchUnWatch(service_req_id,"yes")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' />
                  }
                  </>
                }
            </Box>
            <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>{getTimeStamp(reviewData?.created_at)}</Typography>
            <Chip label={getStatus(reviewData?.status, 'text')} sx={{ backgroundColor: getStatus(reviewData?.status, 'bgcolor'), color: getStatus(reviewData?.status, 'color'), borderRadius: '6px', height: '24px', fontSize: '14px', ml: 2 }} />
          </Box>
        </Grid>
        <Grid item xs={3.5} />
      </Grid>
      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
        <Grid item xs={0.5} />
        <Grid item xs={8}>

          <Box id='print' sx={{ backgroundColor: '#FFFFFF', p: 3 }}>
                <Box sx={{display:'flex',justifyContent:'space-between',width:'100%',position:'relative',zIndex:-5}}>
                         <StyledPrimaryTypography>Use- Security Clearance {reviewData?.ticket_id}</StyledPrimaryTypography>
                         <LogoIcon />
                </Box>
            <Grid container sx={{ mt:2,mb: 3, pb: 3 }} >
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }} >
                <StyledPrimaryTypography >Tenant Details</StyledPrimaryTypography>
                <Button data-html2canvas-ignore='true' onClick={downloadPDF} sx={{ color: '#0F2C6F', textTransform: 'capitalize', backgroundColor: '#CFDDFE', borderRadius: '6px', '&:hover': { backgroundColor: '#CFDDFE' } }} endIcon={<DownloadIcon />}>Download</Button>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography sx={{ color: ' #0F2C6F', fontSize: '14px' }}>{reviewData?.created_by_name}</Typography>
                <StyledTypography>{tenantDetails?.company_name}</StyledTypography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <StyledTypography>Office : {tenantDetails?.country_code}{tenantDetails?.phone_no}</StyledTypography>
                <StyledTypography>Mobile : {tenantDetails?.country_code}{tenantDetails?.phone_no}</StyledTypography>
              </Grid>

            </Grid>

            <Grid container rowSpacing={4} columnSpacing={1}>
              <Grid item xs={4} >
                <StyledPrimaryTypography>Contractor Details</StyledPrimaryTypography>
              </Grid>
              <Grid item xs={4} >
                <StyledTypography>{reviewData?.contractor_company}</StyledTypography>
                <StyledTypography>{reviewData?.contractor_address_1}</StyledTypography>
                <StyledTypography>{reviewData?. contractor_address_2}</StyledTypography>  
                <StyledTypography>Office:{reviewData?.office_phone_code}{reviewData?.office_phone_no}</StyledTypography>
              </Grid>
              <Grid item xs={4} >
                <StyledTypography>{reviewData?.person_in_charge}</StyledTypography>
                <StyledTypography>Mobile:{reviewData?.contractor_phone_code}{reviewData?.contractor_phone_no}</StyledTypography>
              </Grid>
              <Grid item xs={12}>
                <StyledPrimaryTypography>Preferably Work Period </StyledPrimaryTypography>

              </Grid>
              <Grid item xs={12} >
                <TableContainer >
                  <Table sx={{ minWidth: 700,border:'1px solid #C0C7D7' }} aria-label="customized table" >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell align="left">Nationality</StyledTableCell>
                        <StyledTableCell align="left">NRIC</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {serviceReqDetails.securityClearanceWorkMensArrayList&&serviceReqDetails.securityClearanceWorkMensArrayList.map((row) => (
                        <StyledTableRow key={row.name}>
                          <StyledTableCell component="th" scope="row">
                            {row.name}
                          </StyledTableCell>
                          <StyledTableCell align="left">{row.nationality_name}</StyledTableCell>
                          <StyledTableCell align="left">{row.nric}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                      </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={4} >
                <StyledPrimaryTypography>Contract Period</StyledPrimaryTypography>
              </Grid>
              <Grid item xs={4} sm={4} >
                <StyledTypography>Start Date: {moment(reviewData?.from_date).format('DD-MM-YYYY')}</StyledTypography>
                <StyledTypography>End Date: {moment(reviewData?.to_date).format('DD-MM-YYYY')}</StyledTypography>
              </Grid>
              <Grid item xs={4} sm={4} >
                <StyledTypography>Working Days</StyledTypography>
                <StyledTypography>Weekdays: {reviewData.weekday_from_time}-{reviewData.weekday_to_time}</StyledTypography>
                <StyledTypography>Saturday:  {reviewData.saturday_from_time}-{reviewData.saturday_to_time}</StyledTypography>
                <StyledTypography>Sunday:  {reviewData.sunday_ph_from_time}-{reviewData.sunday_ph_to_time}</StyledTypography>
              </Grid>
              <Grid item xs={12}>
                <StyledPrimaryTypography>Work Details</StyledPrimaryTypography>
                <StyledTypography sx={{mt:1}}>{reviewData?.description}</StyledTypography>
              </Grid>
            </Grid>
            <Box sx={{mt:4}}>

              <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>{ terms && terms[0]?.title}</Typography>
              <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{terms && terms[0]?.description}</Typography>
              {terms && terms[1]?.rules.map((value,index)=>(
                                <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{index+1.} {value[`${index+1}`]}</Typography>
                            ))}
             
              <Typography sx={{ color: '#9AA6C3', fontSize: '14px' }}>
                <Checkbox checked disabled />
                I have read and agree to comply with the above terms & conditions.
              </Typography>
            </Box>
          </Box>

        </Grid>
        <Grid item xs={3.5}>
          <Box sx={{ backgroundColor: '#FFFFFF', mx: 3 }}>
              <Box sx={{borderBottom:"1px solid #C0C7D7",py:2,px:3,borderRadius:"8px 8px 0 0 "}}>
                  <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>Status Updates</Typography>
              </Box>
              <Box sx={{p:2}}>
                {role_id==4 ? 
                  <StatusStepper type="ServiceRequest" currentStatus={reviewData?.status} withdrawFunc={stepperWithdrawFunction} statusLogs={serviceReqDetails?.serviceRequestLogTempArray?.length > 0 ? serviceReqDetails?.serviceRequestLogTempArray : []} /> : 
                  <StatusStepper currentStatus={reviewData?.status} withdrawFunc={stepperWithdrawFunction} statusLogs={serviceReqDetails?.serviceRequestsLogsArrayList?.length > 0 ? serviceReqDetails?.serviceRequestsLogsArrayList : []} />
                }
                </Box>
          </Box>
        </Grid>
        
      </Grid>
      {(role_id === 3 || role_id==2 || role_id==14) ? <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
        <Grid item xs={0.5} />
        <Grid item xs={8}>

          {/* <Box sx={{ backgroundColor: '#FFFFFF', p: 3 }}>
            <StyledPrimaryTypography >Uploaded Contractor Signature</StyledPrimaryTypography>
            <List aria-label="main mailbox folders">
              <ListItem alignItems="flex-start" sx={{ border: '1px solid #8080806b', borderRadius: '10px' }}
                secondaryAction={
                  <IconButton onClick={() => serviceReqDetails?.service_request_files ? downloadUploadedSign(serviceReqDetails?.service_request_files[0]?.path, serviceReqDetails?.service_request_files[0]?.original_name) : ''} edge="end" aria-label="delete">
                    <DownloadIcon  />
                  </IconButton>
                }>
                <ListItemButton
                // selected={selectedIndex === 0}
                // onClick={(event) => handleListItemClick(event, 0)}
                >
                  <ListItemIcon>
                    <PdfIcon />
                  </ListItemIcon>
                  <ListItemText sx={{color:'#0F2C6F'}} primary={serviceReqDetails?.service_request_files ? serviceReqDetails?.service_request_files[0]?.original_name : ''} />

                </ListItemButton>
              </ListItem>
            </List>
          </Box> */}
        </Grid>
      </Grid> : ''}
      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
        <Grid item xs={0.5} />
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end', pb: 5, pt: 2 }}>
          {renderActButtons()}
        </Grid>
        <Grid xs={3.5}></Grid>
      </Grid>
    </>
    }
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
    serReqUpdateStatus: state.Service_Req_Reducer.updateStatus,
    updateWatchStatus:state.Service_Req_Reducer.updateWatchStatus,
    notification:state.DashboardReducer.notification,
    isUnAuthorized:state.Service_Req_Reducer.isUnAuthorized

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateServiceRequestStatus: (data) => (dispatch(serviceRequestAction.updateServiceRequestStatus(data))),
    setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
    serviceRequestByID: (data) => (dispatch(serviceRequestAction.serviceRequestDetailsById(data))),
    updateWatch: (data) => (dispatch(serviceRequestAction.updateWatchStatus(data))),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSecurityClearance);