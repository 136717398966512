import axios from "axios";
import { createAction } from "redux-actions";

export const sessionExpired = createAction("SESSION_EXPIRED");

export const updateImportantContact = createAction("UPDATE_CONTACT_REDUCER");

export const setUpdateImportantContact =()=>{
    return(dispatch)=>{
        dispatch(updateImportantContact())
    }
}

export const createContactInitiate = createAction("CREATE_CONTACT_INITIATE")
export const createContactSuccess = createAction("CREATE_CONTACT_SUCCESS")
export const createContactFailure = createAction("CREATE_CONTACT_FAILURE")

export const createContact = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(createContactInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/createContact`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(createContactSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(createContactFailure("Something went wrong"))
            }
        })
    }
}

export const getContactListInitiate = createAction("GET_CONTACTLIST_INITIATE")
export const getContactListSuccess = createAction("GET_CONTACTLIST_SUCCESS")
export const getContactListFailure = createAction("GET_CONTACTLIST_FAILURE")

export const getContactList = ()=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        getContactListInitiate()
        axios.get(`${process.env.REACT_APP_API_URL}/contactList`,config)
        .then((response)=>{
            if(response.data.response_json){
                dispatch(getContactListSuccess(response.data.response_json))
            }else{
                dispatch(getContactListFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getContactListFailure())            
            }
        })
    }
}

export const editContactInitiate = createAction("EDIT_CONTACT_INITIATE")
export const editContactSuccess = createAction("EDIT_CONTACT_SUCCESS")
export const editContactFailure = createAction("EDIT_CONTACT_FAILURE")

export const editContact = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(editContactInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/EditContacts`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(editContactSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(editContactFailure("Something went wrong "))            
            }
        })
    }
}

export const addGroupSubcontact = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(editContactInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/addSubContacts`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(editContactSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(editContactFailure("Something went wrong "))            
            }
        })
    }
}

export const getOneGroupContactInitiate = createAction("GET_ONEGROUPCONTACT_INITIATE")
export const getOneGroupContactSuccess = createAction("GET_ONEGROUPCONTACT_SUCCESS")
export const getOneGroupContactFailure = createAction("GET_ONEGROUPCONTACT_FAILURE")

export const getOneGroupContact = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getOneGroupContactInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getContactListById`,data,config)
        .then((response)=>{
            if(response.data.response_status="success"){
                dispatch(getOneGroupContactSuccess(response.data.response_json))
            }else{
                dispatch(getOneGroupContactFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getOneGroupContactFailure())            
            }
        })
    }
}

export const getHbReasonInitiate = createAction("GET_HBREASON_INITIATE")
export const getHbReasonSuccess = createAction("GET_HBREASON_SUCCESS")
export const getHbReasonFaiure = createAction("GET_HBREASON_FAILURE")

export const getHbReason = ()=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getHbReasonInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/getHandbookReasons`,config)
        .then((response)=>{
            if(response.data.response_json){
                dispatch(getHbReasonSuccess(response.data.response_json.HandbookReasonList))
            }else{
                dispatch(getHbReasonFaiure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getHbReasonFaiure())            
            }
        })
    }
}

export const createHbInitiate = createAction("CREATE_HANDBOOK_INITIATE")
export const createHbSuccess = createAction("CREATE_HANDBOOK_SUCCESS")
export const createHbFailure = createAction("CREATE_HANDBOOK_FAILURE")

export const createHb = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    return(dispatch)=>{
        dispatch(createHbInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/createHandbook`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(createHbSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(createHbFailure(err.response_message))            
            }
        })
    }
}

export const getHbListInitiate = createAction("GET_HBLIST_INITIATE")
export const getHbListSuccess = createAction("GET_HBLIST_SUCCESS")
export const getHbListFailure = createAction("GET_HBLIST_FAILURE")

export const getHbList = ()=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getHbListInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/getHandbookList`,config)
        .then((response)=>{
            if(response.data.response_json){
                dispatch(getHbListSuccess(response.data.response_json))
            }else{
                dispatch(getHbListFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getHbListFailure())            
            }
        })
    }
}

export const  updateHbStatusInitiate = createAction("UPDATE_HBSTATUS_INITIATE")
export const  updateHbStatusSuccess = createAction("UPDATE_HBSTATUS_SUCCESS")
export const  updateHbStatusFailure = createAction("UPDATE_HBSTATUS_FAILURE")

export const updateHbStatus = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(updateHbStatusInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/UpdateActiveRecordChangeStatus`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(updateHbStatusSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(updateHbStatusFailure("Something went wrong"))            
            }
        })
    }
}