import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../Header";
import SuperAdminDashBoard from "../Pages/SuperAdmin";
import Addbuilding from "../Pages/SuperAdmin/addbuilding";
import AddFaultType from "../Pages/SuperAdmin/AddFaultType";
import Addlevel from "../Pages/SuperAdmin/addlevel";
import AddServiceType from "../Pages/SuperAdmin/AddServiceType";
import AddUnit from "../Pages/SuperAdmin/AddUnit";

const SuperAdmin = () => {
  return (
    <React.Fragment>
      <Header>
        <Routes>
          <Route path="/dashboard" element={<SuperAdminDashBoard />}></Route>
          <Route path="/add_building" element={<Addbuilding />}></Route>
          <Route path="/add_level" element={<Addlevel />}></Route>
          <Route path="/add_unit" element={<AddUnit />}></Route>
          <Route path="/add_fault_type" element={<AddFaultType />}></Route>
          <Route path="/add_service_type" element={<AddServiceType />}></Route>
        </Routes>
      </Header>
    </React.Fragment>
  );
};

export default SuperAdmin;
