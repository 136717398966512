import axios from "axios";
import {createAction} from "redux-actions";

export const sessionExpired = createAction("SESSION_EXPIRED");
export const getUserMenuInitiate=createAction("USER_MENU_INITIATE");
export const getUserMenuSuccess=createAction("USER_MENU_SUCCESS");
export const getUserMenuFailure=createAction("USER_MENU_FAILURE");
export const getUserMenuNull = createAction('USER_MENU_NULL')

export const getUserMenuNullAction = () => dispatch => dispatch(getUserMenuNull());

export const getUserMenus=(id,token)=>{
    let config={
        headers:{
            Authorization:`Bearer ${token}`}
    }
    let data={
        role_id:id
    }
    return (dispatch)=>{
        dispatch(getUserMenuInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/getAllMenusByRoleId`,data,config)
          .then((response)=>{
              if(response.status===200) {
                dispatch(getUserMenuSuccess(response.data?.response_json));
             }else{
                dispatch(getUserMenuFailure(response.data.message));
             }
          })
        
          .catch((err) => {
            if(err?.response?.status===401){
              dispatch(sessionExpired('Session Expired'));
            }else{
              dispatch(getUserMenuFailure());
            }
          });
    }

}