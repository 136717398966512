import { Box, Checkbox,  Menu, MenuItem, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useState,useEffect } from 'react'
import { ReactComponent as EditIcon } from '../../Assets/icons/EditIcon.svg';
import { ReactComponent as ViewDetails } from '../../Assets/icons/ViewDetails.svg';
import { ReactComponent as DeleteIcon } from '../../Assets/icons/Delete.svg';
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxIcon.svg';
import { ReactComponent as BellWatch } from "../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../Assets/icons/StructuralFaultBell.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate } from 'react-router-dom';
import { getStatus } from '../../Utils/ActiveStatus';
import { getDDMMMYYYYFormat, getTimeStamp } from '../../Utils/utils';
import { connect } from 'react-redux';
import * as serviceRequestAction from "../../redux/actions/service_request";
import {ReactComponent as ArrowUpwardIcon} from '../../Assets/icons/sortasc.svg';
import {ReactComponent as ArrowDownwardIcon} from '../../Assets/icons/sortdes.svg';
import { SortIconStyle } from '../../Utils/utils';

const StyledTableRow = styled(TableRow)({
    cursor:'pointer',
    '&:nth-child(odd)': {
        backgroundColor: '#FAFBFF',

    },
    '&:nth-child(even)': {
        backgroundColor: '#FFFFFF'
    }
})
const StyledTableCell = styled(TableCell)({
    color: '#0F2C6F',
    fontSize: '13px',
    fontWeight:550,
})
const ServiceRequestList = ({ sorting,tableData, pageCount, fetchData, tab_id,isDraft,updateWatch,updateWatchStatus,currentPage,isArchived }) => {
    const [checked, setChecked] = useState(false)
    const [allChecked, setAllChecked] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [servicedata, setServicedata] = useState([])
    const [selectedId, setSelectedId] = React.useState();
    const [sortStatus,setSortStatus] = useState({date:true,ticket:true,status:true,lastUpdate:true,reportedBy:true})

    const navigate = useNavigate();

    useEffect(() => {
        setServicedata(tableData)
    }, [tableData])
    
    const handleAllChecked = () => {
        setAllChecked(!allChecked)
        let data = tableData;
        for (let i = 0; i < data.length; i++) {
            data[i].service_requests.is_selected = data[i].service_requests.is_selected===0?1:0;
           
        }
        setServicedata([...data])
    }

    const handleChecked = (id) => {
        let checkdata = tableData;
        for (let i = 0; i < checkdata.length; i++) {

            if (checkdata[i].service_requests.service_requests_id == id) {
                checkdata[i].service_requests.is_selected = checkdata[i].service_requests.is_selected===0?1:0;
            }
        }
        setServicedata([...checkdata])
    }


    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleNavigation = (serviceReqData) => {
        if(isDraft==="true" ){
            navigate(`/service_request/2/${serviceReqData.service_request_types_id}/${serviceReqData.service_requests_id}`)
        }else {
            navigate(`/review_service_request/${serviceReqData.service_requests_id}/${serviceReqData.service_request_types_id}/${tab_id}/false`)
        }
    }
    const handlePagination = (event, value) => {
        fetchData(value)
    }
    const handleWatchUnWatch =(id,status)=>{
        let data = {
            service_requests_id:id,
            watch_status:status
        }
        updateWatch(data)
    }

    
    const handleSortStatus = (name,column_name,currentStatus)=>{
        setSortStatus({
            date:name=='date' ? !sortStatus.date : true,
            ticket:name=='ticket' ? !sortStatus.ticket : true,
            status:name=='status' ? !sortStatus.status : true,
            lastUpdate:name=='lastUpdate' ? !sortStatus.lastUpdate : true,
            reportedBy:name=='reportedBy' ? !sortStatus.reportedBy : true
        })
        sorting({column_name,type:currentStatus ? 'DESC' : 'ASC'})
    }
    return (
        <>
          
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                {/* <TableCell><Checkbox icon={<CheckboxIcon/>} size="small"  onClick={handleAllChecked} checked={allChecked}></Checkbox></TableCell> */}
                                <TableCell></TableCell>
                                {tab_id!=2 &&  <StyledTableCell onClick={()=>handleSortStatus('ticket','ticket_id',sortStatus.ticket)}>Ticket ID
                                    {sortStatus.ticket ? <ArrowUpwardIcon style={SortIconStyle} />  : <ArrowDownwardIcon style={SortIconStyle}/> }
                                </StyledTableCell>}
                                <StyledTableCell  onClick={()=>handleSortStatus('date','created_at',sortStatus.date)}>Date Requested
                                    {sortStatus.date ? <ArrowUpwardIcon style={SortIconStyle} />  : <ArrowDownwardIcon style={SortIconStyle}/> }
                                </StyledTableCell>
                                <StyledTableCell>Request Type</StyledTableCell>
                                <StyledTableCell onClick={()=>handleSortStatus('reportedBy','u.name',sortStatus.reportedBy)}>Requested By
                                    {sortStatus.reportedBy ? <ArrowUpwardIcon style={SortIconStyle} />  : <ArrowDownwardIcon style={SortIconStyle}/> }
                                </StyledTableCell>
                                <StyledTableCell onClick={()=>handleSortStatus('status','status',sortStatus.status)}>Status
                                    {sortStatus.status ? <ArrowUpwardIcon style={SortIconStyle} />  : <ArrowDownwardIcon style={SortIconStyle}/> }
                                </StyledTableCell>
                                <StyledTableCell  onClick={()=>handleSortStatus('lastUpdate','updated_at',sortStatus.lastUpdate)}>Last Updated
                                    {sortStatus.lastUpdate ? <ArrowUpwardIcon style={SortIconStyle} />  : <ArrowDownwardIcon style={SortIconStyle}/> }
                                </StyledTableCell>
                                {/* <StyledTableCell>Actions</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        {servicedata?.length> 0 && 
                        <TableBody>
                            {servicedata?.map((value, index) => {
                                return (
                                    <StyledTableRow key={index} sx={{ height: '56px' }} >
                                        {/* <TableCell><Checkbox icon={<CheckboxIcon/>} size="small" checked={value.service_requests.is_selected===0?false:true} onClick={() => handleChecked(value.service_requests.service_requests_id)}></Checkbox></TableCell> */}
                                        <TableCell>
                                        {(!isDraft&&!isArchived) &&   <>
                                                { value.service_requests.watch_status==null || value.service_requests.watch_status=="no" ? 
                                                    <BellUnWatch onClick={()=>handleWatchUnWatch(value.service_requests.service_requests_id,"yes")} />
                                                : 
                                                <BellWatch onClick={()=>handleWatchUnWatch(value.service_requests.service_requests_id,"no")} />
                                                }   
                                            </>
                                            }    
                                         </TableCell>
                                        {tab_id!=2 && <TableCell sx={{ color: ' #2764F4', fontSize: '14px', fontWeight:500 }}
                                            onClick={() => handleNavigation(value.service_requests)}>{value.service_requests.ticket_id}</TableCell>
                                        }    
                                        <TableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight:500 }}
                                            onClick={() => handleNavigation(value.service_requests)}>{getTimeStamp(value.service_requests.created_at)}</TableCell>
                                        <TableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight:500 }}
                                            onClick={() => handleNavigation(value.service_requests)}>{value.service_requests.display_name}</TableCell>
                                        <TableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight:500 }}
                                            onClick={() => handleNavigation(value.service_requests)}>{value.service_requests.name}</TableCell>
                                        <TableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight:500 }}
                                            onClick={() => handleNavigation(value.service_requests)}>
                                                <center>

                                            <Typography
                                                sx={{
                                                    background: getStatus(value.service_requests.status, 'bgcolor'),
                                                    display: "inline-flex",
                                                    padding: { md: "0 8px", xs: "2px 6px" },
                                                    fontWeight: 400,
                                                    fontSize: { md: "14px", xs: "10px" },
                                                    color: getStatus(value.service_requests.status, 'color'),
                                                    borderRadius: "6px",
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {getStatus(value.service_requests.status, 'text')}
                                            </Typography>
                                                </center>
                                        </TableCell>
                                        <TableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight:500 }}
                                            onClick={() => handleNavigation(value.service_requests)}>{getTimeStamp(value.service_requests.updated_at)}</TableCell>
                                        {/* <TableCell sx={{ color: '#000000', fontWeigth: '800', fontSize: '20px',textAlign:'center' }} >
                                            <Box key={value.service_requests.service_requests_id}>
                                                <MoreHorizIcon
                                                    id="basic-button"
                                                    aria-controls={open ? "basic-menu" : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? "true" : undefined}
                                                    onClick={(e) => handleClick(e, value)}
                                                // onClick={() => {
                                                //   setMenuOpen(row.id);
                                                // }}
                                                />
                                            </Box>
                                        </TableCell> */}
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody> 
                    }
                    </Table>
                    {servicedata?.length < 1 && 
                        <Box sx={{ display: 'flex', justifyContent: 'center', m: 5 }}>
                            <Typography sx={{color:'#0F2C6F'}} variant='h6'>No Data Found</Typography>
                        </Box>
                    }
                    {pageCount > 0 && <Pagination onChange={handlePagination} sx={{ display: 'flex', justifyContent: 'center', my: 4 }} count={Math.ceil(pageCount / 10)} page={currentPage} size="large" shape="rounded" />}
                </TableContainer> 

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem sx={{ display: "flex", gap: "8px" }}>
                    <EditIcon /> Edit
                </MenuItem>
                <MenuItem sx={{ display: 'flex', gap: '8px' }}>
                    <ViewDetails />View Details
                </MenuItem>
                <MenuItem sx={{ display: "flex", gap: "8px" }} >
                    <DeleteIcon /> Delete
                </MenuItem>
            </Menu>

        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        updateWatchStatus:state.Service_Req_Reducer.updateWatchStatus
    }   
}

const mapDispatchToProps = (dispatch)=>{
    return{
        updateWatch: (data) => (dispatch(serviceRequestAction.updateWatchStatus(data))),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ServiceRequestList);