import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import { ReactComponent as PlusIcon } from "../../Assets/icons/RoundedPlusIcon.svg";
import { ReactComponent as DownloadIcon } from "../../Assets/icons/DownloadIcon.svg";
import { ReactComponent as PencilIconSlanted } from "../../Assets/icons/PencilIconSlanted.svg";
import { ReactComponent as PdfIcon } from "../../Assets/icons/PdfSmallIcon.svg";
import { ReactComponent as ExcelFormatIcon } from "../../Assets/icons/Excel.svg";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/styles";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDownloadableServiceRequestFormsNullAction,
  getDownloadableServiceRequestFormsAction,
  AddServiceRequestNullAction,
  downloadServiceRequestFormsAction,
} from "../../redux/actions/service_request_forms_action";
import ToastAlert from "../common/Alert";
import ApiService from "../../AxiosConfig/ApiService";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DownloadableServiceRequest = ({
  getServiceRequestFormsDownload,
  getServiceFormsData,
  getServiceRequestFormsNull,
  addFormsData,
  addRequestFormsNull,
  downloadServiceRequestForms,
}) => {
  const navigate = useNavigate();
  const [serviceRequestFormsList, setServiceRequestFormsList] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });

  const [toastConfig, setToastConfig] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });

  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    getServiceRequestFormsDownload();
    setServiceRequestFormsList(getServiceFormsData?.response?.data_array);
    if (addFormsData.response?.response_status === 1) {
      setUploadStatus({
        message: "File Uploaded Successfully",
        status: true,
        color: "green",
        icon: "tick",
      });
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
      }, 5000);
      addRequestFormsNull();
    }
    setToastConfig({});
    return () => {
      getServiceRequestFormsNull();
      setToastConfig({});
    };
  }, []);

  useEffect(() => {
    if (getServiceFormsData.response?.response_status === 1) {
      setServiceRequestFormsList(getServiceFormsData?.response?.data_array);
    }
  }, [getServiceFormsData]);

  const handleNavigate = (id) => {
    navigate(`/service_request_forms/editfile/${id}`);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <ToastAlert
        message={toastConfig?.message}
        status={toastConfig?.status}
        color={toastConfig?.color}
        icon={toastConfig?.icon}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "18px", fontWeight: 500, color: "#0F2C6F" }}
        >
          Downloadable Services Request Forms
        </Typography>
        <Button
          variant="outlined"
          sx={{
            color: "#123FA9",
            textTransform: "none",
            display: "flex",
            alignItems: "center",
            gap: 1,
            height: "40px",
            fontWeight: 600,
          }}
          onClick={() => navigate("/service_request_forms/add_file")}
        >
          Upload <PlusIcon />
        </Button>
      </Box>

      <TableContainer sx={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              {["Title", "File Size", "Uploaded By", "Last Updated"].map(
                (item, index) => (
                  <TableCell
                    key={index}
                    sx={{ fontSize: "12px", fontWeight: 500, color: "#0F2C6F" }}
                  >
                    {item}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {serviceRequestFormsList?.map((item) => (
              <StyledTableRow key={item.id}>
                <StyledTableCell>
                  <Box
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#0F2C6F",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                  >
                    {item?.file_type === "xlsx" && (
                      <ExcelFormatIcon style={{ width: 30, height: 45 }} />
                    )}
                    {item?.file_type === "pdf" && (
                      <PdfIcon style={{ width: 30, height: 45 }} />
                    )}
                    {/* <PdfIcon /> */}
                    {item?.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#6F80A9" }}>
                  {item?.file_size}
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#0F2C6F" }}>
                  {item?.user_name}
                </StyledTableCell>
                <StyledTableCell sx={{ color: "#0F2C6F" }}>
                  {moment(item?.moment).format("DD/MM/YY - hh:mm")}
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <IconButton
                      sx={{
                        p: "10px",
                        border: "1px solid #AAC3FF",
                        borderRadius: "6px",
                        display: "grid",
                        placeItems: "center",
                      }}
                      onClick={() => {
                        saveAs(
                          `${process.env.REACT_APP_ASSETS_PATH}/${item?.path}`,
                          `${item?.name}`
                        );
                      }}
                    >
                      <DownloadIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                    <Box
                      sx={{
                        p: "10px",
                        border: "1px solid #AAC3FF",
                        borderRadius: "6px",
                        display: "grid",
                        placeItems: "center",
                        background: "#123FA9",
                        cursor: "pointer",
                      }}
                      onClick={() => handleNavigate(item.id)}
                    >
                      <PencilIconSlanted style={{ height: 20, width: 20 }} />
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {successModal && (
        <ToastAlert
          message={uploadStatus.message}
          status={uploadStatus.status}
          color={uploadStatus.color}
          icon={uploadStatus.icon}
        />
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceRequestFormsDownload: () => {
      dispatch(getDownloadableServiceRequestFormsAction());
    },
    getServiceRequestFormsNull: () => {
      dispatch(getDownloadableServiceRequestFormsNullAction());
    },
    addRequestFormsNull: () => {
      dispatch(AddServiceRequestNullAction());
    },
    downloadServiceRequestForms: (id) =>
      dispatch(downloadServiceRequestFormsAction(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    getServiceFormsData:
      state.ServiceRequestFormsReducer.getServiceRequestFormsData,
    addFormsData: state.ServiceRequestFormsReducer.addServiceRequestData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadableServiceRequest);
