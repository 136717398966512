import React from 'react'

const DynamicExcelTable = ({header, data}) => {
    const tableClass = {
        border: "0.2px solid black",
        borderCollapse: "collapse",
        fontSize: "13px",
        minWidth: "100%",
        textAlign: "center"
      };
    
      return (
        <table style={tableClass}>
          <thead>
            <tr>
                {header.map((item, index) => 
              <th key={index} style={tableClass}>{item}</th>
                    )}
            </tr>
          </thead>
    
          <tbody>
            

              {data.map((item, index) =><tr key={index}>   {item.map((item,index) =>                  <td key={index} style={tableClass}>{item}</td> )}

</tr>
              )}
          </tbody>
        </table>
      );
}

export default DynamicExcelTable