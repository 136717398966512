import { handleActions } from "redux-actions";

const reportData = {
    accessHistory:[],
    auditLogHistory: [],
    accessFilter:[],
    empData:[],
    empReportFilterData:[],
    employeeList:[],
    faultDatas:[],
    faultReportFilterData:[],
    serviceDatas:[],
    lightDatas:[],
    airConDatas:[],
    companyDatas:[]
}

const reducer = handleActions({
    GET_ACCESS_HISTORY_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ACCESS_HISTORY_SUCCESS:(state,action)=>{
        return{
            ...state,
            accessHistory:action.payload
        }
    },
    GET_ACCESS_HISTORY_FAILURE:(state,action)=>{
        return{
            ...state,
            accessHistory:action.payload
        }
    },

    GET_ALL_AUDIT_LOG_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_ALL_AUDIT_LOG_SUCCESS:(state,action)=>{
        return{
            ...state,
            auditLogHistory:action.payload
        }
    },
    GET_ALL_AUDIT_LOG_FAILURE:(state,action)=>{
        return{
            ...state,
            auditLogHistory:action.payload
        }
    },

    GET_ACCESS_FILTER_INITIATE:(state,action)=>{
        return{
            ...state
        }
    },
    GET_ACCESS_FILTER_SUCCESS:(state,action)=>{
        return{
            ...state,
            accessFilter:action.payload
        }
    },
    GET_ACCESS_FILTER_FAILURE:(state,action)=>{
        return{
            ...state,
            accessFilter:action.payload
        }
    },
    GETEMP_BY_COMPANYID_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GETEMP_BY_COMPANYID_SUCCESS:(state,action)=>{
        return{
            ...state,
            empData:action.payload
        }
    },
    GETEMP_BY_COMPANYID_FAILURE:(state,action)=>{
        return{
            ...state,
            empData:action.payload
        }
    },
    GET_EMPREPORT_FILTERDATA_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_EMPREPORT_FILTERDATA_SUCCESS:(state,action)=>{
        return{
            ...state,
            empReportFilterData:action.payload
        }
    },
    GET_EMPREPORT_FILTERDATA_FAILURE:(state,action)=>{
        return{
            ...state,
            empReportFilterData:[]
        }
    },
    GET_EMPLOYEE_REPORT_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_EMPLOYEE_REPORT_SUCCESS:(state,action)=>{
        return{
            ...state,
            employeeList:action.payload
        }
    },
    GET_EMPLOYEE_REPORT_FAILURE:(state,action)=>{
        return{
            ...state,
            employeeList:[]
        }
    },
    GET_FAULTREPORTS_FILTERDATA_INITIATE:(state)=>{
        return{
            ...state,
        }
    },
    GET_FAULTREPORTS_FILTERDATA_SUCCESS:(state,action)=>{
        return{
            ...state,
            faultReportFilterData:action.payload
        }
    },
    GET_FAULTREPORTS_FILTERDATA_FAILURE:(state)=>{
        return{
            ...state,
            faultReportFilterData:[]
        }
    },

    GET_REPORTS_FAULT_REPORTS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_REPORTS_FAULT_REPORTS_SUCCESS:(state,action)=>{
        return{
            ...state,
            faultDatas:action.payload
        }
    },
    GET_REPORTS_FAULT_REPORTS_FAILURE:(state,action)=>{
        return{
            ...state,
            faultDatas:action.payload
        }
    },
    GET_SERVICE_REPORTS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_SERVICE_REPORTS_SUCCESS:(state,action)=>{
        return{
            ...state,
            serviceDatas:action.payload
        }
    },
    GET_SERVICE_REPORTS_FAILURE:(state,action)=>{
        return{
            ...state,
            serviceDatas:action.payload
        }
    },
    GET_LIGHT_REPORTS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_LIGHT_REPORTS_SUCCESS:(state,action)=>{
        return{
            ...state,
            lightDatas:action.payload
        }
    },
    GET_LIGHT_REPORTS_FAILURE:(state,action)=>{
        return{
            ...state,
            lightDatas:action.payload
        }
    },
    GET_AIRCON_REPORTS_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_AIRCON_REPORTS_SUCCESS:(state,action)=>{
        return{
            ...state,
            airConDatas:action.payload
        }
    },
    GET_AIRCON_REPORTS_FAILURE:(state,action)=>{
        return{
            ...state,
            airConDatas:action.payload
        }
    },
    GET_COMPANY_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_COMPANY_SUCCESS:(state,action)=>{
        return{
            ...state,
            companyDatas:action.payload
        }
    },
    GET_COMPANY_FAILURE:(state,action)=>{
        return{
            ...state,
            companyDatas:action.payload
        }
    }
},reportData)

export default reducer;