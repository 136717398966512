import React from "react";
import { useNavigate } from "react-router";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import OnboardingCompanyTabs from "./OnboardingCompanyTabs";

const OnboardingNewCompany = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      sx={{ paddingTop: { md: "60px", xs: "20px" }, background: "#F9FAFA" }}
    >
      <Grid item lg={2} md={1} sm={1} xs={0.5} />
      <Grid
        item
        lg={8}
        md={10}
        sm={10}
        xs={11}
        sx={{ display: "flex", flexDirection: "column", color: "#0F2C6F" }}
      >
        <BackButton
          cursor="pointer"
          // style={{
          //   background: "#F3F4F8",
          //   padding: "12px",
          //   borderRadius: "6px",
          // }}
          onClick={() => navigate(-1)}
        />
        <Typography
          variant="p"
          sx={{
            margin: "30px 0",
            fontSize: { md: "26px", sm: "18px", xs: "16px" },
          }}
        >
          Onboard New Tenant Company
        </Typography>

        {/* Tab Section */}
        <OnboardingCompanyTabs />
      </Grid>
      <Grid item lg={2} md={1} sm={1} xs={0.5} />
    </Grid>
  );
};

export default OnboardingNewCompany;
