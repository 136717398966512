import React, { useState, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import cloneDeep from 'lodash/cloneDeep';
import {
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Badge,
  Paper,
  Toolbar,
  Box,
  SwipeableDrawer,
  Tooltip,
  MenuItem,
  Menu,
  Typography,
  Icon,
  MenuList,
  Button,
  Autocomplete,
  TextField,
  OutlinedInput,
} from "@mui/material";
import { ReactComponent as Logo } from "../Assets/icons/Logo.svg";
import { ReactComponent as AppBarSearch } from "../Assets/icons/AppBarSearch.svg";
import { ReactComponent as NotificationsIcon } from "../Assets/icons/notifybell.svg";
import { ReactComponent as ProfileBG } from "../Assets/icons/ProfileBG.svg";
import { ReactComponent as CloseIcon } from "../Assets/icons/Close.svg";
import { APPBAR_ACCOUNTS, APPBAR_OPTIONS, notifiCationData } from "../components/Data/Data";
import InputBase from "@mui/material/InputBase";
import { useNavigate, Link, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../Assets/icons/MenuIcon.svg";
import * as DashboardAction from "../redux/actions/Dashboard Action/index"
import { connect } from "react-redux";
import * as useraction from "../redux/actions/useraction";
import * as Commonaction from "../redux/actions/commonAction";
import { getIcons, getLoggedUserData, getNavLinks, sideNavSelectedIcons } from "../Utils/utils";
import { SUPER_ADMIN_MENUS } from "../components/Data/Data";
import ToastAlert from "../Pages/common/Alert";
import { NotificationsTwoTone } from "@mui/icons-material";
import GlobalFilter from "./GlobalFilter";
import axios from "axios";
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import moment from 'moment';

const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    padding: "10px",
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
    borderRadius: "8px",
  },
}
const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    borderRight: "none !important",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const StyledLink = styled(Link)(() => ({
  fontSize: "12px",
  color: "#6F80A9",
  textDecoration: "none",
}));
const mdTheme = createTheme();
const Header = ({ children, userMenus, getUserMenus, sessionExpired, unreadCount,getNotificationMsg,notification,isNotificationReceived,updateNotificationViewStatus,getGlobalFilter,filteredData,sendReminderStatus,setStatusNull,getUserMenuNull}) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [mobileDrawer, setMobileDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const [notifyAnchorEl, setNotifyAnchorEl] = React.useState(null);
  const notifyMenuOpen = Boolean(notifyAnchorEl);
  const [userSideNavData, setSideNavData] = useState([]);
  const [userData, setUserData] = useState({});
  const [searchInput, setSearchInput] = useState({ searchStatus: false, searchData: '' });
  const [updateStatus, setUpdateStatus] = useState({ message: '', status: false, color: '', icon: '' })
  const [activeStatus,setActiveStatus] = useState()
  const [globalFilterData,setGlobalFilterData] = useState('')
  const [options,setOptions] = useState([])
  // const [count, setCount] = useState(() => {
  //   // Initialize count from localStorage or default to 1
  //   const storedCount = localStorage.getItem('count');
  //   return storedCount ? parseInt(storedCount, 10) : 1;
  // });



  useEffect(()=>{
    let timeout;
    let str = globalFilterData.split(' ')
    if(str[1]=='fault_report' || str[1]=='service_request'){
      if(str[1]=='fault_report'){
        navigate(`/review_fault/${str[0]}/0/false`)
      }else if(str[1]=='service_request'){
        navigate(`/review_service_request/${str[0]}/${str[2]}/0/false`)
      }
    }else{
      timeout = setTimeout(()=>{
        getGlobalFilter({
          searchData:globalFilterData,
          role:getLoggedUserData().role
        })
      },800)
    }
      
    return()=>{
        clearTimeout(timeout)
    }
  },[globalFilterData])

  useEffect(()=>{
    // let arr = new Array().fill(filteredData);
    let arr = cloneDeep(filteredData);
      for(let i=0;i<arr.length;i++){
          arr[i].label=`${arr[i].reference_id} ${arr[i].module_name} ${arr[i].service_request_types_id} ${arr[i].ticket_no} ${arr[i].title} ${arr[i].company_name}`
      }
      setOptions([...arr])
   },[filteredData])

  useEffect(()=>{
    getNotificationMsg()
  },[location,isNotificationReceived])


  // useEffect(() => {

  //   const intervalId = setInterval(() => {
  //     console.log('clear ', count);
     
  //     setCount(prevCount => {
  //       const newCount = prevCount + 1;
  //       localStorage.setItem('count', newCount); // Store count in localStorage
  //       return newCount;
  //     });
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId); // Cleanup function to clear the interval
  //   };
  // }, []);
  useEffect(() => {
    renderAutocomplete()
    setGlobalFilterData('')
    setOptions([])
    let menuData = [];

    if(location.pathname=="/helpAndSupport" || location.pathname=="/feedback" || location.pathname=="/reportbug"){
      makeNavbarInactive()
    }else{
      if (userSideNavData?.length > 0) {
        userSideNavData?.forEach((element,index) => {
          let menuObj = {};
          menuObj["id"] = element.id;
          menuObj["isActive"] = ( element.location === location.pathname || (activeStatus[index] )) ? true : false;
          menuObj["value"] = element.value;
          menuObj["label"] = element.label;
          menuObj["icon"] = element?.icon;
          menuObj["location"] = element.location;
          menuData.push(menuObj);
          if (element.location === location.pathname) {
            sessionStorage.setItem("currentPath", location.pathname)
          }
        });
        setSideNavData(menuData);
      }
    }
  }, [location,userMenus])

  useEffect(() => {
    let userLoginSatus = localStorage.getItem("userToken");
    if (userLoginSatus !== null) {
      let responseUserData = JSON.parse(localStorage.getItem("userData"));
      let userNameAcronym = responseUserData?.user_name.match(/\b(\w)/g);
      responseUserData["userNameAcronym"] = userNameAcronym.join("");
      setUserData(responseUserData);
      if (responseUserData?.role === "super_admin") {
        setSideNavData(SUPER_ADMIN_MENUS);
      } else {
        getUserMenus(responseUserData?.role_id, userLoginSatus);
      }
    } else {
      navigate("/");
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {

    if (sessionExpired) {
      console.log('sessionExpired ')
      setUpdateStatus({ message: 'Session Expired', status: true, color: 'red', icon: 'close' })
      setTimeout(() => {
        localStorage.clear();
        sessionStorage.clear();
      }, 2000);
      setTimeout(() => {
        navigate("/");
        window.location.reload()
      }, 4000);
    }
  }, [sessionExpired])

  useEffect(()=>{
      if(sendReminderStatus?.response_status==1){
          setUpdateStatus({ message: sendReminderStatus?.message, status: true, color: 'green', icon: 'close' })
          setTimeout(()=>{
            setUpdateStatus({ message: '', status: false, color: '', icon: '' })
            setStatusNull()
          },3000)
        }else if(sendReminderStatus?.response_status==0){
          setUpdateStatus({ message: sendReminderStatus?.message, status: true, color: 'red', icon: 'close' })
          setTimeout(()=>{
            setUpdateStatus({ message: '', status: false, color: '', icon: '' })
            setStatusNull()
          },3000)
      }
  },[sendReminderStatus])

  useEffect(() => {
    if (userMenus?.length > 0) {
      let menuData = [];
      let currParent = sessionStorage.getItem('currentPath');
      userMenus?.forEach((element) => {
                let menuObj = {};
        menuObj["id"] = element.menu_id;
        menuObj["isActive"] = (getNavLinks(element.menu_name) === currParent) || currParent === null && element.menu_id === 1 ? true : false;
        menuObj["value"] = element.menu_name;
        menuObj["label"] = element.menu_name;
        // menuObj["icon"] = getIcons(element.menu_name);
        menuObj["icon"] = (getNavLinks(element.menu_name) === currParent) || currParent === null && element.menu_id === 1 ? sideNavSelectedIcons[element.menu_name]?.selectedIcon : sideNavSelectedIcons[element.menu_name]?.icon;
        menuObj["location"] = getNavLinks(element.menu_name);
        menuData.push(menuObj);
      });
      setSideNavData(menuData);
     
      let arr = []
      for(let i =0;i<userMenus?.length;i++){
        arr.push(false)
      }
      setActiveStatus(arr)
    }
  }, [userMenus]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setNotifyAnchorEl(null)
  };

  const handleNotifyMenuClick = (event) => {
    setNotifyAnchorEl(event.currentTarget);
  }
  const toggleDrawer = () => {
    setOpen((prevValue) => !prevValue);
  };

  const toggleMobileDrawer = () => {
    setMobileDrawer((prevValue) => !prevValue);
    handleWebDrawer();
  };
 
  const handleWebDrawer = (menuId,fromIndex) => {
    
    sessionStorage.removeItem("filterData")
    sessionStorage.setItem("currentPath", menuId)
    let menuData = [];
    userSideNavData?.forEach((element,index) => {
       let menuObj = {};
      menuObj["id"] = element.id;
      menuObj["isActive"] = element.location === menuId  ? true : false;
      menuObj["value"] = element.value;
      menuObj["label"] = element.label;
      // menuObj["icon"] = element.icon;
      menuObj["icon"] = element.location === menuId ? sideNavSelectedIcons[element.label]?.selectedIcon : sideNavSelectedIcons[element.label]?.icon;
      menuObj["location"] = element.location;
      menuData.push(menuObj);
    });
    setSideNavData(menuData);
    
    let status = activeStatus
    for(let i=0;i<status.length;i++){
      if(fromIndex==i){
        status[i]=true
      }else{
        status[i]=false
      }
    }
    setActiveStatus(status)
  }

  const makeNavbarInactive = ()=>{
    let menuData = []
    userSideNavData?.forEach((element,index) => {
      let menuObj = {};
      menuObj["id"] = element.id;
      menuObj["isActive"] =  false;
      menuObj["value"] = element.value;
      menuObj["label"] = element.label;
      // menuObj["icon"] = element.icon;
      menuObj["icon"] = sideNavSelectedIcons[element.label]?.icon;
      menuObj["location"] = element.location;
      menuData.push(menuObj);
    });
    setSideNavData(menuData);
  }

  const handleAppBaroptions = (action) => {
    if (action === "Logout") {
      let token = localStorage.getItem('userToken')
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let data={
        mode:'website' 
      }
      axios.get(`${process.env.REACT_APP_API_URL}/Logout?mode=website`,config)
        .then((response) => {
          if(response.data.response_status==='success'){
            localStorage.clear();
            sessionStorage.clear();
            getUserMenuNull();            
            setTimeout(() => {
              navigate("/");
            }, 2000);
          }
        })
        .catch((error) => {
          // console.log(error.response);
        })
    }
  };

  const searchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    let newSearchData = searchInput.searchStatus ? '' : searchInput.searchData;
    setSearchInput({ ...searchInput, ['searchStatus']: !searchInput.searchStatus, ['searchData']: newSearchData })

  };

  const renderNotificationIcon = () => {
    return <NotificationsTwoTone />

  }

 
  const renderAutocomplete = ()=>{
    return(
      <>
          <Autocomplete
                  id="globalFilter"
                  clearOnEscape={true}
                  sx={{backgroundColor:'#F3F4F8',borderRadius:'8px',ml:35,border:'#F3F4F8',color:'#9AA6C3'}}
                  options={options}
                  freeSolo
                  autoHighlight
                  inputValue={globalFilterData !='undefined' ? globalFilterData : ''}
                  onChange={(e,value)=>{
                    if(typeof value == 'object') {
                      setGlobalFilterData(value.label)
                    }
                      }} 
                  onInputChange={(e,value)=>{
                    if(value != undefined) {
                      setGlobalFilterData(value)
                    }
                        
                        }} 
                  size="small" fullWidth
                  getOptionLabel={(option) => option.label}
                  popupIcon={<CloseIcon style={{fontSize:'24px'}}/>}
                  renderOption={(props, option) => (
                    <Box  sx={{ display:'flex', flexDirection:'column',alignItems:'flex-start',justifyContent:'flex-start',borderBottom:'1px solid #9AA6C3'  }} {...props}>
                      <Box >
                        <Typography textAlign={"start"} sx={{color:'#0F2C6F',fontWeight:550}}>{option.title}</Typography>
                      </Box>
                      <Box sx={{display:"flex",justifyContent:'space-between'}}>
                        <Typography sx={{color:'#0F2C6F'}}>({option.ticket_no})</Typography>
                        <Typography sx={{color:'#0F2C6F',ml:2}}>{option.company_name}</Typography>
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                    />
                  )}
              />
      </>
    )
  }
  return (
    <ThemeProvider theme={mdTheme}>
      <ToastAlert message={updateStatus?.message} status={updateStatus?.status} color={updateStatus?.color} icon={updateStatus?.icon} />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          position: "fixed",
        }}
      >
        <AppBar
          open={open}
          sx={{
            zIndex: 2,
            width: "100%",
            "& .css-1xc1a5f-MuiToolbar-root": {
              padding: { md: "0 24px", xs: "0 10px" },
            },
            boxShadow: "0px 5px 15px rgba(18, 63, 169, 0.2)",
            border: "none",
            position: "absolute",
          }}
        >
          <Toolbar
            sx={{
              backgroundColor: "#fff !important",
            }}
          >
            <Box
              onClick={toggleDrawer}
              sx={{ display: { md: "block", xs: "none" }, cursor: "pointer" }}
            >
              <MenuIcon />
            </Box>

            <Box
              sx={{
                alignItems: "center",
                margin: "0 5px",
                display: { md: "none", xs: "block" },
                cursor: "pointer",
              }}
            >
              <MenuIcon
                sx={{ color: "#9AA6C3" }}
                onClick={toggleMobileDrawer}
              />
            </Box>

            <Box
              onClick={() => {
                navigate("/dashboard");
                handleWebDrawer(1)
              }}
              sx={{
                display: { md: "block", xs: "none" },
                marginLeft: "30px",
                "& :hover": {
                  boxShadow: "0 0 11px rgba(33,33,33,.2)",
                },
                cursor: "pointer",
              }}
            >
              <Logo />
            </Box>

              {getLoggedUserData()?.role_id!=13 &&  renderAutocomplete()
              }

              <Box sx={{ display: "flex",justifyContent:"flex-end",width:"100%",gap: "32px" }}>
              {getLoggedUserData()?.role_id!=13 && 
                <IconButton
                  onClick={handleNotifyMenuClick}
                  color="inherit"
                  sx={{ display: { md: "flex", xs: "none" },  }}
                 >
                  {notification?.unreadNotificationMessageCount > 0 ? <Badge 
                  anchorOrigin={{
                      vertical:'top',
                      horizontal:'right'
                  }}  variant='dot' color="error">
                    <NotificationsIcon style={{marginTop:'1px'}} />
                  </Badge> : <NotificationsIcon  />}
                </IconButton> 
              }

              <Box
                sx={{
                  display: "flex",
                  color: "#0F2C6F",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
                onClick={handleMenuClick}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ProfileBG />
                  <Tooltip title="Account settings">
                    <Typography
                      sx={{
                        color: "white",
                        position: "absolute",
                        fontSize: "14px",
                        textTransform: "uppercase"
                      }}
                    >
                      {userData?.userNameAcronym}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    display: { md: "inline-flex", xs: "none" },
                    flexDirection: "column",
                    width: "110px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#0F2C6F",
                      textAlign: "center",
                    }}
                  >
                    {userData?.user_name}
                  </Typography>
                  <Typography sx={{display:'none'}}>{'01.06.2023'}</Typography>
                  <Typography
                    sx={{
                      fontSize: "9px",
                      color: "#0F2C6F",
                    }}
                  >
                    ({userData?.role})
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          sx={{
            height: window.innerHeight,
            display: { md: "flex", xs: "none" },
            zIndex: 0,
            background: "white",
          }}
          PaperProps={{
            sx: {
              '::-webkit-scrollbar': {
                width: '7px',
                },
                ' ::-webkit-scrollbar-thumb' :{
                  borderRadius: '10px',
                  background: '#999',
                  },
                  
                  '::-webkit-scrollbar-thumb:hover' : {
                  background: '#999'  
                  }
            }
            
          //   sx: {
          // '::-webkit-scrollbar': { display: 'none' },
          // '-ms-overflow-style': 'none', 
          // 'scrollbarWidth': 'none',
          // width: '250px', 
          // overflow: 'auto',
          //   }
          }}
        >
          <Box sx={{ paddingTop: "125px" }} />
          {userSideNavData &&
            userSideNavData?.map((menus,index) => (
              <NavLink
                key={menus.id}
                end
                to={menus.location}
                style={({ isActive }) => ({
                  color: menus.isActive ? "#173C92" : "#545e6f",
                  fill: menus.isActive ? "#173C92" : "",
                  background: menus.isActive ? "#F3F4F8" : "",
                  margin: "0 6px",
                  borderRadius: "8px",
                  textDecoration: "none",
                })}
                onClick={() => handleWebDrawer(menus.location,index)}
              >
                <List
                  component="nav"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "12px 10px",
                    gap: "20px",
                    borderRadius: "inherit",
                  }}
                >
                  {/* <Link to={menus.location}> */}

                  <ListItemIcon sx={{ minWidth: "25px" }}>
                    <Tooltip
                      title={menus.label}
                      placement="right"
                      arrow
                      disableHoverListener={open}
                    >
                      <Icon sx={{ fill: "inherit", display: 'grid', placeItems: "center" }}>{menus?.icon}</Icon>
                    </Tooltip>
                  </ListItemIcon>
                  {!open ? (
                    ""
                  ) : (
                    <ListItemText
                      sx={{ color: "inherit", fontWeight: "inherit" }}
                      primary={menus.value}
                    />
                  )}
                  {/* </Link> */}
                </List>
              </NavLink>
            ))}

          {open && (
            <Box
              sx={{
                display: "flex",
                fontSize: "12px",
                flexDirection: "column",
                padding: "24px",
                mt:2,
                // bottom: "2px",
                // position: "absolute",
                gap: "8px",
              }}
            >
              <StyledLink onClick={makeNavbarInactive} to="/helpAndSupport">Help & support</StyledLink>
              <StyledLink onClick={makeNavbarInactive} to="/feedback">Send feedback & improvements</StyledLink>
              <StyledLink onClick={makeNavbarInactive} to="/reportbug">Report usability issues</StyledLink>
            </Box>
          )}
        </Drawer>
        <Box

          sx={{
            padding: "64px 0 0 0 ",
            width: "100%",
            // height: "100%",
            position: "relative",
            overflowY: "auto",
            overflowX: "hidden",
            // scrollbarWidth:'thin',
            marginTop: 8,
            '::-webkit-scrollbar': {
              width: '7px',
              },
              
              // '::-webkit-scrollbar-track' : {
              // background: '#f5f5f5',
              // borderRadius: '10px'
              // },
              
             ' ::-webkit-scrollbar-thumb' :{
              borderRadius: '10px',
              background: '#999',
              },
              
              '::-webkit-scrollbar-thumb:hover' : {
              background: '#999'  
              }
          }}
          
        >
          {searchInput.searchStatus ? <GlobalFilter searchValue={searchInput} /> : <Box sx={{marginTop:'-64px'}}>{children}</Box>}
        </Box>
      </Box>

      <Box style={{ zIndex: 2 }}>
        <SwipeableDrawer
          open={mobileDrawer}
          onClose={toggleMobileDrawer}
          onOpen={toggleMobileDrawer}
        >
          <Box
            sx={{
              display: "flex",
              margin: "18px 0",
              alignItems: "center",
              paddingLeft: "18px",
              gap: "25px",
            }}
          >
            <MenuIcon onClick={toggleMobileDrawer} cursor="pointer" />
            <Logo
              onClick={() => {
                toggleMobileDrawer();
                navigate("/dashboard");

              }}
              cursor="pointer"
            />
          </Box>
          {userSideNavData &&
            userSideNavData?.map((menus) => (
              <NavLink
                key={menus.id}
                end
                to={menus.location}
                style={({ isActive }) => ({
                  color: menus.isActive ? "#173C92" : "#6F80A9",
                  background: menus.isActive ? "#F3F4F8" : "",
                  margin: "0 6px",
                  borderRadius: "8px",
                  textDecoration: "none",

                })}
                onClick={toggleMobileDrawer}
              >
                <Box
                  component="nav"
                  sx={{
                    display: "flex",
                    margin: "5px",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: "9px 10px ",
                    gap: "25px",
                  }}
                >
                  {menus?.icon}
                  <Typography
                    sx={{
                      fontSize: { sm: "16px", xs: "14px" },
                    }}
                  >
                    {menus?.value}
                  </Typography>
                </Box>
              </NavLink>
            ))}
          {mobileDrawer && (
            <Box
              sx={{
                display: "flex",
                fontSize: "12px",
                flexDirection: "column",
                padding: "24px",
                bottom: "0px",
                position: "absolute",
                gap: "8px",
              }}
            >
              <StyledLink onClick={makeNavbarInactive} to="/helpAndSupport">Help & support</StyledLink>
              <StyledLink onClick={makeNavbarInactive} to="/feedback">Send feedback & improvements</StyledLink>
              <StyledLink onClick={makeNavbarInactive} to="/reportbug">Report usability issues</StyledLink>
            </Box>
          )}
        </SwipeableDrawer>
      </Box>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {APPBAR_OPTIONS.map((item) => (
          <Box key={item.id}>
            <MenuItem
              sx={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }}
              dense
              onClick={() => handleAppBaroptions(item.option)}
            >
              {item?.icon}
              <Typography
                variant="span"
                sx={{
                  fontSize: { md: "14px", xs: "10px" },
                  fontWeight: 600,
                  color: "#0F2C6F",
                }}
              >
                {item?.option} 
              </Typography> 
            </MenuItem>
            {item?.id !== APPBAR_OPTIONS.length && <></>}
          </Box>
        ))}
      </Menu>
      <Menu
        sx={{width:700}}
        anchorEl={notifyAnchorEl}
        id="account-menu"
        open={notifyMenuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuList>
          {notification?.notificationMessageArrayList?.length>5 ? 
              <>
                {notification?.notificationMessageArrayList?.slice(0,5).map((value,index)=>(
              <div key={index}>
              <MenuItem onClick={()=>{
                updateNotificationViewStatus({notification_messages_id:value.notification_messages_id})
                if(value.title=="Service Request"){
                  let data = value?.serviceRequestArrayList[0]
                  navigate(`/review_service_request/${data?.service_requests_id}/${data?.service_request_types_id}/0/true`)
                } else if(value.title == 'Fault Report'){
                  navigate(`/review_fault/${value.reference_id}/0/true`)
                } else if(value.title == 'Amenity Booking') {
                  localStorage.setItem('notification_clicked','yes')
                    navigate(`/facility_booking/${value.reference_id}`)
                }
              }} key={index}>
                <ListItemIcon>
                 {value.view_status=='0' ? <NotificationsNoneTwoToneIcon /> : <NotificationsNoneOutlinedIcon />  }
                </ListItemIcon>
                <ListItemText sx={{overflow:"hidden"}} primary={value.title} secondary={value.message_description} />
              </MenuItem>
              <Divider />
            </div>
          ))
          }
              </> : 

          <Box sx={{display:"flex",justifyContent:'center',width:120,mb:1}}>
            <Typography>No Notifications</Typography>
          </Box>
          }
          
          <Box sx={{display:"flex",justifyContent:'center',width:"100%"}}>
                  <Button onClick={()=>navigate(`/notifications`)} variant="contained" sx={{textTransform:"capitalize",textAlign:"center",mb:-2}}>View All</Button>
          </Box>
          
        </MenuList>

      </Menu>
      
    </ThemeProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    userMenus: state.UserReducer.userMenus,
    unreadCount: state.UserReducer.unreadCount,
    sessionExpired: state.CommonReducer.sessionExpired,
    notification:state.DashboardReducer.notification,
    isNotificationReceived:state.DashboardReducer.isNotificationReceived,
    filteredData:state.DashboardReducer.filteredData,
    sendReminderStatus:state.CommonReducer.sendReminderStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserMenus: (data, token) =>
      dispatch(useraction.getUserMenus(data, token)),
    getNotificationMsg:()=>dispatch(DashboardAction.getNotificationMsg()),
    updateNotificationViewStatus:(data)=>dispatch(DashboardAction.updateNotificationViewStatus(data)),
    getGlobalFilter:(data)=>dispatch(DashboardAction.getGlobalFilter(data)),
    setStatusNull:()=>dispatch(Commonaction.setStatusNull()).APPBAR_ACCOUNTS,
    getUserMenuNull: () => dispatch(useraction.getUserMenuNullAction()),
  };
  
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
