import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { ReactComponent as StructuralFaultBell } from "../../Assets/icons/StructuralFaultBell.svg";
import { ReactComponent as Delete } from "../../Assets/icons/Delete.svg";
import { ReactComponent as AddImage } from "../../Assets/icons/AddImage.svg";
import { ReactComponent as Recent } from "../../Assets/icons/Recent.svg";
import { ReactComponent as Close } from "../../Assets/icons/Close.svg";
import { ReactComponent as LeftArrowSlider } from "../../Assets/icons/LeftArrowSlider.svg";
import { ReactComponent as RightArrowSlider } from "../../Assets/icons/RightArrowSlider.svg";
import { ReactComponent as Download } from "../../Assets/icons/Download.svg";
import { ReactComponent as DropDownArrow } from "../../Assets/icons/DropDownArrow.svg";
import { ReactComponent as Fileupload } from '../../Assets/icons/Fileupload.svg';
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxTerms.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import DropZone from "../../Utils/dropzone";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { connect } from "react-redux";
import * as faultReportAction from "../../redux/actions/faultreportAction";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { ReactComponent as BellWatch } from "../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../Assets/icons/StructuralFaultBell.svg";
import { getLastUpdatedTime } from "../../Utils/utils";
import * as commonAction from '../../redux/actions/commonAction';
import { MenuProps } from "../../Utils/utils";
import *  as ServiceRequestAction from '../../redux/actions/service_request/index'

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Faults = ({ addFaultReport, addFaultReportAsDraft,addFaultStatus, setFaultReportStateNull, getFaultLocations, faultLocations,getAllLevels, levelDatas,getTenantAccessLevel,tenantOccupiedLeves,faultReportData,FaultReportbyId,updateDraftStatus,updateDraft, getFaultCategoryData, getFaultCategoryListData }) => {
  let {isDraft,id} = useParams()
  const navigate = useNavigate();
  const location = useLocation();
  const [officeBlur, setOfficeBlur] = useState(false);
  const [mobileBlur, setMobileBlur] = useState(false);
  const [termsCheck, setTermsCheck] = useState(false);
  const [faultLevels, setFaultLevels] = React.useState([]);
  const [faultDetails, setFaultDetails] = useState({
    personInCharge: "",
    tenantCompany: "",
    unit: "",
    mobileNumber: "",
    officeNumber: "",
    reportTitle: "",
    reportDescription: "",
    level: "",
    location: "",
    officeNumberCode: '',
    mobileNumberCode: '',
    areaDescription: '',
    notification_status: 'disabled',
    category: ""
  });
  const [faultDetailErrors, setFaultDetailsErrors] = useState({
    personInCharge: "",
    tenantCompany: "",
    unit: "",
    mobileNumber: "",
    officeNumber: "",
    reportTitle: "",
    reportDescription: "",
    level: "",
    location: "",
    areaDescription: '',
    commonError:"",
    category: ""
  });
  const [supportImages, setSupportImages] = useState([]);
  const [reportAddStatus, setReportAddStatus] = useState({ message: '', status: false, color: '', icon: '' })
  const [time, setTime] = useState({ hour: '00', minute: '00', am_pm: '', timeStr: '' })
  const [officePremises,setOfficePremises] = useState(false);
  const [loaderStatus,setLoaderStatus] = useState(false)
  const localData = JSON.parse(localStorage.getItem('userData'));
  const [categoryData, setCategoryData] = useState([])
  useEffect(()=>{ 
    if(isDraft=="true"){
      FaultReportbyId(id)
    }
    getFaultCategoryData()
  },[])

  useEffect(() => {
      if(getFaultCategoryListData?.response != 'Failed') {
        const listArray = getFaultCategoryListData?.response?.data_array?.categoryArrayList?.map((item) => {
          return {
            fault_category_name: item.fault_category_name,
            fault_category_id: item.fault_category_id
          }
        })
        setCategoryData(listArray)
      }
  },[getFaultCategoryListData])

  useEffect(()=>{
    let data = Array.isArray(faultReportData?.response_json) && faultReportData?.response_json[0]?.fault_report
    let files = Array.isArray(faultReportData?.response_json) && faultReportData?.response_json[0]?.fault_report_files

    setFaultDetails({
      ...faultDetails,
      reportTitle:data?.fault_title,
      reportDescription:data?.description==null ? '' : data?.description,
      location:data?.area_description==null ? '' : data?.area_description,
      level:data?.level_no==null ? '' : parseInt(data?.level_no)
    })
    if(files?.length>0){
      for(let i=0;i<files.length;i++){
        let obj = {}
        obj.id = files[i].id
        obj.fileName = files[i].original_name
      }
    }
  },[faultReportData])

  useEffect(()=>{
    if(updateDraftStatus?.response_status==1){
      setLoaderStatus(false)
      setReportAddStatus({ message: updateDraftStatus?.message, status: true, color: 'green', icon: 'tick' })
      setTimeout(() => {
        setReportAddStatus({ message: '', status: false, color: '', icon: '' })
        setFaultReportStateNull()
        // navigate('/fault_reporting/0');
        handleRouteNavigate();

      }, 5000);
    }else if(updateDraftStatus?.response_status==0){
      setLoaderStatus(false)
      setReportAddStatus({ message:updateDraftStatus?.message, status: true, color: 'red', icon: 'close' })
      setFaultReportStateNull()
    }
  },[updateDraftStatus])


  useEffect(() => {
    console.log('addFaultStatus ',addFaultStatus)
    if (addFaultStatus?.response_status === 1 || addFaultStatus?.response_status === "success" ) {
      setLoaderStatus(false)
      setReportAddStatus({ message: addFaultStatus?.message ? addFaultStatus?.message :addFaultStatus?.success_message, status: true, color: 'green', icon: 'tick' })
      setTimeout(() => {
        setReportAddStatus({ message: '', status: false, color: '', icon: '' })
        setFaultReportStateNull()
        // navigate('/fault_reporting/0');
        handleRouteNavigate();

      }, 2000);
    } else if (addFaultStatus?.response_status === 0) {
      setLoaderStatus(false)
      setReportAddStatus({ message: addFaultStatus?.message, status: true, color: 'red', icon: 'close' })
      setFaultReportStateNull()
    }
  }, [addFaultStatus])


  useEffect(() => {
    setTime({ ...time, timeStr: getLastUpdatedTime() })

    const localData = JSON.parse(localStorage.getItem('userData'));
    getFaultLocations()
    getAllLevels();
    getTenantAccessLevel();
    setFaultDetails({
      ...faultDetails, tenantCompany: localData.company_details.companyArrayList[0]?.company_name,
      // unit:localData.company_details.companyArrayList[0].unit_no,
      mobileNumber: localData.company_details?.companyArrayList[0]?.phone_no,
      officeNumber: localData.company_details?.companyArrayList[0]?.phone_no,
      officeNumberCode: localData.company_details?.companyArrayList[0]?.country_code || '+65',
      mobileNumberCode: localData.company_details?.companyArrayList[0]?.country_code || '+65',
      personInCharge: localData?.user_name,
    })
  }, [])


  useEffect(() => {
    if(levelDatas?.length>0){
      setFaultLevels(levelDatas)
    }
  }, [levelDatas])
  
  const handleRouteNavigate = () => {
    let roleId = JSON.parse(localStorage.getItem('userData'))?.role_id;
    if(roleId == 4){
      navigate('/fault_reporting/0')
    }else{
      navigate('/my_faults/0')    
    }
  }

  const validate = () => {
    let noError = true;


    if (!faultDetails.reportTitle) {
      setFaultDetailsErrors((prevValue) => ({
        ...prevValue,
        reportTitle: "Should not be empty",
      }));

      noError = false;
    }

    // if (!faultDetails.reportDescription) {
    //   setFaultDetailsErrors((prevValue) => ({
    //     ...prevValue,
    //     reportDescription: "Should not be empty",
    //   }));
    //   noError = false;
    // }

    if(supportImages.length==0 && faultDetails.reportDescription.length==0){
      setFaultDetailsErrors((prevValue)=>(
        {
          ...prevValue,
          commonError:"Add any attachment or description"
        }
      ))
      noError = false;
    }

    if (!faultDetails.level || faultDetails.level === "Choose your level") {
      setFaultDetailsErrors((prevValue) => ({
        ...prevValue,
        level: "Should not be empty",
      }));
      noError = false;
    }

    if (faultDetails.category == "" || faultDetails.category === "Choose fault category") {
      setFaultDetailsErrors((prevValue) => ({
        ...prevValue,
        category: "Should not be empty",
      }));
      noError = false;
    }

    if (
      !faultDetails.location ||
      faultDetails.location === "Choose your location"
    ) {
      setFaultDetailsErrors((prevValue) => ({
        ...prevValue,
        location: "Should not be empty",
      }));
      noError = false;
    }
    console.log('noerr ',!faultDetails.level || faultDetails.level === "Choose your level")

    return noError;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let noError = validate();
    console.log('noError ',supportImages)
    if (noError ) { // || faultDetails.reportDescription.length > 0
      let responseUserData = JSON.parse(localStorage.getItem("userData"));
      let formData = new FormData();
      formData.append('description', faultDetails.reportDescription)
      formData.append('level_no', faultDetails.level)
      formData.append('service_location', faultDetails.location)
      formData.append('type_of_fault', '-')
      formData.append('company_id', responseUserData.company_id)
      formData.append('office_phone_country_code', faultDetails.officeNumberCode)
      formData.append('office_phone_number', faultDetails.officeNumber)
      formData.append('mobile_country_code', faultDetails.mobileNumberCode)
      formData.append('mobile_number', faultDetails.mobileNumber)
      formData.append('area_description', faultDetails.location === 'others' ? faultDetails.areaDescription : faultDetails.location)
      formData.append('user_id', responseUserData.user_id)
      formData.append('fault_title', faultDetails.reportTitle)
      formData.append('unit_no', localData.company_details.companyArrayList[0].unit_no)
      formData.append('file_count', supportImages.length)
      formData.append('notification_status', faultDetails.notification_status)
      formData.append('fault_category_id', faultDetails.category)
      formData.append('status', isDraft=='true' ? 'submitted' : 'Submitted')
      for (let i = 0; i < supportImages.length; i++) {
        formData.append(`file${i}`, supportImages[i]?.file)
      }
      if(isDraft=="true"){
        formData.append('fault_report_id',id)
      }
      if(isDraft=="true"){
      
        updateDraft(formData)
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }else{
        addFaultReport(formData, localStorage.getItem("userToken"))
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }
      
    } else if (noError) {
      setReportAddStatus({ message: 'Please Provide a description or upload a support image', status: true, color: 'red', icon: 'close' })
    }
  };
  const handleInputChange = (e) => {
    setTime({ ...time, timeStr: getLastUpdatedTime() })
    let { name, value } = e.target;
    const userNameRegex = /^[a-zA-Z_ ]*$/;
    const alphaNumericRegex = "^[A-Za-z0-9? ,_-]+$";
    const unitNumberMatch = /^[0-9#-/]*$/;
    const mobRegex = /^[0]?[65]\d{9}$/;
    setReportAddStatus({ message: '', status: false, color: '', icon: '' })
    if (name === "personInCharge" && value.match(userNameRegex)) {
      setFaultDetailsErrors({ ...faultDetailErrors, [name]: "" });
    } else if (name === "personInCharge") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Invalid Name",
      });
    }

    if (name === "tenantCompany" && value.match(userNameRegex)) {
      setFaultDetailsErrors({ ...faultDetailErrors, [name]: "" });
    } else if (name === "tenantCompany") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Invalid Company Name",
      });
    }

    if (name === "unit" && value.match(unitNumberMatch)) {
      setFaultDetailsErrors({ ...faultDetailErrors, [name]: "" });
    } else if (name === "unit") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Invalid Unit",
      });
    }

    if (name === "officeNumber" && value.match(mobRegex)) {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "",
      });
    } else if (name === "officeNumber") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Invalid office number",
      });
    }

    if (name === "mobileNumber" && value.match(mobRegex)) {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "",
      });
    } else if (name === "mobileNumber") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Invalid mobile number",
      });
    }

    if (name === "reportTitle" ) {
      setFaultDetailsErrors({ ...faultDetailErrors, [name]: "" });
    } else if (name === "reportTitle") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Invalid Report Title",
      });
    }


    if (name === "reportDescription" ) {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        commonError: "",
      });
    } 

    if (name === "level" && value !== "Choose your level") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "",
      });
    } else if (name === "level") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Must not be empty!",
      });
    }

    if (name === "location" && value !== "Choose your location") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "",
      });
    } else if (name === "location") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Must not be empty!",
      });
    }

    if (name === "category" && value !== "Choose fault category") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "",
      });

    } else if (name === "category") {
      setFaultDetailsErrors({
        ...faultDetailErrors,
        [name]: "Must not be empty!",
      });
    }
    setFaultDetails((prevValue) => ({ ...prevValue, [name]: value }));




  };

  const handleMobileInput = (value, country, name) => {
    let e = { target: { name: name, value: value } };
    setFaultDetails({ ...faultDetails, [`${name}Code`]: `+${country.dialCode}` });
    handleInputChange(e);
    
  };


  const receivedFiles = (selectedFiles) => {
    setReportAddStatus({ message: '', status: false, color: '', icon: '' })
    if (selectedFiles.length > 0) {
      setSupportImages([...selectedFiles])
      setFaultDetailsErrors({
        ...faultDetailErrors,
        commonError:""
      })
    }
  }

  const removeSupportImage = (id) => {
    let newSupportImages = [];
    for (var i = 0; i < supportImages.length; i++) {
      if (supportImages[i].id !== id) {
        newSupportImages.push(supportImages[i])
      }
    }
    setSupportImages([...newSupportImages])
  }

  const handleUploadButton = (e) => {

    let target = e.target.files;
    let length = supportImages.length;
    let files = supportImages;
    for (let i = 0; i < target.length; i++) {

      files.push({ id: length, fileName: target[i].name, file: target[i], size: target[i].size, source: URL.createObjectURL(target[i]) })
      // setSupportImages([...supportImages,files[i]])
      length += 1;
    }
    setSupportImages([...files])

  }


  const closetoast = () => {
    setReportAddStatus({ message: '', status: false, color: '', icon: '' })
  }
console.log('fault ',faultDetails)
  const handleSaveAsDraft = ()=>{
    let responseUserData = JSON.parse(localStorage.getItem("userData"));
    let formData = new FormData();
    formData.append('description', faultDetails.reportDescription)
    formData.append('level_no', faultDetails.level)
    formData.append('service_location', faultDetails.location)
    formData.append('type_of_fault', '-')
    formData.append('company_id', responseUserData.company_id)
    formData.append('office_phone_country_code', faultDetails.officeNumberCode)
    formData.append('office_phone_number', faultDetails.officeNumber)
    formData.append('mobile_country_code', faultDetails.mobileNumberCode)
    formData.append('mobile_number', faultDetails.mobileNumber)
    formData.append('area_description', faultDetails.location === 'others' ? faultDetails.areaDescription : faultDetails.location)
    formData.append('user_id', responseUserData.user_id)
    formData.append('fault_title', faultDetails.reportTitle)
    formData.append('unit_no', localData.company_details.companyArrayList[0].unit_no)
    formData.append('notification_status', faultDetails.notification_status)
    formData.append('fault_category_id', faultDetails.category)
    formData.append('status', isDraft=="true" ? 'draft' : 'Submitted')
    if(isDraft=="true"){
      formData.append('fault_report_id',id)
    }
    if(supportImages.length>0){
      formData.append('file_count', supportImages.length)
    }
    for (let i = 0; i < supportImages.length; i++) {
      formData.append(`file${i}`, supportImages[i]?.file)
    }
    if(faultDetails.reportTitle){
      if(isDraft=="true"){
        updateDraft(formData)
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }else{
        addFaultReportAsDraft(formData,localStorage.getItem("userToken"))
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      }
    }else{
      
        setFaultDetailsErrors((prevValue) => ({
          ...prevValue,
          reportTitle: "Should not be empty",
        }));
  
        
    }
   
  }


  // const handleDelete = () => {
  //   setSupportImages([])
  //   setFaultDetails({ level: "", location: "", reportTitle: "", reportDescription: "", });
  // }

  const handleNotifyClick = (notifyStatus) => {
    setFaultDetails({ ...faultDetails, ['notification_status']: notifyStatus })
  }

  return (
    <React.Fragment>
      {loaderStatus && <Loader status={loaderStatus}/>}
      <ToastAlert closeFunc={closetoast} message={reportAddStatus.message} status={reportAddStatus.status} color={reportAddStatus.color} icon={reportAddStatus.icon} />
      <form onSubmit={submitHandler} autoComplete="off">
        <Grid
          sx={{
            paddingTop: { md: "60px", xs: "30px" },
            background: "#F9FAFA",
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
          container
        >
          {/* spacing grid */}
          <Grid item lg={2} md={1} sm={1} xs={0.5} />

          {/* Main Grid Container */}
          <Grid
            item
            sx={{ display: "flex", flexDirection: "column" }}
            lg={8}
            md={10}
            sm={10}
            xs={11}
          >
            {/* Back Button */}
            <BackButton
              onClick={() => {
                navigate(-1);
              }}
              cursor="pointer"
            />

            {/* Heading */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "30px 0",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    fontSize: { md: "24px", xs: "20px" },
                    fontWeight: 500,
                    color: "#0F2C6F",
                  }}
                >
                  Fault Report
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "24px",
                    display: " flex",
                    alignItems: "center",
                    cursor: 'pointer'
                  }}
                >

                </Typography>
              </Box>
              {/* <Box>
                <Delete onClick={deleteHandler} cursor="pointer"  />
              </Box> */}
            </Box>
            <Box sx={{ alignItems: "center", display: "flex", pb: 1, }}>
              <Recent />
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#9AA6C3",
                  fontWeight: "400",
                  pl: "4px",

                }}
              >
                Last Updated at {time.timeStr}
              </Typography>
            </Box>
            {/* Main Content */}
            <Box sx={{ width: "100%", background: "#FFFFFF" }}>
              {/* Tenant Details */}
              <Box>
                {/* Title and Description */}
                <Box sx={{ padding: { md: "30px", xs: "10px" } }}>
                  <Typography
                    sx={{
                      color: "#0F2C6F",
                      fontSize: { md: "18px", xs: "16px" },
                      fontWeight: 500,
                    }}
                  >
                    Tenant Details*
                  </Typography>
                  <Typography
                    sx={{
                      color: "#6F80A9",
                      fontSize: { md: "14px", xs: "12px" },
                      paddingTop: "2px",
                    }}
                  >
                    Please enter the tenant particulars
                  </Typography>
                </Box>

                {/* Person in charge... Details */}
                <Grid
                  container
                  sx={{
                    display: "flex",
                    padding: { md: "30px", xs: "10px" },
                    rowGap: 2,
                  }}
                >
                  <Grid
                    lg={4}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                    item
                  >
                    <InputLabel
                      sx={{
                        padding: "0 0 8px 0",
                        fontSize: { md: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                      }}
                    >
                      Person-In-Charge
                    </InputLabel>
                    <TextField
                      name="personInCharge"
                      placeholder="Cassandra Lee"
                      disabled
                      value={faultDetails.personInCharge}
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      inputProps={{
                        sx: {
                          height: { md: "20px", xs: "10px" },
                          fontSize: { sm: "14px", xs: "12px" },
                          width: "100%",
                          background: "#F3F4F8",
                          borderRadius: "6px",
                          px: "10px",
                        },
                      }}
                      error={faultDetailErrors.personInCharge.length > 0}
                    />
                    {faultDetailErrors.personInCharge && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.personInCharge}
                      </Typography>
                    )}
                  </Grid>
                  <Grid lg={0.5} item />

                  <Grid
                    lg={4}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                    item
                  >
                    <InputLabel
                      sx={{
                        padding: "0 0 8px 0",
                        fontSize: { md: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                      }}
                    >
                      Tenant Company
                    </InputLabel>
                    <TextField
                      inputProps={{
                        sx: {
                          height: { sm: "20px", xs: "10px" },
                          fontSize: { sm: "14px", xs: "12px" },
                          width: "100%",
                          background: "#F3F4F8",
                          borderRadius: "6px",
                          px: "10px",
                        },
                      }}
                      type="text"
                      name="tenantCompany"
                      placeholder="TP ICAP Pte Ltd"
                      disabled
                      value={faultDetails.tenantCompany}
                      onChange={(e) => handleInputChange(e)}
                      error={faultDetailErrors.tenantCompany.length > 0}
                    />
                    {faultDetailErrors.tenantCompany && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.tenantCompany}
                      </Typography>
                    )}
                  </Grid>
                  <Grid lg={0.5} item />

                  <Grid
                    lg={3}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                    item
                  >
                    <Typography sx={{ color: '#0F2C6F', fontWeight: 600, fontSize: '14px' }}>Unit #</Typography>

                    <OutlinedInput disabled value={localData?.company_details?.companyArrayList[0]?.unit_name} sx={{ height: '52px', mt: 1, backgroundColor: '#F3F4F8', borderRadius: '6px' }} />
                    {faultDetailErrors.unit && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.unit}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                {/*Office and Mobile Number */}
                <Grid
                  container
                  sx={{
                    padding: { md: "30px", xs: "10px" },
                    borderBottom: "1px solid #F3F4F8",
                    rowGap: 2,
                  }}
                >
                  <Grid
                    lg={5.5}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      fontSize: { md: "14px", xs: "12px" },
                    }}
                    item
                  >
                    <InputLabel
                      sx={{
                        padding: "0 0 8px 0",
                        fontSize: "inherit",
                        fontWeight: 600,
                        color: "#0F2C6F",
                      }}
                    >
                      Office Telephone
                    </InputLabel>
                    <PhoneInput
                      inputProps={{
                        name: "office",
                        required: true,
                      }}
                      value={`${faultDetails.officeNumberCode} ${faultDetails.officeNumber}`}
                      onChange={(value, country) => {
                        handleMobileInput(value, country, "officeNumber");
                      }}
                      onFocus={() => setOfficeBlur(false)}
                      onBlur={() => setOfficeBlur(true)}
                      country="sg"
                      inputStyle={{
                        width: "100%",
                        background: "#F3F4F8",
                        fontSize: "13px",
                        height: "48px",
                        fontWeight: 500,
                        border: "none",
                      }}
                      containerStyle={{
                        borderRadius: "6px",
                        border:
                          faultDetailErrors.officeNumber && officeBlur
                            ? "1px solid #f44336"
                            : "none",
                      }}
                      buttonStyle={{
                        border: "transparent",
                        margin: 2,
                      }}
                    />
                    {faultDetailErrors.officeNumber && officeBlur && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.officeNumber}
                      </Typography>
                    )}
                  </Grid>
                  <Grid lg={1} item />
                  <Grid
                    lg={5.5}
                    sm={12}
                    xs={12}
                    sx={{ display: "flex", flexDirection: "column" }}
                    item
                  >
                    <InputLabel
                      sx={{
                        padding: "0 0 8px 0",
                        fontSize: { md: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                      }}
                    >
                      Mobile Telephone
                    </InputLabel>
                    <PhoneInput
                      name="mobile"
                      inputProps={{
                        name: "mobile",
                        required: true,
                      }}
                      value={`${faultDetails.mobileNumberCode} ${faultDetails.mobileNumber}`}
                      onChange={(mobile, country) => {
                        handleMobileInput(mobile, country, "mobileNumber");
                      }}
                      onFocus={() => setMobileBlur(false)}
                      onBlur={() => setMobileBlur(true)}
                      country="sg"
                      inputStyle={{
                        width: "100%",
                        background: "#F3F4F8",
                        fontSize: "13px",
                        height: "48px",
                        fontWeight: 500,
                        border: "none",
                      }}
                      containerStyle={{
                        borderRadius: "6px",
                        border:
                          faultDetailErrors.mobileNumber && mobileBlur
                            ? "1px solid #f44336"
                            : "none",
                      }}
                      buttonStyle={{
                        border: "transparent",
                        margin: 2,
                      }}
                    />
                    {faultDetailErrors.mobileNumber && mobileBlur && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.mobileNumber}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                {/* Report Title & Report Description */}

                <Box
                  sx={{
                    padding: { md: "30px", xs: "20px 10px" },
                    borderBottom: "1px solid #F3F4F8",
                  }}
                >
                  {/* <Box>
                    <FormControlLabel
                      label="Office premises"
                      sx={{ color: '#0F2C6F',mb:1 }}
                      control={<Checkbox icon={<CheckboxIcon/>} checked={officePremises}  onClick={()=>setOfficePremises(!officePremises)} />}
                    />
                  </Box> */}
                  <Grid
                    container
                    sx={{
                      padding: '8px 8px 8px 0px',
                      borderBottom: "1px solid #F3F4F8",
                      rowGap: 2,
                    }}
                  >
                    <Grid
                      lg={4}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", flexDirection: "column" }}
                      item
                    >
                      <InputLabel
                        sx={{
                          paddingBottom: "20px",
                          fontSize: { md: "18px", xs: "14px" },
                          fontWeight: 600,
                          color: "#0F2C6F",
                        }}
                      >
                        Level*
                      </InputLabel>
                      <Select
                        MenuProps={MenuProps}
                        style={{
                          width: 150,
                          background: "#F3F4F8",
                          borderRadius: "6px",
                          color: "#0F2C6F",
                        }}
                        name="level"
                        value={faultDetails.level}
                        onChange={(e) => handleInputChange(e)}
                        sx={{
                          fontSize: { sm: "14px", xs: "12px" },
                          height: { md: "55px", xs: "45px" },
                          border:
                            faultDetailErrors.level.length > 0
                              ? "1px solid #f44336"
                              : "none",
                          outline: "none",
                        }}
                        IconComponent={() => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "20px",
                            }}
                          >
                            <DropDownArrow />
                          </Box>
                        )}
                      >
                        <MenuItem sx={{color:"#0F2C6F"}} value="">Choose your level</MenuItem>
                        { officePremises ? tenantOccupiedLeves?.length>0 && tenantOccupiedLeves.map((value)=>(
                            <MenuItem sx={{color:"#0F2C6F"}} value={value.level_id}>{value.level_name}</MenuItem>
                          )) : 
                           faultLevels?.length>0 &&  faultLevels.map((item) => ( 
                            <MenuItem sx={{color:"#0F2C6F"}} value={item.level_id}>{item.level_name}</MenuItem>
                          ))
                        
                        }   
                      </Select>
                      {faultDetailErrors.level && (
                        <Typography sx={{ color: "#f44336" }}>
                          {faultDetailErrors.level}
                        </Typography>
                      )}
                    </Grid>

                    <Grid lg={0.5} item />
                    <Grid
                      lg={4}
                      sm={12}
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                      item
                    >
                      <InputLabel
                        sx={{
                          paddingBottom: "20px",
                          fontSize: { md: "18px", xs: "14px" },
                          fontWeight: 600,
                          color: "#0F2C6F",
                        }}
                      >
                        Incident Location*
                      </InputLabel>
                      <Select
                        style={{
                          width: 250,
                          background: "#F3F4F8",
                          borderRadius: "6px",
                          outline: "none",
                          border:
                            faultDetailErrors.location.length > 0
                              ? "1px solid #f44336"
                              : "none",
                          fontSize: "14px",
                        }}
                        name="location"
                        value={faultDetails.location}
                        onChange={(e) => handleInputChange(e)}
                        IconComponent={() => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "20px",
                            }}
                          >
                            <DropDownArrow />
                          </Box>
                        )}
                        sx={{
                          fontSize: { sm: "14px", xs: "12px" },
                          height: { md: "55px", xs: "45px" },
                        }}
                      >
                        {
                          faultLocations.map((item) => (
                            <MenuItem sx={{color:"#0F2C6F"}} value={item.fault_location_name}>{item.fault_location_name}</MenuItem>
                          ))
                        }
                      </Select>

                      {faultDetailErrors.location && (
                        <Typography sx={{ color: "#f44336" }}>
                          {faultDetailErrors.location}
                        </Typography>
                      )}
                    </Grid>
                    <Grid lg={0.5} item />
                    <Grid
                      lg={4}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", flexDirection: "column" }}
                      item
                    >
                      <InputLabel
                        sx={{
                          paddingBottom: "20px",
                          fontSize: { md: "18px", xs: "14px" },
                          fontWeight: 600,
                          color: "#0F2C6F",
                        }}
                      >
                        Category*
                      </InputLabel>
                      <Select
                        MenuProps={MenuProps}
                        style={{
                          width: 150,
                          background: "#F3F4F8",
                          borderRadius: "6px",
                          color: "#0F2C6F",
                        }}
                        name="category"
                        value={faultDetails?.category}
                        onChange={(e) => handleInputChange(e)}
                        sx={{
                          fontSize: { sm: "14px", xs: "12px" },
                          height: { md: "55px", xs: "45px" },
                          border:
                            faultDetailErrors.category.length > 0
                              ? "1px solid #f44336"
                              : "none",
                          outline: "none",
                        }}
                        IconComponent={() => (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginRight: "20px",
                            }}
                          >
                            <DropDownArrow />
                          </Box>
                        )}
                      >
                        <MenuItem sx={{color:"#0F2C6F"}} value="">Choose fault category</MenuItem>
                        { categoryData?.length>0 && categoryData.map((value)=>(
                            <MenuItem sx={{color:"#0F2C6F"}} value={value.fault_category_id}>{value.fault_category_name}</MenuItem>
                          ))
                        
                        }   
                      </Select>
                      {faultDetailErrors.category && (
                        <Typography sx={{ color: "#f44336" }}>
                          {faultDetailErrors.category}
                        </Typography>
                      )}
                    </Grid>
                    {faultDetails.location === 'Others' ? <Grid
                      lg={6.5}
                      sm={12}
                      xs={12}
                      sx={{ display: "flex", flexDirection: "column", pb: 1, pt: 1 }}
                      item
                    >
                      <InputLabel
                        sx={{
                          padding: "0 0 8px 0",
                          fontSize: { md: "14px", xs: "12px" },
                          fontWeight: 600,
                          color: "#0F2C6F",
                        }}
                      >
                        Area Description
                      </InputLabel>
                      <TextField
                        name="areaDescription"
                        placeholder="Please specify the location details"
                        value={faultDetails.areaDescription}
                        onChange={(e) => handleInputChange(e)}
                        type="text"
                        maxRows={3}
                        inputProps={{
                          sx: {
                            height: { md: "20px", xs: "10px" },
                            fontSize: { sm: "14px", xs: "12px" },
                            width: "100%",
                            background: "#F3F4F8",
                            borderRadius: "6px",
                            px: "10px",
                            color: "#0F2C6F",
                          },
                        }}
                        error={faultDetailErrors.areaDescription.length > 0}
                      />
                      {faultDetailErrors.areaDescription && (
                        <Typography sx={{ color: "#f44336" }}>
                          {faultDetailErrors.areaDescription}
                        </Typography>
                      )}
                    </Grid> : ''}
                  </Grid>

                  <Box>
                    <InputLabel
                      sx={{
                        paddingBottom: "20px",
                        fontSize: { md: "18px", xs: "14px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        pt: 2
                      }}
                    >
                      Report Title*
                    </InputLabel>
                    <TextField
                      name="reportTitle"
                      type="text"
                      placeholder="Please provide a report title"
                      style={{
                        borderRadius: "6px",
                        width: "100%",
                      }}
                      inputProps={{
                        sx: {
                          height: { md: "25px", sm: "20px", xs: "10px" },
                          fontSize: { md: "16px", sm: "14px", xs: "12px" },
                          color: "#0F2C6F",
                        },
                        maxLength:50
                      }}
                      value={faultDetails.reportTitle}
                      onChange={(e) => handleInputChange(e)}
                      error={faultDetailErrors.reportTitle.length > 0}
                    />
                    {faultDetailErrors.reportTitle && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.reportTitle}
                      </Typography>
                    )}
                  </Box>
                  <Box >
                    <Typography sx={{
                      padding: "0 0 8px 0",
                      fontSize: { md: "18px", xs: "12px" },
                      fontWeight: 600,
                      color: "#0F2C6F",
                      mt: 2
                    }}>Supporting material (optional)</Typography>
                    {supportImages.length > 0 ?
                      <Grid container>
                        {supportImages.map((item) => (
                          <Grid item xs={12} sx={{ border: '2px solid #80808021', mb: 1, borderRadius: '5px' }}>
                            <List
                              sx={{
                                width: '100%',
                                bgcolor: 'background.paper',
                                p: 0
                              }}
                            >
                              <ListItem sx={{ pl: 0 }} secondaryAction={
                                <IconButton edge="end" aria-label="delete">
                                  <CloseOutlinedIcon onClick={() => removeSupportImage(item.id)} />
                                </IconButton>}>
                                <ListItemText primary={
                                  <Typography variant='p' sx={{ m: 2 ,color: "#0F2C6F",fontSize:"14px",fontWeight:500 }}>{item.fileName}
                                    <span style={{ color: '#9AA6C3', fontSize: '12px',marginLeft:"5px" }}>{`     (${Math.floor(item.size / 1024)} of ${Math.floor(item.size / 1024)}KB)`}</span>
                                  </Typography>}
                                  secondary={''} />
                              </ListItem>
                            </List>
                          </Grid>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '900px' }}>
                          <Button component='label' variant='outlined' sx={{ color: '#0F2C6F', mt: 2, textTransform: 'capitalize' }} endIcon={<Fileupload></Fileupload>}>Upload
                            <input multiple type='file' accept="image/*" style={{ display: 'none' }} onChange={(e) => handleUploadButton(e)} />
                          </Button>
                        </Box>
                      </Grid>
                      : <DropZone getFiles={receivedFiles} />}
                      {faultDetailErrors.commonError && <Typography sx={{color:"red"}}>{faultDetailErrors.commonError}</Typography>}
                  </Box>

                  <Box>
                    <InputLabel
                      sx={{
                        paddingBottom: "20px",
                        fontSize: { md: "18px", xs: "14px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        paddingTop: "40px",
                      }}
                    >
                      Report Description
                    </InputLabel>
                    {/* <TextField
                      name="reportDescription"
                      multiline
                        sx={{
                        borderRadius: "6px",
                        width: "100%",
                      }}
                      inputProps={{
                        sx: {
                          fontSize: { md: "16px", sm: "14px", xs: "12px" },
                          p: 0,
                        },
                        maxLength:300
                      }}
                      type="text"
                      placeholder="Please provide a brief description of what the fault is regarding, this will help us more efficiently attend to it."
                      value={faultDetails.reportDescription}
                      onChange={(e) => handleInputChange(e)}
                      error={faultDetailErrors.reportDescription.length > 0}
                    /> */}
                    <textarea maxLength={300} name="reportDescription" style={{minWidth:'100%',maxWidth:'100%',height:100,marginTop:'5px',padding:'5px',fontSize:'15px',border:'1px solid #DBDFE9'}} placeholder="Please provide a brief description of what the fault is regarding, this will help us more efficiently attend to it." value={faultDetails.reportDescription}
                      onChange={(e) => handleInputChange(e)}/>
                    <Typography sx={{ color: "#0F2C6F",fontSize:'15px',mt:0.5}}>[{faultDetails?.reportDescription?.length}/300]</Typography>
                    {faultDetailErrors.reportDescription && (
                      <Typography sx={{ color: "#f44336" }}>
                        {faultDetailErrors.reportDescription}
                      </Typography>
                    )}
                  </Box>
                </Box>

                

                {/* Terms and Condition */}
                <Box sx={{ padding: { md: "30px", xs: "10px" } }}>
                  <Typography
                    sx={{
                      fontSize: { md: "18px", xs: "16px" },
                      fontWeight: 550,
                      color: "#0F2C6F",
                    }}
                  >
                    Terms & Conditions*
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { md: "14px", xs: "13px" },
                      fontWeight: 500,
                      color: "#6F80A9",
                      py: "8px",
                    }}
                  >
                    Tenants must agree to the terms and conditions stated below before submitting to building management.
                  </Typography>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#6F80A9",
                        paddingTop: "10px",
                      }}
                    >
                      Important Conditions:
                    </Typography>
                    <br />
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#6F80A9",
                      }}
                    >
                      1.Request will be attended to within 1-2 working days.
                      Please do not sent repeated reports for the same incident.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#6F80A9",
                        my:1
                      }}
                    >
                      2.We aim to resolve your issues within 3-4 working days
                      depending on complexity.
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#6F80A9",
                      }}
                    >
                      3.If the fault is deemed to be within office premises, please
                      submit a "Service Request" instead.
                    </Typography> */}
                  </Box>
                  <br />

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "60px",
                      color: "#6F80A9",
                    }}
                  >
                    <Checkbox
                      {...label}
                      onClick={() => setTermsCheck((prevValue) => !prevValue)}
                      icon={<CheckboxIcon style={{fontSize:"15px"}}/>}
                      sx={{
                        ml:-1
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        pr: "6px",
                        fontSize: { md: "14px", xs: "13px" },
                      }}
                    >
                      I agree with the above
                    </Typography>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: { md: "14px", xs: "13px" },
                        }}
                      >
                        terms and conditions
                      </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Footer */}
            <Box

              sx={{
                padding: "20px 0",
                display: "flex",
                justifyContent: "space-between",
                mx: 1,
                alignItems: "flex-start",
              }}
            >
              <Grid container>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
                  {/* <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Recent />
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#9AA6C3",
                        fontWeight: "400",
                        pl: "4px",
                      }}
                    >
                      Saved Previously at 10.35AM
                    </Typography>
                  </Box> */}
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }} >    <Box sx={{ pb: 4 }}>
                  <Button
                    onClick={() => handleSaveAsDraft()}
                    variant="outlined"
                    sx={{
                      mr: 2,
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: { md: "14px", xs: "13px" },
                      height: '56px',
                      backgroundColor:"#FFFFFF",
                      "&:hover":{ backgroundColor:"#FFFFFF"}
                    }}
                  >
                    Save As Draft
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      height: '56px',
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: { md: "14px", xs: "13px" },
                    }}
                    disabled={!termsCheck}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box></Grid>
              </Grid>
              {/* <Box sx={{ alignItems: "center", display: "flex" }}>
                <Recent />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#9AA6C3",
                    fontWeight: "400",
                    pl: "4px",
                  }}
                >
                  Saved Previously at 10.35AM
                </Typography>
              </Box> */}

            </Box>
          </Grid>

          {/* spacing grid */}
          <Grid lg={2} md={1} sm={1} xs={0.5} item />
        </Grid>

      </form>
      
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    addFaultStatus: state.FaultReportReducer.reportAddStatus,
    faultLocations: state.FaultReportReducer.faultLocations,
    levelDatas: state.CommonReducer.levelDatas,
    tenantOccupiedLeves:state.CommonReducer.tenantOccupiedLeves,
    faultReportData:state.FaultReportReducer.faultReportData,
    updateDraftStatus:state.FaultReportReducer.updateDraft,
    getFaultCategoryListData: state.Service_Req_Reducer.getFaultCategoryList,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    addFaultReport: (data, token) => dispatch(faultReportAction.addFaultReport(data, token)),
    addFaultReportAsDraft:(data,token)=>dispatch(faultReportAction.addFaultReportAsDraft(data,token)),
    getFaultLocations: () => dispatch(faultReportAction.getFaultLocationData()),
    setFaultReportStateNull: () => dispatch(faultReportAction.setFaultReportStateNull()),
    getAllLevels: () => dispatch(commonAction.getAllLevels()),
    getTenantAccessLevel:()=>dispatch(commonAction.getTenantAccessLevel()),
    FaultReportbyId:(data)=>{dispatch(faultReportAction.getFaultReportbyId(data))},
    updateDraft:(data)=>dispatch(faultReportAction.updateDraft(data)),
    getFaultCategoryData:(data)=>dispatch(ServiceRequestAction.getFaultCategoryData(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Faults);
