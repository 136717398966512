import { handleActions } from "redux-actions";

const responseDef = {
    isLoading: false,
    response: null,
    isError: false,
}

const dashboard = {
    data:[],
    notification:[],
    updateAnnouncementReadStatus:null,
    isNotificationReceived:null,
    updateNotificationViewStatus:null,
    filteredData:[],
    getRealtimeTurnStile: {...responseDef},
    dbDataIsLoading: false,

}

const reducer = handleActions({
        GET_DBDATA_INITIATE:(state)=>{
            return{
                ...state,
                dbDataIsLoading: true,
            }
        },
        GET_DBDATA_SUCCESS:(state,action)=>{
            return{
                ...state,
                data:action.payload,
                dbDataIsLoading: false,
            }
        },
        GET_DBDATA_FAILURE:(state,action)=>{
            return{
                ...state,
                data:action.payload,
                dbDataIsLoading: false,
            }
        },
        GET_NOTIFICATION_MSG_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        GET_NOTIFICATION_MSG_SUCCESS:(state,action)=>{
            return{
                ...state,
                notification:action.payload
            }
        },
        GET_NOTIFICATION_MSG_FAILURE:(state,action)=>{
            return{
                ...state,
                notification:action.payload
            }
        },
        UPDATE_ANNOUNCEMENTREAD_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        UPDATE_ANNOUNCEMENTREAD_SUCCESS:(state,action)=>{
            return{
                ...state,
                updateAnnouncementReadStatus:action.payload
            }
        },
        UPDATE_ANNOUNCEMENTREAD_FAILURE:(state,action)=>{
            return{
                ...state,
                updateAnnouncementReadStatus:null
            }
        },
        IS_NOTIFICATION_RECEIVED:(state,action)=>{
            return{
                ...state,
                isNotificationReceived:action.payload
            }
        },
        UPDATE_NOTIFICATION_VIEWSTATUS_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        UPDATE_NOTIFICATION_VIEWSTATUS_SUCCESS:(state,action)=>{
            return{
                ...state,
                updateNotificationViewStatus:action.payload
            }
        },
        UPDATE_NOTIFICATION_VIEWSTATUS_FAILURE:(state,action)=>{
            return{
                ...state,
                updateNotificationViewStatus:action.payload
            }
        },
        GET_GLOBALFILTER_INITIATE:(state)=>{
            return{
                ...state
            }
        },
        GET_GLOBALFILTER_SUCCESS:(state,action)=>{
            return{
                ...state,
                filteredData:action.payload
            }
        },
        GET_GLOBALFILTER_FAILURE:(state,action)=>{
            return{
                ...state,
                filteredData:[]
            }
        },
        GET_REALTIME_TURNSTILE_DATA_INITIATE: (state, action) => {
            return {
                ...state,
                getRealtimeTurnStile: {
                    ...state.getRealtimeTurnStile,
                    isError: false,
                    isLoading: true,
                }
            }
        },
        GET_REALTIME_TURNSTILE_DATA_SUCCESS: (state, action) => {
            return {
                ...state, 
                getRealtimeTurnStile: {
                    isError: false,
                    isLoading: false,
                    response: action.payload
                }
            }
        },
        GET_REALTIME_TURNSTILE_DATA_FAILURE: (state, action) => {
            return {
                ...state, 
                getRealtimeTurnStile:  {response: action.payload, isLoading: false, isError: true}
            }
        },
        GET_REALTIME_TURNSTILE_DATA_NULL: (state,) => {
            return {
                ...state,
                getRealtimeTurnStile: {...responseDef}
            }
        }, 
        
},dashboard)

export default reducer;
