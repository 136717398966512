import React, { useEffect } from 'react'
import XLSX from 'sheetjs-style';
import { saveAs } from "file-saver";

// export default function ExportExcel({ excelData, fileName }) {   
//      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';
//     const exportToExcel = async() => {
//         const ws = XLSX.utils.json_to_sheet(excelData);      
//         const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
//          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//         const data = new Blob([excelBuffer], { type: fileType });
//         saveAs(data, fileName + fileExtension);
//     }
//     return (
//         <div onClick={()=>exportToExcel()}>Download Excel File</div>
//     )
// }


export default function ExportExcel({ excelData, fileName }) {   
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Function to calculate the width of a string in Excel units
    const getColumnWidth = (text) => {
        return { wch: Math.max(text.length + 2, 10) };
    };

    const exportToExcel = async () => {
        // Convert JSON data to sheet
        const ws = XLSX.utils.json_to_sheet(excelData);
        
        const headers = Object.keys(excelData[0]);

        ws['!cols'] = headers.map(header => getColumnWidth(header));


        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
       const data = new Blob([excelBuffer], { type: fileType });
       saveAs(data, fileName + fileExtension);

    }

    return (
        <div onClick={exportToExcel}>Download Excel File</div>
    );

}