import { Button, Grid, Typography, } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { ReactComponent as FaultSettings } from "../../Assets/icons/FaultReportIconBig.svg";
import {ReactComponent as AddIcon} from "../../Assets/icons/WhiteRoundedPlusIcon.svg"
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as faultReportAction from '../../redux/actions/faultreportAction'
import {getAllLevels as GetAllLevels} from "../../redux/actions/commonAction"
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useParams } from "react-router-dom";
import FaultReportTabs from "./Tabs";

const FaultReporting = ({ faultReports, getFaultReports,faultLocations,getFaultLocations, getAllLevels }) => {
  const navigate = useNavigate()
  let { tabvalue } = useParams();
  let selectedTab = parseInt(tabvalue);
  const [reportData, setReportData] = useState([])
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))

  useEffect(() => {
    getFaultLocations()
  }, [getFaultLocations])

  useEffect(() => {
    getAllLevels();
  }, [])

  useEffect(() => {
    if (faultReports?.length > 0) {
      setReportData(faultReports)
    }

  }, [faultReports])

  const getFaultReport = () => {
    let data = {
      id: userData.user_id,
      status: 'all',
      role: userData.role
    }
    getFaultReports(data)
  }

  return (
    <>
      {/* {reportData.length === 0 ? <div style={{

        background: "#F9FAFA",
        display: 'flex',
        justifyContent: 'center', alignItems: 'center',
        height: window.innerHeight / 1.2
      }}>

        <Grid container>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 1 }}
          >
            <FaultSettings style={{ width: '20px !important', height: '30px  !important' }} />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 1 }}
          >
            <Typography variant="h4" sx={{ color: "#002D74" }}>
              Fault Report
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 1 }}
          ></Grid>
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 1 }}
          >
            {userData.role !== 'BMS Coordinator' ? <Typography variant="p" sx={{ color: "#002D74" }}>
              Your company has not yet filed any fault report.<br /> To start a new
              fault report, click the button below
            </Typography> : ''}
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 1 }}
          ></Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            sx={{ p: 1 }}
          >
            {userData.role !== 'BMS Coordinator' ? <Button
              onClick={() => navigate('/report_fault')}
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                backgroundColor: "#002D74",
                fontSize: "15px",
                textTransform: "none",
              }}
            >
              New Report
            </Button> : <Button
              onClick={() => getFaultReport()}
              variant="contained"
              startIcon={<AutorenewIcon />}
              sx={{
                backgroundColor: "#002D74",
                fontSize: "15px",
                textTransform: "none",
              }}
            >
              Refresh Data
            </Button>
            }


          </Grid>
        </Grid>
      </div> : */}
        <Grid container sx={{background: "#F9FAFA",minHeight:"100%"}}>
          <Grid item xs={0.5} />
          <Grid item xs={11}>
          <Box style={{ paddingTop: "64px" }}>
          {/* Fault Reporting Header */}
          <Box style={{ color: "#002D74" }}>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontSize: "26px", fontWeight: "500", color:'#002D74' }}>
                Fault Reporting
              </Typography>

               <Box>
                <Button
                  variant="contained"
                  style={{ textTransform: "none", background: "#123FA9" }}
                  onClick={() => navigate('/report_fault/false/null')}
                  sx={{fontSize: { md: "14px", xs: "10px"}}}
                  endIcon={<AddIcon />}
                >
                  New Report                                     
                </Button>
              </Box>  
            </Box>
            <Box style={{ padding: "2px 0" }}>
              <Typography style={{ fontSize: "14px", color: "#002D74",  fontWeight: "500" }}>
                {/* Fault reports displayed are filed by tenant admins on behalf of the
                company. */}
              All submitted fault reports will be displayed in below table. BMS Administrators can accept, reject, assign staff to the tickets.
              A fault report should be reported for faults or issues noticed by tenants that fall outside their office premises.
              </Typography>
            </Box>
          </Box>
        </Box>
        <FaultReportTabs tabValue={selectedTab} tableData={faultReports} faultReportCount={''} faultLocations={faultLocations}/>
          </Grid>
          <Grid item xs={0.5} />
        </Grid>
        
      {/* } */}

    </>
  );
};

const mapStateToProps = (state) => {
  return {
    faultReports: state.FaultReportReducer.faultReports,
    faultLocations: state.FaultReportReducer.typeOfFault,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getFaultReports: (data) => (dispatch(faultReportAction.getAllFaultReport(data))),
    getFaultLocations: () => dispatch(faultReportAction.getTypeOfFault()),
    getAllLevels: () => dispatch(GetAllLevels())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FaultReporting);
