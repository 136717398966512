import { handleActions } from 'redux-actions';
const UserReducer = {
    userMenus: []
   
};
const reducer = handleActions({
    USER_MENU_INITIATE: (state) => {
        return {
            ...state,
        };
    },
    USER_MENU_SUCCESS: (state,action) => {
        return {
            ...state,
            userMenus:action.payload
        };
    },
    USER_MENU_FAILURE: (state) => {
        return {
            ...state,
        };
    },
    USER_MENU_NULL: (state) => {
        return {            
            userMenus: [],
        }
    }
  

},UserReducer)
export default reducer;