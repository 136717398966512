import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { connect } from "react-redux";
import React, { useState } from "react";

const Addlevel = () => {
  const [buildingid, setBuildingid] = useState("");
  const [addlevel, setAddlevel] = useState();

  const handleBuildingid = (e) => {
    return setBuildingid(e.target.value);
  };

  const handleAddlevel = (e) => {
    setAddlevel(e.target.value);
  };

  const levelSubmit = () => {
  
  };

  return (
    <>
      <Grid
        container
        direction="row"
        columnSpacing={2}
        sx={{ m: 4 }}
        rowSpacing={2}
      >
        <Grid item xs={12} sm={3}>
          <FormControl>
            <InputLabel>Building Id</InputLabel>
            <Select
              value={buildingid}
              name="buildingid"
              label="Building Id"
              onChange={(e) => handleBuildingid(e)}
              sx={{ width: 300 }}
            >
              <MenuItem value="one">One</MenuItem>
              <MenuItem value="two">Two</MenuItem>
              <MenuItem value="three">Three</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <OutlinedInput
            disabled
            placeholder="Building Name"
            type="text"
            name="buildingname"
            //   value={building.buildingAddress}

            sx={{ ml: 2 }}
          ></OutlinedInput>
        </Grid>
        <Grid item xs={12} sm={2}>
          <OutlinedInput
            disabled
            placeholder="Building Address"
            type="text"
            name="buildingaddress"
            sx={{ ml: 2 }}
          ></OutlinedInput>
        </Grid>
        <Grid item xs={12} sm={2}>
          <OutlinedInput
            name="addlevel"
            placeholder="Add Level"
            type="number"
            inputProps={{
              min: 0,
            }}
            onChange={(e) => {
              handleAddlevel(e);
            }}
            sx={{ ml: 2 }}
          ></OutlinedInput>
        </Grid>
      </Grid>
      <Box sx={{ ml: { xs: 15, sm: 55 } }}>
        <Button variant="contained" onClick={levelSubmit}>
          Submit
        </Button>
      </Box>
    </>
  );
};
export default Addlevel;

const mapStateToProps = (state) => {
  return {
    addLevel: state.SuperAdmin.addLevel,
  };
};
