import axios from "axios";
import { createAction } from "redux-actions";
export const updateState = createAction("UPDATE_ANNOUNCEMENT_STATE");
export const sessionExpired = createAction("SESSION_EXPIRED");
export const setAnnouncementStateNull=()=>{
  return (dispatch) => {
    dispatch(updateState());
  }
};

export const getAnnouncementsInitiate = createAction("GET_ANNOUNCEMENTS_INITIATE");
export const getAnnouncementsSuccess = createAction("GET_ANNOUNCEMENTS_SUCCESS");
export const getAnnouncementsFailure = createAction("GET_ANNOUNCEMENTS_FAILURE");

export const getAnnouncementsList = (data) => {
  let token=localStorage.getItem('userToken')     
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getAnnouncementsInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/AnnouncementList`,
        data,
        config
      )
      .then((response) => {
        
        if(response.data?.response_status==='success'){
          dispatch(getAnnouncementsSuccess(response?.data));
        }else{
           dispatch(getAnnouncementsFailure());
         
        }
        
      })
      .catch((err) => {
        if(err?.response?.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
           dispatch(getAnnouncementsFailure());
        }
      });
  };
};

export const getAnnouncementTypesInitiate = createAction("GET_ANNOUNCEMENT_TYPES_INITIATE");
export const getAnnouncementTypesSuccess = createAction("GET_ANNOUNCEMENTS_TYPES_SUCCESS");
export const getAnnouncementTypesFailure = createAction("GET_ANNOUNCEMENTS_TYPES_FAILURE");

export const getAnnouncementTypes = () => {
    let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getAnnouncementTypesInitiate());

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/getAnnouncementTypes`,
        config
      )
      .then((response) => {
        if(response.data?.response_status==='success'){
          dispatch(getAnnouncementTypesSuccess(response?.data?.response_json?.AnnouncementsTypesList));
        }else{
          dispatch(getAnnouncementTypesFailure());
         
        }
        
      })
      .catch((err) => {
        if(err?.response?.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
           dispatch(getAnnouncementTypesFailure());
        }
      });
  };
};

export const addAnnouncementInitiate = createAction("ADD_ANNOUNCEMENT_INITIATE");
export const addAnnouncementSuccess = createAction("ADD_ANNOUNCEMENT_SUCCESS");
export const addAnnouncementFailure = createAction("ADD_ANNOUNCEMENT_FAILURE");

export const addAnnouncement = (data) => {
    let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(addAnnouncementInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/createAnnouncement`,
        data,
        config
      )
      .then((response) => {
        
        if(response.data){
          dispatch(addAnnouncementSuccess(response?.data));
        }
        
      })
      .catch((err) => {
        if(err?.response?.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
           dispatch(addAnnouncementFailure());
        }
      });
  };
};

export const editAnnouncementInitiate = createAction("EDIT_ANNOUNCEMENT_INITIATE");
export const editAnnouncementSuccess = createAction("EDIT_ANNOUNCEMENT_SUCCESS");
export const editAnnouncementFailure = createAction("EDIT_ANNOUNCEMENT_FAILURE");

export const editAnnouncement = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return (dispatch)=>{
    dispatch(editAnnouncementInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/editAnnouncement`,data,config)
    .then((response)=>{
      if(response?.data){
        console.log("witheditann ",response)
        dispatch(editAnnouncementSuccess(response?.data))
      }else{
        dispatch(editAnnouncementFailure())
      }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
         dispatch(editAnnouncementFailure());
      }
    })
  }
}

export const getAnnouncementDetailsByIdInitiate = createAction("GET_ANNOUNCEMENTDETAILS_BYID_INITIATE");
export const getAnnouncementDetailsByIdSuccess = createAction("GET_ANNOUNCEMENTDETAILS_BYID_SUCCESS");
export const getAnnouncementDetailsByIdFailure = createAction("GET_ANNOUNCEMENTDETAILS_BYID_FAILURE");

export const getAnnouncementDetailsById = (data)=>{
  let token = localStorage.getItem("userToken")
  let config={
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(getAnnouncementDetailsByIdInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAnnouncementDetailsById`,data,config)
    .then((response)=>{
      console.log("getById ",response)
      if(response.data?.response_json){
        dispatch(getAnnouncementDetailsByIdSuccess(response?.data?.response_json))
      }else{
        dispatch(getAnnouncementDetailsByIdFailure())
      }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getAnnouncementDetailsByIdFailure())
      }
    })
  }
}

export const deleteAnnouncementInitiate = createAction("DELETE_ANNOUNCEMENT_INITIATE");
export const deleteAnnouncementSuccess = createAction("DELETE_ANNOUNCEMENT_SUCCESS");
export const deleteAnnouncementFailure = createAction("DELETE_ANNOUNCEMENT_FAILURE");

export const deleteAnnouncement = (data)=>{
  let token = localStorage.getItem("userToken")
  let config={
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return (dispatch)=>{
    dispatch(deleteAnnouncementInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/deleteAnnouncement`,data,config)
    .then((response)=>{
     if(response.data?.response_json){
      dispatch(deleteAnnouncementSuccess(response?.data?.response_json))
     }else{
      dispatch(deleteAnnouncementFailure())
     }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(deleteAnnouncementFailure())
      }
    })
  }
}

export const updateAnnouncementInitiate = createAction("UPDATE_ANNOUNCEMENT_INITIATE");
export const updateAnnouncementSuccess = createAction("UPDATE_ANNOUNCEMENT_SUCCESS");
export const updateAnnouncementFailure = createAction("UPDATE_ANNOUNCEMENT_FAILURE");

export const updateAnnouncement = (data)=>{
  let token = localStorage.getItem("userToken")
  let config={
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(updateAnnouncementInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/announcementUpdateStatus`,data,config)
    .then((response)=>{
      if(response.data?.response_json){
        dispatch(updateAnnouncementSuccess(response?.data))
      }else{
        dispatch(updateAnnouncementFailure())
      }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(updateAnnouncementFailure())
      }
    })
  }
}

export const updateAnnouncementPinStatusInitiate = createAction('UPDATE_ANNOUNCEMENT_PIN_STATUS_INITIATE');
export const updateAnnouncementPinStatusSuccess = createAction('UPDATE_ANNOUNCEMENT_PIN_STATUS_SUCCESSS');
export const updateAnnouncementPinStatusFailure = createAction('UPDATE_ANNOUNCEMENT_PIN_STATUS_FAILURE');

export const updateAnnouncementPinStatus = (data) => {
  let token = localStorage.getItem("userToken")
  let config={
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(updateAnnouncementPinStatusInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/Annopintop`,data,config)
    .then((response)=>{
      if(response){
        dispatch(updateAnnouncementPinStatusSuccess(response?.data))
      }else{
        dispatch(updateAnnouncementPinStatusFailure())
      }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(updateAnnouncementPinStatusFailure())
      }
    })
  }
}