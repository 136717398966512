import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as SearchIcon } from "../../Assets/icons/SearchIcon.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { connect } from "react-redux";
import * as commonAction from "../../redux/actions/commonAction";
import {
  ACTIVE_EMPLOYEES,
  TENANT_EMP_DRAFT_COLUMNS,
  TENANT_EMP_ONBOARDING_COLUMNS,
  TENANT_EMP_ACTIVE_COLUMNS,
  TENANT_EMP_OFFBOARD_COLUMNS,
  TENANT_EMP_INACTIVE_COLUMNS
} from "../../components/Data/Data";
import { useNavigate } from "react-router-dom";
import { getStatus } from '../../Utils/ActiveStatus'
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { getYYYYMMDDFormat, preventDatePickerKeyboard } from "../../Utils/utils";
import {ReactComponent as ArrowUpwardIcon} from '../../Assets/icons/sortasc.svg';
import {ReactComponent as ArrowDownwardIcon} from '../../Assets/icons/sortdes.svg';
import { SortIconStyle } from '../../Utils/utils';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#0F2C6F',
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: [theme.palette.common.white],
    fontSize: "13px",
    fontWeight: 550,

  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '14px',
  },

  [theme.breakpoints.up("md")]: {
    [`&.${tableCellClasses.body}`]: {
      fontSize: '14px',
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:nth-of-type(odd)": {
    backgroundColor: "#FAFBFF",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const ActiveEmployess = ({ getEmpList, empList, activeType, empCount,setStatusNull, isLoading }) => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [allCheck, setAllCheck] = useState(false);
  const [empListData, setEmpListData] = useState([]);
  const [userType, setUserType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentColumns, setCurrentColumns] = useState([]);
  const [checked, setChecked] = useState(false);
  const [filterData,setFilterData] = useState({date:null,search:"",rows:'10'})
  const [sortData,setSortData] = useState({name:'',type:''})
  const [activeColumn,setActiveColumn] = useState([{
    id: 1,
    column_name: 'Employee Name',
    sort:true,
    sort_name:'submitted_by'
  },
  {
    id: 2,
    column_name: 'Work Email'
  },
  {
    id: 3,
    column_name: 'Mobile Number'
  },
  {
    id: 4,
    column_name: 'Onboarded Date',
    sort:true,
    sort_name:'access_start_date'
  }, {
    id: 5,
    column_name: 'Office Unit',
    sort:true,
    sort_name:'level_name'
  }, {
    id: 6,
    column_name: 'Tenant Role',
    sort:true,
    sort_name:'role'
  }])
  const [onboardingColumn,setOnboardingColumn] = useState([
    {
      id: 1,
      column_name: 'Employees'
    },
    {
      id: 2,
      column_name: 'Status'
    },
    {
      id: 3,
      column_name: 'Requested on',
      sort:true,
      sort_name:"created_at"
    },
    {
      id: 4,
      column_name: 'Onboard date',
      sort:true,
      sort_name:"access_start_date"
    },
    {
      id: 5,
      column_name: 'Submitted By'
    }, {
      id: 6,
      column_name: 'Last Updated',
      sort:true,
      sort_name:"updated_at"
    }
  ])
  const [offboardingColumn,setOffboardingColumn] = useState([{
    id: 1,
    column_name: 'Employee Name',
    sort:true,
    sort_name:"employee_name"
  },
  {
    id: 2,
    column_name: 'Requested on',
    sort:true,
    sort_name:'created_at'
  },
  {
    id: 3,
    column_name: 'Offboard date',
    sort:true,
    sort_name:'offboard_date'
  },
  {
    id: 4,
    column_name: 'Submitted by'
  },
  {
    id: 5,
    column_name: 'Last updated',
    sort:true,
    sort_name:'updated_at'
  },])
  const [inactiveColumn,setInactiveColumn] = useState([
    {
      id: 1,
      column_name: 'Employee Name',
      sort:true,
      sort_name:'name'
    },
    {
      id: 2,
      column_name: 'Work Email'
    },
    {
      id: 3,
      column_name: 'Mobile Number'
    },
    {
      id: 4,
      column_name: 'Offboarded Date',
      sort:true,
      sort_name:'offboard_date'
    }, {
      id: 5,
      column_name: 'Office Unit',
      sort:true,
      sort_name:'level_name'
    }, {
      id: 6,
      column_name: 'Tenant Role',
    }
  ])

  useEffect(() => { 
    let userData = JSON.parse(localStorage.getItem("userData"));
    let data = { company_id: userData?.company_id, limit: filterData.rows, offset: 0, approved_status: activeType,date:getYYYYMMDDFormat(filterData.date),search:filterData.search,order_by_column:sortData.name,order_by:sortData.name=='' ? '' :  sortData.type==true ? 'ASC' : sortData.type==false ? 'DESC' : '' };
    if (userData !== ""
      // &&activeType==='approved'
    ) {
      getEmpList(data, localStorage.getItem("userToken"));
    }
    //  else if(userData !==""){
    // }
    else {
      navigate("/");
    }

    return()=>{
      setStatusNull()
    }
  }, [filterData,sortData]);
 
  useEffect(() => {
    console.log('isLoading ',isLoading)
    if (empList?.length > 0) {
      console.log('within emplist if conditonsd')
      let list = [...empList];
      for (let i = 0; i < list.length; i++) {
         list[i] = {...list[i], isSelected: false}
      }
     
      setEmpListData(list);
      // setEmpListData(empList);         
    }  else {
      setEmpListData([])
    }
 

  }, [empList]);
 
  const handleCheckbox = (id, isSelected) => {
    let updatedData = {...empListData};
    for (let i = 0; i < updatedData.length; i++) {
      if (updatedData[i].employee_id === id) {
        updatedData[i].isSelected = !updatedData[i]?.isSelected;
      }
    }
    setEmpListData([...updatedData]);
  };

  const handleAllCheck = (e) => {
    const { checked } = e.target;
    setAllCheck((prevValue) => !prevValue);
    let updatedData = {...empListData};
    if (checked === true) {
      for (let i = 0; i < updatedData.length; i++) {
        updatedData[i].isSelected = true;
      }
      setEmpListData([...updatedData]);
    } else if (checked === false) {
      for (let i = 0; i < updatedData.length; i++) {
        updatedData[i].isSelected = false;
      }
      setEmpListData([...updatedData]);
    }
    setAllCheck(!allCheck);

  };

  const handleReset = ()=>{
    setFilterData({
      search:"",
      date:null
    })
    setSortData({type:'',name:''})
    handleSort()
  }

  const getColumns = () => {
    let columns = activeType === 'draft' ? TENANT_EMP_DRAFT_COLUMNS :
      activeType === 'submitted' ? onboardingColumn :
        activeType === 'approved' ? activeColumn :
          activeType === 'offboarded' ? offboardingColumn : inactiveColumn
    return columns;
  }

  const handleSort = (index,type,sortname)=>{
    let data = activeType=='approved' ? activeColumn : activeType=='submitted' ? onboardingColumn : activeType=='offboarded' ? offboardingColumn : activeType=='inactive' ? inactiveColumn : []
    for(let i=0;i<data.length;i++){
          if(i==index){
            data[i].sort=!type
          }else{
            for(let j=0;j<data.length;j++){
              if(j===index){
                continue;
              }
              let obj = data[j]
              let keys = Object.keys(obj)
              if(keys.includes('sort')){
                data[j].sort = true
              }
            }
          }
    }

    if(activeType=='approved'){
      setActiveColumn([...data]) 
    }else if(activeType=='submitted'){
      setOnboardingColumn([...data])
    }else if(activeType=='offboarded'){
      setOffboardingColumn([...data])
    }else if(activeType=='inactive'){
      setInactiveColumn([...data])
    }

    setSortData({name:sortname,type:!type})
  }

  const getTableColumns = () => {
    let columns = getColumns();
    return (
      columns.map((item,index) => (
        item.column_name === 'checkbox' ? <StyledTableCell>
          <Checkbox
            icon={<CheckboxIcon/>}
            size="small"
            onClick={(e) => handleAllCheck(e)}
            checked={allCheck}
            
          />
        </StyledTableCell> : <StyledTableCell onClick={()=>handleSort(index,item.sort,item.sort_name)} align="left" sx={{ textAlign: 'left', fontSize: '16px' }}>
          {item.column_name}
        {item.sort==true ? <ArrowUpwardIcon style={SortIconStyle}/> : item.sort==false ?  <ArrowDownwardIcon style={SortIconStyle}/> : '' }
        </StyledTableCell>
      ))
    )
  }
  const TabFilterOptions = () => {
    return (
      <>
        <Grid container columnSpacing={1}>
          <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
            <FormControl  sx={{ width: 160, pl: 1,ml:1.5 }}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                components={{
                  OpenPickerIcon:DatePickerIcon
                }}
                value={filterData.date}
                onChange={(e)=>setFilterData({
                    ...filterData,
                    date:e
                })}  
                renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} size="small" {...params} />}
                disableFuture={true}
              />
            </FormControl>
            <Typography onClick={handleReset} sx={{color:"#2764F4",textDecoration:"underline",fontSize:"16px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} >Reset</Typography>
          </Grid>
          <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center',pr:2 }}>
            <OutlinedInput value={filterData.search} placeholder="Search..."
              onChange={(e)=>setFilterData({
                ...filterData,
                search:e.target.value
              })}
              name='search'
              endAdornment={<SearchIcon />} size="small" />
          </Grid>
        </Grid>
      </>
    )
  }

  const renderTableDatas = (rowData) => {
    console.log(rowData, 'gunaCheck')
    let columns = getColumns();
    if (activeType === 'approved') {
      return (
        <>
          {columns[0]?.column_name === 'checkbox' ? <StyledTableCell>
            <Checkbox
              icon={<CheckboxIcon/>}
              size="small"
              checked={rowData?.isSelected}
              onClick={(e) => {
                handleCheckbox(rowData.employee_id, rowData?.isSelected);
              }}
            />
          </StyledTableCell> : ''
          }
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}> {rowData.name}</StyledTableCell >
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.email}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData?.mobile_no?.trim() ? rowData.mobile_no :"-" }</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.access_start_date}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.office_unit_no}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.role}</StyledTableCell>
        </>
      )

    } else if (activeType === 'submitted') {
      return (
        <>
          <StyledTableCell onClick={() => navigate(`/review_employee_list/${rowData.employee_batch_log_id}/false`)}>+{rowData.TotalCount}</StyledTableCell >
          <StyledTableCell onClick={() => navigate(`/review_employee_list/${rowData.employee_batch_log_id}/false`)}>
            <Typography
              sx={{
                background: getStatus(rowData.approved_status, 'bgcolor'),
                display: "inline-flex",
                padding: { md: "0 8px", xs: "2px 6px" },
                fontWeight: 400,
                fontSize: { md: "14px", xs: "10px" },
                color: getStatus(rowData.approved_status, 'color'),
                borderRadius: "6px",
              }}
            >
                {getStatus(rowData.approved_status, 'text')}
            </Typography>
         
          </StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/review_employee_list/${rowData.employee_batch_log_id}/false`)}>{rowData.created_at}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/review_employee_list/${rowData.employee_batch_log_id}/false`)}>{rowData.access_start_date}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/review_employee_list/${rowData.employee_batch_log_id}/false`)}>{rowData.submitted_by}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/review_employee_list/${rowData.employee_batch_log_id}/false`)}>{rowData.updated_at}</StyledTableCell>
          {/* <StyledTableCell>
            <Box sx={{ display: "flex", gap: "8px" }}>
              {rowData.role_id === 1 && (
                <img src={Administrator} alt="" />
              )}
              {rowData.role_id === 2 && <img src={FireWarden} alt="" />}
            </Box>
          </StyledTableCell> */}
        </>
      )
    } else if (activeType === 'offboarded') {
      return (
        <>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}> {rowData.TotalCount}</StyledTableCell >
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{moment(rowData.created_at).format("DD-MM-YYYY")}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{moment(rowData?.access_start_date).format("DD-MM-YYYY")}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.submitted_by}</StyledTableCell>
          <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{moment(rowData.updated_at).format("DD-MM-YYYY")}</StyledTableCell>
        </>
      )
    }else if(activeType==="inactive"){
      return(
        <>
        <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.employeeName}</StyledTableCell>
        <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.email}</StyledTableCell>
        <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData?.mobile_no?.trim() ? rowData?.mobile_no : "-" }</StyledTableCell>
        <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{moment(rowData?.offboard_date).format("DD-MM-YYYY")}</StyledTableCell>
        <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.location}</StyledTableCell>
        <StyledTableCell onClick={() => navigate(`/single_employee_review/${rowData.employee_id}/${activeType}`)}>{rowData.job_role}</StyledTableCell>
        </>
      )
    }
  }

  const handlePagination = (event, value) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    let data = { company_id: userData?.company_id, limit: filterData.rows, offset: value - 1, approved_status: activeType,order_by_column:sortData.name,order_by:sortData.type==true ? 'ASC' : sortData.type==false ? 'DESC' : '' };
    getEmpList(data, localStorage.getItem('userToken'))
    setCurrentPage(value)
  }

  return (
    <>
    <Box sx={{ pl: 2, pt: 2}}>
              {activeType!="drafted" &&  TabFilterOptions()}
    </Box>
      
        <TableContainer >
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                {getTableColumns()}
              </TableRow>
            </TableHead>
            {empListData?.length > 0 && 
              <TableBody>
 {empListData.map((item, index) => {
                  return (
                    <StyledTableRow key={item.id}>
                      {renderTableDatas(item)}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            }
          </Table>
        </TableContainer>  

        {!isLoading ? empListData?.length<1 && 
            <Box sx={{ display: "flex", justifyContent: "center",p:5,backgroundColor: 'rgba(143, 146, 161, 0.05)' }}><Typography variant="p" sx={{ mt: 2,fontSize:"20px",color:'#0F2C6F' }}>No data Found </Typography></Box> :
            <Box sx={{display:'flex',justifyContent:'center', alignItems:'center'}}>
            <CircularProgress/>
          </Box>
        }

      {
        empCount>0 ? 
        <Grid container sx={{py:3,backgroundColor:'rgba(143, 146, 161,0.05)'}}>
          <Grid item xs={9} >
              <Pagination sx={{display:'flex',justifyContent:'center'}} page={currentPage} count={Math.ceil(empCount / filterData.rows)} size="large" shape="rounded" onChange={handlePagination} />
          </Grid>
          <Grid item xs={3}  sx={{display:'flex',alignItems:'center'}}>
              <Typography sx={{color:'#0F2C6F',mr:2}}>Show per page</Typography>
              <Select value={filterData.rows} name="rows" size="small" sx={{height:'40px',width:100}} label='Rows'
                          onChange={(e)=>{
                              setFilterData({
                              ...filterData,
                              rows:e.target.value})
                              setCurrentPage(1)
                            }}>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="50">50</MenuItem>
                            <MenuItem value="100">100</MenuItem>
                </Select>
          </Grid>
        </Grid>  : ''
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    empList: state.CommonReducer.empList,
    empCount: state.CommonReducer.empCount,
    isLoading: state.CommonReducer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmpList: (data, token) => dispatch(commonAction.getEmpList(data, token)),
    setStatusNull:()=>dispatch(commonAction.setStatusNull())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveEmployess);
