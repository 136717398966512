import axios from "axios";
import { createAction } from "redux-actions";

export const sessionExpired = createAction("SESSION_EXPIRED");

export const updateState = createAction("UPDATE_BMSSTAFF_REDUCER")

export const setBmsStaffReducernull = ()=>{
    return(dispatch)=>{
        dispatch(updateState())
    }
}

export const getStaffRoleInitiate = createAction("GET_STAFFROLE_INITIATE")
export const getStaffRoleSuccess = createAction("GET_STAFFROLE_SUCCESS")
export const getStaffRoleFailure = createAction("GET_STAFFROLE_FAILURE")

export const getStaffRole = ()=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getStaffRoleInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/getAllRolesForbmsAdmin`,config)
        .then((response)=>{
            if(response.data?.response_status){
                dispatch(getStaffRoleSuccess(response.data?.data_array))
            }else{
                dispatch(getStaffRoleFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getStaffRoleFailure())
            }
        })
    }
}

export const getStaffLevelInitiate = createAction("GET_STAFFLEVEL_INITIATE")
export const getStaffLevelSuccess = createAction("GET_STAFFLEVEL_SUCCESS")
export const getStaffLevelFailure = createAction("GET_STAFFLEVEL_FAILURE")

export const getStaffLevel = ()=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getStaffLevelInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/getBmsAssignedLevelForDropdown`,config)
        .then((response)=>{
            if(response.data?.response_status){
                dispatch(getStaffLevelSuccess(response.data?.data_array))
            }else{
                dispatch(getStaffLevelFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getStaffLevelFailure())
            }
        })
    }
}

export const getAssignedLeveInitiate = createAction("GET_ASSIGNEDLEVEL_INITIATE")
export const getAssignedLevelSuccess = createAction("GET_ASSIGNEDLEVEL_SUCCESS")
export const getAssignedLevelFailure = createAction("GET_ASSIGNEDLEVEL_FAILURE")

export const getAssignedLevel = ()=>{
    let token=localStorage.getItem('userToken')    
    console.log('assignedUnitListtoken ',token) 
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getAssignedLeveInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getCompanyAssignedLevelForDropdown`,'',config)
        .then((response)=>{
            if(response.data?.response_status){
                dispatch(getAssignedLevelSuccess(response.data?.data_array))
            }else{
                dispatch(getAssignedLevelFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getAssignedLevelFailure())
            }
        })
    }
}

export const getAssignedUnitnitiate = createAction("GET_ASSIGNEDUNIT_INITIATE")
export const getAssignedUnitSuccess = createAction("GET_ASSIGNEDUNIT_SUCCESS")
export const getAssignedUnitFailure = createAction("GET_ASSIGNEDUNIT_FAILURE")

export const getAssignedUnit = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getAssignedUnitnitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getCompanyAssignedUnitForDropdown`,data,config)
        .then((response)=>{
            if(response.data?.response_status){
                console.log('response.payload ',response)
                dispatch(getAssignedUnitSuccess(response.data?.data_array))
            }else{
                dispatch(getAssignedUnitFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getAssignedUnitFailure())
            }
        })
    }
}

export const getAccessListInitiate = createAction("GET_ACCESSLIST_INITIATE")
export const getAccessListSuccess = createAction("GET_ACCESSLIST_SUCCESS")
export const getAccessListFailure = createAction("GET_ACCESSLIST_FAILURE")

export const getAccessList = ()=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(getAccessListInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/getAllAccessRights`,config)
        .then((response)=>{
            if(response.data.response_status==1){
                dispatch(getAccessListSuccess(response.data.data_array))
            }else{
                dispatch(getAccessListFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getAccessListFailure())
            }
        })
    }
}

export const getdefaultAccessRightsInitiate = createAction("GET_DEFAULT_ACCESSRIGHTS_INITIATE")
export const getdefaultAccessRightsSuccess = createAction("GET_DEFAULT_ACCESSRIGHTS_SUCCESS")
export const getdefaultAccessRightsFailure = createAction("GET_DEFAULT_ACCESSRIGHTS_FAILURE")

export const getdefaultAccessRights = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getdefaultAccessRightsInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllDefaultAccessRightsByRoleId`,data,config)
        .then((response)=>{
            if(response.data.response_status==1){
                dispatch(getdefaultAccessRightsSuccess(response.data.data_array))
            }else{
                dispatch(getdefaultAccessRightsFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getdefaultAccessRightsFailure())
            }
        })
    }
}

export const addBmsEmpInitiate = createAction("ADD_BMSEMP_INITIATE")
export const addBmsEmpSuccess = createAction("ADD_BMSEMP_SUCCESS")
export const addBmsEmpFailure = createAction("ADD_BMSEMP_FAILURE")

export const addBmsEmp = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    return (dispatch)=>{
        dispatch(addBmsEmpInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/addBmsEmployee`,data,config)
        .then((response)=>{
            if(response.data.response_status=="success"){
                dispatch(addBmsEmpSuccess(response.data.response_json.success_message))
            }else{
                
                dispatch(addBmsEmpFailure(response.data.error_json?.error_message))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(addBmsEmpFailure("Something went wrong "))
            }
        })
    }
}

export const bmsStaffListInitiate = createAction("GET_BMSSTAFFLIST_INITIATE")
export const bmsStaffListSuccess = createAction("GET_BMSSTAFFLIST_SUCCESS")
export const bmsStaffListFailure = createAction("GET_BMSSTAFFLIST_FAILURE")

export const getBmsStaffList = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(bmsStaffListInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllBmsEmployees`,data,config)
        .then((response)=>{
            if(response.data.response_status == 1){
                dispatch(bmsStaffListSuccess(response.data.data_array))
            }else{
                dispatch(bmsStaffListFailure())
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(bmsStaffListFailure())
            }
        })
    }
}

const editStaffOnboardDateInitiate = createAction("EDIT_BMSSTAFF_ONBOARDDATE_INITIATE")
const editStaffOnboardDateSuccess = createAction("EDIT_BMSSTAFF_ONBOARDDATE_SUCCESS")
const editStaffOnboardDateFailure = createAction("EDIT_BMSSTAFF_ONBOARDDATE_FAILURE")

export const editStaffOnboardDate = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(editStaffOnboardDateInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/updateBmsEmployeeOnboardDate`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(editStaffOnboardDateSuccess(response.data))
            }
        })
        .catch((err)=>{
            dispatch(editStaffOnboardDateFailure(err.data))
        })
    }
}

const cancelOnboardStaffInitiate = createAction("CANCEL_BMSSTAFF_ONBOARD_INITIATE")
const cancelOnboardStaffSuccess =  createAction("CANCEL_BMSSTAFF_ONBOARD_SUCCESS")
const cancelOnboardStaffFailure =  createAction("CANCEL_BMSSTAFF_ONBOARD_FAILURE")

export const cancelOnboardStaff = (data)=>{
    let token=localStorage.getItem('userToken')     
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return(dispatch)=>{
        dispatch(cancelOnboardStaffInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/cancelEmployeeOnboard`,data,config)
        .then((response)=>{
            if(response.data){
                dispatch(cancelOnboardStaffSuccess(response.data))
            }
        })
        .catch((err)=>{
            dispatch(cancelOnboardStaffFailure(err.data))
        })
    }
}


