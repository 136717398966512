import { Grid, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, tableCellClasses, Button, IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import * as receptionActions from "../../../redux/actions/visitorsAction";
import * as CommonAction from '../../../redux/actions/commonAction';
import { ReactComponent as BackIcon } from '../../../Assets/icons/back.svg';
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/system';
import moment from 'moment/moment';
import PrintIcon from '@mui/icons-material/Print';
import { TbMailForward } from "react-icons/tb";
import { MdOutlineSms } from "react-icons/md";
import  ResendMailIcon  from '../../../Assets/icons/email.png';
import { ReactComponent as ResendSmsIcon } from '../../../Assets/icons/submit.svg';
import ToastAlert from "../../common/Alert";

const StyledTableCell = styled(TableCell)({
  color:"#0F2C6F",
  fontSize:"13px",
  [`&.${tableCellClasses.head}`]:{
    fontWeight:550
  },
  [`&.${tableCellClasses.body}`]:{
    fontWeight:500
  }
})

const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(odd)":{
    backgroundColor:"#F5F8FF"
  }
})

const ReviewManageInvite = ({ getInvitationDetails,invitationDetails,resendInviteMail,resendInviteMailNullAction,resendInviteMailStatus }) => {
  let navigate = useNavigate()
  let { tabValue, invitation_id, invitation_type } = useParams();
  const [showIframe,setShowIframe] = useState(false)
  const [url,setUrl] = useState(null)
  const [empOffStatus, setEmpOffStatus] = useState({ message: '', status: false, color: '', icon: '' })

  useEffect(() => {
    let requestData = {
      id: invitation_id,
      type: invitation_type
    }
    getInvitationDetails(requestData)
  }, [])

  useEffect(()=>{
    setTimeout(()=>{
        setShowIframe(false)
    },2000)
  },[showIframe])

  
  useEffect(() => {
    if(resendInviteMailStatus?.response != null) {
    if(resendInviteMailStatus?.response?.response_status == 'success') {
        setEmpOffStatus({ message: resendInviteMailStatus?.response?.response_message, status: true, color: 'green', icon: 'tick' })
        setTimeout(()=> {
            resendInviteMailNullAction()
            setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
        },5000)
    } else if(resendInviteMailStatus?.response?.response_status == 'fail') {
        setEmpOffStatus({ message: resendInviteMailStatus?.response?.response_message, status: true, color: 'red', icon: 'close' })
        setTimeout(()=> {
            resendInviteMailNullAction()
            setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
        },5000)
    } else {
        setEmpOffStatus({ message: 'something went wrong', status: true, color: 'red', icon: 'close' })
        setTimeout(()=> {
            resendInviteMailNullAction()
            setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
        },5000)
    }
}
},[resendInviteMailStatus])

const handleResendInviteMail = (invitation_id) => {
    resendInviteMail({sent_invitations_id:invitation_id})
}


  return (
    <>
    {empOffStatus.status && <ToastAlert message={empOffStatus.message} status={empOffStatus.status} color={empOffStatus.color} icon={empOffStatus.icon} />}
       {invitationDetails=='fail' ?  
       <Box sx={{background: 'rgba(143, 146, 161,0.05)',height:"100%",width:'100%',display:'flex',justifyContent:"center",alignItems:"center"}}>
        <Typography sx={{fontSize:'22px',color:'#0F2C6F'}}>No Data Found</Typography>
        </Box> : 
       <>
      <Grid container sx={{ p: 9, background: 'rgba(143, 146, 161,0.05)', fontFamily: 'Inter' }}>
        <Grid item xs={12} style={{ cursor: 'pointer' }}>
          <BackIcon onClick={() => navigate(-1)} />
        </Grid>
        <Grid item xs={12} sx={{ maxHeight: 2 }}>
          <Typography style={{ fontSize: "26px", fontWeight: "500", color: '#0F2C6F' }}>
            Invitation -  {invitationDetails?.company_name}
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: "500", color: '#0F2C6F' }}>
            Assigned on {invitationDetails?.from_date_time!=null && moment.utc(invitationDetails?.from_date_time).format("DD MMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 9, pt: 0, background: 'rgba(143, 146, 161,0.05)', fontFamily: 'Inter' }}>
        <Grid item xs={12} sx={{ background: '#FFFFFF', p: 3, }}>
          <Typography style={{ fontSize: "18px", fontWeight: "500", color: '#0F2C6F' }}>
           {invitationDetails?.invitations_title}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ background: '#FFFFFF', p: 3, pt: 0, }}>
          <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
            Description 
          </Typography>
        </Grid>
        <Grid item xs={9} sx={{ background: '#FFFFFF', p: 3, pt: 0, }}>
          <Typography style={{ fontSize: "12px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
          {invitationDetails?.notes_to_visitor}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ background: '#FFFFFF', p: 3, }}>
          <Typography style={{ fontSize: "18px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
            Invited by
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ background: '#FFFFFF', p: 3, pt: 0 }}>
          <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
            {invitationDetails?.invited_by}
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
          {invitationDetails?.job_title}
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
            Office Unit : {invitationDetails?.comp_office_unit}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ background: '#FFFFFF', p: 3, }}>
          <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
            Office : {invitationDetails?.office_country_code} {invitationDetails?.office_phone_no}
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
            Email: {invitationDetails?.invited_by_email}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ background: '#FFFFFF', p: 3, }}>
          <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
          {invitationDetails?.company_name} • {invitationDetails?.comp_office_unit}
          </Typography>
          <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
          {invitationDetails?.job_title}
          </Typography>
        </Grid>
        <Grid item xs={3} sx={{ background: '#FFFFFF', p: 3, }}>
        </Grid>
        <Grid item xs={7} sx={{ background: '#FFFFFF', p: 3, }}>
          <Box sx={{ border: '1px solid #DBDFE9', borderRadius: '6px', minHeight: 100, display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
            <Box sx={{ p: 2, }}>
              <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#0F2C6F', fontFamily: 'Inter' }}>
                Invitation Period
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                Start :{invitationDetails?.from_date_time!=null &&  moment.utc(invitationDetails?.from_date_time).local().format("DD MMM YYYY")} {moment.utc(invitationDetails?.from_date_time).local().format("HH:mm")} 
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                End : {invitationDetails?.to_date_time!=null && moment.utc(invitationDetails?.to_date_time).local().format("DD MMM YYYY")} {moment.utc(invitationDetails?.to_date_time).local().format("HH:mm")}
              </Typography>
            </Box>
            <Box sx={{ p: 2, pl: 6 }}>
              <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
               {invitationDetails?.visitors?.length} Visitor
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                Company: {invitationDetails?.company_name}
              </Typography>
              <Typography style={{ fontSize: "12px", fontWeight: "400", color: '#0F2C6F', fontFamily: 'Inter' }}>
                Unit: {invitationDetails?.comp_office_unit}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={5} sx={{ background: '#FFFFFF', p: 3, }} />
        <Grid item xs={12} sx={{backgroundColor:"#FFFFFF",p:3}}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Contact</StyledTableCell>
                  <StyledTableCell>Time In</StyledTableCell>
                  <StyledTableCell>Time Out</StyledTableCell>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell>QR</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invitationDetails?.visitors?.map((value)=>(
                    <StyledTableRow>
                    <StyledTableCell sx={{display:'flex'}}><Box sx={{alignSelf:'flex-end'}}>{value.name}</Box> {(invitationDetails?.inv_type == 'Single_vistor' || invitationDetails?.inv_type == 'Tenant_Invite') && <Box>{value.entry_mode == 1 ? (<Tooltip title='Resend Sms'><IconButton  disabled={resendInviteMailStatus?.isLoading} onClick={() => handleResendInviteMail(value.sent_invitations_id)} sx={{height:'20px'}}><Box sx={{ width:'20px', height:'24px'}}><ResendSmsIcon style={{height:'20px', width:'20px'}}/></Box></IconButton></Tooltip>)
                      :(<Tooltip title='Resend Mail'><IconButton  disabled={resendInviteMailStatus?.isLoading} onClick={() => handleResendInviteMail(value.sent_invitations_id)} sx={{height:'20px', padding:'0 8px'}}><Box sx={{ width:'20px'}}><img style={{width:'100%', height:'100%'}} src={ResendMailIcon}/></Box></IconButton></Tooltip>)    
                    }</Box>}</StyledTableCell>
                    <StyledTableCell>{value.email}</StyledTableCell>
                    <StyledTableCell>{value.last_in_time == null ? " _" : moment.utc(value.last_in_time).local().format('HH:mm')}</StyledTableCell>
                    <StyledTableCell>{value.last_out_time== null ? " _" :moment.utc(value.last_out_time).local().format('HH:mm')}</StyledTableCell>
                    <StyledTableCell>{moment.utc(invitationDetails?.from_date_time).local().format("DD/MM/YYYY")}</StyledTableCell>
                    <StyledTableCell>
                    {value.url!='' &&   
                    <PrintIcon onClick={()=>{
                           setUrl(value?.url)
                           setShowIframe(true)
                      }} 
                      ></PrintIcon>}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {showIframe && <iframe src={url} style={{visibility:'hidden'}} />}
        </Grid>
      </Grid>
    </>
}
    </>
   
  )
}
const mapStateToProps = (state) => {
  return {
    invitationDetails:state.ReceptionReducer.invitationDetails,
    resendInviteMailStatus: state.CommonReducer.resendInviteMail
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getVisitorsList: (data) => { dispatch(receptionActions.getVisitorsList(data)) },
    getInvitationDetails: (requestData) => { dispatch(receptionActions.getInvitationDetails(requestData)) },
    resendInviteMail: (data) => (dispatch(CommonAction.resendInviteMail(data))),
    resendInviteMailNullAction: () => dispatch(CommonAction.resendInviteMailNullAction()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReviewManageInvite)