import axios from "axios";
import { createAction } from "redux-actions";
const getToken = () => {
    const token = localStorage.getItem('userToken')
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return config;
}
export const sessionExpired = createAction("SESSION_EXPIRED");
export const receptionActionNull = createAction("RECEPTION_ACTION_NULL");
export const receptionStateToInitial = (requestData) => {
    return (dispatch) => {
        dispatch(receptionActionNull());
    }
}

export const getVisitorAddReasonInitiate = createAction("REASON_FOR_VISITOR_INITIATE");
export const getVisitorAddReasonSuccess = createAction("REASON_FOR_VISITOR_SUCCESS");
export const getVisitorAddReasonFailure = createAction("REASON_FOR_VISITOR_FAILURE");

export const getReasonForVisitors = (requestData) => {
    return (dispatch) => {
        dispatch(getVisitorAddReasonInitiate());
        axios.get(`${process.env.REACT_APP_API_URL}/getVisitingreasons`,)
            .then((response) => {
                if (response.data.response_status === "success") {
                    dispatch(getVisitorAddReasonSuccess(response.data.response_json));
                } else {
                    dispatch(getVisitorAddReasonFailure());
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(getVisitorAddReasonFailure());
                }

            });
    };
};

export const getOrganizationsInitiate = createAction("ORGANIZATION_LIST_INITIATE");
export const getOrganizationsSuccess = createAction("ORGANIZATION_LIST_SUCCESS");
export const getOrganizationsFailure = createAction("ORGANIZATION_LIST_FAILURE");

export const getOrganizationList = () => {
    return (dispatch) => {
        dispatch(getOrganizationsInitiate());
        axios.get(`${process.env.REACT_APP_API_URL}/CompanyListKiosk`,)
            .then((response) => {
                if (response.data.response_status === "success") {
                    dispatch(getOrganizationsSuccess(response.data.response_json));
                } else {
                    dispatch(getOrganizationsFailure());
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(getOrganizationsFailure());
                }

            });
    };
};

export const AddSingleVisitorInitiate = createAction("ADD_SINGLE_VISITOR_INITIATE");
export const AddSingleVisitorSuccess = createAction("ADD_SINGLE_VISITOR_SUCCESS");
export const AddSingleVisitorFailure = createAction("ADD_SINGLE_VISITOR_FAILURE");
export const addSingleVisitors = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(AddSingleVisitorInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/single_visitor`, requestData, getToken(), bodyParams)
            .then((response) => {
                dispatch(AddSingleVisitorSuccess(response.data));
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(AddSingleVisitorFailure());
                }

            });
    };
};
export const inviteSpecialAccessInitiate = createAction("INVITE_SPECIAL_ACCESS_INITIATE");
export const inviteSpecialAccessSuccess = createAction("INVITE_SPECIAL_ACCESS_SUCCESS");
export const inviteSpecialAccessFailure = createAction("INVITE_SPECIAL_ACCESS_FAILURE");
export const inviteSpecialAccess = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(inviteSpecialAccessInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/special_access`, requestData, getToken(), bodyParams,)
            .then((response) => {
                dispatch(inviteSpecialAccessSuccess(response.data));
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(inviteSpecialAccessFailure());
                }

            });
    };
};


export const visitorsListInitiate = createAction("VISITORS_LIST_INITIATE");
export const visitorsListSuccess = createAction("VISITORS_LIST_SUCCESS");
export const visitorsListFailure = createAction("VISITORS_LIST_FAILURE");
export const getVisitorsList = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(visitorsListInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/manage_visitors`, requestData, getToken(), bodyParams,)
            .then((response) => {
                if (response.data?.response_status=='success') {
                    dispatch(visitorsListSuccess(response.data));
                } else {
                    dispatch(visitorsListFailure());
                }

            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(visitorsListFailure());
                }

            });
    };
};

export const visitorsBmsListInitiate = createAction("VISITORS_BMS_LIST_INITIATE");
export const visitorsBmsListSuccess = createAction("VISITORS_BMS_LIST_SUCCESS");
export const visitorsBmsListFailure = createAction("VISITORS_BMS_LIST_FAILURE");
export const getVisitorsBmsList = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(visitorsBmsListInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/manage_visitors_for_bms`, requestData, getToken(), bodyParams,)
            .then((response) => {
                if (response.data?.response_status=='success') {
                    dispatch(visitorsBmsListSuccess(response.data));
                } else {
                    dispatch(visitorsBmsListFailure());
                }

            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(visitorsBmsListFailure());
                }

            });
    };
};

export const visitorsListRefreshInitiate = createAction("VISITORS_LIST_REFRESH_INITIATE");
export const visitorsListRefreshSuccess = createAction("VISITORS_LIST_REFRESH_SUCCESS");
export const visitorsListRefreshFailure = createAction("VISITORS_LIST_REFRESH_FAILURE");
export const getVisitorsListRefresh = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(visitorsListRefreshInitiate());
        axios.get(`${process.env.REACT_APP_API_URL}/getBuildingsStatus`, getToken(), bodyParams,)
            .then((response) => {
                if (response.data?.response_status=='success') {
                    dispatch(visitorsListRefreshSuccess(response.data));
                } else {
                    dispatch(visitorsListRefreshFailure());
                }

            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(visitorsListRefreshFailure());
                }

            });
    };
};


export const inviteAuthorityInitiate = createAction("INVITE_AUTHORITIES_INITIATE");
export const inviteAuthoritySuccess = createAction("INVITE_AUTHORITIES_SUCCESS");
export const inviteAuthorityFailure = createAction("INVITE_AUTHORITIES_FAILURE");
export const getInviteAuthorities = () => {
    return (dispatch) => {
        dispatch(inviteAuthorityInitiate());
        axios.get(`${process.env.REACT_APP_API_URL}/BMSList`, getToken())
            .then((response) => {
                if (response.data?.response_status === 'success') {
                    dispatch(inviteAuthoritySuccess(response.data?.response_data));
                } else {
                    dispatch(inviteAuthorityFailure());
                }

            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(inviteAuthorityFailure());
                }

            });
    };
};
export const invitationDetailsByIdInitiate = createAction("INVITATION_DETAILS_BY_ID_INITIATE");
export const invitationDetailsByIdSuccess = createAction("INVITATION_DETAILS_BY_ID_SUCCESS");
export const invitationDetailsByIdFailure = createAction("INVITATION_DETAILS_BY_ID_FAILURE");
export const getInvitationDetails = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(invitationDetailsByIdInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/SingleInvitationDisplay`, requestData, getToken(), bodyParams,)
            .then((response) => {
                if (response.data?.response_status=='success') {
                    dispatch(invitationDetailsByIdSuccess(response.data.response_data));
                } else {
                    dispatch(invitationDetailsByIdFailure(response.data?.response_status));
                }

            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(invitationDetailsByIdFailure());
                }

            });
    };
}

export const inviteVisitorTenantInitiate = createAction("INVITE_VISITOR_ON_TENANT_INITIATE");
export const inviteVisitorTenantSuccess = createAction("INVITE_VISITOR_ON_TENANT_SUCCESS");
export const inviteVisitorTenantFailure = createAction("INVITE_VISITOR_ON_TENANT_FAILURE");
export const inviteVisitorOnTenant = (requestData) => {
    let bodyParams = {
        key: 'value'
    }
    return (dispatch) => {
        dispatch(AddSingleVisitorInitiate());
        axios.post(`${process.env.REACT_APP_API_URL}/addInvitation`, requestData, getToken(), bodyParams,)
            .then((response) => {
                dispatch(AddSingleVisitorSuccess(response.data));
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    dispatch(sessionExpired('Session Expired'));
                } else {
                    dispatch(AddSingleVisitorFailure());
                }

            });
    };
};

export const getTenantVisitorsListInitiate = createAction('TENANT_INVITERS_LIST_INITIATE');
export const getTenantVisitorsListSuccess = createAction('TENANT_INVITERS_LIST_SUCCESS')
export const getTenantVisitorsListFailure = createAction('TENANT_INVITERS_LIST_FAILURE')

export const getTenantVisitorsList = (requestData) => {
    return (dispatch) => {
        dispatch(getTenantVisitorsListInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllVisitorsByCompanyId`, requestData, getToken())
            .then((response) => {
                dispatch(getTenantVisitorsListSuccess(response.data))
            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
}


export const getTenantVisitorsDetailsInitiate = createAction('TENANT_INVITERS_DETAILS_INITIATE');
export const getTenantVisitorsDetailsSuccess = createAction('TENANT_INVITERS_DETAILS_SUCCESS')
export const getTenantVisitorsDetailsFailure = createAction('TENANT_INVITERS_DETAILS_FAILURE')

export const getTenantVisitorsDetails = (requestData) => {
    return (dispatch) => {
        dispatch(getTenantVisitorsListInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getInvitationDetailsByIdForWeb`, requestData, getToken())
            .then((response) => {
                dispatch(getTenantVisitorsDetailsSuccess(response.data))
            })
            .catch((error) => {
                // console.log(error.response);
            })
    }
}

const deleteVisitorInitiate = createAction("DELETE_VISITOR_INITIATE")
const deleteVisitorSuccess = createAction("DELETE_VISITOR_SUCCESS")
const deleteVisitorFailure = createAction("DELETE_VISITOR_FAILURE")

export const deleteVisitor = (data)=>{
    return(dispatch)=>{
        dispatch(deleteVisitorInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/deleteVisitor`, data, getToken())
        .then((response) => {
            if(response.data.response_status=="fail"){
                dispatch(deleteVisitorFailure(response.data.message))
            }else{
                dispatch(deleteVisitorSuccess(response.data.message))
            }
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                dispatch(sessionExpired('Session Expired'));
            }else{
                 dispatch(deleteVisitorFailure())
            }
        })
    }
}

const addmultiplevisitorInitiate = createAction("ADD_MULTIPLE_VISITOR_INITIATE")
const addmultiplevisitorSuccess = createAction("ADD_MULTIPLE_VISITOR_SUCCESS")
const addmultiplevisitorFailure = createAction("ADD_MULTIPLE_VISITOR_FAILURE")

export const addMultipleVisitor = (data)=>{
    return(dispatch)=>{
        dispatch(addmultiplevisitorInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/sendInvitationsToMultipleVisitor`, data, getToken())
        .then((response) => {
            if(response.data.response_status==1){
                dispatch(addmultiplevisitorSuccess(response.data.message))
            }else{
                dispatch(addmultiplevisitorFailure('Something went wrong'))
            }
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                dispatch(sessionExpired('Session Expired'));
            }else{
                 dispatch(addmultiplevisitorFailure())
            }
        })
    }
}

const getAllUsersInitiate = createAction("GET_ALLUSERS_INITIATE")
const getAllUsersSuccess = createAction("GET_ALLUSERS_SUCCESS")
const getAllUsersFailure = createAction("GET_ALLUSERS_FAILURE")

export const getAllUsers = (data)=>{
    return(dispatch)=>{
        dispatch(getAllUsersInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllUsers`, data, getToken())
        .then((response) => {
            if(response.data.response_json){
                dispatch(getAllUsersSuccess(response.data.response_json))
            }else{
                dispatch(getAllUsersFailure([]))
            }
        })
        .catch((error) => {
            if (error?.response?.status === 401) {
                dispatch(sessionExpired('Session Expired'));
            }else{
                 dispatch(getAllUsersFailure([]))
            }
        })
    }
}

export const getTicketListInitiate = createAction("GET_TICKETLIST_INITIATE")
export const getTicketListSuccess = createAction("GET_TICKETLIST_SUCCESS")
export const getTicketListFailure = createAction("GET_TICKETLIST_FAILURE")

export const getTicketList = ()=>{
    return(dispatch)=>{
        dispatch(getTicketListInitiate())
        axios.get(`${process.env.REACT_APP_API_URL}/ticket_list`,getToken())
        .then((response)=>{
            if(response.data.response_status=='success'){
                dispatch(getTicketListSuccess(response.data.response_json))
            }else{
                dispatch(getTicketListFailure([]))
            }
        })  
        .catch((error)=>{
            if (error?.response?.status === 401) {
                dispatch(sessionExpired('Session Expired'));
            }else{
                 dispatch(getTicketListFailure([]))
            }
        })
    }
}
