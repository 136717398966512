import {
  Box,
  Grid,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {
  StyledTab,
  SecondaryTypography,
  PrimaryTypography,
} from "../Events_Promotions/CreateEvent";
import WifiIcon from "@mui/icons-material/Wifi";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import BatteryCharging50Icon from "@mui/icons-material/BatteryCharging50";
import DOMPurify from "dompurify";
import moment from "moment";
import "./quill-custom.css";
import * as CommonAction from "../../redux/actions/commonAction";
import { connect } from "react-redux";
import ToastAlert from "../../Pages/common/Alert";

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ indent: "-1" }, { indent: "+1" }],
];
const module = {
  toolbar: toolbarOptions,
  keyboard: {
    bindings: {
      "list autofill": {
        prefix: /^\s*()$/,
      },
    },
  },
};

function Contact({
  getContactConfigData,
  getContactConfig,
  updatedContactConfigData,
  updateContactConfig,
  updateConfigContactNullAction,
}) {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [contactDetails, setContactDetails] = useState("");
  const [errors, setErrors] = useState({ title: "", contactDetails: "" });
  const [tabValue, setTabValue] = useState(0);
  const [updateStatus, setUpdateStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  console.log("contactDetails ", contactDetails);
  const handleContactValue = (value) => {
    setContactDetails(value);
    setErrors((prev) => ({ ...prev, contactDetails: "" }));
  };

  const truncate = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    setErrors((prev) => ({ ...prev, title: "" }));
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography variant="span">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const validation = () => {
    let error = [];
    if (title == "") {
      setErrors((prev) => ({ ...prev, title: "Enter Title" }));
      error.push(true);
    } else {
      setErrors((prev) => ({ ...prev, title: "" }));
      error.push(false);
    }
    if (!contactDetails || contactDetails == "<p><br></p>") {
      setErrors((prev) => ({ ...prev, contactDetails: "Enter Description" }));
      error.push(true);
    } else {
      setErrors((prev) => ({ ...prev, contactDetails: "" }));
      error.push(false);
    }
    console.log("error ", error);
    let errorStatus = error.includes(true);
    return !errorStatus;
  };

  const handleSave = () => {
    const reqObj = {
      title: title,
      description: contactDetails,
    };
    const validate = validation();
    console.log("validation ", validate);
    if (validate) updateContactConfig(reqObj);
  };

  useEffect(() => {
    console.log("updatedContactConfigData ", updatedContactConfigData);
    if (updatedContactConfigData.response == "Contacts saved Successfully") {
      setUpdateStatus({
        message: updatedContactConfigData?.response,
        status: true,
        color: "green",
        icon: "close",
      });
      setTimeout(() => {
        setUpdateStatus({ message: "", status: false, color: "", icon: "" });
        updateConfigContactNullAction();
      }, 3000);
    } else if (updatedContactConfigData.response) {
      setUpdateStatus({
        message: updatedContactConfigData?.response,
        status: true,
        color: "red",
        icon: "close",
      });
      setTimeout(() => {
        setUpdateStatus({ message: "", status: false, color: "", icon: "" });
        updateConfigContactNullAction();
      }, 3000);
    }
  }, [updatedContactConfigData]);

  useEffect(() => {
    console.log("getContactConfigData ", getContactConfigData);
    if (
      getContactConfigData?.response?.contactConfigArrayList?.title &&
      getContactConfigData?.response?.contactConfigArrayList?.description
    ) {
      setTitle(getContactConfigData?.response?.contactConfigArrayList?.title);
      setContactDetails(
        getContactConfigData?.response?.contactConfigArrayList?.description
      );
    }
  }, [getContactConfigData]);

  useEffect(() => {
    getContactConfig();
  }, []);

  return (
    <>
      <ToastAlert
        message={updateStatus?.message}
        status={updateStatus?.status}
        color={updateStatus?.color}
        icon={updateStatus?.icon}
      />
      <Grid
        container
        sx={{
          paddingTop: { md: "60px", xs: "20px" },
          background: "#F9FAFA",
          minHeight: "100%",
        }}
      >
        <Grid item lg={2} md={1} sm={1} xs={0.5} />
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", color: "#0F2C6F" }}
          lg={8}
          md={10}
          sm={10}
          xs={10}
        >
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
            cursor="pointer"
          />
          <Box sx={{ marginBottom: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: "#002D74",
                  margin: "30px 0",
                  fontSize: "26px",
                  fontWeight: 500,
                  height: "32px",
                }}
              >
                Manage Contact Details
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "14px", color: "#6F80A9" }}>
              You may add contact details here
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                borderBottom: "1px solid #DBDFE9",
                borderRadius: "8px 8px 0px 0px",
                backgroundColor: "white",
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{ style: { background: "#123FA9" } }}
              >
                <StyledTab
                  label="Create"
                  {...a11yProps(0)}
                  sx={{
                    textTransform: "none",
                    color: "#123FA9",
                    fontSize: "16px",
                  }}
                />
                <StyledTab
                  label="Preview"
                  {...a11yProps(1)}
                  sx={{
                    textTransform: "none",
                    color: "#123FA9",
                    fontSize: "16px",
                  }}
                />
              </Tabs>
            </Box>
            {/* <TabPanel value={tabValue} index={0}> */}
            {tabValue == 0 && (
              <>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    backgroundColor: "white",
                    padding: "10px",
                  }}
                >
                  <Box sx={{ width: "100%", mt: 5 }}>
                    <TextField
                      label="Title"
                      value={title}
                      onChange={handleTitle}
                      fullWidth
                      margin="normal"
                    />
                    <Typography
                      variant="p"
                      sx={{
                        fontSize: "14px",
                        color: "red",
                        display: "block",
                        mb: "5px",
                      }}
                    >
                      {errors?.title}
                    </Typography>
                    <ReactQuill
                      style={{ height: "220px" }}
                      placeholder="Description"
                      value={contactDetails}
                      onChange={handleContactValue}
                      modules={module}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "blockquote",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "color",
                        "align",
                        "code-block",
                      ]}
                    />
                    {errors?.contactDetails != "" && (
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: "14px",
                          color: "red",
                          display: "block",
                          mt: "50px",
                        }}
                      >
                        {errors?.contactDetails}
                      </Typography>
                    )}
                  </Box>
                  {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: errors?.contactDetails !="" ? 0 : 6 }}> */}
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      justifyContent: "flex-end",
                      mt: errors?.contactDetails != "" ? 0 : 6,
                      mb: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        color: "white",
                        borderRadius: "8px",
                        height: "50px",
                        backgroundColor: "#0F2C6F",
                        "&:hover": { backgroundColor: "#0F2C6F" },
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Box>
                  {/* </Box> */}
                </Box>
                {/* </TabPanel> */}
              </>
            )}

            <TabPanel value={tabValue} index={1}>
              <>
                <Grid
                  container
                  sx={{
                    backgroundColor: "#FFFFFF",
                    p: 2,
                    pb: "24px",
                    color: "black",
                  }}
                >
                  <Grid item xs={12}>
                    <PrimaryTypography>Application Preview</PrimaryTypography>
                  </Grid>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      sx={{ ml: 4, maxWidth: "320px", color: "black" }}
                    >
                      <Box
                        sx={{
                          height: "600px",
                          overflowX: "hidden",
                          overflowY: "auto",
                          overflowY: "auto",
                          "&::-webkit-scrollbar": { display: "none" },
                          msOverflowStyle: "none",
                          scrollbarWidth: "none",
                          width: "100%",
                          borderRadius: "8px",
                          border: "1px solid #F3F4F8",
                          mt: 2,
                        }}
                      >
                        <Box
                          sx={{
                            zIndex: 1,
                            position: "sticky",
                            top: 0,
                            height: "250px",
                            width: "320px",
                            background: "rgb(17,57,154)",
                            background:
                              "linear-gradient(90deg, rgba(9,9,121,1) 42%, rgba(0,212,255,1) 100%)",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        >
                          <Box
                            sx={{
                              px: 2,
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "15px", color: "white" }}
                            >
                              {moment(new Date()).local().format("HH:mm")}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                              <WifiIcon
                                style={{ fontSize: "15px", color: "#FFFFFF" }}
                              />
                              <SignalCellularAltIcon
                                style={{
                                  fontSize: "15px",
                                  mx: 1,
                                  color: "#FFFFFF",
                                }}
                              />
                              <BatteryCharging50Icon
                                style={{ fontSize: "15px", color: "#FFFFFF" }}
                              />
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              position: "absolute",
                              bottom: "0%",
                              px: 2,
                              overflowWrap: "anywhere!important",
                              width: "95%",
                              top: "50%",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#FFFFFF",
                                top: "50%",
                                fontSize: "24px",
                                wordWrap: "break-word",
                                fontFamily: "Inter",
                                fontWeight: "200 !important",
                                overflowWrap: "anywhere!important",
                              }}
                            >
                              {truncate(title,20)}
                            </Typography>
                          </Box>
                        </Box>

                        <Box sx={{ py: 1, px: 2 }}>
                          <Typography
                            style={{ color: "black !important" }}
                            sx={{
                              color: "black",
                              whiteSpace: "pre-line",
                              wordBreak: "break-word",
                              lineHeight: "24px",
                              margin: "0!important",
                            }}
                          >
                            <p
                              className="dangerous-html-content"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(contactDetails),
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getContactConfigData: state.CommonReducer.getContactConfigData,
    updatedContactConfigData: state.CommonReducer.updatedContactConfigData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactConfig: () => dispatch(CommonAction.getContactConfig()),
    updateContactConfig: (data) =>
      dispatch(CommonAction.updateContactConfig(data)),
    updateConfigContactNullAction: () =>
      dispatch(CommonAction.updateConfigContactNullAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
