import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as commonAction from "../../redux/actions/commonAction";
import {
    Box,
    Grid,
    Pagination,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import {
    BMS_CO_TENANT_EMPLIST_OFFBOARD_COLUMNS,
    BMS_CO_TENANT_EMPLIST_ACTIVE_COLUMNS,
    BMS_CO_TENANT_EMPLIST_ONBOARD_COLUMNS
} from '../../components/Data/Data.js'
import { ReactComponent as RedTriangleIcon } from "../../Assets/icons/RedTriangleIcon.svg";
import moment from "moment/moment";
import { getYYYYMMDDFormat } from "../../Utils/utils";
import { getStatus } from "../../Utils/ActiveStatus";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: [theme.palette.common.white],
        fontSize: "13px",
        color: '#0F2C6F',
        fontWeight: 550,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        padding: "8px 12px",
        textAlign: "left",
        color: '#0F2C6F',

    },

    [theme.breakpoints.up("md")]: {
        [`&.${tableCellClasses.body}`]: {
            fontSize: "14px",
            padding: "16px",
        },
        [`&.${tableCellClasses.head}`]: {
            fontSize: "14px",
        },
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    background: "white",
    cursor: 'pointer',
    "&:nth-of-type(even)": {
        backgroundColor: "#F3F4F8",
    },
}));
const TenantEmpList = ({ getTenantEmp, tenantEmp, tenantCount, dataType, tabValue, filterData }) => {

    const navigate = useNavigate();
    const [employeeList, setEmployeeList] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [updatedFilterData, setUpdatedFilterData] = React.useState({});

    React.useEffect(() => {
        let timeOut;     
         
        
        timeOut = setTimeout(() => {
            setCurrentPage(0)
            let userData = JSON.parse(localStorage.getItem("userData"));
            let data = {
                company_id: userData?.company_id,
                limit: 10,
                offset:filterData?.offset||0,
                approved_status: dataType,
                status: filterData.status,
                date: (filterData.date !== '' && filterData.date!=null) ? getYYYYMMDDFormat(filterData.date) : null,
                search: filterData.search,
                sort_by:filterData.sort
            };
            setUpdatedFilterData(data)
            if (userData) {
                getTenantEmp(data);
            } else {
                navigate("/");
            }

        }, 1000);
        
        
        
        return () => clearTimeout(timeOut);
    }, [filterData]);

    React.useEffect(() => {
        // if (tenantEmp) {
            setEmployeeList(tenantEmp);
        // }

    }, [tenantEmp, tenantCount]);

    const handlePagignation = (event, value) => {
        setCurrentPage(value);
        let data = {
            limit: 10,
            offset: value - 1,
            approved_status: dataType,
            status: filterData.status,
            date: filterData.date !== '' ? getYYYYMMDDFormat(filterData.date) : '',
            search: filterData.search,
            sort_by:filterData.sort
        };
        setUpdatedFilterData(data);
        getTenantEmp(data, localStorage.getItem("userToken"));
    };

    const getColumns = () => {
        let columns = dataType === 'submitted' ? BMS_CO_TENANT_EMPLIST_ONBOARD_COLUMNS :
            dataType === 'approved' ? BMS_CO_TENANT_EMPLIST_ACTIVE_COLUMNS : BMS_CO_TENANT_EMPLIST_OFFBOARD_COLUMNS
        return columns;
    }
    const getTableColumns = (dataType) => {
        let columns = getColumns()
        return (
            columns.map((item) => (
                item.column_name !== 'checkBox' ? <StyledTableCell>{item.column_name}</StyledTableCell> : ''
            ))

        )
    }

    const renderReview=(id)=>{
        sessionStorage.setItem('filterData',JSON.stringify(updatedFilterData))
        navigate(`/review_employee_list/${id}/${tabValue}/true`)
    }
    const renderTableRow = (item) => {
  
        if (dataType === 'submitted') {
            return (<>
                {/* <StyledTableCell sx={{ color: "#2764F4" }}  onClick={() => renderReview(item.company_id)}>
                    OB-{item.company_id}
                </StyledTableCell> */}
                <StyledTableCell onClick={() => renderReview(item.employee_batch_log_id)}>{item?.company_name?.toUpperCase()}</StyledTableCell>
                <StyledTableCell onClick={() => renderReview(item.employee_batch_log_id
)}>+{item.employee_count}</StyledTableCell>
                <StyledTableCell onClick={() => renderReview(item.employee_batch_log_id
)}>
                    <Typography
                        variant="p"
                        component="span"
                        sx={{
                            background:getStatus(item.approved_status,'bgcolor'),
                            display: "inline-flex",
                            padding: { md: "0 8px", xs: "2px 6px" },
                            fontWeight: 400,
                            fontSize: { md: "14px", xs: "10px" },
                            color:getStatus(item.approved_status,"color"),
                            borderRadius: "6px",
                        }}
                    >
                        {getStatus(item.approved_status,"text")}
                    </Typography>
                </StyledTableCell>
                <StyledTableCell onClick={() => renderReview(item.employee_batch_log_id
)}>{item.submitted_by}</StyledTableCell>
                <StyledTableCell onClick={() => renderReview(item.employee_batch_log_id
)}>
                    {moment(item.created_at).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell onClick={() => renderReview(item.employee_batch_log_id
)}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: { md: "10px", xs: "4px" },
                        }}
                    >
                        {moment(item.access_start_date).format("DD/MM/YYYY")}
                        {/* <RedTriangleIcon /> */}
                    </Box>
                </StyledTableCell>
            </>)

        } else if (dataType === 'approved') {
            return (<>
                <StyledTableCell >{item?.company_name?.toUpperCase()}</StyledTableCell>
                <StyledTableCell >{item.employee_count}</StyledTableCell>
                <StyledTableCell >{item.submitted_by}</StyledTableCell>
                <StyledTableCell >
                    {moment(item.access_start_date).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell >{item.manageName}</StyledTableCell>
                <StyledTableCell >
                    #{item.level_no}-{item.unit_no}
                </StyledTableCell></>)

        } else {
            return (<>
                <StyledTableCell>{item?.company_name?.toUpperCase()}</StyledTableCell>
                <StyledTableCell>{item.emp_name}</StyledTableCell>
                <StyledTableCell> {moment(item.access_start_date).format("DD/MM/YYYY")}</StyledTableCell>
                <StyledTableCell>
                    {moment(item.offboard_date).format("DD/MM/YYYY")}
                </StyledTableCell>
                <StyledTableCell>{item.manageName}</StyledTableCell>
            </>)
        }
    }
    return (
        <Box>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        {getTableColumns(dataType)}
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {employeeList && employeeList?.map((item,index) => (
                        <StyledTableRow key={index}>
                            {renderTableRow(item)}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                    {employeeList?.length < 1 ? <Typography variant='p' sx={{fontSize:"20px",color:'#0F2C6F'}}>No Data Found</Typography> : ''}
                    {tenantCount > 0 ? <Pagination count={Math.ceil(tenantCount / 10)} page={currentPage} size="large" shape="rounded" onChange={handlePagignation} /> : ''}
                </Grid>
            </Grid>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        tenantEmp: state.CommonReducer.tenantEmp,
        tenantCount: state.CommonReducer.empCountForTenant,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantEmp: (data, token) =>
            dispatch(commonAction.getTenantEmp(data, token)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(TenantEmpList);
