import axios from "axios";
import { createAction } from "redux-actions";
export const sessionExpired = createAction("SESSION_EXPIRED");

export const addCountriesInitiate = createAction("ADD_COUNTRY_INITIATE");
export const addCountriessuccess = createAction("ADD_COUNTRY_SUCCESS");
export const addCountriesfailure = createAction("ADD_COUNTRY_FAILURE");

export const addCountry = (requestdata, token) => {
  let bodyparameters = {
    key: "value",
  };
  return (dispatch) => {
    dispatch(addCountriesInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/addCountries`,
        requestdata,
        bodyparameters
      )
      .then((response) => {
        dispatch(addCountriessuccess(response.data.response_json.message));
      })
      
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(addCountriesfailure(err.data));
        }
      });
  };
};

export const deleteCountriesInitiate = createAction("DELETE_COUNTRY_INITIATE");
export const deleteCountriessuccess = createAction("DELETE_COUNTRY_SUCCESS");
export const deleteCountriesfailure = createAction("DELETE_COUNTRY_FAILURE");

export const deleteCountry = (requestdata) => {
  let bodyparameters = {
    key: "value",
  };
  return (dispatch) => {
    dispatch(deleteCountriesInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/deleteCountries`,
        requestdata,
        bodyparameters
      )
      .then((response) => {
        dispatch(deleteCountriessuccess(response.data.response_json.message));
      })
  
       
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(deleteCountriesfailure(err.data));
        }
      });
  };
};

const addBuildingInitiate = createAction("ADD_BUILDING_INITIATE");
const addBuildingSuccess = createAction("ADD_BUILDING_SUCCESS");
const addBuildingFailure = createAction("ADD_BUILDING_FAILURE");

export const addBuilding = (data) => {
  let bodyparameters = {
    key: "value",
  };
  return (dispatch) => {
    dispatch(addBuildingInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/addBuildings`,
        data,
        bodyparameters
      )
      .then((response) => {
        
        dispatch(addBuildingSuccess(response.data.response_json.message));
      })
      
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(addBuildingFailure(err.data));
        }
      });
  };
};

const addfaulttypeinitiate = createAction("ADD_FAULTTYPE_INITIATE");
const addfaulttypesuccess = createAction("ADD_FAULTTYPE_SUCCESS");
const addfaulttypefailure = createAction("ADD_FAULTTYPE_FAILURE");

export const addFault = (data) => {
  return (dispatch) => {
    dispatch(addfaulttypeinitiate());
    axios
      .post(`${process.env.REACT_APP_API_URL}/addFaultType`, data)
      .then((response) => {
        dispatch(addfaulttypesuccess(response.data.response_json.message));
      })
     
      .catch((err) => {
        if(err.response.status===401){
          dispatch(sessionExpired('Session Expired'));
        }else{
          dispatch(addfaulttypefailure(err.data));
        }
      });
  };
};

const addservicetypeinitiate = createAction("ADD_SERVICETYPE_INITIATE")
const addservicetypesuccess = createAction("ADD_SERVICETYPE_SUCCESS")
const addservicetypefailure = createAction("ADD_SERVICETYPE_FAILURE")

export const addService = (data)=>{
  return(dispatch)=>{
    dispatch(addservicetypeinitiate)
    axios.post(`${process.env.REACT_APP_API_URL}/addServiceType`,data)
    .then((response)=>{
      dispatch(addservicetypesuccess(response.data.response_json.message))
    })
    
    .catch((err) => {
      if(err.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(addservicetypefailure(err.data))
      }
    });
  }
}


