import { Grid, OutlinedInput, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as superadminaction from "../../redux/actions/superadminAction";
const AddFaultType = ({ addFaultType, addFault }) => {
  const [faulttype, setFaulttype] = useState("");

  const handleFault = (e) => {
    setFaulttype(e.target.value);
  };

  let obj = {
    fault_type_name: faulttype,
  };

  // useEffect(() => {
  //   console.log(addFaultType);
  // }, [addFaultType]);

  const submitFault = () => {
    addFault(obj);
    setFaulttype("");
  };
  return (
    <>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "start", mt: 7, ml: 10 }}
      >
        <Grid item xs={12}>
          <OutlinedInput
            type="text"
            placeholder="Fault Type"
            value={faulttype}
            onChange={(e) => {
              handleFault(e);
            }}
          ></OutlinedInput>
        </Grid>
        <Grid item xs={12} sx={{ mt: 3, ml: 5 }}>
          <Button variant="contained" onClick={submitFault}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addFaultType: state.SuperAdmin.addFaultType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addFault: (data) => {
      dispatch(superadminaction.addFault(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFaultType);
