import { StepContent, StepLabel, Stepper, Step, Typography, Button, Chip, Grid, Card, CardHeader, Box, OutlinedInput, Modal, IconButton, Avatar, ListItem, List, ListItemText, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { getTimeStamp } from "../../Utils/utils";
import Alert from "./faultalert";
import {ReactComponent as StepperIcon} from '../../Assets/icons/StepperIcon.svg';
import { ReactComponent as CloseButton } from '../../Assets/icons/Alertclose.svg';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as Fileupload } from '../../Assets/icons/Fileupload.svg';
import * as FaultReportAction from '../../redux/actions/faultreportAction';
import * as serviceRequestAction from "../../redux/actions/service_request";
import * as CommonAction from "../../redux/actions/commonAction";
import { useParams } from "react-router-dom";
import DropZone from "../../Utils/dropzone";


const WithdrawAlertData = {
    label:"Withdraw Request",
    description:'Are you sure you want to withdraw request?',
    buttonvalue:'Confirm'
}

const StyledTypography = styled(Typography)({
    textTransform:'capitalize',
    textDecoration:"underline",
    color:"#2764F4",
    fontSize:"16px",
    cursor:'pointer'
})



const StatusStepper = ({ statusLogs,withdrawFunc,currentStatus,reportUpdateStatus,serReqUpdateStatus,type,setUpdateToTechnician,updateReport,updateServiceRequestStatus,sendReminder,sendReminderStatus,bmsCoordinatorList,getBmsCoordinatorList,faultReportData,serviceReqDetails }) => {
    let {id,service_req_id} = useParams()
    const [localStorageData,setLocalStorageData] = useState({})
    const [withdrawAlert,setWithdrawAlert] = useState(false);
    const [showhideStatus,setShowHideStatus] = useState({more:true,less:false})
    const [indexValue,setIndexValue] = useState([])
    const [respondToBms,setRespondToBms] = useState({
        status:false,
        description:'',
        error:'',
        files:[]
    })
    const [viewDetails,setViewDetails] = useState({
        status:false,
        data:{}
    })
    const [sendReminderData,setSendRemainderData] = useState({status:false,description:'',person:''})

    useEffect(()=>{
        getBmsCoordinatorList({module_name:id==undefined ? 'ServiceRequest' : 'FaultReport',id:id==undefined ? service_req_id : id})
        const localdata = JSON.parse(localStorage.getItem('userData'));
        setLocalStorageData(localdata);
    },[])

    useEffect(()=>{
        if(faultReportData?.response_status=="success"){
            const array = faultReportData?.response_json[0]?.status_logs?.filter((value)=>{
                return value.is_row_enabled == true
            })
            setIndexValue([...array])
        }

    },[faultReportData])
    
    useEffect(()=>{
        if(serviceReqDetails?.serviceRequestLogTempArray){
            const array = serviceReqDetails?.serviceRequestLogTempArray?.filter((value)=>{
                return value.is_row_enabled == true
            })
            setIndexValue([...array])
        }
    },[serviceReqDetails])

    useEffect(()=>{
        setWithdrawAlert(false)
        setRespondToBms({
            status:false,
        description:'',
        error:''
        })
    },[reportUpdateStatus,serReqUpdateStatus])

    useEffect(()=>{
        setSendRemainderData({status:false,description:'',person:''})
    },[sendReminderStatus])

    const getChip = (status) => {
        let chip;
        if (status == 'Submitted' || status=='submitted') {
            chip = <Chip sx={{ color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px' }} label={'Submitted'}></Chip>
        }else if(status === 'pending_investigation' || status==='Pending Investigation' ){
            chip = <Chip sx={{ color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px' }} label={'Pending Investigation'}></Chip>
        }else if(status === 'complete_investigation' || status==='Completed Investigation'){
            chip = <Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'Investigation Completed'}></Chip>
        }else if(status === 'work_in_progress'){
            chip = <Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'Work In-Progress'}></Chip>
        }else if(status === 'work_completed'){
            chip = <Chip sx={{ color: '#005934', backgroundColor: '#D3F2E5 ',borderRadius:'5px' }} label={'Work Completed'}></Chip>
        }else if(status === 'closed'){
            chip = <Chip  sx={{ color: '#890122', backgroundColor: ' #F9E7EB',borderRadius:'5px' }} label={'Case Closed'}></Chip>
        }else if(status === 'pending_vendor'){
            chip = <Chip sx={{ color: '#1858F4', backgroundColor: '#8DACF9 ',borderRadius:'5px' }} label={'Pending Vendor'}></Chip>
        }else if(status==='require_more_data' || status==="Require More Information"){
            chip = <Chip sx={{ color: '#890122', backgroundColor: '#F9E7EB',borderRadius:'5px' }} label={'More Information Required'}></Chip>
        }else if(status==='withdraw'){
            chip = <Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'Withdrawn'}></Chip>
        }else if(status==='Update Required' || status==="update_required"){
            chip = <Chip sx={{ color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px' }} label={'Update Required'}></Chip>
        }else if(status==="approved"){
            chip = <Chip sx={{ color: '#005934', backgroundColor: '#D3F2E5 ',borderRadius:'5px' }} label={'Approved'}></Chip>
        }else if(status==="starting_investigation"){
            chip = <Chip sx={{color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px'}} label="Starting Investigation" ></Chip>
        }else if(status==="quotation_updated"){
            chip = <Chip sx={{color: '#890122', backgroundColor: '#F9E7EB',borderRadius:'5px'}} label="Quotation Updated"></Chip>
        }else if(status==="quotation_accepted"){
            chip=<Chip sx={{color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label="Quotation Approved" ></Chip>
        }else if(status==="acknowledgement"){
            chip=<Chip sx={{ color: '#005934', backgroundColor: '#D3F2E5 ',borderRadius:'5px'}} label="Acknowledged"></Chip>
        }else if(status === 'quotation accepted'){
            chip = <Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'Quotation Approved'}></Chip>
        }else if(status === 'rejected'){
            chip = <Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'Rejected'}></Chip>
        }else if(status==="draft"){
            chip=<Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }}  label={"Draft"} />
        }else if(status==="resolved"){
            chip = <Chip sx={{ color: '#005934', backgroundColor: '#D3F2E5 ',borderRadius:'5px' }} label={'Resolved'}></Chip>
        }else if(status==="require_more_data_updated"){
            chip=<Chip  sx={{ color: '#890122', backgroundColor: '#F9E7EB',borderRadius:'5px' }} label="Require More Data Updated"></Chip>
        }else if(status==="deleted"){
            chip=<Chip  sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'In Active'}></Chip>
        }else if(status === 'update_to_bms'){
            chip = <Chip sx={{ color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px' }} label={'Technician Require More Data'}></Chip>
        }else if(status==="update_to_technician"){
            chip=<Chip  sx={{ color: '#890122', backgroundColor: '#F9E7EB',borderRadius:'5px' }} label="Info Updated to Technician"></Chip>
        }else if(status === 'pending_work'  ){
            chip = <Chip sx={{ color: '#8C5001', backgroundColor: '#FFF2D0 ',borderRadius:'5px' }} label={'Pending Work'}></Chip>
        }else if(status === 'reassign_technician'){
            chip = <Chip sx={{ color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px' }} label={'Reassigned Technician'}></Chip>
        }else if(status === 'send_reminder'){
            chip = <Chip sx={{ color: '#0F2C6F', backgroundColor: '#DBDFE9 ',borderRadius:'5px' }} label={'Send Reminder'}></Chip>
        }
            return chip;
    }
    
    const restrictStatus = ()=>{
        
        if(showhideStatus.more){
            return statusLogs.slice(0,3)
        }else{
            return statusLogs
        }
    }
   
    const handleSubmit = ()=>{
        if(!respondToBms.description){
            setRespondToBms({
                ...respondToBms,
                error:"Should not be empty"
            })
        }else{
            let data = new FormData()
                data.append('status','require_more_data_updated')
                data.append('description',respondToBms.description)
                if(respondToBms.files.length>0){
                    data.append('file_count',respondToBms.files.length)
                    for(let i=0;i<respondToBms.files.length;i++){
                        data.append(`file${i}`,respondToBms.files[i].file)
                    }
                }
                if(type=='FaultReport'){
                    data.append('is_response_format_changed' ,'yes')
                    data.append('fault_report_id',id)  
                }else{
                    data.append('service_requests_id',service_req_id)
                }
                
                
                if(type=='FaultReport'){
                    updateReport(data)
                }else{
                    updateServiceRequestStatus(data)
                }
        }
    }
    
    const closeViewDetailModal = ()=>{
        setViewDetails({
            status:false,
            data:{}
        })
    }

    const handleReceivedFiles = (selectedFiles)=>{
        if(selectedFiles.length>0){
            let arr = [...selectedFiles]
            setRespondToBms({
                ...respondToBms,
                files:arr
            })
        }
       
    }

    const removeSupportImage = (id) => {
        let newSupportImages = [];
        let supportImages = respondToBms.files
        for (var i = 0; i < supportImages.length; i++) {
          if (supportImages[i].id !== id) {
            newSupportImages.push(supportImages[i])
          }
        }
        setRespondToBms({
            ...respondToBms,
            files:newSupportImages
        })
    }

    const handleUploadButton = (e) => {
        let target = e.target.files;
        let length = respondToBms.files.length;
        let files = respondToBms.files;
        for (let i = 0; i < target.length; i++) {
          files.push({ id: length, fileName: target[i].name, file: target[i], size: target[i].size, source: URL.createObjectURL(target[i]) })
          length += 1;
        }
        setRespondToBms({
            ...respondToBms,
            files:files
        })
    }

    const getCondition = ()=>{
        if(localStorageData.role=="Tenant Manager" && type){
            return  indexValue.length > 3
        }else{
            return  statusLogs?.length > 3
        }
    }
    return (
        <>
        {withdrawAlert && <Alert closeFunc={setWithdrawAlert} confirmFunc={withdrawFunc} alertData={WithdrawAlertData} />}
            {statusLogs.length > 0 ?
                <>
                <Stepper 
                    orientation='vertical' activeStep={1}>
                    {
                        restrictStatus().map((value, index) =>{
                           return (value.is_row_enabled==true || localStorageData.role!="Tenant Manager" || !type) &&  <Step key={index} active={true}>
                                <StepLabel icon={<StepperIcon style={{marginLeft:'7px'}}/>} >
                                    {getChip(value?.status)}
                                </StepLabel>
                                <StepContent >
                                    <Card sx={{ boxShadow: 'none' }}>
                                        <CardHeader
                                            sx={{ p: 0.5 }}
                                            title={<Typography  sx={{ color: '#0F2C6F',wordBreak:'break-word' }}>{ localStorageData.role=="Tenant Manager" && type  ? value.message :  value.description  }</Typography>}
                                            subheader={<Typography sx={{ color: '#6F80A9', fontSize: '12px' }}>{getTimeStamp(value.created_at)}</Typography>}
                                        />
                                    </Card>
                                    {value.is_button_enabled && <Button onClick={()=>setViewDetails({
                                        status:true,
                                        data:value
                                    })}  variant='outlined' sx={{ borderRadius: '6px', px: 1,mt:1,mr:2, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }}>View Details</Button>  }
                                    {value.is_send_reminder_button_enabled && <Button  onClick={()=>setSendRemainderData({...sendReminderData,status:true})} variant="contained"  sx={{ borderRadius: '6px', px: 1,mt:1,mr:2, fontSize: '12px',textTransform:"capitalize",backgroundColor:"#123FA9",color:"#FFFFFF",fontSize:'13px' }}>Send Reminder</Button>}
                                    
                                    {
                                        (localStorageData.role === 'Tenant Manager'&&value.status === 'submitted' && (currentStatus=="submitted"||currentStatus=="require_more_data"|| currentStatus=="require_more_info" || currentStatus=="require_more_data_updated" || currentStatus=="update_required")) &&  
                                        <Button onClick={()=>setWithdrawAlert(true)} variant='outlined' sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }}>Withdraw</Button> 
                                    }
                                    {
                                        localStorageData.role!="Tenant Manager" && value.status==="update_to_bms" && currentStatus!="closed" && currentStatus!="work_completed" && 
                                        <Button onClick={()=>setUpdateToTechnician(true)} variant="outlined"  sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px'}}>Update To Technician</Button>
                                    }
                                    {
                                        localStorageData.role=="Tenant Manager" && value.status=="require_more_data" && currentStatus=="require_more_data" &&
                                        <Button onClick={()=>setRespondToBms({
                                            ...respondToBms,
                                            status:true
                                        })} variant='outlined' sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }}>Respond</Button>
                                    }
                                   
                                </StepContent>
                            </Step>
                        }
                        )
                    }
                </Stepper> 
                </>
                : ''}
                 
                { ((localStorageData.role=="Tenant Manager" && type && indexValue?.length > 3) ||  (localStorageData.role!="Tenant Manager" && statusLogs?.length > 3)) && 
                    <>
                        {showhideStatus.more && <StyledTypography onClick={()=>setShowHideStatus({...showhideStatus,less:true,more:false})} variant="button" sx={{display:'flex',justifyContent:'center',my:2}}>Show more</StyledTypography> }
                        {showhideStatus.less && <StyledTypography onClick={()=>setShowHideStatus({...showhideStatus,less:false,more:true})} variant="button" sx={{display:'flex',justifyContent:'center',my:2}}>Show less</StyledTypography> }
                    </>
                }
                
        <Modal open={respondToBms.status}>
                <Box sx={{position:"absolute",left:"30%",top:"15%",width:500,backgroundColor:"#FFFFFF"}}>
                        <Box sx={{display:"flex",justifyContent:"space-between",borderBottom:"1px solid #0F2C6F",p:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"16px"}}>Respond To BMS</Typography>
                            <CloseButton onClick={()=>setRespondToBms({
                                            ...respondToBms,
                                            status:false,
                                            error:''
                                        })} />
                        </Box>
                        <Box sx={{p:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"16px"}}>Description</Typography>
                            <OutlinedInput fullWidth onChange={(e)=>setRespondToBms({
                                ...respondToBms,
                                description:e.target.value,
                                error:''
                            })} value={respondToBms.description} />
                            <Typography sx={{color:"red"}}>{respondToBms.error}</Typography>
                        </Box>
                        <Box sx={{p:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"16px"}}>Supporting Material (optional)</Typography>
                            {respondToBms?.files?.length > 0 ?
                            <>
                               {respondToBms.files.map((item)=>(
                                    <Box sx={{ border: '2px solid #80808021', mb: 1, borderRadius: '5px' }}>
                                            <List
                                                sx={{
                                                width: '100%',
                                                bgcolor: 'background.paper',
                                                p: 0
                                                }}
                                            >
                                            <ListItem sx={{ pl: 0 }} secondaryAction={
                                            <IconButton edge="end" aria-label="delete">
                                                <CloseOutlinedIcon onClick={() => removeSupportImage(item.id)} />
                                            </IconButton>}>
                                            <ListItemText primary={
                                                <Typography variant='p' sx={{ m: 2 ,color: "#0F2C6F",fontSize:"14px",fontWeight:500 }}>{item.fileName}
                                                <span style={{ color: '#9AA6C3', fontSize: '12px',marginLeft:"5px" }}>{`     (${Math.floor(item.size / 1024)} of ${Math.floor(item.size / 1024)}KB)`}</span>
                                                </Typography>}
                                                 />
                                            </ListItem>
                                        </List>
                                    </Box>
                               ))}
                               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button component='label' variant='outlined' sx={{ color: '#0F2C6F', mt: 2, textTransform: 'capitalize' }} endIcon={<Fileupload></Fileupload>}>Upload
                                        <input multiple type='file' accept="image/*" style={{ display: 'none' }} onChange={(e) => handleUploadButton(e)} />
                                    </Button>
                                </Box>
                              </>  :                             
                                <DropZone getFiles={handleReceivedFiles} onlyImage={true} />
                            }
                        </Box>
                        <Box sx={{display:"flex",justifyContent:"space-between",p:2}}>
                            <Button onClick={()=>setRespondToBms({
                                            ...respondToBms,
                                            status:false
                                        })} variant='outlined' sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }} >Cancel</Button>
                            <Button onClick={handleSubmit} variant='outlined' sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }} >Submit</Button>
                        </Box>
                </Box>  
        </Modal>

        <Modal open={viewDetails.status} onClose={closeViewDetailModal}>
                <Box sx={{position:"absolute",left:'30%',top:"20%",width:600,maxHeight:'70%',overflow:"auto",backgroundColor:"#FFFFFF"}}>
                    <Box sx={{display:'flex',justifyContent:'space-between',p:2,borderBottom:'1px solid #0F2C6F'}}>
                         <Typography sx={{ color: '#0F2C6F' }}>Details</Typography>
                         <CloseButton onClick={closeViewDetailModal} />          
                    </Box>
                    <Box sx={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                        <Typography  sx={{ color: '#0F2C6F',wordBreak:'break-word',m:1 }}>{viewDetails?.data?.message}</Typography>
                        {viewDetails?.data?.viewDetailsFileArrayList?.length>0 && 
                            viewDetails?.data?.viewDetailsFileArrayList?.map((value)=>(
                                <>
                                 {value.file_type!='pdf' && <img width={500} height={300}  alt='No image found' src={`${process.env.REACT_APP_ASSETS_PATH}/${value.path}`}></img> }
                                 {value.file_type=='pdf' && <Button target="_blank" href={`${process.env.REACT_APP_ASSETS_PATH}/${value.path}`} sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }} variant="outlined">View PDF</Button> }
                                </>
                            ))
                        }
                    </Box>
                    <Card  sx={{mt:2,m:2,border:"1px solid black"}}>
                        <CardHeader avatar={
                        <Avatar src={`${process.env.REACT_APP_ASSETS_PATH}/${viewDetails?.data?.viewDetailsArray?.viewDetailsProfilePath}`}>
                        </Avatar>} title={viewDetails?.data?.viewDetailsArray?.viewDetailsTitle} subheader={viewDetails?.data?.viewDetailsArray?.viewDetailsSubTitle} />
                    </Card>
                </Box>
        </Modal>

        <Modal open={sendReminderData.status}>
            <Box sx={{position:"absolute",left:"30%",top:"15%",width:700,backgroundColor:"#FFFFFF"}}>
                    <Box sx={{display:"flex",justifyContent:"space-between",borderBottom:"1px solid #0F2C6F",p:2}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Send Reminder</Typography>
                            <CloseButton onClick={()=>setSendRemainderData({
                                        status:false,
                                        description:'',
                                        person:''
                             })} />
                    </Box>
                    <Box sx={{p:2}}>
                        <Typography sx={{color:"#0F2C6F",fontSize:"16px"}}>Please confirm that you wish to send a reminder. The reminder will be sent to:</Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"16px",my:2}}>Assigned Personnel</Typography>
                        <Select sx={{width:300}} value={sendReminderData.person} onChange={(e)=>setSendRemainderData({
                            ...sendReminderData,
                            person:e.target.value
                        })}>
                             {bmsCoordinatorList?.map((value)=>(
                                <MenuItem value={value.id}>{value.name}</MenuItem>
                             ))}
                        </Select>
                        <Typography sx={{color:"#0F2C6F",fontSize:"16px",my:2}}>Additional Note</Typography>
                        <OutlinedInput value={sendReminderData.description} onChange={(e)=>setSendRemainderData({
                            ...sendReminderData,
                            description:e.target.value
                        })} fullWidth />
                    </Box>
                    <Box sx={{display:"flex",justifyContent:"space-between",p:2}}>
                            <Button onClick={()=>setSendRemainderData({
                                        status:false,
                                        description:'',
                                        person:''
                             })} variant='outlined' sx={{ borderRadius: '6px', px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#0F2C6F",fontSize:'13px' }} >Back</Button>
                            <Button onClick={()=>{
                                sendReminder({id:id,module_name:type,notify_to_user:sendReminderData.person,description:sendReminderData.description})
                            }} variant='contained' sx={{ borderRadius: '6px',backgroundColor:'0F2C6F',px: 1,mt:1, fontSize: '12px',textTransform:"capitalize",color:"#FFFFFFF",fontSize:'13px' }} >Send</Button>
                    </Box>
            </Box>
        </Modal>
        </>
        
    )
}

const mapStateToProps = (state)=>{
    return{
        reportUpdateStatus: state.FaultReportReducer.reportUpdateStatus,
        serReqUpdateStatus:state.Service_Req_Reducer.updateStatus,
        sendReminderStatus:state.CommonReducer.sendReminderStatus,
        bmsCoordinatorList:state.CommonReducer.bmsCoordinatorList,
        faultReportData:state.FaultReportReducer.faultReportData,
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
    }

}

const mapDispatchToProps=(dispatch)=>{
    return{
        updateReport: (data) => (dispatch(FaultReportAction.updateFaultReport(data))),
        updateServiceRequestStatus: (data) => (dispatch(serviceRequestAction.updateServiceRequestStatus(data))),
        sendReminder: (data) => (dispatch(CommonAction.sendReminder(data))),
        getBmsCoordinatorList:(data)=>(dispatch(CommonAction.getBmsCoordinatorList(data)))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(StatusStepper);