import {  Backdrop, Box } from '@mui/material'
import React from 'react'
import { RotatingTriangles } from 'react-loader-spinner'

export default function Loader({ status }) {
  return (
    <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1,background:"#FFFFFF" }}
    open={true} >
      <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",height:window.innerHeight}}>
        <RotatingTriangles
          visible={true}
          height="80"
          width="80"
          ariaLabel="rotating-triangels-loading"
          wrapperStyle={{}}
          wrapperClass="rotating-triangels-wrapper"
          colors={['#123FA9', '#123FA9', '#AB8900']}
        />
       </Box>
    </Backdrop >
  )
}
