import {
  Box,
  Button,
  Chip,
  Grid,
  Typography,
  IconButton,
  Modal,
  OutlinedInput,
  TextField,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import Faultalert from "../common/faultalert";
import { connect } from "react-redux";
import * as commonAction from "../../redux/actions/commonAction";
import ToastAlert from "../common/Alert";
import { ReactComponent as CloseButton } from "../../Assets/icons/Alertclose.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  checkBmsRoles,
  getTimeStamp,
  getUTCDateFormat,
  preventDatePickerKeyboard,
} from "../../Utils/utils";
import Loader from "../common/Loader";
import moment from "moment";

const StyledChip = styled(Chip)({
  borderRadius: "24px",
  color: "#ffffff",
  fontWeight: 500,
  marginRight: "7px",
  border: "1px solid #C0C7D7",
});

const StyledTypography = styled(Typography)({
  color: "#0F2C6F",
  fontSize: "14px",
});

const approveStatus = ["Drafted", "Approved", "Rejected", "InActived"];
const ReviewTenant = ({
  updateTenantStatus,
  tenantUpdateStatus,
  setStatusNull,
  getTenantDetailsById,
  tenantDetails,
  tenantOffBoardStatus,
  offBoardTenant,
}) => {
  let { id, activeType } = useParams();
  const navigate = useNavigate();
  const [alertData, setAlertData] = useState({
    label: "",
    description: "",
    buttonvalue: "",
    endAction: "",
  });
  const [alertStatus, setAlertStatus] = useState(false);
  const [rejectTenantStatus, setRejectTenantStatus] = useState(false);
  const [authData, setAuthData] = useState([]);
  const [reportAddStatus, setReportAddStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [rejectionMsg, setRejectionMsg] = useState("");
  const [offboardTenantStatus, setOffboardTenantStatus] = useState(false);
  const [offboardData, setOffboardData] = useState(null);
  const [offboardMsg, setOffboardMsg] = useState("");
  const [currentTenantDetails, setCurrentTenantDetails] = useState({});
  const [tenantEmpDetails, setTenantEmpDetails] = useState({});
  const [loaderStatus, setLoaderStatus] = useState(true);
  const [offboardErrs, setOffboardErrs] = useState({
    offboard_date: "",
    remarks: "",
  });
  const [accessibleFloor, setAccessibleFloor] = useState([]);
  const [reonboardDate, setReonboardDate] = useState(null);
  const [notifyData, setNotifyData] = useState({
    label: "Inactive",
    description:
      "Employee accounts which have been successfully offboarded would be automatically pushed to the inactive tab. To reactivate account, tenant managers are to onboard employee again.",
    buttonvalue: "Confirm",
    buttonStyle: "blue",
  });
  const [notifyStatus, setNotifyStatus] = useState(false);

  useEffect(() => {
    let responseUserData = JSON.parse(localStorage.getItem("userData"));
    setAuthData(responseUserData);
    let data = {
      company_id: id,
    };
    getTenantDetailsById(data, localStorage.getItem("userToken"));

    if (currentTenantDetails && tenantEmpDetails) {
      setTimeout(() => {
        setLoaderStatus(false);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    let str = "";
    let val = [];
    if (tenantDetails.hasOwnProperty("employeesArrayList")) {
      setTenantEmpDetails(tenantDetails.employeesArrayList);
      setCurrentTenantDetails(tenantDetails.companiesArrayList[0]);
      for (
        let i = 0;
        i < tenantDetails?.assignedLevelNameArrayList.length;
        i++
      ) {
        val.push(tenantDetails?.assignedLevelNameArrayList[i]?.level_name);
      }
      setAccessibleFloor([...val]);
    }
  }, [tenantDetails]);

  useEffect(() => {
    if (tenantUpdateStatus === "Tenant Approved Successfully") {
      setLoaderStatus(false);
      setRejectTenantStatus(false);
      setAlertStatus(false);
      setStatusNull();
      let data = {
        company_id: id,
      };
      getTenantDetailsById(data, localStorage.getItem("userToken"));
      setReportAddStatus({
        message: tenantUpdateStatus,
        status: true,
        color: "green",
        icon: "tick",
      });
      setTimeout(() => {
        setStatusNull();
        setReportAddStatus({ message: "", status: false, color: "", icon: "" });
        navigate("/onboard_new_tenant/0/0");
      }, 5000);
    } else if (tenantUpdateStatus != null) {
      setLoaderStatus(false);
      setRejectTenantStatus(false);
      setAlertStatus(false);

      let data = {
        company_id: id,
      };
      getTenantDetailsById(data, localStorage.getItem("userToken"));
      setReportAddStatus({
        message: tenantUpdateStatus,
        status: true,
        color: "green",
        icon: "close",
      });
      setTimeout(() => {
        setStatusNull();
        setReportAddStatus({ message: "", status: false, color: "", icon: "" });
        navigate("/onboard_new_tenant/0/0");
      }, 5000);
    }
    // setTimeout(() => {
    //     setStatusNull()
    //     setReportAddStatus({ message: '', status: false, color: '', icon: '' })
    //       navigate('/onboard_new_tenant/0/1')
    // }, 5000);
  }, [tenantUpdateStatus]);

  useEffect(() => {
    setLoaderStatus(false);
    if (tenantOffBoardStatus === "Company Offboarded Successfully") {
      setOffboardTenantStatus(false);
      setReportAddStatus({
        message: "Offboarded Successfully",
        status: true,
        color: "green",
        icon: "tick",
      });
      setNotifyStatus(true);
      setTimeout(() => {
        setStatusNull();
        navigate("/onboard_new_tenant/0/2");
      }, 5000);
    } else if (tenantOffBoardStatus !== null) {
      setOffboardTenantStatus(false);
      setReportAddStatus({
        message: tenantOffBoardStatus,
        status: true,
        color: "red",
        icon: "close",
      });
      setTimeout(() => {
        setStatusNull();
      }, 5000);
    }
  }, [tenantOffBoardStatus]);

  const handlePopup = (alertType) => {
    if (alertType === "rejected") {
      setAlertData({
        label: "",
        description: "",
        buttonvalue: "",
        endAction: alertType,
      });
      setRejectTenantStatus(true);
    } else if (alertType === "offboard") {
      setAlertData({
        label: "",
        description: "",
        buttonvalue: "",
        endAction: alertType,
      });
      setOffboardTenantStatus(true);
    } else if (alertType === "approved") {
      setAlertStatus(true);
      setAlertData({
        label: "Approve Onboarding company",
        description: `Are you sure you want to approve this request?`,
        buttonvalue: "Approve",
        buttonStyle: "blue",
        endAction: alertType,
      });
    } else if (alertType === "withdraw") {
      setAlertStatus(true);
      setAlertData({
        label: "Delete Onboarding company",
        description: `Are you sure you want to delete this request?`,
        buttonvalue: "Delete",
        buttonStyle: "red",
        endAction: alertType,
      });
    }
  };

  const confirmFunc = () => {
    let data = {
      company_id: id,
      approved_status: alertData.endAction,
    };
    updateTenantStatus(data, localStorage.getItem("userToken"));
    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 20000);
  };
  const closeFunc = () => {
    setAlertData({
      label: "",
      description: "",
      buttonvalue: "",
      endAction: "",
    });
    setAlertStatus(false);
    setNotifyStatus(false);
    setReonboardDate(null);
  };
  const handleRejectionMsg = (e) => {
    if (e.target.value.length <= 300) {
      setRejectionMsg(e.target.value);
    }
  };
  const rejectTenant = () => {
    let data = {
      company_id: id,
      approved_status: alertData.endAction,
      rejection_msg: rejectionMsg,
    };
    updateTenantStatus(data, localStorage.getItem("userToken"));
    setLoaderStatus(true);
    setTimeout(() => {
      setLoaderStatus(false);
    }, 20000);
  };

  const closePopup = () => {
    setRejectTenantStatus(false);
    setRejectionMsg("");
    setOffboardTenantStatus(false);
    setOffboardData(null);
    setOffboardErrs({
      offboard_date: "",
      remarks: "",
    });
    setOffboardMsg("");
    setReonboardDate(null);
  };

  const renderBMSManagerButtons = (tenantStatus) => {
    if (tenantStatus === "submitted") {
      return (
        <>
          <Button
            variant="contained"
            color="error"
            sx={{
              backgroundColor: "#BD0251",
              "&:hover": { backgroundColor: "#BD0251" },
              height: "50px",
              textTransform: "capitalize",
            }}
            onClick={() => handlePopup("rejected", 2)}
          >
            Reject
          </Button>
          <Button
            variant="contained"
            sx={{
              color: "#ffffff",
              backgroundColor: "#0F2C6F",
              "&:hover": { backgroundColor: "#0F2C6F" },
              height: "50px",
              textTransform: "capitalize",
            }}
            onClick={() => handlePopup("approved", 1)}
          >
            Approve
          </Button>
        </>
      );
    } else if (tenantStatus === "rejected") {
      return (
        <Button
          variant="contained"
          sx={{
            color: "#ffffff",
            backgroundColor: "#0F2C6F",
            height: "50px",
            textTransform: "capitalize",
          }}
          onClick={() => handlePopup("approved", 1)}
        >
          Re-Approve
        </Button>
      );
    } else if (
      tenantStatus === "approved" &&
      activeType !== "deleted" &&
      activeType !== "offboarded"
    ) {
      return (
        <Button
          variant="contained"
          sx={{
            color: "#ffffff",
            backgroundColor: "#0F2C6F",
            "&:hover": { backgroundColor: "#0F2C6F" },
            height: "50px",
            textTransform: "capitalize",
          }}
          onClick={() => handlePopup("offboard", 1)}
        >
          Offboard
        </Button>
      );
    }
  };
  const renderBMSCoButtons = (tenantStatus) => {
    if (
      tenantStatus === "approved" &&
      activeType !== "deleted" &&
      activeType !== "offboarded"
    ) {
      return (
        <Button
          variant="contained"
          sx={{
            color: "#ffffff",
            "&:hover": { backgroundColor: "#0F2C6F" },
            backgroundColor: "#0F2C6F",
            height: "50px",
            textTransform: "capitalize",
          }}
          onClick={() => handlePopup("offboard", 1)}
        >
          Offboard
        </Button>
      );
    } else if (tenantStatus === "submitted") {
      return (
        <Button
          variant="outlined"
          sx={{
            color: "#0F2C6F",
            backgroundColor: "#FFFFFF",
            "&:hover": { backgroundColor: "#FFFFFF" },
            height: "50px",
            textTransform: "capitalize",
            borderRadius: "8px",
            fontWeight: 600,
            border: "1px solid #AAC3FF",
          }}
          onClick={() => handlePopup("withdraw", 1)}
        >
          Delete
        </Button>
      );
    }
  };
  const validate = () => {
    let error = true;

    if (!offboardMsg) {
      setOffboardErrs((prev) => ({
        ...prev,
        remarks: "Should not be empty!",
      }));
      error = false;
    }

    if (!offboardData) {
      setOffboardErrs((prev) => ({
        ...prev,
        offboard_date: "Should not be empty!",
      }));
      error = false;
    }

    return error;
  };

  const offboardTenant = () => {
    let err = validate();
    let reonboardDateStr;
    if (err) {
      let datestr = `${offboardData.getFullYear()}-${
        offboardData.getMonth() + 1
      }-${offboardData.getDate()}`;
      if (reonboardDate) {
        reonboardDateStr = `${reonboardDate.getFullYear()}-${
          reonboardDate.getMonth() + 1
        }-${reonboardDate.getDate()}`;
      }

      const localData = JSON.parse(localStorage.getItem("userData"));
      let Data = {
        company_id: id,
        offboard_date: datestr,
        remarks: offboardMsg,
        created_by: localData.user_id,
        reonboard_date: reonboardDateStr ? reonboardDateStr : "",
      };
      offBoardTenant(Data);
      setLoaderStatus(true);
    }
  };

  const getTabValue = () => {
    if (activeType === "drafted") {
      return 4;
    } else if (activeType === "submitted") {
      return 1;
    } else if (activeType === "approved") {
      return 0;
    } else if (activeType === "deleted") {
      return 3;
    } else {
      return 2;
    }
  };
  const notifyConfirm = () => {
    navigate("/onboard_new_tenant/0/2");
  };

  const renderEditButton = () =>
    checkBmsRoles() &&
    (activeType === "approved" || activeType === "submitted") && (
      <NavLink to={`/update_onboard_tenant_company/${id}?type=edit`}>
        <Button
          variant="contained"
          sx={{
            textTransform: "none",
            px: 2,
            color: "#ffffff",
            backgroundColor: "#0F2C6F",
            height: "50px",
            textTransform: "capitalize",
            "&:hover": { backgroundColor: "#0F2C6F" },
          }}
        >
          Edit
        </Button>{" "}
      </NavLink>
    );

  return (
    <>
      {loaderStatus === true ? (
        <Loader status={loaderStatus} />
      ) : (
        <>
          <ToastAlert
            message={reportAddStatus.message}
            status={reportAddStatus.status}
            color={reportAddStatus.color}
            icon={reportAddStatus.icon}
          />
          {alertStatus ? (
            <Faultalert
              confirmFunc={confirmFunc}
              alertData={alertData}
              closeFunc={closeFunc}
            />
          ) : (
            ""
          )}
          {notifyStatus && (
            <Faultalert
              alertData={notifyData}
              confirmFunc={notifyConfirm}
              closeFunc={closeFunc}
            />
          )}
          {currentTenantDetails ? (
            <Grid
              container
              sx={{ backgroundColor: " rgba(143, 146, 161,0.05)" }}
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={8} sx={{ borderRadius: "8px" }}>
                <Box sx={{ margin: "5px", pt: 7 }}>
                  <BackIcon
                    onClick={() => navigate(-1)}
                    style={{ cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: 500,
                      color: "#0F2C6F",
                      my: 1,
                    }}
                  >
                    {" "}
                    {currentTenantDetails?.company_name}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#9AA6C3",
                      display: "inline",
                    }}
                  >
                    Requested on{" "}
                    {currentTenantDetails.created_at
                      ? getTimeStamp(currentTenantDetails?.created_at)
                      : ""}
                  </Typography>
                  <Chip
                    sx={{
                      backgroundColor: "#C0C7D7",
                      color: "#0F2C6F",
                      borderRadius: "6px",
                      height: "24px",
                      py: 0.5,
                      ml: 2,
                    }}
                    label={currentTenantDetails?.approved_status?.toUpperCase()}
                  />
                </Box>

                <Grid
                  container
                  rowSpacing={2}
                  sx={{ p: 3, backgroundColor: "#FFFFFF", mt: 2 }}
                >
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#0F2C6F",
                        fontWeight: 550,
                      }}
                    >
                      Company Details
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ color: " #0F2C6F", fontSize: "16px" }}>
                      {currentTenantDetails?.company_name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "14px" }}>
                      Main Contact : {currentTenantDetails.country_code}{" "}
                      {currentTenantDetails?.phone_no}{" "}
                    </Typography>
                    <Typography
                      sx={{ color: " #0F2C6F", fontSize: "14px" }}
                    >{`Default Unit : #${currentTenantDetails?.level_name}-${currentTenantDetails?.unit_name}`}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <Typography sx={{ color: "#0F2C6F", fontSize: "14px" }}>
                      Main Email : {currentTenantDetails?.email}
                    </Typography>
                    <Typography sx={{ color: " #0F2C6F", fontSize: "14px" }}>
                      Accessible Floor :
                      {accessibleFloor?.map((value, index) => {
                        if (index == accessibleFloor?.length - 1) {
                          return <span> #{value}</span>;
                        } else {
                          return <span> #{value} ,</span>;
                        }
                      })}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#0F2C6F",
                        fontWeight: 550,
                      }}
                    >
                      Occupied Units
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {tenantDetails.assignedUnitsArrayList?.map((value) => (
                      <StyledChip
                        sx={{
                          mt: 0.5,
                          backgroundColor: value.unit_name.includes("default")
                            ? "#173C92"
                            : "#9AA6C3",
                        }}
                        label={`#${value.level_name}-${value.unit_name}`}
                      />
                    ))}
                  </Grid>
                </Grid>

                <Box sx={{ p: 3, backgroundColor: "#FFFFFF" }}>
                  {tenantEmpDetails.length > 0
                    ? tenantEmpDetails.map((item) => (
                        <Box sx={{ p: 3, border: " 1px solid #C0C7D7", mb: 2 }}>
                          <Typography
                            sx={{
                              color: "#0F2C6F",
                              fontSize: "16px",
                              fontWeight: 550,
                            }}
                          >
                            {item.role}
                          </Typography>
                          <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={4}>
                              <StyledTypography sx={{ fontWeight: 550 }}>
                                {item.first_name} {item.last_name}
                              </StyledTypography>
                              <StyledTypography>
                                {item.job_title}
                              </StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <StyledTypography>
                                Access Start:{" "}
                                {moment(item.access_start_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </StyledTypography>
                              <StyledTypography>
                                Office Unit: #{item.level_name}-{item.unit_name}
                              </StyledTypography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <StyledTypography>
                                Office:{" "}
                                {item?.office_country_code?.includes("+")
                                  ? `${item.office_phone_no ? item?.office_country_code :"-"}`
                                  : ""}{" "}
                                {item.office_phone_no}
                              </StyledTypography>
                              <StyledTypography>
                                Mobile:{" "}
                                {item?.mobile_country_code?.includes("+")
                                  ? `${item?.mobile_country_code}`
                                  : ""}{" "}
                                {item.mobile_no}
                              </StyledTypography>
                              <StyledTypography>{item.email}</StyledTypography>
                            </Grid>
                          </Grid>
                        </Box>
                      ))
                    : ""}
                </Box>
              </Grid>
              <Grid item xs={2}></Grid>

              <Grid item xs={10} sx={{ pb: 8, pt: 5 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  {renderEditButton()}
                  {authData.role_id === 3
                    ? renderBMSCoButtons(currentTenantDetails?.approved_status)
                    : renderBMSManagerButtons(
                        currentTenantDetails?.approved_status
                      )}
                </Box>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          ) : (
            <Skeleton variant="text" height="100%" />
          )}
          <Modal open={rejectTenantStatus}>
            <Box
              sx={{
                position: "absolute",
                top: "20%",
                left: { xs: "5%", sm: "30%" },
                backgroundColor: "#FFFFFF",
                width: { xs: "auto", md: "600px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C0C7D7",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "15px 15px 15px 24px",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "550", color: "#0F2C6F" }}
                >
                  Reject onboarding tenant company submission
                </Typography>
                <IconButton sixe="large">
                  <CloseButton onClick={() => closePopup()} />
                </IconButton>
              </Box>
              <Box sx={{ padding: "16px 24px" }}>
                <Typography sx={{ color: "#6F80A9", fontSize: "16px" }}>
                  {" "}
                  Comments below will be visible to BMS coordinator
                </Typography>
              </Box>
              <Box sx={{ padding: "16px 24px" }}>
                <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
                  {" "}
                  Please provide Instruction for BMS Coordinator to update*
                </Typography>
              </Box>
              <Box sx={{ padding: "5px 24px" }}>
                <OutlinedInput
                  multiline
                  fullWidth
                  sx={{ color: "#0F2C6F", fontSize: "14px", minHeight: 100 }}
                  placeholder="You may write a short summary or note to BMS Coordinator."
                  onChange={(e) => handleRejectionMsg(e)}
                  value={rejectionMsg}
                />
                <Typography
                  sx={{ color: "#6F80A9", fontSize: "14px", mt: 1 }}
                >{`[${rejectionMsg.length}/300]`}</Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "end", padding: "24px" }}
              >
                <Button
                  variant="contained"
                  onClick={() => rejectTenant()}
                  disabled={rejectionMsg.length <= 0 ? true : false}
                  sx={{
                    color: "#ffffff",
                    backgroundColor: "#0F2C6F",
                    "&:hover": { backgroundColor: "#0F2C6F" },
                    height: "40px",
                    padding: "10px",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal open={offboardTenantStatus}>
            <Box
              sx={{
                position: "absolute",
                top: "20%",
                left: "30%",
                backgroundColor: "#FFFFFF",
                width: "40%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #C0C7D7",
                  borderRadius: "8px 8px 0px 0px",
                  padding: "24px 24px 10px 24px",
                }}
              >
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 550, color: "#0F2C6F" }}
                >
                  Offboard tenant company
                </Typography>
                <IconButton sixe="large">
                  <CloseButton onClick={() => closePopup()} />
                </IconButton>
              </Box>
              <Box sx={{ padding: "16px 24px" }}>
                <Typography sx={{ color: "#0F2C6F", fontSize: "16px" }}>
                  Please select a Offboard Date*
                </Typography>
              </Box>
              <Box sx={{ padding: "16px 24px" }}>
                <DatePicker
                  components={{
                    OpenPickerIcon: DatePickerIcon,
                  }}
                  disablePast
                  label="Offboard Date"
                  value={offboardData}
                  onChange={(newValue) => {
                    setOffboardData(newValue);
                    setOffboardErrs((prev) => ({
                      ...prev,
                      offboard_date: "",
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => preventDatePickerKeyboard(e)}
                      {...params}
                    />
                  )}
                />
                {offboardErrs?.offboard_date && (
                  <Typography sx={{ color: "#f44336" }}>
                    {offboardErrs?.offboard_date}
                  </Typography>
                )}
                <TextField
                  id="outlined-basic"
                  label="Remarks"
                  variant="outlined"
                  sx={{ marginTop: "20px", display: "block" }}
                  onChange={(e) => {
                    setOffboardMsg(e.target.value);
                    setOffboardErrs((prev) => ({
                      ...prev,
                      remarks: "",
                    }));
                  }}
                />
                {offboardErrs?.remarks && (
                  <Typography sx={{ color: "#f44336" }}>
                    {offboardErrs?.remarks}
                  </Typography>
                )}
                <DatePicker
                  label="Reonboard Date"
                  disablePast
                  components={{
                    OpenPickerIcon: DatePickerIcon,
                  }}
                  minDate={
                    new Date() > new Date(tenantEmpDetails?.reonboard_date)
                      ? new Date()
                      : new Date(tenantEmpDetails?.reonboard_date)
                  }
                  value={reonboardDate}
                  onChange={(newValue) => {
                    setReonboardDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      sx={{ mt: 2.5 }}
                      onKeyDown={(e) => preventDatePickerKeyboard(e)}
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "end", padding: "24px" }}
              >
                <Button
                  variant="contained"
                  onClick={() => offboardTenant()}
                  sx={{
                    color: "#ffffff",
                    textTransform: "capitalize",
                    "&:hover": { backgroundColor: "#0F2C6F" },
                    backgroundColor: "#0F2C6F",
                    height: "40px",
                    padding: "10px",
                    fontSize: "14px",
                  }}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantUpdateStatus: state.CommonReducer.tenantUpdateStatus,
    tenantDetails: state.CommonReducer.tenantDetails,
    tenantOffBoardStatus: state.CommonReducer.tenantOffBoardStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateTenantStatus: (data, token) =>
      dispatch(commonAction.manageTenantStatus(data, token)),
    setStatusNull: (data, token) =>
      dispatch(commonAction.setStatusNull(data, token)),
    getTenantDetailsById: (data, token) =>
      dispatch(commonAction.getTenantDetailsById(data, token)),
    offBoardTenant: (data, token) =>
      dispatch(commonAction.offBoardTenant(data, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewTenant);
