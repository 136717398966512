import { Box, Chip, Grid, Typography, Button, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Checkbox, Link } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { ReactComponent as NotificationIcon } from '../../../Assets/icons/OutlinedNotification.svg';
import { ReactComponent as DownloadIcon } from '../../../Assets/icons/DownloadIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../Assets/icons/DeleteExcel.svg';
import { ReactComponent as PdfIcon } from '../../../Assets/icons/PDFIcon.svg';
import DropZone from '../../../Utils/dropzone';
import * as serviceRequestAction from "../../../redux/actions/service_request";
import { connect } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import ToastAlert from '../../common/Alert';
import Loader from '../../common/Loader';
import { getLoggedUserData } from '../../../Utils/utils';
import { saveAs } from 'file-saver';
import moment from 'moment';

const StyledPrimaryTypography = styled(Typography)({
  color: '#0F2C6F',
  fontSize: '18px'
})

const StyledTypography = styled(Typography)({
  color: '#0F2C6F',
  fontSize: '12px'
})

const StyledHeadTableCell = styled(TableCell)({
  color: "#0F2C6F",
  fontSize: '12px',
  fontWeight:550
})

const StyledTableCell = styled(TableCell)({
  color: '#0F2C6F',
  fontSize: '12px'
})
const PreviewSecurityClearance = ({ hidePreview,setServiceRequestStateNull, addSecurityClearanceReqAsDraft, tenantData, reviewData, addSecurityClearanceReqStatus, addSecurityReq,saveSecurityDetails,securityDetails,downloadSecurityPdf,downloadPdf,updateSecurityDraft,updateSecurityDraftStatus,saveSecurityDetailsDraft,setFromPreviewPage }) => {

  let navigate = useNavigate()
  let {reqId} = useParams()
  let { personInCharge, tenantCompany, office, mobile } = tenantData;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [workmanList, setWorkManList] = useState([]);
  const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
  const [loaderStatus,setLoaderStatus] = useState(false)
  const [id,setId] = useState(null)

  useEffect(() => { 
    getworkmanList()
  }, [])

  useEffect(()=>{
    if(workmanList.length>0){
    saveDetails()
    }
  },[workmanList])

  useEffect(()=>{
      if(securityDetails.service_requests_id){
        // downloadSecurityPdf({
        //   company_id:getLoggedUserData().company_id,
        //   service_requests_id:securityDetails.service_requests_id,
        //   security_service_requests_id:securityDetails.service_requests_id
        // })
        // setId(securityDetails.service_requests_id)
        setId(reqId)
      }
  },[securityDetails])

  useEffect(() => {
    setLoaderStatus(false)
    if (addSecurityClearanceReqStatus?.response_status == 1) {
      setSnackbarStatus({ message: addSecurityClearanceReqStatus?.message, status: true, color: 'green', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
        navigate(`/service_request_list/0`)
      }, 5000);
    } else if (addSecurityClearanceReqStatus !== null) {
      setSnackbarStatus({ message: addSecurityClearanceReqStatus?.message, status: true, color: 'red', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
      }, 5000);
    }
  }, [addSecurityClearanceReqStatus])

  useEffect(()=>{
    setLoaderStatus(false)
    if (updateSecurityDraftStatus?.response_status==1) {
      setSnackbarStatus({ message: updateSecurityDraftStatus?.message, status: true, color: 'green', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
        navigate(`/service_request_list/0`)
      }, 5000);
    } else if (addSecurityClearanceReqStatus?.response_status==0) {
      setSnackbarStatus({ message: updateSecurityDraftStatus?.message, status: true, color: 'red', icon: 'close' })
      setServiceRequestStateNull()
      setTimeout(() => {
        removeSnackbar()
      }, 5000);
    }
  },[updateSecurityDraftStatus])

  const removeSnackbar = () => {
    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
  }

  const handleSelectedFiles = (files) => {
    setSelectedFiles([files[0]])
  }

  const saveDetails = ()=>{
    let data = new FormData();
    let arrayList = JSON.stringify(workmanList)
    data.append('company_id', reviewData.company_id);
    data.append('contractor_phone_code', reviewData.mobile_code);
    data.append('contractor_phone_no', reviewData.mobile_number);
    data.append('description', reviewData.workDetails);
    data.append('from_date', reviewData.startDate);
    data.append('office_phone_code', reviewData.office_code);
    data.append('office_phone_no', reviewData.office_number);
    data.append('person_in_charge', reviewData.personInCharge);
    data.append('service_request_types_id', reviewData.service_request_types_id);
    data.append('to_date', reviewData.endDate);
    data.append('from_time', "NA");
    data.append('to_time', "NA");
    data.append("weekday_from_time", reviewData.time.weekStartTime);
    data.append("weekday_to_time", reviewData.time.weekEndTime);
    data.append("saturday_from_time", reviewData.time.satStartTime);
    data.append("saturday_to_time", reviewData.time.satEndTime);
    data.append("sunday_ph_from_time", reviewData.time.sunStartTime);
    data.append("sunday_ph_to_time", reviewData.time.sunEndTime);
    data.append('contractor_company', reviewData.contractorCompany);
    data.append('contractor_address_1', reviewData.addressLine1);
    data.append('contractor_address_2', reviewData.addressLine2);
    data.append('securityArrayList', arrayList);
    if(reqId!='null'){
      // saveSecurityDetailsDraft(data)
    }else{
      // saveSecurityDetails(data)
    }
  }
  const getworkmanList = () => {
    let valid = false;
    let workmanArr = [];
    for (var i = 1; i <= 15; i++) {
      let obj = {};
      if (reviewData.hasOwnProperty(`workManName${i}`)) {
        valid = true;
        obj['name'] = reviewData[`workManName${i}`]
      }else{
        valid = false
      }

      if (reviewData.hasOwnProperty(`workManNationality${i}`)) {
        valid = true;
        obj['nationality_id'] = reviewData[`workManNationality${i}`]
      }else{
        valid = false
      }

      if (reviewData.hasOwnProperty(`workManNationality${i}name`)) {
        valid = true;
        obj['nationality_name'] = reviewData[`workManNationality${i}name`]
      }else{
        valid = false
      }

      if (reviewData.hasOwnProperty(`workManNRIC${i}`)) {
        valid = true;
        obj['nric'] = reviewData[`workManNRIC${i}`]
      }else{
        valid = false
      }

      if(valid){
        workmanArr.push(obj)
      }
      setWorkManList(workmanArr)
    }
  }

  const handleSubmit = (submissionType) => {

    let data = new FormData();
    data.append('company_id', reviewData.company_id);
    data.append('contractor_phone_code', reviewData.mobile_code);
    data.append('contractor_phone_no', reviewData.mobile_number);
    data.append('description', reviewData.workDetails);
    data.append('from_date', reviewData.startDate);
    data.append('office_phone_code', reviewData.office_code);
    data.append('office_phone_no', reviewData.office_number);
    data.append('person_in_charge', reviewData.personInCharge);
    data.append('service_request_types_id', reviewData.service_request_types_id);
    data.append('to_date', reviewData.endDate);
    data.append('from_time', "NA");
    data.append('to_time', "NA");
    data.append("weekday_from_time", reviewData.time.weekStartTime || '');
    data.append("weekday_to_time", reviewData.time.weekEndTime || '');
    data.append("saturday_from_time", reviewData.time.satStartTime || '');
    data.append("saturday_to_time", reviewData.time.satEndTime || '');
    data.append("sunday_ph_from_time", reviewData.time.sunStartTime || '');
    data.append("sunday_ph_to_time", reviewData.time.sunEndTime || '');
    data.append('contractor_company', reviewData.contractorCompany);
    data.append('contractor_address_1', reviewData.addressLine1);
    data.append('contractor_address_2', reviewData.addressLine2);
    data.append('securityArrayList', JSON.stringify(workmanList));
    data.append('file_count', 0);
    // data.append('file0', selectedFiles[0]?.file)
    data.append('service_requests_id',reqId)
    // if(reqId!='null'){
      data.append('status',submissionType )
    // }

    // if (selectedFiles.length && submissionType === "submitted") {
      if (submissionType === "submitted") {
      // if(reqId!='null'){
        // updateSecurityDraft(data)
        addSecurityReq(data)
        setLoaderStatus(true)
        setTimeout(()=>{
          setLoaderStatus(false)
        },15000)
      // }else{
      //   addSecurityReq(data)
      //   setLoaderStatus(true)
      //   setTimeout(()=>{
      //     setLoaderStatus(false)
      //   },15000)
      // }
      
    } 
    // else if (submissionType === "draft") {
    //   if(reqId!='null'){
    //     updateSecurityDraft(data)
    //     setLoaderStatus(true)
    //     setTimeout(()=>{
    //       setLoaderStatus(false)
    //     },15000)
    //   }else{
    //     addSecurityClearanceReqAsDraft(data)
    //     setLoaderStatus(true)
    //     setTimeout(()=>{
    //       setLoaderStatus(false)
    //     },15000)
    //   }
     
    // }
  }

  return (
    <>
    {loaderStatus && <Loader status={loaderStatus} /> }
      <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
        <Grid item xs={0.5} />
        <Box sx={{ mt: 7, mb: 4 }}>
          <ArrowIcon cursor='pointer' onClick={()=>{
            hidePreview(false)
            setFromPreviewPage({lift:false,airCon:false,security:true})
            }}/>
          <Typography sx={{ fontSize: '26px', color: '#0F2C6F', mt: 2, mb: 1 }}>Service request- Security Clearance  </Typography>

          {/* <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>Requested on 13 Jun 2022</Typography> */}
          
        </Box>
        <Grid item xs={8} />
        <Grid item xs={3.5} />
      </Grid>

      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
        <Grid item xs={0.5} />
        <Grid item xs={8}>
          <Box sx={{ backgroundColor: '#FFFFFF', p: 3 }}>
            <StyledPrimaryTypography >Tenant Details</StyledPrimaryTypography>
            <Grid container sx={{ my: 3, pb: 3 }} >
              <Grid item xs={12} sm={4}>
                <Typography sx={{ color: ' #0F2C6F', fontSize: '14px' }}>{personInCharge}</Typography>
                <StyledTypography>{tenantCompany}</StyledTypography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledTypography>Office : {office}</StyledTypography>
                <StyledTypography>Mobile : {mobile}</StyledTypography>
              </Grid>
              {/* <Grid item xs={12} sm={2}>
                  <Button variant='outlined' endIcon={<DownloadIcon/>} sx={{textTransform:"capitalize",backgroundColor:"#CFDDFE",color:"#0F2C6F",fontSize:"14px"}} href={`${process.env.REACT_APP_API_URL}/download-pdf?company_id=${getLoggedUserData().company_id}&service_requests_id=${id}&security_service_requests_id=${id}`}>Download</Button>
                  
              </Grid> */}
            </Grid>

            <Grid container rowSpacing={5}>
              <Grid item xs={12} sm={4}>
                <StyledPrimaryTypography>  Details</StyledPrimaryTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTypography>{reviewData.contractorCompany}</StyledTypography>

                <StyledTypography>{reviewData.addressLine1} </StyledTypography>
                <StyledTypography>{reviewData.addressLine2}</StyledTypography>
                <StyledTypography>Office:+{reviewData.office_code}{reviewData.office_number || reviewData.office} </StyledTypography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <StyledTypography>{reviewData.personInCharge}</StyledTypography>
                <StyledTypography>Mobile:+{reviewData.mobile_code}{reviewData.mobile_number}</StyledTypography>
              </Grid>
              <Grid item xs={12}>
                <StyledPrimaryTypography>Preferably Work Period </StyledPrimaryTypography>
                {/* <StyledPrimaryTypography>11 Oct 2022</StyledPrimaryTypography> */}
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledHeadTableCell >Name</StyledHeadTableCell>
                        <StyledHeadTableCell>Nationality</StyledHeadTableCell>
                        <StyledHeadTableCell>NRIC(Last 4 numbers)</StyledHeadTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {workmanList.map((value) => (
                        <TableRow>
                          <StyledTableCell >{value.name}</StyledTableCell>
                          <StyledTableCell>{value.nationality_name}</StyledTableCell>
                          <StyledTableCell>{value.nric}</StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid item xs={12} container sx={{ my: 5 }}>
              <Grid item xs={4}>
                <StyledPrimaryTypography>Contract Period</StyledPrimaryTypography>
              </Grid>
              <Grid item xs={4}>
                <StyledTypography> Start Date : {moment(reviewData.showstartDate).format('DD-MM-YYYY')}</StyledTypography>
                <StyledTypography> End Date : {moment(reviewData.showendDate).format('DD-MM-YYYY')}</StyledTypography>
              </Grid>
              <Grid item xs={4}>
                <StyledTypography>Working days</StyledTypography>
                <StyledTypography>Weekdays: {reviewData.time.weekStartTime} - {reviewData.time.weekEndTime}</StyledTypography>
                <StyledTypography>Saturday: {reviewData.time.satStartTime}-{reviewData.time.satEndTime}</StyledTypography>
                <StyledTypography>Sunday: {reviewData.time.sunStartTime}-{reviewData.time.sunEndTime}</StyledTypography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <StyledPrimaryTypography>Work Details</StyledPrimaryTypography>
              <Typography sx={{ color: " #0F2C6F", fontSize: "14px" }}>{reviewData.workDetails}</Typography>
            </Grid>

          </Box>
          <Box sx={{ p: 3, backgroundColor: '#FFFFFF' }}>
            <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>{reviewData?.terms_condition[0].title}</Typography>
            <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{reviewData?.terms_condition[0].description}</Typography>
            {reviewData?.terms_condition[1].rules.map((value,index)=>(
                      <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{`${index+1} . ${value[index+1]}`}</Typography>
            ))}
            
            <Box sx={{ display: "flex" }}>
              <Checkbox checked disabled />
              <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 2 }}>I have read and agreed to comply with the above terms and conditions for holding the event in the Building upon the Management’s approval.</Typography>
            </Box>
          </Box>
          <Box sx={{display:'flex', justifyContent:'flex-end',}}>
            <Button  variant="contained" sx={{  my: 2, textTransform: 'capitalize' }} onClick={() => handleSubmit('submitted')}>Submit</Button>
          </Box>
        </Grid>

        <Grid item xs={3.5}>
          {/* <Box sx={{ backgroundColor: '#FFFFFF', mx: 3 }}>
            <Box sx={{ p: 1.5 }}>
              <StyledPrimaryTypography >Contractor Signature</StyledPrimaryTypography>
            </Box>
            <Divider />
            <Box sx={{ px: 2.5, py: 2 }}>
              <Typography sx={{ mb: 1, color: '#0F2C6F', fontSize: '14px' }}>Please download the filled up security clearance file in pdf and upload the completed form with the Contractor’s signature here. </Typography>
              {selectedFiles.length > 0 ?

                <Box sx={{ display: 'flex', p: 3,  justifyContent: 'space-between', border: '1px solid #0F2C6F', borderRadius: '6px' }}>
                  <PdfIcon />
                  <Box>
                    <Typography sx={{ color: '#0F2C6F', fontSize: "14px" }}>{selectedFiles[0]?.fileName}</Typography>
                    <Typography sx={{ color: '#9AA6C3', fontSize: "12px" }}>{Math.round(selectedFiles[0].size / 1000)}KB of {Math.round(selectedFiles[0].size / 1000)}KB</Typography>
                  </Box>
                  <DeleteIcon style={{ marginTop: "5px" }} onClick={() => setSelectedFiles([])} />

                </Box> :
                <DropZone getFiles={handleSelectedFiles} onlyPdf={true} />
              }
              <Button fullWidth variant="outlined" sx={{ my: 2, textTransform: 'capitalize', color: '#0F2C6F', border: '1px solid #AAC3FF', '&:hover': { backgroundColor: '#FFFFFF' } }} onClick={() => handleSubmit('draft')} >Save as Draft</Button>
              <Button fullWidth disabled={selectedFiles.length===0} variant="contained" sx={{ my: 1, textTransform: 'capitalize' }} onClick={() => handleSubmit('submitted')}>Submit</Button>
            </Box>

          </Box> */}
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    addSecurityClearanceReqStatus: state.Service_Req_Reducer.addServiceReqStatus,
    securityDetails:state.Service_Req_Reducer.saveSecurityDetails,
    downloadPdf:state.Service_Req_Reducer.downloadSecurityPdf,
    updateSecurityDraftStatus:state.Service_Req_Reducer.updateSecurityDraftStatus
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addSecurityReq: (data) => (dispatch(serviceRequestAction.addSecurityClearanceReq(data))),
    addSecurityClearanceReqAsDraft: (data) => (dispatch(serviceRequestAction.addSecurityClearanceReqAsDraft(data))),
    setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
    saveSecurityDetails: (data) => (dispatch(serviceRequestAction.saveSecurityDetails(data))),
    saveSecurityDetailsDraft: (data) => (dispatch(serviceRequestAction.saveSecurityDetailsDraft(data))),
    downloadSecurityPdf:(data)=>(dispatch(serviceRequestAction.downloadSecurityPdf(data))),
    updateSecurityDraft:(data)=>(dispatch(serviceRequestAction.updateSecurityDraft(data)))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PreviewSecurityClearance);
