import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import AnnouncementTable from "./AnnouncementTable";
import { ReactComponent as PlusIcon } from '../../Assets/icons/WhiteRoundedPlusIcon.svg';
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { getLoggedUserData } from "../../Utils/utils";

const StyledTab = styled(Tab)({
  textTransform: 'capitalize',
  color: '#6F80A9',
  fontSize: '14px'
})

const Index = () => {
  let { user_id, role_id, role } = getLoggedUserData();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0)

  const renderTabs = () => {

    if (role_id === 3 || role_id === 2 || role_id===14) {
      return (<><Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} sx={{ borderBottom: '1px solid #DBDFE9' }}>
        <StyledTab label='Active' />
        <StyledTab label='Scheduled' />
        <StyledTab label='Inactive' />
        {/* <StyledTab label='Draft' /> */}
      </Tabs>
        <Tabpanel value={tabValue} index={0}>
          <AnnouncementTable activetype='Active' tabValue={tabValue} />
        </Tabpanel>
        <Tabpanel value={tabValue} index={1}>
          <AnnouncementTable activetype='Scheduled'  tabValue={tabValue} />
        </Tabpanel>
        <Tabpanel value={tabValue} index={2}>
          <AnnouncementTable activetype='Inactive' />
        </Tabpanel>
        {/* <Tabpanel value={tabValue} index={3}>
          <AnnouncementTable activetype='Draft' />
        </Tabpanel> */}
      </>)
    } else {
      return (<><Tabs value={tabValue} onChange={(e, value) => setTabValue(value)} sx={{ borderBottom: '1px solid #DBDFE9' }}>
        <StyledTab label='Active' />
      </Tabs>
        <Tabpanel value={tabValue} index={0}>
          <AnnouncementTable activetype='Active' />
        </Tabpanel>
      </>)
    }
  }
  return (
    <>
      <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)',minHeight:"100%" }}>
        <Grid item xs={0.5} />

        <Grid item xs={11}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 8 }}>
            <Box>
              {role_id === 3 || role_id === 2 || role_id===14 ? <Typography sx={{ color: '#002D74', fontSize: '26px', fontWeight:'500' }}>Manage Announcements</Typography> :
                < Typography sx={{ color: '#002D74', fontSize: '26px', fontWeight:'500' }}>Announcements</Typography>}
              {role_id === 3 || role_id === 2 || role_id===14 ? <Typography sx={{ color: "#002D74", fontSize: '14px',fontWeight:'500' }}>BMS Administrators can manage, post and edit announcements. These will be visible to
all users using the SingLand system. (Mobile app and Dashboard)</Typography> : ''}
            </Box>
            <Box>
              {role_id === 3 || role_id === 2 || role_id===14 ? <Button endIcon={<PlusIcon />} variant="contained" onClick={() => navigate('/new_announcement/null/false')} sx={{minWidth:'200px', backgroundColor: '#123FA9', color: '#FFFFFF', fontSize:'14px', textTransform: 'capitalize', '&:hover': { backgroundColor: '#123FA9' } }}>New Announcement</Button> : ''}
            </Box>
          </Box>

          <Box sx={{ backgroundColor: '#FFFFFF' }}>
            {renderTabs()}

          </Box>

        </Grid>

        <Grid item xs={0.5} />
      </Grid>
    </>
  )
};

const Tabpanel = (props) => {
  const { value, index, children } = props;
  return (
    <>
      {value === index && <>{children}</>}
    </>
  )
}

export default Index;
