import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ReactComponent as BackArrow } from "../../Assets/icons/BackArrowAdd.svg";
import { useDropzone } from "react-dropzone";
import { ReactComponent as Sync } from "../../Assets/icons/Sync.svg";
import { useNavigate } from "react-router-dom";
import {
  AddServiceRequestFormsAction, AddServiceRequestNullAction,
} from "../../redux/actions/service_request_forms_action";
import { connect } from "react-redux";
import { ReactComponent as PdfIcon } from "../../Assets/icons/PdfEditIcon.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/EditFileClose.svg";
import { ReactComponent as ImageFormatIcon } from "../../Assets/icons/ImageFormatIcon.svg";
import { ReactComponent as ExcelFormatIcon } from "../../Assets/icons/Excel.svg";

import ToastAlert from "../common/Alert";
import DropZone from "../../Utils/dropzone";


const AddFileServiceRequest = ({
  addRequestForms,
  addFormsData,
  AddServiceRequestNull
}) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [file, setFile] = useState(null);
  const [selectTrigger, setSelectTrigger] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [FileError, setFileError] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [toastModal, setToastModal] = useState(false);


  useEffect(() => {
    if (addFormsData.response?.response_status === 1) {
      setFile("");
      setTitle("");
      setSelectTrigger(false);
      navigate(-1);
    } else if (addFormsData.response?.response_status === 0) {
      setUploadStatus({
        message: "File Not Uploaded",
        status: true,
        color: "red",
        icon: "tick",
      });
      setFile("");
      setTitle("");
      setToastModal(true);
      setSelectTrigger(false);
      setTimeout(() => {
        setToastModal(false);
      }, 3000);
      AddServiceRequestNull();
    }
  }, [addFormsData]);

  const onDrop = (acceptedFiles) => {
     setFileError("");
    setFile(acceptedFiles[0]);
    setSelectTrigger(true);
  };

  const onUpload = () => {
    setTitleError("");
    setFileError("");

    if (!title.trim()) {
      setTitleError("Title should not be empty");
    }
    if (!file) {
      setFileError("File should not be empty");
    }
    if (title.trim() && file) {
      setOpenDeleteModal(true);
    }
  };

  const onSubmitForm = () => {
    const formData = new FormData();
    formData.append("path", file?.file);
    formData.append("name", title);
    addRequestForms(formData);
    setOpenDeleteModal(false);
  };

  const clearForm = () => {
    setTitle('');
    setFile(null);
    setSelectTrigger(false);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Box
      sx={{
        padding: "50px 30px 50px 30px",
        maxWidth: "782px",
        margin: "0 auto",
      }}
    >
      <BackArrow cursor={"pointer"} onClick={() => navigate(-1)} />
      <Typography
        style={{
          fontWeight: 500,
          fontSize: "26px",
          color: "#002D74",
          marginTop: "20px",
        }}
      >
        Upload Service Request Form
      </Typography>
      <Box
        sx={{
          borderRadius: "8px",
          padding: "25px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Typography
          sx={{ fontWeight: 600, fontSize: "14px", color: "#0F2C6F" }}
        >
          Title
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Enter title of the service request form"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            setTitleError("");
          }}
          InputProps={{
            style: {
              borderRadius: "6px",
              border: "1px solid #9AA6C3",
              padding: "8px 12px 8px 12px",
              color: "#0F2C6F",
              height: "48px",
              marginTop: "10px",
              minWidth: "395px",
              fontWeight: 500,
              fontSize: "14px",
            },
            disableUnderline: true,
          }}
        />
        {titleError && (
          <Typography sx={{ color: "#f44336" }}>{titleError}</Typography>
        )}
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "18px",
            color: "#0F2C6F",
            marginTop: "20px",
          }}
        >
          Upload New Service Request Form
        </Typography>
        {FileError && (
          <Typography sx={{ color: "#f44336" }}>{FileError}</Typography>
        )}
        {selectTrigger ? (
          <Box
            sx={{
              borderRadius: "8px",
              border: "1px solid #DBDFE9",
              padding: "25px",
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
            {file?.file?.type === 'application/pdf' && <PdfIcon style={{width: '100px', height: "120px"}} />}
            {file?.file?.type === "text/csv" && <ExcelFormatIcon style={{width: '100px', height: "120px"}} />}

               <Box sx={{ marginLeft: "20px", marginTop: "20px" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "18px",
                    color: "#0F2C6F",
                    marginTop: "10px",
                  }}
                >
                  {file?.fileName}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "12px",
                    color: "#6F80A9",
                    marginTop: "10px",
                  }}
                >
                   {(file?.size / 1024).toFixed(2)} of{" "}
                  {(file?.size / 1024).toFixed(2)} KB
                </Typography>
              </Box>
            </Box>
            <Box>
              <CloseIcon
                cursor={"pointer"}
                onClick={() => {
                  setSelectTrigger(false);
                  setFile(null);
                }}
              />
            </Box>
          </Box>
        
        ) : (
          <DropZone getFiles={e => onDrop(e)} customFileFormat={{              
          'application/pdf': ['.pdf'],
          'application/vnd.ms-excel':['.xlsx','.xls'],
         }} singleFile={true}  customFileText={'.pdf, .xlsx, .xls'} />
          // <Box
          //   {...getRootProps()}
          //   sx={{
          //     borderRadius: "8px",
          //     marginTop: "20px",
          //     padding: "44px 277px 44px 277px",
          //     cursor: "pointer",
          //     backgroundColor: "#EAF0FF",
          //   }}
          // >
          //   <Box
          //     sx={{
          //       display: "flex",
          //       flexDirection: "column",
          //       alignItems: "center",
          //       textalign: "center",
          //     }}
          //   >
          //     <Typography
          //       sx={{ fontWeight: 600, fontSize: "14px", color: "#0F2C6F" }}
          //     >
          //       Drag and Drop file here
          //     </Typography>
          //     <Typography
          //       sx={{
          //         fontWeight: 400,
          //         fontSize: "12px",
          //         color: "#0F2C6F",
          //         width: "350px",
          //         marginTop: "10px",
          //       }}
          //     >
          //       Please ensure that uploaded service request form is accurate.
          //       Files Supported: .pdf, .png, .jpg, .xls (Max 25 MB)
          //     </Typography>
          //     <Button
          //       sx={{
          //         borderRadius: "6px",
          //         border: "1px solid #AAC3FF",
          //         backgroundColor: "#FFFFFF",
          //         padding: "8px 10px 8px 10px",
          //         color: "#0F2C6F",
          //         fontWeight: 600,
          //         fontSize: "14px",
          //         marginTop: "30px",
          //       }}
          //     >
          //       Choose File <input {...getInputProps()} />
          //     </Button>
          //   </Box>
          // </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Sync style={{ marginRight: "8px" }} />
          <Typography
            sx={{ color: "#9AA6C3", fontWeight: 400, fontSize: "14px" }}
          >
            Last Updated at 10:35AM
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button
            sx={{
              borderRadius: "8px",
              border: "1px solid #AAC3FF",
              backgroundColor: "#FFFFFF",
              padding: "13px 10px 13px 10px",
              color: "#0F2C6F",
              fontSize: "14px",
              marginRight: "20px",
              textTransform: "none"
            }}
            onClick={() => clearForm()}
          >
            Clear
          </Button>
          <Button
            onClick={onUpload}
            sx={{
              borderRadius: "8px",
              backgroundColor: "#123FA9",
              padding: "13px 10px 13px 10px",
              color: "#FFFFFF",
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#123FA9",
              },
              textTransform: "none"
            }}
          >
            Upload
          </Button>
        </Box>
      </Box>
      <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #C0C7D7",
              borderRadius: "8px 8px 0px 0px",
              padding: "24px",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Typography
              sx={{ fontWeight: 500, fontSize: "18px", color: "#0F2C6F" }}
            >
              Confirm New Service Request Form
            </Typography>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => setOpenDeleteModal(false)}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#6F80A9",              
              padding: "24px",
            }}
          >
            Please ensure the form details are correct. Once uploaded, the form
            will be published and visible to Singapore Land Tower’s tenants.
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "24px",
          }}
        >
          <Button
            sx={{
              borderRadius: "6px",
              border: "1px solid #AAC3FF",
              padding: "8px 10px 8px 10px ",
              backgroundColor: "#FFFFFF",
              color: "#0F2C6F",
              fontWeight: 600,
              fontSize: "14px",
            }}
            onClick={() => setOpenDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmitForm}
            sx={{
              borderRadius: "6px",
              padding: "8px 10px 8px 10px ",
              backgroundColor: "#123FA9",
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: "14px",
              "&:hover": {
                backgroundColor: "#123FA9",
              },
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {toastModal && (
        <ToastAlert
          message={uploadStatus.message}
          status={uploadStatus.status}
          color={uploadStatus.color}
          icon={uploadStatus.icon}
        />
      )}
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRequestForms: (data) => {
      dispatch(AddServiceRequestFormsAction(data));
    },
    AddServiceRequestNull: () => {
      dispatch(AddServiceRequestNullAction());
    },
  };
};

const mapStateToProps = (state) => {
  return {
    addFormsData: state.ServiceRequestFormsReducer.addServiceRequestData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFileServiceRequest);
