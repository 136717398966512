import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { ReactComponent as RefreshIcon } from '../../../Assets/icons/Refresh.svg';
import { getLastUpdatedTime, getYYYYMMDDFormat, getYYYYMMDDFormatAircon, preventDatePickerKeyboard, } from "../../../Utils/utils";
import { DatePicker, DesktopDatePicker, TimePicker } from "@mui/x-date-pickers";
import { ReactComponent as RoundedPlusIcon } from '../../../Assets/icons/RoundedPlusIcon.svg';
import { ReactComponent as RoundedMinusIcon } from '../../../Assets/icons/MinusIcon.svg';
import { ReactComponent as DatePickerIcon } from '../../../Assets/icons/DatePickerIcon.svg';
import { ReactComponent as CheckboxIcon } from '../../../Assets/icons/CheckboxTerms.svg';
import { connect } from "react-redux";
import * as commonAction from "../../../redux/actions/commonAction";
import * as ServiceAction from "../../../redux/actions/service_request";
import { calculateStartDayPrice, calculateEndDayPrice, getSingleDayPrice, saturDayHours, sundayHours, weekDayHours, calcWeekDaysPrice, calculateSaturDayPrice, calculateSunDayPrice } from "../AirconUtils";
import moment from "moment";
import ToastAlert from "../../common/Alert";
const StyledTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '14px',
    fontWeight: 600,
    marginBottom: '5px'
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const AirConExtensionRequest = ({requestId, getLevels, levels, tenantData, serviceType, returnInputData, generateBillValue, createAirConRequest, termsAndCondition,getPublicHoliday,publicHolidays,setFromPreviewPage,previewPageData,serviceRequestByID,serviceReqDetails,setServiceType }) => {
    let terms_condition = termsAndCondition?.config_value && JSON.parse(termsAndCondition?.config_value);
    const [details, setDetails] = useState([
        {
            id: 1,
            level_id: null,
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            disabledStaus: false,
            min_date: new Date().setDate(new Date().getDate() + 1),
            max_date: new Date().setDate(new Date().getDate() + 8),
            requiredOnSaturdays: false,
            requiredOnSundays: false,
            requiredOnPublicHolidays: false,
        }
    ]);
    const [detailsData, setDetailsData] = useState([])
    const [inputErrors, setInputErrors] = useState({
        start_date: null,
        end_date: null,
        level_id: null,
        start_time: null,
        end_time: null,
        invalid_time: null
    })
    const [lastUpdatedTime, setLastUpdatedTime] = useState('');
    const [airconCheckBox, setAirconCheckBox] = useState({ checkbox1: false, checkbox2: false });
    const [startHours, setStartHours] = useState(sundayHours);
    const [endHours, setEndHours] = useState(sundayHours)
    const [modelOpen, setModelOpen] = useState(false);
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [requestType,setRequestType] = useState("general")
    const [indexValue,setIndexValue] = useState(100)
    const today = new Date()

    const handleModel = () => {
        setModelOpen(!modelOpen);
    };
    console.log('details ',details)

    useEffect(() => {
        getLevels();
        setLastUpdatedTime(getLastUpdatedTime())
        // getAirConStartHours()
        getPublicHoliday()
        if(requestId!="null"){
            serviceRequestByID({ service_requests_id: requestId })
        }
    }, [])
    
    //FROM PREVIEW SCREEN
    useEffect(()=>{
        if(requestId=='null' && sessionStorage.getItem('AirconPreview') && previewPageData.airCon){
            let data = JSON.parse(sessionStorage.getItem('AirconPreview'))
            console.log('details one ',data)
            const jsonToObject = data.map((detail) => {
                if (detail.start_date || detail.end_date) {
                    return {
                        ...detail,
                        start_date: new Date(detail.start_date),
                        end_date: new Date(detail.end_date),
                    };
                }
                return detail;
            });
            console.log('jsonToObject ',jsonToObject)

            setDetails([...jsonToObject])
        }
    },[])

    //FROM DRAFT 
    useEffect(()=>{
        if(serviceReqDetails!=null && serviceReqDetails?.serviceRequestsArrayList?.length > 0){
        let draftDetails = serviceReqDetails?.airConExtensionDurationArrayList
            let arr = []
            for(let i=0;i<draftDetails?.length;i++){
                arr.push({
                        id: i+1,
                        level_id: draftDetails[i].level_id,
                        start_date: new Date(draftDetails[i].from_date),
                        end_date: new Date(draftDetails[i].to_date),
                        start_time: draftDetails[i].start_time,
                        end_time: draftDetails[i].end_time,
                        disabledStaus: draftDetails?.length-1==i ? false : true,
                        min_date: new Date().setDate(new Date().getDate() + 1),
                        max_date: new Date().setDate(new Date().getDate() + 8),
                        requiredOnSaturdays: draftDetails[i].is_saturday=='NA' ? false : true,
                        requiredOnSundays: draftDetails[i].is_sunday=='NA' ? false : true,
                        requiredOnPublicHolidays: draftDetails[i].is_public_holiday=='NA' ? false : true,
                 })
            }
            console.log('details two ',arr)
            setDetails([...arr])
        }
    },[serviceReqDetails])

    useEffect(()=>{
        if(typeof indexValue == 'number'){
            calculateNumberOfDays(indexValue)
        }
    },[details[indexValue]?.start_date,details[indexValue]?.end_date])

    const getAirConStartHours = () => {
        let startDay = new Date(details[0].start_date).getDay(), endDay = new Date(details[0].end_date).getDay(), startDayHours = [], endDayHours = [];
        if (startDay === 6) {
            startDayHours = saturDayHours;
        } else if (startDay === 0) {
            startDayHours = sundayHours;
        } else {
            startDayHours = weekDayHours;
        }

        if (endDay === 6) {
            endDayHours = saturDayHours;
        } else if (endDay === 0) {
            endDayHours = sundayHours;
        } else {
            endDayHours = weekDayHours;
        }

        setStartHours([...startDayHours])
        setEndHours([...endDayHours])
    }


    const handleDetails = (e, reqIndex) => {

        let { name, value } = e.target;
        let newFormValues = [];
        details.forEach((element, index) => {
            if (index === reqIndex) {
                let newobj = { ...element, [name]: value }
                newFormValues.push(newobj)
            } else {
                newFormValues.push(element)
            }

        });
        console.log('details three ',newFormValues)
        setDetails([...newFormValues])
        setInputErrors({ ...inputErrors, [name]: '' })
    }

    const validateAirConExtension = () => {
        let isValid;
        let validateLastDetails = details[details.length - 1], errorArr = [], errObj = {};
        if (validateLastDetails.level_id !== null) {
            errObj[`level_id`] = '';
            errorArr.push(true);
        } else {
            errObj[`level_id`] = 'Level id required!'
            errorArr.push(false);
        }

        if (validateLastDetails.start_date !== null) {
            errObj[`start_date`] = '';
            errorArr.push(true);
        } else {
            errObj[`start_date`] = 'Start date required!'
            errorArr.push(false);
        }

        if (validateLastDetails.end_date !== null) {
            errObj[`end_date`] = '';
            errorArr.push(true);
        } else {
            errObj[`end_date`] = 'End date required!'
            errorArr.push(false);
        }

        if (validateLastDetails.start_time !== null) {
            errObj[`start_time`] = '';
            errorArr.push(true);
        } else {
            errObj[`start_time`] = 'Start time required!'
            errorArr.push(false);
        }

        if (validateLastDetails.end_time !== null) {
            errObj[`end_time`] = '';
            errorArr.push(true);
        } else {
            errObj[`end_time`] = 'End time required!'
            errorArr.push(false);
        }

        if (errorArr[1] && errorArr[2] && errorArr[3] && errorArr[4]) {
            if (new Date(validateLastDetails.start_date).getDate() === new Date(validateLastDetails.end_date).getDate()) {
                let startHour = validateLastDetails.start_time.split(':')[0];
                let endHour = validateLastDetails.end_time.split(':')[0];
                if (endHour <= startHour) {
                    errObj[`invalid_time`] = 'Invalid time selection'
                    errorArr.push(false);
                } else {
                    errObj[`invalid_time`] = ''
                    errorArr.push(true);
                }
            }
        }

        setInputErrors(errObj)
        isValid = errorArr.includes(false)
        return isValid ? false : true;
    }

    useEffect(() => {
        const detailsDatas = details.map((data) => {
            if (data.start_date || data.end_date) {
                return {
                    ...data,
                    start_date: moment(data.start_date).format('YYYY-MM-DD'),
                    end_date: moment(data.end_date).format('YYYY-MM-DD'),
                };
            }
            return data;
        });
        setDetailsData(detailsDatas)
        console.log('detailsDatas ',detailsDatas)
    },[details])

    const handleContinue = () => {
        let valid = validateAirConExtension();

        if (valid) {    
            let dataArray = [], dataToStore = {};
            for (var i = 0; i < details.length; i++) {
                let tempObj = {};
                let { saturdaysCount, sunDaysCount, WeekDaysCount, totalDays } = getDaysCount(new Date(details[i].start_date), new Date(details[i].end_date));
               
                if (totalDays === 1) {
                    console.log('within one day ')
                    let { priceWithoutGST, PriceWithGST, GSTAmount, TotalHours } = getSingleDayPrice(saturdaysCount, sunDaysCount, WeekDaysCount, details[i].start_time, details[i].end_time,details[i]);
                    tempObj['requestedHours'] = TotalHours;
                    tempObj['totalCost'] = priceWithoutGST.toFixed(2);
                    tempObj['totalGST'] = GSTAmount.toFixed(2);
                    tempObj['grandTotal'] = PriceWithGST.toFixed(2);
                    tempObj['level_id'] = details[i].level_id;
                    tempObj['daysCount'] = totalDays;
                    tempObj['start_date'] = getYYYYMMDDFormatAircon(new Date(details[i].start_date));
                    tempObj['end_date'] = getYYYYMMDDFormatAircon(new Date(details[i].end_date));
                    tempObj['start_time'] = details[i].start_time;
                    tempObj['end_time'] = details[i].end_time;
                    tempObj['duration'] = `${moment(details[i].start_date).format("MMM Do")} ${details[i].start_time} - ${moment(details[i].end_date).format("MMM Do")} ${details[i].end_time}`;
                    dataArray.push(tempObj)
                } else {
                    let priceDetails = { priceWithoutGST: 0, PriceWithGST: 0, GSTAmount: 0, TotalHours: 0 }
                    let startDay = new Date(details[i].start_date).getDay();
                    let endDay = new Date(details[i].end_date).getDay();
                    let startdayStr = '', endDayStr = '';
                    if (startDay === 0) {
                        sunDaysCount -= 1;
                        startdayStr = 'SUN';
                    } else if (startDay === 6) {
                        saturdaysCount -= 1;
                        startdayStr = 'SAT';
                    } else {
                        WeekDaysCount -= 1;
                        startdayStr = 'WEEK';
                    }

                    if (endDay === 0) {
                        sunDaysCount -= 1;
                        endDayStr = 'SUN';
                    } else if (endDay === 6) {
                        saturdaysCount -= 1;
                        endDayStr = 'SAT';
                    } else {
                        WeekDaysCount -= 1;
                        endDayStr = 'WEEK';
                    }

                    let { startDayPriceWithoutGST, startDayPriceWithGST, startDayPriceGSTAmount, TotalHours } = calculateStartDayPrice(startdayStr, details[i]);
                    priceDetails.priceWithoutGST += startDayPriceWithoutGST;
                    priceDetails.PriceWithGST += startDayPriceWithGST;
                    priceDetails.GSTAmount += startDayPriceGSTAmount;
                    priceDetails.TotalHours += TotalHours;

                    let { endDayPriceWithoutGST, endDayPriceWithGST, endDayPriceGSTAmount, endTotalHours } = calculateEndDayPrice(endDayStr, details[i]);
                    priceDetails.priceWithoutGST += endDayPriceWithoutGST;
                    priceDetails.PriceWithGST += endDayPriceWithGST;
                    priceDetails.GSTAmount += endDayPriceGSTAmount;
                    priceDetails.TotalHours += endTotalHours;


                    if (sunDaysCount && details[i].requiredOnSundays) {
                        let { sunDayPriceWithoutGST, sunDayPriceWithGST, sunDayGSTAmount, sunDayTotalHours } = calculateSunDayPrice(sunDaysCount);
                        priceDetails.priceWithoutGST += sunDayPriceWithoutGST;
                        priceDetails.PriceWithGST += sunDayPriceWithGST;
                        priceDetails.GSTAmount += sunDayGSTAmount;
                        priceDetails.TotalHours += sunDayTotalHours;
                    }

                    if (saturdaysCount && details[i].requiredOnSaturdays) {
                        let {satPriceWithOutGST,satGSTAmount,satPriceWithGST,satHours} = calculateSaturDayPrice(saturdaysCount);
                        priceDetails.priceWithoutGST += satPriceWithOutGST;
                        priceDetails.PriceWithGST += satPriceWithGST;
                        priceDetails.GSTAmount += satGSTAmount;
                        priceDetails.TotalHours += satHours;
                    }

                    if (WeekDaysCount) {
                        let { weekDaysPriceWithoutGST, weekDaysPriceWithGST, weekDaysGSTAmount, weekDayHours } = calcWeekDaysPrice(WeekDaysCount);
                        priceDetails.priceWithoutGST += weekDaysPriceWithoutGST;
                        priceDetails.PriceWithGST += weekDaysPriceWithGST;
                        priceDetails.GSTAmount += weekDaysGSTAmount;
                        priceDetails.TotalHours += weekDayHours;

                    }
                    tempObj['requestedHours'] = priceDetails.TotalHours;
                    tempObj['totalCost'] = priceDetails.priceWithoutGST.toFixed(2);
                    tempObj['totalGST'] = priceDetails.GSTAmount.toFixed(2);
                    tempObj['grandTotal'] = priceDetails.PriceWithGST.toFixed(2);
                    tempObj['level_id'] = details[i].level_id;
                    tempObj['daysCount'] = totalDays;
                    tempObj['start_date'] = getYYYYMMDDFormatAircon(new Date(details[i].start_date));
                    tempObj['end_date'] = getYYYYMMDDFormatAircon(new Date(details[i].end_date));
                    tempObj['start_time'] = details[i].start_time;
                    tempObj['end_time'] = details[i].end_time;
                    tempObj['duration'] = `${moment(details[i].start_date).format("MMM Do")} ${details[i].start_time} - ${moment(details[i].end_date).format("MMM Do")} ${details[i].end_time}`;
                    dataArray.push(tempObj)
                }
            }

            let billingDetails = { requestedHours: 0, totalCost: 0, GSTCharges: 0, grandTotal: 0 }
            for (let loopFrom = 0; loopFrom < dataArray.length; loopFrom++) {
                billingDetails.requestedHours += parseFloat(dataArray[loopFrom]['requestedHours']);
                billingDetails.GSTCharges += parseFloat(dataArray[loopFrom]['totalGST']);
                billingDetails.totalCost += parseFloat(dataArray[loopFrom]['totalCost']);
                billingDetails.grandTotal += parseFloat(dataArray[loopFrom]['totalCost']) + parseFloat(dataArray[loopFrom]['totalGST']);
            }
            dataToStore['air_con_details'] = dataArray;
            dataToStore['company_id'] = tenantData?.companyId;
            dataToStore['service_request_types_id'] = serviceType;
            dataToStore["terms_condition"] = terms_condition;
            dataToStore["billingDetails"] = billingDetails;
            dataToStore['durationList'] = details
            // console.log('Billing Details',billingDetails);
            returnInputData(dataToStore);
            console.log('before airconpreview ',details)
          
            sessionStorage.setItem('AirconPreview',JSON.stringify(detailsData))
            // if (billingDetails.requestedHours > 4) {
            //     setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
               
            // } 
            // else {
            //     setSnackbarStatus({ message: 'Min 4 hours required', status: true, color: 'red', icon: 'close' })
            //     setTimeout(() => {
            //         setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
            //     }, 5000);
            // }

        }
    };

    const getDaysCount = (startDate, endDate) => {
        const date1 = new Date(startDate);
        const date2 = new Date(endDate);
        let saturdaysCount = 0, sunDaysCount = 0, WeekDaysCount = 0, totalDays = 0;
        if (date1.getDate() === date2.getDate()) {
            if (date1.getDay() === 6) {
                saturdaysCount += 1;
                totalDays += 1;
            } else if (date1.getDay() === 0) {
                sunDaysCount += 1;
                totalDays += 1;
            } else {
                WeekDaysCount += 1;
                totalDays += 1;
            }
        } else {         
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            for (let i = 0; i <= diffDays; i++) {
                let newStartDate = new Date(startDate);
                let addedDay = new Date(newStartDate.setDate(date1.getDate() + i))
                if (addedDay.getDay() === 6) {
                    saturdaysCount += 1;
                    totalDays += 1;
                } else if (addedDay.getDay() === 0) {
                    sunDaysCount += 1;
                    totalDays += 1;
                } else {
                    WeekDaysCount += 1;
                    totalDays += 1;
                }

            }
        }

        return { saturdaysCount, sunDaysCount, WeekDaysCount, totalDays }
    }

    const handleAirConForms = () => {
        if (details.length < 5) {
            let errorStatus = validateAirConExtension();
            if (errorStatus) {
                if (details[details.length - 1].requiredOnPublicHolidays || details[details.length - 1].requiredOnSundays || details[details.length - 1].requiredOnSaturdays) {
                    handleAddNewRange();
                } else {
                    handleAddNewRange();
                }

            }

        }

    }

    const handleAddNewRange = () => {
        setModelOpen(false)
        let arr = [], length = details.length + 1;
        details.forEach((element) => {
            arr.push({ ...element, disabledStaus: true })
        })
        arr.push({
            id: length,
            // start_date: new Date(details[details.length - 1].end_date).setDate(new Date(details[details.length - 1].end_date).getDate() + 1),
            // end_date: new Date(details[details.length - 1].end_date).setDate(new Date(details[details.length - 1].end_date).getDate() + 8),
            start_date:null,
            end_date:null,
            level_id: details[0]?.level_id,
            start_time: '',
            end_time: '',
            disabledStaus: false,
            min_date: new Date(details[details.length - 1].end_date),
            max_date: new Date(details[details.length - 1].end_date).setDate(new Date(details[details.length - 1].end_date).getDate() + 8),
            requiredOnSaturdays: false,
            requiredOnSundays: false,
            requiredOnPublicHolidays: false
        });
        console.log('details four ',arr)
        setDetails([...arr])
    }

    const handleWeekEndSelection = (e, reqIndex) => {
        let { name } = e.target;
        let newFormValues = [];
        details.forEach((element, index) => {
            if (index === reqIndex) {
                let newobj = { ...element, [name]: !element[`${name}`] }
                newFormValues.push(newobj)
            } else {
                newFormValues.push(element)
            }

        });
        console.log('details five ',newFormValues)
        setDetails([...newFormValues])

    }

    const removeAirconForm = (toRemoveForm) => {
        let newForms = details.filter((form, index) => {
            if (index !== toRemoveForm) {
                return form;
            }
        });
        let newFormValues = [];
        newForms.forEach((element, index) => {
            if (index === newForms.length - 1) {
                let newobj = { ...element, ['disabledStaus']: false }
                newFormValues.push(newobj)
            } else {
                newFormValues.push(element)
            }

        });
        setDetails([...newFormValues])
    }
    const handleStartEndDate = (e, reqIndex) => {
        let { name, value } = e.target;
        let {calendarArrayList} = publicHolidays;
        const isHoliday = calendarArrayList?.filter(item => item.calendars_date == moment(value).format('YYYY-MM-DD'))
        console.log("valuevalue ",moment(value).format('YYYY-MM-DD'), isHoliday.length > 0)
        setIndexValue(reqIndex)
        let newHours;
        if (value.getDay() === 6) {
            newHours = saturDayHours;
        } else if (value.getDay() === 0 || isHoliday.length > 0) {
            newHours = sundayHours;
        } else {
            newHours = weekDayHours;
        }
        const currentHour = new Date().getHours()
        let difference = value.getTime() -  new Date().getTime()
        let numberOfDays = difference/(1000 * 60 * 60 * 24);  
        if(numberOfDays<1 && name == 'start_date') {
            let zeroTimeFormat = currentHour <=9 ? `0${currentHour}:00` : `${currentHour}:00`
            const filteredTime = newHours.find((item)=> item > zeroTimeFormat)
            if(filteredTime) {
                const indexValue = newHours.findIndex((item)=> item == filteredTime)
                const newData = newHours.slice(indexValue)
                newHours = newData
                setStartHours([...newHours])
            }
        } else if(numberOfDays>=1 && name == 'start_date') {
            setStartHours([...newHours])
        }
        name === 'end_date' &&  setEndHours([...newHours]) ;
        handleDetails(e, reqIndex);
        // calculateNumberOfDays(reqIndex,value)
    }
console.log("publicHolidays ",publicHolidays)
    const calculateNumberOfDays = (reqIndex,value)=>{
        let difference = details[reqIndex]?.end_date?.getTime() -  details[reqIndex]?.start_date?.getTime()
        let numberOfDays = difference/(1000 * 60 * 60 * 24);  

        if(numberOfDays>=0){
            let arr = []
            for(let i=0;i<numberOfDays+1;i++){
                let date = new Date(details[reqIndex]?.start_date)
                let startDate = new Date(details[reqIndex]?.start_date)
                startDate.setDate(date.getDate()+i)
                arr.push(startDate.getDay())
            }
      
            let startDate = new Date(details[reqIndex]?.start_date)
            let endDate = new Date(details[reqIndex]?.end_date)
            let {calendarArrayList} = publicHolidays;
            for(let i=0;i<calendarArrayList.length;i++){
                    let checkdate = calendarArrayList[i].calendars_date;
                    let date = checkdate.split("-");
                    console.log("zdate ",date)
                    let dateFormat = new Date(parseInt(date[2]),parseInt(date[1]-1),parseInt(date[0]))
                    if(dateFormat <= endDate && dateFormat>=startDate){
                       arr.push(7)
                    }
             }
            
            if(arr.includes(0) &&  arr.includes(6)){
                let newFormValues = []
                    details.forEach((element, index) => {
                        if (index === reqIndex) {
                            let newobj = { ...element, requiredOnSaturdays: true,requiredOnSundays:true }
                            newFormValues.push(newobj)
                        } else {
                            newFormValues.push(element)
                        }
            
                    });
                    console.log('details seven ',newFormValues)
                    setDetails([...newFormValues])
            }else if(arr.includes(6)){
                let newFormValues = []
                    details.forEach((element, index) => {
                        if (index === reqIndex) {
                            let newobj = { ...element, requiredOnSaturdays: true,requiredOnSundays:false }
                            newFormValues.push(newobj)
                        } else {
                            newFormValues.push(element)
                        }
            
                    });
                    console.log('details eight ',newFormValues)
                    setDetails([...newFormValues])
            }else if(arr.includes(0) || arr.includes(7)){
                let newFormValues = []
                    details.forEach((element, index) => {
                        if (index === reqIndex) {
                            let newobj = { ...element, requiredOnSaturdays: false,requiredOnSundays:true }
                            newFormValues.push(newobj)
                        } else {
                            newFormValues.push(element)
                        }
            
                    });
                    console.log('details nine ',newFormValues)
                    setDetails([...newFormValues])
            }else if(!arr.includes(0) && !arr.includes(6)){
                let newFormValues = []
                    details.forEach((element, index) => {
                        if (index === reqIndex) {
                            let newobj = { ...element, requiredOnSaturdays: false,requiredOnSundays:false }
                            newFormValues.push(newobj)
                        } else {
                            newFormValues.push(element)
                        }
            
                    });
                    console.log('details ten ',newFormValues)
                    setDetails([...newFormValues])
            }
        }
    }
   
    const renderAirConForms = () => {
        let e;
        return details.map((formItem, index) => (
            <Grid container sx={{ mt: 2 }} rowSpacing={1} columnSpacing={3} key={index}>
                {/* {requestType=="general" &&  */}
                <Grid item xs={12}>
                    <FormControlLabel
                        label={<Typography sx={{ color: '#0F2C6F', fontSize: '14px', }}>A/C required on Saturdays</Typography>}
                        control={<Checkbox disabled={formItem.disabledStaus} icon={<CheckboxIcon />} name='requiredOnSaturdays' checked={formItem.requiredOnSaturdays} />}
                        onClick={(e) => handleWeekEndSelection(e, index)} />
                    <FormControlLabel
                        label={<Typography sx={{ color: '#0F2C6F', fontSize: '14px', }}>A/C required on Sundays / Public Holidays</Typography>}
                        control={<Checkbox disabled={formItem.disabledStaus} icon={<CheckboxIcon />} name='requiredOnSundays' checked={formItem.requiredOnSundays}
                            onClick={(e) => handleWeekEndSelection(e, index)} />} />
                  
                </Grid>
                {/* } */}
                <Grid item xs={2} >
                    <StyledTypography >Floor</StyledTypography>
                    <Select
                        disabled={index!=0 ? true : formItem.disabledStaus}
                        sx={{ color: "#0F2C6F" }}
                        name={`level_id`}
                        value={details[0].level_id}
                        fullWidth
                        onChange={(e) => handleDetails(e, index)}
                    >
                        {levels?.map((value, index) => (
                            <MenuItem sx={{color:'#0F2C6F'}} value={value.level_id} key={index}>{value.level_name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={2.5} >
                    <StyledTypography >Start Date</StyledTypography>
                    <DatePicker
                        inputFormat="dd/MM/yyyy"
                        disabled={formItem.disabledStaus}
                        components={{
                            OpenPickerIcon: DatePickerIcon
                        }}
                        disableHighlightToday
                        disableMaskedInput
                        disablePast
                        minDate={new Date(formItem.min_date)}
                        //  maxDate={new Date(formItem.max_date)}
                        name={`start_date`}
                        onChange={(newValue) => {
                            handleStartEndDate(e = { target: { value: newValue, name: 'start_date' } }, index);
                        }}
                        value={formItem.start_date}
                        renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                    />
                </Grid>
                <Grid item xs={2.5} >
                    <StyledTypography >End Date</StyledTypography>
                    <DesktopDatePicker
                        disabled={formItem.disabledStaus}
                        components={{
                            OpenPickerIcon: DatePickerIcon
                        }}
                        disablePast
                        name={`end_date`}
                        inputFormat="dd/MM/yyyy"
                        minDate={new Date(formItem.start_date) || new Date()}
                        onChange={(newValue) => {
                            handleStartEndDate(e = { target: { value: newValue, name: 'end_date' } }, index);
                        }}
                        // maxDate={new Date(formItem.max_date)}
                        value={formItem.end_date}
                        renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                    />
                </Grid>

                <Grid item xs={2} >
                    <StyledTypography >Start Time</StyledTypography>
                    <Select disabled={formItem.disabledStaus} sx={{ color: "#0F2C6F" }} name='start_time' MenuProps={MenuProps} fullWidth value={formItem.start_time} onChange={(e) => {
                        handleDetails(e, index);
                    }}>
                        {(startHours).map((value, index) => (
                            <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>
                        ))}
                    </Select>   
                </Grid>
                <Grid item xs={2}>
                    <StyledTypography >End Time</StyledTypography>
                    <Select disabled={formItem.disabledStaus} sx={{ color: "#0F2C6F" }} MenuProps={MenuProps} name='end_time' fullWidth value={formItem.end_time} onChange={(e) => {
                        handleDetails(e, index);
                    }}>
                        {(endHours).map((value, index) => (
                            <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={0.4}>
                    <StyledTypography >&nbsp;</StyledTypography>
                    {index === 0 ? <RoundedPlusIcon style={{ marginTop: '20px', cursor: 'pointer' }} onClick={() => handleAirConForms()} /> : ''}
                    {index > 0 ?
                        <RoundedMinusIcon
                            style={{ marginTop: '20px', cursor: formItem.disabledStaus ? 'no-drop' : 'pointer' }}
                            onClick={formItem.disabledStaus ? null : () => removeAirconForm(index)} />
                        : ''}
                </Grid>
            </Grid>
        ))
    }

    return (
        <>
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />

            <Box sx={{ px: 3, mt: 3 }}>
                <Box >
                    <Typography sx={{ color: '#0F2C6F', fontSize: '18px', fontWeight: 550 }}>Request Details </Typography>
                    <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: 2, mb: 2 }}>Normal Air Con Operation Hours:  Monday to Friday : 08.00 - 18.00  • Saturday : 08.00 - 13.00</Typography>
                    <Typography sx={{ color: '#6F80A9', fontSize: '14px' }}>*1 day advance notices must be given. <span style={{fontWeight:"bold"}}>For urgent requests, please contact (+65 6222 5217) Fire Command Centre (FCC).</span> </Typography> 
                    <>
                        {/* <Select defaultValue="general" value={requestType} onChange={(e)=>setRequestType(e.target.value)}>
                            <MenuItem value="general">General Request</MenuItem>
                            <MenuItem value="publicHolidays"> Public Holidays</MenuItem>
                        </Select> */}
                        {renderAirConForms()}
                        < Grid container sx={{ mt: 1 }} columnSpacing={3}  >
                            <Grid itex xs={2}>
                                <Typography sx={{ color: "#f44336", pl: 3 }} fullWidth>{inputErrors.level_id}</Typography>
                            </Grid>
                            <Grid itex xs={2.5}>
                                <Typography sx={{ color: "#f44336", pl: 3 }} fullWidth>{inputErrors.start_date}</Typography>
                            </Grid>
                            <Grid itex xs={2.5}>
                                <Typography sx={{ color: "#f44336", pl: 3 }} fullWidth>{inputErrors.end_date}</Typography>
                            </Grid>
                            <Grid itex xs={2}>
                                <Typography sx={{ color: "#f44336", pl: 3 }} fullWidth>{inputErrors.start_time}</Typography>
                            </Grid>
                            <Grid itex xs={2}>      
                                <Typography sx={{ color: "#f44336", pl: 3 }} fullWidth>{inputErrors.end_time}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Typography sx={{ color: "#f44336", }} fullWidth>{inputErrors?.invalid_time}</Typography>
                            </Grid>
                        </Grid >
                    </>
                </Box>
                <Typography sx={{ color: '#0F2C6F', fontSize: '18px', fontWeight: 550, mt: 7 }}>Chargeable Rates</Typography>
                {/* <Typography sx={{ color: '#6F80A9', fontSize: '14px', mt: '5px' }}>Short description of chargeable rates for this work request</Typography> */}
                <Box>
                    <Grid container sx={{ border: '1px solid #DBDFE9', borderRadius: "6px", padding: '16px 24px', mt: 3, background: '#F3F4F8' }}>
                        <Grid item xs={12} >
                            {/* <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>Air con extension request to be submitted at least 1 day ahead of the required commencement date. </Typography> */}
                            <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F' }}>Request must be made at least 24 hours in advance to requested date must be provided: </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: '14px', color: '#0F2C6F', mt: 2 }}>Air con extension charges: ${publicHolidays?.cost_per_hour}/ hour<span style={{ fontSize: '13px', color: '#0F2C6F' }}><br />  *not including {publicHolidays?.gst}%GST</span></Typography>
                        </Grid>
                    </Grid>
                </Box>



                <Box sx={{ mt: 3 }}>
                    <Typography sx={{ fontSize: '18px', color: '#0F2C6F', fontWeight: 550 }}>{terms_condition && terms_condition[0].title}*</Typography>
                    <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{terms_condition && terms_condition[0].description}</Typography>
                    {/* {terms_condition[1].rules.map((value,index)=>(
                             <Typography sx={{ color: "#6F80A9", fontSize: '13px', my: 1 }}>{`${index+1} . ${value[index+1]}`}</Typography>
                        ))} */}
                    <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{terms_condition && terms_condition[1].rules[0]["ADVANCE NOTICE"]}</Typography>
                    <Typography sx={{ color: "#6F80A9", fontSize: '12px' }}>{terms_condition && terms_condition[1].rules[1]["Minimum Requirement"]}</Typography>
                    <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{terms_condition && terms_condition[1].rules[2]["URGENT/ ADHOC REQUEST"]}</Typography>
                    {/* <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{terms_condition && terms_condition[1].rules[4]["Normal aircon operation hours"]}</Typography> */}
                    <Box sx={{ display: "flex", ml: -1, alignItems: "center", mt: 1 }}>
                        <Checkbox icon={<CheckboxIcon />} checked={airconCheckBox.checkbox1} onClick={() => setAirconCheckBox({ ...airconCheckBox, [`checkbox1`]: !airconCheckBox.checkbox1 })} />
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px",  }}>I have read and agree to comply with the above terms & conditions and chargeable rates.  </Typography>
                    </Box>
                    {/* <Box sx={{ display: "flex", ml: -1 }}>
                        <Checkbox icon={<CheckboxIcon />} checked={airconCheckBox.checkbox2} onClick={() => setAirconCheckBox({ ...airconCheckBox, [`checkbox2`]: !airconCheckBox.checkbox2 })} />
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 1.2 }}>I have read and agreed with the chargeable rates.</Typography>
                    </Box> */}
                </Box>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, backgroundColor: 'rgba(143, 146, 161,0.05)', py: 5 }}>
                <Box>
                    <RefreshIcon style={{ marginTop: '5px' }} />
                    <Typography sx={{ color: ' #9AA6C3', fontSize: '14px', display: 'inline', ml: 2 }}>Last Updated at {lastUpdatedTime}</Typography>
                </Box>
                <Box sx={{ p: 5, pt: 0,pr:0 }}>
                    <Button sx={{
                        color: '#0F2C6F',
                        height: '50px',
                        width:100,
                        borderRadius: "8px",
                        backgroundColor: '#FFFFFF',
                        textTransform: 'capitalize',
                        border: "1px solid #AAC3FF",
                        '&:hover': { backgroundColor: '#FFFFFF' }
                    }}
                        varian="outlined" onClick={() => setServiceType(0)}>Delete</Button>
                    <Button variant='contained'
                        disabled={!airconCheckBox.checkbox1 }
                        onClick={() => handleContinue()}
                        sx={{
                            backgroundColor: '#123FA9',width:100, color: '#FFFFFF', ml: 3, borderRadius: "8px", height: "50px",
                            textTransform: 'capitalize', '&:hover': { backgroundColor: '#123FA9' }
                        }}>
                        Continue</Button>
                </Box>
            </Box>
            {/* <Dialog
                open={modelOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Aircon Request
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The Selected Range Containes Saturday and Sundays<br />
                        If A/c Not required on Saturday's and Sundays Click Continue, Otherwise Click  Cancel and Select required day!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleModel()} variant='contained'>Cancel</Button>
                    <Button onClick={() => handleAddNewRange()} variant='outlined'>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog> */}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        levels: state.CommonReducer.tenantOccupiedLeves,
        publicHolidays:state.Service_Req_Reducer.publicHolidays,
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLevels: () => (dispatch(commonAction.getTenantAccessLevel())),
        generateBillValue: () => (dispatch(ServiceAction.generateBillValue())),
        createAirConRequest: () => (dispatch(ServiceAction.createAirConRequest())),
        getPublicHoliday:()=>(dispatch(ServiceAction.getPublicHoliday())),
        serviceRequestByID: (data) => (dispatch(ServiceAction.serviceRequestDetailsById(data))),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AirConExtensionRequest)