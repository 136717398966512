import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import *  as ServiceRequestAction from '../../redux/actions/service_request/index'
import {  getServiceCharge, getServiceChargeNullAction } from "../../redux/actions/service_request/index";
import { Box, Button, Grid, Menu, MenuItem, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Typography, tableCellClasses, CircularProgress,Dialog,DialogTitle, DialogContent, DialogActions,IconButton } from "@mui/material";
import styled from "@emotion/styled";
import { ReactComponent as MoreIcons } from '../../Assets/icons/more-alt.svg';
import {ReactComponent as AddIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg"
import { useNavigate } from "react-router-dom";
import ToastAlert from "../common/Alert";
import { ReactComponent as Delete } from "../../Assets/icons/Delete.svg";
import {ReactComponent as PencilIconSlanted} from "../../Assets/icons/PencilIconSlanted.svg";
import moment from "moment";
import Close from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

const StyledTableRow = styled(TableRow)({
    "&:nth-of-type(odd)":{
        backgroundColor:"#FAFBFF"
    },
    "&:nth-of-type(even)":{
        backgroundColor:"#FFFFFF"
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    color: '#0F2C6F !important',
    padding: '5px !important',
    textAlign: "left",
    height: '50px',
    [`&.${tableCellClasses.head}`]: {
        fontSize: '13px',
        fontWeight: 550,
        backgroundColor:'#FFFFFF'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        fontWeight: 500,
    },
}));

const buttonUnSelectedStyle = {
    border: "1px solid #AAC3FF",
    borderRadius: "6px",
    color: "#0F2C6F",
    fontSize: "14px",
    fontWeight: '600',
    textTransform: "capitalize",
    "&:hover": {
        backgroundColor: "#FFFFFF"
    },
    marginRight: "5px"
}

const buttonSelectedStyle = {
    backgroundColor: "#123FA9",
    borderRadius: "6px",
    color: "#FFFFFF",
    fontSize: "14px",
    textTransform: "capitalize",
    marginRight: '10px',
    "&:hover": {
        backgroundColor: "#123FA9"
    }
}

const serviceRatesData = [{id:1, title:'Air-Con Extension Rates', updated_by:'Robin Lau', last_updated: '24/06/22 – 14:03'},{id:2, title:'Manpower Charges', updated_by:'Cristie Li ', last_updated: '24/06/22 – 14:03'}]
const LightBulbProducts = ({getServiceAccesscoriesData,serviceAccesscoriesList,deleteLightProduct,addLightBulbProductStatus,setServiceRequestStateNull,productList,  getServiceChargeAction, getServiceChargeListData, getServiceChargeNull})=>{
    const navigate = useNavigate()
    const [anchorEl,setAnchorEl] = useState(null)
    const [open,setOpen] = useState(false)
    const [id,setId] = useState('')
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const [currentPage,setCurrentPage] = useState(1)
    const [tab, setTab] = useState(localStorage.getItem('inventory_deposit_tab') ? localStorage.getItem('inventory_deposit_tab') == 'service' ? {serviceRates:true, productInventory:false} :{serviceRates:false, productInventory:true} : {serviceRates:true, productInventory:false})
    const [serviceChargeList, setServiceChargeList] = useState({})
    const [openModal, setOpenModal] = useState(false);
    const [selectedProductName, setSelectedProductName] = useState("")
    const [meterialCode, setMeterialCode] = useState("")

    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(()=>{
        getServiceAccesscoriesData({
            limit:10,
            offset:currentPage-1
        })
    },[addLightBulbProductStatus,currentPage])

    useEffect(() => {
        getServiceChargeAction()
    },[])
    console.log('getServiceChargeListData ',getServiceChargeListData)
    console.log('serviceChargeList ',serviceChargeList)
    useEffect(()=>{
        if(getServiceChargeListData?.response?.response_status==1){
            setServiceChargeList(getServiceChargeListData?.response?.dataArray)
            getServiceChargeNull()
           
        }else if(addLightBulbProductStatus?.response_status==0){
            setServiceChargeList(getServiceChargeListData?.response?.dataArray)
            getServiceChargeNull()
        }
    },[getServiceChargeListData])

    useEffect(()=>{
        if(addLightBulbProductStatus?.response_status==1){
            setToastStatus({color:'green',status:true,message:addLightBulbProductStatus.message,icon:'tick'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
                setOpen(false)
            },3000)
        }else if(addLightBulbProductStatus?.response_status==0){
            setToastStatus({color:'red',status:true,message:addLightBulbProductStatus.message,icon:'close'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
            },3000)
        }
    },[addLightBulbProductStatus])


    const handleMoreButton = (e,id)=>{
        setOpen(true)
        setAnchorEl(e.currentTarget)
        setId(id)
    }

    const handlePagination = (e,value)=>{
        setCurrentPage(value)
    }

    const tryToDelete = (id, product, metrial) => {
        console.log('within')
        setId(id)
        setSelectedProductName(product)
        setMeterialCode(metrial)
        setOpenModal(true)
    }
    const ModalAlert = ()=> {
        return(
            <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={openModal}
            >
              <DialogTitle sx={{ m: 0, p: 2, fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#0F2C6F' }} id="customized-dialog-title">
              Confirm Delete Product - {selectedProductName} 
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <DialogContent dividers>
                <Typography gutterBottom>
                    Are you sure you wish to delete this product
                </Typography>

                <Typography gutterBottom sx={{mb:2}}>
                    - {selectedProductName} (Material Code {meterialCode})
                </Typography>

                <Typography gutterBottom>
                    Once deleted,
                </Typography>

                <Typography gutterBottom>
                the product will not be available for selection in existing service request forms.
                </Typography>
              </DialogContent>
              <DialogActions sx={{display:'flex', justifyContent:'space-between'}}>
              <Button variant="outlined" onClick={handleClose} sx={{borderRadius:'6px', p:'8px 10px',}}>
                  <Typography sx={{color:'#0F2C6F', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter'}}>Cancel</Typography>
                </Button>
                <Button variant="contained" onClick={()=>{
                    setOpenModal(false)
                    deleteLightProduct({light_bulb_products_id: id})
            }} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#BD0251',"&:hover":{backgroundColor:'#BD0251'}}}>
                <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Delete Now</Typography>
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
        )
    }

    return(
        <>
        <ModalAlert/>
             <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon}  />  
             <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:"100%"}}>
            <Grid item xs={0.5} />

            <Grid item xs={11} sx={{mt:7}}>
            <Box sx={{display:'flex',justifyContent:'space-between'}}>
                <Box>
                <Typography sx={{color:'#0F2C6F',fontSize:'24px',mb:2,fontWeight:540}}>Manage Chargeable Rates & Product Inventory</Typography>
                    {/* <Typography sx={{color:'#0F2C6F',fontSize:'24px',mb:1,fontWeight:540}}>Inventory Deposit</Typography> */}
                    {/* <Typography sx={{color:'#0F2C6F',fontSize:'16px',mb:5,fontWeight:540}}>You may manage singland inventory products.</Typography> */}
                </Box>
                <Button onClick={()=>navigate('/addProduct/null')} variant="contained" sx={{display:tab.productInventory ? 'flex' : 'none', background: '#123FA9',"&:hover":{backgroundColor:"#123FA9"},textTransform:'capitalize',height:"40px",fontSize: { md: "14px", xs: "10px"} }} endIcon={<AddIcon />}>New Product</Button>
            </Box>
            <Box sx={{mb:2}}>
                <Button sx={tab.serviceRates ? buttonSelectedStyle : buttonUnSelectedStyle} onClick={() => {
                    setTab({
                        serviceRates: true,
                        productInventory: false
                    })
                    localStorage.setItem('inventory_deposit_tab','service')
                }}>Service Rates</Button>
                <Button sx={tab.productInventory ? buttonSelectedStyle : buttonUnSelectedStyle} onClick={() => {
                    setTab({
                        serviceRates: false,
                        productInventory: true
                    })
                    localStorage.setItem('inventory_deposit_tab','product')
                }}>Product Inventory</Button>
            </Box>
            <Box sx={{mb:6}}><Typography sx={{color:'#6F80A9', fontFamily:'Inter', fontWeight:'500', fontSize:'14px', lineHeight:'18px', letterSpacing:'-0.25px'}}>Manage service charges for existing digitilised service request forms. BMS is able to edit and change the rate for services below.</Typography></Box>
            {tab.serviceRates ? (
            <>
            <Box sx={{display:'flex', mb:3, alignItems:'center'}}>
                <Typography sx={{color:'#0F2C6F', fontFamily:'Inter', fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px'}}>Service Charges</Typography>
                {/* <Button  variant="contained"  onClick={()=>navigate('/addService/null')} sx={{ background: '#123FA9',"&:hover":{backgroundColor:"#123FA9"},textTransform:'capitalize',height:"40px",fontSize: { md: "14px", xs: "10px"} }} endIcon={<AddIcon />}>New Service Charge</Button> */}
            </Box>
            <Table >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Title</StyledTableCell>
                        <StyledTableCell>Updated By</StyledTableCell>
                        <StyledTableCell>Last Updated</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{position:'relative'}}>
                    {console.log('serviceChargeList ',serviceChargeList)}
                    {serviceChargeList?.length > 0 ? (serviceChargeList.map((value, index)=>(
                            <StyledTableRow key={index}>
                               <StyledTableCell>{value.title}</StyledTableCell>
                               <StyledTableCell>{value.updated_by}</StyledTableCell>
                               <StyledTableCell>{ moment.utc(value.updated_at, "DD-MM-YYYY HH:mm:ss").local().format("DD/MM/yy")} - { moment.utc(value.updated_at, "DD-MM-YYYY HH:mm:ss").local().format("HH:mm")}</StyledTableCell>
                               {/* <TableCell>
                                    <MoreIcons onClick={(e)=>handleMoreButton(e,value.id)}/>
                               </TableCell> */}
                                <StyledTableCell>
                                    <Box  onClick={()=>navigate(`/addService/${value.row_for}`)} sx={{maxWidth:'45px', p: "10px", border:'1px solid #AAC3FF', borderRadius: "6px", display: "grid", placeItems:'center', background: "#123FA9", cursor:'pointer'}}>
                                    <PencilIconSlanted style={{height: 20, width: 20}} />
                                    </Box>
                                </StyledTableCell>
                            </StyledTableRow>
                    ))):(
                        <Box sx={{mt:2,position:'absolute', top:'50%', left:'50%'}}>
                            <CircularProgress />
                        </Box>
                    )}
                 
                </TableBody>
            </Table></>):('')}
            {tab.productInventory ? (<Table >
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Product Name</StyledTableCell>
                        <StyledTableCell>Material Code</StyledTableCell>
                        <StyledTableCell>Price</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productList?.lightBulbProductsArrayCount> 0 && productList?.lightBulbProductsArrayList.map((value)=>(
                            <StyledTableRow>
                               <StyledTableCell>{value.light_bulb_products_name}</StyledTableCell>
                               <StyledTableCell>{value.material_code}</StyledTableCell>
                               <StyledTableCell>{value.price}</StyledTableCell>
                               {/* <TableCell>
                                    <MoreIcons onClick={(e)=>handleMoreButton(e,value.light_bulb_products_id)}/>
                               </TableCell> */}
                                  <StyledTableCell>
                                    <Box  onClick={()=>navigate(`/addProduct/${value.light_bulb_products_id}`)} sx={{maxWidth:'45px', p: "10px", border:'1px solid #AAC3FF', borderRadius: "6px", display: "grid", placeItems:'center', background: "#123FA9", cursor:'pointer'}}>
                                    <PencilIconSlanted style={{height: 20, width: 20}} />
                                    </Box>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <Box  onClick={()=> tryToDelete(value.light_bulb_products_id,value.light_bulb_products_name, value.material_code)}
                                    sx={{maxWidth:'45px', p: "10px", border:'1px solid #AAC3FF', borderRadius: "6px", display: "grid", placeItems:'center', background: "#fff", cursor:'pointer'}}>
                                    <Delete style={{height: 20, width: 20}} />
                                    </Box>
                                </StyledTableCell>
                           </StyledTableRow>
                    ))}
                 
                </TableBody>
            </Table>):('')}
            {tab.productInventory ?(productList?.lightBulbProductsArrayCount>0? 
                    <Pagination onChange={handlePagination} sx={{ display: 'flex',justifyContent: 'center', py: 4 }} count={Math.ceil(productList?.lightBulbProductsArrayCount/ 10)} page={currentPage} size="large" shape="rounded" /> : 
                    <Box sx={{display:"flex",justifyContent:"center",m:5}}>
                        <Typography sx={{textAlign:"center",fontSize:"20px",color:'#0F2C6F'}}>No data found</Typography>
                    </Box>):('')
                    }
            </Grid>

            <Grid item xs={0.5} />
        </Grid>
        <Menu open={open} anchorEl={anchorEl} onClose={()=>setOpen(false)}>
            <MenuItem onClick={()=>navigate(`/addProduct/${id}`)}>Edit</MenuItem>
            <MenuItem onClick={()=>deleteLightProduct({
                light_bulb_products_id:id
            })}>Delete</MenuItem>
        </Menu>
        </>
    )
}
 
const mapStateToProps = (state)=>{
    return{
        serviceAccesscoriesList:state.Service_Req_Reducer.serviceAccesscoriesList,
        addLightBulbProductStatus:state.Service_Req_Reducer.addLightBulbProductStatus,
        productList:state.Service_Req_Reducer.productList,
        getServiceChargeListData: state.Service_Req_Reducer.getServiceChargeList
    }

}

const mapDispacthToProps = (dispatch)=>{
    return{
        getServiceAccesscoriesData:(data)=>dispatch(ServiceRequestAction.getServiceAccesscoriesData(data)),
        deleteLightProduct:(data)=>dispatch(ServiceRequestAction.deleteLightProduct(data)),
        setServiceRequestStateNull:()=>dispatch(ServiceRequestAction.setServiceRequestStateNull()),
        getServiceChargeAction: (data) => {dispatch(getServiceCharge(data))},
        getServiceChargeNull: () => {dispatch(getServiceChargeNullAction())}
        
    }
}
export default connect(mapStateToProps,mapDispacthToProps)(LightBulbProducts)