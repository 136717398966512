import {
  FormControl,
  MenuItem,
  Grid,
  InputLabel,
  Select,
  OutlinedInput,
  Button,
  Box,
} from "@mui/material";
import { connect } from "react-redux";
import React, { useState } from "react";

const AddUnit = () => {
  const [unitid, setUnitid] = useState("");
  const [unitnumber, setUnitnumber] = useState();

  const handleUnitid = (e) => {
    return setUnitid(e.target.value);
  };

  function handleUnitnumber(e) {
    return setUnitnumber(e.target.value);
  }

  const unitSubmit = () => {
  
  };

  return (
    <>
      <Grid container sx={{ m: 3 }}>
        <Grid item xs={12} sm={3}>
          <FormControl>
            <InputLabel id="level-id">Level Id</InputLabel>
            <Select
              labelId="level-id"
              label="Level Id"
              value={unitid}
              onChange={(e) => handleUnitid(e)}
              sx={{ width: 300 }}
            >
              <MenuItem value="one">One</MenuItem>
              <MenuItem value="two">Two</MenuItem>
              <MenuItem value="three">Three</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} sx={{ m: { xs: 2, sm: 0 } }}>
          <OutlinedInput
            name="unitnumber"
            type="number"
            placeholder="Unit Number"
            sx={{ ml: 5 }}
            inputProps={{
              min: 0,
            }}
            onChange={(e) => handleUnitnumber(e)}
          ></OutlinedInput>
        </Grid>
      </Grid>

      <Box sx={{ ml: { xs: 15, sm: 40 } }}>
        <Button variant="contained" onClick={unitSubmit}>
          Submit
        </Button>
      </Box>
    </>
  );
};

export default AddUnit;

const mapStateToProps = (state) => {
  return {
    addUnit: state.SuperAdmin.addUnit,
  };
};
