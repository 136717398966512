import React from "react";
import { ReactComponent as Dashboard } from "../../src/Assets/icons/DashboardIcon.svg";
import { ReactComponent as SelectedDashboard } from "../../src/Assets/icons/SelectedDashboardIcon.svg";
import { ReactComponent as MemberIcon } from "../../src/Assets/icons/MemberIcon.svg";
import { ReactComponent as SelectedMemberIcon } from "../../src/Assets/icons/SelectedEmployeeIcon.svg";
import { ReactComponent as ServiceRequestIcon } from "../../src/Assets/icons/ServiceRequestIcon.svg";
import { ReactComponent as SelectedServiceRequestIcon } from "../../src/Assets/icons/SelectedRequestIcon.svg";
import { ReactComponent as FaultReportIcon } from "../../src/Assets/icons/FaultReportIcon.svg";
import { ReactComponent as SelectedFaultReportIcon } from "../../src/Assets/icons/SelectedFaultReportIcon.svg";
import { ReactComponent as AnnouncementIcon } from "../../src/Assets/icons/AnnouncementIcon.svg";
import { ReactComponent as SelectedAnnouncementIcon } from "../../src/Assets/icons/SelectedAnnouncementIcon.svg";
import { ReactComponent as Administration } from "../../src/Assets/icons/BmsAdministration.svg";
import { ReactComponent as SelectedAdministration } from "../../src/Assets/icons/SelectedBmsAdministration.svg";
import { ReactComponent as SeasonParkingIcon } from "../../src/Assets/icons/SeasonParkingIcon.svg";
import { ReactComponent as SelectedSeasonParkingIcon } from "../../src/Assets/icons/SelectedSeasonParkingIcon.svg";
import { ReactComponent as SocialImpactIcon } from "../../src/Assets/icons/SocialImpactIcon.svg";
import { ReactComponent as SelectedSocialImpactIcon } from "../../src/Assets/icons/SelectedSocialImpactIcon.svg";
import { ReactComponent as ManageVisitor } from "../../src/Assets/icons/Manage_Visitor_Icon.svg";
import { ReactComponent as SelectedManageVisitor } from "../../src/Assets/icons/SelectedManageVistitorIcon.svg";
import { ReactComponent as NewVisitor } from "../../src/Assets/icons/invite_side_nav.svg";
import { ReactComponent as InviteVisitorIcon } from "../../src/Assets/icons/InviteVisitorIcon.svg";
import { ReactComponent as SelectedInviteVisitorIcon } from "../../src/Assets/icons/SelectedInviteVistorIcon.svg";
import { ReactComponent as ReportIcon } from "../../src/Assets/icons/ReportIcon.svg";
import { ReactComponent as SelectedReportIcon } from "../../src/Assets/icons/SelectedReportIcon.svg";
import { ReactComponent as AmenityBooking } from "../../src/Assets/icons/AmenityBooking.svg";
import { ReactComponent as SelectedAmenityBooking } from "../../src/Assets/icons/SelectedAmenityBooking.svg";
import { ReactComponent as Sustainability } from "../../src/Assets/icons/Sustainability.svg";
import { ReactComponent as SelectedSustainability } from "../../src/Assets/icons/SelectedSustainability.svg";
import { ReactComponent as TestEmail } from "../../src/Assets/icons/EmailIcon.svg";

import moment from "moment";

export const sideNavSelectedIcons = {
  'Dashboard': {
    iconName: "Dashboard",
    icon:  <Dashboard />,
    selectedIcon: <SelectedDashboard/>
  },
  'BMS Administration': {
    iconName: "BMS Administration",
    icon:  <Administration />,
    selectedIcon: <SelectedAdministration/>
  },
  'Manage Tenants': {
    iconName: "Manage Tenants",
    icon:  <MemberIcon />,
    selectedIcon: <SelectedMemberIcon/>
  },
  'Manage Faults': {
    iconName: "Manage Faults",
    icon:  <FaultReportIcon />,
    selectedIcon: <SelectedFaultReportIcon/>
  },
  'Manage Services': {
    iconName: "Manage Services",
    icon:  <ServiceRequestIcon />,
    selectedIcon: <SelectedServiceRequestIcon/>
  },
  'Amenity Booking': {
    iconName: "Amenity Booking",
    icon:  <AmenityBooking />,
    selectedIcon: <SelectedAmenityBooking/>
  },
  'Announcements': {
    iconName: "Announcements",
    icon:  <AnnouncementIcon />,
    selectedIcon: <SelectedAnnouncementIcon/>
  },
  'Manage Employees': {
    iconName: "Manage Employees",
    icon:  <MemberIcon />,
    selectedIcon: <SelectedMemberIcon/>
  },
  'Fault Reporting': {
    iconName: "Fault Reporting",
    icon:  <FaultReportIcon />,
    selectedIcon: <SelectedFaultReportIcon/>
  },
  'Service Request': {
    iconName: "Service Request",
    icon:  <ServiceRequestIcon />,
    selectedIcon: <SelectedServiceRequestIcon/>
  },
  'Season Parking': {
    iconName: "Season Parking",
    icon:  <SeasonParkingIcon />,
    selectedIcon: <SelectedSeasonParkingIcon/>
  },
  'Social Impact': {
    iconName: "Social Impact",
    icon:  <SocialImpactIcon />,
    selectedIcon: <SelectedSocialImpactIcon/>
  },
  'Events & Highlights': {
    iconName: "Events & Highlights",
    icon:  <SocialImpactIcon />,
    selectedIcon: <SelectedSocialImpactIcon/>
  },
  'Register Visitor': {
    iconName: "Register Visitor",
    icon:  <InviteVisitorIcon />,
    selectedIcon: <SelectedInviteVisitorIcon/>
  },
  'Manage Visitors': {
    iconName: "Manage Visitors",
    icon:  <ManageVisitor />,
    selectedIcon: <SelectedManageVisitor/>
  },
  'Upcoming Visitors': {
    iconName: "Upcoming Visitors",
    icon:  <ManageVisitor />,
    selectedIcon: <SelectedManageVisitor/>
  },
  'Visitors': {
    iconName: "Visitors",
    icon:  <InviteVisitorIcon />,
    selectedIcon: <SelectedInviteVisitorIcon/>
  },
  'Reports': {
    iconName: "Reports",
    icon:  <ReportIcon />,
    selectedIcon: <SelectedReportIcon/>
  },
  'My Fault Report': {
    iconName: "Fault Reporting",
    icon:  <FaultReportIcon />,
    selectedIcon: <SelectedFaultReportIcon/>
  },
  'Manage Amenity': {
    iconName: "Manage Amenity",
    icon:  <AmenityBooking />,
    selectedIcon: <SelectedAmenityBooking/>
  },
  'Sustainability': {
    iconName: "Sustainability",
    icon:  <Sustainability />,
    selectedIcon: <SelectedSustainability/>
  },
  'Visitor Management': {
    iconName: "Visitor Management",
    icon:  <ManageVisitor />,
    selectedIcon: <SelectedManageVisitor/>
  },
  'Test Mail': {
    iconName: "Test Mail",
    icon:  <TestEmail />,
    selectedIcon: <TestEmail/>
  },
  'Audit Log': {
    iconName: "Audit Log",
    icon:  <ManageVisitor />,
    selectedIcon: <SelectedManageVisitor/>
  },
}


export const getIcons = (iconName) => {
  let prefferedIcon;
  if (iconName === "Dashboard") {
    prefferedIcon = <Dashboard />;
  } else if (iconName === "BMS Administration") {
    prefferedIcon = <Administration />;
  } else if (iconName === "Manage Tenants") {
    prefferedIcon = <MemberIcon />;
  } else if (iconName === "Manage Faults") {
    prefferedIcon = <FaultReportIcon />;
  } else if (iconName === "Manage Services") {
    prefferedIcon = <ServiceRequestIcon />;
  } else if (iconName === "Announcements") {
    prefferedIcon = <AnnouncementIcon />;
  } else if (iconName === "Manage Employees") {
    prefferedIcon = <MemberIcon />;
  } else if (iconName === "Fault Reporting") {
    prefferedIcon = <FaultReportIcon />;
  } else if (iconName === "Service Request") {
    prefferedIcon = <ServiceRequestIcon />;
  } else if (iconName === "Season Parking") {
    prefferedIcon = <SeasonParkingIcon />;
  } else if (iconName === "Social Impact") {
    prefferedIcon = <SocialImpactIcon />;
  }else if (iconName === "Events & Highlights") {
    prefferedIcon = <SocialImpactIcon />;
  }else if (iconName === "Register Visitor") {
    prefferedIcon = <InviteVisitorIcon />;
  }else if (iconName === "Manage Visitors") {
    prefferedIcon = <ManageVisitor />;
  }else if (iconName === "Upcoming Visitors") {
    prefferedIcon = <ManageVisitor />;
  }else if (iconName === "Visitor Management") {
    prefferedIcon = <ManageVisitor />;
  }else if (iconName === "Visitors") {
    prefferedIcon = <InviteVisitorIcon />;
  }else if(iconName=="Reports"){
    prefferedIcon = <ReportIcon />;
  }else if(iconName == "Amenity Booking"){
    prefferedIcon = <AmenityBooking />
  }else if(iconName == "My Fault Report"){
    prefferedIcon =  <FaultReportIcon />;
  }else if(iconName == "Sustainability"){
    prefferedIcon = <Sustainability />
  }else if(iconName == "Test Mail"){
    prefferedIcon =  <TestEmail />;
  }else if(iconName == "Audit Log"){
    prefferedIcon = <ManageVisitor />
  }
  return prefferedIcon;
};

export const getNavLinks = (toLink) => {
  let prefferedLink;
  let { user_id, role_id } = getLoggedUserData();

  if(toLink === null){
    return;
  }

  if (toLink === "Dashboard") {
    prefferedLink = "/dashboard";
  } else if (toLink === "BMS Administration") {
    prefferedLink = "/bms_administration";
  } else if (toLink === "Manage Tenants") {
    prefferedLink = "/onboard_new_tenant/0/0";
  } else if (toLink === "Manage Faults") {
    prefferedLink = "/manage_faults/0";
  } else if (toLink === "Manage Services") {
    prefferedLink = "/service_request_list/0";
  } else if (toLink === "Announcements") {
    prefferedLink = "/announcement";
  } else if (toLink === "Manage Employees") {
    prefferedLink = "/manage_employees/0";
  } else if (toLink === "Fault Reporting") {
    prefferedLink = `/fault_reporting/0`;
  } else if (toLink === "Service Request") {
    prefferedLink = `/service_request_list/0`;
  } else if (toLink === "Season Parking") {
    prefferedLink = "/season_parking";
  } else if (toLink === "Social Impact") {
    prefferedLink = "/social_impact";
  }else if (toLink === "Events & Highlights") {
    prefferedLink = "/events_promotions/0";
  }else if (toLink === "Register Visitor") {
    prefferedLink = "/register_visitor";
  }else if (toLink === "Manage Visitors") {
    prefferedLink = "/manage_visitors/0";
  }else if (toLink === "Upcoming Visitors") {
    prefferedLink = "/upcomming_visitors/0";
  }else if (toLink === "Visitor Management") {
    prefferedLink = "/visitor_management";
  }else if (toLink === "Visitors") {
    prefferedLink = "/tenant_visitors/0";
  }else if(toLink==="Reports"){
    prefferedLink = "/reports/false"
  } else if (toLink === "Amenity Booking") {
    prefferedLink = "/facility_booking";
  }else if (toLink === 'My Fault Report'){
    prefferedLink = '/my_faults/0'
  }else if(toLink === "Manage Amenity"){
    prefferedLink = "/manage_facility_booking";
  }else if (toLink === 'Sustainability'){
    prefferedLink = '/sustainability'
  }else if(toLink === "Test Mail"){
    prefferedLink = "/testEmail";
  }else if (toLink === 'Audit Log'){
    prefferedLink = '/auditreport'
  }
  return prefferedLink;
};

export const getTimeStamp = (reqTime) => {
  var time = reqTime?.replace(/-/g, "/")
  let MessageDate = new Date(time);
  let today = new Date();
  let timeStamp;
  if (
    MessageDate.getDate() === today.getDate() &&
    MessageDate.getMonth() === today.getMonth() &&
    MessageDate.getFullYear() === today.getFullYear()
  ) {
    timeStamp = moment.utc(time).local().fromNow();
  } else {
    // timeStamp = moment(new Date(`${time}`)).local().format("DD/MM/YYYY-HH:mm");
    timeStamp = moment.utc(time).local().format("DD/MM/YYYY - HH:mm");

  }
  return timeStamp;
};

export const getTimeStampWithoutFromNow = (reqTime) => {
  var time = reqTime?.replace(/-/g, "/")
  let timeStamp;
    timeStamp = moment.utc(time).local().format("HH:mm");
  return timeStamp;
};

export const getDDMMMYYYYFormat = (reqtime)=>{
  var time = reqtime?.replace(/-/g,"/")
  let MessageDate = new Date(time);
  let today = new Date();
  let timeStamp;
  if(
  MessageDate.getDate() === today.getDate() &&
  MessageDate.getMonth() === today.getMonth() &&
  MessageDate.getFullYear() === today.getFullYear()
  ){
    timeStamp= moment.utc(time).local().fromNow();
  }else{
    timeStamp = moment(new Date(`${time}`)).local().format("DD MMM YYYY");
  }
  return timeStamp;
}

export const getLastUpdatedTime = () => {
  const date = new Date();
  let hours = date.getHours(), minute = date.getMinutes();
  let changeFormat = hours > 12 ? hours - 12 : hours
  let newTimeStr = `${changeFormat <= 9 ? `0${changeFormat}` : changeFormat}:${minute <= 9 ? `0${minute}` : minute} ${hours >= 12 ? `PM` : 'AM'}`;
  // setTime({...time,timeStr:newTimeStr})
  return newTimeStr
}


export const getDateTimeFormat = (reqDate) => {
  let date = reqDate.getDate()<=9? `0${reqDate.getDate()}` : reqDate.getDate();
  let month = reqDate.getMonth() < 9 ? `0${reqDate.getMonth() + 1}` : reqDate.getMonth() + 1;
  let year = reqDate.getFullYear();
  let hours = reqDate.getHours()<=9 ? `0${reqDate.getHours()}` : reqDate.getHours();
  let minute = reqDate.getMinutes()== 0 ? '00' : reqDate.getMinutes()<=9 ? `0${reqDate.getMinutes()}` : reqDate.getMinutes();
  let dateStr = `${year}-${month}-${date} ${hours}:${minute}:00`
  return dateStr;
}

export const getUTCDateFormat = (reqDate)=>{
  let date = reqDate.getUTCDate()<10? `0${reqDate.getUTCDate()}` : reqDate.getUTCDate();
  let month = reqDate.getUTCMonth() < 9 ? `0${reqDate.getUTCMonth() + 1}` : reqDate.getUTCMonth() + 1;
  let year = reqDate.getUTCFullYear();
  let dateStr = `${year}-${month}-${date}`
  return dateStr;
}

export const getUTCDateTimeFormat = (reqDate)=>{
  let date = reqDate.getUTCDate()<=9? `0${reqDate.getUTCDate()}` : reqDate.getUTCDate();
  let month = reqDate.getUTCMonth() < 9 ? `0${reqDate.getUTCMonth() + 1}` : reqDate.getUTCMonth() + 1;
  let year = reqDate.getUTCFullYear();
  let hours = reqDate.getUTCHours()<10 ? `0${reqDate.getUTCHours()}` : reqDate.getUTCHours();
  let minute = reqDate.getUTCMinutes()<10 ? `0${reqDate.getUTCMinutes()}` : reqDate.getUTCMinutes();
  let dateStr = `${year}-${month}-${date} ${hours}:${minute}:00`
  return dateStr;
}

export const getYYYYMMDDFormat = (reqDate) => {
   if(reqDate instanceof Date){
    let date = reqDate.getDate()<= 9 ? `0${reqDate.getDate() }` : reqDate.getDate();
    let month = reqDate.getMonth() < 9 ? `0${reqDate.getMonth() + 1}` : reqDate.getMonth() + 1;
    let year = reqDate.getFullYear();
    let dateStr = `${year}-${month}-${date}`;
    return dateStr;
  }else{
    return reqDate
  }
  
}


export const getUTCTimeFormat = (dates,time)=>{
  let splitTime = time.split(":")
  let date = new Date(dates)
  date.setHours(splitTime[0],splitTime[1])
  
  let hours = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours()
  let minutes = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes()

  let timeFormat = `${hours}:${minutes}`
  return timeFormat
}

export const getLoggedUserData=()=>{
  let data=JSON.parse(localStorage.getItem('userData'));
  return data;
}

export const verifyMobileNo=(mobileNo)=>{
  let mobileRegex=/^\+65(6|8|9)\d{7}$/;

  return mobileNo.match(mobileRegex)
}

export const getYYYYMMDDFormatAircon = (reqDate) => {
  let date = reqDate.getDate()<= 9 ? `0${reqDate.getDate()}` : reqDate.getDate();
  let month = reqDate.getMonth() < 9 ? `0${reqDate.getMonth() + 1}` : reqDate.getMonth() + 1;
  let year = reqDate.getFullYear();
  let dateStr = `${year}-${month}-${date}`;
  return dateStr;
}

export const preventDatePickerKeyboard = (e)=>{
  e.preventDefault()  
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

export const SortIconStyle={marginLeft:'8px'}

// export const getUpdatedTimeBasedOnDate = (date) => {
  
//   console.log(new Date(date).getTime(),new Date().getTime())
//   console.log(new Date(date).toDateString() === new Date().toDateString())
//   console.log(new Date(date) - new Date());


//   const date1 = new Date(); // Replace with your date
// const date2 = new Date(date); // Replace with your date

// // Find the difference in milliseconds
// const differenceMs = Math.abs(date2 - date1);

// // Convert milliseconds to hours
// const differenceHours = differenceMs / (1000 * 60 * 60);

//  if(differenceHours > 23){
//     return 
//  }else{
//     return `${differenceHours}`
//  }

//   return date;
// }

export const checkBmsRoles = () => {
  let roleId = JSON.parse(localStorage.getItem('userData'))?.role_id;
  if(roleId === 2 || roleId === 3 || roleId === 14){
    return true;
  }
  return false;
}

export const checkFCCRole = () => {
  let roleId = JSON.parse(localStorage.getItem('userData'))?.role_id;
  if(roleId === 15){
    return true;
  }
  return false;
  
}

export const isToday = (date) => {
  const today = moment().startOf('day');
  return moment(date).isSame(today, 'day');
};

const generateRandomIds = () => {
  return Math.floor(Math.random() * 900) + 100;
};

// Function to generate a unique ID
export const generateUniqueRandomId = (existingList) => {
  let newId;
  do {
    newId = generateRandomIds();
  } while (existingList.some(item => item.id === newId));
  return newId;
};