import React from 'react'
import { Box, Typography } from '@mui/material';
import {ReactComponent as BackButton} from "../../Assets/icons/back.svg";
import {ReactComponent as RefreshIcon} from "../../Assets/icons/Refresh.svg";



const Index = () => {
  return (
    <>
    <Box sx={{p: 10}}>
        <Box sx={{display: "flex", flexDirection: "column", gap: "20px"}}>
             
        <BackButton />
        <Typography variant='h4' sx={{color: "#0F2C6F"}}>Manage Service Request Forms</Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: "10px", color: "#9AA6C3"}}>
            <RefreshIcon /> <Typography variant='p'>Last Updated at 10:35AM</Typography>
        </Box>
        </Box>
    </Box>
    </>
  )
}

export default Index