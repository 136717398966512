import React from "react";
import { ReactComponent as DashboardIcon } from "../../Assets/icons/DashboardIcon.svg";
import { ReactComponent as ServiceRequestIcon } from "../../Assets/icons/ServiceRequestIcon.svg";
import { ReactComponent as FaultReportIcon } from "../../Assets/icons/FaultReportIcon.svg";
import { ReactComponent as SettingsIcon } from "../../Assets/icons/SettingsIcon.svg";
import { ReactComponent as ManageRoleIcon } from "../../Assets/icons/ManageRoleIcon.svg";
import { ReactComponent as PlusIcon } from "../../Assets/icons/PlusIcon.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/icons/LogoutIcon.svg";

export const APPBAR_ACCOUNTS = [
  {
    id: 1,
    avatar: "AB",
    name: "Avery Baker",
    email: "Avery.b@icap.com",
    role: "Tenant Manager (ICAP)",
    avatarbg: "#0F2C6F",
  },
  {
    id: 2,
    avatar: "AB",
    name: "Avery Baker",
    email: "Avery.b@icap.com",
    role: "Tenant Manager (TP)",
    avatarbg: "#A48B2C",
  },
];

export const  APPBAR_OPTIONS = [
  // {
  //   id: 1,
  //   icon: <SettingsIcon />,
  //   option: "Settings",
  // },
  // {
  //   id: 2,
  //   icon: <ManageRoleIcon />,
  //   option: "Manage Role",
  // },
  // {
  //   id: 3,
  //   icon: <PlusIcon />,
  //   option: "Add Account",
  // },
  {
    id: 4,
    icon: <LogoutIcon />,
    option: "Logout",
  },
];
export const DASHBOARD_MANAGE_EMPLOYEES = [
  { id: 1, title: "Admin Account", count: 19 },
  { id: 2, title: "Active Account", count: 226 },
  { id: 3, title: "Inactive Account", count: 5 },
];
export const DASHBOARD_CHART_DATA2 = {
  options: {
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {

            },
            value: {

            }
          }
        }
      }
    }
  },
  series: [44, 55, 41, 17, 15],
  labels: ['A', 'B', 'C', 'D', 'E']
}
export const DASHBOARD_CHART_DATA = {
  series: [
    {
     name:"",
      // data: [5.1, 20.0, 30.0, 20.0, 20.0, 16.6, 8.2],
      data:[300,20,50]
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        dataLabels: {
          position: "", // top, center, bottom
        },
      },
    },
    colors:['#0F2C6F','#2764F4','#AAC3FF'],
    dataLabels: {
      enabled: false,
      formatter: function (val) {
        return val ;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },
    xaxis: {
      // categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      position: "bottom",
      labels:{
        show:false
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#B7C0D4",
            colorTo: "#B7C0D4",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val ;
        },
      },
    },
    title: {
      // text: "Monthly Inflation in Argentina, 2002",
      floating: true,
      offsetY: 0,
      align: "left",
      style: {
        color: "#444",
      },
    },
    tooltip:{
      x:{
        show:false
      },
      y:{
        show:false
      }
    }
  },
};

export const DASHBOARD_EXPENSES = [
  {
    id: 1,
    name: "Facility Booking",
    rate: 2105,
  },
  {
    id: 2,
    name: "Season Parking",
    rate: 6000,
  },
  {
    id: 3,
    name: "Service Requests",
    rate: 700,
  },
  {
    id: 4,
    name: "Miscellaneous Requests",
    rate: 300,
  },
];

export const DASHBOARD_FACILITY_BOOKING = [
  {
    id: 1,
    bookingRoom: "Main Seminar Room #04-03",
    requestedBy: "Joshua Sholz, 08 Feb 2023",
    bookingPeriod: "23 Feb 2023 09:00 AM - 25 Feb 2023 06:00PM",
    status: "Pending Admin Approval",
  },
  {
    id: 2,
    bookingRoom: "Malaysia Room #20-23",
    requestedBy: "Kitty Chan, 20 Feb 2023",
    bookingPeriod: "02 Mar 2023 11:00 AM - 01:00 PM",
    status: "Pending Admin Approval",
  },
];

export const DASHBOARD_FAULT_REPORTING = [
  {
    id: 1,
    fault: "Faulty plug terminal at reception area",
    status: "Pending Admin Input",
    reportedBy: "Casandra Lee, 08 Feb 2023",
  },
];

export const DASHBOARD_SERVICE_REQUEST = [
  {
    id: 1,
    service: "Light Bulb Replacement (08 Feb 2023)",
    status: "Pending Admin Approval",
  },
  {
    id: 2,
    service: "Security Permit (Updated 04 Feb 2023)",
    status: "Pending Admin Approval",
  },
  {
    id: 3,
    service: "Security Permit (Updated 04 Feb 2023)",
    status: "Pending Admin Approval",
  },
];

export const ACTIVE_EMPLOYEES = [
  {
    id: 1,
    employeeName: "Joshua Sholz",
    workEmail: "joshua@sholz@icap.com",
    mobileNumber: "9100 1001",
    jobTitle: "Broker",
    accessStartDate: "22 Jan 2023",
    defaultOfficeUnit: "#17-18",
    tenantRole: {
      FireWarden: "Yes",
      Adminsitrator: "Yes",
    },
    isChecked: false,
  },
  {
    id: 2,
    employeeName: "Muhamad Farhan",
    workEmail: "muhamad.farhan@tp.com",
    mobileNumber: "+62 801 1245 422",
    jobTitle: "Finance Executive",
    accessStartDate: "22 Jan 2023",
    defaultOfficeUnit: "#17-18",
    tenantRole: {
      FireWarden: "No",
      Adminsitrator: "Yes",
    },
    isChecked: false,
  },
  {
    id: 3,
    employeeName: "Grace Yeo",
    workEmail: "grace.yeo@tp.com",
    mobileNumber: "9001 0011",
    jobTitle: "Admin Executive",
    accessStartDate: "22 Jan 2023",
    defaultOfficeUnit: "#17-18",
    tenantRole: {
      FireWarden: "No",
      Adminsitrator: "Yes",
    },
    isChecked: false,
  },
];

export const SUPER_ADMIN_MENUS = [
  {
    id: "1",
    value: "Manage Country",
    label: "Manage Country",
    icon: <DashboardIcon />,
    location: "/dashboard",
  },
  {
    id: "2",
    value: "Add Building",
    label: "Add Building",
    icon: <DashboardIcon />,
    location: "/add_building",
  },
  {
    id: "3",
    value: "Add Level",
    label: "Add Level",
    icon: <DashboardIcon />,
    location: "/add_level",
  },
  {
    id: "4",
    value: "Add Unit",
    label: "Add Unit",
    icon: <DashboardIcon />,
    location: "/add_unit",
  },
  {
    id: "5",
    value: "Add Fault Type",
    label: "Add Fault Type",
    icon: <FaultReportIcon />,
    location: "/add_fault_type",
  },
  {
    id: "6",
    value: "Add Service Type",
    label: "Add Service Type",
    icon: <ServiceRequestIcon />,
    location: "/add_service_type",
  },
];

export const BMS_CO_TENANT_DRAFT_COLUMNS = [{
  id: 1,
  column_name: 'checkBox'
},
{
  id: 2,
  column_name: 'Created Date'
},
{
  id: 3,
  column_name: 'Company'
}
  ,
{
  id: 4,
  column_name: 'Created By'
}
  ,
{
  id: 5,
  column_name: 'Last Updated'
}]

export const BMS_CO_TENANT_INACTIVE_COLUMNS = [
  //   {
  //   id: 1,
  //   column_name: 'Ticket-ID'
  // },
  {
    id: 2,
    column_name: 'Company'
  },
  {
    id: 3,
    column_name: 'Size'
  }
  ,

  {
    id: 4,
    column_name: 'Submitted by(BMS)'
  },
  {
    id: 5,
    column_name: 'Offboarded Date'
  }
  ,
  {
    id: 6,
    column_name: 'Tenant Manager'
  }]

export const BMS_CO_TENANT_ONBOARDING_COLUMNS = [
  {
    id: 1,
    column_name: 'Ticket-ID'
  },
  {
    id: 2,
    column_name: 'Company'
  },
  {
    id: 3,
    column_name: 'Status'
  }
  ,
  {
    id: 4,
    column_name: 'Submitted On'
  }
  ,
  {
    id: 5,
    column_name: 'Onboard Date'
  },
  {
    id: 6,
    column_name: 'Tenant Manager'
  },
  {
    id: 7,
    column_name: 'Reception Unit'
  }]

export const BMS_CO_TENANT_ACTIVE_COLUMNS = [{
  id: 1,
  column_name: 'Company'
},
{
  id: 2,
  column_name: 'Size'
}, {
  id: 3,
  column_name: 'Submitted By(BMS)'
}, {
  id: 4,
  column_name: 'Onboarded Date'
}, {
  id: 5,
  column_name: 'Tenant Manager'
}, {
  id: 6,
  column_name: 'Main Unit'
}]

export const BMS_CO_TENANT_OFFBOARDING_COLUMNS = [
  {
    id: 1,
    column_name: 'Ticket-ID'
  },
  {
    id: 2,
    column_name: 'Company'
  },
  {
    id: 3,
    column_name: 'Status'
  }
  ,
  {
    id: 4,
    column_name: 'Submitted On'
  }
  ,
  {
    id: 5,
    column_name: 'Offboard Date'
  },
  {
    id: 6,
    column_name: 'Tenant Manager'
  },
  {
    id: 7,
    column_name: 'Reception Unit'
  }]

export const BMS_CO_TENANT_EMPLIST_ONBOARD_COLUMNS = [
  //   {
  //   id: 1,
  //   column_name: 'Ticket-ID'
  // },
  {
    id: 2,
    column_name: 'Company'
  },
  {
    id: 3,
    column_name: 'Employees'
  }
  ,
  {
    id: 4,
    column_name: 'Status'
  }
  ,
  {
    id: 5,
    column_name: 'Submitted By'
  },
  // {
  //   id: 6,
  //   column_name: 'Tenant Manager'
  // },
  {
    id: 7,
    column_name: 'Requested On'
  },
  {
    id: 8,
    column_name: 'Active Date'
  }]

export const BMS_CO_TENANT_EMPLIST_ACTIVE_COLUMNS = [
  //   {
  //   id: 1,
  //   column_name: 'Ticket-ID'
  // },

  {
    id: 2,
    column_name: 'Company'
  },
  {
    id: 1,
    column_name: 'Size'
  },
  {
    id: 3,
    column_name: 'Submitted By(BMS)'
  }
  ,
  {
    id: 4,
    column_name: 'Onboard Date'
  }
  ,
  {
    id: 5,
    column_name: 'Tenant Manager'
  },
  {
    id: 6,
    column_name: 'Main Unit'
  },
]
export const BMS_CO_TENANT_EMPLIST_OFFBOARD_COLUMNS = [
  {
    id: 2,
    column_name: 'Company'
  },
  {
    id: 3,
    column_name: 'Employee'
  },
  {
    id: 4,
    column_name: "Onboard Date"
  },
  {
    id: 5,
    column_name: 'Offboard Date'
  },
  {
    id: 6,
    column_name: 'Tenant Manager'
  },
]


export const TENANT_EMP_DRAFT_COLUMNS = [
  {
    id: 4,
    column_name: 'checkbox'
  }, {
    id: 1,
    column_name: 'Created Date'
  }, {
    id: 2,
    column_name: 'Type'
  }, {
    id: 3,
    column_name: 'Last Updated'
  },]
export const TENANT_EMP_ONBOARDING_COLUMNS = [{
  id: 1,
  column_name: 'Employees'
},
{
  id: 2,
  column_name: 'Status'
},
{
  id: 3,
  column_name: 'Requested on'
},
{
  id: 4,
  column_name: 'Onboard date'
},
{
  id: 5,
  column_name: 'Submitted By'
}, {
  id: 6,
  column_name: 'Job Role'
}, {
  id: 7,
  column_name: 'Last Updated'
}]

export const TENANT_EMP_ACTIVE_COLUMNS = [
  // {
  //   id: 8,
  //   column_name: 'checkbox'
  // },
  {
    id: 1,
    column_name: 'Employee Name'
  },
  {
    id: 2,
    column_name: 'Work Email'
  },
  {
    id: 3,
    column_name: 'Mobile Number'
  },
  {
    id: 4,
    column_name: 'Job Title'
  },
  {
    id: 5,
    column_name: 'Onboarded Date'
  }, {
    id: 6,
    column_name: 'Office Unit'
  }, {
    id: 7,
    column_name: 'Tenant Role'
  }
]

export const TENANT_EMP_OFFBOARD_COLUMNS = [
  {
    id: 1,
    column_name: 'Employee Name'
  },
  {
    id: 2,
    column_name: 'Requested on'
  },
  {
    id: 3,
    column_name: 'Offboard date'
  },
  {
    id: 4,
    column_name: 'Submitted by'
  },
  {
    id: 5,
    column_name: 'Job Role'
  },
  {
    id: 6,
    column_name: 'Last updated'
  },
]
export const TENANT_EMP_INACTIVE_COLUMNS = [
  {
    id: 1,
    column_name: 'Employee Name'
  },
  {
    id: 2,
    column_name: 'Work Email'
  },
  {
    id: 3,
    column_name: 'Mobile Number'
  },
  {
    id: 4,
    column_name: 'Job Title'
  },
  {
    id: 5,
    column_name: 'Offboarded Date'
  }, {
    id: 6,
    column_name: 'Office Unit'
  }, {
    id: 7,
    column_name: 'Tenant Role'
  }
]

export const BMS_STAFF_ACTIVE_COLUMNS = [
  // {
  //   id: 1,
  //   column_name: 'Check Box'
  // },
  {
    id: 2,
    column_name: 'Employees Name'
  },
  {
    id: 3,
    column_name: 'Work Email',
  },
  {
    id: 4,
    column_name: 'Mobile Number'
  },
  {
    id: 5,
    column_name: 'Job Title'
  },
  {
    id: 6,
    column_name: 'Onboarded Date'
  },
  {
    id: 7,
    column_name: 'Office Unit'
  },
  {
    id: 8,
    column_name: 'Tenant Role'
  },
  {
    id:9,
    column_name:" "
  }
]

export const BMS_STAFF_ONBOARDING_COLUMNS = [
  // {
  //   id: 1,
  //   column_name: 'Check Box'
  // },
  {
    id: 2,
    column_name: 'Employees'
  },
  {
    id: 3,
    column_name: 'Requested On'
  }, {
    id: 4,
    column_name: 'Active Date'
  }, {
    id: 5,
    column_name: 'Submitted by'
  }, {
    id: 6,
    column_name: 'Job Role'
  }, {
    id: 7,
    column_name: 'Last Updated '
  },
   {
    id:9,
    column_name:""
  }
]

export const BMS_STAFF_OFFBOARDING_COLUMNS = [
  {
      id:1,
      column_name:"Employees Name"
  },
  {
    id:2,
    column_name:"Requested on"
  },
  {
    id:3,
    column_name:"Inactive date"
  },
  {
    id:4,
    column_name:"Submitted by"
  },
  {
    id:5,
    column_name:"Job Role"
  },
  {
    id:6,
    column_name:"Last Updated"
  }
]

export const BMS_STAFF_INACTIVE_COLUMNS = [
  {
    id:1,
    column_name:"Employees Name"
  },
  {
    id:2,
    column_name:"Work Email"
  },
  {
    id:3,
    column_name:"Mobile Number"
  },
  {
    id:4,
    column_name:"Job Title"
  },
  {
    id:5,
    column_name:"Inactive Date"
  },
  {
    id:6,
    column_name:"Office Unit"
  },
  {
    id:7,
    column_name:"Tenant Role"
  }
]

export const BMS_STAFF_DRAFT_COLUMNS = [
  // {
  //   id: 1,
  //   column_name: 'Check Box'
  // },
  {
    id: 2,
    column_name: 'Created Date'
  },
  {
    id: 3,
    column_name: 'Type'
  },
  {
    id: 4,
    column_name: 'Last Updated'
  }
]

export const initialSystemAccess = [
  {
    id: 1,
    name: 'App Access',
    isSelected: false
  },
  {
    id: 2,
    name: 'Dashboard Access',
    isSelected: false
  }
]

export const initialAccessRights = [
  {
    id: 1,
    name: 'Service Request ',
    isSelected: false
  },
  {
    id: 2,
    name: 'Tenant Employee Onboarding ',
    isSelected: false
  },
  {
    id: 3,
    name: 'Tenant Company Onboarding ',
    isSelected: false
  },
  {
    id: 4,
    name: 'Staff Onboarding',
    isSelected: false
  },
  {
    id: 5,
    name: 'Announcement',
    isSelected: false
  },
  {
    id: 6,
    name: 'Information Publishing',
    isSelected: false
  },
  {
    id: 7,
    name: ' Fault Report ',
    isSelected: false
  }
]

export const initialSubmissionRights = [
  {
    id: 1,
    name: 'Service Request ',
    isSelected: false
  },
  {
    id: 2,
    name: 'Tenant Employee Onboarding ',
    isSelected: false
  },
  {
    id: 3,
    name: 'Tenant Company Onboarding ',
    isSelected: false
  },
  {
    id: 4,
    name: 'Staff Onboarding',
    isSelected: false
  },
  {
    id: 5,
    name: 'Announcement',
    isSelected: false
  },
  {
    id: 6,
    name: 'Information Publishing',
    isSelected: false
  },
  {
    id: 7,
    name: ' Fault Report ',
    isSelected: false
  }
]

export const importantContacts = [
  {
    id: 1,
    title: 'External Emergency Hotline',
    data: [{
      id: 'a',
      heading: 'Police',
      contactNo: '999',
      description: ''
    },
    {
      id: 'b',
      heading: 'Ambulance Hotline',
      contactNo: '995',
      description: 'For any safety concerns, please contact FCC immediately and a representative will assist you.'
    }]
  },
  {
    id: 2,
    title: 'SLT Internal Hotline',
    data: [{
      id: 'c',
      heading: 'SLT Security Hotline',
      contactNo: '6122 2331',
      description: 'For any safety concerns, please contact FCC immediately and a representative will assist you.'
    },
    {
      id: 'd',
      heading: 'SLT BMS Hotline',
      contactNo: '6122 2322',
      description: 'For any building related concerns, you may call this number for assistance.'
    }]
  }
]

export const AnnouncementActiveColumn = [  
  {id: 1,
  name: "",},
  {
    id: 2,
    name: 'Title'
  },
  {
    id: 3,
    name: 'Submitted On'
  },
  {
    id: 4,
    name: "Publish On"
  },
  {
    id: 5,
    name: "Read count"
  },
  {
    id: 6,
    name: 'Submitted by'
  },
  {
    id: 7,
    name: 'Job Role'
  },
  {
    id: 8,
    name: ""
  }
]

export const AnnouncementScheduledColumn = [  
  {id: 1,
  name: "",},
  {
    id: 2,
    name: 'Title'
  },
  {
    id: 3,
    name: 'Submitted On'
  },
  {
    id: 4,
    name: "Publish On"
  },
  {
    id: 5,
    name: 'Submitted by'
  },
  {
    id: 6,
    name: 'Job Role'
  },
  // {
  //   id: 7,
  //   name: ""
  // }
]

export const AnnouncementInactiveColumn = [  
  {
    id: 2,
    name: 'Title'
  },
  {
    id: 3,
    name: 'Submitted On'
  },
  {
    id: 4,
    name: "Publish On"
  },
  {
    id: 5,
    name: 'Submitted by'
  },
  {
    id: 6,
    name: 'Job Role'
  },
  // {
  //   id: 7,
  //   name: ""
  // }
]

export const notifiCationData = [{
  id: 1,
  title: 'New employee onboard request',
  message: 'Demo company request a new employee onboard request'
}, {
  id: 2,
  title: 'New service request',
  message: 'Demo company request a service'
},
{
  id: 3,
  title: 'New Announcement',
  message: 'You received a new announcement'
}]

export const Manage_Visitor_All_Tab = [
//   {
//   id: 1,
//   column_name: 'Checkbox'
// }, 
{
  id: 2,
  column_name: 'Registered'
},{
  id:3,
  column_name:'Visitor Name'
},{
  id:4,
  column_name:'Contact Info'
},{
  id:5,
  column_name:'Visiting Company'
},{
  id:6,
  column_name:'Period'
},{
  id:7,
  column_name:'Level'
},{
  id:8,
  column_name:'In'
},{
  id:9,
  column_name:'Out'
},{
  id:10,
  column_name:'Registered by'
}]


export const Manage_Visitor_Invited_Tab = [
//   {
//   id: 1,
//   column_name: 'Checkbox'
// }, 
{
  id: 2,
  column_name: 'Visit Date'
},{
  id:3,
  column_name:'Visitors'
},{
  id:4,
  column_name:'Period'
},{
  id:5,
  column_name:'Title'
},{
  id:6,
  column_name:'Requested by'
},{
  id:7,
  column_name:'Level'
}]

export const Upcomming_Visitor_All_Tab = [
//   {
//   id: 1,
//   column_name: 'Checkbox'
// }, 
{
  id: 2,
  column_name: 'Visit Date'
},{
  id:3,
  column_name:'Invites'
},{
  id:4,
  column_name:'Meeting Title'
},{
  id:5,
  column_name:'Invited by'
},{
  id:6,
  column_name:'Level'
},{
  id:7,
  column_name:'Type'
}]


export const Upcomming_Visitor_Invited_Tab = [
//   {
//   id: 1,
//   column_name: 'Checkbox'
// },
 {
  id: 2,
  column_name: 'Visit Date'
},{
  id:3,
  column_name:'Visitors'
},{
  id:4,
  column_name:'Title'
},{
  id:5,
  column_name:'Requested by'
},{
  id:6,
  column_name:'Level'
}]

export const Tenant_visitor_Active_Column = [
  // {
  //   id:1,
  //   column_name:"Checkbox"
  // },
  {
    id:2,
    column_name:"Meeting Title"
  },
  {
    id:3,
    column_name:"Guests"
  },
  // {
  //   id:4,
  //   column_name:"Status"
  // },
  {
    id:5,
    column_name:'From'
  },
  {
    id:6,
    column_name:'To'
  },
  {
    id:7,
    column_name:"Submitted by"
  }
]

export const Events_Promotions_Active_Columns = [
  // {
  //   id:1,
  //   column_name:"Checkbox"
  // },
  {
    id:2,
    column_name:"Published Date"
  },
  {
    id:3,
    column_name:"Title"
  },
  {
    id:4,
    column_name:"End Date"
  },
  {
    id:5,
    column_name:"Read count"
  },
  {
    id:6,
    column_name:"Submitted By"
  },
  {
    id:7,
    column_name:"Type"
  },
  {
    id:8,
    column_name:"Property"
  },
  {
    id:9,
    column_name:""
  }
]

export const Events_Promotions_Schedule_Columns = [
  // {
  //   id:1,
  //   column_name:"Checkbox"
  // },
  {
    id:2,
    column_name:"Submitted on"
  },
  {
    id:3,
    column_name:"Title"
  },
  {
    id:4,
    column_name:"Publish On"
  },
  {
    id:5,
    column_name:"Submitted By"
  },
  {
    id:6,
    colulmn_name:"Type"
  },
  {
    id:7,
    colulmn_name:"Property"
  },
  {
    id:8,
    column_name:""
  }
]

export const Events_Promotions_Archived_Columns=[
  // {
  //   id:1,
  //   column_name:"Checkbox"
  // },
  {
    id:2,
    column_name:"Published on"
  },
  {
    id:3,
    column_name:"Title"
  },
  {
    id:4,
    column_name:"Date End"
  },
  {
    id:5,
    column_name:"Submitted By"
  },
  {
    id:6,
    column_name:"Type"
  },
  {
    id:7,
    column_name:"Property"
  },
  {
    id:8,
    column_name:""
  }
]

export const Events_Promotions_Dratf_Columns = [
  // {
  //   id:1,
  //   column_name:"Checkbox"
  // },
  {
    id:2,
    column_name:"Submitted on"
  },
  {
    id:3,
    column_name:"Title"
  },
  {
    id:4,
    column_name:"Publish On"
  },
  {
    id:5,
    column_name:"Type"
  },
  {
    id:6,
    column_name:"Property"
  },
  {
    id:7,
    column_name:""
  }
]

export  const TenantDashboardData = [
    {
      id:1,
      name:"Service Request",
      ticket:"AC20011",
      title:"Change of UI",
      date:"25 June 2022",
      status:"New"
    },
    {
      id:2,
      name:"Fault Report ",
      ticket:"AC20011",
      title:"Change of UI",
      date:"25 June 2022",
      status:"Submitted"
    },
    {
      id:3,
      name:"Service Request",
      ticket:"AC20011",
      title:"Change of UI",
      date:"25 June 2022",
      status:"New"
    },
    {
      id:4,
      name:"Fault Report ",
      ticket:"AC20011",
      title:"Change of UI",
      date:"25 June 2022",
      status:"Submitted"
    },
    {
      id:5,
      name:"Service Request",
      ticket:"AC20011",
      title:"Change of UI",
      date:"25 June 2022",
      status:"New"
    },
    {
      id:6,
      name:"Fault Report ",
      ticket:"AC20011",
      title:"Change of UI",
      date:"25 June 2022",
      status:"Submitted"
    }
]

export const Reports_Faults_ColumnName = [
  {
    id:1,
   name:'Ticket No',
   key:'ticket_no'
  },
  {
    id:2,
    name:'Fault Title',
    key:'fault_title'
  },
  {
    id:3,
    name:'Type of Fault',
    key:'type_of_fault'
  },
  // {
  //   id:4,
  //   name:'Description'
  // },
  {
    id:5,
    name:'Level',
    key:'fault_report_models.level_no'
  },
  {
    id:6,
    name:'Service Location',
    key:'fault_report_models.service_location'
  },
  {
    id:7,
    name:'Reported By',
    key:'users.name'
  },
  {
    id:8,
    name:'Company Name',
    key:'companies.company_name'
  },
  {
    id:9,
    name:'Category',
    key:'fault_report_models.fault_category_name'
  },
  {
    id:10,
    name:"Created at",
    key:'fault_report_models.created_at'
  },
  {
    id:11,
    name:'Status',
    key:'status'
  }
]

export const Reports_Employee_ColumnName = [
  {
    id:1,
    name:'Employee Name',
    key:'employee_name'
  },
  {
    id:2,
    name:'Employee Type',
    key:'employee_type'
  },
  {
    id:3,
    name:'Job Title',
    key:'job_title'
  },
  {
    id:4,
    name:'Company Name',
    key:'company_name',
  },
  {
    id:5,
    name:'Access Start Date',
    key:'access_start_date',
  },
  {
    id:6,
    name:'Offboard Date',
    key:'offboard_date',
  },
  {
    id:7,
    name:'Access Level/Unit',
    key:'accessLevel',
  },
  {
    id:8,
    name:'Created by',
    key:'created_by_name',
  },
  {
    id:9,
    name:'Created at',
    key:'created_at',
  }
]

export const Reports_Service_columnName = [
  {
    id:1,
    name:'Ticket ID',
    key:'service_requests.ticket_id'
  },
  {
    id:2,
    name:'Date Requested',
    key:'service_requests.created_at'
  },
  {
    id:3,
    name:'Request Type',
    key:'service_request_types.service_request_types_name'
  },
  {
    id:10,
    name:'Company Name',
    key:'companies.company_name'
  },
  {
    id:4,
    name:'Requested By',
    key:'users.name'
  },
  {
    id:5,
    name:'Status',
    key:'service_requests.status'
  },
  {
    id:6,
    name:'Last Updated',
    key:'service_requests.updated_at'
  }
]

export const CompanyReportColumn = [
  {id:1,
  name:'Company Name',
  key:'company_name',
  },
  {
    id:2,
    name:'Email',
    key:'email'
  },
  {id: 99,
    name: "Tenant Manager",
    key:'tenant_manager',
  },
  {
    id:10,
    name:'Office Unit',
    key:'access_level',
  },
  {
    id:3,
    name:'Office Number',
    key:'phone_no'
  },
  {
    id:4,
    name:'Status',
    key:'approved_status'
  },
  {
    id:5,
    name:'Created by',
    key:'name'
  },
  {
    id:6,
    name:'Created On',
    key:'created_at'
  },
  {
    id:7,
    name:'Onboard Count',
    key:'onboarded_employee_count'
  },
  {
    id:8,
    name:'Offboard Count',
    key:'offboard_count'
  },
  {
    id:9,
    name:'Total',
    key:'total_count'
  }
]