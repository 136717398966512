import { Box, Button, Grid, OutlinedInput, Typography } from "@mui/material";
import React, { useState } from "react";
import {ReactComponent as Fileupload} from '../../Assets/icons/Fileupload.svg'
import {ReactComponent as Close} from '../../Assets/icons/Alertclose.svg'

const Moreinfo =()=>{
    const [inputFiles,setInputFiles] = useState([]);
    const [update,setUpdate] =useState('');

    const handleUpdate=(e)=>{
        setUpdate(e.target.value)
    }
    const handleFiles = (e)=>{
        let filearray =[];
        
        for(let i=0;i<e.target.files.length;i++){
            let obj ={name:e.target.files[i].name,
            size:e.target.files[i].size,
            file:e.target.files[i],
            id:i + 1
            }
            filearray.push(obj)
            
        }
        setInputFiles([...filearray])
      
     
    }
   const handleClose = (e,id)=>{
    let filterfiles= inputFiles.filter((value)=>{
        return value.id !==id
    })
    setInputFiles(filterfiles)
   }
    return(
        <>
        <Grid container sx={{width:'781px'}} >
        <Grid item xs={12} sx={{padding:'16px 24px'}}>
        <Typography sx={{color:'#0F2C6F',fontSize:'26px'}}>Required Update</Typography>
        </Grid>
        <Grid item xs={12} sx={{padding:'16px 24px'}}>
        
        <Typography sx={{fontSize:'14px',color:' #0F2C6F'}}>Building Manager requirement</Typography>
        <Box sx={{height:'120px',width:'733px',backgroundColor:'#F3F4F8',padding:'12px',boxSizing:'border-box'}}>
        <Typography sx={{fontSize:'14px',color:'#9AA6C3'}}>Please update the level number as we are unable to locate the unit number that you have stated. Please
        help to clarify this.</Typography>
        
        </Box>
        </Grid>
        <Grid item xs={12}  sx={{padding:'16px 24px'}}>
        <Typography sx={{fontSize:'14px',color:'#0F2C6F',mb:1}}>Additional Update for Building Manager</Typography>
        <OutlinedInput value={update} onChange={(e)=>{handleUpdate(e)}} type="text" sx={{height:'120px',width:'733px',border:' 1px solid #9AA6C3',borderRadius: '6px',':hover':{border:'1px solid #9AA6C3'}}}></OutlinedInput>
        </Grid>
        <Grid item xs={12} sx={{padding:'16px 24px'}}>
        <Box sx={{display:'flex',justifyContent:'space-between'}}>
            <Typography sx={{color:' #0F2C6F',fontize:'18px'}}>Supporting material <span style={{fontSize:'10px'}}>(optional)</span></Typography>
            {inputFiles.length>0 ? (<Box>
                {inputFiles.map((value)=>{
                    return <Box sx={{display:'flex',justifyContent:'space-between',border:'1px solid blue',m:2,p:1}}>
                        <Typography sx={{color:'#0F2C6F'}}>{value.name}</Typography>
                        <Close onClick={(e)=>handleClose(e,value.id)}></Close>
                    </Box>
                }
                )}
            </Box>  ) :(
        <Button component='label' variant='outlined' endIcon={<Fileupload></Fileupload>}>Upload
            <input multiple type='file' style={{display:'none'}} onChange={(e)=>handleFiles(e)}></input>
        </Button>)
            }
        </Box>
        </Grid>
        <Grid item xs={12}>
            <Box sx={{display:'flex',justifyContent:'flex-end',mt:'40px'}}>
                <Button variant='contained' sx={{color:'white',backgroundColor:'#BD0251',padding:'4px',borderRadius:'6px',mr:'10px',fontSize:'12px'}}>Withdraw</Button>
                <Button variant='contained' sx={{color:'white',backgroundColor:'#123FA9',padding:'6px',borderRadius:'6px',fontSize:'12px'}}>Submit Updates</Button>
            </Box>
        </Grid>
        </Grid>
        </>
    )
}

export default Moreinfo;