import { Box, Typography } from "@mui/material";
import React from "react";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        background: "#F9FAFA",       

      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "#ED4337",
          position: 'absolute',
          top: "50%",
          left:"50%",
          transform: "translate(-50%, -50%"
        }}
      >
        <Typography
          variant="span"
          sx={{ fontSize: { md: "46px", xs: "32px" } }}
        >
          404
        </Typography>
        <Typography variant="p" sx={{ fontSize: { md: "32px", xs: "18px" } }}>
          Not Found
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorPage;
