import { Tabs, Tab, Box, Grid, IconButton, ListItem, ListItemText, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import ManageVisitorsTabs from './ManageVisitorsTabs';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import {ReactComponent as RefreshIcon} from "../../../Assets/icons/ColoredRefreshIcon.svg";
import { useEffect } from 'react';
import moment from 'moment';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export const ManageVisitors = ({getVisitorsList,visitorsDetails, getVisitorsListRefresh, VisitorRefreshdata,isLoading}) => {
  let momdate = moment.utc(VisitorRefreshdata?.last_datetime).local().format("DD MMM YYYY HH:mm:ss")

  useEffect(() => {
    // getVisitorsList({ currentTab: 0, getType: 'All', limit: 10, offset: 0, search: '', date: 0, totalInvites: 0,via:"Manage" })
    getVisitorsListRefresh()
}, [])

  return (
    <>
    <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:"100%"}}>
      <Grid item xs={0.5} />
      <Grid item xs={11}>
          <Box sx={{display:"flex",justifyContent:"space-between",mt:8,mb:4}}> 
              <Box>
                  <Typography style={{ fontSize: "26px", fontWeight: "500", color: '#002D74' }}>
                  Manage Visitors
                  </Typography>
                  <Typography style={{ fontSize: "14px", fontWeight: "500", color: '#002D74' }}>
                  Below are the list of visitors registered and have entered/exited Singapore Land Tower.
                  </Typography>
              </Box>
              <Box  sx={{ background: '#FFFFFF', border: ' 1px solid #DBDFE9', borderRadius: '8px', }}>
                <ListItem key={0}
                   secondaryAction={
                    <Box sx={{display:'flex', alignItems:'center'}}>
                      <Typography sx={{ color: '#2764F4', fontSize: '40px', fontFamily: 'Inter' }}>{VisitorRefreshdata?.cur_today_visitors}</Typography>
                      <Typography>  <span style={{ fontSize: '18px', fontFamily: 'Inter', color: '#0F2C6F' }}>{VisitorRefreshdata?.tot_today_visitors || VisitorRefreshdata?.tot_today_visitors ==0 ? '/':'' }{VisitorRefreshdata?.tot_today_visitors}</span>
                      </Typography>
                    </Box>
                  }
                >
                  <ListItemText
                    primary={<Typography sx={{ color: '#0F2C6F', fontFamily: 'Inter', fontSize: '18px',mr:13 }}>{VisitorRefreshdata?.title}</Typography>}
                    secondary={
                      <Box sx={{display:'flex', alignItems:'center', gap:'8px', mt:1}}>
                    <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontFamily: 'Inter' }}>{momdate}</Typography>
                    <Box sx={{display: "flex", gap: '4px', alignItems: "center",cursor: "pointer"}} >
                                <RefreshIcon className={isLoading && "rotate_animation"} />
                            <Typography onClick={() => getVisitorsListRefresh()} sx={{color: "#0F2C6F", textDecoration: "underline", fontSize: "14px"}}>                                 
                            Refresh</Typography>
                    </Box>
                    </Box>
                  }
                  />
                </ListItem>
              </Box>
          </Box>
          <ManageVisitorsTabs />
      </Grid>
      <Grid item xs={0.5} />
    </Grid>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    visitorsDetails: state.ReceptionReducer.visitorsDetails,
    recordCount: state.ReceptionReducer.recordCount,
    VisitorRefreshdata: state.ReceptionReducer.visitorsRefreshData,
    isLoading: state.ReceptionReducer.isLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getVisitorsList: (data) => { dispatch(receptionActions.getVisitorsList(data)) },
      getVisitorsListRefresh: (data) => { dispatch(receptionActions.getVisitorsListRefresh(data)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageVisitors)