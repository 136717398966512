import React from "react";
import { bool, func } from "prop-types";
import {ReactComponent as CarouselLeftArrow} from '../../../Assets/icons/CarouselLeftArrow.svg'

const CustomLeftNav = React.memo(({ disabled, onClick }) => {
  return (
    <button
      type="button"
      style={{zIndex:1}}
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}
      aria-label="Previous Slide"
    >
      <CarouselLeftArrow/>
    </button>
  );
});

CustomLeftNav.displayName = "CustomLeftNav";

CustomLeftNav.propTypes = {
  disabled: bool.isRequired,
  onClick: func.isRequired,
};

export default CustomLeftNav;
