import { handleActions } from "redux-actions"

const snackBarInitialData = {
    openStatus: false,
    severity: "",
    message: "",
}

const SnackBarReducerData = {
    snackBarData: { ...snackBarInitialData }
}

export const SnackBarReducer = handleActions({
    SHOW_SNACK_BAR: (state, action) => {
         return {
            ...state,
            snackBarData: { openStatus: true, message: action.payload.message, severity: action.payload?.severity || "success" }
        }
    },
    RESET_SNACK_BAR: (state) => {
        return {
            ...state,
            snackBarData: { ...snackBarInitialData }
        };
    }
}, SnackBarReducerData)