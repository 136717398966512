import React, { useState, useEffect, useRef } from 'react'
import { Autocomplete, Box, Button, Chip,  Grid, IconButton, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as receptionActions from "../../../redux/actions/visitorsAction";
import ToastAlert from '../../common/Alert';
import Loader from '../../common/Loader';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

const SingleVisitor = ({ getOrganizationList,receptionCompanies,getReasonForVisitors, visitorReasons, addSingleVisitors, inviteStatus,receptionStateToInitial,getTicketList,ticketList }) => {
    const navigate = useNavigate();
    const [contactInfo, setContactInfo] = useState('');
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [visitorDetails, setVisitorDetails] = useState({
        company_id: '',
        reason_for_visiting: '',
        first_name: '',
        last_name: '',
        contact_info: ''
    })
    const [errorDetails, setErrorDetails] = useState({
        company_id: '',
        reason_for_visiting: '',
        first_name: '',
        last_name: '',
        contact_info: '',
        email_err: ''
    })
    const [loaderStatus,setLoaderStattus] = useState(false)
    const [url,setUrl] = useState(null)
    const [displayFrame,setDislayFrame] = useState(false)
    const [ticketValue,setTicketValue] = useState('_')
    const [ticket,setTicket] = useState([])
    const [ticketId,setTicketId] = useState()
    const [isRefreshClicked,setIsRefreshClicked] = useState(false)
    const [company,setCompany] = useState([])
    const [reasons,setReasons] = useState([])

    useEffect(() => {   
        getReasonForVisitors();
        getOrganizationList();
    }, [])
    
    useEffect(()=>{
        getTicketList()
    },[isRefreshClicked,inviteStatus])

    useEffect(()=>{
        if(ticketList?.length>0){
            let arr = ticketList
            for(let i=0;i<ticketList.length;i++){
                arr[i].label = arr[i].ticket_no
                arr[i].firstName = arr[i].first_name==null ? '' : arr[i].first_name
                arr[i].lastName = arr[i].last_name==null ? '' : arr[i].last_name
                arr[i].data = arr[i].mode==1 ? (arr[i].email) : `${arr[i].mobile_code} - ${arr[i].mobile_no}`
            }
            setTicket([...arr])
        }else{
            setTicket([])
        }
    },[ticketList])

    useEffect(()=>{
        if(receptionCompanies?.length>0){
            let arr = receptionCompanies;
            for(let i=0;i<receptionCompanies.length;i++){
                arr[i].label = arr[i].company_name
            }
            setCompany([...arr])
        }
    },[receptionCompanies])

    useEffect(()=>{ 
        if(visitorReasons?.length>0){
                let filter = visitorReasons.filter((value)=>{
                    return value.col_desc==0
                })
                setReasons([...filter])
        }
    },[visitorReasons])
    
    useEffect(()=>{
        let filter = ticket.filter((value)=>{
            return value.ticket_no == ticketValue
        })
        if(filter.length>0){
            setContactInfo(filter[0].mode==1 ? 'email' : 'mobile')
            setVisitorDetails({
                ...visitorDetails,
                first_name:filter[0].first_name,
                last_name:filter[0].last_name,
                contact_info:filter[0].mode==1 ? filter[0].email : `${filter[0].mobile_code} ${filter[0].mobile_no}`
            })
            setTicketId(filter[0].id)
        }else{
            setVisitorDetails({
                ...visitorDetails,
                first_name:'',
                last_name:'',
                contact_info:''
            })
        }
    },[ticketValue])

    useEffect(() => {
        if (inviteStatus !== null) {
            if (inviteStatus.response_status === 'success') {
                setLoaderStattus(false)
                setContactInfo('')
                setVisitorDetails({
                    company_id: '',
                    reason_for_visiting: '',
                    first_name: '',
                    last_name: '',
                    contact_info: ''
                })
                setTicketValue('_')
                setTicketId('')
                setSnackbarStatus({ message: inviteStatus.response_message, status: true, color: 'green', icon: 'close' })
                setTimeout(() => {
                    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
                    receptionStateToInitial();
                }, 5000);
                if(inviteStatus.response_json.url !=null && inviteStatus.response_json.url!=""){
                    setUrl(inviteStatus.response_json.url)
                    setDislayFrame(true)
                    // setVisitorDetails({
                    //     company_id: '',
                    //     reason_for_visiting: '',
                    //     first_name: '',
                    //     last_name: '',
                    //     contact_info: ''
                    // })
                    // setTicketValue('_')
                    // setTicketId('')
                }
            } else {
                setLoaderStattus(false)
                setSnackbarStatus({ message: inviteStatus.response_message, status: true, color: 'red', icon: 'close' })
                setTimeout(() => {
                    setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
                    receptionStateToInitial();
                }, 5000);
            }
        }
    }, [inviteStatus])

    const handleMobileInput = (data, country, event) => {
        const { name, value } = event.target;
        setVisitorDetails({ ...visitorDetails, [name]: value })
    }
    const handleInput = (event) => {
        const { name, value } = event.target;
        setVisitorDetails({ ...visitorDetails, [name]: value })
        setErrorDetails({ ...errorDetails, [name]: '' })
    }
    const handleContactInfo = (contactType) => {
        setContactInfo(contactType)
        setVisitorDetails({ ...visitorDetails, ['contact_info']: '' })
        setErrorDetails({ ...errorDetails, ['contact_info']: '' })
    }

    const handleVisitor = (visitingBy) => {
        let validation = validate()
        if (validation) {
            let mobileNumber = visitorDetails.contact_info.split(" ")
            let datas = {
                enroll_type: contactInfo === 'email' ? 2 : 1,
                company_id: visitorDetails.company_id,
                visiting_reason_id: visitorDetails.reason_for_visiting,
                other_reason: '',
                visitor_mobilecode: contactInfo === 'mobile' ? visitorDetails.contact_info.slice(0, 4) : '',
                visitor_mobileno: contactInfo === 'mobile' ? mobileNumber[1].replace("-","") : '',
                visitor_email: contactInfo === 'email' ? visitorDetails.contact_info : '',
                visitor_firstname: visitorDetails.first_name,
                visitor_lastname: visitorDetails.last_name,
                // visitor_lastname: "",
                reg_via: visitingBy === 'printPass' ? 2 : 1,
                ticket_id:(ticketValue=='_' || ticketValue=='') ? '' : ticketId
            }
            addSingleVisitors(datas)
            setLoaderStattus(true)
            setTimeout(()=>{
                setLoaderStattus(false)
            },30000)
        }
    }
    const validate = () => {
        let errors = {}, errorStatus = [];

        if (visitorDetails.company_id === '' || visitorDetails.company_id==undefined) {
            errorStatus.push(false)
            errors['company_id'] = 'Select Visiting Company!'
        } else {
            errorStatus.push(true)
            errors['company_id'] = ''
        }

        if (visitorDetails.reason_for_visiting === '') {
            errorStatus.push(false)
            errors['reason_for_visiting'] = 'Reason required!'
        } else {
            errorStatus.push(true)
            errors['reason_for_visiting'] = ''
        }

        if (visitorDetails.first_name === '') {
            errorStatus.push(false)
            errors['first_name'] = 'Name required!'
        } else {
            errorStatus.push(true)
            errors['first_name'] = ''
        }

        // if (visitorDetails.last_name === '') {
        //     errorStatus.push(false)
        //     errors['last_name'] = 'Last name required!'
        // } else {
        //     errorStatus.push(true)  
        //     errors['last_name'] = ''
        // }

        if (contactInfo === '') {
            errorStatus.push(false)
            errors['contact_info'] = 'Contact info required!'
        } else {
            errorStatus.push(true)
            errors['contact_info'] = ''
        }

        if (contactInfo === 'email') {
            const emailRegex =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (visitorDetails.contact_info.match(emailRegex)) {
                errorStatus.push(true)
                errors['email_err'] = ''
            } else if (visitorDetails.contact_info === '') {
                errorStatus.push(false)
                errors['email_err'] = 'Email required!'
            } else {
                errorStatus.push(false)
                errors['email_err'] = 'Invalid Email!'
            }
        }

        let isError = errorStatus.includes(false);
        setErrorDetails({ ...errors });
        return !isError;
    }
    return (
        <>
            <ToastAlert  message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
            {loaderStatus && <Loader status={loaderStatus} /> }
            <Grid container sx={{ p: 25, pb: 0, pt: 7, background: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                    <ArrowIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                    <Box sx={{display:"flex",justifyContent:'space-between'}}>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: '26px',
                            color: "#0F2C6F",
                            my:2
                        }}>Register Visitor</Typography>
                        <Button variant='contained' sx={{backgroundColor:'green',height:'48px',textAlign:'center'}}>
                            <Typography sx={{textAlign:'center',verticalAlign:"center"}}> {ticketValue}</Typography>
                            </Button>
                    </Box>
                    
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', }}>
                    <Box sx={{ width: '100%', background: '#FFFFFF',p:2 }}>
                        <Grid container sx={{ p: 1 }}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ color: '#0F2C6F', fontFamily: 'Inter', fontSize: '18px', fontWeight: 500 }}>
                                    Guest Details
                                </Typography>
                                <Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400 }}>
                                    Please enter the guest details on this page.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                            <Typography sx={{ color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                Ticket No
                                </Typography>
                                <Box sx={{display:'flex',mb:2}}>
                                    <Autocomplete noOptionsText={<Typography>No Tickets available</Typography>} onChange={(e,value)=>{
                                        setContactInfo('email')
                                        setTicketValue(value?.ticket_no)
                                    }} fullWidth options={ticket} renderOption={(props,option,state)=><Typography {...props}>{option.firstName} {option.lastName} - {option.data} - {option.label}</Typography>} renderInput={(params)=> <TextField {...params} />} />
                                <IconButton onClick={()=>setIsRefreshClicked(!isRefreshClicked)}><CachedOutlinedIcon style={{margin:'7px'}} /></IconButton>
                                </Box>
                                <Typography sx={{ color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Visiting Company
                                </Typography>
                                <Autocomplete noOptionsText={<Typography>No Companies available</Typography>} options={company} renderInput={(params)=> <TextField {...params} />} renderOption={(props,option)=><Typography {...props}>{`${option.company_name}`}</Typography>} 
                                    onChange={(e,value)=>{
                                        setVisitorDetails({...visitorDetails,company_id:value?.company_id})
                                        setErrorDetails({...errorDetails,company_id:''})
                                        }} />
                                {/* <Select fullWidth value={visitorDetails.company_id} name='company_id' onChange={(e) => handleInput(e)} MenuProps={MenuProps}>
                                {receptionCompanies.map((item, index) => (
                                    <MenuItem key={index} value={item.company_id}>{`${item.company_name}  (${item.location})`}</MenuItem>
                                ))}
                                </Select> */}
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.company_id}
                                </Typography>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Reason for visitting
                                </Typography>
                                <Select fullWidth name='reason_for_visiting' onChange={(e) => handleInput(e)} value={visitorDetails.reason_for_visiting}>
                                    {reasons?.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                    ))}
                                </Select>
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.reason_for_visiting}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ pr: 1, mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Name*
                                </Typography>
                                <OutlinedInput value={visitorDetails.first_name} name='first_name' onChange={(e) => handleInput(e)} fullWidth />
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.first_name}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={6} sx={{ mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Last Name*
                                </Typography>
                                <OutlinedInput value={visitorDetails.last_name} name='last_name' onChange={(e) => handleInput(e)} fullWidth />
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.last_name}
                                </Typography>
                            </Grid> */}
                            <Grid item xs={4} sx={{ pr: 1, mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    Contact detail for verification
                                </Typography>
                                <Box sx={{ mt: 1 }}>
                                    <Chip label='Email' variant={contactInfo === 'email' ? 'contained' : 'outlined'}
                                        onClick={() => handleContactInfo('email')}
                                        sx={{
                                            color: contactInfo === 'email' ? '#FFFFFF' : '#6F80A9',
                                            background: contactInfo === 'email' ? '#123FA9' : '#FFFFFF',
                                            '&:hover': { backgroundColor: '#123FA9' }
                                        }}
                                    />
                                    <Chip onClick={() => handleContactInfo('mobile')}
                                        variant={contactInfo === 'mobile' ? 'contained' : 'outlined'}
                                        label='Mobile Number'
                                        sx={{
                                            ml: 1,
                                            color: contactInfo === 'mobile' ? '#FFFFFF' : '#6F80A9',
                                            background: contactInfo === 'mobile' ? '#123FA9' : '#FFFFFF',
                                            '&:hover': { backgroundColor: '#123FA9' }
                                        }}
                                    />
                                </Box>
                                <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                    {errorDetails.contact_info}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ pr: 1, mt: 1, display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
                                {contactInfo === 'email' && <Box>
                                    <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                        Email
                                    </Typography>
                                    <OutlinedInput value={visitorDetails.contact_info} fullWidth name='contact_info' onChange={(e) => handleInput(e)} />
                                    <Typography sx={{ color: 'red', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                        {errorDetails.email_err}
                                    </Typography>
                                </Box>}
                                {contactInfo === 'mobile' && <Box>
                                    <Typography sx={{ mt: 2, color: '#0F2C6F', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500 }}>
                                        Mobile Number
                                    </Typography>
                                    <PhoneInput value={visitorDetails.contact_info} disableDropdown inputStyle={{ width: '90%', height: '48px', borderRadius: '6px', color: '#0F2C6F' }}
                                        onChange={(data, country, event) => handleMobileInput(data, country, event)} country='sg' inputProps={{ name: 'contact_info' }} />
                                </Box>}
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'start' }}>
                                        
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end', mt: 5, mb: 5 }}>
                    <Button variant='outlined' sx={{ mr: 2, height: '50px', color: '#0F2C6F',textTransform:"capitalize" }} onClick={() => handleVisitor('sendToDevice')}>
                        Send To Device
                    </Button>
                    <Button variant='contained' sx={{ height: '50px', background: '#123FA9',textTransform:"capitalize" }} onClick={() => handleVisitor('printPass')}>
                        Print Pass
                    </Button>
                </Grid>
            </Grid >
           {displayFrame &&  
                <Box >
                    <iframe id="print" style={{visibility:"hidden"}} src={url} />
                </Box>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        visitorReasons: state.ReceptionReducer.visitorReasons,
        inviteStatus: state.ReceptionReducer.addStatus,
        receptionCompanies: state.ReceptionReducer.receptionCompanies,
        ticketList:state.ReceptionReducer.ticketList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getReasonForVisitors: () => {
            dispatch(receptionActions.getReasonForVisitors());
        },
        addSingleVisitors: (data) => {
            dispatch(receptionActions.addSingleVisitors(data));
        },
        receptionStateToInitial: () => {
            dispatch(receptionActions.receptionStateToInitial());
        },
        getOrganizationList: () => {
            dispatch(receptionActions.getOrganizationList());
        },
        getTicketList:()=>{
            dispatch(receptionActions.getTicketList())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleVisitor)