import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import {ReactComponent as AnnouncentIcon} from '../../Assets/icons/Announcement.svg';
import {ReactComponent as StaffIcon} from '../../Assets/icons/Staff.svg';
import {ReactComponent as InfoIcon} from '../../Assets/icons/Info.svg';
import {ReactComponent as SettingsIcon} from '../../Assets/icons/Settings.svg';
import {ReactComponent as ServiceRequest} from '../../Assets/icons/ServiceRequestIcon.svg';
import {ReactComponent as HelpIcon} from '../../Assets/icons/Help.svg';
import { useNavigate } from "react-router-dom";

const PrimaryTypography = styled(Typography)({
  fontSize:'18px',
  color:'#0F2C6F',
  fontWeight:500,
  marginTop:'10px'
})

const SecondaryTypography = styled(Typography)({
  fontSize:'14px',
  color:'#0F2C6F',
  fontWeight:400,
  marginTop:'10px'
})

const StyledButton = styled(Button)({
  backgroundColor:'#123FA9',
  borderRadius:'6px',
  textTransform:'capitalize',
  cursor:'pointer',
  '&:hover':{
    backgroundColor:'#123FA9'
  },
  marginBottom:'5px'
 
})

const BMSAdministration = () => {
  const navigate = useNavigate();
  return (
    <>
    <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:'auto', pb: 5}}>
      <Grid item xs={0.5} />
   
    <Grid item xs={11} container columnSpacing={2}>
      <Grid item xs={12} sx={{mt:8,mb:4}}>
          <Typography sx={{color:' #002D74',fontSize:'26px',fontWeight:500}}>BMS Administration</Typography>
          <Typography sx={{color:' #002D74',fontSize:'14px',fontWeight:500}}>Please select one of the options below</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{height:'361px',padding:'40px 26px 30px'}}>
          <CardContent sx={{minHeight:180}}>  
            <AnnouncentIcon  />
            <PrimaryTypography >Manage Announcement and Notification</PrimaryTypography>
            <SecondaryTypography >You may create a building message that will be sent to selected or all users of the Singland App & Dashboard. </SecondaryTypography>
          </CardContent>
          <CardActions>
            <StyledButton variant="contained"  onClick={()=>navigate('/announcement')} >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{height:'361px',padding:'40px 26px 30px'}}>
          <CardContent sx={{minHeight:180}}>
            <StaffIcon  />
            <PrimaryTypography>Manage Staff Account</PrimaryTypography>
            <SecondaryTypography>You may edit staff accounts, assign staff job roles and access rights of building staff. For adding of tenant companies/ accounts, please go to manage tenants.</SecondaryTypography>
          </CardContent>
          <CardActions >
            <StyledButton onClick={()=>navigate('/manage_staff/0')} variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card  sx={{height:'361px',padding:'40px 26px 30px'}}>
          <CardContent sx={{minHeight:180}}>
            <InfoIcon  />
            <PrimaryTypography>Manage Useful Information</PrimaryTypography>
            <SecondaryTypography>You may update important contacts and upload files for users to access like tenant handbook.</SecondaryTypography>
          </CardContent>
          <CardActions  >
            <StyledButton onClick={()=>navigate('/information/0')}variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card  sx={{height:'300px',padding:'40px 26px 30px',mt:2}}>
          <CardContent sx={{minHeight:180}}>
            <SettingsIcon />
            <PrimaryTypography>Chargeable Rates & Product Inventory</PrimaryTypography>
            {/* <SecondaryTypography>You may manage SingLand inventory products here by adding, editing or deleting inventory for service requests.</SecondaryTypography> */}
            <SecondaryTypography>You may manage Singapore Land Tower’s stock inventory for service requests.</SecondaryTypography>
          </CardContent>
          <CardActions  >
            <StyledButton onClick={()=>navigate('/lightbulbProducts')}variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card  sx={{height:'300px',padding:'40px 26px 30px',mt:2}}>
          <CardContent sx={{minHeight:180}}>
            <ServiceRequest style={{height: 50, width: 50}} />
            <PrimaryTypography>Manage Service Request Forms</PrimaryTypography>
            <SecondaryTypography>You may view digital service request forms and update downloadable service request forms here.</SecondaryTypography>
          </CardContent>
          <CardActions  >
            <StyledButton onClick={()=>navigate('/service_request_forms/0/0/null')}variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card  sx={{height:'300px',padding:'40px 26px 30px',mt:2}}>
          <CardContent sx={{minHeight:180}}>
            <SettingsIcon />
            <PrimaryTypography>Manage Fault Category</PrimaryTypography>
            {/* <SecondaryTypography>You may manage SingLand inventory products here by adding, editing or deleting inventory for service requests.</SecondaryTypography> */}
            <SecondaryTypography>You may add or update Fault Category.</SecondaryTypography>
          </CardContent>
          <CardActions  >
            <StyledButton onClick={()=>navigate('/manage_fault_category')}variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card  sx={{height:'300px',padding:'40px 26px 30px',mt:2}}>
          <CardContent sx={{minHeight:180}}>
            <HelpIcon style={{height: 50, width: 50}} />
            <PrimaryTypography>Manage Frequently Asked Questions</PrimaryTypography>
            <SecondaryTypography>You may create frequently asked questions that will be shown to all users of the Singland App & Dashboard.</SecondaryTypography>
          </CardContent>
          <CardActions  >
            <StyledButton onClick={()=>navigate('/help')}variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>

      <Grid item xs={12} sm={4}>
        <Card  sx={{height:'300px',padding:'40px 26px 30px',mt:2}}>
          <CardContent sx={{minHeight:180}}>
            <HelpIcon style={{height: 50, width: 50}} />
            <PrimaryTypography>Manage Contact Details</PrimaryTypography>
            <SecondaryTypography>You may create contact details that will be shown to all users of the Singland App & Dashboard.</SecondaryTypography>
          </CardContent>
          <CardActions  >
            <StyledButton onClick={()=>navigate('/contact')}variant="contained" >Manage</StyledButton>
          </CardActions>
        </Card>
      </Grid>
    </Grid>

    <Grid item xs={0.5} />
  </Grid>
    </>
  );
};

export default BMSAdministration;
