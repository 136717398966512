import { Box, Checkbox, Pagination, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import {ReactComponent as CheckboxIcon} from '../../../Assets/icons/CheckboxIcon.svg';
import { getTimeStamp, getTimeStampWithoutFromNow } from '../../../Utils/utils';
import { connect } from 'react-redux';
import moment from 'moment';
const StyledTableCell = styled(TableCell)({
    color: '#0F2C6F',
    fontSize: '13px',
    fontWeight: 550,
    textAlign: "left",
    fontFamily: 'Inter',
    lineHeight: '18px'
})
const StyledTableRow = styled(TableRow)({
    cursor: "pointer",
    height: '15px !important',
    ':nth-last-of-type(even)': {
        backgroundColor: ' #F5F8FF'
    }
})

const  VisitorsList = ({ handlePagignation, tabProps, tableColumnsAndDatas, allselected, handleAllSelected, handleSelected,recordCount,visitors })=> {
    const navigate = useNavigate()
    console.log('visitors ',visitors)
    const renderTableDatas = (rowData) => {
        console.log('rowData ',rowData)
        if (tabProps.getType === 'All' || tabProps.getType === 'Registered') {
            return (
                <StyledTableRow >
                    {/* <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}><Checkbox icon={<CheckboxIcon />} checked={false}></Checkbox></TableCell> */}
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left", minWidth:'100px' }}>{rowData?.registered_date}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.visitor_name}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.email || rowData?.mobile_no}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.company_name}</TableCell>
                      <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>
                            <Box sx={{display:'flex', gap:'5px', minWidth:'200px !important', justifyContent:'center'}}>
                                {/* <Box sx={{display:'flex',flexDirection:'column'}}>
                                    <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem', display: rowData?.to_datetime ? 'block' : 'none'}}>From</Typography>
                                    <Typography sx={{alignSelf:'flex-end', mt:'3px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem', display: rowData?.to_datetime ? 'block' : 'none'}}>To</Typography>
                                </Box> */}
                                <Box sx={{display:'flex', gap:'5px', justifyContent:'center'}}>
                                    {rowData?.from_datetime && 
                                     moment.utc(rowData.from_datetime, "YYYY-MM-DD HH:mm:ss").local().format("DD-MM-yy") == moment.utc(rowData.to_datetime, "YYYY-MM-DD HH:mm:ss").local().format("DD-MM-yy") ?

                                    <Typography sx={{mb:'3px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem',display: rowData?.to_datetime ? 'block' : 'none'}}> {moment.utc(rowData.from_datetime, "YYYY-MM-DD").local().format("DD-MM-yy")}</Typography> : 
                                    <Typography sx={{mb:'3px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem',display: rowData?.to_datetime ? 'block' : 'none'}}> {moment.utc(rowData.from_datetime, "YYYY-MM-DD").local().format("DD-MM-yy")} - { moment.utc(rowData.to_datetime, "YYYY-MM-DD").local().format("DD-MM-yy")}</Typography>
                                    }
                                </Box>
                            </Box>
                        </TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.level_name}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.last_in_time!=null ?  getTimeStampWithoutFromNow(rowData?.last_in_time) : '-'}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.last_out_time!=null ?  getTimeStampWithoutFromNow(rowData?.last_out_time) : '-'}</TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>{rowData?.registered_by}</TableCell>
                </StyledTableRow>
            )
        }
        else if (tabProps.getType === 'Invited' || tabProps.getType === 'Special_access') {
            return (
                <StyledTableRow >
                    {/* <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}><Checkbox icon={<CheckboxIcon />} checked={false}></Checkbox></TableCell> */}
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left", minWidth:'100px' }}
                      onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.visit_date}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                       onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.special_count}
                    </TableCell>
                    <TableCell onClick={()=>{
                        if(rowData?.inv_type == "Single_vistor" || rowData?.inv_type=="Self_kiosk"){
                            navigate(`/review_registered/${rowData?.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Tenant_Invite"){
                            navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                        }else if(rowData?.inv_type=="Special_access"){
                            navigate(`/review_specialaccess/${rowData.invitations_id}`) 
                        }
                    }} sx={{ color: '#0F2C6F', textAlign: "left" }}>
                            <Box sx={{display:'flex', gap:'5px', minWidth:'200px !important', justifyContent:'center'}}>
                                {/* <Box sx={{display:'flex',flexDirection:'column'}}>
                                    <Typography sx={{fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem', display: rowData?.to_datetime ? 'block' : 'none'}}>From</Typography>
                                    <Typography sx={{alignSelf:'flex-end', mt:'3px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem', display: rowData?.to_datetime ? 'block' : 'none'}}>To</Typography>
                                </Box> */}
                                <Box sx={{display:'flex', gap:'5px', justifyContent:'center'}}>
                                    {rowData?.from_datetime && 
                                     moment.utc(rowData.from_datetime, "YYYY-MM-DD HH:mm:ss").local().format("DD-MM-yy") == moment.utc(rowData.to_datetime, "YYYY-MM-DD HH:mm:ss").local().format("DD-MM-yy") ?

                                    <Typography sx={{mb:'3px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem',display: rowData?.to_datetime ? 'block' : 'none'}}> {moment.utc(rowData.from_datetime, "YYYY-MM-DD").local().format("DD-MM-yy")}</Typography> : 
                                    <Typography sx={{mb:'3px',fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontSize:'0.875rem',display: rowData?.to_datetime ? 'block' : 'none'}}> {moment.utc(rowData.from_datetime, "YYYY-MM-DD").local().format("DD-MM-yy")} - { moment.utc(rowData.to_datetime, "YYYY-MM-DD").local().format("DD-MM-yy")}</Typography>
                                    }
                                </Box>
                            </Box>
                        </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                       onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.invitations_title}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                       onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.requested_by}
                    </TableCell>
                    <TableCell sx={{ color: '#0F2C6F', textAlign: "left" }}
                      onClick={()=>{
                        rowData.inv_type =="Special_access" ? navigate(`/review_specialaccess/${rowData.invitations_id}`) : 
                        navigate(`/review_manage_invite/${tabProps.currentTab}/${rowData.invitations_id}/${rowData?.inv_type}`)
                    }}
                    >
                        {rowData?.level_name}
                    </TableCell>
                </StyledTableRow>
            )
        }
    }
    return (
        <>
             <TableContainer >
                <Table>
                    <TableHead>
                        <TableRow>

                            {tableColumnsAndDatas.columns.map((item) => (
                                item.column_name === 'Checkbox' ?
                                    <StyledTableCell >
                                        <Checkbox icon={<CheckboxIcon />} checked={Boolean(allselected)} onClick={() => handleAllSelected()} />
                                    </StyledTableCell> :
                                    <StyledTableCell sx={{textAlign: item.column_name == 'Period' ? 'center' : 'left'}}>
                                        {item.column_name}
                                    </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    {recordCount>0 && 
                    <TableBody>
                        {visitors?.map((item) => (
                            renderTableDatas(item)
                        ))}
                    </TableBody>
                    }
                </Table>
                {
                    recordCount > 0 && 
                    <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: "rgba(143, 146, 161,0.05)", py: 2.5 }}>
                    <Pagination
                        count={Math.ceil(recordCount/ 10)}
                        page={tabProps.offset + 1}
                        size="large"
                        shape="rounded"
                        onChange={handlePagignation} />
                    </Box>
                }
               
            </TableContainer> 
            
            { recordCount< 1 && <Box sx={{display:"flex",justifyContent:"center",p:5,backgroundColor:"rgba(143, 146, 161,0.05)"}}>
                <Typography  sx={{textAlign:"center",fontSize:"20px",color:'#0F2C6F'}}>No Data Found</Typography>
            </Box>
            } 
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        visitors: state.ReceptionReducer.visitorsDataBms,
        recordCount: state.ReceptionReducer.recordCount
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}
 export default connect(mapStateToProps,mapDispatchToProps)(VisitorsList)
