import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as ImportantContactAction from '../redux/actions/ImportantContactAction/index';
import {ReactComponent as PdfIcon} from '../Assets/icons/PDF.svg';

const HeplAndSupport = ({contactList,getContactList,getHbList,handbookList})=>{
    const [activeBook,setActiveBook] = useState();
    
    useEffect(()=>{
        getContactList()
        getHbList()
    },[])
    
    useEffect(()=>{
        getActiveFile()
    },[handbookList])

    const getActiveFile = ()=>{
        for(let i=0;i<=handbookList.length;i++){
            if(handbookList[i]?.status==1){
                setActiveBook(handbookList[i])
            }
        }
    }
    return(
        <>
            <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)'}}>
                <Grid item xs={1.5} />
                <Grid item xs={9} sx={{pt:8}}>
                    <Box>
                        <Typography sx={{color:"#002D74",fontSize:"26px",textTransform:"capitalize"}}>Help & support</Typography>
                        <Typography sx={{color:"#002D74",fontSize:"20px",my:2}}>Got a question? We’d love to hear from you. Please send a mail to the below E-mail </Typography>
                        <Typography sx={{color:"#002D74",fontSize:"15px",fontWeight:550}}>servicedesk@singaporeland.com</Typography>
                     </Box>
                </Grid>
                <Grid item xs={1.5} />
            </Grid>
      
            <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',pt:7,pb:2}}>
            <Grid item xs={1.5} />
            <Grid item xs={9}>
                    <Typography sx={{color:"#002D74",fontSize:"26px",textTransform:"capitalize"}}>Tenant Handbook</Typography>
                    {activeBook && <Box sx={{display:'flex',p:3,width:'100%',border:'1px solid #DBDFE9',mt:2,backgroundColor:'#FFFFFF'}}>
                    <PdfIcon />
                    <Box sx={{display:"flex",flexDirection:"column",ml:3,mt:4}}>
                        <Chip label="Active" sx={{backgroundColor:"#D3F2E5",borderRadius:"6px",width:"80px"}}/>
                        <Typography sx={{color:" #0F2C6F",fontSize:"18px",mt:2}}>{activeBook?.orig_filename}</Typography>
                        <Button target='_blank' href={activeBook.new_filename} variant="outlined" sx={{textTransform:'capitalize',mt:2,color:" #0F2C6F"}}>View PDF</Button>
                    </Box>
                </Box>
                }
            </Grid>
            <Grid item xs={1.5} />
            </Grid>

            <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',pt:7,pb:2}}>
                <Grid item xs={1.5} />
                <Grid item xs={9}>
                    <Typography sx={{color:"#002D74",fontSize:"26px",textTransform:"capitalize"}}>Important Contacts</Typography>
                    {contactList?.map((item)=>(
                  <>
                  <Box
                  key={item?.contact_id}
                  sx={{
                    padding: { md: '25px', xs: '15px' },
                    width:'100%',
                    border:'1px solid #DBDFE9',
                    borderRadius:"8px",
                    mt:2
                  }}
                >
                   <Box sx={{display:'flex',justifyContent:"space-between"}}>
                  <Typography sx={{fontSize:'18px', color:'#0F2C6F', fontWeight:'500'}}>
                {item?.group_title}
                  </Typography>
                  </Box>
                    <Grid container sx={{mt:2}}>
                    {item?.sub_contacts?.map((value)=><>
                      <Grid item container xs={12} sx={{borderBottom:"1px solid #DBDFE9",py:3}}>
                          <Grid item xs={4}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px",fontWeight:600}}>{value?.title}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"12px"}}>{value?.desc}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"18px",fontWeight:500}}>{value.number}</Typography>
                          </Grid>
                      </Grid>
                    </>)}
                    </Grid>
                          
      </Box>
      </>
            ))}
                </Grid>
                <Grid item xs={1.5} />
            </Grid>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
      contactList:state.ImportantContactReducer.contactList,
      handbookList:state.ImportantContactReducer.handbookList,
    }
  }
  
  const mapDispatchToProps = (dispatch)=>{
    return{
      getContactList:()=>(dispatch(ImportantContactAction.getContactList())),
      getHbList:()=>(dispatch(ImportantContactAction.getHbList())),
    }
  }
  
export default connect(mapStateToProps,mapDispatchToProps)(HeplAndSupport);