import React, { useEffect } from 'react'
import Header from '../Header'
  import { Route, Routes, useNavigate } from 'react-router-dom'
import FacilityBooking from '../Pages/FacilityBooking'
import ConfirmedBooking from '../Pages/FacilityBooking/ConfirmedBooking'

const NavigateToRoute = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/manage_facility_booking');
  }, []);
  return;
}
 
const TECTeam = () => {
 
  return (
    <>
    <Header>
        <Routes>
            <Route path="/manage_facility_booking" element={<FacilityBooking />} />
            <Route path="*" element={<NavigateToRoute />} />
            {/* <Route path="/facility_booking/:facility_id" element={<ConfirmedBooking />} /> */}
        </Routes>
    </Header>
    </>
  )
}

export default TECTeam