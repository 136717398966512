import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  MenuList,
  Modal,
  OutlinedInput,
  Pagination,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../Assets/icons/search.svg";
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { ReactComponent as MoreHorizIcon } from "../../Assets/icons/more-alt.svg";
import { ReactComponent as CloseIcon } from "../../Assets/icons/Alertclose.svg";
import {
  BMS_STAFF_ONBOARDING_COLUMNS,
  BMS_STAFF_DRAFT_COLUMNS,
  BMS_STAFF_ACTIVE_COLUMNS,
  ACTIVE_EMPLOYEES,
  BMS_STAFF_OFFBOARDING_COLUMNS,
  BMS_STAFF_INACTIVE_COLUMNS,
} from "../../components/Data/Data";
import { connect } from "react-redux";
import * as BmsStaffAction from "../../redux/actions/bmsStaffAction";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import {
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
} from "../../Utils/utils";
import Faultalert from "../common/faultalert";
import ToastAlert from "../common/Alert";

const StyledTableCell = styled(TableCell)({
  fontSize: "13px",
  color: "#0F2C6F",
  fontWeight: 600,
});

const StyledTableRow = styled(TableRow)({
  cursor: "pointer",
  "&:nth-of-type(even)": {
    backgroundColor: "#F3F4F8",
  },
});

const BodyTableCell = styled(TableCell)({
  fontSize: "14px",
  color: "#0F2C6F",
  fontWeight: 400,
});

const StaffTable = ({
  activeType,
  tabValue,
  bmsStaffList,
  bmsStaffListCount,
  getBmsStaffList,
  editStaffOnboardDate,
  editOnboardDateStatus,
  setBmsStaffReducernull,
  cancelOnboardStaff,
  cancelOnboardStatus,
}) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({ date: null, search: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const Editopen = Boolean(editAnchorEl);
  const [alert, setAlert] = useState({ status: false, data: {} });
  const [selectedData, setSelectedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [editOnboardDate, setEditOnboardDate] = useState(null);
  const [snackbar, setSnackbar] = useState({
    status: false,
    message: "",
    color: "",
  });
  const [editEmp, setEditEmp] = useState(null);

  useEffect(() => {
    let data = {
      status: activeType,
      limit: 10,
      offset: currentPage - 1,
      date: getYYYYMMDDFormat(filterData.date),
      search: filterData.search,
    };
    getBmsStaffList(data);
  }, [activeType, currentPage, filterData, cancelOnboardStatus]);

  useEffect(() => {
    handleModalClose();
    if (editOnboardDateStatus?.response_status == 1) {
      setSnackbar({
        status: true,
        message: editOnboardDateStatus?.message,
        color: "green",
      });
      setTimeout(() => {
        setSnackbar({ status: false, message: "", color: "" });
        setBmsStaffReducernull();
      }, 4000);
    } else if (editOnboardDateStatus?.response_status == 0) {
      setSnackbar({
        status: true,
        message: editOnboardDateStatus?.message,
        color: "red",
      });
      setTimeout(() => {
        setSnackbar({ status: false, message: "", color: "" });
        setBmsStaffReducernull();
      }, 4000);
    }
  }, [editOnboardDateStatus]);

  useEffect(() => {
    handleAlertClose();
    if (cancelOnboardStatus?.response_status == 1) {
      setSnackbar({
        status: true,
        message: cancelOnboardStatus?.message,
        color: "green",
      });
      setTimeout(() => {
        setSnackbar({ status: false, message: "", color: "" });
        setBmsStaffReducernull();
      }, 4000);
    } else if (cancelOnboardStatus?.response_status == 0) {
      setSnackbar({
        status: true,
        message: cancelOnboardStatus?.message,
        color: "red",
      });
      setTimeout(() => {
        setSnackbar({ status: false, message: "", color: "" });
        setBmsStaffReducernull();
      }, 4000);
    }
  }, [cancelOnboardStatus]);

  const handlePagination = (e, value) => {
    setCurrentPage(value);
  };

  const handleReset = () => {
    setFilterData({
      search: "",
      date: null,
    });
  };

  const handleCancelOnboarding = () => {
    setAlert({
      status: true,
      alertData: {
        label: "Cancel Onboarding",
        description: `Are you sure want to cancel onboard "${selectedData?.name}" `,
        buttonvalue: "Confirm",
      },
    });
  };

  const handleAlertClose = () => {
    setAlert({ status: false, alertData: {} });
    setAnchorEl(null);
  };

  const handleAlertConfirm = () => {
    let data = new FormData();
    data.append(`employee_id`, selectedData?.employee_id);
    data.append(`employee_type`, `bms_employee`);
    cancelOnboardStaff(data);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setAnchorEl(null);
  };

  const handleEditConfirm = () => {
    let data = new FormData();
    data.append(`access_start_date`, getYYYYMMDDFormat(editOnboardDate));
    data.append(`employee_id`, selectedData?.employee_id);
    data.append(`employee_type`, `bms_employee`);
    editStaffOnboardDate(data);
  };

  const getColumn = () => {
    let column =
      activeType === "active"
        ? BMS_STAFF_ACTIVE_COLUMNS
        : activeType === "onboarding"
        ? BMS_STAFF_ONBOARDING_COLUMNS
        : activeType === "drafts"
        ? BMS_STAFF_DRAFT_COLUMNS
        : activeType === "offboard"
        ? BMS_STAFF_OFFBOARDING_COLUMNS
        : BMS_STAFF_INACTIVE_COLUMNS;
    return column;
  };
  const getColumnName = () => {
    const column = getColumn();
    return column.map((value) =>
      value.column_name === "Check Box" ? (
        <TableCell>
          <Checkbox icon={<CheckboxIcon />} size="small" />
        </TableCell>
      ) : (
        <StyledTableCell>{value.column_name}</StyledTableCell>
      )
    );
  };

  const getColumnData = (value) => {
    const column = getColumn();
    if (activeType == "onboarding") {
      return (
        <>
          {column.map((value) =>
            value.column_name === "Check Box" ? (
              <TableCell>
                <Checkbox icon={<CheckboxIcon />} size="small" />
              </TableCell>
            ) : (
              ""
            )
          )}
          <BodyTableCell>{value.name}</BodyTableCell>
          <BodyTableCell>{value.requested_on}</BodyTableCell>
          <BodyTableCell>{value.active_date}</BodyTableCell>
          <BodyTableCell>{value.submitted_by}</BodyTableCell>
          <BodyTableCell>{value.role}</BodyTableCell>
          <BodyTableCell>{value.last_updated}</BodyTableCell>
          <TableCell>
            <MoreHorizIcon
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setSelectedData(value);
                setEditEmp(value.employee_id);
              }}
            />
          </TableCell>
        </>
      );
    } else if (activeType == "active") {
      return (
        <>
          {column.map((value) =>
            value.column_name === "Check Box" ? (
              <TableCell>
                <Checkbox icon={<CheckboxIcon />} size="small" />
              </TableCell>
            ) : (
              ""
            )
          )}
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.name}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.email}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.mobile_no}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.job_title}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.access_start_date}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.office_unit}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.role}
          </BodyTableCell>
          <TableCell>
            <MoreHorizIcon
              onClick={(e) => {
                setEditAnchorEl(e.currentTarget);
                setEditEmp(value?.employee_id);
              }}
            />
          </TableCell>
        </>
      );
    } else if (activeType == "offboard") {
      return (
        <>
          {column.map((value) =>
            value.column_name === "Check Box" ? (
              <TableCell>
                <Checkbox icon={<CheckboxIcon />} size="small" />
              </TableCell>
            ) : (
              ""
            )
          )}
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.name}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.requested_on}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.offboard_date}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.submitted_by}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.job_title}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.last_updated}
          </BodyTableCell>
        </>
      );
    } else {
      return (
        <>
          {column.map((value) =>
            value.column_name === "Check Box" ? (
              <TableCell>
                <Checkbox icon={<CheckboxIcon />} size="small" />
              </TableCell>
            ) : (
              ""
            )
          )}
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.name}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.email}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.mobile_no}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.job_title}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.offboard_date}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.office_unit}
          </BodyTableCell>
          <BodyTableCell
            onClick={() =>
              navigate(
                `/single_employee_review/${value.employee_id}/${activeType}`
              )
            }
          >
            {value.role}
          </BodyTableCell>
        </>
      );
    }
  };

  const TabFilterOptions = () => {
    return (
      <>
        <Grid container columnSpacing={1} sx={{ my: 2 }}>
          <Grid
            item
            xs={9}
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            {/* <FormControl fullWidth size="small" sx={{ width: 160, }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Type of Work</InputLabel>
                  <Select name="serviceType" 
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px' }} label="Type of work">
                    
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ width: 160,ml:2 }}>
                  <InputLabel sx={{ color: '#123FA9', fontSize: '14px', fontWeight: 600 }}>Status</InputLabel>
                  <Select
                   
                    sx={{ color: "#123FA9", fontSize: "14px", borderRadius: '6px' }}
                    label="Status" name="serviceStatus"
                   >
                  </Select>
                </FormControl> */}
            <FormControl sx={{ width: 160, pl: 1, ml: 1.5 }}>
              <DatePicker
                inputFormat="dd/MM/yyyy"
                components={{
                  OpenPickerIcon: DatePickerIcon,
                }}
                value={filterData.date}
                onChange={(e) =>
                  setFilterData({
                    ...filterData,
                    date: e,
                  })
                }
                renderInput={(params) => (
                  <TextField
                    onKeyDown={(e) => preventDatePickerKeyboard(e)}
                    size="small"
                    {...params}
                  />
                )}
                disableFuture={true}
              />
            </FormControl>
            <Typography
              onClick={handleReset}
              sx={{
                color: "#2764F4",
                textDecoration: "underline",
                fontSize: "16px",
                ml: 2,
                textUnderlineOffset: "5px",
                cursor: "pointer",
              }}
            >
              Reset
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              pr: 2,
            }}
          >
            <OutlinedInput
              value={filterData.search}
              placeholder="Search..."
              onChange={(e) =>
                setFilterData({
                  ...filterData,
                  search: e.target.value,
                })
              }
              name="search"
              endAdornment={<SearchIcon />}
              size="small"
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      {snackbar.status && (
        <ToastAlert
          status={snackbar.status}
          message={snackbar.message}
          color={snackbar.color}
        />
      )}
      {alert.status && (
        <Faultalert
          closeFunc={handleAlertClose}
          confirmFunc={handleAlertConfirm}
          alertData={alert.alertData}
        />
      )}
      {TabFilterOptions()}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>{getColumnName()}</TableRow>
          </TableHead>
          {bmsStaffList?.length > 0 && (
            <TableBody>
              {bmsStaffList.map((value, index) => (
                <StyledTableRow key={index}>
                  {getColumnData(value)}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {bmsStaffList?.length < 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 4,
            backgroundColor: "rgba(143, 146, 161,0.05)",
          }}
        >
          <Typography sx={{ fontSize: "20px", color: "#0F2C6F" }}>
            No data found
          </Typography>
        </Box>
      )}

      {bmsStaffListCount > 0 && (
        <Pagination
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 3,
            backgroundColor: "rgba(143, 146, 161,0.05)",
          }}
          size="large"
          shape="rounded"
          count={Math.ceil(bmsStaffListCount / 10)}
          page={currentPage}
          onChange={handlePagination}
        ></Pagination>
      )}

      <Menu
        onClose={() => {
          setEditAnchorEl(null);
          setEditEmp(null);
        }}
        open={Editopen}
        anchorEl={editAnchorEl}
      >
        <MenuItem
          onClick={() => {
            navigate(`/edit-bms-staff/${editEmp}`);
            setEditAnchorEl(null);
            setEditAnchorEl(null);
          }}
          sx={{ color: "#0F2C6F" }}
        >
          Edit Staff
        </MenuItem>
      </Menu>

      <Menu onClose={() => setAnchorEl(null)} open={open} anchorEl={anchorEl}>
        <MenuItem
          sx={{ color: "#0F2C6F" }}
          onClick={() => {
            navigate(`/edit-bms-staff/${editEmp}`);
            setEditAnchorEl(null);
            setEditAnchorEl(null);
          }}
        >
          Edit Personal Details
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setModalOpen(true)} sx={{ color: "#0F2C6F" }}>
          Edit Onboard Date
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCancelOnboarding} sx={{ color: "#0F2C6F" }}>
          Cancel Onboarding
        </MenuItem>
      </Menu>

      <Modal onClose={handleModalClose} open={modalOpen}>
        <Box
          sx={{
            position: "absolute",
            top: "15%",
            left: "33%",
            backgroundColor: "#FFFFFF",
            width: 550,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid #0F2C6F",
              p: 2,
            }}
          >
            <Typography
              sx={{ color: "#0F2C6F", fontSize: "16px", fontWeight: 550 }}
            >
              Edit Onboard Date
            </Typography>
            <CloseIcon onClick={handleModalClose} />
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography sx={{ color: "#0F2C6F", my: 1 }}>
              Existing Onboard Date - {selectedData?.active_date}
            </Typography>
            <Typography sx={{ color: "#0F2C6F", mt: 3, mb: 1 }}>
              Select Date *
            </Typography>
            <DatePicker
              disablePast
              value={editOnboardDate}
              onChange={(value) => setEditOnboardDate(value)}
              inputFormat="dd/MM/yyyy"
              components={{
                OpenPickerIcon: DatePickerIcon,
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => preventDatePickerKeyboard(e)}
                  {...params}
                />
              )}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
            <Button
              onClick={handleModalClose}
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                color: "#0F2C6F",
                fontSize: "14px",
                borderRadius: "8px",
                mr: 2,
                height: "40px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleEditConfirm}
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: "#FFFFFF",
                backgroundColor: "#123FA9",
                borderRadius: "8px",
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bmsStaffList: state.BMSStaffReducer.bmsStaffList,
    bmsStaffListCount: state.BMSStaffReducer.bmsStaffListCount,
    editOnboardDateStatus: state.BMSStaffReducer.editOnboardDateStatus,
    cancelOnboardStatus: state.BMSStaffReducer.cancelOnboardStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBmsStaffList: (data) => dispatch(BmsStaffAction.getBmsStaffList(data)),
    editStaffOnboardDate: (data) =>
      dispatch(BmsStaffAction.editStaffOnboardDate(data)),
    cancelOnboardStaff: (data) =>
      dispatch(BmsStaffAction.cancelOnboardStaff(data)),
    setBmsStaffReducernull: () =>
      dispatch(BmsStaffAction.setBmsStaffReducernull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffTable);
