import { Box, Button, Checkbox, FormControl, Grid, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DropZone from "../../../Utils/dropzone";
import { ReactComponent as RefreshIcon } from '../../../Assets/icons/Refresh.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/Alertclose.svg';
import { ReactComponent as CheckboxIcon } from '../../../Assets/icons/CheckboxTerms.svg';
import { ReactComponent as DatePickerIcon } from '../../../Assets/icons/DatePickerIcon.svg';
import { ReactComponent as Fileupload } from '../../../Assets/icons/Fileupload.svg';
import { getLastUpdatedTime, getUTCDateFormat, getUTCTimeFormat, getYYYYMMDDFormat, preventDatePickerKeyboard } from "../../../Utils/utils";
import { styled } from "@mui/system";
import { TimePicker } from "@mui/x-date-pickers";
import { connect } from "react-redux";
import * as commonAction from "../../../redux/actions/commonAction";
import * as ServiceAction from "../../../redux/actions/service_request";
import Alert from '../../common/faultalert'
import { useNavigate, useParams } from "react-router-dom";
import ToastAlert from "../../common/Alert";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Loader from "../../common/Loader";

const alertData = {
    label: 'Confirm Light Bulb Replacement Request',
    description: 'Please ensure all details are entered correctly to prevent any delay to processing of request. Requests are subjected to Singapore Land Tower’s building management approval.',
    buttonvalue: 'Submit'
}

const StyledTypography = styled(Typography)({
    color: "#0F2C6F",
    fontSize: "16px",
    marginBottom: "5px",
    fontWeight:550
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

const LightBulb = ({ levels, getLevels, addServiceReqStatus, addLightBulbReplacementReq,addLightBulbReplacementReqAsDraft, tenantData, serviceType, setServiceRequestStateNull,termsAndCondition,requestId,serviceRequestByID,serviceReqDetails,updateLightDraftStatus,updateLightDraft }) => {
    let {reqId} = useParams()
    const navigate = useNavigate();
    const [lastUpdatedTime, setLastUpdatedTime] = useState('');
    const [lightCheckBox, setLighthCheckBox] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [details, setDetails] = useState({startDate:null,displayStartDate:null,
        startTime:Array.isArray(serviceReqDetails?.serviceRequestsArrayList) &&  serviceReqDetails.serviceRequestsArrayList[0].from_time,
        endTime:Array.isArray(serviceReqDetails?.serviceRequestsArrayList) &&  serviceReqDetails.serviceRequestsArrayList[0].to_time,
    });
    const [inputErrors, setInputErrors] = useState({});
    const [alertOpen, setAlertOpen] = useState(false);
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [hours,setHours]=useState([])
    const [endHours,setEndHours]=useState([])
    let terms_condition =termsAndCondition?.config_value &&  JSON.parse(termsAndCondition?.config_value);
    const [loaderStatus,setLoaderStatus] = useState(false)
    const [draftFiles,setDraftFiles] = useState([])
    const [draftDeletedFiles,setDraftDeletedFiles] = useState([])

    useEffect(() => {
        setLastUpdatedTime(getLastUpdatedTime())
        getLightStartHours(0)
        getLightEndHours(0)
    }, [])

    useEffect(()=>{
            getLevels()
        if(requestId!="null"){
            serviceRequestByID({service_requests_id:requestId})
        }
    },[])
    useEffect(()=>{
        if(serviceReqDetails !=null && serviceReqDetails?.serviceRequestsArrayList?.length> 0){
            let draftDetails = serviceReqDetails.serviceRequestsArrayList[0]
            let draftFiles = serviceReqDetails.service_request_files
             setDetails({
                ...details,
                startDate: draftDetails.serviceRequestDate != "NA" ? new Date(draftDetails.serviceRequestDate) : "",
                displayStartDate:draftDetails.from_date !="NA" ? draftDetails.from_date : "",
                startTime:draftDetails.from_time !="NA" ? draftDetails.from_time : "" ,
                endTime:draftDetails.to_time!="NA" ? draftDetails.to_time : "",
                workDetails:draftDetails.description !="NA" ? draftDetails.description : "",
                location:draftDetails.level_id!='NA' ? draftDetails.level_id : ''
            })
           if(draftFiles.length>0){
                let arr = []
                for(let i=0;i<draftFiles.length;i++){
                    arr.push({id:200+i,submitId:draftFiles[i].id,fileName:draftFiles[i].original_name,size:'',draftFile:true,
                    service_request_logs_id:draftFiles[i].service_requests_logs_id})
                }
                setDraftFiles([...arr])
                setSelectedFile([...arr])
           }
        }
    },[serviceReqDetails,levels])


    useEffect(() => {
        setLoaderStatus(false)
        // handleClose();
        if (addServiceReqStatus?.response_status == 1) {
            setSnackbarStatus({ message: addServiceReqStatus?.message, status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate('/service_request_list/0')
            }, 5000);
        } else if (addServiceReqStatus !== null) {
            setSnackbarStatus({ message: addServiceReqStatus?.message, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 5000);
        }
    }, [addServiceReqStatus])

    useEffect(()=>{
        setLoaderStatus(false)
        if(updateLightDraftStatus?.response_status==1){
            setSnackbarStatus({ message:updateLightDraftStatus?.message , status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate('/service_request_list/0')
            }, 5000);
        }else if(updateLightDraftStatus?.response_status==0){
            setSnackbarStatus({ message:updateLightDraftStatus?.message, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 5000);
        }
    },[updateLightDraftStatus])

    const getLightStartHours = (minTime)=>{
        let hours = [];
        for(let i=minTime;i<24;i++){
            for(let j=1;j<=2;j++){
                if(j%2==0){
                    hours.push(i<=9 ? `0${i}:30` : `${i}:30`)
                }else{
                    hours.push(i<=9 ? `0${i}:00` : `${i}:00`)
                }
            }
        }
        setHours([...hours])
    }

    const getLightEndHours = (minTime)=>{
        let timeIntrevels=[],startTime=minTime===0?0:parseInt(minTime.slice(0,2))+1;
        for(let i=startTime;i<24;i++){
           for(let j=1;j<=2;j++){
               if(j%2==0){
                timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
               }else{
                timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
               }
           }  
       };
       setEndHours([...timeIntrevels])
    }

    const removeSnackbar = () => {
        setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
    }

    const handleDetails = (e, datafrom) => {
        if (datafrom === "date") {
            if(e!=null){
                let date = `${e.getDate() > 9 ? e.getDate() : `0${e.getDate()}`}/${e.getMonth() > 9 ? e.getMonth() + 1 : `0${e.getMonth() + 1}`}/${e.getFullYear()}`
            let displayDate=`${e.getMonth() > 9 ? e.getMonth() + 1 : `0${e.getMonth() + 1}`}/${e.getDate() > 9 ? e.getDate() : `0${e.getDate()}`}/${e.getFullYear()}`
            setDetails({
                ...details,
                startDate: e,
                displayStartDate:displayDate
            })
            setInputErrors({
                ...inputErrors,
                startDate: ''
            })
            }else{
                setDetails({
                    ...details,
                    startDate: null,
                    displayStartDate:null
                })
            }
            
           
        }else{
            let { name, value } = e.target;
            setDetails({
                ...details,
                [name]: value
            })
            setInputErrors({
                ...inputErrors,
                [name]: ''
            })
            if(name === 'startTime' ){
                getLightEndHours(value)
            }
        }
    }

    const handleFiles = (files) => {
        if (files.length > 0) {
            setInputErrors((prev) => (
                {
                    ...prev,
                    ["file"]: ''
                }
            ))
            console.log("handleFiles ",files)
            setSelectedFile(files)
        }
    }

    const handleDeleteFiles = (id) => {
        let filter = selectedFile.filter((value) => {
            return value.id !== id
        })
        setSelectedFile(filter)

        let isDraftDeleted = draftFiles.filter((value)=>{
            return value.id == id
        })
        setDraftDeletedFiles([...draftDeletedFiles,...isDraftDeleted])
    }
    const handleUploadButton = (e) => {

        let target = e.target.files;
        let length = selectedFile.length;
        let files = selectedFile;
        console.log("ligh length ",length)
        for (let i = 0; i < target.length; i++) {
    
          files.push({ id: length, fileName: target[i].name, file: target[i], size: target[i].size, source: URL.createObjectURL(target[i]) })
          length += 1;
        }
        setSelectedFile([...files])
    
      }
console.log("selecte ",selectedFile)
    const validate = () => {
        let err = true;
        if (details.hasOwnProperty("workDetails") && details.workDetails) {
            err = true;
        } else {
            setInputErrors((prev) => (
                {
                    ...prev,
                    ["workDetails"]: 'Should not be empty'
                }
            ))
            err = false;
        }
        if (details.hasOwnProperty("location") && details.location) {
            err = true;
        } else {
            setInputErrors((prev) => (
                {
                    ...prev,
                    ["location"]: 'Select location'
                }
            ))
            err = false;
        }
        if (details.startDate!=null) {
            err = true;
        } else {
            setInputErrors((prev) => (
                {
                    ...prev,
                    ["startDate"]: 'Select Date'
                }
            ))
            err = false;
        }
        if (details.hasOwnProperty("startTime") && details.startTime) {
            err = true;
        } else {
            setInputErrors((prev) => (
                {
                    ...prev,
                    ["startTime"]: 'Select Start Time'
                }
            ))
            err = false;
        }
        if (details.hasOwnProperty("endTime") && details.endTime) {
            err = true;
        } else {
            setInputErrors((prev) => (
                {
                    ...prev,
                    ["endTime"]: 'Select End Time'
                }
            ))
            err = false;
        }
        // if (selectedFile.length > 0) {
        //     err = true
        // } else {
        //     setInputErrors((prev) => (
        //         {
        //             ...prev,
        //             ["file"]: 'Add any attachment'
        //         }
        //     ))
        //     err = false;
        // }
        return err
    }

    const handleContinue = () => {
        let valid = validate()
        if (valid) {
            setAlertOpen(true)
        }
    }
    const handleDataSubmission = () => {
        let arr = []
        let deletedFile = draftDeletedFiles.map((value)=>{
            arr.push(value.submitId)
            return value.submitId
        })
        let string = arr.toString()
        let submittingFile = selectedFile.filter((value)=>{
            return value.draftFile!=true
        })

        setLoaderStatus(true);
        handleClose()
        let data = new FormData();
        data.append("company_id", tenantData.companyId)
        //  data.append("contractor_phone_code",)
        //  data.append("contractor_phone_no")
        data.append("description", details.workDetails)
        data.append("from_date", getYYYYMMDDFormat(details.startDate))
        data.append("from_time", details.startTime)
        // data.append("from_date", getUTCDateFormat(details.startDate))
        // data.append("from_time", getUTCTimeFormat(details.startDate,details.startTime))
        //  data.append("office_phone_code")
        //  data.append("office_phone_code")
        //  data.append("person_in_charge")
        data.append("service_request_types_id", serviceType)
        data.append("to_time", details.endTime)
        // data.append("to_time", getUTCTimeFormat(details.startDate,details.endTime))
        data.append("status", reqId=='null' ? 'Submitted' : "submitted")
        data.append("to_date", "NA")
        data.append("level_id", details.location)
        data.append("file_count", submittingFile.length)
        if(reqId!='null'){
            data.append('service_requests_id',reqId)
            data.append('delete_file',deletedFile.length>0 ? string : "")
        }
        for (let i = 0; i < submittingFile.length; i++) {
            data.append(`file${i}`, submittingFile[i]?.file)
        }
        if(reqId=='null'){
             addLightBulbReplacementReq(data)
             setLoaderStatus(true);
             setTimeout(()=>{
                setLoaderStatus(false)
             },15000)
        }else{
            updateLightDraft(data)
            setLoaderStatus(true);
            setTimeout(()=>{
                setLoaderStatus(false)
             },15000)
        }
    }
    const handleDraft = ()=>{
        let arr = []
        let deletedFile = draftDeletedFiles.map((value)=>{
            arr.push(value.submitId)
            return value.submitId
        })
        let string = arr.toString()
        let submittingFile = selectedFile.filter((value)=>{
            return value.draftFile!=true
        })

        if(details.workDetails){
            let data = new FormData();
            data.append("company_id", tenantData.companyId)
            data.append("description", details.workDetails)
            data.append("from_date", details.startDate && getYYYYMMDDFormat(details.startDate))
            data.append("from_time", details.startTime)
            // data.append("from_date", details.startDate && getUTCDateFormat(details.startDate))
            // data.append("from_time",details.startDate && getUTCTimeFormat(details.startDate,details.startTime))
            data.append("service_request_types_id", serviceType)
            data.append("to_time", details.endTime)
            // data.append("to_time",details.startDate && getUTCTimeFormat(details.startDate,details.endTime))
            data.append("status", reqId=='null' ?  'Submitted' : 'draft')
            data.append("to_date", "NA")
            data.append("level_id", details.location && details.location)
            data.append("file_count", submittingFile.length)
            if(reqId!='null'){
                data.append('service_requests_id',reqId)
                data.append('delete_file',deletedFile.length>0 ? string : "")
            }
            for (let i = 0; i < submittingFile.length; i++) {
                data.append(`file${i}`, submittingFile[i]?.file)
            }
            if(reqId!='null'){
                updateLightDraft(data)
                setLoaderStatus(true);
                setTimeout(()=>{
                    setLoaderStatus(false)
                 },15000)
            }else{
                addLightBulbReplacementReqAsDraft(data)
                setLoaderStatus(true);
                setTimeout(()=>{
                    setLoaderStatus(false)
                 },15000)
            } 
            
        }else{
            setInputErrors((prevValue)=>({
                ...prevValue,
                workDetails:"Should not be empty"
            }))
        }
    }

    const handleClose = () => {
        setAlertOpen(false)
    }
    return (
        <>
            {loaderStatus && <Loader status={loaderStatus} />}
            {alertOpen && <Alert alertData={alertData} confirmFunc={handleDataSubmission} closeFunc={handleClose} />}
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
            <Box sx={{ px: 3 }}>
                <Grid container rowSpacing={4} columnSpacing={4}>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: "18px",fontWeight:550 }}>Preferred Work Timing</Typography>
                        <Typography sx={{ color: '#6F80A9', fontSize: "14px", mt: 1 }}>Please provide the date and time preferred for our technicians to assist with the light bulb replacement works and we will try to accommodate accordingly. </Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <StyledTypography>Start Date</StyledTypography>
                        <DesktopDatePicker
                            components={{
                                OpenPickerIcon:DatePickerIcon
                            }}
                            disablePast
                            onChange={(value) => handleDetails(value, 'date')}
                            // label="Date desktop"
                            inputFormat="dd/MM/yyyy"
                            value={details?.displayStartDate}
                            renderInput={(params) => <TextField onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />}
                        />
                        {inputErrors.startDate && <Typography sx={{ color: "#f44336" }}>{inputErrors.startDate}</Typography>}
                    </Grid>
                    <Grid item xs={4} >
                        <StyledTypography>Time (4 hours)</StyledTypography>
                        <Grid container>
                            <Grid item xs={6}>
                            <Select
                            name="startTime"
                             MenuProps={
                                MenuProps
                            } 
                            defaultValue={details.startTime}
                            sx={{height:"55px",width:100,color: '#0F2C6F'}} onChange={(e)=>handleDetails(e)} value={details.startTime} >
                               {hours.map((value,index)=>{
                                        return <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>              
                                    })}
                            </Select>       
                                
                                {inputErrors.startTime && <Typography sx={{ color: "#f44336" }}>{inputErrors.startTime}</Typography>}
                            </Grid>
                            <Grid item xs={1}>
                                
                            </Grid>
                            <Grid item xs={5}>
                            <Select  
                            name="endTime"
                            MenuProps={
                                MenuProps
                            } defaultValue={details.endTime} sx={{height:"55px",width:100, color: '#0F2C6F'}} onChange={(e)=>handleDetails(e)} value={details.endTime} >
                                {endHours.map((value,index)=>{
                                        return <MenuItem sx={{color:'#0F2C6F'}} key={index} value={value}>{value}</MenuItem>
                                            
                                    })}
                            </Select>
                                
                                {inputErrors.endTime && <Typography sx={{ color: "#f44336" }}>{inputErrors.endTime}</Typography>}
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <StyledTypography>Incident Location*</StyledTypography>
                            <Select value={Number(details.location) || ""} name="location" fullWidth sx={{ color: '#0F2C6F' }} onChange={(e) => handleDetails(e)}>                                
                               
                                {levels?.map((value) => (
                                    <MenuItem sx={{color:'#0F2C6F'}} value={value.level_id}>{value.level_name}</MenuItem>
                                ))}
                            </Select>
                        {inputErrors.location && <Typography sx={{ color: "#f44336" }}>{inputErrors.location}</Typography>}
                    </Grid>
                    <Grid item xs={8}></Grid>
                    <Grid item xs={12}>
                        <StyledTypography>Work Details </StyledTypography>
                        <OutlinedInput value={details.workDetails} autoComplete="off" fullWidth name="workDetails" onChange={(e) => handleDetails(e)} sx={{ fontSize: "14px", color: '#0F2C6F' }} />
                        {inputErrors.workDetails && <Typography sx={{ color: "#f44336" }}>{inputErrors.workDetails}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <StyledTypography>Supporting Materials</StyledTypography>
                        
                        {
                            selectedFile.length > 0  ?
                                <Box>
                                    {selectedFile.map((value) => (
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #DBDFE9', borderRadius: "8px", mt: 2, p: 2 }}>
                                            <Typography sx={{ color: " #0F2C6F", fontSize: '14px' }}>{value.fileName}
                                                <span style={{ color: "#9AA6C3", fontSize: '12px', marginLeft: '10px' }}>({Math.round(value.size / 1000)} of {Math.round(value.size / 1000)}KB)</span></Typography>
                                            <CloseIcon onClick={() => handleDeleteFiles(value.id)} />
                                        </Box>
                                    ))}
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button component='label' variant='outlined' sx={{ color: '#0F2C6F', mt: 2, textTransform: 'capitalize' }} endIcon={<Fileupload></Fileupload>}>Upload
                                            <input multiple type='file'  style={{ display: 'none' }} onChange={(e) => handleUploadButton(e)} />
                                        </Button>
                                    </Box>
                                </Box> : <DropZone getFiles={handleFiles} />
                        }
                        {inputErrors.file && <Typography sx={{ color: "#f44336" }}>{inputErrors.file}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{}}>
                            <StyledTypography >{terms_condition && terms_condition[0]?.title}</StyledTypography>
                            <Typography sx={{ color: '#6F80A9', fontSize: '14px', fontWeigth: 300, mt: 1 }}>{terms_condition && terms_condition[0]?.description}</Typography>
                            <Box sx={{ mt: 1 }}>
                            {terms_condition && terms_condition[1]?.rules?.map((value,index)=>(
                             <Typography sx={{ color: '#6F80A9', fontSize: '14px', fontWeight: 300, mt: 2,mb:1 }}>{`${index+1} . ${value[index+1]}`}</Typography>
                                 ))}
                            
                            </Box>
                            <Checkbox icon={<CheckboxIcon/>} checked={lightCheckBox} sx={{ color: '#6F80A9', borderRadius: '4px',ml:-1 }} onClick={() => setLighthCheckBox(!lightCheckBox)} />
                            <Typography sx={{ display: 'inline', color: '#0F2C6F', fontSize: '13px', fontWeight: 300 }}>
                            I have read and agree to comply with the above terms & conditions.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, backgroundColor: 'rgba(143, 146, 161,0.05)', py: 5 }}>
                <Box>
                    <RefreshIcon style={{ marginTop: '5px' }} />
                    <Typography sx={{ color: ' #9AA6C3', fontSize: '14px', display: 'inline', ml: 2 }}>Last Updated at {lastUpdatedTime}</Typography>
                </Box>
                <Box sx={{ p: 5, pt: 0,pr:0 }}>
                    <Button sx={{
                        color: '#0F2C6F',
                        height: '50px',
                        borderRadius: "8px",
                        backgroundColor: '#FFFFFF',
                        textTransform: 'capitalize',
                        border: "1px solid #AAC3FF",
                        '&:hover': { backgroundColor: '#FFFFFF' }
                    }}  onClick={handleDraft}
                        varian="outlined">Save As Draft</Button>
                    <Button variant='contained'
                        disabled={!lightCheckBox}
                        onClick={() => handleContinue()}
                        sx={{
                            backgroundColor: '#123FA9', color: '#FFFFFF', ml: 3, borderRadius: "8px", height: "50px",
                            textTransform: 'capitalize', '&:hover': { backgroundColor: '#123FA9' }
                        }}>
                        Continue</Button>
                </Box>
            </Box>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        levels: state.CommonReducer.tenantOccupiedLeves,
        addServiceReqStatus: state.Service_Req_Reducer.addServiceReqStatus,
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
        updateLightDraftStatus:state.Service_Req_Reducer.updateLightDraftStatus
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLevels: () => (dispatch(commonAction.getTenantAccessLevel())),
        addLightBulbReplacementReq: (data) => (dispatch(ServiceAction.addLightBulbReplacementReq(data))),
        addLightBulbReplacementReqAsDraft: (data) => (dispatch(ServiceAction.addLightBulbReplacementReqAsDraft(data))),
        setServiceRequestStateNull: () => (dispatch(ServiceAction.setServiceRequestStateNull())),
        serviceRequestByID: (data) => (dispatch(ServiceAction.serviceRequestDetailsById(data))),
        updateLightDraft:(data)=>(dispatch(ServiceAction.updateLightDraft(data)))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LightBulb)