import axios from "axios";
import ApiService from "../../../AxiosConfig/ApiService";
import { createAction } from "redux-actions";
export const updateState = createAction("UPDATE_SERVICE_REQUEST_STATE");
export const sessionExpired = createAction("SESSION_EXPIRED");
const isUnAuthorized = createAction("SERVICE_REQUEST_UNAUTHORIZED")

export const setServiceRequestStateNull = () => {
  return (dispatch) => {
    dispatch(updateState());
  }
};

export const ServiceRequestTypeInitiate = createAction("GET_SERVICE_REQ_TYPES_INITIATE");
export const ServiceRequestTypeSuccess = createAction("GET_SERVICE_REQ_TYPES_SUCCESS");
export const ServiceRequestTypeFailure = createAction("GET_SERVICE_REQ_TYPES_FAILURE");

export const getServiceRequestTypes = () => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(ServiceRequestTypeInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getAllServiceRequestTypes`,
        config
      )
      .then((response) => {

        if (response.data?.response_json?.response_status === 'success') {
          dispatch(ServiceRequestTypeSuccess(response?.data.response_json));
        } else {
          dispatch(ServiceRequestTypeFailure());

        }

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(ServiceRequestTypeFailure());
        }
      });
  };
};

export const getMiscReqTypeInitiate = createAction("GET_MISC_REQ_TYPE_INITIATE")
export const getMiscReqTypeSuccess = createAction("GET_MISC_REQ_TYPE_SUCCESS")
export const getMiscReqTypeFailure = createAction("GET_MISC_REQ_TYPE_FAILURE")

export const getMiscReqType = ()=>{
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch)=>{
    dispatch(getMiscReqTypeInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getMiscellaneousRequestTypes`,null,config)
    .then((response)=>{
      if(response.data.response_json.service_typeArrayList){
        dispatch(getMiscReqTypeSuccess(response.data.response_json.service_typeArrayList))
      }else{
        dispatch(getMiscReqTypeFailure())
      }
    })
    .catch((err)=>{
      if(err?.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getMiscReqTypeFailure())
      }
    })
  }
}

export const getServiceRequestInitiate = createAction("GET_SERVICE_REQ_LIST_INITIATE");
export const getServiceRequestSuccess = createAction("GET_SERVICE_REQ_LIST_SUCCESS");
export const getServiceRequestFailure = createAction("GET_SERVICE_REQ_LIST_FAILURE");

export const getServiceRequestsList = (data) => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getServiceRequestInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getServiceRequestsForWeb`,
        data,
        config
      )
      .then((response) => {

        if (response.data?.response_status === 'success') {
          dispatch(getServiceRequestSuccess(response?.data));
        } else {
          dispatch(getServiceRequestFailure());

        }

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(getServiceRequestFailure());
        }
      });
  };
};
export const serviceRequestByIDInitiate = createAction("SERVICE_REQ_BYID_INITIATE");
export const serviceRequestByIDSuccess = createAction("SERVICE_REQ_BYID_SUCCESS");
export const serviceRequestByIDFailure = createAction("SERVICE_REQ_BYID_FAILURE");

export const serviceRequestDetailsById = (data) => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(serviceRequestByIDInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getServiceRequestsById`,
        data,
        config
      )
      .then((response) => {
        if (response.data?.message === 'Data found') {
          dispatch(serviceRequestByIDSuccess(response.data?.data_array));
        } else {
          dispatch(serviceRequestByIDFailure('Failed'));
          dispatch(isUnAuthorized(response.data.message))
        }

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(serviceRequestByIDFailure('Failed'));
        }
      });
  };
};

export const updateServiceRequestStatusInitiate = createAction("UPDATE_SERVICE_REQ_STATUS_INITIATE");
export const UpdateServiceRequestStatusSuccess = createAction("UPDATE_SERVICE_REQ_STATUS_SUCCESS");
export const updateServiceRequestStatusFailure = createAction("UPDATE_SERVICE_REQ_STATUS_FAILURE");

export const updateServiceRequestStatus = (data) => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateServiceRequestStatusInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/updateServiceRequestStatus`,
        data,
        config
      )
      .then((response) => {
        if (response.data) {
          dispatch(UpdateServiceRequestStatusSuccess(response.data));
        } 

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(updateServiceRequestStatusFailure(err.response.data.message));
        }
      });
  };
};

export const updateQuotationToTenant = (data) => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateServiceRequestStatusInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/updateQuotationToTenant`,
        data,
        config
      )
      .then((response) => {
        if (response.data) {
          dispatch(UpdateServiceRequestStatusSuccess(response.data));
        } 

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(updateServiceRequestStatusFailure('Failed'));
        }
      });
  };
};

export const getCargoLiftObjectiveInitiate = createAction("GET_CARGOLIFT_OBJECTIVE_INITATE");
export const getCargoLiftObjectiveSuccess = createAction("GET_CARGOLIFT_OBJECTIVE_SUCCESS");
export const getCargoLiftObjectiveFailure = createAction("GET_CARGOLIFT_OBJECTIVE_FAILURE")

export const getCargoLiftObjective = () => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(getCargoLiftObjectiveInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getAllCargoObjectives`,null,
        config
      )
      .then((response) => {
        if (response.data.response_json.cargoObjectivesArrayList) {
          dispatch(getCargoLiftObjectiveSuccess(response.data?.response_json.cargoObjectivesArrayList ));
        } else {
          dispatch(getCargoLiftObjectiveFailure('Failed'));

        }

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(getCargoLiftObjectiveFailure('Failed'));
        }
      });
  };
};

export const getNationalityListInitiate = createAction("GET_NATIONALITYLIST_INITIATE");
export const getNationalityListSuccess = createAction("GET_NATIONALITYLIST_SUCCESS");
export const getNationalityListFailure = createAction("GET_NATIONALITYLIST_FAILURE");

export const getNationality = () => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(getNationalityListInitiate())
    axios.get(`${process.env.REACT_APP_API_URL}/getAllNationality`, config)
      .then((response) => {
        if (response.data.response_json) {
          dispatch(getNationalityListSuccess(response.data.response_json))
        } else {
          dispatch(getNationalityListFailure())
        }
      })
      .catch((err) => {
        if (err?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(getNationalityListFailure())
        }
      })
  }
}

export const generateAirConBillInitiate = createAction("GENERATE_AIR_CON_BILL_INITIATE");
export const generateAirConBillSuccess = createAction("GENERATE_AIR_CON_BILL_SUCCESS");
export const generateAirConBillFailure = createAction("GENERATE_AIR_CON_BILL_FAILURE");

export const generateBillValue = (data) => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(generateAirConBillInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/addServiceRequest`,
        data,
        config
      )
      .then((response) => {

        if (response.data?.message === 'Service request submitted to BMS successfully') {
          dispatch(generateAirConBillSuccess('Success'));
        } else {
          dispatch(generateAirConBillFailure('Failed'));

        }

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(generateAirConBillFailure('Faild'));
        }
      });
  };
}


export const addServiceRequestInitiate = createAction("ADD_SERVICE_REQ_INITIATE");
export const addServiceRequestSuccess = createAction("ADD_SERVICE_REQ_SUCCESS");
export const addServiceRequestFailure = createAction("ADD_SERVICE_REQ_FAILURE");

export const createCargoLiftRequest = (data) => {
  let token = localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(addServiceRequestInitiate());

    axios
      .post( 
        `${process.env.REACT_APP_API_URL}/addCargoLiftRequest`,
        data,
        config
      )
      .then((response) => {

        if (response.data?.response_status === 1) {
          dispatch(addServiceRequestSuccess(response.data));
        } else {
          dispatch(addServiceRequestFailure(response.data));

        }

      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'));
        } else {
          dispatch(addServiceRequestFailure(err?.response));
        }
      });
  };
};

export const createCargoLiftReqAsDraft = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addCargoLiftRequestAsDraft`, data, config)
      .then((response) => {
        if (response.data?.response_status === 1) {
          dispatch(addServiceRequestSuccess(response.data))
        }
        else {
          dispatch(addServiceRequestFailure(response.data))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure(err?.response))
        }
      })
  }
}

export const addSecurityClearanceReq = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addSecurityClearanceRequest`, data, config)
      .then((response) => {
        if (response.data?.response_status === 1) {
          dispatch(addServiceRequestSuccess(response.data))
        } else {
          dispatch(addServiceRequestFailure(response.data))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure(err?.response))
        }
      })
  }
}

export const addSecurityClearanceReqAsDraft = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addSecurityClearanceRequestAsDraft`, data, config)
      .then((response) => {
        if (response.data?.response_status === 1) {
          dispatch(addServiceRequestSuccess(response.data))
        } else {
          dispatch(addServiceRequestFailure(response.data))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure(err?.response))
        }
      })
  }
}
export const addLightBulbReplacementReq = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addLightBulbReplacementRequest`, data, config)
      .then((response) => {
        if (response.data.response_status === 1) {
          dispatch(addServiceRequestSuccess(response.data))
        } else {
          dispatch(addServiceRequestFailure(response.data))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure(err?.response))
        }
      })
  }
}

export const addLightBulbReplacementReqAsDraft = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addLightBulbReplacementRequestAsDraft`, data, config)
      .then((response) => {
        if (response.data.response_status === 1) {
          dispatch(addServiceRequestSuccess(response.data))
        } else {
          dispatch(addServiceRequestFailure(response.data))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure(err?.response))
        }
      })
  }
}


export const addMiscelleaneousAdhocRequest = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addMiscellaniousAdhocRequest`,data,config)
    .then((response)=>{
      if(response.data.response_status === 1){
        dispatch(addServiceRequestSuccess(response.data))
      }else{
        dispatch(addServiceRequestFailure(response.data))
      }
    })
    .catch((err)=>{
      if(err.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(addServiceRequestFailure(err?.response))
      }
    })
  }
}

export const addMiscelleaneousAdhocRequestAsDraft = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers:{
      Authorization:`Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addMiscellaniousAdhocRequestAsDraft`,data,config)
    .then((response)=>{
      if(response.data.response_status === 1){
        dispatch(addServiceRequestSuccess(response.data))
      }else{
        dispatch(addServiceRequestFailure(response.data))
      }
    })
    .catch((err)=>{
      if(err.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(addServiceRequestFailure(err?.response))
      }
    })
  }
}

export const createAirConRequest = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addAirConExtensionRequest`, data, config)
      .then((response) => {
        if (response.data.message === 'Service request submitted to BMS successfully') {
          dispatch(addServiceRequestSuccess('Success'))
        } else {
          dispatch(addServiceRequestFailure('Failed'))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure('Failed'))
        }
      })
  }
}

export const createAirConRequestAsDraft = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(addServiceRequestInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addAirconExtensionRequestAsDraft`, data, config)
      .then((response) => {
        if (response.data.message === 'Service request saved as draft successfully') {
          dispatch(addServiceRequestSuccess('Success'))
        } else {
          dispatch(addServiceRequestFailure('Failed'))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(addServiceRequestFailure('Failed'))
        }
      })
  }
}

export const updateQuotationInitiate = createAction("UPDATE_QUOTATION_INITIATE");
export const updateQuotationSuccess = createAction("UPDATE_QUOTATION_SUCCESS");
export const updateQuotationFailure = createAction("UPDATE_QUOTATION_FAILURE");

export const updateQuotation = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return ( dispatch)=>{
    dispatch(updateQuotationInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateLightBulbQuotation`,data,config)
    .then((response)=>{
      if(response?.data?.message==='Quotation Updated'){
        dispatch(updateQuotationSuccess('Success'))
      }else{
        dispatch(updateQuotationFailure("Failed"));
      }
    })
    .catch((err)=>{ 
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(updateQuotationFailure("Failed"))
      }
    })
  }
}

export const getProductsInitiate = createAction("GET_PRODUCTS_INITIATE");
export const getProductsSuccess = createAction("GET_PRODUCTS_SUCCESS");
export const getProductsFailure = createAction("GET_PRODUCTS_FAILURE");

export const getServiceAccesscoriesData = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return ( dispatch)=>{
    dispatch(getProductsInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllLightBulbProducts`,data,config)
    .then((response)=>{
      if(response?.data?.response_status==='success'){
        dispatch(getProductsSuccess(response?.data))
      }else{
        dispatch(getProductsFailure("Failed"));
      }
    })
    .catch((err)=>{ 
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(getProductsFailure("Failed"))
      }
    })
  }
}

export const getFaultCategoryInitiate = createAction("GET_FAULTCATEGORY_INITIATE");
export const getFaultCategorySuccess = createAction("GET_FAULTCATEGORY_SUCCESS");
export const getFaultCategoryFailure = createAction("GET_FAULTCATEGORY_FAILURE");

export const getFaultCategoryData = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return ( dispatch)=>{
    dispatch(getFaultCategoryInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getAllFaultCategory`,data,config)
    .then((response)=>{
      console.log('res ',response?.data)
      if(response?.data?.response_status=== 1){
        dispatch(getFaultCategorySuccess(response?.data))
      }else{
        dispatch(getFaultCategoryFailure("Failed"));
      }
    })
    .catch((err)=>{ 
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(getFaultCategoryFailure("Failed"))
      }
    })
  }
}

export const getMiscelleaneousFormInitiate = createAction("GET_MISCELLEANEOUS_FORM_PDF_INITIATE");
export const getMiscelleaneousFormSuccess = createAction("GET_MISCELLEANEOUS_FORM_PDF_SUCCESS");
export const getMiscelleaneousFormFailure = createAction("GET_MISCELLEANEOUS_FORM_PDF_FAILURE");

export const getMiscelleaneousForm = ()=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(getMiscelleaneousFormInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getMiscellaneousFormPdf`,token,config)
    .then((response)=>{
      if(response.data.response_json.service_typeArrayList){
        dispatch(getMiscelleaneousFormSuccess(response.data.response_json.service_typeArrayList))
      }else{
        dispatch(getMiscelleaneousFormFailure("Failed"))
      }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(getMiscelleaneousFormFailure("Failed"))
      }
    })
  }
}

export const getPublicHolidayInitiate = createAction("GET_PUBLICHOLIDAY_INITIATE")
export const getPublicHolidaySuccess = createAction("GET_PUBLICHOLIDAY_SUCCESS")
export const getPublicHolidayFailure = createAction("GET_PUBLICHOLIDAY_FAILURE")

export const getPublicHoliday = ()=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(getPublicHolidayInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getPublicHolidays`,token,config) 
    .then((response)=>{
      if(response.data.response_json){
        dispatch(getPublicHolidaySuccess(response.data.response_json))
      }else{
        dispatch(getPublicHolidayFailure("Failed"))
      }
    })
    .catch((err)=>{
      if(err?.response?.status===401){
        dispatch(sessionExpired('Session Expired'))
      }else{
        dispatch(getPublicHolidayFailure("Failed"))
      }
    })
  }
}

export const updateWatchStatusInitiate = createAction("UPDATE_SERVICEWATCHSTATUS_INITIATE")
export const updateWatchStatusSuccess = createAction("UPDATE_SERVICEWATCHSTATUS_SUCCESS")
export const updateWatchStatusFailure = createAction("UPDATE_SERVICEWATCHSTATUS_FAILURE")

export const updateWatchStatus = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(updateWatchStatusInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateServiceRequestWatch`,data,config)
    .then((response)=>{
      if(response.data.error_json){
        dispatch(updateWatchStatusFailure(response.data.error_json.watch_status[0]))
      }

      if(response.data.response_json.response_status==1){
        dispatch(updateWatchStatusSuccess(response.data.response_json.message));
      }
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(updateWatchStatusFailure())
      }
    })
  }
  
}

export const getServiceFilterInitiate = createAction("GET_SERVICEFILTER_INITIATE")
export const getServiceFilterSuccess = createAction("GET_SERVICEFILTER_SUCCESS")
export const getServiceFilterFailure = createAction("GET_SERVICEFILTER_FAILURE")

export const getServiceFilter = ()=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(updateWatchStatusInitiate())
    axios.get(`${process.env.REACT_APP_API_URL}/getAllServiceRequestTypesForFilter`,config)
    .then((response)=>{
      if(response.data.error_json){
        dispatch(getServiceFilterFailure(response.data.error_json.watch_status[0]))
      }

      if(response.data.response_json.response_status=="success"){
        dispatch(getServiceFilterSuccess(response.data.response_json.serviceTypeArrayList));
      }
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getServiceFilterFailure())
      }
    })
  }
  
}

export const getServiceActiveStatusInitiate = createAction("GET_SERVICEACTIVEFILTERSTATUS_INITIATE")
export const getServiceActiveStatusSuccess = createAction("GET_SERVICEACTIVEFILTERSTATUS_SUCCESS")
export const getServiceActiveStatusFailure = createAction("GET_SERVICEACTIVEFILTERSTATUS_FAILURE")

export const getServiceActiveStatus = ()=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getServiceActiveStatusInitiate())
    axios.get(`${process.env.REACT_APP_API_URL}/getServiceRequestFilterStatusForActiveTab`,config)
    .then((response)=>{
      if(response.data.error_json){
        dispatch(getServiceActiveStatusFailure())
      }

      if(response.data.response_json.response_status=="success"){
        dispatch(getServiceActiveStatusSuccess(response.data.response_json.faultStatusArrayList));
      }
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getServiceActiveStatusFailure())
      }
    })
  }
  
}

export const getServiceInActiveStatusInitiate = createAction("GET_SERVICEINACTIVEFILTERSTATUS_INITIATE")
export const getServiceInActiveStatusSuccess = createAction("GET_SERVICEINACTIVEFILTERSTATUS_SUCCESS")
export const getServiceInActiveStatusFailure = createAction("GET_SERVICEINACTIVEFILTERSTATUS_FAILURE")

export const getServiceInActiveStatus = ()=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return(dispatch)=>{
    dispatch(getServiceInActiveStatusInitiate())
    axios.get(`${process.env.REACT_APP_API_URL}/getServiceRequestFilterStatusForClosedTab`,config)
    .then((response)=>{
      if(response.data.error_json){
        dispatch(getServiceInActiveStatusFailure())
      }

      if(response.data.response_json.response_status=="success"){
        dispatch(getServiceInActiveStatusSuccess(response.data.response_json.faultStatusArrayList));
      }
    })
    .catch((error)=>{
      if(error.response.status===401){
        dispatch(sessionExpired('Session Expired'));
      }else{
        dispatch(getServiceInActiveStatusFailure())
      }
    })
  }
  
}

const getAirConCalcInitiate = createAction("GET_AIRCON_CALC_INITIATE")
const getAirConCalcSuccess = createAction("GET_AIRCON_CALC_SUCCESS")
const getAirConCalcFailure = createAction("GET_AIRCON_CALC_FAILURE")

export const getAirConCalc = (data)=>{
  let token=localStorage.getItem('userToken')
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
    return(dispatch)=>{
        dispatch(getAirConCalcInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/calculateAirconCharges`,data,config)
        .then((response)=>{
          if(response.data.response_status==1){
             dispatch(getAirConCalcSuccess(response.data.data_array))
          }
        })
        .catch((error)=>{
          if(error.response.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
            dispatch(getAirConCalcFailure())
          }
        })
    }
}

const saveSecurityInitiate = createAction("SAVE_SECURITY_DETAILS_INITIATE")
const saveSecuritySuccess= createAction("SAVE_SECURITY_DETAILS_SUCCESS")
const saveSecurityFailure = createAction("SAVE_SECURITY_DETAILS_FAILURE")

export const saveSecurityDetails = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(saveSecurityInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/saveSecurityClearancePdf`, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(saveSecuritySuccess(response.data))
        } else {
          dispatch(saveSecurityFailure('Failed'))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(saveSecurityFailure('Failed'))
        }
      })
  }
}

export const saveSecurityDetailsDraft = (data) => {
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(saveSecurityInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/saveSecurityClearanceDraftPdf`, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(saveSecuritySuccess(response.data))
        } else {
          dispatch(saveSecurityFailure('Failed'))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(saveSecurityFailure('Failed'))
        }
      })
  }
}

const downloadSecurityPdfInitiate = createAction("DOWNLOAD_SECURITY_PDF_INITIATE")  
const downloadSecurityPdfSuccess = createAction("DOWNLOAD_SECURITY_PDF_SUCCESS")  
const downloadSecurityPdfFailure = createAction("DOWNLOAD_SECURITY_PDF_FAILURE")  

export const downloadSecurityPdf = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(downloadSecurityPdfInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/downloadPDF`, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(downloadSecurityPdfSuccess(response.data))
        } else {
          dispatch(downloadSecurityPdfFailure('Failed'))
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(downloadSecurityPdfFailure('Failed'))
        }
      })
  }
}

const UpdateMiscDraftInitiate = createAction("UPDATE_MISC_DRAFT_INITIATE")
const UpdateMiscDraftSuccess = createAction("UPDATE_MISC_DRAFT_SUCCESS")
const UpdateMiscDraftFailure = createAction("UPDATE_MISC_DRAFT_FAILURE")

export const updateMiscDraft = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(UpdateMiscDraftInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateMiscellaniousAdhocRequestAsDraft `, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(UpdateMiscDraftSuccess(response.data))
        } 
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(UpdateMiscDraftFailure('Failed'))
        }
      })
  }
}

const updateLightDraftInitiate  = createAction("UPDATE_LIGHT_DRAFT_INITIATE")
const updateLightDraftSuccess  = createAction("UPDATE_LIGHT_DRAFT_SUCCESS")
const updateLightDraftFailure = createAction("UPDATE_LIGHT_DRAFT_FAILURE")

export const updateLightDraft = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(updateLightDraftInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateLightBulbReplacementRequestAsDraft`, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(updateLightDraftSuccess(response.data))
        } 
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(updateLightDraftFailure('Failed'))
        }
      })
  }
}

const updateLiftDraftInitiate = createAction("UPDATE_LIFT_DRAFT_INITIATE")
const updateLiftDraftSuccess = createAction("UPDATE_LIFT_DRAFT_SUCCESS")
const updateLiftDraftFailure = createAction("UPDATE_LIFT_DRAFT_FAILURE")

export const updateLiftDraft = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(updateLiftDraftInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateCargoLiftRequestAsDraft `, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(updateLiftDraftSuccess(response.data))
        } 
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(updateLiftDraftFailure('Failed'))
        }
      })
  }
}

const updateSecurityDraftInitiate = createAction("UPDATE_SECURITY_DRAFT_INITIATE")
const updateSecurityDraftSuccess= createAction("UPDATE_SECURITY_DRAFT_SUCCESS")
const updateSecurityDraftFailure = createAction("UPDATE_SECURITY_DRAFT_FAILURE")

export const updateSecurityDraft = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return (dispatch) => {
    dispatch(updateSecurityDraftInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateSecurityClearanceRequestAsDraft `, data, config)
      .then((response) => {
        if (response.data) {
          dispatch(updateSecurityDraftSuccess(response.data))
        } 
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired('Session Expired'))
        } else {
          dispatch(updateSecurityDraftFailure('Failed'))
        }
      })
  }
}

const addLightproductInitiate = createAction("ADD_LIGHTPRODUCT_INITIATE")
const addLightproductSuccess = createAction("ADD_LIGHTPRODUCT_SUCCESS")
const addLightproductFailure = createAction("ADD_LIGHTPRODUCT_FAILURE")

export const addLightProduct = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(addLightproductInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addLightBulbProducts `, data, config)
    .then((response)=>{
      if(response.data){
        dispatch(addLightproductSuccess(response.data))
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(addLightproductFailure())
      }
    })
  }
}

const editLightproductInitiate = createAction("EDIT_LIGHTPRODUCT_INITIATE")
const editLightproductSuccess = createAction("EDIT_LIGHTPRODUCT_SUCCESS")
const editLightproductFailure = createAction("EDIT_LIGHTPRODUCT_FAILURE")

export const editLightProduct = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(editLightproductInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/editLightBulbProducts `, data, config)
    .then((response)=>{
      if(response.data){
        dispatch(addLightproductSuccess(response.data))
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(editLightproductFailure())
      }
    })
  }
}

const editFaultCategoryInitiate = createAction("EDIT_FAULTCATEGORY_INITIATE")
const editFaultCategorySuccess = createAction("EDIT_FAULTCATEGORY_SUCCESS")
const editFaultCategoryFailure = createAction("EDIT_FAULTCATEGORY_FAILURE")

export const editFaultCategory = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(editFaultCategoryInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/updateFaultCategory `, data, config)
    .then((response)=>{
      console.log('pan ',response?.data)
      if(response.status == 200){
        dispatch(editFaultCategorySuccess(response.data))
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(editFaultCategoryFailure())
      }
    })
  }
}

const deleteLightproductInitiate = createAction("DELETE_LIGHTPRODUCT_INITIATE")
const deleteLightproductSuccess = createAction("DELETE_LIGHTPRODUCT_SUCCESS")
const deleteLightproductFailure = createAction("DELETE_LIGHTPRODUCT_FAILURE")

export const deleteLightProduct = (data)=>{
  console.log('back')
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(deleteLightproductInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/deleteLightBulbProducts `, data, config)
    .then((response)=>{
      if(response.data){
        dispatch(addLightproductSuccess(response.data))
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(deleteLightproductFailure())
      }
    })
  }
}

const getLightproductByIdInitiate = createAction("GET_LIGHTPRODUCTBYID_INITIATE")
const getLightproductByIdSuccess = createAction("GET_LIGHTPRODUCTBYID_SUCCESS")
const getLightproductByIdFailure = createAction("GET_LIGHTPRODUCTBYID_FAILURE")

export const getLightProductById = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(getLightproductByIdInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getLightBulbProductsById `, data, config)
    .then((response)=>{
      if(response.data.response_status=='success'){
        dispatch(getLightproductByIdSuccess(response.data.lightBulbProductsArrayList))
      }else{
        dispatch(getLightproductByIdFailure())
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(getLightproductByIdFailure())
      }
    })
  }
}

export const getLightBulbServiceChargeInitiate = createAction("GET_LIGHT_BULB_SERVICE_CHARGE_INITIATE")
export const getLightBulbServiceChargeSuccess = createAction("GET_LIGHT_BULB_SERVICE_CHARGE_SUCCESS")
export const getLightBulbServiceChargeFailure = createAction("GET_LIGHT_BULB_SERVICE_CHARGE_FAILURE")
export const getLightBulbServiceChargeNull = createAction('GET_LIGHT_BULB_SERVICE_CHARGE_NULL');

export const getLightBulbServiceChargeNullAction = () => dispatch => dispatch(getLightBulbServiceChargeNull());

export const getLightBulbServiceChargeAction = (params) => async (dispatch) => {
    dispatch(getLightBulbServiceChargeInitiate());
    
    try {
        const response = await ApiService().post('/getLightBulbServiceCharge',params);
         if(response.data?.response_status === 1){
            console.log(response)
            dispatch(getLightBulbServiceChargeSuccess(response.data));
        }else{
            dispatch(getLightBulbServiceChargeFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(getLightBulbServiceChargeFailure(error.response.data));
            }
    }

  }

  const addFaultCategoryInitiate = createAction("ADD_FAULTCATEGORY_INITIATE")
const addFaultCategorySuccess = createAction("ADD_FAULTCATEGORY_SUCCESS")
const addFaultCategoryFailure = createAction("ADD_FAULTCATEGORY_FAILURE")

export const addFaultCategory = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(addFaultCategoryInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/addFaultCategory `, data, config)
    .then((response)=>{
      if(response.data){
        dispatch(addFaultCategorySuccess(response.data))
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(addFaultCategoryFailure())
      }
    })
  }
}

const geFaultCategoryByIdInitiate = createAction("GET_FAULTCATEGORYBYID_INITIATE")
const geFaultCategoryByIdSuccess = createAction("GET_FAULTCATEGORYBYID_SUCCESS")
const geFaultCategoryByIdFailure = createAction("GET_FAULTCATEGORYBYID_FAILURE")

export const geFaultCategoryById = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(geFaultCategoryByIdInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/getFaultCategory `, data, config)
    .then((response)=>{
      console.log('resposn ',response)
      if(response.data.response_status==1){
  
        dispatch(geFaultCategoryByIdSuccess(response.data.data_array))
      }else{
        dispatch(geFaultCategoryByIdFailure())
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(geFaultCategoryByIdFailure())
      }
    })
  }
}

const deleteFaultCategoryInitiate = createAction("DELETE_FAULTCATEGORY_INITIATE")
const deleteFaultCategorySuccess = createAction("DELETE_FAULTCATEGORY_SUCCESS")
const deleteFaultCategoryFailure = createAction("DELETE_FAULTCATEGORY_FAILURE")

export const deleteFaultCategory = (data)=>{
  let token = localStorage.getItem("userToken")
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  return(dispatch)=>{
    dispatch(deleteFaultCategoryInitiate())
    axios.post(`${process.env.REACT_APP_API_URL}/deleteFaultCategory `, data, config)
    .then((response)=>{
      if(response.data){
        dispatch(deleteFaultCategorySuccess(response.data))
      }
    })
    .catch((err)=>{
      if (err?.response?.status === 401) {
        dispatch(sessionExpired('Session Expired'))
      } else {
        dispatch(deleteFaultCategoryFailure())
      }
    })
  }
}

const getConfigByIdInitiate = createAction("GET_CONFIGBYID_INITIATE")
const getConfigByIdSuccess = createAction("GET_CONFIGBYID_SUCCESS")
const getConfigByIdFailure = createAction("GET_CONFIGBYID_FAILURE")
export const getConfigByIdNull = createAction('GET_CONFIGBYID_NULL');

export const getConfigByIdNullAction = () => dispatch => dispatch(getConfigByIdNull())  

export const getConfigById = (params) => {
  return async (dispatch) => {
       dispatch(getConfigByIdInitiate());
      try {
          const response = await ApiService().post('/getConfigValues',params);
          console.log(response);
          if(response.data?.response_status === 1){
               dispatch(getConfigByIdSuccess(response.data))
              }else{
              dispatch(getConfigByIdFailure(response.data))

              }
          } catch (error) {
              if(error.status===401){
                  dispatch(sessionExpired('Session Expired'));
                }else{
                    dispatch(getConfigByIdFailure(error.response.data))
                  }
       }
  }
}

const updateConfigInitiate = createAction("UPDATE_CONFIG_INITIATE")
const updateConfigSuccess = createAction("UPDATE_CONFIG_SUCCESS")
const updateConfigFailure = createAction("UPDATE_CONFIG_FAILURE")
export const updateConfigNull = createAction('UPDATE_CONFIG_NULL');

export const updateConfigNullAction = () => dispatch => dispatch(updateConfigNull())  

export const updateConfig = (params) => {
  return async (dispatch) => {
       dispatch(updateConfigInitiate());
      try {
          const response = await ApiService().post('/updateConfigValues',params);
          console.log(response);
          if(response.data?.response_status === 1){
               dispatch(updateConfigSuccess(response.data))
              }else{
              dispatch(updateConfigFailure(response.data))

              }
          } catch (error) {
              if(error.status===401){
                  dispatch(sessionExpired('Session Expired'));
                }else{
                    dispatch(updateConfigFailure(error.response.data))
                  }
       }
  }
}

const getServiceChargeInitiate = createAction("GET_SERVICE_CHARGE_INITIATE")
const getServiceChargeSuccess = createAction("GET_SERVICE_CHARGE_SUCCESS")
const getServiceChargeFailure = createAction("GET_SERVICE_CHARGE_FAILURE")
export const getServiceChargeNull = createAction('GET_SERVICE_CHARGE_NULL');

export const getServiceChargeNullAction = () => dispatch => dispatch(getServiceChargeNull())  

export const getServiceCharge = (params) => {
  return async (dispatch) => {
       dispatch(getServiceChargeInitiate());
      try {
          const response = await ApiService().post('/getServiceChargeList',params);
          console.log(response);
          if(response.data?.response_status === 1){
               dispatch(getServiceChargeSuccess(response.data))
              }else{
              dispatch(getServiceChargeFailure(response.data))

              }
          } catch (error) {
              if(error.status===401){
                  dispatch(sessionExpired('Session Expired'));
                }else{
                    dispatch(getServiceChargeFailure(error.response.data))
                  }
       }
  }
}