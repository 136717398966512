import React, {useEffect, useState, useRef} from 'react'
import { Box, Button, Typography, FormControl, InputLabel, Menu, MenuItem, Checkbox, Input, Fade,
  FormControlLabel, Grid, IconButton, CircularProgress, Pagination, Paper, Popper,  Table, TextField, TableHead, TableBody, DialogTitle, DialogContent, 
  Select } from '@mui/material'
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import { DatePicker } from '@mui/x-date-pickers';
import {ReactComponent as DownArrow } from "../../Assets/icons/DropDownArrow.svg";
import { preventDatePickerKeyboard } from '../../Utils/utils';
import { StyledTableCell, StyledTableRow } from '../ManageTenant/ActiveTenant';
import {ReactComponent as SearchIcon} from '../../Assets/icons/SearchIcon.svg';
import {ReactComponent as NoDataIcon} from '../../Assets/icons/NoDataIcon.svg';
import {ACTIVE_COLUMNS, ARCHIVED_COLUMNS, DRAFT_COLUMNS, SCHEDULE_COLUMNS} from './SustainableTableColumns';
import { getSustainableListAction, getSustainableListNullAction, InactiveSustainableEvent, InactivesustainableStateNull } from '../../redux/actions/SustainabilityAction';
import { ReactComponent as CheckboxIcon } from "../../Assets/icons/CheckboxIcon.svg";
import { ReactComponent as MoreIcons } from '../../Assets/icons/more-alt.svg';
import { connect } from 'react-redux';
import { BootstrapDialog } from '../FacilityBooking/SingleRoomFeature';
import { getYYYYMMDDFormat } from '../../Utils/utils';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import ToastAlert from '../common/Alert';
import { useDebounce } from "use-debounce";
import { makeStyles } from '@mui/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  customDropdown: {
    position: 'absolute',
    width:'100px',
    zIndex: 1000,
    backgroundColor: 'white',
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
}));

const SustainabiltyTabs = ({tabName, setValue, getSustainableList, getSustainableListNull,inactiveSustainableNull, getSustainableListData, sustainableListCount, inactiveSustainable, eventAndPromotionsInactiveStatus}) => {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterData, setFilterData] = useState({ date: null, search: ''})
    const [debouncedText] = useDebounce(filterData.search, 1000);
    const [sustainableData, setSustainableData] = useState([]);
    const [menuDropdown, setmenuDropdown] = React.useState(null);
    const [selectedId, setSelectedId] = React.useState(null);
    const [currentPage,setCurrentPage] = useState(1)
    const [menuText,setMenuText] = useState('In-active')
    const [open, setOpen] = React.useState(false);
    const [snackbarStatus, setSnackbarStatus] = React.useState({ message: '', status: false, color: '', icon: '' }); 
    const [open1, setOpen1] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const optionsOpen = Boolean(anchorEl);
    const navigate = useNavigate()

    useEffect(() => {
      setIsLoading(true)
      const currenTabname = {'Active':0,'Schedule':1,'Archived':2,'Draft':3}
      console.log('currenTabname ',currenTabname[tabName])
      localStorage.setItem('tab',currenTabname[tabName]);
    },[tabName])
  
    const handleClose = () => {
      setOpen(false)
    };




    const handleClick2 = (event, id,name, currentTarget) => {
    // setOptionsOpen(true);
    setAnchorEl(currentTarget);
    setSelectedId(id);
     setMenuText(name)
   };


    const handleFilterDatas = (value, name) => {
      setFilterData({ ...filterData, [name]: value })
    }

    const handleOpen = () => {
      setOpen(true)
    };

    const MakeInactive = () => {
      handleStatusInactive();
      setOpen(false);
    }
    
    const debounceDidMount = useRef(false);
    useEffect(() => {
      if (!debounceDidMount.current) { debounceDidMount.current = true; return; } //-->prevent initial render
      if(tabName) {
        const reqParams = {
          TabStatus: tabName,
          search:debouncedText,
          date:filterData?.date!=null ? getYYYYMMDDFormat(filterData?.date) : "",
          limit: 10,
          offset:0
        }
        getSustainableList(reqParams)
      }
    },[debouncedText])

    const dateDidMount = useRef(false);

    useEffect(() => {
      if (!dateDidMount.current) { dateDidMount.current = true; return; } //-->prevent initial render
      if(tabName) {
        const reqParams = {
          TabStatus: tabName,
          search:debouncedText,
          date:filterData?.date!=null ? getYYYYMMDDFormat(filterData?.date) : "",
          limit: 10,
          offset:0
        }
        getSustainableList(reqParams)
      }
    },[filterData.date])

    useEffect(() => {
      setIsLoading(true)
      if(tabName) {
        const reqParams = {
          TabStatus: tabName,
          search:"",
          date:"",
          limit: 10,
          offset:0
        }
        getSustainableList(reqParams)
      }
    },[])

    useEffect(() => {
      setSustainableData([]);
      getSustainableListNull();  
    
     }, [])

     useEffect(() => {
      if(eventAndPromotionsInactiveStatus !== null && eventAndPromotionsInactiveStatus?.response_status === 'success'){
        setSnackbarStatus({ message: eventAndPromotionsInactiveStatus.response_message, status: true, color: 'green', icon: 'tick' })
        let data={
          TabStatus:"Archived",
          limit:10,
          offset:0,
          search:"",
          date: null,
          type:"",
      }
      localStorage.setItem('tab',2);
      setValue(2)
      getSustainableList(data);
      inactiveSustainableNull();
  
        setTimeout(() => {
          setSnackbarStatus({message: '', status: false, color: '', icon: ''})
      }, 3000)
      }
    }, [eventAndPromotionsInactiveStatus])

    
     useEffect(() => {
      if(getSustainableListData?.response?.response_data){    
        setSustainableData(getSustainableListData?.response?.response_data)
        getSustainableListNull();  
          setIsLoading(false)
      }
    }, [getSustainableListData])

     const getLocalTime = (time) => {
      let localTime = moment.utc(time).local().format('DD MMM YYYY hh:mm A')
      return localTime;
    }

    const handlePagination = (e,value)=>{
        setCurrentPage(value)
          let datas={
            TabStatus: tabName,
            search:"",
            date:"",
              limit:10,
              offset:parseInt(value-1),
              // search:filterData.search,
              // date:filterData.date!=null ? getYYYYMMDDFormat(filterData.date) : "",
          }
          getSustainableList(datas)
    }
  
    const handleOptionClosed = () => {
      setAnchorEl(null);
    }
    const handleOptionClose = () => {                    
      setAnchorEl(null);
      setmenuDropdown(null);
    };

    const handleStatusInactive = () => {
      handleOptionClose();
      inactiveSustainable({id: Number(selectedId), status: 'Inactive'})
     }

    const getTableColumns = (dataType) => {
      const obj = {Drafts:DRAFT_COLUMNS, Scheduled: SCHEDULE_COLUMNS,Active: ACTIVE_COLUMNS,Archived: ARCHIVED_COLUMNS}
      let currentColumn;
      switch(tabName) {
        case 'Draft':
          currentColumn = DRAFT_COLUMNS
        break;
        case 'Schedule':
          currentColumn = SCHEDULE_COLUMNS
        break;
        case 'Active':
          currentColumn = ACTIVE_COLUMNS
          break;
          case 'Archived':
            currentColumn = ARCHIVED_COLUMNS
            break;
        default:
          
      }

      let columns = currentColumn
      return (
        columns?.map((item, index) => (
        <StyledTableCell sx={{color: '#0F2C6F', fontWeight: 500 }} key={index}>{item.column_name}</StyledTableCell>
        ))
      )
    }

    const EmptyBodyData = () => {
      return(
        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', gap:'10px', padding:'60px 0', width:'100%'}}>
          <NoDataIcon />
          <Typography sx={{color:'#0F2C6F', fontSize:'14px',lineHeight:'24px', textAlign:'center', fontWeight:'500'}}>No {tabName} Data</Typography>
          {/* <Typography sx={{color:'#0F2C6F', fontSize:'14px',lineHeight:'24px', textAlign:'center', fontWeight:'500'}}>In order to make a booking, click on the make a booking button.</Typography> */}
          
        </Box>
      )
    }

    const handleReopen = ()=>{
      getSustainableListNull()
      navigate(`/edit_sustainability/${selectedId}/false/true/false`)
    }

    
  const AgreementModal = () => {
    return(
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      > <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
      Do you want to In-active?
      </DialogTitle>
        <DialogContent dividers>
           
            <Box sx={{display:'flex', justifyContent:'space-between', gap:'10px'}}>
                <Button variant='outlined' sx={{ textTransform: 'capitalize', color: '#0F2C6F',borderRadius: '8px', minWidth:'50px',  height: '40px',}} onClick={handleClose}>Cancel</Button>
                <Button variant='contained' sx={{textTransform: 'capitalize',color: '#FFFFFF', borderRadius: '8px', minWidth:'50px',  height: '40px', backgroundColor: '#123FA9', '&:hover': { backgroundColor: '#123FA9' }}} onClick={MakeInactive}>Yes</Button>
            </Box>
        </DialogContent>

      </BootstrapDialog>
    )
}

  const editPost = ()=> {
    getSustainableListNull()
    navigate(`/edit_sustainability/${selectedId}/false/true/false`)
  }

    const getTableDatas = ()=>{
      if(tabName=='Draft'){
        return sustainableData?.map((item, index)=>(
          <StyledTableRow key={index} onClick={()=>{
              navigate(`/create_sustainability/${item.sustainable_id}/false/false/true`)
        }}>
          {/* <StyledTableCell align="left">
              <Checkbox
                  icon={<CheckboxIcon/>}
                  size="small"
              /> 
          </StyledTableCell> */}
          <StyledTableCell align="left">
            <Typography variant='p'> {getLocalTime(item?.created_at)}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.title}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> { item?.schedule_from_datetime != null ? getLocalTime(item?.schedule_from_datetime) : "-"}</Typography>
          </StyledTableCell>
        </StyledTableRow>
        ))
      }else if(tabName=='Schedule'){
        return  sustainableData?.map((item, index)=>(
          <StyledTableRow key={index}>
          {/* <StyledTableCell align="left">
              <Checkbox
                  icon={<CheckboxIcon/>}
                  size="small"
              /> 
          </StyledTableCell> */}
          <StyledTableCell align="left">
            <Typography variant='p'> {getLocalTime(item?.created_at)}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.title}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> { item?.post_setting == 2 ?  getLocalTime(item?.schedule_to_datetime) : moment(item?.schedule_to_datetime).utc().format('DD MMM YYYY hh:mm A')}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.submitted_by}</Typography>
          </StyledTableCell>
          <StyledTableCell><IconButton onClick={(e) => handleClick2(e, item.sustainable_id,'Edit', e.currentTarget)}><MoreIcons /></IconButton></StyledTableCell>
        </StyledTableRow>
        ))
      }else if(tabName =='Active'){
        return  sustainableData?.map((item, index)=>(
          <>
          <StyledTableRow key={index} sx={{position:'relative'}}>
          {/* <StyledTableCell align="left">
              <Checkbox
                  icon={<CheckboxIcon/>}
                  size="small"
              /> 
          </StyledTableCell> */}
          <StyledTableCell align="left">
            <Typography variant='p'> {getLocalTime(item?.created_at)}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.title}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> { item?.post_setting == 2 ? getLocalTime(item?.schedule_to_datetime) : moment(item?.schedule_to_datetime).utc().format('DD MMM YYYY hh:mm A')}</Typography>
          </StyledTableCell>
          <StyledTableCell sx={{textAlign:'left'}}>
            <Typography variant='p'> {item?.sustainables_reads_count}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.submitted_by}</Typography>
          </StyledTableCell>
          <StyledTableCell>
            <IconButton onClick={(e) => handleClick2(e, item.sustainable_id, 'In-active', e.currentTarget)}><MoreIcons className='btn'  /></IconButton>
            </StyledTableCell>
        </StyledTableRow>
        </>
        ))
      } else if('Archived') {
        return  sustainableData?.map((item, index)=>(
          <StyledTableRow key={index}>
          {/* <StyledTableCell align="left">
              <Checkbox
                  icon={<CheckboxIcon/>}
                  size="small"
              /> 
          </StyledTableCell> */}
          <StyledTableCell align="left">
            <Typography variant='p'> {getLocalTime(item?.created_at)}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.title}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.post_setting == 2 ? getLocalTime(item?.schedule_to_datetime) : moment(item?.schedule_to_datetime).utc().format('DD MMM YYYY hh:mm A')}</Typography>
          </StyledTableCell>
          <StyledTableCell align="left">
            <Typography variant='p'> {item?.submitted_by}</Typography>
          </StyledTableCell>
        </StyledTableRow>
        ))
      }
    
    }


  return (
    <>
    <AgreementModal />
    <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
    <Box sx={{width: "100%"}}>
      <Box sx={{display: tabName == 'Active' || tabName == 'Schedule' ? 'flex' : 'none', justifyContent:'space-between', px:'8px'}}>
          <Box sx={{display: sustainableData?.length >0 ? "flex" : 'none', alignItems: "center", gap: 2}}>
              {/* <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}        
            sx={{textTransform: "none", boxShadow: 1, display: "flex", alignItems: "center", gap :1, color: "#123FA9", fontWeight: 600, fontSize: "14px"}}
          >
            Type
            <DownArrow />
          </Button>
          <Menu         
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={menuPositions}
          >
          
            {[1,2,3].map((item,index) => <MenuItem key={index}  onClick={handleClose}>Drafts {item}</MenuItem>)}
          </Menu> */}

          {/* <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}        
            sx={{textTransform: "none", boxShadow: 1, display: "flex", alignItems: "center", gap :1, color: "#123FA9", fontWeight: 600, fontSize: "14px"}}
          >
            Status
            <DownArrow />
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={menuPositions}
          >
            {[1,2,3].map((item,index) => <MenuItem key={index}  onClick={handleClose}>Status {item}</MenuItem>)}
          </Menu>   */}
          <FormControl sx={{ width: 200, ml: 1}}>
              <DatePicker
                  inputFormat="dd/MM/yyyy"
                  components={{
                      OpenPickerIcon:DatePickerIcon
                  }}
                  size="small"
                  // label={activeType === 'all' ? 'Requested Date' : 'Closed Date'}
                  value={filterData.date}
                  onChange={(newValue) => { handleFilterDatas(newValue, 'date') }}
                  renderInput={(params) => <TextField autoComplete='off' onKeyDown={(e)=>preventDatePickerKeyboard(e)} size='small' {...params} />}
                  // disableFuture={true}
                  
              />
          </FormControl>
          </Box>
          <Box>
            <Box
              sx={{
                float: "right",
              padding:'5px',
                display: sustainableData?.length >0 ? "flex" : 'none',
                alignItems: "center",
                border: " 1px solid #DBDFE9",
                borderRadius: "6px",
                mr:2
              }}
            >
              <Input
                name={'search'}
                onChange={(e) => handleFilterDatas(e.target.value, e.target.name)}
                size="small"
                type="search"
                value={filterData.search}
                placeholder='Search'
                style={{
                  border: "none",
                  outline: "none",
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "100%",
                }}
                inputProps={{
                  sx: {
                    height: { md: "25px", sm: "20px", xs: "4px" },
                    fontSize: { md: "16px", sm: "14px", xs: "12px" },
                    flexGrow: 1,
                    width: "inherit",
                  },
                }}
                disableUnderline
              />
              <SearchIcon cursor="pointer" style={{ flexGrow: 0 }} />
            </Box>
          </Box>
       </Box>
       <Box>
        {/* <ActiveTable /> */}
       {optionsOpen && <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={optionsOpen}
            onClose={handleOptionClosed}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={() =>menuText=='In-active' ?  handleOpen() : handleReopen()} sx={{margin: "0 20px"}}>{menuText}</MenuItem>        
            {menuText=='In-active' &&  <MenuItem onClick={editPost}  sx={{margin: "0 20px"}}>Edit Post</MenuItem> }
          </Menu> }

      {!isLoading ? (sustainableData?.length>0 ? (<Table>
          <TableHead>
            <StyledTableRow >
              {getTableColumns()}
            </StyledTableRow>
          </TableHead>
          <TableBody>
          {getTableDatas()}
          </TableBody>
        </Table>):(
          <EmptyBodyData/>
        )):(
          <Box sx={{display:'flex',justifyContent:'center', alignItems:'center'}}>
            <CircularProgress/>
          </Box>
        )}
        {sustainableListCount<1 && 
          <Box sx={{display:"flex",justifyContent:"center",m:5}}>
               <Typography sx={{textAlign:"center",fontSize:"20px",color:'#0F2C6F'}}>No data found</Typography>
          </Box>
        }
        {sustainableListCount>0 &&  <Pagination page={currentPage} sx={{display:"flex",justifyContent:"center",py:3,backgroundColor:"rgba(143, 146, 161, 0.05)" }} count={Math.ceil(sustainableListCount/10)} size="large" shape='rounded' onChange={handlePagination}  /> }
       </Box>
    </Box>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSustainableList: (data) => {
      dispatch(getSustainableListAction(data))
    },
    getSustainableListNull: (data) => {
      dispatch(getSustainableListNullAction(data))
    },
    inactiveSustainable: (data) => {
      dispatch(InactiveSustainableEvent(data))
    },
    inactiveSustainableNull: (data) => {
      dispatch(InactivesustainableStateNull(data))
    },
  }
}

const mapStateToProps = (state) => {
  return {
    getSustainableListData: state.SustainableListReducer.getSustainableList,
    sustainableListCount: state.SustainableListReducer.getSustainableList?.response?.total_records,
    eventAndPromotionsInactiveStatus:state.SustainableListReducer?.sustainableInactiveData?.response,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SustainabiltyTabs)