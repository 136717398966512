import { Grid, Typography, Button, TextField, Modal } from "@mui/material";
import DraggableList from "react-draggable-list";
import React, { useState, useEffect, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { ReactComponent as AddWhiteIcon } from "../../Assets/icons/WhiteRoundedPlusIcon.svg";
import { generateUniqueRandomId } from "../../Utils/utils";
import * as HelpAction from "../../redux/actions/helpAction";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Container } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import Dragdrop from "../../Assets/icons/dragdrop.png";
import { ReactComponent as DragEdit } from "../../Assets/icons/dragEdit.svg";
import ToastAlert from "../common/Alert";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const initialItems = [
  { id: uuidv4(), title: "Item 1", textFieldValue: "", richTextValue: "" },
];

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "courier",
  "georgia",
  "impact",
  "tahoma",
  "times-new-roman",
  "verdana",
];
Quill.register(Font, true);

const TAB_HEADINGS = [
  {
    id: 0,
    name: "Important Contacts",
  },
  {
    id: 1,
    name: "Tenant Handbook",
  },
];

// const toolbarOptions = [['bold', 'italic', 'underline'],  [{ 'list': 'ordered'}, { 'list': 'bullet' }],['link'],    ]
// const module = {toolbar: toolbarOptions}

const toolbarOptions = [
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [{ indent: "-1" }, { indent: "+1" }],
];
const module = {
  toolbar: toolbarOptions,
  keyboard: {
    bindings: {
      "list autofill": {
        prefix: /^\s*()$/,
      },
    },
  },
};

const DraggableAccordion = ({
  item,
  index,
  handleChange,
  handleDelete,
  handleSave,
  handleCancel,
  expanded,
  handleChangeAccordion,
  errors,
}) => {
  const handleTextFieldChange = (e) => {
    handleChange(item.id, "textFieldValue", e.target.value);
  };

  const handleRichTextChange = (value) => {
    handleChange(item.id, "richTextValue", value);
  };
  console.log("accordion errors ", errors);

  const truncate = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
  };

  return (
    <Accordion
      sx={{ my: "20px", borderRadius: "8px", p: "10px 15px 10px 12px" }}
      expanded={expanded === item.id}
      onChange={handleChangeAccordion(item.id)}
    >
      <AccordionSummary>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              component="img"
              src={Dragdrop}
              sx={{ width: "20px", height: "20px" }}
            />
            <Typography>{ truncate(item?.textFieldValue ,60) }</Typography>
          </Box>
          <DragEdit style={{ width: "20px", height: "20px" }} />
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <TextField
          label="Title"
          value={item?.textFieldValue}
          onChange={handleTextFieldChange}
          fullWidth
          margin="normal"
        />
        <Typography
          variant="p"
          sx={{ fontSize: "14px", color: "red", display: "block", mt: "-10px" }}
        >
          {errors?.textFieldValue}
        </Typography>
        <ReactQuill
          style={{ height: "150px" }}
          placeholder="Description"
          value={item?.richTextValue}
          onChange={handleRichTextChange}
          modules={module}
          formats={[
            "header",
            "font",
            "size",
            "bold",
            "italic",
            "underline",
            "blockquote",
            "list",
            "bullet",
            "indent",
            "link",
            "color",
            "align",
            "code-block",
          ]}
        />
        <Typography
          variant="p"
          sx={{ fontSize: "14px", color: "red", display: "block", mt: 4 }}
        >
          {errors?.richTextValue}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 6 }}>
          <Button
            variant="contained"
            sx={{
              color: " #0F2C6F",
              textTransform: "capitalize",
              color: "white",
              borderRadius: "8px",
              height: "50px",
              backgroundColor: "#BD0251",
              "&:hover": { backgroundColor: "#BD0251" },
            }}
            onClick={() => handleDelete(item.id, item?.faq_id)}
          >
            Delete
          </Button>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Button
              variant="outlined"
              sx={{
                color: " #0F2C6F",
                textTransform: "capitalize",
                border: " 1px solid #AAC3FF",
                borderRadius: "8px",
                height: "50px",
                backgroundColor: "#FFFFFF",
                "&:hover": { backgroundColor: "#FFFFFF" },
              }}
              onClick={() => handleCancel(item.id, item?.faq_id)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "capitalize",
                color: "white",
                borderRadius: "8px",
                height: "50px",
                backgroundColor: "#0F2C6F",
                "&:hover": { backgroundColor: "#0F2C6F" },
              }}
              onClick={() => handleSave(item.id, item?.faq_id)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const Help = ({
  addFaqStatus,
  addFaq,
  faqNull,
  getAllFaq,
  allFaqList,
  updateFaqById,
  updateFaqStatus,
  updateFaqNull,
  deleteFaqById,
  deleteFaqStatus,
  deleteFaqNull,
  sortFaqById,
  sortOrderStatus,
  sortFaqNull,
}) => {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState({
    textFieldValue: "",
    richTextValue: "",
  });
  const [originalItems, setOriginalItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toastStatus, setToastStatus] = useState({
    color: "",
    status: false,
    message: "",
    icon: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState(null);
  const [pendingAccordion, setPendingAccordion] = useState(null);
  const [editorData, setEditorData] = React.useState("");

  const handleEditorChange = (event) => {
    setEditorData(event.editor.getData());
  };

  const handleAddQuestions = () => {
    // const newId = generateUniqueRandomId(items);
    // setItems([...items, { id: newId.toString(), title: '', textFieldValue: '', richTextValue: '' }]);
    // setOriginalItems([...originalItems, {id: newId.toString(), title: '', textFieldValue: '', richTextValue: '' }])
    // setExpanded(newId.toString());
    // handleScrollView(newId.toString())
    const isNewlyAddedItem = items?.find((item) => item?.faq_id === expanded);
    if (
      !isNewlyAddedItem &&
      expanded &&
      !items?.find((item) => item.id === expanded)?.faq_id
    ) {
      setPendingAction(() => () => {
        const newId = generateUniqueRandomId(items);
        setItems([
          ...items,
          {
            id: newId.toString(),
            title: "",
            textFieldValue: "",
            richTextValue: "",
          },
        ]);
        setOriginalItems([
          ...originalItems,
          {
            id: newId.toString(),
            title: "",
            textFieldValue: "",
            richTextValue: "",
          },
        ]);
        setExpanded(newId.toString());
        handleScrollView(newId.toString());
      });
      setModalOpen(true);
    } else {
      const newId = generateUniqueRandomId(items);
      setItems([
        ...items,
        {
          id: newId.toString(),
          title: "",
          textFieldValue: "",
          richTextValue: "",
        },
      ]);
      setOriginalItems([
        ...originalItems,
        {
          id: newId.toString(),
          title: "",
          textFieldValue: "",
          richTextValue: "",
        },
      ]);
      setExpanded(newId.toString());
      handleScrollView(newId.toString());
    }
  };

  const handleModalClose = (action) => {
    // if (save && pendingAction) {
    //   pendingAction();
    //   handleSave(expanded, null);
    // }
    // setPendingAction(null);
    // setModalOpen(false);

    if (action === "save") {
      handleSave(
        pendingAccordion,
        items.find((item) => item.id === pendingAccordion)?.faq_id
      );
    } else if (action === "delete") {
      handleDelete(
        pendingAccordion,
        items.find((item) => item.id === pendingAccordion)?.faq_id
      );
    }
    setPendingAction(null);
    setPendingAccordion(null);
    // setModalOpen(false);
  };

  const handleScrollView = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 100);
  };

  const validation = (reqItem) => {
    console.log("reqItem ", reqItem);
    let error = [];
    if (reqItem.textFieldValue == "") {
      setErrors((prev) => ({ ...prev, textFieldValue: "Enter Title" }));
      error.push(true);
    } else {
      setErrors((prev) => ({ ...prev, textFieldValue: "" }));
      error.push(false);
    }
    if (!reqItem.richTextValue || reqItem.richTextValue == "<p><br></p>") {
      setErrors((prev) => ({ ...prev, richTextValue: "Enter Description" }));
      error.push(true);
    } else {
      setErrors((prev) => ({ ...prev, richTextValue: "" }));
      error.push(false);
    }
    console.log("error ", error);
    let errorStatus = error.includes(true);
    return !errorStatus;
  };

  const handleSave = (id, faqId) => {
    const item = items.find((item) => item.id === id);
    const expandedItem = items.find((item) => item.id === expanded);
    if (faqId && faqId == expanded) {
      const validate = validation(item);
      if (!validate) return;
      const ind = items.findIndex((element) => element.faq_id == faqId);
      const reqObj = {
        faq_id: faqId,
        title: item.textFieldValue ? item.textFieldValue : item.title,
        description: item.richTextValue ? item.richTextValue : item.description,
        sort_order: ind + 1,
      };
      updateFaqById(reqObj);
    } else if (id == expanded) {
      const validate = validation(item);
      console.log("valisat ", validate);
      if (!validate) return;
      const ind = items.findIndex((element) => element.id == id);
      const reqObj = {
        sort_order: ind + 1,
        title: item.textFieldValue ? item.textFieldValue : item.title,
        description: item.richTextValue,
      };
      addFaq(reqObj);
    } else {
      const validate = validation(expandedItem);
      if (!validate) return;
      const ind = items.findIndex((element) => element.id == expanded);
      const reqObj = {
        sort_order: ind + 1,
        title: expandedItem.textFieldValue
          ? expandedItem.textFieldValue
          : expandedItem.title,
        description: expandedItem.richTextValue,
      };
      addFaq(reqObj);
    }
    setPendingAction(null);
    setPendingAccordion(null);
  };

  const handleCancel = (id, faqId) => {
    if (faqId) {
      console.log("items ", items);
      const reqObj = originalItems?.filter((item) => item.faq_id == faqId);
      console.log("reqObj ", reqObj);
      const reqObj1 = items?.map((item, index) => {
        if (item.faq_id == faqId) {
          return {
            ...item,
            textFieldValue: reqObj[0].title,
            richTextValue: reqObj[0].description,
          };
        } else {
          return { ...item };
        }
      });
      setItems(reqObj1);
    } else {
      console.log("items ", items);
      const reqObj = originalItems?.filter((item) => item.id == id);
      console.log("reqObj ", reqObj);
      const reqObj1 = items?.map((item, index) => {
        if (item.id == id) {
          return {
            ...item,
            textFieldValue: reqObj[0].title,
            richTextValue: reqObj[0].description,
          };
        } else {
          return { ...item };
        }
      });
      console.log("reqObj1 ", reqObj1);
      setItems(reqObj1);
    }
  };

  const handleDelete = (id, faqId) => {
    console.log("id ", id);
    console.log("faqId ", expanded);
    if (faqId && faqId == expanded) {
      deleteFaqById({ faq_id: faqId });
    } else if (id == expanded) {
      setItems((prevItems) => prevItems.filter((item) => item.id !== id));
      setOriginalItems((prevItems) =>
        prevItems.filter((item) => item.id !== id)
      );
      setExpanded(false);
    } else {
      console.log("withins ", id, expanded);
      setItems((prevItems) => prevItems.filter((item) => item.id !== expanded));
      setOriginalItems((prevItems) =>
        prevItems.filter((item) => item.id !== expanded)
      );
      setExpanded(false);
    }
    setPendingAction(null);
    setPendingAccordion(null);
    setModalOpen(false);
  };

  const handleChange = (id, field, value) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const handleListAlignment = (listItems) => {
    // console.log("listItems ",listItems)
    const reqObj = listItems?.map((item, index) => {
      return {
        faq_id: item.faq_id ? item.faq_id : parseInt(item.id),
        sort_order: index + 1,
      };
    });
    // console.log("reqObj ",reqObj)
    sortFaqById(reqObj);
  };

  const onDragEnd = (result) => {
    if (expanded == false) {
      if (!result.destination) return;
      const reorderedItems = Array.from(items);
      const [movedItem] = reorderedItems?.splice(result?.source?.index, 1);
      reorderedItems.splice(result?.destination?.index, 0, movedItem);
      setItems(reorderedItems);
      handleListAlignment(reorderedItems);
    }
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    // console.log("expanded ",expanded)
    // setExpanded(isExpanded ? panel : false);

    // if (expanded && expanded !== panel && !items.find(item => item.id === expanded).faq_id) {
    //   setPendingAccordion(panel);
    //   setPendingAction(() => () => setExpanded(isExpanded ? panel : false));
    //   setModalOpen(true);
    // } else {
    //   setExpanded(isExpanded ? panel : false);
    // }
    setErrors({ textFieldValue: "", richTextValue: "" });
    console.log("items ", items);
    console.log("expanded ", expanded);
    console.log(
      "!items?.find(item => item?.id === expanded)?.faq_id ",
      items?.find((item) => item?.faq_id === expanded)
    );
    const isNewlyAddedItem = items?.find((item) => item?.faq_id === expanded);
    if (
      !isNewlyAddedItem &&
      expanded &&
      (expanded !== panel || !isExpanded) &&
      !items?.find((item) => item?.id === expanded)?.faq_id
    ) {
      setPendingAccordion(panel);
      setPendingAction(() => () => setExpanded(isExpanded ? panel : false));
      setModalOpen(true);
    } else {
      setExpanded(isExpanded ? panel : false);
    }
  };

  useEffect(() => {}, [expanded]);

  useEffect(() => {
    if (addFaqStatus?.response_status == 1) {
      setToastStatus({
        color: "green",
        status: true,
        message: addFaqStatus?.message,
        icon: "tick",
      });
      getAllFaq();
      setExpanded(false);
      setModalOpen(false);
      setTimeout(() => {
        setToastStatus({ color: "", status: false, message: "" });
        faqNull();
      }, 3000);
    } else if (addFaqStatus?.response_status == 0) {
      setToastStatus({
        color: "red",
        status: true,
        message: addFaqStatus?.message,
        icon: "close",
      });
      setModalOpen(false);
      setTimeout(() => {
        setToastStatus({ color: "", status: false, message: "" });
        faqNull();
      }, 3000);
    }
  }, [addFaqStatus]);

  useEffect(() => {
    if (updateFaqStatus?.response_status == 1) {
      setToastStatus({
        color: "green",
        status: true,
        message: updateFaqStatus?.message,
        icon: "tick",
      });
      getAllFaq();
      setExpanded(false);
      setTimeout(() => {
        setToastStatus({ color: "", status: false, message: "" });
        updateFaqNull();
      }, 3000);
    } else if (updateFaqStatus?.response_status == 0) {
      setToastStatus({
        color: "red",
        status: true,
        message: updateFaqStatus?.message,
        icon: "close",
      });
      setTimeout(() => {
        setToastStatus({ color: "", status: false, message: "" });
        updateFaqNull();
      }, 3000);
    }
  }, [updateFaqStatus]);

  useEffect(() => {
    if (deleteFaqStatus?.response_status == 1) {
      setToastStatus({
        color: "green",
        status: true,
        message: deleteFaqStatus?.message,
        icon: "tick",
      });
      getAllFaq();
      setExpanded(false);
      setTimeout(() => {
        setToastStatus({ color: "", status: false, message: "" });
        deleteFaqNull();
      }, 3000);
    } else if (deleteFaqStatus?.response_status == 0) {
      setToastStatus({
        color: "red",
        status: true,
        message: deleteFaqStatus?.message,
        icon: "close",
      });
      setTimeout(() => {
        setToastStatus({ color: "", status: false, message: "" });
        deleteFaqNull();
      }, 3000);
    }
  }, [deleteFaqStatus]);

  useEffect(() => {
    if (sortOrderStatus?.response_status == 1) {
      // setToastStatus({color:'green',status:true,message:sortOrderStatus?.message,icon:'tick'})
      getAllFaq();
      setTimeout(() => {
        // setToastStatus({color:'',status:false, message:""})
        sortFaqNull();
      }, 3000);
    } else if (sortOrderStatus?.response_status == 0) {
      // setToastStatus({color:'red',status:true,message:sortOrderStatus?.message,icon:'close'})
      setTimeout(() => {
        // setToastStatus({color:'',status:false, message:""})
        sortFaqNull();
      }, 3000);
    }
  }, [sortOrderStatus]);

  useEffect(() => {
    getAllFaq();
  }, []);

  useEffect(() => {
    if (allFaqList?.response_status == 1) {
      if (allFaqList?.data_array?.faqArrayList?.length != 0) {
        const reqList = allFaqList?.data_array?.faqArrayList?.map((item) => ({
          ...item,
          id: item.faq_id.toString(),
          textFieldValue: item.title,
          richTextValue: item.description,
        }));
        console.log("reqList ", reqList);
        setItems(reqList);
        setOriginalItems(reqList);
      }
    } else {
      setItems([]);
    }
  }, [allFaqList]);

  return (
    <>
      <ToastAlert
        message={toastStatus?.message}
        status={toastStatus?.status}
        color={toastStatus?.color}
        icon={toastStatus?.icon}
      />
      <Grid
        container
        sx={{
          paddingTop: { md: "60px", xs: "20px" },
          background: "#F9FAFA",
          minHeight: "100%",
        }}
      >
        <Grid item lg={2} md={1} sm={1} xs={0.5} />
        <Grid
          item
          sx={{ display: "flex", flexDirection: "column", color: "#0F2C6F" }}
          lg={8}
          md={10}
          sm={10}
          xs={10}
        >
          <BackButton
            onClick={() => {
              navigate(-1);
            }}
            cursor="pointer"
          />
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                sx={{
                  color: "#002D74",
                  margin: "30px 0",
                  fontSize: "26px",
                  fontWeight: 500,
                  height: "32px",
                }}
              >
              Manage Frequently Asked Questions
              </Typography>
              <Button
                onClick={handleAddQuestions}
                variant="contained"
                sx={{
                  maxHeight: "40px",
                  alignSelf: "center",
                  color: "#FFFFFF",
                  backgroundColor: "#123FA9",
                  "&:hover": { backgroundColor: "#123FA9" },
                  textTransform: "capitalize",
                  fontSize: { md: "14px", xs: "10px" },
                }}
                endIcon={<AddWhiteIcon />}
              >
                Add Questions
              </Button>
            </Box>
            <Typography sx={{ fontSize: "14px", color: "#6F80A9" }}>
            You may create and edit an FAQ to be shown in the mobile app.
            </Typography>
          </Box>

          {/* tabs */}

          <Box>
            <Box sx={{ width: "100%", marginTop: "50px" }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {items.map((item, index) =>
                        item?.faq_id ? (
                          <Draggable
                            key={item?.id}
                            draggableId={item?.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DraggableAccordion
                                  item={item}
                                  index={index}
                                  handleChange={handleChange}
                                  handleDelete={handleDelete}
                                  handleSave={handleSave}
                                  handleCancel={handleCancel}
                                  expanded={expanded}
                                  handleChangeAccordion={handleChangeAccordion}
                                  errors={errors}
                                />
                              </div>
                            )}
                          </Draggable>
                        ) : (
                          <div id={item.id} key={item.id}>
                            <DraggableAccordion
                              item={item}
                              index={index}
                              handleChange={handleChange}
                              handleDelete={handleDelete}
                              handleSave={handleSave}
                              handleCancel={handleCancel}
                              expanded={expanded}
                              handleChangeAccordion={handleChangeAccordion}
                              errors={errors}
                            />
                          </div>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Confirm Action
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Do you want to{" "}
                    {pendingAction === handleSave
                      ? "save changes?"
                      : pendingAction === handleDelete
                      ? "delete this item?"
                      : "cancel this action?"}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 3,
                    }}
                  >
                    <Button
                      onClick={() => handleModalClose("delete")}
                      sx={{}}
                      variant="contained"
                      color="error"
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => handleModalClose("save")}
                      sx={{}}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    addFaqStatus: state.faqReducer.addFaqStatus,
    allFaqList: state.faqReducer.allFaqList,
    updateFaqStatus: state.faqReducer.updateFaqStatus,
    deleteFaqStatus: state.faqReducer.deleteFaqStatus,
    sortOrderStatus: state.faqReducer.sortOrderStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFaq: () => dispatch(HelpAction.getAllFaq()),
    updateFaqById: (data) => dispatch(HelpAction.updateFaqById(data)),
    addFaq: (data) => dispatch(HelpAction.addFaq(data)),
    deleteFaqById: (data) => dispatch(HelpAction.deleteFaqById(data)),
    faqNull: () => dispatch(HelpAction.faqNull()),
    updateFaqNull: () => dispatch(HelpAction.updateFaqNull()),
    deleteFaqNull: () => dispatch(HelpAction.deleteFaqNull()),
    sortFaqById: (data) => dispatch(HelpAction.sortFaqById(data)),
    sortFaqNull: () => dispatch(HelpAction.sortFaqNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Help);
