import axios from "axios";
import { createAction } from "redux-actions";

export const sessionExpired = createAction("SESSION_EXPIRED");

export const AddFaqInitiate = createAction("ADD_FAQ_INITIATE")
export const AddFaqSuccess = createAction("ADD_FAQ_SUCCESS")
export const AddFaqFailure = createAction("ADD_FAQ_FAILURE")
export const faqNull = createAction("FAQ_NULL")

export const FaqAction = () => dispatch => dispatch(faqNull());

export const addFaq = (data)=>{
    let token=localStorage.getItem('userToken')    
    console.log('addFaq ',token) 
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(AddFaqInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/addFaq`,data,config)
        .then((response)=>{
            console.log('addFaq response',response)
            if(response.data?.response_status){
                dispatch(AddFaqSuccess(response.data))
            }else{
                dispatch(AddFaqFailure(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(AddFaqFailure())
            }
        })
    }
}

export const getAllFaqInitiate = createAction("GETALL_FAQ_INITIATE")
export const getAllFaqSuccess = createAction("GETALL_FAQ_SUCCESS")
export const getAllFaqFailure = createAction("GETALL_FAQ_FAILURE")

export const getAllFaq = ()=>{
    let token=localStorage.getItem('userToken')    
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(getAllFaqInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/getAllFaq`,'',config)
        .then((response)=>{
            console.log('getAllFaq response',response)
            if(response.data?.response_status){
                dispatch(getAllFaqSuccess(response.data))
            }else{
                dispatch(getAllFaqFailure(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(getAllFaqFailure())
            }
        })
    }
}

export const updateFaqInitiate = createAction("UPDATE_FAQ_INITIATE")
export const updateFaqSuccess = createAction("UPDATE_FAQ_SUCCESS")
export const updateFaqFailure = createAction("UPDATE_FAQ_FAILURE")
export const updateFaqNull = createAction("UPDATE_FAQ_NULL")

export const updateFaqById = (data)=>{
    let token=localStorage.getItem('userToken')    
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(updateFaqInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/updateFaq`,data,config)
        .then((response)=>{
            console.log('updateFaq response',response)
            if(response.data?.response_status){
                dispatch(updateFaqSuccess(response.data))
            }else{
                dispatch(updateFaqFailure(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(updateFaqFailure())
            }
        })
    }
}

export const deleteFaqInitiate = createAction("DELETE_FAQ_INITIATE")
export const deleteFaqSuccess = createAction("DELETE_FAQ_SUCCESS")
export const deleteFaqFailure = createAction("DELETE_FAQ_FAILURE")
export const deleteFaqNull = createAction("DELETE_FAQ_NULL")

export const deleteFaqById = (data)=>{
    let token=localStorage.getItem('userToken')    
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(deleteFaqInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/deleteFaq`,data,config)
        .then((response)=>{
            console.log('deleteFaq response',response)
            if(response.data?.response_status){
                dispatch(deleteFaqSuccess(response.data))
            }else{
                dispatch(deleteFaqFailure(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(deleteFaqFailure())
            }
        })
    }
}

export const sortFaqInitiate = createAction("SORT_FAQ_INITIATE")
export const sortFaqSuccess = createAction("SORT_FAQ_SUCCESS")
export const sortFaqFailure = createAction("SORT_FAQ_FAILURE")
export const sortFaqNull = createAction("SORT_FAQ_NULL")

export const sortFaqById = (data)=>{
    let token=localStorage.getItem('userToken')    
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return (dispatch)=>{
        dispatch(sortFaqInitiate())
        axios.post(`${process.env.REACT_APP_API_URL}/sortAllFaq`,data,config)
        .then((response)=>{
            console.log('sortFaq response',response)
            if(response.data?.response_status){
                dispatch(sortFaqSuccess(response.data))
            }else{
                dispatch(sortFaqSuccess(response.data))
            }
        })
        .catch((err)=>{
            if(err.status===401){
                dispatch(sessionExpired('Session Expired'));
            }else{
                dispatch(sortFaqFailure())
            }
        })
    }
}