import { Box, Typography } from '@mui/material'
import React from 'react'

export default function TermsAnConditions() {
    return (
        <Box sx={{ padding: {  xs: "10px" } }}>
            <Typography
                sx={{
                    fontSize: { md: "18px", xs: "16px" },
                    fontWeight: 500,
                    color: "#0F2C6F",
                }}
            >
                Terms & Conditions*
            </Typography>
            <Typography
                sx={{
                    fontSize: { md: "14px", xs: "13px" },
                    fontWeight: 500,
                    color: "#6F80A9",
                    py: "8px",
                }}
            >
                Tenants must agree to the terms and conditions stated below before submitting to building management.
            </Typography>
            <Box>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#6F80A9",
                        paddingTop: "10px",
                    }}
                >
                    Important Conditions
                </Typography>
                <br />
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#6F80A9",
                    }}
                >
                    1.Request will be attended to within 1-2 working days.
                    Please do not sent repeated reports for the same incident.
                </Typography>
                <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#6F80A9",
                        my:1
                    }}
                >
                    2.We aim to resolve your issues within 3-4 working days
                    depending on complexity.
                </Typography>
                {/* <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#6F80A9",
                    }}
                >3.If the fault is deemed to be within office premises, please submit a “Service Request” instead. 

                </Typography> */}
            </Box>
        </Box>
    )
}
