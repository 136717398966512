import { Autocomplete, Badge, Box, Button, Checkbox, Chip, Divider, FormControlLabel, Grid, OutlinedInput, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../Assets/icons/back.svg';
import { getLoggedUserData } from '../../Utils/utils';
import { ReactComponent as CheckboxIcon } from '../../Assets/icons/CheckboxTerms.svg';
import { ReactComponent as DeleteIcon } from '../../Assets/icons/Delete.svg';
import { ReactComponent as AddIcon } from '../../Assets/icons/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../../Assets/icons/MinusIcon.svg';
import * as visitorsAction from '../../redux/actions/visitorsAction';
import ToastAlert from '../common/Alert';
import moment from 'moment'
import Faultalert from '../common/faultalert';

const StyledTableRow = styled(TableRow)({
    cursor: "pointer",
    '&:nth-of-type(odd)': {
        backgroundColor: "#F8F9FB"
    }
});


export const ReviewInvite = ({ getTenantVisitorsDetails, teantInviteDetails,deleteVisitor,deleteVisitorStatus,receptionStateToInitial,addMultipleVisitor,addMultipleVisitorStatus,visitorMail,getAllUsers}) => {
    const navigate = useNavigate();
    let { company_details, company_id, user_id } = getLoggedUserData();
    let { invite_id,isEdit } = useParams();
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: 'close' })
    const [inviteDetails, setInviteDetails] = useState({ baseDetails: {}, visitorDetails: [] });
    const [editStatus,setEditStatus]=useState(false)
    const [addVisitor,setAddVisitor] = useState([])
    const [addVisitorError,setAddVisitorError] = useState([])
    const [alert,setAlert] = useState({
        status:false,
        data:{},
        multiple:false
    })
    const [uniqueMailErr,setUniqueMailErr] = useState('')
    const alertData = [{
        label:"Delete this visitor",
        description:"Please confirm that you wish to cancel the access",
        buttonvalue:'Confirm'
    },
    {
        label:"Confirm  Changes of Invitation",
        description:`Please confirm that you wish to Invite ${addVisitor.length} visitors`,
        buttonvalue:"Confirm"
    }]
    const [options,setOptions] = useState([])
    const [existingMailList,setExistingMailList] = useState([])
    useEffect(()=>{
        getAllUsers()
    },[])

    useEffect(()=>{
        setExistingMailList(visitorMail)
        let arr = []
        for(let i =0;i<visitorMail?.length;i++){
            if(visitorMail[i].email!=null){
                arr.push({label:visitorMail[i].email})
            }
        }   
        setOptions(arr)
    },[visitorMail])
    
    useEffect(() => {
        let reqData = {
            invitations_id: invite_id,
        }
        getTenantVisitorsDetails(reqData)
    }, [getTenantVisitorsDetails,deleteVisitorStatus,addMultipleVisitorStatus])
    
    useEffect(() => {
        if (teantInviteDetails.response_status) {
            setInviteDetails({ ...inviteDetails, baseDetails: teantInviteDetails.data_array.invitationArrayList[0], visitorDetails: teantInviteDetails.data_array.sentInvitationArrayList })
        } 

    }, [teantInviteDetails])

    useEffect(()=>{
        // setEditStatus(false)
        setAddVisitor([])
        if(deleteVisitorStatus=="Visitor Deleted Successfully"){
            setAlert({
                status:false,
                data:{},
                multiple:false
            })
            setSnackbarStatus({
                status:true,
                message:deleteVisitorStatus,
                color:"green"
            })
            setTimeout(()=>{
                setSnackbarStatus({
                    status:false,
                    message:'',
                    icon:'',
                    color:''
                })
                receptionStateToInitial()
            },3000)
        }else if(deleteVisitorStatus!=null){
            setSnackbarStatus({
                status:true,
                message:deleteVisitorStatus,
                icon:'',
                color:"red"
            })
            setTimeout(()=>{
                setSnackbarStatus({
                    status:false,
                    message:'',
                    icon:'',
                    color:""
                })
                receptionStateToInitial()
            },3000)
        }
    },[deleteVisitorStatus])
    
   useEffect(()=>{
    // setEditStatus(false)
    setAddVisitor([])
    if(addMultipleVisitorStatus=="Invitations added successfully"){
        setAlert({
            status:false,
            data:{},
            multiple:false
        })
        setSnackbarStatus({
            status:true,
            message:addMultipleVisitorStatus,
            color:"green"
        })
        setTimeout(()=>{
            setSnackbarStatus({
                status:false,
                message:'',
                icon:'',
                color:''
            })
            receptionStateToInitial()
        },3000)
    }else if(addMultipleVisitorStatus!=null){
        setSnackbarStatus({
            status:true,
            message:addMultipleVisitorStatus,
            icon:'',
            color:"red"
        })
        setTimeout(()=>{
            setSnackbarStatus({
                status:false,
                message:'',
                icon:'',
                color:""
            })
            receptionStateToInitial()
        },3000)
    }
    
   },[addMultipleVisitorStatus])

    const handleAddVisitor = (isAdd)=>{
        if(addVisitor.length<1){
            setAddVisitor([
                ...addVisitor,
                {id:1,firstname:'',lastname:'',email:'',disabled:false}
            ])
        }else{
            let arr = []
            let err = []
            const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            for(let i=0;i<addVisitor.length;i++){
                let obj = {}
                if(addVisitor[i].firstname!=''){
                    obj.firstname=''
                }else{
                    obj.firstname="Should not be empty"
                    err.push(true)
                }

                if(addVisitor[i].lastname!=''){
                    obj.lastname=''
                }else{
                    obj.lastname="Should not be empty"
                    err.push(true)
                }

                if(addVisitor[i].email!='' && addVisitor[i].email.match(emailRegex)){
                    obj.email=''
                }else{
                    obj.email="Enter a valid email"
                    err.push(true)
                }
                arr.push(obj)
            }

            let mailarr = []
            for(let i=0;i<addVisitor.length;i++){
                mailarr.push(addVisitor[i].email)
            }
            let uniqueMail = new Set(mailarr)
            if(addVisitor.length != uniqueMail.size){
                err.push(true)
                setUniqueMailErr("Please enter unique mail")
            }

            setAddVisitorError([...arr])
           
            if(!err.includes(true) && isAdd=="add"){
                setAddVisitor([
                    ...addVisitor,
                    {id:addVisitor.length+1,firstname:'',lastname:'',email:''}
                ])
            }
            if(!err.includes(true) && isAdd=='submit'){
                setAlert({
                    status:true,
                    data:alertData[1],
                    multiple:true
                })
            }
        }
    }

    const handleMinusIcon = (value)=>{
        let filterArray = addVisitor.filter((item)=>{
            return value.id != item.id
        })
        setAddVisitor([...filterArray])
    }

    const handleDetails = (e,fromIndex,val)=>{
        
        let arr = []
        if(val){
            setUniqueMailErr('')
            getAllUsers({email:val})
            addVisitor.forEach((item,index)=>{
                if(index==fromIndex){
                    let obj = {...item,[e]:val}
                    arr.push(obj)
                }else{
                  arr.push(item)
                }
            })
            setAddVisitor([...arr])
            setAddVisitorError([])

            //cheking whether the mail is already registered
            let filteredArray = existingMailList.filter((value)=>{
                return value.email == val
            })
            if(filteredArray.length>0){
                let arr = []
                addVisitor.forEach((item,index)=>{
                    if(index==fromIndex){
                        let obj = {...item,firstname:filteredArray[0].first_name,lastname:filteredArray[0].last_name,email:val,disabled:true}
                        arr.push(obj)
                    }else{
                      arr.push(item)
                    }
                })
                setAddVisitor([...arr])
            }else{
                let arr = []
                addVisitor.forEach((item,index)=>{
                    if(index==fromIndex){
                        let obj = {...item,firstname:'',lastname:'',email:val,disabled:false}
                        arr.push(obj)
                    }else{
                      arr.push(item)
                    }
                })
                setAddVisitor([...arr])
            }
        }else{
            let {name,value} = e.target
            addVisitor.forEach((item,index)=>{
                if(index==fromIndex){
                    let obj = {...item,[name]:value}
                    arr.push(obj)
                }else{
                  arr.push(item)
                }
            })
            setAddVisitor([...arr])
            setAddVisitorError([])
        }
        
    }

    const handleDeleteIcon = (invId,userId)=>{
        setAlert({
            status:true,
            data:alertData[0],
            id:userId
        })
        
    }
    const handleConfirm = ()=>{
        deleteVisitor({
            invitations_id:inviteDetails?.baseDetails?.invitations_id,
            user_id:alert?.id
        })
    }

    const submitMultipleVisitors = ()=>{
        let data = addVisitor.map((value)=>{
            return {
                first_name:value.firstname,
                last_name:value.lastname,
                name:`${value.firstname} ${value.lastname}`,
                email:value.email,
                invitations_id:inviteDetails?.baseDetails?.invitations_id,
                user_id:getUserId(value.email)
            }
        })
        addMultipleVisitor({
             userArrayList:JSON.stringify(data) 
        })
    }

    const getUserId = (mail)=>{
     let filter = existingMailList.filter((value)=>{
        return value.email == mail
     })
     if(filter.length>0){
        return filter[0].id
     }else{
        return "new"
     }
    }
    return (
        <Grid container sx={{ background: "rgba(143, 146, 161,0.05)",pb:5 }}>
            <Grid item xs={1.5} />
            <Grid item xs={9}>
            <Grid container sx={{ mt: 8,backgroundColor:"#FFFFFF"}}>
            {alert.status &&  <Faultalert confirmFunc={alert.multiple ? submitMultipleVisitors : handleConfirm} closeFunc={setAlert}  alertData={alert.data} /> }
           <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />

           <Grid item xs={12} sx={{backgroundColor:"rgba(143, 146, 161,0.05)"}}>
               <Box >
                   <BackIcon onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
                   <Typography sx={{ color: " #0F2C6F", fontSize: "26px", my: 1.5 }}>Invited Visitor</Typography>
                  {editStatus &&  <Typography sx={{ color: "#6F80A9", fontSize: "14px",mb:1.5 }}>You may now add/ edit or delete visitors. Please note that any changes you reflect on this page will be updated immediately.</Typography> }
               </Box>
           </Grid>
           <Grid item xs={12} >
               <Typography sx={{ color: "#0F2C6F", fontSize: "18px", fontFamily: 'Inter', fontWeight: 500,p:2 }}>
                   {inviteDetails.baseDetails.invitations_title}
               </Typography>
           </Grid>
           <Grid item xs={3} sx={{ mt: 2}}>
               <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500,pl:2 }}>
                   Description
               </Typography>
           </Grid>
           <Grid item xs={9} sx={{ mt: 2 }}>
               <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400,pl:2 }}>
                   {inviteDetails.baseDetails.notes_to_visitor}
               </Typography>
           </Grid>
           <Grid item xs={12} sx={{ mt: 2 }}>
               <Divider />
           </Grid>
           <Grid item xs={12} sx={{ mt: 2 }}>
               <Typography sx={{ color: "#0F2C6F", fontSize: "18px", fontFamily: 'Inter', fontWeight: 500,pl:2 }}>
                   Invited by
               </Typography>
           </Grid>
           <Grid item xs={4} sx={{ mt: 2,pl:2 }}>
               <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>
                   {inviteDetails.baseDetails.invited_by_name}
               </Typography>
               <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                   {inviteDetails.baseDetails.invited_by_role}
               </Typography>
           </Grid>
           <Grid item xs={4} sx={{ mt: 2 }}>
               <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                   Office: {inviteDetails.baseDetails.country_code}  {inviteDetails.baseDetails?.phone_no}
               </Typography>
               <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                   {inviteDetails.baseDetails.email}
               </Typography>
           </Grid>
           <Grid item xs={4} sx={{ mt: 2 }}>
               {/* <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                   HR Manager
               </Typography> */}
               <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                   {`Office Unit: #${inviteDetails?.baseDetails.level_name} - ${inviteDetails.baseDetails.unit_name}`}
               </Typography>
           </Grid>
           <Grid item xs={12} sx={{ mt: 2 }}>
               <Divider />
           </Grid>
           <Grid item xs={12} sx={{ mt: 2,pl:2 }}>
               <Box sx={{ height: 100, border: '1px solid #DBDFE9', borderRadius: '6px', width: 500, display: 'flex', justifyContent: 'start' }}>
                   <Box sx={{ p: 3 }}>
                       <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontFamily: 'Inter', fontWeight: 500, }}>
                           Access Date
                       </Typography>
                       <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                           Start: {moment.utc(inviteDetails?.baseDetails.from_date_time).local().format("DD-MM-yyyy HH:mm")} 
                       </Typography>
                       <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                           End: {moment.utc(inviteDetails?.baseDetails.to_date_time).local().format("DD-MM-yyyy HH:mm")} 
                       </Typography>

                   </Box>
                   <Box sx={{ p: 3, pl: 10 }}>
                       <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                           {inviteDetails.visitorDetails.length} Visitor
                       </Typography>
                       <Typography sx={{ color: "#0F2C6F", fontSize: "12px", fontFamily: 'Inter', fontWeight: 400, }}>
                           {`Location: #${inviteDetails?.baseDetails.level_id} - ${inviteDetails.baseDetails.unit_name}`}
                       </Typography>
                   </Box>
               </Box>
           </Grid>
           <Grid item xs={12} sx={{ mt: 2,pl:2 }}>
               <TableContainer>
                   <Table>
                       <TableHead>
                           <TableRow>
                               <TableCell sx={{ fontFamily: 'Inter', color: '#0F2C6F', fontSize: '12px', fontWeight: 500, }}>
                                   Name
                               </TableCell>
                               <TableCell sx={{ fontFamily: 'Inter', color: '#0F2C6F', fontSize: '12px', fontWeight: 500, }}>
                                   Email
                               </TableCell>
                               <TableCell sx={{ textAlign: 'center', fontFamily: 'Inter', color: '#0F2C6F', fontSize: '12px', fontWeight: 500, }}>
                                   Access Unit
                               </TableCell>
                               <TableCell></TableCell>
                           </TableRow>
                       </TableHead>
                       <TableBody>
                           {inviteDetails.visitorDetails.map((item, index) => (
                               <StyledTableRow key={index}>
                                   <TableCell sx={{ borderBottom: 'none',color: '#0F2C6F' }}>
                                       {item.name}
                                   </TableCell>
                                   <TableCell sx={{ borderBottom: 'none',color: '#0F2C6F' }}>
                                       {item?.email}
                                   </TableCell>
                                   <TableCell sx={{ borderBottom: 'none', textAlign: 'center', }}>
                                       <Typography sx={{color: "#0F2C6F"}}>
                                        {item?.access_unit}
                                        </Typography>
                                   </TableCell>
                                   <TableCell>
                                        {editStatus && <DeleteIcon onClick={()=>handleDeleteIcon(item.sent_invitations_id,item.user_id)} /> }
                                   </TableCell>
                               </StyledTableRow>
                           ))}
                       </TableBody>
                   </Table>
               </TableContainer>
           </Grid>
           <Grid item xs={12}>
            {addVisitor?.map((value,index)=>(
                <>
                    <Grid container columnSpacing={2} sx={{pl:2,my:4}}>   
                        <Grid item xs={4}>
                            <Typography  sx={{fontSize:'14px',color:"#0F2C6F",fontWeight:550,mb:1}}>E-mail</Typography>
                            <Autocomplete disableClearable freeSolo name='email' value={value.email} onInputChange={(e,value)=>handleDetails('email',index,value)}  options={options} renderInput={(params)=><TextField {...params}  />} />
                            <Typography sx={{color:"red",fontSize:"14px"}}>{addVisitorError[index]?.email}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={{fontSize:'14px',color:"#0F2C6F",fontWeight:550,mb:1}}>First Name</Typography>
                            <OutlinedInput disabled={value.disabled} name='firstname' value={value.firstname} onChange={(e)=>handleDetails(e,index)} />
                            <Typography sx={{color:"red",fontSize:"14px"}}>{addVisitorError[index]?.firstname}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography sx={{fontSize:'14px',color:"#0F2C6F",fontWeight:550,mb:1}}>Last Name</Typography>
                            <OutlinedInput disabled={value.disabled} name='lastname'  value={value.lastname} onChange={(e)=>handleDetails(e,index)} />
                            <Typography sx={{color:"red",fontSize:"14px"}}>{addVisitorError[index]?.lastname}</Typography>
                        </Grid>
                        
                        <Grid item xs={2} sx={{display:"flex",alignItems:'center'}}>
                            <MinusIcon onClick={()=>handleMinusIcon(value)} style={{marginTop:"25px"}} />
                        </Grid>
                    </Grid>
                </>
            ))}
            <Typography sx={{color:"red",textAlign:"center"}}>{uniqueMailErr}</Typography>
           </Grid>
            <Grid item xs={12} sx={{display:"flex",justifyContent:"center"}}>
               {editStatus &&  <Button onClick={()=>handleAddVisitor('add')} sx={{border:"1px solid #AAC3FF",borderRadius:"6px",color:"#0F2C6F",textTransform:"capitalize",my:2}} endIcon={<AddIcon/>}>Add Visitor</Button> }
            </Grid>
           <Grid item xs={12} sx={{ mt: 2,pl:2 }}>
               <FormControlLabel
                   label={<Typography sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 500, fontFamily: 'Inter' }}>Notify the inviter when first guest has arrived.</Typography>}
                   control={<Checkbox disabled checked={true} icon={<CheckboxIcon />}
                   />} />
           </Grid>

           {isEdit=='true' && <Grid item xs={12} sx={{ mt: 5, display: 'flex', justifyContent: 'end',backgroundColor:"rgba(143, 146, 161,0.05)" }}>
               {editStatus ? 
                    <Button variant='contained'
                        onClick={()=>{
                            if(addVisitor.length>0){
                                handleAddVisitor('submit')
                               
                            }
                           
                        }}
                        sx={{
                            textTransform: 'capitalize',
                            color: '#FFFFFF',
                            borderRadius: '8px',
                            height: '48px',
                            backgroundColor: '#123FA9',
                            my:3,
                            '&:hover': { backgroundColor: '#123FA9' }
                        }}
                >Confirm</Button> : 
               
               <Button variant='contained'
                   sx={{
                       textTransform: 'capitalize',
                       color: '#FFFFFF',
                       borderRadius: '8px',
                       height: '48px',
                       backgroundColor: '#123FA9',
                       my:3,
                       '&:hover': { backgroundColor: '#123FA9' }
                   }}
                   onClick={() => setEditStatus(!editStatus)}
               >Update Visitor</Button>
                }
           </Grid>
            }
           </Grid>
            </Grid>
            <Grid item xs={1.5} />
        </Grid>
        
        
    )
}

const mapStateToProps = (state) => {
    return {
        teantInviteDetails: state.ReceptionReducer.teantInviteDetails,
        deleteVisitorStatus:state.ReceptionReducer.deleteVisitorStatus,
        addMultipleVisitorStatus:state.ReceptionReducer.addMultipleVisitorStatus,
        visitorMail:state.ReceptionReducer.visitorMail
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getTenantVisitorsDetails: (data) => { dispatch(visitorsAction.getTenantVisitorsDetails(data)) },
        deleteVisitor :(data)=>(dispatch(visitorsAction.deleteVisitor(data))),
        addMultipleVisitor:(data)=>(dispatch(visitorsAction.addMultipleVisitor(data))),
        receptionStateToInitial: () => {dispatch(visitorsAction.receptionStateToInitial())},
        getAllUsers:(data)=>(dispatch(visitorsAction.getAllUsers(data)))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewInvite)