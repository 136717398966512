import axios from "axios";
import { createAction } from "redux-actions";

export const loginInitiate = createAction("LOGIN_INITIATE");
export const loginSuccess = createAction("LOGIN_SUCCESS");
export const loginFailure = createAction("LOGIN_FAILURE");
export const loginStateNull = createAction("LOGIN_STATE_NULL");

export const loginService = (requestData) => {
  return (dispatch) => {
    dispatch(loginInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/login`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data?.response_json?.token) {
          dispatch(loginSuccess(response.data));
          console.log('loginData ',response.data?.response_json)
          localStorage.setItem(
            "userToken",
            response.data?.response_json?.token
          );
          localStorage.setItem(
            "tokenExpiryTime",
            response.data?.response_json?.token_expiry_time
          );
          localStorage.setItem(
            "refreshToken",
            response.data?.response_json?.refresh_token
          );
          localStorage.setItem(
            "refreshTokenExpiryTime",
            response.data?.response_json?.refresh_token_expiry_time
          );
          localStorage.setItem(
            "userData",
            JSON.stringify(response.data?.response_json?.userData)
          );
        } else if (response.data?.response_json?.success_message === 'New User Login') {
          dispatch(loginFailure(response.data?.response_json?.success_message));
        } else {
          dispatch(loginFailure(response.data?.error_json?.error_message));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          dispatch(loginFailure('Something Went Wrong!'));
        } else {
          dispatch(loginFailure(error.response.data));
        }
      });
  };
};

export const updateToken = (requestData) => {
  return (dispatch) => {
    // dispatch(loginInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/UpdateToken`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data?.response_json?.token) {
          // dispatch(loginSuccess(response.data));
          localStorage.setItem(
            "userToken",
            response.data?.response_json?.token
          );
          localStorage.setItem(
            "tokenExpiryTime",
            response.data?.response_json?.token_expiry_time
          );
          localStorage.setItem(
            "refreshToken",
            response.data?.response_json?.refresh_token
          );
          localStorage.setItem(
            "refreshTokenExpiryTime",
            response.data?.response_json?.refresh_token_expiry_time
          );

        } else if (response.data?.response_json?.success_message === 'New User Login') {
          // dispatch(loginFailure(response.data?.response_json?.success_message));
        } else {
          // dispatch(loginFailure(response.data?.error_json?.error_message));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          // dispatch(loginFailure('Something Went Wrong!'));
        } else {
          // dispatch(loginFailure(error.response.data));
        }
      });
  };
};


export const sendOTPInitiate = createAction("SEND_OTP_INITIATE");
export const sendOTPSuccess = createAction("SEND_OTP_SUCCESS");
export const sendOTPFailure = createAction("SEND_OTP_FAILURE");
export const sendOTPService = (requestData) => {
  return (dispatch) => {
    dispatch(sendOTPInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/sendOtp`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data) {
          dispatch(sendOTPSuccess(response.data));
        } else {
          dispatch(sendOTPFailure(response.data?.error_json));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          dispatch(sendOTPFailure('Something Went Wrong!'));
        } else {
          dispatch(sendOTPFailure(error.response.data));
        }
      });
  };
};




export const verifyOTPInitiate = createAction("VERIFY_OTP_INITIATE");
export const verifyOTPSuccess = createAction("VERIFY_OTP_SUCCESS");
export const verifyOTPFailure = createAction("VERIFY_OTP_FAILURE");
export const verifyOTPService = (requestData) => {
  return (dispatch) => {
    dispatch(verifyOTPInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/verifyOtp`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data) {
          dispatch(verifyOTPSuccess(response.data));
        } else {
          dispatch(verifyOTPFailure(response.data?.error_json));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          dispatch(verifyOTPFailure('Something Went Wrong!'));
        } else {
          dispatch(verifyOTPFailure(error.response.data));
        }
      });
  };
};

export const passwordResetInitiate = createAction("PASSWORD_RESET_INITIATE");
export const passwordResetSuccess = createAction("PASSWORD_RESET_SUCCESS");
export const passwordResetFailure = createAction("PASSWORD_RESET_FAILURE");
export const passwordResetService = (requestData) => {
  return (dispatch) => {
    dispatch(passwordResetInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/changePassword`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data?.message) {
          dispatch(passwordResetSuccess(response.data));
        } else {
          dispatch(passwordResetFailure(response.data?.error_json));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          dispatch(passwordResetFailure('Something Went Wrong!'));
        }else{
          dispatch(passwordResetFailure(error.response.data));
        }
       
      });
  };
};
export const loginNullService = () => {
  return (dispatch) => {
    dispatch(loginStateNull());
  };
};

export const ForgotPassword = (requestData) => {
  return (dispatch) => {
    dispatch(sendOTPInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/ForgotPassword`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data?.response_json) {
          dispatch(sendOTPSuccess(response.data?.response_json));
        } else {
          dispatch(sendOTPFailure(response.data?.error_json));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          dispatch(sendOTPFailure('Something Went Wrong!'));
        } else {
          dispatch(sendOTPFailure(error.response.data));
        }
      });
  };
};

export const verifyForgotOTPService = (requestData) => {
  return (dispatch) => {
    dispatch(verifyOTPInitiate());
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/verifyForgotOtp`,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        if (response.data?.response_json) {
          dispatch(verifyOTPSuccess(response.data?.response_json));
        } else {
          dispatch(verifyOTPFailure(response.data?.error_json));
        }
      })
      .catch((error) => {
        if (error.response.response === "") {
          dispatch(verifyOTPFailure('Something Went Wrong!'));
        } else {
          dispatch(verifyOTPFailure(error.response.data));
        }
      });
  };
};