import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
 import { connect } from 'react-redux'
import * as DashboardAction from "../../redux/actions/Dashboard Action/index"
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import {ReactComponent as RefreshIcon} from "../../Assets/icons/ColoredRefreshIcon.svg";
import { preventDatePickerKeyboard } from '../../Utils/utils'
import {ReactComponent as PriorityHighTwoToneIcon} from "../../Assets/icons/Warning.svg";
import moment from 'moment'

const getLastMonthDate = () => {
    let date = new Date();

    date.setMonth(date.getMonth() - 1);

    return date;
}


const FCCDashboard = ({getDbData,dbData,dbDataIsLoadingData}) => {

    const [dates, setDates] = useState({startDate: getLastMonthDate(), endDate: Date.now() });
    const [updatedDate, setUpdatedDate] = useState('');   
    
    const [pendingUpdateOption, setPendingUpdateOption] = useState(0);
    const [ongoingTicketsOptions, setOngoingTicketOptions] = useState(0);
    const [completedTicketsOptions, setCompletedTicketsOptions] = useState(0);

    console.log(dbData);


    useEffect(() => {
        getDbData({
            "start_date": moment(dates.startDate).format('YYYY-MM-DD'),
            "end_date": moment(dates.endDate).format('YYYY-MM-DD'),
            "limit": 10,
            "offset": 0,
            "currentMonth": ""
        })
    }, [])

    useEffect(() => {
        if(dbData){
            setUpdatedDate(moment(Date.now()).format('DD MMM YYYY, HH:mm'))
        }
    }, [dbData])


    const handleRefresh = () => {
        getDbData({
            "start_date": moment(dates.startDate).format('YYYY-MM-DD'),
            "end_date": moment(dates.endDate).format('YYYY-MM-DD'),
            "limit": 10,
            "offset": 0,
            "currentMonth": ""
        })
    }

    const handleDate = (e,name) => {
        console.log(e, name);
        setDates(prevValue => ({...prevValue, [name]: e}))
    }

  return (
    <div style={{ background: '#F9FAFA', height: 'auto'}}>
    <Box sx={{ py: 3, px:2 }}>

        <Box sx={{width: '100%', display:'flex' ,justifyContent: 'flex-end', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <DesktopDatePicker  inputFormat="dd/MM/yyyy" 
            // minDate={dates.startDate && new Date(dates.startDate)}
             disableFuture components={{
                OpenPickerIcon:DatePickerIcon
            }}   renderInput={(params) => <TextField sx={{width: 150}} onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} value={dates.startDate} onChange={(e)=>handleDate(e,'startDate')} />
            <DesktopDatePicker   inputFormat="dd/MM/yyyy" minDate={dates.startDate && new Date(dates.startDate)} disableFuture components={{
                OpenPickerIcon:DatePickerIcon
            }}   renderInput={(params) => <TextField sx={{width: 150}} onKeyDown={(e)=>preventDatePickerKeyboard(e)} {...params} />} value={dates.endDate} onChange={(e)=>handleDate(e,'endDate')} />
            </Box>
            <Box sx={{p: 3, display: 'flex', alignItems: "center", gap: '50px',border: '1px solid #DBDFE9', borderRadius: "12px", background: "#ffffff"}}>
        <Box>
            <Typography sx={{fontSize: '20px', fontWeight: 500, color: '#6F80A9'}}>Visitors in the building</Typography>
            <Box sx={{display: 'flex',alignItems: "center", gap: 1}}>
                <Typography sx={{fontSize: "14px", color: '#6F80A9'}}>{updatedDate}</Typography>
                <Box sx={{display: "flex", gap: '4px', alignItems: "center",cursor: "pointer", userSelect: 'none'}} onClick={() => handleRefresh() }>
                                <RefreshIcon className={ dbDataIsLoadingData && "rotate_animation"} />
                            <Typography sx={{color: "#0F2C6F", textDecoration: "underline", fontSize: "14px"}}>                                 
                            Refresh</Typography>
                            </Box>
            </Box>
        </Box>

        <Box sx={{display: 'flex', alignItems :'center', gap: '8px'}}>
            <Typography sx={{fontSize: '36px', fontWeight: 500, color: "#2764F4"}}>0</Typography>
            <Typography sx={{fontSize: '16px', fontWeight: 500, color: '#6F80A9'}}>/ {dbData?.visitors }</Typography>
        </Box>
    </Box>
        </Box>

        <Box sx={{  minHeight: '',width: '100%', display: 'grid', gridTemplateColumns: '32% 32% 32%',justifyContent: 'space-between', mt: 2 }}>

            <Box sx={{ background:'white', p: '24px'}}>
                <Box sx={{display: 'flex', alignItems: "center", justifyContent: 'space-between'}}  >
                    <Box>
                        <Typography sx={{fontSize: '18px',fontWeight: 500, color: '#0F2C6F'}}>Pending Update</Typography>
                        <Typography sx={{fontSize: '14px',fontWeight: 400, color: '#0F2C6F'}}>Pending update status from you.</Typography>
                    </Box>
                    <Button variant='outlined' sx={{textTransform: 'none', color: "#0F2C6F"}}>View All</Button>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', gap: 1,my: 2, width: '100%'}}>
                    {['Fault reports', 'Service request'].map((item, index) => <Typography sx={{backgroundColor: index === pendingUpdateOption ? '#123fa9' : 'transparent', color: index === pendingUpdateOption ? 'white' :'#6F80A9' , border: '1px solid #6F80A9', px:2,py: 1, borderRadius:'24px', userSelect: 'none', cursor :'pointer',textAlign: 'center', fontSize: '12px'}} onClick={() => setPendingUpdateOption(prevValue => prevValue !== index && index)}>{item}</Typography>)}
                </Box>                    

                <Box sx={{display:"grid", placeItems: "center", height: 450}}>
                    <center>
                    <PriorityHighTwoToneIcon />
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>You are caught up!</Typography>
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Nothing to approve at the moment.</Typography>
                    </center>
                </Box>
            </Box>
            <Box sx={{ background:'white', p: '24px'}}>
                <Box sx={{display: 'flex', alignItems: "center", justifyContent: 'space-between'}}  >
                    <Box>
                        <Typography sx={{fontSize: '18px',fontWeight: 500, color: '#0F2C6F'}}>Ongoing Tickets</Typography>
                        <Typography sx={{fontSize: '14px',fontWeight: 400, color: '#0F2C6F'}}>Ongoing tickets for keep in view.</Typography>
                    </Box>
                    <Button variant='outlined' sx={{textTransform: 'none', color: "#0F2C6F"}}>View All</Button>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', gap: 1,my: 2, width: '100%'}}>
                    {['Fault reports', 'Service request'].map((item, index) => <Typography sx={{backgroundColor: index === ongoingTicketsOptions ? '#123fa9' : 'transparent', color: index === ongoingTicketsOptions ? 'white' :'#6F80A9' , border: '1px solid #6F80A9', px:2,py: 1, borderRadius:'24px', userSelect: 'none', cursor :'pointer',textAlign: 'center', fontSize: '12px'}} onClick={() => setOngoingTicketOptions(prevValue => prevValue !== index && index)}>{item}</Typography>)}
                </Box>                    

                <Box sx={{display:"grid", placeItems: "center", height: 450}}>
                    <center>
                    <PriorityHighTwoToneIcon />
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>You are caught up!</Typography>
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Nothing to approve at the moment.</Typography>
                    </center>
                </Box>
            </Box>
            <Box sx={{ background:'white', p: '24px'}}>
                <Box sx={{display: 'flex', alignItems: "center", justifyContent: 'space-between'}}  >
                    <Box>
                        <Typography sx={{fontSize: '18px',fontWeight: 500, color: '#0F2C6F'}}>Completed Tickets</Typography>
                        <Typography sx={{fontSize: '14px',fontWeight: 400, color: '#0F2C6F'}}>Tickets that have been completed.</Typography>
                    </Box>
                    <Button variant='outlined' sx={{textTransform: 'none', color: "#0F2C6F"}}>View All</Button>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center', gap: 1,my: 2, width: '100%'}}>
                    {['Fault reports', 'Service request'].map((item, index) => <Typography sx={{backgroundColor: index === completedTicketsOptions ? '#123fa9' : 'transparent', color: index === completedTicketsOptions ? 'white' :'#6F80A9' , border: '1px solid #6F80A9', px:2,py: 1, borderRadius:'24px', userSelect: 'none', cursor :'pointer',textAlign: 'center', fontSize: '12px'}} onClick={() => setCompletedTicketsOptions(prevValue => prevValue !== index && index)}>{item}</Typography>)}
                </Box>                    

                <Box sx={{display:"grid", placeItems: "center", height: 450}}>
                    <center>
                    <PriorityHighTwoToneIcon />
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>You are caught up!</Typography>
                    <Typography sx={{color:"#6F80A9",fontSize:"14px"}}>Nothing to approve at the moment.</Typography>
                    </center>
                </Box>
            </Box>
            

        </Box>
    </Box>
    </div>
  )
}

 
const mapStateToProps = (state)=>{
    return{
        dbData:state.DashboardReducer.data,
        getRealtimeTurnStileData:state.DashboardReducer.getRealtimeTurnStile,
        dbDataIsLoadingData:state.DashboardReducer.dbDataIsLoading,
    }
  }
  
  const mapDispatchToProps = (dispatch)=>{
    return{
        getDbData:(data)=>dispatch(DashboardAction.getDbData(data)),
        getRealtimeTurnStile: () => dispatch(DashboardAction.getRealtimeTurnStileAction()),
    }
  }

  export default connect(mapStateToProps,mapDispatchToProps)(FCCDashboard)