import { Box, Button, Checkbox, Chip, Grid, IconButton, List, ListItem, ListItemText, Modal, OutlinedInput, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowIcon } from '../../../Assets/icons/back.svg';
import { ReactComponent as BellWatch } from "../../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../../Assets/icons/StructuralFaultBell.svg";
import { ReactComponent as DownloadIcon } from '../../../Assets/icons/DownloadIcon.svg';
import { ReactComponent as CloseIcon } from '../../../Assets/icons/Alertclose.svg';
import { ReactComponent as LogoIcon } from '../../../Assets/icons/Logo.svg';
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as serviceRequestAction from "../../../redux/actions/service_request";
import ToastAlert from "../../common/Alert";
import StatusStepper from '../../common/stepper'
import { checkFCCRole, getLoggedUserData, getTimeStamp } from "../../../Utils/utils";
import Faultalert from "../../common/faultalert";
import { getStatus } from "../../../Utils/ActiveStatus";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { saveAs } from 'file-saver';
import UpdatePopup from "../UpdatePopup";
import Alert from '../../common/faultalert';
import EditQuotation from "../EditQuotation";
import Loader from "../../common/Loader";
import html2PDF from 'jspdf-html2canvas';
import moment from "moment";
import UnAuthorized from "../../common/Unauthorized";
import {ReactComponent as PdfIcon} from '../../../Assets/icons/PDF.svg';
import {ReactComponent as ImageFormatIcon} from '../../../Assets/icons/ImageFormatIcon.svg';
import {ReactComponent as DownloadButtonIcon} from '../../../Assets/icons/OutlinedDownload.svg';


const StyledTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '12px'
})

const StyledPrimaryTypography = styled(Typography)({
    color: '#0F2C6F',
    fontSize: '18px'
})

const StyledTableCell = styled(TableCell)({
    borderBottom: "none",
    color: " #0F2C6F",
    fontSize: "14px",
    fontWeight: 550
})

const StyledTableCellBorder = styled(TableCell)({
    color: " #0F2C6F",
    fontSize: "14px",
    fontWeight: 550,
})

const alertDatas = [
    { id: 1, label: "Withdraw Request", description: 'Are you sure you want to withdraw request', buttonvalue: 'Confirm', buttonStyle: 'blue' },
    { id: 2, label: "Approve Service Request ", description: 'Approve service request?', buttonvalue: 'Confirm', buttonStyle: 'blue' },
    { id: 3, label: "Reject Service Request", description: 'Reject service request?', buttonvalue: 'Confirm', buttonStyle: 'blue' },
    {   id:4,
        label: "Accept Quotation",
        description: "Please ensure all details are entered correctly to prevent any delay to processing of request. Requests are subjected to Singapore Land Tower’s building management approval. By accepting this quotation, we will continue proceeding on the service. ",
        buttonvalue: "Accept"
    }
]

const acceptAlertData = {
    label: "Accept Quotation",
    description: "Please ensure all details are entered correctly to prevent any delay to processing of request. Requests are subjected to Singapore Land Tower’s building management approval. By accepting this quotation, we will continue proceeding on the service. ",
    buttonvalue: "Accept"
}



const ReviewLightBulb = ({ updateQuotationToTenant,quotationUpdatedStatus, tab_value, serviceReqDetails, addServiceReqStatus, serviceRequestByID, setServiceRequestStateNull, updateServiceRequestStatus, serReqUpdateStatus,fromDashboard,updateWatchStatus,updateWatch,notification,isUnAuthorized }) => {
    let { user_id, role_id } = getLoggedUserData();
    const navigate = useNavigate();
    const { service_req_id } = useParams();
    const [reviewData, setReviewData] = useState({})
    const [tenantDetails, setTenantDetails] = useState({})
    const [snackbarStatus, setSnackbarStatus] = useState({ message: '', status: false, color: '', icon: '' })
    const [confirmationAlert, setConfirmAlert] = useState({ status: false, alertData: {}, actionStatus: '' });
    const [openPopup, setOpenPopup] = useState(false);
    const [editQuotation, setEditQuotation] = useState('');
    const [confirmquotation, setConfirmquotation] = useState(false);
    const [notetoTenant, setNoteToTenant] = useState('');
    const [quotationData, setQuotationDatas] = useState([]);
    const [quotationTotal, setQuotationTotal] = useState();
    const [vatTotal,setVatTotal] = useState()
    const [openAcknowledgeAlert,setOpenAcknowledgeAlert] = useState(false);
    const [acknowledgeFeedback,setAcknowledgeFeedback] = useState('');
    const [error,setError] = useState({acknowledge:"",quotation:""})
    const enableButtonStatus = ["closed","rejected","withdraw"]
    const [updateButtonStatus,setUpdateButtonStatus] = useState(false);
    const [nextUpdateStatus,setNextUpdateStatus] = useState(null); 
    let localStorageData = JSON.parse(localStorage.getItem("userData"));
    const [terms,setTerms] = useState(null);
    const [loaderStatus,setLoaderStatus] = useState(true);
    const [description, setDescription] = useState("");
    const [sapDetails, setSapDetails] = useState({})
    const [showSapDetails, setShowSapDetails] = useState(true);

    useEffect(() => {
        serviceRequestByID({ service_requests_id: service_req_id })
    }, [updateWatchStatus,editQuotation,service_req_id,notification])

    useEffect(() => {
        if (serviceReqDetails?.serviceRequestsArrayList) {
            setReviewData(serviceReqDetails?.serviceRequestsArrayList[0])
            getUpdateButtonStatus(serviceReqDetails?.serviceRequestsArrayList[0])
            setTenantDetails(serviceReqDetails?.companiesArrayList[0])
            setLoaderStatus(false)
        }else if(isUnAuthorized!=false){
            setLoaderStatus(false)
        }
        if(serviceReqDetails?.nextStatusListArrayList){
            setNextUpdateStatus(serviceReqDetails?.nextStatusListArrayList)
        }
        if(serviceReqDetails?.termsArrayList){
            setTerms(JSON.parse(serviceReqDetails?.termsArrayList[0].config_value))
        }
        if (serviceReqDetails?.lightBulbQuotationArrayList!=false && serviceReqDetails?.lightBulbQuotationArrayList?.length>0 ) {
            setQuotationDatas(serviceReqDetails?.lightBulbQuotationArrayList);
            let newGrandTotal = 0;
            let totalvat = 0;
             serviceReqDetails?.lightBulbQuotationArrayList.forEach(element => {
                if (element.row_type === 'grand_total_row') {
                    newGrandTotal = element.grand_total_price
                    totalvat = element.vat_price
                }

            });
            setQuotationTotal(newGrandTotal)
            setVatTotal(totalvat)
        }

        if(serviceReqDetails?.service_request_sap_invoice){
            setSapDetails(serviceReqDetails?.service_request_sap_invoice);
        }

    }, [serviceReqDetails])

    useEffect(() => {
        if (serReqUpdateStatus?.response_status==1) {
            closeFunc()
            setOpenPopup(false)
            setSnackbarStatus({ message: serReqUpdateStatus?.message, status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
                navigate(`/service_request_list/${tab_value}`)
            }, 3000);
        } else if (serReqUpdateStatus?.response_status==0) {
            closeFunc()
            setSnackbarStatus({ message: serReqUpdateStatus?.message, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 3000);
        }else if (serReqUpdateStatus!=null) {
            closeFunc()
            setSnackbarStatus({ message: serReqUpdateStatus, status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 3000);
        }
    }, [serReqUpdateStatus])
    useEffect(() => {
        if (quotationUpdatedStatus === 'Success') {
            serviceRequestByID({ service_requests_id: service_req_id })
            setEditQuotation('')
            setSnackbarStatus({ message: 'Qutation Updated Successfully', status: true, color: 'green', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 3000);
        } else if (quotationUpdatedStatus !== null) {
            setSnackbarStatus({ message: 'Qutation Updated Failed!', status: true, color: 'red', icon: 'close' })
            setServiceRequestStateNull()
            setTimeout(() => {
                removeSnackbar()
            }, 3000);
        }
    }, [quotationUpdatedStatus])

    useEffect(()=>{
        if(updateWatchStatus=="Service Request watch updated successfully"){
         setSnackbarStatus({ message: updateWatchStatus, status: true, color: 'green', icon: 'close' })
          setTimeout(()=>{
             removeSnackbar()
            setServiceRequestStateNull()
          },3000)
        }else if(updateWatchStatus!=null){
         setSnackbarStatus({ message:updateWatchStatus , status: true, color: 'red', icon: 'close' })
          setTimeout(()=>{
             removeSnackbar()
            setServiceRequestStateNull()
          },3000)
        }
      },[updateWatchStatus])

    const removeSnackbar = () => {
        setSnackbarStatus({ message: '', status: false, color: '', icon: '' })
    }

    const downloadPDF = () => {
        let data = document.getElementById('print')
        html2PDF(data,{
              jsPDF: {
                format: 'a4',
              },    
              imageType: 'image/jpeg',
              output: `${reviewData?.ticket_id } ${moment(new Date()).format('DD/MM/yyyy')}.pdf`,
              
            })
    }

    const downloadUploadedSign = (filePath, fileName) => {
        if (filePath !== '') {
            window.open(`${process.env.REACT_APP_ASSETS_PATH}/${filePath}`, '_blank');
        }

    }
    const updateStatus = (actionStatus, actionStatusTypeId) => {
        if (actionStatusTypeId < 4) {
            setConfirmAlert({ status: true, alertData: alertDatas[actionStatusTypeId], actionStatus: actionStatus })
        }

    }
    const handleFileDownload = (actionType,fileName) => {
        let i;
        if (actionType === 'All') {
            for (i = 0; i < serviceReqDetails?.service_request_files?.length; i++) {
                saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${serviceReqDetails?.service_request_files[i]?.path}`, `${serviceReqDetails?.service_request_files[i]?.original_name}`)
            }
        } else {
            saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${actionType}`, `${fileName}`)
        }
    }

    const getUpdateButtonStatus=(reviewDatas)=>{
        let filterData = enableButtonStatus.filter((value)=>{
            return value===reviewDatas.status
        });

        setUpdateButtonStatus(filterData.length>0)

    }

    const renderActButtons = () => {

        if (parseInt(role_id) === 3 || parseInt(role_id)===2 || parseInt(role_id)===14 || checkFCCRole()) {
            if (reviewData?.status === 'submitted') {
                return (<> <Button variant="outlined" sx={{
                    background: '#FFFFFF',
                    color: '#123FA9',
                    height: '50px',
                    mr: 2,
                    fontSize: '14px',
                    textTransform: 'none',
                }} onClick={() => updateStatus('rejected', 2)}>Reject</Button>
                    <Button variant="contained" sx={{
                        background: '#123FA9',
                        color: '#FFFFFF',
                        height: '50px',
                        fontSize: '14px',
                        textTransform: 'none',
                    }} onClick={() => updateStatus('approved', 1)}>Approve</Button></>
                )
            } else {

                return (<Button
                    disabled={updateButtonStatus}
                     variant="contained" sx={{
                    background: '#123FA9',
                    color: '#FFFFFF',
                    height: '50px',
                    width: '114px',
                    fontSize: '14px',
                    pr: 0,
                    pl: 0,
                    textTransform: 'none',

                }} onClick={() => setOpenPopup(true)}>Update Status</Button>)
            }
        } else {
            if ((parseInt(role_id) !== 3 && parseInt(role_id)!==2 && parseInt(role_id)!==14) && reviewData?.status === 'submitted') {
                return (
                    <Button variant="contained" sx={{
                        background: '#BD0251',
                        color: '#FFFFFF',
                        height: '50px',
                        width: '114px',
                        fontSize: '14px',
                        pr: 0,
                        pl: 0,
                        textTransform: 'none',
                        "&:hover": { backgroundColor: "#BD0251" }
                    }} onClick={() => updateStatus('withdraw', 0)}>Withdraw</Button>
                )

            }else if(parseInt(role_id)===4 && reviewData?.status==="resolved"){
                return (<Button variant="contained" sx={{
                    background: '#123FA9',
                    color: '#FFFFFF',
                    height: '50px',
                    width: '114px',
                    fontSize: '14px',
                    pr: 0,
                    pl: 0,
                    textTransform: 'none',

                }} onClick={() => setOpenAcknowledgeAlert(true)}>Acknowledge</Button>)
            }
        }

    }
    const renderQuotation = () => {
        return (
            <>
                {(quotationData!==false&&quotationData.length>0) && 
                <Box sx={{ backgroundColor: "#FFFFFF", p: 2 }}>
                    <Box sx={{display:"flex",justifyContent:'space-between'}}>
                        <Box sx={{display:'flex'}}>
                            <Typography sx={{ color: "#0F2C6F", fontSize: "26px" }}>Estimated Quotation - </Typography>
                            <Typography  sx={{ color: "#0F2C6F", fontSize: "16px",mt:1.1,ml:1 }}> {reviewData?.ticket_id}</Typography>
                        </Box>
                        <Box sx={{mt:0.6}}>
                            <LogoIcon />
                        </Box>
                    </Box>

                    <Box sx={{ border: " 1px solid #DBDFE9", borderRadius: "6px", p: 2, mt: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                            <Box>
                                <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: 600 }}>Bill Summary</Typography>
                                <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 0.5 }}>Billing Method : Company</Typography>
                            </Box>
                            {
                           
                                (localStorageData.role_id === 3 || localStorageData.role_id === 2 || localStorageData.role_id === 14) && reviewData?.status!=='quotation_accepted' && reviewData?.status!=="work_in_progress" && reviewData?.status!=='quotation_updated' && reviewData?.status!=="acknowledgement" && reviewData?.status!=='closed' && reviewData?.status!=="work_completed" && reviewData?.status!=="withdraw" &&  reviewData?.status!=="resolved" && reviewData?.status !== "rejected" &&
                                <Box data-html2canvas-ignore='true'>
                                    <Button onClick={() => setEditQuotation('lightBulb')} variant="outlined" sx={{ color: "#0F2C6F", border: "1px solid #AAC3FF", borderRadius: "6px", textTransform: "capitalize", mr: 2 }}>Edit</Button>
                                    <Button onClick={() => setConfirmquotation(true)} variant="contained" sx={{ backgroundColor: "#123FA9", color: "#FFFFFF", borderRadius: "6px", textTransform: "capitalize" }}>Update to Tenant</Button>
                                </Box>
                            }

                        </Box>
                        {/* <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 2 }}>Total Working Hours:{totalWorkHours}</Typography> */}
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell >Product Cost Breakdown</StyledTableCell>
                                        <StyledTableCell>Qty.</StyledTableCell>
                                        <StyledTableCell>Price/Unit</StyledTableCell>
                                        <StyledTableCell>GST({serviceReqDetails?.lightBulbQuotationArrayList && serviceReqDetails?.lightBulbQuotationArrayList[0]?.vat_percentage ? parseInt(serviceReqDetails?.lightBulbQuotationArrayList[0]?.vat_percentage) : ''}%)</StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'end'}}>Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        quotationData.map((value) => (
                                            ( value.row_type == "price_list_row") && <TableRow>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.light_bulb_products_name}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.quantity}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.price}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.vat_price}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px",textAlign:'end' }}>{value.grand_total_price}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell >Service Cost Breakdown</StyledTableCell>
                                        <StyledTableCell>Hrs</StyledTableCell>
                                        <StyledTableCell>Price/Hrs</StyledTableCell>
                                        <StyledTableCell>GST({serviceReqDetails?.lightBulbQuotationArrayList && serviceReqDetails?.lightBulbQuotationArrayList[0]?.vat_percentage ? parseInt(serviceReqDetails?.lightBulbQuotationArrayList[0]?.vat_percentage) : ''}%)</StyledTableCell>
                                        <StyledTableCell sx={{textAlign:'end'}}>Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        quotationData.map((value) => (
                                            ( value.row_type == "technician_row") && <TableRow>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.light_bulb_products_name || 'Technician'}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.quantity}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.price}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px" }}>{value.vat_price}</TableCell>
                                                <TableCell sx={{ color: "#0F2C6F", fontSize: "14px",textAlign:'end' }}>{value.grand_total_price}</TableCell>
                                            </TableRow>
                                            
                                        ))
                                    }
                                    <TableRow>
                                        <StyledTableCellBorder>
                                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: "600" }}>Total Amount </Typography>
                                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px" }}>Without GST included.</Typography>
                                        </StyledTableCellBorder>
                                        <StyledTableCellBorder></StyledTableCellBorder>
                                        <StyledTableCellBorder></StyledTableCellBorder>
                                        <StyledTableCellBorder></StyledTableCellBorder>
                                        <StyledTableCellBorder sx={{ color: "#0F2C6F", fontSize: "20px",textAlign:'end' }}>{(quotationTotal - vatTotal)?.toFixed(2)}</StyledTableCellBorder>
                                    </TableRow>
                                    <TableRow>
                                        <StyledTableCellBorder>
                                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: "600" }}>Total GST Amount </Typography>
                                            {/* <Typography sx={{ color: "#0F2C6F", fontSize: "14px" }}>Without VAT included.</Typography> */}
                                        </StyledTableCellBorder>
                                        <StyledTableCellBorder></StyledTableCellBorder>
                                        <StyledTableCellBorder></StyledTableCellBorder>
                                        <StyledTableCellBorder></StyledTableCellBorder>
                                        <StyledTableCellBorder sx={{ color: "#0F2C6F", fontSize: "20px",textAlign:'end' }}>{vatTotal}</StyledTableCellBorder>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <StyledTableCell>
                                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px", fontWeight: "600" }}>Total Amount (SGD)</Typography>
                                            <Typography sx={{ color: "#0F2C6F", fontSize: "14px" }}>GST included.</Typography>
                                        </StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                        <StyledTableCell sx={{ color: "#0F2C6F", fontSize: "26px",textAlign:'end' }}>{quotationTotal}</StyledTableCell>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>

                    </Box>
                    {
                        localStorageData.role_id === 4 &&  reviewData?.status==="quotation_updated" &&
                        <Box data-html2canvas-ignore='true' sx={{ display: "flex", justifyContent: "flex-end", my: 2 }}>
                            <Button onClick={() => updateStatus('withdraw', 0)} variant="contained" sx={{ mr: 2, textTransform: "capitalize", backgroundColor: "#BD0251", color: "#FFFFFF", borderRadius: "6px", "&:hover": { backgroundColor: "#BD0251" } }}>Withdraw</Button>
                            <Button onClick={() => updateStatus('quotation_accepted', 3)} variant="contained" sx={{ textTransform: "capitalize", backgroundColor: "#123FA9", color: "#FFFFFF", borderRadius: "6px", "&:hover": { backgroundColor: "#123FA9" } }}>Accept</Button>
                        </Box>
                    }

                </Box>
             }
            </>
        )
    }

    const confirmFunc = () => {

        if(!description && confirmationAlert.actionStatus === "rejected"){
            setError(prevValue=>({...prevValue, description:'Remarks required !' }))
            return;
        }else setError(prevValue=>({...prevValue, description:'' }))

        let serReqNewStatus = {
            service_requests_id: service_req_id,
            description: description,
            status: confirmationAlert.actionStatus
        }
       
        updateServiceRequestStatus(serReqNewStatus)
    }

    const closeFunc = () => {
        
        setConfirmAlert({ status: false, alertData: {}, actionStatus: '' })
        setNoteToTenant('')
        setConfirmquotation(false)
        setOpenAcknowledgeAlert(false);
    }
    const updateQuotationStatus = () => {
        if(!notetoTenant){
            setError({
                ...error,
                quotation:"Should not be empty"
            })
        }else{
            let serReqNewStatus = {
                service_requests_id: service_req_id,
                status: 'quotation_updated',
                description:notetoTenant
            }
            updateQuotationToTenant(serReqNewStatus)
        }
        
    }

    const updateAcknowledgement = ()=>{
        if(!acknowledgeFeedback){
            setError({
                ...error,
                acknowledge:"Should not be empty"
            })
        }else{
            let serReqNewStatus = {
                service_requests_id: service_req_id,
                status: 'acknowledgement',
                description:acknowledgeFeedback
            }
            updateServiceRequestStatus(serReqNewStatus)
        }
       
    }
    
    const stepperWithdrawFunction = ()=>{
        let serReqNewStatus = {
            service_requests_id: service_req_id,
            status: 'withdraw'
        }
        updateServiceRequestStatus(serReqNewStatus)
    }

    const handleWatchUnWatch =(id,status)=>{
        let data = {
            service_requests_id:id,
            watch_status:status
        }
        updateWatch(data)
    }

    const renderDescription =()=>{
        if(confirmationAlert.actionStatus === "rejected"){
            return  <Box sx={{  padding: '16px 24px' }}>
              <TextField multiline fullWidth rows={3} value={description} name="description" onChange={(e)=> { 
                setDescription(e.target.value); setError(prevValue=>({...prevValue,description:''})) ;
                }} />
              <Typography variant='p' sx={{ fontSize: "14px", color: 'red' }}>
                  <br /> {error?.description}
              </Typography>
            </Box>
        }else return null
    }

    const handleSapFileDownload = (downloadFile, fileName) => {
        saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${downloadFile}`, fileName)
  }

    return (
        <>
        {loaderStatus ? <Loader status={loaderStatus} /> : isUnAuthorized!=false ? <UnAuthorized data={isUnAuthorized} />  :
        <>
            <ToastAlert message={snackbarStatus.message} status={snackbarStatus.status} color={snackbarStatus.color} icon={snackbarStatus.icon} />
            {openPopup && <UpdatePopup closeFunc={()=>{setOpenPopup("");  setError(prevValue=>({...prevValue, description:'' }))}} serviceType={service_req_id} serviceTypeValue={"Light Bulb Replacement"} currentStatus={reviewData?.status} nextStatus={nextUpdateStatus} />}
            <EditQuotation closeFunc={setEditQuotation} quotationData={serviceReqDetails?.lightBulbQuotationArrayList} quotationType={editQuotation} serviceRequestId={service_req_id} />
            {confirmationAlert.status  && <Alert alertData={confirmationAlert.alertData} closeFunc={setConfirmAlert} confirmFunc={confirmFunc} renderElement={renderDescription()} />  }

            <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={0.5} />
                <Grid item xs={8}>
                    <Box sx={{ mt: 7, mb: 4 }}>
                        <ArrowIcon cursor='pointer' onClick={() =>{
                            // if(fromDashboard=="true"){
                            //     navigate(`/dashboard`)
                            // }else{
                                navigate(-1)
                            // }
                        }} />
                        <Box sx={{display:"flex"}}>
                        <Box sx={{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between"}}> 
                            <Typography sx={{ fontSize: '26px', color: '#0F2C6F', pt: 2, pb: 1 }}>{reviewData?.display_name} - #{reviewData?.ticket_id}   </Typography>
                            <Button data-html2canvas-ignore='true' onClick={downloadPDF} sx={{ color: '#0F2C6F', textTransform: 'capitalize', backgroundColor: '#CFDDFE', borderRadius: '6px', '&:hover': { backgroundColor: '#CFDDFE' } }} endIcon={<DownloadIcon />}>Download</Button>
                            </Box>
                            {reviewData?.status!='rejected' && reviewData?.status!='closed' && reviewData?.status!='withdraw' && 
                                reviewData?.status!='acknowledgement' && reviewData?.status!='resolved' && 
                            <>
                                {serviceReqDetails?.watch_status=="yes" ?  <BellWatch onClick={()=>handleWatchUnWatch(service_req_id,"no")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' /> : 
                                    <BellUnWatch onClick={()=>handleWatchUnWatch(service_req_id,"yes")} style={{ marginLeft: '15px',alignSelf:"center" }} cursor='pointer' />
                                }
                            </>
                            }
                        </Box>

                        <Typography sx={{ color: '#9AA6C3', fontSize: "12px", display: 'inline' }}>{getTimeStamp(reviewData?.created_at)}</Typography>
                        <Chip label={getStatus(reviewData?.status, 'text')} sx={{ backgroundColor: getStatus(reviewData?.status, 'bgcolor'), color: getStatus(reviewData?.status, 'color'), borderRadius: '6px', height: '24px', fontSize: '14px', ml: 2 }} />
                    </Box>
                </Grid>
                <Grid item xs={3.5} />
            </Grid>

            <Grid  container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={0.5} />
                <Grid id='print' item xs={8}>
                    
                    {(reviewData?.status === "submitted" || reviewData?.status === "approved" || reviewData?.status === "pending_investigation" || (getLoggedUserData().role_id==4 && reviewData?.status=='complete_investigation')) ? <></> :  renderQuotation()
                       
                    }
                       {reviewData?.status === 'closed' &&
                        <Box sx={{my: 3, background: '#ffffff', borderRadius: '8px',p:3 }}>
                            <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#0F2C6F"}}>Final SAP Invoice</Typography>
                            <Box sx={{p:2, border: '1px solid #DBDFE9', borderRadius: "8px", my: 2}}>

                            {showSapDetails &&
                                <Box sx={{mb: 2, borderBottom: '1px solid #DBDFE9', pb: 2, display: 'flex', flexDirection: "column", gap: '20px'}}>
                                {/* SAP No */}
                               {sapDetails?.sap_number && <Box>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 500}}>SAP Number</Typography>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 400}}>{sapDetails?.sap_number}</Typography>
                                </Box>}

                                {/* Comments */}
                              {sapDetails?.sap_description &&  <Box>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 500}}>Comments</Typography>
                                    <Typography sx={{fontSize:"14px", color: "#0F2C6F" ,fontWeight: 400}}>{sapDetails?.sap_description}</Typography>
                                </Box> }
                                </Box> }

                                <Box sx={{display: "flex", width: '100%', justifyContent: 'space-between', alignItems: "center"}}>  
                                    <Box>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>Total Amount (SGD)</Typography>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>GST Included</Typography>

                                    </Box>
                                    <Typography sx={{fontSize: "26px", fontWeight: 500, color: "#A48B2C"}}>${sapDetails?.final_invoice_amount || 0}</Typography>
                                </Box>


                            </Box>
                           {sapDetails?.file &&  showSapDetails &&  <Box sx={{pt: 2}}>
                            <Typography sx={{fontSize: "18px", fontWeight: 500, color: "#0F2C6F "}}>Uploaded SAP Invoice</Typography>
                            <Box sx={{display: "flex", width:"-webkit-fill-available", justifyContent: "space-between", alignItems: "center", my: 2, p: 2,border: '1px solid #DBDFE9', borderRadius: "8px"}}>
                                <Box sx={{display: 'flex', alignItems: "center", gap: 2}}>
                                {sapDetails?.file_type === 'pdf' ? <PdfIcon style={{width: '40px', height: "40px"}} />
                                 : <ImageFormatIcon style={{width: '40px', height: "40px"}} />}
                                 <Box>
                                    <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#0F2C6F"}}>{sapDetails?.file_name}</Typography>
                                   {sapDetails?.file_size && <Typography sx={{fontSize: "14px", fontWeight: 500, color: "#9AA6C3"}}>{sapDetails?.file_size} of {sapDetails?.file_size} KB</Typography>}
                                 </Box>
                                </Box> 

                                <IconButton style={{borderRadius: 0}} onClick={() => handleSapFileDownload(sapDetails?.file, sapDetails?.file_name)}>
                                 <DownloadButtonIcon />
                                </IconButton>
                             </Box>
                             </Box>}

                            <Box sx={{display: "flex", py: 1, justifyContent: "center", cursor: "pointer"}}>
                                <Typography sx={{fontSize: '14px', color: "#2764F4", textDecoration: "underline"}}
                                onClick={() => setShowSapDetails(prevValue => !prevValue)}
                                >{showSapDetails ? 'Hide' : 'Show'} details</Typography>
                            </Box>
                            
                        </Box>
                            }

                    <Box sx={{ backgroundColor: '#FFFFFF', p: 3 }}>

                        <Grid container sx={{ my: 3, pb: 3 }} >
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                <StyledPrimaryTypography >Tenant Details</StyledPrimaryTypography>
                             
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Typography sx={{ color: ' #0F2C6F', fontSize: '14px' }}>{reviewData?.created_by_name}</Typography>
                                <StyledTypography>{tenantDetails?.company_name}</StyledTypography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <StyledTypography>Office : {tenantDetails?.country_code}{tenantDetails?.phone_no}</StyledTypography>
                                <StyledTypography>Mobile : {tenantDetails?.country_code}{tenantDetails?.phone_no}</StyledTypography>
                            </Grid>

                        </Grid>
                        <Grid container rowSpacing={4}>
                            <Grid item xs={4}>
                                <StyledPrimaryTypography>Preferably Work Period </StyledPrimaryTypography>
                                <StyledPrimaryTypography>{reviewData?.serviceRequestDate}</StyledPrimaryTypography>
                            </Grid>
                            <Grid item xs={4}>
                                <StyledTypography>Working Days</StyledTypography>
                                <StyledTypography>Weekdays: {reviewData?.from_time}-{reviewData?.to_time}</StyledTypography>
                                {/* <StyledTypography>Duration:  {reviewData?.service_request_duration }</StyledTypography> */}
                                <StyledTypography>Level:  {reviewData?.level_id}</StyledTypography>
                            </Grid>
                            <Grid item xs={12}>
                                <StyledPrimaryTypography>Work Details</StyledPrimaryTypography>
                                <StyledTypography>{reviewData?.description}</StyledTypography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <StyledPrimaryTypography>Supporting Materials(optional)</StyledPrimaryTypography>
                                {serviceReqDetails?.service_request_files?.length > 0 && <Button data-html2canvas-ignore="true" variant="outlined" onClick={() => handleFileDownload('All')} endIcon={<DownloadIcon />} sx={{ color: '#0F2C6F', fontSize: '14px', textTransform: 'capitalize', fontWeight: 600, '&:hover': { backgroundColor: "#FFFFFF" } }}>Download All Attachments</Button>}
                            </Grid>
                            {serviceReqDetails?.service_request_files?.length > 0 ? serviceReqDetails?.service_request_files.map((item) => (
                                <Grid item xs={12}>
                                    <List
                                        sx={{
                                            width: '100%',
                                            bgcolor: 'background.paper',
                                            p: 0
                                        }}
                                    >
                                        <ListItem sx={{ pl: 0, border: '1px solid gray' }} secondaryAction={
                                            <IconButton edge="end" aria-label="delete">
                                                <FileDownloadOutlinedIcon style={{ color: '#6F80A9' }} onClick={() => handleFileDownload(item?.path,item.original_name)} />
                                            </IconButton>}>
                                            <ListItemText primary={<Typography sx={{ ml: 2, color: '#0F2C6F' }} variant='h6'>{item.original_name}</Typography>} secondary={''} />

                                        </ListItem>
                                    </List>
                                </Grid>
                            )) : <Typography sx={{display:"flex",justifyContent:'center',color:"#0F2C6F",width:"100%"}}>No Attachments</Typography>}

                        </Grid>
                        <Box sx={{ mt: 3 }}>
                            <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>{ terms && terms[0]?.title}*</Typography>
                            <Typography sx={{ fontSize: '14px', color: ' #6F80A9', my: 2 }}>{terms && terms[0]?.description}</Typography>
                            {terms && terms[1].rules.map((value,index)=>(
                                <Typography sx={{ color: "#6F80A9", fontSize: '12px', my: 1 }}>{index+1}.{value[`${index+1}`]}</Typography>
                            ))}
                            
                            <Typography sx={{ color: '#9AA6C3', fontSize: '14px' }}>
                                <Checkbox checked disabled />
                                I have read and agree to comply with the above terms & conditions.
                            </Typography>
                        </Box>
                    </Box>

                </Grid>
                <Grid item xs={3.5}>
                    <Box sx={{ backgroundColor: '#FFFFFF', mx: 3 }}>
                        <Box sx={{borderBottom:"1px solid #C0C7D7",py:2,px:3,borderRadius:"8px 8px 0 0 "}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>Status Updates</Typography>
                        </Box>
                        <Box sx={{p:2}}>
                            {role_id==4 ? 
                            <StatusStepper type='ServiceRequest' currentStatus={reviewData?.status} withdrawFunc={stepperWithdrawFunction} statusLogs={serviceReqDetails?.serviceRequestLogTempArray?.length > 0 ? serviceReqDetails?.serviceRequestLogTempArray : []} /> : 
                            <StatusStepper  currentStatus={reviewData?.status} withdrawFunc={stepperWithdrawFunction} statusLogs={serviceReqDetails?.serviceRequestsLogsArrayList?.length > 0 ? serviceReqDetails?.serviceRequestsLogsArrayList : []} />
                            }
                        </Box>
                    </Box>
                    
                </Grid>
                <Grid item xs={12}>
                    <Button></Button>
                    <Button></Button>
                </Grid>
            </Grid>
            <Grid container sx={{ backgroundColor: 'rgba(143, 146, 161,0.05)' }}>
                <Grid item xs={0.5} />
                <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'end', pb: 5, pt: 2 }}>
                    {renderActButtons()}
                </Grid>
                <Grid xs={3.5}></Grid>
            </Grid>

            <Modal open={confirmquotation}>
                <Box sx={{ position: "absolute", backgroundColor: "#FFFFFF", left: '25%', top: '10%', width: "40%" }}>
                    <Box sx={{ display: "flex", justifyContent: 'space-between', borderBottom: "1px solid #C0C7D7", p: 3 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "18px" }}>Confirm Quotation for Tenant</Typography>
                        <CloseIcon onClick={() => closeFunc()} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ p: 3 }}>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>Please ensure all details are entered correctly to prevent any delay to processing of request. Requests are subjected to Singapore Land Tower’s building management approval.</Typography>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 3, mb: 1 }}>Note to Tenant</Typography>
                        <OutlinedInput inputProps={{ maxLength: 300 }} onChange={(e) => setNoteToTenant(e.target.value)} fullWidth />
                        <Typography sx={{ mt: 1, color: " #9AA6C3", fontSize: "14px" }}>[{notetoTenant?.length}/300]</Typography>
                        {error.quotation && <Typography sx={{color:'#f44336'}}>{error.quotation}</Typography>}  
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
                        <Button sx={{
                            cursor:"pointer",
                            textTransform: "capitalize",
                            color: "#0F2C6F",
                            border: "1px solid #AAC3FF",
                            borderRadius: "6px"
                        }} onClick={() => closeFunc()} >Cancel</Button>
                        <Button
                        onClick={()=>updateQuotationStatus()}
                        //disabled={notetoTenant?.length===0}
                         sx={{
                            cursor:"pointer",
                            color: "#FFFFFF",
                            backgroundColor: "#123FA9",
                            textTransform: "capitalize",
                            borderRadius: "6px",
                            "&:hover": { backgroundColor: "#123FA9" }
                        }}>Submit</Button>
                    </Box>

                </Box>
            </Modal>

            <Modal open={openAcknowledgeAlert}>
                <Box sx={{ position: "absolute", backgroundColor: "#FFFFFF", left: '25%', top: '10%', width: "40%" }}>
                    <Box sx={{ display: "flex", justifyContent: 'space-between', borderBottom: "1px solid #C0C7D7", p: 3 }}>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "18px" }}>Acknowledgement of Light Bulb Replacement Request</Typography>
                        <CloseIcon onClick={() => closeFunc()} style={{ cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ p: 3 }}>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>By clicking this button means you have acknowledge the completation of the request and agree to the invoice then turn it to a resolved case. If there is any inconvenient, please contact BMS 6824 8333</Typography>
                        <Typography sx={{ color: "#0F2C6F", fontSize: "14px", mt: 3, mb: 1 }}>Feedback on Work</Typography>
                        <OutlinedInput inputProps={{ maxLength: 300 }} onChange={(e) => setAcknowledgeFeedback(e.target.value)} fullWidth />
                        <Typography sx={{ mt: 1, color: " #9AA6C3", fontSize: "14px" }}>[{acknowledgeFeedback?.length}/300]</Typography>
                        {error.acknowledge && <Typography sx={{color:'#f44336'}}> {error.acknowledge}</Typography> }
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 3 }}>
                        <Button sx={{
                            cursor:"pointer",
                            textTransform: "capitalize",
                            color: "#0F2C6F",
                            border: "1px solid #AAC3FF",
                            borderRadius: "6px"
                        }} onClick={() => closeFunc()} >Cancel</Button>
                        <Button
                        onClick={updateAcknowledgement}
                        //disabled={notetoTenant?.length===0}
                         sx={{
                            cursor:"pointer",
                            color: "#FFFFFF",
                            backgroundColor: "#123FA9",
                            textTransform: "capitalize",
                            borderRadius: "6px",
                            "&:hover": { backgroundColor: "#123FA9" }
                        }}>Acknowledge</Button>
                    </Box>

                </Box>
            </Modal>
        </>}
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        serviceReqDetails: state.Service_Req_Reducer.serviceReqDetails,
        serReqUpdateStatus: state.Service_Req_Reducer.updateStatus,
        quotationUpdatedStatus: state.Service_Req_Reducer.quotationUpdateStatus,
        updateWatchStatus:state.Service_Req_Reducer.updateWatchStatus,
        notification:state.DashboardReducer.notification,
        isUnAuthorized:state.Service_Req_Reducer.isUnAuthorized

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateServiceRequestStatus: (data) => (dispatch(serviceRequestAction.updateServiceRequestStatus(data))),
        setServiceRequestStateNull: () => (dispatch(serviceRequestAction.setServiceRequestStateNull())),
        serviceRequestByID: (data) => (dispatch(serviceRequestAction.serviceRequestDetailsById(data))),
        updateQuotationToTenant:(data)=>(dispatch(serviceRequestAction.updateQuotationToTenant(data))),
        updateWatch: (data) => (dispatch(serviceRequestAction.updateWatchStatus(data))),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLightBulb);