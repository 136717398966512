import { handleActions } from 'redux-actions';

const responseDef = {
    isLoading: false,
    response: null,
    isError: false,
}
const Service_Req_Reducer = {
    service_req_list: [],
    addServiceReqStatus:null,
    serviceRequestTypes:[],
    serviceReqDetails:{},
    updateStatus:null,
    cargoLiftObjective:[],
    addServiceReqAsDraft:null,
    countries_list:[],
    quotationUpdateStatus:null,
    serviceAccesscoriesList:[],
    miscelleaneousFormPdf:null,
    serviceRequestTerms:[],
    miscelleaneousTypes:[],
    publicHolidays:[],
    updateWatchStatus:null,
    serviceFilter:null,
    activeFilterStatus:[],
    inactiveFilterStatus:[],
    airConCalc:[],
    saveSecurityDetails:[],
    downloadSecurityPdf:[],
    updateMiscDraftStatus:null,
    updateLightDraftStatus:null,
    updateLiftDraftStatus:null,
    updateSecurityDraftStatus:null,
    isUnAuthorized:false,
    addLightBulbProductStatus:null,
    deleteLightBulbProductStatus:null,
    editLightBulbProductStatus:null,
    lightBulbProduct:[],
    productList:null,
    getLightBulbServiceCharge: {...responseDef},
    getConfigByIdData: {...responseDef},
    updateConfigStatusData: {...responseDef},
    getServiceChargeList: {...responseDef},
    addFaultCategory: {...responseDef},
    getFaultCategoryList: {...responseDef},
    getFaultCategoryByIdData: {...responseDef},
    editFaultCategoryData: {...responseDef},
    deleteFaultCategoryData: {...responseDef},
};
const reducer = handleActions({
    UPDATE_SERVICE_REQUEST_STATE: (state) => {
        return {
            ...state,
            addServiceReqStatus: null,
            service_req_list: [],
            updateStatus:null,
            addSecurityClearanceReq:null,
            quotationUpdateStatus:null,
            serviceRequestLists:null,
            serviceReqDetails:[],
            updateWatchStatus:null,
            updateMiscDraftStatus:null,
            updateLightDraftStatus:null,
            updateLiftDraftStatus:null,
            updateSecurityDraftStatus:null,
            addLightBulbProductStatus:null,
            addFaultCategory:{...responseDef},
            getFaultCategoryList:{...responseDef},
            getFaultCategoryByIdData: {...responseDef},
            editFaultCategoryData: {...responseDef},
            deleteFaultCategoryData: {...responseDef},
        }
    },
    GET_SERVICE_REQ_TYPES_INITIATE: (state) => {
        return {
            ...state,

        }
    },
    GET_SERVICE_REQ_TYPES_SUCCESS: (state, action) => {
        return {
            ...state,
            serviceRequestTypes: action.payload.service_typeArrayList,
            serviceRequestTerms:action.payload.terms
        }
    },
    GET_SERVICE_REQ_TYPES_FAILURE: (state) => {
        return {
            ...state,
            serviceRequestTypes: []
        }
    },
    GET_MISC_REQ_TYPE_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_MISC_REQ_TYPE_SUCCESS:(state,action)=>{
        return{
            ...state,
            miscelleaneousTypes:action.payload
        }
    },
    GET_MISC_REQ_TYPE_FAILURE:(state)=>{
        return{
            ...state,
            miscelleaneousTypes:[]
        }
    },
    GET_SERVICE_REQ_LIST_INITIATE: (state) => {
        return {
            ...state,
        };
    },
    GET_SERVICE_REQ_LIST_SUCCESS: (state, action) => {
        return {
            ...state,
            service_req_list: action.payload.response_json,
            totalRecords:action.payload.totalRecords
        };
    },
    GET_SERVICE_REQ_LIST_FAILURE: (state, action) => {
        return {
            ...state,
            service_req_list:[],
            totalRecords:0
        }
    },
    
    SERVICE_REQ_BYID_INITIATE: (state, action) => {
        return {
            ...state,
            isUnAuthorized:false
        };
    },
    SERVICE_REQ_BYID_SUCCESS: (state, action) => {
        return {
            ...state,
            serviceReqDetails: action.payload
        };
    },
    SERVICE_REQ_BYID_FAILURE: (state, action) => {
        return {
            ...state,
            serviceReqDetails: action.payload
        };
    },
    UPDATE_SERVICE_REQ_STATUS_INITIATE: (state, action) => {
        return {
            ...state,

        };
    },
    UPDATE_SERVICE_REQ_STATUS_SUCCESS: (state, action) => {
        return {
            ...state,
            updateStatus: action.payload
        };
    },
    UPDATE_SERVICE_REQ_STATUS_FAILURE: (state, action) => {
        return {
            ...state,
            updateStatus: action.payload
        };
    },
    GET_CARGOLIFT_OBJECTIVE_INITATE: (state, action) => {
        return {
            ...state
        }
    },
    GET_CARGOLIFT_OBJECTIVE_SUCCESS: (state, action) => {
        return {
            ...state,
            cargoLiftObjective: action.payload
        }
    },
    GET_CARGOLIFT_OBJECTIVE_FAILURE: (state, action) => {
        return {
            ...state,
            cargoLiftObjective: []
        }
    },  
    GET_NATIONALITYLIST_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_NATIONALITYLIST_SUCCESS:(state,action)=>{
        return{
            ...state,
            countries_list:action.payload
        }
    },
    GET_NATIONALITYLIST_FAILURE:(state)=>{
        return{
            ...state,
            countries_list:[]
        }
    },
    GENERATE_AIR_CON_BILL_INITIATE: (state, action) => {
        return {
            ...state,
        };
    },
    GENERATE_AIR_CON_BILL_SUCCESS: (state, action) => {
        return {
            ...state,
        };
    },
    GENERATE_AIR_CON_BILL_FAILURE: (state, action) => {
        return {
            ...state,
        };
    },
    ADD_SERVICE_REQ_INITIATE: (state, action) => {
        return {
            ...state,
        };
    },
    ADD_SERVICE_REQ_SUCCESS: (state, action) => {
        return {
            ...state,
            addServiceReqStatus: action.payload
        };
    },
    ADD_SERVICE_REQ_FAILURE: (state, action) => {
        return {
            ...state,
            addServiceReqStatus: action.payload
        };
    },
    UPDATE_QUOTATION_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    UPDATE_QUOTATION_SUCCESS:(state,action)=>{
        return{
            ...state,
            quotationUpdateStatus:action.payload
        }
    },
    UPDATE_QUOTATION_FAILURE:(state,action)=>{
        return{
            ...state,
            quotationUpdateStatus:action.payload
        }
    },
    GET_PRODUCTS_INITIATE:(state,action)=>{
        return{
            ...state,
          
        }
    },
    GET_PRODUCTS_SUCCESS:(state,action)=>{
        return{
            ...state,
            serviceAccesscoriesList:action.payload.lightBulbProductsArrayList,
            productList:action.payload
        }
    },
    GET_PRODUCTS_FAILURE:(state,action)=>{
        return{
            ...state,
            serviceAccesscoriesList:[],
            productList:null
        }
    },
    GET_MISCELLEANEOUS_FORM_PDF_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_MISCELLEANEOUS_FORM_PDF_SUCCESS:(state,action)=>{
        return{
            ...state,
            miscelleaneousFormPdf:action.payload
        }
    },
    GET_MISCELLEANEOUS_FORM_PDF_FAILURE:(state,action)=>{
        return{
            ...state,
            miscelleaneousFormPdf:[]
        }
    },
    GET_PUBLICHOLIDAY_INITIATE:(state)=>{
        return{
            ...state
        }
    },
    GET_PUBLICHOLIDAY_SUCCESS:(state,action)=>{
        return{
            ...state,
            publicHolidays:action.payload
        }
    },
    GET_PUBLICHOLIDAY_FAILURE:(state,action)=>{
        return{
            ...state,
            publicHolidays:[]
        }
    },
    UPDATE_SERVICEWATCHSTATUS_INITIATE:(state)=>{
        return{
          ...state
        }
      },
    UPDATE_SERVICEWATCHSTATUS_SUCCESS:(state,action)=>{
        return{
          ...state,
          updateWatchStatus:action.payload
        }
      },
    UPDATE_SERVICEWATCHSTATUS_FAILURE:(state,action)=>{
        return{
          ...state,
          updateWatchStatus:action.payload
        }
      },
      GET_SERVICEFILTER_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      GET_SERVICEFILTER_SUCCESS:(state,action)=>{
        return{
            ...state,
            serviceFilter:action.payload
        }
      },
      GET_SERVICEFILTER_FAILURE:(state,action)=>{
        return{
            ...state,
            serviceFilter:null
        }
      },
      GET_SERVICEACTIVEFILTERSTATUS_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      GET_SERVICEACTIVEFILTERSTATUS_SUCCESS:(state,action)=>{
        return{
            ...state,
            activeFilterStatus:action.payload
        }
      },
      GET_SERVICEACTIVEFILTERSTATUS_FAILURE:(state,action)=>{
        return{
            ...state,
            activeFilterStatus:[]
        }
      },
      GET_SERVICEINACTIVEFILTERSTATUS_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      GET_SERVICEINACTIVEFILTERSTATUS_SUCCESS:(state,action)=>{
        return{
            ...state,
            inactiveFilterStatus:action.payload
        }
      },
      GET_SERVICEINACTIVEFILTERSTATUS_FAILURE:(state,action)=>{
        return{
            ...state,
            inactiveFilterStatus:[]
        }
      },
      GET_AIRCON_CALC_INITIATE:(state,action)=>{
        return{
            ...state
        }
      },
      GET_AIRCON_CALC_SUCCESS:(state,action)=>{
        return{
            ...state,
            airConCalc:action.payload
        }
      },
      GET_AIRCON_CALC_FAILURE:(state,action)=>{
        return{
            ...state,
            airConCalc:action.payload
        }
      },
      SAVE_SECURITY_DETAILS_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      SAVE_SECURITY_DETAILS_SUCCESS:(state,action)=>{
        return{
            ...state,
            saveSecurityDetails:action.payload
        }
      },
      SAVE_SECURITY_DETAILS_FAILURE:(state,action)=>{
        return{
            ...state,
            saveSecurityDetails:action.payload
        }
      },
      DOWNLOAD_SECURITY_PDF_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      DOWNLOAD_SECURITY_PDF_SUCCESS:(state,action)=>{
        return{
            ...state,
            downloadSecurityPdf:action.payload
        }
      },
      DOWNLOAD_SECURITY_PDF_FAILURE:(state,action)=>{
        return{
            ...state,
            downloadSecurityPdf:action.payload
        }
      },
      UPDATE_MISC_DRAFT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      UPDATE_MISC_DRAFT_SUCCESS:(state,action)=>{
        return{
            ...state,
            updateMiscDraftStatus:action.payload
        }
      },
      UPDATE_MISC_DRAFT_FAILURE:(state,action)=>{
        return{
            ...state,
            updateMiscDraftStatus:action.payload
        }
      },
      UPDATE_LIGHT_DRAFT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      UPDATE_LIGHT_DRAFT_SUCCESS:(state,action)=>{
        return{
            ...state,
            updateLightDraftStatus:action.payload
        }
      },
      UPDATE_LIGHT_DRAFT_FAILURE:(state,action)=>{
        return{
            ...state,
            updateLightDraftStatus:action.payload
        }
      },
      UPDATE_LIFT_DRAFT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      UPDATE_LIFT_DRAFT_SUCCESS:(state,action)=>{
        return{
            ...state,
            updateLiftDraftStatus:action.payload
        }
      },
      UPDATE_LIFT_DRAFT_FAILURE:(state,action)=>{
        return{
            ...state,
            updateLiftDraftStatus:action.payload
        }
      },
      UPDATE_SECURITY_DRAFT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      UPDATE_SECURITY_DRAFT_SUCCESS:(state,action)=>{
        return{
            ...state,
            updateSecurityDraftStatus:action.payload
        }
      },
      UPDATE_SECURITY_DRAFT_FAILURE:(state,action)=>{
        return{
            ...state,
            updateSecurityDraftStatus:action.payload

        }
      },
      SERVICE_REQUEST_UNAUTHORIZED:(state,action)=>{
        return{
            ...state,
            isUnAuthorized:action.payload

        }
      },
      ADD_LIGHTPRODUCT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      ADD_LIGHTPRODUCT_SUCCESS:(state,action)=>{
        return{
            ...state,
            addLightBulbProductStatus:action.payload
        }
      },
      ADD_LIGHTPRODUCT_FAILURE:(state,action)=>{
        return{
            ...state,
            addLightBulbProductStatus:action.payload
        }
      },
      EDIT_LIGHTPRODUCT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      EDIT_LIGHTPRODUCT_SUCCESS:(state,action)=>{
        return{
            ...state,
            editLightBulbProductStatus:action.payload
        }
      },
      EDIT_LIGHTPRODUCT_FAILURE:(state,action)=>{
        return{
            ...state,
            editLightBulbProductStatus:action.payload
        }
      },
      DELETE_LIGHTPRODUCT_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      DELETE_LIGHTPRODUCT_SUCCESS:(state,action)=>{
        return{
            ...state,
            deleteLightBulbProductStatus:action.payload
        }
      },
      DELETE_LIGHTPRODUCT_FAILURE:(state,action)=>{
        return{
            ...state,
            deleteLightBulbProductStatus:action.payload
        }
      },
      GET_LIGHTPRODUCBYID_INITIATE:(state)=>{
        return{
            ...state
        }
      },
      GET_LIGHTPRODUCTBYID_SUCCESS:(state,action)=>{
        return{
            ...state,
            lightBulbProduct:action.payload
        }
      },
      GET_LIGHTPRODUCTBYID_FAILURE:(state,action)=>{
        return{
            ...state,
            lightBulbProduct:[]
        }
      },
      GET_LIGHT_BULB_SERVICE_CHARGE_INITIATE:(state)=>{
        return{
          ...state,
          getLightBulbServiceCharge:{
            ...state.getLightBulbServiceCharge,
            isError: false,
            isLoading: true,
          }
        }
      },
      GET_LIGHT_BULB_SERVICE_CHARGE_SUCCESS:(state,action)=>{
        return{
          ...state,
          getLightBulbServiceCharge: {
            isError: false,
            isLoading: false,
            response: action.payload
        }
        }
      },
      GET_LIGHT_BULB_SERVICE_CHARGE_FAILURE:(state,action)=>{
        return{
          ...state,
          getLightBulbServiceCharge: {...responseDef}
        }
      },
      GET_LIGHT_BULB_SERVICE_CHARGE_NULL: (state,) => {
        return {
            ...state,
            getLightBulbServiceCharge: {...responseDef}
        }
    },
    ADD_FAULTCATEGORY_INITIATE:(state)=>{
        return{
            ...state,
            addFaultCategory: {...state.addFaultCategory, isLoading: true, isError: false}
        }
    },
    ADD_FAULTCATEGORY_SUCCESS:(state,action)=>{
        return{
            ...state,
            addFaultCategory: {isError: false, response: action.payload, isLoading: false}
        }
    },
    ADD_FAULTCATEGORY_FAILURE:(state,action)=>{
        return{
            ...state,
            addFaultCategory: {response: action.payload, isLoading: false, isError: true}
        }
    },
      GET_CONFIGBYID_INITIATE: (state, action) => {
        return {
            ...state,
            getConfigByIdData: {...state.getConfigByIdData, isLoading: true, isError: false}
        }
     },
     GET_CONFIGBYID_SUCCESS: (state, action) => {
         console.log('reducer 4 ',action.payload);
        return {
            ...state,
            getConfigByIdData: {isError: false, response: action.payload, isLoading: false}
        }
     },
     GET_CONFIGBYID_FAILURE: (state, action) => {
        return {
            ...state,
            getConfigByIdData: {response: action.payload, isLoading: false, isError: true}
        }
     },
     GET_CONFIGBYID_NULL: (state, action) => {
        return {
            ...state,
            getConfigByIdData: {...responseDef}
        }
     },
     UPDATE_CONFIG_INITIATE: (state, action) => {
        return {
            ...state,
            updateConfigStatusData: {...state.updateConfigStatusData, isLoading: true, isError: false}
        }
     },
     UPDATE_CONFIG_SUCCESS: (state, action) => {
        return {
            ...state,
            updateConfigStatusData: {isError: false, response: action.payload, isLoading: false}
        }
     },
     UPDATE_CONFIG_FAILURE: (state, action) => {
        return {
            ...state,
            updateConfigStatusData: {response: action.payload, isLoading: false, isError: true}
        }
     },
     UPDATE_CONFIG_NULL: (state, action) => {
        return {
            ...state,
            updateConfigStatusData: {...responseDef}
        }
     }, 
     
     GET_SERVICE_CHARGE_INITIATE: (state, action) => {
        return {
            ...state,
            getServiceChargeList: {...state.getServiceChargeList, isLoading: true, isError: false}
        }
     },
     GET_SERVICE_CHARGE_SUCCESS: (state, action) => {
        return {
            ...state,
            getServiceChargeList: {isError: false, response: action.payload, isLoading: false}
        }
     },
     GET_SERVICE_CHARGE_FAILURE: (state, action) => {
        return {
            ...state,
            getServiceChargeList: {response: action.payload, isLoading: false, isError: true}
        }
     },
     GET_SERVICE_CHARGE_NULL: (state, action) => {
        return {
            ...state,
            getServiceChargeList: {...responseDef}
        }
     }, 
     GET_FAULTCATEGORY_INITIATE: (state, action) => {
        return {
            ...state,
            getFaultCategoryList: {...state.getFaultCategoryList, isLoading: true, isError: false}
        }
     },
     GET_FAULTCATEGORY_SUCCESS: (state, action) => {
        return {
            ...state,
            getFaultCategoryList: {isError: false, response: action.payload, isLoading: false}
        }
     },
     GET_FAULTCATEGORY_FAILURE: (state, action) => {
        return {
            ...state,
            getFaultCategoryList: {response: action.payload, isLoading: false, isError: true}
        }
     },

     GET_FAULTCATEGORYBYID_INITIATE: (state, action) => {
        return {
            ...state,
            getFaultCategoryByIdData: {...state.getFaultCategoryByIdData, isLoading: true, isError: false}
        }
     },
     GET_FAULTCATEGORYBYID_SUCCESS: (state, action) => {
        return {
            ...state,
            getFaultCategoryByIdData: {isError: false, response: action.payload, isLoading: false}
        }
     },
     GET_FAULTCATEGORYBYID_FAILURE: (state, action) => {
        return {
            ...state,
            getFaultCategoryByIdData: {response: action.payload, isLoading: false, isError: true}
        }
     },
     EDIT_FAULTCATEGORY_INITIATE: (state, action) => {
        return {
            ...state,
            editFaultCategoryData: {...state.updateConfigStatusData, isLoading: true, isError: false}
        }
     },
     EDIT_FAULTCATEGORY_SUCCESS: (state, action) => {
        return {
            ...state,
            editFaultCategoryData: {isError: false, response: action.payload, isLoading: false}
        }
     },
     EDIT_FAULTCATEGORY_FAILURE: (state, action) => {
        return {
            ...state,
            editFaultCategoryData: {response: action.payload, isLoading: false, isError: true}
        }
     },
     DELETE_FAULTCATEGORY_INITIATE:(state)=>{
        return{
            ...state,
            deleteFaultCategoryData: {...state.deleteFaultCategoryData, isLoading: true, isError: false}
        }
      },
      DELETE_FAULTCATEGORY_SUCCESS:(state,action)=>{
        return{
            ...state,
            deleteFaultCategoryData: {isError: false, response: action.payload, isLoading: false}
        }
      },
      DELETE_FAULTCATEGORY_FAILURE:(state,action)=>{
        return{
            ...state,
            deleteFaultCategoryData: {response: action.payload, isLoading: false, isError: true}
        }
      },

}, Service_Req_Reducer)
export default reducer;