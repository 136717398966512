import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as InviteSingleIcon } from '../../../Assets/icons/Invite_Single_White.svg'
import { ReactComponent as InviteSingleColor } from '../../../Assets/icons/Invite_Single_Color.svg'
import { ReactComponent as InviteSpecialColor } from '../../../Assets/icons/Invite_Special_Color.svg'
import { ReactComponent as InviteSpecialIcon } from '../../../Assets/icons/Invite_Special_white.svg'
const primaryCard = { textAlign: 'center', fontWeight: 500, fontSize: '20px', fontFamily: 'Inter', width: '285px', height: '313px', background: ' #FFFFFF', color: '#0F2C6F', border: '1.62644px solid #AAC3FF', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '15px' };
const secondaryCard = { textAlign: 'center', fontWeight: 500, fontSize: '20px', fontFamily: 'Inter', width: '285px', height: '313px', background: ' #123FA9', color: ' #FFFFFF', border: '1.62644px solid #123FA9', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '15px' };
const primaryCardSpecial = { textAlign: 'center', fontWeight: 500, fontSize: '20px', fontFamily: 'Inter', ml: 2.5, width: '285px', height: '313px', color: '#0F2C6F', background: ' #FFFFFF', border: '1.62644px solid #AAC3FF', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '15px' };
const secondaryCardSpecial = { textAlign: 'center', fontWeight: 500, fontSize: '20px', fontFamily: 'Inter', ml: 2.5, width: '285px', height: '313px', color: ' #FFFFFF', background: ' #123FA9', border: '1.62644px solid #123FA9', display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: '15px' };

export default function RegisterVisitors() {
    const navigate = useNavigate();
    const [selectedCard, setSelectedCard] = useState('')

    return (
        <Box sx={{display:'flex',  width:'100%',height:'100%', background: 'rgba(143, 146, 161,0.05)',}}>
        <Grid container sx={{display:'flex', justifyContent:'center',alignItems:'center', p: 6, position: 'relative',  background: 'rgba(143, 146, 161,0.05)',fontFamily:'Inter' }}>
            <Box sx={{maxHeight:'600px',display:'flex', flexDirection:'column', gap:'15px'}}>
            <Grid item xs={12} sx={{  display: 'flex', justifyContent: 'center',  }}>
                <Typography variant='h6' sx={{ fontSize: '26px',textAlign:'center',color:'#0F2C6F' }}>
                Please select one of the registration methods.
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', }}>
                <Box sx={selectedCard === 'single_visitor' ? secondaryCard : primaryCard} onClick={() => setSelectedCard('single_visitor')}>
                    {selectedCard === 'single_visitor' ? <InviteSingleColor style={{ marginTop: '25%' }} /> : <InviteSingleIcon style={{ marginTop: '25%' }} />}
                    <Typography variant='h6' sx={{ fontSize: '20px' }}>
                        Single Visitor
                    </Typography>
                    <Typography variant='h6' sx={{ fontSize: '14px' }}>
                        One-time entry passes for visitors.
                    </Typography>
                </Box>
                <Box sx={selectedCard === 'special_access' ? secondaryCardSpecial : primaryCardSpecial} onClick={() => setSelectedCard('special_access')}>
                    {selectedCard === 'special_access' ? <InviteSpecialColor style={{ marginTop: '25%' }} /> : <InviteSpecialIcon style={{ marginTop: '25%' }} />}
                    <Typography variant='h6' sx={{ fontSize: '20px' }}>
                        Special Access
                    </Typography>
                    <Typography variant='h6' sx={{ fontSize: '14px', p:'0 18px' }}>
                        Only with building manager and executives approval.
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', height:'40px', mt:1}}>
                <Button variant={ selectedCard === '' ? 'outlined' : 'contained'} disabled={selectedCard === '' ? true : false}
                    onClick={() => navigate(`/${selectedCard}`)}
                    sx={{ color: '#FFFFFF', textTransform:"capitalize", background:selectedCard === '' ? 'transparent' : '#123FA9', borderRadius: '8px', width: '603px', '&:hover': { backgroundColor: '#123FA9' } }}>Continue </Button>
            </Grid>
            </Box>
        </Grid>
        </Box>
    )
}
