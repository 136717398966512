import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./components/LandingPage";
import Routing from "./Routes/Routing";
import { connect } from "react-redux";

const App = ({loginMessage})=> {
  const [isLogin,setIsLogin] = useState(localStorage.getItem('userData'))
  
  useEffect(()=>{
    setIsLogin(localStorage.getItem('userData'))
  },[loginMessage])

  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {isLogin!=null && <Route path="/*" element={<Routing />} />}
        {isLogin==null && <Route path="/*" element={<LandingPage />} />}
      </Routes>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    loginMessage: state.LoginReducer.loginMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

  };
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
