import React,{useState, useEffect, useRef } from 'react'
import { useNavigate } from "react-router";
import {Box, Grid, Typography, TextField, Input, Dialog,DialogTitle, DialogContent, DialogActions,Backdrop , InputLabel, IconButton, FormControl, MenuItem, Select, Chip, Button, Switch, SwitchProps,FormGroup, FormControlLabel, Menu, Card, CardContent, CardMedia, CardActionArea, CircularProgress  } from "@mui/material";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { ReactComponent as DatePickerIcon } from '../../Assets/icons/DatePickerIcon.svg';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import { MenuProps } from '../../Utils/utils';
import {ReactComponent as NoDataIcon} from '../../Assets/icons/NoDataIcon.svg';
import {ReactComponent as SearchIcon} from '../../Assets/icons/SearchIcon.svg';
import RoomFeatureImage1 from '../../Assets/icons/RoomFeatureImage1.png';
import RoomFeatureImage2 from '../../Assets/icons/RoomFeatureImage2.png';
import RoomFeatureImage3 from '../../Assets/icons/RoomFeatureImage3.png';
import RoomFeatureImage4 from '../../Assets/icons/RoomFeatureImage4.png';
import RoomFeatureImage5 from '../../Assets/icons/RoomFeatureImage5.png';
import {ReactComponent as CloseIcon} from '../../Assets/icons/Close.svg';
 import { connect } from 'react-redux';
import moment from 'moment';
import { useDebounce } from "use-debounce"
import Close from "@mui/icons-material/Close";
import { BootstrapDialog } from '../BMSAdministration/AddService';
import { facilityBookingRedirectPaymenNullAction, facilityBookingRedirectPaymentAction, getAvaiiableRoomListFacilityBookingNullAction, getAvailableRoomListFacilityBookingAction, getTecFeatureListAction, reduxFacilityBookingRoomDataNullAction, reduxFacilityBookingRoomDataUpdateAction } from '../../redux/actions/facilityBookingAction';
 


const useStyles = makeStyles((theme) => ({
  customTextField: {
    width:'100%',
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '& .MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1976d2', 
    },
    '& .MuiOutlinedInput-root.Mui-error:focus .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1976d2 !important', 
    },
  },
  select: {
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
  },
}));



const staticCardsData = [{id:1, src: RoomFeatureImage1, title:'TEC Swing Room A', level: 5, unit:'#5-10', duration: 'From $39 / Half Hour'},
{id:2, src: RoomFeatureImage2, title:'Creative Space 22C', level: 5, unit:'#5-22', duration: 'From $39 / Half Hour'},
{id:3, src: RoomFeatureImage3, title:'Lounge Area', level: 5, unit:'#5-22', duration: 'From $39 / Half Hour'},
{id:4, src: RoomFeatureImage4, title:'Indonesia Room A', level: 5, unit:'#5-25', duration: 'From $39 / Half Hour'},
{id:5, src: RoomFeatureImage5, title:'Creative Space', level: 5, unit:'#5-27', duration: 'From $39 / Half Hour'}
]

const RoomFeaturesListDropDown = ['WebCam', 'Wifi', 'TV', 'Phone']


 function BookingSelection({getAvailableRoomListFacilityBooking, getAvailableRoomListData, getAvailableRoomListFacilityBookingNull, facilityBookingRedirectPaymentActionNull, facilityBookingRedirectPaymentActionData, getTecFeatureList, getTecFeatureListData,reduxBookingData,reduxBookingDataNull,reduxBookingDataUpdate}) {
  const navigate = useNavigate();
  const classes = useStyles()
  // const [bookingData, setBookingData] = useState({
  //   date: "",
  //   fromTime: "",
  //   endTime: "",
  //   roomCapacity: "",
  //   isVideoConference: 'false'
  // });

  const {date, fromTime,endTime,roomCapacity,isVideoConference} = reduxBookingData;

  const [bookingError, setBookingError] = useState({
    date: "",
    fromTime: "",
    endTime: "",
    roomCapacity:""
  });

  const staticStartHours = ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'];
  const [startHours,setStartHours] = useState(staticStartHours)
  const [eventEndHours,setEventEndHours] = useState()
  const [selectedChips, setSelectedChips] = useState([]);
  const [paymentNotifyModel, setPaymentNotifyModel] = useState(false)
  const StyledTypography = styled(Typography)({
    fontSize: "13px",
    color: " #0F2C6F",
    fontWeight: 550,
    margin:"8px 0 8px 0"
})
  const [avaliableRoomList, setAvailableRoomList] = useState([])
  const [tecFacilityTest, setTectFacilityTest] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchDebounce, setSearchDebounce] = useDebounce("", 500)
  const searchRef = useRef();
  const [paymentResponse, setPaymentResponse] = useState('')

  const handleClose = () => {
      setPaymentNotifyModel(false);
      localStorage.removeItem('payment_message')
      setPaymentResponse('')
  };

  useEffect(() => {
    const responseValue = localStorage.getItem('payment_message')
    console.log('responseValue ',responseValue)
    handleOptionsReset()
    if(responseValue) {
      setPaymentResponse(responseValue)
      setTimeout(()=>{
        setPaymentNotifyModel(true)
      },1000)
      
    }
  },[])
  console.log('paymentResponse ',paymentResponse)
  
useEffect(() => {
  if(getAvailableRoomListData.response?.dataArray){    
    setAvailableRoomList(getAvailableRoomListData.response?.dataArray?.tempAvailableRoomListArray)   
    document.getElementById('facilityBookingScrollIntoView').scrollIntoView({behavior: "smooth"})
    }
    else{
    setAvailableRoomList([]);

  }
}, [getAvailableRoomListData])

useEffect(() => {
  getTecFeatureList();
  setSearchText("");
  
  if(facilityBookingRedirectPaymentActionData.response?.dataArray){
    facilityBookingRedirectPaymentActionNull();
    console.log('facilityPaymentParams ',localStorage.getItem('facilityPaymentParams'))
    localStorage.removeItem('facilityPaymentParams');
  } 
  if(fromTime){
      getEventEndHours(fromTime);    
    }

    if(roomCapacity){
      setSelectedChips(roomCapacity);
    }

    
      setAvailableRoomList([]);
  
 }, [])

//  console.log('state', bookingData);
 
 useEffect(() => {
  if(getTecFeatureListData.response?.response_status === 1){
    setTectFacilityTest(getTecFeatureListData.response.dataArray);
  }
 }, [getTecFeatureListData]) 

 useEffect(() => {  
  if(searchDebounce !== ""){
    setAvailableRoomList(() => getAvailableRoomListData.response?.dataArray?.tempAvailableRoomListArray.filter(item => item.title.toLowerCase().includes(searchDebounce.toLowerCase())));
   }else{
    setAvailableRoomList(getAvailableRoomListData.response?.dataArray?.tempAvailableRoomListArray);
    
  }
 }, [searchDebounce])

  

 const handleReduxBookingData = (data) => {
  console.log(data);
  // getEventEndHours(data.fromTime);
  // setBookingData(data);
  // setSelectedChips(data.roomCapacity);
 }

const IOSSwitch = styled((SwitchProps) => (
  <Switch  checked={isVideoConference}   onChange={handleSwitchChange} focusVisibleClassName=".Mui-focusVisible" disableRipple {...SwitchProps} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#123FA9',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const handleSwitchChange = (event) => {
  reduxBookingDataUpdate({ ...reduxBookingData, ['isVideoConference']: event.target.checked })
};

  const handleDate = (e, datafrom) => {
    reduxBookingDataUpdate({ ...reduxBookingData, [datafrom]: moment(e).format() })
    setBookingError({
        ...bookingError,
        [datafrom]: ''
    })
}

 
 const getEventEndHours = (minTime)=>{
  // let timeIntrevels=[],startTime=minTime===0?0:parseInt(minTime.slice(0,2))+1;

//   for(let i=startTime;i<24;i++){
//      for(let j=1;j<=2;j++){
//          if(j%2==0){
//           timeIntrevels.push(i<=9?`0${i}:30`:`${i}:30`) 
//          }else{
//           timeIntrevels.push(i<=9?`0${i}:00`:`${i}:00`)           
//          }
//      }  
//  };

  let index = staticStartHours.findIndex(item => item === minTime);

   setEventEndHours(staticStartHours.slice(index + 1, staticStartHours.length));

//  setEventEndHours([...timeIntrevels])
}

const handleTime = (e, datafrom) => {


   console.log(e.target.value);

  if(datafrom=="fromTime"){
      getEventEndHours(e.target.value)      
  }

  reduxBookingDataUpdate({
      ...reduxBookingData,
      [datafrom]: e.target.value,
  })
  setBookingError({
      ...bookingError,
      [datafrom]: ''
  })
}

const convertLocalTimeToUtc = (time) => {

  let utc;
  let timeArr = time.split(":");

  utc = new Date(date).setHours(Number(timeArr[0]), Number(timeArr[1]), Number("00"));


  return moment.utc(utc).format("YYYY-MM-DDTHH:mm:ss");
}

const handleChipClick = (chip) => {   
  setBookingError({...bookingError, roomCapacity: ""});
    reduxBookingDataUpdate({...reduxBookingData, roomCapacity: chip});
};

// useEffect(() => {
//   reduxBookingDataUpdate({...reduxBookingData, ['roomCapacity']: selectedChips})
// },[selectedChips])

const chipsData = ['01 - 10', '11 - 20'];

const validateBookingDetails = () => {
  let noError = true;
  let errorObj = {};
  for(const ele in reduxBookingData){
     if(ele === 'roomCapacity' &&  reduxBookingData[ele].length === 0){      
      errorObj[ele] = 'Should not be empty!';
      noError = false;
    }else if(reduxBookingData[ele] === ""){
      errorObj[ele] = 'Should not be empty!';
      noError = false;
    }
  }  
  setBookingError({...bookingError, ...errorObj})    
  return noError;
} 
 const handleSubmitButton = () => {
  let noError = validateBookingDetails();
  if(noError){
    let reqParams = {};       

     reqParams['start_date'] = convertLocalTimeToUtc(fromTime);
     reqParams['end_date'] = convertLocalTimeToUtc(endTime);
     reqParams['room_capacity'] = roomCapacity.replace(/\s+/g, "");
     reqParams['is_vc_booking'] = isVideoConference ? 'true' : 'false';    
     getAvailableRoomListFacilityBooking(reqParams);
  }
}

const compareHours = (hour, minutes) => {
  let index = staticStartHours.findIndex(item => item === `${hour}:${minutes <=  29 ? '30' : '00'}`);
   if(minutes > 29){
    index = index + 2;
  } 

  setStartHours(staticStartHours.slice(index, staticStartHours.length));
}

const handleRoomClick = id => {
  facilityBookingRedirectPaymentActionNull();
  localStorage.removeItem('facilityPaymentParams');
  // reduxBookingDataUpdate(bookingData);
   navigate(`/single_room_feature/${id}`)
}

const handleSearchText = value => {  
  setSearchText(value) 
  setSearchDebounce(value);
}

const handleOptionsReset = () => {
  setAvailableRoomList([]);
  getAvailableRoomListFacilityBookingNull();
  setSelectedChips('');
  setSearchText("");
  // reduxBookingDataUpdate({
  //   date: "",
  //   fromTime: "",
  //   endTime: "",
  //   roomCapacity:[],
  //   isVideoConference: false
  // })

  reduxBookingDataNull();
  setBookingError({
    date: "",
    fromTime: "",
    endTime: "",
    roomCapacity:"",
  })

}

const checkFilterEmpty = () => {  
  for(const key in reduxBookingData){
     if(key !== 'isVideoConference'){
      if(reduxBookingData[key].length > 0){
        return false;
      }
    }
  }
  return selectedChips.length > 0 ? false : true;
}

 
const ChoosingSlot = () => {
  return(
    <Box
    sx={{
      width: '100%',
      background: '#fff',
      borderRadius: '8px',
      color: '#0F2C6F',
      padding:'10px',
    }}
  >
    <Grid container >
      <Grid item xs={6} sx={{ p: 1, width:'100%' }}>
          <StyledTypography >Start Date</StyledTypography>
          <DesktopDatePicker
              inputFormat='dd/MM/yyyy'
              disablePast
              components={{
                  OpenPickerIcon:DatePickerIcon
              }}
              value={new Date(date) || null}
              onChange={(e) => {
                handleDate(e, 'date');
                 if(moment(e).format('DD/MM/YYYY') === moment(new Date()).format('DD/MM/YYYY')){
                  
                  let hours = new Date().getHours();
                  let minutes = new Date().getMinutes();
                  compareHours(hours < 10 ? '0' + hours : hours, minutes);                                
                               
                }else{
                  setStartHours(staticStartHours)
                }
                }}
              renderInput={(params) => <TextField onKeyDown={(e)=>e.preventDefault()} {...params} className={classes.customTextField} />} />
          <Typography sx={{ fontSize: "14px", color: 'red',mt:1 }}>
              {bookingError?.date}
          </Typography>
      </Grid>
      <Grid xs={6} sx={{ pl: 1, mt: 1, display: 'flex'}}>
                <Grid sx={{flex:1}}>
                    <StyledTypography>Start Time</StyledTypography>
                    <Select className={classes.select} sx={{width:'100%'}}  MenuProps={MenuProps} value={fromTime} defaultValue={fromTime || "10:00"}   onChange={(newValue) =>{ 
                        handleTime(newValue, 'fromTime')}}
                        disabled={date === ""}
                         >
                          <MenuItem value="" sx={{display: "none"}}><em>None</em></MenuItem>
                        {startHours && startHours?.map((value)=>(
                          <MenuItem sx={{color:'#0F2C6F'}} value={value}>{value}</MenuItem>
                          ))}
                    </Select>
                    
                    <Typography sx={{ fontSize: "14px", color: 'red',mt:1 }}>
                        {bookingError?.fromTime}
                    </Typography>
                </Grid>
                <Grid sx={{ml:1, flex:1}}>
                    <StyledTypography>End Time</StyledTypography>
                    <Select className={classes.select} sx={{width:'100%'}}  MenuProps={MenuProps} value={endTime} onChange={(newValue) => handleTime(newValue, 'endTime')}
                    disabled={date === ""}
                    >
                      <MenuItem value="" sx={{display: "none"}}><em>None</em></MenuItem>
                        {eventEndHours && eventEndHours?.map((value)=>(
                            <MenuItem sx={{color:'#0F2C6F'}} value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                   
                    <Typography sx={{ fontSize: "14px", color: 'red',mt:1 }}>
                        {bookingError?.endTime}
                    </Typography>
                </Grid>
            </Grid>
    </Grid>
    <Grid container >
      <Grid xs={6} sx={{ pl: 1, mt: 1, display: 'flex'}}>
          <Grid sx={{flex:1}}>
              <StyledTypography>Room Capacity (No. of Pax)</StyledTypography>
              <Box sx={{display:'flex',gap:'10px'}}>
              {chipsData.map((chip) => 
                <Chip
                  key={chip}
                  label={chip}
                  clickable
                  sx={{height:'48px',flex:1,color:'#0F2C6F' }}
                  onClick={() => handleChipClick(chip)}
                  // color={selectedChips.includes(chip) ? 'primary' : 'customColor'}
                  style={{ backgroundColor: roomCapacity === chip ? '#CFDDFE' : '#F3F4F8', }}
                />
              )}
              </Box>
          <Typography sx={{ fontSize: "14px", color: 'red',mt:1 }}>
              {bookingError?.roomCapacity}
          </Typography>
          </Grid>
      </Grid>
      <Grid xs={6} sx={{ mt: 1,  display: 'flex', justifyContent:'flex-end', alignItems:'flex-end'}}>
        <Box  sx={{  display: 'flex', justifyContent:'flex-end', alignItems:'flex-end', gap:'15px'}}>
          <Button variant='outlined' sx={{ textTransform: 'capitalize', color: '#0F2C6F',borderRadius: '8px', minWidth:'50px',  height: '48px',}} onClick={() => handleOptionsReset()}>Reset</Button>
          <Button variant='contained' sx={{textTransform: 'capitalize',color: '#FFFFFF', borderRadius: '8px', minWidth:'50px',  height: '48px', backgroundColor: '#123FA9', '&:hover': { backgroundColor: '#123FA9' }}}
          onClick={() => handleSubmitButton()}>Search</Button>
         </Box>
      </Grid>
     </Grid>
     {/* <Grid container >
      <Grid item xs={12} sx={{ pl: 1, mt: 3, display: 'flex',}}>
        <Typography variant='p' sx={{flex:'1', fontSize: "14px", color: '#0F2C6F',fontWeight:"400", alignSelf:'center' }}>Require Video Conference Capabilities</Typography>
        <FormGroup>
          <FormControlLabel
            control={<IOSSwitch sx={{ m: 1, flex:'1' }} defaultChecked={false} />}
          />
        </FormGroup>
      </Grid>
     </Grid> */}
  </Box>
  )
}

const BookingPaymentAlert = ()=> {
  return(
      <React.Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={paymentNotifyModel}
      >
        <DialogTitle sx={{ m: 0, p: 2, fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#BD0251' }} id="customized-dialog-title">
        Booking Unsuccessful!
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom sx={{mb:2}}>
          {paymentResponse}
          </Typography>
        </DialogContent>
        <DialogActions sx={{display:'flex', justifyContent:'flex-end'}}>

          <Button variant="contained" onClick={handleClose} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#BD0251',"&:hover":{backgroundColor:'#BD0251'}}}>
          <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Ok</Typography>
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  )
}

const EmptyData = () => {
  return(
    <Box sx={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent:'center', gap:'10px', padding:'60px 0', width:'100%'}}>
      <NoDataIcon />
      <Typography sx={{color:'#DBDFE9', fontSize:'14px',lineHeight:'24px', textAlign:'center', fontWeight:'500'}}>Select the date and time to view the available facilities.</Typography>
      
      
    </Box>
  )
}


  
  return (
    <Grid
    container
    sx={{ paddingTop: { md: "60px", xs: "20px" }, background: "#F9FAFA" }}
  >
    <BookingPaymentAlert />
    <Grid item lg={2} md={1} sm={1} xs={0.5} />
    <Grid item lg={8} md={10} sm={10} xs={11} >
  {/* <Grid item xs={1.5} />
    <Grid item xs={9}
     sx={{ display: "flex", flexDirection: "column", color: "#0F2C6F" }}
    > */}
      <BackButton
        cursor="pointer"
        onClick={() => navigate('/facility_booking')}
      />
      <Box sx={{margin: "30px 0",display:'flex', flexDirection:'column', gap:'15px'}}>
        <Typography
            variant="p"
            sx={{
            fontSize: {color:'#0F2C6F', md: "26px", sm: "18px", xs: "16px", fontWeight:'500' },
            }}
        >
            Make a Booking
        </Typography>
        <Typography variant="p" sx={{fontSize: '14px', color:'#6F80A9', fontWeight:'500'}}
        >
            Browse & book event spaces and function room facilities within Singapore Land Buildings.
        </Typography>
      </Box>

      <>
      {/* {loaderStatus && <Loader status={loaderStatus} />}
      {alertStatus && <Faultalert closeFunc={DeleteFunc} confirmFunc={confirmFunc} alertData={alertData}></Faultalert>} */}
      <ChoosingSlot/>
      <Box sx={{mt:3,  width: '100%',
          background: '#fff',
          borderRadius: '8px',
          color: '#0F2C6F',
          padding:'10px'}}>
        <Box sx={{display:'flex', justifyContent:'flex-end'}}>
          {/* <Box sx={{display: "flex", alignItems: "center", gap: "10px"}}>
            <Box sx={{ minWidth: 120, borderRadius:'50px' }}>
              <Grid >
              <FormControl size='small' sx={{ width: 180, pl: 1 }}>
              <InputLabel
                    sx={{ color: "#123FA9", fontWeight: "600", fontSize: '14px', backgroundColor:'white'}}
                >
                    Room Features
                </InputLabel> 
                      <Select  sx={{width:'100%', borderRadius:'6px',boxShadow: '0px 3px 2px 0px #EAF0FF'}} placeholder='RoomFeatures'  MenuProps={MenuProps} value={bookingData?.roomFeatures || ""} onChange={(newValue) => handleTime(newValue, 'roomFeatures')}
                      >
                        <MenuItem value="" sx={{display: "none"}}><em>None</em></MenuItem>
                        {tecFacilityTest.map(item => 
                              <MenuItem sx={{color:'#0F2C6F'}} key={item?.id} value={JSON.stringify(item)}>{item.name}</MenuItem>
                          )}                         
                      </Select>
              </FormControl>
              </Grid>
            </Box>
            <Box>
              <Typography sx={{color:"#2764F4",textDecoration:"underline",fontSize:"14px",ml:2,textUnderlineOffset:"5px",cursor:"pointer"}} >Reset</Typography>
            </Box>
          </Box> */}
          <Box>
        <Box
            sx={{float: "right", padding:'5px', display: "flex", alignItems: "center",border: " 1px solid #DBDFE9", borderRadius: "6px",}}>
            <Input
              name={'search'}
              onChange={(e) => handleSearchText(e.target.value)}
              size="small"
              type="text"
              value={searchText}
              placeholder='Search'
              style={{
                border: "none",
                outline: "none",
                fontSize: "14px",
                fontWeight: 400,
                width: "100%",
              }}
              inputProps={{
                sx: {
                  height: { md: "25px", sm: "20px", xs: "4px" },
                  fontSize: { md: "16px", sm: "14px", xs: "12px" },
                  flexGrow: 1,
                  width: "inherit",                  
                },
              }}
              ref={searchRef}
              disableUnderline
               disabled={getAvailableRoomListData.response?.dataArray?.tempAvailableRoomListArray === undefined || getAvailableRoomListData.isLoading}
            />
             {searchText && <IconButton onClick={() => {
               handleSearchText("")
              }}> <CloseIcon style={{width: 15, height: 15,  }} /></IconButton>}
            <SearchIcon  />
          </Box>
        </Box>
        </Box>
              
          {getAvailableRoomListData.isLoading === true && <Box sx={{width: "100%", display: "grid", placeItems: "center", height: "100px"}}><CircularProgress /></Box> }
          {checkFilterEmpty() &&  getAvailableRoomListData.isLoading !== true &&  <EmptyData/> }

          { (getAvailableRoomListData.response?.message.toLowerCase() === 'no data found' || (searchText.length > 0 && avaliableRoomList?.length === 0)) && getAvailableRoomListData.isLoading !== true &&
           <Box sx={{ display: 'flex', justifyContent: 'center', my: 2,p: 5,backgroundColor:"rgba(143, 146, 161,0.05)" }}>
           <Typography sx={{color:"#0F2C6F"}} variant='h6'>No Rooms Available</Typography>
       </Box>
          }

          <div id="facilityBookingScrollIntoView"></div>

          {(avaliableRoomList?.length > 0 && getAvailableRoomListData.isLoading === false ) && (<Box  sx={{pl:1,mt:2,mb:2, display:'flex', flexWrap:'wrap', gap:'30px'}}>
            {avaliableRoomList.length > 0 &&avaliableRoomList.map((item, index) => (

                    <Card sx={{ maxWidth: 232, minWidth:232, backgroundColor:'#F3F4F8', borderRadius:'16px' }} onClick={() => handleRoomClick(item.id)}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        width="232"
                        height="200"
                        image={item.image_path}
                        alt={item.image_name}
                      />
                      <CardContent>
                         <Box sx={{display:'flex',flexDirection:'column', ml:'20px',}}>
                         <Typography  sx={{ fontSize: "18px", fontWeight:'600', color:'#0F2C6F', lineHeight:'24px'}}>{item.title}</Typography>
                        <Typography  sx={{ fontSize: "14px", fontWeight:'400', color:'#0F2C6F', lineHeight:'24px'}}>level {item?.level} </Typography>
                       </Box>
                       <Typography  sx={{ ml:'20px',mb:1, fontSize: "16px", fontWeight:'400', color:'#6F80A9', lineHeight:'24px'}}>{item?.finalPrice} </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
            ))}
        </Box>) }
 
        {/* {avaliableRoomList?.length === 0 && getAvailableRoomListData?.isLoading === false && <Box sx={{ display: 'flex', justifyContent: 'center', p: 5,backgroundColor:"rgba(143, 146, 161,0.05)" }}>
                            <Typography sx={{color:"#0F2C6F"}} variant='h6'>No Data Found</Typography>
                        </Box>} */}

      </Box>
      </>
    </Grid>
    <Grid item lg={2} md={1} sm={1} xs={0.5} />
  </Grid>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAvailableRoomListFacilityBooking: (data) => {
      dispatch(getAvailableRoomListFacilityBookingAction(data))
    },
    getAvailableRoomListFacilityBookingNull: ( ) => {
      dispatch(getAvaiiableRoomListFacilityBookingNullAction( ))
    },
    facilityBookingRedirectPaymentActionNull: () => dispatch(facilityBookingRedirectPaymenNullAction()),
    getTecFeatureList: () => dispatch(getTecFeatureListAction()),
    reduxBookingDataNull: () => dispatch(reduxFacilityBookingRoomDataNullAction()),
    reduxBookingDataUpdate: (data) => dispatch(reduxFacilityBookingRoomDataUpdateAction(data))
  }
}

const mapStateToProps = (state) => {
  return {
    getAvailableRoomListData: state.FacilityBookingReducer.getAvailableRooms,
    facilityBookingRedirectPaymentActionData: state.FacilityBookingReducer.facilityBookingRedirectPayment,
    getTecFeatureListData:  state.FacilityBookingReducer.getTecFeatureList,
    reduxBookingData:  state.FacilityBookingReducer.reduxBookingData,
     
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingSelection)
