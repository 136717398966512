import { Box, Modal, Typography } from '@mui/material';
import Location from '../../Assets/icons/location.svg';
import Clock from '../../Assets/icons/clock.svg';
import Phone from '../../Assets/icons/phone.png';
import Email from '../../Assets/icons/email.png';
import { ReactComponent as CloseIcon } from '../../Assets/icons/Close.svg';
import moment from 'moment';
import React from 'react'

const ModalView = ({ handleModalClose, item}) => {

    const modalClose = () => {
        handleModalClose();
    }


    const viewDetailsArray = [{icon: Location, title: "Location"}, {icon: Clock, title: "Hours"}, {icon: Phone, title: "Contact"}, {icon: Email, title: "Email"}];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: `${window.innerWidth * 0.6}px`,
        bgcolor: 'background.paper',
        border: '1px solid #808080',
        boxShadow: 24,
        borderRadius: '10px',
        p: 4,
        outline: "none",
        overflowY: 'auto',       
      };

      const getViewDetailsData = (id) => {
        // let [selectedItem] = tableData.filter(item => item.eve_pro_id === Number(selectedId));

        let selectedItem = item;
    
        if(id === 'Location'){     
            return (selectedItem.location !== null && selectedItem.location !== undefined) ? selectedItem.location : "-"      
        }else if(id === 'Hours'){
          if(selectedItem.env_type === 'Promotion' && selectedItem.timings.length > 0){      
            return <>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: "2px", width: "100%"}}>
              <Box sx={{display:'flex',justifyContent:'flex-end'}}>
                  <Typography>{moment(item.event_start_time).format('DD MMM yyyy hh:mm A')}</Typography>
                  <Typography sx={{mx:2}}>-</Typography>
                  <Typography>{moment(item.event_end_time).format('DD MMM yyyy hh:mm A')}</Typography>
              </Box>
                {selectedItem.timings.map((item,index) => {
                  if(item.closed_status === 0){
                    return <>
                        <Box sx={{display: 'flex', gap: "20px", alignItems: 'center', justifyContent: "flex-end", }} key={index}>
                          <Typography sx={{textTransform:'capitalize'}}>{item.day}</Typography>
                          <Box sx={{display: 'flex', alignItems: "center", gap: "10px"}}>
                            <Typography >{get12HrsTime(item.from_time)}</Typography>
                            <Typography>-</Typography>
                            <Typography >{get12HrsTime(item.to_time)}</Typography>
                          </Box>                
                        </Box>
                        </>
                  }
                }
                )}
            </Box>
            </>
          }else{
            return <Box sx={{display: 'flex', flexDirection: 'row', gap: "10px",justifyContent: 'center',alignItems: 'center'}}>

            <Typography>{moment(item.event_start_time).format('DD MMM yyyy hh:mm A')}</Typography>
            <Typography>-</Typography>
            <Typography>{moment(item.event_end_time).format('DD MMM yyyy hh:mm A')}</Typography>
            
            </Box>
            
          }
        }
        else if(id === 'Contact'){      
          console.log('contact ',selectedItem.mobile_code );
          if((selectedItem.mobile_no !== null && selectedItem.mobile_no !='' && selectedItem.mobile_no !='-') && (selectedItem.mobile_code !== null && selectedItem.mobile_code !='' && selectedItem.mobile_code !='-')) {
            console.log('within sd')
          } else {
            console.log('elese ');
          }
            // return selectedItem.mobile_no !== null && selectedItem.mobile_code !== null? `(${selectedItem.mobile_code.includes("+") ? selectedItem.mobile_code : `+${selectedItem.mobile_code}`}) ${selectedItem.mobile_no}` : '-'      
            return (selectedItem.mobile_no !== null && selectedItem.mobile_no !='' && selectedItem.mobile_no !='-') && (selectedItem.mobile_code !== null && selectedItem.mobile_code !='' && selectedItem.mobile_code !='-') ?  `(${`${selectedItem.mobile_code}`}) ${selectedItem.mobile_no}` : '-'      
        }else if(id === 'Email'){      
            return selectedItem.email !== null ? selectedItem.email : "-";      
        }
        else{
          return null;
        }
      }
    
      const get12HrsTime = (time) => {
        if(time !== null && time !== undefined){         
          
          return moment(time, "HH:mm:ss").format("h:mm A");
        }else{
          return "-"
        }
      }

      const getLocalTime = (time) => {
        return moment.utc(time).local().format(' HH:mm A'); 
      }
    

  return (
    <Modal
      open={true}
      onClose={() => modalClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
    
  <Box sx={style}>
    <Box sx={{color: "#123FA9"}}>
        <Box sx={{paddingBottom: "20px", display: 'flex', justifyContent: "space-between", alignItems: 'center',}}>
          <Typography sx={{fontSize: "1.275rem", fontWeight: 600}}>Events and Key Highlights</Typography>
          <CloseIcon style={{height: "25px", width: "25px", cursor: "pointer"}} onClick={() => modalClose()} />
        </Box>
        <Box style={{height: `${window.innerHeight * 0.7}px`, overflowY: 'auto', paddingRight: "10px"}}>
          <Box style={{display: 'flex', width: "100%", position: 'relative'}}>

          <Box style={{position: 'absolute', alignSelf: 'flex-end', background: 'linear-gradient(to bottom,transparent 0%, rgb(90, 90, 90, 0.001) 20%,rgb(90, 90, 90, 0.2) 20%, rgb(90, 90, 90, 0.4) 40%,rgb(90, 90, 90, 0.5) 60%, rgb(90, 90, 90, 0.6) 80%, rgb(90, 90, 90, 0.7) 100%)', color: 'white', fontSize: "1.15rem", display: 'flex', flexDirection: 'column', padding: "15px", width: '-webkit-fill-available', gap: "4px"}}><Typography variant='p' sx={{width: 'auto'}}>{item.title}</Typography>
          {item.name !== null && <Typography variant='p' sx={{fontSize: "1rem"}}>{item.name}</Typography>}
          </Box>
          <img src={item?.assets?.cover?.new_filename} alt={item?.assets?.cover?.orig_filename} style={{height: "300px", width: "100%", objectFit: "contain"}} /></Box>

        <Box sx={{display: 'flex', flexDirection: 'column', padding: "20px 0", gap: "10px"}}>
          <Typography varaint='h4' sx={{fontSize: "1rem",maxWidth:`${window.innerWidth * 0.6}px`,wordBreak:'break-word'}}>
            <pre style={{ overflowX: 'auto',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontSize: '15px'}} dangerouslySetInnerHTML={{ __html: item?.desc }} />
            </Typography>
          <Box sx={{display: 'flex', alignItems: 'center', overflowX: "auto"}}>
            {item.assets.add_on.length > 0 && item.assets.add_on.map(ele  => <img src={ele.new_filename} alt={ele.orig_filename} style={{height: "125px", width: "125px",margin:'10px',objectFit: "contain", borderRadius: "10px"}}  />)}

          </Box>
          
        </Box>

        <Box sx={{display: 'flex', flexDirection: 'column',gap: "2.5px", paddingBottom: "10px"}}>
          <Typography variant='h6' sx={{fontSize: "1rem", fontWeight: 500}}>CONDITIONS</Typography>
          <Typography  sx={{fontSize: "1rem",fontWeight: 500,maxWidth:`${window.innerWidth * 0.6}px`,wordBreak:'break-word'}}>
            <pre style={{ overflowX: 'auto',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontSize: '15px'}} dangerouslySetInnerHTML={{ __html: item.t_and_c }} />
            </Typography>
        </Box>

        <Box sx={{background: "#F8F9FB", padding: "20px", display: 'flex', flexDirection: 'column', gap: "15px",borderRadius: "10px"}}>
        {viewDetailsArray.map((detailItem, index) => <Box sx={{display: 'flex',justifyContent: 'space-between', width: "100%"}} key={index}>
          <Box sx={{display: 'flex',  alignSelf: 'flex-start', width: "50%"}}>
            <Box sx={{display: 'flex', alignItems: 'center', gap: "10px"}}>

        <img src={detailItem.icon} alt={detailItem?.title} width="20"  />
        <Typography variant='p'>{detailItem?.title}</Typography>
            </Box>
          </Box>

          <Box sx={{width: "50%", display: 'flex', justifyContent: "flex-end", alignItems: 'center'}}>{getViewDetailsData(detailItem?.title)}</Box>
        </Box>)}
        </Box>

        </Box>

        </Box>
     
  </Box>

</Modal>
  )
}

export default ModalView