import { Box, Button, Grid, Typography, TextField, CircularProgress } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import * as CommonAction from '../../redux/actions/commonAction';
import ToastAlert from "../common/Alert";



const SendEmail = ({ testMailStatus, testMail, testMailNullAction}) => {

const [mailData, setMailData] = useState({email:"",subject:"",message:""});
const [error, setError] = useState({})
const [empOffStatus, setEmpOffStatus] = useState({ message: '', status: false, color: '', icon: '' })

const handleChange = (value, dataFrom) => {
    setMailData(prev => ({...prev, [dataFrom]:value}))
    setError({
        ...error,
        [dataFrom]: ''
    })
}
const validate = () => {
    let newErrors = [], errorMessges = {};
    const emailRegx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mailData.email)
    if (mailData.email === undefined || mailData.email=='') {
        newErrors.push(false)
        errorMessges['email'] = 'Please fill out this field'
    } else if(!emailRegx) {
        newErrors.push(false)
        errorMessges['email'] = 'Please enter valid email'
    }else {
        newErrors.push(true)
        errorMessges['email'] = ''
    }
    if (mailData.subject === undefined || mailData.subject=='') {
        newErrors.push(false)
        errorMessges['subject'] = 'Please fill out this field'
    } else if(mailData.subject && mailData.subject.length < 3 ) {
        newErrors.push(false)
        errorMessges['subject'] = 'Must be atleast 3 characters'
    } else {
        newErrors.push(true)
        errorMessges['subject'] = ''
    }
    if (mailData.message === undefined || mailData.message=='') {
        newErrors.push(false)
        errorMessges['message'] = 'Please fill out this field'
    } else {
        newErrors.push(true)
        errorMessges['message'] = ''
    }
    let errorStatus = newErrors.includes(false)
    setError({ ...errorMessges })
    return !errorStatus;
}
console.log('mailData ',mailData)
 const handleSubmit = () => {
    const err = validate()
    if(err) {
        testMail({
            email: mailData.email,
            subject: mailData.subject,
            message: mailData.message
        })
    }

 }

 useEffect(() => {
    if(testMailStatus?.response != null) {
    if(testMailStatus?.response?.response_status == 'success') {
        setEmpOffStatus({ message: testMailStatus?.response?.message, status: true, color: 'green', icon: 'close' })
        setMailData({email:"",subject:"",message:""})
        setTimeout(()=> {
            testMailNullAction()
            setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
        },5000)
    } else if(testMailStatus?.response?.response_status == 'fail') {
        setEmpOffStatus({ message: testMailStatus?.response?.message, status: true, color: 'red', icon: 'close' })
        setTimeout(()=> {
            testMailNullAction()
            setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
        },5000)
    } else {
        setEmpOffStatus({ message: 'something went wrong', status: true, color: 'red', icon: 'close' })
        setTimeout(()=> {
            testMailNullAction()
            setEmpOffStatus({ message: '', status: false, color: '', icon: '' })
        },5000)
    }
}
},[testMailStatus])

    return (
        <>
        {empOffStatus.status && <ToastAlert message={empOffStatus.message} status={empOffStatus.status} color={empOffStatus.color} icon={empOffStatus.icon} closeFunc={() => setEmpOffStatus({ message: '', status: false, color: '', icon: '' })}/>}
        <Grid container sx={{backgroundColor:"rgba(143, 146, 161,0.05)",minHeight:"100%"}}>
            <Grid item xs={0.5} />
            <Grid item xs={11} >

               <Box sx={{display:"flex",justifyContent:"space-between",mt:8, width: "100%"}}>
                    <Box sx={{width: "100%"}}>
                            <Typography style={{ fontSize: "26px", fontWeight: "500", color: '#002D74' }}>
                                Testing Email
                            </Typography>
                          
                        </Box>
                       
                </Box> 
               
                <Box
                    sx={{ background: "white", borderBottom: "1px solid #DBDFE9", borderRadius: "8px 8px 0px 0px",mt:4 }}
                >
                    <Box sx={{p:4}}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "550", color: '#0F2C6F', fontFamily: 'Inter',mb:1 }}>
                        Email*
                    </Typography>
                    <TextField
                        onChange={(e) => handleChange(e.target.value, 'email')}
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        name='email'
                        value={mailData?.email}
                    />
                    <Typography variant='p' sx={{ fontSize: "14px", color: 'red', pb:3 }}>{error?.email}</Typography>
                    <Typography sx={{mb:3}}></Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "550", color: '#0F2C6F', fontFamily: 'Inter',mb:1 }}>
                        Subject*
                    </Typography>
                    <TextField
                        fullWidth
                        onChange={(e) => handleChange(e.target.value, 'subject')}
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        name='subject'
                        value={mailData?.subject}
                    />
                    <Typography variant='p' sx={{ fontSize: "14px", color: 'red',pb:3 }}>{error?.subject}</Typography>
                    <Typography sx={{mb:3}}></Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "550", color: '#0F2C6F', fontFamily: 'Inter',mb:1 }}>
                        Message*
                    </Typography>
                    <TextField fullWidth id="outlined-basic"
                        onChange={(e) => handleChange(e.target.value, 'message')}
                        label=""
                        variant="outlined"
                        name='message'
                        multiline
                        minRows={3}
                        maxRows={5}
                        value={mailData?.message}
                    />
                    <Typography variant='p' sx={{ fontSize: "14px", color: 'red',mb:3 }}>{error?.message}</Typography>
                    <Typography sx={{mb:3}}></Typography>
                    <Button
                    variant="contained"
                    disabled={testMailStatus.isLoading}
                    sx={{
                      width:'120px',
                      height: '40px',
                      textTransform: "none",
                      borderRadius: "8px",
                      fontSize: { md: "14px", xs: "13px" },
                    }}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Send
                    {testMailStatus.isLoading === true  && <CircularProgress sx={{ml:'12px' }} size={20} />}
                  </Button>
                    </Box>
                   </Box>
            </Grid>
            <Grid item xs={0.5} />
        </Grid>

        </>
    )
}

const mapStateToProps = (state) => {
    return {
        testMailStatus: state.CommonReducer.testMail
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        testMail: (data) => (dispatch(CommonAction.testMail(data))),
        testMailNullAction: () => dispatch(CommonAction.testMailNullAction()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SendEmail)