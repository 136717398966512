import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { Box, Button, Grid, OutlinedInput, Typography, styled,Dialog,DialogTitle, DialogContent, DialogActions,IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import * as ServiceAction from "../../redux/actions/service_request/index"
import { deleteLightProduct } from "../../redux/actions/service_request/index";
import ToastAlert from "../common/Alert";
import Loader from "../common/Loader";
import { makeStyles } from '@mui/styles';
import Close from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
const StyledTypography = styled(Typography)({
    color: '#0F2C6F', 
    fontSize: '16px',
    marginBottom:'8px'
})

const Style = makeStyles({
    input:{
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
})

const AddProduct = ({lightBulbProduct,getLightProductById,addLightProduct,editLightProduct,addLightBulbProductStatus,setServiceRequestStateNull, deleteLightProduct})=>{
    const navigate = useNavigate()
    const {id} = useParams()
    const [data,setData] = useState({name:"",code:'',price:""})
    const [error,setError] = useState({name:'',code:"",price:''})
    const [toastStatus,setToastStatus] = useState({color:'',status:false,message:'',icon:''})
    const customStyle = Style()
    const [openModal, setOpenModal] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSaveClose = () => {
        setOpenSaveModal(false);
    };

    useEffect(()=>{
        if(id!='null'){
            getLightProductById({light_bulb_products_id:id})
        }
    },[])   

    useEffect(()=>{
        if(lightBulbProduct?.length>0 && id!='null'){
            setData({
                name:lightBulbProduct[0]?.light_bulb_products_name,
                code:lightBulbProduct[0]?.material_code,
                price:lightBulbProduct[0]?.price
            })
        }
    },[lightBulbProduct])

    useEffect(()=>{
        if(addLightBulbProductStatus?.response_status==1){
            console.log('qithin useEffect add ',addLightBulbProductStatus)
            setToastStatus({color:'green',status:true,message:addLightBulbProductStatus.message,icon:'tick'})
            setData({name:"",code:'',price:""})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
                navigate(-1)
            },3000)
        }else if(addLightBulbProductStatus?.response_status==0){
            setToastStatus({color:'red',status:true,message:addLightBulbProductStatus.message,icon:'close'})
            setTimeout(()=>{
                setToastStatus({color:'',status:false})
                setServiceRequestStateNull()
            },3000)
        }
    },[addLightBulbProductStatus])

    const handleData = (e)=>{
        let {name,value} = e.target
        setData({
            ...data,
            [name]:value
        })
        setError({
            ...error,
            [name]:''
        })
    }

    const validate = ()=>{
        let err = false
        if(data.name==''){
            setError((prev)=>({
                ...prev,
                name:'Enter product name'
            }))
            err=true
        }
        if(data.code==''){
            setError((prev)=>({
                ...prev,
                code:'Enter product code'
            }))
            err=true
        }
        if(data.price==''){
            setError((prev)=>({
                ...prev,
                price:'Enter product price'
            }))
            err=true
        } else if(data.price <= 0) {
            setError((prev)=>({
                ...prev,
                price:'Price must be greater than 0'
            }))
            err=true
        }
        return err
    }   

    const handleSubmit = ()=>{
        let isError = validate()
        if(!isError){
            if(id=='null'){
               setOpenSaveModal(true)
            }else{
                editLightProduct({
                    light_bulb_products_name:data.name,
                    material_code:data.code,
                    price:data.price,
                    light_bulb_products_id:id
                })
            }
        }
    }

    const tryToDelete = () => {
        console.log('within')
        setOpenModal(true)
    }

    const ModalAlert = ()=> {
        return(
            <React.Fragment>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={openModal}
            >
              <DialogTitle sx={{ m: 0, p: 2, fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#0F2C6F' }} id="customized-dialog-title">
              Confirm Delete Product - {data.name} 
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <DialogContent dividers>
                <Typography gutterBottom>
                    Are you sure you wish to delete this product
                </Typography>

                <Typography gutterBottom sx={{mb:2}}>
                    - {data.name} (Material Code {data.code})
                </Typography>

                <Typography gutterBottom>
                    Once deleted,
                </Typography>

                <Typography gutterBottom>
                the product will not be available for selection in existing service request forms.
                </Typography>
              </DialogContent>
              <DialogActions sx={{display:'flex', justifyContent:'space-between'}}>
              <Button variant="outlined" onClick={handleClose} sx={{borderRadius:'6px', p:'8px 10px',}}>
                  <Typography sx={{color:'#0F2C6F', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter'}}>Cancel</Typography>
                </Button>
                <Button variant="contained" onClick={()=>{
                    deleteLightProduct({light_bulb_products_id: id})
                    setOpenModal(false)
            }} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#BD0251',"&:hover":{backgroundColor:'#BD0251'}}}>
                <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Delete Now</Typography>
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
        )
    }

    const ModalSaveAlert = ()=> {
        return(
            <React.Fragment>
            <BootstrapDialog
              onClose={handleSaveClose}
              aria-labelledby="customized-dialog-title"
              open={openSaveModal}
            >
              <DialogTitle sx={{ m: 0, p: 2, fontWeight:'500', fontSize:'18px', lineHeight:'24px', letterSpacing:'-0.25px', color:'#0F2C6F' }} id="customized-dialog-title">
              Confirm additon of new product
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleSaveClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
              <DialogContent dividers>
                <Typography gutterBottom>
                Please ensure the rate displayed is correct. 
                </Typography>
                <Typography gutterBottom>
                Once updated, the new product will be visible for new service requests.
                </Typography>
              </DialogContent>
              <DialogActions sx={{display:'flex', justifyContent:'space-between'}}>
              <Button variant="outlined" onClick={handleSaveClose} sx={{borderRadius:'6px', p:'8px 10px',}}>
                  <Typography sx={{color:'#0F2C6F', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter'}}>Cancel</Typography>
                </Button>
                <Button variant="contained" onClick={()=>{
                   addLightProduct({
                    light_bulb_products_name:data.name,
                    material_code:data.code,
                    price:data.price,
                   
                    }) 
                    setOpenSaveModal(false)
            }} sx={{borderRadius:'6px', p:'8px 10px',textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}}>
                <Typography sx={{color:'#fff', fontSize:'14px', fontWeight:'600',lineHeight:'24px', fontFamily:'Inter', }}>Confirm</Typography>
                </Button>
              </DialogActions>
            </BootstrapDialog>
          </React.Fragment>
        )
    }

    return(
        <>
        <ModalSaveAlert />
        <ModalAlert />
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:'100% '}}>            
            <Grid item xs={2} />
            <Grid item xs={8} sx={{mt:7}}>
             <ToastAlert  message={toastStatus?.message} status={toastStatus?.status}  color={toastStatus?.color} icon={toastStatus?.icon}  />  
                <BackButton style={{cursor:'hover'}} onClick={()=>navigate(-1)} />
                <Box sx={{my:2}}>
                    <Typography sx={{color:'#0F2C6F',fontSize:"20px"}}>{id !='null' ? 'Edit Product Detail' : 'Add a new product'}</Typography>
                    <Typography sx={{color:'#6F80A9', fontFamily:'Inter', fontWeight:'500', fontSize:'14px', lineHeight:'20px', letterSpacing:'-0.25px'}}>Once product details are updated, it will be reflected in all new service requests.  </Typography>
                </Box>
                <Box sx={{mt:3,backgroundColor:"#FFFFFF",p:2}}>
                    <StyledTypography >Product Name</StyledTypography>
                    <OutlinedInput multiline inputProps={{maxLength:255}} fullWidth name="name" value={data.name} onChange={(e)=>handleData(e)} />
                    <Typography sx={{color:'red'}}>{error.name}</Typography>
                </Box>
                <Grid container  sx={{backgroundColor:"#FFFFFF",p:2}}>
                    <Grid item xs={5.5}>
                        <Box sx={{my:3}}>
                            <StyledTypography>Material Code</StyledTypography>
                            <OutlinedInput className={customStyle.input} type="number" fullWidth name="code" value={data.code} onChange={(e)=>handleData(e)} />
                            <Typography sx={{color:'red'}}>{error.code}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={5.5}>
                        <Box  sx={{my:3}}>
                            <StyledTypography>Price ($)</StyledTypography>
                            <OutlinedInput className={customStyle.input} type="number" fullWidth name="price" value={data.price} onChange={(e)=>handleData(e)} />
                            <Typography sx={{color:'red'}}>{error.price}</Typography>
                        </Box>
                    </Grid>
                </Grid>
               
                <Box sx={{display:'flex',justifyContent:"flex-end",mt:5, gap:'15px'}}>
                    {id !='null' && <Button sx={{textTransform:'capitalize'}} variant="outlined" onClick={tryToDelete}>Delete</Button>}
                    <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9',"&:hover":{backgroundColor:'#123FA9'}}} variant="contained" onClick={handleSubmit}>{id !='null' ? 'Save' : 'Create'}</Button>
                </Box>
            </Grid>
            <Grid item xs={2} />
        </Grid>
        </>
    )
}

const mapStateToProps = (state)=>{
    return{
        lightBulbProduct:state.Service_Req_Reducer.lightBulbProduct,
        addLightBulbProductStatus:state.Service_Req_Reducer.addLightBulbProductStatus
    }

}

const mapDispacthToProps = (dispatch)=>{
    return{
        getLightProductById:(data)=>dispatch(ServiceAction.getLightProductById(data)),
        addLightProduct:(data)=>dispatch(ServiceAction.addLightProduct(data)),
        editLightProduct:(data)=>dispatch(ServiceAction.editLightProduct(data)),
        setServiceRequestStateNull:()=>dispatch(ServiceAction.setServiceRequestStateNull()),
        deleteLightProduct:(data)=>dispatch(deleteLightProduct(data)),
    }
}
export default connect(mapStateToProps,mapDispacthToProps)(AddProduct)