import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react';
import {ReactComponent as DownloadIcon} from '../../../Assets/icons/servicedownload.svg';
import {ReactComponent as FillFormIcon} from '../../../Assets/icons/fillform.svg';
import {ReactComponent as SubmitIcon} from '../../../Assets/icons/submit.svg';
import {ReactComponent as ArrowIcon} from '../../../Assets/icons/rightarrowicon.svg';
import {ReactComponent as PDFIcon} from '../../../Assets/icons/PDFIcon.svg';
import {ReactComponent as OutlinedDownloadIcon} from '../../../Assets/icons/OutlinedDownload.svg';
import { ReactComponent as Excel } from "../../../Assets/icons/Excel.svg";
import * as ServiceAction from "../../../redux/actions/service_request";
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import { useLocation } from 'react-router-dom';
import DownloadableServiceRequest from '../../ManageServiceRequestForms/DownloadableServiceRequest';

const filedData = [
    {id:1,  name:"Use of Common Area" },{id:2,  name:"Use of Common Area" },{id:3,  name:"Use of Common Area" },
    {id:4,  name:"Use of Common Area" },{id:5,  name:"Use of Common Area" },{id:6,  name:"Use of Common Area" },
    {id:7,  name:"Use of Common Area" },{id:8,  name:"Use of Common Area" },{id:9,  name:"Use of Common Area" },
    {id:10,  name:"Use of Common Area" },{id:11,  name:"Use of Common Area" },
]


const MiscelleaneousOthers = ({miscelleaneousFormPdf,getMiscelleaneousForm})=> {
    const location = useLocation();
    useEffect(()=>{
        getMiscelleaneousForm()
    },[])

    const handleFileDownload = (path,name)=>{
        saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${path}`,`${name}`)
    }
  return (
    <>
    <Box sx={{p:3,mt:2}}>
        {location.pathname.includes('/service_request_forms') ? <DownloadableServiceRequest />:
        <>
        <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>Other Services Request Form</Typography>
        {/* <Typography sx={{color:" #6F80A9",fontSize:"14px"}}>Please download the form below and submit it to bms.admin@singaporelandtower.com</Typography> */}
        <Typography sx={{color:" #6F80A9",fontSize:"14px",my:1}}>Please download the forms below and submit it to <span style={{color:'#2764F4'}}>bcslt@singaporeland.com.</span> </Typography>
        {/* <Typography sx={{color:" #6F80A9",fontSize:"14px"}}>* For any enquires,please contact BMS Team at 6011 6005.</Typography> */}
        <Grid container rowSpacing={3} columnSpacing={3} sx={{mt:2}}>
            {
                miscelleaneousFormPdf?.map((value)=>(
                    <Grid item xs={12} sm={6}>
                       <Box sx={{display:"flex",justifyContent:"space-between",border:"1px solid #DBDFE9",borderRadius:"8px",p:2, alignItems:'center'}}>
                            <Box sx={{ml:2,mr:2, display:'flex', alignItems:'center', gap:'15px'}}>
                               {value.file_type=='excel' ? <Excel /> : <PDFIcon /> }
                                <Typography sx={{color:"#0F2C6F",fontSize:"14px",textAlign:"left"}}>{value.name}</Typography>
                                {/* <Typography sx={{color:"#9AA6C3",fontSize:"12px"}}>56 of 56KB</Typography> */}
                            </Box>
                            <OutlinedDownloadIcon style={{cursor:"pointer"}} onClick={()=>handleFileDownload(value.path,value.name)} />
                       </Box>
                    </Grid>
                ))
            }
        </Grid>
        <Box sx={{mt:5}}>
            <Typography sx={{color:"#0F2C6F",fontSize:"18px"}}>How to Submit the Miscellaneous Form</Typography>
            <Typography sx={{color:" #6F80A9",fontSize:"14px",my:1}}>Please follow the steps listed below to submit the forms.</Typography>
            <Typography sx={{color:" #6F80A9",fontSize:"14px"}}>If you need any urgent assistance, you may reach Building Management Services at <span style={{color:'#2764F4'}}>bcslt@singaporeland.com</span> or at <span style={{color:'#2764F4'}}>6011 6005.</span></Typography>
            <Grid container sx={{mt:8}}>
                <Grid item xs={3} >
                    <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <DownloadIcon />
                        <Typography sx={{color:"#0F2C6F",fontSize:"18px",mt:2}}>Download</Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:2, textAlign:'center'}}>Download the service form you needed</Typography>
                    </Box>
                </Grid>
                <Grid item xs={1}>
                    <Box sx={{mt:'50%',display:"flex",justifyContent:"center"}}>
                            <ArrowIcon />
                    </Box>
                </Grid>
                <Grid item xs={3} >  
                    <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <FillFormIcon />
                        <Typography sx={{color:"#0F2C6F",fontSize:"18px",mt:2}}>Fill the form</Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:0}}>Print out and fill in the form.</Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:0}}>OR</Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:0}}>E-fill the form.</Typography>
                    </Box>
                 </Grid>
                <Grid item xs={1} >
                    <Box sx={{mt:'50%',display:"flex",justifyContent:"center"}}>
                        <ArrowIcon />
                    </Box>
                </Grid>
                <Grid item xs={4} >
                    <Box sx={{display:"flex",flexDirection:"column",alignItems:'center'}}> 
                        <SubmitIcon />
                        <Typography sx={{color:"#0F2C6F",fontSize:"18px",mt:2}}>Submit!</Typography>
                        {/* <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:2}}>Scan and submit to <span style={{color:"#6F80A9",fontSize:'14xp'}}>bms.admin@singaporelandtower.com</span></Typography> */}
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:0}}>Attach as a <span style={{fontWeight:'bold'}}>PDF</span></Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:0}}>and submit via email</Typography>
                        <Typography sx={{color:"#0F2C6F",fontSize:"12px",mt:0}}>to</Typography>
                        <Typography sx={{color:"#2764F4",fontWeight:'400',fontSize:"12px",mt:0}}>bcslt@singaporeland.com</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Typography sx={{color:"#6F80A9",fontSize:"14px",mt:3}}>*For urgent ad-hoc request after office hours, forms must be sent personally to senior security officer at 1st storey, Fire Command Centre for processing.</Typography>
        </Box>
        </> }
    </Box>
    </>
  )
}

const mapStateToProps = (state)=>{
    return{
        miscelleaneousFormPdf:state.Service_Req_Reducer.miscelleaneousFormPdf
    }
}           

const mapDispatchToProps = (dispatch)=>{
    return{
        getMiscelleaneousForm:()=>(dispatch(ServiceAction.getMiscelleaneousForm()))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(MiscelleaneousOthers);