import React from 'react'
import Header from '../Header'
import FaultReporting from '../Pages/FaultReporting'
import { Route, Routes } from 'react-router-dom'
import Faults from '../Pages/FaultReporting/Faults'
import TenantVisitors from '../Pages/TenantVisitors'
import BatchInvitation from '../Pages/TenantVisitors/BatchInvitation'
import FacilityBooking from '../Pages/FacilityBooking'
import BookingSelection from '../Pages/FacilityBooking/BookingSelection'
import SingleRoomFeature from '../Pages/FacilityBooking/SingleRoomFeature'
import ConfirmedBooking from '../Pages/FacilityBooking/ConfirmedBooking'
import PaymentConfirmation from './PaymentConfirmation'
import InviteViaExcel from '../Pages/TenantVisitors/InviteViaExcel'
import ReviewInvite from '../Pages/TenantVisitors/ReviewInvite'
import ServiceRequestList from "../Pages/ServiceRequest/Index.js";
import ReviewReport from '../Pages/FaultReporting/ReviewReport.js'
import FCCDashboard from '../Pages/Dashboard/FCCDashboard.js'
import ManageVisitor from '../Pages/Visitors/ManageVisitorBms/index.js';
import UpcommingVisitors from '../Pages/Visitors/UpcommingVisitors.js';
import ReviewManageInvite from '../Pages/Visitors/UpcommingVisitors.js/ReviewManageInvite';
import ReviewSpecialAccess from '../Pages/Visitors/ManageVisitorBms/ReviewSpecialAccess.js';
import VisitorManagement from "../Pages/Visitors/ManageVisitorBms/VisitorManagement.js";
import ReviewRegistered from '../Pages/Visitors/ManageVisitors.js/ReviewRegistered';
import ReviewRequest from '../Pages/ServiceRequest/ReviewRequests/index.js'

const FCC = () => {

 
  return (
    <>
    <Header>
        <Routes>
         <Route path='/dashboard' element={<FCCDashboard />} />
        <Route path="/manage_faults/:tabvalue" element={<FaultReporting />} />
        <Route path="/report_fault/:isDraft/:id" element={<Faults />} />
        <Route path="/review_fault/:id/:tabValue/:fromDashboard" element={<ReviewReport />} />
        <Route path="/service_request_list/:tab_id" element={<ServiceRequestList />} />        
        <Route path="/review_service_request/:service_req_id/:service_req_type_id/:tab_id/:fromDashboard" element={<ReviewRequest />} />
        <Route path="/tenant_visitors/:tabValue" element={<TenantVisitors/>} />
        <Route path="/batch_invitation" element={<BatchInvitation/>} />
          <Route path="/batch_invitation_excel" element={<InviteViaExcel  />} />
          <Route path="/facility_booking" element={<FacilityBooking />} />
          <Route path="/booking_selection" element={<BookingSelection />} />
          <Route path='/single_room_feature/:feature_id' element={<SingleRoomFeature />} />
          <Route path="/facility_booking/:facility_id" element={<ConfirmedBooking />} />
          <Route path="/payment_response" element={<PaymentConfirmation />} />   
          <Route path="/my_faults/:tabvalue" element={<FaultReporting />} />      
          <Route path="/review_tenant_inivte/:tabValue/:invite_id/:isEdit" element={<ReviewInvite/>} />
          <Route path="/visitor_management" element={<VisitorManagement />} />
          <Route path="/manage_visitor/:tabValue" element={<ManageVisitor />} />
          <Route path="/upcomming_visitors/:tabValue" element={<UpcommingVisitors />} />
          <Route path="/review_manage_invite/:tabValue/:invitation_id/:invitation_type" element={<ReviewManageInvite />} />
          <Route path='/review_specialaccess/:id' element={<ReviewSpecialAccess/>} />
          <Route path='/review_registered/:id/:type' element={<ReviewRegistered />} />
        </Routes>
    </Header>
    </>
  )
}

export default FCC