
import { Box,  Button,  Grid, Typography  } from "@mui/material";
import React, { useState } from "react";
import Dropzone from "react-dropzone";

const DropZone =({getFiles,onlyPdf,onlyImage,singleFile, customFileFormat = undefined, customFileText = undefined})=>{
  const [formatError,setFormatError] = useState('')
  let acceptAll = {
    'image/*':[],
    'application/pdf':[],
    'application/vnd.ms-excel':[],
    'text/csv':[] 
  }

  let acceptImage = {
    'image/*':[],
  }

  let acceptPdf = {
    'application/pdf':[]
  }

  console.log(onlyPdf, onlyImage, customFileFormat);


const handleFiles=(acceptedFiles)=>{
  if(acceptedFiles.length>0){
    let filearray =[]
    for(var i=0;i<acceptedFiles.length;i++){
        let data = {}
        const file = acceptedFiles[i]
        data['id']=i;
        data['fileName']=file.name;
        data['file'] =file;
        data['size'] =file.size;
        const reader = new FileReader();  

        reader.addEventListener("load", () => {    
            // convert image file to base64 string    
            data['src'] = reader.result;  }, false);  
            filearray.push(data)
            if (file) {    reader.readAsDataURL(file);}
       
    }
    getFiles(filearray);
  }
   
}
 return(
        <Grid container  sx={{backgroundColor:'#EAF0FF'}}>
            <Grid item xs={12} sx={{minHeight:250,display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <Dropzone 
                      accept={onlyPdf ? acceptPdf : onlyImage ? acceptImage : customFileFormat || ""}  
                      onDropRejected={(value)=>{
                          if(value.length>0){
                            if(value[0].errors[0].message == 'File type must be application/pdf')
                              setFormatError('The file is invalid or not supported')
                            else if(value[0].errors[0].message == 'File is larger than 25000000 bytes') {
                              setFormatError('File must have below the size of 25MB.Please select file again!')
                            }else{
                              setFormatError('The file is invalid or not supported')
                            }
                          }
                      }} 
                      onDrop={acceptedFiles =>{ 
                        handleFiles(acceptedFiles)
                        setFormatError('')
                      }} 
                      maxSize={25000000}
                      multiple={singleFile ? false : true}
                  >
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                              <Typography sx={{fontSize:'14px',color:' #0F2C6F',fontWeight:600}}>Drag and drop files here</Typography>
                              {/* <Typography sx={{fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>Please ensure that uploaded order is correct</Typography> */}
                              <Typography sx={{fontSize:'12px',color:'#0F2C6F',fontWeight:400}}>Files Supported:  
                              {"  "}{ customFileText ||
                              <>
                              {onlyImage && `.svg, .png, .jpg,`} {onlyPdf && `.pdf`}  {!onlyPdf && !onlyImage &&  ` , .svg, .png, .jpg, .xls,.pdf`}
                              </>
                              }
                               (Max 25 MB)</Typography>
                              <Button variant="outlined" sx={{backgroundColor:'white',color:'#0F2C6F',fontSize:'14px',mt:2,border: '1px solid #AAC3FF',
                              borderRadius:' 6px','&:hover':{backgroundColor:'white'}}}>Choose file</Button>
                              <Typography sx={{color:"red",fontSize:'14px'}}>{formatError}</Typography>
                            </Box>
                          </div>
                        </section>
                      )}
                 </Dropzone>
            </Grid>
      </Grid>
      
    )
}

export default DropZone;