import { handleActions } from "redux-actions"

const responseDef = {
    isLoading: false,
    response: null,
    isError: false,
}

const sustainableListReducerData = {
    getSustainableList: {...responseDef},
    sustainableCreationData: {...responseDef},
    getSingleSustainableDetailsData: {...responseDef},
    sustainableEditData: {...responseDef},
    sustainableDraftData: {...responseDef},
    sustainableInactiveData: {...responseDef},
}

const sustainableCreationReducerData = {
}

const getSingleSustainableDetailsReducerData = {
}

export const SustainableListReducer = handleActions({
    GET_SUSTAINABLE_LIST_INITIATE: (state, action) => {
        return {
            ...state,
            getSustainableList: {...state.getSustainableList, isLoading: true, isError: false}
        }
    },
    GET_SUSTAINABLE_LIST_SUCCESS: (state, action) => {
        return {
            ...state,
            getSustainableList: {isError: false, response: action.payload, isLoading: false}
        }
    },
    GET_SUSTAINABLE_LIST_FAILURE: (state, action) => {
        return {
            ...state,
            getSustainableList: {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_SUSTAINABLE_LIST_NULL: (state, action) => {
        return {
            ...state,
            getSustainableList: {...responseDef}
        }
    },
    CREATE_SUSTAINABLE_EVENT_INITIATE: (state, action) => {
        return {
            ...state,
            sustainableCreationData: {...state.getSustainableList, isLoading: true, isError: false}
        }
    },
    CREATE_SUSTAINABLE__EVENT_SUCCESS: (state, action) => {
        console.log('reducer 4 ',action.payload);
        return {
            ...state,
            sustainableCreationData: {isError: false, response: action.payload, isLoading: false}
        }
    },
    CREATE_SUSTAINABLE__EVENT_FAILURE: (state, action) => {
        return {
            ...state,
            sustainableCreationData: {response: action.payload, isLoading: false, isError: true}
        }
    },
    STATE_TO_NULL: (state, action) => {
        return {
            ...state,
            sustainableCreationData: {...responseDef}
        }
    },
    GET_SINGLE_SUSTAIN_DETAILS_INITIATE: (state, action) => {
        return {
            ...state,
            getSingleSustainableDetailsData: {...state.getSingleSustainableDetailsData, isLoading: true, isError: false}
        }
    },
    GET_SINGLE_SUSTAIN_DETAILS_SUCCESS: (state, action) => {
        console.log('reducer 4 ',action.payload);
        return {
            ...state,
            getSingleSustainableDetailsData: {isError: false, response: action.payload, isLoading: false}
        }
    },
    GET_SINGLE_SUSTAIN_DETAILS_FAILURE: (state, action) => {
        return {
            ...state,
            getSingleSustainableDetailsData: {response: action.payload, isLoading: false, isError: true}
        }
    },
    GET_SINGLE_SUSTAIN_STATE_TO_NULL: (state, action) => {
        return {
            ...state,
            getSingleSustainableDetailsData: {...responseDef}
        }
    },
    EDIT_SUSTAINABLE_EVENT_INITIATE: (state, action) => {
        return {
            ...state,
            sustainableEditData: {...state.sustainableEditData, isLoading: true, isError: false}
        }
    },
    EDIT_SUSTAINABLE__EVENT_SUCCESS: (state, action) => {
        console.log('reducer 4 ',action.payload);
        return {
            ...state,
            sustainableEditData: {isError: false, response: action.payload, isLoading: false}
        }
    },
    EDIT_SUSTAINABLE__EVENT_FAILURE: (state, action) => {
        return {
            ...state,
            sustainableEditData: {response: action.payload, isLoading: false, isError: true}
        }
    },
    EDIT_STATE_TO_NULL: (state, action) => {
        return {
            ...state,
            sustainableEditData: {...responseDef}
        }
    },
    UPDATE_SUSTAINABLE_DRAFT_INITIATE: (state, action) => {
        return {
            ...state,
            sustainableDraftData: {...state.sustainableEditData, isLoading: true, isError: false}
        }
    },
    UPDATE_SUSTAINABLE_DRAFT_SUCCESS: (state, action) => {
        console.log('reducer 4 ',action.payload);
        return {
            ...state,
            sustainableDraftData: {isError: false, response: action.payload, isLoading: false}
        }
    },
    UPDATE_SUSTAINABLE_DRAFT_FAILURE: (state, action) => {
        return {
            ...state,
            sustainableDraftData: {response: action.payload, isLoading: false, isError: true}
        }
    },
    UPDATE_STATE_TO_NULL: (state, action) => {
        return {
            ...state,
            sustainableDraftData: {...responseDef}
        }
    },

    INACTIVE_SUSTAINABLE_EVENT_INITIATE: (state, action) => {
        return {
            ...state,
            sustainableInactiveData: {...state.sustainableInactiveData, isLoading: true, isError: false}
        }
    },
    INACTIVE_SUSTAINABLE__EVENT_SUCCESS: (state, action) => {
        console.log('reducer 4 ',action.payload);
        return {
            ...state,
            sustainableInactiveData: {isError: false, response: action.payload, isLoading: false}
        }
    },
    INACTIVE_SUSTAINABLE__EVENT_FAILURE: (state, action) => {
        return {
            ...state,
            sustainableInactiveData: {response: action.payload, isLoading: false, isError: true}
        }
    },
    INACTIVE_STATE_TO_NULL: (state, action) => {
        return {
            ...state,
            sustainableInactiveData: {...responseDef}
        }
    },


},sustainableListReducerData)

// export const CreateSustainableReducer = handleActions({

// }, sustainableCreationReducerData)

// export const getSingleSustainableDetailsReducer = handleActions({

// }, getSingleSustainableDetailsReducerData)

