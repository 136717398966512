import {
  Box,
  Button,
  FormControl,
  Chip,
  IconButton,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  ListItemText,
  Checkbox,
  Select,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  List,
  ListItem,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as BackIcon } from "../../Assets/icons/back.svg";
import { ReactComponent as RefreshIcon } from "../../Assets/icons/Refresh.svg";
import { ReactComponent as DatePickerIcon } from "../../Assets/icons/DatePickerIcon.svg";
import AvatarRemove from "../../Assets/icons/avatarRemove.png";
import { MenuProps } from "../../Utils/utils.js";
import styled from "@emotion/styled";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import * as CommonAction from "../../redux/actions/commonAction";
import * as BmsStaffAction from "../../redux/actions/bmsStaffAction";
import { connect } from "react-redux";
import {
  getLastUpdatedTime,
  getLoggedUserData,
  getYYYYMMDDFormat,
  preventDatePickerKeyboard,
} from "../../Utils/utils.js";
import ToastAlert from "../common/Alert";
import moment from "moment";
import { makeStyles, useTheme } from "@mui/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { ReactComponent as RoundedPlusIcon } from "../../Assets/icons/RoundedPlusIcon.svg";
import EmptyAvatar from "../../Assets/icons/Default.png";
import EmptyJpg from "../../Assets/icons/EmptyJpg.png";
import ApiService from "../../AxiosConfig/ApiService.js";
import Close from "@mui/icons-material/Close";

const StyledTypography = styled(Typography)({
  color: "#0F2C6F",
  fontSize: "14px",
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 312,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: " #0F2C6F",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontWeight: 500,
  },
}));

const StyledOutlinedInput = styled(OutlinedInput)({
  borderRadius: "6px",
  width: "90%",
  height: "48px",
  color: "#0F2C6F",
  marginTop: "10px",
});

const UpdateEmpDetails = ({
  empDetails,
  getEmpDetailsById,
  updateEmployeeStatus,
  updateEmployee,
  setStatusNull,
  getAllLevels,
  getUnitsByLevelid,
  levelDatas,
  unitDatas,
  getAllRoles,
  roleData,
  getStaffRoles,
  bmsRoles,
  levelList,
  getStaffLevel,
  assignedLevelList,
  assignedUnitList,
  getAssignedLevel,
  getAssignedUnit,
}) => {
  const navigate = useNavigate();
  const inputImg = useRef();
  const { emp_id, activeType } = useParams();
  const [employeeDetails, setEmployeeDetails] = useState([]);
  const [supportingFiles, setSupportingFiles] = useState(null);
  const [companyDetails, setCompanyDetails] = useState({});
  const [isToggle, setIsToggle] = useState(false);
  const [time, setTime] = useState({ hour: "00", minute: "00", am_pm: "" });
  const [empData, setEmpData] = useState({
    firstName: "",
    lastName: "",
    mail: "",
    jobPosition: "",
    mobile: "",
    office: "",
    onboardDate: "",
    offboardDate: "",
    submittedBy: "",
    jobTitle: "",
    tenantRole: "",
    level: "",
    unit: "",
    role_id: "",
  });
  console.log(empData);
  const [empUpdateStatus, setEmpUpdateStatus] = useState({
    message: "",
    status: false,
    color: "",
    icon: "",
  });
  const [accessStartDate, setAccessStartDate] = useState(null);
  const [accessError, setAccessError] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [isPhoneNumberChanged, setIsPhoneNumberChanger] = useState({
    mobile: false,
    office: false,
  });
  const [personName, setPersonName] = useState([]);
  const [personNameError, setPersonNameError] = useState("");
  const [levelLists, setLevelLists] = useState([]);
  const classes = useStyles();
  const [assignedUnitsArrayList, setAssignedUnitsArrayList] = useState([]);
  const [levels, setLevels] = React.useState("");
  const [units, setUnits] = React.useState("");
  console.log(units);
  const [unitNumber, setUnitNumber] = React.useState("");
  console.log(unitNumber);
  const [items, setItems] = React.useState([]);
  const [itemsError, setItemsError] = React.useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  console.log(selectedItems);
  console.log(items);
  const [levelValidation, setLevelValidation] = useState("");
  const [imageErr, setImageErr] = useState(false);
  const [assignedCompanyUnits, setAssignedCompanyUnits] = useState([]);
  const [filteredAssignedCompanyUnits, setFilteredAssignedCompanyUnits] =
    useState([]);
  const [isExists, setIsExists] = useState(false);
  const [defaultUnidValidate, setDefaultUnitValidate] = useState(null);
  const [modalData, setModalData] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const handleDelete = (id) => () => {
    const itemFilter = items.filter((val) => val.assigned_units_id !== id);
    setItems(itemFilter);
    const selectedFilters = selectedItems.filter((val) => val !== id);
    setSelectedItems(selectedFilters);
  };

  useEffect(() => {
    setTime({ ...time, timeStr: getLastUpdatedTime() });
    getEmpDetailsById({ employee_id: emp_id });
    getAllLevels();
    getStaffLevel();
    getAssignedLevel();
    if (checkTenantManger()) {
      getAllRoles();
    } else if (checkBMSRoles()) {
      getStaffRoles();
    }
  }, []);

  useEffect(() => {
    ApiService()
      .get(`${process.env.REACT_APP_API_URL}/getFRconfig`)
      .then((res) => setModalData(res.data?.response_json?.fr_contents))
      .catch((err) => console.log(err));
  }, []);

  const handleClick = (data) => {
    setSelectedItems((prev) => {
      const isAlreadySelected = selectedItems.some(
        (item) => item.assigned_units_id === data.assigned_units_id
      );

      if (isAlreadySelected) {
        return selectedItems.filter(
          (item) => item.assigned_units_id !== data.assigned_units_id
        );
      } else {
        return [...prev, data];
      }
    });
  };

  const handleChange = (event) => {
    setPersonName(event.target.value);
    setPersonNameError("");
  };

  const handleToggleChange = () => {
    if (!isToggle) {
      setOpenModal(true);
    } else {
      setImageErr(false);
      setSupportingFiles(null);
      setIsToggle((prev) => !prev);
    }
  };

  const handleConfrim = () => {
    setImageErr(false);
    setSupportingFiles(null);
    setIsToggle((prev) => !prev);
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const ModalAlert = () => {
    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        sx={{ "& .MuiDialog-paper": { width: "60%" } }} // Fixed width for the dialog
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "25px",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottom: "1px solid #e0e0e0", // Bottom border
            gap: "10px", // Gap between content
          }}
        >
          <Typography
            sx={{ fontWeight: "500", fontSize: "18px", color: "#0F2C6F" }}
          >
            {modalData?.fr_title ||
              "Do you want to turn on the facial recognition?"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#0F2C6F",
              marginTop: "15px",
            }}
            gutterBottom
          >
            {modalData?.fr_desc ||
              "  An update is available with new features, a faster experiences,fixes and more. Please update to continue using it."}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              color: "#0F2C6F",
              borderColor: "#AAC3FF",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_cancel_btn || "Cancel"}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleConfrim}
            sx={{
              borderRadius: "6px",
              p: "8px 10px",
              textTransform: "capitalize",
              backgroundColor: "#123FA9",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                fontFamily: "Inter",
              }}
            >
              {modalData?.fr_confirm_btn || "Confirm and Turn On"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleInputImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      e.target.value = null;
      setImageErr(false);
    }
  };

  const handleImage = () => {
    inputImg.current.click();
  };

  const handleDropImage = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSupportingFiles({
        file: file,
        fileName: file.name,
        size: file.size,
        src: URL.createObjectURL(file), // Create a blob URL for image preview
      });
      setImageErr(false);
      e.target.value = null;
    }
  };

  const handleDragImage = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const findData = unitDatas?.find(
      (value) => value?.unit_id === empData?.unit
    );
    setDefaultUnitValidate(findData);
  }, [unitDatas, empData?.unit]);

  const addLevelAndUnits = () => {
    const selected = empDetails?.assignedUnitsArrayList?.map(
      (value) => value.assigned_units_id
    );
    setSelectedItems(selected);
    setItems(empDetails?.assignedUnitsArrayList);
  };

  useEffect(() => {
    if (empDetails?.employeeArrayList?.length > 0) {
      setEmployeeDetails(empDetails.employeeArrayList);
      setCompanyDetails(empDetails.companyArrayList[0]);
      setAssignedUnitsArrayList(empDetails?.assignedUnitsArrayList || []);
      const dat = empDetails?.assignedUnitsArrayList?.map(
        (item) => item.level_name
      );
      setPersonName(dat);
      setLevelLists(empDetails?.assignedUnitsArrayList);
      const { employeeArrayList } = empDetails;

      if (employeeArrayList[0]?.is_fr_enabled === 1) {
        setIsToggle(true);
        setIsExists(true);
      } else {
        setIsToggle(false);
        setIsExists(false);
      }

      setEmpData({
        ...empData,
        firstName: employeeArrayList[0]?.first_name,
        lastName: employeeArrayList[0]?.last_name,
        mail: "",
        jobPosition: "",
        mobile: `${employeeArrayList[0]?.mobile_country_code} ${employeeArrayList[0]?.mobile_no}`,
        office: `${
          employeeArrayList[0]?.office_country_code != null
            ? `${employeeArrayList[0]?.office_country_code}  ${employeeArrayList[0]?.office_phone_no}`
            : ""
        } `,
        mobileCode: employeeArrayList[0]?.mobile_country_code,
        officeCode: employeeArrayList[0]?.office_country_code,
        onboardDate: "",
        offboardDate: "",
        submittedBy: "",
        jobTitle: employeeArrayList[0]?.job_title,
        tenantRole: "",
        unit: employeeArrayList[0]?.unit_id,
        level: employeeArrayList[0]?.level_id,
        role_id: empDetails.employeeArrayList[0].employee_role_id,
      });
      setStartDate(
        new Date(empDetails.employeeArrayList[0]?.access_start_date)
      );
      addLevelAndUnits(empDetails);
    }

    return () => {
      setStatusNull();
    };
  }, [empDetails]);

  useEffect(() => {
    if (empData.level != "" && empData.level != undefined) {
      getUnitsByLevelid({ level_id: empData.level });
    }
  }, [empData.level]);

  useEffect(() => {
    if (updateEmployeeStatus?.response_status === "success") {
      setEmpUpdateStatus({
        message: updateEmployeeStatus?.response_json?.success_message,
        status: true,
        color: "green",
        icon: "tick",
      });
      setTimeout(() => {
        setEmpUpdateStatus({ message: "", status: false, color: "", icon: "" });
        setStatusNull();
        if (
          getLoggedUserData().role_id == 2 ||
          getLoggedUserData().role_id == 14
        ) {
          navigate("/manage_staff/0");
        } else {
          navigate("/manage_employees/0");
        }
      }, 5000);
    } else if (updateEmployeeStatus?.response_status === "fail") {
      setEmpUpdateStatus({
        message: updateEmployeeStatus?.error_json?.error_message,
        status: true,
        color: "red",
        icon: "close",
      });
      setTimeout(() => {
        setEmpUpdateStatus({ message: "", status: false, color: "", icon: "" });
        setStatusNull();
      }, 5000);
    }
  }, [updateEmployeeStatus]);

  const checkTenantManger = () => {
    let id = JSON.parse(localStorage.getItem("userData")).role_id;
    return id === 4 ? true : false;
  };

  const checkBMSRoles = () => {
    let id = JSON.parse(localStorage.getItem("userData")).role_id;
    return id === 2 || id === 3 || id === 14 ? true : false;
  };

  const checkBMSRole = () => {
    let id = JSON.parse(localStorage.getItem("userData")).role_id;
    return id === 2 || id === 14 ? true : false;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmpData({ ...empData, [name]: value });
    setTime({ ...time, timeStr: getLastUpdatedTime() });
  };
  const handleMobileInput = (data, country, event, value) => {
    let { name } = event.target;
    setIsPhoneNumberChanger((prev) => ({ ...prev, [name]: true }));
    setEmpData({
      ...empData,
      [event.target.name]: value,
      [`${name}Code`]: `+${country.dialCode}`,
      [`${name}number`]: data.slice(country.dialCode.length),
    });
  };
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);

  const validation = () => {
    let noErrors = true;
    if (
      (getLoggedUserData().role_id == 2 || getLoggedUserData().role_id == 14) &&
      personName?.length == 0
    ) {
      setPersonNameError("Should not be empty!");
      noErrors = false;
    }
    if (getLoggedUserData().role_id == 4 && items?.length == 0) {
      setItemsError("Should not be empty!");
      noErrors = false;
    }
    if (isToggle && !isExists) {
      if (!supportingFiles?.src) {
        setImageErr("Please upload the image");
        noErrors = false;
      }
    }
    if (!selectedItems.includes(defaultUnidValidate.assigned_units_id)) {
      setLevelValidation(
        "Please add the Default access unit number atleast one in access level field"
      );
      noErrors = false;
    }

    return noErrors;
  };

  const handleSaveButton = () => {
    const validate = validation();
    if (!validate) return;

    const level_array2 = selectedItems?.map((item) => item.assigned_units_id);
    const formData = new FormData();

    formData.append(
      "first_name",
      empData.firstName !== ""
        ? empData.firstName
        : employeeDetails[0]?.first_name
    );
    formData.append(
      "last_name",
      empData.lastName !== "" ? empData.lastName : employeeDetails[0]?.last_name
    );
    formData.append(
      "job_title",
      empData.jobTitle !== "" ? empData.jobTitle : employeeDetails[0]?.job_title
    );
    formData.append("level_no", empData.level);
    formData.append("unit_no", empData.unit);

    formData.append("mobile_no", "");
    formData.append("mobile_country_code", "");
    formData.append("emp_id", employeeDetails[0]?.employee_id);
    formData.append("email", employeeDetails[0]?.email);
    formData.append("role_id", empData.role_id);
    formData.append("assigned_unit_id_str", JSON.stringify(selectedItems));
    formData.append(
      "access_start_date",
      activeType === "inactive" || activeType === "in_active"
        ? getYYYYMMDDFormat(accessStartDate)
        : activeType === "approved"
        ? getYYYYMMDDFormat(startDate)
        : employeeDetails[0]?.access_start_date
    );
    formData.append(
      "is_reonboard",
      activeType === "inactive" || activeType === "in_active" ? "yes" : "no"
    );

    if (isToggle && supportingFiles?.size) {
      formData.append("face_photo", supportingFiles?.file);
    }
    formData.append("enable_fr", isToggle ? Number(1) : Number(0));
    if (!checkTenantManger()) {
      formData.append(
        "office_phone_no",
        isPhoneNumberChanged.mobile
          ? empData?.mobile?.slice(empData?.mobileCode?.length + 1)?.trim()
          : inputRef?.current?.value?.slice(empData?.mobileCode?.length + 1)
      );
      formData.append("office_country_code", empData?.mobileCode);
    }

    if (activeType === "inactive" || activeType === "in_active") {
      if (accessStartDate === null) {
        setAccessError("Enter Reonboard Date");
      } else {
        setAccessError("");
        updateEmployee(formData);
      }
    } else {
      updateEmployee(formData);
    }
  };

  const handleClear = () => {
    window.location.reload();
    // setEmpData({
    //   firstName: "",
    //   lastName: "",
    //   jobPosition: "",
    //   mobile: "+65",
    //   office: "+65",
    // });
  };

  const checkActiveDate = (date) => {
    return new Date() > date ? true : false;
  };

  const handleAddUnits = (e) => {
    setUnits(e.target.value);
    setLevelValidation("");
    setUnitNumber(e.target.value);
  };

  const handleAddClick = () => {
    setLevelValidation("");
    if (
      items.find((val) => val?.assigned_units_id === units.assigned_units_id)
    ) {
      setLevels("");
      setUnits("");
    } else {
      setSelectedItems((prev) => [...prev, units.assigned_units_id]);
      const newItem = {
        assigned_units_id: units.assigned_units_id,
        level_name: levels.level_name,
        unit_name: units.unit_name,
      };
      setItems([...items, newItem]);
      setLevels("");
      setUnits("");
    }
    setAssignedCompanyUnits((prev) => [...prev, units]);
  };

  const removeSupportImage = () => {
    setSupportingFiles(null);
  };

  const IOSSwitch = styled((SwitchProps) => (
    <Switch
      checked={isToggle}
      onChange={handleToggleChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...SwitchProps}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#123FA9",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      <ToastAlert
        message={empUpdateStatus.message}
        status={empUpdateStatus.status}
        color={empUpdateStatus.color}
        icon={empUpdateStatus.icon}
      />
      {openModal && ModalAlert()}
      <Grid container sx={{ backgroundColor: "rgba(143, 146, 161,0.05)" }}>
        <Grid item xs={2} />

        <Grid item xs={8}>
          <Box sx={{ pt: 7, pb: 4 }}>
            <BackIcon onClick={() => navigate(-1)} cursor="pointer" />
            <Typography
              sx={{ color: " #0F2C6F", fontSize: "26px", mt: 3, mb: 1 }}
            >
              {activeType === "inactive" || activeType === "in_active"
                ? "Reonboard "
                : "Update Employee Details"}
            </Typography>
            <Typography sx={{ color: "#9AA6C3", fontSize: "12px" }}>
              Onboarded on{" "}
              {moment(employeeDetails[0]?.access_start_date).format(
                "DD-MM-YYYY"
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor: "#FFFFFF",
              p: 3,
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#0F2C6F", fontSize: "18px", fontWeight: 500 }}
              >
                Personal Details
              </Typography>
              <Typography
                sx={{
                  color: "#6F80A9",
                  fontSize: "14px",
                  fontWeight: 500,
                  mt: 1,
                }}
              >
                Tenant Manager will be the person who is authorised
              </Typography>
              <Typography
                sx={{
                  color: "#6F80A9",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                to make payments on behalf of the company.
              </Typography>
            </Box>
            <img
              style={{
                width: "120px",
                height: "120px",
                borderRadius: "231px",
                border: "1px solid #DBDFE9",
              }}
              src={supportingFiles?.src ? supportingFiles?.src : EmptyAvatar}
            />
          </Box>
          <Grid
            container
            sx={{ backgroundColor: "#FFFFFF", p: 3 }}
            rowSpacing={2}
          >
            <Grid item xs={12} sm={6}>
              <StyledTypography>First Name</StyledTypography>
              <StyledOutlinedInput
                name="firstName"
                onChange={(e) => handleInputChange(e)}
                value={empData.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography>Last Name</StyledTypography>
              <StyledOutlinedInput
                name="lastName"
                value={empData.lastName}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTypography>Work Email Address</StyledTypography>
              <StyledOutlinedInput
                name="mail"
                value={employeeDetails[0]?.email || ""}
                disabled
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <StyledTypography>Job Position</StyledTypography>
              <StyledOutlinedInput
                name="jobTitle"
                value={empData.jobTitle}
                onChange={(e) => handleInputChange(e)}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              {checkTenantManger() && (
                <>
                  <StyledTypography sx={{ mb: 2 }}>
                    {checkTenantManger() && "Tenant Role*"}{" "}
                    {checkBMSRoles() && "BMS Role*"}{" "}
                  </StyledTypography>
                  <FormControl sx={{ minWidth: 150 }} disabled>
                    <Select
                      value={empData.role_id || ""}
                      onChange={(e) =>
                        handleInputChange({
                          target: { name: "role_id", value: e.target.value },
                        })
                      }
                    >
                      {checkTenantManger() &&
                        roleData?.map((ele, index) => (
                          <MenuItem key={index} value={ele.role_id}>
                            {ele.role}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Grid>
            {empData.role_id == 4 && (
              <Grid item xs={12} sm={6}>
                <StyledTypography sx={{ mb: 2 }}>
                  Mobile Number
                </StyledTypography>
                <PhoneInput
                  country={"sg"}
                  value={empData.mobile}
                  onChange={(data, country, event, value) =>
                    handleMobileInput(data, country, event, value)
                  }
                  inputStyle={{
                    height: "48px",
                    border: " 1px solid #9AA6C3",
                    width: "90%",
                  }}
                  inputProps={{ ref: inputRef, name: "mobile" }}
                />
              </Grid>
            )}

            {checkTenantManger() == false && (
              <Grid item xs={12} sm={6}>
                <StyledTypography sx={{ mb: 2 }}>
                  Office Phone Number
                </StyledTypography>
                <PhoneInput
                  name="office"
                  value={empData.office}
                  onChange={(data, country, event, value) =>
                    handleMobileInput(data, country, event, value)
                  }
                  inputStyle={{
                    height: "48px",
                    border: " 1px solid #9AA6C3",
                    width: "100%",
                  }}
                  inputProps={{ ref: inputRef2, name: "office" }}
                />
              </Grid>
            )}
            {checkBMSRole() == true && (
              <Grid item xs={12} sm={6}>
                <StyledTypography>Level</StyledTypography>
                <FormControl
                  className={classes.formControl}
                  style={{ width: "100%" }}
                >
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {levelList?.map((item, index) => (
                      <MenuItem
                        data-key={item.assigned_units_id}
                        onClick={() => handleClick(item)}
                        key={item.assigned_units_id}
                        className="levelName"
                        id={item.assigned_units_id}
                        value={item.level_name}
                      >
                        <Checkbox
                          checked={personName.indexOf(item.level_name) > -1}
                        />
                        <ListItemText primary={item.level_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {personNameError != "" && (
                  <Typography sx={{ color: "#f44336" }}>
                    {personNameError}
                  </Typography>
                )}
              </Grid>
            )}

            {checkTenantManger() && (
              <Grid
                item
                xs={12}
                sx={{
                  paddingRight: { md: "100px", xs: "5px" },
                  padding: "10px 0",

                  width: "100%",
                }}
              >
                <InputLabel
                  sx={{
                    fontWeight: 600,
                    fontSize: "14px",
                    color: "#0F2C6F",
                    paddingBottom: "8px",
                  }}
                >
                  Access level and units *
                </InputLabel>
                <Box>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <FormControl sx={{ display: "flex", width: "50%" }}>
                      <Select
                        value={levels}
                        onChange={(e) => {
                          setLevels(e.target.value);
                          setLevelValidation("");
                        }}
                        displayEmpty
                        placeholder="level"
                        style={{ marginRight: "10px" }}
                        sx={{
                          height: { md: "inherit", sm: "55px", xs: "45px" },
                          color: "#0F2C6F",
                          fontSize: "14px",
                          color: "#0F2C6F",
                        }}
                      >
                        {assignedLevelList?.map((value, index) => (
                          <MenuItem
                            value={value}
                            key={index}
                            onClick={() =>
                              getAssignedUnit({ level_id: value.level_id })
                            }
                          >
                            {value.level_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{ display: "flex", width: "50%", marginLeft: "18px" }}
                    >
                      <Select
                        value={units}
                        onChange={handleAddUnits}
                        displayEmpty
                        placeholder="unit"
                        style={{ marginRight: "10px" }}
                        sx={{
                          height: { md: "inherit", sm: "55px", xs: "45px" },
                          color: "#0F2C6F",
                          fontSize: "14px",
                          color: "#0F2C6F",
                        }}
                      >
                        {assignedUnitList?.map((value, index) => (
                          <MenuItem value={value} key={index}>
                            {value.unit_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <IconButton
                      color="primary"
                      onClick={handleAddClick}
                      disabled={!levels || !units}
                    >
                      <RoundedPlusIcon />
                    </IconButton>
                  </Box>
                  {itemsError != "" && (
                    <Typography sx={{ color: "#f44336" }}>
                      {itemsError}
                    </Typography>
                  )}
                  {levelValidation != "" && (
                    <Typography sx={{ color: "#f44336" }}>
                      {levelValidation}
                    </Typography>
                  )}
                  <Box
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {items.map((item, index) => (
                      <Chip
                        key={index}
                        sx={{
                          color: "#fff",
                          background: "#123FA9",
                          "& .MuiChip-deleteIcon": {
                            color: "#fff",
                            "&:hover": {
                              color: "#B0B0B0",
                            },
                          },
                          fontSize: { md: "14px", xs: "10px" },
                        }}
                        label={`#${item?.level_name}${"-"}${item?.unit_name}`}
                        onDelete={handleDelete(item?.assigned_units_id)}
                      />
                    ))}
                  </Box>
                </Box>
              </Grid>
            )}

            {employeeDetails[0]?.employee_type == "tenant_employee" && (
              <Grid item xs={12}>
                <StyledTypography sx={{ mb: 2 }}>
                  Office Unit Number
                </StyledTypography>
                <Select
                  sx={{
                    height: { md: "inherit", sm: "55px", xs: "45px" },
                    width: "80px",
                    color: "#0F2C6F",
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                  value={Number(empData.level) || ""}
                  name="level"
                  onChange={(e) => handleInputChange(e)}
                >
                  {levelDatas?.map((value, index) => (
                    <MenuItem key={index} value={Number(value.level_id)}>
                      {value.level_name}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  sx={{
                    ml: 2,
                    height: { md: "inherit", sm: "55px", xs: "45px" },
                    fontSize: "14px",
                    color: "#0F2C6F",
                  }}
                  value={Number(empData.unit) || ""}
                  name="unit"
                  onChange={(e) => handleInputChange(e)}
                >
                  {unitDatas?.map((value, index) => (
                    <MenuItem key={index} value={Number(value.unit_id)}>
                      {value.unit_name}
                    </MenuItem>
                  ))}
                </Select>
                {activeType !== "inactive" && (
                  <>
                    <StyledTypography sx={{ mt: 2 }}>
                      Onboard Date
                    </StyledTypography>
                    <DatePicker
                      disablePast
                      inputFormat="dd/MM/yyyy"
                      value={new Date(startDate)}
                      onChange={(value) => setStartDate(value)}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => preventDatePickerKeyboard(e)}
                          {...params}
                        />
                      )}
                      components={{
                        OpenPickerIcon: DatePickerIcon,
                      }}
                      disabled={
                        activeType === "approved" && checkActiveDate(startDate)
                      }
                    />
                  </>
                )}
              </Grid>
            )}
            {(activeType === "inactive" || activeType === "in_active") && (
              <Grid item xs={12}>
                <StyledTypography sx={{ mb: 1 }}>
                  Reonboard Date
                </StyledTypography>
                <DatePicker
                  disablePast
                  inputFormat="dd/MM/yyyy"
                  value={accessStartDate}
                  onChange={(value) => setAccessStartDate(value)}
                  renderInput={(params) => (
                    <TextField
                      onKeyDown={(e) => preventDatePickerKeyboard(e)}
                      {...params}
                    />
                  )}
                  components={{
                    OpenPickerIcon: DatePickerIcon,
                  }}
                />
                <Typography sx={{ color: "red", mt: 1 }}>
                  {accessError}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ backgroundColor: "#FFFFFF", p: 2 }}>
            <Grid item xs={12} sx={{ mb: 4 }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                <Typography
                  sx={{
                    width: "fit-content",
                    borderRadius: "6px",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    letterSpacing: "-0.25px",
                    fontFamily: "inter",
                    color: "#0F2C6F",
                    padding: "0 8px",
                    backgroundColor: "#CFDDFE",
                  }}
                >
                  NEW
                </Typography>
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "-0.25px",
                      fontFamily: "inter",
                      color: "#0F2C6F",
                    }}
                  >
                    Facial Recognition
                  </Typography>
                  <IOSSwitch defaultChecked={false} />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "-0.25px",
                      fontFamily: "inter",
                      color: "#6F80A9",
                    }}
                  >
                    Automatically activate the facial recognition feature for
                    this tenant upon entering the building.
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      letterSpacing: "-0.25px",
                      fontFamily: "inter",
                      color: "#6F80A9",
                    }}
                  >
                    You can skip this step and let the tenant upload it by
                    themselves.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            {isToggle && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    mt: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "24px",
                      letterSpacing: "-0.25px",
                      fontFamily: "inter",
                      color: "#0F2C6F",
                    }}
                  >
                    Photo Requirements:
                  </Typography>
                  <ol style={{ color: "#0F2C6F" }}>
                    <li style={{ color: "inherit" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                        }}
                      >
                        Please submit a single color photograph.
                      </Typography>
                    </li>
                    <li style={{ color: "inherit" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                        }}
                      >
                        Ensure the photo was taken within the last six months.
                      </Typography>
                    </li>
                    <li style={{ color: "inherit" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                        }}
                      >
                        Use a clear image showing the entire face; avoid
                        selfies.
                      </Typography>
                    </li>
                    <li style={{ color: "inherit" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                        }}
                      >
                        Do not alter the photo using computer software, phone
                        apps, or filters.
                      </Typography>
                    </li>
                    <li style={{ color: "inherit" }}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          letterSpacing: "-0.25px",
                          fontFamily: "inter",
                          color: "#0F2C6F",
                        }}
                      >
                        The background should be white or off-white, free from
                        shadows, texture, or lines.
                      </Typography>
                    </li>
                  </ol>
                  <Box sx={{ padding: "16px 24px" }}>
                    <Typography
                      sx={{ color: "#0F2C6F", fontSize: "16px", mb: 2 }}
                    >
                      Supporting Materials(optional)
                    </Typography>
                    {supportingFiles?.size ? (
                      <Grid
                        item
                        xs={12}
                        sx={{
                          border: "2px solid #80808021",
                          mb: 0.5,
                          borderRadius: "5px",
                        }}
                      >
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            p: 0,
                          }}
                        >
                          <ListItem
                            sx={{ pl: 0 }}
                            secondaryAction={
                              <IconButton edge="end" aria-label="delete">
                                <Box
                                  component="img"
                                  src={AvatarRemove}
                                  onClick={removeSupportImage}
                                />
                              </IconButton>
                            }
                          >
                            <ListItemText
                              primary={
                                <Box
                                  sx={{
                                    ml: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={EmptyJpg}
                                    sx={{
                                      width: "40px",
                                      height: "48px",
                                    }}
                                  />
                                  <Typography variant="p" sx={{ m: 2 }}>
                                    {supportingFiles.fileName}
                                    <span
                                      style={{
                                        color: "gray",
                                        fontSize: "15px",
                                      }}
                                    >{`     (${Math.floor(
                                      supportingFiles?.size / 1024
                                    )} of ${Math.floor(
                                      supportingFiles?.size / 1024
                                    )}KB)`}</span>
                                  </Typography>
                                </Box>
                              }
                              secondary={""}
                            />
                          </ListItem>
                        </List>
                      </Grid>
                    ) : (
                      <>
                        <Grid
                          container
                          sx={{
                            backgroundColor: imageErr ? "#FEE4E2" : "#EAF0FF",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              minHeight: 250,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              onDragOver={handleDragImage}
                              onDrop={handleDropImage}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: " #0F2C6F",
                                  fontWeight: 600,
                                }}
                              >
                                Drag and drop files here
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "#0F2C6F",
                                  fontWeight: 400,
                                }}
                              >
                                Files Supported: svg, .png, .jpg (Max 25 MB)
                              </Typography>
                              <Button
                                variant="outlined"
                                sx={{
                                  backgroundColor: "white",
                                  color: "#0F2C6F",
                                  fontSize: "14px",
                                  mt: 2,
                                  border: "1px solid #AAC3FF",
                                  borderRadius: " 6px",
                                  "&:hover": {
                                    backgroundColor: "white",
                                  },
                                }}
                                onClick={handleImage}
                              >
                                Choose file
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png,.svg,.gif"
                                  ref={inputImg}
                                  onChange={handleInputImage}
                                  hidden
                                />
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                        <Typography sx={{ color: "#f44336", mt: 2 }}>
                          {imageErr}
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Onboarded Date</StyledTableCell>
                    <StyledTableCell>Offboarded Date</StyledTableCell>
                    <StyledTableCell>Submitted by</StyledTableCell>
                    <StyledTableCell>Role</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ backgroundColor: " #F5F8FF", cursor: "pointer" }}
                  >
                    <StyledTableCell sx={{ fontWeight: 400 }}>
                      {moment(employeeDetails[0]?.access_start_date).format(
                        "DD-MM-YYYY"
                      )}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 400 }}>
                      {employeeDetails[0]?.offboard_date != null &&
                        moment(employeeDetails[0]?.offboard_date).format(
                          "DD-MM-YYYY"
                        )}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 400 }}>
                      {employeeDetails[0]?.created_by_name}
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 400 }}>
                      {employeeDetails[0]?.created_by_role}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", py: 5 }}>
            <Box sx={{ display: "flex" }}>
              <RefreshIcon />
              <Typography
                sx={{
                  display: "inline",
                  color: " #9AA6C3",
                  fontSize: "14px",
                  ml: 1,
                }}
              >
                Last updated at {time.timeStr}
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={() => handleClear()}
                sx={{
                  textTransform: "capitalize",
                  color: " #0F2C6F",
                  height: "50px",
                  borderRadius: "8px",
                  border: "1px solid #AAC3FF",
                  mr: 2,
                  "&:hover": { backgroundColor: "#FFFFFF" },
                }}
              >
                Clear
              </Button>
              <Button
                onClick={() => handleSaveButton()}
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#123FA9",
                  height: "50px",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  "&:hover": { backgroundColor: "#123FA9" },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={2} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    empDetails: state.CommonReducer.empDetails,
    updateEmployeeStatus: state.CommonReducer.updateEmployeeStatus,
    levelDatas: state.CommonReducer.tenantOccupiedLeves,
    unitDatas: state.CommonReducer.tenantOccupiedUnits,
    roleData: state.CommonReducer.roleData,
    bmsRoles: state.BMSStaffReducer.rolesList,
    levelList: state.BMSStaffReducer.levelList,
    assignedLevelList: state.BMSStaffReducer.assignedLevelList,
    assignedUnitList: state.BMSStaffReducer.assignedUnitList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmpDetailsById: (data) => dispatch(CommonAction.getEmpDetailsById(data)),
    updateEmployee: (data) => dispatch(CommonAction.updateEmployee(data)),
    getAllLevels: () => dispatch(CommonAction.getTenantAccessLevel()),
    getUnitsByLevelid: (data) =>
      dispatch(CommonAction.getTenantAccessUnit(data)),
    setStatusNull: () => dispatch(CommonAction.setStatusNull()),
    getAllRoles: () => dispatch(CommonAction.getAllRoles()),
    getStaffRoles: () => dispatch(BmsStaffAction.getStaffRole()),
    getStaffLevel: () => dispatch(BmsStaffAction.getStaffLevel()),
    getAssignedLevel: () => dispatch(BmsStaffAction.getAssignedLevel()),
    getAssignedUnit: (data) => dispatch(BmsStaffAction.getAssignedUnit(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmpDetails);
