import { Box, Button, Input, InputLabel, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux'
import { ReactComponent as Logo } from "../../Assets/icons/Logo.svg";
import * as loginaction from "../../redux/actions/loginaction";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import loginBgImage from '../../Assets/icons/loginbg.svg';


const ForgotPassword = ({ hideForgotscreen,loginNullService, email, sendOTPService, verifyOTPService, passwordResetService, sendStatus, verifyStatus, resetStatus }) => {
    const [renderContainer, setRenderContainer] = useState('email');
    const [buttonText, setButtonText] = useState('Send OTP');
    const [passwordVisibility, setPasswordVisibility] = useState({ confirmPassword: false, password: false });
    const [details, setDetails] = useState({ email: email, otp: '', password: '', confirmPassword: '', user_id: null });
    const [errorStatus, setErrorStatus] = useState({
        email: "",
        password: "",
        loginError: "",
        commonError: "",
        otpError: '',
        confirmPassword: ''
    });
    const handleChange = (e) => {
        let { name, value } = e.target;
        setDetails({ ...details, [name]: value }) 
    }

    const handleKeyPress = (e) => {
        if (e.key === " ") {
            e.preventDefault(); 
        }
    }
    useEffect(() => {

        if (sendStatus?.message === 'Invalid Email Address') {
            setErrorStatus({ ...errorStatus, ['loginError']: 'Email Not registered yet!' })
        } else if (sendStatus?.message === 'OTP sent successfully') {
            setDetails({ ...details, ['user_id']: sendStatus?.user_id })
            loginNullService();
            setErrorStatus({ loginError: 'Otp send successfully!' });
            setTimeout(() => {
                setRenderContainer('otp')
                setButtonText('Verify OTP')
                setErrorStatus({ loginError: '' });
            }, 5000);

        }
    }, [sendStatus])

    useEffect(() => {
        if (verifyStatus?.error_message === 'OTP verification failed. Please check your OTP') {
            setErrorStatus({ ...errorStatus, ['loginError']: 'OTP verification failed. Please check your OTP' })
        } else if (verifyStatus?.success_message === 'OTP verified successfully') {
            loginNullService();
            setErrorStatus({ loginError: 'Otp verified successfully!' });
            setTimeout(() => {
                setRenderContainer('password')
                setButtonText('Change Password')
                setErrorStatus({ loginError: '' });
            }, 5000);

        }
    }, [verifyStatus])

    useEffect(() => {
        if (resetStatus?.error_message === 'Password Updated Failed') {
            setErrorStatus({ ...errorStatus, ['loginError']: 'Password Updated Failed' })
        } else if (resetStatus?.message === 'Password updated successfully') {
            loginNullService();
            setErrorStatus({ loginError: 'Password Updated Successfully!' });
            setTimeout(() => {          
                setErrorStatus({ loginError: '' });
                hideForgotscreen(false)
            }, 5000);

        }
    }, [resetStatus])
    const buttonHandler = () => {
        if (renderContainer === 'email') {
            if (verifyEmail()) {
                let data = {
                    email: details.email
                }
                sendOTPService(data)
            }
        } else if (renderContainer === 'otp') {
            if (verifyOTP()) {
                let data = {
                    forgot_otp: details.otp,
                    user_id: details.user_id
                }
                verifyOTPService(data)
            }
        } else if (renderContainer === 'password') {
            if (verifyPassword()) {
                let data = {
                    password: details.confirmPassword,
                    user_id: details.user_id
                }
                passwordResetService(data)
            }
        }
    }
    const verifyEmail = () => {
        let status = false;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (details.email == '' || details.email==undefined) {
            status = false;
            setErrorStatus({ ...errorStatus, ['email']: 'Email Required!' })
        } else if (!details.email.match(emailRegex)) {
            status = false;
            setErrorStatus({ ...errorStatus, ['email']: 'Invalid email!' })
        } else if (details.email.match(emailRegex)) {
            status = true;
            setErrorStatus({ ...errorStatus, ['email']: '' })
        }
        return status;
    }
    const verifyOTP = () => {
        let status = false;
        if (details.otp.length === 6) {
            status = true;
            setErrorStatus({ ...errorStatus, ['otpError']: '' })
        } else if (details.otp.length > 0 && details.otp.length < 6) {
            status = false;
            setErrorStatus({ ...errorStatus, ['otpError']: '6 digit otp required!' })
        } else {
            status = false;
            setErrorStatus({ ...errorStatus, ['otpError']: 'OTP required!' })
        }

        return status;
    }

    const verifyPassword = () => {
        //let passwordRegex = /(?=[A-Za-z0-9@#$*`.,/?;:-_()\|'"%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$*`.,/?;:-_()\|'"%^&+!=])(?=.{8,}).*$/
        let passwordRegex = /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/
        let status = false, confirmPasswordStatus = false, passwordMatched = false;
        let message='',confirmPasswordMessage='',commonError;

        if (details.password.length === 0) {
            status = false;
            message= 'Password required!';
        } else if (details.password.length < 8) {
            status = false;
            message='Password min 8 character required!';
        }  else if(details.password.match(passwordRegex) == null){
            status=false
            message='Password should contain 1 capital letter and one special character'
        } else if (details.password.length >= 8) {
            status = true;
            message= '';
        } 
       

        if (details.confirmPassword.length >= 8) {
            confirmPasswordStatus = true;
            confirmPasswordMessage='';
        } else if (details.confirmPassword.length > 0) {
            confirmPasswordStatus = false;
            confirmPasswordMessage='Password min 8 character required!';
        } else if (details.confirmPassword.length === 0) {
            confirmPasswordStatus = false;
            confirmPasswordMessage='Password required!';
        }

        if (status && confirmPasswordStatus) {
            if (details.confirmPassword === details.password) {
                passwordMatched = true;
                commonError='';
            } else {
                passwordMatched = false;
                commonError='Password not matched!';
            }
        }
        setErrorStatus({ ...errorStatus, ['password']: message,['confirmPassword']:confirmPasswordMessage,['commonError']:commonError})
        return passwordMatched;
    }

    const hideShowPassword = (property, propertyStatus) => {
        setPasswordVisibility({ ...passwordVisibility, [property]: propertyStatus })
    }
    const emailContainer = () => {
        return (
            <>
                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                    }}
                >
                    Corporate Email Address
                </InputLabel>
                <Input
                    type="email"
                    name="email"
                    placeholder="Johndoe@example.com"
                    style={{
                        padding: "12px 6px",
                        border: "1px solid #DBDFE9",
                        borderRadius: "6px",
                        outlineColor: "#123FA9",
                    }}
                    value={details.email}
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                        sx: {
                            height: { md: "25px", sm: "20px", xs: "4px" },
                            fontSize: { md: "16px", sm: "14px", xs: "12px" },
                            color: "#0F2C6F",
                        },
                    }}
                    autoComplete="off"
                    disableUnderline
                />
                <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.email}
                </Typography>
            </>
        )
    }

    const otpContainer = () => {
        return (
            <>
                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                    }}
                >
                    Verify OTP
                </InputLabel>
                <OtpInput
                    isInputNum={true}
                    value={details.otp}
                    onChange={(otp) => setDetails({ ...details, ['otp']: otp })}
                    numInputs={6}
                    containerStyle={{ display: 'flex', justifyContent: 'center' }}
                    inputStyle={{ width: '40px', height: '40px' }}
                    separator={<span> &nbsp;</span>}
                />
                <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.otpError}
                </Typography>
            </>
        )
    }

    const passwordContainer = () => {
        return (
            <>
                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        marginTop: "10px",
                    }}
                >
                    Password
                </InputLabel>
                <Input
                    type={passwordVisibility.password ? 'text' : 'password'}
                    placeholder="xxxxx"
                    name="password"
                    endAdornment={passwordVisibility.password ?
                        <VisibilityIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('password', !passwordVisibility.password)} /> :
                        <VisibilityOffIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('password', !passwordVisibility.password)} />}
                    sx={{
                        padding: "12px 6px",
                        border: "1px solid #DBDFE9",
                        borderRadius: "6px",
                        outlineColor: "#123FA9",
                    }}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleKeyPress}
                    inputProps={{
                        sx: {
                            height: { md: "25px", sm: "20px", xs: "4px" },
                            fontSize: { md: "16px", sm: "14px", xs: "12px" },
                            color: "#0F2C6F",
                        },
                    }}
                    disableUnderline
                />
                {errorStatus.password && <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.password}
                </Typography>
                }

                <InputLabel
                    sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        fontWeight: 600,
                        color: "#0F2C6F",
                        marginTop: "10px",
                    }}
                >
                    Confirm Password
                </InputLabel>
                <Input
                    type={passwordVisibility.confirmPassword ? 'text' : 'password'}
                    endAdornment={passwordVisibility.confirmPassword ?
                        <VisibilityIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('confirmPassword', !passwordVisibility.confirmPassword)} />
                        : <VisibilityOffIcon style={{cursor:'pointer'}} onClick={() => hideShowPassword('confirmPassword', !passwordVisibility.confirmPassword)} />}

                    placeholder="xxxxx"
                    name="confirmPassword"
                    sx={{
                        padding: "12px 6px",
                        border: "1px solid #DBDFE9",
                        borderRadius: "6px",
                        outlineColor: "#123FA9",
                    }}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleKeyPress}
                    inputProps={{
                        sx: {
                            height: { md: "25px", sm: "20px", xs: "4px" },
                            fontSize: { md: "16px", sm: "14px", xs: "12px" },
                            color: "#0F2C6F",
                        },
                    }}
                    disableUnderline
                />
                {errorStatus.confirmPassword && <Typography variant="p" color="error" sx={{ fontSize: "15px" }}>
                    {errorStatus.confirmPassword}
                </Typography>
                }

                {errorStatus.commonError &&
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Typography variant="p" color="error" sx={{ fontSize: "15px", mt: 2 }}>
                            {errorStatus.commonError}
                        </Typography>
                    </Box>
                }
            </>
        )
    }
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100%",
                // background: "#0F2C6F",
                backgroundImage:`url(${loginBgImage})`,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    background: "white",
                    padding: { md: "42px 34px", sm: "30px", xs: "20px" },
                    flexDirection: "column",
                    borderRadius: "8px",
                    width: { sm: "360px", xs: "270px" },
                    mx: "auto",

                    boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.05)",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Logo
                        style={{
                            width: "224px",
                            height: "48px",
                        }}
                    />
                    {/* <Typography
              sx={{
                fontSize: "9px",
                fontWeight: 500,
                color: "#0F2C6F",
                textAlign: "center",
              }}
            >
              Tenant Office Manager Dashboard
            </Typography> */}
                </Box>

                <Box
                    sx={{ padding: "50px 0 ", display: "flex", flexDirection: "column" }}
                    rowGap={1}
                >

                    {
                        renderContainer === 'email' ?
                            emailContainer() :
                            renderContainer === 'otp' ?
                                otpContainer() :
                                renderContainer === 'password' ?
                                    passwordContainer() : null
                    }


                </Box>
                <Box
                    sx={{
                        paddingBotton: "50px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{ textTransform: "none", background: "#123FA9" }}
                        onClick={() => buttonHandler()}
                    >
                        {buttonText}
                    </Button>
                    <Button
                        onClick={() => hideForgotscreen(false)}
                    >
                        Cancel
                    </Button>
                    <Typography variant="p" color="error" sx={{ fontSize: "15px", mt: 1 }}>
                        {errorStatus.loginError}
                    </Typography>
                    {/* <a
              href="/"
              style={{
                textAlign: "center",
                paddingTop: "15px",
                fontSize: "14px",
                fontWeight: 400,
                color: "#0F2C6F",
              }}
            >
              Create new account
            </a> */}
                </Box>
            </Box>
        </Box>
    )
}
const mapStateToProps = (state) => {
    return {
        sendStatus: state.LoginReducer.sendStatus,
        verifyStatus: state.LoginReducer.verifyStatus,
        resetStatus: state.LoginReducer.resetStatus,
    }

}
const mapDispatchToProps = (dispatch) => {
    return {
        sendOTPService: (data) => dispatch(loginaction.ForgotPassword(data)),
        verifyOTPService: (data) => dispatch(loginaction.verifyForgotOTPService(data)),
        passwordResetService: (data) => dispatch(loginaction.passwordResetService(data)),
        loginNullService: () => dispatch(loginaction.loginNullService()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)