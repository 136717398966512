import axios from "axios";
import { createAction } from "redux-actions";
import ApiService from "../../../AxiosConfig/ApiService";
export const updateStateFault = createAction("UPDATE_STATE_FAULT_REPORT");
export const sessionExpired = createAction("SESSION_EXPIRED");
export const setFaultReportStateNull = () => {
  return (dispatch) => {
    dispatch(updateStateFault());
  };
};

export const addFaultReportInitiate = createAction("ADD_FAULT_REPORT_INITIATE");
export const addFaultReportSuccess = createAction("ADD_FAULT_REPORT_SUCCESS");
export const addFaultReportFailure = createAction("ADD_FAULT_REPORT_FAILURE");

export const addFaultReport = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(addFaultReportInitiate());

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/addFaultReportForWeb`,
        data,
        config
      )
      .then((response) => {
        if (response.data.response_json?.response_status === 1) {
          dispatch(addFaultReportSuccess(response.data));
        } else {
          dispatch(addFaultReportFailure(response.data));
        }
      })

      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(addFaultReportFailure());
        }
      });
  };
};

export const addFaultReportAsDraft = (data, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(addFaultReportInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/addFaultReportAsDraft`,
        data,
        config
      )
      .then((response) => {
        if (response.data.response_json?.response_status === "success") {
          dispatch(addFaultReportSuccess(response.data.response_json));
        } else {
          dispatch(addFaultReportFailure(response.data));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(addFaultReportFailure());
        }
      });
  };
};

export const getFaultReportInitiate = createAction("FAULT_REPORT_INITIATE");
export const getFaultReportSuccess = createAction("FAULT_REPORT_SUCCESS");
export const getFaultReportFailure = createAction("FAULT_REPORT_FAILURE");
export const getFaultReportNull = createAction("FAULT_REPORT_NULL");

export const getAllFaultReport = (data) => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getFaultReportInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getFaultReportsForWeb`,
        data,
        config
      )
      .then((response) => {
        if (response?.data?.response_json) {
          dispatch(getFaultReportSuccess(response?.data));
        } else if (
          response.data?.error_json?.error_message === "No data found."
        ) {
          dispatch(getFaultReportFailure([]));
        }
      })

      .catch((err) => {
        if (err?.response?.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(getFaultReportFailure(err?.data));
        }
      });
  };
};

export const getFaultReportNullAction = () => (dispatch) =>
  dispatch(getFaultReportNull());

export const updateReportInitiate = createAction("UPDATE_REPORT_INITIATE");
export const updateReportSuccess = createAction("UPDATE_REPORT_SUCCESS");
export const updateReportFailure = createAction("UPDATE_REPORT_FAILURE");
export const updateFaultReport = (data) => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateReportInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/updateFaultReportStatus`,
        data,
        config
      )
      .then((response) => {
        if (response.data) {
          dispatch(updateReportSuccess(response.data));
        } else {
          dispatch(updateReportFailure("faild"));
        }
      })

      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(updateReportFailure(err.data));
        }
      });
  };
};
export const getFaultReportbyIdInitiate = createAction(
  "FAULTREPORT_BYID_INITIATE"
);
export const getFaultReportbyIdSuccess = createAction(
  "FAULTREPORT_BYID_SUCCESS"
);
export const getFaultReportbyIdFailure = createAction(
  "FAULTREPORT_BYID_FAILURE"
);

export const getFaultReportbyId = (reportId) => {
  let token = localStorage.getItem("userToken");
  let data = {
    fault_report_id: reportId,
  };
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getFaultReportbyIdInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getFaultReportsById`,
        data,
        config
      )

      .then((response) => {
        dispatch(getFaultReportbyIdSuccess(response.data));
      })

      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(getFaultReportbyIdFailure(err.data.response_json));
        }
      });
  };
};
export const faultLocationInitiate = createAction("FAULT_LOCATION_INITIATE");
export const faultLocationSuccess = createAction("FAULT_LOCATION_SUCCESS");
export const faultLocationFailure = createAction("FAULT_LOCATION_FAILURE");

export const getFaultLocationData = (data) => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(faultLocationInitiate());
    axios
      .get(`${process.env.REACT_APP_API_URL}/getAllFaultLocations`, config)
      .then((response) => {
        if (response.data.response_json) {
          dispatch(
            faultLocationSuccess(
              response.data.response_json?.faultLocationArrayList
            )
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(faultLocationFailure(err.data));
        }
      });
  };
};

export const technicianListInit = createAction("TECHNICIANS_INITIATE");
export const technicianListSuccess = createAction("TECHNICIANS_SUCCESS");
export const technicianListFailure = createAction("TECHNICIANS_FAILURE");

export const getTechnicianList = (data) => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return (dispatch) => {
    dispatch(technicianListInit());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/getAssignedToBmsEmployeeList`,
        data,
        config
      )
      .then((response) => {
        dispatch(technicianListSuccess(response.data.response_json));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(technicianListFailure());
        }
      });
  };
};

export const updateWatchStatusInitiate = createAction(
  "UPDATE_WATCHSTATUS_INITIATE"
);
export const updateWatchStatusSuccess = createAction(
  "UPDATE_WATCHSTATUS_SUCCESS"
);
export const updateWatchStatusFailure = createAction(
  "UPDATE_WATCHSTATUS_FAILURE"
);

export const updateWatchStatus = (data) => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateWatchStatusInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/updateFaultReportWatch`,
        data,
        config
      )
      .then((response) => {
        if (response.data.error_json) {
          dispatch(
            updateWatchStatusFailure(response.data.error_json.watch_status[0])
          );
        }

        if (response.data.response_json.response_status == 1) {
          dispatch(
            updateWatchStatusSuccess(response.data.response_json.message)
          );
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(updateWatchStatusFailure());
        }
      });
  };
};

export const getTypeOfFaultInitiate = createAction("GET_TYPEOFFAULT_INITIATE");
export const getTypeOfFaultSuccess = createAction("GET_TYPEOFFAULT_SUCCESS");
export const getTypeOfFaultFailure = createAction("GET_TYPEOFFAULT_FAILURE");

export const getTypeOfFault = () => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getTypeOfFaultInitiate());
    axios
      .get(`${process.env.REACT_APP_API_URL}/getAllFaultReportTypes`, config)
      .then((response) => {
        if (response.data.data_array) {
          dispatch(getTypeOfFaultSuccess(response.data.data_array));
        } else {
          dispatch(getTypeOfFaultFailure());
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(getTypeOfFaultFailure());
        }
      });
  };
};

const updateDraftInitiate = createAction("UPDATE_DRAFT_INITIATE");
const updateDraftSuccess = createAction("UPDATE_DRAFT_SUCCESS");
const updateDraftFailure = createAction("UPDATE_DRAFT_FAILURE");

export const updateDraft = (data) => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(updateDraftInitiate());
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/updateFaultReportAsDraft`,
        data,
        config
      )
      .then((response) => {
        if (response.data) {
          dispatch(updateDraftSuccess(response.data));
        } else {
          dispatch(updateDraftFailure());
        }
      })
      .catch((error) => {
        if (error.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(updateDraftFailure());
        }
      });
  };
};

export const getFaultActiveStatusInitiate = createAction(
  "GET_FAULTACTIVEFILTERSTATUS_INITIATE"
);
export const getFaultActiveStatusSuccess = createAction(
  "GET_FAULTACTIVEFILTERSTATUS_SUCCESS"
);
export const getFaultActiveStatusFailure = createAction(
  "GET_FAULTACTIVEFILTERSTATUS_FAILURE"
);

export const getFaultActiveStatus = () => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getFaultActiveStatusInitiate());
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/getFaultReportFilterStatusForActiveTab`,
        config
      )
      .then((response) => {
        if (response.data.error_json) {
          dispatch(getFaultActiveStatusFailure());
        }

        if (response.data.response_json.response_status == "success") {
          dispatch(
            getFaultActiveStatusSuccess(
              response.data.response_json.faultStatusArrayList
            )
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(getFaultActiveStatusFailure());
        }
      });
  };
};

export const getFaultResolvedStatusInitiate = createAction(
  "GET_FAULTRESOLVEDFILTERSTATUS_INITIATE"
);
export const getFaultResolvedStatusSuccess = createAction(
  "GET_FAULTRESOLVEDFILTERSTATUS_SUCCESS"
);
export const getFaultResolvedStatusFailure = createAction(
  "GET_FAULTRESOLVEDFILTERSTATUS_FAILURE"
);

export const getFaultResolvedStatus = () => {
  let token = localStorage.getItem("userToken");
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return (dispatch) => {
    dispatch(getFaultResolvedStatusInitiate());
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/getFaultReportFilterStatusForClosedTab`,
        config
      )
      .then((response) => {
        if (response.data.error_json) {
          dispatch(getFaultResolvedStatusFailure());
        }

        if (response.data.response_json.response_status == "success") {
          dispatch(
            getFaultResolvedStatusSuccess(
              response.data.response_json.faultStatusArrayList
            )
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(sessionExpired("Session Expired"));
        } else {
          dispatch(getFaultResolvedStatusFailure());
        }
      });
  };
};

export const getMyFaultReportsForWebInitiate = createAction(
  "GET_MY_FAULT_REPORTS_FOR_WEB_INITIATE"
);
export const getMyFaultReportsForWebSuccess = createAction(
  "GET_MY_FAULT_REPORTS_FOR_WEB_SUCCESS"
);
export const getMyFaultReportsForWebFailure = createAction(
  "GET_MY_FAULT_REPORTS_FOR_WEB_FAILURE"
);
export const getMyFaultReportsForWebNull = createAction(
  "GET_MY_FAULT_REPORTS_FOR_WEB_NULL"
);

export const getMyFaultReportsForWebNullAction = () => (dispatch) =>
  dispatch(getMyFaultReportsForWebNull());

export const getMyFaultReportsForWebAction = (params) => async (dispatch) => {
  dispatch(getMyFaultReportsForWebInitiate());
  try {
    const response = await ApiService().post(
      "/getMyFaultReportsForWeb",
      params
    );
    console.log(response);

    if (response.data?.response_status === "success") {
      dispatch(getMyFaultReportsForWebSuccess(response.data));
    } else {
      dispatch(getMyFaultReportsForWebFailure(response.data));
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(sessionExpired("Session Expired"));
    } else {
      dispatch(getMyFaultReportsForWebFailure(error.response.data));
    }
  }
};

export const getPriorityLevelsInitiate = createAction(
  "GET_PRIORITY_LEVELS_INITIATE"
);
export const getPriorityLevelsSuccess = createAction(
  "GET_PRIORITY_LEVELS_SUCCESS"
);
export const getPriorityLevelsFailure = createAction(
  "GET_PRIORITY_LEVELS_FAILURE"
);
export const getPriorityLevelsNull = createAction("GET_PRIORITY_LEVELS_NULL");

export const getPriorityLevelsNullAction = () => (dispatch) =>
  dispatch(getPriorityLevelsNull());

export const getPriorityLevelsAction = () => async (dispatch) => {
  dispatch(getPriorityLevelsInitiate());
  try {
    const response = await ApiService().get("/getPriorityLevels");
    console.log(response);

    if (response.data?.response_status === "success") {
      dispatch(getPriorityLevelsSuccess(response.data));
    } else {
      dispatch(getPriorityLevelsFailure(response.data));
    }
  } catch (error) {
    if (error.response.status === 401) {
      dispatch(sessionExpired("Session Expired"));
    } else {
      dispatch(getPriorityLevelsFailure(error.response.data));
    }
  }
};
