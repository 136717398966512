import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as commonAction from "../../redux/actions/commonAction";
import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  BMS_CO_TENANT_OFFBOARDING_COLUMNS,
  BMS_CO_TENANT_ACTIVE_COLUMNS,
  BMS_CO_TENANT_ONBOARDING_COLUMNS,
  BMS_CO_TENANT_INACTIVE_COLUMNS,
  BMS_CO_TENANT_DRAFT_COLUMNS
} from '../../components/Data/Data.js'
import { ReactComponent as RedTriangleIcon } from "../../Assets/icons/RedTriangleIcon.svg";
import moment from "moment/moment";
import { getStatus } from '../../Utils/ActiveStatus'
import Loader from "../common/Loader";
import { getYYYYMMDDFormat } from "../../Utils/utils";
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#0F2C6F",
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: [theme.palette.common.white],
    fontSize: "13px",
    fontWeight: 550,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    padding: "8px 12px",
  },

  [theme.breakpoints.up("md")]: {
    [`&.${tableCellClasses.body}`]: {
      fontSize: "14px",
      padding: "16px",
    },
    [`&.${tableCellClasses.head}`]: {
      fontSize: "14px",
    },
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  background: "white",
  cursor: 'pointer',
  "&:nth-of-type(even)": {
    backgroundColor: "#F3F4F8",
  },
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#123FA9',
    color: '#FFFFFF',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    textAlign:'center',

  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#123FA9',
  },
}));

const ActiveTenant = ({ getCompanyList, tenantList, tenantCount, dataType, filterData }) => {

  const navigate = useNavigate();
  const [companyList, setCompanyList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [updatedFilterData, setUpdatedFilterData] = React.useState({});
  React.useEffect(() => {
    let timer;

    timer = setTimeout(() => {

      
      setCurrentPage(1)
      let userData = JSON.parse(localStorage.getItem("userData"));
      let data = {
      limit: 10,
      offset: filterData?.offset || 0,
      approved_status: dataType,
      status: filterData.status,
      date: (filterData.date !== '' && filterData.date !=null) ? getYYYYMMDDFormat(filterData.date) : null,
      search: filterData.search,
      max_emp_size: filterData.max_emp_size,
      sort_by:filterData.sort
    };
    setUpdatedFilterData(data)
    if (userData) {
      getCompanyList(data, localStorage.getItem("userToken"));
    } else {
      navigate("/");
    }
  }, 1000)

  return () => clearTimeout(timer);
  }, [filterData]);


  React.useEffect(() => {
    if (tenantList) {
      setCompanyList(tenantList);
    }
  }, [tenantList, tenantCount]);

  const handlePagignation = (event, value) => {
    setCompanyList([])
    setCurrentPage(value);
    let data = {
      limit: 10,
      offset: value - 1,
      approved_status: dataType,
      status: filterData.status,
      date: filterData.date !== '' ? getYYYYMMDDFormat(filterData.date) : '',
      search: filterData.search,
      max_emp_size: filterData.max_emp_size,
      sort_by:filterData.sort
    };
    setUpdatedFilterData(data);
    getCompanyList(data, localStorage.getItem("userToken"));
  };
  const getColumns = () => {
    let columns = dataType === 'drafted' ? BMS_CO_TENANT_DRAFT_COLUMNS :
      dataType === 'submitted' ? BMS_CO_TENANT_ONBOARDING_COLUMNS :
        dataType === 'approved' ? BMS_CO_TENANT_ACTIVE_COLUMNS :
          dataType === 'offboarded' ? BMS_CO_TENANT_OFFBOARDING_COLUMNS : BMS_CO_TENANT_INACTIVE_COLUMNS
    return columns;
  }
  const getTableColumns = (dataType) => {
    let columns = getColumns()
    return (
      columns.map((item, index) => (
        item.column_name !== 'checkBox' ? <StyledTableCell sx={{ color: '#0F2C6F', fontWeight: 500 }} key={index}>{item.column_name}</StyledTableCell> : ''
      ))
    )
  }
  const getStatusData = (rowStatus) => {
    return (<Typography
      variant="p"
      component="span"
      sx={{
        background: getStatus(rowStatus, 'bgcolor'),
        display: "inline-flex",
        padding: { md: "0 8px", xs: "2px 6px" },
        fontWeight: 400,
        fontSize: { md: "14px", xs: "10px" },
        color: getStatus(rowStatus, 'color'),
        borderRadius: "6px",
      }}
    >
      {getStatus(rowStatus, 'text')}
    </Typography>)
  }
  const getOffboardDateWithIcon = (offboardDate) => {
    const date1 = new Date();
    const date2 = new Date(offboardDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (date2 - date1 < 0 || diffDays <= 7) {
      return (

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { md: "10px", xs: "4px" },
          }}
        >
          {moment(offboardDate).format("DD/MM/YYYY")}
          <LightTooltip componentsProps={{}}
            title={date2<date1 ? `Onboard date is exceded` : `Onboard date is comming up in ${diffDays} days`} arrow placement='right-start'>
            <RedTriangleIcon />
          </LightTooltip>

        </Box >
      )
    } else {
      return (
        moment(offboardDate).format("DD/MM/YYYY")
      )

    }


  }
  const renderTableDatas = (rowdata) => {
    let columns = getColumns();
    if (dataType === 'drafted') {
      return (<>
        <StyledTableCell sx={{ color: "#0F2C6F", fontSize: '14px', fontWeight: 400 }}>
          {moment(rowdata?.created_at).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell sx={{ color: ' #0F2C6F', fontSize: '14px', fontWeight: 500 }}>{rowdata?.company_name}</StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>{rowdata?.manageName}</StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { md: "10px", xs: "4px" },
            }}
          >
            {moment(rowdata?.updated_at).format("DD/MM/YYYY")}
            <RedTriangleIcon />
          </Box>
        </StyledTableCell>
      </>)
    } else if (dataType === 'submitted') {
      return (<>
        <StyledTableCell sx={{ color: "#2764F4" }}>
          OB-{rowdata?.company_id}
        </StyledTableCell>
        <StyledTableCell sx={{ color: ' #0F2C6F', fontSize: '14px', fontWeight: 500 }}>{rowdata?.company_name?.toUpperCase()}</StyledTableCell>
        <StyledTableCell>
          {getStatusData(rowdata?.approved_status)}
        </StyledTableCell>
        <StyledTableCell sx={{ color: "#0F2C6F", fontSize: '14px', fontWeight: 400 }}>
          {moment(rowdata?.created_at).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400 }}>
          {getOffboardDateWithIcon(rowdata?.access_start_date)}
        </StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>{rowdata?.manageName}</StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>
          #{rowdata?.level_no}-{rowdata?.unit_no}
        </StyledTableCell></>)
    } else if (dataType === 'approved') {
      return (<>
        <StyledTableCell sx={{ color: ' #0F2C6F', fontSize: '14px', fontWeight: 500 }}>{rowdata?.company_name?.toUpperCase()}</StyledTableCell>
        <StyledTableCell sx={{ color: ' #0F2C6F', fontSize: '14px', fontWeight: 500 }}>{rowdata?.employee_count}</StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>{rowdata?.submitted_by}</StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400 }}>
          {moment(rowdata?.access_start_date).format("DD/MM/YYYY")}
        </StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>{rowdata?.manageName}</StyledTableCell>
        <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>
          #{rowdata?.level_no}-{rowdata?.unit_no}
        </StyledTableCell>
      </>)
    } else if (dataType === 'offboarded') {
      return (
        <>
          <StyledTableCell sx={{ color: "#2764F4" }}>
            OB-{rowdata?.company_id}
          </StyledTableCell>
          <StyledTableCell sx={{ color: ' #0F2C6F', fontSize: '14px', fontWeight: 500 }}>{rowdata?.company_name?.toUpperCase()}</StyledTableCell>
          <StyledTableCell>
            {getStatusData(rowdata?.approved_status)}
          </StyledTableCell>
          <StyledTableCell sx={{ color: "#0F2C6F", fontSize: '14px', fontWeight: 400 }}>
            {moment(rowdata?.created_at).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400 }}>
            {moment(rowdata?.access_end_date).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>{rowdata?.manageName}</StyledTableCell>
          <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>
          {rowdata.reception_no}
          </StyledTableCell>
        </>)
    } else if (dataType === 'deleted') {
      return (
        <>
          {/* <StyledTableCell sx={{ color: "#2764F4" }}>
            OB-{rowdata?.company_id}
          </StyledTableCell> */}
          <StyledTableCell sx={{ color: ' #0F2C6F', fontSize: '14px', fontWeight: 500 }}>{rowdata?.company_name?.toUpperCase()}</StyledTableCell>
          <StyledTableCell>
            {rowdata?.employeeCount}
          </StyledTableCell>
          {/* <StyledTableCell sx={{ color: "#0F2C6F", fontSize: '14px', fontWeight: 400 }}>
            {moment(rowdata?.created_at).format("DD/MM/YYYY")}
          </StyledTableCell> */}
          <StyledTableCell>{rowdata?.submitted_by}</StyledTableCell>
          <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px', fontWeight: 400 }}>
            {moment(rowdata?.access_end_date).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>{rowdata?.manageName}</StyledTableCell>
          {/* <StyledTableCell sx={{ color: '#0F2C6F', fontSize: '14px' }}>
            #{rowdata?.level_no}-{rowdata?.unit_no}
          </StyledTableCell> */}
        </>
      )
    }
  }
  const handleNavigation = (item) => {
    sessionStorage.setItem('filterData', JSON.stringify(updatedFilterData))
    navigate(`/review_tenant_details/${item.company_id}/${dataType}`)
  }
  // if(companyList.length===0)return(<Loader status={true}/>)
  return (
    <Box>
      <Table>
        <TableHead>
          <StyledTableRow >
            {getTableColumns(dataType)}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {companyList && companyList?.map((item) => (
            <StyledTableRow key={item.company_id} onClick={() => handleNavigation(item)} >
              {renderTableDatas(item)}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
          {!tenantCount ? <Typography variant='p' sx={{fontSize:"20px",color:"#0F2C6F"}}>No Data Found</Typography> : ''}
          {tenantCount > 0 ? <Pagination count={Math.ceil(tenantCount / 10)} page={currentPage} size="large" shape="rounded" onChange={handlePagignation} /> : ''}
        </Grid>

      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantList: state.CommonReducer.tenantList,
    tenantCount: state.CommonReducer.tenantCount
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: (data, token) =>
      dispatch(commonAction.getCompanyList(data, token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveTenant);
