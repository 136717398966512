import { Button,Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import {ReactComponent as AddIcon} from '../../Assets/icons/WhiteRoundedPlusIcon.svg';
import StaffTable from "./StaffTable";
import { useNavigate, useParams } from "react-router-dom";

const StyledTab = styled(Tab)({
    textTransform:'capitalize',
    color:'#6F80A9'
})

const StaffTab = ()=>{
    const navigate = useNavigate();
    let {tabId} = useParams();
    const [tabValue,setTabValue] = useState(parseInt(tabId));
    return(
        <>
        <Grid container sx={{backgroundColor:'rgba(143, 146, 161,0.05)',minHeight:"100%"}}>
            <Grid item xs={0.5} />

            <Grid item xs={11}>
                <Box sx={{display:'flex',justifyContent:'space-between',mb:5,mt:8}}>
                    <Box>
                        <Typography sx={{color:'#002D74',fontSize:'26px',fontWeight:500}}>Manage Staff</Typography>
                        <Typography sx={{color:'#002D74',fontSize:'14px',fontWeight:500}}>Manage all staff accounts under building administration.</Typography>
                    </Box>
                    <Button onClick={()=>navigate('/onboard_new_staff')} endIcon={<AddIcon/>} variant="contained" sx={{textTransform:'capitalize',height:'40px',backgroundColor:'#123FA9',borderRadius:'6px',color:'#FFFFFF','&:hover':{backgroundColor:'#123FA9'}, fontSize: {xs: "12px", md: "14px"}, margin: "0 10px" }}>Add Employee</Button>
                </Box>

                <Box sx={{backgroundColor:'#FFFFFF'}}>
                <Box sx={{borderBottom:'1px solid #DBDFE9'}}>
                <Tabs value={tabValue} onChange={(e,value)=>setTabValue(value)}  >
                    <StyledTab label='Active'></StyledTab>
                    <StyledTab label='Onboarding'></StyledTab>
                    <StyledTab label='Offboarding'></StyledTab>
                    <StyledTab label='Inactive'></StyledTab>
                    {/* <StyledTab label='Drafts' ></StyledTab> */}
                </Tabs>
                </Box>
                <Tabpanel value={tabValue} index={0}>
                    <StaffTable activeType='active' tabValue={parseInt(tabValue)} />
                </Tabpanel>
                <Tabpanel value={tabValue} index={1}>
                    <StaffTable activeType='onboarding' tabValue={parseInt(tabValue)} />
                </Tabpanel>
                <Tabpanel value={tabValue} index={2}>
                     <StaffTable activeType='offboard' tabValue={parseInt(tabValue)} />
                </Tabpanel>
                <Tabpanel value={tabValue} index={3}>
                    <StaffTable activeType='in_active' tabValue={parseInt(tabValue)} />
                </Tabpanel>
                {/* <Tabpanel value={tabValue} index={4}>
                    <StaffTable activeType='drafts' tabValue={parseInt(tabValue)} />
                </Tabpanel> */}
                </Box>

            </Grid>

            <Grid item xs={0.5} />
        </Grid>
        </>
    )
}

const Tabpanel =(props)=>{
    const {children,value,index} = props;
    return(
        <>
        {value===index && <>{children}</>}
        </>
    )
}

export default StaffTab;