import { createAction } from "redux-actions";
import ApiService from "../../../AxiosConfig/ApiService";
import { sessionExpired } from "../faultreportAction";

export const getDownloadableServiceRequestFormsInitiate = createAction(
  "GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_INITIATE"
);
export const getDownloadableServiceRequestFormsSuccess = createAction(
  "GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_SUCCESS"
);
export const getDownloadableServiceRequestFormsFailure = createAction(
  "GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_FAILURE"
);
export const getDownloadableServiceRequestFormsNull = createAction(
  "GET_DOWNLOADABLE_SERVICE_REQUEST_FORMS_NULL"
);

export const getDownloadableServiceRequestFormsNullAction = () => (dispatch) =>
  dispatch(getDownloadableServiceRequestFormsNull());

export const getDownloadableServiceRequestFormsAction = () => {
  return async (dispatch) => {
    dispatch(getDownloadableServiceRequestFormsInitiate());

    try {
      const response = await ApiService().get("/get_service_request_forms");
      console.log(response.data, "dev1");
      if (response.data?.response_status === 1) {
        dispatch(getDownloadableServiceRequestFormsSuccess(response.data));
      } else {
        dispatch(getDownloadableServiceRequestFormsFailure(response.data));
      }
    } catch (error) {
      if (error.status === 401) {
        dispatch(sessionExpired("Session Expired"));
      } else {
        dispatch(
          getDownloadableServiceRequestFormsFailure(error.response.data)
        );
      }
    }
  };
};

export const AddServiceRequestInitiate = createAction(
  "ADD_SERVICE_REQUEST_INITIATE"
);
export const AddServiceRequestSuccess = createAction(
  "ADD_SERVICE_REQUEST_SUCCESS"
);
export const AddServiceRequestFailure = createAction(
  "ADD_SERVICE_REQUEST_FAILURE"
);
export const AddServiceRequestNull = createAction("ADD_SERVICE_REQUEST_NULL");

export const AddServiceRequestNullAction = () => (dispatch) =>
  dispatch(AddServiceRequestNull());

export const AddServiceRequestFormsAction = (params) => {
  return async (dispatch) => {
    dispatch(AddServiceRequestInitiate());

    try {
      const response = await ApiService().post(
        "/upload_service_request_forms",
        params
      );
      console.log(response, "file dev");
      if (response.data?.response_status === 1) {
        dispatch(AddServiceRequestSuccess(response.data));
      } else {
        dispatch(AddServiceRequestFailure(response.data));
      }
    } catch (error) {
      if (error.status === 401) {
        dispatch(sessionExpired("Session Expired"));
      } else {
        dispatch(AddServiceRequestFailure(error.response.data));
      }
    }
  };
};

export const GetServiceRequestFormsByIdInitiate = createAction(
  "GET_SERVICE_REQUEST_FORMS_BY_ID_INITIATE"
);
export const GetServiceRequestFormsByIdSuccess = createAction(
  "GET_SERVICE_REQUEST_FORMS_BY_ID_SUCCESS"
);
export const GetServiceRequestFormsByIdFailure = createAction(
  "GET_SERVICE_REQUEST_FORMS_BY_ID_FAILURE"
);
export const GetServiceRequestFormsByIdNull = createAction(
  "GET_SERVICE_REQUEST_FORMS_BY_ID_NULL"
);
export const GetServiceRequestFormsByIdNullAction = () => (dispatch) =>
  dispatch(GetServiceRequestFormsByIdNull());

export const GetServiceRequestFormsByIdAction = (id) => {
  return async (dispatch) => {
    dispatch(GetServiceRequestFormsByIdInitiate());

    try {
      const response = await ApiService().get(
        `/${id}/getServiceRequestFormsById`
      );
      console.log(response, "byIdApi");
      if (response.data?.response_status === 1) {
        dispatch(GetServiceRequestFormsByIdSuccess(response.data));
      } else {
        dispatch(GetServiceRequestFormsByIdFailure(response.data));
      }
    } catch (error) {
      if (error.status === 401) {
        dispatch(sessionExpired("Session Expired"));
      } else {
        dispatch(GetServiceRequestFormsByIdFailure(error.response.data));
      }
    }
  };
};

export const DeleteServiceRequestFormsInitiate = createAction(
  "DELTE_SERVICE_REQUEST_FORMS_INITIATE"
);
export const DeleteServiceRequestFormsSuccess = createAction(
  "DELTE_SERVICE_REQUEST_FORMS_SUCCESS"
);
export const DeleteServiceRequestFormsFailure = createAction(
  "DELTE_SERVICE_REQUEST_FORMS_FAILURE"
);
export const DeleteServiceRequestFormsNull = createAction(
  "DELTE_SERVICE_REQUEST_FORMS_NULL"
);

export const DeleteServiceRequestFormsNullAction = () => (dispatch) =>
  dispatch(DeleteServiceRequestFormsNull());

export const DeleteServiceRequestFormsAction = (id) => {
  return async (dispatch) => {
    dispatch(DeleteServiceRequestFormsInitiate());

    try {
      const response = await ApiService().delete(
        `/${id}/delete_service_request_forms`
      );
      console.log(response, "deleteRequest");
      if (response.data?.response_status === 1) {
        dispatch(DeleteServiceRequestFormsSuccess(response.data));
      } else {
        dispatch(DeleteServiceRequestFormsFailure(response.data));
      }
    } catch (error) {
      if (error.status === 401) {
        dispatch(sessionExpired("Session Expired"));
      } else {
        dispatch(DeleteServiceRequestFormsFailure(error.response.data));
      }
    }
  };
};





export const EditServiceRequestFormsInitiate = createAction(
  "EDIT_SERVICE_REQUEST_FORMS_INITIATE"
);
export const EditServiceRequestFormsSuccess = createAction(
  "EDIT_SERVICE_REQUEST_FORMS_SUCCESS"
);
export const EditServiceRequestFormsFailure = createAction(
  "EDIT_SERVICE_REQUEST_FORMS_FAILURE"
);
export const EditServiceRequestFormsNull = createAction(
  "EDIT_SERVICE_REQUEST_FORMS_NULL"
);

export const EditServiceRequestFormsNullAction = () => (dispatch) =>
  dispatch(EditServiceRequestFormsNull());

export const EditServiceRequestFormsAction = (id, params) => {
  return async (dispatch) => {
    dispatch(EditServiceRequestFormsInitiate());

    try {
      const response = await ApiService().post(
        `/${id}/upload_service_request_forms`,
        params
      );
      console.log(response, "editform");
      if (response.data?.response_status === 1) {
        dispatch(EditServiceRequestFormsSuccess(response.data));
      } else {
        dispatch(EditServiceRequestFormsFailure(response.data));
      }
    } catch (error) {
      if (error.status === 401) {
        dispatch(sessionExpired("Session Expired"));
      } else {
        dispatch(EditServiceRequestFormsFailure(error.response.data));
      }
    }
  };
};


export const downloadServiceRequestFormsInitiate = createAction('DOWNLOAD_SERVICE_REQUEST_FORMS_INITIATE');
export const downloadServiceRequestFormsSuccess = createAction('DOWNLOAD_SERVICE_REQUEST_FORMS_SUCCESS');
export const downloadServiceRequestFormsFailure = createAction('DOWNLOAD_SERVICE_REQUEST_FORMS_FAILURE');
export const downloadServiceRequestFormsNull = createAction('DOWNLOAD_SERVICE_REQUEST_FORMS_NULL');

export const downloadServiceRequestFormsNullAction = () => dispatch => dispatch(downloadServiceRequestFormsNull());

export const downloadServiceRequestFormsAction = (id) => async (dispatch) => {
    dispatch(downloadServiceRequestFormsInitiate());
    try {
        const response = await ApiService().get(`${id}/download_service_request_forms`);
        if(response.data?.response_status === 1){
            dispatch(downloadServiceRequestFormsSuccess(response.data));
        }else{
            dispatch(downloadServiceRequestFormsFailure(response.data));
        }
    } catch (error) {
        if(error.status===401){
            dispatch(sessionExpired('Session Expired'));
          }else{
              dispatch(downloadServiceRequestFormsFailure(error.response.data));
            }
    }
}