import { Badge, Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import * as announcementAction from "../../redux/actions/announcement";
import * as eventsAndPromotionAction from "../../redux/actions/EventsAndPromotionAction";
import { ReactComponent as FaultReportIcon } from "../../Assets/icons/NotificationEvent.svg";
import { ReactComponent as ServiceRequestIcon } from "../../Assets/icons/ServiceRequestIcon.svg";
import * as DashboardAction from "../../redux/actions/Dashboard Action/index";
import styled from "@emotion/styled";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import AnnouncementModalView from "../Announcement/AnnouncementModalView";
import EventandPromotionModal from "../Events_Promotions/ModalView";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const TabPanel = (props) => {
  let { index, value, children } = props;
  return value === index && <>{children}</>;
};

const StyledButton = styled(Button)({
  color: "#0F2C6F",
  fontSize: "14px",
  border: "1px solid #AAC3FF",
  borderRadius: "6px",
});

const AnnouncementNotification = ({
  notification,
  getAnnouncementsList,
  announcementList,
  updateAnnouncementReadStatus,
  updateAnnouncementStatus,
  updateEventsReadStatus,
  eventAndPromotionReadStatus,
  updateNotificationViewStatus,
}) => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showEventsModal, setShowEventsModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [announcementData, setAnnouncementData] = useState([]);

  useEffect(() => {
    let data = {
      limit: 10,
      offset: 0,
      status: "Active",
      type_id: "",
      keyword: "",
      via: "non_list",
    };
    getAnnouncementsList(data);
  }, [eventAndPromotionReadStatus, updateAnnouncementStatus]);

  useEffect(() => {
    setAnnouncementData([...announcementList]);
  }, [announcementList]);

  const handleTabChange = (value) => {
    setTabValue(value);
  };

  const handleModalClose = () => {
    setShowAnnouncementModal(false);
    setShowEventsModal(false);
  };

  const handleViewDetails = (value) => {
    if (value.type == "Announcement") {
      updateAnnouncementReadStatus({ id: value.announcement_id });
      setSelectedData(value);
      setShowAnnouncementModal(true);
    } else {
      updateEventsReadStatus({ id: value.eve_pro_id });
      setSelectedData(value);
      setShowEventsModal(true);
    }
  };

  const stripHtmlTags = (htmlString) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: "rgba(143, 146, 161,0.05)", minHeight: "100%" }}
      >
        <Grid item xs={0.5} />
        <Grid item xs={11}>
          {showAnnouncementModal && (
            <AnnouncementModalView
              item={selectedData}
              handleModalClose={handleModalClose}
            />
          )}
          {showEventsModal && (
            <EventandPromotionModal
              item={selectedData}
              handleModalClose={handleModalClose}
            />
          )}
          <Box sx={{ pt: 8 }}>
            <Typography
              sx={{ color: "#0F2C6F", fontSize: "20px", fontWeight: 550 }}
            >
              Notifications
            </Typography>

            <Box sx={{ backgroundColor: "#FFFFFF", mt: 2 }}>
              <Tabs
                value={tabValue}
                onChange={(e, value) => handleTabChange(value)}
                sx={{ borderBottom: "1px solid #DBDFE9" }}
              >
                <Tab
                  sx={{
                    color: "#123FA9",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                  label="Annoucements"
                />
                <Tab
                  sx={{
                    color: "#123FA9",
                    fontSize: "14px",
                    textTransform: "capitalize",
                  }}
                  label="Tickets"
                />
              </Tabs>
              <TabPanel value={tabValue} index={0}>
                <Grid container>
                  {announcementData?.map((value) => (
                    <Grid item container xs={12} sx={{ p: 3 }}>
                      <Grid item xs={1}>
                        {value.is_readed == "false" && (
                          <Box
                            sx={{
                              color: "#FFFFFF",
                              backgroundColor: "#123FA9",
                              fontSize: "14px",
                              borderRadius: "45%",
                              px: 0.5,
                              height: "8px",
                              width: "1px",
                              mr: 0.4,
                              ml: 5,
                            }}
                          ></Box>
                        )}
                        <FaultReportIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Box sx={{ ml: 3 }}>
                          <Typography
                            sx={{ color: value.color_code || "#BD0251" }}
                          >
                            {value.announcement_type_name || value.type}
                          </Typography>
                          <Typography
                            sx={{ mt: 2, color: "#0F2C6F", fontSize: "18px" }}
                          >
                            {value.title}
                          </Typography>
                          <Typography
                            sx={{
                              my: 1,
                              color: "#0F2C6F",
                              fontSize: "16px",
                              wordBreak: "break-all",
                            }}
                          >
                            {stripHtmlTags(
                              value?.short_desc
                                ? value?.short_desc
                                : value?.desc
                            )}
                          </Typography>
                          <StyledButton
                            sx={{ textTransform: "capitalize" }}
                            variant="outlined"
                            onClick={() => handleViewDetails(value)}
                          >
                            View Details
                          </StyledButton>
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>
                          {moment(value.created_at).format("DD MMM YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>

              <TabPanel value={tabValue} index={1}>
                <>
                  {notification?.notificationMessageArrayList?.map((value) => (
                    <Box
                      sx={{
                        p: 3,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        {value.view_status == "0" ? (
                          <Badge badgeContent={2} variant="dot">
                            <NotificationsNoneTwoToneIcon />
                          </Badge>
                        ) : (
                          <NotificationsNoneOutlinedIcon />
                        )}
                        <Box sx={{ ml: 5 }}>
                          <Typography
                            sx={{ color: "#0F2C6F", fontSize: "18px" }}
                          >
                            {value.title}
                          </Typography>
                          <Typography
                            sx={{ color: "#0F2C6F", fontSize: "16px", my: 1.5 }}
                          >
                            {value.message_description}
                          </Typography>
                          <StyledButton
                            onClick={() => {
                              updateNotificationViewStatus({
                                notification_messages_id:
                                  value.notification_messages_id,
                              });
                              if (value.title == "Service Request") {
                                let data = value?.serviceRequestArrayList[0];
                                navigate(
                                  `/review_service_request/${data?.service_requests_id}/${data?.service_request_types_id}/0/true`
                                );
                              } else if (value.title == "Fault Report") {
                                navigate(
                                  `/review_fault/${value.reference_id}/0/true`
                                );
                              } else if (value.title == "Amenity Booking") {
                                navigate(
                                  `/facility_booking/${value.reference_id}`
                                );
                              }
                            }}
                            sx={{ textTransform: "capitalize" }}
                            variant="outlined"
                          >
                            View Details
                          </StyledButton>
                        </Box>
                      </Box>
                      <Typography sx={{ color: "#6F80A9", fontSize: "14px" }}>
                        {moment(value.created_at).format("DD MMM YYYY")}
                      </Typography>
                    </Box>
                  ))}
                </>
              </TabPanel>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={0.5} />
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    notification: state.DashboardReducer.notification,
    announcementList: state.AnnouncementReducer.announcementList,
    announcementCounts: state.AnnouncementReducer.announcementCounts,
    updateAnnouncementStatus:
      state.AnnouncementReducer.updateAnnouncementReadStatus,
    eventAndPromotionReadStatus: state.eAndpReducer.eventAndPromotionReadStatus,
    updateNotificationStatus:
      state.DashboardReducer.updateNotificationViewStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAnnouncementsList: (data) =>
      dispatch(announcementAction.getAnnouncementsList(data)),
    updateAnnouncementReadStatus: (data) =>
      dispatch(DashboardAction.updateAnnouncementReadStatus(data)),
    updateEventsReadStatus: (data) =>
      dispatch(eventsAndPromotionAction.updateReadStatus(data)),
    updateNotificationViewStatus: (data) =>
      dispatch(DashboardAction.updateNotificationViewStatus(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnouncementNotification);
