import { Box, IconButton, Modal, Typography, Button } from "@mui/material";
import { ReactComponent as CloseButton } from '../../Assets/icons/Alertclose.svg'
import React, { useState } from "react";
import { styled } from "@mui/system";
const Faultalert = ({ confirmFunc,closeFunc,alertData, renderElement=null }) => {
    const [open, setOpen] = useState(true)

    const buttonRed=  {
        backgroundColor: ' #BD0251',
        textTransform: 'capitalize',
        height: '40px',
        padding: '10px',
        fontSize: '14px',
        '&:hover': { backgroundColor: ' #BD0251' }
    }

    const buttonBlue={
        backgroundColor: ' #123FA9;',
        textTransform: 'capitalize',
        height: '40px',
        padding: '10px',
        fontSize: '14px',
        '&:hover': { backgroundColor: '#123FA9;' }
    }


    return (
        <>
            <Modal open={open} >
                <Box sx={{
                    position: 'absolute',
                    top: '20%',
                    left: { xs: '5%', sm: '30%' },
                    backgroundColor: '#FFFFFF',
                    width: { xs: 'auto', md: '600px' }
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #C0C7D7',
                        borderRadius: '8px 8px 0px 0px',
                        padding: '24px'
                    }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: '500', color: '#0F2C6F' }}>{alertData.label}</Typography>
                        <IconButton sixe='large'>
                            <CloseButton onClick={()=>closeFunc('')} />
                        </IconButton>
                    </Box>
                    <Box sx={{  padding: '16px 24px' }}>
                        <Typography sx={{ color: '#6F80A9', fontSize: '14px' }}>{alertData.description}</Typography>

                    </Box>
                    {renderElement}                  
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px' }}>
                        <Button variant='outlined' onClick={()=>closeFunc('')}
                            sx={{
                                border: '1px solid #AAC3FF',
                                borderRadius: '6px',
                                color: '#0F2C6F',
                                width: '66px',
                                height: '40px',
                                padding: '18px',
                                textTransform:'capitalize'
                            }}>Cancel</Button>
                        <Button variant='contained' onClick={()=>confirmFunc('')} sx={alertData.buttonStyle==='red'?buttonRed:buttonBlue}>{alertData.buttonvalue}</Button>
                    </Box>
                </Box>
            </Modal>

        </>
    )
}

export default Faultalert;