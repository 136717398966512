import { Button, Card, CardContent,Skeleton, CardHeader, Checkbox, Chip, Grid, IconButton, List, ListItem, ListItemText, Typography, Modal, OutlinedInput } from '@mui/material'
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react'
import { Link, useParams,useNavigate } from 'react-router-dom'
import { ReactComponent as BackButton } from "../../Assets/icons/back.svg";
import { ReactComponent as BellWatch } from "../../Assets/icons/NotificationBell.svg";
import { ReactComponent as BellUnWatch } from "../../Assets/icons/StructuralFaultBell.svg";
import { ReactComponent as LogoIcon } from '../../Assets/icons/Logo.svg';
import { ReactComponent as NoAttachmentIcon } from '../../Assets/icons/NoAttachment.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ReactComponent as CloseButton } from '../../Assets/icons/Alertclose.svg'
import StatusStepper from '../common/stepper';
import TermsAnConditions from '../common/FaultT&C';
import UpdateFaultReportStatus from '../common/tenantfaultpopup';
import MoreInfoPopup from '../common/tenantfault';
import Faultalert from '../common/faultalert';
import * as FaultReportAction from '../../redux/actions/faultreportAction';
import { connect } from 'react-redux';
import Moreinfo from './moreinfo'
import { checkBmsRoles, checkFCCRole, getTimeStamp } from '../../Utils/utils';
import { saveAs } from 'file-saver'
import ToastAlert from '../common/Alert';
import { getStatus } from '../../Utils/ActiveStatus';
import html2PDF from 'jspdf-html2canvas';
import UnAuthorized from '../common/Unauthorized';

const ReviewReport=({ faultReportData,FaultReportbyId,updateReport,reportUpdateStatus,setFaultReportStateNull,updateWatch,updateWatchStatus,notification})=> {
    
    let {id,tabValue,fromDashboard}=useParams();
    const navigate=useNavigate()
    const [userData, setUserData] = useState({})
    const [alertType, setAlertType] = useState('')
    const[reportData,setReportData]=useState([])
    const [withdrawData, setWithdrawDatas] =
     useState({label:"Withdraw Report", description:"Are you sure you want to withdraw this report?", buttonvalue:"Confirm",buttonStyle:'blue'})
    const [withdrawDisabled,setWithdrawDisabled] = useState(false)
    const [toast,setToast] = useState(false);
    const [watchToast,setWatchToast] = useState(false);
    const [updateToTechnician,setUpdateToTechnician] = useState(false)
    const [comment,setComment] = useState()
    const [commentError,setCommentError] = useState("")
    const [isUnauthorized,setIsUnauthorized] = useState(false)
    let Statuslist = ["submitted","require_more_data","require_more_data_updated","pending_investigation","complete_investigation"]
    //let updateStatusEnable = ["submitted","require_more_data_updated","work_completed",'pending_investigation']
    let updateStatusEnable = ["closed","rejected"]

    useEffect(() => {
        let responseUserData = JSON.parse(localStorage.getItem("userData"));
        setUserData(responseUserData)
        FaultReportbyId(id)    
    }, [updateWatchStatus,id,notification])

    useEffect(() => {
        if(faultReportData.response_status=='success'){
            setReportData(faultReportData.response_json[0])
            setIsUnauthorized(false)
        }else if(faultReportData.response_status=='fail'){
            setIsUnauthorized(true)
        }
       
    }, [faultReportData])

    useEffect(() => {
        if(reportUpdateStatus?.response_status==1){
                FaultReportbyId(id)
                setUpdateToTechnician(false)
                setAlertType('')
                setToast(true)
                setTimeout(()=>{
                    setToast(false)   
                    setFaultReportStateNull()
                    if(userData.role_id== 4){
                        navigate("/fault_reporting/0");
                    }else if(userData.user_id !== reportData?.fault_report?.created_by){
                        navigate("/manage_faults/0")
                    }else{
                        navigate("/my_faults/0")
                    }
                },3000)
        }else if(reportUpdateStatus?.response_status==0){
                setToast(true)
                setTimeout(()=>{
                    setToast(false)   
                    setFaultReportStateNull()
                },3000)
            }
    }, [reportUpdateStatus])

    useEffect(()=>{ 
        if(updateWatchStatus=="Fault report watch updated successfully"){
            setWatchToast(true)
            setTimeout(() => {
                setWatchToast(false)
                setFaultReportStateNull()
            }, 4000)
        }else if(updateWatchStatus!=null){
            console.log('within false')
            setWatchToast(true)
            setTimeout(() => {
                setWatchToast(false)
                setFaultReportStateNull()
            }, 3000)
        }
    },[updateWatchStatus])
        
    const renderAlert = (typeAlert) => {
        console.log('tenant sdeds ',typeAlert)
        setAlertType(typeAlert)
    }    

    const withdrawFault = () => {
        const data = {
            status: 'withdraw',
            fault_report_id:id,
            is_response_format_changed : "yes"
        }
        setAlertType('')
        updateReport(data)
    }
    
    const submitToTechnician = ()=>{
        if(!comment){
            setCommentError("Should not be empty")
        }else{
            const data = {
                status: 'update_to_technician',
                fault_report_id:id,
                fault_description:comment,
                is_response_format_changed : "yes"

            }
            setAlertType('')
            updateReport(data)
        }
       
    }

    const handleFileDownload=(actionType,fileName)=>{
        let i;
        if(actionType==='All'){
            for(i=0;i<reportData?.fault_report_files?.length;i++){
                saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${reportData?.fault_report_files[i]?.path}`, `${reportData?.fault_report_files[i]?.original_name}`)
                }
        }else{
            saveAs(`${process.env.REACT_APP_ASSETS_PATH}/${actionType}`, `${fileName}`)
        }
    }

  
    const downloadPdf = ()=>{
        let data = document.getElementById('print')
        html2PDF(data,{
            jsPDF: {
                format: 'a4',
              },
              imageType: 'image/jpeg',
              output: `${reportData?.fault_report?.fault_title}.pdf`
        })
    }

    const handleWatchUnWatch = (id,status) => {
        let data = {
            fault_report_id:id,
            watch_status:status
        }
        updateWatch(data)
    }

    const getOfficeMobileNo = (data) => {         
            if(data){
                let {country_code, phone_no} = data;
                return `${country_code} ${phone_no}` || "-"
            }
            return "-"
    }
 
 
    return (
        <>
        {isUnauthorized===true ? <UnAuthorized data={faultReportData?.error_json?.error_message} />: <> 
       {reportData? 
       
       <Grid container sx={{ background: '#8080800d',pl:5  }}>
        {watchToast && <ToastAlert  message={updateWatchStatus} status={watchToast} color='green'/>}
        {console.log('type alertType ',alertType)}
          {alertType === 'requestMoreInfo' ? <UpdateFaultReportStatus updateAlertStatus={setAlertType} reportdata={reportData}/> :
                alertType === 'updateInfo' ? <MoreInfoPopup  nextStatus={reportData?.nextStatusListArrayList} updateAlertStatus={setAlertType} reportdata={reportData} status={reportData?.fault_report?.status} /> :
                    alertType === 'withdraw' ? <Faultalert confirmFunc={withdrawFault}  alertData={withdrawData} closeFunc={setAlertType} reportdata={reportData}/> : ''}
            <Grid item xs={12} sx={{ mt: 8, }}> <BackButton
                onClick={() => {                    
                        navigate(-1)
                 }}
                style={{ marginLeft: '5px' }}
                cursor="pointer"
            /></Grid>
           {userData?.role!=='BMS Coordinator'&&reportData?.fault_report?.type_of_fault==='require_more_data'?
           <Grid item xs={12}>
               <Moreinfo/>
           </Grid>:''
           }
            <Grid item xs={12}>
                <Card  sx={{ boxShadow: 'none', maxWidth: 600, background: 'none' }}>
                    <CardHeader
                        sx={{
                            "&.MuiCardHeader-root":{
                            padding:"8px" 
                        }}}
                        title={<Typography
                                sx={{
                                    fontSize: { md: "24px", xs: "20px" },
                                    fontWeight: 500,
                                    color: "#0F2C6F",
                                    display:'inline-flex'
                                }}
                            >
                              Fault Report -   {reportData?.fault_report?.ticket_no!="pending" ? reportData?.fault_report?.ticket_no :  `# XXXXXXXX  `}  
                               {reportData?.fault_report?.status!='withdraw' && reportData?.fault_report?.status!='closed' && reportData?.fault_report?.status!='rejected' &&  <span style={{display:'flex', alignItems:'center', marginLeft:'10px' }}>
                                    {reportData?.fault_report?.watch_status=="yes" ? <BellWatch onClick={()=>handleWatchUnWatch(reportData?.fault_report?.fault_report_id,"no")} style={{cursor:'pointer'}}/> : <BellUnWatch onClick={()=>handleWatchUnWatch(reportData?.fault_report?.fault_report_id,"yes")} style={{cursor:'pointer'}}/>}
                                 </span>
                                }
                            </Typography>}
                        subheader={<Typography
                            sx={{color:' #9AA6C3',mt:1}}
                        >
                            Reported on -  {reportData?getTimeStamp(reportData?.fault_report?.created_at):'' }
                            <Chip label={getStatus(reportData.fault_report?.status,'text')} sx={{color:getStatus(reportData.fault_report?.status,'color'),p:0.5,display:'inline',height:'30px',ml:2,backgroundColor:getStatus(reportData.fault_report?.status,'bgcolor'),borderRadius:'6px' }}/>
                        </Typography>}
                    />
                </Card>
            </Grid>
            <Grid item xs={8}>
              <Box id='print'>  
                
                <Card sx={{ boxShadow: 'none', mr: 1, ml: 1,mt:2 }}>
                <Box sx={{display:'flex',justifyContent:'space-between',px:2,py:1,position:'relative'}}>
                            <Typography sx={{
                                fontSize: '20px',
                                color: "#0F2C6F",
                                zIndex:-1
                            }}>{reportData?.fault_report?.ticket_no=='Pending' ? '' : reportData?.fault_report?.ticket_no} </Typography>
                            <LogoIcon style={{zIndex:'-1'}} />
                </Box>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings">
                                <Button data-html2canvas-ignore="true" onClick={downloadPdf} variant='outlined' sx={{textTransform:'capitalize',fontWeight:600,backgroundColor:'#CFDDFE','&:hover':{backgroundColor:'#CFDDFE'},color:'#0F2C6F'}} endIcon={<FileDownloadOutlinedIcon />}>Download</Button>
                             </IconButton>
                        }
                        title={<Typography
                            sx={{
                                fontSize: '20px',
                                color: "#0F2C6F",
                            }}
                        >
                            Tenant Details
                        </Typography>}

                    />
                    <CardContent>
                        <Grid container>
                            <Grid item xs={6} sx={{ borderBottom: '1px solid #80808021' }}>
                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <ListItem sx={{ pl: 0 }}>
                                        <ListItemText primary={<Typography variant='h6' sx={{color:'#0F2C6F',fontSize:'14px',fontWeight:500}}>{reportData?.fault_report?.user_name}</Typography>} 
                                        secondary={<Typography variant='p' sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:400}}>{reportData?.fault_report?.company_name}</Typography>} />

                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={6} sx={{ borderBottom: '1px solid #80808021' }}>
                                    
                                <List
                                    sx={{
                                        width: '100%',
                                        maxWidth: 360,
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <ListItem sx={{ pl: 0 }}>
                                        <ListItemText primary={
                                        <Typography variant='p' sx={{color:'#0F2C6F',fontSize:'12px',fontWeight:400}}>Office: {getOfficeMobileNo(faultReportData?.response_json?.[0]?.companyArrayList?.[0])}<br/></Typography>
                                    } 
                                     />
                                    </ListItem>
                                </List>
                            </Grid>
 
                            <Grid item xs={12}>
                                    <Box>
                                        <Typography variant='h6' sx={{mb:3,fontSize:'26px',color:'#0F2C6F',wordBreak:"break-all",width:"95%"}}>{reportData?.fault_report?.fault_title}</Typography>
                                        <Typography variant='p' sx={{fontsize:'14px',color:'#0F2C6F'}}>Level {reportData?.fault_report?.level_no}. {reportData?.fault_report?.service_location}</Typography>
                                    </Box>
                              
                            </Grid>
                            <Grid item xs={12} sx={{mt:1.5}}>
                                <Typography variant='p' sx={{fontSize:'14px',color:'#0F2C6F',wordWrap:'break-word'}}>
                                {reportData?.fault_report?.description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardHeader
                        action={
                            <IconButton aria-label="settings">
                                {reportData?.fault_report_files?.length > 0 && 
                                <Button data-html2canvas-ignore="true" variant='outlined' sx={{backgroundColor:"#FFFFFF",fontWeight:600,color:"#0F2C6F",textTransform:'capitalize'}} endIcon={<FileDownloadOutlinedIcon />} onClick={()=>handleFileDownload('All')}>Download All Attachments</Button>
                                }
                                </IconButton>
                        }
                        title={<Typography
                            sx={{
                                fontSize: '20px',
                                color: "#0F2C6F",

                            }}

                        >
                            Supporting Materials <span style={{fontSize:'14px'}}>(optional)</span>

                        </Typography>}
                    />
                    <CardContent>
                        <Grid container>
                            {reportData?.fault_report_files?.length>0?reportData.fault_report_files.map((item,index) => (
                                <Grid key={index} item xs={12} sx={{ border: '2px solid #80808021', mb: 0.5, borderRadius: '5px' }}>
                                    <List
                                        sx={{
                                            width: '100%',
                                            bgcolor: 'background.paper',
                                            p: 0
                                        }}
                                    >
                                        <ListItem sx={{ pl: 0 }} secondaryAction={
                                            <IconButton edge="end" aria-label="delete">
                                                <FileDownloadOutlinedIcon style={{color:'#6F80A9'}} onClick={()=>handleFileDownload(item.path,item.original_name)}/>
                                            </IconButton>}>
                                            <ListItemText primary={<Typography sx={{ ml: 2,color:'#0F2C6F' }} variant='h6'>{item.original_name}</Typography>} secondary={''} />

                                        </ListItem>
                                    </List>
                                </Grid>
                            )): <Box sx={{display:'flex',flexDirection:"column",justifyContent:'center',alignItems:'center',width:'100%',height:80,border:'1px solid #0F2C6F',borderRadius:'6px'}}>
                                 <NoAttachmentIcon />
                                <Typography  sx={{fontSize:'14px',color:' #0F2C6F'}}>No Attachments</Typography>
                                </Box>}
                        </Grid>
                        <Grid  item xs={12} sx={{mt:2}}>
                            <TermsAnConditions />
                        </Grid>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#6F80A9",
                            }}
                        >
                            <Checkbox
                            disabled    
                            checked={true}
                                sx={{
                                    "& .MuiSvgIcon-root": {
                                        fontSize: { md: 28, xs: 18 },
                                    },
                                }}
                            />
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    pr: "6px",
                                    fontSize: { md: "14px", xs: "13px" },
                                }}
                            >
                                I agree with the
                            </Typography>
                            {/* <Link to="#"> */}
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: { md: "14px", xs: "13px" },
                                    }}
                                >
                                    terms and conditions
                                </Typography>
                            {/* </Link> */}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: 'end',
                        mt: 2, pr: 1
                    }}
                >
                    {(userData.user_id === reportData?.fault_report?.created_by) && Statuslist.includes(reportData?.fault_report?.status) &&  
                    <Button  variant='contained' color='error' size='small' sx={{ mb: 10,textTransform:'capitalize',mr:2,fontSize:"16px" }} onClick={() => renderAlert('withdraw')}>Withdraw</Button>
                    }
                {(userData.role !== 'BMS Coordinator' && userData.role!=="BMS Manager" && userData.role!=="BMS Executive" && checkFCCRole() === false) ?
                // ( Statuslist.includes(reportData?.fault_report?.status) && 
                // <Button  variant='contained' color='error' sx={{ mb: 10,textTransform:'capitalize',mt:2,fontSize:"16px" }} onClick={() => renderAlert('withdraw')}>Withdraw</Button>) 
                <></>
                :
                ((reportData?.fault_report?.status==='withdraw'  || reportData?.fault_report?.status==='closed')? '':

                (userData.role =="BMS Coordinator" || userData.role=="BMS Manager" || userData.role=="BMS Executive" || checkFCCRole()) && 
                <>
                {reportData?.fault_report?.status==='submitted' && <Button variant='outlined' sx={{ mr: 2, mb: 10,textTransform:'capitalize',backgroundColor:"#FFFFFF","&:hover":{backgroundColor:"#FFFFFF"} }} onClick={() => renderAlert('requestMoreInfo')}>Request More Info</Button>
                }
    
                {!updateStatusEnable.includes(reportData?.fault_report?.status) && <Button variant='contained' sx={{ mb: 10,textTransform:'capitalize',backgroundColor:"#123FA9" }}  onClick={() => renderAlert('updateInfo')}>Update Status</Button>}
                  </>
                )
                }
                 
            {userData.role!="Tenant Manager" && reportData?.fault_report?.status==="update_to_bms" && 
                <Button variant='contained' sx={{ mb: 10,textTransform:'capitalize',backgroundColor:"#123FA9" }}  onClick={()=>setUpdateToTechnician(true)}>Update </Button>
            }
                </Box>
                {toast && <ToastAlert  message={reportUpdateStatus?.message} status={toast} color={reportUpdateStatus?.response_status==1 ? 'green' : reportUpdateStatus?.response_status==0 ? 'red' : ''} />}
            </Grid>

            <Grid item xs={4}>
            <Box sx={{ backgroundColor: '#FFFFFF', mx: 3,mt:2 }}>
                        <Box sx={{borderBottom:"1px solid #C0C7D7",py:2,px:3,borderRadius:"8px 8px 0 0 "}}>
                            <Typography sx={{color:"#0F2C6F",fontSize:"14px"}}>Status Updates</Typography>
                        </Box>
                        <Box sx={{p:2}}>
                            {userData.role=="Tenant Manager" ? 
                            <StatusStepper type="FaultReport" withdrawFunc={withdrawFault} currentStatus={reportData.fault_report?.status} statusLogs={reportData?.status_logs?.length>0?reportData?.status_logs:[]} /> : 
                            <StatusStepper type="FaultReport"  setUpdateToTechnician={setUpdateToTechnician} withdrawFunc={withdrawFault} currentStatus={reportData.fault_report?.status} statusLogs={reportData?.fault_report_status_logs?.length>0?reportData?.fault_report_status_logs:[]} />
                            }
                        </Box>
                    </Box>
            </Grid>
        </Grid >: <Skeleton variant='text' height='100%' />}
        </>}

        <Modal open={updateToTechnician}>
                 <Box sx={{
                    position: 'absolute',
                    top: '20%',
                    left: { xs: '5%', sm: '30%' },
                    backgroundColor: '#FFFFFF',
                    width: { xs: 'auto', md: '600px' }
                }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: 'space-between',
                        borderBottom: '1px solid #C0C7D7',
                        borderRadius: '8px 8px 0px 0px',
                        padding: '24px'
                    }}>
                        <Typography sx={{ fontSize: '18px', fontWeight: '500', color: '#0F2C6F' }}>Update Information to Technician </Typography>
                        <IconButton sixe='large'>
                            <CloseButton onClick={()=>setUpdateToTechnician(false)}  />
                        </IconButton>
                    </Box>
                    <Box sx={{  padding: '16px 24px' }}>
                        <Typography sx={{color:'#0F2C6F'}}>Comments for Technician</Typography>
                       <OutlinedInput fullWidth value={comment} onChange={(e)=>{
                        setCommentError('')
                        setComment(e.target.value)}} />
                        {commentError && <Typography sx={{color:"red"}}>{commentError}</Typography>}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '24px' }}>
                        <Button variant='outlined' 
                            onClick={()=>setUpdateToTechnician(false)}
                            sx={{
                                border: '1px solid #AAC3FF',
                                borderRadius: '6px',
                                color: '#0F2C6F',
                                width: '66px',
                                height: '40px',
                                padding: '18px',
                                textTransform:'capitalize'
                            }}>Cancel</Button>
                        <Button onClick={submitToTechnician} variant='contained' sx={{textTransform:"capitalize"}} >Confirm</Button>
                    </Box>
                </Box>
        </Modal>
        </>
    )
}

const mapStateToProps =(state)=>{
    return{
        faultReportData:state.FaultReportReducer.faultReportData,
        reportUpdateStatus: state.FaultReportReducer.reportUpdateStatus,
        updateWatchStatus:state.FaultReportReducer.updateWatchStatus,
        notification:state.DashboardReducer.notification,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return{
        FaultReportbyId:(data)=>{dispatch(FaultReportAction.getFaultReportbyId(data))},
        updateReport: (data) => (dispatch(FaultReportAction.updateFaultReport(data))),
        setFaultReportStateNull:()=>(dispatch(FaultReportAction.setFaultReportStateNull())),
        updateWatch: (data) => (dispatch(FaultReportAction.updateWatchStatus(data))),

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ReviewReport)