import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { connect } from "react-redux";
// import countries from "countries-list";
import * as superAdminActions from "../../redux/actions/superadminAction";
import DeleteAction from "./deletecountry";

const SuperAdminDashBoard = ({ countryAddStatus, addCountry }) => {
  const [action, setAction] = useState("");

  const [countryData, setCountriesData] = useState([]);
  const [country, setCountry] = useState({
    country_name: "",
    country_code: "",
  });

  useEffect(() => {
    let countryArray = [];

    // Object.keys(countries.countries).map((key) =>
    //   countryArray.push(countries.countries[key])
    // );

    // setCountriesData(countryArray);
  }, []);

  const handleAction = (e) => {
    setAction(e.target.value);
  };

  const handleCountryValues = (e) => {
    let newValue = JSON.parse(e.target.value);

    setCountry({ country_code: newValue.phone, country_name: newValue.name });
  };

  const submitCountryData = () => {
    addCountry(country, localStorage.getItem("userToken"));
  };

  return (
    <React.Fragment>
      <FormControl sx={{ m: 5 }}>
        <InputLabel id="select-action">Select Action</InputLabel>
        <Select
          label="Select Action"
          labelId="select-action"
          value={action}
          onChange={(e) => {
            handleAction(e);
          }}
          sx={{ width: 300 }}
        >
          <MenuItem value="add">Add</MenuItem>
          <MenuItem value="delete">Delete</MenuItem>
        </Select>
      </FormControl>

      {action === "add" && (
        <Box sx={{ m: 5 }}>
          <TextField
            id="country_name"
            select
            label="Country Name"
            SelectProps={{
              native: true,
            }}
            onChange={(e) => handleCountryValues(e)}
          >
            {countryData.map((value) => {
              return (
                <option key={value.name} value={JSON.stringify(value)}>
                  {value.name}
                </option>
              );
            })}
          </TextField>

          <TextField
            sx={{ ml: 2, mt: { xs: 3, sm: 0 } }}
            id="country_code"
            select
            label="Country Code"
            // value={country.country_code}
            SelectProps={{
              native: true,
            }}
            onChange={(e) => handleCountryValues(e)}
          >
            {countryData.map((value) => {
              return (
                <option key={value.name} value={JSON.stringify(value)}>
                  {value.phone}
                </option>
              );
            })}
          </TextField>
          <Box sx={{ ml: { xs: 10, sm: 35 }, mt: 3 }}>
            <Button variant="contained" onClick={() => submitCountryData()}>
              submit
            </Button>
          </Box>
        </Box>
      )}

      {action === "delete" && <DeleteAction></DeleteAction>}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    countryAddStatus: state.SuperAdmin.countryAddStatus,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCountry: (data, token) =>
      dispatch(superAdminActions.addCountry(data, token)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminDashBoard);
