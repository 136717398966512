import { Button, OutlinedInput, Grid } from "@mui/material";
import { connect } from "react-redux";
import React, { useState } from "react";
import { addService } from "../../redux/actions/superadminAction";

const AddServiceType = ({ addServiceType, addService }) => {
  const [servicetype, setServicetype] = useState();

  const handleservice = (e) => {
    setServicetype(e.target.value);
  };

  const obj ={
    service_type_name:servicetype
  }
  const submitService = () => {
    addService(obj);
   
  };
  return (
    <>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "start", mt: 7, ml: 10 }}
      >
        <Grid item xs={12}>
          <OutlinedInput
            placeholder="Add Service Type"
            type="text"
            onChange={(e) => {
              handleservice(e);
            }}
          ></OutlinedInput>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={submitService} sx={{ m: 2 }}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};



const mapStateToProps = (state) => {
  return {
    addServiceType: state.SuperAdmin.addServiceType,
  };
};

const mapDispatchToProps =(dispatch)=>{
return{
  addService:(data)=>{
    dispatch(addService(data))
  }
}
}

export default connect(mapStateToProps,mapDispatchToProps)(AddServiceType)