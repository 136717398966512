
import { Box, Button, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ReactComponent as Close } from '../../Assets/icons/Alertclose.svg';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dropzone from '../../Utils/dropzone';
import { ReactComponent as Fileupload } from '../../Assets/icons/Fileupload.svg';
import { connect } from 'react-redux';
import * as faultReportAction from '../../redux/actions/faultreportAction'
import { useNavigate } from 'react-router-dom';
import ToastAlert from './Alert';
const UpdateFaultReportStatus = ({ setFaultReportStateNull,updateAlertStatus, reportUpdateStatus, updateReport, getFaultReports, reportdata }) => {
    const navigate = useNavigate('/fault_reports')
    const [open, setOpen] = useState(true)
    const [report, setReport] = useState({
        faulttype: '',
        ticketowner: '',
        status: 'require_more_data',
        instruction: '',
        

    })
    const [reportError,setReportError] = useState({faulttype:'',instruction:''});
    const [wordcount, setWordcount] = useState(0)
    const [supportImages, setSupportImages] = useState([]);
    const [updateStatus, setUpdateStatus] = useState({ message: '', status: false, color: '', icon: '' })
    useEffect(() => {
        if (reportUpdateStatus) {
            setTimeout(() => {
               setOpen(false)
                updateAlertStatus('')
                setFaultReportStateNull()
            }, 5000);

            setUpdateStatus({
                message: reportUpdateStatus?.message, status: true, color: reportUpdateStatus?.response_json == 0 ? 'red' : 'green',
                icon: reportUpdateStatus?.response_json ==0 ? 'close' : 'tick'
            })
        }
    }, [reportUpdateStatus])

    const handleReport = (e) => {
        const { value, name } = e.target
        if (name !== 'instruction') {


            setReport({ ...report, [name]: value })
            setReportError({
                ...reportError,
                faulttype:''
            })
        } else {
            if (report.instruction.length === 300) {

            } else {
                setReport({ ...report, [name]: value })
                setWordcount(report.instruction.length + 1)
                setReportError({
                    ...reportError,
                    instruction:''
                })
            }
        }
    }

    const receivedFiles = (selectedFiles) => {
        if (selectedFiles.length > 0) {
            setSupportImages([...selectedFiles])
        }
    }
    const removeSupportImage = (id) => {
        let newSupportImages = [];
        for (var i = 0; i < supportImages.length; i++) {
            if (supportImages[i].id !== id) {
                newSupportImages.push(supportImages[i])
            }
        }
        setSupportImages([...newSupportImages])
    }
    const handlePopup = () => {
        setOpen(false)
        updateAlertStatus('')
    }

    const handleUploadButton = (e)=>{
        let selectedImages = supportImages;
        let obj;
        let file = e.target.files;
        let id =selectedImages.length;
        for(let i = 0;i<file.length;i++){
            obj = {id:id,fileName:file[i].name,file:file[i],size:file[i].size}
            selectedImages.push(obj)
            id+=1
        }
        setSupportImages([...selectedImages])
        
    }

    const validate = ()=>{
        let error=true;
        // if(!report.faulttype){
        //     setReportError((prevValue)=>({
        //         ...prevValue,
        //         faulttype:'Should not be empty'
        //     }))
        //     error=false;
        // }
        if(!report.instruction){
            setReportError((prevValue)=>({
                ...prevValue,
                instruction:'Should not be empty'
            }))
            error=false;
        }
        return error;
    }

    const updateReportStatus = () => {
        
        const formdata = new FormData();
        formdata.append( 'status', report.status)
        formdata.append( 'fault_report_id', reportdata?.fault_report?.fault_report_id)
        formdata.append('ticket_owner', report.ticketowner)
        formdata.append( 'type_of_fault', report.faulttype)
        formdata.append( 'file_count',supportImages.length)
        formdata.append('fault_description',report.instruction)
        formdata.append(' is_response_format_changed','yes')

        for(let i=0;i<supportImages.length;i++){
            formdata.append(`file${i}`,supportImages[i].file)
        }

        // const data = {
        //     status: report.status,
        //     fault_report_id: reportdata?.fault_report?.fault_report_id,
        //     ticket_owner: report.ticketowner,
        //     type_of_fault: report.faulttype,
        //     file_count:supportImages.length,
        //     fault_description:report.instruction,
            
        // }
        
        let err = validate();
        if(err){
            updateReport(formdata)
        }
       
    }

    return (
        <>
            <ToastAlert message={updateStatus.message} status={updateStatus.status} color={updateStatus.color} icon={updateStatus.icon} />
        
            <Modal open={open} sx={{ overflow: 'scroll' }} >

                <Box sx={{
                    position: 'absolute',
                    left: { xs: '2%', sm: '30%' },
                    top: '3%',
                    right: '2%',
                  
                    backgroundColor: '#FFFFFF',
                    width: { xs: 'auto', md: '600px' },
                  
                }}>
                    <Box sx={{

                        display: 'flex',
                        justifyContent: "space-between",

                        borderBottom: '1px solid #C0C7D7',
                        borderRadius: '4px 4px 0px 0px',
                        padding: '14px'
                    }}>
                        <Typography sx={{ fontSize: '18px', color: '#0F2C6F' }}>Request more information from tenant</Typography>
                        <Close style={{ cursor: 'pointer' }} onClick={() => handlePopup()}></Close>
                    </Box>
                    <Box sx={{ height: '20px', padding: '16px ' }}>
                        <Typography sx={{ color: '#6F80A9', fontSize: '14px' }}>Queries below will be submitted to the tenant</Typography>

                    </Box>
                    {/* <Box sx={{ height: '80px', padding: '16px',my:2 }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Fault Type *</Typography>
                        <FormControl fullWidth sx={{ mt: '10px' }}>
                            <InputLabel sx={{color:'#9AA6C3'}}>Choose Fault Type</InputLabel>
                            <Select label='Choose Fault Type' sx={{color:' #0F2C6F'}} value={report.faulttype} name='faulttype' onChange={(e) => { handleReport(e) }} >
                                <MenuItem value='Structural Faults'>Structural Faults</MenuItem>
                                <MenuItem value='Toilet Fault'>Toilet Fault</MenuItem>
                                <MenuItem value='Equipment Fault'>Equipment Fault</MenuItem>
                                <MenuItem value='Other Fault'>Other Fault</MenuItem>
                            </Select>
                        </FormControl>
                        {reportError.faulttype && <Typography sx={{color:"#f44336"}}>{reportError.faulttype}</Typography>}
                    </Box> */}
                    {/* <Box sx={{ height: '80px', padding: '24px' }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14px' }}>Ticket Owner *</Typography>
                        <FormControl fullWidth sx={{ mt: '10px' }}>
                            <InputLabel sx={{color:'#9AA6C3'}}>Select Ticket Owner</InputLabel>
                            <Select label='Select Ticket Owner' sx={{color:'#0F2C6F'}} value={report.ticketowner} name='ticketowner' onChange={(e) => { handleReport(e) }}>
                                <MenuItem value='1'>Sandy Tan</MenuItem>
                                <MenuItem value='2'>Sandy </MenuItem>
                                <MenuItem value=' 3'> Tan</MenuItem>
                                <MenuItem value='4'>Sandy Tan</MenuItem>
                            </Select>
                        </FormControl>
                    </Box> */}
                    <Box sx={{ height: '80px', padding: '16px',mt:1 }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '16px' }}>Update Status to*</Typography>
                        <FormControl fullWidth sx={{ mt: '10px' }}>
                            <Select  defaultValue={"require_more_data"}  sx={{color:'#0F2C6F'}}  name='status' onChange={(e) => { handleReport(e) }}>
                                <MenuItem  value='require_more_data'>Require More Information</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ padding: '16px',mt:3 }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '16px',mb:1 }}>Please provide instructions for tenant to update</Typography>

                        <OutlinedInput sx={{ width: { xs: '300px', md: '552px' } }} onChange={(e) => { handleReport(e) }} name='instruction' value={report.instruction} multiline type='text' placeholder='You may write a short summary or note to tenant'></OutlinedInput>
                        <Typography sx={{ color: '#9AA6C3',mt:1 }}>{wordcount}/300</Typography>
                        {reportError.instruction && <Typography sx={{color:"#f44336"}}>{reportError.instruction}</Typography>}
                    </Box>
                    <Box sx={{ padding: '16px' }}>
                        <Typography sx={{ color: '#0F2C6F', fontSize: '14xp', cursor: 'pointer',mb:1 }}>Supporting Material (optional)</Typography>
                        {supportImages?.length > 0 ? <Grid container >
                            {supportImages.map((item) => (
                                <Grid item xs={12} sx={{ border: '2px solid #80808021', mb: 0.5, borderRadius: '5px' }}>
                                    <List
                                        sx={{
                                            width: '100%',
                                            bgcolor: 'background.paper',
                                            p: 0
                                        }}
                                    >
                                        <ListItem sx={{ pl: 0 }} secondaryAction={
                                            <IconButton edge="end" aria-label="delete">
                                                <CloseOutlinedIcon onClick={() => removeSupportImage(item.id)} />
                                            </IconButton>}>
                                            <ListItemText primary={
                                                <Typography variant='p' sx={{ m: 2,color:"#0F2C6F",fontSize:"14px" }}>{item.fileName}
                                                    <span style={{ color: 'gray', fontSize: '12px' }}>{`     (${Math.floor(item.size / 1024)} of ${Math.floor(item.size / 1024)}KB)`}</span>
                                                </Typography>}
                                                secondary={''} />
                                        </ListItem>
                                    </List>
                                                
                                </Grid>

                            ))}
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '550px' }}>
                                <Button component="label" variant='outlined' sx={{ color: '#0F2C6F', mt: 2,textTransform:'capitalize' }} endIcon={<Fileupload></Fileupload>}>
                                    <input multiple onChange={(e)=>handleUploadButton(e)} type="file" style={{display:"none"}}/>
                                    Upload</Button>
                            </Box>
                        </Grid> :
                            <Dropzone getFiles={receivedFiles}></Dropzone>}


                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '24px' }}>
                        <Button sx={{textTransform:'capitalize',backgroundColor:'#123FA9'}} variant='contained' onClick={() => updateReportStatus()}>Submit</Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        reportUpdateStatus: state.FaultReportReducer.reportUpdateStatus
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateReport: (data) => (dispatch(faultReportAction.updateFaultReport(data))),
        getFaultReports: (data) => (dispatch(faultReportAction.getAllFaultReport(data))),
        setFaultReportStateNull: () => dispatch(faultReportAction.setFaultReportStateNull())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateFaultReportStatus);